import {
  OnInit,
  Signal,
  WritableSignal,
  Input,
  signal,
  computed,
  Component,
  HostBinding,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  DestroyRef
} from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { USelectSItem } from '@shift/ulib';

import { TrackingService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { RideOrdersService } from '@app/ride-orders/services';
import { GetRidesPurchaseOrder, GetRidesPurchaseOrderRow, RideOrdersSummary } from '@app/ride-orders/models';
import { BuilderActiveRideCostType, BuilderMode } from '@app/builder/models';
import { builderConfig } from '@app/builder/configs';
import { BuilderCommonService, BuilderDataService, BuilderService } from '@app/builder/services';
import { builderRideOrderSummaryComponentConfig } from './builder-ride-order-summary.component.config';

@Component({
  selector: 'app-builder-ride-order-summary',
  templateUrl: './builder-ride-order-summary.component.html',
  styleUrls: [ './builder-ride-order-summary.component.scss', './builder-ride-order-summary.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderRideOrderSummaryComponent implements OnInit {
  @Input() isRtl: boolean;
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'builder-ride-order-summary';

  config = cloneDeep(builderRideOrderSummaryComponentConfig);
  shuttleCompanies: USelectSItem[] = [];
  shuttleCompanyContracts: USelectSItem[] = [];
  rideOrderSummaryFormStore: RideOrdersSummary;

  readonly #nisText = toSignal(this.translateService.get(this.config.dictionary.nis));
  readonly #purchaseOrders: WritableSignal<GetRidesPurchaseOrder[]> = signal([]);
  readonly #totalPrice = toSignal(this.builderDataService.rideOrderSummaryForm.get('totalPrice').valueChanges
    .pipe(startWith(this.builderDataService.rideOrderSummaryForm.get('totalPrice').value)));
  readonly #purchaseOrder = toSignal(this.builderDataService.rideOrderSummaryForm.get('purchaseOrder').valueChanges
    .pipe(startWith(this.builderDataService.rideOrderSummaryForm.get('purchaseOrder').value)));
  readonly #purchaseOrderRow = toSignal(this.builderDataService.rideOrderSummaryForm.get('purchaseOrderRow').valueChanges
    .pipe(startWith(this.builderDataService.rideOrderSummaryForm.get('purchaseOrderRow').value)));

  readonly purchaseOrdersItems: Signal<USelectSItem[]> = computed(() => this.#purchaseOrders().map(purchaseOrder => ({ value: purchaseOrder.number, name: purchaseOrder.number })));
  readonly rowsInOrdersByOrderNumberItems: Signal<{ [key: string]: USelectSItem[]; }> = computed(() =>
    this.#purchaseOrders()
      .reduce((acc, purchaseOrder) => ({
        ...acc,
        [purchaseOrder.number]: purchaseOrder.rows.map(row => ({
          value: row.number,
          name: row.number,
          subtitle: `${row.remainingBudget} ${this.#nisText()}`,
          notAvailable: row.remainingBudget < this.#totalPrice()
        }))
      }), {})
  );
  readonly selectedRowInOrder: Signal<GetRidesPurchaseOrderRow> = computed(() =>
    this.#purchaseOrders()
      .find(order => order.number === this.#purchaseOrder())
      ?.rows
      .find(orderRow => orderRow.number === this.#purchaseOrderRow())
  );

  constructor(
    private destroyRef: DestroyRef,
    private cdRef: ChangeDetectorRef,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private rideOrdersService: RideOrdersService,
    private builderCommonService: BuilderCommonService,
    private builderService: BuilderService,
    public builderDataService: BuilderDataService
  ) {}

  get showConfirmationAndCommentsFields(): boolean {
    const editMode = this.builderDataService.config().mode === BuilderMode.Edit;
    const departmentChangedToApproving = this.builderDataService.rideOrder.hasApprovingDepartmentStore && this.builderDataService.rideOrder.hasApprovingDepartment;
    const rideEditedByRequestingDepartment = editMode && !this.builderDataService.rideOrder.summaryVisibleStore && this.builderDataService.rideOrder.summaryVisible;
    const approvingDepartmentRemoved = editMode && !this.builderDataService.rideOrder.hasApprovingDepartment && this.builderDataService.rideOrder.hasApprovingDepartmentStore;

    return !rideEditedByRequestingDepartment &&
      (departmentChangedToApproving || approvingDepartmentRemoved || this.builderDataService.rideOrder.readOnlyMode);
  }

  ngOnInit() {
    this.initFormData();
    this.onRideOrderSummaryFormValueChanges();
    this.onHashcalRideTypesItemsChanges();
  }

  private updateRideOrderSummaryFormStore() {
    this.rideOrderSummaryFormStore = this.builderDataService.rideOrderSummaryForm.value;
  }

  private restoreRideOrderSummaryForm() {
    this.builderDataService.rideOrderSummaryForm.patchValue(this.rideOrderSummaryFormStore, { emitEvent: false });
  }

  private getRidesPurchaseOrders(updateRideOrderConfirmationAndCommentsFieldsState: boolean = true) {
    return this.rideOrdersService.getRidesPurchaseOrders({
      routeId: this.builderDataService.addEditForm.get('details.routeId').value,
      period: {
        dateFrom: this.builderDataService.activeRide.date,
        dateTo: this.builderDataService.activeRide.date,
        days: [ this.builderDataService.activeRide.dayOfWeek ]
      }
    })
      .pipe(
        tap((purchaseOrders => {
          this.#purchaseOrders.set(purchaseOrders);

          if (updateRideOrderConfirmationAndCommentsFieldsState) {
            this.builderDataService.checkRideOrderDepartmentsAvailability(purchaseOrders, false);
            this.builderDataService.updateRideOrderConfirmationAndCommentsFieldsState();
          }

          this.cdRef.markForCheck();
        }))
      );
  }

  private getShuttleCompanyContracts() {
    const shuttleCompanyId = this.builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').value;

    if (!shuttleCompanyId) {
      this.shuttleCompanyContracts = [];

      this.cdRef.markForCheck();

      return;
    }

    this.builderService.getShuttleCompanyContracts({
      shuttleCompanyPeriods: [ {
        shuttleCompanyId,
        dateFrom: moment(this.builderDataService.datesChanges.dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE),
        dateTo: moment(this.builderDataService.datesChanges.dates[this.builderDataService.datesChanges.dates.length - 1]).format(AppConstants.DATE_FORMAT_BASE_LINE)
      } ]
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        this.shuttleCompanyContracts = data?.shuttleCompanies[0]?.contracts.map(contract =>
          ({ name: contract.name, value: contract.id })
        ) || [];

        this.cdRef.markForCheck();
      });
  }

  private initFormData() {
    this.updateRideOrderSummaryFormStore();

    this.builderDataService.getRideOrderShuttleCompanyCostTypes();

    this.getShuttleCompanyContracts();

    this.rideOrdersService.getShuttleCompanies({
      branchId: this.builderDataService.activeRide.branchId,
      date: this.builderDataService.activeRide.date
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(shuttleCompanies => {
        this.shuttleCompanies = shuttleCompanies.map(shuttleCompany => ({ value: shuttleCompany.id, name: shuttleCompany.name }));

        this.cdRef.markForCheck();
      });

    of(this.builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').value)
      .pipe(
        switchMap(() => this.getRidesPurchaseOrders(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(purchaseOrders => {
        this.builderDataService.checkRideOrderDepartmentsAvailability(purchaseOrders);
        this.builderDataService.updateRideOrderConfirmationAndCommentsFieldsState();
      });

    this.builderDataService.rideOrderSummaryForm.get('shuttleCompanyId')
      .valueChanges
      .pipe(
        switchMap(shuttleCompanyId =>
          this.rideOrdersService.changeShuttleCompany({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            shuttleCompanyId
          })
            .pipe(
              tap(summary => {
                this.builderDataService.rideOrderSummaryForm.patchValue(summary, { emitEvent: false });

                if (this.rideOrderSummaryFormStore.contractId !== summary.contractId) {
                  this.builderDataService.getRideOrderShuttleCompanyCostTypes();
                }

                this.updateRideOrderSummaryFormStore();
                this.updateRideOrderSummaryFormPriceFieldState();

                this.getShuttleCompanyContracts();
              }),
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        switchMap(() => this.getRidesPurchaseOrders()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.builderDataService.rideOrderSummaryForm.get('costType')
      .valueChanges
      .pipe(
        switchMap(costType =>
          this.rideOrdersService.changeShuttleCompanyCost({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            costType,
            shuttleCompanyId: this.builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').value,
            price: this.builderDataService.rideOrderSummaryForm.get('price').value
          })
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          const { totalPrice, ...fieldsUpdateWithoutEvent } = summary;

          this.builderDataService.rideOrderSummaryForm.patchValue(fieldsUpdateWithoutEvent, { emitEvent: false });
          this.builderDataService.rideOrderSummaryForm.get('totalPrice').patchValue(totalPrice);

          this.updateRideOrderSummaryFormStore();
          this.updateRideOrderSummaryFormPriceFieldState();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.builderDataService.rideOrderSummaryForm.get('hashcalRideType')
      .valueChanges
      .pipe(
        switchMap(hashcalRideType =>
          this.rideOrdersService.changeShuttleCompanyHashcalRideType({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            shuttleCompanyId: this.builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').value,
            hashcalRideType
          })
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          this.builderDataService.rideOrderSummaryForm.patchValue(summary, { emitEvent: false });

          this.updateRideOrderSummaryFormStore();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.builderDataService.rideOrderSummaryForm.get('price')
      .valueChanges
      .pipe(
        debounceTime(500),
        switchMap(price =>
          this.rideOrdersService.changeShuttleCompanyCost({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            costType: this.builderDataService.rideOrderSummaryForm.get('costType').value,
            shuttleCompanyId: this.builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').value,
            price
          })
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          const { totalPrice, ...fieldsUpdateWithoutEvent } = summary;

          this.builderDataService.rideOrderSummaryForm.patchValue(fieldsUpdateWithoutEvent, { emitEvent: false });
          this.builderDataService.rideOrderSummaryForm.get('totalPrice').patchValue(totalPrice);

          this.updateRideOrderSummaryFormStore();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.builderDataService.rideOrderSummaryForm.get('purchaseOrder')
      .valueChanges
      .pipe(
        switchMap(purchaseOrder =>
          this.rideOrdersService.changePurchaseOrder({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            purchaseOrder
          })
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          this.builderDataService.rideOrderSummaryForm.patchValue(summary, { emitEvent: false });

          this.updateRideOrderSummaryFormStore();

          this.builderDataService.updateRideOrderHasApprovingDepartment(false);
          this.builderDataService.updateRideOrderConfirmationAndCommentsFieldsState();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.builderDataService.rideOrderSummaryForm.get('purchaseOrderRow')
      .valueChanges
      .pipe(
        switchMap(purchaseOrderRow =>
          this.rideOrdersService.changePurchaseOrderRow({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            purchaseOrderRow
          })
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          this.builderDataService.rideOrderSummaryForm.patchValue(summary, { emitEvent: false });

          this.updateRideOrderSummaryFormStore();

          this.builderDataService.checkRideOrderApprovingDepartmentAvailability();
          this.builderDataService.updateRideOrderConfirmationAndCommentsFieldsState();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.builderDataService.rideOrderSummaryForm.get('status')
      .valueChanges
      .pipe(
        switchMap(status =>
          this.rideOrdersService.changeStatus({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            status
          })
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          this.builderDataService.rideOrderSummaryForm.patchValue(summary, { emitEvent: false });

          this.updateRideOrderSummaryFormStore();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.builderDataService.rideOrderSummaryForm.get('approvingComments')
      .valueChanges
      .pipe(
        debounceTime(500),
        switchMap(comments =>
          this.rideOrdersService.changeApprovingComments({
            routeId: this.builderDataService.addEditForm.get('details.routeId').value,
            comments
          })
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          this.builderDataService.rideOrderSummaryForm.patchValue(summary, { emitEvent: false });

          this.updateRideOrderSummaryFormStore();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.builderDataService.rideOrderSummaryForm.get('contractId')
      .valueChanges
      .pipe(
        debounceTime(500),
        switchMap(contractId =>
          (
            contractId ?
              this.rideOrdersService.changeContract({
                routeId: this.builderDataService.addEditForm.get('details.routeId').value,
                contractId
              }) :
              this.rideOrdersService.removeContract({
                routeId: this.builderDataService.addEditForm.get('details.routeId').value,
                contractId: this.rideOrderSummaryFormStore.contractId
              })
          )
            .pipe(
              catchError(() => {
                this.restoreRideOrderSummaryForm();

                return of(undefined);
              })
            )
        ),
        filter(summary => !!summary),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        summary => {
          this.builderDataService.rideOrderSummaryForm.patchValue(summary, { emitEvent: false });

          this.updateRideOrderSummaryFormStore();
          this.updateRideOrderSummaryFormPriceFieldState();

          this.builderDataService.getRideOrderShuttleCompanyCostTypes();
        },
        () => this.restoreRideOrderSummaryForm()
      );

    this.updateRideOrderSummaryFormPriceFieldState();
  }

  private onRideOrderSummaryFormValueChanges() {
    this.builderDataService.rideOrderSummaryForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.checkAndEnableFormControls(value);

        this.builderCommonService.editRouteChangedSet(true);
      });
  }

  private onHashcalRideTypesItemsChanges() {
    this.builderDataService.rideOrderHashcalRideTypesItems$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(hashcalRideTypesItems => {
        const hashcalRideType = this.builderDataService.rideOrderSummaryForm.get('hashcalRideType');

        if (hashcalRideTypesItems?.length) {
          hashcalRideType.setValidators(Validators.required);
        } else {
          hashcalRideType.clearValidators();
        }

        hashcalRideType.updateValueAndValidity({ emitEvent: false });
      });
  }

  private updateRideOrderSummaryFormPriceFieldState() {
    const priceField = this.builderDataService.rideOrderSummaryForm.get('price');
    const costType = this.builderDataService.rideOrderSummaryForm.get('costType');

    const disabled = (
      !this.builderDataService.activeRide.isAnyTargetStation ||
      costType.value > BuilderActiveRideCostType.ManualPrice ||
      costType.value === null
    );

    if (disabled) {
      priceField.clearValidators();
      priceField.disable({ emitEvent: false });
    } else {
      priceField.setValidators(Validators.required);
      priceField.enable({ emitEvent: false });
    }
  }

  private checkAndEnableFormControls(formValue: { [key: string]: string; }) {
    Object.entries(this.config.formControlDependenciesByControlName).forEach(([ key, value ]) => {
      const formControl = this.builderDataService.rideOrderSummaryForm.get(key);

      const enableFormControl = formControl.disabled &&
        (<string[]>value).every(controlName => formValue[controlName]);

      if (enableFormControl) {
        formControl.enable({ emitEvent: false });
      }
    });
  }

  track(message: string) {
    this.trackingService.track(`[${builderConfig.buildMode[this.builderDataService.config().buildMode]?.trackingId}] - Order modal - ${message}`);
  }
}
