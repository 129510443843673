import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RouteSplitPassenger, RouteSplitPassengers } from '@app/route-split/models';

@Injectable()
export class RouteSplitService {
  private BASE_PATH = 'RouteSplit';

  constructor(private apiService: ApiService) {}

  initialize(): Observable<string> {
    return this.apiService.get(`${this.BASE_PATH}/Initialize`);
  }

  getRoutePassengers(routeId: number): Observable<RouteSplitPassenger[]> {
    return this.apiService.get(this.BASE_PATH, { routeId });
  }

  splitRoutePassengers(passengers: RouteSplitPassengers): Observable<number[]> {
    return this.apiService.post(this.BASE_PATH, passengers);
  }
}
