import { Injectable, WritableSignal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  readonly #class: WritableSignal<string> = signal(null);

  readonly class = this.#class.asReadonly();

  updateClass(value: string) {
    this.#class.set(value);
  }
}
