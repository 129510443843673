const DICTIONARY_PATH: string = 'builder.tuning';

export const builderTuningComponentConfig = {
  dictionary: {
    customer: `${DICTIONARY_PATH}.customer`,
    maxCapacity: `${DICTIONARY_PATH}.maxCapacity`,
    maxCapacityDesc: `${DICTIONARY_PATH}.maxCapacityDesc`,
    direction: `${DICTIONARY_PATH}.direction`,
    date: `${DICTIONARY_PATH}.date`,
    day: `${DICTIONARY_PATH}.day`,
    change: `${DICTIONARY_PATH}.change`,
    hour: `${DICTIONARY_PATH}.hour`,
    subCustomers: `${DICTIONARY_PATH}.subCustomers`,
    sc: `${DICTIONARY_PATH}.sc`,
    contract: `${DICTIONARY_PATH}.contract`,
    carType: `${DICTIONARY_PATH}.carType`,
    executionCost: `${DICTIONARY_PATH}.executionCost`,
    purchaseOrder: `${DICTIONARY_PATH}.purchaseOrder`,
    purchaseOrderRow: `${DICTIONARY_PATH}.purchaseOrderRow`,
    carNum: `${DICTIONARY_PATH}.carNum`,
    driver: `${DICTIONARY_PATH}.driver`,
    driverHours: `${DICTIONARY_PATH}.driverHours`,
    yit: `${DICTIONARY_PATH}.yit`,
    yitSyncBy: `${DICTIONARY_PATH}.yitSyncBy`,
    hashcalRideType: `${DICTIONARY_PATH}.hashcalRideType`,
    executingSc: `${DICTIONARY_PATH}.executingSc`,
    subContractorDriverCost: `${DICTIONARY_PATH}.subContractorDriverCost`,
    warning: {
      original: `${DICTIONARY_PATH}.warning.original`,
      startTime: `${DICTIONARY_PATH}.warning.startTime`,
      endTime: `${DICTIONARY_PATH}.warning.endTime`
    },
    editMethod: {
      title: `${DICTIONARY_PATH}.editMethod.title`,
      toggle: `${DICTIONARY_PATH}.editMethod.toggle`
    },
    select: 'general.select',
    search: 'general.search',
    removeSelect: 'general.removeSelect',
    uSelectS: 'uSelectS',
    uInputDateRange: 'uInputDateRange',
    uSelectDays: 'uSelectDays',
    uRange: 'uRange'
  }
};
