import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { escape, cloneDeep } from 'lodash';
import {
  UHereMapMarkerConfig,
  UHereMapMarkerInfo,
  UHereMapMarkerLive,
  UHereMapPathLivePoint
} from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import {
  MonitoringDashboardLastCoordinateItem,
  MonitoringDashboardMapMarkerExtraOptions,
  MonitoringDashboardMapPath,
  MonitoringDashboardReceiveCoordinates,
  MonitoringDashboardPassengerLive,
  MonitoringDashboardPathLive,
  MonitoringDashboardSourceType
} from '@app/monitoring-dashboard/models';
import { monitoringDashboardConfig } from '@app/monitoring-dashboard/configs';

@Injectable()
export class MonitoringDashboardDataService {
  markers: UHereMapMarkerConfig[] = [];
  paths: MonitoringDashboardMapPath[] = [];
  pathsLive: MonitoringDashboardPathLive[] = cloneDeep(monitoringDashboardConfig.pathsLive);
  monitoredPathsMarkers: UHereMapMarkerInfo[][] = [];
  allVehicles: UHereMapMarkerLive[] = [];
  allDrivers: UHereMapMarkerLive[] = [];
  allAccompanies: UHereMapMarkerLive[] = [];
  passengersLive: MonitoringDashboardPassengerLive[] = [];
  traffic: boolean;

  markersSet(markers: UHereMapMarkerConfig[]) {
    this.markers = markers;
  }

  pathsSet(paths: MonitoringDashboardMapPath[]) {
    this.paths = paths;
  }

  monitoredPathsMarkersSet(markers: UHereMapMarkerInfo[][]) {
    this.monitoredPathsMarkers = markers;
  }

  markersClear() {
    this.markers = [];
  }

  pathsClear() {
    this.paths = [];
  }

  generateMarkers(items: MonitoringDashboardLastCoordinateItem[], extraOptions?: MonitoringDashboardMapMarkerExtraOptions): UHereMapMarkerConfig[] {
    return items.map(item => ({
      id: item.id || item.name,
      position: {
        lat: item.latitude,
        lng: item.longitude
      },
      icon: {
        url: extraOptions.icon.url,
        width: extraOptions.icon.width,
        height: extraOptions.icon.height
      },
      opacity: extraOptions.opacity,
      content: `
        <div class="monitoring-dashboard-map-point">
          <div class="monitoring-dashboard-map-point__name">${escape(item.name)}</div>
          <div class="monitoring-dashboard-map-point__content">${moment.utc(item.locatedAt).local().format(AppConstants.DATE_FORMAT_BASE_SLASH_TIME)}</div>
        </div>
      `
    }));
  }

  updateAllVehicles(vehicles: MonitoringDashboardLastCoordinateItem[]) {
    this.allVehicles = this.generateMarkers(vehicles, monitoringDashboardConfig.layers.mapMarkers.vehicle);
  }

  clearAllVehicles() {
    this.allVehicles = [];
  }

  updateAllDrivers(drivers: MonitoringDashboardLastCoordinateItem[]) {
    this.allDrivers = this.generateMarkers(drivers, monitoringDashboardConfig.layers.mapMarkers.driver);
  }

  clearAllDrivers() {
    this.allDrivers = [];
  }

  updateAllAccompanies(accompanies: MonitoringDashboardLastCoordinateItem[]) {
    this.allAccompanies = this.generateMarkers(accompanies, monitoringDashboardConfig.layers.mapMarkers.accompany);
  }

  clearAllAccompanies() {
    this.allAccompanies = [];
  }

  updateTraffic(traffic: boolean) {
    this.traffic = traffic;
  }

  updateMonitoringPathsVisibility(visible: boolean) {
    this.pathsLive = this.pathsLive.map(pathLive => ({ ...pathLive, showPolyline: visible, showPointsInfo: visible }));
    this.paths = this.paths.map(path => ({
      ...path,
      config: {
        ...path.config,
        ...(path.sourceType ? { hidden: !visible } : {})
      }
    }));
    this.monitoredPathsMarkers = this.monitoredPathsMarkers.map(markers => markers.map(marker => ({ ...marker, hidden: !visible })));
  }

  coordinatesReceive(data: MonitoringDashboardReceiveCoordinates) {
    if ([ MonitoringDashboardSourceType.Passenger ].includes(data.sourceType)) {
      this.passengersUpdate(data);

      return;
    }

    const newPoint: UHereMapPathLivePoint = {
      position: { lat: data.latitude, lng: data.longitude },
      content: moment.utc(data.locatedAt).local().format(AppConstants.TIME_FORMAT),
      icon: {
        color: monitoringDashboardConfig.pathsLivePointsColors[data.sourceType]
      }
    };

    const path = this.pathsLive.find(pathLive => pathLive.sourceType === data.sourceType);

    if (path.points.length) {
      const hasPoint = path.points.some(point => point.position.lat === newPoint.position.lat && point.position.lng === newPoint.position.lng);

      if (!hasPoint) {
        path.points = [ ...path.points, newPoint ];
      }
    } else {
      path.points = [ newPoint ];
    }
  }


  passengersUpdate(data: MonitoringDashboardReceiveCoordinates) {
    const passengerLivePoint: UHereMapMarkerConfig = {
      id: data.memberId,
      position: {
        lat: data.latitude,
        lng: data.longitude
      },
      icon: {
        url: monitoringDashboardConfig.mapMarkers.passenger.url,
        width: monitoringDashboardConfig.mapMarkers.passenger.width,
        height: monitoringDashboardConfig.mapMarkers.passenger.height
      }
    };

    const passenger = this.passengersLive.find(passengerLive => passengerLive.id === data.memberId);

    if (passenger) {
      passenger.points = [ ...passenger.points, passengerLivePoint ];
    } else {
      this.passengersLive = [ ...this.passengersLive, { id: data.memberId, points: [ passengerLivePoint ] } ];
    }
  }

  coordinatesClear() {
    this.passengersLive = [];
    this.pathsLive = this.pathsLive.map(pathLive => ({ ...pathLive, points: [] }));
  }
}
