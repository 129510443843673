import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { SignalRService } from '@app/shared/services';
import {  RideOrderStatusUpdated } from '@app/ride-orders/models';

@Injectable({
  providedIn: 'root'
})
export class RideOrdersTableHubService extends SignalRService {
  BASE_PATH = 'rideOrdersTableHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onUpdateRideOrderStatus(): Observable<RideOrderStatusUpdated> {
    return this.onChannel<RideOrderStatusUpdated>('RideOrderStatusUpdated');
  }
}
