import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { cloneDeep } from 'lodash';
import { UCommonModule, UPopupService } from '@shift/ulib';

import { LocalizedToastrService } from '@app/shared/services/localized-toast.service';
import { Errors } from '@app/shared/models';
import { EmailContent, EmailErrorMessages } from '@app/emails/models';
import { EmailsService } from '@app/emails/services';
import { EmailsShuttleCompanyDetailsComponent } from './emails-shuttle-company-details/emails-shuttle-company-details.component';
import { emailsShuttleCompanyComponentConfig } from './emails-shuttle-company.component.config';

@Component({
  selector: 'app-emails-shuttle-company',
  templateUrl: './emails-shuttle-company.component.html',
  standalone: true,
  imports: [
    TabsModule,
    UCommonModule,
    TranslateModule,
    EmailsShuttleCompanyDetailsComponent
  ]
})
export class EmailsShuttleCompanyComponent implements OnInit {
  @Output() hideEmail: EventEmitter<void> = new EventEmitter<void>();

  config = cloneDeep(emailsShuttleCompanyComponentConfig);
  buttons = [
    { name: '', action: 'cancel', clear: true },
    { name: '', action: 'sending', clear: false }
  ];
  emailContent: EmailContent;
  emailContentForm: UntypedFormGroup;
  emailErrorMessages: EmailErrorMessages;

  constructor(
    private localizedToastrService: LocalizedToastrService,
    private formBuilder: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private translateService: TranslateService,
    private uPopupService: UPopupService,
    private emailsService: EmailsService
  ) { }

  ngOnInit(): void {
    this.translateService.get(this.config.dictionary.actions).subscribe((actions: any) => {
      this.buttons.forEach((button: any) => {
        switch (button.action) {
          case 'cancel': {
            button.name = actions.cancel;
            break;
          }

          case 'sending': {
            button.name = actions.sendAndClose;
            break;
          }
        }
      });
    });

    this.translateService.get(this.config.dictionary.errors).subscribe((value: EmailErrorMessages) => {
      this.emailErrorMessages = value;
    });

    this.emailContentForm =  this.formBuilder.group({
      subject: [ '', Validators.required ],
      content: [ '' ],
      receivers: [ [], Validators.required ],
      attachment: [ '' ],
      attachmentId: [ '' ],
      emails: [ [] ]
    });

    if (this.emailContent) {
      this.updateEmailContent(this.emailContent);
    }
  }

  updateEmailContent(emailContent: EmailContent): void {
    const { subject, content, receivers, attachment } = emailContent;

    this.emailContentForm.patchValue({
      subject,
      content,
      receivers,
      attachment: attachment.name,
      attachmentId: attachment.id
    });
  }

  checkActions(action: string): void {
    const actionsTypes = {
      cancel: 'cancel',
      sending: 'sending',
      shutDown: 'shutDown'
    };

    switch (action) {
      case actionsTypes.sending: {
        this.sendMessages();
        break;
      }

      case actionsTypes.cancel: {
        this.bsModalRef.hide();
        break;
      }

      case actionsTypes.shutDown: {
        this.shutDownMessage();
        break;
      }
    }
  }

  sendMessages(): void {
    if (!this.emailContentForm.valid) { return; }

    const { attachmentId, content, subject, receivers } = this.emailContentForm.value;
    const params = {
      attachment: attachmentId,
      content,
      subject,
      receivers
    };

    this.emailsService.sendShuttleCompanyChanges(params)
      .subscribe(
        res => {
          const { urlsReplaced } = res;

          if (!urlsReplaced) {
            this.localizedToastrService.success(this.config.dictionary.successful);
            this.bsModalRef.hide();
            this.hideEmail.emit();
          } else {
            this.openConfirmPopup();
          }
        },
        (err) => {
          if (err.code === Errors.EmailsMaxRecipients) {
            const { maxRecipients } = err.errorData;

            this.openErrorPopup(maxRecipients);
          }

          if (err.code === Errors.EmailsLimit) {
            this.openErrorPopup();
          }
        }
      );
  }

  openConfirmPopup(): void {
    this.uPopupService.showMessage({
      message: this.config.dictionary.confirmUrl,
      yes: this.config.dictionary.confirm
    }, () => {
      this.localizedToastrService.success(this.config.dictionary.successful);
      this.bsModalRef.hide();
      this.hideEmail.emit();
    });
  }

  shutDownMessage(): void {
    if (this.uPopupService.popupRef) { return; }

    this.uPopupService.showMessage({
      message: this.config.dictionary.sendConfirm,
      yes: this.config.dictionary.yes,
      no: this.config.dictionary.no
    }, () => { this.sendMessages(); },
    () => {
      this.bsModalRef.hide();
      this.hideEmail.emit();
    });
  }

  openErrorPopup(count?: number): void {
    this.uPopupService.showMessage({
      message: count ? `${this.emailErrorMessages.maxRecipients.sending} ${count} ${this.emailErrorMessages.maxRecipients.remove}` : this.emailErrorMessages.limitEmails,
      yes: this.config.dictionary.confirm
    }, () => {});
  }
}
