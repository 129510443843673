import { Component, HostBinding, Input, Output, EventEmitter } from '@angular/core';

import { EntityMenu, EntityMenuItem } from '@app/shared/models';

@Component({
  selector: 'app-entity-menu',
  templateUrl: './entity-menu.component.html',
  styleUrls: [ './entity-menu.component.scss', './entity-menu.component.rtl.scss' ]
})
export class EntityMenuComponent {
  @Input() activeAction: string;
  @Input() set menu(value: EntityMenu[]) {
    this._menu = value;
    this.updateMenu();
  }

  @Output() menuItemAction: EventEmitter<EntityMenuItem> = new EventEmitter();
  @Output() activeActionChange: EventEmitter<string> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'entity-menu';

  private _menu: EntityMenu[] = [];

  menuStore: EntityMenu[] = [];

  constructor() {}

  get menu(): EntityMenu[] { return this._menu; }

  updateMenu() {
    this.menuStore = this.menu;
  }

  menuItemClick(item: EntityMenuItem) {
    this.activeActionChange.emit(item.action);
    this.menuItemAction.emit(item);
  }
}
