import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { PassengerDetails, PassengersInitialData, PassengersInitialDataType } from '@app/passengers/models';
import { RideCommandersParams, RideCommanders } from '@app/ride-commanders/models';

@Injectable({
  providedIn: 'root'
})
export class CommandersService {
  private BASE_PATH: string = 'Commanders';

  constructor(
    private apiService: ApiService
  ) {}

  getInitialData(types?: PassengersInitialDataType[]): Observable<PassengersInitialData> {
    return this.apiService.get(`${this.BASE_PATH}/InitialData`, types && { types });
  }

  getCommandersTable(data: RideCommandersParams): Observable<RideCommanders> {
    return this.apiService.post(`${this.BASE_PATH}/GetTable`, data);
  }

  getCommanderById(id: number): Observable<PassengerDetails> {
    return this.apiService.get(`${this.BASE_PATH}/${id}`);
  }

  removeCommander(id: number[]): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Delete`, id);
  }

  updateCommander(data: PassengerDetails): Observable<PassengerDetails> {
    return this.apiService.put(`${this.BASE_PATH}`, data);
  }

  saveCommander(data: PassengerDetails): Observable<PassengerDetails> {
    return this.apiService.post(`${this.BASE_PATH}`, data);
  }

  findCommander(contact: string, identity: string): Observable<PassengerDetails> {
    return this.apiService.get(`${this.BASE_PATH}/Find`, { contact, identity });
  }
}
