export enum ContactType {
  Phone = 13,
  Mobile = 14,
  Fax = 15,
  Email = 37
}

export class ContactItem {
  type: ContactType = null;
  contact: string = '';
}

export class Contacts {
  groupId: number = null;
  role: string = '';
  name: string = '';
  contacts: ContactItem[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
