<div class="routes-change-hour__container">
  <div class="routes-change-hour__wrapper">
    <h4 class="routes-change-hour__title">{{config.dictionary.title | translate}}</h4>
  </div>
  <u-group 
    class="u-group_flex u-group_app-routes-change"
    [name]="config.dictionary.hours | translate"
    >
    <div class="routes-change-hour__wrappers">
      <div class="routes-change-hour__item">
        <u-input-time
          [class]="'u-input-time_center'" 
          [formControl]="form.controls['startHour']"
        ></u-input-time>
        </div>
        <div class="routes-change-hour__item">
          <u-input-time
            [class]="'u-input-time_center'" 
            [formControl]="form.controls['endHour']"
          ></u-input-time>
        </div>
    </div>
  </u-group>
  <u-group 
    class="u-group_flex u-group_app-routes-change"
    [name]="config.dictionary.type | translate"
  >
    <app-routes-period
      [typeFormControl]="form.controls['type']"
      [changes]="form.controls['datesChange'].value"
      [viewportElement]="viewportElement"
      [authCustomer]="authCustomer"
      [activeDays]="activeDays"
      (updatePeriod)="updatePeriod($event)"
      (updateDates)="updateDates($event)"
    ></app-routes-period>
  </u-group>
  <u-group 
    class="u-group_flex u-group_app-routes-change"
    [name]="config.dictionary.comments | translate"
  >
  <textarea
    uTextarea
    [formControl]="form.controls['comment']"
  ></textarea>
  </u-group>

  <div class="routes-change-hour__buttons">
    <div class="routes-change-hour__button">
      <button
        uButton
        class="routes-change-hour__btn"
        [disabled]="!showSaveBtn"
        [size]="uButtonSize.Small"
        (click)="saveChanges()"
      >
        {{config.dictionary.actions.saveAndClose | translate}}
      </button>
    </div>
    <div class="routes-change-hour__button">
      <button
        uButton
        class="routes-change-hour__btn"
        [disabled]="!showSaveBtn"
        [size]="uButtonSize.Small"
        (click)="saveChanges(routesChangeEmailSendType.SendBackgroundEmail)"
      >
        {{config.dictionary.actions.saveAndSendSC | translate}}
      </button>
    </div>
    <div class="routes-change-hour__button">
      <button
        uButton
        class="routes-change-hour__btn"
        [disabled]="!showSaveBtn"
        [size]="uButtonSize.Small"
        (click)="saveChanges(routesChangeEmailSendType.GenerateEditableEmail)"
      >
        {{config.dictionary.actions.saveAndEditEmail | translate}}
      </button>
    </div>
  </div>
</div>
