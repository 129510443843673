<div class="delete-route-header">
  <div class="delete-route-header__title">
    <span class="delete-route-header__title-text">{{(routes.length > 1 ? config.dictionary.titlePlural : config.dictionary.title) | translate}}</span>
  </div>
  <div class="delete-route-header__close" (click)="close()"></div>
</div>
<div class="delete-route-body">
  <div class="delete-route-body__item">
    <span class="delete-route-body__item-title">{{config.dictionary.deletePeriod.title | translate}}</span>
    <div class="delete-route-body__item-input">
      <u-select-s
        [class]="'u-select-s_app-delete-routes'"
        [classUInput]="'u-input_arrow-light'"
        [items]="types"
        [formControl]="form.controls['type']"
        [messages]="'uSelectS' | translate"
        [empty]="false"
        [valid]="form.controls['type'].valid"
        [placeholder]="config.dictionary.select | translate"
      ></u-select-s>
    </div>
  </div>

  <div class="delete-route-body__item delete-route-body__item_no-title">
    <div class="delete-route-body__item-input">
      <div class="delete-route-body-date">
        <input
          *ngIf="!showFromDate"
          uInput
          type="text"
          [formControl]="form.controls['dateFrom']"
          class="delete-route-body-date-disabled"
        >
        <u-input-date
          *ngIf="showFromDate"
          [disableDaysBefore]="form.controls['disableDaysBefore'].value"
          [disableDaysAfter]="form.controls['disableDaysAfter'].value"
          [classUInput]="'u-input_text-center'"
          [formControl]="form.controls['changeDateFrom']"
          [valid]="form.controls['changeDateFrom'].valid"
          [placement]="isRtl ? [ 'bottom-left', 'top-left' ] : [ 'bottom-right', 'top-right' ]"
          [showIcon]="false"
          [messages]="config.dictionary.uInputDate | translate"
        ></u-input-date>
      </div>
      <div class="delete-route-body-date">
        <input
          uInput
          type="text"
          [formControl]="form.controls['dateTo']"
          class="delete-route-body-date-disabled"
        >
      </div>
    </div>
  </div>

  <div class="delete-route-body__confirm" *ngIf="routes.length > 1">
    <div class="delete-route-body__confirm-text delete-route-body__confirm-text_warning">{{routesToDeleteWarning}}</div>
    <div class="delete-route-body__confirm-text delete-route-body__confirm-text_warning">{{config.dictionary.permanentDeleteWarning | translate}}</div>
    <div class="delete-route-body__confirm-text">{{config.dictionary.enterNumberToConfirm | translate}}</div>

    <div class="delete-route-body__confirm-amount">
      <span class="delete-route-body__confirm-amount-title">{{config.dictionary.routesAmount | translate}}</span>
      <div class="delete-route-body__confirm-amount-input">
        <u-input-number
          [class]="'u-input-number_app-delete-route'"
          [formControl]="form.get('routesAmount')"
          [min]="0"
          [valid]="form.get('routesAmount').valid"
          [valueType]="'number'"
        ></u-input-number>
        <span class="delete-route-body__confirm-amount-input-error" *ngIf="form.get('routesAmount').dirty && form.get('routesAmount').invalid">
          {{config.dictionary.invalidNumber | translate}}
        </span>
      </div>
    </div>
  </div>

  <button
    uButton
    class="delete-route-body__submit u-font-weight-medium"
    [disabled]="!form.valid"
    (click)="save()"
  >
    {{config.dictionary.applyDelete | translate}}
  </button>
</div>
