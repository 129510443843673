<u-modal-entity
  class="u-modal-entity_app-stations"
  [title]="config.dictionary.title | translate"
  [isRtl]="isRtl"
  [buttons]="config.buttons"
  [formChanged]="true"
  (action)="checkActions($event)"
>
  <tabset
    class="u-tabset-pills"
    type="pills"
  >
    <tab>
      <div
        class="stations-add-edit__main"
        tabindex="-1"
      >
        <div class="stations-add-edit__form">
          <u-group
            class="u-group_large-field"
            [name]="config.dictionary.name | translate"
            [valid]="addEditForm.get('name').valid"
            [message]="config.dictionary.required | translate"
          >
            <input
              uInput
              type="text"
              [formControl]="addEditForm.get('name')"
              [placeholder]="config.dictionary.name | translate"
            >
          </u-group>
          <ng-container
            *ngIf="nameLocalizationFeature"
          >
            <ng-container
              *ngFor="let langCode of nameLocalizationFeature"
            >
              <u-group
                class="u-group_large-field"
                [name]="config.dictionary.translations[langCode]['name'] | translate"
                [valid]="addEditForm.get('translations.' + langCode + '.name').valid"
                [message]="config.dictionary.required | translate"
              >
                <input
                  uInput
                  type="text"
                  [formControl]="addEditForm.get('translations.' + langCode + '.name')"
                  [placeholder]="config.dictionary.translations[langCode]['name'] | translate"
                >
              </u-group>
            </ng-container>
          </ng-container>
          <u-group
            class="u-group_large-field"
            [name]="config.dictionary.address | translate"
            [valid]="addEditForm.get('fullAddress').valid || addEditForm.get('latitude').valid || addEditForm.get('longitude').valid"
            [message]="config.dictionary.required | translate"
          >
            <u-address
              [showMapIcon]="false"
              [placeholder]="config.dictionary.address | translate"
              [address]="{
                address: addEditForm.get('fullAddress').value,
                lat: addEditForm.get('latitude').value,
                lng: addEditForm.get('longitude').value
              }"
              (addressChange)="updateAddress($event)"
              (mapIconClick)="track('Address field - click on map icon')"
            ></u-address>
          </u-group>
        </div>
        <div class="stations-add-edit__map">
          <app-stations-map [stationMap]="map" (stationPoint)="updateAddress($event)"></app-stations-map>
        </div>
      </div>
    </tab>
  </tabset>
</u-modal-entity>
