import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { SignalRService } from '@app/shared/services';
import {
  RouteNoteAdded,
  RouteNoteUpdated,
  RouteNoteMarkedAsDone,
  RoutesUpdateRideStatus,
  RoutesUpdateApprovals
} from '@app/routes/models';

@Injectable({
  providedIn: 'root'
})
export class RoutesTableHubService extends SignalRService {
  BASE_PATH = 'routesTableHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onRouteNoteAdded(): Observable<RouteNoteAdded> {
    return this.onChannel<RouteNoteAdded>('RouteNoteAdded');
  }

  onRouteNoteUpdated(): Observable<RouteNoteUpdated> {
    return this.onChannel<RouteNoteUpdated>('RouteNoteUpdated');
  }

  onRouteNoteMarkedAsDone(): Observable<RouteNoteMarkedAsDone> {
    return this.onChannel<RouteNoteMarkedAsDone>('RouteNoteMarkedAsDone');
  }

  onUpdateRideStatus(): Observable<RoutesUpdateRideStatus> {
    return this.onChannel<RoutesUpdateRideStatus>('UpdateRideStatus');
  }

  onUpdateApprovals(): Observable<RoutesUpdateApprovals> {
    return this.onChannel<RoutesUpdateApprovals>('UpdateApprovals');
  }
}
