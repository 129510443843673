export interface MultiDeleteConfirmDictionary {
  title?: string;
  confirmButton?: string;
  deletionWillCause?: string;
  itemsToDeleteWarning?: string;
  permanentDeleteWarning?: string;
  enterNumberToConfirm?: string;
  itemsAmount?: string;
  invalidNumber?: string;
}

export interface MultiDeleteConfirmInitialState {
  deleteConfirmType?: string;
  itemsToDeleteAmount: number;
  deleteWarningMessages?: string[];
  dictionary?: MultiDeleteConfirmDictionary;
}
