import { ChangeDetectionStrategy, Component, computed, HostBinding, input, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UButtonModule } from '@shift/ulib';
import { cloneDeep } from 'lodash';
import * as moment from 'moment/moment';

import { AppConstants } from '@app/shared/constants';
import { RoutePlannerCompareProp, RoutePlannerCompare } from '@app/route-planner/models';
import { builderFullSummaryComponentConfig } from './builder-full-summary.component.config';

@Component({
  selector: 'app-builder-full-summary',
  templateUrl: './builder-full-summary.component.html',
  styleUrls: [ './builder-full-summary.component.scss', 'builder-full-summary.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UButtonModule,
    TranslateModule
  ]
})
export class BuilderFullSummaryComponent {
  readonly numberOfRoutes = input<number>();
  readonly summary = input<RoutePlannerCompare>();

  readonly close = output<void>();

  @HostBinding('class') readonly hostClasses = 'builder-full-summary';

  readonly #config = signal(cloneDeep(builderFullSummaryComponentConfig));
  readonly #showExplanation = signal(false);

  readonly config = this.#config.asReadonly();
  readonly showExplanation = this.#showExplanation.asReadonly();
  readonly rows = computed(() =>
    this.config().rows.map(row => ({
      ...row,
      ...this.summary()[row.prop],
      ...(
        row.prop === this.routePlannerCompareProp.PassengerAverageTime || row.prop === this.routePlannerCompareProp.TotalTime ?
          {
            original: moment(this.summary()[row.prop].original, AppConstants.TIME_FORMAT_FULL).format(AppConstants.TIME_FORMAT),
            alternative: moment(this.summary()[row.prop].alternative, AppConstants.TIME_FORMAT_FULL).format(AppConstants.TIME_FORMAT)
          } :
          {}
      )
    }))
  );

  readonly routePlannerCompareProp = RoutePlannerCompareProp;

  onShowExplanationChange() {
    this.#showExplanation.set(!this.showExplanation());
  }
}
