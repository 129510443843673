import { UInputCitiesCity } from '@shift/ulib';

export interface Cities {
  showBranchHierarchy: boolean;
  cities: UInputCitiesCity[];
}

export interface CitiesState {
  cities: UInputCitiesCity[];
}
