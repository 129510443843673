import { AuthCustomerType } from '@app/auth/models';

const inputShifts = {
  activeDays: 5
};

const inputShiftsArmy = {
  activeDays: 0
};

const inputShiftsMunicipality = {
  activeDays: 0
};

export const inputShiftsConfig = {
  default: inputShifts,
  [AuthCustomerType.Army]: inputShiftsArmy,
  [AuthCustomerType.Municipality]: inputShiftsMunicipality
};
