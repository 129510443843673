const DICTIONARY_PATH: string = 'routes.changeShuttleCompany';

export const routesChangeShuttleCompanyComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    shuttleCompany: `${DICTIONARY_PATH}.shuttleCompany`,
    driver: `${DICTIONARY_PATH}.driver`,
    car: `${DICTIONARY_PATH}.car`,
    type: `${DICTIONARY_PATH}.type`,
    comments: `${DICTIONARY_PATH}.comments`,
    actions: {
      saveAndClose: 'general.saveAndClose',
      saveAndSendSC: 'general.saveAndSendSC',
      saveAndEditEmail: 'general.saveAndEditEmail'
    }
  }
};
