import { Component, DestroyRef, HostBinding, Input, OnChanges, SimpleChanges, WritableSignal, inject, signal } from '@angular/core';
import { cloneDeep } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UPopoverDirective } from '@shift/ulib';

import { AuthModuleDemandsShiftsViewFeatureType } from '@app/auth/models';
import { AppConstants } from '@app/shared/constants';
import {
  DemandsShiftsViewShift,
  DemandsShiftsViewShiftDemand,
  DemandsShiftsViewShiftRowDayOfWeekDepartment,
  DemandsDayOfWeek,
  DemandsDemandType,
  DemandsPassengerShift,
  DemandsPassengerShiftSeriesUpdateType,
  DemandsShiftRepeatOptionsActionType,
  DemandsView
} from '@app/demands/models';
import {
  DemandsAssignShiftDataService,
  DemandsCommonService,
  DemandsShiftsViewService
} from '@app/demands/services';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { demandsPassengersDayComponentConfig } from './demands-passengers-day.component.config';

@Component({
  selector: 'app-demands-passengers-day',
  templateUrl: './demands-passengers-day.component.html',
  styleUrls: [ './demands-passengers-day.component.scss', 'demands-passengers-day.component.rtl.scss' ]
})
export class DemandsPassengersDayComponent implements OnChanges {
  @Input() featureType: AuthModuleDemandsShiftsViewFeatureType;
  @Input() date: string;
  @Input() dayOfWeek: DemandsDayOfWeek;
  @Input() departments: DemandsShiftsViewShiftRowDayOfWeekDepartment[];
  @Input() shift: DemandsShiftsViewShift;
  @Input() departmentGroupHeights: { [key: number]: number; };

  @HostBinding('class') hostClasses: string = 'demands-passengers-day';

  private readonly destroyRef = inject(DestroyRef);
  private readonly trackingService = inject(TrackingService);
  private readonly localizationService = inject(LocalizationService);
  private readonly demandsAssignShiftDataService = inject(DemandsAssignShiftDataService);
  private readonly demandsShiftsViewService = inject(DemandsShiftsViewService);
  public readonly demandsCommonService = inject(DemandsCommonService);

  #config = signal(undefined);
  #assignPassengerEditData: WritableSignal<DemandsPassengerShift> = signal(undefined);
  #passengerShiftDemand: WritableSignal<DemandsShiftsViewShiftDemand | {}> = signal({});

  readonly config = this.#config.asReadonly();
  readonly assignPassengerEditData = this.#assignPassengerEditData.asReadonly();
  readonly passengerShiftDemand = this.#passengerShiftDemand.asReadonly();

  demandsView = DemandsView;
  demandsDemandType = DemandsDemandType;
  appConstants = AppConstants;
  isRtl: boolean = this.localizationService.isRtl();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.featureType) {
      this.#config.set(cloneDeep(demandsPassengersDayComponentConfig[this.featureType] || demandsPassengersDayComponentConfig.default));
    }
  }

  private track(message: string) {
    this.trackingService.track(`[${this.config().tracking.id}] - ${message}`);
  }

  assignNewPassenger(popover: UPopoverDirective, department?: DemandsShiftsViewShiftRowDayOfWeekDepartment) {
    this.track(`Add assignment (click on '+' ${this.config().tracking.passenger})`);

    if (this.demandsCommonService.assignPassengerPopover && this.demandsCommonService.assignPassengerPopover.isOpen()) { return false; }

    this.#passengerShiftDemand.set(department ? { departmentId: department.departmentId } : {});
    this.#assignPassengerEditData.set(undefined);
    this.demandsCommonService.assignPassengerPopover = popover;
    this.demandsCommonService.assignPassengerPopover.open();
    this.demandsCommonService.setBackdrop(true);
  }

  assignPassengerEdit(passengerShiftDemand: DemandsShiftsViewShiftDemand, popover: UPopoverDirective) {
    this.track(`Edit ${this.config().tracking.passenger} (click on existing ${this.config().tracking.passenger})`);

    if (this.demandsCommonService.assignPassengerPopover && this.demandsCommonService.assignPassengerPopover.isOpen()) { return false; }

    this.#passengerShiftDemand.set(passengerShiftDemand);
    this.demandsCommonService.assignPassengerPopover = popover;

    this.demandsShiftsViewService
      .getPassengerDemand({
        passengerId: passengerShiftDemand.passengerId,
        demandId: passengerShiftDemand.demandId
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        this.#assignPassengerEditData.set(data);
        this.demandsCommonService.assignPassengerPopover.open();
        this.demandsCommonService.setBackdrop(true);
      });
  }

  assignPassengerClose() {
    if (this.demandsCommonService.assignPassengerPopover && this.demandsCommonService.assignPassengerPopover.isOpen()) {
      this.demandsCommonService.assignPassengerPopover.close();
      this.demandsCommonService.assignPassengerPopover = null;
      this.demandsCommonService.setBackdrop(false);
    }
  }

  deletePassenger(event: MouseEvent, passengerShiftDemand: DemandsShiftsViewShiftDemand) {
    event.stopPropagation();

    this.deleteConfirmPopup(passengerShiftDemand.demandType)
      .pipe(
        switchMap((seriesUpdateType: DemandsPassengerShiftSeriesUpdateType) =>
          this.demandsShiftsViewService.deletePassengerDemand({
            passengerId: passengerShiftDemand.passengerId,
            demandId: passengerShiftDemand.demandId,
            seriesUpdateType
          })
        )
      )
      .subscribe(() =>
        this.demandsCommonService.assignPassengerDemandUpdate({
          shiftId: this.shift.shiftId,
          branchId: this.shift.branchId,
          startTime: this.shift.startTime,
          endTime: this.shift.endTime
        })
      );
  }

  deleteConfirmPopup(demandType: DemandsDemandType) {
    return this.demandsAssignShiftDataService.openConfirmationPopup({ message: this.config().dictionary.deleteShiftConfirm })
      .pipe(
        filter(value => value),
        switchMap(() =>
          demandType === DemandsDemandType.Repeatable ?
            this.demandsAssignShiftDataService.shiftRepeatOptions()
              .pipe(
                filter(({ type, value }) => type === DemandsShiftRepeatOptionsActionType.Submit && value !== null),
                tap(({ value }) => {
                  const trackMessages = {
                    [DemandsPassengerShiftSeriesUpdateType.OnlyCurrentDemand]: 'Delete occurrence',
                    [DemandsPassengerShiftSeriesUpdateType.CurrentAndFutureDemands]: `Delete ${this.config().tracking.shift} and all future`,
                    [DemandsPassengerShiftSeriesUpdateType.AllDemands]: 'Delete series'
                  };

                  if (trackMessages[value]) {
                    this.trackEditRepeatShift(trackMessages[value]);
                  }
                }),
                map(({ value }) => value)
              ) : of(null)
        ));
  }

  trackEditRepeatShift(message: string) {
    this.track(`Edit repeat ${this.config().tracking.shift} - ${message}`);
  }
}
