import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
  standalone: true
})
export class IncludesPipe implements PipeTransform {
  transform<T>(values: T[], value: T): boolean {
    return values && values.length && values.includes(value);
  }
}
