import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { AuthDataService } from '@app/auth/services';
import { AuthModuleName, AuthModuleTransportationFeature, AuthModuleTransportationFeatureType } from '@app/auth/models';
import { ExportToExcelObject } from '@app/shared/models';
import { ApiService } from '@app/shared/services';
import { FileSaverService } from '@app/files/services';
import {
  ShuttleCompany,
  ShuttleCompanyDetails,
  ShuttleCompanyAddEdit,
  ShuttleCompaniesImportContract,
  ShuttleCompaniesCtrlStatus
} from '@app/shuttle-companies/models';

@Injectable({
  providedIn: 'root'
})
export class ShuttleCompaniesService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private BASE_PATH: string = 'ShuttleCompanies';
  private BASE_PATH_TYPE: string = '';

  constructor(
    private apiService: ApiService,
    private fileSaverService: FileSaverService,
    private authDataService: AuthDataService
  ) {
    this.initBasePathType();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initBasePathType() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.Transportation, AuthModuleTransportationFeature.Type)
      .pipe(
        filter(data => !!data),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(type => this.BASE_PATH_TYPE = type === AuthModuleTransportationFeatureType.Generic ? '' : `/${type}`);
  }

  getShuttleCompanies(search: string = ''): Observable<ShuttleCompany[]> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, { search });
  }

  getShuttleCompanyByBusinessId(businessId: number): Observable<ShuttleCompanyDetails> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${businessId}`);
  }

  getShuttleCompanyTab(shuttleCompanyId: number, apiPath: string): Observable<ShuttleCompanyDetails> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${shuttleCompanyId}${apiPath}`);
  }

  getLinkToCtrl(shuttleCompanyId: number): Observable<ShuttleCompaniesCtrlStatus> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${shuttleCompanyId}/LinkToCtrl`);
  }

  saveShuttleCompany(shuttleCompany: ShuttleCompanyAddEdit): Observable<number> {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, shuttleCompany);
  }

  deleteShuttleCompanies(shuttleCompanyIds: number[]): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, {}, { shuttleCompanyIds });
  }

  exportToExcel(exportObject: ExportToExcelObject) {
    this.apiService.postBlob(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/Export/Excel`, exportObject.data, 'blob')
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(excelFile => this.fileSaverService.downloadBlobFile(excelFile, exportObject.fileName));
  }

  importContract(body: FormData): Observable<ShuttleCompaniesImportContract> {
    return this.apiService.postFormFile(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/Import/Contract`, body, false, true);
  }
}
