<div class="activities-filters__content">
  <div class="activities-filters__header-wrapper">
    <div class="activities-filters__header">
      {{config.dictionary.name | translate}}
    </div>
    <div class="activities-filters__close-icon" (click)="closeAction()"></div>
  </div>
  <form class="activities-filters__form" [formGroup]="form">
    <div class="activities-filters__item-wrapper">
      <div class="activities-filters__item">
        <div class="activities-filters__item-name">
          {{config.dictionary.status | translate}}
        </div>
        <u-multiselect
          class="activities-filters__item-form-control"
          formControlName="statusTypes"
          [disableTooltip]="false"
          [classUInput]="'u-input_arrow'"
          [items]="config.options.statusTypes"
          [placeholder]="config.dictionary.select | translate"
          [messages]="'uMultiselect' | translate"
        >
        </u-multiselect>
      </div>
    </div>
    <div class="activities-filters__item-wrapper">
      <div class="activities-filters__item">
        <div class="activities-filters__item-name">
          {{config.dictionary.type | translate}}
        </div>
        <u-multiselect
          class="activities-filters__item-form-control"
          formControlName="types"
          [disableTooltip]="false"
          [classUInput]="'u-input_arrow'"
          [items]="config.options.types"
          [placeholder]="config.dictionary.select | translate"
          [messages]="'uMultiselect' | translate"
        >
        </u-multiselect>
      </div>
    </div>
    <div class="activities-filters__item-wrapper">
      <div class="activities-filters__item">
        <div class="activities-filters__item-name">
          {{config.dictionary.save | translate}}
        </div>
        <u-multiselect
          class="activities-filters__item-form-control"
          formControlName="saveTypes"
          [disableTooltip]="false"
          [classUInput]="'u-input_arrow'"
          [items]="config.options.saveTypes"
          [placeholder]="config.dictionary.select | translate"
          [messages]="'uMultiselect' | translate"
        >
        </u-multiselect>
      </div>
    </div>
  </form>
  <div class="activities-filters__button-wrapper">
    <div class="activities-filters__button" (click)="clearFiltersAction()">
      {{config.dictionary.clearFilters | translate}}
    </div>
  </div>
</div>
