<div class="cities-combinations__header">
  <div class="cities-combinations__top-buttons" *ngIf="!cityCombinationRulesForm.disabled">
    <ng-container *ngFor="let button of config.buttons">
      <button
        *ngIf="!button.popover && !button.hidden"
        uButton
        class="cities-combinations__top-button"
        [ngClass]="{
          'u-font-weight-medium': button.medium
        }"
        [uId]="button.uId"
        [disabled]="button.disabled"
        [withPlus]="button.withPlus"
        (click)="onConfigButtonClick(button)"
      >
        {{button.title | translate}}
      </button>
      <button
        #buttonPopover="uPopover"
        *ngIf="button.popover && !button.hidden"
        uButton
        class="cities-combinations__top-button"
        [ngClass]="{
          'u-font-weight-medium': button.medium
        }"
        [uId]="button.uId"
        [disabled]="button.disabled"
        [withPlus]="button.withPlus"
        [uPopover]="button.popover.template"
        [container]="button.popover.container || 'body'"
        [placement]="isRtl ? button.popover.placement.rtl : button.popover.placement.ltr"
        [popoverClass]="button.popover.class"
        [autoClose]="button.popover.autoClose || 'outside'"
        [verticalOffset]="button.popover.verticalOffset"
        (click)="onConfigButtonClick(button, buttonPopover)"
      >
        {{button.title | translate}}
      </button>
    </ng-container>
  </div>
  <div
    *ngIf="allCitiesAreAssociated"
    class="cities-combinations__subtitle"
  >
    {{config.dictionary.subtitle | translate}}
  </div>
</div>
<div
  *ngIf="cityCombinationRulesFormStore"
  class="cities-combinations__wrapper"
>
  <u-virtual-scroller
    #scroll
    class="cities-combinations__virtual-scroller"
    [items]="cityCombinationRulesFormStore.controls"
    [checkResizeInterval]="0"
  >
    <div
      *ngFor="let ruleForm of scroll.viewPortItems"
      [formGroup]="ruleForm"
      class="cities-combinations__row"
    >
      <u-checkbox
        class="u-checkbox_circle u-checkbox_green"
        [uTooltip]="(ruleForm.get('isActive').value ? config.dictionary.active : config.dictionary.inactive) | translate"
        [container]="'body'"
        [formControl]="ruleForm.get('isActive')"
        (click)="onIsActiveCheckboxClick()"
      ></u-checkbox>
      <div class="cities-combinations__rule-name-wrapper">
        <u-group class="cities-combinations__group u-group_medium-field u-group_app-cities-combinations-rule-name">
          <input
            uInput
            type="text"
            [class]="'u-input_underline'"
            [placeholder]="config.dictionary.ruleName | translate"
            [maxLength]="100"
            [formControl]="ruleForm.get('name')"
            [valid]="ruleForm.get('name').valid"
          >
        </u-group>
      </div>
      <u-group class="cities-combinations__group u-group_medium-field u-group_app-cities-combinations-rule-type">
        <u-select-s
          [placeholder]="config.dictionary.select | translate"
          [items]="config.ruleTypes"
          [classUInput]="'u-input_underline u-input_no-arrow-hover'"
          [formControl]="ruleForm.get('type')"
          [container]="'body'"
          [empty]="false"
          [valid]="ruleForm.get('type').valid"
        ></u-select-s>
      </u-group>
      <u-group
        [name]="(isRtl ? config.dictionary.of : '') | translate"
        class="cities-combinations__group u-group_large-field u-group_app-cities-combinations-with-label"
      >
        <u-input-cities
          *ngIf="ruleForm.get('type').value === ruleType.FixedGroup"
          [formControl]="ruleForm.get('baseCities')"
          [updateOn]="ruleForm.get('baseCities').updateOn"
          [items]="
            filteredBaseCities[ruleType.FixedGroup][cityCombinationRulesFormStore.controls.indexOf(ruleForm)] &&
            filteredBaseCities[ruleType.FixedGroup][cityCombinationRulesFormStore.controls.indexOf(ruleForm)].length ?
              filteredBaseCities[ruleType.FixedGroup][cityCombinationRulesFormStore.controls.indexOf(ruleForm)] : cities
          "
          [multiselect]="true"
          [classUDropdownContent]="'u-dropdown_app-input-cities-city-combinations'"
          [classUInput]="'u-input_underline u-input_no-arrow-hover'"
          [valid]="ruleForm.get('baseCities').valid"
          [container]="'body'"
          [placement]="[ 'bottom', 'top' ]"
          (click)="ruleForm.get('baseCities').enabled && filterBaseCities(ruleForm, ruleForm.get('type').value, true)"
        ></u-input-cities>
        <u-input-cities
          *ngIf="ruleForm.get('type').value !== ruleType.FixedGroup"
          [formControl]="ruleForm.get('baseCities')"
          [updateOn]="ruleForm.get('baseCities').updateOn"
          [items]="
            filteredBaseCities[ruleForm.get('type').value === ruleType.DontCombine ?  ruleType.DontCombine : ruleType.Combine][cityCombinationRulesFormStore.controls.indexOf(ruleForm)] &&
            filteredBaseCities[ruleForm.get('type').value === ruleType.DontCombine ?  ruleType.DontCombine : ruleType.Combine][cityCombinationRulesFormStore.controls.indexOf(ruleForm)].length ?
              filteredBaseCities[ruleForm.get('type').value === ruleType.DontCombine ?  ruleType.DontCombine : ruleType.Combine][cityCombinationRulesFormStore.controls.indexOf(ruleForm)] :
              cities
          "
          [classUDropdownContent]="'u-dropdown_app-input-cities-city-combinations'"
          [class]="'input-cities_city-combinations'"
          [classUInput]="'u-input_underline u-input_no-arrow-hover'"
          [valid]="ruleForm.get('baseCities').valid"
          [container]="'body'"
          [placement]="[ 'bottom', 'top' ]"
          (click)="
            ruleForm.get('baseCities').enabled &&
            filterBaseCities(ruleForm, ruleForm.get('type').value, true)
          "
        ></u-input-cities>
      </u-group>
      <u-group
        *ngIf="ruleForm.get('type').value === ruleType.FixedGroup"
        class="cities-combinations__group-toll-roads-checkbox u-group_large-field u-group_app-cities-combinations-toll-roads-checkbox"
        [name]="config.dictionary.allowTollRoads | translate"
      >
        <u-checkbox
          class="u-checkbox_box"
          [formControl]="ruleForm.get('useTollRoads')"
        ></u-checkbox>
      </u-group>
      <u-group
        *ngIf="ruleForm.get('type').value !== ruleType.FixedGroup"
        class="cities-combinations__group u-group_large-field u-group_app-cities-combinations-with-label"
        [name]="config.dictionary.with | translate | lowercase"
      >
        <u-input-cities
          [formControl]="ruleForm.get('attachedCities')"
          [updateOn]="ruleForm.get('attachedCities').updateOn"
          [items]="
            filteredAttachedCities[ruleForm.get('type').value === ruleType.DontCombine ?  ruleType.DontCombine : ruleType.Combine][cityCombinationRulesFormStore.controls.indexOf(ruleForm)] &&
            filteredAttachedCities[ruleForm.get('type').value === ruleType.DontCombine ?  ruleType.DontCombine : ruleType.Combine][cityCombinationRulesFormStore.controls.indexOf(ruleForm)].length ?
              filteredAttachedCities[ruleForm.get('type').value === ruleType.DontCombine ?  ruleType.DontCombine : ruleType.Combine][cityCombinationRulesFormStore.controls.indexOf(ruleForm)] :
              cities
          "
          [classUDropdownContent]="'u-dropdown_app-input-cities-city-combinations'"
          [multiselect]="true"
          [classUInput]="'u-input_underline u-input_no-arrow-hover'"
          [valid]="ruleForm.get('attachedCities').valid"
          [container]="'body'"
          [placement]="[ 'bottom', 'top' ]"
          (click)="ruleForm.get('attachedCities').enabled && filterAttachedCities(ruleForm, ruleForm.get('type').value)"
        ></u-input-cities>
      </u-group>
      <div
        *ngIf="showDeleteButton && !cityCombinationRulesForm.disabled"
        class="cities-combinations__delete-icon"
        (click)="deleteRule(ruleForm, ruleForm.get('name').value)"
      ></div>
    </div>
  </u-virtual-scroller>
  <div *ngIf="showGoToSettings && cityCombinationRulesFormStore && cityCombinationRulesFormStore.controls.length === 0" class="cities-combinations__go-to-settings">
    <div class="cities-combinations__go-to-settings-text">{{config.dictionary.noRulesDefined | translate}}</div>
    <button
      uButton
      class="cities-combinations__go-to-settings-button"
      (click)="goToSettings()"
    >
      {{config.dictionary.goToSettings | translate}}
    </button>
  </div>
</div>

<u-side-modal
  *ngIf="showExplanation"
  [config]="explanationModalConfig"
  [class]="'u-side-modal_app-cities-combinations'"
  (modalOpen)="$event.manualOpen && onSideModalButtonClick()"
  (modalBodyButtonClick)="onExplanationButtonClick($event)"
></u-side-modal>

<ng-template #explanationModalBody>
  <div class="cities-combinations-explanation-text" *ngFor="let line of config.dictionary.explanationText">
    {{line | translate}}
  </div>
</ng-template>
