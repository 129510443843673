import { RideTypesPerChanges } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.daily.warning';

const dictionary = {
  notSet: `${DICTIONARY_PATH}.notSet`,
  types: {
    startHour: `${DICTIONARY_PATH}.types.startHour`,
    endHour: `${DICTIONARY_PATH}.types.endHour`,
    accompany: `${DICTIONARY_PATH}.types.accompany`
  },
  anonymousAccompany: 'general.anonymousAccompany'
};

export const routesDailyWarningComponentConfig = {
  dictionary,
  titlesByType: {
    [RideTypesPerChanges.StartHour]: dictionary.types.startHour,
    [RideTypesPerChanges.EndHour]: dictionary.types.endHour,
    [RideTypesPerChanges.Accompany]: dictionary.types.accompany
  }
};
