<app-table-page
  [config]="config().tablePageConfig"
></app-table-page>

<ng-template #contentCell let-row="row">
  <div class="audit-history-content-comparing">
    <div class="audit-history-content-comparing__new">{{row.newValue}}</div>
    <div class="audit-history-content-comparing__old">{{row.originalValue}}</div>
  </div>
</ng-template>
