import { RoutesDeletePeriod } from '@app/routes/models';

const DICTIONARY_PATH: string = 'shared.deleteRoute';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  titlePlural: `${DICTIONARY_PATH}.titlePlural`,
  applyDelete: `${DICTIONARY_PATH}.applyDelete`,
  deletePeriod: {
    title: `${DICTIONARY_PATH}.period.title`,
    whole: `${DICTIONARY_PATH}.period.whole`,
    wholePlural: `${DICTIONARY_PATH}.period.wholePlural`,
    fromDate: `${DICTIONARY_PATH}.period.startFrom`
  },
  routesToDeleteWarning: `${DICTIONARY_PATH}.routesToDeleteWarning`,
  permanentDeleteWarning: `${DICTIONARY_PATH}.permanentDeleteWarning`,
  enterNumberToConfirm: `${DICTIONARY_PATH}.enterNumberToConfirm`,
  routesAmount: `${DICTIONARY_PATH}.routesAmount`,
  invalidNumber: `${DICTIONARY_PATH}.invalidNumber`,
  select: 'general.select',
  uInputDate: 'uInputDate'
};

export const deleteRouteComponentConfig = {
  dictionary,
  deletePeriodSelectItems: [
    { name: dictionary.deletePeriod.whole, value: RoutesDeletePeriod.Whole },
    { name: dictionary.deletePeriod.fromDate, value: RoutesDeletePeriod.FromDate }
  ],
  deletePeriodSelectItemsPlural: [
    { name: dictionary.deletePeriod.wholePlural, value: RoutesDeletePeriod.Whole },
    { name: dictionary.deletePeriod.fromDate, value: RoutesDeletePeriod.FromDate }
  ]
};
