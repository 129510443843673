import { Pipe, PipeTransform } from '@angular/core';

import { appConfig } from '@app/shared/configs';
import { environment } from '@environments/environment';
import { AppConstants } from '@app/shared/constants';
import { DistanceUnit, EnvMetricSystemType } from '@app/shared/models';

@Pipe({
  name: 'kmMileConvertor',
  standalone: true
})
export class KmMileConvertorPipe implements PipeTransform {
  private mileToKm(value: number | string): number {
    return Math.round((parseFloat(<string>value) / AppConstants.MILES_FACTOR) * 100) / 100;
  }

  private kmToMile(value: number | string): number {
    return Math.round((parseFloat(<string>value) * AppConstants.MILES_FACTOR) * 100) / 100;
  }

  transform(value: number | string, convertTo?: DistanceUnit.Km | DistanceUnit.Mile, valueUnit: DistanceUnit.Km | DistanceUnit.Mile = appConfig.defaultDistanceUnit): number | string {
    if (!value
      || (environment.config.metricSystem === EnvMetricSystemType.Metric && valueUnit === DistanceUnit.Km && convertTo !== DistanceUnit.Mile)
      || (environment.config.metricSystem === EnvMetricSystemType.Imperial && valueUnit === DistanceUnit.Mile && convertTo !== DistanceUnit.Km)
    ) {
      return value;
    }

    if (convertTo) {
      switch (convertTo) {
        case DistanceUnit.Km: {
          return this.mileToKm(value);
        }

        case DistanceUnit.Mile: {
          return this.kmToMile(value);
        }
      }
    }

    switch (environment.config.metricSystem) {
      case EnvMetricSystemType.Imperial: {
        return this.kmToMile(value);
      }

      case EnvMetricSystemType.Metric: {
        return this.mileToKm(value);
      }
    }
  }
}
