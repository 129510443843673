import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { cloneDeep } from 'lodash';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UTooltipModule } from '@shift/ulib';

import { RoutesRidePassenger } from '@app/routes/models';
import { routesRidePassengersComponentConfig } from './routes-ride-passengers.component.config';

@Component({
  selector: 'app-routes-ride-passengers',
  templateUrl: './routes-ride-passengers.component.html',
  styleUrls: [ './routes-ride-passengers.component.scss', './routes-ride-passengers.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ CommonModule, TranslateModule, UTooltipModule ]
})
export class RoutesRidePassengersComponent {
  @Input() passengers: RoutesRidePassenger[];

  @HostBinding('class') hostClasses: string = 'routes-ride-passengers';

  config = cloneDeep(routesRidePassengersComponentConfig);

  passengerTrackBy(index: number, passenger: RoutesRidePassenger) {
    return passenger?.id;
  }
}
