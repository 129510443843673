import { Component, ViewEncapsulation } from '@angular/core';
import { ModalBackdropComponent } from 'ngx-bootstrap/modal';

/* eslint-disable */
@Component({
  selector: 'app-bs-modal-backdrop',
  template: ' ',
  host: { class: 'modal-backdrop modal-backdrop-absolute' },
  styleUrls: [ './builder-modal-backdrop.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class BuilderModalBackdropComponent extends ModalBackdropComponent {}
