import { AuthModuleBranchesFeatureType } from '@app/auth/models';

const DICTIONARY_PATH: string = 'shared.inputBranches';

export const inputBranchesComponentConfig = {
  dictionary: {
    assignAutomatically: {
      text: `${DICTIONARY_PATH}.assignAutomatically.text`,
      info: {
        title: {
          [AuthModuleBranchesFeatureType.Generic]: `${DICTIONARY_PATH}.assignAutomatically.info.title`,
          [AuthModuleBranchesFeatureType.Iec]: `${DICTIONARY_PATH}.assignAutomatically.info.title`,
          [AuthModuleBranchesFeatureType.Base]: `${DICTIONARY_PATH}.assignAutomatically.info.titleBases`,
          [AuthModuleBranchesFeatureType.ActivityCenter]: `${DICTIONARY_PATH}.assignAutomatically.info.titleActivityCenters`
        },
        subtitle: `${DICTIONARY_PATH}.assignAutomatically.info.subtitle`
      }
    }
  }
};
