import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ExportToExcelObject } from '@app/shared/models';
import { Station } from '@app/stations/models';
import { ApiService } from '@app/shared/services';
import { FileSaverService } from '@app/files/services';

@Injectable({
  providedIn: 'root'
})
export class StationsService {
  private BASE_PATH = 'Stations';

  constructor(
    private apiService: ApiService,
    private fileSaverService: FileSaverService
  ) {}

  getSavedStations(): Observable<Station[]> {
    return this.apiService.get(`${this.BASE_PATH}/GetSavedStations`);
  }

  saveStation(params: Station): Observable<{ id: number; name: string; }> {
    return this.apiService.post(`${this.BASE_PATH}/SaveStation`, params);
  }

  updateStation(params: Station): Observable<{ id: number; name: string; }> {
    return this.apiService.put(`${this.BASE_PATH}/UpdateStation`, params);
  }

  deleteStations(params: number[]): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/DeleteStations`, params);
  }

  exportToExcel(params: ExportToExcelObject): Observable<void> {
    return this.apiService.postBlob(`${this.BASE_PATH}/Export/Excel`, params.data, 'blob')
      .pipe(tap(res => this.fileSaverService.downloadBlobFile(res, params.fileName)));
  }
}
