import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ValidationService } from '@app/shared/services';

@Injectable()
export class ShuttleCompaniesRatesService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private validationService: ValidationService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initRatesValidators(form: UntypedFormGroup) {
    const nightlyRateControls = (form.get('nightlyRate') as UntypedFormGroup).controls;
    const weekendRateControls = (form.get('weekendRate') as UntypedFormGroup).controls;

    const nightlyTimeValidators = [
      this.validationService.conditionalValidator(() => nightlyRateControls.isActive.value, Validators.required)
    ];

    nightlyRateControls.startTime.setValidators(nightlyTimeValidators);
    nightlyRateControls.endTime.setValidators(nightlyTimeValidators);

    const weekendTimeValidators = [
      this.validationService.conditionalValidator(() => weekendRateControls.isActive.value, Validators.required),
      this.validationService.conditionalValidator(
        () => weekendRateControls.startDay.value === weekendRateControls.endDay.value,
        () => this.validationService.startTimeBeforeEndTime(
          weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl
        )
      )
    ];

    weekendRateControls.startTime.setValidators(weekendTimeValidators);
    weekendRateControls.endTime.setValidators(weekendTimeValidators);

    this.onRatesChange(form);
  }

  onRatesChange(form: UntypedFormGroup) {
    const nightlyRateControls = (form.get('nightlyRate') as UntypedFormGroup).controls;
    const weekendRateControls = (form.get('weekendRate') as UntypedFormGroup).controls;

    nightlyRateControls
      .isActive
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(nightlyRateControls.startTime as UntypedFormControl, nightlyRateControls.endTime as UntypedFormControl));

    weekendRateControls
      .isActive
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl));

    weekendRateControls
      .startDay
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl));

    weekendRateControls
      .endDay
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl));

    weekendRateControls
      .startTime
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe(() => weekendRateControls.endTime.updateValueAndValidity());

    weekendRateControls
      .endTime
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe(() => weekendRateControls.startTime.updateValueAndValidity());
  }

  updateTimeValidators(startTime: UntypedFormControl, endTime: UntypedFormControl) {
    startTime.updateValueAndValidity();
    endTime.updateValueAndValidity();
  }
}
