import { URadioButton } from '@shift/ulib';

import { GeneralCardButton, GeneralCardButtonValue, TablePageConfig } from '@app/shared/models';
import { AuthModuleSupervisorsFeatureType } from '@app/auth/models';
import { RouteDirection } from '@app/routes/models';
import { RouteSplitEmptyStationsAction } from '@app/route-split/models';

const DICTIONARY_PATH: string = 'routeSplit.card';

const buttons: GeneralCardButton[] = [
  {
    value: GeneralCardButtonValue.Continue,
    name: 'general.saveAndClose',
    disabled: true
  }
];

const table: TablePageConfig = {
  id: 'route-split-card',
  tableConfig: {
    columns: [
      {
        prop: 'check',
        name: '',
        hideable: false,
        width: 20,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideDivider: true
      },
      {
        prop: 'name',
        name: `${DICTIONARY_PATH}.table.name`,
        minWidth: 120,
        filterType: 'select',
        cellTemplateName: 'nameCell'
      },
      {
        prop: 'mobile',
        name: `${DICTIONARY_PATH}.table.mobile`,
        minWidth: 112,
        filterType: 'select'
      },
      {
        prop: 'stationName',
        name: `${DICTIONARY_PATH}.table.station`,
        minWidth: 200,
        filterType: 'select'
      },
      {
        prop: 'targetName',
        minWidth: 200,
        filterType: 'select',
        nameByDirection: true
      }
    ],
    showCounters: false,
    hideColumnsFilters: true
  },
  hideMetadata: true
};

const emptyStationsActions: URadioButton[] = [
  {
    value: RouteSplitEmptyStationsAction.Keep,
    name: `${DICTIONARY_PATH}.allowEmptyStations.keep`
  },
  {
    value: RouteSplitEmptyStationsAction.Remove,
    name: `${DICTIONARY_PATH}.allowEmptyStations.remove`
  }
];

export const routeSplitCardComponentConfig = {
  buttons,
  table,
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    namesByDirection: {
      [RouteDirection.Forward]: `${DICTIONARY_PATH}.table.destination`,
      [RouteDirection.Backward]: `${DICTIONARY_PATH}.table.source`
    },
    supervisorTitlesByFeatureType: {
      [AuthModuleSupervisorsFeatureType.Commander]: `${DICTIONARY_PATH}.table.rideCommander`,
      [AuthModuleSupervisorsFeatureType.Generic]: `${DICTIONARY_PATH}.table.rideSupervisor`
    },
    allowEmptyStations: {
      title: `${DICTIONARY_PATH}.allowEmptyStations.title`,
      selectAction: `${DICTIONARY_PATH}.allowEmptyStations.selectAction`
    }
  },
  emptyStationsActions
};
