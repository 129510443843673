import { builderConfig } from '@app/builder/configs';
import { BuilderLayer, BuilderLayerId } from '@app/builder/models';

const DICTIONARY_PATH: string = 'builder.layers';

const layers: BuilderLayer[] = [
  {
    id: BuilderLayerId.MonitoringPath,
    title: `${DICTIONARY_PATH}.monitoringPath`,
    active: true
  }
];

export const builderLayersComponentConfig = {
  trackingId: builderConfig.routeAddEditTrackingId,
  dictionaryPath: DICTIONARY_PATH,
  layers
};
