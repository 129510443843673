import { UMultiselectItem } from '@shift/ulib';

import { CityCombinations, DaysOfWeek, RoutePolicy, SelectSItem } from '@app/shared/models';
import { RouteType } from '@app/routes/models';
import {
  RoutePlannerBranch,
  RoutePlannerDirectionType,
  RoutePlannerRouteGenerationMode,
  RoutePlannerRoutesGeneration,
  RoutePlannerRouteType,
  RoutePlannerShift,
  RoutePlannerShuttleCompany,
  RoutePlannerVehicleType
} from '@app/route-planner/models';
import { BuilderTimeType } from './builder.models';

export enum BuilderFullStep {
  SelectPassengers,
  PlanningDetails,
  RoutesResults
}

export enum BuilderFullRoutesFor {
  All,
  Schools,
  Activities
}

export enum BuilderFullCostType {
  FixedPrice = 'fixedPrice',
  AccordingToContract = 'accordingToContract'
}

export enum BuilderFullSkippedAction {
  Download,
  Close,
  CloseContinue
}

export enum BuilderFullSkippedReason {
  PassengerUnknown = 'PassengerUnknown',
  PassengerStationUnknown = 'PassengerStationUnknown',
  TargetStationUnknown = 'TargetStationUnknown',
  PassengerCityNotAvailable = 'PassengerCityNotAvailable',
  PassengerNoDemands = 'PassengerNoDemands',
  NoPathAvailable = 'NoPathAvailable',
  AlgorithmTimeout = 'AlgorithmTimeout',
  AlgorithmFailed = 'AlgorithmFailed',
  InternalError = 'InternalError',
  UndefinedSchedule = 'UndefinedSchedule'
}

export interface BuilderFullRoutesGenerationState  {
  show: boolean;
  showTooltip: boolean;
  disabled: boolean;
  disabledAiMode: boolean;
  mode: RoutePlannerRoutesGeneration;
}

export interface BuilderFullVisibleComponents {
  main: boolean;
  routes: boolean;
  passengers: boolean;
  filter: boolean;
  settings: boolean;
  map: boolean;
}

export interface BuilderFullOptions {
  routesFor: SelectSItem[];
  directions: SelectSItem[];
  timeTypes: SelectSItem[];
  pricingTypes: SelectSItem[];
  shuttleCompanies: SelectSItem[];
  vehicleTypes: UMultiselectItem[];
  planningTypes: SelectSItem[];
  routeTypes: SelectSItem[];
}

export interface BuilderFullConfigData {
  sessionId: string;
  maxDate: string;
  minDate: string;
  cityCombinations: CityCombinations;
  routePolicy: RoutePolicy;
  shuttleCompanies: RoutePlannerShuttleCompany[];
  vehicleTypes: RoutePlannerVehicleType[];
  routeTypes: RoutePlannerRouteType[];
  shifts: RoutePlannerShift[];
  routePlannerPassengersAmountRequestLimit: number;
  branches: RoutePlannerBranch[];
  isDemandsPlanningEnabled: boolean;
  isTemplatesPlanningEnabled: boolean;
}

export interface BuilderFullPassengers {
  all: number;
  selected: number;
  selectedItems: any[];
  columnsFiltered: boolean;
  resetColumnsFilter: boolean;
}

export interface BuilderFullMessage {
  type: 'success' | 'error';
  content: string;
}

export interface BuilderFullFilterDefault {
  direction: RoutePlannerDirectionType;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  activeDays: DaysOfWeek[];
  byDemand: boolean;
  shuttleCompanyId: number;
  calculationTimeType: BuilderTimeType;
  customTime: string;
  costType: BuilderFullCostType;
  fixedCost: number;
  mode: RoutePlannerRouteGenerationMode;
  routeTemplateIds: number[];
  minimizeVehicleType: boolean;
  allowMultipleTemplateUsage: boolean;
  routeType: RouteType;
}
