import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import {
  UMultiselectItem,
  USearchAppliedFilter,
  USearchConfig,
  USearchFilter,
  USelectSItem,
  UInputCitiesCity,
  USearchFiltersMode,
  UInputCitiesCityLocation
} from '@shift/ulib';

import { CommonService } from './common.service';
import { HeaderSearchFiltersStorageService } from './header-search-filters-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderSearchFiltersService {
  private readonly commonService = inject(CommonService);
  private readonly headerSearchFiltersStorageService = inject(HeaderSearchFiltersStorageService);

  private showFiltersDrawer: BehaviorSubject<boolean> = new BehaviorSubject(this.headerSearchFiltersStorageService.getShowFiltersDrawer());
  private searchConfigByUrls: BehaviorSubject<{ [key: string]: USearchConfig; }> = new BehaviorSubject({});
  private uSearchConfig: BehaviorSubject<{ value: USearchConfig; propagate?: boolean; }> =
    new BehaviorSubject({ value: { filters: [], appliedSearch: '', appliedFilters: [], filtersMode: USearchFiltersMode.Drawer } });

  showFiltersDrawer$ = this.showFiltersDrawer.asObservable();
  uSearchConfigValue$ = this.uSearchConfig.asObservable().pipe(map(res => res.value));
  existFilters$ = this.uSearchConfig.asObservable()
    .pipe(
      map(data => !!data.value.filters.length)
    );
  filters$ = this.uSearchConfig.asObservable()
    .pipe(
      filter(update => update.propagate !== false),
      map(data => data.value.filters)
    );
  appliedFilters$ = this.uSearchConfig.asObservable()
    .pipe(
      map(data => data.value.appliedFilters),
      distinctUntilChanged(isEqual)
    );
  appliedSearch$ = this.uSearchConfig.asObservable()
    .pipe(
      map(data => data.value.appliedSearch),
      distinctUntilChanged()
    );
  showHeaderFiltersDrawer$ = combineLatest([ this.showFiltersDrawer$, this.existFilters$ ])
    .pipe(map(([ showFiltersDrawer, existFilters ]) => showFiltersDrawer && existFilters));

  private getCurrentUrlSearchConfig(): USearchConfig {
    return this.searchConfigByUrls.value && this.searchConfigByUrls.value[this.commonService.previousUrl().replace('/', '')];
  }

  private updateSearchConfigByUrl(searchConfig: USearchConfig = this.uSearchConfig.value.value) {
    if (searchConfig) {
      const searchConfigByUrlsValue = this.searchConfigByUrls.value;

      searchConfigByUrlsValue[this.commonService.previousUrl().replace('/', '')] = searchConfig;

      this.searchConfigByUrls.next(searchConfigByUrlsValue);
    }
  }

  reset() {
    this.setSearchConfig({
      filters: [],
      appliedSearch: '',
      filtersMode: USearchFiltersMode.Drawer
    });
  }

  resetAppliedFilters(propagate?: boolean) {
    this.setSearchConfig({
      ...this.uSearchConfig.value.value,
      appliedFilters: []
    }, propagate);
  }

  resetSearchConfigByUrl(url: string) {
    if (this.searchConfigByUrls.value.hasOwnProperty(url)) {
      this.searchConfigByUrls.next({
        ...this.searchConfigByUrls.value,
        [url]: null
      });
    }
  }

  onFiltersApplied(appliedFilters: USearchAppliedFilter[]) {
    this.setSearchConfig({
      ...this.uSearchConfig.value.value,
      appliedFilters
    });

    this.applyFilters();
  }

  getSearchValue(): string {
    return this.uSearchConfig.value.value.appliedSearch;
  }

  applySearch(appliedSearch: string) {
    this.setSearchConfig({
      ...this.uSearchConfig.value.value,
      appliedSearch
    });

    this.updateSearchConfigByUrl();
  }

  applyFilters(filters: USearchFilter[] = this.getFilters()) {
    this.setSearchConfig({
      ...this.uSearchConfig.value.value,
      filters
    });

    this.updateSearchConfigByUrl();
  }

  updateFiltersControls(filters: USearchFilter[]) {
    this.setSearchConfig({
      ...this.uSearchConfig.value.value,
      filters
    }, false);

    this.updateSearchConfigByUrl();
  }

  updateFilterItems(filterName: string, items: (UInputCitiesCity | USelectSItem | UMultiselectItem)[]) {
    this.setSearchConfig({
      ...this.uSearchConfig.value.value,
      filters: this.uSearchConfig.value.value.filters.map(filterObj =>
        ({
          ...filterObj,
          items: filterObj.name === filterName ? items : filterObj.items
        })
      )
    }, false);
  }

  updateFiltersItems(data) {
    this.setSearchConfig({
      ...this.uSearchConfig.value.value,
      filters: this.uSearchConfig.value.value.filters.map(filterObj => ({
        ...filterObj,
        ...(!(filterObj.items && filterObj.items.length) ? {
          items: data && Array.isArray(data[filterObj.name]) && data[filterObj.name]
        } : {})
      }))
    }, false);
  }

  loadCurrentUrlSearchConfig() {
    this.setSearchConfig({ ...(this.getCurrentUrlSearchConfig() || this.uSearchConfig.value.value) });
  }

  getSearchConfig(): USearchConfig {
    return this.uSearchConfig.value.value;
  }

  setSearchConfig(searchConfig: USearchConfig, propagate: boolean = true) {
    this.uSearchConfig.next({ value: searchConfig, propagate });
  }

  getFilters() {
    return this.uSearchConfig.value.value.filters;
  }

  getAppliedFilters() {
    return this.uSearchConfig.value.value.appliedFilters;
  }

  getAppliedFiltersValue() {
    let value: { [key: string]: number | string | boolean | (number | string | boolean | UInputCitiesCityLocation)[] } = {};

    if (this.uSearchConfig.value.value.appliedFilters) {
      value = this.uSearchConfig.value.value.appliedFilters.reduce((acc, obj) => {
        if (obj.name && obj.value !== undefined) {
          acc[obj.name] = obj.value;
        }

        return acc;
      }, {});
    }

    return value;
  }

  getFiltersValue(data?: USearchFilter[]) {
    const filters = data || this.uSearchConfig.value.value.filters;

    let value: { [key: string]: number | string | boolean | (number | string | boolean | UInputCitiesCityLocation)[] } = {};

    if (filters) {
      value = filters.reduce((acc, ob) => {
        if (ob.name && ob.control.value !== undefined) {
          acc[ob.name] = ob.control.value;
        }

        return acc;
      }, {});
    }

    return value;
  }

  getFiltersValueByUrl(url: string) {
    return this.getFiltersValue(this.searchConfigByUrls?.value?.[url]?.filters);
  }

  toggleShowFiltersDrawer() {
    this.updateShowFiltersDrawer(!this.showFiltersDrawer.value);
  }

  updateShowFiltersDrawer(isOpen: boolean) {
    if (this.getSearchConfig().filtersMode === USearchFiltersMode.Drawer) {
      this.showFiltersDrawer.next(isOpen);

      this.headerSearchFiltersStorageService.updateShowFiltersDrawer(isOpen);
    }
  }
}
