import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, Subscription } from 'rxjs';
import { delay, filter, retryWhen, switchMap, takeWhile } from 'rxjs/operators';

import { SignalRService } from '@app/shared/services';
import { ShuttleCompanyStateChanged } from '@app/shuttle-companies/models';
import { appConfig } from '@app/shared/configs';

@Injectable()
export class ShuttleCompaniesHubService extends SignalRService {
  private lockedShuttleCompanyId: number;
  private reconnectSubscription: Subscription;

  BASE_PATH: string = 'shuttleCompaniesHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  async startSession() {
    this.init();

    await this.start();

    this.clearReconnectSubscription();

    this.reconnectSubscription = this.onClose()
      .pipe(
        filter(err => !!err),
        switchMap(() =>
          from(this.start())
            .pipe(
              retryWhen(err =>
                err.pipe(
                  delay(appConfig.retryRequestTimeout),
                  takeWhile(() => !this.isConnected)
                )
              )
            )
        )
      )
      .subscribe(async () => {
        if (this.lockedShuttleCompanyId) {
          await this.lockShuttleCompany(this.lockedShuttleCompanyId);
        }
      });
  }

  private clearReconnectSubscription() {
    if (this.reconnectSubscription) {
      this.reconnectSubscription.unsubscribe();
      this.reconnectSubscription = null;
    }
  }

  async stopSession() {
    if (this.signalRClient) {
      this.clearReconnectSubscription();

      if (this.isConnected) {
        await this.stop();
      }
    }
  }

  async lockShuttleCompany(shuttleCompanyId: number) {
    this.lockedShuttleCompanyId = shuttleCompanyId;

    if (this.isConnected) {
      await this.signalRClient.send('Lock', shuttleCompanyId);
    }
  }

  async unlockShuttleCompany() {
    this.lockedShuttleCompanyId = null;

    if (this.isConnected) {
      await this.signalRClient.send('Unlock');
    }
  }

  onShuttleCompanyStateChanged(): Observable<ShuttleCompanyStateChanged> {
    return this.onChannel('stateChanged');
  }
}
