import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_KEY, RoutesState } from '@app/routes/state/reducers';

export const selectRoutesState = createFeatureSelector<RoutesState>(
  FEATURE_KEY
);

export const selectRoutesDailyInitedState = createSelector(
  selectRoutesState,
  (state) => state.daily.inited
);

export const selectRoutesDailyItemsState = createSelector(
  selectRoutesState,
  (state) => state.daily.items
);

export const selectRoutesDailyParamsState = createSelector(
  selectRoutesState,
  (state) => state.daily.params
);

export const selectRoutesDailySelectedFiltersType = createSelector(
  selectRoutesState,
  (state) => state.daily.selectedFilters.type
);

export const selectRoutesWeeklySelectedFilters = createSelector(
  selectRoutesState,
  (state) => state.weekly.selectedFilters
);

export const selectRoutesViewTypeValue = createSelector(
  selectRoutesState,
  (state) => state.viewType.value
);
