<div 
  *ngIf="form"
  [formGroup]="form"
  class="route-policy__main"
  #viewport 
>
  <u-group
    class="u-group_large-field u-group_flex u-group_app-route-policy"
    [name]="config.dictionary.maxShuttleTime | translate"
  >
    <u-input-number
      formControlName="maxDurationMinutes"
      [min]="10"
      [max]="600"
      [decimal]="true"
      [valid]="form.get('maxDurationMinutes').valid"
      [valueType]="'number'"
    ></u-input-number>
    <div class="route-policy__extra-info">{{config.dictionary.minutes | translate}}</div>
  </u-group>
  <u-group
    class="u-group_large-field u-group_flex u-group_app-route-policy"
    [name]="config.dictionary.getEarlyToDest | translate"
  >
    <div class="route-policy__double">
      <div class="route-policy__double-item">
        <u-toggle formControlName="useGetEarlyToDestination"></u-toggle>
      </div>
      <div class="route-policy__double-item">
        <u-input-number
          *ngIf="form.get('useGetEarlyToDestination').value"
          formControlName="getEarlyToDestinationMinutes"
          [min]="1"
          [max]="60"
          [decimal]="true"
          [valid]="form.get('getEarlyToDestinationMinutes').valid"
          [valueType]="'number'"
        ></u-input-number>
      </div>
    </div>
    <div
      *ngIf="form.get('useGetEarlyToDestination').value || form.get('useGetEarlyToDestination').disabled"
      class="route-policy__extra-info"
    >
      {{config.dictionary.minutes | translate}}
    </div>
  </u-group>
  <u-group
    class="u-group_large-field u-group_flex u-group_app-route-policy"
    [name]="config.dictionary.pickupFromCentralStations | translate"
  >
    <div class="route-policy__double">
      <div class="route-policy__double-item">
        <u-toggle formControlName="useCentralStations"></u-toggle>
      </div>
      <div class="route-policy__double-item">
        <u-input-number
          formControlName="centralStationRadiusMeters"
          [min]="1"
          [max]="100000"
          [decimal]="true"
          [valid]="form.get('centralStationRadiusMeters').valid"
          [valueType]="'number'"
        ></u-input-number>
      </div>
    </div>
    <div
      class="route-policy__extra-info"
    >
      {{config.dictionary.meters | translate}}
    </div>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.pickupOptions.title | translate"
  >
    <u-select-s
      [items]="pickupOptionTypes"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('curbApproach').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [viewportElement]="viewport"
      [viewportAutoClose]="true"
      formControlName="curbApproach"
      [container]="'body'"
      [classUDropdownContent]="'u-dropdown-content_app-default'"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_large-field u-group_flex u-group_app-route-policy"
    [name]="config.dictionary.pickupFromBusStops | translate"
  >
    <u-toggle formControlName="useBusStations"></u-toggle>
  </u-group>
  <u-group
    class="u-group_large-field u-group_flex u-group_app-route-policy"
    [name]="config.dictionary.waitOnStation | translate"
  >
    <u-input-number
      formControlName="waitOnStationSeconds"
      [min]="0"
      [max]="600"
      [decimal]="true"
      [valid]="form.get('waitOnStationSeconds').valid"
      [valueType]="'number'"
    ></u-input-number>
    <div class="route-policy__extra-info">{{config.dictionary.seconds | translate}}</div>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.pickUpFrom | translate"
  >
    <u-select-s
      [items]="passengerStationTypes"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('calculateBy').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [viewportElement]="viewport"
      [viewportAutoClose]="true"
      formControlName="passengerStationType"
      [container]="'body'"
      [classUDropdownContent]="'u-dropdown-content_app-default'"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.calculate | translate"
  >
    <u-select-s
      [items]="calculateTypes"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('calculateBy').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [viewportElement]="viewport"
      [viewportAutoClose]="true"
      formControlName="calculateBy"
      [container]="'body'"
      [classUDropdownContent]="'u-dropdown-content_app-default'"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy u-group_app-route-policy-question"
    [name]="config.dictionary.speedRate | translate"
  >
    <u-input-number
      formControlName="speedRate"
      [min]="50"
      [max]="200"
      [valueType]="'number'"
    ></u-input-number>
    <div class="route-policy__extra-info">%</div>
    <div
      class="route-policy__question-icon"
      [uTooltip]="config.dictionary.cheapestPrice.infoTooltip | translate"
      [tooltipClass]="'u-tooltip_general u-tooltip_app-route-policy-question'"
      [placement]="['top', 'bottom']"
      [container]="'body'"
    ></div>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy u-group_app-route-policy-question"
    [name]="config.dictionary.cheapestPrice.title | translate"
  >
    <u-toggle formControlName="cheapestPrice" (toggleChanged)="cheapestPriceChange($event)"></u-toggle>
    <div
      class="route-policy__question-icon route-policy__question-icon_with-margin"
      [uTooltip]="config.dictionary.cheapestPriceTitleInfoTooltip | translate"
      [tooltipClass]="'u-tooltip_general u-tooltip_app-route-policy-question'"
      [placement]="['top', 'bottom']"
      [container]="'body'"
    ></div>
    <div class="route-policy__extra-info">{{config.dictionary.cheapestPrice.onlyUsingPricing | translate}}</div>
  </u-group>
  <u-group
    *ngIf="form.get('algorithmVersion').value === routePolicyAlgorithmVersion.AlgoV2A"
    class="u-group_large-field u-group_app-route-policy u-group_app-route-policy-question"
    [name]="config.dictionary.equalDistribution | translate"
  >
    <u-toggle formControlName="useEqualDistribution"></u-toggle>
    <div
      class="route-policy__question-icon route-policy__question-icon_with-margin"
      [uTooltip]="config.dictionary.equalDistributionInfo | translate"
      [tooltipClass]="'u-tooltip_general u-tooltip_app-route-policy-question'"
      [placement]="['top', 'bottom']"
      [container]="'body'"
    ></div>
  </u-group>
  <u-group
    *ngIf="authPolicyRoutes | includes: authPolicyRoute.UseTraffic"
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.useTrafficData | translate"
  >
    <u-toggle formControlName="useTraffic"></u-toggle>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.tollRoads | translate"
  >
    <u-toggle formControlName="useTollRoads"></u-toggle>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.share | translate"
  >
    <u-toggle formControlName="useSharing"></u-toggle>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy u-group_flex"
    [name]="config.dictionary.getEarlyBy | translate"
  >
    <u-input-number
      formControlName="consolidationTimeMinutes"
      [min]="0"
      [max]="180"
      [decimal]="true"
      [valid]="form.get('consolidationTimeMinutes').valid"
      [valueType]="'number'"
    ></u-input-number>
    <div class="route-policy__extra-info">{{config.dictionary.minutesFrom | translate}}</div>
  </u-group>
  <u-group
    *ngIf="authPolicyRoutes | includes: authPolicyRoute.DirectRoute"
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.directRoute | translate"
  >
    <u-toggle formControlName="directRoute"></u-toggle>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.useCitySettings | translate"
  >
    <u-toggle formControlName="useCityOptions"></u-toggle>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.routeNamePolicy | translate"
  >
    <u-select-s
      [items]="routeNamePolicyTypes"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('routeNamePolicy').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [viewportAutoClose]="true"
      formControlName="routeNamePolicy"
      [container]="'body'"
      [classUDropdownContent]="'u-dropdown-content_app-default'"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_large-field u-group_app-route-policy"
    [name]="config.dictionary.passengerAddressPolicy | translate"
  >
    <u-select-s
      [items]="passengerAddressPolicyTypes"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [valid]="form.get('passengerAddressPolicy').valid"
      [empty]="false"
      [placeholder]="config.dictionary.select | translate"
      [viewportAutoClose]="true"
      formControlName="passengerAddressPolicy"
      [container]="'body'"
      [classUDropdownContent]="'u-dropdown-content_app-default'"
    ></u-select-s>
  </u-group>
</div>
