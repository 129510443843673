import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, HostBinding, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { USelectSItem } from '@shift/ulib';

import { TrackingService } from '@app/shared/services';
import { BuilderAddPassengerToExistStationSaveAction } from '@app/builder/models';
import { builderConfig } from '@app/builder/configs';
import { builderAddPassengerToExistStationComponentConfig } from './builder-add-passenger-to-exist-station.component.config';

@Component({
  selector: 'app-builder-add-passenger-to-exist-station',
  templateUrl: './builder-add-passenger-to-exist-station.component.html',
  styleUrls: [ './builder-add-passenger-to-exist-station.component.scss', './builder-add-passenger-to-exist-station.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderAddPassengerToExistStationComponent implements OnInit {
  @Output() saveAction: EventEmitter<BuilderAddPassengerToExistStationSaveAction> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-add-passenger-to-exist-station';

  private readonly bsModalRef = inject(BsModalRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private readonly trackingService = inject(TrackingService);

  config = cloneDeep(builderAddPassengerToExistStationComponentConfig);
  passengerId: number;
  passengerName: string;
  form: UntypedFormGroup = this.formBuilder.group({
    stationId: [ null, [ Validators.required ] ],
    destinationId: [ null, [ Validators.required ] ],
    assignedDestination: [ false ]
  });
  stations: USelectSItem[] = [];
  destinations: USelectSItem[] = [];

  ngOnInit() {
    this.onFormValueChanges();
    this.setDefaultValues();
  }

  private onFormValueChanges() {
    this.form.get('assignedDestination').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value) {
          this.form.get('destinationId').patchValue(null);
          this.form.get('destinationId').disable();
        } else {
          this.form.get('destinationId').enable();
        }

        this.trackEvent(`check 'Add the passenger's assigned destination'`);
      });
  }

  private setDefaultValues() {
    if (this.stations?.length === 1) {
      this.form.get('stationId').patchValue(this.stations[0].value);
      this.form.get('stationId').disable();
    }

    if (this.destinations?.length === 1) {
      this.form.get('destinationId').patchValue(this.destinations[0].value);
      this.form.get('destinationId').disable();
    }
  }

  trackEvent(message: string) {
    this.trackingService.track(`[${builderConfig.routeAddEditTrackingId}] - ${this.config.trackingId} - ${message}`);
  }

  close() {
    this.trackEvent('click on X button');

    this.bsModalRef.hide();
  }

  save() {
    this.trackEvent('click on Save');

    this.saveAction.emit({
      passengerId: this.passengerId,
      stationId: this.form.get('stationId').value,
      destinationId: this.form.get('destinationId').value
    });

    this.bsModalRef.hide();
  }
}

