import { Injectable, inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { RoutesExportType } from '@app/routes/models';
import { RoutesExportComponent } from '@app/routes/components';

@Injectable({
  providedIn: 'root'
})
export class RoutesExportModalService {
  private readonly bsModalService = inject(BsModalService);

  openModal(routeIds: number[], exportType: RoutesExportType) {
    this.bsModalService.show(
      RoutesExportComponent,
      {
        class: 'u-modal u-modal_app-routes-export',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: {
          routeIds,
          exportType
        }
      }
    );
  }
}
