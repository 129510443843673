import { Component, HostBinding, Input, ViewEncapsulation, inject } from '@angular/core';

import { BuilderDataService } from '@app/builder/services';
import { BuilderBuildMode, BuilderMapRide, BuilderMode } from '@app/builder/models';

@Component({
  selector: 'app-builder-map',
  templateUrl: './builder-map.component.html',
  styleUrls: [ './builder-map.component.scss', './builder-map.component.rtl.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class BuilderMapComponent {
  @Input() mapRide: BuilderMapRide;

  @HostBinding('class') hostClasses: string = 'builder-map';

  public readonly builderDataService = inject(BuilderDataService);

  readonly builderBuildMode = BuilderBuildMode;
  readonly builderMode = BuilderMode;
}
