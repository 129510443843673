const DICTIONARY_PATH: string = 'builder.fines';

export const builderFinesComponentConfig = {
  dictionary: {
    executor: `${DICTIONARY_PATH}.executor`,
    date: `${DICTIONARY_PATH}.date`,
    shuttleCompany: `${DICTIONARY_PATH}.shuttleCompany`,
    contract: `${DICTIONARY_PATH}.contract`,
    violation: `${DICTIONARY_PATH}.violation`,
    amount: `${DICTIONARY_PATH}.amount`,
    comment: `${DICTIONARY_PATH}.comment`,
    deleteBtn: `${DICTIONARY_PATH}.deleteBtn`,
    amountCurrency: `${DICTIONARY_PATH}.amountCurrency`,
    deletedBy: `${DICTIONARY_PATH}.deletedBy`,
    uGrid: 'uGrid'
  }
};
