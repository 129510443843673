import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthModuleGuard } from '@app/auth/guards/auth-module.guard';
import { AuthModuleName } from '@app/auth/models';
import { AuthFacade } from '@app/auth/state/facades';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class AuthModuleBIGuard extends AuthModuleGuard {
  MODULE_NAME = AuthModuleName.BI;

  constructor(
    router: Router,
    authFacade: AuthFacade,
    authDataService: AuthDataService
  ) {
    super(router, authFacade, authDataService);
  }
}
