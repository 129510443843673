import { UInputCitiesCityLocation, UThreeDotsPopoverItem } from '@shift/ulib';

import { ApiBaseResponse } from '@app/shared/models';
import { RouteLockState } from '@app/route-lock-state/models';
import { ActivityLive } from '@app/activities/models';
import { RouteDirection, RouteSaveStatus } from '@app/routes/models';

export enum RoutesWeeklyFilterType {
  Driver = 'driver',
  AccompanyName = 'accompanyName'
}

export interface RoutesWeeklyFilters {
  type: RoutesWeeklyFilterType;
}

export interface RoutesWeeklyParams {
  periodEnd: string;
  periodStart: string;
  direction: RouteDirection;
  customerId?: number;
  masterSubCustomerIds?: number[];
  memberId?: number;
  isNextYear?: boolean;
  weeklyTableMode?: number;
  searchText?: string;
  schoolIds?: number[];
  classTypeIds?: number[];
  passengerIds?: number[];
  departmentIds?: number[];
  shiftIds?: number[];
  routeIds?: number[];
  tagIds?: number[];
  branchIds?: number[];
  cities?: UInputCitiesCityLocation[];
}

export interface RoutesWeekly extends ApiBaseResponse {
  value: RouteWeekly[];
}

export interface RouteWeeklyRide {
  accompany: {
    value: number;
    name: string;
  };
  date: string;
  driver: {
    approvals: string;
    value: number;
    name: string;
  };
  endDateTime: string;
  isCancelled: boolean;
  isSelfShuttleCompany: boolean;
  needAccompany: boolean;
  plannedAccompany: {
    value: number;
    name: string;
  };
  plannedEndDateTime: string;
  plannedNeedAccompany: boolean;
  plannedStartDateTime: string;
  plannedTotalPassengers: number;
  requiredRecalculation: boolean;
  rideId: number;
  shuttleCompany: {
    hasYit: boolean;
    value: number;
    name: string;
  };
  startDateTime: string;
  totalPassengers: number;
  totalPassengerCustomers: number;
}

export interface RouteWeekly {
  activities: ActivityLive[];
  code: number;
  direction: RouteDirection;
  endDate: string;
  id: number;
  name: string;
  startDate: string;
  lockDateTime: string;
  locked: boolean;
  lockedByUserId: number;
  rides: RouteWeeklyRide[];
  routeCustomerId: number;
  isOwnedBySc: boolean;
}

export interface RouteWeeklyRow extends RouteWeekly {
  saveStatus: RouteSaveStatus;
  startDateTime?: string;
  endDateTime?: string;
  shuttleCompany?: string;
  threeDotsItems: UThreeDotsPopoverItem[];
  hideActions: boolean;
  days: number[];
  totalPassengers: number;
  totalPassengerCustomers: number;
  lockState: RouteLockState;
}
