<u-grid-header
  [config]="{
    buttons: buttons,
    showButtons: showButtons,
    showCounters: showCounters,
    filteredColumns: filteredColumns,
    selected: selected,
    showSelected: showSelected,
    total: total,
    attentionMessage: attentionMessage,
    dictionary: dictionary
  }"
  (buttonClick)="onButtonClick($event)"
></u-grid-header>