import { inject, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { BulkChangeRoute } from '@app/bulk-change/models';
import { BulkChangeComponent } from '@app/bulk-change/components';

@Injectable({
  providedIn: 'root'
})
export class BulkChangeModalService {
  private readonly bsModalService = inject(BsModalService);

  openModal(routes: BulkChangeRoute[]) {
    return this.bsModalService.show(
      BulkChangeComponent,
      {
        class: 'u-modal u-modal_app-bulk-change',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          routes
        }
      }
    );
  }
}
