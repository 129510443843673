import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, first, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UInputCitiesCity, UInputCitiesCityArea, UPopupService, USideModalButton, USideModalConfig, UInputCitiesCityLocationType, UInputCitiesCityLocation, UInputCitiesItem, UPopoverDirective } from '@shift/ulib';

import {
  CitiesCombinationsDataService,
  LocalizationService,
  TrackingService
} from '@app/shared/services';
import {
  CityCombinationsButtonId,
  CityCombinationsRule,
  CityCombinationsRuleType,
  CityCombinationsButton,
  GeneralButtonProp,
  NavigationPaths
} from '@app/shared/models';
import { environment } from '@environments/environment';
import { AuthDataService } from '@app/auth/services';
import { citiesCombinationsComponentConfig } from './cities-combinations.component.config';

@Component({
  selector: 'app-cities-combinations',
  templateUrl: './cities-combinations.component.html',
  styleUrls: [ './cities-combinations.component.scss', './cities-combinations.component.rtl.scss' ]
})
export class CitiesCombinationsComponent implements OnInit, OnDestroy {
  @Input() cities: any[] = [];
  @Input() routePolicyForm: UntypedFormGroup;
  @Input() useTollRoads: boolean;
  @Input() cityCombinationRulesForm: UntypedFormControl;
  @Input() showExplanation: boolean;
  @Input() showGoToSettings: boolean;
  @Input() showAddButton: boolean = true;
  @Input() showDeleteButton: boolean = true;
  @Input() buttons: CityCombinationsButton[];
  @Input() defaultBranchId: number = 0;

  @Output() buttonClick: EventEmitter<{ button: CityCombinationsButton; popover: UPopoverDirective; }> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'cities-combinations';

  @ViewChild('explanationModalBody', { static: true }) explanationModalBody: TemplateRef<any>;

  private unsubscribe: Subject<void> = new Subject();

  cityCombinationRulesFormStore: UntypedFormArray;
  deleteRuleConfirmation: string = '';
  ruleType = CityCombinationsRuleType;
  explanationModalConfig: USideModalConfig;
  isRtl = this.localizationService.isRtl();
  config = cloneDeep(citiesCombinationsComponentConfig);

  filteredBaseCities: { [key: string]: { [key: string]: UInputCitiesItem[]; }; } = {
    [CityCombinationsRuleType.FixedGroup]: {},
    [CityCombinationsRuleType.Combine]: {},
    [CityCombinationsRuleType.DontCombine]: {}
  };

  filteredAttachedCities: { [key: string]: { [key: string]: UInputCitiesItem[]; }; } = {
    [CityCombinationsRuleType.DontCombine]: {},
    [CityCombinationsRuleType.Combine]: {}
  };

  selectedBaseCities: { [key: string]: { [key: string]: UInputCitiesCityLocation[]; }; } = {
    [CityCombinationsRuleType.FixedGroup]: {},
    [CityCombinationsRuleType.Combine]: {},
    [CityCombinationsRuleType.DontCombine]: {}
  };

  selectedAttachedCities: { [key: string]: { [key: string]: UInputCitiesCityLocation[]; }; } = {
    [CityCombinationsRuleType.DontCombine]: {},
    [CityCombinationsRuleType.Combine]: {}
  };

  unavailableAttachedCities: { [key: string]: { [key: string]: UInputCitiesCityLocation[] }; } = {
    [CityCombinationsRuleType.DontCombine]: {},
    [CityCombinationsRuleType.Combine]: {}
  };
  allCitiesAreAssociated: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private uPopupService: UPopupService,
    private localizationService: LocalizationService,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private citiesCombinationsDataService: CitiesCombinationsDataService,
    private authDataService: AuthDataService
  ) {}

  ngOnInit() {
    this.initCityCombinationRulesFormStore();
    this.getTranslation();
    this.onFormValueChanges();
    this.initExplanationModal();
    this.checkAssociatedCities();
    this.updateConfigButtons();
    this.onCityCombinationRulesFormValueChanges();

    if (!this.showAddButton) {
      this.updateConfigButton(GeneralButtonProp.Hidden, true, CityCombinationsButtonId.NewRule);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private onCityCombinationRulesFormValueChanges() {
    this.cityCombinationRulesForm.valueChanges
      .pipe(
        startWith<[], []>(this.cityCombinationRulesForm.value),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next)),
        map(([ , next ]) => next),
        filter(data => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe(value => {
        const validRules = this.cityCombinationRulesFormStore.value.filter(this.ruleValid);

        if (!isEqual(validRules, value)) {
          this.resetData();
          this.initCityCombinationRulesFormStore();
          this.onFormValueChanges();
          this.checkAssociatedCities();
        }
      });
  }

  private ruleValid(rule: CityCombinationsRule): boolean {
    return !!(rule.type === CityCombinationsRuleType.FixedGroup ? rule.baseCities?.length :
      rule.baseCities?.length && rule.attachedCities?.length);
  }

  private resetData() {
    this.filteredBaseCities = {
      [CityCombinationsRuleType.FixedGroup]: {},
      [CityCombinationsRuleType.Combine]: {},
      [CityCombinationsRuleType.DontCombine]: {}
    };

    this.filteredAttachedCities = {
      [CityCombinationsRuleType.DontCombine]: {},
      [CityCombinationsRuleType.Combine]: {}
    };

    this.selectedBaseCities = {
      [CityCombinationsRuleType.FixedGroup]: {},
      [CityCombinationsRuleType.Combine]: {},
      [CityCombinationsRuleType.DontCombine]: {}
    };

    this.selectedAttachedCities = {
      [CityCombinationsRuleType.DontCombine]: {},
      [CityCombinationsRuleType.Combine]: {}
    };

    this.unavailableAttachedCities = {
      [CityCombinationsRuleType.DontCombine]: {},
      [CityCombinationsRuleType.Combine]: {}
    };
  }

  private updateConfigButtons() {
    if (this.buttons?.length) {
      this.config.buttons = [
        ...this.config.buttons,
        ...this.buttons
      ];
    }
  }

  private updateConfigButton(key: GeneralButtonProp, value?: boolean | string, id?: CityCombinationsButtonId) {
    this.config.buttons = this.config.buttons.map(button => !id || button.id === id ? ({
      ...button,
      [key]: value
    }) : button);
  }

  private getTranslation() {
    this.translateService.get(this.config.dictionary.deleteRuleConfirmation)
      .pipe(first())
      .subscribe((translated: string) => this.deleteRuleConfirmation = translated);
  }

  private initCityCombinationRulesFormStore() {
    this.cityCombinationRulesFormStore = this.fb.array(
      this.cityCombinationRulesForm.value.map(rule => this.citiesCombinationsDataService.generateCitiesCombinationsRule(rule))
    );

    this.cityCombinationRulesFormStore.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(rules => {
        if (!this.cityCombinationRulesForm.dirty) {
          this.cityCombinationRulesForm.markAsDirty();
        }

        this.cityCombinationRulesForm.patchValue(rules.filter(this.ruleValid));
      });

    if (this.cityCombinationRulesForm.disabled) {
      this.cityCombinationRulesFormStore.disable({ emitEvent: false });
    }
  }

  private initExplanationModal() {
    if (this.showExplanation) {
      const userInfo = this.authDataService.userInfo();
      const explanationModalByCustomerType = this.config.explanationModal[userInfo.customer.type] || this.config.explanationModal.default;

      this.explanationModalConfig = explanationModalByCustomerType[environment.config.environmentType] || explanationModalByCustomerType.default;

      this.explanationModalConfig.modal.bodyTemplate = this.explanationModalBody;

      if (!this.cityCombinationRulesFormStore.value.length) {
        this.explanationModalConfig.initiallyOpen = true;
      }
    }
  }

  private onFormValueChanges() {
    this.cityCombinationRulesFormStore.controls.forEach((ruleForm: UntypedFormGroup) => {
      ruleForm.get('type').valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((value: CityCombinationsRuleType) => this.onRuleTypeChange(value));

      ruleForm.get('baseCities').valueChanges
        .pipe(
          takeUntil(this.unsubscribe),
          startWith<null, null>(null),
          pairwise(),
          filter(([ prev, next ]) => !isEqual(prev, next)),
          map(([ , next ]) => next),
          filter(data => !!data)
        )
        .subscribe(() => {
          this.setUnavailableAttachedCities();
          this.checkAssociatedCities();
          this.checkIfBaseCityIsAttachedInOtherForm(ruleForm, ruleForm.get('type').value);
        });

      ruleForm.get('attachedCities').valueChanges
        .pipe(
          takeUntil(this.unsubscribe),
          startWith<null, null>(null),
          pairwise(),
          filter(([ prev, next ]) => !isEqual(prev, next)),
          map(([ , next ]) => next),
          filter(data => !!data)
        )
        .subscribe(() => {
          this.setUnavailableAttachedCities();
          this.checkAssociatedCities();
          this.checkIfAttachedCityIsBaseInOtherForm(ruleForm);
        });
    });
  }

  private checkAssociatedCities() {
    const allSelectedCityValues = this.cityCombinationRulesFormStore.controls.reduce(
      (acc, form: UntypedFormGroup) => [ ...acc, ...(form.get('baseCities').value || []) ], []
    );

    this.allCitiesAreAssociated = this.cities.every(availableCity => allSelectedCityValues.some(
      (selectedCity: UInputCitiesCityLocation) => selectedCity && selectedCity.cityId === availableCity.cityId && !selectedCity.areaId)
    );

    if (this.showAddButton) {
      this.updateConfigButton(GeneralButtonProp.Disabled, this.allCitiesAreAssociated, CityCombinationsButtonId.NewRule);
    }
  }

  private clearSelectedCities() {
    this.selectedBaseCities = {
      [CityCombinationsRuleType.Combine]: {},
      [CityCombinationsRuleType.DontCombine]: {},
      [CityCombinationsRuleType.FixedGroup]: {}
    };

    this.selectedAttachedCities = {
      [CityCombinationsRuleType.DontCombine]: {},
      [CityCombinationsRuleType.Combine]: {}
    };
  }

  private updateSelectedBaseCities(ruleType: CityCombinationsRuleType, value: UInputCitiesCityLocation[], index: number) {
    this.selectedBaseCities[ruleType][index] = [ ...(this.selectedBaseCities[ruleType][index] || []), ...(Array.isArray(value) ? value : [ value ]) ];
  }

  private updateSelectedAttachedCities(ruleType: CityCombinationsRuleType, value: UInputCitiesCityLocation[], index: number) {
    this.selectedAttachedCities[ruleType][index] = [ ...(this.selectedBaseCities[ruleType][index] || []), ...(Array.isArray(value) ? value : [ value ]) ];
  }

  private updateSelectedCities() {
    this.clearSelectedCities();

    const rulesLength = this.cityCombinationRulesFormStore.value.length;

    for (let i = 0; i < rulesLength; i++) {
      const ruleForm = this.cityCombinationRulesFormStore.value[i];
      const baseCityValue: UInputCitiesCityLocation[] = ruleForm.baseCities;

      if (baseCityValue) {
        const type = ruleForm.type;

        this.updateSelectedBaseCities(CityCombinationsRuleType.FixedGroup, baseCityValue, i);
        this.updateSelectedBaseCities(CityCombinationsRuleType.Combine, baseCityValue, i);
        this.updateSelectedBaseCities(CityCombinationsRuleType.DontCombine, baseCityValue, i);

        if (type === CityCombinationsRuleType.Combine) {
          this.updateSelectedBaseCities(CityCombinationsRuleType.FixedGroup, ruleForm.attachedCities, i);
        }

        if (type === CityCombinationsRuleType.FixedGroup) {
          this.updateSelectedAttachedCities(CityCombinationsRuleType.DontCombine, baseCityValue, i);
          this.updateSelectedAttachedCities(CityCombinationsRuleType.Combine, baseCityValue, i);
        }
      }
    }
  }

  private getBaseCityFromFormById(ruleForm: UntypedFormGroup, cityId: number): boolean {
    const baseCities: UInputCitiesCityLocation[] = Array.isArray(ruleForm.get('baseCities').value) ? ruleForm.get('baseCities').value : [ ruleForm.get('baseCities').value ];

    return baseCities.some((baseCity: UInputCitiesCityLocation) => baseCity && baseCity.cityId === cityId);
  }

  private cityAttachedInThisForm(ruleForm: UntypedFormGroup, city: UInputCitiesItem): boolean {
    const attachedCities = this.cityCombinationRulesFormStore.controls[this.cityCombinationRulesFormStore.controls.indexOf(ruleForm)].get('attachedCities').value;

    if (!attachedCities?.length) { return false; }

    return this.cityCombinationRulesFormStore.controls[this.cityCombinationRulesFormStore.controls.indexOf(ruleForm)]
      .get('attachedCities').value.some((attachedCity: UInputCitiesCityLocation) =>
        attachedCity.cityId === city.cityId && city.branches.every(branch => !branch.areas)
      );
  }

  private getExcludedAttachedCity(ruleForm: UntypedFormGroup): UInputCitiesCityLocation {
    let excludedCityValue = null;
    const baseCities = ruleForm.get('baseCities').value;
    const baseCitiesRuleForm: UInputCitiesCityLocation = baseCities?.length ? baseCities[0] : null;
    const type = ruleForm.get('type').value;

    for (const form of this.cityCombinationRulesFormStore.controls) {
      const sameControl = this.cityCombinationRulesFormStore.controls.indexOf(form) === this.cityCombinationRulesFormStore.controls.indexOf(ruleForm);

      if (!sameControl) {
        const sameType = form.get('type').value === type;

        if (!sameType) {
          const attachedCities = form.get('attachedCities').value || [];
          const attachedCityIsBase = attachedCities.some((attachedCity: UInputCitiesCityLocation) =>
            baseCitiesRuleForm && attachedCity.cityId === baseCitiesRuleForm.cityId && attachedCity.type === baseCitiesRuleForm.type
          );

          if (attachedCityIsBase) {
            excludedCityValue = form.get('baseCities').value[0];

            break;
          }
        }
      }
    }

    return excludedCityValue;
  }

  private setUnavailableAttachedCities() {
    this.cityCombinationRulesFormStore.controls.forEach((form: UntypedFormGroup) => {
      if (form.get('type').value && form.get('type').value !== CityCombinationsRuleType.FixedGroup) {
        let unavailableAttachedCities: UInputCitiesCityLocation[] = [];

        this.cityCombinationRulesFormStore.controls.forEach((ruleForm: UntypedFormGroup) => {
          const baseCities: UInputCitiesCityLocation = Array.isArray(ruleForm.get('baseCities').value) && ruleForm.get('baseCities').value.length ? ruleForm.get('baseCities').value[0] : null;
          const attachedCityThatIsBaseCity: UInputCitiesCityLocation = form.get('attachedCities').value && form.get('attachedCities').value.length &&
            form.get('attachedCities').value.find((attachedCity: UInputCitiesCityLocation) => {
              if (!baseCities || !attachedCity) { return false; }

              if (baseCities.areaId && attachedCity.areaId) {
                return attachedCity.cityId === baseCities.cityId && attachedCity.areaId === baseCities.areaId;
              }

              return attachedCity.cityId === baseCities.cityId && attachedCity.type === baseCities.type;
            });

          if (attachedCityThatIsBaseCity && form.get('type').value === ruleForm.get('type').value) {
            unavailableAttachedCities = [ ...unavailableAttachedCities, attachedCityThatIsBaseCity ];
          }
        });

        if (this.unavailableAttachedCities[form.get('type').value][this.cityCombinationRulesFormStore.controls.indexOf(form)]) {
          this.unavailableAttachedCities[form.get('type').value][this.cityCombinationRulesFormStore.controls.indexOf(form)] =
            [ ...this.unavailableAttachedCities[form.get('type').value][this.cityCombinationRulesFormStore.controls.indexOf(form)], ...unavailableAttachedCities ];
        } else {
          this.unavailableAttachedCities[form.get('type').value][this.cityCombinationRulesFormStore.controls.indexOf(form)] =
            [ ...unavailableAttachedCities ];
        }
      }
    });
  }

  private clearUnavailableCities(ruleForm: UntypedFormGroup) {
    const ruleType = ruleForm.get('type').value;
    const ruleFormIndex = this.cityCombinationRulesFormStore.controls.indexOf(ruleForm);

    if (this.unavailableAttachedCities[ruleType] && this.unavailableAttachedCities[ruleType][ruleFormIndex]) {
      this.unavailableAttachedCities[ruleType][ruleFormIndex] = [];
    }
  }

  private getSelectedBaseCitiesExceptCurrentForm(ruleType: CityCombinationsRuleType, indexToExcept: number, cityId: number): UInputCitiesCityLocation[] {
    if (!this.selectedBaseCities[ruleType]) { return []; }

    return Object.entries(this.selectedBaseCities[ruleType])
      .reduce((acc, [ key, value ]) => {
        if (+key !== indexToExcept) {
          return [ ...acc, ...value.filter((baseCity: UInputCitiesCityLocation) => baseCity?.cityId === cityId) ];
        }

        return acc;
      }, []);
  }

  private getSelectedAttachedCitiesExceptCurrentForm(ruleType: CityCombinationsRuleType, indexToExcept: number, cityId: number): UInputCitiesCityLocation[] {
    if (!this.selectedAttachedCities[ruleType]) { return []; }

    return Object.entries(this.selectedAttachedCities[ruleType])
      .reduce((acc, [ key, value ]) => {
        if (+key !== indexToExcept) {
          return [ ...acc, ...value.filter((attachedCity: UInputCitiesCityLocation) => attachedCity?.cityId === cityId) ];
        }

        return acc;
      }, []);
  }

  private updateFilteredBaseCities({
    ruleForm,
    ruleType,
    ruleFormIndex,
    city
  }: {
    ruleForm: UntypedFormGroup;
    ruleType: CityCombinationsRuleType;
    ruleFormIndex: number;
    city: any;
  }) {
    const otherFormBaseCities: UInputCitiesCityLocation[] = this.getSelectedBaseCitiesExceptCurrentForm(ruleType, ruleFormIndex, city.cityId);
    const otherFormBaseCityHasAreaId = otherFormBaseCities?.length &&
      (otherFormBaseCities[0].areaId || otherFormBaseCities[0].type === UInputCitiesCityLocationType.RestOfCity);
    const updatedCity: UInputCitiesItem = {
      cityId: city.cityId,
      name: city.name,
      branches: city.branches?.map(branch => ({
        ...branch,
        areas: branch.areas?.length ? [ ...branch.areas ] : null
      }))
    };

    if (otherFormBaseCityHasAreaId) {
      const areaIds = otherFormBaseCities.reduce((acc, otherFormCity) => {
        if (otherFormCity.areaId && otherFormCity.cityId === updatedCity.cityId) {
          return [ ...acc, otherFormCity.areaId ];
        }

        return acc;
      }, []);

      if (areaIds.length) {
        updatedCity.noAll = true;
        updatedCity.branches = updatedCity.branches?.map(branch => ({
          ...branch,
          areas: branch.areas?.length ? branch.areas.filter(area => !areaIds.includes(area.areaId)) : null
        }));
      }

      const isRestOfTheCityInOtherForm = this.cityCombinationRulesFormStore.controls.some((form: UntypedFormGroup) => {
        const baseCities = form.get('baseCities').value;
        const sameControl = ruleFormIndex === this.cityCombinationRulesFormStore.controls.indexOf(form);

        return !sameControl &&
        baseCities?.some((baseCity: UInputCitiesCityLocation) =>
          baseCity.cityId === city.cityId && baseCity.type === UInputCitiesCityLocationType.RestOfCity
        );
      });

      if (isRestOfTheCityInOtherForm) {
        updatedCity.noAll = true;
        updatedCity.noRest = true;
      }
    }

    if (this.checkBaseCityAvailability(ruleFormIndex, updatedCity, ruleForm, ruleType)) {
      this.filteredBaseCities = {
        ...this.filteredBaseCities,
        [ruleType]: {
          ...(this.filteredBaseCities[ruleType] || {}),
          [ruleFormIndex]: [
            ...(this.filteredBaseCities[ruleType]?.[ruleFormIndex] || []),
            updatedCity
          ]
        }
      };
    }
  }

  private checkBaseCityAvailability(ruleFormIndex: number, city: UInputCitiesCity, ruleForm: UntypedFormGroup, ruleType: CityCombinationsRuleType) {
    const otherFormBaseCities: UInputCitiesCityLocation[] = this.getSelectedBaseCitiesExceptCurrentForm(ruleType, ruleFormIndex, city.cityId);

    if (
      otherFormBaseCities && otherFormBaseCities.length &&
      (otherFormBaseCities[0].areaId || otherFormBaseCities[0].type === UInputCitiesCityLocationType.RestOfCity)
    ) {
      const isRestOfCityInOtherBaseField = this.cityCombinationRulesFormStore.controls.some((form: UntypedFormGroup) => {
        if (ruleFormIndex === this.cityCombinationRulesFormStore.controls.indexOf(form)) { return false; }

        return form.get('baseCities').value && form.get('baseCities').value.length &&
          form.get('baseCities').value.some(
            (baseCity: UInputCitiesCityLocation) => baseCity.type === UInputCitiesCityLocationType.RestOfCity && baseCity.cityId === city.cityId
          );
      });

      const withAreas = city.branches?.every(branch => branch.areas?.length);

      return withAreas || !isRestOfCityInOtherBaseField;
    }

    return !this.cityAttachedInThisForm(ruleForm, city) &&
      (this.getBaseCityFromFormById(ruleForm, city.cityId) || !otherFormBaseCities?.length);
  }

  private updateFilteredAttachedCities({
    ruleForm,
    ruleType,
    ruleFormIndex,
    city,
    baseCities,
    excludedAttachedCity
  }: {
    ruleForm: UntypedFormGroup;
    ruleType: CityCombinationsRuleType;
    ruleFormIndex: number;
    city: any;
    baseCities: UInputCitiesCityLocation;
    excludedAttachedCity: UInputCitiesCityLocation;
  }) {
    const unavailableAttachedCities = this.unavailableAttachedCities[ruleType]?.[ruleFormIndex];
    const unavailableAttachedCity = unavailableAttachedCities?.find(unavailableCity => unavailableCity.cityId === city.cityId);
    const otherFormAttachedCities = this.getSelectedAttachedCitiesExceptCurrentForm(ruleType, ruleFormIndex, city.cityId);
    let updatedCity: UInputCitiesItem = {
      cityId: city.cityId,
      name: city.name,
      branches: city.branches?.map(branch => ({
        ...branch,
        areas: branch.areas?.length ? [ ...branch.areas ] : null
      }))
    };

    if (unavailableAttachedCity) {
      updatedCity = {
        ...updatedCity,
        branches: !!unavailableAttachedCity.areaId ? updatedCity.branches?.map(branch => ({
          ...branch,
          areas: branch.areas?.map(area => ({
            ...area,
            notAvailable: unavailableAttachedCity.areaId === area.areaId
          }))
        })) : updatedCity.branches,
        notAvailable: unavailableAttachedCity.type === UInputCitiesCityLocationType.AllCity,
        notAvailableRest: unavailableAttachedCity.type === UInputCitiesCityLocationType.RestOfCity
      };
    }

    const cityHasAreaId = baseCities && baseCities.areaId && city.cityId === baseCities.cityId;

    if (cityHasAreaId) {
      updatedCity.branches = updatedCity.branches?.map(branch => ({
        ...branch,
        areas: branch.areas?.filter((area: UInputCitiesCityArea) => {
          const otherFormAttachedCityAreaId = otherFormAttachedCities.some(otherFormAttachedCity => otherFormAttachedCity.areaId === area.areaId);

          return excludedAttachedCity?.areaId && otherFormAttachedCities?.length ?
            area.areaId !== baseCities.areaId && area.areaId !== excludedAttachedCity.areaId && !otherFormAttachedCityAreaId :
            excludedAttachedCity?.areaId ? area.areaId !== baseCities.areaId && area.areaId !== excludedAttachedCity.areaId :
              otherFormAttachedCities?.length ? area.areaId !== baseCities.areaId && !otherFormAttachedCityAreaId : area.areaId !== baseCities.areaId;
        })
      }));

      updatedCity.noAll = true;

      const otherFormAttachedCityIsRestOfCity = otherFormAttachedCities?.some(otherFormAttachedCity =>
        otherFormAttachedCity.type === UInputCitiesCityLocationType.RestOfCity
      );

      if (excludedAttachedCity?.type === UInputCitiesCityLocationType.RestOfCity || otherFormAttachedCityIsRestOfCity) {
        updatedCity.noRest = true;
      }

      const areasEmpty = updatedCity.branches?.every(branch => branch.areas && !branch.areas.length);

      if (!(updatedCity.noAll && updatedCity.noRest && areasEmpty)) {
        this.filteredAttachedCities[ruleType][ruleFormIndex] = [
          ...(this.filteredAttachedCities[ruleType] && this.filteredAttachedCities[ruleType][ruleFormIndex] || []),
          updatedCity
        ];
      }

      return;
    }

    const ruleFormBaseCitiesValue = ruleForm.get('baseCities').value;
    const isRestOfCity = ruleFormBaseCitiesValue?.[0]?.cityId === updatedCity.cityId &&
      ruleFormBaseCitiesValue?.[0]?.type === UInputCitiesCityLocationType.RestOfCity;

    if (isRestOfCity) {
      updatedCity.branches = updatedCity.branches?.map(branch => ({
        ...branch,
        areas: (excludedAttachedCity?.areaId || otherFormAttachedCities?.length) ?
          branch.areas.filter((area: UInputCitiesCityArea) => {
            const otherFormAttachedCityAreaId = otherFormAttachedCities.some(otherFormAttachedCity => otherFormAttachedCity.areaId === area.areaId);

            return excludedAttachedCity?.areaId && otherFormAttachedCities?.length ?
              area.areaId !== baseCities.areaId && area.areaId !== excludedAttachedCity.areaId && !otherFormAttachedCityAreaId :
              excludedAttachedCity?.areaId ? area.areaId !== baseCities.areaId && area.areaId !== excludedAttachedCity.areaId :
                otherFormAttachedCities?.length ? !otherFormAttachedCityAreaId :
                  excludedAttachedCity ? excludedAttachedCity.type === UInputCitiesCityLocationType.RestOfCity : area;
          }) : branch.areas
      }));

      updatedCity.noRest = true;
      updatedCity.noAll = true;

      const areasEmpty = updatedCity.branches?.every(branch => branch.areas && !branch.areas.length);

      if (!(updatedCity.noAll && updatedCity.noRest && areasEmpty)) {
        this.filteredAttachedCities[ruleType][ruleFormIndex] = [
          ...(this.filteredAttachedCities[ruleType] && this.filteredAttachedCities[ruleType][ruleFormIndex] || []),
          updatedCity
        ];
      }

      return;
    }

    const cityIsAttachedInThisForm = ruleForm.get('attachedCities').value.some((attachedCity: UInputCitiesCityLocation) => attachedCity.cityId === updatedCity.cityId);

    if (
      baseCities?.cityId !== updatedCity.cityId &&
      ((!otherFormAttachedCities?.length || otherFormAttachedCities.some(otherFormAttachedCity => !!otherFormAttachedCity.areaId)) || cityIsAttachedInThisForm) &&
      (!excludedAttachedCity || updatedCity.cityId !== excludedAttachedCity.cityId || excludedAttachedCity.type !== UInputCitiesCityLocationType.AllCity)
    ) {
      const withAreas = updatedCity.branches?.some(branch => branch.areas);

      if (
        ((excludedAttachedCity?.areaId) ||
        (otherFormAttachedCities?.some(otherFormAttachedCity => !!otherFormAttachedCity.areaId))) &&
        withAreas
      ) {
        updatedCity.branches = updatedCity.branches?.map(branch => ({
          ...branch,
          areas: branch.areas.filter(area => {
            const otherFormAttachedCityAreaId = otherFormAttachedCities.some(otherFormAttachedCity => otherFormAttachedCity.areaId === area.areaId);

            return excludedAttachedCity && otherFormAttachedCities?.length ?
              area.areaId !== excludedAttachedCity.areaId && !otherFormAttachedCityAreaId :
              excludedAttachedCity ? area.areaId !== excludedAttachedCity.areaId :
                otherFormAttachedCities?.length ? !otherFormAttachedCityAreaId : area;
          })
        }));

        updatedCity.noAll = true;
      }

      if (
        excludedAttachedCity?.type === UInputCitiesCityLocationType.RestOfCity ||
        (
          otherFormAttachedCities?.some(otherFormAttachedCity => otherFormAttachedCity.type === UInputCitiesCityLocationType.RestOfCity)
        )
      ) {
        updatedCity.noAll = true;
        updatedCity.noRest = true;
      }

      const areasEmpty = updatedCity.branches?.every(branch => branch.areas && !branch.areas.length);

      if (!(updatedCity.noAll && updatedCity.noRest && areasEmpty)) {
        this.filteredAttachedCities = {
          ...this.filteredAttachedCities,
          [ruleType]: {
            ...(this.filteredAttachedCities[ruleType] || {}),
            [ruleFormIndex]: [
              ...(this.filteredAttachedCities[ruleType]?.[ruleFormIndex] || []),
              updatedCity
            ]
          }
        };
      }
    }
  }

  filterBaseCities(ruleForm: UntypedFormGroup, ruleType: CityCombinationsRuleType, updateSelected?: boolean) {
    if (updateSelected) {
      this.updateSelectedCities();
    }

    const ruleFormIndex = this.cityCombinationRulesFormStore.controls.indexOf(ruleForm);

    this.filteredBaseCities[ruleType][ruleFormIndex] = [];

    for (const city of this.cities) {
      this.updateFilteredBaseCities({ ruleForm, ruleType, ruleFormIndex, city });
    }
  }

  filterAttachedCities(ruleForm: UntypedFormGroup, ruleType: CityCombinationsRuleType, updateSelected?: boolean) {
    if (updateSelected) {
      this.updateSelectedCities();
    }

    this.clearUnavailableCities(ruleForm);
    this.setUnavailableAttachedCities();

    const ruleFormIndex = this.cityCombinationRulesFormStore.controls.indexOf(ruleForm);
    const baseCitiesValue = this.cityCombinationRulesFormStore.controls[ruleFormIndex].get('baseCities').value;
    const baseCities: UInputCitiesCityLocation = baseCitiesValue.length ? baseCitiesValue[0] : baseCitiesValue;
    const excludedAttachedCity = this.getExcludedAttachedCity(ruleForm);

    this.filteredAttachedCities[ruleType][ruleFormIndex] = [];

    for (const city of this.cities) {
      if (ruleType !== CityCombinationsRuleType.FixedGroup) {
        this.updateFilteredAttachedCities({ ruleForm, ruleType, ruleFormIndex, city, baseCities, excludedAttachedCity });
      }
    }
  }

  private checkIfBaseCityIsAttachedInOtherForm(ruleForm: UntypedFormGroup, ruleType: CityCombinationsRuleType) {
    if (ruleType === CityCombinationsRuleType.FixedGroup) { return; }

    const baseCities: UInputCitiesCityLocation = Array.isArray(ruleForm.get('baseCities').value) ? ruleForm.get('baseCities').value[0] : ruleForm.get('baseCities').value;
    let baseCitiesFromMatchedForm: UInputCitiesCityLocation[] = [];

    this.cityCombinationRulesFormStore.controls.forEach((form: UntypedFormGroup) => {
      const attachedCityIsBaseCity = Array.isArray(form.get('attachedCities').value) &&
        form.get('attachedCities').value.some((attachedCity: UInputCitiesCityLocation) => {
          if (!baseCities || !attachedCity) { return false; }

          if (baseCities.areaId && attachedCity.cityId) {
            return attachedCity.cityId === baseCities.cityId && baseCities.areaId === attachedCity.areaId;
          }

          return attachedCity.cityId === baseCities.cityId && attachedCity.type === baseCities.type;
        });

      const isNotTheSameForms = this.cityCombinationRulesFormStore.controls.indexOf(ruleForm) !== this.cityCombinationRulesFormStore.controls.indexOf(form);

      if (attachedCityIsBaseCity && isNotTheSameForms && ruleForm.get('type').value === form.get('type').value) {
        baseCitiesFromMatchedForm = [ ...baseCitiesFromMatchedForm, form.get('baseCities').value[0] ];
      }
    });

    if (baseCitiesFromMatchedForm) {
      this.filterAttachedCities(ruleForm, ruleForm.get('type').value, true);
      ruleForm.get('attachedCities').patchValue([ ...baseCitiesFromMatchedForm ]);

      return;
    }

    ruleForm.get('attachedCities').patchValue([]);
  }

  private checkIfAttachedCityIsBaseInOtherForm(ruleForm: UntypedFormGroup) {
    const attachedCities: UInputCitiesCityLocation[] = ruleForm.get('attachedCities').value;
    const ruleFormIndex = this.cityCombinationRulesFormStore.controls.indexOf(ruleForm);
    const ruleFormType = ruleForm.get('type').value;

    for (const form of this.cityCombinationRulesFormStore.controls) {
      const isAttachedCityBaseInOtherForm = attachedCities && attachedCities.some((attachedCity: UInputCitiesCityLocation) => {
        if (!attachedCity || !Array.isArray(form.get('baseCities').value)) { return false; }

        if (form.get('baseCities').value.length && attachedCity.areaId && form.get('baseCities').value[0].areaId) {
          return attachedCity.cityId === form.get('baseCities').value[0].cityId && attachedCity.areaId === form.get('baseCities').value[0].areaId;
        }

        return form.get('baseCities').value.length && attachedCity.cityId === form.get('baseCities').value[0].cityId &&
          attachedCity.type === form.get('baseCities').value[0].type;
      });

      const isNotTheSameForms = this.cityCombinationRulesFormStore.controls.indexOf(ruleForm) !== this.cityCombinationRulesFormStore.controls.indexOf(form);

      if (isAttachedCityBaseInOtherForm && isNotTheSameForms && ruleForm.get('type').value === form.get('type').value) {
        const hasAttachedCity = form.get('attachedCities').value.some((attachedCity: UInputCitiesCityLocation) =>
          ruleForm.get('baseCities').value.length &&
          attachedCity.cityId === ruleForm.get('baseCities').value[0].cityId &&
          attachedCity.type === ruleForm.get('baseCities').value[0].type &&
          attachedCity.areaId === ruleForm.get('baseCities').value[0].areaId
        );

        if (!hasAttachedCity) {
          form.get('attachedCities').patchValue([ ...form.get('attachedCities').value, ruleForm.get('baseCities').value[0] ]);
        }

        if (this.filteredAttachedCities[ruleFormType] && this.filteredAttachedCities[ruleFormType][ruleFormIndex]) {
          this.filteredAttachedCities[ruleFormType][ruleFormIndex] = this.filteredAttachedCities[ruleFormType][ruleFormIndex]
            .map((filteredAttachedCity: any) => {
              if (form.get('baseCities').value[0].type === UInputCitiesCityLocationType.RestOfCity) {
                return {
                  ...filteredAttachedCity,
                  notAvailableRest: true
                };
              }

              const branchesWithAreas = filteredAttachedCity.branches?.filter(branch => branch.areas);

              if (branchesWithAreas.length && form.get('baseCities').value[0].areaId) {
                return {
                  ...filteredAttachedCity,
                  branches: filteredAttachedCity.branches?.map(branch => ({
                    ...branch,
                    areas: branch.areas?.map(area => ({
                      ...area,
                      ...(area.areaId === form.get('baseCities').value[0].areaId && { notAvailable: true })
                    }))
                  }))
                };
              }

              return {
                ...filteredAttachedCity,
                ...(filteredAttachedCity.cityId === form.get('baseCities').value[0].cityId && { notAvailable: true })
              };
            });
        }
      }
    }
  }

  private onRuleTypeChange(value: CityCombinationsRuleType) {
    this.trackingService.track(`[${this.config.trackingId}] - rule row - rule type field - click on ${value}`);
  }

  private deleteUnavailableAttachedCities(ruleType: CityCombinationsRuleType, indexOfDeletedForm: number) {
    if (this.unavailableAttachedCities[ruleType] && this.unavailableAttachedCities[ruleType][indexOfDeletedForm]) {
      delete this.unavailableAttachedCities[ruleType][indexOfDeletedForm];
    }
  }

  private clearRuleFormData(ruleType: CityCombinationsRuleType, indexOfForm: number) {
    if (this.filteredBaseCities[ruleType] && this.filteredBaseCities[ruleType][indexOfForm]) {
      delete this.filteredBaseCities[ruleType][indexOfForm];
    }

    if (this.filteredAttachedCities[ruleType] && this.filteredAttachedCities[ruleType][indexOfForm]) {
      delete this.filteredAttachedCities[ruleType][indexOfForm];
    }
  }

  addRule() {
    this.trackingService.track(`[${this.config.trackingId}] - click on 'add a rule' button`);

    const newRule: CityCombinationsRule = {
      id: -1,
      name: '',
      isActive: true,
      useTollRoads: !!(this.useTollRoads || this.routePolicyForm?.get('useTollRoads')?.value),
      type: '',
      baseCities: [],
      attachedCities: [],
      branchId: this.defaultBranchId
    };

    this.cityCombinationRulesFormStore.push(this.citiesCombinationsDataService.generateCitiesCombinationsRule(newRule));
    this.onFormValueChanges();
  }

  onIsActiveCheckboxClick() {
    this.trackingService.track(`[${this.config.trackingId}] - rule row - click on active/inactive toggle`);
  }

  deleteRule(ruleForm: UntypedFormGroup, ruleName: string) {
    this.trackingService.track(`[${this.config.trackingId}] - rule row - rule type field - click on delete icon`);

    this.uPopupService.showMessage({
      message: this.deleteRuleConfirmation.replace(ruleName ? '{{ruleName}}' : ' \'{{ruleName}}\'', ruleName),
      yes: 'general.delete',
      no: 'general.cancel'
    },
    () => {
      const indexOfForm = this.cityCombinationRulesFormStore.controls.indexOf(ruleForm);
      this.deleteUnavailableAttachedCities(ruleForm.get('type').value, indexOfForm);

      if (indexOfForm >= 0) {
        this.cityCombinationRulesFormStore.removeAt(indexOfForm);
        this.cityCombinationRulesForm.markAsDirty();

        if (this.cityCombinationRulesForm.value[indexOfForm]) {
          this.cityCombinationRulesForm.value.splice(indexOfForm, 1);
        }

        this.clearRuleFormData(ruleForm.get('type').value, indexOfForm);
        this.checkAssociatedCities();
      }
    });
  }

  onSideModalButtonClick() {
    this.trackingService.track(`[${this.config.trackingId}] - ${this.config.explanationModalTrackingId} - click on Explanation button`);
  }

  onExplanationButtonClick(button: USideModalButton) {
    this.trackingService.track(`[${this.config.trackingId}] - ${this.config.explanationModalTrackingId} - click on ${button.id} button`);
  }

  goToSettings() {
    this.router.navigate([ NavigationPaths.Settings ], { queryParams: { activeItem: 'cityCombinationRules' } });
  }

  toggleRulesState(activate: boolean) {
    const rulesLength = this.cityCombinationRulesFormStore.controls.length;

    for (let i = 0; i < rulesLength; i++) {
      const isActiveStore = this.cityCombinationRulesFormStore.controls[i].get('isActive');

      if (isActiveStore.value !== activate) {
        isActiveStore.patchValue(activate);
      }
    }
  }

  onConfigButtonClick = (button: CityCombinationsButton, popover?: UPopoverDirective) => {
    this.buttonClick.emit({ button, popover });

    switch(button.id) {
      case CityCombinationsButtonId.NewRule: {
        this.addRule();

        break;
      }

      case CityCombinationsButtonId.ActivateAll: {
        this.toggleRulesState(true);

        break;
      }

      case CityCombinationsButtonId.InactivateAll: {
        this.toggleRulesState(false);

        break;
      }
    }
  };
}
