import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'movePassengersSearch',
  standalone: true
})
export class MovePassengersSearchPipe implements PipeTransform {
  transform(items: any[], value: string, keys: string[]): any[] {
    if (value && keys) {
      return items.filter(item => {
        const searchValue: string = value.toLowerCase();

        return keys.some(key => {
          if (item[key] === null || item[key] === undefined) {
            return false;
          }

          const itemValue: string = `${item[key]}`.toLowerCase();

          if (key === 'participants' || key === 'passengers') {
            const searchProperties: { [ key: string ]: string[] } = {
              passengers: [ 'name' ],
              participants: [ 'name', 'address' ]
            };

            return item[key].some(searchItem =>
              searchProperties[key].some(searchItemKey => {
                if (searchItem[searchItemKey] === null || searchItem[searchItemKey] === undefined) {
                  return false;
                }

                const searchItemValue: string = `${searchItem[searchItemKey]}`.toLowerCase();

                return searchItemValue.includes(searchValue);
              })
            );
          }

          return itemValue.includes(searchValue);
        });
      });
    }

    return items;
  }
}
