import { Component, OnInit, Output, EventEmitter, OnDestroy, HostBinding } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

import { LocalizationService, ValidationService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { ModalActions } from '@app/shared/models';
import { RoutesDeletePeriod, RoutesViewTypeMode } from '@app/routes/models';
import { deleteRouteComponentConfig } from './delete-route.component.config';

@Component({
  selector: 'app-delete-route',
  templateUrl: './delete-route.component.html',
  styleUrls: [ './delete-route.component.scss', './delete-route.component.rtl.scss' ]
})
export class DeleteRouteComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'delete-route';

  private activeRoute;
  private mode = RoutesViewTypeMode.DailyView;
  private unsubscribe: Subject<void> = new Subject();

  routes: any;
  form: UntypedFormGroup;
  showFromDate = false;
  routesToDeleteWarning: string;
  config = cloneDeep(deleteRouteComponentConfig);
  types = this.config.deletePeriodSelectItems;
  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private fb: UntypedFormBuilder,
    private deleteModalRef: BsModalRef,
    private translateService: TranslateService,
    private headerDataService: HeaderDataService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.initForm();

    if (this.routes) {
      this.updateForm(this.routes);

      this.types = this.routes.length > 1 ? this.config.deletePeriodSelectItemsPlural : this.config.deletePeriodSelectItems;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initForm() {
    this.form = this.fb.group({
      routeIds: [ [] ],
      dateFrom: [ { value: `${moment().format(AppConstants.DATE_FORMAT_BASE_SLASH)}`, disabled: true } ],
      dateTo: [ { value: AppConstants.DATE_FORMAT_BASE_SLASH, disabled: true } ],
      disableDaysBefore: [ null ],
      disableDaysAfter: [ null ],
      changeDateFrom: [ '' ],
      permanently: [ true ],
      type: [ RoutesDeletePeriod.Whole ]
    });

    this.onTypeChange();
  }

  private onTypeChange() {
    this.form.get('type')
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: RoutesDeletePeriod) => {
        if (value === RoutesDeletePeriod.FromDate) {
          this.showFromDate = true;
          this.form.patchValue({
            permanently: false
          });
        } else {
          this.showFromDate = false;
          this.form.patchValue({
            permanently: true
          });
        }
      });
  }

  private updateForm(routes: any) {
    this.form.patchValue({
      changeDateFrom: this.headerDataService.getDate(),
      routeIds: routes
    });

    if (this.activeRoute) {
      if (this.mode === RoutesViewTypeMode.DailyView) {
        this.updateDateRange('routeStartDate', 'routeEndDate');
      }

      if (this.mode === RoutesViewTypeMode.WeeklyView) {
        this.updateDateRange('startDate', 'endDate');
      }
    }

    if (this.routes.length > 1) {
      this.form.addControl('routesAmount', this.fb.control(0, [ ValidationService.equalsTo(this.routes.length) ]));

      this.translateService.get(this.config.dictionary.routesToDeleteWarning)
        .pipe(first())
        .subscribe(translated => this.routesToDeleteWarning = translated.replace('{{amount}}', this.routes.length));
    }
  }

  private updateDateRange(startDate: string, endDate: string): void {
    const dateFrom = moment(this.activeRoute[startDate]).format(AppConstants.DATE_FORMAT_BASE_SLASH);
    const dateTo = moment(this.activeRoute[endDate]).format(AppConstants.DATE_FORMAT_BASE_SLASH);
    const disableDaysBefore = moment(this.activeRoute[startDate]).format(AppConstants.DATE_FORMAT_ISO);
    const disableDaysAfter = moment(this.activeRoute[endDate]).format(AppConstants.DATE_FORMAT_ISO);

    this.form.patchValue({
      dateFrom,
      dateTo,
      disableDaysBefore,
      disableDaysAfter
    });
  }

  close() {
    this.deleteModalRef.hide();
    this.action.emit({ type: ModalActions.Close });
  }

  save() {
    const { routeIds, changeDateFrom, permanently } = this.form.value;
    const body = {
      routeIds,
      from: changeDateFrom,
      permanently
    };

    this.action.emit({ type: 'save', body });
  }
}
