export interface GeoDataAddress {
  placeId: string;
  fullAddress: string;
  latitude: number;
  longitude: number;
}

export interface GeoDataWalkingDistanceParams {
  origin: string;
  destination: string;
}
