<div class="builder-info__items">
  <div *ngIf="showEyeFilters" class="builder-info__item">
    <div class="u-icon u-icon_pointer u-icon_app-builder-eye-big-thin" (click)="filter()"></div>
  </div>
  <div class="builder-info__item">
    {{'builder.info.time' | translate}} {{activeRide.duration}}
  </div>
  <div class="builder-info__item">
    {{activeRide.distance | kmMileConvertor}} {{distanceDictionary && distanceDictionary.km | translate}}
  </div>
  <!--
  <div class="builder-info__item">
    <div class="builder-info__check">
      <div class="builder-info__check-name">
        {{'builder.info.traffic' | translate}}
      </div>
      <div class="builder-info__check-box">
        <u-checkbox
          class="u-checkbox_big"
          [(ngModel)]="mapRide.traffic"
          (click)="traffic()"
        ></u-checkbox>
      </div>
    </div>
  </div>
  -->
</div>
<div class="builder-info__types">
  <div class="builder-info__type">
    <div class="builder-info__type-icon builder-info__type-icon_black"></div>
    <div class="builder-info__type-name">{{'builder.info.route' | translate}}</div>
  </div>
  <div *ngIf="displayAccompany" class="builder-info__type">
    <div class="builder-info__type-icon builder-info__type-icon_blue-light"></div>
    <div class="builder-info__type-name">{{'builder.info.accompany' | translate}}</div>
  </div>
</div>
