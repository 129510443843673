export enum Errors {
  ServerIsDown = '0',
  NeedRecaptchaFirstOption = '1014',
  NeedRecaptchaSecondOption = '1015',
  NeedEula = '1016',
  OtpTokenError = '1023',
  MaxPassengersReached = '1101',
  OverloadedCarType = '1102',
  CarTypeExceedsVehicleCapacity = '1106',
  VehicleSubceedsCarTypeCapacity = '1107',
  PermissionsDenied = '1128',
  RideStartInLessThanTwoHours = '1132',
  ExtraFeeNecessary = '1203',
  TemplateDoesntHaveEnoughSeatsForDemands = '1808',
  EmailsMaxRecipients = '1901',
  EmailsLimit = '1902',
  OverlapShift = '4542',
  ContractWithoutBranchesErrorCode = '5714',
  ContractAreaIsIntersectingWithAnotherContractArea = '5742'
}
