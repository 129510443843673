import { Component, OnInit, HostBinding, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { USelectSItem } from '@shift/ulib';

import { RoutePolicyAlgorithmVersion, RoutePolicyPickUpOption } from '@app/shared/models';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { AuthDataService } from '@app/auth/services';
import { AuthPolicyName, AuthPolicyRoute } from '@app/auth/models';
import { routePolicyConfig } from '@app/shared/configs';
import { routePolicyComponentConfig } from './route-policy.component.config';

@Component({
  selector: 'app-route-policy',
  templateUrl: './route-policy.component.html',
  styleUrls: [ './route-policy.component.scss', './route-policy.component.rtl.scss' ]
})
export class RoutePolicyComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;

  @HostBinding('class') hostClasses: string = 'route-policy';

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(routePolicyComponentConfig);
  isRtl: boolean = true;
  calculateTypes: USelectSItem[] = routePolicyConfig.calculateTypes;
  passengerStationTypes: USelectSItem[] = routePolicyConfig.passengerStationTypes;
  pickupOptionTypes: USelectSItem[] = routePolicyConfig.pickupOptionTypes;
  passengerAddressPolicyTypes: USelectSItem[] = routePolicyConfig.passengerAddressPolicyTypes;
  routeNamePolicyTypes: USelectSItem[] = routePolicyConfig.routeNamePolicyTypes;
  routePolicyAlgorithmVersion = RoutePolicyAlgorithmVersion;
  authPolicyRoutes: AuthPolicyRoute[] = [];
  authPolicyRoute = AuthPolicyRoute;

  constructor(
    private authDataService: AuthDataService,
    private trackingService: TrackingService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();

    this.initAuthPolicies();
    this.onPickupOptionsValueChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initAuthPolicies() {
    this.authDataService.policies$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(policies => this.authPolicyRoutes = policies && policies[AuthPolicyName.Routes]);
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${event}`);
  }

  private onPickupOptionsValueChanges() {
    this.form.get('curbApproach').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        switch (value) {
          case RoutePolicyPickUpOption.AnySide: {
            this.trackEvent('Pickup Option - select Both sides of the road');
            break;
          }

          case RoutePolicyPickUpOption.VehicleAbilities: {
            this.trackEvent('Pickup Option - select According to vehicle abilities');
            break;
          }

          case RoutePolicyPickUpOption.RightSide: {
            this.trackEvent('Pickup Option - select according to Passenger location');
            break;
          }
        }
      });
  }

  cheapestPriceChange(newVal: boolean) {
    this.trackEvent(`Cheapest toggle - click on ${newVal ? 'Active' : 'Inactive'}`);
  }
}
