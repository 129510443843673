import {
  Input,
  Output,
  Component,
  HostBinding,
  OnInit,
  EventEmitter,
  inject,
  DestroyRef
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { URangePreset } from '@shift/ulib';
import { cloneDeep } from 'lodash';

import { TrackingService } from '@app/shared/services';
import { RoutesChangeType } from '@app/routes/models';
import {
  BuilderConfig,
  BuilderActiveRide,
  BuilderDataStoreOptions,
  BuilderMode,
  BuilderBuildMode,
  BuilderTuningMode,
  BuilderCalculationMode
} from '@app/builder/models';
import { BuilderDataService, BuilderCommonService, BuilderTuningStoreService } from '@app/builder/services';
import { builderConfig } from '@app/builder/configs';
import {
  AuthCustomer,
  AuthModuleRouteBuilderFeatureType
} from '@app/auth/models';
import { builderTuningComponentConfig } from './builder-tuning.component.config';

@Component({
  selector: 'app-builder-tuning',
  templateUrl: './builder-tuning.component.html',
  styleUrls: [ './builder-tuning.component.scss', './builder-tuning.component.rtl.scss' ]
})
export class BuilderTuningComponent implements OnInit {
  @Input() builderDataConfig: BuilderConfig;
  @Input() addEditForm: UntypedFormGroup;
  @Input() activeRide: BuilderActiveRide;
  @Input() options: BuilderDataStoreOptions;
  @Input() customerData: any;
  @Input() lang: string;
  @Input() viewportPopover: HTMLElement;
  @Input() viewportDropdown: HTMLElement;
  @Input() isRtl: boolean;
  @Input() authCustomer: AuthCustomer;
  @Input() mode: BuilderTuningMode = BuilderTuningMode.Default;

  @Output() formControlClick: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-tuning';

  private readonly destroyRef = inject(DestroyRef);
  private readonly builderCommonService = inject(BuilderCommonService);
  public readonly builderTuningStoreService = inject(BuilderTuningStoreService);
  public readonly builderDataService = inject(BuilderDataService);
  public readonly trackingService = inject(TrackingService);

  readonly routeBuilderFeatureTypeShuttleCompany = this.builderDataService.routeBuilderFeatureTypeShuttleCompany;
  readonly routeBuilderFeatureMasterCustomer = this.builderDataService.routeBuilderFeatureMasterCustomer;
  readonly routeBuilderFeatureTransportAdditionalFieldsDriverHours = this.builderDataService.routeBuilderFeatureTransportAdditionalFieldsDriverHours;

  config = cloneDeep(builderTuningComponentConfig);
  carNumbers = this.builderTuningStoreService.carNumbersByCarTypeId(null);
  builderCalculationMode = BuilderCalculationMode;
  routeChangesType = RoutesChangeType;
  builderBuildMode = BuilderBuildMode;
  builderMode = BuilderMode;
  availablePresets = {
    planned: [
      URangePreset.ActiveWeekDay,
      URangePreset.FromTodayOn,
      URangePreset.FromTomorrowOn,
      URangePreset.FromCustomDayOn,
      URangePreset.All,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    notPlanned: [
      URangePreset.DisplayedDay,
      URangePreset.Today,
      URangePreset.Tomorrow,
      URangePreset.ActiveWeekDay,
      URangePreset.FromCustomDayOn,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    [AuthModuleRouteBuilderFeatureType.ShuttleCompany]: [
      URangePreset.Today,
      URangePreset.Custom
    ]
  };
  authModuleRouteBuilderFeatureType = AuthModuleRouteBuilderFeatureType;
  builderTuningMode = BuilderTuningMode;

  ngOnInit() {
    this.onAddEditFormActiveRideValueChanges();
    this.onActiveRouteData();
  }

  private onActiveRouteData() {
    if (this.mode === BuilderTuningMode.SC) {
      this.builderCommonService.activeRouteData$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          if (this.addEditForm.get('activeRide.shuttleCompanyId').value) {
            this.loadShuttleCompanyData(this.addEditForm.get('activeRide.shuttleCompanyId').value);
            this.updateCarNumbers(this.addEditForm.get('activeRide.carTypeId').value);
          } else {
            this.builderTuningStoreService.reset();
          }
        });
    }
  }

  private onAddEditFormActiveRideValueChanges() {
    if (this.mode === BuilderTuningMode.SC) {
      this.addEditForm.get('activeRide.shuttleCompanyId').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.editShuttleCompanyClick();

          this.builderDataService.editShuttleCompany();
        });

      this.addEditForm.get('activeRide.carTypeId').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => {
          this.carTypeIdChange(value);

          this.builderDataService.editCarType();
        });

      this.addEditForm.get('activeRide.contractId').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => {
          this.trackEvent('Map tab - Contract - Select a contract');
          this.loadShuttleCompanyData(this.addEditForm.get('activeRide.shuttleCompanyId').value);

          if (!value) {
            this.builderDataService.editContractRemove(this.builderDataService.routeStore.activeRide.contractId);

            return;
          }

          this.builderDataService.editContractSet();
        });

      this.addEditForm.get('activeRide.chargeExtraFee').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.builderDataService.editChargeExtraFee());

      this.addEditForm.get('activeRide.hashcalRideType').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.builderDataService.editHashcalRideType());

      this.addEditForm.get('activeRide.executionCost').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.builderDataService.editExecutionCost());

      this.addEditForm.get('activeRide.driverHours').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.builderDataService.editDriverHours());

      this.addEditForm.get('activeRide.cost').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.builderDataService.editCost();
          this.scCostChange();
        });

      this.addEditForm.get('activeRide.costType').valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.builderDataService.editCost();
          this.builderDataService.updateHashcalRideTypeState();
        });

      merge(
        this.addEditForm.get('activeRide.tollRoadsCostType').valueChanges,
        this.addEditForm.get('activeRide.tollRoadsCost').valueChanges
      )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.builderDataService.editTollRoadsCost());

      this.builderTuningStoreService.hashcalRideTypesItems$
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(data => !!data)
        )
        .subscribe(() => this.builderDataService.updateHashcalRideTypeState());
    }
  }

  loadShuttleCompanyData(shuttleCompanyId: number) {
    this.builderTuningStoreService.getShuttleCompany({
      shuttleCompanyId,
      contractId: this.addEditForm.get('activeRide.contractId').value,
      routeMode: builderConfig.routeModesByBuildMode[this.builderDataConfig.buildMode] || builderConfig.routeModesByBuildMode.default
    });
  }

  directionClick() {
    if (this.builderDataConfig.mode === BuilderMode.Add) {
      this.trackingService.track('[Route Add, Map tab] - select direction');
    }
  }

  periodClick() {
    if (this.builderDataConfig.mode === BuilderMode.Add) {
      this.trackingService.track('[Route Add, Map tab] - select period');
    }
  }

  editStartTimeClick() {
    if (this.builderDataConfig.mode === BuilderMode.Add) {
      this.trackingService.track('[Route Add, Map tab] - click on start time');
    } else {
      this.trackingService.track('[Route Edit, Map tab] - click on change start time');
    }
  }

  editEndTimeClick() {
    if (this.builderDataConfig.mode === BuilderMode.Add) {
      this.trackingService.track('[Route Add, Map tab] - click on end time');
    } else {
      this.trackingService.track('[Route Edit, Map tab] - click on change end time');
    }
  }

  editShuttleCompanyClick() {
    if (this.builderDataConfig.mode === BuilderMode.Add) {
      this.trackingService.track('[Route Add, Map tab] - select SC');
    } else {
      this.trackingService.track('[Route Edit, Map tab] - click on change SC');
    }
  }

  scCostChange() {
    if (this.builderDataConfig.mode === 'edit') {
      this.trackingService.track('[Track edit] - price changed');
    }
  }

  carTypeIdChange(value: number) {
    if (this.builderDataConfig.mode === BuilderMode.Edit) {
      this.trackingService.track('[Track edit] - vehicle changed');
    }

    this.updateCarNumbers(value);
  }

  carTypeIdClick() {
    this.trackEvent('planned car type - click on value');
  }

  driverIdChange() {
    if (this.builderDataConfig.mode === 'edit') {
      this.trackingService.track('[Track edit] - driver changed');
    }
  }

  selectPurchaseOrder(value: string) {
    this.trackEvent('Route Add/Edit - select Purchase Order');

    if (value) {
      this.builderDataService.addEditForm.get('activeRide.purchaseOrderRow').enable({ emitEvent: false });
    } else {
      this.builderDataService.addEditForm.get('activeRide.purchaseOrderRow').disable({ emitEvent: false });
      this.builderDataService.addEditForm.get('activeRide.purchaseOrderRow').reset();
    }

    this.builderDataService.setRidePurchaseOrdersRows(value);
    this.builderDataService.editPurchaseOrder();
  }

  selectPurchaseOrderRow() {
    this.trackEvent('Route Add/Edit- select Purchase Row');
    this.builderDataService.editPurchaseOrderRow();
  }

  updateCarNumbers(value: number) {
    this.carNumbers = this.builderTuningStoreService.carNumbersByCarTypeId(value);
  }

  trackEvent(event: string) {
    this.trackingService.track(`[${this.builderDataConfig.trackingId || builderConfig.trackingId}] - ${event}`);
  }
}
