<u-modal-entity
  class="u-modal-entity_app-accessibility-affidavit"
  [title]="config.dictionary.title"
  [buttons]="config.buttons"
  [isRtl]="isRtl"
  (action)="onAction($event)"
>
  <div class="accessibility-affidavit-content">
    <ng-container *ngTemplateOutlet="lang === languages.Hebrew ? he : en"></ng-container>
  </div>
</u-modal-entity>

<ng-template #en>
  <h2>Accessibility by SHIFT LIVE</h2>
  <p>
    SHIFT LIVE makes every effort and invests many resources in order to provide all its customers with equal, dignified, accessible and professional service. In accordance with the Equal Rights for Persons with Disabilities Law, 5758-1998 and the regulations enacted pursuant to it, considerable efforts and resources are invested in making the necessary accessibility adjustments that will enable a person with disabilities to receive the services provided to all customers, independently and equally.
  </p>
  <h2>Accessible Customer Service</h2>
  <ul>
    <li>SHIFT LIVE has no face to face customer reception. You can contact us through digital channels or the call center</li>
    <li><b>Employee training for accessible service</b> – training sessions are held to familiarize employees with accessibility, create awareness among employees and provide practical tools for providing accessible service.</li>
    <li><b>Accessible call center</b> – the voice response has been made accessible so that the information is transmitted in clear language and without background music.</li>
    <li>
      <b>Additional means of contact</b> – There are several additional channelsfor contact:
      <ul>
        <li>Phone: 0533533521</li>
        <li>email: <a href="mailto:support@shiftlive.net">support&#64;shiftlive.net</a></li>
        <li>
          Contact us via "Contact Us" on the homepage (link): <a href="https://shiftlive.net/%d7%99%d7%a6%d7%99%d7%a8%d7%aa-%d7%a7%d7%a9%d7%a8/" target="_blank">https://shiftlive.net/%d7%99%d7%a6%d7%99%d7%a8%d7%aa-%d7%a7%d7%a9%d7%a8/</a>
        </li>
        <li>Chat: For riders, linked from the Shiftapp World of Transport</li>
        <li>Chat: The transportation manager in the organization has a built-inSHIFT system</li>
        <li>WhatsApp hotline: 0525705953</li>
        <li>Contacting us by mail: SHIFT LIVE Kibbutz Ramat David D.N.</li>
      </ul>
    </li>
  </ul>
  <h2>Ways to contact requests and suggestions for improvement regarding accessibility</h2>
  <p>It should be noted that we continue our efforts to improve the company's accessibility as part of our commitment to enable the entire population, including people with disabilities, to receive the most accessible service.</p>
  <p>If you have encountered any problem or malfunction regarding accessibility, we would be happy for you to let us know and we will make every effort to find a suitable solution for you and address the problem as soon as possible.</p>
  <h2>ACCESSIBILITY COORDINATOR DETAILS IN SHIFT LIVE</h2>
  <ul>
    <li>Name: Yonit Gordon</li>
    <li>Phone: 0548663222</li>
    <li>email: yonit&#64;shiftlive.net</li>
    <li>WhatsApp: 0548663222</li>
  </ul>
  <h2>Publish the Accessibility Statement</h2>
  <p>Accessibility statement updated on: 12/3/2024</p>
</ng-template>

<ng-template #he>
  <h2>הסדרי נגישות חברת</h2>
  <p>
    חברת SHIFT LIVE עושה את מירב המאמצים ומשקיעה משאבים רבים על מנת לספק לכל לקוחותיו שירות שוויוני, מכובד, נגיש ומקצועי. בהתאם לחוק שוויון זכויות לאנשים עם מוגבלויות תשנ"ח-1998 ולתקנות שהותקנו מכוחו, מושקעים מאמצים ומשאבים רבים בביצוע התאמות הנגישות הנדרשות שיביאו לכך שאדם בעל מוגבלות יוכל לקבל את השירותים הניתנים לכלל הלקוחות, באופן עצמאי ושוויוני.
  </p>
  <h2>שירות לקוחות נגיש</h2>
  <ul>
    <li>חברת SHIFT LIVE אינה מקבלת קהל. ניתן לפנות אלינו דרך הערוצים הדיגיטליים או המוקד הטלפוני</li>
    <li><b>הדרכות עובדים לשירות נגיש</b> - מתקיימות הדרכות להכרת תחום הנגישות, יצירת מודעות בקרב העובדים והקניית כלים מעשיים למתן שירות נגיש.</li>
    <li><b>מוקד טלפוני נגיש</b> -  המענה הקולי הונגש כך שהמידע מועבר בשפה ברורה וללא מוסיקת רקע.</li>
    <li>
      <b>אמצעים נוספים ליצירת קשר</b> - קיימים מספר ערוצים נוספים ליצירת קשר:
      <ul>
        <li>טלפון: 0533533521</li>
        <li>דואר אלקטרוני: <a href="mailto:support@shiftlive.net">support&#64;shiftlive.net</a></li>
        <li>
          פניה באמצעות "צור קשר" באתר הבית (לינק): <a href="https://shiftlive.net/%d7%99%d7%a6%d7%99%d7%a8%d7%aa-%d7%a7%d7%a9%d7%a8/" target="_blank">https://shiftlive.net/%d7%99%d7%a6%d7%99%d7%a8%d7%aa-%d7%a7%d7%a9%d7%a8/</a>
        </li>
        <li>צ'אט: לנוסעים, בקישור מתוך האפליקציה  shift עולם של תחבורה</li>
        <li>צ'אט: למנהל ההיסעים בארגון מובנה במערכת SHIFT</li>
        <li>מוקד וואטסאפ: 0525705953</li>
        <li>פניה באמצעות הדואר: SHIFT LIVE  קיבוץ רמת דוד ד.נ עמקים</li>
      </ul>
    </li>
  </ul>
  <h2>דרכי פנייה לבקשות והצעות לשיפור בנושא נגישות</h2>
  <p>יש לציין כי אנו ממשיכים במאמצים לשפר את נגישות החברה כחלק ממחויבותנו לאפשר לכלל האוכלוסייה כולל אנשים עם מוגבלויות לקבל את השרות הנגיש ביותר.</p>
  <p>במידה ונתקלת בבעיה או בתקלה כלשהי בנושא הנגישות, נשמח שתעדכן אותנו בכך ואנו נעשה כל מאמץ למצוא עבורך פתרון מתאים ולטפל בתקלה בהקדם ככל שניתן.</p>
  <h2>פרטי רכז הנגישות ב - SHIFT LIVE</h2>
  <ul>
    <li>שם: יונית גורדון</li>
    <li>טלפון: 0548663222</li>
    <li>דוא”ל: yonit&#64;shiftlive.net</li>
    <li>ווטסאפ: 0548663222</li>
  </ul>
  <h2>פרסום הצהרת הנגישות</h2>
  <p>הצהרת הנגישות עודכנה ביום: 12/3/2024</p>
</ng-template>
