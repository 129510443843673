import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { USidebarMenuService } from '@shift/ulib';

import { AccompaniesAddEditMode } from '@app/accompanies/models';
import { AccompaniesAddEditComponent } from '@app/accompanies/components/accompanies-add-edit/accompanies-add-edit.component';

@Injectable()
export class AccompaniesDataService {
  constructor(
    private uSidebarMenuService: USidebarMenuService,
    private bsModalService: BsModalService
  ) {}

  openAddEditModal(editData?, mode: AccompaniesAddEditMode = AccompaniesAddEditMode.Default) {
    return this.bsModalService.show(
      AccompaniesAddEditComponent,
      {
        class: this.uSidebarMenuService.getCollapsedValue() ? 'u-modal u-modal_content u-modal_content-hide-menu' : 'u-modal u-modal_content',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          editData,
          mode
        }
      }
    );
  }
}
