import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { UPopupService } from '@shift/ulib';

import { ValidationService } from '@app/shared/services/validation.service';
import { ContactType, InputContact, InputContactItem } from '@app/shared/models';
import { inputContactsConfig } from '@app/shared/configs';

@Injectable({
  providedIn: 'root'
})
export class InputContactsService {
  form: UntypedFormArray;
  contactGroupId: number = 0;

  constructor(
    public fb: UntypedFormBuilder,
    public validationService: ValidationService,
    public uPopupService: UPopupService
  ) {}

  generateContact(contact: InputContact): UntypedFormGroup {
    const contactForm = this.generateContactForm(contact);
    const contacts = contactForm.get('contacts') as UntypedFormArray;

    contact.contacts.forEach(ob => {
      contacts.push(this.generateContactItem(ob));
    });

    return contactForm;
  }

  generateContactForm(contact: InputContact): UntypedFormGroup {
    return this.fb.group({
      groupId: [ contact.groupId ],
      role: [ contact.role, [ Validators.required ] ],
      name: [ contact.name, [] ],
      contacts: this.fb.array([])
    });
  }

  generateContactItem(userContact: InputContactItem): UntypedFormGroup {
    const userContactForm = this.fb.group({
      type: [ userContact.type, [ Validators.required ] ],
      contact: [ userContact.contact, [ Validators.required ] ]
    });

    const typeForm = userContactForm.get('type');
    const contactForm = userContactForm.get('contact');

    contactForm.setAsyncValidators(this.checkUniqueContactItem.bind(contactForm, typeForm));

    this.validationService.updateValidationContactData(contactForm, typeForm.value);

    typeForm.valueChanges.subscribe((value: any) => {
      if (!value) { return; }

      this.validationService.updateValidationContactData(contactForm, value);
    });

    return userContactForm;
  }

  checkUniqueContactItem = (type: AbstractControl, contact: AbstractControl): Observable<{ unique: boolean } | null> => {
    if (!contact.valueChanges || contact.pristine) {
      return of(null);
    } else {
      return contact.valueChanges
        .pipe(
          debounceTime(0),
          distinctUntilChanged(),
          take(1),
          map(() => {
            if (
              this.form
                .value
                .reduce((acc: InputContactItem[], ob: InputContact) => acc.concat(ob.contacts), [])
                .filter((ob: InputContactItem) => ob.type === type.value && ob.contact === contact.value).length > 1
            ) {
              return { unique: true };
            }

            return null;
          })
        );
    }
  };

  generateNewContactData(groupId: number): InputContact {
    return {
      groupId,
      role: '',
      name: '',
      contacts: [
        { type: inputContactsConfig.defaultContactType, contact: '' }
      ]
    };
  }

  addContact() {
    this.contactGroupId--;

    this.form.markAsDirty();
    this.form.insert(0, this.generateContact(this.generateNewContactData(this.contactGroupId)));
  }

  removeContact({ index }: { index: number }) {
    this.form.markAsDirty();
    this.form.removeAt(index);
  }

  addContactItem({ index }: { index: number }) {
    const contactsItemsForm = this.form.at(index).get('contacts') as UntypedFormArray;

    contactsItemsForm.markAsDirty();
    contactsItemsForm.push(this.generateContactItem({ ...new InputContactItem(), type: ContactType.Mobile }));
  }

  removeContactItem({ index, indexItem }: { index: number, indexItem: number }) {
    const contactsItemsForm = this.form.at(index).get('contacts') as UntypedFormArray;

    contactsItemsForm.markAsDirty();
    contactsItemsForm.removeAt(indexItem);
  }
}
