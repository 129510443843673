import { Injectable } from '@angular/core';

import { headerSearchFiltersConfig } from '@app/shared/configs';

@Injectable({
  providedIn: 'root'
})
export class HeaderSearchFiltersStorageService {
  get() {
    return JSON.parse(localStorage.getItem(headerSearchFiltersConfig.storageKey));
  }

  update(value: object) {
    localStorage.setItem(headerSearchFiltersConfig.storageKey, JSON.stringify({
      ...this.get(),
      ...value
    }));
  }

  getShowFiltersDrawer() {
    return this.get()?.showFiltersDrawer || false;
  }

  updateShowFiltersDrawer(showFiltersDrawer: boolean) {
    this.update({ showFiltersDrawer });
  }
}
