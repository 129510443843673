import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_KEY, AuthState } from '@app/auth/state/reducers';

export const selectAuthState = createFeatureSelector<AuthState>(
  FEATURE_KEY
);

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state) => state.status
);

export const selectAuthStatusInitedState = createSelector(
  selectAuthStatusState,
  (state) => state.inited
);

export const selectAuthStatusDataState = createSelector(
  selectAuthStatusState,
  (state) => state.data
);

export const selectAuthCountdownState = createSelector(
  selectAuthState,
  (state) => state.countdown
);

export const selectAuthCountdownCounterState = createSelector(
  selectAuthCountdownState,
  (state) => state.counter
);

export const selectLoggedIn = createSelector(selectAuthStatusDataState, (user) => !!user);
