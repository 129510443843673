import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AuthUserInfo } from '@app/auth/models';

declare let plantrack;

@Injectable({
  providedIn: 'root'
})
export class PlantrackService {
  constructor() {
    this.load();
  }

  private load() {
    plantrack.load(`https://analytics.planhat.com/analytics/${environment.config.plantrackKey}`);
  }

  init(loginDetails: AuthUserInfo) {
    plantrack.identify(
      loginDetails.person.personId,
      {
        email: loginDetails.person.email || '',
        role_type: loginDetails.person.roleName,
        name: loginDetails.person.firstName + ' ' + loginDetails.person.lastName,
        companyId: loginDetails.customer.customerGuid,
        phone: loginDetails.person.mobile || ''
      }
    );
  }

  track(message: string) {
    plantrack.track(message);
  }
}
