import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';

import { RoutesDailyApprovalsStatus } from '@app/routes/models';
import { routesDailyApprovalsComponentConfig } from './routes-daily-approvals.component.config';

@Component({
  selector: 'app-routes-daily-approvals',
  templateUrl: './routes-daily-approvals.component.html',
  styleUrls: [ './routes-daily-approvals.component.scss', './routes-daily-approvals.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesDailyApprovalsComponent implements OnInit, OnChanges {
  @Input() isRejected = false;
  @Input() actualApprovalsCount: number;
  @Input() expectedApprovalsCount: number;

  @HostBinding('class') hostClasses: string = 'routes-daily-approvals';

  showRouteApprovals = false;
  routeApprovals = '';
  routesDailyApprovalsStatus = RoutesDailyApprovalsStatus;
  config = cloneDeep(routesDailyApprovalsComponentConfig);

  ngOnInit() {
    this.updateRouteApprovals();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.actualApprovalsCount || changes.expectedApprovalsCount) {
      this.updateRouteApprovals();
    }
  }

  private updateRouteApprovals() {
    if (this.actualApprovalsCount !== null && this.expectedApprovalsCount !== null) {
      this.showRouteApprovals = true;
      this.routeApprovals = `(${this.expectedApprovalsCount}/${this.actualApprovalsCount})`;
    }
  }
}
