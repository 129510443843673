<span *ngIf="modeAi()" class="builder-full-routes-generation__active">{{config().dictionary.mode.ai | translate}}</span>
<span 
  class="builder-full-routes-generation__menu-icons"
  #tooltip="uTooltip"
  [uTooltip]="tooltipContent"
  [tooltipClass]="'u-tooltip_app-builder-full-routes-generation'"
  [placement]="[ 'bottom' ]"
  [container]="'body'"
  [triggers]="'manual'"
  [autoClose]="false"
>
  <u-menu-icons
    class="u-menu-icons_app-builder-full-routes-generation"
    [icons]="modeMenuIcons() ?? []"
    [isRtl]="isRtl"
    (iconClick)="onModeMenuIconClick($event)"
  ></u-menu-icons>
</span>

<ng-template #tooltipContent>
  <div class="builder-full-routes-generation-tooltip-content">
    {{config().dictionary.suggestionsReady | translate}}
    <div class="u-icon u-icon_pointer u-icon_app-close-circle-white" (click)="closeTooltip()"></div>
  </div>
</ng-template>