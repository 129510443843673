<app-general-card
  [title]="config.dictionary.title"
  [buttons]="config.buttons"
  (buttonClick)="onButtonClick($event)"
  (closeAction)="close()"
>
  <div class="routes-cancel-ride__body">
    <div class="routes-cancel-ride__body-title">{{activeRide.code}} {{activeRide.name}}</div>
    <div class="routes-cancel-ride__body-groups">
      <u-group
        class="u-group_app-routes-cancel-ride u-group_app-max-field-height"
        [name]="config.dictionary.fields.type | translate"
      >
        <div class="routes-cancel-ride__body-double">
          <div class="routes-cancel-ride__body-double-item">
            <input
              uInput
              type="text"
              [class]="'u-input_text-grey'"
              [disabled]="true"
              [value]="config.dictionary.unplanned | translate"
            >
          </div>
          <div class="routes-cancel-ride__body-double-item"></div>
        </div>
      </u-group>
      <u-group
        class="u-group_app-routes-cancel-ride"
        [name]="config.dictionary.fields.period | translate"
      >
        <app-routes-periods
          [defaultData]="routesPeriodsDefaultData"
          [form]="form.get('periods')"
          [hidePlusIcon]="isSCCustomer"
          [isOwnedBySc]="activeRide.isOwnedBySc"
          [authCustomer]="authCustomer"
          (clickOnPlusIcon)="track('Period - click on plus')"
        ></app-routes-periods>
      </u-group>
      <u-group
        *ngIf="!isSCCustomer || activeRide.isOwnedBySc"
        class="u-group_app-routes-cancel-ride u-group_app-max-field-height"
        [name]="config.dictionary.fields.cancelClause | translate"
      >
        <u-select-s
          [classUInput]="'u-input_arrow-light u-input_light'"
          [classUDropdownContent]="'u-dropdown-content_app-routes-change'"
          [items]="config.cancelClauseList"
          [formControl]="form.get('cancelClause')"
          [messages]="config.dictionary.select.messages | translate"
          [empty]="false"
          [placeholder]="config.dictionary.select.placeholder | translate"
        ></u-select-s>
      </u-group>
      <u-group
        class="u-group_app-routes-cancel-ride u-group_app-max-field-height"
        [name]="config.dictionary.fields.charge | translate"
        *ngIf="form.get('cancelClause').value > 0 || form.get('cancelClause').value === routesCancelRideClauseType.Manual"
      >
        <div class="routes-cancel-ride__body-double">
          <div class="routes-cancel-ride__body-double-item">
            <u-input-number
              [formControl]="form.get('chargeAmount')"
              [decimal]="true"
              [maxDecimalDigits]="2"
              [valid]="form.get('chargeAmount').disabled || form.get('chargeAmount').valid"
              [enabled]="!form.get('chargeAmount').disabled"
              [showCounter]="true"
              [min]="0"
              [max]="9999.99"
            ></u-input-number>
          </div>
          <div class="routes-cancel-ride__body-double-item">
            <u-select-s
              [classUInput]="'u-input_arrow-light u-input_light'"
              [classUDropdownContent]="'u-dropdown-content_app-routes-change'"
              [items]="config.chargeTypeList"
              [formControl]="form.get('chargeType')"
              [messages]="config.dictionary.select.messages | translate"
              [empty]="false"
              [placeholder]="config.dictionary.select.placeholder | translate"
            ></u-select-s>
          </div>
        </div>
      </u-group>
    </div>
  </div>
</app-general-card>
