import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { RouteDailyRideStatus } from '@app/routes/models';
import { MonitoringDashboardPassengerStatus, MonitoringDashboardRideBase, MonitoringDashboardRideDetailsBase } from '@app/monitoring-dashboard/models';
import { monitoringDashboardConfig } from '@app/monitoring-dashboard/configs';

@Injectable()
export class MonitoringDashboardRidesService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  rides: any = [];
  rideActive: any = null;

  constructor(
    private translateService: TranslateService,
    private headerDataService: HeaderDataService
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  load(rides: MonitoringDashboardRideBase[]) {
    this.translateService.get(monitoringDashboardConfig.dictionary.rideStatuses)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(statuses => {
        this.rides = rides.map(ride => {
          const startTimeParsed = moment(ride.startDateTime);
          const endTimeParsed = moment(ride.endDateTime);

          const startDateTime = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO)
            .set({
              hours: startTimeParsed.hours(),
              minutes: startTimeParsed.minutes(),
              seconds: startTimeParsed.seconds()
            });

          const endDateTime = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO)
            .set({
              hours: endTimeParsed.hours(),
              minutes: endTimeParsed.minutes(),
              seconds: endTimeParsed.seconds()
            });

          const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO);
          const isToday = moment().isSame(activeDate, 'd');
          const startTimeDays = isToday ? null : activeDate.diff(moment(), 'd') + 1;

          return {
            ...ride,
            startDateTime: startDateTime.toDate(),
            endDateTime: endDateTime.toDate(),
            startTimeDays,
            statusText: this.getRideStatusText(ride, statuses),
            details: {
              participants: []
            }
          };
        });
      });
  }

  private getRideStatusText(ride: MonitoringDashboardRideBase, statuses: { [x: string]: string; }): string {
    if (ride.rideStatus === RouteDailyRideStatus.New && ride.delayTime) {
      return statuses['errorFirstPart'];
    }

    if ([ RouteDailyRideStatus.FinishedMonitored, RouteDailyRideStatus.Finished ].includes(ride.rideStatus)) {
      return statuses['finished'];
    }

    if ([ RouteDailyRideStatus.OngoingMonitored, RouteDailyRideStatus.Ongoing ].includes(ride.rideStatus)) {
      return statuses['ongoing'];
    }

    if (ride.rideStatus === RouteDailyRideStatus.Cancelled) {
      return statuses['cancelled'];
    }
  }

  clear() {
    this.rides = [];
    this.rideActive = null;
  }

  getRideById(id: number) {
    return this.rides.find((ob: any) => ob.rideId === id);
  }

  setRideDetailsById(details: MonitoringDashboardRideDetailsBase, id: number) {
    const ride = this.getRideById(id);

    if (ride && details) {
      ride.details = {
        ...details,
        distance: details.distance ? +details.distance.toFixed(2) : 0,
        duration: details.duration ? moment.duration(details.duration).format(AppConstants.TIME_FORMAT_FULL) : null,
        stations: details.stations ? details.stations.map(station => {
          const noEntrance = details.monitoredPaths && (!station.actualArriveDateTime || !moment(station.actualArriveDateTime).isValid());

          return {
            ...station,
            actualArriveDateTime: moment(station.actualArriveDateTime).valueOf() < 0 ? null : station.actualArriveDateTime,
            noEntrance,
            arrivalGapInMin: noEntrance ? null : Math.round(moment(station.actualArriveDateTime).diff(moment(station.arrivalDateTime), 'seconds', true) / 60),
            showPassengers: false
          };
        }) : []
      };
    }
  }

  getRideActive() {
    return this.rideActive;
  }

  setRideActive(id: number) {
    const ride = this.rides.find((ob: any) => ob.rideId === id);

    if (ride) {
      this.rideActive = ride;
    }
  }

  setRideStatus(id: number, status: string) {
    const ride = this.rides.find((ob: any) => ob.rideId === id);

    if (ride) {
      ride.rideStatus = status;
    }
  }

  setRideDelay(id: number, delay: number | null) {
    const ride = this.rides.find((ob: any) => ob.rideId === id);

    if (ride) {
      ride.delayTime = delay;
    }
  }

  setRideActiveParticipantStatus(passengerId: number, status: MonitoringDashboardPassengerStatus) {
    const ride = this.getRideActive();

    if (ride && ride.details) {
      ride.details.stations.forEach(station => {
        const passengerForUpdates = station.passengers && station.passengers.find(passenger => passenger.id === passengerId);

        if (passengerForUpdates) {
          const field = status.toLowerCase();

          passengerForUpdates[field] = true;
        }
      });
    }
  }

  updateLiveCoordinate(data: any) {
    const { latitude, longitude, locatedAt, memberId } = data;
    const ride = this.getRideActive();
    const coords = {
      latitude,
      longitude,
      locatedAt
    };

    if (ride && ride.details && ride.details.monitoredPaths) {
      const participant = ride.details.monitoredPaths.find((ob: any) => ob.memberId === memberId);
      const firstLongitude = participant.coordinates[0].longitude;
      const firstLatitude = participant.coordinates[0].latitude;

      if (firstLongitude !== coords.longitude && coords.latitude !== firstLatitude) {
        participant.coordinates.splice(0, 0, coords);
        return;
      } else { return; }
    }
  }
}
