import {
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UGridHeaderModule } from '@shift/ulib';
import { cloneDeep } from 'lodash';

import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { GridHeaderButton, GridHeaderTemplate } from '@app/shared/models';
import { gridHeaderComponentConfig } from './grid-header.component.config';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: [ './grid-header.component.scss', './grid-header.component.rtl.scss' ],
  standalone: true,
  providers: [ AuthDataSnapshotService ],
  imports: [
    CommonModule,
    TranslateModule,
    UGridHeaderModule
  ]
})
export class GridHeaderComponent implements OnChanges {
  @Input() template: GridHeaderTemplate;
  @Input() showButtons: boolean = true;
  @Input() showCounters: boolean = true;
  @Input() filteredColumns: boolean;
  @Input() selected: number;
  @Input() showSelected: boolean = true;
  @Input() total: number;
  @Input() attentionMessage: string;
  @Input() dictionary: { selected?: string; all?: string; };

  @Output() buttonClick: EventEmitter<GridHeaderButton> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'grid-header';

  private readonly authDataService = inject(AuthDataService);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  config = cloneDeep(gridHeaderComponentConfig);
  buttons: GridHeaderButton[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.template) {
      this.setButtons(this.template);
    }
  }

  private setButtons(template: GridHeaderTemplate) {
    this.buttons = this.config.buttonsByTemplates[template] && this.config.buttonsByTemplates[template]
      .filter(button =>
        (!button.feature || this.authDataService.checkFeature(button.feature)) &&
        (!button.permission || this.authDataSnapshotService.checkPermission(button.permission))
      );
  }

  onButtonClick(button: GridHeaderButton) {
    this.buttonClick.emit(button);
  }
}
