import { USearchFilter, USearchFilterType } from '@shift/ulib';

import {
  CustomerDataType,
  DaysOfWeek,
  GlobalSearchFilter,
  GridHeaderTemplate,
  HeaderMenuIconsTemplate,
  MultiDeleteConfirmDictionary,
  NavigationPaths,
  TablePageConfig,
  ThreeDotsPopoverItem
} from '@app/shared/models';
import {
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType,
  AuthModuleName,
  AuthModuleRouteTemplatesFeature,
  AuthModuleRouteTemplatesFeatureType,
  AuthPermission
} from '@app/auth/models';
import { RouteDirection } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routeTemplates';

const threeDotsItems: ThreeDotsPopoverItem[] = [
  {
    name: `${DICTIONARY_PATH}.table.threeDotsMenu.newRouteFromTemplate`,
    action: 'new-routes',
    permission: AuthPermission.CreateRoutes
  },
  { name: `${DICTIONARY_PATH}.table.threeDotsMenu.duplicate`, action: 'duplicate' },
  { name: `${DICTIONARY_PATH}.table.threeDotsMenu.delete`, action: 'delete' }
];

const multiDeleteDictionary: MultiDeleteConfirmDictionary = {
  title: `${DICTIONARY_PATH}.table.deleteConfirm.multi.title`,
  itemsAmount: `${DICTIONARY_PATH}.table.deleteConfirm.multi.itemsAmount`,
  invalidNumber: `${DICTIONARY_PATH}.table.deleteConfirm.multi.invalidNumber`,
  confirmButton: `${DICTIONARY_PATH}.table.deleteConfirm.multi.confirmButton`,
  enterNumberToConfirm: `${DICTIONARY_PATH}.table.deleteConfirm.multi.enterNumberToConfirm`,
  itemsToDeleteWarning: `${DICTIONARY_PATH}.table.deleteConfirm.multi.itemsToDeleteWarning`
};

const tablePageConfig: TablePageConfig = {
  id: 'route-templates',
  trackingId: 'Routes & rides, Route templates-Table',
  headerMenuIconsTemplate: HeaderMenuIconsTemplate.RouteTemplates,
  gridHeaderTemplate: GridHeaderTemplate.General,
  tableConfig: {
    columns: [
      {
        prop: 'check',
        name: '',
        width: 43,
        hideable: false,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideDivider: true
      },
      {
        prop: 'activities',
        name: '',
        width: 48,
        maxWidth: 48,
        frozenLeft: true,
        hideable: false,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        custom: true,
        hideDivider: true,
        cellTemplateName: 'routeTemplatesActivitiesCell',
        cellClass: 'activities-body-cell'
      },
      {
        prop: 'code',
        name: `${DICTIONARY_PATH}.table.number`,
        filterType: 'select',
        minWidth: 156,
        cellTemplateName: 'routeTemplatesCodeCell',
        cellClass: 'datatable-body-cell_progress'
      },
      {
        prop: 'name',
        name: `${DICTIONARY_PATH}.table.name`,
        filterType: 'select',
        minWidth: 184
      },
      {
        prop: 'direction',
        name: `${DICTIONARY_PATH}.table.direction`,
        filterType: 'select',
        minWidth: 143,
        cellTemplateName: 'routeTemplatesDirectionCell',
        filterTemplateName: 'routeTemplatesDirectionCellFilter'
      },
      {
        prop: `day-${DaysOfWeek.Sunday}`,
        name: 'general.days.sunday',
        filterType: 'select',
        minWidth: 116,
        maxWidth: 116,
        cellTemplateName: 'routeTemplatesTimeCell',
        filterTemplateName: 'routeTemplatesTimeCellFilter',
        filterTypeItemsFnName: 'timeFilterTypeItemsFn',
        filterTypeItemsSearchFnName: 'timeFilterTypeItemsSearchFn',
        comparatorFnName: 'sortTimeRange'
      },
      {
        prop: `day-${DaysOfWeek.Monday}`,
        name: 'general.days.monday',
        filterType: 'select',
        minWidth: 116,
        maxWidth: 116,
        cellTemplateName: 'routeTemplatesTimeCell',
        filterTemplateName: 'routeTemplatesTimeCellFilter',
        filterTypeItemsFnName: 'timeFilterTypeItemsFn',
        filterTypeItemsSearchFnName: 'timeFilterTypeItemsSearchFn',
        comparatorFnName: 'sortTimeRange'
      },
      {
        prop: `day-${DaysOfWeek.Tuesday}`,
        name: 'general.days.tuesday',
        filterType: 'select',
        minWidth: 116,
        maxWidth: 116,
        cellTemplateName: 'routeTemplatesTimeCell',
        filterTemplateName: 'routeTemplatesTimeCellFilter',
        filterTypeItemsFnName: 'timeFilterTypeItemsFn',
        filterTypeItemsSearchFnName: 'timeFilterTypeItemsSearchFn',
        comparatorFnName: 'sortTimeRange'
      },
      {
        prop: `day-${DaysOfWeek.Wednesday}`,
        name: 'general.days.wednesday',
        filterType: 'select',
        minWidth: 116,
        maxWidth: 116,
        cellTemplateName: 'routeTemplatesTimeCell',
        filterTemplateName: 'routeTemplatesTimeCellFilter',
        filterTypeItemsFnName: 'timeFilterTypeItemsFn',
        filterTypeItemsSearchFnName: 'timeFilterTypeItemsSearchFn',
        comparatorFnName: 'sortTimeRange'
      },
      {
        prop: `day-${DaysOfWeek.Thursday}`,
        name: 'general.days.thursday',
        filterType: 'select',
        minWidth: 116,
        maxWidth: 116,
        cellTemplateName: 'routeTemplatesTimeCell',
        filterTemplateName: 'routeTemplatesTimeCellFilter',
        filterTypeItemsFnName: 'timeFilterTypeItemsFn',
        filterTypeItemsSearchFnName: 'timeFilterTypeItemsSearchFn',
        comparatorFnName: 'sortTimeRange'
      },
      {
        prop: `day-${DaysOfWeek.Friday}`,
        name: 'general.days.friday',
        filterType: 'select',
        minWidth: 116,
        maxWidth: 116,
        cellTemplateName: 'routeTemplatesTimeCell',
        filterTemplateName: 'routeTemplatesTimeCellFilter',
        filterTypeItemsFnName: 'timeFilterTypeItemsFn',
        filterTypeItemsSearchFnName: 'timeFilterTypeItemsSearchFn',
        comparatorFnName: 'sortTimeRange'
      },
      {
        prop: `day-${DaysOfWeek.Saturday}`,
        name: 'general.days.saturday',
        filterType: 'select',
        minWidth: 116,
        maxWidth: 116,
        cellTemplateName: 'routeTemplatesTimeCell',
        filterTemplateName: 'routeTemplatesTimeCellFilter',
        filterTypeItemsFnName: 'timeFilterTypeItemsFn',
        filterTypeItemsSearchFnName: 'timeFilterTypeItemsSearchFn',
        comparatorFnName: 'sortTimeRange'
      },
      {
        prop: 'totalPassengers',
        name: `${DICTIONARY_PATH}.table.passengers`,
        filterType: 'select',
        minWidth: 82
      },
      {
        prop: 'shuttleCompanyName',
        name: `${DICTIONARY_PATH}.table.shuttleCompany`,
        filterType: 'select',
        minWidth: 122,
        cellTemplateName: 'routeTemplatesShuttleCompanyNameCell',
        filterTemplateName: 'routeTemplatesShuttleCompanyNameCellFilter'
      },
      {
        prop: 'carTypeName',
        name: `${DICTIONARY_PATH}.table.carType`,
        filterType: 'select',
        minWidth: 95
      },
      {
        prop: 'customerName',
        name: `${DICTIONARY_PATH}.table.customer`,
        filterType: 'select',
        minWidth: 160,
        hideColumn: true,
        feature: {
          module: AuthModuleName.RouteTemplates,
          name: AuthModuleRouteTemplatesFeature.Type,
          values: [ AuthModuleRouteTemplatesFeatureType.ShuttleCompany ]
        }
      }
    ],
    showCounters: true,
    disabledRowProp: 'locked'
  },
  modalComponent: null
};

const tagsGlobalFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'tagIds',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.tags`
};

const departmentsGlobalFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'departmentIds',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.departments`
};

const passengersGlobalFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'passengerIds',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.passengers`,
  feature: {
    module: AuthModuleName.Passengers
  }
};

const citiesGlobalFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'cities',
  type: USearchFilterType.InputCitiesMultiselect,
  title: `${DICTIONARY_PATH}.globalFilters.cities`
};

const branchesGlobalFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'branchIds',
  type: USearchFilterType.Multiselect,
  title: `${DICTIONARY_PATH}.globalFilters.branches`,
  resetFiltersByNames: [ citiesGlobalFilter.name ]
};

const globalFilters: USearchFilter[] = [
  departmentsGlobalFilter,
  passengersGlobalFilter,
  {
    items: [],
    value: [],
    name: 'schoolIds',
    type: USearchFilterType.Multiselect,
    title: `${DICTIONARY_PATH}.globalFilters.schools`
  },
  {
    items: [],
    value: [],
    name: 'classTypeIds',
    type: USearchFilterType.Multiselect,
    title: `${DICTIONARY_PATH}.globalFilters.classes`
  },
  citiesGlobalFilter
];

const globalFiltersArmy: USearchFilter[] = [
  tagsGlobalFilter,
  {
    ...branchesGlobalFilter,
    title: `${DICTIONARY_PATH}.globalFilters.bases`
  },
  citiesGlobalFilter
];

const globalFiltersBusiness: GlobalSearchFilter[] = [
  {
    ...departmentsGlobalFilter,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Generic, AuthModuleDepartmentsFeatureType.Iec ]
    }
  },
  {
    ...departmentsGlobalFilter,
    title: `${DICTIONARY_PATH}.globalFilters.departmentsDivision`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Division ]
    }
  },
  passengersGlobalFilter,
  branchesGlobalFilter,
  citiesGlobalFilter
];

const customerDataFilters = {
  departmentIds: CustomerDataType.Departments,
  passengerIds: CustomerDataType.Passengers,
  schoolIds: CustomerDataType.Schools,
  classTypeIds: CustomerDataType.ClassTypes
};

const customerDataFiltersArmy = {
  tagIds: CustomerDataType.Tags,
  branchIds: CustomerDataType.Branches
};

const customerDataFiltersBusiness = {
  departmentIds: CustomerDataType.Departments,
  passengerIds: CustomerDataType.Passengers,
  branchIds: CustomerDataType.Branches
};

const routeTemplatesComponent = {
  routeBuilderUrl: NavigationPaths.BuilderRouteTemplates,
  threeDotsItems,
  readonlyModeHiddenColumnProps: [ 'activities' ],
  tablePageConfig,
  multiDeleteDictionary,
  dictionary: {
    required: 'general.required',
    delete: 'general.delete',
    cancel: 'general.cancel',
    edit: 'general.edit',
    view: 'general.view',
    inactive: `${DICTIONARY_PATH}.table.inactive`,
    directions: {
      [RouteDirection.Forward]: `${DICTIONARY_PATH}.table.directions.pickup`,
      [RouteDirection.Backward]: `${DICTIONARY_PATH}.table.directions.dropOff`
    },
    emptyBody: {
      firstSection: {
        title: `${DICTIONARY_PATH}.table.emptyBody.firstSection.title`,
        body: {
          1: `${DICTIONARY_PATH}.table.emptyBody.firstSection.body.1`
        }
      },
      secondSection: {
        title: `${DICTIONARY_PATH}.table.emptyBody.secondSection.title`,
        body: {
          1: `${DICTIONARY_PATH}.table.emptyBody.secondSection.body.1`,
          2: `${DICTIONARY_PATH}.table.emptyBody.secondSection.body.2`,
          3: `${DICTIONARY_PATH}.table.emptyBody.secondSection.body.3`
        }
      },
      createTemplate: `${DICTIONARY_PATH}.table.emptyBody.createNewTemplate`
    },
    newRoutes: `${DICTIONARY_PATH}.routesFromTemplates.create`,
    newRoutesDisabled: `${DICTIONARY_PATH}.routesFromTemplates.createDisabled`,
    deleteConfirm: `${DICTIONARY_PATH}.table.deleteConfirm.single`
  },
  globalFilters,
  customerDataFilters
};

const routeTemplatesArmyComponent = {
  ...routeTemplatesComponent,
  globalFilters: globalFiltersArmy,
  customerDataFilters: customerDataFiltersArmy
};

const routeTemplatesBusinessComponent = {
  ...routeTemplatesComponent,
  globalFilters: globalFiltersBusiness,
  customerDataFilters: customerDataFiltersBusiness
};

const routeTemplatesShuttleCompanyComponent = {
  ...routeTemplatesComponent,
  tablePageConfig: {
    ...routeTemplatesComponent.tablePageConfig,
    showGlobalSearch: false
  }
};

export const routeTemplatesComponentConfig = {
  default: routeTemplatesComponent,
  [AuthModuleRouteTemplatesFeatureType.Army]: routeTemplatesArmyComponent,
  [AuthModuleRouteTemplatesFeatureType.Generic]: routeTemplatesBusinessComponent,
  [AuthModuleRouteTemplatesFeatureType.ShuttleCompany]: routeTemplatesShuttleCompanyComponent
};
