import { DistanceUnit, Languages } from '@app/shared/models';

export const appConfig = {
  store: {
    name: 'Shift'
  },
  idPrefix: 'at',
  defaultDistanceUnit: <DistanceUnit.Km | DistanceUnit.Mile>DistanceUnit.Km,
  defaultLanguage: Languages.Hebrew,
  languages: [
    {
      name: 'עברית',
      value: Languages.Hebrew
    },
    {
      name: 'English',
      value: Languages.English
    }
  ],
  rtlLanguages: [
    Languages.Hebrew
  ],
  activities: {
    maxDisplayCounter: 99
  },
  retryRequestTimeout: 5000,
  progressBarId: 'at-layout-progressbar',
  storageKeys: {
    usersTablesProps: 'usersTablesProps'
  }
};
