import { UInputCitiesCityLocation } from '@shift/ulib';

import { Cities, GeneralButton } from '@app/shared/models';

export enum CityCombinationsRuleType {
  FixedGroup = 'FixedGroup',
  DontCombine = 'DontCombine',
  Combine = 'Combine'
}

export enum CityCombinationsButtonId {
  NewRule = 'NewRule',
  ActivateAll = 'ActivateAll',
  InactivateAll = 'InactivateAll'
}

export interface CityCombinationsRule {
  id: number;
  name: string;
  isActive: boolean;
  useTollRoads: boolean;
  type: string;
  baseCities: UInputCitiesCityLocation[];
  attachedCities: UInputCitiesCityLocation[];
  branchId: number;
}

export interface CityCombinationsButton extends Omit<GeneralButton, 'id'> {
  id: CityCombinationsButtonId;
}

export class CityCombinations {
  citiesTree: Cities;
  rules: CityCombinationsRule[] = [];
  useTollRoads: boolean;
}
