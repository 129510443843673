import { Component, HostBinding, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UButtonSize, UButtonView, UGridService, UStep } from '@shift/ulib';

import {
  CitiesCombinationsByBranchService,
  HeaderMenuIconsService,
  HeaderSearchFiltersService,
  LocalizationService,
  TrackingService
} from '@app/shared/services';
import { Errors, HeaderMenuIconValue, ModalActions } from '@app/shared/models';
import {
  AuthModuleName,
  AuthModulePassengersFeature,
  AuthModulePassengersFeatureType,
  AuthModuleRoutePlannerFeature,
  AuthModuleRoutePlannerFeatureSkippedPassengersExport
} from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { PassengersExtraFilters, PassengersMode } from '@app/passengers/models';
import { routesConfig } from '@app/routes/configs';
import { PassengersDataService } from '@app/passengers/services';
import { RoutePlannerService } from '@app/route-planner/services';
import { RoutePlannerDirectionType, RoutePlannerSkippedPassenger } from '@app/route-planner/models';
import { BuilderFullStep } from '@app/builder/models';
import {
  BuilderCommonService,
  BuilderFullCalculationService,
  BuilderFullCalculationStoreService,
  BuilderFullCommonService,
  BuilderFullMapService,
  BuilderRoutesStoreService,
  BuilderFullRoutesGenerationService
} from '@app/builder/services';
import { builderFullConfig } from '@app/builder/configs';
import { builderFullComponentConfig } from './builder-full.component.config';

@Component({
  selector: 'app-builder-full',
  templateUrl: './builder-full.component.html',
  styleUrls: [ './builder-full.component.scss', './builder-full.component.rtl.scss' ],
  providers: [
    CitiesCombinationsByBranchService,
    BuilderFullCalculationService,
    BuilderFullCalculationStoreService,
    BuilderFullCommonService,
    BuilderFullMapService
  ]
})
export class BuilderFullComponent implements OnInit, OnDestroy {
  @ViewChild('calculationFailedTemplates', { static: true }) calculationFailedTemplates: TemplateRef<any>;

  @HostBinding('class') hostClasses: string = 'builder-full';

  private readonly uGridService = inject(UGridService);
  private readonly localizationService = inject(LocalizationService);
  private readonly headerMenuIconsService = inject(HeaderMenuIconsService);
  private readonly routePlannerService = inject(RoutePlannerService);
  private readonly trackingService = inject(TrackingService);
  private readonly passengersDataService = inject(PassengersDataService);
  private readonly authDataService = inject(AuthDataService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly builderFullMapService = inject(BuilderFullMapService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  public readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  public readonly builderFullCommonService = inject(BuilderFullCommonService);
  public readonly builderFullCalculationService = inject(BuilderFullCalculationService);
  public readonly builderCommonService = inject(BuilderCommonService);
  public readonly builderFullRoutesGenerationService = inject(BuilderFullRoutesGenerationService);

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(builderFullComponentConfig);
  savingRoutes$: Observable<boolean> = this.builderFullCalculationService.savingRoutes$;
  summaryLoading$: Observable<boolean> = this.builderFullCalculationService.summaryLoading$;
  isRtl: boolean = this.localizationService.isRtl();
  passengersFeatureType: AuthModulePassengersFeatureType;
  authModulePassengersFeatureType = AuthModulePassengersFeatureType;
  skippedPassengersExport: AuthModuleRoutePlannerFeatureSkippedPassengersExport;
  passengersMode = PassengersMode;
  uButtonView = UButtonView;
  uButtonSize = UButtonSize;

  get visibleComponents() {
    return this.builderFullCommonService.visibleComponents;
  }

  get passengers() {
    return this.builderFullCommonService.passengers;
  }

  get message() {
    return this.builderFullCommonService.message;
  }

  get passengersExtraFilters(): PassengersExtraFilters {
    return this.builderFullCalculationService.passengersExtraFilters;
  }

  get skippedPassengers(): RoutePlannerSkippedPassenger[] {
    return this.builderFullCommonService.skippedPassengers;
  }

  ngOnInit() {
    this.initHeaderIcons();
    this.onHeaderIconsAction();
    this.initModuleFeatures();
    this.resetGlobalAndPassengersFilters();
    this.updateErrorTemplatesByErrorCode();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.builderRoutesStoreService.routeActiveSet(null);
    this.builderRoutesStoreService.routesSet([]);
    this.builderRoutesStoreService.setRoutesFullChanged(false);
    this.builderRoutesStoreService.resetMessagesTable();

    this.resetGlobalAndPassengersFilters();
  }

  private updateErrorTemplatesByErrorCode() {
    this.builderFullCalculationService.updateErrorTemplatesByErrorCode(Errors.TemplateDoesntHaveEnoughSeatsForDemands, this.calculationFailedTemplates);
  }

  private initHeaderIcons() {
    this.headerMenuIconsService.setMenuIconsByTemplate(this.config.headerMenuIconsTemplate[BuilderFullStep.SelectPassengers]);
  }

  private onHeaderIconsAction() {
    this.headerMenuIconsService.menuIconClick$.pipe(takeUntil(this.unsubscribe))
      .subscribe(({ value }) => {
        const activeStep = this.builderFullCommonService.steps.find(step => step.active);

        switch (value) {
          case HeaderMenuIconValue.NewBuilder:
          case HeaderMenuIconValue.RegularRoute:
          case HeaderMenuIconValue.FixedRoute:
          case HeaderMenuIconValue.ShiftsRoute:
          case HeaderMenuIconValue.SpecialRoute:
          case HeaderMenuIconValue.ShuttleRoute:
          case HeaderMenuIconValue.DeliveryRoute: {
            if (
              activeStep.value === BuilderFullStep.PlanningDetails ||
              activeStep.value === BuilderFullStep.RoutesResults
            ) {
              this.builderCommonService.updateRouteType(routesConfig.routeTypeByHeaderMenuIconValue[value]);

              this.trackEvent(`Global Header - Plus icon ${value}`);

              this.addRoute();
            }

            break;
          }

          case HeaderMenuIconValue.Plus: {
            if (activeStep.value === BuilderFullStep.SelectPassengers) {
              this.trackEvent('Global Header - Plus icon add passenger');

              this.passengersDataService.openAddEditModal()
                .pipe(
                  switchMap(data => data.content.action),
                  take(1),
                  takeUntil(this.unsubscribe),
                  filter(({ type }) => type === ModalActions.Submit)
                )
                .subscribe(() => this.headerSearchFiltersService.applyFilters());
            }

            break;
          }
        }
      });
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${builderFullConfig.trackingId}] - ${event}`);
  }

  private initModuleFeatures() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.Passengers, AuthModulePassengersFeature.Type)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(passengersFeatureType => {
        this.passengersFeatureType = passengersFeatureType;
      });

    this.authDataService.moduleFeatureByName$(AuthModuleName.RoutePlanner, AuthModuleRoutePlannerFeature.SkippedPassengersExport)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(skippedPassengersExport => {
        this.skippedPassengersExport = skippedPassengersExport;
      });
  }

  private openPassengers(): void {
    this.builderFullCommonService.updateVisible({
      passengers: true,
      routes: false,
      main: false,
      map: false
    });

    this.openFilter();

    this.builderFullCommonService.updateSteps([
      {
        value: BuilderFullStep.SelectPassengers,
        active: true,
        clickable: false
      },
      {
        value: BuilderFullStep.PlanningDetails,
        active: true,
        clickable: false
      },
      {
        value: BuilderFullStep.RoutesResults,
        active: false,
        clickable: true
      }
    ]);

    this.headerMenuIconsService.setMenuIconsByTemplate(builderFullComponentConfig.headerMenuIconsTemplate[BuilderFullStep.SelectPassengers]);
  }

  updatePassengersAll(data: number): void {
    this.builderFullCommonService.updatePassengers({
      all: data
    });
  }

  selectPassengers(data: any): void {
    this.builderFullCommonService.updatePassengers({
      selected: data.length,
      selectedItems: data
    });

    this.builderFullCalculationService.updatePassengerIds(data.map(ob => ob.passengerId));

    this.builderFullCommonService.updateSteps([
      {
        value: BuilderFullStep.RoutesResults,
        disabled: true,
        clickable: false
      }
    ]);
  }

  columnsFilteredPassengers(data: boolean): void {
    this.builderFullCommonService.updatePassengers({
      columnsFiltered: data
    });
  }

  resetColumnsFilterPassengers(data: boolean): void {
    this.builderFullCommonService.updatePassengers({
      resetColumnsFilter: data
    });
  }

  openFilter(): void {
    this.trackEvent('click on open planning menu');

    this.builderFullCommonService.updateVisible({
      filter: true,
      main: false
    });

    this.builderFullCommonService.updateSteps([
      {
        value: BuilderFullStep.PlanningDetails,
        active: true,
        clickable: false
      }
    ]);
  }

  closeFilter(): void {
    this.builderFullCommonService.updateVisible({
      filter: false
    });

    this.builderFullCommonService.updateSteps([
      {
        value: BuilderFullStep.PlanningDetails,
        active: false,
        clickable: true
      }
    ]);
  }

  closeMain(): void {
    this.builderFullRoutesGenerationService.updateDisabled(false);

    this.builderFullCommonService.updateVisible({
      main: false
    });
  }

  addRoute(): void {
    const filterValue = this.builderFullCalculationService.filter.value;

    this.routePlannerService.new({
      activeDays: filterValue.activeDays,
      calculationTimeType: filterValue.calculationTimeType,
      customTime: filterValue.customTime,
      directions: filterValue.direction === RoutePlannerDirectionType.BackwardAndForward ?
        [ RoutePlannerDirectionType.Forward, RoutePlannerDirectionType.Backward ] : [ filterValue.direction ],
      endDate: filterValue.endDate,
      startDate: filterValue.startDate,
      shuttleCompanyId: filterValue.shuttleCompanyId,
      routeType: this.builderCommonService.routeType()
    })
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.builderRoutesStoreService.routesAdd(
          {
            status: null,
            routeId: data.routeId,
            code: data.number,
            name: data.name,
            direction: data.direction,
            days: data.activeDays,
            startDate: data.startDate,
            endDate: data.endDate,
            rideStartDateTime: data.startTime,
            rideEndDateTime: data.endTime,
            totalPassengers: data.totalPassengers,
            carTypeName: data.carType ? data.carType.name : null,
            carTypeCapacity: data.carType ? data.carType.seatsCount : null,
            shuttleCompany: data.shuttleCompany ? data.shuttleCompany.name : null,
            timeType: data.timeType,
            locked: false,
            allowEmptyStations: data.allowEmptyStations,
            branchNames: data.branchNames
          },
          false
        );

        this.builderCommonService.editRouteIdSet(data.routeId);

        this.builderFullCommonService.updateVisible({
          routes: true,
          main: true,
          filter: false
        });
      });
  }

  editRoute() {
    this.builderFullRoutesGenerationService.updateDisabled(true);

    this.builderFullCommonService.updateVisible({
      routes: true,
      main: true,
      filter: false
    });
  }

  showSkippedPassengers() {
    if (this.skippedPassengers && this.skippedPassengers.length) {
      this.builderFullCommonService.showSkippedPassengerPopup(this.skippedPassengers);
    }
  }

  finishAndCreate(): void {
    this.trackEvent('click on finish');

    this.builderFullCalculationService.finishAndCreate();
  }

  toggleSettings(): void {
    this.trackEvent('click on policy icon');

    this.builderFullCommonService.updateVisible({
      settings: !this.visibleComponents.settings
    });
  }

  loadMap(): void {
    this.trackEvent('click on map icon');

    if (this.passengers.selected > 0) {
      this.builderFullMapService.loadMapData();
    } else {
      this.builderFullCommonService.updateVisible({
        map: true,
        passengers: false
      });
    }
  }

  loadPassengersGrid(): void {
    this.trackEvent('click on table icon');

    this.builderFullCommonService.updateVisible({
      map: false,
      passengers: true
    });
  }

  resetGlobalAndPassengersFilters(): void {
    const passengersTableNames: string[] = [
      'passengers',
      'passengers-army',
      'passengers-generic'
    ];

    passengersTableNames.forEach(passengersTableName => {
      this.uGridService.saveTablePropToLocalStorage({
        propName: 'columnsFiltersStore',
        tableName: passengersTableName,
        userId: this.uGridService.getUserIdFromLocalStorage(),
        saveTableProps: true,
        value: []
      });
    });
  }

  onStepClick(step: UStep) {
    switch (step.value) {
      case BuilderFullStep.SelectPassengers: {
        this.openPassengers();

        this.builderFullRoutesGenerationService.updateShow(false);

        break;
      }

      case BuilderFullStep.PlanningDetails: {
        this.openFilter();

        break;
      }

      case BuilderFullStep.RoutesResults: {
        this.builderFullCommonService.updateSteps([
          {
            value: BuilderFullStep.SelectPassengers,
            active: false,
            clickable: true
          },
          {
            value: BuilderFullStep.PlanningDetails,
            active: false,
            clickable: true
          },
          {
            value: BuilderFullStep.RoutesResults,
            active: true,
            clickable: false
          }
        ]);

        this.builderFullCommonService.updateVisible({
          passengers: false,
          routes: true,
          main: false,
          map: false,
          filter: false
        });

        this.builderFullRoutesGenerationService.updateShow(true);

        break;
      }
    }
  }

  onBuilderRoutesInit() {
    this.headerMenuIconsService.setMenuIconsByTemplate(builderFullComponentConfig.headerMenuIconsTemplate[BuilderFullStep.RoutesResults]);
  }

  onAiVsAlgoSummaryClick(isOpen: boolean) {
    if (!isOpen) {
      this.builderFullCalculationService.getCompare();
    } else {
      this.builderFullCalculationService.updateAlgoAiCompareSummary(null);
    }
  }
}
