import { UDatePickerRangeMode, UDateRangePreset } from '@shift/ulib';

import { RoutesExportType } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.export';
const TRACKING_ID: string = 'Routes, Details Report';

const dictionary = {
  period: `${DICTIONARY_PATH}.period`,
  email: `${DICTIONARY_PATH}.email`,
  selectPeriod: `${DICTIONARY_PATH}.selectPeriod`,
  download: `${DICTIONARY_PATH}.download`,
  send: `${DICTIONARY_PATH}.send`,
  sendPopup: `${DICTIONARY_PATH}.sendPopup`,
  downloadPopup: `${DICTIONARY_PATH}.downloadPopup`,
  confirm: 'general.confirm',
  cannotDownload: 'general.cannotDownload',
  required: 'general.required'
};

const datePresets: UDateRangePreset[] = [ UDateRangePreset.CurrentWeek, UDateRangePreset.NextWeek, UDateRangePreset.Custom ];

export const routesExportComponentConfig = {
  [RoutesExportType.Pdf]: {
    dictionary: {
      ...dictionary,
      title: `${DICTIONARY_PATH}.titlePdf`
    },
    datePresets,
    trackingId: TRACKING_ID,
    datePickerRangeMode: UDatePickerRangeMode.Week
  },
  [RoutesExportType.Excel]: {
    dictionary: {
      ...dictionary,
      title: `${DICTIONARY_PATH}.titleExcel`
    },
    datePresets,
    trackingId: TRACKING_ID,
    defaultActivePreset: UDateRangePreset.CurrentWeek,
    datePickerRangeMode: UDatePickerRangeMode.Default,
    maxPeriodSelection: {
      amount: 6,
      unit: 'days'
    }
  }
};
