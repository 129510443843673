import { InputShift, InputContact, AddEditModalConfig, CitiesPolicyCity, CityCombinationsButton, CityCombinationsRule, CityCombinations, AreasButton, Areas, Area } from '@app/shared/models';

export type BranchTabData = BranchDetails | InputShift[] | BranchShuttlePolicy | CityCombinations | Areas;

export enum BranchesOption {
  Without
}

export enum BranchesAddEditModalTabDataName {
  Details = 'details',
  Shifts = 'shifts',
  CitiesPolicy = 'shuttlePolicy',
  CitiesCombinations = 'citiesCombinations',
  BranchAreas = 'branchAreas'
}

export enum BranchesAddEditGeneralButtonId {
  CopyFromBranch = 'copyFromBranch'
}

export enum BranchesAddEditCopyFromBranchMode {
  CitiesPolicy = 'citiesPolicy',
  CitiesCombinations = 'citiesCombinations',
  BranchAreas = 'branchAreas'
}

export enum BranchesAddEditEventDataName {
  CopyFromBranchCitiesPolicy = 'copyFromBranchCitiesPolicy',
  CopyFromBranchCitiesCombinations = 'copyFromBranchCitiesCombinations',
  CopyFromBranchAreas = 'copyFromBranchAreas'
}

export class BranchBase {
  branchId: number;
  name: string;
  address: {
    fullAddress: string;
    latitude: number;
    longitude: number;
  };
  comments: string;
  isMain: boolean;

  constructor(values: object) {
    Object.assign(this, values);
  }
}

export class Branch extends BranchBase {
  phone: string;
}

export class BranchDetails extends BranchBase {
  reservationEnabled: boolean;
  contacts: InputContact[];
}

export class BranchShuttlePolicy {
  cities: CitiesPolicyCity[];
}

export interface BranchAddEdit {
  details: BranchDetails;
  shifts: InputShift[];
  shuttlePolicy: BranchShuttlePolicy;
  cityCombinationRules: CityCombinationsRule[];
}

export interface BranchesAddEditComponentConfig {
  unavailableMainBranchTabs: BranchesAddEditModalTabDataName[];
  trackingTabDataNames: { [key: string]: string; };
  dictionary: { [key: string]: string; };
  addEditModalConfig: AddEditModalConfig;
}

export interface BranchesAddEditCityCombinationsButton extends Omit<CityCombinationsButton, 'id'> {
  id: BranchesAddEditGeneralButtonId;
}

export interface BranchesAddEditAreasButton extends Omit<AreasButton, 'id'> {
  id: BranchesAddEditGeneralButtonId;
}

export interface BranchAddEditCopyFromBranchData {
  branch: number;
  field: (string | number)[];
}

export interface BranchesAddEditCopyBody {
  branchId: number;
  copyBranchId: number;
}

export interface BranchesAddEditCopyCityCombinationsBody extends BranchesAddEditCopyBody {
  cityCombinationRules: CityCombinationsRule[];
  copyCityCombinationRuleIds: number[];
}

export interface BranchesAddEditCopyAreasBody extends BranchesAddEditCopyBody {
  areas: Area[];
  copyAreaIds: number[];
}
