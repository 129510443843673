import {
  Action,
  combineReducers
} from '@ngrx/store';

import * as coreReducers from '@app/shared/state/reducers';
import * as routesDailyReducer from '@app/routes/state/reducers/routes-daily.reducer';
import * as routesWeeklyReducer from '@app/routes/state/reducers/routes-weekly.reducer';
import * as routesViewTypeReducer from '@app/routes/state/reducers/routes-view-type.reducer';

export const FEATURE_KEY = 'routes';

export interface RoutesState {
  daily: routesDailyReducer.State;
  weekly: routesWeeklyReducer.State;
  viewType: routesViewTypeReducer.State;
}

export interface State extends coreReducers.State {
  [FEATURE_KEY]: RoutesState;
}

export function reducers(state: RoutesState | undefined | any, action: Action) {
  return combineReducers({
    [routesDailyReducer.FEATURE_KEY]: routesDailyReducer.reducer,
    [routesWeeklyReducer.FEATURE_KEY]: routesWeeklyReducer.reducer,
    [routesViewTypeReducer.FEATURE_KEY]: routesViewTypeReducer.reducer
  })(state, action);
}
