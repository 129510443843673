import {
  OnInit,
  Input,
  Output,
  Component,
  ViewChild,
  HostBinding,
  TemplateRef,
  EventEmitter,
  inject,
  DestroyRef
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

import { CitiesPolicyCity } from '@app/shared/models';
import { LocalizationService } from '@app/shared/services';
import { citiesPolicyComponentConfig } from './cities-policy.component.config';

@Component({
  selector: 'app-cities-policy',
  templateUrl: './cities-policy.component.html',
  styleUrls: [ './cities-policy.component.scss', './cities-policy.component.rtl.scss' ]
})
export class CitiesPolicyComponent implements OnInit {
  @Input() citiesPolicyForm: UntypedFormArray;

  @Output() updateCityAction: EventEmitter<CitiesPolicyCity> = new EventEmitter();

  @ViewChild('toggleCell', { static: true }) public toggleCell: TemplateRef<HTMLElement>;
  @ViewChild('maxTravelTimeCell', { static: true }) public maxTravelTimeCell: TemplateRef<HTMLElement>;
  @ViewChild('cityCentralStationAddressCell', { static: true }) public cityCentralStationAddressCell: TemplateRef<HTMLElement>;

  @HostBinding('class') hostClasses: string = 'cities-policy';

  private readonly destroyRef = inject(DestroyRef);
  private readonly translateService = inject(TranslateService);
  private readonly localizationService = inject(LocalizationService);

  private isAllowedFilters: { yes: string; no: string; };

  config = cloneDeep(citiesPolicyComponentConfig);
  dictionary = cloneDeep(citiesPolicyComponentConfig.dictionary);
  isRtl: boolean = this.localizationService.isRtl();
  lang: string = this.localizationService.getLanguage();

  rowIdentity: Function = row => row.cityId;

  ngOnInit() {
    this.initForm();
    this.translate();
  }

  private initForm() {
    if (this.citiesPolicyForm && this.citiesPolicyForm.controls.length) {
      this.citiesPolicyForm.controls.forEach((control: UntypedFormGroup, index) => {
        control.addControl('index', new UntypedFormControl(index));

        if (!control.value.enabled) {
          control.get('useCityCentralStation').disable();
        }
      });
    }
  }

  private translate() {
    this.translateService.get([ this.config.dictionary.yes, this.config.dictionary.no ])
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(translations => {
        this.isAllowedFilters = {
          yes: translations[this.config.dictionary.yes],
          no: translations[this.config.dictionary.no]
        };

        this.attachTemplatesToColumns();
      });
  }

  private attachTemplatesToColumns() {
    this.config.grid.columns.forEach(column => {
      column['cellTemplate'] = column.cellTemplateName && this[column.cellTemplateName];
      column['filterTypeItemsFn'] = column.filterTypeItemsFnName && this[column.filterTypeItemsFnName];
      column['filterTypeItemsSearchFn'] = column.filterTypeItemsSearchFnName && this[column.filterTypeItemsSearchFnName];
    });
  }

  private isAllowedFilterItems = () => [ this.isAllowedFilters.yes, this.isAllowedFilters.no ];

  private isAllowedFilterItemsSearch = (value, searchValue): boolean => {
    if (searchValue === this.isAllowedFilters.yes) {
      return value;
    }

    if (searchValue === this.isAllowedFilters.no) {
      return !value;
    }
  };

  onEditValuesClose(data) {
    if (data.prop === 'cityCentralStationAddress.fullAddress') {
      if (data.newValue) {
        this.citiesPolicyForm.at(data.row.index).get('cityCentralStationAddress').patchValue(
          {
            fullAddress: data.newValue,
            latitude: data.row['cityCentralStationAddress.fullAddress.lat'],
            longitude: data.row['cityCentralStationAddress.fullAddress.lng'],
            cityId: data.row['cityCentralStationAddress.fullAddress.cityId'],
            addressId: data.row['cityCentralStationAddress.fullAddress.addressId']
          }
        );
      } else {
        (this.citiesPolicyForm.at(data.row.index).get('cityCentralStationAddress') as UntypedFormGroup).reset();
      }
    }

    if (data.prop === 'maxDurationMinutes') {
      if (data.newValue) {
        this.citiesPolicyForm.at(data.row.index).get('maxDurationMinutes').patchValue(data.newValue);
      }
    }

    this.citiesPolicyForm.markAsDirty();
    this.citiesPolicyForm.updateValueAndValidity();
    this.updateCityAction.emit(data.row);
  }

  cellClick(event, enabled: boolean) {
    if (!enabled) {
      event.stopPropagation();
    }
  }

  onEnabledToggle(newValue: boolean, index: number) {
    if (newValue) {
      this.citiesPolicyForm.at(index).get('useCityCentralStation').enable();

      if (!this.citiesPolicyForm.at(index).get('maxDurationMinutes').value) {
        this.citiesPolicyForm.at(index).get('maxDurationMinutes').patchValue(this.config.minMaxDurationMinutes);
      }
    } else {
      this.citiesPolicyForm.at(index).get('useCityCentralStation').disable();
    }
  }
}
