import { Component, OnInit, HostBinding, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { cloneDeep } from 'lodash';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { AppLanguage } from '@app/shared/models';
import { RoutePlannerSkippedPassenger } from '@app/route-planner/models';
import { BuilderFullSkippedAction, BuilderFullSkippedReason } from '@app/builder/models';
import { builderFullSkippedPassengersComponentConfig } from './builder-full-skipped-passengers.component.config';

@Component({
  selector: 'app-builder-full-skipped-passengers',
  templateUrl: './builder-full-skipped-passengers.component.html',
  styleUrls: [ './builder-full-skipped-passengers.component.scss', './builder-full-skipped-passengers.component.rtl.scss' ]
})
export class BuilderFullSkippedPassengersComponent implements OnInit {
  @Output() action: EventEmitter<BuilderFullSkippedAction> = new EventEmitter<BuilderFullSkippedAction>();

  @ViewChild('reasonColumn', { static: true }) public reasonColumn: TemplateRef<any>;

  @HostBinding('class') hostClasses: string = 'builder-full-skipped-passengers';

  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  columns = [];
  skippedPassengers: RoutePlannerSkippedPassenger[];
  skippedPassengersNoDemands: RoutePlannerSkippedPassenger[];
  config = cloneDeep(builderFullSkippedPassengersComponentConfig);
  builderFullSkippedAction = BuilderFullSkippedAction;

  constructor(
    private localizationService: LocalizationService,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.skippedPassengersNoDemands = this.skippedPassengers.filter(passenger => passenger.skipReason === BuilderFullSkippedReason.PassengerNoDemands);

    this.attachTemplatesToColumns();
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${event}`);
  }

  private attachTemplatesToColumns() {
    const columns = this.config.columns;

    if (columns) {
      columns.forEach(column => {
        if (column.cellTemplateName) {
          column['cellTemplate'] = this[column.cellTemplateName];
        }
      });
    }

    this.columns = columns;
  }

  download() {
    this.trackEvent('Passengers not added popup - click on Download file');

    this.action.emit(BuilderFullSkippedAction.Download);
  }

  close(type: number) {
    if (type === BuilderFullSkippedAction.CloseContinue) {
      this.trackEvent('Passengers not added popup - click on Close & Continue');
    }

    this.action.emit(BuilderFullSkippedAction.Close);
  }
}
