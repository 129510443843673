import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { NextYearDataService } from '@app/next-year/services';

@Injectable()
export class NextYearModeInterceptor implements HttpInterceptor {
  constructor(
    private nextYearDataService: NextYearDataService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const nextYearMode = this.nextYearDataService.isInNextYearMode;

    return nextYearMode ? next.handle(this.addNextYearModeHeader(req, nextYearMode)) : next.handle(req);
  }

  private addNextYearModeHeader(request: HttpRequest<any>, value: boolean) {
    return request.clone({ headers: request.headers.set('next-year-mode', `${value}`) });
  }
}
