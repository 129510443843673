<u-modal-entity
  [title]="config().dictionary.title"
  [buttons]="config().buttons"
  [isRtl]="isRtl"
  [formChanged]="addEditForm.dirty"
  (action)="checkActions($event)"
>
  <tabset
    class="u-tabset-pills"
    type="pills"
  >
    <tab
      id="info"
      heading="{{config().dictionary.infoTab | translate}}"
      [disabled]="!addEditForm.valid"
    >
      <div class="accompanies-add-edit__tab-content" tabindex="-1">
        <div class="u-modal__body">
          <app-accompanies-add-edit-info
            [addEditForm]="addEditForm"
            [mode]="mode"
            (loadAccompany)="onLoadAccompany($event)"
            (updateForm)="updateForm($event)"
            (closeModal)="closeModal()"
          ></app-accompanies-add-edit-info>
        </div>
      </div>
    </tab>
  </tabset>
</u-modal-entity>
