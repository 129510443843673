<div class="builder-full-summary__header">
  <div class="builder-full-summary__header-title">{{config().dictionary.aiVsAlgoSummary | translate}}</div>
  <div
    class="builder-full-summary__header-close"
    (click)="close.emit()"
  ></div>
</div>
<div class="builder-full-summary__explanation">
  @if (numberOfRoutes()) {
    <div class="builder-full-summary__explanation-title">
      {{(config().dictionary.aiFoundSuggestions | translate: { count: numberOfRoutes() })}}
    </div>
  }
  <button
    uButton
    class="builder-full-summary__explanation-btn"
    (click)="onShowExplanationChange()"
  >
    {{config().dictionary.howDoesItWork | translate}}
  </button>

  @if (showExplanation()) {
    <p class="builder-full-summary__explanation-text">
      {{config().dictionary.howDoesItWorkDescription | translate}}
    </p>
  }
</div>
<div class="builder-full-summary__wrapper">
  @if (summary()) {
    <table class="builder-full-summary__table">
      <thead class="builder-full-summary__table-header">
      <tr class="builder-full-summary__table-header-row">
        <th class="builder-full-summary__table-header-column"></th>
        <th class="builder-full-summary__table-header-column">{{config().dictionary.originalPlaning | translate}}</th>
        <th class="builder-full-summary__table-header-column">{{config().dictionary.ai | translate}}</th>
        <th class="builder-full-summary__table-header-column builder-full-summary__table-header-column_percentage">
          {{config().dictionary.gap | translate}} %
        </th>
      </tr>
      </thead>
      <tbody class="builder-full-summary__table-body">
        @for (row of rows(); track row.prop) {
          <tr class="builder-full-summary__table-body-row">
            <td class="builder-full-summary__table-row-value">{{row.name | translate}}</td>
            <td class="builder-full-summary__table-row-value">
              <div [class.builder-full-summary__table-row-value-percentage]="row.symbol === '%'">
                {{row.original}} {{row.symbol | translate}}
              </div>
            </td>
            <td class="builder-full-summary__table-row-value">
              <div [class.builder-full-summary__table-row-value-percentage]="row.symbol === '%'">
                {{row.alternative}} {{row.symbol | translate}}
              </div>
            </td>
            <td class="builder-full-summary__table-row-value builder-full-summary__table-row-value_gap">
              <div class="builder-full-summary__table-row-value-percentage">
                {{row.gapPercentage}} %
              </div>

              @if (row.alternative !== row.original) {
                <span
                  class="builder-full-summary__table-row-value-icon"
                  [class.builder-full-summary__table-row-value-icon_up]="row.alternative > row.original"
                ></span>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <div class="loader loader_circle loader_all-events"></div>
  }
</div>
