const DICTIONARY_PATH: string = 'activities';

const dictionary: { [key: string]: string; } = {
  title: `${DICTIONARY_PATH}.title`,
  search: `${DICTIONARY_PATH}.search`,
  types: `${DICTIONARY_PATH}.types`,
  resetFilters: `${DICTIONARY_PATH}.resetFilters`,
  noNotifications: `${DICTIONARY_PATH}.noNotifications`,
  deleteConfirm: `${DICTIONARY_PATH}.deleteConfirm`,
  excelFileName: `${DICTIONARY_PATH}.excelFileName`
};

export const activitiesComponentConfig = {
  trackingId: 'Activity Log',
  dictionaryPath: DICTIONARY_PATH,
  dictionary
};
