import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
  standalone: true
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], param: { name: string; value: string | number; }): any[] {
    if (param) {
      return items.filter(item => `${item[param.name]}`.toLowerCase().includes(`${param.value}`.toLowerCase()));
    }

    return items;
  }
}
