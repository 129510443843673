import { URangePreset } from '@shift/ulib';

import { AuthModuleAccompanyFeature, AuthModuleBulkChangesFeature, AuthModuleBulkChangesFeatureType, AuthModuleName, AuthPermission } from '@app/auth/models';
import { AccompanyCostType, AccompanyPriceType } from '@app/accompanies/models';
import { ShuttleCompanyCostCalculationMode } from '@app/shuttle-companies/models';
import { BulkChangeChangeType, BulkChangeSubject } from '@app/bulk-change/models';

const DICTIONARY_PATH: string = 'bulkChange';

const driverAction = {
  value: BulkChangeSubject.Driver,
  name: `${DICTIONARY_PATH}.actions.driver`,
  feature: {
    module: AuthModuleName.BulkChanges,
    name: AuthModuleBulkChangesFeature.Type,
    values: [
      AuthModuleBulkChangesFeatureType.Generic
    ]
  },
  permission: AuthPermission.EditRoutes
};

const vehicleAction = {
  value: BulkChangeSubject.Vehicle,
  name: `${DICTIONARY_PATH}.actions.vehicle`,
  feature: {
    module: AuthModuleName.BulkChanges,
    name: AuthModuleBulkChangesFeature.Type,
    values: [
      AuthModuleBulkChangesFeatureType.Generic
    ]
  },
  permission: AuthPermission.EditRoutes
};

const assignActionType = { value: BulkChangeChangeType.Assign, name: `${DICTIONARY_PATH}.actionTypes.assign` };
const removeActionType = { value: BulkChangeChangeType.Remove, name: `${DICTIONARY_PATH}.actionTypes.remove` };
const swapActionType = { value: BulkChangeChangeType.Swap, name: `${DICTIONARY_PATH}.actionTypes.swap` };

export const bulkChangeComponentConfig = {
  trackingId: 'Bulk change',
  dateFormat: 'dd/MM/yy',
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    routes: `${DICTIONARY_PATH}.routes`,
    titleMessage: `${DICTIONARY_PATH}.titleMessage`,
    type: `${DICTIONARY_PATH}.type`,
    period: `${DICTIONARY_PATH}.period`,
    actionType: `${DICTIONARY_PATH}.actionType`,
    action: `${DICTIONARY_PATH}.action`,
    comment: `${DICTIONARY_PATH}.comment`,
    save: `${DICTIONARY_PATH}.save`,
    savedMessage: `${DICTIONARY_PATH}.savedMessage`,
    accompanyCost: `${DICTIONARY_PATH}.accompanyCost`,
    hours: `${DICTIONARY_PATH}.hours`,
    shuttleCompanyRidePrice: `${DICTIONARY_PATH}.shuttleCompanyRidePrice`,
    executionPrice: `${DICTIONARY_PATH}.executionPrice`,
    overridePricelistToDefault: `${DICTIONARY_PATH}.overridePricelistToDefault`,
    actions: {
      accompany: `${DICTIONARY_PATH}.actions.accompany`,
      driver: `${DICTIONARY_PATH}.actions.driver`,
      hour: `${DICTIONARY_PATH}.actions.hour`,
      cancel: `${DICTIONARY_PATH}.actions.cancel`,
      restore: `${DICTIONARY_PATH}.actions.restore`,
      sendToSC: `${DICTIONARY_PATH}.actions.sendToSC`
    },
    uSelectS: 'uSelectS',
    select: 'general.select',
    unplanned: 'general.unplanned',
    closeConfirm: 'general.closeConfirm',
    yes: 'general.yes',
    no: 'general.no'
  },
  changePresetsType: {
    planned: URangePreset.All,
    fixed: URangePreset.DisplayedDay
  },
  actions: [
    {
      value: BulkChangeSubject.ShuttleCompanyCloseRide,
      name: `${DICTIONARY_PATH}.actions.shuttleCompanyCloseRide`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      },
      permission: AuthPermission.EditRoutes
    },
    {
      value: BulkChangeSubject.ShuttleCompanyOpenRide,
      name: `${DICTIONARY_PATH}.actions.shuttleCompanyOpenRide`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      },
      permission: AuthPermission.EditRoutes
    },
    {
      value: BulkChangeSubject.Accompany,
      name: `${DICTIONARY_PATH}.actions.accompany`,
      feature: {
        module: AuthModuleName.Accompany,
        name: AuthModuleAccompanyFeature.Type
      },
      permission: AuthPermission.AssignAccompanyToRides
    },
    {
      value: BulkChangeSubject.AccompanyCost,
      name: `${DICTIONARY_PATH}.actions.accompanyCost`,
      feature: {
        module: AuthModuleName.Accompany,
        name: AuthModuleAccompanyFeature.Type
      },
      permission: AuthPermission.AssignAccompanyToRides
    },
    vehicleAction,
    {
      ...vehicleAction,
      name: `${DICTIONARY_PATH}.actions.vehicleSC`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      }
    },
    driverAction,
    {
      ...driverAction,
      name: `${DICTIONARY_PATH}.actions.driverSC`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      }
    },
    {
      value: BulkChangeSubject.Cancel,
      name: `${DICTIONARY_PATH}.actions.cancel`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.Generic,
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      },
      permission: AuthPermission.EditRoutes
    },
    {
      value: BulkChangeSubject.Hour,
      name: `${DICTIONARY_PATH}.actions.hour`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.Generic
        ]
      },
      permission: AuthPermission.EditRoutes
    },
    {
      value: BulkChangeSubject.Restore,
      name: `${DICTIONARY_PATH}.actions.restore`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.Generic,
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      },
      permission: AuthPermission.CreateRoutes
    },
    {
      value: BulkChangeSubject.ShuttleCompany,
      name: `${DICTIONARY_PATH}.actions.shuttleCompany`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.Generic,
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      },
      permission: AuthPermission.EditRoutes
    },
    {
      value: BulkChangeSubject.SendToSC,
      name: `${DICTIONARY_PATH}.actions.sendToSC`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.Generic
        ]
      }
    },
    {
      value: BulkChangeSubject.ShuttleCompanyRidePrice,
      name: `${DICTIONARY_PATH}.actions.shuttleCompanyRidePrice`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.Generic,
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      },
      permission: AuthPermission.EditRoutes
    },
    {
      value: BulkChangeSubject.ExecutionPrice,
      name: `${DICTIONARY_PATH}.actions.executionPrice`,
      feature: {
        module: AuthModuleName.BulkChanges,
        name: AuthModuleBulkChangesFeature.Type,
        values: [
          AuthModuleBulkChangesFeatureType.ShuttleCompany
        ]
      },
      permission: AuthPermission.EditRoutes
    }
  ],
  actionTypes: {
    default: [ assignActionType, removeActionType, swapActionType ],
    [BulkChangeSubject.Vehicle]: [ assignActionType, removeActionType ],
    [BulkChangeSubject.ShuttleCompany]: [ assignActionType, removeActionType ]
  },
  unavailableChangeTypeSubjects: [
    BulkChangeSubject.Cancel,
    BulkChangeSubject.Hour,
    BulkChangeSubject.Restore,
    BulkChangeSubject.AccompanyCost,
    BulkChangeSubject.SendToSC,
    BulkChangeSubject.ShuttleCompanyRidePrice,
    BulkChangeSubject.ShuttleCompanyCloseRide,
    BulkChangeSubject.ShuttleCompanyOpenRide,
    BulkChangeSubject.ExecutionPrice
  ],
  messageSuffixes: {
    changesSubject: {
      [BulkChangeSubject.Accompany]: 'acc change',
      [BulkChangeSubject.AccompanyCost]: 'accompany cost',
      [BulkChangeSubject.Driver]: 'driver change',
      [BulkChangeSubject.Cancel]: 'cancel ride',
      [BulkChangeSubject.Restore]: 'restore ride',
      [BulkChangeSubject.Hour]: 'change hour',
      [BulkChangeSubject.SendToSC]: 'send to shuttle company',
      [BulkChangeSubject.ShuttleCompany]: 'shuttle company change',
      [BulkChangeSubject.ShuttleCompanyRidePrice]: 'ride price change',
      [BulkChangeSubject.ShuttleCompanyCloseRide]: 'close rides change',
      [BulkChangeSubject.ShuttleCompanyOpenRide]: 'open rides change',
      [BulkChangeSubject.Vehicle]: 'vehicle change',
      [BulkChangeSubject.ExecutionPrice]: 'execution price change'
    },
    changeType: {
      [BulkChangeChangeType.Assign]: 'assign',
      [BulkChangeChangeType.Remove]: 'remove',
      [BulkChangeChangeType.Swap]: 'swap'
    }
  },
  accompanyPriceTypes: [
    { value: AccompanyPriceType.Manual, name: `${DICTIONARY_PATH}.accompanyPriceTypes.manual` },
    { value: AccompanyPriceType.Auto, name: `${DICTIONARY_PATH}.accompanyPriceTypes.auto` }
  ],
  accompanyCostTypes: [
    { value: AccompanyCostType.Fixed, name: `${DICTIONARY_PATH}.accompanyCostTypes.fix` },
    { value: AccompanyCostType.ByManualHour, name: `${DICTIONARY_PATH}.accompanyCostTypes.hour` }
  ],
  costCalculationModes: [
    { value: ShuttleCompanyCostCalculationMode.Manual, name: `${DICTIONARY_PATH}.costCalculationModes.manual` },
    { value: ShuttleCompanyCostCalculationMode.ContractDefaultPricelist, name: `${DICTIONARY_PATH}.costCalculationModes.byContract` }
  ]
};
