import { Pipe, PipeTransform } from '@angular/core';

import { MonitoringDashboardStationBase } from '@app/monitoring-dashboard/models';

@Pipe({
  name: 'monitoringDashboardStationsSearch'
})
export class MonitoringDashboardStationsSearchPipe implements PipeTransform {
  transform(stations: MonitoringDashboardStationBase[], searchText: string): MonitoringDashboardStationBase[] {
    if (!stations || !searchText) {
      return stations;
    }

    return stations.filter(station => {
      const stationMatch = Object.keys(station)
        .filter(key => typeof station[key] === 'string')
        .some(stringKey => station[stringKey].toLowerCase().indexOf(searchText.toLowerCase()) !== -1);

      const passengerMatch = station.passengers.some(passenger => Object.keys(passenger)
        .filter(key => typeof passenger[key] === 'string')
        .some(stringKey => passenger[stringKey].toLowerCase().indexOf(searchText.toLowerCase()) !== -1));

      return stationMatch || passengerMatch;
    });
  }
}
