<div class="builder-fines__main">
  <u-grid
    [tableName]="'fines'"
    [saveTableProps]="true"
    [columns]="columns"
    [rows]="rows"
    [columnMode]="'force'"
    [headerHeight]="45"
    [headerOffset]="10"
    [footerHeight]="0"
    [rowHeight]="55"
    [scrollbarV]="true"
    [scrollbarH]="true"
    [isRtl]="isRtl"
    [lang]="lang"
    [showColumnsFilter]="true"
    [messages]="config.dictionary.uGrid | translate"
  ></u-grid>

  <ng-template #dateColumn let-row="row">
    <div class="builder-fines__text">{{row.date | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH}}</div>
  </ng-template>

  <ng-template #finePriceColumn let-row="row">
    <div class="builder-fines__text">{{row.finePrice}} {{config.dictionary.amountCurrency | translate}}</div>
  </ng-template>

  <ng-template #createdByColumn let-row="row">
    <div class="builder-fines__block">
      <span class="builder-fines__text">{{row.createdByName}}</span>
      <br/>
      <span class="builder-fines__created-by">{{row.createdDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH}}</span>
      <span class="builder-fines__inline">{{row.createdDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.TIME_FORMAT}}</span>
    </div>
  </ng-template>

  <ng-template #deletedByColumn let-row="row">
    <div class="builder-fines__block" *ngIf="row.deletedDate && row.deletedByName && row.deletedById">
      <span class="builder-fines__text">{{config.dictionary.deletedBy | translate}} {{row.deletedByName}}</span>
      <br/>
      <span class="builder-fines__removed-by">{{row.deletedDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH}}</span>
      <span class="builder-fines__inline">{{row.deletedDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.TIME_FORMAT}}</span>
    </div>
    <div class="builder-fines__block" *ngIf="!row.deletedDate && !row.deletedByName && !row.deletedById">
      <button type="button" class="builder-fines__remove-btn" (click)="deleteFine(row.id, row.trackId);">
        {{config.dictionary.deleteBtn | translate}}
      </button>
    </div>
  </ng-template>

  <ng-template #fineDescriptionColumn let-row="row">
    <div class="builder-fines__description">
      {{row.fineDescription}}
    </div>
  </ng-template>

  <ng-template #commentColumn let-row="row">
    <div class="builder-fines__comment">
      {{row.comment}}
    </div>
  </ng-template>
</div>
