import { USidebarMenuItem, USidebarTopMenuItem } from '@shift/ulib';

import {
  AuthModuleFeature,
  AuthModuleFeatureGroup,
  AuthOptionalModule
} from '@app/auth/models';

export enum SidebarMenuHeaderItemValue {
  Logout = 'logout',
  SwitchCustomer = 'switchCustomer',
  AccessibilityAffidavit = 'accessibilityAffidavit'
}

export interface SidebarMenuItem extends Omit<USidebarMenuItem, 'id'> {
  id?: string;
  optionalModule?: AuthOptionalModule;
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  checkBySelfSCId?: boolean;
}

export interface SidebarTopMenuItem extends Omit<USidebarTopMenuItem, 'id' | 'children'> {
  id?: string;
  optionalModule?: AuthOptionalModule;
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  checkBySelfSCId?: boolean;
  children?: SidebarMenuItem[];
}
