<div
  *ngIf="bulkChangeDataService.form.get('general') as generalForm"
  class="bulk-change__wrapper"
>
  <div class="bulk-change__header">
    <div class="bulk-change__header-item">
      <div class="bulk-change__header-title-wrapper">
        <span class="bulk-change__header-title">
          {{config().dictionary.title | translate}}
        </span>
        <span class="bulk-change__header-title-text">
          ({{routes.length}} {{config().dictionary.routes | translate}})
        </span>
      </div>
      <div class="bulk-change__header-close" (click)="closeClick()"></div>
    </div>
    <div class="bulk-change__header-item">
      <span class="bulk-change__header-title-text">
        {{config().dictionary.titleMessage | translate}}
      </span>
      <span
        *ngIf="datesChangesForm"
        class="bulk-change__header-title-range"
      >
        {{datesChangesForm.get('dates').value[0] | date: config().dateFormat}} -
        {{datesChangesForm.get('dates').value[datesChangesForm.get('dates').value.length - 1] | date: config().dateFormat}}
      </span>
    </div>
  </div>

  <u-group
    class="u-group_app-bulk-change"
    [name]="config().dictionary.action | translate"
  >
    <div class="bulk-change__group">
      <div class="bulk-change__group-field">
        <u-select-s
          [classUInput]="'u-input_arrow-light u-input_light'"
          [items]="config().actions"
          [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
          [formControl]="subjectBulkChange"
          [valid]="subjectBulkChange.valid"
          [messages]="config().dictionary.uSelectS | translate"
          [empty]="false"
          [classUDropdownContent]="'u-dropdown-content_app-default'"
          [placeholder]="config().dictionary.select | translate"
        ></u-select-s>
      </div>
      <div class="bulk-change__group-field">
        <u-select-s
          *ngIf="subjectBulkChange.value && !(config().unavailableChangeTypeSubjects | includes: subjectBulkChange.value)"
          [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
          [classUInput]="'u-input_arrow-light u-input_light'"
          [items]="config().actionTypes[subjectBulkChange.value] ?? config().actionTypes.default"
          [formControl]="generalForm.controls['changeType']"
          [valid]="generalForm.controls['changeType'].valid"
          [messages]="config().dictionary.uSelectS | translate"
          [empty]="false"
          [placeholder]="config().dictionary.select | translate"
        ></u-select-s>
      </div>
    </div>
  </u-group>

  <ng-container *ngIf="subjectBulkChange.value === bulkChangeSubject.AccompanyCost">
    <u-group
      class="u-group_app-bulk-change"
      [name]="config().dictionary.accompanyCost | translate"
    >
      <div class="bulk-change__group">
        <div class="bulk-change__group-field">
          <u-select-s
            [classUInput]="'u-input_arrow-light u-input_light'"
            [items]="config().accompanyCostTypes"
            [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
            [formControl]="accompanyCostForm.get('costType')"
            [messages]="config().dictionary.uSelectS | translate"
            [empty]="false"
            [classUDropdownContent]="'u-dropdown-content_app-default'"
            [placeholder]="config().dictionary.select | translate"
          ></u-select-s>
        </div>
        <div class="bulk-change__group-field">
          <input
            uInput
            [mask]="'0*.00'"
            [dropSpecialCharacters]="false"
            [valid]="accompanyCostForm.get('costPerHour').valid"
            [formControl]="accompanyCostForm.get('costPerHour')"
          >
        </div>
      </div>
    </u-group>
    <u-group
      *ngIf="accompanyCostForm.get('costType').value === accompanyCostType.ByManualHour"
      class="u-group_app-bulk-change"
      [name]="config().dictionary.hours | translate"
    >
      <div class="bulk-change__group">
        <div class="bulk-change__group-field">
          <u-select-s
            [classUInput]="'u-input_arrow-light u-input_light'"
            [items]="config().accompanyPriceTypes"
            [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
            [formControl]="accompanyCostForm.get('priceType')"
            [messages]="config().dictionary.uSelectS | translate"
            [empty]="false"
            [classUDropdownContent]="'u-dropdown-content_app-default'"
            [placeholder]="config().dictionary.select | translate"
          ></u-select-s>
        </div>
        <div class="bulk-change__group-field" *ngIf="accompanyCostForm.get('priceType').value === accompanyPriceType.Manual">
          <input
            uInput
            [mask]="'0*.00'"
            [dropSpecialCharacters]="false"
            [valid]="accompanyCostForm.get('hours').valid"
            [formControl]="accompanyCostForm.get('hours')"
          >
        </div>
      </div>
    </u-group>
  </ng-container>

  @if ({
    ridePriceForm: generalForm.get('ridePrice'),
    executionPrice: generalForm.get('executionPrice')
  }; as form) {
    @if (subjectBulkChange.value === bulkChangeSubject.ShuttleCompanyRidePrice) {
      <u-group
        class="u-group_app-bulk-change"
        [name]="config().dictionary.shuttleCompanyRidePrice | translate"
      >
        <div class="bulk-change__group">
          <div class="bulk-change__group-field">
            <u-select-s
              [classUInput]="'u-input_arrow-light u-input_light'"
              [items]="config().costCalculationModes"
              [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
              [formControl]="form.ridePriceForm.get('costCalculationMode')"
              [messages]="config().dictionary.uSelectS | translate"
              [empty]="false"
              [classUDropdownContent]="'u-dropdown-content_app-default'"
              [placeholder]="config().dictionary.select | translate"
            />
          </div>

          @if (form.ridePriceForm.get('cost'); as costControl) {
            <div class="bulk-change__group-field">
              <u-input-number
                uId="bulk-change-ride-price"
                [formControl]="costControl"
                [min]="0"
                [valid]="costControl.valid"
                [placeholder]="config().dictionary.shuttleCompanyRidePrice | translate"
                [decimal]="true"
                [maxDecimalDigits]="2"
              />
            </div>
          }

        </div>
      </u-group>

      @if (form.ridePriceForm.get('overridePricelistToDefault'); as overridePricelistToDefaultControl) {
        <u-group class="u-group_app-bulk-change">
          <div class="bulk-change__group">
            <div class="bulk-change__checkbox-field">
              <u-checkbox
                class="u-checkbox_box u-checkbox_app-round-checkbox"
                [formControl]="overridePricelistToDefaultControl"
              />
              <span class="bulk-change__checkbox-field-text">
                {{config().dictionary.overridePricelistToDefault | translate}}
              </span>
            </div>
          </div>
        </u-group>
      }
    }

    @if (subjectBulkChange.value === bulkChangeSubject.ExecutionPrice) {
      <u-group
        class="u-group_app-bulk-change"
        [nameTooltip]="config().dictionary.executionPrice | translate"
        [name]="config().dictionary.executionPrice | translate"
      >
        <div class="bulk-change__group">
          <div class="bulk-change__group-field">
            <u-input-number
              uId="bulk-change-ride-price"
              [formControl]="form.executionPrice"
              [min]="0"
              [valid]="form.executionPrice.valid"
              [placeholder]="config().dictionary.executionPrice | translate"
              [decimal]="true"
              [maxDecimalDigits]="2"
            />
          </div>
        </div>
      </u-group>
    }
  }

  <u-group
    *ngIf="bulkChangeDataService.generalDetailsForm"
    class="u-group_app-bulk-change"
    [name]="bulkChangeDataService.config.fieldName | translate"
  >
    <div
      class="bulk-change__group"
      [ngClass]="{
        'bulk-change__group_reverse': subjectBulkChange.value === bulkChangeSubject.Hour
      }"
    >
      <div
        *ngFor="let item of (bulkChangeDataService.generalDetailsForm.controls | keyvalue); let first = first"
        class="bulk-change__group-field"
      >
        <u-select-s
          *ngIf="item.key !== 'time'"
          [placement]="
            isRtl && first || item.key === 'timeType' ?
            [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] :
            [ 'bottom-left', 'bottom-left', 'top-left', 'top-left' ]
          "
          [classUInput]="'u-input_arrow-light u-input_light'"
          [items]="bulkChangeDataService.bulkChangeSelectItems"
          [formControl]="item.value"
          [valid]="item.value.valid"
          [messages]="config().dictionary.uSelectS | translate"
          [empty]="false"
          [placeholder]="config().dictionary.select | translate"
          (click)="bulkChangeDetails(item)"
        ></u-select-s>
        <u-input-time
          *ngIf="item.key === 'time'"
          [class]="'u-input-time_center'"
          [defaultTime]="false"
          [valid]="item.value.valid"
          [formControl]="item.value"
          (click)="bulkChangeDetails(item)"
        ></u-input-time>
      </div>
    </div>
  </u-group>
  <u-group
    *ngIf="periodsForm"
    class="u-group_app-bulk-change"
    [name]="config().dictionary.type | translate"
  >
    <div class="bulk-change__group">
      <div class="bulk-change__group-field">
        <input
          uInput
          type="text"
          [class]="'u-input_text-grey'"
          [disabled]="true"
          [value]="config().dictionary.unplanned | translate"
        >
      </div>
      <div class="bulk-change__group-field"></div>
    </div>
  </u-group>
  <u-group
    class="u-group_app-bulk-change"
    *ngIf="subjectBulkChange.value !== bulkChangeSubject.SendToSC"
    [name]="(periodsForm ? config().dictionary.period : config().dictionary.actionType) | translate"
  >
    <app-routes-period
      *ngIf="datesChangesForm"
      [typeFormControl]="typeBulkChange"
      [changes]="datesChangesForm.value"
      [changePresetsType]="config().changePresetsType"
      [isCustomHeaderDate]="true"
      (updatePeriod)="bulkChangeDataService.updatePeriod($event)"
      (updateDates)="bulkChangeDataService.updateDates($event)"
    ></app-routes-period>
    <app-routes-periods
      *ngIf="periodsForm"
      [defaultData]="bulkChangeDataService.routesPeriodsDefaultData"
      [form]="periodsForm"
      [hidePlusIcon]="isSCCustomer()"
      (clickOnPlusIcon)="clickOnPeriodPlusIcon()"
    ></app-routes-periods>
  </u-group>

  <u-group
    class="u-group_app-bulk-change"
    *ngIf="subjectBulkChange.value === bulkChangeSubject.SendToSC"
    [name]="config().dictionary.period | translate"
  >
    <u-input-date-range
      [class]="'u-input-date-range_blue'"
      [container]="'body'"
      [placement]="[ 'bottom' ]"
      [formControl]="datesRange"
      [sundayFirstDay]="true"
      [defaultDate]="false"
      [showIcon]="false"
      [required]="true"
      [maxPeriodSelectionFromSelectedDate]="{ amount: maxDaysRange(), unit: 'days' }"
      [valid]="datesRange.valid"
      [messages]="'uInputDateRange' | translate"
    ></u-input-date-range>
  </u-group>

  <u-group
    *ngIf="subjectBulkChange.value !== bulkChangeSubject.Cancel && subjectBulkChange.value !== bulkChangeSubject.Restore && subjectBulkChange.value !== bulkChangeSubject.SendToSC"
    class="u-group_app-bulk-change"
    [name]="config().dictionary.comment | translate"
  >
    <textarea
      uTextarea
      [formControl]="generalForm.controls['comment']"
      [placeholder]="config().dictionary.comment | translate"
    ></textarea>
  </u-group>
  <div class="bulk-change__btn-wrapper">
    <button
      uButton
      [disabled]="generalForm.invalid"
      (click)="save()"
    >
      {{config().dictionary.save | translate}}
    </button>
  </div>
</div>
