import { inject, Injectable, signal } from '@angular/core';
import { isEqual } from 'lodash';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { forkJoin, of, pipe } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

import { patchSignal, rxMethod, tapResponse } from '@app/shared/utils';
import { BuilderService } from '@app/builder/services';
import { BuilderAgendaState, BuilderGetShuttleCompaniesBody, BuilderGetShuttleCompanyContractsBody } from '@app/builder/models';

@Injectable()
export class BuilderAgendaStoreService {
  private readonly builderService = inject(BuilderService);

  readonly #state = signal<BuilderAgendaState>({ shuttleCompanies: null, shuttleCompanyContracts: null });

  readonly state = this.#state.asReadonly();

  readonly state$ = toObservable(this.state);

  readonly getShuttleCompaniesAndContracts = rxMethod<{
    shuttleCompanies: BuilderGetShuttleCompaniesBody;
    shuttleCompanyContracts: BuilderGetShuttleCompanyContractsBody;
  }>(
    pipe(
      distinctUntilChanged(isEqual),
      switchMap(({ shuttleCompanies, shuttleCompanyContracts }) =>
        forkJoin([
          this.builderService.getShuttleCompanies(shuttleCompanies),
          shuttleCompanyContracts ? this.builderService.getShuttleCompanyContracts(shuttleCompanyContracts) : of(null)
        ])
          .pipe(
            tapResponse(
              data => patchSignal(this.#state, {
                shuttleCompanies: data[0],
                shuttleCompanyContracts: data[1]
              }),
              () => null
            )
          )
      )
    )
  );

  reset() {
    this.#state.set({ shuttleCompanies: null, shuttleCompanyContracts: null });
  }
}
