import { AuthModuleCustomerStationsFeatureNameLocalization } from '@app/auth/models';
import { mapConfig } from '@app/shared/configs';
import { ModalActions } from '@app/shared/models';
import { StationProp } from '@app/stations/models';

const DICTIONARY_PATH: string = 'stations.addEdit';

export const stationsAddEditComponentConfig = {
  trackingId: 'Station',
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    name: `${DICTIONARY_PATH}.name`,
    deleteConfirm: `${DICTIONARY_PATH}.deleteConfirm`,
    closeConfirm: `${DICTIONARY_PATH}.closeConfirm`,
    address: `${DICTIONARY_PATH}.address`,
    successful: 'general.successful',
    yes: 'general.yes',
    no: 'general.no',
    error: 'general.error',
    required: 'general.required',
    translations: {
      [AuthModuleCustomerStationsFeatureNameLocalization.AR]: {
        [StationProp.Name]: `${DICTIONARY_PATH}.translations.ar.name`
      }
    }
  },
  buttons: [
    { name: `${DICTIONARY_PATH}.delete`, action: ModalActions.Delete, clear: true },
    { name: `${DICTIONARY_PATH}.saveAndClose`, action: ModalActions.Submit, clear: false }
  ],
  map: {
    coords: mapConfig.coords,
    zoom: 15,
    point: {
      address: null,
      config: {
        alignCenter: true
      }
    }
  }
};
