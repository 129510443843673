import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UButtonModule, UPopoverModule, UCommonModule, UTooltipModule, UClickOutsideDirective } from '@shift/ulib';

import { SharedModule } from '@app/shared/shared.module';
import { AutofocusDirective } from '@app/shared/directives';
import { NotesComponent, NotesListComponent, NoteComponent } from '@app/notes/components';

const COMPONENTS = [
  NotesComponent,
  NotesListComponent,
  NoteComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    UButtonModule,
    UPopoverModule,
    UCommonModule,
    UTooltipModule,
    SharedModule,
    AutofocusDirective,
    UClickOutsideDirective
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    NotesComponent,
    NotesListComponent
  ]
})
export class NotesModule {}
