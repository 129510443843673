<div #monitoringDashboard class="monitoring-dashboard__wrapper">
  <div class="monitoring-dashboard__rides">
    <div class="monitoring-dashboard-rides">
      <div class="monitoring-dashboard-rides__header">
        <div class="monitoring-dashboard-rides__header-title">{{monitoringDashboardRidesService.rides.length}} {{config.dictionary.rides.title | translate}}</div>
        <input
          uInput
          type="text"
          [formControl]="ridesSearchText"
          [class]="'monitoring-dashboard-rides__header-search'"
          [placeholder]="config.dictionary.search | translate"
        >
        <span class="monitoring-dashboard-rides__header-search-icon"></span>
      </div>
      <div class="monitoring-dashboard-rides__list">
        <u-virtual-scroller class="monitoring-dashboard-rides__virtual-scroller" #scroll [items]="monitoringDashboardRidesService.rides | monitoringDashboardRidesSearch: ridesSearchText.value" [enableUnequalChildrenSizes]="true">
          <div
            *ngFor="let ride of scroll.viewPortItems"
            [ngClass]="{
              'monitoring-dashboard-ride_active': monitoringDashboardRidesService.rideActive && monitoringDashboardRidesService.rideActive.rideId === ride.rideId,
              'monitoring-dashboard-ride_red': ride.rideStatus === routeDailyRideStatus.New && ride.delayTime
            }"
            (click)="rideActiveSelect(ride); trackEvent('click on track')"
            id="monitoring-dashboard-rides-id-{{ride.rideId}}"
            class="monitoring-dashboard-ride"
          >
            <div class="monitoring-dashboard-ride__wrapper">
              <div class="monitoring-dashboard-ride__status">
                <div class="monitoring-dashboard-ride__status-block monitoring-dashboard-ride__status-block_black" *ngIf="ride.rideStatus === routeDailyRideStatus.New && !ride.delayTime">
                  <app-monitoring-dashboard-timer *ngIf="ride.startTimeDays < 1" [startTime]="ride.startDateTime" [textBeforeTime]="'general.timeIn' | translate"></app-monitoring-dashboard-timer>
                  <div class="monitoring-dashboard-ride__diff-time-days" *ngIf="ride.startTimeDays >= 1">{{ride.startTimeDays}} {{'monitoringDashboard.rides.days' | translate}}</div>
                </div>
                <div
                  class="monitoring-dashboard-ride__status-block monitoring-dashboard-ride__status-block_red"
                  *ngIf="ride.rideStatus === routeDailyRideStatus.New && ride.delayTime"
                >
                  {{ride.statusText}} {{ride.delayTime}} {{'monitoringDashboard.rideStatus.errorSecondPart' | translate}}
                </div>
                <div
                  class="monitoring-dashboard-ride__status-block monitoring-dashboard-ride__status-block_green monitoring-dashboard-ride__status-block_antena monitoring-dashboard-ride__status-block_antena-green"
                  *ngIf="ride.rideStatus === routeDailyRideStatus.FinishedMonitored || ride.rideStatus === routeDailyRideStatus.OngoingMonitored"
                >
                  {{ride.statusText}}
                </div>
                <div
                  class="monitoring-dashboard-ride__status-block monitoring-dashboard-ride__status-block_gray monitoring-dashboard-ride__status-block_antena monitoring-dashboard-ride__status-block_antena-gray"
                  *ngIf="ride.rideStatus === routeDailyRideStatus.Finished || ride.rideStatus === routeDailyRideStatus.Ongoing"
                >
                  {{ride.statusText}}
                </div>
                <div
                  class="monitoring-dashboard-ride__status-block monitoring-dashboard-ride__status-block_red monitoring-dashboard-ride__status-block_antena monitoring-dashboard-ride__status-block_antena-gray"
                  *ngIf="ride.rideStatus === routeDailyRideStatus.Cancelled"
                >
                  {{ride.statusText}}
                </div>
              </div>
              <div class="monitoring-dashboard-ride__details">
                <div class="monitoring-dashboard-ride__customer-name" *ngIf="ride.supplierName">{{ride.supplierName}}</div>
                <div class="monitoring-dashboard-ride__name" [ngClass]="{'monitoring-dashboard-ride__name_cross': ride.rideStatus === routeDailyRideStatus.Cancelled}">
                  {{ride.routeName}}
                </div>
                <div class="monitoring-dashboard-ride__info">
                  <span class="monitoring-dashboard-ride__time">{{ride.startDateTime | date: appConstants.TIME_FORMAT}} - {{ride.endDateTime | date: appConstants.TIME_FORMAT}}</span>
                </div>
              </div>
            </div>
          </div>
        </u-virtual-scroller>
      </div>
    </div>
  </div>
  <div class="monitoring-dashboard__stations" *ngIf="rideStationsShow">
    <div class="monitoring-dashboard-stations">
      <div class="monitoring-dashboard-stations__header">
        <div class="monitoring-dashboard-stations__header-wrapper">
          <div class="monitoring-dashboard-stations__header-title">
            {{(monitoringDashboardRidesService.rideActive.details.stations | itemsFieldSum: 'passengersCount')}}
            {{'monitoringDashboard.details.passengers' | translate}}
          </div>
          <button uButton class="monitoring-dashboard-stations__header-stations-toggle" (click)="toggleOpenStations()">
            {{(isStationsOpen ? config.dictionary.details.closeAllStations : config.dictionary.details.openAllStations) | translate}}
          </button>
          <div class="monitoring-dashboard-stations__close" (click)="rideStationsShowChange(false)"></div>
        </div>
        <input
          uInput
          type="text"
          [formControl]="stationsSearchText"
          [class]="'monitoring-dashboard-stations__header-search'"
          [placeholder]="config.dictionary.search | translate"
        >
        <span class="monitoring-dashboard-stations__header-search-icon"></span>
      </div>

      <ng-template #popoverDetails>
        <div class="monitoring-dashboard-details">
          <div class="monitoring-dashboard-details__header">
            <div class="monitoring-dashboard-details__header-close" (click)="closeMonitoringDashboardDetails()"></div>
            <div class="monitoring-dashboard-details__header-title">{{'monitoringDashboard.details.title' | translate}}</div>
          </div>
          <div class="monitoring-dashboard-details__content">
            <div class="monitoring-dashboard-details__row">
              <div class="monitoring-dashboard-details__row-title">{{'monitoringDashboard.details.platform' | translate}}</div>
              <div class="monitoring-dashboard-details__row-value">{{monitoringDetails.platform}}</div>
            </div>
            <div class="monitoring-dashboard-details__row">
              <div class="monitoring-dashboard-details__row-title">{{'monitoringDashboard.details.appVersion' | translate}}</div>
              <div class="monitoring-dashboard-details__row-value">{{monitoringDetails.appVersion}}</div>
            </div>
            <!--
            <div class="monitoring-dashboard-details__row">
              <div class="monitoring-dashboard-details__row-title">{{'monitoringDashboard.details.lastInteraction' | translate}}</div>
              <div class="monitoring-dashboard-details__row-value">{{monitoringDetails.lastInteractionDateTime}}</div>
            </div>
            -->
            <div class="monitoring-dashboard-details__row">
              <div class="monitoring-dashboard-details__row-title">{{'monitoringDashboard.details.batteryLevel' | translate}}</div>
              <div class="monitoring-dashboard-details__row-value">{{monitoringDetails.batteryLevel}}<span *ngIf="monitoringDetails.batteryLevel">%</span></div>
            </div>
            <div class="monitoring-dashboard-details__row">
              <div class="monitoring-dashboard-details__row-title">{{'monitoringDashboard.details.gpsStatus' | translate}}</div>
              <div class="monitoring-dashboard-details__row-value">{{monitoringDetails.gpsStatus}}</div>
            </div>
            <div class="monitoring-dashboard-details__row">
              <div class="monitoring-dashboard-details__row-title">{{'monitoringDashboard.details.firstCoordinate' | translate}}</div>
              <div class="monitoring-dashboard-details__row-value">{{monitoringDetails.firstCoordinate}}</div>
            </div>
            <div class="monitoring-dashboard-details__row">
              <div class="monitoring-dashboard-details__row-title">{{'monitoringDashboard.details.lastCoordinate' | translate}}</div>
              <div class="monitoring-dashboard-details__row-value">{{monitoringDetails.lastCoordinate}}</div>
            </div>
            <div class="monitoring-dashboard-details__close" (click)="closeMonitoringDashboardDetails($event)">{{'monitoringDashboard.details.close' | translate}}</div>
          </div>
        </div>
      </ng-template>

      <div class="monitoring-dashboard-stations__list">
        <div class="monitoring-dashboard-station" *ngIf="driver">
          <div class="monitoring-dashboard-station__header">
            <div
              class="monitoring-dashboard-station__header-name monitoring-dashboard-station__header-name_small"
              [uTooltip]="driver.name"
            >
              {{driver.name}}
            </div>
            <div class="monitoring-dashboard-station__header-info">
              <div class="monitoring-dashboard-station__header-info-type">
                <button
                  uButton
                  class="u-button_app-monitoring-dashboard-station-header-info-type"
                >
                  {{'monitoringDashboard.station.type.driver' | translate}}
                </button>
              </div>
              <div class="monitoring-dashboard-station__header-info-status">
                <div>
                  <div
                    *ngIf="driver.hasMobileApp"
                    class="monitoring-dashboard-station__status monitoring-dashboard-station__status_antena"
                    [ngClass]="{
                      'monitoring-dashboard-station__status_antena-green': (driver.id | monitoringDashboardIsMonitored: details.monitoredPaths),
                      'monitoring-dashboard-station__status_antena-gray': !(driver.id | monitoringDashboardIsMonitored: details.monitoredPaths)
                    }"
                    [uPopover]="popoverDetails"
                    [container]="'body'"
                    [placement]="isRtl ? [ 'right-top', 'right-bottom' ] : [ 'left-top', 'left-bottom' ]"
                    [autoClose]="'outside'"
                    [viewportElement]="monitoringDashboard"
                    [popoverClass]="'u-popover_app-monitoring-dashboard'"
                    #popoverMonitored="uPopover"
                    (click)="openMonitoringDashboardDetails(driver, popoverMonitored)"
                  ></div>
                  <div
                    *ngIf="!driver.hasMobileApp"
                    class="monitoring-dashboard-station__status monitoring-dashboard-station__status_antena monitoring-dashboard-station__status_antena-no-app"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="monitoring-dashboard-station__row">
            <div class="monitoring-dashboard-station__row-phone">{{driver.phone}}</div>
          </div>
          <div class="monitoring-dashboard-station__row"></div>
        </div>

        <div
          class="monitoring-dashboard-station"
          *ngFor="let station of monitoringDashboardRidesService.rideActive.details.stations | monitoringDashboardStationsSearch: stationsSearchText.value; let i = index"
          (click)="stationSetMapCenter(station)"
        >
          <div class="monitoring-dashboard-station__header">
            <div
              class="monitoring-dashboard-station__header-name"
              [ngClass]="{
                'monitoring-dashboard-station__header-name_small': station.type !== monitoringDashboardStationType.Station,
                'monitoring-dashboard-station__header-name_medium': station.type === monitoringDashboardStationType.Accompany
              }"
              [uTooltip]="
                station.type === monitoringDashboardStationType.Accompany ?
                (accompany.isAnonymous ? (config.dictionary.anonymousAccompany | translate) : accompany.name) :
                station.name
              "
            >
              {{
                station.type === monitoringDashboardStationType.Accompany ?
                (accompany.isAnonymous ? (config.dictionary.anonymousAccompany | translate) : accompany.name) :
                station.name
              }}
            </div>
            <div class="monitoring-dashboard-station__header-info">
              <div class="monitoring-dashboard-station__header-info-type">
                <button
                  *ngIf="station.type === monitoringDashboardStationType.Destination && activeRideDirection === routeDirection.Backward"
                  uButton
                  class="u-button_app-monitoring-dashboard-station-header-info-type"
                >
                  {{'monitoringDashboard.station.type.pickup' | translate}}
                </button>
                <button
                  *ngIf="station.type === monitoringDashboardStationType.Destination && activeRideDirection !== routeDirection.Backward"
                  uButton
                  class="u-button_app-monitoring-dashboard-station-header-info-type"
                >
                  {{'monitoringDashboard.station.type.target' | translate}}
                </button>
                <button
                  *ngIf="station.type === monitoringDashboardStationType.Accompany"
                  uButton
                  class="u-button_app-monitoring-dashboard-station-header-info-type"
                >
                  {{'monitoringDashboard.station.type.accompany' | translate}}
                </button>
              </div>
              <div class="monitoring-dashboard-station__header-info-status">
                <div *ngIf="station.type === monitoringDashboardStationType.Accompany">
                  <div
                    *ngIf="accompany.hasMobileApp"
                    class="monitoring-dashboard-station__status monitoring-dashboard-station__status_antena"
                    [ngClass]="{
                      'monitoring-dashboard-station__status_antena-green': (accompany.id | monitoringDashboardIsMonitored: details.monitoredPaths),
                      'monitoring-dashboard-station__status_antena-gray': !(accompany.id | monitoringDashboardIsMonitored: details.monitoredPaths)
                    }"
                    [uPopover]="popoverDetails"
                    [container]="'body'"
                    [placement]="isRtl ? [ 'right-top', 'right-bottom' ] : [ 'left-top', 'left-bottom' ]"
                    [autoClose]="'outside'"
                    [viewportElement]="monitoringDashboard"
                    [popoverClass]="'u-popover_app-monitoring-dashboard'"
                    #popoverMonitored="uPopover"
                    (click)="openMonitoringDashboardDetails(accompany, popoverMonitored)"
                  ></div>
                  <div
                    *ngIf="!accompany.hasMobileApp"
                    class="monitoring-dashboard-station__status monitoring-dashboard-station__status_antena monitoring-dashboard-station__status_antena-no-app"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="monitoring-dashboard-station__row">
            <div [uTooltip]="station.address" class="monitoring-dashboard-station__row-address">{{station.address}}</div>
            <div *ngIf="station.type === monitoringDashboardStationType.Accompany && !accompany.isAnonymous && accompany.phone" class="monitoring-dashboard-station__row-phone">{{accompany.phone}}</div>
          </div>
          <div class="monitoring-dashboard-station__row">
            <div class="monitoring-dashboard-station__row-time">
              <div class="monitoring-dashboard-station__row-time-planned">
                <div class="monitoring-dashboard-station__row-time-planned-icon" [uTooltip]="'monitoringDashboard.station.time.planned' | translate"></div>
                <div
                  class="monitoring-dashboard-station__row-time-planned-value"
                  [ngClass]="{'monitoring-dashboard-station__row-time-planned-value_crossed': !station.noEntrance && station.arrivalGapInMin}"
                >
                  {{station.arrivalDateTime | date: appConstants.TIME_FORMAT}}
                </div>
              </div>
              <div
                class="monitoring-dashboard-station__row-time-actual"
                *ngIf="station.noEntrance || station.arrivalGapInMin"
              >
                <div class="monitoring-dashboard-station__row-time-actual-icon" [uTooltip]="'monitoringDashboard.station.time.actual' | translate"></div>
                <div class="monitoring-dashboard-station__row-time-actual-value">{{station.noEntrance ? ('monitoringDashboard.station.time.noEntrance' | translate) : station.actualArriveDateTime | date: appConstants.TIME_FORMAT}}</div>
              </div>

              <div
                *ngIf="station.arrivalGapInMin"
                class="monitoring-dashboard-station__row-time-gap"
                [ngClass]="{'monitoring-dashboard-station__row-time-gap_green': station.arrivalGapInMin < 0}"
              >
                (<div class="monitoring-dashboard-station__row-time-gap-container">
                  <span class="monitoring-dashboard-station__row-time-gap-value">
                    {{station.arrivalGapInMin > 0 ? '+' : ''}}{{station.arrivalGapInMin}}
                  </span>
                  <span class="monitoring-dashboard-station__row-time-gap-unit">{{'general.minutes' | translate}}</span>
                </div>)
              </div>
              <div
                *ngIf="station.actualArriveDateTime && !station.arrivalGapInMin"
                class="monitoring-dashboard-station__row-time-gap monitoring-dashboard-station__row-time-gap_green"
              >
                ({{'monitoringDashboard.station.time.onTime' | translate}})
              </div>
            </div>
            <div
              *ngIf="station.type === monitoringDashboardStationType.Station"
              class="monitoring-dashboard-station__row-arrow"
              [ngClass]="{
                'monitoring-dashboard-station__row-arrow_up': station.showPassengers
              }"
              (click)="$event.stopPropagation(); toggleShowPassenger(station)"
            ></div>
          </div>
          <div class="monitoring-dashboard-station__passengers" *ngIf="station.showPassengers">
            <div
              class="monitoring-dashboard-station__passenger"
              *ngFor="let passenger of station.passengers"
            >
              <div class="monitoring-dashboard-station-passenger">
                <div class="monitoring-dashboard-station-passenger__header">
                  <div class="monitoring-dashboard-station-passenger__header-bullet">•</div>
                  <div class="monitoring-dashboard-station-passenger__header-data">
                    <div class="monitoring-dashboard-station-passenger__header-name">
                      <span
                        class="monitoring-dashboard-station-passenger__header-text"
                        [uTooltip]="passenger.name"
                      >
                        {{passenger.name}}
                      </span>
                    </div>
                    <div class="monitoring-dashboard-station-passenger__header-info">
                      <div class="monitoring-dashboard-station-passenger__header-info-status">
                        <div>
                          <div
                            *ngIf="passenger.hasMobileApp"
                            class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_antena"
                            [ngClass]="{
                              'monitoring-dashboard-station-passenger__status_antena-green': (passenger.id | monitoringDashboardIsMonitored: details.monitoredPaths),
                              'monitoring-dashboard-station-passenger__status_antena-gray': !(passenger.id | monitoringDashboardIsMonitored: details.monitoredPaths)
                            }"
                            [uPopover]="popoverDetails"
                            [container]="'body'"
                            [placement]="isRtl ? [ 'right-top', 'right-bottom' ] : [ 'left-top', 'left-bottom' ]"
                            [autoClose]="'outside'"
                            [viewportElement]="monitoringDashboard"
                            [popoverClass]="'u-popover_app-monitoring-dashboard'"
                            #popoverMonitored="uPopover"
                            (click)="openMonitoringDashboardDetails(passenger, popoverMonitored)"
                          ></div>
                          <div
                            *ngIf="!passenger.hasMobileApp"
                            class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_antena monitoring-dashboard-station-passenger__status_antena-no-app"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="monitoring-dashboard-station-passenger__row-container">
                  <div class="monitoring-dashboard-station-passenger__row-phone">{{passenger.phone}}</div>
                  <div class="monitoring-dashboard-station-passenger__row-status">
                  <span
                    class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_green"
                    *ngIf="passenger.active && (!isMunicipalityCustomer || isRideHaveAccompany) && passenger.checkIn && !passenger.selfReported"
                    [uTooltip]="passenger.checkInAt | date: 'dd/MM/yyyy HH:mm'"
                    [placement]="[ 'bottom' ]"
                    [container]="'body'"
                  >
                    {{'monitoringDashboard.station.pickedUp' | translate}}
                  </span>
                    <span
                      class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_gray"
                      *ngIf="passenger.active && isRideHaveAccompany && passenger.checkIn === null"
                    >
                    {{'monitoringDashboard.station.notReported' | translate}}
                  </span>
                    <span
                      class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_gray"
                      *ngIf="passenger.active && (!isMunicipalityCustomer || isRideHaveAccompany) && passenger.checkIn === false && !passenger.selfReported"
                      [uTooltip]="passenger.checkInAt | date: 'dd/MM/yyyy HH:mm'"
                      [placement]="[ 'bottom' ]"
                      [container]="'body'"
                    >
                    {{'monitoringDashboard.station.notPickedUp' | translate}}
                  </span>
                    <span
                      class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_green"
                      *ngIf="passenger.active && (!isMunicipalityCustomer || !isRideHaveAccompany) && passenger.checkIn && passenger.selfReported"
                    >
                    {{'monitoringDashboard.station.reported' | translate}}
                  </span>
                    <span
                      class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_gray"
                      *ngIf="passenger.active && (!isMunicipalityCustomer || !isRideHaveAccompany) && passenger.checkIn === null && !passenger.selfReported"
                    >
                    {{'monitoringDashboard.station.notReported' | translate}}
                  </span>
                    <span
                      class="monitoring-dashboard-station-passenger__status monitoring-dashboard-station-passenger__status_gray"
                      *ngIf="!passenger.active"
                    >
                    {{'monitoringDashboard.station.notComing' | translate}}
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="station.anonymousPassengersCount"
              class="monitoring-dashboard-station__passenger"
            >
              <div class="monitoring-dashboard-station-passenger">
                <div class="monitoring-dashboard-station-passenger__header">
                  <div class="monitoring-dashboard-station-passenger__header-bullet">•</div>
                  <div class="monitoring-dashboard-station-passenger__header-name">
                    <span
                      class="monitoring-dashboard-station-passenger__header-text"
                      uTooltip="{{station.anonymousPassengersCount}} {{'monitoringDashboard.station.anonymousPassengers' | translate}}"
                    >
                      {{station.anonymousPassengersCount}} {{'monitoringDashboard.station.anonymousPassengers' | translate}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div
        class="monitoring-dashboard-stations__summary"
        *ngIf="isRideHaveSupervisor || isRideHaveAccompany"
      >
        <div class="monitoring-dashboard-stations__summary-info">{{pickedUpSum}} {{'monitoringDashboard.summary.pickedUp' | translate}}</div>
        <div class="monitoring-dashboard-stations__summary-divider"></div>
        <div class="monitoring-dashboard-stations__summary-info">{{notPickedUpSum}} {{'monitoringDashboard.summary.notPickedUp' | translate}}</div>
        <div class="monitoring-dashboard-stations__summary-divider"></div>
        <div class="monitoring-dashboard-stations__summary-info">{{notMarkedSum}} {{'monitoringDashboard.summary.notMarked' | translate}}</div>
      </div>
      <div
        class="monitoring-dashboard-stations__summary"
        *ngIf="!isRideHaveSupervisor && !isRideHaveAccompany"
      >
        <div class="monitoring-dashboard-stations__summary-info">{{reportedSum}} {{'monitoringDashboard.summary.reported' | translate}}</div>
        <div class="monitoring-dashboard-stations__summary-divider"></div>
        <div class="monitoring-dashboard-stations__summary-info">{{notReportedSum}} {{'monitoringDashboard.summary.notReported' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="monitoring-dashboard__main" [ngClass]="{ 'monitoring-dashboard__main_stations': rideStationsShow }">
    <app-monitoring-dashboard-map
      [ride]="monitoringDashboardRidesService.rideActive"
      [traffic]="monitoringDashboardDataService.traffic"
      [stationCenter]="stationCenter"
      [bounds]="bounds"
    ></app-monitoring-dashboard-map>
    <app-monitoring-dashboard-info
      *ngIf="monitoringDashboardRidesService.rideActive"
      [ride]="monitoringDashboardRidesService.rideActive"
      [authUserInfo]="authUserInfo"
    ></app-monitoring-dashboard-info>
    <app-monitoring-dashboard-layers></app-monitoring-dashboard-layers>
  </div>
  <div class="monitoring-dashboard__close" (click)="headerDataService.closeDashboard()"></div>
</div>
