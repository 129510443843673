import { AddEditModalConfig, AuditHistory } from '@app/shared/models';

export enum ShuttleCompaniesCarsAddEditModalTabDataName {
  Details = 'details',
  Accounting = 'accounting',
  History = 'history',
  Documents = 'documents'
}

export enum ShuttleCompanyCarAddEditAccountingOwnershipType {
  Company = 1,
  Freelance,
  Leasing,
  Rental
}

export enum ShuttleCompanyCarAddEditMonitoringCarStatus {
  Unknown,
  HasMonitoring,
  DoesNotHaveMonitoring
}

export interface ShuttleCompaniesCarsAddEditComponentConfig {
  dictionary: { [key: string]: string; };
  addEditModalConfig: AddEditModalConfig;
}

export interface ShuttleCompanyCar {
  number: number;
  carId: number;
  carType: string;
  carTypeId: number;
  hasLift: boolean;
  monitoringVendor: string;
  monitoringVendorId: number;
}

export class ShuttleCompanyCarAddEdit {
  id?: number;
  number: string;
  carTypeId: number;
  carType: string;
  driverIds: number[];
  carId: number = 0;
  elevator: boolean | string;
  monitoringCarStatus: ShuttleCompanyCarAddEditMonitoringCarStatus;
  monitoringVendor: number;
  monitoringConnectionStatus: number;
  mileage: number;
  shuttleCompanyId: number;
  insurance: {
    supplierName: string;
    policyNumber: string;
  };
  inspection: {
    nextMileage: number;
    nextDate: string;
    currentMileage: number;
  };
  details: {
    manufacturer: string;
    manufactureDate: string;
    model: string;
    color: string;
    fuelType: string;
    hasLift: true;
    status: number;
  };
  chassisAndTire: {
    vin: string;
    engineNumber: string;
    engineVolume: string;
    tireSize: string
  };
  accounting: {
    ownershipType: ShuttleCompanyCarAddEditAccountingOwnershipType;
    accountingSupplierName: string;
    worth: number;
    purchaseDate: string;
    purchaseCost: number;
    saleDate: string;
    saleCost: number;
    contractNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    annualMileage: number;
    periodMileage: number;
    oneTimePayment: number;
    monthlyCost: number;
    accountingKey: string
  };
  notes: string;
  carSizeType: string;
  seatsCount: number;
  history?: AuditHistory[];
}

export interface ShuttleCompanyCarDocument {
  documentName: string;
  expirationDate: string;
  id: string;
  fileName: string;
}
