import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'builderApprovalStatus'
})
export class BuilderApprovalStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 0: return 'builder.history.approveStatuses.waiting';
      case 1: return 'builder.history.approveStatuses.approved';
      case 2: return 'builder.history.approveStatuses.rejected';
      default: return 'builder.history.approveStatuses.notRequired';
    }
  }
}

