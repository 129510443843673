import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { GeoDataAddress, GeoDataWalkingDistanceParams } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class GeoDataService {
  private BASE_PATH = 'GeoData';

  constructor (
    private apiService: ApiService
  ) {}

  autocomplete(text: string): Observable<GeoDataAddress[]> {
    return this.apiService.get(`${this.BASE_PATH}/Autocomplete`, { text });
  }

  placeInfo(latitude: number, longitude: number): Observable<GeoDataAddress> {
    return this.apiService.get(`${this.BASE_PATH}/PlaceInfo`, { latitude, longitude });
  }

  walkingDistance(params: GeoDataWalkingDistanceParams): Observable<number> {
    return this.apiService.get(`${this.BASE_PATH}/WalkingDistance`, params);
  }
}
