import { URangePreset } from '@shift/ulib';

import { AuthCustomerType } from '@app/auth/models';

const DICTIONARY_PATH: string = 'routes.changeSupervisor';

const availablePresets: URangePreset[] = [
  URangePreset.DisplayedDay,
  URangePreset.Today,
  URangePreset.Tomorrow,
  URangePreset.ActiveWeekDay,
  URangePreset.FromTodayOn,
  URangePreset.FromCustomDayOn,
  URangePreset.All,
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

const routesChangeSupervisorComponent = {
  dictionary: {
    select: 'general.select',
    removeSelect: 'general.removeSelect',
    title: `${DICTIONARY_PATH}.title`,
    rideSupervisor: `${DICTIONARY_PATH}.rideSupervisor`,
    type: `${DICTIONARY_PATH}.type`,
    comments: `${DICTIONARY_PATH}.comments`,
    actions: {
      saveAndClose: 'general.saveAndClose',
      saveAndSendSC: 'general.saveAndSendSC',
      saveAndEditEmail: 'general.saveAndEditEmail'
    }
  },
  availablePresets
};

export const routesChangeSupervisorComponentConfig = {
  default: routesChangeSupervisorComponent,
  [AuthCustomerType.Army]: {
    ...routesChangeSupervisorComponent,
    dictionary: {
      ...routesChangeSupervisorComponent.dictionary,
      title: `${DICTIONARY_PATH}.titleCommander`,
      rideSupervisor: `${DICTIONARY_PATH}.rideCommander`
    }
  }
};
