import { USideModalConfig } from '@shift/ulib';

import { AreasButton, AreasButtonId, EnvType } from '@app/shared/models';
import { AuthCustomerType } from '@app/auth/models';

const DICTIONARY_PATH: string = 'shared.areas';

const buttons: AreasButton[] = [
  {
    id: AreasButtonId.NewArea,
    withPlus: true,
    title: `${DICTIONARY_PATH}.addArea`,
    uId: 'areas-top-button-new-area',
    medium: true
  }
];

const explanationModal: USideModalConfig = {
  button: {
    text: `${DICTIONARY_PATH}.explanation.button`,
    icon: '/assets/images/common/explanation.svg'
  },
  modal: {
    title: `${DICTIONARY_PATH}.explanation.title`,
    image: {
      width: 298,
      height: 298,
      path: '/assets/images/cities-areas/explanation.svg'
    },
    buttons: [
      {
        id: 'Academy',
        text: `${DICTIONARY_PATH}.explanation.academy`,
        icon: '/assets/images/common/academy.svg',
        url: 'https://intercom.help/ShiftLive/he/articles/6259688-מודול-אזורים'
      },
      {
        id: 'T.V',
        text: `${DICTIONARY_PATH}.explanation.tv`,
        icon: '/assets/images/common/tv.svg',
        url: 'https://intercom.help/ShiftLive/he/articles/6259692-קורס-מודול-אזורים'
      }
    ]
  }
};

const explanationModalOrange: USideModalConfig = {
  ...explanationModal,
  modal: {
    ...explanationModal.modal,
    image: {
      path: '/assets/images/themes/orange/cities-areas/explanation.svg'
    },
    buttons: [
      {
        id: 'Academy',
        text: `${DICTIONARY_PATH}.explanation.academy`,
        icon: '/assets/images/common/academy.svg',
        url: 'https://intercom.help/Traffical/he/articles/6259694-מודול-אזורים'
      },
      {
        id: 'T.V',
        text: `${DICTIONARY_PATH}.explanation.tv`,
        icon: '/assets/images/common/tv.svg',
        url: 'https://intercom.help/Traffical/he/articles/6259696-קורס-מודול-אזורים'
      }
    ]
  }
};

const explanationModalGreen: USideModalConfig = {
  ...explanationModal,
  modal: {
    ...explanationModal.modal,
    image: {
      path: '/assets/images/themes/green/cities-areas/explanation.svg'
    },
    buttons: [
      {
        id: 'Academy',
        text: `${DICTIONARY_PATH}.explanation.academy`,
        icon: '/assets/images/common/academy.svg',
        url: 'https://intercom.help/Traffical/he/articles/6259694-מודול-אזורים'
      },
      {
        id: 'T.V',
        text: `${DICTIONARY_PATH}.explanation.tv`,
        icon: '/assets/images/common/tv.svg',
        url: 'https://intercom.help/Traffical/he/articles/6259696-קורס-מודול-אזורים'
      }
    ]
  }
};

const explanationModalByEnvTypes = {
  default: explanationModal,
  [EnvType.Traffical]: explanationModalOrange,
  [EnvType.IDF]: explanationModalOrange
};

export const areasComponentConfig = {
  buttons,
  dictionary: {
    areaName: `${DICTIONARY_PATH}.table.areaName`,
    messagesTable: `${DICTIONARY_PATH}.uGridMessages`,
    explanationText: [
      `${DICTIONARY_PATH}.explanation.text.1`,
      `${DICTIONARY_PATH}.explanation.text.2`,
      `${DICTIONARY_PATH}.explanation.text.3`,
      `${DICTIONARY_PATH}.explanation.text.otherQuestions`
    ],
    closeConfirm: `${DICTIONARY_PATH}.closeConfirm`,
    deleteConfirm: `${DICTIONARY_PATH}.deleteConfirm`,
    existingArea: `${DICTIONARY_PATH}.existingArea`,
    selectCity: `${DICTIONARY_PATH}.table.selectCity`,
    uGrid: 'uGrid',
    uSelectS: 'uSelectS',
    removeSelect: 'general.removeSelect',
    delete: 'general.delete',
    cancel: 'general.cancel'
  },
  explanationModal: {
    default: explanationModalByEnvTypes,
    [AuthCustomerType.ShuttleCompany]: {
      ...explanationModalByEnvTypes,
      [EnvType.Shift]: explanationModalGreen
    }
  },
  columns: [
    {
      prop: 'cityName',
      filterType: 'select',
      width: 136,
      cellTemplateName: 'cityAssignmentColumn',
      name: `${DICTIONARY_PATH}.table.cityAssignment`
    },
    {
      prop: 'cityArea.name',
      cellTemplateName: 'areaNameColumn',
      width: 212,
      name: `${DICTIONARY_PATH}.table.areaName`,
      hideDivider: true
    }
  ],
  map: {
    zoom: 8,
    center: {
      lat: 31.768319,
      lng: 35.21371
    },
    markers: {
      passenger: {
        icon: {
          url: '/assets/images/map/point-passenger.svg',
          width: 48,
          height: 48
        }
      },
      destination: {
        icon: {
          url: '/assets/images/map/point-destination.svg',
          width: 48,
          height: 48
        }
      }
    }
  }
};
