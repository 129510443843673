export enum CustomerDataType {
  Departments = 'departments',
  Branches = 'branches',
  RouteTypes = 'routeTypes',
  CostTypes = 'costTypes',
  Customers = 'customers',
  Schools = 'schools',
  Shifts = 'shifts',
  ShuttleCompanies = 'shuttlecompanies',
  Passengers = 'passengers',
  ClassTypes = 'classtypes',
  Tags = 'tags',
  AvailableShifts = 'availableshifts'
}

export interface CustomerData {
  [key: string]: {
    id: number;
    name: string;
    grade?: number;
    minDistanceToSchool?: number;
    branchId?: number;
  }[];
}
