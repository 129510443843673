import { Pipe, PipeTransform } from '@angular/core';

import { appConfig } from '@app/shared/configs';

@Pipe({
  name: 'builderStationId'
})
export class BuilderStationIdPipe implements PipeTransform {
  transform(station): string {
    if (station.type === 'station') {
      return `${appConfig.idPrefix}-builder-station-${station.order}`;
    }

    if (station.type === 'destination') {
      return `${appConfig.idPrefix}-builder-destination-${station.order}`;
    }

    return null;
  }
}

