import { UHereMapCoordinates } from '@shift/ulib';

import { AddEditModalConfig, DaysOfWeek, ModalActions } from '@app/shared/models';
import { RouteRecalculateMode, RoutesCancellationChargeType } from '@app/routes/models';

export type ShuttleCompanyContractTabData =
  ShuttleCompanyContractSpecialRates |
  ShuttleCompanyContractPricelistCities |
  ShuttleCompanyContractPricelistKmBase |
  ShuttleCompanyContractPricelistKmRanges |
  ShuttleCompanyContractPricelistHashcal |
  ShuttleCompanyContractVehicleOccupancy[] |
  ShuttleCompaniesContractArea[];


export enum ShuttleCompanyContractSpecialRatesCalculationType {
  Direction = 'Direction',
  DepartureTime = 'DepartureTime',
  ArrivalTime = 'ArrivalTime'
}

export enum ShuttleCompanyContractPricelistCostType {
  Percent = 'Percent',
  Money = 'Money'
}

export enum ShuttleCompaniesContractsAddEditConfirmPeriod {
  Whole,
  Custom
}

export enum ShuttleCompaniesContractsAddEditModalTabDataName {
  PricelistKmRanges = 'pricelistKmRanges',
  PricelistCities = 'pricelistCities',
  PricelistKmBase = 'pricelistKmBase',
  PricelistHashcal = 'pricelistHashcal',
  Areas = 'areas'
}

export enum ShuttleCompaniesContractsAddEditCancelationAction {
  Save = 'Save',
  Close = 'Close'
}

export enum ShuttleCompaniesContractsAddEditCancelationChangesType {
  CancelationClause = 'CancelationClause',
  ChangeClause = 'ChangeClause'
}

export enum ShuttleCompanyContractPricelistRangesColumnName {
  From = 'from',
  To = 'to',
  Price = 'price'
}

export enum ShuttleCompanyContractPricelistTollRoadsCostType {
  NoCharge = 'NoCharge',
  EntrancePricePerDirection = 'EntrancePricePerDirection'
}

export interface ShuttleCompanyContractBase {
  contracts: ShuttleCompanyContract[];
  lockState: ShuttleCompanyLockState;
}

export interface ShuttleCompanyContract {
  contractId: string;
  name: string;
  branches: {
    id: number;
    name: string;
  }[];
  startDate: string;
  endDate: string;
  isActive: boolean;
  comments: string;
}

export interface ShuttleCompanyLockState {
  locked: boolean;
  lockDateTime: string;
  lockedByUser: string;
}

export interface ShuttleCompanyContractUpdateStatusBody {
  isActive: boolean;
  contractId: string;
}

export interface ShuttleCompanyContractAddEdit {
  contractId: string;
  details: {
    name: string;
    branches: {
      branchId: number;
      assignAutomatically: boolean;
    }[];
    startDate: string;
    endDate: string;
    comments: string;
  };
}

export class ShuttleCompanyContractChangePoliciesCancellationPolicy {
  policyId: number = null;
  name: string = '';
  chargeType: RoutesCancellationChargeType = RoutesCancellationChargeType.Percentage;
  amount: number = null;
  minutesBeforeRideStart: number = null;
  weekDays: DaysOfWeek[] = [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ];

  constructor(values: object) {
    Object.assign(this, values);
  }
}

export interface ShuttleCompanyContractChangePoliciesCancellationPolicyRow extends ShuttleCompanyContractChangePoliciesCancellationPolicy {
  amountText: string;
  minutesBeforeRideStartText: string;
}

export class ShuttleCompanyContractChangePoliciesChangePassengersAmountPolicy {
  policyId: number = null;
  name: string = '';
  minutesBeforeRideStart: number = null;
  weekDays: DaysOfWeek[] = [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ];
  autoMinimizeCarType: boolean = false;
  routeRecalculateMode: RouteRecalculateMode = RouteRecalculateMode.KeepCurrent;

  constructor(values: object) {
    Object.assign(this, values);
  }
}

export interface ShuttleCompanyContractChangePoliciesChangePassengersAmountPolicyRow extends ShuttleCompanyContractChangePoliciesChangePassengersAmountPolicy {
  minutesBeforeRideStartText: string;
}

export interface ShuttleCompanyContractAddEditBody extends ShuttleCompanyContractAddEdit {
  vehicleOccupancies?: ShuttleCompanyContractVehicleOccupancy[];
  splitOptions?: {
    dateFrom: string;
  };
  specialRates?: ShuttleCompanyContractSpecialRates;
  pricelists?: {
    pricelistCities?: ShuttleCompanyContractPricelistCities;
    pricelistKmBase?: ShuttleCompanyContractPricelistKmBase;
    pricelistKmRanges?: ShuttleCompanyContractPricelistKmRanges;
    pricelistHashcal?: ShuttleCompanyContractPricelistHashcal;
  };
  changePolicies?: {
    cancellationPolicies: ShuttleCompanyContractChangePoliciesCancellationPolicy[];
    changePassengersAmountPolicies: ShuttleCompanyContractChangePoliciesChangePassengersAmountPolicy[];
  };
}

export interface ShuttleCompanyContractAnyRelatedRidesBody {
  contractId: string;
  startDate: string;
  endDate: string;
  branchIds: number[];
}

export interface ShuttleCompanyContractBranchesParams {
  shuttleCompanyId: number;
  contractId: string;
  periodFrom: string;
  periodTo: string;
}

export interface ShuttleCompanyContractBranch {
  branchId: number;
  name: string;
  customerName: string;
  isAssignAutomaticallyAllowed: boolean;
}

export interface ShuttleCompanyContractsConfirmPeriodPopupAction {
  type: ModalActions;
  dateFrom?: string;
}

export interface ShuttleCompanyContractVehicleOccupancy {
  vehicleTypeId: number;
  name: string;
  seatsCount: number;
  maxCapacity: number;
  allowedOccupancy: number;
  isActive: boolean;
}

export interface ShuttleCompanyContractSpecialRatesNightRate {
  isActive: boolean;
  calculationType: ShuttleCompanyContractSpecialRatesCalculationType;
  startTime: string;
  endTime: string;
}

export interface ShuttleCompanyContractSpecialRatesWeekendRate extends ShuttleCompanyContractSpecialRatesNightRate {
  startDay: DaysOfWeek;
  endDay: DaysOfWeek;
}

export interface ShuttleCompanyContractSpecialRatesOccasionBase {
  name: string;
  startDateTime: string;
  endDateTime: string;
  isImported: boolean;
}

export interface ShuttleCompanyContractSpecialRatesOccasion extends ShuttleCompanyContractSpecialRatesOccasionBase {
  id: number;
}

export interface ShuttleCompanyContractSpecialRates {
  nightRate: ShuttleCompanyContractSpecialRatesNightRate;
  weekendRate: ShuttleCompanyContractSpecialRatesWeekendRate;
  occasions: ShuttleCompanyContractSpecialRatesOccasionBase[];
}

export interface ShuttleCompanyContractPricelist {
  isActive: boolean;
  default: boolean;
  nightCostType: ShuttleCompanyContractPricelistCostType;
  nightCost: number;
  weekendCostType: ShuttleCompanyContractPricelistCostType;
  weekendCost: number;
  tollRoadsCost: number;
  tollRoadsCostType: ShuttleCompanyContractPricelistTollRoadsCostType;
}

export interface ShuttleCompanyContractPricelistVehicleTypeBase {
  id: number;
  name: string;
}

export interface ShuttleCompanyContractPricelistVehicleType extends ShuttleCompanyContractPricelistVehicleTypeBase {
  isActive: boolean;
  seatsCount: number;
}

export interface ShuttleCompanyContractPricelistCitiesPricesItem {
  vehicleTypeId: number;
  basePrice: number;
  entryPrice: number;
}

export interface ShuttleCompanyContractPricelistCitiesPrice {
  cityId: number;
  name: string;
  prices: ShuttleCompanyContractPricelistCitiesPricesItem[];
}

export interface ShuttleCompanyContractPricelistCities extends ShuttleCompanyContractPricelist {
  vehicleTypes: ShuttleCompanyContractPricelistVehicleTypeBase[];
  citiesPrices: ShuttleCompanyContractPricelistCitiesPrice[];
}

export interface ShuttleCompanyContractPricelistKmBasePrice {
  vehicleType: ShuttleCompanyContractPricelistVehicleTypeBase;
  maxDistance: number;
  price: number;
}

export interface ShuttleCompanyContractPricelistRange {
  from: number;
  to: number;
  price: number;
}

export interface ShuttleCompanyContractPricelistKmBase extends ShuttleCompanyContractPricelist {
  basePrices: ShuttleCompanyContractPricelistKmBasePrice[];
  perKmPrices: ShuttleCompanyContractPricelistRange[];
}

export interface ShuttleCompanyContractPricelistKmRangesPriceRangePrice extends ShuttleCompanyContractPricelistRange {
  isNew: boolean;
}

export interface ShuttleCompanyContractPricelistKmRangesPrice {
  vehicleType: ShuttleCompanyContractPricelistVehicleTypeBase;
  kmRanges: ShuttleCompanyContractPricelistRange[];
  extraCost: number;
  nightCostType: ShuttleCompanyContractPricelistCostType;
  nightCost: number;
  weekendCostType: ShuttleCompanyContractPricelistCostType;
  weekendCost: number;
  tollRoadsCost: number;
  tollRoadsCostType: ShuttleCompanyContractPricelistTollRoadsCostType;
}

export interface ShuttleCompanyContractPricelistKmRanges {
  isActive: boolean;
  default: boolean;
  prices: ShuttleCompanyContractPricelistKmRangesPrice[];
}

export interface ShuttleCompaniesContractsAddEditModalConfig extends AddEditModalConfig {
  fieldsMessageByFeature: { [key: string]: string | { [key: string]: string; }; };
}

export interface ShuttleCompaniesContractArea {
  areaId: number;
  name: string;
  polygon: UHereMapCoordinates[];
}

export interface ShuttleCompanyContractOtherContractsAreasBody {
  contractId: string;
  startDate: string;
  endDate: string;
  branchIds: number[];
  vehicleTypeIds: number[];
}

export interface ShuttleCompanyContractOtherContractsArea {
  contractName: string;
  shuttleCompanyName: string;
  name: string;
  startDate: string;
  endDate: string;
  polygon: UHereMapCoordinates[];
}

export interface ShuttleCompanyContractPricelistHashcal {
  maxAerialRadiusKm: number;
  tollRoadCostPerDirection: number;
  prices: ShuttleCompanyContractPricelistHashcalPrice[];
  isActive: boolean;
  default: boolean;
}

export interface ShuttleCompanyContractPricelistHashcalPrice {
  vehicleType: ShuttleCompanyContractPricelistVehicleTypeBase;
  pricePerKm: number;
  pricePerHour: number;
  basePrice: number;
  nightCostType: number;
  nightCost: number;
  weekendCostType: number;
  weekendCost: number;
  kmRanges: ShuttleCompanyContractPricelistRange[];
  timeRanges: ShuttleCompanyContractPricelistRange[];
  isTaxi: boolean;
  tollRoadsCost: number;
  tollRoadsCostType: ShuttleCompanyContractPricelistTollRoadsCostType;
}
