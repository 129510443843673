import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges, ViewChild, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { UPopoverDirective } from '@shift/ulib';

import { BuilderRoutesPassengersInfoStoreService } from '@app/builder/services';
import { LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-builder-routes-passengers-info',
  templateUrl: './builder-routes-passengers-info.component.html',
  styleUrls: [ './builder-routes-passengers-info.component.scss', './builder-routes-passengers-info.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderRoutesPassengersInfoComponent implements OnChanges {
  @Input() routeId: number;
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'builder-routes-passengers-info';

  @ViewChild('popover') popover: UPopoverDirective;

  private readonly localizationService = inject(LocalizationService);
  private readonly builderRoutesPassengersInfoStoreService = inject(BuilderRoutesPassengersInfoStoreService);

  readonly #routeId: WritableSignal<number> = signal(undefined);
  readonly #open: WritableSignal<boolean> = signal(false);

  passengers = computed(() => this.builderRoutesPassengersInfoStoreService.infoByRouteId(this.#routeId())());
  isRtl: boolean = this.localizationService.isRtl();

  constructor() {
    effect(() => {
      const open = this.#open();
      const passengers = this.passengers();

      if (open && passengers?.length && !this.popover?.isOpen()) {
        this.popover?.open();

        return;
      }

      if (!open && this.popover?.isOpen()) {
        this.popover?.close();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routeId) {
      this.#routeId.set(this.routeId);
    }
  }

  openPassengersList() {
    this.builderRoutesPassengersInfoStoreService.getInfo({
      routeId: this.#routeId()
    });

    this.#open.set(true);
  }

  closePassengersList() {
    this.#open.set(false);
  }

  closePassengersListAndCancelGetInfo() {
    if (this.#open() && !this.popover?.isOpen()) {
      this.closePassengersList();

      if (!this.passengers()) {
        this.builderRoutesPassengersInfoStoreService.cancelGetInfo();
      }
    }
  }
}
