import { Injectable } from '@angular/core';
import { DestroyRef, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ShuttleCompanyCarsService } from '@app/shuttle-companies/services';
import { ShuttleCompanyCarDocument } from '@app/shuttle-companies/models';

@Injectable()
export class ShuttleCompaniesCarsAddEditDocumentsService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly shuttleCompanyCarsService = inject(ShuttleCompanyCarsService);

  private form: FormArray;

  private generateDocumentForm(document?: ShuttleCompanyCarDocument) {
    const form = this.formBuilder.group({
      documentName: [ document?.documentName ?? '', Validators.required ],
      expirationDate: [ document?.expirationDate ?? null ],
      id: [ document?.id ?? null ],
      fileName: [ document?.fileName ?? null ],
      file: [ { value: null, disabled: document?.documentName ? false : true } ]
    });

    form.get('documentName').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => !!data ? form.get('file').enable() : form.get('file').disable());

    return form;
  }

  initForm(form: FormArray) {
    this.form = form;
  }

  generateForm(documents: ShuttleCompanyCarDocument[]) {
    return this.formBuilder.array(documents.map(document => this.generateDocumentForm(document)));
  }

  newDocument() {
    this.form.markAsDirty();
    this.form.push(this.generateDocumentForm());
  }

  getDocument(documentForm: FormGroup) {
    const { id, fileName } = documentForm.getRawValue();

    this.shuttleCompanyCarsService.getDocument(id, fileName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  removeDocument(data: { documentForm: FormGroup; index: number; }) {
    this.form.markAsDirty();
    this.form.removeAt(data.index);
  }

  removeDocumentFile(documentForm: FormGroup) {
    documentForm.markAsDirty();
    documentForm.patchValue({
      file: null,
      fileName: null
    });
  }

  uploadDocument(documentForm: FormGroup, carId: number) {
    const { file } = documentForm.getRawValue();

    if (file) {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('fileType', file.type);
      formData.append('carId', `${carId}`);

      this.shuttleCompanyCarsService.uploadDocument(formData)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          id => {
            documentForm.markAsDirty();
            documentForm.patchValue({ id, fileName: file.name });
          },
          () => {
            documentForm.markAsDirty();
            documentForm.patchValue({ file: null });
          }
        );
    }
  }
}
