<ng-container *ngIf="departments?.length">
  <div 
    class="demands-passengers-day-department-group" *ngFor="let department of departments; let i = index"
    [ngStyle]="{
      height: departmentGroupHeights[i] + 'px'
    }"
  >
    <div class="demands-passengers-day-department">
      <div class="demands-passengers-day-department__name">{{department.departmentName || (config().dictionary.withoutDepartment | translate)}}</div>
      <div class="demands-passengers-day-department__passengers">({{department.demands?.length}})</div>
    </div>
    <div
      *ngFor="let demand of department.demands"
      class="demands-passengers-day-action demands-passengers-day-action_type"
      [ngStyle]="{
        'border-color': shift.branchColor
      }"
      #popover="uPopover"
      [uPopover]="assignPassenger" 
      [placement]="isRtl ? [ 'right-top', 'right-bottom', 'right', 'left-top', 'left-bottom', 'left' ] : [ 'left-top', 'left-bottom', 'left', 'right-top', 'right-bottom', 'right' ]"
      [container]="'body'"
      [viewportAutoClose]="false"
      [autoClose]="false"
      [verticalAlignByViewport]="true"
      [popoverClass]="'u-popover_app-demands-assign-shift'"
      [triggers]="'manual'"
      (click)="assignPassengerEdit(demand, popover)"
    >
      <div class="demands-passengers-day-action__header">
        <div class="demands-passengers-day-action__header-title">
          {{demand.firstName}} {{demand.lastName}}
        </div>
        <div class="demands-passengers-day-action__header-icons">
          <div
            class="demands-passengers-day-action__header-icon"
            uTooltip="{{
              (demand.demandType === demandsDemandType.Regular || demand.demandType === demandsDemandType.Reservation) ?
              (demand.createDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME) : null
            }}"
            [placement]="[ 'top' ]"
            [tooltipClass]="'u-tooltip_app-demands-icon'"
            [container]="'body'"
            [ngClass]="{
              'demands-passengers-day-action__header-icon_clock': demand.demandType === demandsDemandType.Regular,
              'demands-passengers-day-action__header-icon_mobile': demand.demandType === demandsDemandType.Reservation,
              'demands-passengers-day-action__header-icon_repeat': demand.demandType === demandsDemandType.Repeatable
            }"
          ></div>
          <div
            *ngIf="demand.demandType === demandsDemandType.Repeatable"
            class="demands-passengers-day-action__header-icon demands-passengers-day-action__header-icon_clock"
            uTooltip="{{demand.createDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}"
            [placement]="[ 'top' ]"
            [tooltipClass]="'u-tooltip_app-demands-icon'"
            [container]="'body'"
          ></div>
          <div
            class="demands-passengers-day-action__header-icon demands-passengers-day-action__header-icon_delete"
            (click)="deletePassenger($event, demand)"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="demands-passengers-day-action demands-passengers-day-action_new"
      [ngClass]="{
        'demands-passengers-day-action_department': department.demands?.length,
        'demands-passengers-day-action_transparent': department.demands?.length,
        'demands-passengers-day-action_new_visible': popoverNew.isOpen() || department.demands?.length,
        'demands-passengers-day-action_disabled': !(shift.days | includes: dayOfWeek)
      }"
      #popoverNew="uPopover"
      [uPopover]="assignPassenger" 
      [placement]="isRtl ? [ 'right-top', 'right-bottom', 'right', 'left-top', 'left-bottom', 'left' ] : [ 'left-top', 'left-bottom', 'left', 'right-top', 'right-bottom', 'right' ]"
      [container]="'body'"
      [viewportAutoClose]="false"
      [autoClose]="false"
      [verticalAlignByViewport]="true"
      [popoverClass]="'u-popover_app-demands-assign-shift'"
      [triggers]="'manual'"
      (click)="assignNewPassenger(popoverNew, department)"
    >
      <div class="demands-passengers-day-action-new"></div>
    </div>
  </div>
</ng-container>

<div
  *ngIf="!departments?.length"
  class="demands-passengers-day-action demands-passengers-day-action_new"
  [ngClass]="{
    'demands-passengers-day-action_new_visible': popoverNew.isOpen(),
    'demands-passengers-day-action_new_offset': !!shift?.demands?.length,
    'demands-passengers-day-action_disabled': !(shift.days | includes: dayOfWeek)
  }"
  #popoverNew="uPopover"
  [uPopover]="assignPassenger" 
  [placement]="isRtl ? [ 'right-top', 'right-bottom', 'right', 'left-top', 'left-bottom', 'left' ] : [ 'left-top', 'left-bottom', 'left', 'right-top', 'right-bottom', 'right' ]"
  [container]="'body'"
  [viewportAutoClose]="false"
  [autoClose]="false"
  [verticalAlignByViewport]="true"
  [popoverClass]="'u-popover_app-demands-assign-shift'"
  [triggers]="'manual'"
  (click)="assignNewPassenger(popoverNew)"
>
  <div class="demands-passengers-day-action-new"></div>
</div>

<ng-template #assignPassenger>
  <app-demands-assign-shift
    [featureType]="featureType"
    [date]="date"
    [dayOfWeek]="dayOfWeek"
    [shift]="shift"
    [passengerShiftDemand]="passengerShiftDemand()"
    [assignPassengerDemand]="true"
    [editData]="assignPassengerEditData()"
    [view]="demandsView.Shifts"
    (closeAction)="assignPassengerClose()"
  ></app-demands-assign-shift>
</ng-template>
