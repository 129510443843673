export enum RideChangeType {
  StationsAdded,
  StationsRemoved,
  StationAddressChanged,
  StationsOrderChanged,
  TrackNameChanged,
  TrackCodeChanged,
  UseTollRoadsChanged,
  BudgetTypeChanged,
  DepartmentChanged,
  TrackPeriodChanged,
  TrackCommentsChanged,
  ActiveDaysChanged,
  WaypointsChanged,
  HoursChanged,
  SupplierAssigned,
  SupplierChanged,
  DriverAssigned,
  DriverChanged,
  CarTypeChanged,
  SupplierCostChanged,
  AccompanyAssigned,
  AccompanyChanged,
  AccompanyCostChanged,
  TrackCreated,
  SubCustomers,
  AccompanyReturn,
  RouteDeleted = 27,
  RideCancelled,
  PassengerCancel,
  PassengerAdd,
  DestinationAdded,
  DestinationRemoved,
  CarChanged,
  SubSupplierChanged,
  RideDeleted,
  StationOrderTypeChanged,
  RideRestored,
  BranchChanged,
  BidNumberChanged,
  RouteNewYearDuplicated,
  WaitOnStationChanged,
  CalculateByChanged,
  RideCanceledWithBilling,
  ChangedCancellationBilling,
  SupervisorAssigned,
  SupervisorChanged,
  CommanderAssigned,
  CommanderChanged,
  RouteSpeedRateChanged,
  TimeTrackingStartDateTimeChanged,
  TimeTrackingEndDateTimeChanged,
  RegulationNumberChanged,
  ContractChanged
}

export enum RideCostType {
  ManualPrice,
  PricePerKm,
  PriceByCity,
  FixedRidePrice,
  OccasionalRidePrice,
  ShuttleRidePrice,
  StandByRidePrice,
  MultiDaysRidePrice
}

export enum RideStationEntrance {
  never,
  beforeExpectedTime,
  afterExpectedTime
}

export enum RideTypesPerChanges {
  StartHour,
  EndHour,
  Accompany
}

export enum RideTimeType {
  StartTime = 1,
  EndTime = 2
}

export interface RideStationEntranceStatus {
  actualArriveDateTime: string;
  stationEntrance: RideStationEntrance;
}

export interface RideMonitoringData {
  rideId: number;
  actualArriveDateTime: string;
  gapInMinutes: number;
  stationEntranceActualArriveDateTime: string;
  stationEntrance: number;
  stationEntranceStatus: RideStationEntranceStatus;
}
