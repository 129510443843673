import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RoutePolicy } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class RoutePolicyService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  generateRoutePolicy(data: RoutePolicy): UntypedFormGroup {
    const routePolicy = this.fb.group({
      useSharing: [ data.useSharing ],
      maxDurationMinutes: [ data.maxDurationMinutes, [ Validators.required ] ],
      useGetEarlyToDestination: [ data.useGetEarlyToDestination ],
      getEarlyToDestinationMinutes: [ data.getEarlyToDestinationMinutes ],
      useCentralStations: [ data.useCentralStations ],
      centralStationRadiusMeters: [ data.centralStationRadiusMeters ],
      curbApproach: [ data.curbApproach ],
      useBusStations: [ { value: data.useBusStations, disabled: !data.useCentralStations } ],
      waitOnStationSeconds: [ data.waitOnStationSeconds ],
      useTollRoads: [ data.useTollRoads ],
      useTraffic: [ data.useTraffic ],
      calculateBy: [ data.calculateBy ],
      cheapestPrice: [ data.cheapestPrice ],
      consolidationTimeMinutes: [ data.consolidationTimeMinutes ],
      passengerStationType: [ data.passengerStationType ],
      useCityOptions: [ data.useCityOptions ],
      directRoute: [ data.directRoute ],
      passengerAddressPolicy: [ data.passengerAddressPolicy ],
      routeNamePolicy: [ data.routeNamePolicy ],
      useEqualDistribution: [ data.useEqualDistribution ],
      algorithmVersion: [ data.algorithmVersion ],
      speedRate: [ data.speedRate ]
    });

    const useCentralStations = routePolicy.get('useCentralStations');
    const useBusStations = routePolicy.get('useBusStations');

    useCentralStations
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: boolean) => {
        if (value) {
          useBusStations.enable();
        } else {
          useBusStations.patchValue(false);
          useBusStations.disable();
        }
      });

    return routePolicy;
  }
}
