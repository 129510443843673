import { USelectSItem } from '@shift/ulib';

import { ShuttleCompanyRideLocationType } from '@app/shuttle-companies/models';

const DICTIONARY_PATH: string = 'builder.rideOrder.details';

const assignSCByItems: USelectSItem[] = [
  { value: ShuttleCompanyRideLocationType.StartLocation, name: `${DICTIONARY_PATH}.assignSCBy.items.startLocation` },
  { value: ShuttleCompanyRideLocationType.EndLocation, name: `${DICTIONARY_PATH}.assignSCBy.items.endLocation` }
];

export const builderRideOrderDetailsComponentConfig = {
  idPrefix: 'builder-ride-order-details',
  assignSCByItems,
  dictionary: {
    assignSCBy: `${DICTIONARY_PATH}.assignSCBy.title`,
    orderNumber: `${DICTIONARY_PATH}.orderNumber`,
    department: `${DICTIONARY_PATH}.department`,
    departmentDivision: `${DICTIONARY_PATH}.departmentDivision`,
    region: `${DICTIONARY_PATH}.region`,
    vehicleTypeAndQuantity: `${DICTIONARY_PATH}.vehicleTypeAndQuantity`,
    quantity: `${DICTIONARY_PATH}.quantity`,
    orderPurpose: `${DICTIONARY_PATH}.orderPurpose`,
    date: `${DICTIONARY_PATH}.date`,
    time: `${DICTIONARY_PATH}.time`,
    rideSupervisor: `${DICTIONARY_PATH}.rideSupervisor`,
    comments: `${DICTIONARY_PATH}.comments`,
    routeType: `${DICTIONARY_PATH}.routeType`,
    emptyStations: {
      title: `${DICTIONARY_PATH}.emptyStations.title`,
      warning: `${DICTIONARY_PATH}.emptyStations.warning`
    },
    select: `general.select`,
    removeSelect: 'general.removeSelect',
    uInputDate: '`uInputDate`',
    uSelectS: 'uSelectS',
    uToggle: 'uToggle'
  }
};
