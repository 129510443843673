import { Component, OnInit, Input, HostBinding, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { USelectSItem } from '@shift/ulib';

import { TrackingService } from '@app/shared/services';
import { RouteDirection } from '@app/routes/models';
import {
  AuthModuleName,
  AuthModuleRouteBuilderFeature,
  AuthModuleRouteBuilderFeatureDetail,
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType,
  AuthModuleRouteBuilderFeatureOrderPurpose
} from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { builderConfig } from '@app/builder/configs';
import { BuilderBuildMode } from '@app/builder/models';
import { BuilderDataService, BuilderDataStoreService } from '@app/builder/services';
import { builderDetailsComponentConfig } from './builder-details.component.config';

@Component({
  selector: 'app-builder-details',
  templateUrl: './builder-details.component.html',
  styleUrls: [ './builder-details.component.scss', './builder-details.component.rtl.scss' ]
})
export class BuilderDetailsComponent implements OnInit {
  @Input() options: any;
  @Input() addEditForm: any;
  @Input() isRtl: boolean;
  @Input() lang: string;
  @Input() viewportElement: HTMLElement;
  @Input() orderPurposesByRouteTypeOptions: { [key: string]: USelectSItem[]; };

  @HostBinding('class') hostClasses: string = 'builder-details';

  private readonly destroyRef = inject(DestroyRef);
  private readonly authDataService = inject(AuthDataService);
  private readonly trackingService = inject(TrackingService);
  public readonly builderDataService = inject(BuilderDataService);
  public readonly builderDataStoreService = inject(BuilderDataStoreService);

  routeDirection = RouteDirection;
  builderBuildMode = BuilderBuildMode;
  departmentsType: AuthModuleDepartmentsFeatureType;
  departmentsTypes = AuthModuleDepartmentsFeatureType;
  routeBuilderDetail = AuthModuleRouteBuilderFeatureDetail;
  routeBuilderDetails: AuthModuleRouteBuilderFeatureDetail[];
  currentRouteBuilderOrderPurpose: AuthModuleRouteBuilderFeatureOrderPurpose;
  hasMasterCustomerFeature: boolean;
  config = cloneDeep(builderDetailsComponentConfig);

  ngOnInit() {
    this.getAuthModules();
  }

  private getAuthModules() {
    this.authDataService.modules$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(modules => {
        this.routeBuilderDetails = modules[AuthModuleName.RouteBuilder]?.[AuthModuleRouteBuilderFeature.Details];
        this.departmentsType = modules[AuthModuleName.Departments]?.[AuthModuleDepartmentsFeature.Type];
        this.hasMasterCustomerFeature = !!modules[AuthModuleName.RouteBuilder]?.[AuthModuleRouteBuilderFeature.MasterCustomer];
        this.currentRouteBuilderOrderPurpose = modules[AuthModuleName.RouteBuilder]?.[AuthModuleRouteBuilderFeature.OrderPurpose];
      });
  }

  track(message: string) {
    this.trackingService.track(`[${builderConfig.trackingId} - Details] - ${message}`);
  }

  onRequiresApprovalChange(isRequired: boolean) {
    this.track(`Requires approval - ${isRequired ? 'Yes' : 'No'}`);
  }

  onAllowEmptyStationsChange(value: boolean) {
    this.track(`Allow empty stations - ${value ? 'Active' : 'Inactive'}`);
  }
}
