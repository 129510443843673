import { Component, DestroyRef, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first, take } from 'rxjs/operators';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UButtonModule, UCommonModule, UDateRangePreset, UDateRangePresetsDataAction, UDateRangePresetsMode, UPopupService } from '@shift/ulib';

import { LocalizationService, ValidationService, TrackingService, SystemService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuthDataService } from '@app/auth/services';
import { RoutesExportOutputAction, RoutesExportPdfBody, RoutesExportType } from '@app/routes/models';
import { RoutesService } from '@app/routes/services';
import { routesExportComponentConfig } from './routes-export.component.config';

@Component({
  selector: 'app-routes-export',
  templateUrl: './routes-export.component.html',
  styleUrls: [ './routes-export.component.scss', './routes-export.component.rtl.scss' ],
  standalone: true,
  imports: [ CommonModule, UCommonModule, TranslateModule, ReactiveFormsModule, UButtonModule ]
})
export class RoutesExportComponent implements OnInit {
  @Output() sendPdf: EventEmitter<any> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'routes-export';

  config;
  exportType: RoutesExportType;
  emailControl: UntypedFormControl;
  isRtl: boolean = this.localizationService.isRtl();
  from: string;
  to: string;
  dataRangePresets: UDateRangePresetsDataAction;
  routeIds: number[];
  disableDelete: boolean;
  downloadMaxCount: string;
  uDateRangePresetsMode = UDateRangePresetsMode;
  activePreset: UDateRangePreset;
  dates: string[];

  constructor(
    private destroyRef: DestroyRef,
    private authDataService: AuthDataService,
    private localizationService: LocalizationService,
    private modalRef: BsModalRef,
    private routesService: RoutesService,
    private systemService: SystemService,
    private uPopupService: UPopupService,
    private headerDataService: HeaderDataService,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.initAuthUserInfo();
  }

  private initAuthUserInfo() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(authUserInfo => {
        this.config = cloneDeep(routesExportComponentConfig[this.exportType]);

        this.emailControl = new UntypedFormControl(authUserInfo.person.email, [ Validators.required, ValidationService.email ]);

        if (this.exportType === RoutesExportType.Pdf) {
          this.getMaxAmountOfRoutesForDownloadPdf();
        }

        this.updateActivePresetAndDates();
      });
  }

  private getMaxAmountOfRoutesForDownloadPdf() {
    this.systemService
      .systemSettingsValues('MaxAmountOfRoutesForDownloadPdf')
      .pipe(first())
      .subscribe(res => {
        if (res) {
          this.disableDelete = res < this.routeIds.length;
          this.downloadMaxCount = res;
        }
      });
  }

  private updateActivePresetAndDates() {
    const startDateWeek = moment().startOf('week');
    const endDateWeek = moment().endOf('week').startOf('day');
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('day');

    if (activeDate.isBetween(startDateWeek, endDateWeek, null, '[]')) {
      this.activePreset = null;
      this.dates = null;
    } else {
      this.activePreset = UDateRangePreset.Custom;
      this.dates = [
        moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO),
        moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).endOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO)
      ];
    }
  }

  private exportPdf(outputAction: RoutesExportOutputAction): void {
    if (!this.emailControl.valid && outputAction === RoutesExportOutputAction.Email) { return; }

    const { from, to } = this.dataRangePresets;

    const params: RoutesExportPdfBody = {
      routeIds: this.routeIds,
      dateFrom: from,
      dateTo: to,
      receiver: this.emailControl.value,
      outputAction
    };

    this.routesService.exportPdf(params).subscribe(() => {
      this.close();

      if (outputAction === RoutesExportOutputAction.Email) {
        this.sendPdf.emit({ sendPdf: true, email: this.emailControl.value });
      }
    });

    this.showPopup(outputAction);
  }

  private showPopup(type: RoutesExportOutputAction): void {
    const popupOptions = {
      message: type === RoutesExportOutputAction.Email ? this.config.dictionary.sendPopup : this.config.dictionary.downloadPopup,
      yes: this.config.dictionary.confirm
    };

    this.uPopupService.showMessage(popupOptions,
      () => {
        this.close();
      });
  }

  private exportExcel(outputAction: RoutesExportOutputAction) {
    this.routesService.exportExcel({
      dateFrom: this.dataRangePresets.from,
      dateTo: this.dataRangePresets.to,
      outputAction,
      receiver: this.emailControl.value,
      routeIds: this.routeIds
    })
      .pipe(first())
      .subscribe(() => this.close());

    this.showPopup(outputAction);
  }

  changeDatesAction(data: UDateRangePresetsDataAction): void {
    this.dataRangePresets = data;
  }

  sendEmail(): void {
    this.trackEvent('click on send');

    switch (this.exportType) {
      case RoutesExportType.Pdf: {
        this.exportPdf(RoutesExportOutputAction.Email);

        break;
      }

      case RoutesExportType.Excel: {
        this.exportExcel(RoutesExportOutputAction.Email);

        break;
      }
    }
  }

  close(): void {
    this.modalRef.hide();
  }

  download(): void {
    this.trackEvent('click on download');

    switch (this.exportType) {
      case RoutesExportType.Pdf: {
        this.exportPdf(RoutesExportOutputAction.Download);

        break;
      }

      case RoutesExportType.Excel: {
        this.exportExcel(RoutesExportOutputAction.Download);

        break;
      }
    }
  }

  trackEvent(event: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${event}`);
  }
}
