import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';

import {
  RoutesMovePassengersActionData,
  RoutesMovePassengersGetRoutes,
  RoutesMovePassengersGetRoutesParams,
  RoutesMovePassengersActionParams,
  RoutesMovePassengersFinish,
  RoutesMovePassengersRouteMapView,
  RoutesMovePassengersRouteMapViewGetParams
} from '@app/routes/models';

@Injectable()
export class RouteStationsMoveService {
  private BASE_PATH: string = 'RouteStationsMove';

  constructor (
    private apiService: ApiService
  ) {}

  initialize(): Observable<string> {
    return this.apiService.post(`${this.BASE_PATH}/Initialize`);
  }

  getBranches(): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/Branches`);
  }

  start(routeId: number): Observable<string> {
    return this.apiService.get(`${this.BASE_PATH}/Start`, { routeId });
  }

  getRoutes(params: RoutesMovePassengersGetRoutesParams): Observable<RoutesMovePassengersGetRoutes> {
    return this.apiService.post(`${this.BASE_PATH}/GetRoutes`, params);
  }

  move(params: RoutesMovePassengersActionParams, skipErrorCodesMessage?: (number | string)[]): Observable<RoutesMovePassengersActionData> {
    return this.apiService.post(`${this.BASE_PATH}`, params, 'text', false, false, skipErrorCodesMessage);
  }

  finish(sendBackgroundEmail?: boolean): Observable<RoutesMovePassengersFinish> {
    return this.apiService.post(`${this.BASE_PATH}/Finish`, { sendBackgroundEmail });
  }

  getRouteMapViewData(params: RoutesMovePassengersRouteMapViewGetParams): Observable<RoutesMovePassengersRouteMapView[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetRoutesMapView`, params);
  }

  resetSession(): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Reset`);
  }

  saveSession(): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Save`);
  }
}
