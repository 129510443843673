import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { UTooltipModule } from '@shift/ulib';

import { RideDriverApproval, RideDriverApprovalApproveStatus } from '@app/ride-approvals/models';

@Component({
  selector: 'app-routes-ride-drivers',
  templateUrl: './routes-ride-drivers.component.html',
  styleUrl: './routes-ride-drivers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UTooltipModule
  ]
})
export class RoutesRideDriversComponent {
  readonly drivers = input<RideDriverApproval[]>([]);

  @HostBinding('class') hostClasses: string = 'routes-ride-drivers';

  readonly rideDriverApprovalApproveStatus = RideDriverApprovalApproveStatus;
}
