<h4 class="routes-change-accompany-cost__title">{{config.dictionary.title | translate}}</h4>

<u-group
  class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
  [name]="config.dictionary.costCalculation | translate"
>
  <div class="routes-change-accompany-cost__double-field">
    <div class="routes-change-accompany-cost__double-field-item">
      <u-select-s
        [classUInput]="'u-input_arrow-light u-input_light'"
        [classUDropdownContent]="'u-dropdown-content_app-routes-change'"
        [items]="config.costCalculationCostTypeOptions"
        [formControl]="form.get('costType')"
        [valid]="form.get('costType').valid"
        [empty]="false"
        [placement]="[ 'bottom-left', 'bottom-right' ]"
        [messages]="config.dictionary.uSelectS | translate"
      ></u-select-s>
    </div>
    <div class="routes-change-accompany-cost__double-field-item">
      <u-input-number
        *ngIf="form.get('costType').value === costType.Fixed"
        [formControl]="form.controls['totalCost']"
        [decimal]="true"
        [maxDecimalDigits]="2"
        [valueType]="'number'"
      ></u-input-number>
      <u-input-number
        *ngIf="form.get('costType').value === costType.Hourly"
        [formControl]="form.controls['costPerHour']"
        [decimal]="true"
        [maxDecimalDigits]="2"
        [valueType]="'number'"
      ></u-input-number>
    </div>
  </div>
</u-group>

<u-group
  *ngIf="form.get('costType').value === costType.Hourly"
  class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
  [name]="config.dictionary.hours | translate"
>
  <div class="routes-change-accompany-cost__double-field">
    <div class="routes-change-accompany-cost__double-field-item">
      <u-select-s
        [items]="config.hoursPriceOptions"
        [formControl]="form.controls['priceOption']"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [empty]="false"
        [messages]="config.dictionary.uSelectS | translate"
        (selectItem)="selectHoursPriceOption($event.value)"
      ></u-select-s>
    </div>
    <div class="routes-change-accompany-cost__double-field-item">
      <u-input-number
        [formControl]="form.controls['hours']"
        [decimal]="true"
        [maxDecimalDigits]="2"
        [valueType]="'number'"
      ></u-input-number>
    </div>
  </div>
</u-group>

<u-group
  *ngIf="form.get('costType').value === costType.Hourly"
  class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
  [name]="config.dictionary.cost | translate"
>
  <div class="routes-change-accompany-cost__hours-cost-container">
    <span class="routes-change-accompany-cost__hours-cost-value">{{form.get('hours').value * form.get('costPerHour').value}}</span>
  </div>
</u-group>

<u-group
  class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
  [name]="config.dictionary.type | translate"
>
  <app-routes-period
    [typeFormControl]="form.controls['type']"
    [changes]="form.controls['datesChange'].value"
    [viewportElement]="viewportElement"
    (updatePeriod)="updatePeriod($event)"
    (updateDates)="updateDates($event)"
  ></app-routes-period>
</u-group>

<div class="routes-change-accompany-cost__buttons">
  <div class="routes-change-accompany-cost__button">
    <button
      uButton
      class="u-font-weight-medium routes-change-accompany-cost__btn"
      [size]="uButtonSize.Small"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      {{config.dictionary.actions.saveAndClose | translate}}
    </button>
  </div>

  <div class="routes-change-accompany-cost__button">
    <button
      uButton
      class="u-font-weight-medium routes-change-accompany-cost__btn"
      [size]="uButtonSize.Small"
      [disabled]="form.invalid"
      (click)="submit(routesChangeEmailSendType.SendBackgroundEmail)"
    >
      {{config.dictionary.actions.saveAndSendSC | translate}}
    </button>
  </div>
  <div class="routes-change-accompany-cost__button">
    <button
      uButton
      class="u-font-weight-medium routes-change-accompany-cost__btn"
      [size]="uButtonSize.Small"
      [disabled]="form.invalid"
      (click)="submit(routesChangeEmailSendType.GenerateEditableEmail)"
    >
      {{config.dictionary.actions.saveAndEditEmail | translate}}
    </button>
  </div>
</div>
