<div class="entity-menu__groups">
  <div class="entity-menu__group" *ngFor="let item of menuStore">
    <div class="entity-menu__group-name">{{item.name | translate}}</div>
    <div class="entity-menu__items">
      <div
        *ngFor="let menuItem of item.items"
        class="entity-menu__item"
        [ngClass]="{'entity-menu__item_active': activeAction === menuItem.action}"
        (click)="menuItemClick(menuItem)"
      >
        {{menuItem.name | translate}}
        <span *ngIf="menuItem.required" class="entity-menu__item-required">*</span>
      </div>
    </div>
  </div>
</div>
