const DICTIONARY_PATH: string = 'routes.daily.fine';

export const routesDailyFineComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    date: `${DICTIONARY_PATH}.date`,
    shuttleCompany: `${DICTIONARY_PATH}.shuttleCompany`,
    noShuttleCompany: `${DICTIONARY_PATH}.noShuttleCompany`,
    contract: `${DICTIONARY_PATH}.contract`,
    selectPlaceholder: `${DICTIONARY_PATH}.selectPlaceholder`,
    violation: `${DICTIONARY_PATH}.violation`,
    amount: `${DICTIONARY_PATH}.amount`,
    comment: `${DICTIONARY_PATH}.comment`,
    commentPlaceholder: `${DICTIONARY_PATH}.commentPlaceholder`,
    createFineMessage: {
      success: `${DICTIONARY_PATH}.createFineMessage.success`,
      fail: `${DICTIONARY_PATH}.createFineMessage.fail`
    },
    uInputDate: 'uInputDate',
    uSelectS: 'uSelectS',
    select: 'general.select',
    removeSelect: 'general.removeSelect',
    save: 'general.save',
    closeConfirm: 'general.closeConfirm',
    yes: 'general.yes',
    no: 'general.no'
  }
};
