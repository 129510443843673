import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { UButtonSize, UHereMapBounds, UHereMapLatLng, UHereMapPointCenterConfig, UPopoverDirective } from '@shift/ulib';

import { CommonService, LocalizationService, TrackingService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuthDataService } from '@app/auth/services';
import { AuthCustomerType, AuthUserInfo } from '@app/auth/models';
import { RouteDailyRideStatus, RouteDirection } from '@app/routes/models';
import { MonitoringDashboardDataService, MonitoringDashboardHubService, MonitoringDashboardRidesService, MonitoringDashboardService } from '@app/monitoring-dashboard/services';
import { MonitoringDashboardRideBase, MonitoringDashboardRideDetailsBase, MonitoringDashboardStation, MonitoringDashboardStationType } from '@app/monitoring-dashboard/models';
import { monitoringDashboardConfig } from '@app/monitoring-dashboard/configs';
import { monitoringDashboardComponentConfig } from './monitoring-dashboard.component.config';

@Component({
  selector: 'app-monitoring-dashboard',
  templateUrl: './monitoring-dashboard.component.html',
  styleUrls: [ './monitoring-dashboard.component.scss', './monitoring-dashboard.component.rtl.scss' ],
  providers: [ MonitoringDashboardRidesService, MonitoringDashboardDataService, MonitoringDashboardHubService ]
})
export class MonitoringDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('scroll', { static: false }) virtualScroller: any;

  @HostBinding('class') hostClasses: string = 'monitoring-dashboard';

  private unsubscribe: Subject<void> = new Subject();

  routeDirection = RouteDirection;
  activeRideDirection: RouteDirection;
  rideStationsShow: boolean;
  bounds: UHereMapBounds;
  stationCenter: {
    position: UHereMapLatLng;
    config: UHereMapPointCenterConfig;
  } = {
      position: null,
      config: {
        zoom: 17
      }
    };
  headerDate: any;
  monitoringDetails = {
    appVersion: null,
    batteryLevel: null,
    gpsStatus: null,
    lastInteractionDateTime: null,
    platform: null,
    firstCoordinate: null,
    lastCoordinate: null
  };
  isStationsOpen: boolean;
  activeMonitoringParticipant: any;
  popoverDetails: UPopoverDirective;
  isRtl: boolean = this.localizationService.isRtl();
  isRideHaveAccompany: boolean = false;
  isRideHaveSupervisor: boolean;
  isMunicipalityCustomer: boolean;
  reportedSum: number = 0;
  notReportedSum: number = 0;
  pickedUpSum: number = 0;
  notPickedUpSum: number = 0;
  notMarkedSum: number = 0;
  appConstants = AppConstants;
  monitoringDashboardStationType = MonitoringDashboardStationType;
  routeDailyRideStatus = RouteDailyRideStatus;
  authUserInfo: AuthUserInfo;
  uButtonSize = UButtonSize;
  config = cloneDeep(monitoringDashboardComponentConfig);
  ridesSearchText: UntypedFormControl = new UntypedFormControl('');
  stationsSearchText: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private localizationService: LocalizationService,
    private authDataService: AuthDataService,
    private monitoringDashboardService: MonitoringDashboardService,
    public monitoringDashboardRidesService: MonitoringDashboardRidesService,
    public monitoringDashboardDataService: MonitoringDashboardDataService,
    public monitoringDashboardHubService: MonitoringDashboardHubService,
    public commonService: CommonService,
    public trackingService: TrackingService,
    public headerDataService: HeaderDataService
  ) {}

  async ngOnInit() {
    this.headerDataService.updateShowGlobalSearch(false);
    this.monitoringDashboardHubService.init();

    await this.monitoringDashboardHubService.start();

    this.monitoringDashboardHubService
      .onReceiveCoordinates()
      .subscribe(data => {
        this.monitoringDashboardDataService.coordinatesReceive(data);
        this.monitoringDashboardRidesService.updateLiveCoordinate(data);
      });

    this.monitoringDashboardHubService
      .onUpdateTracksStatus()
      .subscribe(data => {
        this.monitoringDashboardRidesService.setRideStatus(data.id, data.status);

        if (data.status === RouteDailyRideStatus.Finished) {
          const rideActive = this.monitoringDashboardRidesService.getRideActive();

          if (rideActive && rideActive.id === data.id) {
            this.rideActiveSelect(rideActive);
          }
        }
      });

    this.monitoringDashboardHubService
      .onUpdateTracksDelay()
      .subscribe(data => {
        this.monitoringDashboardRidesService.setRideDelay(data.id, data.delay);
      });

    this.monitoringDashboardHubService
      .onUpdatePassengersStatus()
      .subscribe(data => {
        this.monitoringDashboardRidesService.setRideActiveParticipantStatus(data.passengerId, data.status);
      });

    this.initAuthUserInfo();
  }

  ngOnDestroy() {
    this.headerDataService.updateShowGlobalSearch(true);
    this.monitoringDashboardHubService.stop();
    this.headerDate.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initAuthUserInfo() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.authUserInfo = data;
        this.isMunicipalityCustomer = data.customer.type === AuthCustomerType.Municipality;

        this.onHeaderDateChange();
      });
  }

  private onHeaderDateChange() {
    this.headerDate = this.headerDataService.date$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.rideStationsShowChange(false);

        this.monitoringDashboardDataService.markersClear();
        this.monitoringDashboardDataService.pathsClear();
        this.monitoringDashboardDataService.coordinatesClear();
        this.monitoringDashboardRidesService.clear();

        this.getRides();
      });
  }

  trackEvent(message: string) {
    this.trackingService.track(`[${monitoringDashboardConfig.trackingId}] - ${message}`);
  }

  get accompany() {
    return this.monitoringDashboardRidesService.rideActive.details.accompany;
  }

  get driver() {
    return this.monitoringDashboardRidesService.rideActive.details.driver;
  }

  get details() {
    return this.monitoringDashboardRidesService.rideActive.details;
  }

  getRides() {
    this.monitoringDashboardService
      .getRides(moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(this.appConstants.DATE_FORMAT_BASE_LINE))
      .subscribe(
        rides => {
          this.monitoringDashboardRidesService.load(rides);

          const monitoringDashboard = this.headerDataService.monitoringDashboard();

          if (monitoringDashboard.rideId) {
            const rideActive = this.monitoringDashboardRidesService.rides.find(obj => obj.rideId === monitoringDashboard.rideId || obj.routeId === monitoringDashboard.routeId);

            if (rideActive) {
              this.rideActiveSelect(rideActive);

              return;
            }
          }

          const [ rideFirst ] = this.monitoringDashboardRidesService.rides;
          const rideOngoing = this.monitoringDashboardRidesService.rides.find(obj => obj.status === RouteDailyRideStatus.Ongoing || obj.status === RouteDailyRideStatus.OngoingMonitored);
          const rideStatus = this.monitoringDashboardRidesService.rides.find(obj => obj.status === RouteDailyRideStatus.New);

          this.rideActiveSelect(rideOngoing || rideStatus || rideFirst);
        }
      );
  }

  private initPathsLiveMonitoringPoints(ride: MonitoringDashboardRideBase, details: MonitoringDashboardRideDetailsBase) {
    if (ride.rideStatus !== RouteDailyRideStatus.OngoingMonitored || !details.monitoredPaths || !details.monitoredPaths.length) {
      return;
    }

    details.monitoredPaths.forEach(monitoredPath => {
      const currentPathLive = this.monitoringDashboardDataService.pathsLive.find(pathLive => pathLive.sourceType === monitoredPath.sourceType);

      if (!currentPathLive || !currentPathLive.points.length) {
        this.monitoringDashboardDataService.coordinatesReceive({
          sourceType: monitoredPath.sourceType,
          memberId: monitoredPath.memberId,
          latitude: monitoredPath.coordinates && monitoredPath.coordinates.length && monitoredPath.coordinates[monitoredPath.coordinates.length - 1].latitude,
          longitude: monitoredPath.coordinates && monitoredPath.coordinates.length && monitoredPath.coordinates[monitoredPath.coordinates.length - 1].longitude,
          locatedAt: monitoredPath.coordinates && monitoredPath.coordinates.length && monitoredPath.coordinates[monitoredPath.coordinates.length - 1].locatedAt
        });
      }
    });
  }

  rideActiveSelect(ride: MonitoringDashboardRideBase) {
    if (ride) {
      this.headerDataService.updateMonitoringDashboard({ rideId: ride.rideId, routeId: ride.routeId });

      this.isStationsOpen = false;
      this.stationsSearchText.reset();
      this.monitoringDashboardDataService.coordinatesClear();

      this.monitoringDashboardService
        .getRideDetails(ride.rideId)
        .subscribe(details => {
          this.monitoringDashboardRidesService.setRideDetailsById(details, ride.rideId);
          this.monitoringDashboardRidesService.setRideActive(ride.rideId);

          this.activeRideDirection = ride.direction;

          this.rideStationsShowChange(true);

          this.monitoringDashboardHubService.monitor(ride.rideId);
          this.virtualScroller.scrollInto(ride);

          this.getSummaryInfo();

          const activeRide = this.monitoringDashboardRidesService.getRideActive();

          if (activeRide && activeRide.details) {
            this.stationCenter.config = {
              ...this.stationCenter.config,
              bounds: {
                points: activeRide.details.stations.map(station => ({ lat: station.latitude, lng: station.longitude }))
              }
            };

            this.bounds = {
              points: activeRide.details.stations.map(station => ({ lat: station.latitude, lng: station.longitude }))
            };

            this.initPathsLiveMonitoringPoints(ride, details);
          }
        });
    }
  }

  rideStationsShowChange(action: boolean) {
    this.rideStationsShow = action;
  }

  stationSetMapCenter(station: MonitoringDashboardStation) {
    if (!this.stationCenter.position || (this.stationCenter.position && this.stationCenter.position.lat !== station.latitude && this.stationCenter.position.lng !== station.longitude)) {
      this.stationCenter.position = {
        lat: station.latitude,
        lng: station.longitude
      };
    } else {
      this.stationCenter.position = null;
    }
  }

  openMonitoringDashboardDetails(item: any, popover: any) {
    this.popoverDetails = popover;
    const lastInteractionDateTime = item.lastInteractionDateTime ? moment(item.lastInteractionDateTime).format('DD/MM HH:mm') : null;

    if (this.monitoringDashboardRidesService.rideActive.details.monitoredPaths) {
      this.activeMonitoringParticipant = this.monitoringDashboardRidesService.rideActive.details.monitoredPaths.find((obj: any) => obj.memberId === item.itemId);
    }

    if (this.activeMonitoringParticipant) {
      const coordinates = this.activeMonitoringParticipant.coordinates;
      let max = coordinates[0].locatedAt;
      let min = max;

      for (let i = 1; i < coordinates.length; i++) {
        if (moment(coordinates[i].locatedAt).valueOf() < moment(max).valueOf()) { max = coordinates[i].locatedAt; }
        if (moment(coordinates[i].locatedAt).valueOf() > moment(min).valueOf()) { min = coordinates[i].locatedAt; }
      }

      this.monitoringDetails = {
        appVersion: item.appVersion,
        batteryLevel: item.batteryLevel,
        gpsStatus: item.gpsStatus,
        lastInteractionDateTime: lastInteractionDateTime,
        platform: item.platform,
        firstCoordinate: moment.utc(max).local().format(this.appConstants.TIME_FORMAT_FULL),
        lastCoordinate: moment.utc(min).local().format(this.appConstants.TIME_FORMAT_FULL)
      };
    } else {
      this.monitoringDetails = {
        appVersion: item.appVersion,
        batteryLevel: item.batteryLevel,
        gpsStatus: item.gpsStatus,
        lastInteractionDateTime: lastInteractionDateTime,
        platform: item.platform,
        firstCoordinate: null,
        lastCoordinate: null
      };
    }
  }

  closeMonitoringDashboardDetails() {
    if (this.popoverDetails.isOpen()) {
      this.popoverDetails.close();
    }
  }

  getSummaryInfo() {
    const { details } = this.monitoringDashboardRidesService.getRideActive();

    this.isRideHaveAccompany = !!details.accompany;
    this.isRideHaveSupervisor = !!details.supervisor;
    this.reportedSum = 0;
    this.notReportedSum = 0;
    this.pickedUpSum = 0;
    this.notPickedUpSum = 0;
    this.notMarkedSum = 0;

    const passengers = details.stations.reduce((acc, station) => [ ...acc, ...station.passengers ], []);

    passengers.forEach(passenger => {
      if (this.isRideHaveSupervisor || this.isRideHaveAccompany) {
        if (passenger.checkIn) {
          this.pickedUpSum++;
        } else if (!passenger.checkIn && passenger.checkIn !== null) {
          this.notPickedUpSum++;
        } else {
          this.notMarkedSum++;
        }
      } else {
        if (passenger.checkIn) {
          this.reportedSum++;
        } else {
          this.notReportedSum++;
        }
      }
    });
  }

  toggleShowPassenger(station: MonitoringDashboardStation) {
    this.trackEvent('station card - click on arrow');

    station.showPassengers = !station.showPassengers;
  }

  toggleOpenStations() {
    this.isStationsOpen = !this.isStationsOpen;

    this.monitoringDashboardRidesService.rideActive.details.stations.forEach(station => station.showPassengers = this.isStationsOpen);
  }
}
