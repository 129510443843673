import { inject, Injectable, signal } from '@angular/core';
import * as moment from 'moment';

import { AppConstants } from '@app/shared/constants';
import { patchSignal } from '@app/shared/utils';
import { AuthDataSnapshotService } from '@app/auth/services';
import { BuilderFullOptions, BuilderFullConfigData } from '@app/builder/models';
import { builderFullConfig } from '@app/builder/configs';

@Injectable()
export class BuilderFullCalculationStoreService {
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  readonly #optionsDefault = signal<BuilderFullOptions>(builderFullConfig.optionsDefault);
  readonly #options = signal<BuilderFullOptions>(builderFullConfig.options);
  readonly #configData = signal<BuilderFullConfigData>(builderFullConfig.configData);

  readonly optionsDefault = this.#optionsDefault.asReadonly();
  readonly options = this.#options.asReadonly();
  readonly configData = this.#configData.asReadonly();

  constructor() {
    this.initDirections();
  }

  private initDirections() {
    this.updateOptions({
      directions: this.optionsDefault().directions.filter(obj => this.authDataSnapshotService.checkFeatureAndPermission(obj))
    });
  }

  updateConfigData(data: BuilderFullConfigData) {
    patchSignal(
      this.#configData,
      {
        ...data,
        minDate: moment(data.minDate, AppConstants.DATE_FORMAT_BASE_LINE).startOf('day').subtract(1, 'days').format(AppConstants.DATE_FORMAT_ISO),
        maxDate: moment(data.maxDate, AppConstants.DATE_FORMAT_BASE_LINE).startOf('day').add(1, 'days').format(AppConstants.DATE_FORMAT_ISO)
      }
    );

    const options = {
      shuttleCompanies: (data.shuttleCompanies ?? []).map(shuttleCompany => ({ value: shuttleCompany.id, name: shuttleCompany.name })),
      vehicleTypes: data.vehicleTypes.map(vehicleType => ({ value: vehicleType.id, name: `${vehicleType.name} (${vehicleType.seatsCount})` })),
      routeTypes: data.routeTypes.map(routeType => ({ value: routeType.id, name: routeType.name }))
    };

    patchSignal(this.#optionsDefault, options);

    this.updateOptions(options);
  }

  updateOptions(data: Partial<BuilderFullOptions>) {
    patchSignal(this.#options, data);
  }
}
