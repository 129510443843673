const DICTIONARY_PATH: string = 'shared.inputMonitoring';

export const inputMonitoringComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    status: `${DICTIONARY_PATH}.status`,
    select: 'general.select',
    removeSelect: 'general.removeSelect'
  }
};
