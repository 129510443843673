import { createReducer, on } from '@ngrx/store';

import * as AuthActions from '@app/auth/state/actions';
import { AuthUserInfo } from '@app/auth/models';

export const FEATURE_KEY: string = 'status';

export interface State {
  inited: boolean;
  data: AuthUserInfo;
}

export const initialState: State = {
  inited: false,
  data: null
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.initFinished, (state) => ({ ...state, inited: true })),
  on(AuthActions.initUser, AuthActions.updateUserSuccess, (state, { data }) => ({ ...state, data }))
);
