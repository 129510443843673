import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { PassengerDetails, PassengersInitialData, PassengersInitialDataType } from '@app/passengers/models';
import { RideSupervisorsParams, RideSupervisors } from '@app/ride-supervisors/models';

@Injectable({
  providedIn: 'root'
})
export class SupervisorsService {
  private BASE_PATH: string = 'Supervisors';

  constructor(
    private apiService: ApiService
  ) {}

  getInitialData(types?: PassengersInitialDataType[]): Observable<PassengersInitialData> {
    return this.apiService.get(`${this.BASE_PATH}/InitialData`, types && { types });
  }

  getSupervisorsTable(data: RideSupervisorsParams): Observable<RideSupervisors> {
    return this.apiService.post(`${this.BASE_PATH}/GetTable`, data);
  }

  getSupervisorById(id: number): Observable<PassengerDetails> {
    return this.apiService.get(`${this.BASE_PATH}/${id}`);
  }

  removeSupervisor(id: number[]): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Delete`, id);
  }

  updateSupervisor(data: PassengerDetails): Observable<PassengerDetails> {
    return this.apiService.put(`${this.BASE_PATH}`, data);
  }

  saveSupervisor(data: PassengerDetails): Observable<PassengerDetails> {
    return this.apiService.post(`${this.BASE_PATH}`, data);
  }

  findSupervisor(contact: string, identity: string): Observable<PassengerDetails> {
    return this.apiService.get(`${this.BASE_PATH}/Find`, { contact, identity });
  }
}
