<div class="general-card__header">
  <div class="general-card__title">
    <span class="general-card__title-text">{{title | translate}}</span>
  </div>
  <span
    class="general-card__close-icon"
    [uId]="closeIconId"
    (click)="closeAction.emit()"
  ></span>
</div>
<div class="general-card__body">
  <ng-content></ng-content>
  <div class="general-card__buttons">
    <button
      *ngFor="let btn of buttons"
      uButton
      class="general-card__btn"
      [disabled]="btn.disabled"
      [uId]="btn.id"
      (click)="buttonClick.emit(btn.value)"
    >
      {{btn.name | translate}}
    </button>
  </div>
</div>
