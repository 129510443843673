import { inject, Injectable } from '@angular/core';

import { ThreeDotsPopoverItem } from '@app/shared/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';

@Injectable()
export class RoutesDataService {
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  private readonly authDataService = inject(AuthDataService);

  getFilteredThreeDotsMenu(menu: ThreeDotsPopoverItem[]): ThreeDotsPopoverItem[] {
    return menu.filter(item =>
      (item.feature ? this.authDataService.checkFeature(item.feature) : true) &&
      (item.permission ? this.authDataSnapshotService.checkPermission(item.permission) : true)
    );
  }
}
