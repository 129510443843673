import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import {
  EmailsContentShuttleCompanyChanges,
  EmailsContentShuttleCompanyChangesParams,
  EmailsSendBackgroundShuttleCompanyChangesParams,
  EmailsShuttleCompany,
  EmailsShuttleCompanyChanges,
  EmailsShuttleCompanyChangesParams
} from '@app/emails/models';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {
  private BASE_PATH: string = 'Emails';

  constructor(private apiService: ApiService) {}

  getShuttleCompanies(): Observable<EmailsShuttleCompany[]> {
    return this.apiService.get(`${this.BASE_PATH}/ShuttleCompanies`);
  }

  getContentShuttleCompanyChanges(params: EmailsContentShuttleCompanyChangesParams): Observable<EmailsContentShuttleCompanyChanges> {
    return this.apiService.post(`${this.BASE_PATH}/Content/ShuttleCompanyChanges`, params);
  }

  sendShuttleCompanyChanges(params: EmailsShuttleCompanyChangesParams): Observable<EmailsShuttleCompanyChanges> {
    return this.apiService.post(`${this.BASE_PATH}/ShuttleCompanyChanges`, params);
  }

  sendBackgroundShuttleCompanyChanges(params: EmailsSendBackgroundShuttleCompanyChangesParams): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/SendBackground/ShuttleCompanyChanges`, params);
  }
}
