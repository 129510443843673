<div 
  class="demands-assign-pick__double"
  *ngIf="form"
>
  <div class="demands-assign-pick__double-item">
    <u-select-s
      [messages]="'uSelectS' | translate"
      [items]="addressTypes"
      [empty]="false"
      [formControl]="form.get('addressType')"
      [valid]="form.get('addressType').valid" 
      [placeholder]="'general.select' | translate"
      [container]="'body'"
      [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
    ></u-select-s> 
  </div>
  <div class="demands-assign-pick__double-item">
    <input
      *ngIf="form.get('addressType').value === passengerShiftAdvancedAddressType.PassengerSettings"
      uInput
      type="text"
      [value]="passengerSettingsValue || ''"
      [disabled]="true"
    >
    <u-address
      *ngIf="form.get('addressType').value === passengerShiftAdvancedAddressType.AnyAddress && form.get('address').value"
      [valid]="form.get('address.fullAddress').valid && form.get('address.latitude').valid && form.get('address.longitude').valid"
      [address]="{
        address: form.get('address.fullAddress').value,
        lat: form.get('address.latitude').value,
        lng: form.get('address.longitude').value
      }"
      [container]="'body'"
      (addressChange)="updateAddress($event)"
      (mapIconClick)="trackingService.track('Address field - click on map icon')"
    ></u-address>
    <u-select-s
      *ngIf="form.get('addressType').value === passengerShiftAdvancedAddressType.SavedStation"
      [messages]="'uSelectS' | translate"
      [items]="stations"
      [empty]="false"
      [formControl]="form.get('stationId')"
      [valid]="form.get('stationId').valid" 
      [placeholder]="'general.select' | translate"
      [container]="'body'"
      [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
    ></u-select-s> 
  </div>
</div>
