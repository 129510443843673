import { USelectSItem } from '@shift/ulib';

import {
  RoutePolicyCalculateBy,
  RoutePolicyPassengerAddressPolicy,
  RoutePolicyPickUpOption,
  RoutePolicyRouteNamePolicy
} from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.routePolicy';

const calculateTypes: USelectSItem[] = [
  { value: RoutePolicyCalculateBy.Time, name: `${DICTIONARY_PATH}.calculateType.time` },
  { value: RoutePolicyCalculateBy.TimeAndDistance, name: `${DICTIONARY_PATH}.calculateType.timeAndDistance` }
];

const passengerStationTypes: USelectSItem[] = [
  { value: null, name: `${DICTIONARY_PATH}.passengerStationType.byPassengersSettings` },
  { value: 0, name: `${DICTIONARY_PATH}.passengerStationType.home` },
  { value: 1, name: `${DICTIONARY_PATH}.passengerStationType.station` }
];

const passengerAddressPolicyTypes: USelectSItem[] = [
  { value: RoutePolicyPassengerAddressPolicy.Exact, name: `${DICTIONARY_PATH}.passengerAddressPolicyType.exact` },
  { value: RoutePolicyPassengerAddressPolicy.Original, name: `${DICTIONARY_PATH}.passengerAddressPolicyType.original` }
];

const routeNamePolicyTypes: USelectSItem[] = [
  { value: RoutePolicyRouteNamePolicy.AllCityNames, name: `${DICTIONARY_PATH}.routeNamePolicyType.allCityNames` },
  { value: RoutePolicyRouteNamePolicy.FirstOrLastAddress, name: `${DICTIONARY_PATH}.routeNamePolicyType.firstOrLastAddress` }
];

const pickupOptionTypes: USelectSItem[] = [
  { value: RoutePolicyPickUpOption.VehicleAbilities, name: `${DICTIONARY_PATH}.pickupOptions.vehicleAbilities` },
  { value: RoutePolicyPickUpOption.AnySide, name: `${DICTIONARY_PATH}.pickupOptions.bothSides` },
  { value: RoutePolicyPickUpOption.RightSide, name: `${DICTIONARY_PATH}.pickupOptions.passengerLocation` }
];

export const routePolicyConfig = {
  calculateTypes,
  passengerStationTypes,
  passengerAddressPolicyTypes,
  routeNamePolicyTypes,
  pickupOptionTypes
};
