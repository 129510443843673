import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RouteLockState } from '@app/route-lock-state/models';

@Injectable({
  providedIn: 'root'
})
export class RouteLockStateService {
  private BASE_PATH: string = 'RouteLockState';

  constructor(
    private apiService: ApiService
  ) {}

  getRouteLockState(routeId: number): Observable<RouteLockState> {
    return this.apiService.get(this.BASE_PATH, { routeId });
  }
}
