import { URangePreset, USelectSItem } from '@shift/ulib';

import { GeneralOptionsType } from '@app/shared/models';
import { RouteRecalculateMode } from '@app/routes/models';
import { PassengersChangesActions } from '@app/passengers/models';

const DICTIONARY_PATH: string = 'routes.changePassenger';

const recalculateRouteOptions: USelectSItem[] = [
  { name: `${DICTIONARY_PATH}.recalculateRouteOptions.keepCurrent`, value: RouteRecalculateMode.KeepCurrent },
  { name: `${DICTIONARY_PATH}.recalculateRouteOptions.optimal`, value: RouteRecalculateMode.Automatic },
  { name: `${DICTIONARY_PATH}.recalculateRouteOptions.manualStations`, value: RouteRecalculateMode.ManualStations }
];

const recalculateRouteOptionsFull: USelectSItem[] = [
  ...recalculateRouteOptions,
  { name: `${DICTIONARY_PATH}.recalculateRouteOptions.manual`, value: RouteRecalculateMode.FullManual }
];

const recalculateRouteOptionsPlanhat = {
  [RouteRecalculateMode.KeepCurrent]: 'keep current',
  [RouteRecalculateMode.Automatic]: 'automatic',
  [RouteRecalculateMode.ManualStations]: 'manual stations',
  [RouteRecalculateMode.FullManual]: 'full manual'
};

const availablePresets: URangePreset[] = [
  URangePreset.DisplayedDay,
  URangePreset.Today,
  URangePreset.Tomorrow,
  URangePreset.ActiveWeekDay,
  URangePreset.FromTodayOn,
  URangePreset.FromCustomDayOn,
  URangePreset.All,
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

export const routesChangePassengerComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    passengers: `${DICTIONARY_PATH}.passengers`,
    type: `${DICTIONARY_PATH}.type`,
    recalculateRoute: `${DICTIONARY_PATH}.recalculateRoute`,
    comments: `${DICTIONARY_PATH}.comments`,
    actions: {
      saveAndClose: 'general.saveAndClose',
      saveAndSendSC: 'general.saveAndSendSC',
      saveAndEditEmail: 'general.saveAndEditEmail'
    }
  },
  actions: {
    default: [
      { name: `${DICTIONARY_PATH}.types.removePassenger`, value: PassengersChangesActions.Remove },
      { name: `${DICTIONARY_PATH}.types.addPassenger`, value: PassengersChangesActions.Add }
    ],
    noPassengers: [
      { name: `${DICTIONARY_PATH}.types.addPassenger`, value: PassengersChangesActions.Add }
    ]
  },
  addNewPassengerOption: { name: 'general.options.addNew', value: GeneralOptionsType.AddNew },
  availablePresets,
  recalculateRouteOptions,
  recalculateRouteOptionsFull,
  recalculateRouteOptionsPlanhat
};
