import { Signal } from '@angular/core';
import { Observable, Unsubscribable } from 'rxjs';

export type RxMethod<Input> = ((
  input: Input | Signal<Input> | Observable<Input>
) => Unsubscribable) &
  Unsubscribable;

export type PatchSignalUpdater<State extends object> = (
  state: State
) => Partial<State>;

export interface TapResponseObserver<T, E> {
  next: (value: T) => void;
  error: (error: E) => void;
  complete?: () => void;
  finalize?: () => void;
}
