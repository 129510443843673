import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/shared/services';
import { FileSaverService } from '@app/files/services';
import {
  FeedDataType,
  FeedTasksType,
  FeedFinishedType,
  FeedRealTimeType,
  FeedReOptimization
} from '@app/feed/models';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  private BASE_PATH: string = 'Feed';

  constructor(
    private apiService: ApiService,
    private fileSaverService: FileSaverService
  ) {}

  getRealTimeData(): Observable<FeedRealTimeType> {
    return this.apiService.get(`${this.BASE_PATH}/OngoingRides`);
  }

  getFinishedData(date: object): Observable<FeedFinishedType> {
    return this.apiService.get(`${this.BASE_PATH}/FinishedRides`, date);
  }

  getTasksData(date: object): Observable<FeedTasksType> {
    return this.apiService.get(`${this.BASE_PATH}/Tasks`, date);
  }

  getAccompanyDetails(date: object): Observable<FeedDataType[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetAccompanies`, date);
  }

  getDriversDetails(date: object): Observable<FeedDataType[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetDrivers`, date);
  }

  getReOptimizationData(date: string): Observable<FeedReOptimization> {
    return this.apiService.get(`${this.BASE_PATH}/ReOptimization`, { date });
  }

  getDuplicatedPassengersExcel(date: string, fileName: string): Observable<Blob> {
    return this.apiService.postBlob(`${this.BASE_PATH}/DuplicatedPassengers/Excel`, { date }, 'blob')
      .pipe(tap(res => this.fileSaverService.downloadBlobFile(res, fileName)));
  }
}
