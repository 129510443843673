import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthEffects } from '@app/auth/state/effects';
import { AuthFacade } from '@app/auth/state/facades';
import * as authReducers from '@app/auth/state/reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(authReducers.FEATURE_KEY, authReducers.reducers),
    EffectsModule.forFeature([ AuthEffects ])
  ],
  providers: [ AuthFacade ]
})
export class AuthStateModule {}
