import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { cloneDeep, isEqual } from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ActivitiesFilters } from '@app/activities/models';
import { activitiesFiltersComponentConfig } from './activities-filters.component.config';

@Component({
  selector: 'app-activities-filters',
  templateUrl: './activities-filters.component.html',
  styleUrls: [ './activities-filters.component.scss', './activities-filters.component.rtl.scss' ]
})
export class ActivitiesFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activitiesFilters: ActivitiesFilters;

  @Output() applyFilters: EventEmitter<ActivitiesFilters> = new EventEmitter();
  @Output() clearFilters: EventEmitter<void> = new EventEmitter();
  @Output() closeFilters: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'activities-filters';

  private unsubscribe = new Subject<void>();

  form: UntypedFormGroup;
  config = cloneDeep(activitiesFiltersComponentConfig);

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.form && changes.activitiesFilters) {
      this.form.patchValue(this.activitiesFilters);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initForm() {
    this.form = this.fb.group({
      statusTypes: [ this.activitiesFilters.statusTypes ],
      types: [ this.activitiesFilters.types ],
      saveTypes: [ this.activitiesFilters.saveTypes ]
    });
  }

  private onFormChanges() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        filter(data => !isEqual(data, this.activitiesFilters))
      )
      .subscribe(data => this.applyFilters.emit(data));
  }

  clearFiltersAction() {
    this.clearFilters.emit();
  }

  closeAction() {
    this.closeFilters.emit();
  }
}
