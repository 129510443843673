import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { UPopupService } from '@shift/ulib';
import { cloneDeep } from 'lodash';

import { ValidationService } from '@app/shared/services/validation.service';
import { TrackingService } from '@app/shared/services/tracking.service';
import { LocalizationService } from '@app/shared/services/localization.service';
import { LocalizedToastrService } from '@app/shared/services/localized-toast.service';
import { AuthDataSnapshotService } from '@app/auth/services';
import { PassengersService } from '@app/passengers/services/passengers.service';
import { ModalActions, Person, PersonParentContact, PersonParentContactRelativeType } from '@app/shared/models';
import {
  PassengerDetails,
  PassengerMunicipalityDisabilityCode,
  PassengerMunicipalityDisabilityCodesListData,
  PassengerMunicipalityParentRoleType,
  PassengerPickUpType,
  PassengerRoute,
  PassengersAddEditMode,
  PassengersInitialData,
  PassengersTabName
} from '@app/passengers/models';
import { PassengersAddEditDataService } from '@app/passengers/services/passengers-add-edit-data.service';
import { AuthModulePassengersFeatureType } from '@app/auth/models';
import { passengersConfig } from '@app/passengers/configs';

@Component({
  selector: 'app-passengers-municipality-add-edit',
  templateUrl: './passengers-municipality-add-edit.component.html',
  styleUrls: [ './passengers-municipality-add-edit.component.scss', './passengers-municipality-add-edit.component.rtl.scss' ],
  providers: [ AuthDataSnapshotService ]
})
export class PassengersMunicipalityAddEditComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter();

  private unsubscribe: Subject<any> = new Subject();

  addEditForm: UntypedFormGroup;
  editPassenger: { editData: PassengerDetails; initialData: PassengersInitialData; };
  buttons = cloneDeep(passengersConfig.addEditButtons).filter(button =>
    !button.permission || this.authDataSnapshotService.checkPermission(button.permission)
  );
  title: string;
  isRtl = this.localizationService.isRtl();
  disabilityCodesListData: PassengerMunicipalityDisabilityCodesListData;
  isPassengerSavingActive: boolean;
  mode: PassengersAddEditMode = PassengersAddEditMode.Default;
  excludedRouteId: number;
  passengerRoutes: PassengerRoute[] = [];
  passengersTabName = PassengersTabName;

  constructor(
    private toastr: LocalizedToastrService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private modalRef: BsModalRef,
    private passengersService: PassengersService,
    private validationService: ValidationService,
    private uPopupService: UPopupService,
    private trackingService: TrackingService,
    public localizationService: LocalizationService,
    private modalRemoveRef: BsModalRef,
    public passengersAddEditDataService: PassengersAddEditDataService,
    public authDataSnapshotService: AuthDataSnapshotService
  ) {}

  ngOnInit() {
    const { initialData } = this.editPassenger;

    if (initialData) {
      this.disabilityCodesListData = {
        disabilityCodes: this.getCustomerDisabilityCodes(initialData),
        shuttleApprovals: initialData.shuttleApprovals.map(({ id, name }) => ({ value: id, name })),
        accompanyApprovals: initialData.accompanyApprovals.map(({ id, name }) => ({ value: id, name }))
      };
    }

    if (this.editPassenger && !this.editPassenger.editData) {
      this.buttons = this.buttons.filter(button => button.action !== ModalActions.Delete);
    }

    this.translate.get('passengers.addEdit.passengerTitle').subscribe((title: string) => {
      this.title = title;
    });

    this.initAddEditForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.passengersAddEditDataService.resetData();
  }

  private initAddEditForm() {
    const shiftDays = [];

    for (let i = 0; i < 7; i++) {

      shiftDays.push({
        dayOfWeek: i,
        startTime: null,
        endTime: null
      });
    }

    this.addEditForm = this.fb.group({
      details: this.fb.group({
        id: [ 0 ],
        passengerId: [ 0 ],
        firstName: [ '', Validators.required ],
        lastName: [ '', Validators.required ],
        identity: [ '' ],
        gender: [ 0 ],
        address: [ '', Validators.required ],
        latitude: [ '', Validators.required ],
        longitude: [ '', Validators.required ],
        placeId: [ null ],
        pickUpType: [ PassengerPickUpType.FromHome, Validators.required ],
        birthDate: [ '' ],
        status: [ '', Validators.required ],
        comment: [ '' ],
        stationId: [ null ],
        stationAddress: this.fb.group({
          fullAddress: [],
          latitude: [],
          longitude: [],
          placeId: []
        }),
        needAccompany: [ false ],
        needElevator: [ false ],
        schoolId: [ null ],
        classNumber: [ null ],
        classTypeId: [ null ],
        departmentId: [ null ],
        distanceToSchool: [ 0 ],
        eligibleForShuttle: [ false ],
        eligibleForAccompany: [ false ],
        accompanyApproval: [ 0 ],
        shuttleApproval: [ 0 ],
        refundEligibility: [ null ],
        contacts: this.fb.array([]),
        parents: this.fb.array([]),
        schedule: [
          shiftDays.map(ob => (
            {
              dayOfWeek: ob.dayOfWeek,
              startTime: ob.startTime,
              endTime: ob.endTime
            }))
        ],
        disabilityCodes: this.fb.array([]),
        passengerRoutes: [ [] ]
      })
    });

    const details = this.addEditForm.get('details');

    details.get('identity').setValidators([
      Validators.minLength(9),
      Validators.maxLength(9),
      ValidationService.number,
      ValidationService.identity,
      this.checkIdentifierPersonContactsPassenger.bind(this, details.get('parents'))
    ]);

    details.get('birthDate').setValidators([
      this.validationService.checkBirthday.bind(this)
    ]);

    if (this.editPassenger.editData) {
      this.updatePassenger(this.editPassenger.editData);
      this.getSummaryRoutes(this.editPassenger.editData);
    } else {
      this.updateDisabilityCodes([]);
    }

    if (!this.authDataSnapshotService.managePassengers()) {
      this.addEditForm.disable();
    }
  }

  getSummaryRoutes({ passengerId }) {
    if (!passengerId) { return; }

    const param = {
      passengerId
    };

    this.passengersService.getRoutesInfoByIds(param)
      .pipe(
        take(1)
      )
      .subscribe(routes => {
        this.passengerRoutes = routes;
      });
  }

  checkIdentifierPersonContactsPassenger = (personContacts: UntypedFormControl, control: UntypedFormControl) => {
    const allRemove = !personContacts.value.filter((item: any) => item.relativeType === PassengerMunicipalityParentRoleType.Passenger).some(obContact => obContact.contacts.some(ob => ob.type === 14));

    return personContacts.value.length > 0 && !allRemove || !!control.value ? null : { required: true };
  };

  checkActions(action: ModalActions) {
    switch (action) {
      case ModalActions.Submit: {
        this.submit();

        break;
      }

      case ModalActions.Shutdown: {
        this.closeModal();

        break;
      }

      case ModalActions.Delete: {
        this.deletePassenger();

        break;
      }
    }
  }

  private track(name: string) {
    this.trackingService.track(`[${passengersConfig.trackingId}] - ${name}`);
  }

  updatePassenger(data: any, emitEvent: boolean = true) {
    if (data.contacts.length) {
      // this.updatePassengerContacts(data.contacts);

      const parentContactTemplate = {
        parentId: 0,
        relativeType: PassengerMunicipalityParentRoleType.Passenger,
        firstName: 'passenger',
        lastName: 'contact',
        contacts: data.contacts
      };

      data.parents.push(parentContactTemplate);
    }

    if (data.schedule) {
      this.updatePassengerScheduleDays(data.schedule);
    }

    if (data.parents) {
      this.updateParents(data.parents);
    }

    if (data.disabilityCodes) {
      this.updateDisabilityCodes(data.disabilityCodes);
    }

    this.addEditForm.get('details').patchValue({
      id: data.id,
      passengerId: data.passengerId,
      firstName: data.firstName,
      lastName: data.lastName,
      identity: data.identity,
      gender: data.gender,
      address: data.address,
      latitude: data.latitude,
      longitude: data.longitude,
      placeId: data.placeId,
      pickUpType: data.pickUpType,
      birthDate: data.birthDate,
      status: data.status,
      comment: data.comment,
      stationId: data.stationId,
      stationAddress: {
        latitude: data.stationAddress && data.stationAddress.latitude,
        longitude: data.stationAddress && data.stationAddress.longitude,
        placeId: data.stationAddress && data.stationAddress.placeId,
        fullAddress: data.stationAddress && data.stationAddress.fullAddress
      },
      schoolId: data.schoolId,
      classNumber: data.classNumber,
      classTypeId: data.classTypeId,
      departmentId: data.departmentId,
      distanceToSchool: data.distanceToSchool,
      needAccompany: data.needAccompany,
      needElevator: data.needElevator,
      eligibleForShuttle: data.eligibleForShuttle ? data.eligibleForShuttle : 0,
      eligibleForAccompany: data.eligibleForAccompany ? data.eligibleForAccompany : 0,
      shuttleApproval: data.shuttleApproval,
      accompanyApproval: data.accompanyApproval,
      refundEligibility: data.refundEligibility
    }, { emitEvent });
  }

  getCustomerDisabilityCodes(initialData: PassengersInitialData) {
    return initialData.disabilityCodes.map((ob: PassengerMunicipalityDisabilityCode) => ({
      value: ob.id,
      ...ob
    }));
  }

  getDisabilityCodeById(id: number, disabilityCodes: any[]): any {
    return disabilityCodes.find(({ value }) => value === id);
  }

  updateDisabilityCodes(disabilityCodes: number[]): void {
    if (!disabilityCodes.length) {
      disabilityCodes.push(0);
    }

    const disabilityCodesForm = this.addEditForm.get('details.disabilityCodes') as UntypedFormArray;

    disabilityCodesForm.controls.forEach((ob: any, i: number) => disabilityCodesForm.removeAt(i));
    disabilityCodesForm.controls = [];

    disabilityCodes.forEach((code: any, i: number) => {
      const disabilityCode =  this.getDisabilityCodeById(code, this.disabilityCodesListData.disabilityCodes);
      const disabilityCodeForm = disabilityCode ? this.generateDisabilityCode({ id: i, code: disabilityCode.value, ...disabilityCode }) : this.generateDisabilityCode({
        id: disabilityCodesForm.length,
        code: 0,
        eligibleForShuttle: false,
        eligibleForAccompany: false
      });
      const codeForm = disabilityCodeForm.get('code');
      const eligibleForShuttleForm = disabilityCodeForm.get('eligibleForShuttle');
      const eligibleForAccompanyForm = disabilityCodeForm.get('eligibleForAccompany');

      codeForm.valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((value: any) => {
          if (!value) { return; }

          const activeCode = this.getDisabilityCodeById(value, this.disabilityCodesListData.disabilityCodes);

          eligibleForShuttleForm.patchValue(activeCode.eligibleForShuttle, { emitEvent: false });
          eligibleForAccompanyForm.patchValue(activeCode.eligibleForAccompany,  { emitEvent: false });

          this.track('Choose disability code');
        });

      disabilityCodesForm.push(disabilityCodeForm);
    });
  }

  generateDisabilityCode(ob: any): UntypedFormGroup {
    return this.fb.group({
      id: [ ob.id ],
      code: [ ob.code ],
      eligibleForShuttle: [ ob.eligibleForShuttle ],
      eligibleForAccompany: [ ob.eligibleForAccompany ]
    });
  }

  addDisabilityCode(): void {
    const disabilityCodesForm = this.addEditForm.get('details.disabilityCodes') as UntypedFormArray;
    const disabilityCodeForm = this.generateDisabilityCode({
      id: disabilityCodesForm.length,
      code: 0,
      eligibleForShuttle: false,
      eligibleForAccompany: false
    });

    const codeForm = disabilityCodeForm.get('code');
    const eligibleForShuttleForm = disabilityCodeForm.get('eligibleForShuttle');
    const eligibleForAccompanyForm = disabilityCodeForm.get('eligibleForAccompany');

    codeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: any) => {
        if (!value) { return; }

        const activeCode = this.getDisabilityCodeById(value, this.disabilityCodesListData.disabilityCodes);

        eligibleForShuttleForm.patchValue(activeCode.eligibleForShuttle, { emitEvent: false });
        eligibleForAccompanyForm.patchValue(activeCode.eligibleForAccompany, { emitEvent: false });

        this.track('Choose disability code');
      });

    disabilityCodesForm.push(disabilityCodeForm);

    this.addEditForm.get('details').markAsDirty();
  }

  removeDisabilityCode({ index }): void {
    const disabilityCodesForm = this.addEditForm.get('details.disabilityCodes') as UntypedFormArray;

    disabilityCodesForm.removeAt(index);
    this.addEditForm.get('details').markAsDirty();
  }

  updateParents(parents: any) {
    const parentsForm = this.addEditForm.get('details.parents') as UntypedFormArray;

    parentsForm.controls.forEach((ob: any, i: number) => parentsForm.removeAt(i));
    parentsForm.controls = [];

    parents.forEach(parent => {
      const parentForm: UntypedFormGroup = this.fb.group({
        parentId: [ parent.parentId, Validators.required ],
        firstName: [ parent.firstName, Validators.required ],
        lastName: [ parent.lastName, Validators.required ],
        relativeType: [ parent.relativeType, Validators.required ],
        contacts: this.fb.array([])
      });

      this.updateParentContacts(parent.contacts, parentsForm, parentForm);

      parentsForm.push(parentForm);
      parentsForm.updateValueAndValidity();
    });
  }

  updateParentContactsEvent({ parentContacts, parentsForm, parentForm }) {
    this.updateParentContacts(parentContacts, parentsForm, parentForm);
  }

  updateParentContacts(parentContacts, parentsForm, parentForm) {
    const parentContactsForm = parentForm.get('contacts') as UntypedFormArray;

    parentContactsForm.controls.forEach((ob: any, i: number) => parentContactsForm.removeAt(i));
    parentContactsForm.controls = [];

    parentContacts.forEach(parentContact => {
      const parentContactForm: UntypedFormGroup = this.fb.group({
        type: [ parentContact.type, [ Validators.required ] ],
        id: [ parentContact.id ],
        value: [ parentContact.value ],
        isConfirmed: [ parentContact.isConfirmed ],
        oldValue: [ parentContact.oldValue ]
      });

      const type = parentContactForm.get('type');
      const data = parentContactForm.get('value');

      this.validationService.updateValidationContactData(data, type.value);

      type.valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((value: any) => {
          if (!value) { return; }

          this.validationService.updateValidationContactData(data, value);
        });

      parentContactsForm.push(parentContactForm);
    });
  }

  updatePassengerScheduleDays(scheduleDays: any) {
    const days = scheduleDays.map(({ dispersionTime, collectionTime }, i) => ({
      dayOfWeek: i,
      startTime: dispersionTime,
      endTime: collectionTime
    }));
    const scheduleForm = this.addEditForm.get('details.schedule');

    scheduleForm.patchValue(days);
  }

  getRemoveRoutes(routes: any) {
    this.addEditForm.get('details.passengerRoutes').patchValue(routes);
    this.addEditForm.get('details.passengerRoutes').markAsDirty();
  }

  submit() {
    if (this.isPassengerSavingActive) {
      return;
    }

    this.track(!this.editPassenger.editData ? 'Saved new' : 'Edited passenger saved');

    if (this.addEditForm.valid && !this.addEditForm.dirty) {
      this.modalRef.hide();

      return;
    }

    this.savePassenger();
  }

  private getSavePassengerBody() {
    const schedule = this.addEditForm.get('details.schedule').value;
    const disabilityCodes = this.addEditForm.get('details.disabilityCodes').value ? this.addEditForm.get('details.disabilityCodes').value.map((ob: any) => ob.code) : [];
    const stationAddress = this.addEditForm.get('details.stationAddress').value;
    const addEditFormValue = this.addEditForm.getRawValue();

    return {
      ...addEditFormValue,
      details: {
        ...addEditFormValue.details,
        disabilityCodes,
        schedule:
          schedule.map((day, index) => {
            if (index <= 5) {
              return {
                day: 41 + index,
                dispersionTime: day.startTime,
                collectionTime: day.endTime
              };
            } else {
              return {
                day: 218,
                dispersionTime: day.startTime,
                collectionTime: day.endTime
              };
            }
          }),
        stationAddress: stationAddress && Object.values(stationAddress).every(value => !!value) ? stationAddress : null,
        contacts: addEditFormValue.details.parents.filter(parent => parent.relativeType === PassengerMunicipalityParentRoleType.Passenger).reduce((acc, ob) => acc.concat(ob.contacts), []),
        parents: addEditFormValue.details.parents.filter(parent => parent.relativeType !== PassengerMunicipalityParentRoleType.Passenger)
      }
    };
  }

  private savePassenger() {
    if (!this.addEditForm.valid) {
      this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.error.passengerNotValid });

      return;
    }

    this.isPassengerSavingActive = true;

    this.passengersAddEditDataService.savePassengerAndCheckRoutes(this.getSavePassengerBody(), AuthModulePassengersFeatureType.Student)
      .pipe(
        tap(data => {
          if (!data) {
            this.isPassengerSavingActive = false;
          }
        }),
        filter(data => !!data),
        finalize(() => this.isPassengerSavingActive = false),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([ passenger, wasEditedByRoutesPopup ]) => {
        this.modalRemoveRef.hide();
        this.toastr.success('general.successful');

        this.track(this.addEditForm.value.id > 0 ? 'edit' : 'add');

        if (wasEditedByRoutesPopup && this.mode === PassengersAddEditMode.AssignToRoute) {
          const passengerRoutes = passenger.passengerRoutes;
          const removeFromCurrentRoute = this.excludedRouteId && passengerRoutes && passengerRoutes.length &&
            passengerRoutes.some(route => !route.keep && route.routeId === this.excludedRouteId);

          this.action.emit({
            type: removeFromCurrentRoute ? ModalActions.Delete : ModalActions.Submit,
            ...(removeFromCurrentRoute ? {} : { value: passenger })
          });

          return;
        }

        this.action.emit({ type: ModalActions.Submit, value: passenger });
      });
  }

  deletePassenger() {
    const passengerId = this.addEditForm.get('details.passengerId').value;

    this.track(ModalActions.Delete);

    if (passengerId <= 0) {
      this.modalRef.hide();
      return;
    }

    this.uPopupService.showMessage(
      {
        message: 'passengers.addEdit.deleteConfirm',
        yes: 'general.yes',
        no: 'general.no'
      },
      () => {
        if (passengerId > 0) {
          this.passengersService.deletePassengers([ passengerId ]).subscribe(() => {
            this.track(ModalActions.Delete);
            this.modalRef.hide();
            this.action.emit({ type: ModalActions.Delete });
          });
        }
      }
    );
  }

  closeModal() {
    if (!this.addEditForm.dirty) {
      this.action.emit({ type: ModalActions.Close });

      return;
    }

    if (this.addEditForm.touched) {
      if (this.uPopupService.popupRef) { return; }

      this.uPopupService.showMessage(
        {
          showXIcon: true,
          message: 'general.closeConfirm',
          yes: 'general.yes',
          no: 'general.no'
        },
        () => {
          if (this.addEditForm.valid) {
            this.submit();
          } else {
            this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.error.passengerNotValid });
          }
        },
        () => {
          if (!this.editPassenger.editData) {
            this.modalRef.hide();
            this.action.emit({ type: ModalActions.Close });
          } else {
            this.modalRef.hide();
            this.action.emit({ type: ModalActions.Close, value: this.editPassenger && this.editPassenger.editData && this.editPassenger.editData.id });
          }
        }
      );
    } else {
      this.modalRef.hide();
      this.action.emit({ type: ModalActions.Close });
    }
  }

  updatePersonExist(person: Person) {
    if (person.contacts) {
      const parents: PersonParentContact[] = [];

      const parentContactTemplate: PersonParentContact = {
        parentId: 0,
        relativeType: PersonParentContactRelativeType.Passenger,
        firstName: 'passenger',
        lastName: 'contact',
        contacts: person.contacts
      };

      parents.push(parentContactTemplate);

      person.parents = parents;
    }

    if (person.parents) {
      this.updateParents(person.parents);
    }

    this.addEditForm.get('details').patchValue({
      passengerId: 0,
      id: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      identity: person.identity,
      address: person.address,
      birthDate: person.birthDate,
      comment: person.comment,
      latitude: person.latitude,
      longitude: person.longitude
    });
  }

  updatePassengerExist(id: any) {
    this.passengersService.getPassengerDetails(id)
      .subscribe(passenger => {
        this.updatePassenger(passenger);
      });
  }

  changeClassHours(scheduleDays: any) {
    if (!scheduleDays) { return; }
    this.updatePassengerScheduleDays(scheduleDays);
  }

  onTabSelect(tabName: PassengersTabName, addEditForm: UntypedFormGroup) {
    this.passengersAddEditDataService.onTabSelect(tabName, addEditForm)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }
}
