import { createAction, props } from '@ngrx/store';

import { RouteNotesState } from '@app/route-notes/models';
import {
  RouteDaily,
  RoutesDailyParams,
  RouteDailyActivitiesUpdateItem,
  RoutesUpdateApprovals,
  RoutesUpdateRideStatus,
  RoutesWeeklyFilters,
  RoutesDailyFilters,
  RideMonitoringData,
  RouteDailyUpdateRouteNoteAdded,
  RouteDailyUpdateRouteNoteUpdated,
  RouteDailyUpdateRouteNotesRead,
  RoutesViewType,
  RouteNoteMarkedAsDone
} from '@app/routes/models';
import { RideApproval } from '@app/ride-approvals/models';
import { ActivityRoute } from '@app/activities/models';

const ACTION_PREFIX: string = '[Routes]';

export const dailyInit = createAction(`${ACTION_PREFIX} Daily init`);
export const dailyInited = createAction(`${ACTION_PREFIX} Daily inited`);
export const dailyItemsInit = createAction(`${ACTION_PREFIX} Daily items init`, props<{ params: RoutesDailyParams }>());
export const dailyLoad = createAction(`${ACTION_PREFIX} Daily load`, props<{ params: RoutesDailyParams }>());
export const dailyLoadCancel = createAction(`${ACTION_PREFIX} Daily load cancel`);
export const dailyLoadSuccess = createAction(`${ACTION_PREFIX} Daily load success`, props<{ items: RouteDaily[]; }>());
export const dailyLoadNotes = createAction(`${ACTION_PREFIX} Daily load notes`, props<{ items: RouteNotesState[]; }>());
export const dailyLoadActivities = createAction(`${ACTION_PREFIX} Daily load activities`, props<{ items: ActivityRoute[]; }>());
export const dailyLoadDriverApprovals = createAction(`${ACTION_PREFIX} Daily load driver approvals`, props<{ items: RideApproval[]; }>());
export const dailyLoadMonitoringData = createAction(`${ACTION_PREFIX} Daily load monitoring data`, props<{ items: RideMonitoringData[]; }>());
export const dailyClearItemsSaveStatus = createAction(`${ACTION_PREFIX} Daily clear items save status`, props<{ routeIds: number[]; }>());
export const dailyUpdateItems = createAction(`${ACTION_PREFIX} Daily update items`, props<{ items: RouteDaily[]; }>());
export const dailyDeleteItems = createAction(`${ACTION_PREFIX} Daily delete items`, props<{ routeIds: number[]; }>());
export const dailyActivitiesUpdateItems = createAction(`${ACTION_PREFIX} Daily activities update items`, props<{ items: RouteDailyActivitiesUpdateItem[]; }>());
export const dailyHubInited = createAction(`${ACTION_PREFIX} Daily hub inited`);
export const dailyUpdateRideStatus = createAction(`${ACTION_PREFIX} Daily update ride status`, props<{ data: RoutesUpdateRideStatus; }>());
export const dailyUpdateRouteNoteAdded = createAction(`${ACTION_PREFIX} Daily update route note added`, props<{ data: RouteDailyUpdateRouteNoteAdded; }>());
export const dailyUpdateRouteNotesRead = createAction(`${ACTION_PREFIX} Daily update route notes read`, props<{ data: RouteDailyUpdateRouteNotesRead; }>());
export const dailyUpdateRouteNoteUpdated = createAction(`${ACTION_PREFIX} Daily update route note updated`, props<{ data: RouteDailyUpdateRouteNoteUpdated; }>());
export const dailyUpdateRouteNoteRemoved = createAction(`${ACTION_PREFIX} Daily update route note removed`, props<{ data: RouteNoteMarkedAsDone; }>());
export const dailyUpdateDriverApprovals = createAction(`${ACTION_PREFIX} Daily update driver approvals`, props<{ data: RoutesUpdateApprovals; }>());
export const dailySelectedFiltersUpdate = createAction(`${ACTION_PREFIX} Daily filters update`, props<{ filters: RoutesDailyFilters; }>());
export const dailyItemsReset = createAction(`${ACTION_PREFIX} Daily items reset`);
export const dailyReset = createAction(`${ACTION_PREFIX} Daily reset`);
export const weeklySelectedFiltersInit = createAction(`${ACTION_PREFIX} Weekly filters init`, props<{ filters: RoutesWeeklyFilters; }>());
export const weeklySelectedFiltersUpdate = createAction(`${ACTION_PREFIX} Weekly filters update`, props<{ filters: RoutesWeeklyFilters; }>());
export const weeklyReset = createAction(`${ACTION_PREFIX} Weekly reset`);
export const viewTypeUpdate = createAction(`${ACTION_PREFIX} View type update`, props<{ value: RoutesViewType; }>());
