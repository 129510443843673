import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { ShuttleCompanyCustomer, ShuttleCompanyCustomerSlim } from '@app/shuttle-companies/models';
import { ExportToExcelObject } from '@app/shared/models';
import { FileSaverService } from '@app/files/services';

@Injectable({
  providedIn: 'root'
})
export class  ShuttleCompanyCustomersService {
  private BASE_URL: string = 'ShuttleCompanyCustomers';

  constructor(
    private apiService: ApiService,
    private fileSaverService: FileSaverService
  ) {}

  getAllCustomers(search: string = ''): Observable<ShuttleCompanyCustomerSlim[]> {
    return this.apiService.get(`${this.BASE_URL}/List`, { search });
  }

  getCustomer(customerId: number): Observable<ShuttleCompanyCustomer> {
    return this.apiService.get(this.BASE_URL, { customerId });
  }

  saveCustomer(customer: ShuttleCompanyCustomer): Observable<ShuttleCompanyCustomer> {
    return this.apiService.post(this.BASE_URL, customer);
  }

  updateCustomer(customer: ShuttleCompanyCustomer): Observable<ShuttleCompanyCustomer> {
    return this.apiService.put(this.BASE_URL, customer);
  }

  deleteCustomers(customerIds: number[]): Observable<void> {
    return this.apiService.delete(this.BASE_URL, {}, { customerIds });
  }

  findCustomerById(businessIdentity: string): Observable<ShuttleCompanyCustomer> {
    return this.apiService.get(`${this.BASE_URL}/FindByIdentity`, { businessIdentity });
  }

  exportToExcel(exportObject: ExportToExcelObject): void {
    this.apiService.postBlob(`${this.BASE_URL}/Export/Excel`, exportObject.data, 'blob').subscribe(excelFile =>
      this.fileSaverService.downloadBlobFile(excelFile, exportObject.fileName));
  }
}
