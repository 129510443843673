import { Pipe, PipeTransform } from '@angular/core';

import { Activity, ActivityRouteFromTemplateCreationResult } from '@app/activities/models';

@Pipe({
  name: 'activityShuttleCompanyHasMoreThanSingleContract'
})
export class ActivityShuttleCompanyHasMoreThanSingleContractPipe implements PipeTransform {
  transform(activity: Activity): boolean {
    return activity?.additionalData?.processedTemplates
      .some(processedTemplate =>
        processedTemplate.creationResults
          .some(creationResult =>
            creationResult.result === ActivityRouteFromTemplateCreationResult.ShuttleCompanyHasMoreThanSingleContractForSelectedPeriod
          )
      );
  }
}
