@for(driver of drivers(); track driver) {
  <div class="routes-ride-drivers__item">
    <div class="routes-ride-drivers__item-header">
      <span
        class="routes-ride-drivers__item-header-name"
        uTooltip="{{driver.typeName}}"
        [placement]="[ 'top', 'bottom' ]"
        [container]="'body'"
      >
        {{driver.typeName}}
      </span>

      <div class="routes-ride-drivers__item-header-approval">
        <span
          class="routes-ride-drivers__item-header-approval-status"
          [class.routes-ride-drivers__item-header-approval-status_rejected]="driver.approveStatus === rideDriverApprovalApproveStatus.Rejected"
        >
          <span class="routes-ride-drivers__item-header-approval-status-icon routes-ride-drivers__item-header-approval-status-icon_rejected"></span>
        </span>
        <span
          class="routes-ride-drivers__item-header-approval-status"
          [class.routes-ride-drivers__item-header-approval-status_approved]="driver.approveStatus === rideDriverApprovalApproveStatus.Approved"
        >
          <span class="routes-ride-drivers__item-header-approval-status-icon routes-ride-drivers__item-header-approval-status-icon_approved"></span>
        </span>
      </div>
    </div>

    <div class="routes-ride-drivers__item-value">
      {{driver.newValue}}
    </div>
  </div>
}
