import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';

import { AppConstants } from '@app/shared/constants';
import { RoutesDailyMovePassengersDataService } from '@app/routes/services';
import { RoutesDailyMovePassenger, RoutesDailyMovePassengersRoute } from '@app/routes/models';
import { routesDailyMovePassengersConfig } from '@app/routes/configs';
import { routesDailyMovePassengersComponentConfig } from './routes-daily-move-passengers.component.config';

@Component({
  selector: 'app-routes-daily-move-passengers',
  templateUrl: './routes-daily-move-passengers.component.html',
  styleUrls: [ './routes-daily-move-passengers.component.scss', './routes-daily-move-passengers.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesDailyMovePassengersComponent implements OnInit, OnDestroy {
  @Output() closeAction: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'routes-daily-move-passengers';

  @HostListener('window:mousemove', [ '$event' ])
  onMouseMove(event: MouseEvent) {
    if (this.routesDailyMovePassengersDataService.getDragActiveValue()) {
      this.routesDailyMovePassengersDataService.updateMousePosition(event);
    }
  }

  appConstants = AppConstants;
  routesDailyMovePassengersConfig = routesDailyMovePassengersConfig;
  config = cloneDeep(routesDailyMovePassengersComponentConfig);

  constructor(
    public routesDailyMovePassengersDataService: RoutesDailyMovePassengersDataService
  ) {}

  ngOnInit() {
    this.routesDailyMovePassengersDataService.initialize();
  }

  ngOnDestroy() {
    this.routesDailyMovePassengersDataService.resetData();
  }

  trackByRouteId(index: number, route: RoutesDailyMovePassengersRoute) {
    return route.routeId;
  }

  trackByPassengerId(index: number, passenger: RoutesDailyMovePassenger) {
    return passenger.passengerId;
  }
}
