<div class="reminder__header">
  <div class="reminder__header-title">
    <span class="reminder__header-title-text">{{config.dictionary.reminder | translate}}</span>
  </div>
  <span class="reminder__header-close" (click)="deleteReminderOnly()"></span>
</div>
<div class="reminder__body">
  <p class="reminder__body-text">{{reminder.nvDescription}}</p>
</div>
<div class="reminder__footer">
  <span class="reminder__footer-date">{{reminder.dtDateTime | date: config.dateFormat}}</span>
  <div class="reminder__footer-actions">
    <span
      class="reminder__footer-actions-done"
      (click)="deleteNoteAndReminder()"
    >
      {{config.dictionary.done | translate}}
    </span>
    <span
      class="reminder__footer-actions-close"
      (click)="deleteReminderOnly()"
    >
      {{config.dictionary.close | translate}}
    </span>
  </div>
</div>
