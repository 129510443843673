import { Component, HostBinding, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UHereMapLatLng, UHereMapMarkerCluster } from '@shift/ulib';

import { BuilderFullMapService } from '@app/builder/services';
import { builderFullConfig } from '@app/builder/configs';

@Component({
  selector: 'app-builder-full-map',
  templateUrl: './builder-full-map.component.html',
  styleUrls: [ './builder-full-map.component.scss', './builder-full-map.component.rtl.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class BuilderFullMapComponent implements OnDestroy {
  @HostBinding('class') hostClasses: string = 'builder-full-map';

  coords: UHereMapLatLng = builderFullConfig.map.coords;
  zoom: number = builderFullConfig.map.zoom;
  maxZoom: number = builderFullConfig.map.maxZoom;

  constructor(
    private builderFullMapService: BuilderFullMapService
  ) {}

  ngOnDestroy(): void {
    this.builderFullMapService.resetMarkers();
  }

  get markers(): UHereMapMarkerCluster[] {
    return this.builderFullMapService.markers;
  }
}
