<div
  class="routes-move-passengers__close"
  [class.routes-move-passengers__close_disabled]="routesMovePassengersDataService.isLoading()"
  (click)="closeModal()"
>
  <div class="u-icon u-icon_pointer u-icon_app-close-move-passengers"></div>
</div>
<div
  *ngIf="routesMovePassengersDataService.showBackdrop"
  class="routes-move-passengers__backdrop"
  (click)="routesMovePassengersDataService.updateShowBackdrop(false)"
></div>
<div class="routes-move-passengers__content">
  <div class="routes-move-passengers__routes-panel">
    <div class="routes-move-passengers__period">
      <div class="routes-move-passengers__filters">
        <ng-container *ngIf="featureType === authModulePassengersFeatureType.Student; else genericFilters">
          <u-select-s
            [empty]="false"
            [items]="config.filterTypes"
            [formControl]="form.get('typeFilter')"
            [container]="'body'"
            [placement]="[ 'bottom' ]"
            [messages]="config.dictionary.messages.select | translate"
            (click)="form.get('typeFilter').enabled && routesMovePassengersDataService.updateShowBackdrop(true)"
          ></u-select-s>
          <ng-container *ngIf="form.get('typeFilter').value === routesMovePassengersFilterType.ActivityCenters">
            <ng-container *ngTemplateOutlet="genericFilter; context: {
              items: customerData && customerData[customerDataType.Branches],
              placeholder: config.dictionary.filters.placeholders.activityCenters,
              control: form.get('branchIds')
            }"></ng-container>
          </ng-container>
          <ng-container *ngIf="form.get('typeFilter').value === routesMovePassengersFilterType.EducationalInstitution">
            <ng-container *ngTemplateOutlet="genericFilter; context: {
              items: customerData && customerData[customerDataType.Schools],
              placeholder: config.dictionary.filters.placeholders.institution,
              control: form.get('schoolIds')
            }"></ng-container>
          </ng-container>
        </ng-container>
        <ng-template #genericFilters>
          <ng-container *ngTemplateOutlet="genericFilter; context: {
            items: customerData && customerData[customerDataType.Branches],
            placeholder: config.dictionary.filters.placeholders.branches,
            control: form.get('branchIds')
          }"></ng-container>
        </ng-template>
      </div>
      <div class="routes-move-passengers__types-changes">
        <app-routes-period
          [typeFormControl]="form.controls['typeChange']"
          [changes]="form.controls['datesChange'].value"
          [placeholder]="config.dictionary.changeType"
          [placeholderURange]="config.dictionary.period"
          [needPresetAutoUpdate]="false"
          [inputValidURange]="form.get('datesChange.dates').valid || form.get('datesChange.dates').disabled"
          [disabledURange]="form.get('datesChange.dates').disabled"
          [container]="'body'"
          (updatePeriod)="routesMovePassengersDataService.updatePeriod($event)"
          (click)="form.get('datesChange.dates').enabled && routesMovePassengersDataService.updateShowBackdrop(true)"
        ></app-routes-period>
      </div>
      <button
        uButton
        class="routes-move-passengers__reset-btn"
        [disabled]="
          !form.get('typeChange').value ||
          !form.get('datesChange.dates').value?.length ||
          form.invalid ||
          routesMovePassengersDataService.isLoading()
        "
        (click)="showResetChangesConfirmationPopup()"
      >
        {{config.dictionary.resetChanges | translate | lowercase}}
      </button>
    </div>
    <div class="routes-move-passengers__main">
      <div
        *ngIf="
          !form.get('typeChange').value || !form.get('datesChange.dates').value?.length || form.invalid
        "
        class="routes-move-passengers__date-is-not-selected"
      >
        {{config.dictionary.dateIsNotSelected | translate}}
      </div>
      <div
        class="routes-move-passengers__block routes-move-passengers__block_stations"
        [ngClass]="{ 'routes-move-passengers__block_with-border': stations.length }"
      >
        <!-- Stations search -->
        <div class="routes-move-passengers__search">
          <div class="routes-move-passengers__search-header">
            <div class="routes-move-passengers__search-name">
              <span class="routes-move-passengers__search-info">{{sourceRoute.code}}</span>
              <span
                class="routes-move-passengers__search-info routes-move-passengers__search-info_name"
                uTooltip="{{sourceRoute.name}}"
                [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                [container]="'body'"
              >
                {{sourceRoute.name}}
              </span>
            </div>
            <div class="routes-move-passengers__search-result">
              ({{stations | movePassengersSearch: searchPassengers : ['name', 'address', 'passengers'] | routesMovePassengersCount}} {{config.dictionary.passengers | translate}})
            </div>
          </div>
          <input
            class="routes-move-passengers__search-field"
            uInput
            [placeholder]="config.dictionary.searchPassengers | translate"
            type="text"
            [(ngModel)]="searchPassengers"
          >
        </div>

        <!-- Stations list -->
        <div
          *ngIf="form.get('typeChange').value && form.get('datesChange.dates').value?.length && form.valid"
          class="routes-move-passengers__stations"
          cdkDropList
          [id]="'stations'"
          [cdkDropListConnectedTo]="routes | routesMovePassengersRouteIds"
        >
          <div
            *ngFor="let station of stations | movePassengersSearch: searchPassengers : [ 'name', 'address', 'passengers' ]; let i = index"
            class="routes-move-passengers__station"
            cdkDrag
            [cdkDragData]="{ station: station }"
            (cdkDragStarted)="
              routesMovePassengersDataService.singleDaySelected ?
              multipleStationsDragStarted($event, station, i) : dragStarted(station.name, 'station')
            "
            (cdkDragEnded)="dragEnded(routesMovePassengersDataService.singleDaySelected)"
            (cdkDragDropped)="
              routesMovePassengersDataService.singleDaySelected ? multipleEntitiesDropped($event) : droppedStation($event)
            "
          >
            <div
              class="routes-move-passengers__station-wrapper"
              [ngClass]="{ 'routes-move-passengers__station-wrapper_is-dragging': station.isDragging && station.checked }"
            >
              <div class="routes-move-passengers__station-main">
                <div class="routes-move-passengers__station-info">
                  <u-checkbox
                    *ngIf="routesMovePassengersDataService.singleDaySelected"
                    class="u-checkbox_box routes-move-passengers__station-checkbox"
                    [(ngModel)]="routesMovePassengersDataService.stations[i].checked"
                    (ngModelChange)="routesMovePassengersDataService.toggleStationCheckbox(i)"
                  ></u-checkbox>
                  <div
                    class="routes-move-passengers__station-name routes-move-passengers__station-name_bold"
                    uTooltip="{{i + 1}} {{config.dictionary.station | translate}}"
                    [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                    [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                    [container]="'body'"
                  >
                    {{i + 1}} {{config.dictionary.station | translate}}
                  </div>
                  <div
                    class="routes-move-passengers__station-content"
                    uTooltip="{{station.name}}"
                    [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                    [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                    [container]="'body'"
                  >
                    {{station.name}}
                  </div>
                </div>
                <div class="routes-move-passengers__station-info">
                  <div
                    class="routes-move-passengers__station-name"
                    [ngClass]="{ 'routes-move-passengers__station-name_has-checkbox': routesMovePassengersDataService.singleDaySelected }"
                  >
                    {{station.time | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT}}
                  </div>
                  <div
                    class="routes-move-passengers__station-content"
                    uTooltip="{{station.address}}"
                    [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                    [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                    [container]="'body'"
                  >
                    {{station.address}}
                  </div>
                </div>
              </div>
              <div
                class="routes-move-passengers__station-passengers"
                cdkDropList
                [id]="'passengers'"
                [cdkDropListConnectedTo]="routes | routesMovePassengersRouteIds"
              >
                <div
                  *ngFor="let passenger of station.passengers; let passengerIndex = index;"
                  class="routes-move-passengers__station-passenger"
                  cdkDrag
                  [ngClass]="{ 'routes-move-passengers__station-passenger_has-checkbox': routesMovePassengersDataService.singleDaySelected }"
                  [cdkDragData]="{ passenger: passenger, station: station }"
                  (cdkDragStarted)="
                    routesMovePassengersDataService.singleDaySelected ?
                    multiplePassengersDragStarted($event, passenger, i, passengerIndex) : dragStarted(passenger.name, 'passenger')
                  "
                  (cdkDragEnded)="dragEnded(routesMovePassengersDataService.singleDaySelected)"
                  (cdkDragDropped)="
                    routesMovePassengersDataService.singleDaySelected ? multipleEntitiesDropped($event) : droppedPassenger($event)
                  "
                >
                  <ng-container *ngIf="!passenger.isDragging || !passenger.checked">
                    <u-checkbox
                      *ngIf="routesMovePassengersDataService.singleDaySelected"
                      class="u-checkbox_box routes-move-passengers__station-passenger-checkbox"
                      [(ngModel)]="routesMovePassengersDataService.stations[i].passengers[passengerIndex].checked"
                      (ngModelChange)="routesMovePassengersDataService.togglePassengerCheckbox(i, passengerIndex)"
                    ></u-checkbox>
                    <span
                      class="routes-move-passengers__station-passenger-name"
                      [uTooltip]="passenger.name"
                      [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                      [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                      [container]="'body'"
                    >
                      {{passenger.name}}
                    </span>
                    <ng-container *ngIf="routesMovePassengersDataService.singleDaySelected">
                      <div
                        *cdkDragPlaceholder
                        class="routes-move-passengers__dnd-placeholder"
                      ></div>
                    </ng-container>
                    <div
                      *cdkDragPreview
                      class="routes-move-passengers__dnd-placeholder"
                    >
                      <ng-container
                        *ngIf="routesMovePassengersDataService.singleDaySelected"
                      >
                        <ng-container *ngIf="isPassengerDragging">
                          <div
                            *ngIf="!stations[i].checked"
                            class="routes-move-passengers__dnd-preview"
                            [translate]="config.dictionary.moveAmountPassengers"
                            [translateParams]="{ amount: routesMovePassengersDataService.checkedItemsNumber.passengers || 1 }"
                          ></div>
                          <div
                            *ngIf="stations[i].checked && routesMovePassengersDataService.checkedItemsNumber.stations > 1"
                            class="routes-move-passengers__dnd-preview"
                            [translate]="config.dictionary.moveAmountStations"
                            [translateParams]="{ amount: routesMovePassengersDataService.checkedItemsNumber.stations || 1 }"
                          ></div>
                          <div
                            *ngIf="stations[i].checked && routesMovePassengersDataService.checkedItemsNumber.stations <= 1"
                            class="routes-move-passengers__dnd-preview"
                          >
                            {{station.name}}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!isPassengerDragging">
                          <div
                            *ngIf="routesMovePassengersDataService.checkedItemsNumber.stations > 1"
                            class="routes-move-passengers__dnd-preview"
                            [translate]="config.dictionary.moveAmountStations"
                            [translateParams]="{ amount: routesMovePassengersDataService.checkedItemsNumber.stations || 1 }"
                          ></div>
                          <div
                            *ngIf="routesMovePassengersDataService.checkedItemsNumber.stations <= 1"
                            class="routes-move-passengers__dnd-preview"
                          >
                            {{dragContent}}
                          </div>
                        </ng-container>
                      </ng-container>
                      <div
                        *ngIf="!routesMovePassengersDataService.singleDaySelected"
                        class="routes-move-passengers__dnd-preview"
                      >
                        {{dragContent}}
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div
                  *ngIf="station.anonymousPassengersCount"
                  class="routes-move-passengers__station-passenger"
                  [ngClass]="{ 'routes-move-passengers__station-passenger_has-checkbox': routesMovePassengersDataService.singleDaySelected }"
                  uTooltip="{{config.dictionary.anonymousPassengers | translate}} {{station.anonymousPassengersCount}}"
                  [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                  [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                  [container]="'body'"
                >
                  <u-checkbox
                    *ngIf="routesMovePassengersDataService.singleDaySelected"
                    class="u-checkbox_box routes-move-passengers__station-passenger-checkbox"
                    [(ngModel)]="routesMovePassengersDataService.anonymousPassengers[station.rideStationId].checked"
                    (ngModelChange)="routesMovePassengersDataService.toggleAnonymousPassengerCheckbox(i)"
                  ></u-checkbox>
                  <span class="routes-move-passengers__station-passenger-anonymous">
                    {{config.dictionary.anonymousPassengers | translate}} {{station.anonymousPassengersCount}}
                  </span>
                </div>
                <div
                  *cdkDragPreview
                  class="routes-move-passengers__dnd-placeholder"
                >
                  <div class="routes-move-passengers__dnd-preview">
                    {{dragContent}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!routesMovePassengersDataService.isLoading() && !(stations | movePassengersSearch: searchPassengers : [ 'name', 'address', 'passengers' ]).length"
            class="routes-move-passengers__message"
          >
            {{(searchPassengers === '' ? config.dictionary.allDone : config.dictionary.noResults) | translate}}
          </div>
        </div>
      </div>
      <div class="routes-move-passengers__block routes-move-passengers__block_routes">
        <!-- Routes search -->
        <div class="routes-move-passengers__search">
          <div class="routes-move-passengers__search-header">
            <div class="routes-move-passengers__search-name routes-move-passengers__search-name_routes-direction">
              <div class="routes-move-passengers__search-name-routes">
                {{config.dictionary.routes | translate}}
              </div>
              <div class="routes-move-passengers__search-name-direction">
                {{(sourceRoute.direction === direction.Forward ? 'general.direction.forward' : 'general.direction.backward') | translate}}
              </div>
            </div>
            <div class="routes-move-passengers__search-result">
              ({{(routes | movePassengersSearch: searchRoutes : ['name', 'number', 'participants']).length}} {{config.dictionary.routesAmount | translate}})
            </div>
          </div>
          <input
            class="routes-move-passengers__search-field"
            uInput
            [placeholder]="config.dictionary.searchRoutes | translate"
            type="text"
            [(ngModel)]="searchRoutes"
          >
        </div>

        <!-- Routes list -->
        <div
          *ngIf="form.get('typeChange').value && form.get('datesChange.dates').value?.length && form.valid"
          class="routes-move-passengers__routes"
        >
          <div
            *ngFor="let route of routes | movePassengersSearch: searchRoutes : [ 'name', 'number', 'participants' ]"
            class="routes-move-passengers__route"
            cdkDropList
            [id]="'route-' + route.routeId"
            [ngClass]="!!route.color && ('routes-move-passengers__route_color-' + route.color)"
            (cdkDropListDropped)="droppedIntoRoute($event, route, routesMovePassengersDataService.singleDaySelected)"
          >
            <div class="routes-move-passengers__route-map-icon-wrapper">
              <div
                class="routes-move-passengers__route-map-icon"
                [ngClass]="{ 'routes-move-passengers__route-map-icon_disabled': routes | movePassengersDisabledRouteMapIcon: route }"
                (click)="updateRouteColor(route.routeId)"
              ></div>
            </div>
            <div class="routes-move-passengers__route-details-wrapper">
              <div class="routes-move-passengers__route-title">
                <span class="routes-move-passengers__route-number">{{route.number}}</span>
                <span
                  class="routes-move-passengers__route-name"
                  [uTooltip]="route.name"
                  [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                  [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                  [container]="'body'"
                >
                  {{route.name}}
                </span>
              </div>
              <div class="routes-move-passengers__route-details">
                <div class="routes-move-passengers__route-details-dates">
                  <ng-container *ngIf="route.dateFrom === route.dateTo">
                    {{route.dateFrom | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH}}
                  </ng-container>
                  <ng-container *ngIf="route.dateFrom !== route.dateTo">
                    {{route.dateFrom | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH}}
                    -
                    {{route.dateTo | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH}}
                  </ng-container>
                </div>

                <div class="routes-move-passengers__route-details-time">,
                  {{route.startTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT}} -
                  {{route.endTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT}}
                  <span class="routes-move-passengers__route-divider">|</span>
                </div>

                <div class="routes-move-passengers__route-details-days">
                  {{route.activeDays | obData: (config.dictionary.shortDays | translate)}}
                  <span class="routes-move-passengers__route-divider">|</span>
                </div>

                <div class="routes-move-passengers__route-details-car">
                  {{route.vehicleTypeName ? route.vehicleTypeName : config.dictionary.noCar | translate}}
                </div>

                <div class="routes-move-passengers__route-details-passengers">
                  <span class="routes-move-passengers__route-divider">|</span>
                  {{route.totalPassengers}} {{config.dictionary.passengers | translate}}
                </div>
              </div>
              <div class="routes-move-passengers__route-participants" *ngIf="route.participants && route.participants.length">
                <div
                  *ngFor="let participant of route.participants"
                  class="routes-move-passengers__route-participant"
                  [ngClass]="{ 'routes-move-passengers__route-participant_destination': participant.type === movePassengersRouteParticipantType.Target }"
                >
                  <div
                    class="routes-move-passengers__route-participant-name"
                    uTooltip="
                      {{participant.amount === null ? participant.name : (config.dictionary.anonymousPassenger | translate)}}
                      {{participant.amount}} {{participant.type === movePassengersRouteParticipantType.Accompany && participant.isAnonymous ? ('general.anonymousAccompany' | translate) : null}}
                    "
                    [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                    [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                    [container]="'body'"
                  >
                    {{participant.amount === null ? participant.name : (config.dictionary.anonymousPassenger | translate)}} {{participant.amount}}
                    {{participant.type === movePassengersRouteParticipantType.Accompany && participant.isAnonymous ? ('general.anonymousAccompany' | translate) : null }}
                  </div>
                  <div
                    class="routes-move-passengers__route-participant-address"
                    [uTooltip]="participant.address"
                    [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
                    [tooltipClass]="'u-tooltip_app-routes-move-passengers'"
                    [container]="'body'"
                  >
                    {{participant.address}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!routesMovePassengersDataService.isLoading() && !(routes | movePassengersSearch: searchRoutes : [ 'name', 'number', 'participants' ]).length"
            class="routes-move-passengers__message"
          >
            {{(searchRoutes === '' ? config.dictionary.noAvailableRoutes : config.dictionary.noResults) | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="routes-move-passengers__actions">
      <u-droplist
        class="u-droplist_black"
        [selectedClickable]="true"
        [items]="routesMovePassengersDataService.saveActions"
        [selected]="routesMovePassengersDataService.selectedSaveAction"
        (changeSelected)="onSaveActionChange($event)"
      ></u-droplist>
    </div>
  </div>

  <div class="routes-move-passengers__map">
    <div class="routes-move-passengers__map-header">
      <app-move-passengers-info
        *ngIf="routesMovePassengersDataService.infoSourceRoute"
        [sourceRoute]="routesMovePassengersDataService.infoSourceRoute"
        [routes]="routesMovePassengersDataService.infoRoutes"
        (routeDeleteIconClick)="routesMovePassengersDataService.updateRouteColor($event)"
      ></app-move-passengers-info>
    </div>
    <u-here-map
      [center]="routesMovePassengersDataService.mapData.coords"
      [zoom]="routesMovePassengersDataService.mapData.zoom"
      [bounds]="routesMovePassengersDataService.mapData.bounds"
    >
      <u-here-map-point-center
        [position]="routesMovePassengersDataService.mapData.fitCenter.position"
      ></u-here-map-point-center>
      <u-here-map-point-center
        [position]="routesMovePassengersDataService.mapData.markerCenter.position"
        [config]="routesMovePassengersDataService.mapData.markerCenter.config"
      ></u-here-map-point-center>
      <u-here-map-marker
        *ngFor="let marker of routesMovePassengersDataService.mapData.markers.stations"
        [config]="marker"
        [draggable]="false"
      ></u-here-map-marker>
      <u-here-map-path
        *ngFor="let path of routesMovePassengersDataService.mapData.paths"
        [config]="path"
        [draggable]="false"
      ></u-here-map-path>
    </u-here-map>
  </div>
</div>

<ng-template
  #genericFilter
  let-items="items"
  let-placeholder="placeholder"
  let-control="control"
>
  <u-multiselect
    [items]="items || []"
    [formControl]="control"
    [placement]="[ 'bottom' ]"
    [placeholder]="placeholder | translate"
    [container]="'body'"
    [messages]="config.dictionary.messages.multiselect | translate"
    [valid]="control.valid || control.disabled"
    (click)="control.enabled && routesMovePassengersDataService.updateShowBackdrop(true)"
  ></u-multiselect>
</ng-template>
