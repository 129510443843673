import { Component, HostBinding, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { USelectSItem } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { inputMonitoringComponentConfig } from './input-monitoring.component.config';

@Component({
  selector: 'app-input-monitoring',
  templateUrl: './input-monitoring.component.html',
  styleUrls: [ './input-monitoring.component.scss', './input-monitoring.component.rtl.scss' ]
})
export class InputMonitoringComponent {
  @Input() form: UntypedFormGroup;
  @Input() monitoringVendors: USelectSItem[];

  @HostBinding('class') hostClasses: string = 'input-monitoring';

  config = cloneDeep(inputMonitoringComponentConfig);
  isRtl: boolean = this.localizationService.isRtl();

  constructor(private localizationService: LocalizationService) {}
}
