import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { SignalRService } from '@app/shared/services';
import { RoutesDailyMovePassengersMoveFinished } from '@app/routes/models';

@Injectable()
export class RoutesMovePassengersHubService extends SignalRService {
  BASE_PATH = 'routesMovePassengersHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onPassengersMoveFinished(): Observable<RoutesDailyMovePassengersMoveFinished> {
    return this.onChannel('PassengersMoveFinished');
  }
}
