export interface Holiday {
  name: string;
  endDateTime: string;
  startDateTime: string;
}

export interface HolidaysCalendarItem {
  date: string;
  name: string;
}
