import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { SignalRService } from '@app/shared/services/signal-r.service';
import { ActivityUpdate } from '@app/activities/models';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesHubService extends SignalRService {
  BASE_PATH = 'activitiesHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onActivitiesUpdate(): Observable<ActivityUpdate[]> {
    return this.onChannel<ActivityUpdate[]>('ActivitiesUpdate');
  }
}
