import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AuthModuleFeature } from '@app/auth/models';
import { AuthFacade } from '@app/auth/state/facades';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class AuthModuleFeatureGuard  {
  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    private authDataService: AuthDataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const feature: AuthModuleFeature = route.data.feature;

    return this.authFacade.isInited$
      .pipe(
        filter(inited => !!inited),
        switchMap(() => of(feature ? this.authDataService.checkFeature(feature) : false)),
        map(module => {
          if (!module) {
            this.router.navigate([ '/' ]);

            return false;
          }

          return true;
        }),
        take(1)
      );
  }
}
