import {
  AuthModuleBranchesFeature,
  AuthModuleBranchesFeatureType,
  AuthModuleCustomerStationsFeature,
  AuthModuleCustomerStationsFeatureEntityManager,
  AuthModuleName,
  AuthModulePassengersFeature,
  AuthModulePassengersFeatureEntityManager,
  AuthPermission
} from '@app/auth/models';
import {
  BuilderAccompanyOptionsType,
  BuilderSearchItem,
  BuilderSearchItemType,
  BuilderSearchItems
} from '@app/builder/models';
import { GeneralOptionsType } from '@app/shared/models';

const DICTIONARY_PATH: string = 'builder.search';

const dictionary = {
  search: `${DICTIONARY_PATH}.search`,
  placeholder: `${DICTIONARY_PATH}.placeholder`,
  addresses: `${DICTIONARY_PATH}.addresses`,
  passengers: `${DICTIONARY_PATH}.passengers`,
  stations: `${DICTIONARY_PATH}.stations`,
  schools: `${DICTIONARY_PATH}.schools`,
  accompanies: `${DICTIONARY_PATH}.accompanies`,
  more: `${DICTIONARY_PATH}.more`,
  back: `${DICTIONARY_PATH}.back`,
  branches: `${DICTIONARY_PATH}.branches`,
  addToExistingStation: `${DICTIONARY_PATH}.addToExistingStation`,
  supervisor: 'builder.common.supervisor',
  commander: 'builder.common.commander',
  options: {
    general: {
      addNew: 'general.options.addNew'
    },
    accompanies: {
      required: 'builder.options.accompanies.required',
      anonymous: 'builder.options.accompanies.anonymous'
    }
  }
};

const dictionaryByFeature = {
  [dictionary.branches]: [
    {
      name: `${DICTIONARY_PATH}.bases`,
      feature: {
        module: AuthModuleName.Branches,
        name: AuthModuleBranchesFeature.Type,
        values: [ AuthModuleBranchesFeatureType.Base ]
      }
    },
    {
      name: `${DICTIONARY_PATH}.activityCenters`,
      feature: {
        module: AuthModuleName.Branches,
        name: AuthModuleBranchesFeature.Type,
        values: [ AuthModuleBranchesFeatureType.ActivityCenter ]
      }
    }
  ]
};

const items: BuilderSearchItems = {
  addresses: [],
  passengers: [
    new BuilderSearchItem(
      BuilderSearchItemType.Passenger,
      GeneralOptionsType.AddNew,
      dictionary.options.general.addNew,
      null,
      null,
      null,
      null,
      false,
      {
        module: AuthModuleName.Passengers,
        name: AuthModulePassengersFeature.EntityManager,
        values: [ AuthModulePassengersFeatureEntityManager.EntityManagerFeature ]
      },
      null,
      null,
      AuthPermission.ManagePassengers
    )
  ],
  stations: [
    new BuilderSearchItem(
      BuilderSearchItemType.Station,
      GeneralOptionsType.AddNew,
      dictionary.options.general.addNew,
      null,
      null,
      null,
      null,
      false,
      {
        module: AuthModuleName.CustomerStations,
        name: AuthModuleCustomerStationsFeature.EntityManager,
        values: [ AuthModuleCustomerStationsFeatureEntityManager.EntityManagerFeature ]
      }
    )
  ],
  branches: [],
  schools: [],
  accompanies: [
    new BuilderSearchItem(
      BuilderSearchItemType.Accompany,
      GeneralOptionsType.AddNew,
      dictionary.options.general.addNew,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      AuthPermission.ManageAccompanies
    ),
    new BuilderSearchItem(BuilderSearchItemType.Accompany, BuilderAccompanyOptionsType.Required, dictionary.options.accompanies.required, null, null, null),
    new BuilderSearchItem(BuilderSearchItemType.Accompany, null, dictionary.options.accompanies.anonymous, null, null, null)
  ]
};

export const builderSearchComponentConfig = {
  dictionary,
  dictionaryByFeature,
  items
};
