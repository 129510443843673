<div class="builder-convert-to__wrapper">
  <div class="builder-convert-to__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-convert-to__main">
    <div class="builder-convert-to__message">
      {{'builder.convertTo.message' | translate}}
    </div>
    <div class="builder-convert-to__list" (click)="$event.stopPropagation()">
      <div 
        class="builder-convert-to__item" 
        *ngFor="let item of checkboxes"
      > 
        <div class="builder-convert-to__item-content">
          <div class="builder-convert-to__item-checkbox">
            <u-checkbox class="u-checkbox_square" [ngModel]="item.check" (ngModelChange)="checkItem(item)"></u-checkbox>
          </div>
          <div class="builder-convert-to__item-name">{{item.name | translate}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="specific.check" class="builder-convert-to__passengers" (click)="$event.stopPropagation()">
      <u-multiselect
        [placeholder]="'builder.convertTo.select' | translate"
        [items]="items" 
        [(ngModel)]="passengers"
        [messages]="'uMultiselect' | translate"
      ></u-multiselect>
    </div>
    <div class="builder-convert-to__actions">
      <button class="builder-convert-to__actions-btn" (click)="apply()">{{'builder.convertTo.apply' | translate}}</button>
    </div> 
  </div>
</div>