<div class="builder-days-duplicate__wrapper" [delayClickOutsideInit]="true" (uClickOutside)="close()">
  <div class="builder-days-duplicate__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-days-duplicate__main">
    <div class="builder-days-duplicate__message">
      {{'builder.daysDuplicate.message' | translate}}
    </div>
    <div class="builder-days-duplicate__list" (click)="$event.stopPropagation()">
      <u-select-days
        class="u-select-days_app-builder-days-duplicate"
        [messages]="'uSelectDays' | translate"
        [(ngModel)]="days"
        [sundayFirstDay]="true"
        [rtl]="isRtl"
        [lang]="lang"
        [availableDays]="daysAvailable"
      ></u-select-days>
    </div>
    <div class="builder-days-duplicate__actions">
      <button class="builder-days-duplicate__actions-btn" (click)="apply()">{{'builder.daysDuplicate.apply' | translate}}</button>
    </div> 
  </div>
</div>