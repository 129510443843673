import { activitiesConfig } from '@app/activities/configs';

const DICTIONARY_PATH: string = 'activities.filters';

const dictionary = {
  name: `${DICTIONARY_PATH}.name`,
  status: `${DICTIONARY_PATH}.status`,
  type: `${DICTIONARY_PATH}.type`,
  save: `${DICTIONARY_PATH}.save`,
  select: 'general.select',
  clearFilters: `${DICTIONARY_PATH}.clearFilters`
};

export const activitiesFiltersComponentConfig = {
  dictionary,
  options: activitiesConfig.filters.options
};
