<div
  class="builder-full__wrapper"
>
  <div
    class="builder-full__top"
    [ngClass]="{'builder-full__top_full': !visibleComponents.filter}"
    *ngIf="visibleComponents.passengers || visibleComponents.map"
  >
    <div class="builder-full__top-main">
      <u-steps
        [steps]="builderFullCommonService.steps"
        (stepClick)="onStepClick($event)"
      ></u-steps>

      <div
        *ngIf="!visibleComponents.filter || passengers.columnsFiltered"
        class="builder-full__top-btn-wrap"
      >
        <button
          uButton
          class="builder-full__top-btn"
          [hidden]="!passengers.columnsFiltered || visibleComponents.map"
          (click)="resetColumnsFilterPassengers(true)"
        >
          {{'general.resetFiltersBtn' | translate}}
        </button>
      </div>
      <div
        *ngIf="visibleComponents.map && passengers.selected === 0"
        class="builder-full__top-message"
      >
        {{'builder.full.map.noSelectedPassengers' | translate}}
      </div>
    </div>
    <app-grid-header
      class="builder-full__top-counter-data"
      [showButtons]="false"
      [selected]="passengers.selected"
      [total]="passengers.all"
    ></app-grid-header>
  </div>
  <div class="builder-full__top builder-full__top_full" *ngIf="visibleComponents.routes">
    <div class="builder-full__top-main">
      <u-steps
        [steps]="builderFullCommonService.steps"
        (stepClick)="onStepClick($event)"
      ></u-steps>
    </div>
    <div
      class="builder-full__top-message builder-full__top-message_status"
      [ngClass]="{
        'builder-full__top-message_status_success': message.type === 'success',
        'builder-full__top-message_status_error': message.type === 'error'
      }"
      *ngIf="message"
    >
      {{message.content | translate}}
    </div>
    <div
      class="builder-full__top-counter"
      [translate]="'builder.full.routes.counter'"
      [translateParams]="builderRoutesStoreService.routesCounter()"
    ></div>
  </div>
  <div class="builder-full__split">
    <u-split
      [useTransition]="true"
      [dir]="isRtl ? 'rtl' : 'ltr'"
      [direction]="'horizontal'"
      [disabled]="visibleComponents.filter"
    >
      <u-split-area
        class="u-split-area-layout u-split-area-layout_app-builder-full-filter"
        [panelWidth]="197"
        [order]="1"
        [titleText]="'builder.full.filterTitle' | translate"
        [showTitle]="true"
        [showTitleCloseBtn]="!!visibleComponents.routes"
        (panelClose)="closeFilter()"
        [visible]="visibleComponents.filter"
      >
        <app-builder-full-filter
          (loadMap)="loadMap()"
          (toggleSettings)="toggleSettings()"
          (loadPassengersGrid)="loadPassengersGrid()"
        ></app-builder-full-filter>
      </u-split-area>
      <u-split-area
        class="u-split-area-layout u-split-area-layout_app-default builder-full__area"
        [order]="2"
        *ngIf="visibleComponents.passengers || visibleComponents.map"
      >
        <div *ngIf="visibleComponents.passengers" class="builder-full__area-grid">
          <app-passengers-municipality
            uId="builder-full-passengers"
            *ngIf="passengersFeatureType === authModulePassengersFeatureType.Student"
            [showGridOnly]="true"
            [resetColumnsFilter]="passengers.resetColumnsFilter"
            [selectedPassengers]="passengers.selectedItems"
            [extraFilters]="passengersExtraFilters"
            [maxAmountPassengers]="builderFullCalculationService.configData().routePlannerPassengersAmountRequestLimit"
            (selectPassengers)="selectPassengers($event)"
            (updatePassengersAll)="updatePassengersAll($event)"
            (updateColumnsFiltered)="columnsFilteredPassengers($event)"
            (updateResetColumnsFilter)="resetColumnsFilterPassengers($event)"
          ></app-passengers-municipality>
          <app-passengers-army
            uId="builder-full-passengers-army"
            *ngIf="passengersFeatureType === authModulePassengersFeatureType.Soldier || passengersFeatureType === authModulePassengersFeatureType.SoldierMaster"
            [showGridOnly]="true"
            [resetColumnsFilter]="passengers.resetColumnsFilter"
            [selectedPassengers]="passengers.selectedItems"
            [extraFilters]="passengersExtraFilters"
            [selectedSubCustomers]="builderFullCalculationService.selectedSubCustomers"
            [maxAmountPassengers]="builderFullCalculationService.configData().routePlannerPassengersAmountRequestLimit"
            (selectPassengers)="selectPassengers($event)"
            (updatePassengersAll)="updatePassengersAll($event)"
            (updateColumnsFiltered)="columnsFilteredPassengers($event)"
            (updateResetColumnsFilter)="resetColumnsFilterPassengers($event)"
          ></app-passengers-army>
          <app-passengers-generic
            *ngIf="builderFullCalculationService.routePlannerTypeShuttleCompany() || passengersFeatureType === authModulePassengersFeatureType.Generic || passengersFeatureType === authModulePassengersFeatureType.Iec"
            [mode]="builderFullCalculationService.routePlannerTypeShuttleCompany() ? passengersMode.RoutePlannerSC : passengersMode.Default"
            [showGridOnly]="true"
            [resetColumnsFilter]="passengers.resetColumnsFilter"
            [selectedPassengers]="passengers.selectedItems"
            [extraFilters]="passengersExtraFilters"
            [maxAmountPassengers]="builderFullCalculationService.configData().routePlannerPassengersAmountRequestLimit"
            (selectPassengers)="selectPassengers($event)"
            (updatePassengersAll)="updatePassengersAll($event)"
            (updateColumnsFiltered)="columnsFilteredPassengers($event)"
            (updateResetColumnsFilter)="resetColumnsFilterPassengers($event)"
          ></app-passengers-generic>
        </div>
        <div
          *ngIf="visibleComponents.map"
          class="builder-full__area-grid"
        >
          <app-builder-full-map></app-builder-full-map>
        </div>
        <ng-container *ngTemplateOutlet="settings"></ng-container>
      </u-split-area>
      <u-split-area *ngIf="visibleComponents.main ? (builderCommonService.isExpanded$ | async) === false : visibleComponents.routes" class="u-split-area-layout u-split-area-layout_app-default builder-full__area" [size]="30" [minSize]="552" [order]="3">
        <div class="builder-full__area-grid builder-full__area-grid_routes">
          <app-builder-routes (editRouteAction)="editRoute()" (init)="onBuilderRoutesInit()" (closeRouteAction)="closeMain()"></app-builder-routes>
        </div>
        <ng-container *ngTemplateOutlet="areaFooter"></ng-container>
        <ng-container *ngTemplateOutlet="settings"></ng-container>
      </u-split-area>
      <u-split-area *ngIf="visibleComponents.main" class="builder-full__main-area" [size]="70" [minSize]="510" [order]="4">
        <app-builder-main (closeRoute)="closeMain()"></app-builder-main>
      </u-split-area>
    </u-split>
  </div>
</div>
<div *ngIf="visibleComponents.settings" class="builder-full__backdrop"></div>

<ng-template #areaFooter>
  <div class="builder-full__area-footer">
    <div class="builder-full__area-footer-info-wrapper">
      @if (skippedPassengersExport && visibleComponents.routes && builderFullCommonService.showSkippedPassengersPopup) {
        <div
          class="builder-full__area-footer-skipped-button"
          [uTooltip]="skippedPassengersInfo"
          [tooltipClass]="'u-tooltip_general'"
          [placement]="[ 'top' ]"
          [container]="'body'"
          (click)="showSkippedPassengers()"
        ></div>
        <ng-template #skippedPassengersInfo>
          <div class="builder-full-skipped-passengers-info">
          <span
            class="builder-full-skipped-passengers-info__amount"
            [translate]="'builder.full.skippedPassengersInfo.amount'"
            [translateParams]="{ amount: skippedPassengers.length || 0 }"
          ></span>
            <br>
            <span class="builder-full-skipped-passengers-info__get-list">{{'builder.full.skippedPassengersInfo.getList' | translate}}</span>
          </div>
        </ng-template>
      }

      <button
        uButton
        class="u-font-weight-medium"
        #popoverBuilderFullSummary="uPopover"
        [disabled]="builderFullRoutesGenerationService.disabledAiMode()"
        [uPopover]="aiAlgoSummary"
        [placement]="isRtl ? 'top-right' : 'top-left'"
        [container]="'body'"
        [popoverClass]="'u-popover_app-builder-full-summary'"
        [viewportAutoClose]="false"
        [autoClose]="false"
        (click)="onAiVsAlgoSummaryClick(popoverBuilderFullSummary.isOpen())"
      >
        {{'builder.full.aiVsAlgoSummary' | translate}}
      </button>
      <ng-template #aiAlgoSummary>
        <app-builder-full-summary
          [summary]="builderFullCalculationService.algoAiCompareSummary()"
          [numberOfRoutes]="builderRoutesStoreService.routesCounter().aiSuggestions"
          (close)="onAiVsAlgoSummaryClick(popoverBuilderFullSummary.isOpen()); popoverBuilderFullSummary.close()"
        ></app-builder-full-summary>
      </ng-template>
    </div>
    <button
      *ngIf="visibleComponents.routes && authDataSnapshotService.createRoutes()"
      uButton
      uId="builder-full-create-routes"
      class="builder-full__create-routes-button"
      [ngClass]="{
        'builder-full__create-routes-button_ai': builderFullRoutesGenerationService.modeAi()
      }"
      [view]="uButtonView.Primary"
      [size]="uButtonSize.Large"
      [disabled]="!builderRoutesStoreService.searchedRoutes()?.length || (summaryLoading$ | async) || (savingRoutes$ | async)"
      (click)="finishAndCreate()"
    >
      <span *ngIf="builderFullRoutesGenerationService.modeAi()" class="u-icon u-icon_app-ai-mode-white"></span>
      {{'builder.full.routes.createRoutes' | translate}}
    </button>
  </div>
</ng-template>

<ng-template #settings>
  <app-builder-full-settings
    class="builder-full__settings"
    *ngIf="visibleComponents.settings"
  ></app-builder-full-settings>
</ng-template>

<ng-template #calculationFailedTemplates let-templates="templates">
  <div class="builder-full-failed-templates">
    <div
      *ngFor="let template of templates"
      class="builder-full-failed-templates__item"
    >
      <div class="builder-full-failed-templates__item-number">
        {{template.number}}
      </div>
      <div class="builder-full-failed-templates__item-name">
        {{template.name}}
      </div>
    </div>
  </div>
</ng-template>
