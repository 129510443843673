<div class="builder-ask__wrapper" [delayClickOutsideInit]="true" (uClickOutside)="close()">
  <div class="builder-ask__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-ask__main">
    <div class="builder-ask__message">
      
      {{message | translate}}
    </div>
    <div class="builder-ask__list">
      <ng-container *ngFor="let item of checkboxesStore">
        <div class="builder-ask__item" *ngIf="show(item)">
          <div class="builder-ask__item-field">
            <u-checkbox class="u-checkbox u-checkbox_square-medium" [(ngModel)]="item.check"></u-checkbox>
          </div>
          <div class="builder-ask__item-name">{{item.name | translate}}</div>
        </div>
      </ng-container>
    </div>
    <div class="builder-ask__actions">
      <button class="builder-ask__actions-btn builder-ask__actions-btn_clear" (click)="close()">{{'builder.ask.cancel' | translate}}</button>
      <button class="builder-ask__actions-btn" (click)="apply()">{{'builder.ask.apply' | translate}}</button>
    </div> 
  </div>
</div>