import { ApiBaseResponse, ModalActions, PersonContact } from '@app/shared/models';

export enum AccompaniesAddEditMode {
  Default = 'default',
  AssignToRoute = 'assignToRoute'
}

export enum AccompaniesAddEditActionMode {
  Add = 'add',
  Edit = 'edit'
}

export enum AccompanyCostType {
  Fixed,
  ByManualHour,
  ByAutoHour
}

export enum AccompanyPriceType {
  Manual,
  Auto
}

export interface AccompanyAddEdit {
  id: number;
  firstName: string;
  lastName: string;
  identity: string;
  address: string;
  latitude: number;
  longitude: number;
  placeId: number;
  gender: number;
  birthDate: string;
  comment: string;
  contacts: PersonContact[];
  accompanyId: number;
  jobPercentage: number;
  statusId: number;
  validationErrors: {
    [key: string]: string;
  };
}

export interface Accompany {
  address: string;
  birthDate: string;
  comments: string;
  firstName: string;
  identity: string;
  jobPercentage: string;
  lastName: string;
  memberId: number;
  mobile: string;
  status: number;
}

export interface AccompanyExportToExcelBody {
  columns: {
    field: string;
    alias: string;
  }[];
  accompanyIds: number[];
}

export interface AccompanyAddEditResponse extends ApiBaseResponse {
  value: AccompanyAddEdit;
}

export interface AccompanyResponse extends ApiBaseResponse {
  value: Accompany[];
}

export interface AccompanyAddEditAction {
  type: ModalActions;
  value: any;
  mode?: AccompaniesAddEditActionMode;
}
