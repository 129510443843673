import { Component, HostBinding } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UMenuIcon, UMenuIconsModule } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { RoutesViewType } from '@app/routes/models';
import { RoutesFacade } from '@app/routes/state/facades';
import { headerRoutesViewTypeComponentConfig } from './header-routes-view-type.component.config';

@Component({
  selector: 'app-header-routes-view-type',
  templateUrl: './header-routes-view-type.component.html',
  standalone: true,
  imports: [ UMenuIconsModule, AsyncPipe ]
})
export class HeaderRoutesViewTypeComponent {
  @HostBinding('class') hostClasses: string = 'header-routes-view-type';

  config = cloneDeep(headerRoutesViewTypeComponentConfig);
  isRtl: boolean = this.localizationService.isRtl();
  routesViewTypeIcons$ = this.routesFacade.viewTypeValue$
    .pipe(
      map(viewType =>
        this.config.routesViewTypeIcons
          .map(routesViewTypeIcon => ({ ...routesViewTypeIcon, selected: routesViewTypeIcon.value === viewType }))
      )
    );

  constructor(
    private localizationService: LocalizationService,
    private routesFacade: RoutesFacade
  ) {}

  onRoutesViewTypeIconClick(icon: UMenuIcon) {
    this.routesFacade.viewTypeUpdate(icon.value as RoutesViewType);
  }
}
