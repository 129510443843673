import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { RideOrder, RideOrdersBody } from '@app/ride-orders/models';
import * as RideOrdersSelectors from '@app/ride-orders/state/selectors';
import * as RideOrdersActions from '@app/ride-orders/state/actions';

@Injectable()
export class RideOrdersFacade {
  rideOrdersItems$: Observable<RideOrder[]> = this.store.select(RideOrdersSelectors.selectRideOrdersItemsState);

  constructor(
    private readonly store: Store<any>
  ) {}

  init() {
    this.store.dispatch(RideOrdersActions.init());
  }

  itemsInit(params: RideOrdersBody) {
    this.store.dispatch(RideOrdersActions.itemsInit({ params }));
  }

  load(params: RideOrdersBody) {
    this.store.dispatch(RideOrdersActions.load({ params }));
  }

  loadCancel() {
    this.store.dispatch(RideOrdersActions.loadCancel());
  }

  itemsReset() {
    this.store.dispatch(RideOrdersActions.itemsReset());
  }
}
