import { Injectable } from '@angular/core';

declare global {
	interface Navigator {
		msSaveBlob?: (blob: any, defaultName?: string) => boolean;
	}
}

@Injectable({
  providedIn: 'root'
})
export class FileSaverService {
  base64ToBlob(base64Data: string, type: string = 'application/octet-stream'): Blob {
    const sliceSize: number = 1024;
    const byteCharacters: string = atob(base64Data);
    const bytesLength: number = byteCharacters.length;
    const slicesCount: number = Math.ceil(bytesLength / sliceSize);
    const byteArrays: Uint8Array[] = new Array(slicesCount);

    for (let sliceIndex: number = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin: number = sliceIndex * sliceSize;
      const end: number = Math.min(begin + sliceSize, bytesLength);
      const bytes: number[] = new Array(end - begin);

      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }

      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type });
  }

  downloadBlobFile(blob: Blob, filename: string) {
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');

      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }
    }
  }
}
