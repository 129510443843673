import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/shared/services';
import { AuditHistory } from '@app/shared/models';
import { FileSaverService } from '@app/files/services';
import { ShuttleCompaniesCustomerData, ShuttleCompanyCarAddEdit, ShuttleCompanyCar, ShuttleCompanyCarDocument } from '@app/shuttle-companies/models';

@Injectable({
  providedIn: 'root'
})
export class ShuttleCompanyCarsService {
  private readonly apiService = inject(ApiService);
  private readonly fileSaverService = inject(FileSaverService);

  private BASE_PATH: string = 'ShuttleCompanyCars';

  getCar(carId: number): Observable<ShuttleCompanyCarAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}`, { carId });
  }

  getAllCars(searchText: string = ''): Observable<ShuttleCompanyCar[]> {
    return this.apiService.get(`${this.BASE_PATH}/All`, { searchText });
  }

  getList(): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/List`);
  }

  getCustomerData(): Observable<ShuttleCompaniesCustomerData> {
    return this.apiService.get(`${this.BASE_PATH}/CustomerData`);
  }

  getAudit(carId: number): Observable<AuditHistory[]> {
    return this.apiService.get(`${this.BASE_PATH}/Audit`, { carId });
  }

  getDocuments(carId: number): Observable<ShuttleCompanyCarDocument[]> {
    return this.apiService.get(`${this.BASE_PATH}/Documents`, { carId });
  }

  getDocument(id: number, fileName: string): Observable<Blob> {
    return this.apiService.getBlob(`${this.BASE_PATH}/Document`, 'blob', { id })
      .pipe(
        tap(res => this.fileSaverService.downloadBlobFile(res, fileName))
      );
  }

  uploadDocument(body: FormData): Observable<string> {
    return this.apiService.postFormFile(`${this.BASE_PATH}/Document`, body, false, false, null, true);
  }

  saveCar(car: ShuttleCompanyCarAddEdit): Observable<ShuttleCompanyCarAddEdit> {
    return this.apiService.post(`${this.BASE_PATH}/Save`, car);
  }

  updateCar(car: ShuttleCompanyCarAddEdit): Observable<ShuttleCompanyCarAddEdit> {
    return this.apiService.put(`${this.BASE_PATH}/Update`, car);
  }

  deleteCars(carIds: number[]): Observable<ShuttleCompanyCarAddEdit> {
    return this.apiService.post(`${this.BASE_PATH}/Delete`, { carIds });
  }
}
