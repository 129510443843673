import { EnvMetricSystemType } from '@app/shared/models';

export const appDictionaryConfig = {
  distance: {
    [EnvMetricSystemType.Metric]: {
      km: 'general.units.km',
      kmTitle: 'general.units.kmTitle',
      meter: 'general.units.meter'
    },
    [EnvMetricSystemType.Imperial]: {
      km: 'general.units.mile',
      kmTitle: 'general.units.mileTitle',
      meter: 'general.units.feet'
    }
  },
  contactFiles: {
    title: 'general.contactFiles.title'
  }
};
