import { Injectable } from '@angular/core';

import { BuilderActiveRideBase, BuilderStationListItemType, BuilderStation, BuilderStationListItem } from '@app/builder/models';

@Injectable()
export class BuilderStationService implements BuilderStation {
  list: BuilderStationListItem[] = [];

  customerData = {
    schools: [],
    departments: [],
    branches: [],
    cities: [],
    shifts: [],
    classes: [],
    subCustomers: [],
    shuttleCompanies: [],
    accompanies: [],
    budgetTypes: []
  };

  constructor() {}

  customerDataSet(data: any) {
    this.customerData = data;
  }

  isPassengerWithoutDestination(): boolean {
    return this.list.some(station => station.passengers && station.passengers.some(passenger => passenger.targetStationId === 0));
  }

  isAnyDestination(): boolean {
    return this.list.some(station => station.type === 'destination');
  }

  stationById(id: number): BuilderStationListItem {
    return this.list.find(obj => obj.rideStationId === id);
  }

  stationMovePassenger(ridePassengerId: number, rideStationId: number) {
    const passangerStations = this.list
      .filter((obStation: any) => obStation.passengers && obStation.passengers.some((ob: any) => ob.ridePassengerId === ridePassengerId));

    const stationMove = passangerStations.find((ob: any) => ob.rideStationId !== rideStationId);

    passangerStations.forEach((obStation: any) => {
      if (obStation.rideStationId !== rideStationId) {
        const passengerIndex = obStation.passengers.findIndex((ob: any) => ob.ridePassengerId === ridePassengerId);

        if (passengerIndex !== -1) {
          obStation.passengers.splice(passengerIndex, 1);
        }
      }
    });

    return stationMove;
  }

  stationAccUpdate(station: BuilderStationListItem, activeRide: BuilderActiveRideBase) {
    let expandOptions = null;
    let accompanyPlanned = null;

    const accompanyActive = activeRide.accompanies.find((ob: any) => ob.active);
    const accompanyInActive = activeRide.accompanies.find((ob: any) => !ob.active);

    if (accompanyActive) {
      const accompany = this.customerData.accompanies.find((ob: any) => ob.id === accompanyActive.accompanyId);

      station.name = accompany.name;

      expandOptions = accompanyActive;
    }

    if (!accompanyActive) {
      const anonymousStation = activeRide.accompanies.find((ob: any) => ob.accompanyId === null);

      station.name = 'builder.options.accompanies.anonymous';

      expandOptions = anonymousStation;
    }

    if (accompanyInActive) {
      if (!expandOptions) {
        station.name = accompanyInActive.name;
        expandOptions = accompanyInActive;
      } else {
        accompanyPlanned = accompanyInActive;
      }
    }

    if (!accompanyInActive && !expandOptions.planned) {
      accompanyPlanned = {
        accompanyId: 'without'
      };
    }

    const options = {
      accompanyId: expandOptions.accompanyId,
      costOption: 0,
      cost: null,
      priceOption: 0,
      price: null,
      priceH: null,
      return: expandOptions.destinationStationId !== null,
      pickUpHome: expandOptions.sourceStationId !== null,
      planned: accompanyPlanned
    };

    if (expandOptions.costType === 0) {
      options.costOption = expandOptions.costType;
      options.cost = expandOptions.totalCost;
    }

    if (expandOptions.costType === 1) {
      options.costOption = 1;
      options.priceOption = 0;
      options.cost = expandOptions.costPerHour;
      options.price = expandOptions.hours;
      options.priceH = expandOptions.totalCost;
    }

    if (expandOptions.costType === 2) {
      options.costOption = 1;
      options.priceOption = 1;
      options.cost = expandOptions.costPerHour;
      options.price = expandOptions.hours;
      options.priceH = expandOptions.totalCost;
    }

    if (station.expand) {
      station.expand.options = options;
    } else {
      station.expand = {
        check: false,
        options
      };
    }
  }

  stationAccExpandSync(activeRide: BuilderActiveRideBase) {
    this.list.forEach(station => {
      if (station.type === BuilderStationListItemType.Accompany) {
        this.stationAccUpdate(station, activeRide);
      }
    });
  }

  parse(activeRide: BuilderActiveRideBase, active = false) {
    let list: BuilderStationListItem[] = activeRide.stations
      .filter(station => (station.active === active && !activeRide.cancelled) || (!active && activeRide.cancelled))
      .map(station => {
        const type = {
          0: BuilderStationListItemType.Station,
          10: BuilderStationListItemType.Destination,
          20: BuilderStationListItemType.Accompany
        };

        const currentStation: BuilderStationListItem = {
          ...station,
          type: type[station.type],
          order: null
        };

        if (currentStation.type === BuilderStationListItemType.Station) {
          currentStation.passengers = activeRide.passengers
            .filter(passenger => passenger.stationId === station.rideStationId)
            .map(passenger => ({ ...passenger, destination: 0, type: BuilderStationListItemType.Passenger }));
        }

        if (currentStation.type === BuilderStationListItemType.Accompany) {
          this.stationAccUpdate(currentStation, activeRide);
        }

        return currentStation;
      });

    if (activeRide.needAccompany && active) {
      const isAccompany = list.some(obj => obj.type === BuilderStationListItemType.Accompany);

      if (!isAccompany) {
        list = [
          {
            address: null,
            arrivalTime: null,
            latitude: null,
            longitude: null,
            name: 'builder.options.accompanies.required',
            rideStationId: 'required',
            type: BuilderStationListItemType.Accompany,
            expand: {
              check: false,
              options: {
                accompanyId: 'required',
                costOption: 0,
                cost: null,
                priceOption: 0,
                price: null,
                priceH: null,
                return: false,
                pickUpHome: true,
                costPerHour: null,
                plannedCostPerHour: null,
                costType: null,
                plannedCostType: null,
                hours: null,
                plannedHours: null,
                totalCost: null,
                plannedTotalCost: null
              }
            }
          },
          ...list
        ];
      }
    }

    let stationOrder = 1;
    let destinationOrder = 1;

    list.forEach(obj => {
      if (obj.type === BuilderStationListItemType.Station) {
        obj.order = stationOrder;

        stationOrder += 1;
      }

      if (obj.type === BuilderStationListItemType.Destination) {
        obj.order = destinationOrder;

        destinationOrder += 1;
      }
    });

    list.forEach(station => {
      if (station.passengers) {
        station.passengers.forEach(passenger => {
          const currentStation = list.find(obj => obj.type === 'destination' && obj.rideStationId === passenger.targetStationId);

          passenger.destination = currentStation && currentStation.order ? currentStation.order : 0;
        });
      }
    });

    this.list = list;
  }

  updatePassengerCustomerName(passengerId: number, name: string) {
    this.list.forEach(item => {
      const passenger = item.passengers && item.passengers.find(obj => obj.passengerId === passengerId);

      if (passenger) {
        item.passengers = item.passengers.map(obj => obj.passengerId === passengerId ? { ...obj, customerName: name } : obj);
      }
    });
  }
}
