import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  private BASE_PATH: string = 'Constants';

  constructor(private apiService: ApiService) {}

  getConstants(categories: string): Observable<{ [key: string]: { id: number; name: string; }[]; }> {
    return this.apiService.get(this.BASE_PATH, { categories });
  }
}
