import { ModalActions } from '@app/shared/models';
import { AuthPermission } from '@app/auth/models';

const DICTIONARY_PATH: string = 'accompanies.addEdit';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  infoTab: `${DICTIONARY_PATH}.infoTab`,
  deleteConfirm: `${DICTIONARY_PATH}.deleteConfirm`,
  closeConfirm: `${DICTIONARY_PATH}.closeConfirm`,
  yes: 'general.yes',
  no: 'general.no',
  successful: 'general.successful',
  error: 'general.error',
  delete: 'general.delete',
  save: 'general.save'
};

export const accompaniesAddEditComponentConfig = {
  dictionary,
  buttons: [
    {
      name: dictionary.delete,
      action: ModalActions.Delete,
      clear: true,
      permission: AuthPermission.ManageAccompanies
    },
    {
      name: dictionary.save,
      action: ModalActions.Submit,
      clear: false,
      permission: AuthPermission.ManageAccompanies
    }
  ]
};
