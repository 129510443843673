export enum DaysOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export enum YesNoNumber {
  No = 0,
  Yes = 1
}

export interface ApiBaseError {
  code: string;
  categoryDescription: string;
  description: string;
}

export interface ApiBaseResponse {
  errors: ApiBaseError[];
  success?: boolean;
}

export interface ApiBaseResponseEmptyValue extends ApiBaseResponse {
  value: null;
}
