import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as moment from 'moment';
import 'moment-duration-format';

import { environment } from '@environments/environment';
import { LocalizationService } from '@app/shared/services';
import { AuthFacade } from '@app/auth/state/facades';
import { AuthService } from '@app/auth/services';
import { authConfig } from '@app/auth/configs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly document = inject(DOCUMENT);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly localizationService = inject(LocalizationService);
  private readonly authFacade = inject(AuthFacade);
  private readonly oAuthService = inject(OAuthService);
  private readonly authService = inject(AuthService);

  readonly isInited$ = this.authFacade.isInited$;
  readonly isLoggedIn$ = this.authFacade.isLoggedIn$;

  constructor() {
    moment.locale(this.localizationService.getLanguage());
  }

  ngOnInit() {
    this.initAuthLogin();
  }

  private async initAuthLogin() {
    const queryParams = new URLSearchParams(this.document.location.search);
    const s = queryParams.get('s');

    const authCodeFlowConfig: AuthConfig = {
      issuer: `${environment.config.idpAuthority}/`,
      redirectUri: `${this.document.location.origin}/signin-callback`,
      postLogoutRedirectUri: `${this.document.location.origin}/signout-callback`,
      clientId: authConfig.clientId,
      responseType: authConfig.responseType,
      scope: authConfig.scope,
      customQueryParams: s && { s }
    };

    this.oAuthService.configure(authCodeFlowConfig);
    this.oAuthService.events
      .pipe(
        filter((event: OAuthErrorEvent) => event.type === 'token_refresh_error'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => {
        const { error } = event.reason as HttpErrorResponse;

        if (error && error.redirectUrl) {
          location.href = `${environment.config.idpAuthority}${error.redirectUrl}`;
        }
      });

    await this.oAuthService.loadDiscoveryDocumentAndTryLogin();

    const accessToken = this.oAuthService.getAccessToken();
    const refreshToken = this.oAuthService.getRefreshToken();

    if (!accessToken && !refreshToken && !authConfig.callbackUrls.some(url => location.pathname.includes(url))) {
      this.authService.clearStorages();

      this.oAuthService.initLoginFlow();
    } else {
      if (refreshToken && this.authService.isExpiredAccessToken()) {
        try {
          await this.oAuthService.refreshToken();
        } catch {
          this.authService.clearStorages();

          this.oAuthService.initLoginFlow();
        }
      }

      if (authConfig.callbackUrls.some(url => location.pathname.includes(url))) {
        const { lang } = this.activatedRoute.snapshot.queryParams;

        if (lang) {
          this.localizationService.saveLanguage(lang);
        }
      }

      this.localizationService.init();
      this.authFacade.init();
    }
  }
}
