import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

import { HeaderSearchFiltersService } from '@app/shared/services';
import { NavigationPaths } from '@app/shared/models';
import { patchSignal } from '@app/shared/utils';
import { AuthDataService } from '@app/auth/services';
import { AuthModuleName } from '@app/auth/models';
import { DemandsState, DemandsView } from '@app/demands/models';
import { DemandsStorageService } from '@app/demands/services';

@Injectable({
  providedIn: 'root'
})
export class DemandsStoreService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly authDataService = inject(AuthDataService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly demandsStorageService = inject(DemandsStorageService);

  readonly #state = signal<DemandsState>({
    showViewToggle: false,
    view: null
  });

  readonly state = this.#state.asReadonly();
  readonly showViewToggle = computed(() => this.state().showViewToggle);
  readonly view = computed(() => this.state().view);

  readonly view$ = toObservable(this.view);

  constructor() {
    this.initView();
    this.resetGlobalFiltersOnViewChange();
  }

  private initView() {
    const availableViews = Object
      .entries({
        [DemandsView.Passengers]: {
          module: AuthModuleName.DemandsPassengersView
        },
        [DemandsView.Shifts]: {
          module: AuthModuleName.DemandsShiftsView
        }
      })
      .filter(([ key, value ]) => this.authDataService.checkFeature(value))
      .map(([ key ]) => key);

    let view = this.demandsStorageService.getView() || DemandsView.Passengers;

    if (!availableViews.includes(view)) {
      view = <DemandsView>availableViews?.[0];
    }

    this.updateView(view);
  }

  private resetGlobalFiltersOnViewChange() {
    this.view$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.router.url.replace('/', '') === NavigationPaths.Demands) {
          this.headerSearchFiltersService.reset();
        } else {
          this.headerSearchFiltersService.resetSearchConfigByUrl(NavigationPaths.Demands);
        }
      });
  }

  updateShowViewToggle(showViewToggle: boolean) {
    patchSignal(this.#state, { showViewToggle });
  }

  updateView(view: DemandsView) {
    patchSignal(this.#state, { view });

    this.demandsStorageService.updateView(view);
  }
}
