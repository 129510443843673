import { UDateRangePreset } from '@shift/ulib';

import { EmailsSendShuttleCompaniesChangesDatesType, EmailsSendShuttleCompaniesChangesType } from '@app/emails/models';

const DICTIONARY_PATH: string = 'emails.sendShuttleCompaniesChanges';

export const emailsSendShuttleCompaniesChangesComponentConfig = {
  trackingId: 'Bulk send changes',
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    datesFiltrationType: {
      title: `${DICTIONARY_PATH}.datesFiltrationType.title`
    },
    rides: {
      shuttleCompanies: `${DICTIONARY_PATH}.rides.shuttleCompanies`,
      changesTypes: `${DICTIONARY_PATH}.rides.changesTypes`,
      changeSubject: `${DICTIONARY_PATH}.rides.changeSubject`,
      includeChanges: `${DICTIONARY_PATH}.rides.includeChanges`,
      all: `${DICTIONARY_PATH}.rides.all`,
      lastChanges: `${DICTIONARY_PATH}.rides.lastChanges`,
      openForEdit: `${DICTIONARY_PATH}.rides.openForEdit`,
      send: `${DICTIONARY_PATH}.rides.send`
    },
    select: 'general.select',
    uSelectS: 'uSelectS',
    uMultiselect: 'uMultiselect'
  },
  datesFiltrationType: [
    { value: EmailsSendShuttleCompaniesChangesDatesType.CreationDate, name: `${DICTIONARY_PATH}.datesFiltrationType.creationDate` },
    { value: EmailsSendShuttleCompaniesChangesDatesType.ChangePeriod, name: `${DICTIONARY_PATH}.datesFiltrationType.changePeriod` }
  ],
  changeType: [
    { value: EmailsSendShuttleCompaniesChangesType.All, name: `${DICTIONARY_PATH}.changeTypes.all` },
    { value: EmailsSendShuttleCompaniesChangesType.Planned, name: `${DICTIONARY_PATH}.changeTypes.planned` },
    { value: EmailsSendShuttleCompaniesChangesType.UnPlanned, name: `${DICTIONARY_PATH}.changeTypes.notPlanned` }
  ],
  uDateRangePresets: [ UDateRangePreset.Today, UDateRangePreset.Tomorrow, UDateRangePreset.FromDateOn, UDateRangePreset.CurrentMonth, UDateRangePreset.Custom ]
};
