import { AuthModuleDemandsShiftsViewFeatureType } from '@app/auth/models';
import { demandsConfig } from '@app/demands/configs';
import { DemandsView } from '@app/demands/models';

const DICTIONARY_PATH: string = 'demands.passengersDay';

const demandsPassengersDayComponent = {
  tracking: demandsConfig.tracking[DemandsView.Shifts].default,
  dictionary: {
    withoutDepartment: `${DICTIONARY_PATH}.withoutDepartment`,
    deleteShiftConfirm: 'demands.assignShift.deleteShiftConfirm'
  }
};

const demandsPassengersDayComponentMunicipality = {
  ...demandsPassengersDayComponent,
  tracking: demandsConfig.tracking[DemandsView.Shifts].activitySchedule
};

const demandsPassengersDayComponentArmy = {
  ...demandsPassengersDayComponent
};

export const demandsPassengersDayComponentConfig = {
  default: demandsPassengersDayComponent,
  [AuthModuleDemandsShiftsViewFeatureType.Student]: demandsPassengersDayComponentMunicipality,
  [AuthModuleDemandsShiftsViewFeatureType.Soldier]: demandsPassengersDayComponentArmy
};
