<ng-template
  #titleWrapper
  let-itemType
  let-title="title"
>
  <div class="builder-search-list__title">
    <span class="builder-search-list__title-text">
      {{title | translate}}
    </span>
    <span
      class="builder-search-list__title-btn"
      [uId]="'builder-search-list-title-btn-' + itemType"
      (click)="updateVisibleItemType(itemType)"
    >
      {{(visibleItemType === itemType ? config.dictionary.back : config.dictionary.more) | translate}}
    </span>
  </div>
</ng-template>

<ng-template
  #listItem
  let-items
  let-condition="condition"
  let-itemType="itemType"
  let-title="title"
>
  <div
    *ngIf="condition"
    class="builder-search-list__item"
  >
    <ng-container
      *ngTemplateOutlet="titleWrapper; context: {
        $implicit: itemType,
        title: title
      }"
    ></ng-container>
    <div class="builder-search-block">
      <a
        class="builder-search-block__link"
        href="#"
        *ngFor="let item of items | slice: 0 : limitTo(items.length)"
        (click)="itemSelect($event, item)"
      >
        {{item.name | translate}}
      </a>
    </div>
  </div>
</ng-template>

<ng-template #searchDropdown>
  <div
    class="builder-search__list"
    (uClickOutside)="outsideClick($event)"
  >
    <div class="builder-search-list">
      <div class="builder-search-list__wrapper">
        <ng-container
          *ngTemplateOutlet="listItem; context: {
            $implicit: items.addresses,
            condition: items.addresses && items.addresses.length &&
              [ builderSearchItemType.All, builderSearchItemType.Address ] | includes: visibleItemType,
            itemType: builderSearchItemType.Address,
            title: config.dictionary.addresses
          }"
        ></ng-container>
        <div
          *ngIf="
            items.passengers && items.passengers.length &&
            [ builderSearchItemType.All, builderSearchItemType.Passenger ] | includes: visibleItemType
          "
          class="builder-search-list__item"
        >
          <ng-container
            *ngTemplateOutlet="titleWrapper; context: {
              $implicit: builderSearchItemType.Passenger,
              title: config.dictionary.passengers
            }"
          ></ng-container>
          <div class="builder-search-block">
            <a
              class="builder-search-block__link"
              href="#"
              *ngFor="let item of items.passengers | slice: 0:limitTo(items.passengers.length)"
              [uId]="item.name + (item.customerName ? ('-' + item.customerName) : '')"
              [ngClass]="{ 'builder-search-block__link_has-sub-customer': item.customerName }"
              (click)="itemSelect($event, item)"
            >
              <span class="builder-search-block__link-name-wrapper">
                <span
                  class="builder-search-block__link-name"
                  [ngClass]="{ 'builder-search-block__link-name_is-supervisor': item.isSupervisor }"
                >
                  {{item.name | translate}}
                </span>
                <span
                  class="builder-search-block__link-details"
                >
                  <span
                    *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeGeneric() && item.isSupervisor"
                    class="builder-search-block__link-details-text"
                  >
                    {{config.dictionary.supervisor | translate}}
                  </span>
                  <span 
                    *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeCommander() && item.isSupervisor" 
                    class="builder-search-block__link-details-text"
                  >
                    {{config.dictionary.commander | translate}}
                  </span>
                  <span class="builder-search-block__link-details-icon">
                    <div 
                      class="u-icon u-icon_pointer u-icon_app-builder-add-passenger-to-station" 
                      [container]="'body'"
                      [uTooltip]="config.dictionary.addToExistingStation | translate"
                      (click)="itemSelect($event, item, true)"
                    ></div>
                  </span>
                </span>
              </span>
              <span
                *ngIf="item.customerName"
                class="builder-search-block__link-subtitle"
              >
                {{item.customerName}}
              </span>
            </a>
          </div>
        </div>
        <ng-container
          *ngTemplateOutlet="listItem; context: {
            $implicit: items.stations,
            condition: items.stations && items.stations.length &&
              [ builderSearchItemType.All, builderSearchItemType.Station ] | includes: visibleItemType,
            itemType: builderSearchItemType.Station,
            title: config.dictionary.stations
          }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="listItem; context: {
            $implicit: items.branches,
            condition: items.branches && items.branches.length &&
              [ builderSearchItemType.All, builderSearchItemType.Branch ] | includes: visibleItemType,
            itemType: builderSearchItemType.Branch,
            title: config.dictionary.branches
          }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="listItem; context: {
            $implicit: items.schools,
            condition: items.schools && items.schools.length && !!educationInstitutionsFeatureType() &&
              [ builderSearchItemType.All, builderSearchItemType.School ] | includes: visibleItemType,
            itemType: builderSearchItemType.School,
            title: config.dictionary.schools
          }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="listItem; context: {
            $implicit: items.accompanies,
            condition: items.accompanies && items.accompanies.length && !!accompanyFeatureType() &&
              ([ builderSearchItemType.All, builderSearchItemType.Accompany ] | includes: visibleItemType) &&
              builderDataService.assignAccompanyToRidesAllowed(),
            itemType: builderSearchItemType.Accompany,
            title: config.dictionary.accompanies
          }"
        ></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<div class="builder-search__wrapper">
  <div class="builder-search__group">
    <div class="builder-search__group-name">{{config.dictionary.search | translate}}</div>
    <div
      class="builder-search__group-field"
    >
      <input
        #searchPopover="uPopover"
        uInput
        uId="builder-search-input"
        [uPopover]="searchDropdown"
        [container]="'body'"
        [viewportElement]="document.body"
        [placement]="[ 'bottom', 'top' ]"
        [placementDefault]="'top'"
        [autoClose]="false"
        [popoverClass]="'u-popover_app-builder-search'"
        [showArrow]="false"
        [triggers]="'manual'"
        [formControl]="search"
        [placeholder]="config.dictionary.placeholder | translate"
        (click)="showListChange($event, true); formControlClick.emit();"
      >
    </div>
  </div>
  <div
    uId="builder-search-filter"
    class="builder-search__filter"
    (click)="filter()"
  >
    <div class="u-icon u-icon_pointer u-icon_app-builder-sliders"></div>
  </div>
</div>
