import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject, Subscription, timer } from 'rxjs';
import { cloneDeep } from 'lodash';

import { environment } from '@environments/environment';
import { TrackingService } from '@app/shared/services';
import { AuthCustomer, AuthCustomerType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { MonitoringDashboardLayer, MonitoringDashboardLayerId, MonitoringDashboardSourceType } from '@app/monitoring-dashboard/models';
import { MonitoringDashboardDataService, MonitoringDashboardService } from '@app/monitoring-dashboard/services';
import { monitoringDashboardLayersComponentConfig } from './monitoring-dashboard-layers.component.config';

@Component({
  selector: 'app-monitoring-dashboard-layers',
  templateUrl: './monitoring-dashboard-layers.component.html',
  styleUrls: [ './monitoring-dashboard-layers.component.scss', './monitoring-dashboard-layers.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonitoringDashboardLayersComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses: string = 'monitoring-dashboard-layers';

  private unsubscribe: Subject<void> = new Subject();
  private getLastCoordinatesSubscription: Subscription;

  config = cloneDeep(monitoringDashboardLayersComponentConfig.default);
  showLayersMenu = false;

  constructor(
    private monitoringDashboardDataService: MonitoringDashboardDataService,
    private monitoringDashboardService: MonitoringDashboardService,
    private trackingService: TrackingService,
    private authDataService: AuthDataService
  ) {}

  ngOnInit() {
    this.initAuthCustomer();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initAuthCustomer() {
    this.authDataService.customer$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(authCustomer => {
        this.config = cloneDeep(monitoringDashboardLayersComponentConfig[authCustomer.type] || monitoringDashboardLayersComponentConfig.default);
        this.addDriversAndCarsToLayers(this.config.layers, authCustomer);
      });
  }

  private addDriversAndCarsToLayers(layers: MonitoringDashboardLayer[], authCustomer: AuthCustomer) {
    if (authCustomer.type === AuthCustomerType.ShuttleCompany || authCustomer.selfShuttleCompanyId) {
      const trafficIndex = layers.findIndex(layer => layer.id === MonitoringDashboardLayerId.Traffic);

      if (trafficIndex >= 0) {
        layers.splice(trafficIndex, 0, this.config.allDriversLayer, this.config.allVehiclesLayer);
      } else {
        layers.push(this.config.allDriversLayer, this.config.allVehiclesLayer);
      }
    }
  }

  private getLastCoordinates(types: MonitoringDashboardSourceType[]) {
    if (this.getLastCoordinatesSubscription) {
      this.getLastCoordinatesSubscription.unsubscribe();
    }

    if (!types.length) {
      return;
    }

    this.getLastCoordinatesSubscription = timer(0, environment.config.dashboardRefreshTime * 1000)
      .pipe(
        switchMap(() => this.monitoringDashboardService.getLastCoordinates(types)),
        tap(items => {
          this.config.layersActions.forEach(layersAction => {
            const allItems = items.find(item => item.type === layersAction.type);

            if (allItems) {
              this.monitoringDashboardDataService[layersAction.updateFuncName](allItems.coordinates);
            }
          });
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private trackEvent(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  toggleLayer(layer: MonitoringDashboardLayer) {
    layer.active = !layer.active;

    this.trackEvent(`layers button > click on ${layer.id.replace('-', ' ')}`);

    if (layer.id === MonitoringDashboardLayerId.Traffic) {
      this.monitoringDashboardDataService.updateTraffic(layer.active);

      return;
    }

    if (layer.id === MonitoringDashboardLayerId.MonitoringPath) {
      this.monitoringDashboardDataService.updateMonitoringPathsVisibility(layer.active);

      return;
    }

    if (!layer.active && layer.type) {
      this.config.layersActions.forEach(layersAction => {
        if (layer.type === layersAction.type) {
          this.monitoringDashboardDataService[layersAction.clearFuncName]();
        }
      });
    }

    this.getLastCoordinates(this.config.layers.filter(obj => obj.active && obj.type !== undefined).map(obj => obj.type));
  }

  updateShowLayersMenu(value: boolean) {
    this.showLayersMenu = value;
  }
}
