import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';

import { AuthUserInfo } from '@app/auth/models';
import { isNotNullOrUndefined } from '@app/shared/utils';
import * as AuthSelectors from '@app/auth/state/selectors';
import * as AuthActions from '@app/auth/state/actions';

@Injectable()
export class AuthFacade {
  private readonly store = inject(Store);

  readonly isInited$: Observable<boolean> = this.store.select(AuthSelectors.selectAuthStatusInitedState);
  readonly isLoggedIn$: Observable<boolean> = this.store.select(AuthSelectors.selectLoggedIn);
  readonly countdownCounter$: Observable<number> = this.store.select(AuthSelectors.selectAuthCountdownCounterState);
  readonly userInfo$: Observable<AuthUserInfo> = this.store.select(AuthSelectors.selectAuthStatusDataState).pipe(isNotNullOrUndefined());

  private dispatch(action: Action) {
    this.store.dispatch(action);
  }

  init() {
    this.dispatch(AuthActions.init());
  }

  updateUser() {
    this.dispatch(AuthActions.updateUser());
  }

  logout() {
    this.dispatch(AuthActions.logout());
  }
}
