import { AuthCustomerType } from '@app/auth/models';
import { EnvType } from '@app/shared/models';
import { MonitoringDashboardLegend } from '@app/monitoring-dashboard/models';

const DICTIONARY_PATH: string = 'monitoringDashboard.info';

const dictionary = {
  km: `${DICTIONARY_PATH}.km`,
  time: `${DICTIONARY_PATH}.time`,
  title: `${DICTIONARY_PATH}.legend.title`,
  destination: `${DICTIONARY_PATH}.legend.destination`,
  station: `${DICTIONARY_PATH}.legend.station`,
  supervisor: `${DICTIONARY_PATH}.legend.supervisor`,
  supervisorDescription: `${DICTIONARY_PATH}.legend.supervisorDescription`,
  commander: `${DICTIONARY_PATH}.legend.commander`,
  commanderDescription: `${DICTIONARY_PATH}.legend.commanderDescription`,
  stationDescription: `${DICTIONARY_PATH}.legend.stationDescription`,
  accompanyStation: `${DICTIONARY_PATH}.legend.accompanyStation`,
  accompanyStationDescription: `${DICTIONARY_PATH}.legend.accompanyStationDescription`,
  plannedRoute: `${DICTIONARY_PATH}.legend.plannedRoute`,
  driverMonitoring: `${DICTIONARY_PATH}.legend.driverMonitoring`,
  vehicleMonitoring: `${DICTIONARY_PATH}.legend.vehicleMonitoring`,
  supervisorMonitoring: `${DICTIONARY_PATH}.legend.supervisorMonitoring`,
  commanderMonitoring: `${DICTIONARY_PATH}.legend.commanderMonitoring`,
  accompanyMonitoring: `${DICTIONARY_PATH}.legend.accompanyMonitoring`,
  arrowDirection: `${DICTIONARY_PATH}.legend.arrowDirection`
};

const destinationLegend: MonitoringDashboardLegend = {
  id: 'destination',
  title: dictionary.destination,
  icon: {
    default: '/assets/images/map/point-destination.svg'
  }
};

const stationLegendIconDefault = '/assets/images/map/station-number.svg';
const stationLegendIconOrange = '/assets/images/themes/orange/map/station-number.svg';
const stationLegendIconGreen = '/assets/images/themes/green/map/station-number.svg';

const stationLegend: MonitoringDashboardLegend = {
  id: 'station',
  title: dictionary.station,
  description: dictionary.stationDescription,
  icon: {
    default: stationLegendIconDefault,
    [EnvType.Traffical]: stationLegendIconOrange,
    [EnvType.IDF]: stationLegendIconOrange
  }
};

const supervisorLegend: MonitoringDashboardLegend = {
  id: 'supervisor',
  title: dictionary.supervisor,
  description: dictionary.supervisorDescription,
  icon: {
    default: '/assets/images/map/supervisor.svg'
  }
};

const commanderLegend: MonitoringDashboardLegend = {
  ...supervisorLegend,
  title: dictionary.commander,
  description: dictionary.commanderDescription
};

const plannedRouteLegend: MonitoringDashboardLegend = {
  id: 'plannedRoute',
  title: dictionary.plannedRoute,
  description: dictionary.arrowDirection,
  icon: {
    default: '/assets/images/monitoring-dashboard/ride-direction.svg'
  }
};

const driverMonitoringLegend: MonitoringDashboardLegend = {
  id: 'driverMonitoring',
  title: dictionary.driverMonitoring,
  description: dictionary.arrowDirection,
  icon: {
    default: '/assets/images/monitoring-dashboard/ride-direction-driver.svg'
  }
};

const vehicleMonitoringLegend: MonitoringDashboardLegend = {
  id: 'vehicleMonitoring',
  title: dictionary.vehicleMonitoring,
  description: dictionary.arrowDirection,
  icon: {
    default: '/assets/images/monitoring-dashboard/ride-direction-vehicle.svg'
  }
};

const supervisorMonitoringLegend: MonitoringDashboardLegend = {
  id: 'supervisorMonitoring',
  title: dictionary.supervisorMonitoring,
  description: dictionary.arrowDirection,
  icon: {
    default: '/assets/images/monitoring-dashboard/ride-direction-supervisor.svg'
  }
};

const commanderMonitoringLegend: MonitoringDashboardLegend = {
  ...supervisorMonitoringLegend,
  title: dictionary.commanderMonitoring
};

const accompanyStationLegend: MonitoringDashboardLegend = {
  id: 'accompanyStation',
  title: dictionary.accompanyStation,
  description: dictionary.accompanyStationDescription,
  icon: {
    default: '/assets/images/map/point-accompany.svg'
  }
};

const accompanyMonitoringLegend: MonitoringDashboardLegend = {
  id: 'accompanyMonitoring',
  title: dictionary.accompanyMonitoring,
  description: dictionary.arrowDirection,
  icon: {
    default: '/assets/images/monitoring-dashboard/ride-direction-accompany.svg'
  }
};

const monitoringDashboardInfoComponent = {
  dictionary,
  legends: [
    destinationLegend,
    stationLegend,
    plannedRouteLegend,
    driverMonitoringLegend,
    vehicleMonitoringLegend
  ]
};

export const monitoringDashboardInfoComponentConfig = {
  default: {
    ...monitoringDashboardInfoComponent,
    legends: [
      destinationLegend,
      stationLegend,
      supervisorLegend,
      plannedRouteLegend,
      driverMonitoringLegend,
      vehicleMonitoringLegend,
      supervisorMonitoringLegend
    ]
  },
  [AuthCustomerType.ShuttleCompany]: {
    ...monitoringDashboardInfoComponent,
    legends: [
      destinationLegend,
      {
        ...stationLegend,
        icon: {
          ...stationLegend.icon,
          [EnvType.Shift]: stationLegendIconGreen
        }
      },
      plannedRouteLegend,
      driverMonitoringLegend,
      vehicleMonitoringLegend
    ]
  },
  [AuthCustomerType.Municipality]: {
    ...monitoringDashboardInfoComponent,
    legends: [
      destinationLegend,
      stationLegend,
      accompanyStationLegend,
      plannedRouteLegend,
      driverMonitoringLegend,
      vehicleMonitoringLegend,
      accompanyMonitoringLegend
    ]
  },
  [AuthCustomerType.Army]: {
    ...monitoringDashboardInfoComponent,
    legends: [
      destinationLegend,
      stationLegend,
      commanderLegend,
      plannedRouteLegend,
      driverMonitoringLegend,
      vehicleMonitoringLegend,
      commanderMonitoringLegend
    ]
  }
};
