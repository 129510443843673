import { URangePreset } from '@shift/ulib';

import { DaysOfWeek } from '@app/shared/models';

const DICTIONARY_PATH: string = 'routeTemplates.routesFromTemplates';
const availableRangePresets: URangePreset[] = [
  URangePreset.Today,
  URangePreset.Tomorrow,
  URangePreset.UpcomingWeek,
  URangePreset.Custom
];

export const routesFromTemplatesComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    period: `${DICTIONARY_PATH}.period`,
    drivers: `${DICTIONARY_PATH}.drivers`,
    shuttleCompany: `${DICTIONARY_PATH}.shuttleCompany`,
    accompanies: `${DICTIONARY_PATH}.accompanies`,
    duplicateAsDailyRoutes: `${DICTIONARY_PATH}.duplicateAsDailyRoutes`,
    cancel: 'general.cancel',
    saveAndClose: 'general.saveAndClose',
    toggle: `${DICTIONARY_PATH}.toggle`,
    saveSuccess: `${DICTIONARY_PATH}.saveSuccess`
  },
  availableRangePresets,
  availableDays: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ],
  trackingId: 'Create routes from template'
};
