<div class="routes-export__container">
  <div class="routes-export__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-routes-export-close"></div>
  </div>
  <div class="routes-export__title">{{config.dictionary.title | translate}}</div>
  <div class="routes-export__main-form">
    <u-date-range-presets
      [class]="'u-date-range-presets_app-routes-export'"
      [datePickerRangeMode]="config.datePickerRangeMode"
      [defaultDate]="false"
      [checkDays]="true"
      [checkDaysBtn]="false"
      [presets]="config.datePresets"
      [mode]="uDateRangePresetsMode.GroupRange"
      [activePreset]="activePreset || config.defaultActivePreset"
      [dates]="dates"
      [maxPeriodSelectionFromSelectedDate]="config.maxPeriodSelection"
      (changeDatesAction)="changeDatesAction($event)"
    ></u-date-range-presets>
    <div class="routes-export__main-form-item">
      <u-group
        class="u-group_app-routes-export"
        [name]="config.dictionary.email | translate"
        [valid]="emailControl.valid"
        [message]="config.dictionary.required | translate"
      >
        <input
          uInput
          type="text" 
          [formControl]="emailControl"
          [valid]="emailControl.valid"
          (blur)="trackEvent('type email')"
          placeholder=""
        >
      </u-group>
    </div>
  </div>
  <div class="routes-export__actions">
    <button
      uButton
      class="routes-export__actions-download"
      [disabled]="disableDelete"
      (click)="download()"
    >
      {{config.dictionary.download | translate}}
    </button>
    <button
      uButton
      [disabled]="!emailControl.valid"
      (click)="sendEmail()"
    >
      {{config.dictionary.send | translate}}
    </button>
    <span
      *ngIf="disableDelete"
      class="routes-export__actions-error-message"
      [translate]="config.dictionary.cannotDownload"
      [translateParams]="{ amount: downloadMaxCount || 0 }"
    ></span>
  </div>
</div>
