import { Component, Input, ViewEncapsulation, ViewChild, OnChanges, HostBinding } from '@angular/core';
import * as moment from 'moment';
import { escape } from 'lodash';
import {
  UHereMapLatLng,
  UHereMapMarkerConfig,
  UHereMapPathType,
  UHereMapPathWaypoint,
  UHereMapPointCenterConfig,
  UHereMapBounds
} from '@shift/ulib';

import { environment } from '@environments/environment';
import { mapConfig } from '@app/shared/configs';
import { AppConstants } from '@app/shared/constants';
import { AuthDataService } from '@app/auth/services';
import { MonitoringDashboardDataService } from '@app/monitoring-dashboard/services';
import { MonitoringDashboardMapPath, MonitoringDashboardPathLive, MonitoringDashboardSourceType, MonitoringDashboardStationType } from '@app/monitoring-dashboard/models';
import { monitoringDashboardConfig } from '@app/monitoring-dashboard/configs';

@Component({
  selector: 'app-monitoring-dashboard-map',
  templateUrl: './monitoring-dashboard-map.component.html',
  styleUrls: [ './monitoring-dashboard-map.component.scss', './monitoring-dashboard-map.component.rtl.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class MonitoringDashboardMapComponent implements OnChanges {
  @Input() ride: any;
  @Input() traffic: boolean;
  @Input() stationCenter: {
    position: UHereMapLatLng;
    config: UHereMapPointCenterConfig;
  };
  @Input() bounds: UHereMapBounds;

  @ViewChild('map', { static: false }) map: any;

  @HostBinding('class') hostClasses: string = 'monitoring-dashboard-map';

  coords: UHereMapLatLng = mapConfig.coords;
  zoom: number = monitoringDashboardConfig.mapRide.zoom;

  constructor(
    private authDataService: AuthDataService,
    public monitoringDashboardDataService: MonitoringDashboardDataService
  ) {}

  ngOnChanges(changes: any) {
    if (changes.ride && this.ride && this.ride.details) {
      let markerStationNumber = 0;
      let markerDestinationNumber = 0;

      const points = this.ride.details.stations;

      const supervisor = this.ride.details.supervisor;

      const destinations = points.filter(point => point.type === MonitoringDashboardStationType.Destination);

      const userInfo = this.authDataService.userInfo();
      const markersByCustomerType = mapConfig.markers[userInfo.customer.type] || mapConfig.markers.default;
      const markersByEnvType = markersByCustomerType[environment.config.environmentType] || markersByCustomerType.default;

      const markers: UHereMapMarkerConfig[] = points
        .map(point => {
          const markerByType = {
            [MonitoringDashboardStationType.Station]: supervisor && supervisor.sourceStationId === point.id ? markersByEnvType.supervisor : markersByEnvType.station,
            [MonitoringDashboardStationType.Destination]: destinations.length > 1 ? markersByEnvType.multipleDestination : markersByEnvType.destination,
            [MonitoringDashboardStationType.Accompany]: markersByEnvType.accompany
          };

          let labelContent: number = null;

          switch (point.type) {
            case MonitoringDashboardStationType.Station: {
              markerStationNumber += 1;
              labelContent = markerStationNumber;

              break;
            }

            case MonitoringDashboardStationType.Destination: {
              markerDestinationNumber += 1;
              labelContent = markerDestinationNumber;

              break;
            }
          }

          return {
            label: point.type === MonitoringDashboardStationType.Destination && destinations.length <= 1 ? null : {
              content: labelContent,
              style: markerByType[point.type].label.style
            },
            content: escape(point.address),
            position: {
              lat: point.latitude,
              lng: point.longitude
            },
            icon: markerByType[point.type].icon
          };
        });

      this.monitoringDashboardDataService.markersSet(markers);

      let waypointsAccompanyFirst: UHereMapPathWaypoint[] = [];
      const pointFirst = points[0];
      const pointSecond = points[1];

      if (points && points.length > 1 && pointFirst && pointFirst.type === MonitoringDashboardStationType.Accompany && pointSecond) {
        waypointsAccompanyFirst = [
          {
            lat: pointFirst.latitude,
            lng: pointFirst.longitude,
            stopover: true
          },
          {
            lat: pointSecond.latitude,
            lng: pointSecond.longitude,
            stopover: true
          }
        ];
      }

      let waypointsAccompanyLast: UHereMapPathWaypoint[] = [];
      const pointPenult = points[points.length - 2];
      const pointLast = points[points.length - 1];

      if (points && points.length > 1 && pointPenult && pointLast && pointLast.type === MonitoringDashboardStationType.Accompany) {
        waypointsAccompanyLast = [
          {
            lat: pointPenult.latitude,
            lng: pointPenult.longitude,
            stopover: true
          },
          {
            lat: pointLast.latitude,
            lng: pointLast.longitude,
            stopover: true
          }
        ];
      }

      let paths: MonitoringDashboardMapPath[] = [];

      const waypoints = (this.ride.details.waypoints || []).map(waypoint => ({
        lat: waypoint.latitude,
        lng: waypoint.longitude,
        stopover: waypoint.stopover
      }));

      if (this.ride.details.encodedPolylineJson) {
        paths = [ { config: { type: UHereMapPathType.Polylines, color: mapConfig.pathColors.main, polylines: JSON.parse(this.ride.details.encodedPolylineJson), waypoints } }, ...paths ];
      } else if (waypoints.length) {
        paths = [ { config: { type: UHereMapPathType.Waypoints, color: mapConfig.pathColors.main, waypoints, avoidTollRoads: !this.ride.details.useTollRoads } }, ...paths ];
      }

      if (waypointsAccompanyFirst.length) {
        paths = [ { config: { type: UHereMapPathType.Waypoints, color: mapConfig.pathColors.accompany, waypoints: waypointsAccompanyFirst, avoidTollRoads: !this.ride.details.useTollRoads } }, ...paths ];
      }

      if (waypointsAccompanyLast.length) {
        paths = [ { config: { type: UHereMapPathType.Waypoints, color: mapConfig.pathColors.accompany, waypoints: waypointsAccompanyLast, avoidTollRoads: !this.ride.details.useTollRoads } }, ...paths ];
      }

      const monitoredPathsMarkers = [];

      if (this.ride.details.monitoredPaths) {
        const monitoredPaths = [];

        this.ride.details.monitoredPaths
          .filter(path => ![ MonitoringDashboardSourceType.Passenger ].includes(path.sourceType))
          .forEach(path => {
            monitoredPaths.push({
              config: {
                type: UHereMapPathType.Points,
                color: monitoringDashboardConfig.mapRide.monitoredPathColors[path.sourceType],
                points: path.coordinates.map(coordinates => ({
                  lat: coordinates.latitude,
                  lng: coordinates.longitude
                }))
              },
              sourceType: path.sourceType
            });

            monitoredPathsMarkers.push(
              path.coordinates
                .map(coordinates => ({
                  position: {
                    lat: coordinates.latitude,
                    lng: coordinates.longitude
                  },
                  icon: {
                    color: monitoringDashboardConfig.mapRide.monitoredPathMarkerColors[path.sourceType]
                  },
                  content: moment.utc(coordinates.locatedAt).local().format(AppConstants.TIME_FORMAT)
                }))
                .reduce((acc, item, index) =>
                  index === 0 || index === path.coordinates.length - 1 || !acc.some(obj => obj.content === item.content) ? [ ...acc, item ] : acc,
                []
                )
            );
          });

        paths = [ ...paths, ...monitoredPaths ];
      }

      this.monitoringDashboardDataService.pathsSet(paths);
      this.monitoringDashboardDataService.monitoredPathsMarkersSet(monitoredPathsMarkers);
    }
  }

  trackBySourceType(index: number, item: MonitoringDashboardPathLive) {
    return item.sourceType;
  }
}
