import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { RideOrdersFacade } from '@app/ride-orders/state/facades';
import { RideOrdersEffects } from '@app/ride-orders/state/effects';
import * as rideOrdersReducers from '@app/ride-orders/state/reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(rideOrdersReducers.FEATURE_KEY, rideOrdersReducers.reducers),
    EffectsModule.forFeature([ RideOrdersEffects ])
  ],
  providers: [ RideOrdersFacade ]
})
export class RideOrdersStateModule {}
