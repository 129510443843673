import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ValidationService } from '@app/shared/services';

@Injectable()
export class ShuttleCompanyContractsRatesService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private validationService: ValidationService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initRatesValidators(form: UntypedFormGroup) {
    const nightRateControls = (form.get('nightRate') as UntypedFormGroup).controls;
    const weekendRateControls = (form.get('weekendRate') as UntypedFormGroup).controls;

    const nightTimeValidators = [
      this.validationService.conditionalValidator(() => nightRateControls.isActive.value, Validators.required)
    ];

    nightRateControls.startTime.setValidators(nightTimeValidators);
    nightRateControls.endTime.setValidators(nightTimeValidators);

    if (!weekendRateControls.isAuto.value) {
      const weekendTimeValidators = [
        this.validationService.conditionalValidator(() => weekendRateControls.isActive.value, Validators.required),
        this.validationService.conditionalValidator(
          () => weekendRateControls.startDay.value === weekendRateControls.endDay.value,
          () => this.validationService.startTimeBeforeEndTime(
            weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl
          )
        )
      ];

      weekendRateControls.startTime.setValidators(weekendTimeValidators);
      weekendRateControls.endTime.setValidators(weekendTimeValidators);
    }

    this.unsubscribe.next();

    this.onRatesChange(form);
  }

  removeWeekendRateValidators(form: UntypedFormGroup) {
    const weekendRateControls = (form.get('weekendRate') as UntypedFormGroup).controls;

    weekendRateControls.startTime.clearValidators();
    weekendRateControls.endTime.clearValidators();

    this.updateTimeValidators(weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl);
  }

  onRatesChange(form: UntypedFormGroup) {
    const nightRateControls = (form.get('nightRate') as UntypedFormGroup).controls;
    const weekendRateControls = (form.get('weekendRate') as UntypedFormGroup).controls;

    nightRateControls.isActive.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(nightRateControls.startTime as UntypedFormControl, nightRateControls.endTime as UntypedFormControl));

    weekendRateControls.isActive.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl));

    weekendRateControls.startDay.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl));

    weekendRateControls.endDay.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateTimeValidators(weekendRateControls.startTime as UntypedFormControl, weekendRateControls.endTime as UntypedFormControl));

    weekendRateControls.startTime.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe(() => weekendRateControls.endTime.updateValueAndValidity());

    weekendRateControls.endTime.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe(() => weekendRateControls.startTime.updateValueAndValidity());
  }

  updateTimeValidators(startTime: UntypedFormControl, endTime: UntypedFormControl) {
    startTime.updateValueAndValidity();
    endTime.updateValueAndValidity();
  }
}
