import { HeaderMenuIconsTemplate } from '@app/shared/models';
import { BuilderBuildMode } from '@app/builder/models';

const splitPanels = {
  routes: {
    size: 30,
    minSize: 200,
    panelName: 'builderRoutes'
  },
  main: {
    size: 70,
    minSize: 510,
    panelName: 'builderMain'
  }
};

export const builderDefaultComponentConfig = {
  splitPanels,
  default: {
    buildMode: {
      [BuilderBuildMode.RideOrders]: {
        headerMenuIconsTemplate: HeaderMenuIconsTemplate.RideOrders
      },
      [BuilderBuildMode.Routes]: {
        headerMenuIconsTemplate: HeaderMenuIconsTemplate.BuilderDefaultRoutes
      },
      [BuilderBuildMode.RouteTemplates]: {
        headerMenuIconsTemplate: HeaderMenuIconsTemplate.BuilderDefaultTemplates
      }
    }
  }
};
