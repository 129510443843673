import { AddEditModalButton, AddEditModalAction } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.addEditModal';

const defaultButtons: AddEditModalButton[] = [
  { name: `${DICTIONARY_PATH}.delete`, action: AddEditModalAction.Delete, clear: true },
  { name: `${DICTIONARY_PATH}.saveAndClose`, action: AddEditModalAction.Submit, clear: false }
];

export const addEditModalConfig = {
  defaultButtons,
  dictionary: {
    closeConfirm: `${DICTIONARY_PATH}.closeConfirm`,
    error: 'general.error',
    submitSuccess: 'general.successful',
    yes: 'general.yes',
    no: 'general.no',
    invalidForm: 'general.invalidForm'
  }
};
