const DICTIONARY_PATH: string = 'routes.changeTimeTracking';

export const routesChangeTimeTrackingComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    titleCommander: `${DICTIONARY_PATH}.titleCommander`,
    start: `${DICTIONARY_PATH}.start`,
    end: `${DICTIONARY_PATH}.end`,
    comments: `${DICTIONARY_PATH}.comments`,
    saveAndClose: 'general.saveAndClose'
  }
};
