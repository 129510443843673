import { UInputCitiesCityLocation, URangePreset } from '@shift/ulib';

import { ApiBaseResponse, DaysOfWeek } from '@app/shared/models';
import { ActivityLive } from '@app/activities/models';
import { RouteLockState } from '@app/route-lock-state/models';
import { RouteDailyRideStatus, RoutesDailyFilterType } from './routes-daily.models';

export enum RoutesWeeklyTableMode {
  Accompany,
  Driver
}

export enum RoutesViewTypeMode {
  DailyView = 'daily-view',
  WeeklyView = 'weekly-view'
}

export enum RoutesThreeDotsPopoverItemAction {
  NewFine = 'new-fine',
  RestoreRide = 'restore-ride',
  SplitRoute = 'split-route',
  TempComment = 'temp-comment',
  DeleteRoute = 'delete-route',
  CreateRouteTemplate = 'create-route-template',
  MovePassengers = 'move-passengers',
  CancelRide = 'cancel-ride'
}

export enum RoutesDeletePeriod {
  Whole = 1,
  FromDate = 2
}

export enum RoutesCancelRideClauseType {
  Manual = 'manual',
  NoCharge = 'noCharge',
  AccordingToContract = 'accordingToContract'
}

export enum RoutesExportType {
  Pdf,
  Excel
}

export enum RoutesExportOutputAction {
  Email,
  Download
}

export enum RouteType {
  Regular,
  Shuttle,
  HopOnHopOff,
  Fixed,
  Shifts,
  Special,
  Delivery
}

export enum RouteSaveStatus {
  Successful = 'successful',
  Failed = 'failed'
}

export enum RouteDirectionType {
  Forward,
  Backward
}

export enum RouteDirection {
  Forward = 120,
  Backward = 121,
  BackwardAndForward = 238
}

export enum RouteRecalculateMode {
  KeepCurrent,
  Automatic,
  ManualStations,
  FullManual
}

export enum RoutesCancellationBillingType {
  NoCharge,
  CancellationClause,
  Manual,
  AccordingToContract
}

export enum RoutesCancellationChargeType {
  Fixed,
  Percentage,
  NoCharge
}

export enum RouteChangeReportMode {
  None = 'None',
  BackgroundEmail = 'BackgroundEmail',
  EditableEmail = 'EditableEmail'
}

export enum RouteRideSupervisorLevel {
  Low,
  Medium,
  High
}

export enum RoutesViewType {
  Daily = 1,
  Weekly = 2
}

export enum RoutesTransportationAiSuggestionsType {
  Vehicle = 'VehicleAssignment',
  Driver = 'DriverAssignment',
  ShuttleCompany = 'ShuttleCompanyAssignment'
}

export enum RoutesAiTransportationSuggestionsStatusChangedAiJobState {
  NotStarted,
  Running,
  Completed,
  Failed,
  Updated
}

export interface RoutesThreeDotsDeleteAction {
  routeId: number;
  activeRoute: any;
  mode: RoutesViewTypeMode.DailyView | RoutesViewTypeMode.WeeklyView;
}

export interface RoutesPeriodsDefaultData {
  dates: string[];
  dateFrom: string;
  dateTo: string;
  type: URangePreset;
  availablePresets: URangePreset[];
  checkDaysActive: DaysOfWeek[];
  checkDaysAvailable: DaysOfWeek[];
  comment: string;
}

export interface RoutesExportExcelBody {
  dateFrom: string;
  dateTo: string;
  routeIds: number[];
  receiver: string;
  outputAction: RoutesExportOutputAction;
}

export interface RoutesExportPdfBody {
  dateFrom: string;
  dateTo: string;
  routeIds: number[];
  receiver: string;
  outputAction: RoutesExportOutputAction;
  memberId?: number;
  customerId?: number;
  filterPassengerIds?: number[];
}

export interface RoutesRidePassengerDetails {
  id: number;
  name: string;
  amount: number;
  status: string;
  address: string;
  checkIn: boolean;
  mobile: string;
}

export interface RoutesInitalData {
  classes?: {
    grade: number;
    minDistanceToSchool: number;
    id: number;
    name: string;
  }[];
  schools?: {
    id: number;
    customerId: number;
    name: string;
    address: string;
    latitude: number;
    longitude: number;
    institutionCode: string;
  }[];
  departments?: {
    id: number;
    name: string;
  }[];
  tags?: {
    id: number;
    name: string;
  }[];
  branches?: {
    id: number;
    name: string;
  }[];
  shifts?: {
    id: number;
    name: string;
    days: {
      [key: string]: {
        startTime: string;
        endTime: string;
      };
    };
  }[];
  costTypes?: {
    id: number;
    name: string;
  }[];
}

export interface RoutesRidePassenger {
  id?: number;
  name: string;
  amount: number;
  status: string;
  address: string;
  checkIn?: boolean;
  mobile?: string;
}

export interface RoutesEntityList extends ApiBaseResponse {
  value: {
    value: number;
    name: string;
  }[];
}

export interface RoutesCarsList extends ApiBaseResponse {
  value: {
    value: number;
    name: string;
    carTypeKeyword: string;
    seatsCount: number;
  }[];
}

export interface RoutesCancellationData {
  chargeType: RoutesCancellationChargeType;
  chargePercentage: number;
  chargeAmount: number;
  clauseName: string;
  billingType: RoutesCancellationBillingType;
}

export interface RoutesCancellationPolicesParamsPeriod {
  dateFrom: string;
  dateTo: string;
  days: DaysOfWeek[];
}

export interface RoutesCancellationPolicesParams {
  routeId: number;
  rideStartDateTime: string;
  periods: RoutesCancellationPolicesParamsPeriod[];
}

export interface RoutesCancellationPolicy {
  policyId: number;
  name: string;
  chargeType: RoutesCancellationChargeType;
  amount: number;
  minutesBeforeRideStart: number;
  weekDays: number;
}

export interface RoutesCancellationPolices {
  defaultPolicyId: number;
  cancellationPolicies: RoutesCancellationPolicy[];
}

export interface RoutesDeleteParams {
  routeIds: number[];
  from: string;
  permanently: boolean;
}

export interface RoutesCarTypesBody {
  routeId: number;
  dateFrom: string;
  dateTo: string;
  days: DaysOfWeek[];
}

export interface RoutesCarType {
  id: number;
  name: string;
}

export interface RoutesExportAgendaExcelParams {
  isNextYear?: boolean;
  branchIds: number[];
  classTypeIds: number[];
  departmentIds: number[];
  passengerIds: number[];
  schoolIds: number[];
  shiftIds: number[];
  columns: {
    field: string;
    alias: string;
  }[];
  date: string;
  direction: RouteDirection;
  filterType: RoutesDailyFilterType;
  routeIds: number[];
  searchText: string;
  tagIds: number[];
  bidNumbers: string[];
  cities: UInputCitiesCityLocation[];
  masterSubCustomerIds: number[];
}

export interface RouteRideSupervisorsParams {
  routeId: number;
  activeDate: string;
  customerId?: number;
}

export interface RouteRideSupervisor {
  passengerId: number;
  name: string;
  level: RouteRideSupervisorLevel;
}

export interface RouteRideSupervisors {
  onRide: RouteRideSupervisor[];
  others: RouteRideSupervisor[];
}

export interface RoutesRidePassengersCustomer {
  id: number;
  name: string;
  passengersAmount: number;
}

export interface RoutesRidePassengers extends ApiBaseResponse {
  value: {
    id: number;
    name: string;
  }[];
}

export interface RoutesCustomerPassenger {
  id: number;
  name: string;
}

export interface RouteNoteBase {
  noteId: number;
  routeId: number;
}

export interface RouteNoteAdded extends RouteNoteBase {
  createdByMemberId: number;
}

export interface RouteNoteUpdated extends RouteNoteBase {
  lastModifyByMemberId: number;
}

export interface RouteNoteMarkedAsDone extends RouteNoteBase {
  anyNote: boolean;
}

export interface RoutesUpdateRideStatus {
  id: number;
  status: RouteDailyRideStatus;
}

export interface RoutesUpdateApprovals {
  routeId: number;
  actualApprovalsCount: number;
  isRejected: boolean;
  memberId: number;
}

export interface RoutesBaseActiveRide {
  routeId: number;
  routeStartDate: string;
  routeEndDate: string;
  days: number[];
  code: number;
  name: string;
  isOwnedBySc: boolean;
}

export interface RoutesRestoreActiveRide extends RoutesBaseActiveRide {
  totalPassengers: number;
}

export interface RoutesCancelActiveRide extends RoutesBaseActiveRide {
  rideStartDateTime: string;
}

export interface RoutesOptionsCheckbox {
  name: string;
  checked: boolean;
  value: RoutesTransportationAiSuggestionsType;
}

export interface RoutesTransportationAiSuggestionsItemVehicle {
  carId: number;
  actualCarTypeName: string;
  actualCarTypeId: number;
  carNumber: string;
}

export interface RoutesTransportationAiSuggestionsItemShuttleCompany {
  name: string;
  shuttleCompanyId: number;
}

export interface RoutesTransportationAiSuggestionsItemDriver {
  driverId: number;
  fullName: string;
}

export interface RoutesTransportationAiSuggestionsItem {
  rideId: number;
  vehicle: RoutesTransportationAiSuggestionsItemVehicle;
  shuttleCompany: RoutesTransportationAiSuggestionsItemShuttleCompany;
  driver: RoutesTransportationAiSuggestionsItemDriver;
}

export interface RoutesAiTransportationSuggestionsStatusChanged {
  aiJobState: RoutesAiTransportationSuggestionsStatusChangedAiJobState;
  sessionId: string;
}

export interface RoutesAiRouteUpdated {
  sessionId: string;
  routeId: number;
}

export interface RouteRowWithActivities {
  id: number;
  saveStatus: RouteSaveStatus;
  locked: boolean;
  activities: ActivityLive[];
  lockState: RouteLockState;
}
