import { Component, OnInit, OnDestroy, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import {
  UButtonModule,
  UCommonModule,
  UDateRangePresetsDataAction,
  UDateRangePresetsMode,
  UMultiselectItem
} from '@shift/ulib';

import { AppLanguage } from '@app/shared/models';
import {
  LocalizationService,
  TrackingService
} from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { appConfig } from '@app/shared/configs';
import { RouteAuditService } from '@app/route-audit/services';
import { EmailsDataService, EmailsService } from '@app/emails/services';
import { EmailsSendShuttleCompaniesChangesDatesType, EmailsSendShuttleCompaniesChangesType } from '@app/emails/models';
import { emailsSendShuttleCompaniesChangesComponentConfig } from './emails-send-shuttle-companies-changes.component.config';

@Component({
  selector: 'app-emails-send-shuttle-companies-changes',
  templateUrl: './emails-send-shuttle-companies-changes.component.html',
  styleUrls: [ './emails-send-shuttle-companies-changes.component.scss', './emails-send-shuttle-companies-changes.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UCommonModule,
    UButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    NgClass
  ]
})
export class EmailsSendShuttleCompaniesChangesComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses: string = 'emails-send-shuttle-companies-changes';

  private unsubscribe: Subject<void> = new Subject();

  shuttleCompanies: UMultiselectItem[] = [];
  changeSubjects: UMultiselectItem[] = [];
  filtersForm: UntypedFormGroup;
  showAvailabilityIcon: boolean = false;
  lastChanges: boolean = false;
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = appConfig.defaultLanguage;
  uDateRangePresetsMode = UDateRangePresetsMode;
  config = cloneDeep(emailsSendShuttleCompaniesChangesComponentConfig);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private emailsService: EmailsService,
    private emailsDataService: EmailsDataService,
    private trackingService: TrackingService,
    private bsModalRef: BsModalRef,
    private routeAuditService: RouteAuditService,
    public localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.track('click on send changes');
    this.getChangesTypes();
    this.updateFiltersForm();
    this.getShuttleCompanies();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private track(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  private updateFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      shuttleCompanies: [ [], Validators.required ],
      datesFiltrationType: [ EmailsSendShuttleCompaniesChangesDatesType.CreationDate, Validators.required ],
      dates: [ [], Validators.required ],
      changeType:  [ EmailsSendShuttleCompaniesChangesType.All, Validators.required ],
      changeSubjects:  [ [], Validators.required ],
      openForEdit: [ false ],
      onlyLastChange: [ false ]
    });

    this.onFiltersFormValueChanges();
  }

  private onFiltersFormValueChanges() {
    this.filtersForm.get('shuttleCompanies')
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => value && this.track('click on select SC'));

    this.filtersForm.get('changeType')
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => value && this.track('click on select change type'));

    this.filtersForm.get('changeSubjects')
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => value && this.track('click on select change subjects'));
  }

  private getChangesTypes() {
    this.routeAuditService.getRouteAuditChangesTypes()
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(res => this.changeSubjects = res.map(obj => ({ value: obj.id, name: obj.name })));
  }

  private getShuttleCompanies() {
    this.emailsService.getShuttleCompanies()
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(shuttleCompanies => {
        this.shuttleCompanies = shuttleCompanies.map(shuttleCompany => ({ value: shuttleCompany.id, name: shuttleCompany.name, notAvailable: shuttleCompany.notAvailable }));
        this.showAvailabilityIcon = this.shuttleCompanies.some(shuttleCompany => shuttleCompany.notAvailable);
      });
  }

  private datesEqual(firstDate: string, secondDate: string): boolean {
    return moment(firstDate, AppConstants.DATE_FORMAT_BASE_LINE).isSame(moment(secondDate, AppConstants.DATE_FORMAT_BASE_LINE));
  }

  changeDatesAction(dateRangeData: UDateRangePresetsDataAction) {
    const dates = this.filtersForm.get('dates').value;

    if (dates.from && !this.datesEqual(dateRangeData.from, dates.from)) {
      this.track('click on select start date');
    }

    if (dates.to && !this.datesEqual(dateRangeData.to, dates.to)) {
      this.track('click on select end date');
    }

    this.filtersForm.get('dates').patchValue(dateRangeData);
  }

  sendEmails() {
    this.emailsDataService.sendChangesFromBulkSend(this.filtersForm.value);

    this.bsModalRef.hide();
  }

  closeClick() {
    this.bsModalRef.hide();
  }

  toggleLastChanges(action: boolean) {
    this.track(action ? 'click on include only last changes' : 'click on include all changes');

    this.lastChanges = action;

    const onlyLastChangeForm = this.filtersForm.get('onlyLastChange');

    onlyLastChangeForm.patchValue(action);
  }
}
