import { inject, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { UPopupService } from '@shift/ulib';
import { Subject } from 'rxjs';

import { routesConfig } from '@app/routes/configs';
import { TrackingService } from '@app/shared/services';
import { RouteTemplatesService } from '@app/route-templates/services';

@Injectable()
export class RoutesCommonService {
  private readonly uPopupService = inject(UPopupService);
  private readonly trackingService = inject(TrackingService);
  private readonly routeTemplatesService = inject(RouteTemplatesService);

  private readonly changedRoute: Subject<void> = new Subject();

  readonly changedRoute$ = this.changedRoute.asObservable();

  createRouteTemplate(routeId: number, activeDate: string) {
    this.uPopupService.showMessage(
      {
        message: `${routesConfig.dictionaryPath}.routeTemplate.message`,
        yes: `${routesConfig.dictionaryPath}.routeTemplate.saveAndClose`,
        no: `${routesConfig.dictionaryPath}.routeTemplate.cancel`
      },
      () => {
        this.trackingService.track(`[${routesConfig.trackingId}} > 3 dot menu] - click on edit template`);
        this.routeTemplatesService.createTemplateFromRoute({ routeId, activeDate }).pipe(first()).subscribe();
      }
    );
  }

  updateChangedRoute() {
    this.changedRoute.next();
  }
}
