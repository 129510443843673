const DICTIONARY_PATH: string = 'demands.routesPopup';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  subTitle: `${DICTIONARY_PATH}.subTitle`,
  actions: {
    cancel: `${DICTIONARY_PATH}.actions.cancel`,
    save: `${DICTIONARY_PATH}.actions.save`,
    remove: `${DICTIONARY_PATH}.actions.remove`,
    keep: `${DICTIONARY_PATH}.actions.keep`
  },
  directions: {
    forward: `${DICTIONARY_PATH}.table.directions.forward`,
    backward: `${DICTIONARY_PATH}.table.directions.backward`
  },
  table: {
    passenger: `${DICTIONARY_PATH}.table.passenger`,
    routeCode: `${DICTIONARY_PATH}.table.routeCode`,
    routeName: `${DICTIONARY_PATH}.table.routeName`,
    direction: `${DICTIONARY_PATH}.table.direction`,
    date: `${DICTIONARY_PATH}.table.date`,
    hours: `${DICTIONARY_PATH}.table.hours`,
    nextDay: `${DICTIONARY_PATH}.table.nextDay`
  }
};

export const demandsRoutesPopupComponentConfig = {
  dictionary,
  columns: [
    { prop: 'passengerName', name: dictionary.table.passenger, minWidth: 128, maxWidth: 128, hideColumn: false, resizeable: false },
    { prop: 'code', name: dictionary.table.routeCode, minWidth: 98, maxWidth: 98, hideColumn: false, resizeable: false },
    { prop: 'name', name: dictionary.table.routeName, minWidth: 170, maxWidth: 170, custom: false, resizeable: false },
    { prop: 'direction', name: dictionary.table.direction, minWidth: 91, maxWidth: 91, cellTemplateName: 'direction', custom: false, resizeable: false },
    { prop: 'date', name: dictionary.table.date, minWidth: 98, maxWidth: 119, cellTemplateName: 'date', resizeable: false },
    { prop: 'hours', name: dictionary.table.hours, minWidth: 174, maxWidth: 174, cellTemplateName: 'hours', resizeable: false, hideDivider: true },
    { prop: 'actions', name: '', hideable: false, minWidth: 128, maxWidth: 128, cellTemplateName: 'actionsButton', hideDivider: true, resizeable: false }
  ]
};
