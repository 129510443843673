import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as moment from 'moment';

import { AppConstants } from '@app/shared/constants';
import { appConfig } from '@app/shared/configs';
import { RouteSaveStatus } from '@app/routes/models';
import {
  ActivitiesAllParams,
  ActivitiesFilters,
  ActivitiesLiveParams,
  Activity,
  ActivityAdditionalStatus,
  ActivityFiltersStatusType,
  ActivityFiltersType,
  ActivityLive,
  ActivityType,
  ActivityUpdate,
  ActivityUpdateStatus
} from '@app/activities/models';
import { ActivitiesService } from '@app/activities/services/activities.service';
import { ActivitiesHubService } from '@app/activities/services/activities-hub.service';
import { activitiesConfig } from '@app/activities/configs';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesDataService {
  private activitiesFilters: BehaviorSubject<ActivitiesFilters> = new BehaviorSubject({
    statusTypes: [ ActivityFiltersStatusType.Active ],
    types: activitiesConfig.filters.options.types.map(item => item.value as ActivityFiltersType),
    saveTypes: activitiesConfig.filters.options.saveTypes.map(item => item.value as ActivityAdditionalStatus)
  });
  private showDate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(activitiesConfig.defaultDateVisibility);
  private activeDate: BehaviorSubject<string> = new BehaviorSubject(null);
  private activities: BehaviorSubject<Activity[]> = new BehaviorSubject([]);
  private activitiesUpdate: Subject<ActivityUpdate[]> = new Subject();
  private activitiesLive: BehaviorSubject<ActivityLive[]> = new BehaviorSubject([]);
  private activeRouteId: BehaviorSubject<number> = new BehaviorSubject(null);
  private activitiesParams: BehaviorSubject<ActivitiesAllParams> = new BehaviorSubject(null);
  private activitiesLiveParams: BehaviorSubject<ActivitiesLiveParams> = new BehaviorSubject(null);
  private memberId: number = null;

  showDate$: Observable<boolean> = this.showDate.asObservable();
  activeDate$: Observable<string> = this.activeDate.asObservable();
  activitiesUpdate$: Observable<ActivityUpdate[]> = this.activitiesUpdate.asObservable();
  activitiesLive$: Observable<ActivityLive[]> = this.activitiesLive.asObservable();
  activitiesLiveCounter$: Observable<number> = this.activitiesLive$.pipe(map(activities => this.calculateActivitiesCounter(activities)));
  activeRouteId$: Observable<number> = this.activeRouteId.asObservable();
  subscriptions: Subscription = new Subscription();
  activitiesFilters$: Observable<ActivitiesFilters> = this.activitiesFilters.asObservable();
  activitiesWithFilters$ = combineLatest([ this.activities, this.activitiesFilters$ ])
    .pipe(
      map(([ activities, activitiesFilters ]) => {
        if (activitiesFilters.statusTypes.length) {
          const isActive = activitiesFilters.statusTypes.includes(ActivityFiltersStatusType.Active);
          const isArchived = activitiesFilters.statusTypes.includes(ActivityFiltersStatusType.Archived);

          if (!isActive || !isArchived) {
            activities = activities.filter(activity => !isActive ? activity.isArchived : !isArchived ? !activity.isArchived : activity);
          }
        }

        if (activitiesFilters.types.length) {
          activities = activities.filter(activity =>
            activitiesFilters.types
              .reduce((acc, type) => [ ...acc, ...activitiesConfig.filters.types[type] ], [])
              .includes(activity.type === ActivityType.ReOptimization ? activity.additionalData.type : activity.type)
          );
        }

        if (activitiesFilters.saveTypes.length) {
          activities = activities.filter(activity => {
            if (activitiesConfig.filters.availableSaveTypes.includes(activity.type)) {
              const hasStatusOrChangeStatus = !!activity.additionalData && !!(activity.additionalData.status || activity.additionalData.changeStatus);

              return hasStatusOrChangeStatus &&
                (activitiesFilters.saveTypes.includes(activity.additionalData.status) || activitiesFilters.saveTypes.includes(activity.additionalData.changeStatus));
            }

            if (activitiesConfig.filters.availableSaveStatusTypes[activity.type]) {
              return activitiesFilters.saveTypes.includes(activitiesConfig.filters.availableSaveStatusTypes[activity.type]);
            }

            return true;
          });
        }

        return activities;
      })
    );

  constructor(
    private activitiesService: ActivitiesService,
    private activitiesHubService: ActivitiesHubService
  ) {}

  initActivitiesHub() {
    this.activitiesHubService.init();
    this.activitiesHubService.start();

    this.onActivitiesUpdate();
  }

  destroyActivitiesHub() {
    this.activitiesHubService.stop();
    this.subscriptions.unsubscribe();
  }

  onActivitiesUpdate() {
    this.subscriptions.add(
      this.activitiesHubService
        .onActivitiesUpdate()
        .subscribe(activitiesUpdate => {
          const activities = this.activitiesLiveParams ?
            activitiesUpdate
              .filter(activity => {
                if (!activity.startDate || !activity.endDate) {
                  return true;
                }

                const activitiesLiveStartDate = moment(this.activitiesLiveParams.value.startDate).startOf('day');
                const activitiesLiveEndDate = moment(this.activitiesLiveParams.value.endDate).startOf('day');
                const activityStartDate = moment(activity.startDate).startOf('day');
                const activityEndDate = moment(activity.endDate).startOf('day');

                return (
                  activitiesLiveStartDate.isBetween(activityStartDate, activityEndDate, null, '[]') ||
                  activitiesLiveEndDate.isBetween(activityStartDate, activityEndDate, null, '[]')
                ) || (
                  activityStartDate.isBetween(activitiesLiveStartDate, activitiesLiveEndDate, null, '[]') ||
                  activityEndDate.isBetween(activitiesLiveStartDate, activitiesLiveEndDate, null, '[]')
                );
              })
              .filter(activity => activity.status === ActivityUpdateStatus.Read ? activity.memberId === this.memberId : true)
            : activitiesUpdate;

          this.activitiesUpdate.next(activities);
          this.updateActivitiesLive(activities);
        })
    );
  }

  resetShowDate() {
    this.setShowDate(activitiesConfig.defaultDateVisibility);
  }

  setShowDate(value: boolean) {
    this.showDate.next(value);
  }

  calculateActivitiesCounter(activities: ActivityLive[] = []) {
    const counter = activities && activities.filter(activity => !activity.isRead).length;

    return counter >= 100 ? appConfig.activities.maxDisplayCounter : counter;
  }

  loadActivitiesLive(params: ActivitiesLiveParams) {
    this.activitiesLiveParams.next(params);

    this.activitiesService.getLiveActivities(params)
      .pipe(first())
      .subscribe(activitiesLive => this.activitiesLive.next(activitiesLive));
  }

  updateActivitiesLive(activitiesUpdate: ActivityUpdate[]) {
    this.activitiesLive.next(this.parseActivities(this.activitiesLive.value, activitiesUpdate));
  }

  getRouteSaveStatus(saveStatus: string, routeActivitiesUpdate: ActivityUpdate[]): RouteSaveStatus {
    const routeActivitiesUpdateState = routeActivitiesUpdate.filter(activity => activitiesConfig.saveStatusTypes.includes(activity.type) && activity.status === ActivityUpdateStatus.New);

    return routeActivitiesUpdateState.length ? activitiesConfig.saveStatusByType[routeActivitiesUpdateState[routeActivitiesUpdateState.length - 1].type] : saveStatus;
  }

  isLockedRoute(currentLockState: boolean, routeActivitiesUpdate: ActivityUpdate[]): boolean {
    const routeActivitiesUpdateState = routeActivitiesUpdate.filter(activity => activitiesConfig.lockedTypes.includes(activity.type));

    return routeActivitiesUpdateState.length ? activitiesConfig.lockedByType[routeActivitiesUpdateState[routeActivitiesUpdateState.length - 1].type] : currentLockState;
  }

  parseActivities(activitiesLive: ActivityLive[], activitiesUpdate: ActivityUpdate[]): ActivityLive[] {
    const activitiesUpdateState = activitiesUpdate.filter(activity => activitiesConfig.counterTypes.includes(activity.type));

    return [
      ...(activitiesLive || [])
        .filter(activityLive =>
          !activitiesUpdateState.some(activity => activity.activityId === activityLive.activityId && activity.status === ActivityUpdateStatus.Delete)
        )
        .map(activityLive => {
          const activityUpdated = activitiesUpdateState
            .filter(activity => activity.status === ActivityUpdateStatus.Read || activity.status === ActivityUpdateStatus.Update)
            .find(activity => activity.activityId === activityLive.activityId);

          return {
            ...activityLive,
            isRead: activityUpdated ? activityUpdated.status === ActivityUpdateStatus.Read : activityLive.isRead
          };
        }),
      ...activitiesUpdateState
        .filter(activity => activity.status === ActivityUpdateStatus.New)
        .map(activity => ({ activityId: activity.activityId, isRead: false }))
    ];
  }

  loadActivities(params: ActivitiesAllParams) {
    this.activeDate.next(moment(params.startDate).format(AppConstants.DATE_FORMAT_BASE_SLASH_SHORT));
    this.activeRouteId.next(params.routeId || null);
    this.activitiesParams.next(params);

    this.activitiesService.getAllActivities(params)
      .pipe(first())
      .subscribe(activities => this.activities.next(activities.map(activity => new Activity(activity))));
  }

  loadTemplateActivity(routeId: number) {
    this.activeRouteId.next(routeId || null);

    this.activitiesService.getRouteTemplateActivities(routeId)
      .pipe(first())
      .subscribe(activities => this.activities.next(activities.map(activity => new Activity(activity))));
  }

  applyActivitiesFilters(data: ActivitiesFilters) {
    this.activitiesFilters.next(data);
  }

  clearActivitiesFilters() {
    this.activitiesFilters.next({
      statusTypes: [ ActivityFiltersStatusType.Active ],
      types: activitiesConfig.filters.options.types.map(item => item.value as ActivityFiltersType),
      saveTypes: activitiesConfig.filters.options.saveTypes.map(item => item.value as ActivityAdditionalStatus)
    });
  }

  reloadActivities(params?: ActivitiesAllParams) {
    this.loadActivities({
      ...this.activitiesParams.value,
      ...params
    });
  }

  hasActivity(activityId: number): boolean {
    return this.activities.value.some(activity => activity.activityId === activityId);
  }

  deleteActivity(activityId: number) {
    this.activitiesService.deleteActivity(activityId)
      .pipe(first())
      .subscribe(() => {
        this.activities.next(this.activities.value.filter(activity => activity.activityId !== activityId));
        this.activitiesLive.next(this.activitiesLive.value.filter(activity => activity.activityId !== activityId));
      });
  }

  archiveActivity(activityId: number) {
    this.activitiesService.archiveActivity(activityId)
      .pipe(first())
      .subscribe(() => this.updateArchivedActivity(activityId));
  }

  updateArchivedActivity(activityId: number) {
    this.activities.next(this.activities.value
      .map(activity => (activity.activityId === activityId ? { ...activity, isArchived: true } : activity))
    );
  }

  updateActivities(newActivities: Activity[]) {
    this.activities.next(this.activities.value
      .map(activity => {
        const newActivity = newActivities.find(obj => activity.activityId === obj.activityId);

        return newActivity ? { ...activity, ...newActivity } : activity;
      })
    );
  }

  toggleActivity(activityId: number) {
    this.activities.next(this.activities.value
      .map(activity => (activity.activityId === activityId ? { ...activity, expanded: !activity.expanded } : activity))
    );
  }

  resetActivityShuttleCompaniesEmailKeys(activityId: number) {
    this.activities.next(this.activities.value
      .map(activity => (activity.activityId === activityId ? {
        ...activity,
        additionalData: {
          ...activity.additionalData,
          shuttleCompaniesEmailKeys: null
        }
      } : activity))
    );
  }

  updateMemberId(memberId: number) {
    this.memberId = memberId;
  }
}
