import { AuthModuleCustomerStationsFeatureNameLocalization } from '@app/auth/models';

export enum StationsMode {
  Add = 'add',
  Edit = 'edit'
}

export enum StationProp {
  Name = 'name',
  CityId = 'cityId',
  Distance = 'distance',
  FullAddress = 'fullAddress',
  Latitude = 'latitude',
  Longitude = 'longitude',
  StationId = 'stationId',
  Translations = 'translations'
}

export interface Station {
  [StationProp.CityId]: number;
  [StationProp.Distance]: number;
  [StationProp.FullAddress]: string;
  [StationProp.Latitude]: number;
  [StationProp.Longitude]: number;
  [StationProp.Name]: string;
  [StationProp.StationId]: number;
  [StationProp.Translations]: {
    [AuthModuleCustomerStationsFeatureNameLocalization.AR]?: {
      [StationProp.Name]?: string;
    }
  };
}

export interface StationsState {
  stations: Station[];
}
