import { AuthCustomerType } from '@app/auth/models';
import { MonitoringDashboardLayer, MonitoringDashboardLayerId, MonitoringDashboardLayersAction, MonitoringDashboardSourceType } from '@app/monitoring-dashboard/models';
import { monitoringDashboardConfig } from '@app/monitoring-dashboard/configs';

const DICTIONARY_PATH: string = 'monitoringDashboard.layers';

const dictionary: { [key: string]: string; } = {
  title: `${DICTIONARY_PATH}.title`
};

const allDriversLayer: MonitoringDashboardLayer = {
  id: MonitoringDashboardLayerId.AllDrivers,
  type: MonitoringDashboardSourceType.Driver,
  title: `${DICTIONARY_PATH}.allDrivers`
};

const allVehiclesLayer: MonitoringDashboardLayer = {
  id: MonitoringDashboardLayerId.AllVehicles,
  type: MonitoringDashboardSourceType.Car,
  title: `${DICTIONARY_PATH}.allVehicles`
};

const layers: MonitoringDashboardLayer[] = [
  {
    id: MonitoringDashboardLayerId.MonitoringPath,
    title: `${DICTIONARY_PATH}.monitoringPath`,
    active: true
  }
  // {
  //   id: MonitoringDashboardLayerId.Traffic,
  //   title: `${DICTIONARY_PATH}.traffic`
  // }
];

const layersActions: MonitoringDashboardLayersAction[] = [
  {
    type: MonitoringDashboardSourceType.Car,
    updateFuncName: 'updateAllVehicles',
    clearFuncName: 'clearAllVehicles'
  },
  {
    type: MonitoringDashboardSourceType.Driver,
    updateFuncName: 'updateAllDrivers',
    clearFuncName: 'clearAllDrivers'
  },
  {
    type: MonitoringDashboardSourceType.Accompany,
    updateFuncName: 'updateAllAccompanies',
    clearFuncName: 'clearAllAccompanies'
  }
];

const monitoringDashboardLayersComponent = {
  trackingId: monitoringDashboardConfig.trackingId,
  dictionaryPath: DICTIONARY_PATH,
  dictionary,
  layers,
  layersActions,
  allDriversLayer,
  allVehiclesLayer
};

const monitoringDashboardLayersComponentMunicipality = {
  ...monitoringDashboardLayersComponent,
  layers: [
    {
      id: MonitoringDashboardLayerId.AllAcc,
      type: MonitoringDashboardSourceType.Accompany,
      title: `${DICTIONARY_PATH}.allAcc`
    },
    ...monitoringDashboardLayersComponent.layers
  ]
};

export const monitoringDashboardLayersComponentConfig = {
  default: monitoringDashboardLayersComponent,
  [AuthCustomerType.Municipality]: monitoringDashboardLayersComponentMunicipality
};
