import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RoutesExportExcelBody, RoutesExportPdfBody, RouteType } from '@app/routes/models';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private BASE_PATH: string = 'Routes';

  constructor(
    private apiService: ApiService
  ) {}

  getRoutes(params: { startDate: string; endDate: string; routeType?: RouteType }): Observable<{ name: string; ids: number[]; }[]> {
    return this.apiService.get(this.BASE_PATH, params);
  }

  exportPdf(data: RoutesExportPdfBody): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Export/Pdf`, data);
  }

  downloadPdf(key: string): Observable<Blob> {
    return this.apiService.getBlob(`${this.BASE_PATH}/Download/Pdf`, 'blob', { key });
  }

  exportExcel(data: RoutesExportExcelBody): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Export/Excel`, data);
  }

  downloadExcel(key: string): Observable<Blob> {
    return this.apiService.getBlob(`${this.BASE_PATH}/Download/Excel`, 'blob', { key });
  }
}
