import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Errors } from '@app/shared/models';
import { ApiService } from '@app/shared/services/api.service';
import {
  ShuttleCompanyContractTabData,
  ShuttleCompanyContractAddEdit,
  ShuttleCompanyContractAddEditBody,
  ShuttleCompanyContractAnyRelatedRidesBody,
  ShuttleCompanyContractBase,
  ShuttleCompanyContractBranch,
  ShuttleCompanyContractBranchesParams,
  ShuttleCompanyContractUpdateStatusBody,
  ShuttleCompanyContractOtherContractsAreasBody,
  ShuttleCompanyContractOtherContractsArea
} from '@app/shuttle-companies/models';

@Injectable({
  providedIn: 'root'
})
export class ShuttleCompanyContractsService {
  private BASE_PATH: string = 'ShuttleCompanyContracts';

  constructor(private apiService: ApiService) {}

  getContracts(shuttleCompanyId: number): Observable<ShuttleCompanyContractBase> {
    return this.apiService.get(this.BASE_PATH, { shuttleCompanyId });
  }

  getBranches(params: ShuttleCompanyContractBranchesParams): Observable<ShuttleCompanyContractBranch[]> {
    return this.apiService.get(`${this.BASE_PATH}/Branches`, params);
  }

  updateContractStatus(body: ShuttleCompanyContractUpdateStatusBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Status`, body, false, [ Errors.ContractWithoutBranchesErrorCode ], null, true);
  }

  openContract(contractId: string): Observable<ShuttleCompanyContractAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/Open`, { contractId });
  }

  newContract(shuttleCompanyId: number): Observable<ShuttleCompanyContractAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/New`, { shuttleCompanyId });
  }

  duplicateContract(sourceContractId: string): Observable<ShuttleCompanyContractAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/Duplicate`, { sourceContractId });
  }

  deleteContract(contractId: number): Observable<void> {
    return this.apiService.delete(this.BASE_PATH, { contractId });
  }

  saveContract(body: ShuttleCompanyContractAddEditBody): Observable<void> {
    return this.apiService.post(this.BASE_PATH, body, 'text', false, false, null, true);
  }

  checkAnyRelatedRides(body: ShuttleCompanyContractAnyRelatedRidesBody): Observable<boolean> {
    return this.apiService.post(`${this.BASE_PATH}/AnyRelatedRides`, body);
  }

  getContractTab(contractId: string, apiPath: string): Observable<ShuttleCompanyContractTabData> {
    return this.apiService.get(`${this.BASE_PATH}/${apiPath}`, { contractId });
  }

  getOtherContractsAreas(body: ShuttleCompanyContractOtherContractsAreasBody): Observable<ShuttleCompanyContractOtherContractsArea> {
    return this.apiService.post(`${this.BASE_PATH}/GetOtherContractsAreas`, body);
  }
}
