import { USearchFilter } from '@shift/ulib';

import { AuthModuleFeature, AuthModuleFeatureGroup } from '@app/auth/models';

export interface GlobalSearchFilter extends USearchFilter {
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  resetFiltersByNames?: string[];
  value?: string | number | boolean | (string | number | boolean)[];
  itemsLazyLoad?: boolean;
}
