import { computed, inject, Injectable, signal } from '@angular/core';
import { exhaustMap, filter, map, tap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

import { CitiesService } from '@app/shared/services';
import { patchSignal, rxMethod, tapResponse, transformToInputCities } from '@app/shared/utils';
import { CitiesState } from '@app/shared/models';

@Injectable()
export class CitiesStoreService {
  private readonly citiesService = inject(CitiesService);

  readonly #state = signal<CitiesState>({ cities: null });

  readonly state = this.#state.asReadonly();
  readonly cities = computed(() => this.state().cities);

  readonly cities$ = toObservable(this.cities);
  readonly getCities$ = this.cities$
    .pipe(
      tap(cities => !cities && this.getCities()),
      filter(cities => !!cities)
    );
  readonly getCitiesBySelectedBranchIds$ = (branchIds: number[]) => this.getCities$
    .pipe(
      map(cities =>
        cities.reduce((acc, city) => {
          const branches = branchIds.length ? city.branches.filter(branch => branchIds.includes(branch.branchId)) : city.branches;

          if (branches?.length) {
            return [ ...acc, { ...city, branches } ];
          }

          return acc;
        }, [])
      )
    );

  readonly getCities = rxMethod<void>(
    pipe(
      filter(() => !this.cities()),
      exhaustMap(() =>
        this.citiesService.getFilter()
          .pipe(
            tapResponse(
              data => patchSignal(this.#state, { cities: transformToInputCities(data) }),
              () => null
            )
          )
      )
    )
  );

  reset() {
    this.#state.set({ cities: null });
  }
}
