import { Injectable, inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { RoutesRestoreRideComponent } from '@app/routes/components';
import { RoutesViewTypeMode, RoutesRestoreActiveRide } from '@app/routes/models';

@Injectable()
export class RoutesRestoreRideModalService {
  private readonly bsModalService = inject(BsModalService);

  openModal(activeRide: RoutesRestoreActiveRide, viewTypeMode?: RoutesViewTypeMode) {
    return this.bsModalService.show(
      RoutesRestoreRideComponent,
      {
        class: 'u-modal u-modal_app-general-card',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          activeRide,
          viewTypeMode
        }
      }
    );
  }
}
