<div class="builder-duplicate-route__header">
  <div class="builder-duplicate-route__name">{{form.get('name').value}}</div>
  <div class="builder-duplicate-route__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
</div>
<div class="builder-duplicate-route__header-divider"></div>
<div class="builder-duplicate-route__wrapper">
  <u-group
    class="u-group_large-field"
    [name]="'builder.duplicateRoute.routeName' | translate"
  > 
    <input
      uInput
      type="text"
      [placeholder]="'builder.duplicateRoute.routeName' | translate"
      [valid]="form.get('name').valid"
      [formControl]="form.get('name')"
    >
  </u-group>
  <u-group
    class="u-group_large-field"
    [name]="'builder.duplicateRoute.direction' | translate"
  > 
    <u-select-s
      [items]="directions"
      [messages]="'uSelectS' | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [empty]="false"
      [placeholder]="'general.select' | translate"
      [valid]="form.get('direction').valid"
      [formControl]="form.get('direction')"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_large-field"
    [name]="'builder.duplicateRoute.time' | translate"
  > 
    <div class="builder-duplicate-route__double">
      <div class="builder-duplicate-route__double-item">
        <u-input-time
          [class]="'u-input-time_center'"
          [valid]="form.get('startTime').valid"
          [formControl]="form.get('startTime')"
        ></u-input-time>
      </div>
      <div class="builder-duplicate-route__double-item">
        <u-input-time
          [class]="'u-input-time_center'"
          [valid]="form.get('startTime').valid"
          [formControl]="form.get('endTime')"
        ></u-input-time>
      </div>
    </div>
  </u-group>
  <u-group
    class="u-group_large-field"
    [name]="'builder.duplicateRoute.period' | translate"
  > 
    <u-select-s
      [items]="presets"
      [messages]="'uSelectS' | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [empty]="false"
      [placeholder]="'general.select' | translate"
      [valid]="form.get('preset').valid"
      [formControl]="form.get('preset')"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_large-field"
  > 
    <u-input-date-range
      [class]="'u-input-date-range_blue'"
      [container]="'body'"
      [placement]="isRtl ? [ 'top-right' ] : [ 'top-left' ]"
      [formControl]="form.get('dates')"
      [sundayFirstDay]="true"
      [defaultDate]="false"
      [showIcon]="false"
      [required]="true"
      [messages]="'uInputDateRange' | translate"
    ></u-input-date-range>
  </u-group>
</div>
<div class="builder-duplicate-route__action">
  <div class="builder-duplicate-route__action-left">
    <button
      uButton
      class="builder-duplicate-route__action-btn builder-duplicate-route__action-btn_cancel"
      (click)="close(); trackingService.track('[Route planner, duplicate route] - click on cancel')"
    >
      {{'builder.duplicateRoute.cancel' | translate}}
    </button>
  </div>
  <div class="builder-duplicate-route__action-right">
    <button
      uButton
      class="builder-duplicate-route__action-btn"
      (click)="save(false)"
    >
      {{'builder.duplicateRoute.saveAndEdit' | translate}}
    </button>
    <button
      uButton
      class="builder-duplicate-route__action-btn"
      (click)="save(true)"
    >
      {{'builder.duplicateRoute.saveAndClose' | translate}}
    </button>
  </div>
</div>


