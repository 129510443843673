import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Cities } from '@app/shared/models';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {
  private BASE_PATH: string = 'Cities';

  constructor(
    private apiService: ApiService
  ) {}

  getFilter(): Observable<Cities> {
    return this.apiService.get(`${this.BASE_PATH}/Filter`);
  }
}
