import { AppConstants } from '@app/shared/constants';

export function isValidEmail(email: string) {
  if (!email) { return false; }

  const emailLowerCase = email.toLowerCase();
  const matches = emailLowerCase.match(AppConstants.EMAIL_REG_EXP);

  return !!matches && matches.length && matches[0] === emailLowerCase;
}
