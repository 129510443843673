import {
  Component,
  OnInit,
  HostBinding,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UPopupService } from '@shift/ulib';

import { EventData, EventDataType } from '@app/shared/models';
import { InputShiftsService } from '@app/shared/services';
import { AuthDataService } from '@app/auth/services';
import { AuthCustomerType, AuthModuleBranchesFeature, AuthModuleBranchesFeatureType, AuthModuleName } from '@app/auth/models';
import { inputShiftsComponentConfig } from './input-shifts.component.config';

@Component({
  selector: 'app-input-shifts',
  templateUrl: './input-shifts.component.html',
  styleUrls: [ './input-shifts.component.scss', './input-shifts.component.rtl.scss' ],
  providers: [ InputShiftsService ]
})
export class InputShiftsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: UntypedFormArray;
  @Input() config: object;

  @Output() eventData: EventEmitter<EventData> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'input-shifts';

  private unsubscribe: Subject<void> = new Subject();

  branchesFeatureType: AuthModuleBranchesFeatureType;
  authModuleBranchesFeatureType = AuthModuleBranchesFeatureType;
  eventDataType = EventDataType;
  tracking = cloneDeep(inputShiftsComponentConfig.tracking.default);
  dictionary = cloneDeep(inputShiftsComponentConfig.dictionary.default);

  constructor(
    private authDataService: AuthDataService,
    private uPopupService: UPopupService,
    public inputShiftsService: InputShiftsService
  ) {}

  ngOnInit() {
    this.setCustomerAndDictionary(true);
    this.getBranchesFeatureType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && this.config) {
      this.setCustomerAndDictionary();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getBranchesFeatureType() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.Branches, AuthModuleBranchesFeature.Type)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(branchesFeatureType => this.branchesFeatureType = branchesFeatureType);
  }

  setCustomerAndDictionary(initForm?: boolean) {
    this.authDataService.customer$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(customer => {
        this.setCustomerCategoryTypeDictionary(customer.type);

        if (initForm) {
          this.inputShiftsService.initForm(this.form, customer.type);
        }
      });
  }

  setCustomerCategoryTypeDictionary(customerType: AuthCustomerType) {
    this.tracking = cloneDeep(inputShiftsComponentConfig.tracking[customerType] || inputShiftsComponentConfig.tracking.default);
    this.dictionary = cloneDeep(inputShiftsComponentConfig.dictionary[customerType] || inputShiftsComponentConfig.dictionary.default);

    this.setDictionary();
  }

  setDictionary() {
    if (this.config && this.config['dictionary']) {
      Object.assign(this.dictionary, this.config['dictionary']);
    }
  }

  removeShift(index: number) {
    const optionsPopup = {
      message: this.dictionary.removeMessage,
      yes: this.dictionary.yes,
      no: this.dictionary.no
    };

    this.uPopupService.showMessage(
      optionsPopup,
      () => {
        this.inputShiftsService.removeShift({
          index
        });
      }
    );
  }

  sendEventData(type: EventDataType, name: string) {
    this.eventData.emit({
      type,
      name
    });
  }
}
