import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { RemindersService } from '@app/reminder/services';
import { NotesItem } from '@app/notes/models';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  BASE_PATH: string = 'Notes';

  constructor(
    private apiService: ApiService,
    private remindersService: RemindersService
  ) { }

  getAllNotes(): Observable<NotesItem[]> {
    return this.apiService.get(`${this.BASE_PATH}/Notes`);
  }

  saveNewNote(newNote: NotesItem): Observable<NotesItem>  {
    return this.apiService.post(`${this.BASE_PATH}/NoteAdd`, newNote);
  }

  editNote(changedNote: NotesItem): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/NoteChange`, changedNote);
  }

  deleteNote(noteId: number): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}/NoteDelete`, { iNoteId: noteId });
  }

  deleteNoteAndReminder(noteId: number, reminderId: number): Observable<[ void, void | boolean ]> {
    return forkJoin([ this.deleteNote(noteId), reminderId > 0 ? this.remindersService.deleteReminder({ reminderId }) : of(true) ]);
  }
}
