import { Pipe, PipeTransform } from '@angular/core';

import { MovePassengersRoute } from '@app/shared/models';

@Pipe({
  name: 'movePassengersDisabledRouteMapIcon',
  standalone: true
})
export class MovePassengersDisabledRouteMapIconPipe implements PipeTransform {
  transform(routes: MovePassengersRoute[], currentRoute: MovePassengersRoute): boolean {
    return !currentRoute.color && routes.filter(route => !!route.color).length >= 3;
  }
}
