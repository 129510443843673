import { computed, inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { pipe } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { patchSignal, rxMethod, tapResponse } from '@app/shared/utils';
import {
  BuilderTuningState,
  BuilderRouteMode
} from '@app/builder/models';
import { BuilderService } from '@app/builder/services';

@Injectable()
export class BuilderTuningStoreService {
  private readonly builderService = inject(BuilderService);

  readonly #state = signal<BuilderTuningState>({ data: null });

  readonly state = this.#state.asReadonly();
  readonly data = computed(() => this.state().data);
  readonly drivers = computed(() => (this.data()?.details?.drivers || []).map(driver => ({ value: driver.id, name: driver.name })));
  readonly carTypes = computed(() => (this.data()?.details?.carTypes || []).map(carType => ({ value: carType.id, name: `${carType.name} (${carType.capacity})` })));
  readonly costTypes = computed(() => (this.data()?.details?.costTypes || []).map(costType => ({ value: costType.id, name: costType.name })));
  readonly hashcalRideTypesItems = computed(() =>
    this.data()?.details?.costTypes.filter(costType => costType.hashcalRideTypes).reduce((acc, item) => ({
      ...acc,
      [item.id]: item.hashcalRideTypes.map(({ id, name }) => ({ value: id, name }))
    }), {})
  );
  readonly carNumbersByCarTypeId = (carTypeId: number) => computed(() =>  {
    const selectedCarType = this.data()?.details?.carTypes.find(carType => carType.id === carTypeId);

    return this.data()?.details?.carTypes ? this.data().details.carTypes
      .filter(carType => !selectedCarType || carType.capacity)
      .reduce((acc, carType) =>
        [ ...acc, ...carType.cars.map(car => ({ value: car.id, name: `${car.name} (${carType.name})` })) ], []
      ) : [];
  });

  readonly hashcalRideTypesItems$ = toObservable(this.hashcalRideTypesItems);

  readonly getShuttleCompany = rxMethod<{
    shuttleCompanyId: number;
    contractId: string;
    routeMode: BuilderRouteMode;
  }>(
    pipe(
      distinctUntilChanged((prev, curr) => this.state().data && isEqual(prev, curr)),
      switchMap(({ shuttleCompanyId, contractId, routeMode }) =>
        this.builderService.getShuttleCompanies({
          shuttleCompanyRides: [
            {
              shuttleCompanyId,
              contractId
            }
          ],
          routeMode
        })
          .pipe(
            tapResponse(
              ({ shuttleCompaniesDetails }) => patchSignal(this.#state, { data: shuttleCompaniesDetails?.[0] }),
              () => null
            )
          )
      )
    )
  );

  reset() {
    this.#state.set({ data: null });
  }
}
