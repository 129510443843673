import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { Reminder, ReminderNote } from '@app/reminder/models';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {
  BASE_PATH: string = 'Reminders';

  constructor(private apiService: ApiService) {}

  getAllReminders(): Observable<Reminder[]> {
    return this.apiService.get(`${this.BASE_PATH}/All`);
  }

  addReminder(reminder: Reminder): Observable<Reminder> {
    return this.apiService.post(this.BASE_PATH, reminder);
  }

  addNoteReminder(reminder: ReminderNote): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/ReminderAdd`, reminder);
  }

  deleteReminder(param: { reminderId: number }): Observable<void> {
    return this.apiService.delete(this.BASE_PATH, param);
  }

  deleteNoteReminder(params: { iNoteId?: number, iReminderId?: number }): Observable<[ void, void ]> {
    return forkJoin([ this.apiService.delete(`${this.BASE_PATH}/ReminderDelete`, params), this.deleteReminder({ reminderId: params.iReminderId }) ]);
  }
}
