import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UDatePickerRangeMaxPeriodSelection, UPopupService, URangePreset } from '@shift/ulib';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { RouteTemplatesService } from '@app/route-templates/services';
import { routeTemplatesConfig } from '@app/route-templates/configs';
import { RouteRemplatesInitialDataType, RouteTemplatesTableRow } from '@app/route-templates/models';
import { AppLanguage } from '@app/shared/models';
import { AuthModuleRouteTemplatesFeatureType } from '@app/auth/models';
import { AppConstants } from '@app/shared/constants';
import { routesFromTemplatesComponentConfig } from './routes-from-templates.component.config';

@Component({
  selector: 'app-routes-from-templates',
  templateUrl: './routes-from-templates.component.html',
  styleUrls: [ './routes-from-templates.component.scss', './routes-from-templates.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesFromTemplatesComponent implements OnInit, OnDestroy {
  @Input() templates: RouteTemplatesTableRow[];
  @Input() featureType: AuthModuleRouteTemplatesFeatureType;

  @HostBinding('class') hostClasses: string = 'routes-from-templates';

  private unsubscribe: Subject<void> = new Subject();

  newRoutesForm: UntypedFormGroup;
  newRoutesPopupTitle: string;
  isMunicipalityFeatureType: boolean;
  config = routesFromTemplatesComponentConfig;
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  maxPeriodSelectionFromSelectedDate: UDatePickerRangeMaxPeriodSelection;

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private modalRef: BsModalRef,
    private popupService: UPopupService,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private localizationService: LocalizationService,
    private routeTemplatesService: RouteTemplatesService
  ) {}

  ngOnInit() {
    this.initFeatureType();
    this.trackEvent(this.templates.length > 1 ? 'click on create multiple new routes' : 'click on create new route from template');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${routeTemplatesConfig.trackingId}] - ${this.config.trackingId} - ${event}`);
  }

  private initFeatureType() {
    this.isMunicipalityFeatureType = this.featureType === AuthModuleRouteTemplatesFeatureType.Municipality;

    this.initPopup();
  }

  private initPopup() {
    this.translateService.get(this.config.dictionary.title)
      .pipe(first())
      .subscribe(translatedTitle => {
        this.newRoutesPopupTitle = translatedTitle.replace('{{numRoutes}}', this.templates.length);

        this.initNewRoutesForm();
      });
  }

  private initNewRoutesForm() {
    const today = moment().startOf('day');
    const todayDate = today.format(AppConstants.DATE_FORMAT_ISO);
    const todayDayOfWeek = today.day();

    this.newRoutesForm = this.fb.group({
      datesChange: this.fb.group({
        dates: [ [ todayDate ], Validators.required ],
        dateFrom: [],
        dateTo: [],
        type: [ URangePreset.Today ],
        availablePresets: [ this.config.availableRangePresets ],
        checkDaysActive: [ [ todayDayOfWeek ] ],
        checkDaysAvailable: [ this.config.availableDays ],
        displayedDay: [ todayDate ]
      }),
      drivers: [ true ],
      shuttleCompany: [ false ],
      asDailyRoutes: [ false ]
    });

    if (this.isMunicipalityFeatureType) {
      this.newRoutesForm.addControl('accompanies', new UntypedFormControl(true));
    }

    this.newRoutesForm.get('shuttleCompany')
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value) {
          this.newRoutesForm.get('drivers').patchValue(true);
          this.newRoutesForm.get('drivers').disable();
        } else {
          this.newRoutesForm.get('drivers').enable();
        }
      });

    this.newRoutesForm.get('asDailyRoutes')
      .valueChanges
      .pipe(
        switchMap(value => {
          this.trackEvent('check Duplicate as daily routes');

          if (value) {
            const dates = this.newRoutesForm.get('datesChange.dates').value;
            const dateFrom = moment(dates[0], AppConstants.DATE_FORMAT_ISO).startOf('day');
            const dateTo = moment(dates[dates.length - 1], AppConstants.DATE_FORMAT_ISO).startOf('day');
            const datesDiffDays = dateTo.diff(dateFrom, 'days') + 1;

            return this.routeTemplatesService.getInitialData([ RouteRemplatesInitialDataType.AllowedAmountOfDailyRoutes ])
              .pipe(
                tap(data => {
                  this.maxPeriodSelectionFromSelectedDate = { amount: data.allowedAmountOfDailyRoutes, unit: 'days' };

                  if (datesDiffDays > data.allowedAmountOfDailyRoutes) {
                    this.newRoutesForm.get('datesChange').patchValue({
                      dates: [ todayDate ],
                      type: URangePreset.Today,
                      displayedDay: todayDate,
                      checkDaysActive: [ todayDayOfWeek ]
                    });
                  }

                  if (data.allowedAmountOfDailyRoutes < 7) {
                    this.newRoutesForm.get('datesChange').patchValue({
                      availablePresets: this.config.availableRangePresets.filter(preset => preset !== URangePreset.UpcomingWeek)
                    });
                  }

                  this.cdRef.markForCheck();
                })
              );
          } else {
            this.newRoutesForm.get('datesChange').patchValue({
              availablePresets: this.config.availableRangePresets
            });

            this.maxPeriodSelectionFromSelectedDate = null;

            return of(null);
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  onDatesSave(data) {
    this.newRoutesForm.get('datesChange').patchValue(data);
  }

  closeNewRoutesPopup() {
    this.trackEvent('click on cancel');
    this.modalRef.hide();
  }

  saveNewRoutesPopup() {
    const dates = this.newRoutesForm.value.datesChange.dates;
    this.trackEvent('click on save & close');
    this.routeTemplatesService.createRoutesFromTemplates({
      templateIds: this.templates.map(template => template.id),
      startDate: dates[0],
      endDate: dates[dates.length - 1],
      withDrivers: !this.newRoutesForm.value.drivers,
      withAccompanies: !this.newRoutesForm.value.accompanies,
      withShuttleCompany: !this.newRoutesForm.value.shuttleCompany,
      asDailyRoutes: this.newRoutesForm.value.asDailyRoutes
    })
      .pipe(first())
      .subscribe();
    this.popupService.showMessage({
      message: this.config.dictionary.saveSuccess,
      yes: 'general.confirm'
    }, () => this.modalRef.hide());
  }
}
