import { DestroyRef, Injectable, inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, take } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authDataService = inject(AuthDataService);

  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.config.applicationInsightsKey
    }
  });

  private onResolveEndRoute(event: ResolveEnd) {
    const activatedComponent = this.getActivatedComponent(event.state.root);

    if (activatedComponent) {
      this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
    }
  }

  private setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  private logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot) {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot) {
    let path = '';

    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      path += this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  private onRouterEvents() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof ResolveEnd)
      )
      .subscribe((event: ResolveEnd) => this.onResolveEndRoute(event));
  }

  private onUserInfo() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(userInfo => this.setUserId(`${userInfo.person.memberId}`));
  }

  init() {
    this.appInsights.loadAppInsights();

    this.onRouterEvents();
    this.onUserInfo();
  }
}
