import { USelectSItem } from '@shift/ulib';

import { ContactType } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.inputCommunications';

const contactTypes: USelectSItem[] = [
  { value: ContactType.Phone, name: 'general.contacts.phone' },
  { value: ContactType.Mobile, name: 'general.contacts.mobile' },
  { value: ContactType.Fax, name: 'general.contacts.fax' },
  { value: ContactType.Email, name: 'general.contacts.email' }
];

const inputCommunicationsConfig = {
  contactTypes,
  dictionary: {
    communications: `${DICTIONARY_PATH}.communications`,
    select: 'general.select',
    remove: 'general.remove',
    uSelectS: 'uSelectS'
  }
};

export default inputCommunicationsConfig;
