import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthRedirectCallbackComponent } from '@app/auth/components';

const routes: Routes = [
  {
    path: 'signin-callback',
    component: AuthRedirectCallbackComponent
  },
  {
    path: 'signout-callback',
    component: AuthRedirectCallbackComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AuthRoutingModule {}
