import { Component, HostBinding, Input, OnInit, OnChanges } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

import { InputContactsService, LocalizationService } from '@app/shared/services';
import { inputContactsComponentConfig } from './input-contacts.component.config';

@Component({
  selector: 'app-input-contacts',
  templateUrl: './input-contacts.component.html',
  styleUrls: [ './input-contacts.component.scss', './input-contacts.component.rtl.scss' ],
  providers: [ InputContactsService ]
})
export class InputContactsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormArray;
  @Input() showTitle: boolean = true;

  @HostBinding('class') hostClasses: string = 'input-contacts';

  config = inputContactsComponentConfig;
  contacts: UntypedFormArray;
  isRtl: boolean = true;

  constructor(
    private localizationService: LocalizationService,
    public inputContactsService: InputContactsService
  ) {}

  ngOnInit() {
    this.inputContactsService.form = this.form;
    this.isRtl = this.localizationService.isRtl();
  }

  ngOnChanges(): void {
    this.inputContactsService.form = this.form;
  }
}
