import { AuthCustomerType } from '@app/auth/models';
import { EnvType } from '@app/shared/models';

const themesByEnvTypes = {
  default: null,
  [EnvType.IDF]: 'idf-theme',
  [EnvType.Traffical]: 'traffical-theme'
};

export const themesConfig = {
  default: themesByEnvTypes,
  [AuthCustomerType.ShuttleCompany]: {
    ...themesByEnvTypes,
    [EnvType.Shift]: 'shift-shuttle-company-theme'
  }
};
