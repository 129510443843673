import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { MonitoringDashboardLastCoordinate, MonitoringDashboardSourceType, MonitoringDashboardRideDetailsBase, MonitoringDashboardRideBase } from '@app/monitoring-dashboard/models';

@Injectable()
export class MonitoringDashboardService {
  private BASE_PATH = 'MonitoringDashboard';

  constructor (
    private apiService: ApiService
  ) {}

  getRides(date: string): Observable<MonitoringDashboardRideBase[]> {
    return this.apiService.get(this.BASE_PATH, { date });
  }

  getRideDetails(rideId: number): Observable<MonitoringDashboardRideDetailsBase> {
    return this.apiService.get(`${this.BASE_PATH}/RideDetails`, { rideId });
  }

  getLastCoordinates(sourceTypes: MonitoringDashboardSourceType[]): Observable<MonitoringDashboardLastCoordinate[]> {
    return this.apiService.post(`${this.BASE_PATH}/LastCoordinates`, { sourceTypes }, 'text', false, true);
  }
}
