<app-general-card
  *ngIf="form"
  [title]="config.dictionary.title"
  [buttons]="config.buttons"
  (buttonClick)="onButtonClick($event)"
  (closeAction)="close()"
>
  <div class="routes-restore-ride__body">
    <div
      *ngIf="canceledRidesDates && !canceledRidesDates.length"
      class="routes-restore-ride__body-warning"
    >
      {{config.dictionary.noCanceledRides | translate}}
    </div>
    <div class="routes-restore-ride__body-title">{{activeRide.code}} {{activeRide.name}}</div>
    <div class="routes-restore-ride__body-groups">
      <u-group
        class="u-group_app-routes-restore-ride"
        [name]="config.dictionary.period | translate"
      >
        <app-routes-periods
          [dayIndicatorConfig]="{ dates: activeRide.totalPassengers > 0 ? canceledRidesDates : [] }"
          [defaultData]="routesPeriodsDefaultData"
          [form]="form.get('periods')"
          [hidePlusIcon]="isSCCustomer"
          [isOwnedBySc]="activeRide.isOwnedBySc"
          [authCustomer]="authCustomer"
          (clickOnPlusIcon)="track('Period - click on plus')"
        ></app-routes-periods>
      </u-group>
    </div>
  </div>
</app-general-card>
