export enum RoutePolicyPassengerAddressPolicy {
  Exact = 1,
  Original = 2
}

export enum RoutePolicyRouteNamePolicy {
  AllCityNames = 1,
  FirstOrLastAddress = 2
}

export enum RoutePolicyPickUpOption {
  VehicleAbilities = null,
  AnySide = 0,
  RightSide = 1
}

export enum RoutePolicyCalculateBy {
  Time = 1,
  TimeAndDistance
}

export enum RoutePolicyAlgorithmVersion {
  AlgoV2 = 'V2',
  AlgoV2A = 'V2a'
}

export enum RoutePolicyTabName {
  Policy = 'policy',
  CitiesCombinations = 'citiesCombinations',
  CitiesPricing = 'citiesPricing'
}

export class RoutePolicy {
  useSharing: boolean = true;
  maxDurationMinutes: number = 0;
  useGetEarlyToDestination: boolean = true;
  getEarlyToDestinationMinutes: number = 0;
  useCentralStations: boolean = true;
  centralStationRadiusMeters: number = 0;
  curbApproach: RoutePolicyPickUpOption = RoutePolicyPickUpOption.VehicleAbilities;
  useBusStations: boolean = true;
  waitOnStationSeconds: number = 0;
  useTollRoads: boolean = true;
  useTraffic: boolean = false;
  calculateBy: number = 1;
  cheapestPrice: boolean;
  consolidationTimeMinutes: number = 0;
  passengerStationType: number = 0;
  useCityOptions: boolean = true;
  directRoute: boolean = true;
  passengerAddressPolicy: RoutePolicyPassengerAddressPolicy = RoutePolicyPassengerAddressPolicy.Exact;
  routeNamePolicy: RoutePolicyRouteNamePolicy = null;
  useEqualDistribution: boolean = false;
  algorithmVersion: RoutePolicyAlgorithmVersion = null;
  speedRate: number = 100;
}
