export enum FeedStatus {
  OnGoingWithoutMonitoring = 'OnGoingWithoutMonitoring',
  OnGoingWithMonitoring = 'OnGoingWithMonitoring',
  OnGoingWithoutEntrance =  'OnGoingWithoutEntrance',
  Finished = 'Finished',
  FinishedMonitored = 'FinishedMonitored',
  FinishedWithoutEntrance = 'FinishedWithoutEntrance',
  FinishedWithDelay = 'FinishedWithDelay',
  FinishedHasNotPickedPassengers = 'FinishedHasNotPickedPassengers',
  AccompaniesWithNoAssignment = 'AccompaniesWithNoAssignment',
  DuplicatedAccompany = 'DuplicatedAccompany',
  NeededAccompany = 'NeededAccompany',
  DriversWithNoAssignment = 'DriversWithNoAssignment',
  DuplicatedDriver = 'DuplicatedDriver',
  NeededDriver = 'NeededDriver',
  NeededShuttleCompany = 'NeededShuttleCompany',
  WithoutPrice = 'WithoutPrice',
  WithoutSupervisor = 'WithoutSupervisor',
  DuplicatedSupervisor = 'DuplicatedSupervisor',
  RidesToReOptimize = 'RidesToReOptimize',
  RidesWithEmptyStations = 'RidesWithEmptyStations',
  ReOptimizationsDone = 'ReOptimizationsDone',
  DuplicatedPassenger = 'RoutesWithDuplicatedPassenger'
}

export enum FeedType {
  ReOptimization = 'reOptimization',
  Finished = 'finished',
  RealTime = 'realTime',
  Task = 'task'
}

export enum FeedRefreshMode {
  Default = 'default',
  OnlyIcon = 'onlyIcon'
}

export enum FeedFilterPage {
  RoutesDaily = 'routesDaily',
  RoutesWeekly = 'routesWeekly'
}

export interface FeedTasksType {
  accompaniesWithNoAssignment: number[];
  driversWithNoAssignment: number[];
  duplicatedAccompany: number[];
  duplicatedDriver: number[];
  missingAccompany: number[];
  missingDriver: number[];
  missingShuttleCompany: number[];
  summary: number;
  withoutPrice: number[];
  withoutSupervisor: number[];
  duplicatedSupervisor: number[];
  passengersWithoutSchoolOrSchedule: number[];
  unassignedPassengersForPickUp: number[];
  unassignedPassengersForDropOff: number[];
  duplicatedPassenger: number[];
  routesWithDuplicatedPassenger?: number[];
}

export interface FeedDataType {
  address: string;
  memberId: string;
  mobile: string;
  name: string;
}

export interface FeedFinishedType {
  finished: number[];
  monitored: number[];
  notCheckedIn: number[];
  summary: number;
  totalMarked: number[];
  withDelay: number[];
  withoutEntrance: number[];
}

export interface FeedReOptimization {
  forReOptimization: number[];
  forCleanup: number[];
  sessionsAmount: number;
}

export interface FeedRealTimeType {
  summary: number;
  withMonitoring: number[];
  withoutEntrance: number[];
  withoutMonitoring: number[];
}

export interface FeedFilterData {
  data: FeedReOptimization | FeedFinishedType | FeedRealTimeType | FeedTasksType;
  prop: FeedType;
}

export interface FeedTasksDetails {
  showDetails: boolean;
  idArray: number[];
  type: FeedStatus;
  date: string;
  refreshMode: FeedRefreshMode;
}

export class FeedFilter {
  page: FeedFilterPage;
  value: FeedStatus;
  items: number[];

  constructor(value?: { page: FeedFilterPage; value: FeedStatus; items?: number[]; }) {
    Object.assign(this, value);
  }
}

export class FeedDataHolder {
  reOptimization: FeedReOptimization;
  finished: FeedFinishedType;
  task: FeedTasksType;
  realTime: FeedRealTimeType;
}
