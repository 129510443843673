import { inject, Injectable } from '@angular/core';

import { ThreeDotsPopoverItem } from '@app/shared/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import { ActivityUpdate } from '@app/activities/models';
import { ActivitiesDataService } from '@app/activities/services';
import { RouteRowWithActivities } from '@app/routes/models';

@Injectable()
export class RoutesDataService {
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  private readonly activitiesDataService = inject(ActivitiesDataService);

  getFilteredThreeDotsMenu(menu: ThreeDotsPopoverItem[]): ThreeDotsPopoverItem[] {
    return menu.filter(item =>
      (item.feature ? this.authDataSnapshotService.checkFeature(item.feature) : true) &&
      (item.permission ? this.authDataSnapshotService.checkPermission(item.permission) : true)
    );
  }

  getRouteRowsWithActivitiesUpdate<T extends RouteRowWithActivities>(rows: T[], activitiesUpdate: ActivityUpdate[]) {
    const saveStatusRouteIds: number[] = [];

    return {
      rows: rows.map(row => {
        const routeActivitiesUpdate = activitiesUpdate.filter(activity => activity.routeId === row.id);

        if (routeActivitiesUpdate.length) {
          const saveStatus = this.activitiesDataService.getRouteSaveStatus(row.saveStatus, routeActivitiesUpdate);

          if (saveStatus) {
            saveStatusRouteIds.push(row.id);
          }

          const locked = this.activitiesDataService.isLockedRoute(row.locked, routeActivitiesUpdate);

          return {
            ...row,
            activities: this.activitiesDataService.parseActivities(row.activities, routeActivitiesUpdate),
            locked,
            saveStatus,
            lockState: locked ? row.lockState : null
          };
        }

        return row;
      }),
      saveStatusRouteIds
    };
  }
}
