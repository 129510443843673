import { createAction, props } from '@ngrx/store';

import { RideOrder, RideOrderActivitiesUpdateItem, RideOrdersBody, RideOrderStatusUpdated } from '@app/ride-orders/models';

const ACTION_PREFIX: string = '[Ride Orders]';

export const init = createAction(`${ACTION_PREFIX} init`);
export const initialized = createAction(`${ACTION_PREFIX} initialized`);
export const itemsInit = createAction(`${ACTION_PREFIX} items init`, props<{ params: RideOrdersBody; }>());
export const load = createAction(`${ACTION_PREFIX} load`, props<{ params: RideOrdersBody; }>());
export const loadSuccess = createAction(`${ACTION_PREFIX} load success`, props<{ items: RideOrder[]; }>());
export const loadCancel = createAction(`${ACTION_PREFIX} load cancel`);
export const hubInitialized = createAction(`${ACTION_PREFIX} hub initialized`);
export const itemsReset = createAction(`${ACTION_PREFIX} items reset`);
export const activitiesUpdateItems = createAction(`${ACTION_PREFIX} activities update items`, props<{ items: RideOrderActivitiesUpdateItem[]; }>());
export const updateItems = createAction(`${ACTION_PREFIX} update items`, props<{ items: RideOrder[]; }>());
export const updateRideOrderStatus = createAction(`${ACTION_PREFIX} update ride order status`, props<RideOrderStatusUpdated>());
