<div class="move-passengers-info__route">
  <div class="move-passengers-info__route-info">
    <div
      class="move-passengers-info__route-arrow-icon"
      [ngClass]="{
        'move-passengers-info__route-arrow-icon_active': isRoutesListVisible,
        'move-passengers-info__route-arrow-icon_disabled': !routes.length
      }"
      (click)="toggleRoutesList()"
    ></div>
    <span
      class="move-passengers-info__route-code"
      [uTooltip]="sourceRoute.code"
      [container]="'body'"
      [placement]="['bottom', 'top']"
    >
      {{sourceRoute.code}}
    </span>
    <span
      class="move-passengers-info__route-name"
      [uTooltip]="sourceRoute.name"
      [container]="'body'"
      [placement]="['bottom', 'top']"
    >
      {{sourceRoute.name}}
    </span>
  </div>
  <div class="move-passengers-info__route-details">
    <span
      class="move-passengers-info__route-distance"
      [uTooltip]="(sourceRoute.distance | movePassengersRouteDistance) | kmMileConvertor"
      [container]="'body'"
      [placement]="['bottom', 'top']"
    >
      {{config.dictionary.km | translate}}-
      {{(sourceRoute.distance | movePassengersRouteDistance) | kmMileConvertor}}
    </span>
    <span
      class="move-passengers-info__route-time"
      [uTooltip]="sourceRoute.duration"
      [container]="'body'"
      [placement]="['bottom', 'top']"
    >
      {{config.dictionary.time | translate}}-
      {{sourceRoute.duration}}
    </span>
    <div
      (click)="toggleLegend()"
      class="move-passengers-info__route-info-icon-wrapper"
    >
      <img
        class="move-passengers-info__route-info-icon"
        [src]="isLegendVisible ? '/assets/images/common/info-active.svg' : '/assets/images/common/info.svg'"
      >
    </div>
  </div>
</div>
<div
  *ngIf="isRoutesListVisible"
  class="move-passengers-info__routes"
>
  <div
    *ngFor="let route of routes"
    class="move-passengers-info__route move-passengers-info__route_item"
  >
    <div class="move-passengers-info__route-info">
      <div
        class="move-passengers-info__route-circle"
        [ngClass]="[
          route.color ? 'move-passengers-info__route-circle-' + route.color : ''
        ]"
      ></div>
      <span
        class="move-passengers-info__route-code"
        [uTooltip]="route.code"
        [container]="'body'"
        [placement]="['bottom', 'top']"
      >
        {{route.code}}
      </span>
      <span
        class="move-passengers-info__route-name"
        [uTooltip]="route.name"
        [container]="'body'"
        [placement]="['bottom', 'top']"
      >
        {{route.name}}
      </span>
    </div>
    <div class="move-passengers-info__route-details">
      <span
        class="move-passengers-info__route-distance"
        [uTooltip]="(route.distance | movePassengersRouteDistance) | kmMileConvertor"
        [container]="'body'"
        [placement]="['bottom', 'top']"
      >
        {{config.dictionary.km | translate}}-
        {{(route.distance | movePassengersRouteDistance) | kmMileConvertor}}
      </span>
      <span
        class="move-passengers-info__route-time"
        [uTooltip]="route.duration"
        [container]="'body'"
        [placement]="['bottom', 'top']"
      >
        {{config.dictionary.time | translate}}-
        {{route.duration}}
      </span>
      <div
        class="move-passengers-info__route-delete-icon-wrapper"
        (click)="deleteRoute(route.routeId)"
      >
        <div class="move-passengers-info__route-delete-icon"></div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="isLegendVisible"
  class="move-passengers-info__legends"
>
  <div class="move-passengers-info__legends-title-wrapper">
    <span class="move-passengers-info__legends-title">
      {{config.dictionary.legend.title | translate}}
    </span>
    <div
      (click)="closeLegend()"
      class="move-passengers-info__legends-close-icon"
    ></div>
  </div>
  <div
    *ngFor="let legend of config.legends"
    class="move-passengers-info__legend"
  >
    <img
      [src]="legend.iconUrl"
      class="move-passengers-info__legend-icon"
    />
    <div class="move-passengers-info__legend-title">
      {{legend.title | translate}}
    </div>
    <div
      *ngIf="legend.description"
      class="move-passengers-info__legend-description"
    >
      {{legend.description | translate}}
    </div>
  </div>
</div>
