import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of, Observable, Subscriber, from } from 'rxjs';
import { concatMap, first, reduce, switchMap, take, tap } from 'rxjs/operators';
import { UPopupService } from '@shift/ulib';

import { LocalizedToastrService } from '@app/shared/services';
import { ActivitiesService } from '@app/activities/services';
import { EmailsSendShuttleCompaniesChangesComponent, EmailsShuttleCompanyComponent } from '@app/emails/components';
import { emailsConfig } from '@app/emails/configs';
import {
  EmailsContentShuttleCompanyChangesParams,
  EmailsDataModalBackgroundParams,
  EmailsDataModalContentData,
  EmailsDataModalContentParams,
  EmailsDataModalParams,
  EmailsSendShuttleCompaniesChangesType
} from '@app/emails/models';
import { EmailsService } from './emails.service';

@Injectable({
  providedIn: 'root'
})
export class EmailsDataService {
  constructor(
    private localizedToastrService: LocalizedToastrService,
    private bsModalService: BsModalService,
    private emailsService: EmailsService,
    private activitiesService: ActivitiesService,
    private uPopupService: UPopupService
  ) {}

  getParamsForContent(shuttleCompanies: number[], emailDataContentParam: EmailsDataModalContentParams): EmailsContentShuttleCompanyChangesParams[] {
    return shuttleCompanies.map((shuttleCompanyId: number) => ({
      shuttleCompanyId,
      ...emailDataContentParam
    }));
  }

  confirmMessages(message: string): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.uPopupService.showMessage({
        message: `${message}`,
        yes: emailsConfig.dictionary.confirm
      },
      () => {
        subscriber.complete();
      },
      null,
      () => {
        subscriber.complete();
      });
    });
  }

  sendChangesFromBulkSend(data: EmailsDataModalContentData): void {
    this.sendSingleEmailChange(data)
      .pipe(
        take(data.shuttleCompanies.length)
      )
      .subscribe();
  }

  sendSingleEmailChange(data: EmailsDataModalContentData): Observable<void> {
    return this.getParamsSendSingleEmailChange({ ...data })
      .pipe(
        switchMap(({ sendBackground, params }) => {
          const { changesCount } = params;

          if (changesCount === 0) {
            return this.confirmMessages(emailsConfig.dictionary.notHaveChanges);
          }

          return !sendBackground ?
            from(params)
              .pipe(
                concatMap((obj: EmailsContentShuttleCompanyChangesParams) => this.emailsService.getContentShuttleCompanyChanges(obj)
                  .pipe(
                    switchMap(emailContent => this.openEmailsShuttleCompanyModal(emailContent))
                  )
                )
              ) :
            this.emailsService.sendBackgroundShuttleCompanyChanges(params)
              .pipe(
                tap(() => {
                  if (sendBackground) {
                    this.localizedToastrService.success(emailsConfig.dictionary.successful);
                  }
                })
              );
        })
      );
  }

  getParamsSendSingleEmailChange(data: EmailsDataModalContentData): any {
    const {
      changeSubjects,
      lastRouteAuditLogId,
      datesFiltrationType,
      shuttleCompanies,
      openForEdit,
      onlyLastChange,
      routeId,
      changeType,
      dates
    } = data;
    const contentParams: EmailsDataModalContentParams = {
      changeType: changeType === EmailsSendShuttleCompaniesChangesType.All ? null : data.changeType,
      from: dates.from,
      to: dates.to,
      days: dates.days,
      datesFiltrationType,
      changeSubjects,
      lastRouteAuditLogId,
      routeId,
      onlyLastChange
    };
    let shuttleCompanyParams: EmailsContentShuttleCompanyChangesParams[] = [];

    if (shuttleCompanies.length) {
      shuttleCompanyParams = this.getParamsForContent(shuttleCompanies, contentParams);
    }

    const shuttleCompaniesModalParam: EmailsDataModalParams = { sendBackground: false, params: shuttleCompanyParams };
    const shuttleCompaniesBackgroundParam: EmailsDataModalBackgroundParams = {
      sendBackground: true,
      params: {
        shuttleCompanyIds: shuttleCompanies,
        ...contentParams
      }
    };

    return openForEdit ? of(shuttleCompaniesModalParam) : of(shuttleCompaniesBackgroundParam);
  }

  openEmailsShuttleCompanyModal(emailContent): Observable<void> {
    const modalChangeRef = this.bsModalService.show(
      EmailsShuttleCompanyComponent,
      {
        class: 'u-modal u-modal_content',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: {
          emailContent
        }
      }
    );

    return modalChangeRef.content.hideEmail
      .pipe(first());
  }

  openEmailsSendShuttleCompaniesChangesModal() {
    this.bsModalService.show(
      EmailsSendShuttleCompaniesChangesComponent,
      {
        class: 'u-modal u-modal_app-emails-send-shuttle-companies-changes',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false
      }
    );
  }

  sendActivityEmails(activityId: number, cacheKeys: string[]): Observable<void[]> {
    return from(cacheKeys)
      .pipe(
        concatMap(cacheKey => this.activitiesService.getShuttleCompanyChanges(activityId, cacheKey)
          .pipe(
            switchMap(emailContent => this.openEmailsShuttleCompanyModal(emailContent))
          )
        ),
        reduce((acc, emailModal) => [ ...acc, emailModal ], [])
      );
  }
}
