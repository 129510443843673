import { TemplateRef } from '@angular/core';
import { PlacementArray } from '@shift/ulib/utils/positioning';

export enum GeneralOptionsType {
  AddNew = 'addNew'
}

export enum GeneralButtonProp {
  Disabled = 'disabled',
  Hidden = 'hidden'
}

export interface GeneralButton {
  id: string | number;
  title: string;
  uId?: string;
  withPlus?: boolean;
  medium?: boolean;
  popover?: {
    template: TemplateRef<HTMLElement>;
    placement: {
      ltr: PlacementArray;
      rtl: PlacementArray;
    };
    container?: string;
    class?: string;
    autoClose?: boolean | string;
  };
  [GeneralButtonProp.Disabled]?: boolean;
  [GeneralButtonProp.Hidden]?: boolean;
}
