import { BuilderRoutesState } from '@app/builder/models';

const defaultMessagesTable: string = 'uGridMessagesTable';

const initialState: BuilderRoutesState = {
  routes: [],
  search: '',
  activeRoute: {
    initialState: null,
    id: null
  },
  showBackdrop: false,
  routesFullChanged: false,
  grid: {
    columns: {
      filtered: false,
      resetFilter: false
    },
    rowClassObjects: [],
    messages: defaultMessagesTable,
    props: {}
  }
};

export const builderRoutesConfig = {
  defaultMessagesTable,
  initialState,
  tableName: 'builder-routes'
};
