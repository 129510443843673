import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { SignalRService } from '@app/shared/services';
import { UserNotificationBackgroundTaskError, UserNotificationDownloadRouteDetails } from '@app/user/models';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationHubService extends SignalRService {
  BASE_PATH = 'userNotificationHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onDownloadRouteDetails(): Observable<UserNotificationDownloadRouteDetails> {
    return this.onChannel<UserNotificationDownloadRouteDetails>('DownloadRouteDetails');
  }

  onBackgroundTaskError(): Observable<UserNotificationBackgroundTaskError> {
    return this.onChannel<UserNotificationBackgroundTaskError>('BackgroundTaskError');
  }
}
