const DICTIONARY_PATH: string = 'notes';

export const notesComponentConfig = {
  dateFormat: 'YYYY/M/D HH:m',
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    new: `${DICTIONARY_PATH}.new`,
    done: `${DICTIONARY_PATH}.done`,
    reminder: `${DICTIONARY_PATH}.reminder`,
    reminderTitle: `${DICTIONARY_PATH}.reminderTitle`,
    errors: {
      reminderInThePast: `${DICTIONARY_PATH}.errors.reminderInThePast`
    },
    confirm: 'general.confirm'
  }
};
