import { Pipe, PipeTransform } from '@angular/core';

import { MonitoringDashboardMonitoredPath } from '@app/monitoring-dashboard/models';

@Pipe({
  name: 'monitoringDashboardIsMonitored'
})
export class MonitoringDashboardIsMonitoredPipe implements PipeTransform {
  transform(memberId: number, monitoredPaths: MonitoringDashboardMonitoredPath[]): boolean {
    return monitoredPaths && monitoredPaths.some(monitoredPath => monitoredPath.memberId === memberId);
  }
}
