import { Pipe, PipeTransform } from '@angular/core';

import { BuilderActiveRideVehicle, BuilderDayOfWeekVehicle } from '@app/builder/models';

@Pipe({
  name: 'builderVehicleNumber'
})
export class BuilderVehicleNumberPipe implements PipeTransform {
  transform(vehicle: BuilderActiveRideVehicle | BuilderDayOfWeekVehicle): string {
    return vehicle?.carId === null ? vehicle.number: null;
  }
}

