import { Pipe, PipeTransform } from '@angular/core';

import { Activity, ActivityPassengerChange, ActivityPassengerChangesAffectRoutesType } from '@app/activities/models';
import { activitiesConfig } from '@app/activities/configs';

@Pipe({
  name: 'activitiesPassengerChangeExtraData'
})
export class ActivitiesPassengerChangeExtraDataPipe implements PipeTransform {
  transform(activity: Activity, passengerChange: ActivityPassengerChange): string {
    if (
      passengerChange.type === ActivityPassengerChangesAffectRoutesType.SchoolChanged &&
      !activity.additionalData.isRouteUpdateFailed &&
      passengerChange.doesAffectRoutes
    ) {
      return activitiesConfig.dictionary.destinationWasAdded;
    }

    if (
      [
        ActivityPassengerChangesAffectRoutesType.AddressChanged,
        ActivityPassengerChangesAffectRoutesType.SchoolChanged,
        ActivityPassengerChangesAffectRoutesType.PickUpTypeChanged,
        ActivityPassengerChangesAffectRoutesType.StationChanged
      ].includes(passengerChange.type)
    ) {
      if (passengerChange.doesAffectRoutes && activity.additionalData.isRouteUpdateFailed) {
        return activitiesConfig.dictionary.wasNotAppliedOnTheRoute;
      }
    }

    if (!passengerChange.doesAffectRoutes) {
      return activitiesConfig.dictionary.notAffectingTheRoute;
    }

    return null;
  }
}
