import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isEqual, omit } from 'lodash';
import { USelectSItem } from '@shift/ulib';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { ModalActions } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { RouteDirection } from '@app/routes/models';
import {
  BuilderDuplicateRouteAction,
  BuilderDuplicateRouteForm,
  BuilderRoute,
  BuilderTimeType
} from '@app/builder/models';
import { builderDuplicateRouteComponentConfig } from './builder-duplicate-route.component.config';

@Component({
  selector: 'app-builder-duplicate-route',
  templateUrl: './builder-duplicate-route.component.html',
  styleUrls: [ './builder-duplicate-route.component.scss', './builder-duplicate-route.component.rtl.scss' ]
})
export class BuilderDuplicateRouteComponent implements OnInit, OnDestroy {
  @Output() action: EventEmitter<BuilderDuplicateRouteAction> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-duplicate-route';

  private unsubscribe: Subject<void> = new Subject();

  activeRoute: BuilderRoute;
  form: UntypedFormGroup;
  directions: USelectSItem[] = builderDuplicateRouteComponentConfig.directions;
  presetsStore: USelectSItem[] = builderDuplicateRouteComponentConfig.presetsStore;
  presets: USelectSItem[] = this.presetsStore;
  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private localizationService: LocalizationService,
    public trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.initForm({
      routeId: this.activeRoute.routeId,
      name: this.activeRoute.name,
      isAutoGeneratedName: true,
      direction: this.activeRoute.direction,
      startTime: this.activeRoute.rideStartDateTime,
      endTime: this.activeRoute.rideEndDateTime,
      timeType: this.activeRoute.timeType,
      preset: 'all',
      startDate: this.activeRoute.startDate,
      endDate: this.activeRoute.endDate,
      dates: [ this.activeRoute.startDate, this.activeRoute.endDate ]
    });

    this.updatePresets();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initForm(data: BuilderDuplicateRouteForm): void {
    const form = this.formBuilder.group({
      routeId: [ data.routeId ],
      name: [ data.name, [ Validators.required ] ],
      isAutoGeneratedName: [ data.isAutoGeneratedName ],
      direction: [ data.direction ],
      startTime: [ data.timeType === BuilderTimeType.StartTime ? data.startTime : null ],
      endTime: [ { value: data.timeType === BuilderTimeType.EndTime ? data.endTime : null, disabled: data.direction === RouteDirection.Backward } ],
      timeType: [ data.timeType ],
      preset: [ data.preset ],
      startDate: [ data.startDate, [ Validators.required ] ],
      endDate: [ data.endDate, [ Validators.required ] ],
      dates: [ data.dates ]
    });

    const direction = form.get('direction');
    const name = form.get('name');
    const isAutoGeneratedName = form.get('isAutoGeneratedName');
    const preset = form.get('preset');
    const startTime = form.get('startTime');
    const endTime = form.get('endTime');
    const timeType = form.get('timeType');
    const dates = form.get('dates');

    direction
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: number) => {
        // if direction changed and name didn't change by user then change order of elements
        if (!name.dirty && name.value.includes(builderDuplicateRouteComponentConfig.routeNameDivider)) {
          const nameNew: string = name.value
            .split(builderDuplicateRouteComponentConfig.routeNameDivider)
            .map((el: string) => el.replace(/\s/g, ''))
            .reverse()
            .join(` ${builderDuplicateRouteComponentConfig.routeNameDivider} `);

          name.setValue(nameNew);
        }

        if (value === RouteDirection.Forward) {
          endTime.enable();
        }

        if (value === RouteDirection.Backward) {
          endTime.disable();

          if (timeType.value === BuilderTimeType.EndTime) {
            startTime.patchValue(endTime.value);
          }
        }
      });

    name
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        isAutoGeneratedName.patchValue(false);
      });

    preset
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: string) => {
        dates.patchValue(this.selectDatesByPreset(value));
      });

    startTime
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        if (value) {
          endTime.patchValue(null);
          timeType.patchValue(BuilderTimeType.StartTime);
        }
      });

    endTime
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        if (value) {
          startTime.patchValue(null);
          timeType.patchValue(BuilderTimeType.EndTime);
        }
      });

    dates
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe((items: string[]) => {
        if (items.length) {
          form.patchValue({
            startDate: items[0],
            endDate: items[items.length - 1]
          });

          const detectedPresets: string[] = this.detectPossiblePresetsByDates(items);

          if (!detectedPresets.includes(preset.value)) {
            preset.patchValue(detectedPresets[0], { emitEvent: false });
          }
        } else {
          form.patchValue({
            startDate: null,
            endDate: null
          });
        }
      });

    this.form = form;
  }

  updatePresets(): void {
    const today = moment().startOf('day');
    const startDate = moment(this.activeRoute.startDate).startOf('day');
    const endDate = moment(this.activeRoute.endDate).startOf('day');

    if (!today.isBetween(startDate, endDate, null, '[]')) {
      this.presets = this.presetsStore
        .filter(preset => preset.value !== 'today' && preset.value !== 'fromTodayOn');
    }
  }

  selectDatesByPreset(name: string): string[] {
    const startDate: string = this.activeRoute.startDate;
    const endDate: string = this.activeRoute.endDate;
    let dates: string[] = [];

    switch (name) {
      case 'all': {
        const dateStart: string = moment(startDate).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
        const dateEnd: string = moment(endDate).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

        dates = [ dateStart, dateEnd ];

        break;
      }

      case 'today': {
        const dateStart = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);

        dates = [ dateStart, dateStart ];

        break;
      }

      case 'fromTodayOn': {
        const dateStart = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);
        const dateEnd: string = moment(endDate).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

        dates = [ dateStart, dateEnd ];

        break;
      }

      case 'custom': {
        dates = [];

        break;
      }

      default: { break; }
    }

    return dates;
  }

  detectPossiblePresetsByDates(dates: string[]): string[] {
    const presets: string[] = [];

    if (isEqual(dates, this.selectDatesByPreset('all'))) {
      presets.push('all');
    }

    if (isEqual(dates, this.selectDatesByPreset('today'))) {
      presets.push('today');
    }

    if (isEqual(dates, this.selectDatesByPreset('fromTodayOn'))) {
      presets.push('fromTodayOn');
    }

    presets.push('custom');

    return presets;
  }

  close(): void {
    this.bsModalRef.hide();
    this.action.emit({ type: ModalActions.Close });
  }

  save(close: boolean): void {
    const { value } = this.form;
    const body: any = {
      ...omit(value, [ 'startTime', 'endTime', 'preset', 'dates' ]),
      time: value.timeType === BuilderTimeType.StartTime ? value.startTime : value.endTime
    };

    let type: string = 'save';

    if (close) {
      this.trackingService.track('[Route planner, duplicate route] - click on save');

      type = 'saveAndClose';
    } else {
      this.trackingService.track('[Route planner, duplicate route] - click on edit');
    }

    this.action.emit({ type, body });
  }
}
