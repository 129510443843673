import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, OnDestroy, Output } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AppConstants } from '@app/shared/constants';
import { NotesService } from '@app/notes/services';
import { NotesItem } from '@app/notes/models';
import { Reminder } from '@app/reminder/models';
import { RemindersService } from '@app/reminder/services';
import { reminderComponentConfig } from './reminder.component.config';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: [ './reminder.component.scss', './reminder.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ TranslateModule, DatePipe ]
})
export class ReminderComponent implements OnDestroy {
  @Output() closeAction: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'reminder';

  private unsubscribe: Subject<void> = new Subject();

  note: NotesItem;
  reminder: Reminder;
  appConstants = AppConstants;
  config = cloneDeep(reminderComponentConfig);

  constructor(
    private notesService: NotesService,
    private remindersService: RemindersService
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private deleteReminder() {
    this.remindersService.deleteReminder({ reminderId: this.reminder.iReminderId })
      .pipe(
        first(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.closeAction.emit());
  }

  deleteNoteAndReminder() {
    if (!this.note) {
      this.deleteReminder();

      return;
    }

    this.notesService.deleteNoteAndReminder(this.note.iNoteId, this.reminder.iReminderId)
      .pipe(
        first(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.closeAction.emit());
  }

  deleteReminderOnly() {
    if (!this.note) {
      this.deleteReminder();

      return;
    }

    this.remindersService.deleteNoteReminder({
      iNoteId: this.note.iNoteId,
      iReminderId: this.reminder.iReminderId
    })
      .pipe(
        first(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.closeAction.emit());
  }
}
