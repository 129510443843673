import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RoutesEffects } from '@app/routes/state/effects';
import * as routesReducers from '@app/routes/state/reducers';
import { RoutesFacade } from '@app/routes/state/facades';

@NgModule({
  imports: [
    StoreModule.forFeature(routesReducers.FEATURE_KEY, routesReducers.reducers),
    EffectsModule.forFeature([ RoutesEffects ])
  ],
  providers: [ RoutesFacade ]
})
export class RoutesStateModule {}
