import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, withLatestFrom, takeUntil, concatMap, delay } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { HeaderDataService } from '@app/shared/services';
import { NavigationPaths } from '@app/shared/models';
import * as RideOrdersActions from '@app/ride-orders/state/actions';
import * as RideOrdersSelectors from '@app/ride-orders/state/selectors';
import { RideOrdersTableHubService, RideOrdersTableService } from '@app/ride-orders/services';
import { ActivitiesDataService } from '@app/activities/services';
import { ActivityUpdate, ActivityType } from '@app/activities/models';
import { RideOrder } from '@app/ride-orders/models';

@Injectable()
export class RideOrdersEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideOrdersActions.init),
      withLatestFrom(this.store.select(RideOrdersSelectors.selectRideOrdersInitializedState)),
      filter(([ , initialized ]) => !initialized),
      map(() => RideOrdersActions.initialized())
    )
  );

  itemsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideOrdersActions.itemsInit),
      withLatestFrom(this.store.select(RideOrdersSelectors.selectRideOrdersItemsState)),
      filter(([ , items ]) => !items),
      map(([ { params } ]) => RideOrdersActions.load({ params }))
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideOrdersActions.load),
      switchMap(({ params }) =>
        this.rideOrdersTableService.getRideOrders(params)
          .pipe(takeUntil(this.actions$.pipe(ofType(RideOrdersActions.loadCancel))))
      ),
      map((data) => RideOrdersActions.loadSuccess({ items: data }))
    )
  );

  hubInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideOrdersActions.initialized),
      switchMap(() => {
        this.rideOrdersTableHubService.init();

        return from(this.rideOrdersTableHubService.start());
      }),
      map(() => RideOrdersActions.hubInitialized())
    )
  );

  updateRideOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideOrdersActions.initialized),
      switchMap(() => this.rideOrdersTableHubService.onUpdateRideOrderStatus()),
      withLatestFrom(this.store.select(RideOrdersSelectors.selectRideOrdersItemsState)),
      filter(([ { routeId }, items ]) => !!items && items.some(item => item.routeId === routeId)),
      map(([ data ]) => RideOrdersActions.updateRideOrderStatus(data))
    )
  );

  itemsReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideOrdersActions.initialized),
      switchMap(() => this.headerDataService.dateForm.valueChanges),
      filter(() => this.router.url !== `/${NavigationPaths.RideOrders}`),
      map(() => RideOrdersActions.itemsReset())
    )
  );

  activitiesUpdateInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RideOrdersActions.initialized),
      switchMap(() =>
        this.activitiesDataService.activitiesUpdate$
          .pipe(
            withLatestFrom(this.store.select(RideOrdersSelectors.selectRideOrdersItemsState)),
            filter(items => items.every(item => !!item?.length)),
            map(([ activitiesUpdate, rideOrders ]: [ ActivityUpdate[], RideOrder[] ]) => {
              const savedRideOrderIds: number[] = activitiesUpdate.reduce((acc, activity) => ([
                ...acc,
                ...(activity.type === ActivityType.RouteSaveSuccess ? [ activity.routeId ] : [])
              ]), []);
              const updatedRideOrders = rideOrders.map(rideOrder => {
                const rideOrderActivitiesUpdate = activitiesUpdate.filter(activity => activity.routeId === rideOrder.routeId);

                if (rideOrderActivitiesUpdate.length) {
                  return {
                    locked: this.activitiesDataService.isLockedRoute(null, rideOrderActivitiesUpdate),
                    routeId: rideOrder.routeId
                  };
                }

                return rideOrder;
              });

              this.store.dispatch(RideOrdersActions.updateItems({ items: updatedRideOrders as RideOrder[] }));

              return { savedRideOrderIds };
            }),
            filter(data => !!data.savedRideOrderIds.length),
            delay(2000),
            concatMap(({ savedRideOrderIds }) => {
              if (savedRideOrderIds.length) {
                return of(savedRideOrderIds)
                  .pipe(
                    withLatestFrom(
                      this.store.select(RideOrdersSelectors.selectRideOrdersParamsState)
                    ),
                    concatMap(([ routeIds, params ]) =>
                      this.rideOrdersTableService.getRideOrders({ ...params, routeIds })
                    ),
                    filter(data => !!data),
                    map((items) => RideOrdersActions.updateItems({ items }))
                  );
              }
            })
          )
      )
    )
  );

  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly headerDataService: HeaderDataService,
    private readonly activitiesDataService: ActivitiesDataService,
    private readonly rideOrdersTableHubService: RideOrdersTableHubService,
    private readonly rideOrdersTableService: RideOrdersTableService
  ) {}
}
