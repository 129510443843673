<div class="routes-change-time-tracking__title">
  {{(supervisorType === supervisorTypes.Commander ? config && config.dictionary.titleCommander : config && config.dictionary.title) | translate}}
</div>

<u-group
  class="u-group_app-routes-change u-group_flex"
  [name]="config.dictionary.start | translate"
>
  <div class="routes-change-time-tracking__item">
    <div class="routes-change-time-tracking__item-field">
      <u-input-date
        [container]="'body'"
        [showIcon]="false"
        [classUInput]="'u-input_text-center u-input_cursor-pointer'"
        [formControl]="form.get('startDate')"
        [placement]="[ 'bottom-right' ]"
        [messages]="'uInputDate' | translate">
      </u-input-date>
    </div>
    <div class="routes-change-time-tracking__item-field">
      <u-input-time
        [class]="'u-input-time_center'"
        [formControl]="form.controls['startTime']"
      ></u-input-time>
    </div>
  </div>
</u-group>
<u-group
  class="u-group_app-routes-change u-group_flex"
  [name]="config.dictionary.end | translate"
>
  <div class="routes-change-time-tracking__item">
    <div class="routes-change-time-tracking__item-field">
      <u-input-date
        [container]="'body'"
        [showIcon]="false"
        [classUInput]="'u-input_text-center u-input_cursor-pointer'"
        [formControl]="form.get('endDate')"
        [placement]="[ 'bottom-right' ]"
        [messages]="'uInputDate' | translate">
      </u-input-date>
    </div>
    <div class="routes-change-time-tracking__item-field">
      <u-input-time
        [class]="'u-input-time_center'"
        [formControl]="form.controls['endTime']"
      ></u-input-time>
    </div>
  </div>
</u-group>
<u-group
  class="u-group_app-routes-change u-group_flex"
  [name]="config.dictionary.comments | translate"
>
  <textarea uTextarea [formControl]="form.controls['comment']"></textarea>
</u-group>

<div class="routes-change-time-tracking__buttons">
  <button
    uButton
    (click)="saveChanges()"
  >
    {{config.dictionary.saveAndClose | translate}}
  </button>
</div>
