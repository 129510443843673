import { EntityModalButton } from '@shift/ulib';

import { AddEditModalAction } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.routePolicyTabs';

const dictionary = {
  routePolicy: `${DICTIONARY_PATH}.title`,
  citiesPricing: `${DICTIONARY_PATH}.pricesByCities`,
  citiesCombinations: `${DICTIONARY_PATH}.citiesCombinations`,
  attention: `${DICTIONARY_PATH}.attention`,
  availableCarTypes: `${DICTIONARY_PATH}.availableCarTypes`,
  uSelectS: 'uSelectS',
  uMultiselect: 'uMultiselect',
  select: 'general.select'
};

const buttons: EntityModalButton[] = [
  { name: `${DICTIONARY_PATH}.apply`, action: AddEditModalAction.Submit, clear: false }
];

export const routePolicyTabsConfig = {
  buttons,
  dictionary
};
