import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appScrollToEl]',
  standalone: true
})
export class ScrollToElDirective implements OnInit {
  @Input() scrollToEl: boolean;

  constructor(
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (this.scrollToEl) {
      this.el.nativeElement.scrollIntoView();
    }
  }
}
