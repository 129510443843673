const DICTIONARY_PATH: string = 'monitoringDashboard';

export const monitoringDashboardComponentConfig = {
  dictionary: {
    anonymousAccompany: 'general.anonymousAccompany',
    search: 'general.search',
    rides: {
      title: `${DICTIONARY_PATH}.rides.title`
    },
    details: {
      openAllStations: `${DICTIONARY_PATH}.details.openAllStations`,
      closeAllStations: `${DICTIONARY_PATH}.details.closeAllStations`
    }
  }
};
