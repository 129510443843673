import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiService } from '@app/shared/services/api.service';
import {
  ShuttleCompanyFineNew,
  ShuttleCompanyFineData
} from '@app/shuttle-companies/models';

@Injectable({
  providedIn: 'root'
})
export class ShuttleCompanyFinesService {
  private BASE_URL: string = 'ShuttleCompanyFines';

  constructor(private apiService: ApiService) { }

  getFines(params: any): Observable<any> {
    return this.apiService.get(this.BASE_URL, params);
  }

  getShuttleCompanyData(routeId: number, rideDate: string): Observable<ShuttleCompanyFineData> {
    return this.apiService.get(`${this.BASE_URL}/ShuttleCompanyData`, { routeId, rideDate });
  }

  createFine(fine: ShuttleCompanyFineNew): Observable<void> {
    return this.apiService.post(this.BASE_URL, fine);
  }

  deleteFine(params: any): Observable<any> {
    return this.apiService.delete(this.BASE_URL, params);
  }
}
