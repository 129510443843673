import { Component, OnInit, ViewChild, TemplateRef, Input, HostBinding, inject, DestroyRef } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep } from 'lodash';

import { appConfig } from '@app/shared/configs';
import { AppLanguage } from '@app/shared/models';
import { LocalizationService } from '@app/shared/services';
import { BuilderConfig } from '@app/builder/models';
import { RouteAuditService } from '@app/route-audit/services';
import { RideChangeType } from '@app/routes/models';
import { builderHistoryComponentConfig } from './builder-history.component.config';

@Component({
  selector: 'app-builder-history',
  templateUrl: './builder-history.component.html',
  styleUrls: [ './builder-history.component.scss', './builder-history.component.rtl.scss' ]
})
export class BuilderHistoryComponent implements OnInit {
  @Input() builderDataConfig: BuilderConfig;
  @Input() routeId: number;

  @HostBinding('class') hostClasses: string = 'builder-history';

  @ViewChild('isPlannedChange', { static: true }) public isPlannedChange: TemplateRef<any>;
  @ViewChild('oldNewChange', { static: true }) public oldNewChange: TemplateRef<any>;
  @ViewChild('period', { static: true }) public period: TemplateRef<any>;
  @ViewChild('executor', { static: true }) public executor: TemplateRef<any>;
  @ViewChild('approval', { static: true }) public approval: TemplateRef<any>;

  private readonly destroyRef = inject(DestroyRef);
  private readonly routeAuditService = inject(RouteAuditService);
  private readonly localizationService = inject(LocalizationService);

  rideChangeType = RideChangeType;
  rows = [];
  isRtl = true;
  columns = [];
  showApprovals = false;
  lang: AppLanguage = appConfig.defaultLanguage;
  config = cloneDeep(builderHistoryComponentConfig);

  ngOnInit() {
    this.columns = [
      { name: 'builder.history.type', prop: 'isPlanned', minWidth: 72, width: 72, resizeable: true, cellTemplate: this.isPlannedChange },
      { name: 'builder.history.changeType', prop: 'typeName', minWidth: 102, width: 120, resizeable: true },
      { name: 'builder.history.detail', prop: 'oldNewChange', minWidth: 128, resizeable: true, cellTemplate: this.oldNewChange, cellClass: this.addTwoLinesClass },
      { name: 'builder.history.period', prop: 'period', minWidth: 128, resizeable: true, cellTemplate: this.period, cellClass: this.addTwoLinesClass, comparator: this.sortPeriod.bind(this) },
      { name: 'builder.history.executor', prop: 'changeAuthorName', minWidth: 106, width: 125, resizeable: true, cellTemplate: this.executor, cellClass: this.addTwoLinesClass,  comparator: this.sortExecutor.bind(this) },
      { name: 'builder.history.driver', prop: 'approvePersonName', minWidth: 106, width: 125, resizeable: true },
      { name: 'builder.history.driverApproval', prop: 'driverApproval', minWidth: 106, width: 125, resizeable: true, cellTemplate: this.approval, cellClass: this.addTwoLinesClass }
    ];

    this.isRtl = this.localizationService.isRtl();
    this.lang = this.localizationService.getLanguage();

    this.getData();
  }

  getData() {
    this.routeAuditService.getRouteAudit(this.routeId)
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(res => {
        this.showApprovals = res.showApprovals;
        this.rows = res.auditRecords;

        if (!this.showApprovals) {
          this.columns = this.columns.filter(e => e.prop !== 'driverApproval');
        }
      });
  }

  addTwoLinesClass({ row, column }) {
    const columnName = column.prop;
    let addClass = false;

    switch (columnName) {
      case 'oldNewChange': row.oldValue && row.newValue ? addClass = true : addClass = false; break;
      case 'period': row.periodStart && row.periodEnd && row.newValue ? addClass = true : addClass = false; break;
      case 'changeAuthorName': row.changeAuthorName && row.changeDate ? addClass = true : addClass = false; break;
      case 'driverApproval': row.approveStatus && row.approveStatusSetDate ? addClass = true : addClass = false; break;
    }

    return { 'two-lines-padding' : addClass };
  }

  sortPeriod(propA, propB, rowA, rowB) {
    const startPeriodA = rowA.periodStart;
    const startPeriodB = rowB.periodStart;

    if (moment(startPeriodA).isBefore(startPeriodB)) {
      return -1;
    } else if (moment(startPeriodA).isSame(startPeriodB)) {
      return 0;
    } else {
      return 1;
    }
  }

  sortExecutor(propA, propB, rowA, rowB) {
    const changeDateA = rowA.changeDate;
    const changeDateB = rowB.changeDate;

    if (moment(changeDateA).isBefore(changeDateB)) {
      return -1;
    } else if (moment(changeDateA).isSame(changeDateB)) {
      return 0;
    } else {
      return 1;
    }
  }
}
