import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-builder-popup',
  templateUrl: './builder-popup.component.html',
  styleUrls: [ './builder-popup.component.scss', './builder-popup.component.rtl.scss' ]
})
export class BuilderPopupComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-popup';

  options: {
    message: string,
    ok: string,
    cancel: string
  };

  constructor(
    public popupRef: BsModalRef
  ) {}

  ok() {
    this.popupRef.hide();
    this.action.emit({ result: 'ok' });
  }

  cancel() {
    this.popupRef.hide();
    this.action.emit({ result: 'cancel' });
  }
}
