import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { first, switchMap, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { UPopoverDirective, UPopupService } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { AppLanguage } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { AuthDataService } from '@app/auth/services';
import { AuthUserInfo } from '@app/auth/models';
import { Reminder, ReminderNote } from '@app/reminder/models';
import { ReminderDataService, RemindersService } from '@app/reminder/services';
import { NotesItemWithReminder } from '@app/notes/models';
import { NotesService } from '@app/notes/services';
import { notesComponentConfig } from './notes.component.config';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: [ './notes.component.scss', './notes.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesComponent implements OnInit, OnDestroy {
  @Output() closeNotes: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'notes';

  private unsubscribe: Subject<void> = new Subject();

  selectedNoteIndex: number;
  reminderPopover: UPopoverDirective;
  notes: FormArray = new FormArray([]);
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  authUserInfo: AuthUserInfo;
  config = cloneDeep(notesComponentConfig);

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private uPopupService: UPopupService,
    private localizationService: LocalizationService,
    private authDataService: AuthDataService,
    private remindersService: RemindersService,
    private reminderDataService: ReminderDataService,
    private notesService: NotesService
  ) {}

  ngOnInit() {
    this.initAuthUserInfo();
    this.fetchNotes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initAuthUserInfo() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(userInfo => this.authUserInfo = userInfo);
  }

  private fetchNotes() {
    this.notesService.getAllNotes()
      .pipe(
        first(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(notes => {
        notes.reverse().forEach(note => {
          const reminderDate = note.dtReminder ? moment(note.dtReminder) : moment();

          this.notes.push(this.fb.group({
            id: note.iNoteId,
            text: [ note.nvMessage ],
            reminder: this.fb.group({
              id: [ note.iReminderId || -1 ],
              date: [ [ reminderDate ], Validators.required ],
              time: [ reminderDate && reminderDate.format(AppConstants.TIME_FORMAT), Validators.required ],
              dateToDisplay: [ note.dtReminder && reminderDate.format(`${AppConstants.DATE_FORMAT_BASE_SLASH} ${AppConstants.TIME_FORMAT}`) ]
            })
          }));
        });

        this.cdRef.markForCheck();
      });
  }

  private saveNewNote(noteForm: FormGroup) {
    noteForm.markAsPristine();

    this.notesService.saveNewNote({
      nvMessage: noteForm.value.text,
      dtCreateDate: moment().format(this.config.dateFormat),
      iPersonId: this.authUserInfo.person.personId
    })
      .pipe(
        first(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(createdNote => {
        this.reminderDataService.notes.push(createdNote);

        noteForm.addControl('id', new FormControl(createdNote.iNoteId));

        this.cdRef.markForCheck();
      });
  }

  private editNote(noteForm: FormGroup) {
    this.notesService.editNote({
      iNoteId: noteForm.value.id,
      nvMessage: noteForm.value.text,
      dtCreateDate: moment().format(this.config.dateFormat),
      iPersonId: this.authUserInfo.person.personId
    })
      .pipe(
        first(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => noteForm.markAsPristine());
  }

  private removeEmptyNote(index: number) {
    const reminderPopoverClosed = this.reminderPopover ? !this.reminderPopover.isOpen() : true;
    const noteEmpty = this.notes.at(index) && !this.notes.at(index).value.text;

    if (reminderPopoverClosed && noteEmpty) {
      this.notes.removeAt(index);

      this.cdRef.markForCheck();
    }
  }

  private addReminder(reminder: Reminder, note: NotesItemWithReminder, noteIndex: number) {
    this.remindersService.addReminder(reminder)
      .pipe(
        first(),
        takeUntil(this.unsubscribe),
        switchMap(createdReminder => {
          const noteReminder: ReminderNote = {
            iNoteId: note.id,
            dtReminder: reminder.dtDateTime,
            iReminderId: createdReminder.iReminderId
          };

          return forkJoin([ of(createdReminder), this.remindersService.addNoteReminder(noteReminder) ]);
        })
      )
      .subscribe(([ createdReminder ]) => {
        this.notes.at(noteIndex).get('reminder').patchValue({
          id: createdReminder.iReminderId,
          dateToDisplay: moment(createdReminder.dtDateTime).format(`${AppConstants.DATE_FORMAT_BASE_SLASH} ${AppConstants.TIME_FORMAT}`)
        });

        this.reminderDataService.reminders.push(createdReminder);
        this.reminderDataService.notes.find(noteObj => note.id === noteObj.iNoteId).iReminderId = createdReminder.iReminderId;

        this.cdRef.markForCheck();
      });
  }

  addNewNote(event: MouseEvent) {
    event.stopPropagation();

    this.removeEmptyNote(0);

    const note = this.fb.group({
      text: [],
      reminder: this.fb.group({
        id: [ -1 ],
        dateToDisplay: [],
        date: [ [ moment() ], Validators.required ],
        time: [ moment().format(AppConstants.TIME_FORMAT), Validators.required ]
      })
    });

    this.notes.insert(0, note);

    this.selectedNoteIndex = 0;

    this.cdRef.markForCheck();
  }

  onClickInsideNote(index: number, noteTextarea: HTMLElement) {
    this.selectedNoteIndex = index;

    noteTextarea.style.height = `${noteTextarea.scrollHeight}px`;
  }

  onClickOutsideNote(index: number, noteTextarea: HTMLElement) {
    if (this.selectedNoteIndex === index) {
      this.removeEmptyNote(index);

      const noteForm = this.notes.at(index) as FormGroup;

      if (noteForm && noteForm.dirty) {
        if (!noteForm.value.id) {
          this.saveNewNote(noteForm);
        } else if (noteForm.get('text').dirty) {
          this.editNote(noteForm);
        }
      }
    }

    noteTextarea.style.height = '64px';
  }

  openReminder(popover: UPopoverDirective, noteIndex: number) {
    if (this.reminderPopover && this.reminderPopover.isOpen()) {
      this.reminderPopover.close();
    }

    this.reminderPopover = popover;
    this.selectedNoteIndex = noteIndex;
  }

  closeReminder(event: MouseEvent) {
    this.reminderPopover.close();

    event.stopPropagation();
  }

  removeNote(index: number, event: MouseEvent) {
    event.stopPropagation();

    const noteValue = this.notes.at(index).value;

    if (noteValue.text && noteValue.id) {
      this.notesService.deleteNoteAndReminder(noteValue.id, noteValue.reminder.id)
        .pipe(
          first(),
          takeUntil(this.unsubscribe)
        )
        .subscribe(() => {
          this.notes.removeAt(index);

          this.cdRef.markForCheck();
        });
    } else {
      this.notes.removeAt(index);

      this.cdRef.markForCheck();
    }
  }

  addNoteReminder(noteIndex: number) {
    if (this.notes.at(noteIndex).get('reminder').valid) {
      const note = this.notes.at(noteIndex).value;
      const reminder: Reminder = {
        iReminderId: -1,
        nvDescription: note.text,
        iPersonId: this.authUserInfo.person.personId,
        dtDateTime: moment(
          note.reminder.date[0].format(AppConstants.DATE_FORMAT_BASE_LINE) + ' ' + note.reminder.time
        ).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE)
      };

      if (moment(reminder.dtDateTime).isSameOrBefore(moment())) {
        this.uPopupService.showErrorMessage({ message: this.config.dictionary.errors.reminderInThePast });
      } else {
        this.addReminder(reminder, note, noteIndex);
      }
    }
  }

  trackById(index: number, note: FormControl) {
    return note.value.id;
  }
}
