import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RouteShuttleCompaniesDates } from '@app/route-shuttle-companies/models';

@Injectable({
  providedIn: 'root'
})
export class RouteShuttleCompaniesService {
  private BASE_PATH: string = 'RouteShuttleCompanies';

  constructor(
    private apiService: ApiService
  ) {}

  getRouteShuttleCompaniesDates(routeId: number): Observable<RouteShuttleCompaniesDates> {
    return this.apiService.get(`${this.BASE_PATH}/Dates`, { routeId });
  }
}
