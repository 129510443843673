import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { GeneralCardButton, GeneralCardButtonValue } from '@app/shared/models';

@Component({
  selector: 'app-general-card',
  templateUrl: './general-card.component.html',
  styleUrls: [ './general-card.component.scss', './general-card.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralCardComponent {
  @Input() title: string;
  @Input() closeIconId: string;
  @Input() buttons: GeneralCardButton[] = [];

  @Output() buttonClick: EventEmitter<GeneralCardButtonValue> = new EventEmitter();
  @Output() closeAction: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'general-card';
}
