<div class="builder-days-copy__wrapper">
  <!--
  <div class="builder-days-copy__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  -->
  <div class="builder-days-copy__main">
    <div class="builder-days-copy__message">
      {{'builder.daysCopy.message' | translate}}
    </div>
    <div class="builder-days-copy__list" (click)="$event.stopPropagation()">
      <div 
        class="builder-days-copy__item" 
        *ngFor="let item of checkboxes"
      > 
        <div class="builder-days-copy__item-content">
          <div class="builder-days-copy__item-checkbox">
            <u-checkbox class="u-checkbox_square" [ngModel]="item.check" (ngModelChange)="checkItem(item)"></u-checkbox>
          </div>
          <div class="builder-days-copy__item-name">{{item.name | translate}}</div>
        </div>
        <div class="builder-days-copy__item-select" *ngIf="item.value === 'duplicate'">
          <u-select-s
            [items]="days"
            [(ngModel)]="dayActive"
            [empty]="false"
            [placeholder]="'uSelectS.placeholder' | translate"
          ></u-select-s>
        </div>
      </div>
    </div>
    <div class="builder-days-copy__actions">
      <button class="builder-days-copy__actions-btn" (click)="apply()">{{'builder.daysCopy.apply' | translate}}</button>
    </div> 
  </div>
</div>