import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import { SharedModule } from '@app/shared/shared.module';
import { TablePageService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuditHistory, AuditHistoryRow } from '@app/shared/models';
import { auditHistoryComponentConfig } from './audit-history.component.config';

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrl: './audit-history.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [ TablePageService ],
  imports: [ SharedModule ]
})
export class AuditHistoryComponent implements OnInit {
  @Input() form: FormControl<AuditHistory[]>;

  @ViewChild('contentCell', { static: true }) public contentCell: TemplateRef<any>;

  @HostBinding('class') hostClasses: string = 'audit-history';

  private readonly destroyRef = inject(DestroyRef);
  private readonly tablePageService = inject(TablePageService);

  readonly #config = signal(cloneDeep(auditHistoryComponentConfig));

  readonly config = this.#config.asReadonly();

  ngOnInit() {
    this.updateTableConfig();
    this.onFormChanges();
  }

  private updateTableConfig() {
    this.#config.set({
      ...this.#config(),
      tablePageConfig: {
        ...this.#config().tablePageConfig,
        tableConfig: {
          ...this.#config().tablePageConfig.tableConfig,
          cellTemplates: {
            contentCell: this.contentCell
          },
          cellClassFns: {
            twoLinesClass: this.twoLinesClass
          }
        }
      }
    });
  }

  private onFormChanges() {
    this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(items =>
        this.tablePageService.initRows(
          <AuditHistoryRow[]>items?.map(item => ({
            ...item,
            dateTime: item.dateTimeUtc && moment.utc(item.dateTimeUtc).local().format(AppConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME)
          }))
        )
      );
  }

  twoLinesClass = ({ row }) => ({ 'two-lines-padding': !!(row.newValue && row.originalValue) });
}
