import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

import { AppConstants } from '@app/shared/constants';
import { BuilderRoute } from '@app/builder/models';

@Pipe({
  name: 'builderRoutesPeriod'
})
export class BuilderRoutesPeriodPipe implements PipeTransform {
  transform(route: BuilderRoute): string {
    const startDate = moment(route.startDate, AppConstants.DATE_FORMAT_BASE_LINE).format(AppConstants.DATE_FORMAT_BASE_SLASH);

    return route.startDate !== route.endDate ? `${startDate} - ${moment(route.endDate, AppConstants.DATE_FORMAT_BASE_LINE).format(AppConstants.DATE_FORMAT_BASE_SLASH)}` :  startDate;
  }
}
