import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CustomerData, CustomerDataType } from '@app/shared/models';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService {
  private readonly apiService = inject(ApiService);

  private BASE_PATH: string = 'CustomerData';

  getCustomerData(params: { types: CustomerDataType[]; }): Observable<CustomerData> {
    return this.apiService.get(this.BASE_PATH, params);
  }
}
