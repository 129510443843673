import { computed, inject, Injectable, signal } from '@angular/core';
import { exhaustMap, filter, map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

import { patchSignal, rxMethod, tapResponse } from '@app/shared/utils';
import { BuilderService } from '@app/builder/services';
import { BuilderPassengersState } from '@app/builder/models';

@Injectable()
export class BuilderPassengersStoreService {
  private readonly builderService = inject(BuilderService);

  readonly #state = signal<BuilderPassengersState>({ mobiles: {} });

  readonly state = this.#state.asReadonly();
  readonly mobiles = computed(() => this.state().mobiles);
  readonly mobileByPassengerId = (passengerId: number) => computed(() => this.mobiles()[passengerId]);

  readonly mobiles$ = toObservable(this.mobiles);
  readonly mobileByPassengerId$ = (passengerId: number) => this.mobiles$.pipe(map(mobiles => mobiles[passengerId]));

  readonly getMobileByPassengerId = rxMethod<{
    passengerId: number;
  }>(
    pipe(
      filter(({ passengerId }) => this.mobileByPassengerId(passengerId)() === undefined),
      exhaustMap(({ passengerId }) =>
        this.builderService.getPassengerMobile(passengerId)
          .pipe(
            tapResponse(
              mobile => patchSignal(this.#state, state => ({
                mobiles: {
                  ...state.mobiles,
                  [passengerId]: mobile || null
                }
              })),
              () => null
            )
          )
      )
    )
  );

  reset() {
    this.#state.set({ mobiles: {} });
  }
}
