import { Injectable, OnDestroy } from '@angular/core';
import { CdkDragDrop, CdkDragEnter, CdkDragStart } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Subject, forkJoin, from, of } from 'rxjs';
import {
  debounceTime,
  map,
  switchMap,
  filter,
  pairwise,
  distinctUntilChanged,
  take,
  tap,
  startWith,
  takeUntil,
  withLatestFrom,
  concatMap
} from 'rxjs/operators';
import { UPopupService } from '@shift/ulib';

import { OperationGuidService, TrackingService, HeaderDataService } from '@app/shared/services';
import {
  RouteDailyRow,
  RoutesDailyMovePassenger,
  RoutesDailyMovePassengerBase,
  RoutesDailyMovePassengersRideChangePlanType,
  RoutesDailyMovePassengersRoute,
  RoutesDailyMovePassengersRouteBase,
  RouteType
} from '@app/routes/models';
import { routesConfig, routesDailyMovePassengersConfig } from '@app/routes/configs';
import { RoutesMovePassengersHubService, RoutesPassengersMoveService } from '@app/routes/services';

@Injectable()
export class RoutesDailyMovePassengersDataService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private routesQueueCounter: BehaviorSubject<number> = new BehaviorSubject(null);
  private loadedPassengerRoutes: BehaviorSubject<RoutesDailyMovePassengersRoute[]> = new BehaviorSubject([]);
  private availableRoutes: BehaviorSubject<RouteDailyRow[]> = new BehaviorSubject([]);
  private invalidPassengers: BehaviorSubject<RoutesDailyMovePassenger[]> = new BehaviorSubject([]);
  private dragActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private activeDragEvent: BehaviorSubject<CdkDragStart> = new BehaviorSubject(null);
  private mousePosition: BehaviorSubject<{ x: number; y: number; }> = new BehaviorSubject(null);
  private checkedEntitiesAmount: BehaviorSubject<{ routes: number; passengers: number; }> = new BehaviorSubject({
    routes: 0,
    passengers: 0
  });
  private routesQueue: BehaviorSubject<{
    data: RouteDailyRow[];
    propagate?: boolean;
    emitImmediately?: boolean;
  }> = new BehaviorSubject({ data: [] });

  routesQueueCounter$ = this.routesQueueCounter.asObservable().pipe(distinctUntilChanged());
  loadedPassengerRoutes$ = this.loadedPassengerRoutes.asObservable().pipe(distinctUntilChanged());
  availableRoutes$ = this.availableRoutes.asObservable().pipe(distinctUntilChanged());
  invalidPassengers$ = this.invalidPassengers.asObservable().pipe(distinctUntilChanged());
  dragActive$ = this.dragActive.asObservable().pipe(distinctUntilChanged());
  checkedEntitiesAmount$ = this.checkedEntitiesAmount.asObservable().pipe(distinctUntilChanged());
  passengerRoutesExpanded$ = this.loadedPassengerRoutes$.pipe(
    distinctUntilChanged(),
    map(routes => routes.every(route => !route.passengers?.length || route.expanded))
  );

  constructor(
    private uPopupService: UPopupService,
    private trackingService: TrackingService,
    private operationGuidService: OperationGuidService,
    private headerDataService: HeaderDataService,
    private routesPassengersMoveService: RoutesPassengersMoveService,
    private routesMovePassengersHubService: RoutesMovePassengersHubService
  ) {}

  get isQueueEmpty(): boolean {
    return !this.routesQueue.value.data.length;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private track(message: string) {
    this.trackingService.track(`[${routesConfig.trackingId}] - ${routesDailyMovePassengersConfig.trackingId} - ${message}`);
  }

  private onPassengerRoutesLoad() {
    this.routesQueue.asObservable()
      .pipe(
        debounceTime(routesDailyMovePassengersConfig.requestDelay),
        switchMap(value =>
          value.emitImmediately ?
            of(value).pipe(startWith({ data: [], propagate: true, emitImmediately: true })) :
            of(value)
        ),
        pairwise(),
        tap(([ prev, curr ]) => {
          if (prev?.data?.length && curr?.data?.length) {
            this.routesQueueCounter.next(curr.data.length);
          }
        }),
        filter(([ prev, curr ]) =>
          curr.propagate && (curr.emitImmediately || !prev || !prev.data.length) && !!curr.data.length
        ),
        map(([ , curr ]) => curr.data.slice(0, routesDailyMovePassengersConfig.itemsToLoadLimit)),
        withLatestFrom(this.headerDataService.date$),
        concatMap(([ routes, date ]) =>
          forkJoin([
            this.routesPassengersMoveService.getRoutes(date, this.getRouteIdsExceptForLoaded(routes)),
            of(this.routesQueue.value.data.length)
          ])
        ),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([ passengerRoutes, queueLength ]: [ passengerRoutes: RoutesDailyMovePassengersRouteBase[], queueLength: number ]) =>
        this.updateLoadedPassengerRoutes(passengerRoutes, queueLength)
      );
  }

  private getRouteIdsExceptForLoaded(routes: RouteDailyRow[]): number[] {
    return routes.reduce((acc, route) => {
      const routeLoaded = this.loadedPassengerRoutes.value.find(passengerRoute =>
        passengerRoute.routeId === route.routeId
      );

      return [
        ...acc,
        ...(!routeLoaded ? [ route.routeId ] : [])
      ];
    }, []);
  }

  private updateLoadedPassengerRoutes(routes: RoutesDailyMovePassengersRouteBase[], queueLengthBeforeLoading?: number) {
    this.removeLoadedRoutesFromQueue(routes);

    this.loadedPassengerRoutes.next([
      ...this.loadedPassengerRoutes.value,
      ...this.getMappedPassengerRoutes(routes.filter(route =>
        !this.routesQueue.value.data.some(queueItem => queueItem.routeId === route.routeId)
      ))
    ]);

    const checkAddedRoutesDuringLoading = Number.isFinite(queueLengthBeforeLoading) &&
      queueLengthBeforeLoading <= routesDailyMovePassengersConfig.itemsToLoadLimit;

    if (checkAddedRoutesDuringLoading) {
      this.routesQueue.next({
        data: this.routesQueue.value.data,
        propagate: true,
        emitImmediately: true
      });
    }
  }

  private removeLoadedRoutesFromQueue(loadedRoutes: RoutesDailyMovePassengersRouteBase[]) {
    const data = this.routesQueue.value.data.filter(queueItem =>
      !loadedRoutes.some(route => route.routeId === queueItem.routeId)
    );

    this.routesQueue.next({
      data,
      propagate: false
    });

    this.routesQueueCounter.next(data.length);
  }

  private getMappedPassengerRoutes(routes: RoutesDailyMovePassengersRouteBase[]): RoutesDailyMovePassengersRoute[] {
    const checkedRoutes = this.loadedPassengerRoutes.value.filter(passengerRoute =>
      passengerRoute.checked || passengerRoute.passengers.some(passenger => passenger.checked)
    );

    return routes.map(route =>
      this.getMappedPassengerRoute(route, !!checkedRoutes.length, checkedRoutes)
    );
  }

  private getMappedPassengerRoute(
    route: RoutesDailyMovePassengersRouteBase,
    updateInvalidDirection: boolean,
    checkedRoutes: RoutesDailyMovePassengersRoute[]
  ): RoutesDailyMovePassengersRoute {
    const currentRouteState = this.loadedPassengerRoutes.value.find(loadedRoute =>
      loadedRoute.routeId === route.routeId
    );

    return {
      ...route,
      ...(updateInvalidDirection ? { invalidDirection: route.direction !== checkedRoutes[0].direction } : {}),
      checked: currentRouteState?.checked,
      disabled: currentRouteState?.disabled,
      expanded: currentRouteState ? currentRouteState.expanded : true,
      passengers: this.getMappedPassengerRoutePassengers(route.passengers, currentRouteState)
    };
  }

  private getMappedPassengerRoutePassengers(
    passengers: RoutesDailyMovePassengerBase[],
    currentRouteState: RoutesDailyMovePassengersRoute
  ): RoutesDailyMovePassenger[] {
    return passengers.map(passenger => {
      const previousPassengerState = currentRouteState?.passengers.find(loadedPassenger =>
        loadedPassenger.passengerId === passenger.passengerId
      );

      return {
        ...passenger,
        checked: previousPassengerState?.checked,
        disabled: previousPassengerState?.disabled
      };
    });
  }

  private getUniqueRoutes(routes: RouteDailyRow[]): RouteDailyRow[] {
    return routes.filter(route =>
      !this.routesQueue.value.data.some(queueItem => queueItem.routeId === route.routeId) &&
      !this.loadedPassengerRoutes.value.some(passengerRoute => passengerRoute.routeId === route.routeId)
    );
  }

  private filterQueueAccordingToRoutes(routes: RouteDailyRow[]) {
    return this.routesQueue.value.data.filter(queueItem =>
      routes.some(route => route.routeId === queueItem.routeId)
    );
  }

  private removeLoadedPassengerRoutes(routes: RouteDailyRow[]) {
    const selectedAndRemovedPassengerRoutes = this.getSelectedAndRemovedPassengerRoutes(routes);

    this.loadedPassengerRoutes.next(selectedAndRemovedPassengerRoutes.selected);

    this.unlockRemovedPassengerRoutes(selectedAndRemovedPassengerRoutes.removed);
  }

  private getSelectedAndRemovedPassengerRoutes(routes: RouteDailyRow[]): {
    selected: RoutesDailyMovePassengersRoute[];
    removed: RoutesDailyMovePassengersRoute[];
  } {
    return this.loadedPassengerRoutes.value.reduce((acc, loadedRoute) => {
      const selected = routes.some(route => route.routeId === loadedRoute.routeId);

      return {
        ...acc,
        selected: selected ? [ ...acc.selected, loadedRoute ] : acc.selected,
        removed: selected ? acc.removed : [ ...acc.removed, loadedRoute ]
      };
    }, { selected: [], removed: [] });
  }

  private unlockRemovedPassengerRoutes(routes: RoutesDailyMovePassengersRoute[]) {
    if (!routes?.length) { return; }

    this.routesPassengersMoveService.removeRoutes(routes.map(route => route.routeId))
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private updatePassengerRoutesInvalidDirection(route: RoutesDailyMovePassengersRoute) {
    if (!route) { return; }

    const routeChecked = route.checked || route.passengers.some(passenger => passenger.checked);
    const hasInvalidDirectionRoutes = this.loadedPassengerRoutes.value.some(passengerRoute => passengerRoute.invalidDirection);
    const hasOtherCheckedRoutes = this.loadedPassengerRoutes.value.some(passengerRoute =>
      passengerRoute.routeId !== route.routeId && (passengerRoute.checked || passengerRoute.passengers.some(passenger => passenger.checked))
    );

    if (routeChecked && !hasInvalidDirectionRoutes) {
      this.loadedPassengerRoutes.next(
        this.loadedPassengerRoutes.value.map(passengerRoute => ({
          ...passengerRoute,
          invalidDirection: passengerRoute.direction !== route.direction
        }))
      );
    }

    if (!routeChecked && !hasOtherCheckedRoutes && hasInvalidDirectionRoutes) {
      this.loadedPassengerRoutes.next(
        this.loadedPassengerRoutes.value.map(passengerRoute => ({
          ...passengerRoute,
          invalidDirection: false
        }))
      );
    }
  }

  private updateDragData(event: CdkDragStart) {
    event.source.data = this.loadedPassengerRoutes.value.reduce((acc, route) => {
      const checkedPassengers = route.passengers.filter(passenger => passenger.checked);

      return [
        ...acc,
        ...(!route.dragProcessed && (route.checked || checkedPassengers.length) ? [ {
          ...route,
          passengers: checkedPassengers
        } ] : [])
      ];
    }, []);
  }

  private updateCheckedRoutesAndPassengers() {
    this.checkedEntitiesAmount.next(this.loadedPassengerRoutes.value.reduce((acc, route) => {
      const checkedPassengersAmount = route.passengers.reduce((passengerAcc, passenger) => {
        if (passenger.checked && !route.dragProcessed) {
          return passengerAcc + (passenger.isAnonymous ? passenger.amount : 1);
        }

        return passengerAcc;
      }, 0);

      return {
        ...acc,
        routes: checkedPassengersAmount && !route.dragProcessed ? acc.routes + 1 : acc.routes,
        passengers: acc.passengers + checkedPassengersAmount
      };
    }, { routes: 0, passengers: 0 }));
  }

  private addTemporaryPassengerRoute(event: CdkDragStart, route: RoutesDailyMovePassengersRoute, routeIndex: number) {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.reduce((acc, passengerRoute, index) => [
        ...acc,
        ...(index === routeIndex ? [ {
          ...route,
          temp: true
        } ] : []),
        passengerRoute
      ], [])
    );

    this.updatePassengerRouteDroplistPositions(event);
  }

  private updatePassengerRouteDroplistPositions(event: CdkDragStart) {
    setTimeout(() => {
      event.source._dragRef['_dropContainer']['_siblings'].forEach(sibling =>
        sibling.element?.id?.startsWith(routesDailyMovePassengersConfig.idPrefixes.passengerRoute) &&
        sibling._cacheParentPositions()
      );
    });
  }

  private addTemporaryPassenger(route: RoutesDailyMovePassengersRoute, passenger: RoutesDailyMovePassenger, passengerIndex: number) {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.reduce((acc, passengerRoute) => [
        ...acc,
        ...(passengerRoute.routeId === route.routeId ? [ {
          ...passengerRoute,
          passengers: [
            ...passengerRoute.passengers.slice(0, passengerIndex),
            {
              ...passenger,
              temp: true
            },
            ...passengerRoute.passengers.slice(passengerIndex)
          ]
        } ] : [ passengerRoute ])
      ], [])
    );
  }

  private removeTemporaryPassengerRoutes() {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.filter(route => !route.temp)
    );
  }

  private removeTemporaryPassengers() {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.map(route => ({
        ...route,
        passengers: route.passengers.filter(passenger => !passenger.temp)
      }))
    );
  }

  private getStationsGroupedByRideStationId(passengerRoutes: RoutesDailyMovePassengersRoute[]) {
    return passengerRoutes.reduce((acc, passengerRoute) => {
      passengerRoute.passengers.forEach(passenger => {
        const existingStation = acc.find(station => station.rideStationId === passenger.rideStationId);

        if (existingStation) {
          if (passenger.isAnonymous) {
            existingStation.passengerIds = null;
          }

          if (existingStation.passengerIds?.length) {
            existingStation.passengerIds.push(passenger.passengerId);
          }
        } else {
          acc.push({
            routeId: passengerRoute.routeId,
            rideStationId: passenger.rideStationId,
            passengerIds: passenger.isAnonymous ? null : [ passenger.passengerId ]
          });
        }
      });

      return acc;
    }, []);
  }

  private uncheckAnonymousPassenger(route: RoutesDailyMovePassengersRoute, passenger: RoutesDailyMovePassenger) {
    const currentStationAnonymousPassenger = !passenger.checked && route.passengers.find(routePassenger =>
      routePassenger.isAnonymous && routePassenger.rideStationId === passenger.rideStationId
    );

    if (currentStationAnonymousPassenger && currentStationAnonymousPassenger.checked !== passenger.checked) {
      currentStationAnonymousPassenger.checked = false;
    }
  }

  private enablePassengerRoutesExceptForProcessedRoutes(routes: RoutesDailyMovePassengersRoute[]) {
    this.updatePassengerRoutesDisabled(
      this.loadedPassengerRoutes.value.reduce((acc, route) => {
        const isProcessed = route.dragProcessed || routes.some(obj => obj.routeId === route.routeId);

        return [
          ...acc,
          ...(!isProcessed ? [
            route.routeId
          ] : [])
        ];
      }, []),
      false
    );
  }

  private enableAvailablePassengerRoutes(draggedRoutes: RoutesDailyMovePassengersRoute[], destinationRouteId: number) {
    const destinationRouteInPanel = this.loadedPassengerRoutes.value.some(route =>
      route.routeId === destinationRouteId
    );

    this.updatePassengerRoutesDisabled(
      [
        ...this.loadedPassengerRoutes.value.reduce((acc, route) => {
          const enableRoute = !route.invalidDirection || !route.dragProcessed ||
            draggedRoutes.some(draggedRoute => draggedRoute.routeId === route.routeId);

          return [
            ...acc,
            ...(enableRoute ? [
              route.routeId
            ] : [])
          ];
        }, []),
        ...(destinationRouteInPanel ? [ destinationRouteId ] : [])
      ],
      false
    );
  }

  private updatePassengerRoutesDisabled(routeIds: number[], disabled: boolean) {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.map(route => ({
        ...route,
        ...(routeIds.includes(route.routeId) ? {
          disabled
        } : {})
      }))
    );
  }

  private disablePassengerRoutes() {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.map(route => ({
        ...route,
        disabled: true
      }))
    );
  }

  private updateLoadedPassengerRoutesOnRefresh(updatedRoutes: RoutesDailyMovePassengersRouteBase[], routeIdsToUpdate: number[]) {
    const removedRoutes = routeIdsToUpdate?.filter(routeId =>
      !updatedRoutes.some(route => route.routeId === routeId)
    ) || [];
    const loadedPassengerRoutes = this.loadedPassengerRoutes.value.reduce((acc, passengerRoute) => {
      const removedRoute = removedRoutes.includes(passengerRoute.routeId);
      const updatedRoute = !removedRoute && updatedRoutes?.find(route => route.routeId === passengerRoute.routeId);

      return [
        ...acc,
        ...(updatedRoute ? [ {
          ...passengerRoute,
          passengers: this.getMappedPassengerRoutePassengers(updatedRoute.passengers, passengerRoute)
        } ] : [
          ...(removedRoute ? [] : [ passengerRoute ])
        ])
      ];
    }, []);

    this.loadedPassengerRoutes.next(loadedPassengerRoutes);
  }

  private movePassengers(route: RouteDailyRow | RoutesDailyMovePassengersRoute, draggedPassengerRoutes: RoutesDailyMovePassengersRoute[]) {
    this.disablePassengerRoutes();

    this.headerDataService.date$
      .pipe(
        switchMap(date => this.routesPassengersMoveService.movePassengers({
          date,
          routeId: route.routeId,
          allowCapacityChange: false,
          type: RoutesDailyMovePassengersRideChangePlanType.Unplanned,
          stations: this.getStationsGroupedByRideStationId(draggedPassengerRoutes)
        })),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        errors => {
          if (errors) { return; }

          const destinationRouteInPanel = this.loadedPassengerRoutes.value.find(passengerRoute =>
            passengerRoute.routeId === route.routeId
          );
          const routes = [
            ...draggedPassengerRoutes,
            ...(destinationRouteInPanel ? [ destinationRouteInPanel ] : [])
          ];

          this.enablePassengerRoutesExceptForProcessedRoutes(routes);
          this.updatePassengerRoutesDragProcessedOnMove(routes);
        },
        () => this.enableAvailablePassengerRoutes(draggedPassengerRoutes, route.routeId)
      );
  }

  private onPassengersMoveFinished() {
    this.routesMovePassengersHubService.onPassengersMoveFinished()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        if (data.errors?.length) {
          this.uPopupService.showErrorMessage({ message: data.errors[0].errorDescription });

          const routeIds = [ data.destinationRouteId, ...data.sourceRouteIds ];

          this.updatePassengerRoutesDragProcessedOnMoveFinished(routeIds);
          this.updatePassengerRoutesDisabled(routeIds, false);
        } else {
          this.refreshLoadedPassengerRoutes(data.destinationRouteId, data.sourceRouteIds);
        }
      });
  }

  private updatePassengerRoutesDragProcessedOnMove(routes: RoutesDailyMovePassengersRoute[]) {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.map(passengerRoute => {
        const routeForProcessing = routes.some(route => route.routeId === passengerRoute.routeId);

        return routeForProcessing ? {
          ...passengerRoute,
          dragProcessed: true
        } : passengerRoute;
      })
    );
  }

  private updatePassengerRoutesDragProcessedOnMoveFinished(routeIds: number[]) {
    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.map(passengerRoute => {
        const isProcessed = passengerRoute.dragProcessed && routeIds.includes(passengerRoute.routeId);

        return isProcessed ? {
          ...passengerRoute,
          dragProcessed: false
        } : passengerRoute;
      })
    );
  }

  private refreshLoadedPassengerRoutes(destinationRouteId: number, sourceRouteIds: number[]) {
    const destinationInPanel = this.loadedPassengerRoutes.value.some(passengerRoute => passengerRoute.routeId === destinationRouteId);
    const routeIdsToUpdate = [ ...sourceRouteIds, ...(destinationInPanel ? [ destinationRouteId ] : []) ];

    this.headerDataService.date$
      .pipe(
        switchMap(date => this.routesPassengersMoveService.getRoutes(date, routeIdsToUpdate)),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(passengerRoutes => {
        this.updateLoadedPassengerRoutesOnRefresh(passengerRoutes, routeIdsToUpdate);
        this.updatePassengerRoutesDragProcessedOnMoveFinished([ destinationRouteId, ...sourceRouteIds ]);
        this.updatePassengerRoutesDisabled([ destinationRouteId, ...sourceRouteIds ], false);
        this.updatePassengerRoutesInvalidDirection(
          this.loadedPassengerRoutes.value.find(passengerRoute => sourceRouteIds.includes(passengerRoute.routeId))
        );
      });
  }

  private getPassengerRouteErrors(route: RouteDailyRow, passengerRoute: RoutesDailyMovePassengersRoute): string[] {
    const conditionsByError = {
      [routesDailyMovePassengersConfig.dictionary.errors.existOnRoute]: route.code === passengerRoute.number,
      [routesDailyMovePassengersConfig.dictionary.errors.routeLocked]: route.locked && !route.disabled,
      [routesDailyMovePassengersConfig.dictionary.errors.routeCanceled]: route.cancelled || !route.active,
      [routesDailyMovePassengersConfig.dictionary.errors.routeManualOrShuttle]: route.isFullManualRide || route.routeType.id === RouteType.Shuttle,
      [routesDailyMovePassengersConfig.dictionary.errors.differentDirection]: route.direction !== passengerRoute.direction
    };

    return Object.entries(conditionsByError).reduce((acc, [ key, value ]) => value ? [ ...acc, key ] : acc, []);
  }

  private resetInvalidPassengers() {
    this.invalidPassengers.next([]);
  }

  private async stopRoutesMovePassengersHub(): Promise<void> {
    await this.routesMovePassengersHubService.stop();
  }

  private async startRoutesMovePassengersHub(guid: string): Promise<void> {
    this.routesMovePassengersHubService.init(`?guid=${guid}`);

    await this.routesMovePassengersHubService.start();
  }

  private toggleDragPreviewContainerMovement(enabled: boolean) {
    this.activeDragEvent.value.source._dragRef['_hasStartedDragging'] = enabled;
  }

  private dispatchMouseMoveEvent() {
    document.dispatchEvent(new MouseEvent('mousemove', {
      bubbles: true,
      clientX: this.mousePosition.value.x,
      clientY: this.mousePosition.value.y
    }));
  }

  initialize() {
    this.unsubscribe.next();

    this.routesPassengersMoveService.initialize()
      .pipe(
        tap(guid => this.operationGuidService.setGuid(guid)),
        switchMap(guid =>
          from([
            from(this.stopRoutesMovePassengersHub()),
            from(this.startRoutesMovePassengersHub(guid))
          ])
        ),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.onPassengerRoutesLoad();
        this.onPassengersMoveFinished();
      });
  }

  loadMorePassengerRoutes() {
    const routesToLoad = this.routesQueue.value.data.slice(0, routesDailyMovePassengersConfig.itemsToLoadLimit);

    this.headerDataService.date$
      .pipe(
        switchMap(date =>
          forkJoin([
            this.routesPassengersMoveService.getRoutes(date, routesToLoad.map(route => route.routeId)),
            of(this.routesQueue.value.data.length)
          ])
        ),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([ passengerRoutes, queueLength ]) => this.updateLoadedPassengerRoutes(passengerRoutes, queueLength));
  }

  updateRoutesQueue(routes: RouteDailyRow[], emitImmediately?: boolean) {
    this.removeLoadedPassengerRoutes(routes);

    this.routesQueue.next({
      data: [ ...this.filterQueueAccordingToRoutes(routes), ...this.getUniqueRoutes(routes) ],
      propagate: true,
      emitImmediately: emitImmediately || this.routesQueue.value.emitImmediately
    });

    if (this.routesQueueCounter.value > this.routesQueue.value.data.length) {
      this.routesQueueCounter.next(this.routesQueue.value.data.length);
    }
  }

  updateAvailableRoutes(routes: RouteDailyRow[]) {
    this.availableRoutes.next(routes);
  }

  resetData() {
    this.routesQueue.next({ data: [] });
    this.routesQueueCounter.next(null);
    this.unsubscribe.next();
    this.loadedPassengerRoutes.next([]);

    this.stopRoutesMovePassengersHub();

    this.operationGuidService.removeGuid();
  }

  onPassengerRouteCheckedChange(route: RoutesDailyMovePassengersRoute) {
    route.passengers.forEach(passenger => passenger.checked = route.checked);

    this.updatePassengerRoutesInvalidDirection(route);
  }

  onPassengerCheckedChange(route: RoutesDailyMovePassengersRoute, passenger: RoutesDailyMovePassenger) {
    this.uncheckAnonymousPassenger(route, passenger);

    route.checked = route.passengers.every(routePassenger => routePassenger.checked);

    this.updatePassengerRoutesInvalidDirection(route);
  }

  onAnonymousPassengerCheckedChange(route: RoutesDailyMovePassengersRoute, anonymousPassenger: RoutesDailyMovePassenger) {
    route.passengers.forEach(passenger => {
      if (anonymousPassenger.checked && passenger.rideStationId === anonymousPassenger.rideStationId) {
        passenger.checked = true;
      }
    });

    route.checked = route.passengers.every(passenger => passenger.checked);

    this.updatePassengerRoutesInvalidDirection(route);
  }

  uncheckAll() {
    const processedRoute = this.loadedPassengerRoutes.value.find(route => route.dragProcessed);

    this.loadedPassengerRoutes.next(
      this.loadedPassengerRoutes.value.map(route => ({
        ...route,
        checked: route.dragProcessed ? route.checked : false,
        invalidDirection: processedRoute ? processedRoute.direction !== route.direction : false,
        passengers: route.passengers.map(passenger => ({
          ...passenger,
          checked: route.dragProcessed ? passenger.checked : false
        }))
      }))
    );
  }

  onPassengersRouteDragStart(event: CdkDragStart, route: RoutesDailyMovePassengersRoute, index: number) {
    this.activeDragEvent.next(event);
    this.dragActive.next(true);
    this.invalidPassengers.next([]);

    if (!route.checked) {
      route.checked = true;

      route.passengers.forEach(passenger => passenger.checked = true);

      this.updatePassengerRoutesInvalidDirection(route);
    }

    this.updateDragData(event);
    this.updateCheckedRoutesAndPassengers();
    this.addTemporaryPassengerRoute(event, route, index);
  }

  onPassengerDragStart(event: CdkDragStart, route: RoutesDailyMovePassengersRoute, passenger: RoutesDailyMovePassenger, index: number) {
    this.activeDragEvent.next(event);
    this.dragActive.next(true);
    this.invalidPassengers.next([]);

    if (!passenger.checked) {
      passenger.checked = true;

      if (passenger.isAnonymous) {
        this.onAnonymousPassengerCheckedChange(route, passenger);
      } else {
        this.onPassengerCheckedChange(route, passenger);
      }
    }

    this.updateDragData(event);
    this.updateCheckedRoutesAndPassengers();
    this.addTemporaryPassenger(route, passenger, index);
  }

  onDragDropped() {
    this.activeDragEvent.next(null);
    this.dragActive.next(false);

    this.removeTemporaryPassengerRoutes();
  }

  onDropListEntered(event: CdkDragEnter, route: RoutesDailyMovePassengersRoute) {
    const draggedRoutes = event.item.data;

    route.hoveredByDragItem = !draggedRoutes.some(draggedRoute => draggedRoute.routeId === route.routeId);

    this.resetInvalidPassengers();
  }

  onDropListExited(route: RoutesDailyMovePassengersRoute) {
    route.hoveredByDragItem = false;
  }

  onDropListDropped(event: CdkDragDrop<any>, route: RouteDailyRow | RoutesDailyMovePassengersRoute) {
    this.activeDragEvent.next(null);
    this.dragActive.next(false);

    if ((<RoutesDailyMovePassengersRoute>route).hoveredByDragItem) {
      (<RoutesDailyMovePassengersRoute>route).hoveredByDragItem = false;
    }

    this.removeTemporaryPassengerRoutes();
    this.removeTemporaryPassengers();

    if (event && event.previousContainer !== event.container && !this.invalidPassengers.value.length) {
      this.track('drop passenger to another route');
      this.movePassengers(route, event.item.data);
    }
  }

  checkPassengerRoutesValidity(event: CdkDragEnter, targetRoute: RouteDailyRow): boolean {
    const draggedPassengerRoutes = event.item.data;

    this.invalidPassengers.next(
      draggedPassengerRoutes.reduce((acc, passengerRoute) => {
        const routeErrors = !passengerRoute.temp && this.getPassengerRouteErrors(targetRoute, passengerRoute);

        return [
          ...acc,
          ...(routeErrors.length ? [
            ...passengerRoute.passengers.map(passenger => ({
              ...passenger,
              errors: routeErrors
            }))
          ] : [])
        ];
      }, [])
    );

    return !this.invalidPassengers.value.length;
  }

  updateDropLists() {
    if (this.dragActive.value && this.activeDragEvent.value) {
      const coordinates = {
        x: (<MouseEvent>this.activeDragEvent.value.event).clientX,
        y: (<MouseEvent>this.activeDragEvent.value.event).clientY
      };

      this.toggleDragPreviewContainerMovement(false);

      this.activeDragEvent.value.source._dragRef['_updateActiveDropContainer'](coordinates, coordinates);

      this.toggleDragPreviewContainerMovement(true);
      this.dispatchMouseMoveEvent();
    }
  }

  updateMousePosition(event: MouseEvent) {
    this.mousePosition.next({
      x: event.clientX,
      y: event.clientY
    });
  }

  getDragActiveValue(): boolean {
    return this.dragActive.value;
  }

  updatePassengerRouteExpanded(routeId: number, expanded: boolean) {
    this.loadedPassengerRoutes.next(this.loadedPassengerRoutes.value.map(route =>
      route.routeId === routeId ? ({
        ...route,
        expanded: !expanded
      }) : route
    ));
  }

  updatePassengerRoutesExpanded(expanded: boolean) {
    this.loadedPassengerRoutes.next(this.loadedPassengerRoutes.value.map(route => route.passengers?.length ? ({
      ...route,
      expanded
    }) : route));
  }
}
