import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, takeUntil, take } from 'rxjs/operators';
import { UPopupService } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { HeaderDataService } from '@app/shared/services';
import { isNotNullOrUndefined } from '@app/shared/utils';
import { AuthModuleName } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { NextYearService } from './next-year.service';
import { nextYearConfig } from '../configs';

@Injectable({
  providedIn: 'root'
})
export class NextYearDataService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private openNextYearPanel: Subject<void> = new Subject();
  private nextYearActivationStatus: BehaviorSubject<{ isInProgress: boolean; isNextYearActivated: boolean; isNextYearAvailable: boolean; }> = new BehaviorSubject(null);
  private nextYearActivated = false;

  nextYearAvailable = false;
  nextYearName = '';
  nextYearStartDate = null;
  isInNextYearMode = false;
  isNextYearDates: boolean;

  nextYearActivationStatus$ = this.nextYearActivationStatus.asObservable();
  openNextYearPanel$ = this.openNextYearPanel.asObservable();

  constructor(
    private authDataService: AuthDataService,
    private nextYearService: NextYearService,
    private popupService: UPopupService,
    private headerDataService: HeaderDataService
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private moveToNextYear() {
    const newDate = this.nextYearStartDate;

    this.headerDataService.dateSet(newDate);
  }

  private startNewNextYearProcess() {
    this.popupService.showMessage(
      {
        message: nextYearConfig.dictionary.processStarted,
        yes: nextYearConfig.dictionary.yes,
        no: nextYearConfig.dictionary.no
      },
      () => this.openNextYearPanel.next(),
      () => this.moveToNextYear()
    );
  }

  updateIsNextYearDates(value: boolean) {
    this.isNextYearDates = value;
  }

  getNextYearData(moveToNextYear: boolean = false) {
    this.nextYearService.getNextYearActivationStatus()
      .pipe(
        first(),
        filter(data => data)
      )
      .subscribe(nextYearActivationStatus => {
        this.nextYearActivated = nextYearActivationStatus.isNextYearActivated;
        this.nextYearAvailable = nextYearActivationStatus.isNextYearAvailable;
        this.nextYearActivationStatus.next(nextYearActivationStatus);

        this.nextYearService.getNextYearStartDate()
          .pipe(
            first(),
            filter(data => data)
          )
          .subscribe(data => {
            this.nextYearStartDate = moment(data);

            if (moveToNextYear) {
              this.moveToNextYear();
            }
          });

        this.nextYearService.getNextYearName()
          .pipe(
            first(),
            filter(data => data)
          )
          .subscribe(data => {
            this.nextYearName = data;
          });
      });
  }

  nextYearIconClick() {
    if (!this.nextYearActivated && !this.isInNextYearMode && this.nextYearStartDate) {
      this.openNextYearPanel.next();
      return;
    }

    if (this.nextYearActivated && this.nextYearStartDate) {
      const today = moment();
      this.nextYearStartDate = moment(this.nextYearStartDate, AppConstants.DATE_FORMAT_BASE_SLASH);

      if (today.isAfter(this.nextYearStartDate) && today.isBefore(this.nextYearStartDate.add(6, 'month'))) {
        this.startNewNextYearProcess();
      } else if (this.nextYearActivated) {
        this.moveToNextYear();
      }
    }

    if (this.nextYearStartDate === null) {
      this.openNextYearPanel.next();
    }
  }

  leaveNextYear() {
    const newDate = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    this.headerDataService.dateSet(newDate);
  }

  init() {
    this.authDataService.moduleByName$(AuthModuleName.NextYear)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe),
        isNotNullOrUndefined()
      )
      .subscribe(() => this.getNextYearData());

    this.headerDataService.date$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(date => {
        this.isInNextYearMode = this.nextYearActivated && this.nextYearStartDate && (moment(date).startOf('day') >= this.nextYearStartDate.startOf('day'));
      });
  }
}
