export const builderModalConfig = {
  defaults: {
    class: '',
    focus: true,
    show: false,
    animated: true,
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: false,
    initialState: {}
  },
  backdropTimeout: 150,
  classNames: {
    fade: 'fade',
    show: 'show',
    open: 'modal-open'
  }
};
