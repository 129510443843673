const DICTIONARY_PATH: string = 'builder.rideOrder.summary';

export const builderRideOrderSummaryComponentConfig = {
  dictionary: {
    nis: 'general.nis',
    shuttleCompany: `${DICTIONARY_PATH}.shuttleCompany`,
    contract: `${DICTIONARY_PATH}.contract`,
    ridePrice: `${DICTIONARY_PATH}.ridePrice`,
    totalOrder: `${DICTIONARY_PATH}.totalOrder`,
    purchaseOrder: `${DICTIONARY_PATH}.purchaseOrder`,
    purchaseOrderRow: `${DICTIONARY_PATH}.purchaseOrderRow`,
    budget: `${DICTIONARY_PATH}.budget`,
    pricelist: `${DICTIONARY_PATH}.pricelist`,
    hashcalRideType: `${DICTIONARY_PATH}.hashcalRideType`,
    confirmation: `${DICTIONARY_PATH}.confirmation`,
    comments: `${DICTIONARY_PATH}.comments`,
    select: {
      messages: 'uSelectS',
      placeholder: 'general.select'
    }
  },
  formControlDependenciesByControlName: {
    purchaseOrder: [ 'shuttleCompanyId' ],
    purchaseOrderRow: [ 'shuttleCompanyId', 'purchaseOrder' ]
  }
};
