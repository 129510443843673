import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';

import { environment } from '@environments/environment';
import { AuthFacade } from '@app/auth/state/facades';
import { AuthService } from '@app/auth/services';

@Injectable()
export class AuthRefreshTokenInterceptor implements HttpInterceptor {
  private readonly oAuthService = inject(OAuthService);
  private readonly authFacade = inject(AuthFacade);
  private readonly authService = inject(AuthService);

  private isRefreshing = false;
  private token: BehaviorSubject<string> = new BehaviorSubject(null);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const apiUrl = environment.config.serverUrlBase === '/' ? `${location.origin}/api` : `${environment.config.serverUrlBase}api`;

    return req.url && req.url.includes(apiUrl) ? of(this.authService.isExpiredAccessToken())
      .pipe(
        switchMap(expired => expired ? this.refreshToken(req, next) : next.handle(req))
      ) : next.handle(req);
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.token.next(null);

      return from(this.oAuthService.refreshToken())
        .pipe(
          catchError(err => {
            this.isRefreshing = false;

            this.authService.clearStorages();
            this.oAuthService.initLoginFlow();

            return throwError(err);
          }),
          switchMap((data: TokenResponse) => {
            this.isRefreshing = false;

            this.authFacade.updateUser();

            this.token.next(data.access_token);

            return next.handle(this.addTokenHeader(request, data.access_token));
          })
        );
    }

    return this.token.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}
