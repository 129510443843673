import { Component, EventEmitter, HostBinding, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AppLanguage, ModalActions } from '@app/shared/models';
import { LocalizationService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { RouteDirection } from '@app/routes/models';
import {
  DemandsPassengerRide,
  DemandsRemovePassengerRideParam,
  DemandsPassengersRoute,
  DemandsPassengersRoutesActions,
  DemandsPassengersRoutesPopupAction
} from '@app/demands/models';
import { demandsRoutesPopupComponentConfig } from './demands-routes-popup.component.config';

@Component({
  selector: 'app-demands-routes-popup',
  templateUrl: './demands-routes-popup.component.html',
  styleUrls: [ './demands-routes-popup.component.scss', './demands-routes-popup.component.rtl.scss' ]
})
export class DemandsRoutesPopupComponent implements OnInit {
  @Output() action: EventEmitter<DemandsPassengersRoutesPopupAction> = new EventEmitter();

  @ViewChild('actionsButton', { static: true }) private actionsButton: TemplateRef<any>;
  @ViewChild('normalCell', { static: true }) private normalCell: TemplateRef<any>;
  @ViewChild('direction', { static: true }) private direction: TemplateRef<any>;
  @ViewChild('date', { static: true }) private date: TemplateRef<any>;
  @ViewChild('hours', { static: true }) private hours: TemplateRef<any>;

  @HostBinding('class') hostClasses: string = 'demands-routes-popup';

  config = cloneDeep(demandsRoutesPopupComponentConfig);
  passengerRides: DemandsPassengerRide[];
  routes: DemandsPassengersRoute[] = [];
  columns: any[] = [];
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  passengersRemoveRoutes: DemandsRemovePassengerRideParam[] = [];
  passengersKeepRoutes: DemandsRemovePassengerRideParam[] = [];
  routeDirection = RouteDirection;
  demandsPassengersRoutesActions = DemandsPassengersRoutesActions;
  appConstants = AppConstants;

  constructor(
    private localizationService: LocalizationService,
    private modalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.attachColumnsTemplate();
    this.formatPassengerRides();
  }

  private formatPassengerRides() {
    if (!this.passengerRides) { return; }

    this.routes = this.passengerRides.map(route => ({
      keep: true,
      isTemplate: false,
      code: route.routeNumber,
      date: route.date,
      startTime: route.startTime,
      endTime: route.endTime,
      direction: route.direction,
      name: route.routeName,
      routeId: route.routeId,
      passengerId: route.passengerId,
      passengerName: route.passengerName,
      isOvernight: route.isOvernight
    }));
  }

  private attachColumnsTemplate() {
    this.columns = this.config.columns.map(column => ({
      ...column,
      cellTemplate: this[column.cellTemplateName] || this.normalCell
    }));
  }

  toggleActionButton(row: DemandsPassengersRoute, action: DemandsPassengersRoutesActions) {
    const isKeepAction = action === DemandsPassengersRoutesActions.Keep;

    row.keep = isKeepAction;

    this.passengersKeepRoutes = isKeepAction ? [ ...this.passengersKeepRoutes, { passengerId: row.passengerId, routeId: row.routeId, date: row.date } ] :
      this.passengersKeepRoutes.filter(route => route.routeId !== row.routeId);

    this.passengersRemoveRoutes = isKeepAction ? this.passengersRemoveRoutes.filter(route => route.routeId !== row.routeId) :
      [ ...this.passengersRemoveRoutes, { passengerId: row.passengerId, routeId: row.routeId, date: row.date } ];
  }

  closePopup() {
    if (!this.modalRef) { return; }

    this.modalRef.hide();
    this.action.emit({ type: ModalActions.Cancel, removeRoutes: null });
    this.modalRef = null;
  }

  save() {
    if (!this.passengersRemoveRoutes.length && !this.passengersKeepRoutes.length) {
      this.action.emit({ type: '', removeRoutes: [], removeErrorPopup: false });
      this.modalRef.hide();

      return;
    }

    let options: DemandsPassengersRoutesPopupAction = {
      type: ModalActions.Save,
      params: {}
    };

    if (this.passengerRides) {
      options = {
        ...options,
        params: {
          passengersRides: this.passengersRemoveRoutes
        }
      };
    }

    this.action.emit(options);
    this.modalRef.hide();
  }
}
