import { Injectable } from '@angular/core';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UPopupService } from '@shift/ulib';

import { AuthModuleDemandsPassengersViewFeatureType, AuthModuleDemandsShiftsViewFeatureType } from '@app/auth/models';
import {
  DemandsConfirmationPopupOptions,
  DemandsConfirmationPopupValues,
  DemandsShiftRepeatOptionsAction
} from '@app/demands/models';
import { DemandsShiftRepeatOptionsComponent } from '@app/demands/components';
import { demandsConfig } from '@app/demands/configs';

@Injectable()
export class DemandsAssignShiftDataService {
  private readonly bsModalService = inject(BsModalService);
  private readonly uPopupService = inject(UPopupService);

  openConfirmationPopup(
    options: DemandsConfirmationPopupOptions,
    values: DemandsConfirmationPopupValues = new DemandsConfirmationPopupValues()
  ): Observable<boolean> {
    return new Observable(subscriber => {
      this.uPopupService.showMessage({
        yes: demandsConfig.dictionary.yes,
        no: demandsConfig.dictionary.no,
        ...options
      },
      () => {
        subscriber.next(values.yes);
        subscriber.complete();
      },
      () => {
        subscriber.next(values.no);
        subscriber.complete();
      },
      () => {
        subscriber.next(values.close);
        subscriber.complete();
      });
    });
  }

  shiftRepeatOptions(
    isEditMode?: boolean,
    hasRepeatChanged?: boolean,
    featureType?: AuthModuleDemandsPassengersViewFeatureType | AuthModuleDemandsShiftsViewFeatureType
  ) {
    return new Observable(subscriber => {
      this.bsModalService.show(
        DemandsShiftRepeatOptionsComponent,
        {
          class: 'u-modal u-modal_app-demands-assign-ask',
          animated: true,
          ignoreBackdropClick: true,
          initialState: {
            isEditMode,
            hasRepeatChanged,
            featureType
          }
        }
      )
        .content
        .action
        .pipe(take(1))
        .subscribe((action: DemandsShiftRepeatOptionsAction) => {
          subscriber.next(action);
          subscriber.complete();
        });
    });
  }
}
