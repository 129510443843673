import {
  Component,
  OnChanges,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { UDatePickerRangeMode } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { LocalizationService } from '@app/shared/services';
import { weekSwitchComponentConfig } from './week-switch.component.config';

@Component({
  selector: 'app-week-switch',
  templateUrl: './week-switch.component.html',
  styleUrls: [ './week-switch.component.scss', './week-switch.component.rtl.scss' ]
})
export class WeekSwitchComponent implements OnChanges {
  @Input() initDate: string;
  @Input() startDateLimit: string;
  @Input() endDateLimit: string;
  @Input() datePickerRangeFooterTemplate: TemplateRef<any>;

  @Output() weekChange: EventEmitter<any> = new EventEmitter();
  @Output() resetWeekChange: EventEmitter<string> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'week-switch';

  config = cloneDeep(weekSwitchComponentConfig);
  startDateWeek = moment().startOf('week');
  endDateWeek = moment().endOf('week').startOf('day');
  uDatePickerRangeMode = UDatePickerRangeMode;
  isRtl: boolean = true;
  dates = [];

  constructor(
    private localizationService: LocalizationService
  ) {
    this.isRtl = this.localizationService.isRtl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initDate && this.initDate) {
      const isNegativeTimezone: boolean = new Date(this.initDate).getTimezoneOffset() > 0;

      this.startDateWeek = moment(this.initDate).utc(!isNegativeTimezone).startOf('week').startOf('day');
      this.endDateWeek = moment(this.initDate).utc(!isNegativeTimezone).endOf('week').startOf('day');
      this.dates = [ this.startDateWeek, this.endDateWeek ];
    }
  }

  resetWeek() {
    const today = moment().format(AppConstants.DATE_FORMAT_ISO);

    this.startDateWeek = moment(today).startOf('week');
    this.endDateWeek = moment(today).endOf('week').startOf('day');

    const startDate = this.startDateWeek.format(AppConstants.DATE_FORMAT_ISO);
    const endDate = this.endDateWeek.format(AppConstants.DATE_FORMAT_ISO);

    this.weekChange.emit({
      startDate,
      endDate
    });

    this.resetWeekChange.emit(today);
  }

  showToday() {
    const today = moment().format(AppConstants.DATE_FORMAT_ISO);

    return !moment(today).isBetween(this.startDateWeek, this.endDateWeek, null, '[]') && moment(today).isBetween(this.startDateLimit, this.endDateLimit, null, '[]');
  }

  rangeDatesSave(value: string[]) {
    const startDate = value[0];
    const endDate = moment(value[value.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    this.startDateWeek = moment(value[0]);
    this.endDateWeek = moment(value[value.length - 1]);

    this.weekChange.emit({
      startDate,
      endDate
    });
  }
}
