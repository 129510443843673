import { Component, OnInit, Output, EventEmitter, HostBinding, ChangeDetectionStrategy, inject, DestroyRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UAddress, UPopupService } from '@shift/ulib';

import {
  LocalizationService,
  TrackingService,
  LocalizedToastrService
} from '@app/shared/services';
import { ModalActions } from '@app/shared/models';
import { AuthDataService } from '@app/auth/services';
import {
  AuthModuleCustomerStationsFeature,
  AuthModuleCustomerStationsFeatureNameLocalization,
  AuthModuleName
} from '@app/auth/models';
import { Station, StationProp, StationsMode } from '@app/stations/models';
import { StationsService } from '@app/stations/services/stations.service';
import { stationsAddEditComponentConfig } from './stations-add-edit.component.config';

@Component({
  selector: 'app-stations-add-edit',
  templateUrl: './stations-add-edit.component.html',
  styleUrls: [ './stations-add-edit.component.scss', './stations-add-edit.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StationsAddEditComponent implements OnInit {
  @Input() editData: Station;

  @Output() action: EventEmitter<{ type: ModalActions; value?: number; mode?: StationsMode; }> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'stations-add-edit';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly modalRef = inject(BsModalRef);
  private readonly uPopupService = inject(UPopupService);
  private readonly toastr = inject(LocalizedToastrService);
  private readonly trackingService = inject(TrackingService);
  private readonly authDataService = inject(AuthDataService);
  private readonly stationsService = inject(StationsService);
  public readonly localizationService = inject(LocalizationService);

  private mode: StationsMode = StationsMode.Add;

  addEditForm: UntypedFormGroup;
  isRtl: boolean = this.localizationService.isRtl();
  nameLocalizationFeature: AuthModuleCustomerStationsFeatureNameLocalization[];
  config = cloneDeep(stationsAddEditComponentConfig);
  map = this.config.map;

  ngOnInit() {
    this.updateMode(this.editData ? StationsMode.Edit : StationsMode.Add);
    this.getAuthModulesFeatures();
  }

  private getAuthModulesFeatures() {
    this.authDataService.modules$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        this.nameLocalizationFeature = data[AuthModuleName.CustomerStations]?.[AuthModuleCustomerStationsFeature.NameLocalization];

        this.initForm();
        this.updateForm(this.editData);
        this.updateStationMap(this.editData);
      });
  }

  private updateMode(value: StationsMode) {
    this.mode = value;
  }

  private updateStationMap(data: Station) {
    if (!data) { return; }

    this.map.point.address = {
      address: data.fullAddress,
      lat: data.latitude,
      lng: data.longitude
    };
  }

  private initForm() {
    this.addEditForm = this.fb.group({
      stationId: [ 0 ],
      name: [ '', Validators.required ],
      fullAddress: [ '', Validators.required ],
      latitude: [ '', Validators.required ],
      longitude: [ '', Validators.required ],
      placeId: [ null ],
      translations: this.fb.group({})
    });

    this.setAddEditFormTranslations();
  }

  private setAddEditFormTranslations() {
    if (this.nameLocalizationFeature) {
      for (const langCode of this.nameLocalizationFeature) {
        (this.addEditForm.get('translations') as UntypedFormGroup).setControl(
          langCode,
          this.fb.group({
            [StationProp.Name]: [ this.editData?.translations[langCode]?.[StationProp.Name] || '', Validators.required ]
          })
        );
      }
    }
  }

  private saveStation() {
    this.track(`Station ${this.mode === StationsMode.Add ? 'add' : 'edit'} saved`);

    if (this.addEditForm.invalid) {
      this.uPopupService.showErrorMessage({ message: this.config.dictionary.error });

      return;
    }

    if (!this.addEditForm.dirty) {
      return this.modalRef.hide();
    }

    const editMode = this.mode === StationsMode.Edit;

    (
      editMode ? this.stationsService.updateStation(this.addEditForm.value) :
        this.stationsService.saveStation(this.addEditForm.value)
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data =>
        this.onStationSave(editMode ? this.addEditForm.value.stationId : data.id)
      );
  }

  private onStationSave(id: number) {
    this.track(`${this.mode === StationsMode.Add ? 'add' : 'edit'}`);

    this.action.emit({
      type: ModalActions.Submit,
      value: id,
      mode: this.mode
    });

    this.modalRef.hide();
    this.toastr.success(this.config.dictionary.successful);
  }

  private deleteStation() {
    this.uPopupService.showMessage(
      {
        message: this.config.dictionary.deleteConfirm,
        yes: this.config.dictionary.yes,
        no: this.config.dictionary.no
      },
      () => {
        this.stationsService.deleteStations([ this.addEditForm.value.stationId ])
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.action.emit({
              type: ModalActions.Delete
            });

            this.modalRef.hide();
          });
      }
    );
  }

  private updateForm(data: Station) {
    if (!data) { return; }

    this.addEditForm.patchValue(data);
  }

  private closeStation() {
    if (!this.addEditForm.dirty) {
      return this.modalRef.hide();
    }

    if (this.uPopupService.popupRef) { return; }

    this.uPopupService.showMessage(
      {
        showXIcon: true,
        message: this.config.dictionary.closeConfirm,
        yes: this.config.dictionary.yes,
        no: this.config.dictionary.no
      },
      () => this.saveStation(),
      () => this.modalRef.hide()
    );
  }

  track(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  checkActions(action: ModalActions) {
    switch (action) {
      case ModalActions.Submit: {
        this.saveStation();

        break;
      }

      case ModalActions.Delete: {
        this.deleteStation();

        break;
      }

      case ModalActions.Shutdown: {
        this.closeStation();

        break;
      }
    }
  }

  updateAddress(data: UAddress) {
    this.addEditForm.patchValue({
      fullAddress: data.address,
      latitude: data.lat,
      longitude: data.lng,
      placeId: data.placeId
    });

    this.addEditForm.markAsDirty();

    this.map.point.address = {
      address: data.address,
      lat: data.lat,
      lng: data.lng
    };
  }
}
