import { RoutePlannerRoutesGeneration } from '@app/route-planner/models';
import { BuilderFullRoutesGenerationState } from '@app/builder/models';

const initialState: BuilderFullRoutesGenerationState = {
  show: false,
  showTooltip: false,
  disabled: false,
  disabledAiMode: true,
  mode: RoutePlannerRoutesGeneration.Algo
};

export const builderFullRoutesGenerationConfig = {
  initialState
};
