<div
  *ngIf="form"
  class="contacts__main"
>
  <div class="contacts__header">
    <div
      *ngIf="showTitle"
      class="u-text-line u-text-line_app-title contacts__header-title"
    >
      {{config.dictionary.title | translate}}
    </div>
    <button
      *ngIf="!form.disabled"
      uButton
      class="u-font-weight-medium"
      [withPlus]="true"
      (click)="addContact()"
    >
      {{config.dictionary.newContact | translate}}
    </button>
  </div>
  <div class="contacts__items">
    <div
      *ngFor="let contactForm of form.controls; let i = index;"
      [formGroup]="contactForm"
      class="contacts__item"
    >
      <u-group
        class="u-group_large-field  u-group_flex u-group_app-contacts"
        [name]="config.dictionary.contact | translate"
      >
        <div class="contacts__double">
          <div class="contacts__double-item">
            <input
              uInput
              type="text"
              [valid]="contactForm.get('role').valid"
              [placeholder]="config.dictionary.role | translate"
              formControlName="role"
            >
          </div>
          <div class="contacts__double-item">
            <input
              uInput
              type="text"
              [valid]="contactForm.get('name').valid"
              [placeholder]="config.dictionary.name | translate"
              formControlName="name"
            >
          </div>
        </div>
        <div class="contacts__actions" *ngIf="!form.disabled">
          <div class="contacts__actions-btn contacts__actions-btn_remove" (click)="removeContact(i)"></div>
        </div>
      </u-group>
      <u-group
        *ngFor="let contactItemForm of contactForm.get('contacts').controls; let iItem = index; let lastItem = last"
        [formGroup]="contactItemForm"
        class="u-group_large-field  u-group_flex u-group_app-contacts"
      >
        <div class="contacts__double">
          <div class="contacts__double-item">
            <u-select-s
              [items]="config.contactTypes"
              [messages]="config.dictionary.uSelectS | translate"
              [viewportElement]="viewportElement"
              [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
              [emptyName]="config.dictionary.select | translate"
              [emptyRemove]="config.dictionary.remove | translate"
              [placeholder]="config.dictionary.select | translate"
              [valid]="contactItemForm.get('type').valid"
              formControlName="type"
              [container]="'body'"
              [classUDropdownContent]="'u-dropdown-content_app-default'"
            ></u-select-s>
          </div>
          <div class="contacts__double-item">
            <input
              uInput
              type="text"
              [valid]="!contactItemForm.get('contact').pending ? contactItemForm.get('contact').valid : true"
              formControlName="contact"
            >
          </div>
        </div>
        <div class="contacts__actions" *ngIf="!form.disabled">
          <div class="contacts__actions-btn contacts__actions-btn_remove" *ngIf="!lastItem || iItem > 0" (click)="removeContactItem(i, iItem)"></div>
          <div class="contacts__actions-btn contacts__actions-btn_add" *ngIf="lastItem" (click)="addContactItem(i)"></div>
        </div>
      </u-group>
    </div>
  </div>
</div>
