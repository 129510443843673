import { MonitoringDashboardPathLive, MonitoringDashboardSourceType } from '@app/monitoring-dashboard/models';

const DICTIONARY_PATH: string = 'monitoringDashboard';

const mapMarkers = {
  accompany: {
    url: '/assets/images/map/point-accompany-big.svg',
    width: 32,
    height: 32
  },
  driver: {
    url: '/assets/images/map/point-driver-big.svg',
    width: 32,
    height: 32
  },
  vehicle: {
    url: '/assets/images/map/point-vehicle-big.svg',
    width: 32,
    height: 32
  },
  supervisor: {
    url: '/assets/images/map/point-supervisor-big.svg',
    width: 32,
    height: 32
  },
  passenger: {
    url: '/assets/images/map/point-passenger.svg',
    width: 48,
    height: 48
  }
};

const pathsLive: MonitoringDashboardPathLive[] = [
  {
    sourceType: MonitoringDashboardSourceType.Car,
    points: [],
    icon: {
      url: mapMarkers.vehicle.url,
      width: 32,
      height: 32
    },
    polylineOptions: {
      color: '#39B54A'
    },
    showPointsInfo: true,
    showPolyline: true
  },
  {
    sourceType: MonitoringDashboardSourceType.Driver,
    points: [],
    icon: {
      url: mapMarkers.driver.url,
      width: 32,
      height: 32
    },
    polylineOptions: {
      color: '#c53cbf'
    },
    showPointsInfo: true,
    showPolyline: true
  },
  {
    sourceType: MonitoringDashboardSourceType.ExternalDriver,
    points: [],
    icon: {
      url: mapMarkers.driver.url,
      width: 32,
      height: 32
    },
    polylineOptions: {
      color: '#c53cbf'
    },
    showPointsInfo: true,
    showPolyline: true
  },
  {
    sourceType: MonitoringDashboardSourceType.Accompany,
    points: [],
    icon: {
      url: mapMarkers.accompany.url,
      width: 32,
      height: 32
    },
    polylineOptions: {
      color: '#02C0FF'
    },
    showPointsInfo: true,
    showPolyline: true
  },
  {
    sourceType: MonitoringDashboardSourceType.Supervisor,
    points: [],
    icon: {
      url: mapMarkers.supervisor.url,
      width: 32,
      height: 32
    },
    polylineOptions: {
      color: '#02C0FF'
    },
    showPointsInfo: true,
    showPolyline: true
  }
];

const pathsLivePointsColors = {
  [MonitoringDashboardSourceType.Car]: '#39B54A',
  [MonitoringDashboardSourceType.Driver]: '#c53cbf',
  [MonitoringDashboardSourceType.Accompany]: '#02c0ff',
  [MonitoringDashboardSourceType.Supervisor]: '#02c0ff',
  [MonitoringDashboardSourceType.ExternalDriver]: '#c53cbf'
};

const dictionary = {
  rideStatuses: `${DICTIONARY_PATH}.rideStatus`
};

export const monitoringDashboardConfig = {
  dictionary,
  trackingId: 'Routes & Rides - Dashboard',
  mapMarkers,
  pathsLive,
  pathsLivePointsColors,
  layers: {
    mapMarkers: {
      accompany: {
        icon: mapMarkers.accompany,
        opacity: 0.6
      },
      driver: {
        icon: mapMarkers.driver,
        opacity: 0.6
      },
      vehicle: {
        icon: mapMarkers.vehicle,
        opacity: 0.6
      }
    }
  },
  mapRide: {
    zoom: 16,
    monitoredPathColors: {
      [MonitoringDashboardSourceType.Car]: '#39B54A',
      [MonitoringDashboardSourceType.Driver]: '#c53cbf',
      [MonitoringDashboardSourceType.Accompany]: '#02c0ff',
      [MonitoringDashboardSourceType.Supervisor]: '#02c0ff',
      [MonitoringDashboardSourceType.ExternalDriver]: '#c53cbf'
    },
    monitoredPathMarkerColors: {
      [MonitoringDashboardSourceType.Car]: '#39B54A',
      [MonitoringDashboardSourceType.Driver]: '#c53cbf',
      [MonitoringDashboardSourceType.Accompany]: '#02c0ff',
      [MonitoringDashboardSourceType.Supervisor]: '#02c0ff',
      [MonitoringDashboardSourceType.ExternalDriver]: '#c53cbf'
    }
  }
};
