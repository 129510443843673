const DICTIONARY_PATH: string = 'builder.stationArrivalTime';

export const builderStationArrivalTimeComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    time: `${DICTIONARY_PATH}.time`,
    theDayAfter: `${DICTIONARY_PATH}.theDayAfter`,
    cancel: 'general.cancel',
    saveAndClose: 'general.saveAndClose'
  }
};
