import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

declare const ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  init() {
    ga('create', environment.config.googleAnalyticsKey, 'auto');
    ga('send', 'pageview');
  }
}
