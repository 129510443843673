<div class="demands-assign-shift__header">
  <div class="demands-assign-shift__name">{{(assignPassengerDemand ? dictionary.assignPassenger : dictionary.title) | translate}}</div>
  <div class="demands-assign-shift__close" (click)="closePopup()">
    <div class="u-icon u-icon_pointer u-icon_app-close"></div>
  </div>
</div>
<div class="demands-assign-shift__content">
  <div class="demands-assign-shift__base">
    <u-group
      *ngIf="assignPassengerDemand && form.get('departmentId')"
      class="u-group_app-demands-assign-shift"
      [name]="dictionary.department | translate"
    > 
      <u-select-s 
        uId="demands-assign-shift-passenger"
        [messages]="'uSelectS' | translate"
        [items]="departmentsItems()"
        [empty]="false"
        [formControl]="form.get('departmentId')"
        [valid]="form.get('departmentId').disabled || form.get('departmentId').valid" 
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [container]="'body'"
        [classUDropdownContent]="'u-dropdown-content_app-demands-assign-shift'"
        [placeholder]="'general.select' | translate"
      ></u-select-s>
    </u-group>
    <u-group
      *ngIf="assignPassengerDemand && form.get('passengerId')"
      class="u-group_app-demands-assign-shift"
      [name]="dictionary.passenger | translate"
    >
      <u-select-s 
        uId="demands-assign-shift-passenger"
        [messages]="'uSelectS' | translate"
        [items]="passengersItems()"
        [empty]="false"
        [formControl]="form.get('passengerId')"
        [valid]="form.get('passengerId').disabled || form.get('passengerId').valid" 
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [container]="'body'"
        [classUDropdownContent]="'u-dropdown-content_app-demands-assign-shift'"
        [placeholder]="'general.select' | translate"
      ></u-select-s> 
    </u-group>
    <u-group
      class="u-group_app-demands-assign-shift"
      [name]="dictionary.branch | translate"
    >
      <u-select-s 
        [messages]="'uSelectS' | translate"
        [items]="branches"
        [empty]="false"
        [formControl]="form.get('branchId')"
        [valid]="form.get('branchId').disabled || form.get('branchId').valid" 
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [container]="'body'"
        [classUDropdownContent]="'u-dropdown-content_app-demands-assign-shift'"
        [placeholder]="'general.select' | translate"
      ></u-select-s> 
    </u-group>
    <u-group
      class="u-group_app-demands-assign-shift"
      [name]="dictionary.shift | translate"
    >
      <u-select-s
        [messages]="'uSelectS' | translate"
        [items]="shifts"
        [empty]="false"
        [formControl]="form.get('shiftId')"
        [valid]="form.get('shiftId').disabled || form.get('shiftId').valid" 
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [container]="'body'"
        [classUDropdownContent]="'u-dropdown-content_app-demands-assign-shift'"
        [placeholder]="'general.select' | translate"
      ></u-select-s>
    </u-group>
    <u-group
      class="u-group_app-demands-assign-shift"
    >
      <div class="demands-assign-shift__double demands-assign-shift__independently">
        <div class="demands-assign-shift__double-item">
          <div class="demands-assign-shift__checkbox">
            <div class="demands-assign-shift__checkbox-content">
              <u-checkbox
                class="u-checkbox u-checkbox_box u-checkbox_app-demands-assign-shift"
                [formControl]="form.get('comesIndependent')"
              ></u-checkbox>
            </div>
            <div class="demands-assign-shift__checkbox-name">{{dictionary.arrivesIndependently | translate}}</div>
          </div>
        </div>
        <div class="demands-assign-shift__double-item">
          <div class="demands-assign-shift__checkbox">
            <div class="demands-assign-shift__checkbox-content">
              <u-checkbox
                class="u-checkbox u-checkbox_box u-checkbox_app-demands-assign-shift"
                [formControl]="form.get('leavesIndependent')"
              ></u-checkbox>
            </div>
            <div class="demands-assign-shift__checkbox-name">{{dictionary.leavesIndependently | translate}}</div>
          </div>
        </div>
      </div>
    </u-group>
    <u-group
      class="u-group_app-demands-assign-shift"
      [name]="dictionary.hours | translate"
    >
      <div class="demands-assign-shift__double demands-assign-shift__time">
        <div class="demands-assign-shift__double-item">
          <u-input-time
            [class]="'u-input-time_center'" 
            [defaultTime]="false"
            [formControl]="form.get('startTime')"
            [valid]="form.get('startTime').disabled || form.get('startTime').valid" 
          ></u-input-time>
        </div>
        <div class="demands-assign-shift__double-item">
          <u-input-time
            [class]="'u-input-time_center'" 
            [defaultTime]="false"
            [formControl]="form.get('endTime')"
            [valid]="form.get('endTime').disabled || form.get('endTime').valid" 
          ></u-input-time>
        </div>
      </div>
    </u-group>
  </div>
  <div
    *ngIf="advanced"
    class="demands-assign-shift__advanced"
  >
    <ng-container *ngTemplateOutlet="advancedBtn"></ng-container>
  </div>
  <div *ngIf="advanced" class="demands-assign-shift__advanced-list">
    <u-group
      class="u-group_app-demands-assign-shift"
      [name]="dictionary.pickUp | translate"
    >
      <app-demands-assign-pick
        [form]="form.get('advancedSettings.pickUpPassengerAddress')"
        [stations]="stations"
        [addressTypes]="config.options.pickUpAndDropOff"
        [passengerSettingsValue]="assignPassengerDemand | demandsAssignShiftPassengerSettings : passengerPickUpSettings() : passenger"
      ></app-demands-assign-pick>
    </u-group>
    <u-group
      class="u-group_app-demands-assign-shift"
      [name]="dictionary.dropOff | translate"
    >
      <app-demands-assign-pick
        [form]="form.get('advancedSettings.dropOffPassengerAddress')"
        [stations]="stations"
        [addressTypes]="config.options.pickUpAndDropOff"
        [passengerSettingsValue]="assignPassengerDemand | demandsAssignShiftPassengerSettings : passengerPickUpSettings() : passenger"
      ></app-demands-assign-pick>
    </u-group>
    <div class="demands-assign-shift__repeat">
      <div class="demands-assign-shift__repeat-action" (click)="showRepeatInfo()">
        <div class="demands-assign-shift__repeat-icon"></div>
        <button
          uButton
          class="u-font-weight-bold"
        >
          {{dictionary.repeat | translate}}
        </button>
      </div>
      <div
        *ngIf="repeatInfo"
        (click)="hideRepeatInfo()"
        class="demands-assign-shift__repeat-close"
      >
        <div class="u-icon u-icon_pointer u-icon_app-close demands-assign-shift__repeat-close-icon"></div>
        <div class="demands-assign-shift__repeat-close-title">{{dictionary.clearRepeat | translate}}</div>
      </div>
    </div>
    <div
      *ngIf="repeatInfo"
      class="demands-assign-shift__repeat-list"
    >
      <u-group
        class="u-group_app-demands-assign-shift"
        [name]="dictionary.dates | translate"
      >
        <u-input-date-range
          [class]="'u-input-date-range_app-demands-assign-shift'"
          [sundayFirstDay]="true"
          [required]="true"
          [(ngModel)]="datesRange.dates"
          [maxPeriodSelection]="datesRange.maxPeriodSelection"
          [checkDaysActive]="datesRange.checkDaysActive"
          [checkDaysAvailable]="datesRange.checkDaysAvailable"
          [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
          [checkDays]="true"
          [container]="'body'"
          [messages]="'uInputDateRange' | translate"
          [showIcon]="false"
          [classUInput]="'u-input_arrow u-input_text-center u-input_text-grey'"
          (saveAction)="onDatesChange($event)"
        ></u-input-date-range>
      </u-group>
      <u-group
        class="u-group_app-demands-assign-shift"
        [name]="dictionary.repeatEvery | translate"
      >
        <div class="demands-assign-shift__double demands-assign-shift__repeat-period">
          <div class="demands-assign-shift__double-item">
            <u-input-number
              [min]="config.repeatInfo.repeatPeriod.min"
              [max]="config.repeatInfo.repeatPeriod.max"
              [formControl]="form.get('advancedSettings.repeatInfo.repeatPeriod')"
              [valid]="form.get('advancedSettings.repeatInfo.repeatPeriod').valid"
              [showCounter]="true"
            ></u-input-number>
          </div>
          <div class="demands-assign-shift__double-item">
            <u-select-s
              [messages]="'uSelectS' | translate"
              [items]="config.options.repeatTypes"
              [empty]="false"
              [placeholder]="'general.select' | translate"
              [formControl]="form.get('advancedSettings.repeatInfo.repeatType')"
              [valid]="form.get('advancedSettings.repeatInfo.repeatType').valid"
              [container]="'body'"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
            ></u-select-s>
          </div>
        </div>
      </u-group>
      <u-group
        *ngIf="form.get('advancedSettings.repeatInfo.repeatType').value === passengerShiftRepeatType.Weeks"
        class="u-group_app-demands-assign-shift"
        [name]="dictionary.inDays | translate"
      >
        <u-select-days
          class="u-select-days_app-demands-assign-shift"
          [container]="'body'"
          [messages]="'uSelectDays' | translate"
          [(ngModel)]="datesRange.checkDaysActive"
          [sundayFirstDay]="true"
          [rtl]="isRtl"
          [lang]="lang"
          [availableDays]="availableDays"
          [showInfo]="false"
          [showAllBtn]="false"
          [minDays]="1"
          (ngModelChange)="activeDaysChange()"
        ></u-select-days>
      </u-group>
    </div>
  </div>
</div>
<div 
  class="demands-assign-shift__actions"
  [ngClass]="{
    'demands-assign-shift__actions_advanced': advanced
  }"
>
  <div 
    class="demands-assign-shift__actions-btn"
    [ngClass]="{
      'demands-assign-shift__actions-btn_hidden': advanced
    }"
  >
    <ng-container *ngTemplateOutlet="advancedBtn"></ng-container>
  </div>
  <div class="demands-assign-shift__actions-btn">
    <button
      uButton
      class="u-font-weight-bold demands-assign-shift__actions-btn-remove"
      *ngIf="editData" 
      (click)="delete()"
    >
      {{(assignPassengerDemand ? dictionary.deletePassenger : dictionary.deleteShift) | translate}}
    </button>
  </div>
  <div class="demands-assign-shift__actions-btn">
    <button
      uButton
      class="u-font-weight-bold"
      [disabled]="!form.valid"
      (click)="apply()"
    >
      {{dictionary.save | translate}}
    </button>
  </div>
</div>

<ng-template #advancedBtn>
  <div class="demands-assign-shift__advanced-button" (click)="toggleAdvanced()">
    <button
      uButton
      class="u-font-weight-bold"
    >
      {{dictionary.advanced | translate}}
    </button>
    <div
      class="demands-assign-shift__advanced-icon"
      [ngClass]="{
        'demands-assign-shift__advanced-icon_up': advanced
      }"
    ></div>
  </div>
</ng-template>