import { URangePreset } from '@shift/ulib';

import { DaysOfWeek } from '@app/shared/models';

const DICTIONARY_PATH: string = 'routes.changeCar';

const availablePresets: URangePreset[] = [
  URangePreset.DisplayedDay,
  URangePreset.Today,
  URangePreset.ActiveWeekDay,
  URangePreset.FromTodayOn,
  URangePreset.FromTomorrowOn,
  URangePreset.FromCustomDayOn,
  URangePreset.All,
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

export const routesChangeCarComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    carType: `${DICTIONARY_PATH}.carType`,
    changeType: `${DICTIONARY_PATH}.changeType`,
    comments: `${DICTIONARY_PATH}.comments`,
    noVehicles: `${DICTIONARY_PATH}.noVehicles`,
    actions: {
      saveAndClose: 'general.saveAndClose',
      saveAndSendSC: 'general.saveAndSendSC',
      saveAndEditEmail: 'general.saveAndEditEmail'
    }
  },
  availablePresets,
  checkDaysAvailable: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ]
};
