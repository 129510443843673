import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { UPopupService, USearchFilter, USearchFilterType, USidebarMenuService } from '@shift/ulib';

import { passengersConfig } from '@app/passengers/configs';
import { ModalActions } from '@app/shared/models';
import { AuthModuleName, AuthModulePassengersFeature, AuthModulePassengersFeatureType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import {
  PassengerDetails,
  PassengerDistanceToStation,
  PassengersAddEditMode,
  PassengersExtraFilters,
  PassengersExtraFiltersValues
} from '@app/passengers/models';
import { BuilderFullRoutesFor } from '@app/builder/models';
import { PassengersMunicipalityAddEditComponent } from '@app/passengers/components/passengers-municipality-add-edit/passengers-municipality-add-edit.component';
import { PassengersGenericAddEditComponent } from '@app/passengers/components/passengers-generic-add-edit/passengers-generic-add-edit.component';
import { PassengersArmyAddEditComponent } from '@app/passengers/components/passengers-army-add-edit/passengers-army-add-edit.component';
import { PassengersService } from '@app/passengers/services/passengers.service';
import { PassengersInitialDataStoreService } from '@app/passengers/services';
import { CommandersService } from '@app/ride-commanders/services';
import { SupervisorsService } from '@app/ride-supervisors/services';
import { HeaderSearchFiltersService, LocalizedToastrService } from '@app/shared/services';
import { PassengersRoutesPopupService } from '@app/passengers/services/passengers-routes-popup.service';

@Injectable()
export class PassengersDataService implements OnDestroy {
  private filtersSubscription: Subscription = new Subscription();
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private bsModalService: BsModalService,
    private authDataService: AuthDataService,
    private uPopupService: UPopupService,
    private uSidebarMenuService: USidebarMenuService,
    private passengersService: PassengersService,
    private commandersService: CommandersService,
    private supervisorsService: SupervisorsService,
    private toastr: LocalizedToastrService,
    private headerSearchFiltersService: HeaderSearchFiltersService,
    private passengersRoutesPopupService: PassengersRoutesPopupService
  ) {}

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getExtraFiltersParams(extraFilters: PassengersExtraFilters): PassengersExtraFiltersValues {
    let params = null;

    if (extraFilters) {
      const { directions, startDate, startTime, endDate, endTime, customerId } = extraFilters?.values;

      if (directions && startDate && endDate) {
        params = {
          directions,
          startDate,
          startTime,
          endDate,
          endTime
        };
      }

      if (customerId) {
        params = {
          ...params,
          customerId
        };
      }
    }

    return params;
  }

  generateExtraFilters(extraFilters: PassengersExtraFilters, state: { shiftIds: number[] } = { shiftIds: [] }, isAfterSchoolActivitiesEnabled?: boolean): USearchFilter[] {
    const filters: USearchFilter[] = [];
    const { directions, startDate, endDate, routesFor } = extraFilters.values;
    const { shifts } = extraFilters.options;
    const isAllData: boolean = !!(directions && startDate && endDate);

    if (shifts && shifts.length) {
      filters.push({
        name: 'shifts',
        items: shifts,
        control: new UntypedFormControl({ value: isAllData ? state.shiftIds : [], disabled: !isAllData || routesFor === BuilderFullRoutesFor.Schools }),
        type: USearchFilterType.Multiselect,
        title: `passengers.globalFilters.${isAfterSchoolActivitiesEnabled ? 'activities' : 'shifts'}`
      });
    }

    return filters;
  }

  generateFiltersValues(filters: USearchFilter[]) {
    let values: any = {};

    if (filters) {
      values = filters.reduce((acc, ob) => {
        if (ob.name && ob.control.value) {
          acc[ob.name] = ob.control.value;
        }

        return acc;
      }, {});
    }

    return values;
  }

  generateFiltersItems(filters: USearchFilter[]) {
    let values: any = {};

    if (filters) {
      values = filters.reduce((acc, ob) => {
        if (ob.name && ob.items) {
          acc[ob.name] = ob.items;
        }

        return acc;
      }, {});
    }

    return values;
  }

  private showRoutesPopupError() {
    this.uPopupService.showMessage({
      message: passengersConfig.dictionary.confirmRoutesPopup.message,
      yes: passengersConfig.dictionary.confirmRoutesPopup.confirm
    }, null);
  }

  private processValidationErrors(validationProperty: any, validationErrors: any): boolean {
    return validationErrors[validationProperty]
      && (validationErrors[validationProperty] === 'FIELD_REQUIRED' || validationErrors[validationProperty] === 'NOT_VALID_FIELD');
  }

  private checkPassengerForErrors(passenger: PassengerDetails, isSupervisor?: boolean) {
    if (!passenger) { return; }

    const validationErrors = passenger.validationErrors;

    if (validationErrors && Object.keys(validationErrors).length !== 0) {
      if (this.processValidationErrors('Mobile', validationErrors)) {
        this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.saveErrors.mobileOrIdError });
      } else {
        this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.saveErrors.commonError });
      }
    } else {
      if (isSupervisor !== null && isSupervisor !== undefined) {
        this.toastr.success(`${isSupervisor ? 'rideSupervisors' : 'rideCommanders'}.saveSucceed`);

        return;
      }

      this.toastr.success('passengers.general.savedSuccessfully');
    }
  }

  updatePassengerFromTableAndCheckRoutes(currentPassenger: PassengerDetails, featureType: AuthModulePassengersFeatureType): Observable<PassengerDetails> {
    return this.passengersService.updatePassenger(currentPassenger)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            this.showRoutesPopupError();

            return of(null);
          }

          const passenger = response.value;
          const passengerRoutes = passenger.passengerRoutes;

          if (passengerRoutes && passengerRoutes.length) {
            return this.passengersRoutesPopupService.showRoutesPopup(passenger.passengerId, passengerRoutes)
              .pipe(
                switchMap(({ type, params }) => {
                  switch (type) {
                    case ModalActions.Save: {
                      return this.updatePassengerFromTableAndCheckRoutes(
                        {
                          ...passenger,
                          passengerRoutes: params.routes
                        },
                        featureType
                      );
                    }

                    case ModalActions.Cancel: {
                      return of(null);
                    }
                  }
                }),
                takeUntil(this.unsubscribe)
              );
          }

          this.checkPassengerForErrors(passenger);

          return of(passenger);
        })
      );
  }

  private updateSupervisor(supervisor: PassengerDetails, isSupervisor: boolean = true): Observable<PassengerDetails> {
    if (isSupervisor) {
      return this.supervisorsService.updateSupervisor(supervisor);
    }

    return this.commandersService.updateCommander(supervisor);
  }

  updateSupervisorFromTableAndCheckRoutes(currentPassenger: PassengerDetails, isSupervisor: boolean = true): Observable<PassengerDetails> {
    return this.updateSupervisor(currentPassenger, isSupervisor)
      .pipe(
        switchMap(passenger => {
          if (!passenger) {
            this.showRoutesPopupError();

            return of(null);
          }

          const passengerRoutes = passenger.passengerRoutes;

          if (passengerRoutes && passengerRoutes.length) {
            return this.passengersRoutesPopupService.showRoutesPopup(passenger.passengerId, passengerRoutes)
              .pipe(
                takeUntil(this.unsubscribe),
                switchMap(({ type, params }) => {
                  switch (type) {
                    case ModalActions.Save: {
                      return this.updateSupervisorFromTableAndCheckRoutes(
                        {
                          ...passenger,
                          passengerRoutes: params.routes
                        },
                        isSupervisor
                      );
                    }

                    case ModalActions.Cancel: {
                      return of(null);
                    }
                  }
                })
              );
          }

          this.checkPassengerForErrors(passenger, isSupervisor);

          return of(passenger);
        })
      );
  }

  onGlobalFiltersChange(filters: USearchFilter[], extraFilters: PassengersExtraFilters, passengersInitialDataStoreService: PassengersInitialDataStoreService) {
    const branches = filters.find(item => item.name === 'branches');
    const shifts = filters.find(item => item.name === 'shifts');

    if (branches && branches.items && shifts && shifts.items) {
      this.filtersSubscription.add(
        branches.control
          .valueChanges
          .subscribe(branchIds => {
            if (branchIds && branchIds.length && extraFilters.originalOptions && extraFilters.originalOptions.shifts) {
              const originalShifts = extraFilters.originalOptions.shifts;
              const hasBranchesWithoutShift = !branchIds
                .every(branchId => originalShifts.some(shift => shift.branchId === branchId));
              const mainBranchId = passengersInitialDataStoreService.data().branches.find(ob => ob.isMain).id;

              shifts.items = originalShifts
                .filter(shift => branchIds.includes(shift.branchId) || (hasBranchesWithoutShift && shift.branchId === mainBranchId))
                .map(shift => ({ value: shift.id, name: shift.name }));
            } else {
              shifts.items = extraFilters.options.shifts;
            }

            this.headerSearchFiltersService.updateFilterItems('shifts', shifts.items);
          })
      );
    }
  }

  getDistanceRange(
    distance: number,
    params: {
      bottom: number;
      top: number;
      step: number
    } = {
      bottom: 100,
      top: 1000,
      step: 99
    }): { between: number; to: number; } {
    if (distance < params.bottom) {
      return { between: null, to: params.bottom };
    }

    if (distance > params.top) {
      return { between: params.top, to: null };
    }

    for (let i = params.bottom; i < params.top; i += (params.step + 1)) {
      if (distance < i + params.step + 1) {
        return { between: i, to: i + params.step };
      }
    }
  }

  sortDistanceRange(a, b): -1 | 0 | 1 {
    if (a === PassengerDistanceToStation.NoStation || a === PassengerDistanceToStation.NoValue) {
      return -1;
    }

    if (a && b) {
      if ((a && a.between) < (b && b.between)) {
        return -1;
      }

      if ((a && a.between) > (b && b.between)) {
        return 1;
      }
    }

    return 0;
  }

  openAddEditModal(editData?: PassengerDetails, mode?: PassengersAddEditMode, excludedRouteId?: number): Observable<BsModalRef> {
    return this.authDataService.moduleFeatureByName$(AuthModuleName.Passengers, AuthModulePassengersFeature.Type)
      .pipe(
        take(1),
        switchMap(type =>
          forkJoin([
            of(type),
            this.passengersService.getInitialData({ types: passengersConfig.initialBaseAddEditDataTypes[type] || passengersConfig.initialBaseAddEditDataTypes.default })
          ])
        ),
        switchMap(([ type, initialData ]) => {
          const addEditComponents = {
            [AuthModulePassengersFeatureType.Student]: PassengersMunicipalityAddEditComponent,
            [AuthModulePassengersFeatureType.Generic]: PassengersGenericAddEditComponent,
            [AuthModulePassengersFeatureType.Soldier]: PassengersArmyAddEditComponent,
            [AuthModulePassengersFeatureType.Iec]: PassengersGenericAddEditComponent
          };

          return of(this.bsModalService.show(
            addEditComponents[type],
            {
              class: this.uSidebarMenuService.getCollapsedValue() ? 'u-modal u-modal_content u-modal_content-hide-menu' : 'u-modal u-modal_content',
              animated: true,
              ignoreBackdropClick: true,
              backdrop: true,
              keyboard: false,
              initialState: {
                editPassenger: {
                  editData,
                  initialData
                },
                mode,
                excludedRouteId
              }
            }
          ));
        })
      );
  }

  editPassengerById(passengerId, mode?: PassengersAddEditMode, excludedRouteId?: number): Observable<BsModalRef> {
    return this.passengersService.getPassengerDetails(passengerId)
      .pipe(
        take(1),
        switchMap(passenger => this.openAddEditModal(passenger, mode, excludedRouteId))
      );
  }

  getConfirmationPopupData(isPerson?: boolean) {
    return passengersConfig.dictionary.confirmationPopups[isPerson ? 'existPerson' : 'existPassenger'];
  }

  getExistingGlobalFilterControl(value, disabled?: boolean): UntypedFormControl {
    return new UntypedFormControl({ value: value, disabled });
  }
}
