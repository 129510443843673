import { UInputCitiesCityLocation, USelectSItem } from '@shift/ulib';

import { PassengerDemandStatus, PassengerStatusType, PassengerType } from '@app/passengers/models';

export enum PassengerMunicipalityParentRoleType {
  Passenger = 0,
  Parent = 35,
  Siblings = 36,
  Other = 80,
  SocialWorker = 232
}

export interface PassengerMunicipalityDisabilityCode {
  id: number;
  name: string;
  eligibleForShuttle: boolean;
  eligibleForAccompany: boolean;
}

export interface PassengerMunicipalityDisabilityCodesListData {
  disabilityCodes: PassengerMunicipalityDisabilityCode[];
  shuttleApprovals: USelectSItem[];
  accompanyApprovals: USelectSItem[];
}

export class PassengersMunicipalityGetParameters {
  search: string;
  passengerType: PassengerType;
  departmentsIds: number[];
  schoolsIds: number[];
  classTypeIds: number[];
  statusTypes: PassengerStatusType[];
  shiftIds?: number[];
  demandStatuses?: PassengerDemandStatus[];
  branchIds?: number[];
  demandBranchIds?: number[];
  demandCities?: UInputCitiesCityLocation[];
  passengerCities?: UInputCitiesCityLocation[];
  skip: number;
  take: number;
}
