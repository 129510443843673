import {
  Component,
  ChangeDetectionStrategy,
  inject,
  HostBinding
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Languages, ModalActions } from '@app/shared/models';
import { LocalizationService } from '@app/shared/services';
import { accessibilityAffidavitComponentConfig } from './accessibility-affidavit.component.config';

@Component({
  selector: 'app-accessibility-affidavit',
  templateUrl: './accessibility-affidavit.component.html',
  styleUrls: [ './accessibility-affidavit.component.scss', './accessibility-affidavit.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessibilityAffidavitComponent {
  @HostBinding('class') hostClasses: string = 'accessibility-affidavit';

  private readonly modalRef = inject(BsModalRef);
  private readonly localizationService = inject(LocalizationService);

  config = cloneDeep(accessibilityAffidavitComponentConfig);
  isRtl = this.localizationService.isRtl();
  lang = this.localizationService.getLanguage();
  languages = Languages;

  onAction(action: ModalActions) {
    if (action === ModalActions.Close) {
      this.modalRef.hide();
    }
  }
}
