import { DestroyRef, Injectable, inject } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UGridActionName, UGridActionType, UGridService } from '@shift/ulib';

import { IntercomService, PlantrackService } from '@app/shared/services';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly uGridService = inject(UGridService);
  private readonly plantrackService = inject(PlantrackService);
  private readonly intercomService = inject(IntercomService);
  private readonly authDataService = inject(AuthDataService);

  private allowTracking: boolean;

  private onUserInfo() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(userInfo => {
        this.plantrackService.init(userInfo);
        this.intercomService.init(userInfo);

        this.allowTracking = userInfo.person.sendAnalytics;

        if (this.allowTracking) {
          this.track('Login');
        }
      });
  }

  private onGridAction() {
    this.uGridService.action$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(action => action.type === UGridActionType.Click && action.name === UGridActionName.AddressMapIcon)
      )
      .subscribe(() => this.track('Address field - click on map icon'));
  }

  track(message: string) {
    if (this.allowTracking) {
      this.plantrackService.track(message);
      this.intercomService.track(message);
    }
  }

  init() {
    this.onUserInfo();
    this.onGridAction();
  }
}
