import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { first } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UPopoverDirective } from '@shift/ulib';

import { AuthModuleDemandsPassengersViewFeatureType } from '@app/auth/models';
import { AppConstants } from '@app/shared/constants';
import {
  DemandsBulkPassengerShift,
  DemandsDayOfWeek,
  DemandsDemand,
  DemandsDemandType,
  DemandsPassengerShift,
  DemandsTableRow
} from '@app/demands/models';
import {
  DemandsPassengersViewService,
  DemandsCommonService
} from '@app/demands/services';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { demandsDayComponentConfig } from './demands-day.component.config';

@Component({
  selector: 'app-demands-day',
  templateUrl: './demands-day.component.html',
  styleUrls: [ './demands-day.component.scss', 'demands-day.component.rtl.scss' ]
})
export class DemandsDayComponent implements OnChanges {
  @Input() featureType: AuthModuleDemandsPassengersViewFeatureType;
  @Input() demands: (DemandsDemand | DemandsBulkPassengerShift)[];
  @Input() date: string;
  @Input() dayOfWeek: DemandsDayOfWeek;
  @Input() hasBranches: boolean;
  @Input() passenger: DemandsTableRow;
  @Input() passengers: DemandsTableRow[];
  @Input() isBulkAssignOpen: boolean;

  @HostBinding('class') hostClasses: string = 'demands-day';

  config = cloneDeep(demandsDayComponentConfig.default);
  dictionary = this.config.dictionary;
  demandsDemandType = DemandsDemandType;
  appConstants = AppConstants;
  assignShiftEditData: DemandsPassengerShift;
  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private demandsPassengersViewService: DemandsPassengersViewService,
    public demandsCommonService: DemandsCommonService,
    private trackingService: TrackingService,
    private localizationService: LocalizationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.featureType) {
      this.updateConfig();
    }
  }

  private updateConfig() {
    this.config = cloneDeep(demandsDayComponentConfig[this.featureType] || demandsDayComponentConfig.default);
    this.dictionary = this.config.dictionary;
  }

  private track(message: string) {
    this.trackingService.track(`[${this.config.tracking.id}] - ${message}`);
  }

  assignNewShift(popover: UPopoverDirective) {
    this.track(`Add assignment (click on '+' ${this.config.tracking.shift})`);

    if (this.demandsCommonService.assignShiftPopover && this.demandsCommonService.assignShiftPopover.isOpen()) { return false; }

    this.assignShiftEditData = null;
    this.demandsCommonService.assignShiftPopover = popover;
    this.demandsCommonService.assignShiftPopover.open();
    this.demandsCommonService.setBackdrop(true);
  }

  assignShiftEdit(demand: DemandsDemand, popover: UPopoverDirective) {
    this.track(`Edit ${this.config.tracking.shift} (click on existing ${this.config.tracking.shift})`);

    if (
      (this.demandsCommonService.assignShiftPopover && this.demandsCommonService.assignShiftPopover.isOpen()) ||
      this.isBulkAssignOpen
    ) { return false; }

    this.demandsCommonService.assignShiftPopover = popover;

    this.demandsPassengersViewService
      .getPassengerShift({
        passengerId: demand.passengerId,
        demandId: demand.demandId
      })
      .pipe(first())
      .subscribe(data => {
        this.assignShiftEditData = data;
        this.demandsCommonService.assignShiftPopover.open();
        this.demandsCommonService.setBackdrop(true);
      });
  }

  assignShiftDemandEdit(shiftDemand: DemandsBulkPassengerShift, popover: UPopoverDirective) {
    this.track(`Edit ${this.config.tracking.shift} (click on existing ${this.config.tracking.shift})`);

    if (this.demandsCommonService.assignShiftPopover && this.demandsCommonService.assignShiftPopover.isOpen()) { return false; }

    this.demandsCommonService.assignShiftPopover = popover;
    this.assignShiftEditData = shiftDemand;
    this.demandsCommonService.assignShiftPopover.open();
    this.demandsCommonService.setBackdrop(true);
  }

  assignShiftClose() {
    if (this.demandsCommonService.assignShiftPopover && this.demandsCommonService.assignShiftPopover.isOpen()) {
      this.demandsCommonService.assignShiftPopover.close();
      this.demandsCommonService.assignShiftPopover = null;
      this.demandsCommonService.setBackdrop(false);
    }
  }
}
