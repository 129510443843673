import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { ApprovalsConfig } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ApprovalsConfigService {
  private BASE_PATH: string = 'ApprovalsConfig';

  constructor(
    private apiService: ApiService
  ) {}

  getApprovalsConfig(): Observable<ApprovalsConfig> {
    return this.apiService.get(this.BASE_PATH);
  }
}
