import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

import { TrackingService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuthDataService } from '@app/auth/services';
import { AuthModuleName, AuthModuleSupervisorsFeature, AuthModuleSupervisorsFeatureType } from '@app/auth/models';
import { RouteDailyRow } from '@app/routes/models';
import { RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { routesConfig } from '@app/routes/configs';
import { routesChangeTimeTrackingComponentConfig } from './routes-change-time-tracking.component.config';

@Component({
  selector: 'app-routes-change-time-tracking',
  templateUrl: './routes-change-time-tracking.component.html',
  styleUrls: [ './routes-change-time-tracking.component.scss', './routes-change-time-tracking.component.rtl.scss' ]
})
export class RoutesChangeTimeTrackingComponent implements OnInit {
  @Input() activeRide: RouteDailyRow;

  @HostBinding('class') hostClasses: string = 'routes-change-time-tracking';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly authDataService = inject(AuthDataService);
  private readonly routesCommonService = inject(RoutesCommonService);
  private readonly trackingService = inject(TrackingService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly routesTableService = inject(RoutesTableService);

  form: UntypedFormGroup;
  config = cloneDeep(routesChangeTimeTrackingComponentConfig);
  supervisorType: AuthModuleSupervisorsFeatureType;
  supervisorTypes = AuthModuleSupervisorsFeatureType;

  ngOnInit() {
    this.getSupervisorsFeature();
    this.initForm();
  }

  private getSupervisorsFeature() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.Supervisors, AuthModuleSupervisorsFeature.Type)
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(supervisorsType => this.supervisorType = supervisorsType);
  }

  private initForm() {
    this.form = this.fb.group({
      startDate: [ this.activeRide['timeTrackingStartDateTime'] ],
      startTime: [ this.activeRide['timeTrackingStartDateTime'] ? moment(this.activeRide['timeTrackingStartDateTime']).format(AppConstants.TIME_FORMAT) : null ],
      endDate: [ this.activeRide['timeTrackingEndDateTime'] ],
      endTime: [ this.activeRide['timeTrackingEndDateTime'] ? moment(this.activeRide['timeTrackingEndDateTime']).format(AppConstants.TIME_FORMAT) : null ],
      comment: [ '' ]
    });

    this.form.get('startDate').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.trackEvent('change start date'));

    this.form.get('startTime').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.trackEvent('change start time'));

    this.form.get('endDate').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.trackEvent('change end date'));

    this.form.get('endTime').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.trackEvent('change end time'));
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${routesConfig.trackingId}] - supv. time report - ${event}`);
  }

  saveChanges() {
    const startDateTime = this.form.value.startDate && moment(this.form.value.startDate).utc().set({
      hours: moment(this.form.value.startTime, AppConstants.TIME_FORMAT).hours(),
      minutes: moment(this.form.value.startTime, AppConstants.TIME_FORMAT).minutes()
    }).format(AppConstants.DATE_FORMAT_ISO);

    const endDateTime = this.form.value.endDate && moment(this.form.value.endDate).utc().set({
      hours: moment(this.form.value.endTime, AppConstants.TIME_FORMAT).hours(),
      minutes: moment(this.form.value.endTime, AppConstants.TIME_FORMAT).minutes()
    }).format(AppConstants.DATE_FORMAT_ISO);

    this.trackEvent('click on save&close');

    this.routesTableService.changeTimeTrack({
      routeId: this.activeRide.routeId,
      activeDate: this.headerDataService.getActiveDate(),
      value: {
        comment: this.form.value.comment,
        rideId: this.activeRide['rideId'],
        startDateTime,
        endDateTime
      }
    })
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }
}
