import {
  GridColumn,
  TablePageConfig
} from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.auditHistory';

const columns: GridColumn[] = [
  {
    name: `${DICTIONARY_PATH}.changeType`,
    prop: 'type',
    minWidth: 102,
    width: 120,
    resizeable: true,
    filterType: 'select'
  },
  {
    name: `${DICTIONARY_PATH}.changeContent`,
    prop: 'content',
    minWidth: 128,
    resizeable: true,
    filterType: 'text',
    cellTemplateName: 'contentCell',
    cellClassFnName: 'twoLinesClass'
  },
  {
    name: `${DICTIONARY_PATH}.changedBy`,
    prop: 'author',
    minWidth: 106,
    width: 125,
    resizeable: true,
    filterType: 'select'
  },
  {
    name: `${DICTIONARY_PATH}.changeAt`,
    prop: 'dateTime',
    minWidth: 106,
    width: 125,
    resizeable: true
  }
];

const tablePageConfig: TablePageConfig = {
  id: 'auditHistory',
  tableConfig: {
    columns,
    showCounters: false
  },
  hideMetadata: true
};

export const auditHistoryComponentConfig = {
  tablePageConfig
};
