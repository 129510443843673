import {
  OnInit,
  Output,
  inject,
  signal,
  Component,
  DestroyRef,
  HostBinding,
  ChangeDetectionStrategy,
  Signal,
  WritableSignal,
  EventEmitter
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { cloneDeep } from 'lodash';

import { LocalizationService, ValidationService } from '@app/shared/services';
import { ModalActions, MultiDeleteConfirmDictionary } from '@app/shared/models';
import { multiDeleteConfirmComponentConfig } from './multi-delete-confirm.component.config';

@Component({
  selector: 'app-multi-delete-confirm',
  templateUrl: './multi-delete-confirm.component.html',
  styleUrls: [ './multi-delete-confirm.component.scss', './multi-delete-confirm.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiDeleteConfirmComponent implements OnInit {
  @Output() action = new EventEmitter<ModalActions>();

  @HostBinding('class') hostClasses: string = 'multi-delete-confirm';

  private readonly destroyRef = inject(DestroyRef);
  public readonly modalRef = inject(BsModalRef);
  public readonly translateService = inject(TranslateService);
  public readonly localizationService = inject(LocalizationService);

  readonly #deleteWarningMessage: WritableSignal<string> = signal('');
  readonly #dictionaryStore: WritableSignal<MultiDeleteConfirmDictionary> = signal({});

  readonly deleteWarningMessage: Signal<string> = this.#deleteWarningMessage.asReadonly();
  readonly dictionaryStore: Signal<MultiDeleteConfirmDictionary> = this.#dictionaryStore.asReadonly();

  config = cloneDeep(multiDeleteConfirmComponentConfig);
  isRtl: boolean = this.localizationService.isRtl();
  deleteConfirmType: string;
  itemsToDeleteAmount: number;
  deleteWarningMessages: string[] = [];
  itemsToDeleteAmountCtrl: UntypedFormControl;
  dictionary?: MultiDeleteConfirmDictionary;

  ngOnInit() {
    this.translate();

    this.itemsToDeleteAmountCtrl = new UntypedFormControl(0, [ ValidationService.equalsTo(this.itemsToDeleteAmount) ]);
  }

  private translate() {
    if (!this.dictionary && this.deleteConfirmType) {
      forkJoin([
        this.translateService.get(this.deleteConfirmType),
        this.translateService.get(this.config.dictionaryPath)
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(([ type, dictionary ]) => {
          const modifiedDictionary = cloneDeep(dictionary);

          this.deleteConfirmType = type;

          Object.keys(modifiedDictionary).forEach(item => modifiedDictionary[item] = modifiedDictionary[item].replace('{{type}}', this.deleteConfirmType));

          this.#dictionaryStore.set(modifiedDictionary);
        });
    } else {
      this.#dictionaryStore.set(cloneDeep(this.dictionary));
    }

    this.translateService.get(this.dictionaryStore().itemsToDeleteWarning)
      .subscribe(translated => this.#deleteWarningMessage.set(translated.replace('{{amount}}', this.itemsToDeleteAmount)));
  }

  closePopup() {
    this.action.emit(ModalActions.Close);
    this.modalRef.hide();
  }

  save() {
    this.action.emit(ModalActions.Save);
  }
}
