import { AuthCustomerType } from '@app/auth/models';
import { EnvType, MovePassengersLegend } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.movePassengersInfo';

const destinationLegend: MovePassengersLegend = {
  id: 'destination',
  title: `${DICTIONARY_PATH}.legend.destination`,
  iconUrl: '/assets/images/map/point-destination.svg'
};

const stationLegend: MovePassengersLegend = {
  id: 'station',
  title: `${DICTIONARY_PATH}.legend.station`,
  description: `${DICTIONARY_PATH}.legend.stationDescription`,
  iconUrl: '/assets/images/map/station-number.svg'
};

const stationLegendOrange: MovePassengersLegend = {
  ...stationLegend,
  iconUrl: '/assets/images/themes/orange/map/station-number.svg'
};

const stationLegendGreen: MovePassengersLegend = {
  ...stationLegend,
  iconUrl: '/assets/images/themes/green/map/station-number.svg'
};

const accompanyStationLegend: MovePassengersLegend = {
  id: 'accompanyStation',
  title: `${DICTIONARY_PATH}.legend.accompanyStation`,
  description: `${DICTIONARY_PATH}.legend.accompanyStationDescription`,
  iconUrl: '/assets/images/map/point-accompany.svg'
};

const legendsDefault = [
  destinationLegend,
  stationLegend
];

const legendsOrange = [
  destinationLegend,
  stationLegendOrange
];

const legendsGreen = [
  destinationLegend,
  stationLegendGreen
];

const legendsByEnvTypes = {
  default: legendsDefault,
  [EnvType.Traffical]: legendsOrange,
  [EnvType.IDF]: legendsOrange
};

const legends = {
  default: legendsByEnvTypes,
  [AuthCustomerType.ShuttleCompany]: {
    ...legendsByEnvTypes,
    [EnvType.Shift]: legendsGreen
  }
};

export const movePassengersInfoComponentConfig = {
  dictionary: {
    km: `${DICTIONARY_PATH}.km`,
    time: `${DICTIONARY_PATH}.time`,
    legend: {
      title: `${DICTIONARY_PATH}.legend.title`
    }
  },
  legends,
  accompanyStationLegend
};
