import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { MonitoringDashboardRideBase } from '@app/monitoring-dashboard/models';
import { AppConstants } from '@app/shared/constants';

@Pipe({
  name: 'monitoringDashboardRidesSearch'
})
export class MonitoringDashboardRidesSearchPipe implements PipeTransform {
  transform(rides: MonitoringDashboardRideBase[], searchText: string): MonitoringDashboardRideBase[] {
    if (!rides || !searchText) {
      return rides;
    }

    return rides.filter(ride => {
      const time = `${moment(ride['startDateTime']).format(AppConstants.TIME_FORMAT)} - ${moment(ride['endDateTime']).format(AppConstants.TIME_FORMAT)}`;

      return time.indexOf(searchText) !== -1 ||
        Object.keys(ride)
          .filter(key => typeof ride[key] === 'string')
          .some(stringKey => ride[stringKey].toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    });
  }
}
