<ng-container *ngIf="isInited$ | async; else loading">
  <ng-container *ngIf="isLoggedIn$ | async; else login">
    <app-layout></app-layout>
  </ng-container>
</ng-container>

<ng-template #login>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
