import { computed, inject, Injectable, signal } from '@angular/core';
import { exhaustMap, filter } from 'rxjs/operators';
import { pipe } from 'rxjs';

import { CitiesService } from '@app/shared/services';
import { patchSignal, rxMethod, tapResponse, transformToInputCities } from '@app/shared/utils';
import { BuilderFilterState } from '@app/builder/models';

@Injectable()
export class BuilderFilterStoreService {
  private readonly citiesService = inject(CitiesService);

  readonly #state = signal<BuilderFilterState>({ cities: null });

  readonly state = this.#state.asReadonly();
  readonly cities = computed(() => this.state().cities);
  readonly citiesBySelectedBranchIds = (branchIds: number[]) => computed(() => (this.cities() || [])
    .reduce((acc, city) => {
      const branches = branchIds.length ? city.branches.filter(branch => branchIds.includes(branch.branchId)) : city.branches;

      if (branches?.length) {
        return [ ...acc, { ...city, branches } ];
      }

      return acc;
    }, []));

  readonly getCities = rxMethod<void>(
    pipe(
      filter(() => !this.cities()),
      exhaustMap(() =>
        this.citiesService.getFilter()
          .pipe(
            tapResponse(
              data => patchSignal(this.#state, { cities: transformToInputCities(data) }),
              () => null
            )
          )
      )
    )
  );

  reset() {
    this.#state.set({ cities: null });
  }
}
