import { Pipe, PipeTransform } from '@angular/core';

import { BuilderBuildMode, BuilderConfig, BuilderMode } from '@app/builder/models';

@Pipe({
  name: 'builderIsRemovePassengerAllowed'
})
export class BuilderIsRemovePassengerAllowedPipe implements PipeTransform {
  transform(builderConfig: BuilderConfig, passengerId: number, selectedPassengerId: number): boolean {
    if (builderConfig.buildMode === BuilderBuildMode.RouteSuggestions) {
      return builderConfig.mode === BuilderMode.Add ? passengerId !== selectedPassengerId : passengerId === selectedPassengerId;
    }

    return true;
  }
}

