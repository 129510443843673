import { Pipe, PipeTransform } from '@angular/core';

import { routesDailyMovePassengersConfig } from '@app/routes/configs';
import { RouteDaily, RoutesDailyMovePassengersRoute } from '@app/routes/models';

@Pipe({
  name: 'routesDailyMovePassengersRouteIds'
})
export class RoutesDailyMovePassengersRouteIdsPipe implements PipeTransform {
  transform(routes: RouteDaily[], passengerRoutes: RoutesDailyMovePassengersRoute[]): string[] {
    if (routes && passengerRoutes) {
      return [
        ...routes.map(route => `${routesDailyMovePassengersConfig.idPrefixes.route}-${route.routeId}`),
        ...passengerRoutes.map(route => `${routesDailyMovePassengersConfig.idPrefixes.passengerRoute}-${route.routeId}`)
      ];
    }

    if (routes) {
      return routes.map(route => `${routesDailyMovePassengersConfig.idPrefixes.route}-${route.routeId}`);
    }

    return passengerRoutes.map(route => `${routesDailyMovePassengersConfig.idPrefixes.passengerRoute}-${route.routeId}`);
  }
}
