import { GeneralCardButton, GeneralCardButtonValue } from '@app/shared/models';

const DICTIONARY_PATH: string = 'auth.customerSwitchRole';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  customer: `${DICTIONARY_PATH}.customer`,
  role: `${DICTIONARY_PATH}.role`
};

const buttons: GeneralCardButton[] = [
  { value: GeneralCardButtonValue.Ok, name: 'general.ok' }
];

export const authCustomerSwitchRoleComponentConfig = {
  dictionary,
  buttons
};
