import { BuilderBuildMode } from '@app/builder/models';

const DICTIONARY_PATH: string = 'builder.stations';

export const builderStationsComponentConfig = {
  trackingId: 'Routes & Rides, Route editor - Station card',
  dictionary: {
    customer: `${DICTIONARY_PATH}.customer`,
    warning: {
      original: `${DICTIONARY_PATH}.warning.original`,
      name: `${DICTIONARY_PATH}.warning.name`,
      phone: `${DICTIONARY_PATH}.warning.phone`,
      address: `${DICTIONARY_PATH}.warning.address`,
      bringBack: `${DICTIONARY_PATH}.warning.bringBack`,
      hour: `${DICTIONARY_PATH}.warning.hour`,
      priceType: `${DICTIONARY_PATH}.warning.priceType`,
      totalPrice: `${DICTIONARY_PATH}.warning.totalPrice`,
      selectDestination: `${DICTIONARY_PATH}.warning.selectDestination`
    },
    passenger: {
      destinationChange: {
        title: `${DICTIONARY_PATH}.passenger.destinationChange.title`,
        search: `${DICTIONARY_PATH}.passenger.destinationChange.search`,
        destinations: `${DICTIONARY_PATH}.passenger.destinationChange.destinations`,
        notFound: `${DICTIONARY_PATH}.passenger.destinationChange.notFound`,
        stations: `${DICTIONARY_PATH}.passenger.destinationChange.stations`
      }
    },
    station: {
      dragToHere: `${DICTIONARY_PATH}.station.dragToHere`,
      zoomStation: `${DICTIONARY_PATH}.station.zoomStation`,
      moveStationDown: `${DICTIONARY_PATH}.station.moveStationDown`,
      moveStationUp: `${DICTIONARY_PATH}.station.moveStationUp`,
      deleteStation: `${DICTIONARY_PATH}.station.deleteStation`,
      moreActions: `${DICTIONARY_PATH}.station.moreActions`,
      pickUpFirst: `${DICTIONARY_PATH}.station.pickUpFirst`,
      pickUpHome: `${DICTIONARY_PATH}.station.pickUpHome`,
      withBack: `${DICTIONARY_PATH}.station.withBack`,
      withoutBack: `${DICTIONARY_PATH}.station.withoutBack`,
      setAsFirst: `${DICTIONARY_PATH}.station.setAsFirst`,
      setAsLast: `${DICTIONARY_PATH}.station.setAsLast`,
      convertToSource: `${DICTIONARY_PATH}.station.convertToSource`,
      convertToDestination: `${DICTIONARY_PATH}.station.convertToDestination`,
      saveInStationsList: `${DICTIONARY_PATH}.station.saveInStationsList`,
      convertToStation: `${DICTIONARY_PATH}.station.convertToStation`,
      addNewPassenger: `${DICTIONARY_PATH}.station.addNewPassenger`,
      addAnonymousPassenger: `${DICTIONARY_PATH}.station.addAnonymousPassenger`,
      passengers: `${DICTIONARY_PATH}.station.passengers`,
      externalPassengers: `${DICTIONARY_PATH}.station.externalPassengers`,
      type: {
        accompany: `${DICTIONARY_PATH}.station.type.accompany`,
        destination: `${DICTIONARY_PATH}.station.type.destination`,
        station: `${DICTIONARY_PATH}.station.type.station`,
        start: `${DICTIONARY_PATH}.station.type.start`
      },
      passenger: {
        assignAsRideSupervisor: `${DICTIONARY_PATH}.station.passenger.assignAsRideSupervisor`,
        assignAsRideCommander: `${DICTIONARY_PATH}.station.passenger.assignAsRideCommander`,
        notComing: `${DICTIONARY_PATH}.station.passenger.notComing`,
        coming: `${DICTIONARY_PATH}.station.passenger.coming`,
        moveToLast: `${DICTIONARY_PATH}.station.passenger.moveToLast`,
        moveToFirst: `${DICTIONARY_PATH}.station.passenger.moveToFirst`,
        supervisor: `${DICTIONARY_PATH}.station.passenger.supervisor`,
        commander: `${DICTIONARY_PATH}.station.passenger.commander`,
        convertToPassenger: `${DICTIONARY_PATH}.station.passenger.convertToPassenger`,
        editPassengerDetails: `${DICTIONARY_PATH}.station.passenger.editPassengerDetails`,
        noMobile: `${DICTIONARY_PATH}.station.passenger.noMobile`
      },
      accompany: {
        cost: `${DICTIONARY_PATH}.station.accompany.cost`,
        costH: `${DICTIONARY_PATH}.station.accompany.costH`,
        hours: `${DICTIONARY_PATH}.station.accompany.hours`,
        return: `${DICTIONARY_PATH}.station.accompany.return`
      }
    },
    required: 'builder.options.accompanies.required'
  },
  editStationArrivalDateTimeBuildModes: [
    BuilderBuildMode.Routes,
    BuilderBuildMode.RouteTemplates,
    BuilderBuildMode.RideOrders
  ]
};
