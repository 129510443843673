import { Action, combineReducers } from '@ngrx/store';

import * as coreReducers from '@app/shared/state/reducers';
import * as rideOrdersReducer from '@app/ride-orders/state/reducers/ride-orders.reducer';

export const FEATURE_KEY = 'rideOrders';

export interface RideOrdersState {
  rideOrders: rideOrdersReducer.State;
}

export interface State extends coreReducers.State {
  [FEATURE_KEY]: RideOrdersState;
}

export function reducers(state: RideOrdersState | undefined | any, action: Action) {
  return combineReducers({
    [rideOrdersReducer.FEATURE_KEY]: rideOrdersReducer.reducer
  })(state, action);
}
