import {
  Component,
  computed,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { cloneDeep, isEqual } from 'lodash';
import { UAddress } from '@shift/ulib';

import { swapArrayElements } from '@app/shared/utils';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuthDataSnapshotService } from '@app/auth/services';
import { AuthUserInfo } from '@app/auth/models';
import { RouteRideSupervisorLevel } from '@app/routes/models';
import {
  BuilderAccompanyOptionsType,
  BuilderActiveRide,
  BuilderBuildMode,
  BuilderCalculationMode,
  BuilderPassenger,
  BuilderPassengerType,
  BuilderStationArrivalTimeEditAction,
  BuilderStationArrivalTimeSaveAction,
  BuilderStationListItem,
  BuilderStationListItemType,
  BuilderViewMode
} from '@app/builder/models';
import { BuilderDataService } from '@app/builder/services';
import { builderStationsComponentConfig } from './builder-stations.component.config';

@Component({
  selector: 'app-builder-stations',
  templateUrl: './builder-stations.component.html',
  styleUrls: [ './builder-stations.component.scss', './builder-stations.component.rtl.scss' ]
})
export class BuilderStationsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stations: BuilderStationListItem[];
  @Input() customerData: any;
  @Input() options: any;
  @Input() viewportPopover: HTMLElement;
  @Input() viewportDropdown: HTMLElement;
  @Input() viewportArrivalTimePopover: HTMLElement;
  @Input() stationShowOnMapIcon = false;
  @Input() passengerEyeIcon = false;
  @Input() inactive = false;
  @Input() destinationStart = false;
  @Input() selectedPassengerId: number;
  @Input() hasRouteChanged: number;
  @Input() disabled: boolean;
  @Input() authUserInfo: AuthUserInfo;
  @Input() activeRide: BuilderActiveRide;

  @Output() stationsOrderAction: EventEmitter<void> = new EventEmitter();
  @Output() stationShowOnMapAction: EventEmitter<any> = new EventEmitter();
  @Output() stationRemoveAction: EventEmitter<any> = new EventEmitter();
  @Output() stationEditAction: EventEmitter<any> = new EventEmitter();
  @Output() stationAccSyncAction: EventEmitter<any> = new EventEmitter();
  @Output() stationAccReturnAction: EventEmitter<any> = new EventEmitter();
  @Output() stationAccPickUpAction: EventEmitter<any> = new EventEmitter();
  @Output() stationSetFirstAction: EventEmitter<any> = new EventEmitter();
  @Output() stationSetLastAction: EventEmitter<any> = new EventEmitter();
  @Output() stationSetDestinationAction: EventEmitter<any> = new EventEmitter();
  @Output() stationSaveAction: EventEmitter<any> = new EventEmitter();
  @Output() stationAddNewPassengerAction: EventEmitter<{ rideStationId: number; }> = new EventEmitter();
  @Output() stationAddAnonymousPassengerAction: EventEmitter<{ rideStationId: number; }> = new EventEmitter();
  @Output() stationEditArrivalTimeAction: EventEmitter<BuilderStationArrivalTimeEditAction> = new EventEmitter();
  @Output() passengerEyeAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerRemoveAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerMoveToStationAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerMoveToFirstAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerMoveToLastAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerComingSetAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerSupervisorSetAction: EventEmitter<{ passengerId: number; }> = new EventEmitter();
  @Output() passengerSupervisorResetAction: EventEmitter<{ passengerId: number; }> = new EventEmitter();
  @Output() passengerEditAction: EventEmitter<BuilderPassenger> = new EventEmitter();
  @Output() passengerAnonymousEditAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerDestinationSetAction: EventEmitter<any> = new EventEmitter();
  @Output() passengerNameHoverAction: EventEmitter<{ passengerId: number; }> = new EventEmitter();
  @Output() formControlClick: EventEmitter<void> = new EventEmitter();

  @HostBinding('class')
  get hostClasses() {
    return {
      'builder-stations': true,
      'builder-stations_disabled': this.disabled
    };
  }

  private readonly localizationService = inject(LocalizationService);
  public readonly trackingService = inject(TrackingService);
  public readonly builderDataService = inject(BuilderDataService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  private dndBlocked: boolean;
  private stationsStore = null;
  private unsubscribe: Subject<void> = new Subject();

  readonly authModules = this.builderDataService.authModules;
  readonly customerIsOwnedByScAndSupervisorModuleStatusActive = this.builderDataService.customerIsOwnedByScAndSupervisorModuleStatusActive;
  readonly customerSupervisorModuleStatusFeatureType = this.builderDataService.customerSupervisorModuleStatusFeatureType;
  readonly customerSupervisorModuleStatusFeatureTypeGeneric = this.builderDataService.customerSupervisorModuleStatusFeatureTypeGeneric;
  readonly customerSupervisorModuleStatusFeatureTypeCommander = this.builderDataService.customerSupervisorModuleStatusFeatureTypeCommander;
  readonly routeBuilderFeatureTypeGeneric = this.builderDataService.routeBuilderFeatureTypeGeneric;
  readonly routeBuilderFeatureTypeShuttleCompany = this.builderDataService.routeBuilderFeatureTypeShuttleCompany;
  readonly routeBuilderFeatureMasterCustomer = this.builderDataService.routeBuilderFeatureMasterCustomer;
  readonly passengersFeatureType = computed(() => this.authModules()?.passengers?.type);

  appConstants = AppConstants;
  editStationArrivalDateTime: boolean;
  builderBuildMode = BuilderBuildMode;
  builderViewMode = BuilderViewMode;
  builderStationListItemType = BuilderStationListItemType;
  warningAccompanyInfo: any = null;
  routeRideSupervisorLevel = RouteRideSupervisorLevel;
  isRtl: boolean = true;
  stationEdit = {
    id: null,
    param: null,
    data: '',
    type: null,
    extra: {
      latitude: null,
      longitude: null,
      placeId: null
    }
  };

  passengerAnonymousEdit = {
    rideStationId: null,
    ridePassengerId: null,
    amount: null
  };
  passengerDestinationEdit = {
    search: '',
    stations: [],
    stationsStore: [],
    destinations: [],
    destinationsStore: [],
    rideStationId: null,
    ridePassengerId: null,
    popover: null
  };
  passengerDestinationHover = {
    name: null,
    address: null
  };
  builderCalculationMode = BuilderCalculationMode;
  builderPassengerType = BuilderPassengerType;
  config = cloneDeep(builderStationsComponentConfig);

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();

    this.initEditStationArrivalDateTime();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stations && this.stations) {
      this.passengerDestinationEdit.search = '';

      this.passengerDestinationEdit.stationsStore = this.stations
        .filter((ob: any) => ob.type === 'station')
        .map((ob: any) => ({
          targetRideStationId: ob.rideStationId,
          name: ob.name,
          order: ob.order
        }));

      this.passengerDestinationEdit.stations = this.passengerDestinationEdit.stationsStore;

      this.passengerDestinationEdit.destinationsStore = this.stations
        .filter((ob: any) => ob.type === 'destination')
        .map((ob: any) => ({
          targetRideStationId: ob.rideStationId,
          name: ob.name,
          address: ob.address,
          order: ob.order
        }));

      this.passengerDestinationEdit.destinations = this.passengerDestinationEdit.destinationsStore;
    }
  }

  private initEditStationArrivalDateTime() {
    this.editStationArrivalDateTime = this.config.editStationArrivalDateTimeBuildModes.includes(this.builderDataService.buildMode()) && this.builderDataService.viewModeDefault();
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${builderStationsComponentConfig.trackingId}] - ${event}`);
  }

  warningAccompanyInfoSet(station: any) {
    const { planned } = station.expand.options;
    const accompany = this.customerData.accompanies.find((ob: any) => ob.id === planned.accompanyId);
    const plannedCostType = this.options.costAccompany.find((ob: any) => ob.value === planned.plannedCostType);
    const pickUpHome = this.options.returnAccompany.find((ob: any) => ob.value === planned.destinationStationId !== null);

    if (accompany && accompany.id !== BuilderAccompanyOptionsType.Without) {
      this.warningAccompanyInfo = {
        id: accompany.id,
        name: accompany.name,
        mobile: accompany.mobile,
        pickUpHome: pickUpHome ? pickUpHome.name : null,
        address: accompany.address,
        longitude: accompany.longitude,
        latitude: accompany.latitude,
        costPerHour: planned.plannedCostPerHour,
        costType: plannedCostType ? plannedCostType.name : null,
        hours: planned.plannedHours,
        totalCost: planned.plannedTotalCost
      };
    } else {
      this.warningAccompanyInfo = {
        id: accompany.id,
        name: 'builder.options.accompanies.without'
      };
    }
  }

  warningAccompanyInfoReset() {
    this.warningAccompanyInfo = null;
  }

  stationEditChange(station: any, param: string) {
    if (!this.builderDataService.customerIsOwnedBySc()) {
      return;
    }

    if (this.stationEdit.id && this.stationEdit.id !== station.rideStationId) {
      this.stationEditClose(this.stationEdit);
    }

    this.stationEdit.id = station.rideStationId;
    this.stationEdit.param = param;
    this.stationEdit.type = station.type;

    if (param === 'name' && station.type === 'accompany') {
      this.stationEdit.data = station.expand.options.accompanyId;
    } else {
      this.stationEdit.data = station[param];
    }

    if (param === 'address') {
      this.stationEdit.extra = {
        latitude: station.latitude,
        longitude: station.longitude,
        placeId: null
      };
    }
  }

  stationEditClose(station: any, event?: PointerEvent) {
    if (!!event?.view.window.getSelection().toString().length) {
      return;
    }

    const { id, param, type, data, extra } = this.stationEdit;

    if (type === 'accompany' && param === 'name') {
      if (data === BuilderAccompanyOptionsType.Without) {
        this.stationRemoveAction.emit({ rideStationId: id });
      } else {
        const accompany = this.customerData.accompanies.find((obj: any) => obj.id === data);

        if (accompany && station.expand.options.accompanyId !== accompany.id) {
          this.stationEditAction.emit({
            rideStationId: id,
            accompanyId: accompany.id,
            param
          });
        }
      }
    } else {
      if (param === 'address') {
        if (extra && extra.latitude && extra.longitude && station.address !== data) {
          this.stationEditAction.emit({
            rideStationId: id,
            param,
            [param]: data,
            ...extra
          });
        }
      } else {
        if (station[param] !== data) {
          this.stationEditAction.emit({
            rideStationId: id,
            param,
            [param]: data
          });
        }
      }
    }

    this.stationEdit.id = null;
    this.stationEdit.param = null;
    this.stationEdit.type = null;
    this.stationEdit.data = '';
    this.stationEdit.extra = {
      latitude: null,
      longitude: null,
      placeId: null
    };
  }

  passengerAnonymousEditChange(station: any, passenger: any) {
    this.passengerAnonymousEdit.rideStationId = station.rideStationId;
    this.passengerAnonymousEdit.ridePassengerId = passenger.ridePassengerId;
    this.passengerAnonymousEdit.amount = passenger.amount;
  }

  passengerAnonymousEditClose(passenger: any) {
    this.passengerAnonymousEdit.amount = Number(this.passengerAnonymousEdit.amount);

    if (this.passengerAnonymousEdit.amount && this.passengerAnonymousEdit.amount !== passenger.amount) {
      passenger.amount = this.passengerAnonymousEdit.amount;

      const { rideStationId, ridePassengerId, amount } = this.passengerAnonymousEdit;

      this.passengerAnonymousEditAction.emit({ rideStationId, ridePassengerId, amount });
    }

    this.passengerAnonymousEdit.rideStationId = null;
    this.passengerAnonymousEdit.ridePassengerId = null;
    this.passengerAnonymousEdit.amount = null;
  }

  updateAddress(data: UAddress) {
    this.stationEdit.data = data.address;
    this.stationEdit.extra = {
      latitude: data.lat,
      longitude: data.lng,
      placeId: data.placeId
    };

    if (data.lat && data.lng) {
      this.stationEditClose(this.stationEdit);
    }
  }

  onDragStart() {
    this.stationsStore = [ ...this.stations ];
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    const activeIndex = event.index > 1 ? event.index - 1 : event.index;
    const eventData = event.data;

    if (list[activeIndex] && list[activeIndex].type === 'accompany') {
      this.dndBlocked = true;
      return;
    }

    if (eventData.type === 'passenger' && list.some(ob => ob.ridePassengerId === eventData.ridePassengerId)) {
      this.dndBlocked = true;
      return;
    }

    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }

      list.splice(index, 0, eventData);
    }

    if (!this.stationsStore) {
      this.stationsOrderAction.emit();
    }
  }

  onDragged(item: any, list: any[], effect: DropEffect, type: string, station: any) {
    if (this.dndBlocked) {
      return;
    }

    if (effect === 'move') {
      if (type === 'passenger') {
        this.passengerMoveToStation(item, station);
      } else {
        const index = list.indexOf(item);

        list.splice(index, 1);
      }
    }
  }

  onDragEnd() {
    if (!isEqual(this.stationsStore, this.stations)) {
      this.stationsOrderAction.emit();
    }

    this.stationsStore = null;
    this.dndBlocked = false;
  }

  stationShowOnMap(station: any) {
    this.stationShowOnMapAction.emit(station);
  }

  stationExpandToggle(station: any) {
    if (station.entityId !== 'required') {
      station.expand.check = !station.expand.check;
    }
  }

  stationAccExpandEdit(station: any) {
    this.stationAccSyncAction.emit({ rideStationId: station.rideStationId });
  }

  stationAccExpandEditReturn(station: any) {
    const { options } = station.expand;

    this.stationAccReturnAction.emit({ rideStationId: station.rideStationId, returnHome: options.return });
  }

  stationRemove(list: any, station: any) {
    this.stationRemoveAction.emit({ rideStationId: station.rideStationId });
  }

  passengerRemove(passenger: any, station: any) {
    this.passengerRemoveAction.emit({ rideStationId: station.rideStationId, ridePassengerId: passenger.ridePassengerId, passengerId: passenger.passengerId });
  }

  passengerMoveToStation(passenger: any, station: any) {
    this.passengerMoveToStationAction.emit({ rideStationId: station.rideStationId, ridePassengerId: passenger.ridePassengerId });
  }

  stationAccPickUpFirst(rideStationId: number) {
    this.stationAccPickUpAction.emit({ rideStationId, type: 'first' });
  }

  stationAccPickUpHome(rideStationId: number) {
    this.stationAccPickUpAction.emit({ rideStationId, type: 'home' });
  }

  stationAccWithBack(rideStationId: number) {
    this.stationAccReturnAction.emit({ rideStationId, returnHome: true });
  }

  stationAccWithoutBack(rideStationId: number) {
    this.stationAccReturnAction.emit({ rideStationId, returnHome: false });
  }

  stationSetFirst(rideStationId: number) {
    this.stationSetFirstAction.emit({ rideStationId });
  }

  stationSetLast(rideStationId: number) {
    this.stationSetLastAction.emit({ rideStationId });
  }

  stationSetDestination(rideStationId: number, type: boolean) {
    this.stationSetDestinationAction.emit({ rideStationId, type });
  }

  stationSave(rideStationId: number) {
    this.trackEvent('click on save as fixed station');

    this.stationSaveAction.emit({ rideStationId });
  }

  stationAddNewPassenger(rideStationId: number) {
    this.trackEvent('click on add passenger');

    this.stationAddNewPassengerAction.emit({ rideStationId });
  }

  stationAddAnonymousPassenger(rideStationId: number) {
    this.stationAddAnonymousPassengerAction.emit({ rideStationId });
  }

  passengerEye(passenger: any) {
    this.passengerEyeAction.emit(passenger);
  }

  passengerEdit(passenger: BuilderPassenger) {
    this.trackEvent('click on edit passenger');

    this.passengerEditAction.emit(passenger);
  }

  passengerMoveToFirst(rideStationId: number, passengerId: number, ridePassengerId: number) {
    this.passengerMoveToFirstAction.emit({ rideStationId, passengerId, ridePassengerId });
  }

  passengerMoveToLast(rideStationId: number, passengerId: number, ridePassengerId: number) {
    this.passengerMoveToLastAction.emit({ rideStationId, passengerId, ridePassengerId });
  }

  passengerComingSet(rideStationId: number, passengerId: number, type: boolean) {
    this.passengerComingSetAction.emit({ rideStationId, passengerId, type });
  }

  passengerSupervisorSet(passengerId: number) {
    this.passengerSupervisorSetAction.emit({ passengerId });
  }

  passengerSupervisorReset(passengerId: number) {
    this.passengerSupervisorResetAction.emit({ passengerId });
  }

  passengerDestinationInit(rideStationId: number, ridePassengerId: number, targetStationId: number, popover: any) {
    this.passengerDestinationEdit.rideStationId = rideStationId;
    this.passengerDestinationEdit.ridePassengerId = ridePassengerId;
    this.passengerDestinationEdit.popover = popover;
    this.passengerDestinationEdit.stations = this.passengerDestinationEdit.stationsStore.filter((ob: any) => ob.targetRideStationId !== rideStationId);
    this.passengerDestinationEdit.destinations = this.passengerDestinationEdit.destinationsStore.filter((ob: any) => ob.targetRideStationId !== targetStationId);
  }

  passengerDestinationHoverInit(targetStationId: number) {
    const passengerDestinationHover = this.passengerDestinationEdit.destinationsStore.find((ob: any) => ob.targetRideStationId === targetStationId);

    if (passengerDestinationHover) {
      const { name, address } = passengerDestinationHover;

      this.passengerDestinationHover = {
        name: name,
        address: address
      };
    } else {
      this.passengerDestinationHover = {
        name: null,
        address: null
      };
    }
  }

  passengerDestinationSet(targetRideStationId: number) {
    const rideStationId = this.passengerDestinationEdit.rideStationId;
    const ridePassengerId = this.passengerDestinationEdit.ridePassengerId;

    this.passengerDestinationSetAction.emit({
      rideStationId,
      ridePassengerId,
      targetRideStationId
    });

    this.passengerDestinationEdit.rideStationId = null;
    this.passengerDestinationEdit.ridePassengerId = null;
    this.passengerDestinationEdit.popover.close();
  }

  moveStationDown(station) {
    this.trackEvent('click on down arrow');

    const stationIndex = this.stations.findIndex(obj => obj.rideStationId === station.rideStationId);

    if (stationIndex < this.stations.length) {
      swapArrayElements(this.stations, stationIndex, stationIndex + 1);

      this.stationsOrderAction.emit();
    }
  }

  moveStationUp(station) {
    this.trackEvent('click on up arrow');

    const stationIndex = this.stations.findIndex(obj => obj.rideStationId === station.rideStationId);

    if (stationIndex > 0) {
      swapArrayElements(this.stations, stationIndex, stationIndex - 1);

      this.stationsOrderAction.emit();
    }
  }

  editStationArrivalTime(data: BuilderStationArrivalTimeSaveAction, rideStationId: number) {
    this.stationEditArrivalTimeAction.emit({ ...data, rideStationId });
  }

  passengerNameHover(passenger: BuilderPassenger) {
    if (this.routeBuilderFeatureMasterCustomer() && passenger.passengerId && !passenger.customerName) {
      this.passengerNameHoverAction.emit({ passengerId: passenger.passengerId });
    }
  }
}
