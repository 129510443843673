import { URangePreset, USelectSItem } from '@shift/ulib';

import { DaysOfWeek } from '@app/shared/models';
import {
  RoutesChangeAccompanyCostCalculationHoursPriceOption,
  RoutesChangeAccompanyCostCalculationCostType
} from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.changeAccompanyCost';

const costCalculationCostTypeOptions: USelectSItem[] = [
  { value: RoutesChangeAccompanyCostCalculationCostType.Fixed, name: `${DICTIONARY_PATH}.fixed` },
  { value: RoutesChangeAccompanyCostCalculationCostType.Hourly, name: `${DICTIONARY_PATH}.hourly` }
];

const hoursPriceOptions: USelectSItem[] = [
  { value: RoutesChangeAccompanyCostCalculationHoursPriceOption.Manual, name: `${DICTIONARY_PATH}.manual` },
  { value: RoutesChangeAccompanyCostCalculationHoursPriceOption.Auto, name: `${DICTIONARY_PATH}.auto` }
];

const availableDatePresets: URangePreset[] = [
  URangePreset.DisplayedDay,
  URangePreset.Today,
  URangePreset.Tomorrow,
  URangePreset.ActiveWeekDay,
  URangePreset.FromTodayOn,
  URangePreset.FromCustomDayOn,
  URangePreset.All,
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

const checkDaysAvailable: DaysOfWeek[] = [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ];

export const routesChangeAccompanyCostComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    costCalculation: `${DICTIONARY_PATH}.costCalculation`,
    hours: `${DICTIONARY_PATH}.hours`,
    cost: `${DICTIONARY_PATH}.cost`,
    manual: `${DICTIONARY_PATH}.manual`,
    auto: `${DICTIONARY_PATH}.auto`,
    type: `${DICTIONARY_PATH}.type`,
    comments: `${DICTIONARY_PATH}.type`,
    actions: {
      saveAndClose: 'general.saveAndClose',
      saveAndSendSC: 'general.saveAndSendSC',
      saveAndEditEmail: 'general.saveAndEditEmail'
    },
    uSelectS: 'uSelectS'
  },
  hoursPriceOptions,
  availableDatePresets,
  checkDaysAvailable,
  costCalculationCostTypeOptions
};
