import { URangePreset, USelectSItem } from '@shift/ulib';

import { RoutesChangesAccompanySourceStationType, RoutesChangeAccompanyType } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.changeAccompany';

const accompaniesBaseOptions: USelectSItem[] = [
  {
    value: RoutesChangeAccompanyType.Without,
    name: 'general.none'
  },
  {
    value: RoutesChangeAccompanyType.Required,
    name: 'general.required'
  }
];

const accompaniesFullOptions: USelectSItem[] = [
  ...accompaniesBaseOptions,
  {
    value: null,
    name: 'general.anonymousAccompany'
  }
];

const sourceTypeOptions: USelectSItem[] = [
  {
    value: RoutesChangesAccompanySourceStationType.FirstStation,
    name: `${DICTIONARY_PATH}.fromFirstStation`
  },
  {
    value: RoutesChangesAccompanySourceStationType.Home,
    name: `${DICTIONARY_PATH}.fromHome`
  }
];

const availableDatePresets: URangePreset[] = [
  URangePreset.DisplayedDay,
  URangePreset.Today,
  URangePreset.Tomorrow,
  URangePreset.ActiveWeekDay,
  URangePreset.FromTodayOn,
  URangePreset.FromCustomDayOn,
  URangePreset.All,
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

export const routesChangeAccompanyComponentConfig = {
  dictionary: {
    uSelectS: 'uSelectS',
    none: 'general.none',
    required: 'general.required',
    removeSelect: 'general.removeSelect',
    title: `${DICTIONARY_PATH}.title`,
    accompany: `${DICTIONARY_PATH}.accompany`,
    pickupDropOff: `${DICTIONARY_PATH}.pickupDropOff`,
    return: `${DICTIONARY_PATH}.return`,
    toggle: {
      active: 'general.yes',
      notActive: 'general.no'
    },
    type: `${DICTIONARY_PATH}.type`,
    comments: `${DICTIONARY_PATH}.comments`,
    actions: {
      saveAndClose: 'general.saveAndClose',
      saveAndSendSC: 'general.saveAndSendSC',
      saveAndEditEmail: 'general.saveAndEditEmail'
    }
  },
  accompaniesBaseOptions,
  accompaniesFullOptions,
  sourceTypeOptions,
  availableDatePresets
};
