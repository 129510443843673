<div
  *ngFor="let customer of customers; trackBy: customerTrackBy"
  class="routes-ride-passengers-customers__item"
>
  <div
    *ngIf="customer.id"
    class="routes-ride-passengers-customers__item-title"
  >
    {{customer.name}}
  </div>
  <div class="routes-ride-passengers-customers__item-subtitle">
    <ng-container *ngIf="customer.id">
      {{config.dictionary.passengersOnRide | translate}} - {{customer.passengersAmount}}
    </ng-container>
    <ng-container *ngIf="!customer.id">
      {{customer.passengersAmount}} {{config.dictionary.anonymousPassengers | translate | lowercase}}
    </ng-container>
  </div>
</div>
