import {
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injectable,
  Renderer2,
  RendererFactory2,
  TemplateRef
} from '@angular/core';
import { ComponentLoader, ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { BuilderModalBackdropComponent } from '@app/builder/components/builder-modal/builder-modal-backdrop.component';
import {
  BuilderModalContainerComponent
} from '@app/builder/components/builder-modal/builder-modal-container.component';
import { builderModalConfig } from '@app/builder/configs';

let currentId = 1;

@Injectable()
export class BuilderModalService {
  // constructor props
  config: ModalOptions = builderModalConfig.defaults;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onShow: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onShown: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHidden: EventEmitter<any> = new EventEmitter();

  protected isBodyOverflowing = false;
  protected originalBodyPadding = 0;

  protected scrollbarWidth = 0;

  protected backdropRef: ComponentRef<BuilderModalBackdropComponent>;
  private _backdropLoader: any;
  private modalsCount = 0;
  private lastDismissReason = '';

  private loaders: any[] = [];

  private _renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, private clf: ComponentLoaderFactory) {
    this._backdropLoader = clf.createLoader<BuilderModalBackdropComponent>(
      null,
      null,
      null
    );
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  /** Shows a modal */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  show(content: string | TemplateRef<any> | any, config?: ModalOptions, el?: ElementRef): BsModalRef {
    this.modalsCount++;
    this._createLoaders();
    this.config = Object.assign({}, builderModalConfig.defaults, config);
    this.config.id = config?.id || currentId++;
    this._showBackdrop(el);
    this.lastDismissReason = null;

    return this._showModal(content, el);
  }

  hide(id?: number | string) {
    if (this.modalsCount === 1) {
      this._hideBackdrop();
      this.resetScrollbar();
    }
    this.modalsCount = this.modalsCount >= 1 ? this.modalsCount - 1 : 0;
    setTimeout(() => {
      this._hideModal(id);
      this.removeLoaders(id);
    }, this.config.animated ? builderModalConfig.backdropTimeout : 0);
  }

  _showBackdrop(el?: ElementRef): void {
    const isBackdropEnabled =
      this.config.backdrop || this.config.backdrop === 'static';
    const isBackdropInDOM =
      !this.backdropRef || !this.backdropRef.instance.isShown;

    if (this.modalsCount === 1) {
      this.removeBackdrop();

      if (isBackdropEnabled && isBackdropInDOM) {
        this._backdropLoader
          .attach(BuilderModalBackdropComponent)
          .to(el ? el : 'body')
          .show({ isAnimated: this.config.animated });
        this.backdropRef = this._backdropLoader._componentRef;
      }
    }
  }

  _hideBackdrop(): void {
    if (!this.backdropRef) {
      return;
    }
    this.backdropRef.instance.isShown = false;
    const duration = this.config.animated ? builderModalConfig.backdropTimeout : 0;
    setTimeout(() => this.removeBackdrop(), duration);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _showModal(content: any, el?: ElementRef): BsModalRef {
    const modalLoader = this.loaders[this.loaders.length - 1];
    const bsModalRef: any = new BsModalRef();
    const modalContainerRef = modalLoader
      .provide({ provide: ModalOptions, useValue: this.config })
      .provide({ provide: BsModalRef, useValue: bsModalRef })
      .attach(BuilderModalContainerComponent)
      .to(el ? el : 'body')
      .show({
        content,
        isAnimated: this.config.animated,
        initialState: this.config.initialState,
        bsModalService: this,
        id: this.config.id
      });

    modalContainerRef.instance.level = this.getModalsCount();
    bsModalRef.hide = () => {
      modalContainerRef.instance.hide();
    };
    bsModalRef.content = modalLoader.getInnerComponent() || null;
    bsModalRef.setClass = (newClass: string) => {
      modalContainerRef.instance.config.class = newClass;
    };
    bsModalRef.id = modalContainerRef.instance.config?.id;

    return bsModalRef;
  }

  _hideModal(id?: number | string): void {
    if (id != null) {
      const indexToRemove = this.loaders.findIndex(loader => loader.instance?.config.id === id);
      const modalLoader = this.loaders[indexToRemove];
      if (modalLoader) {
        modalLoader.hide(id);
      }
    } else {
      this.loaders.forEach(
        (loader: ComponentLoader<BuilderModalContainerComponent>) => {
          if (loader.instance) {
            loader.hide(loader.instance.config.id);
          }
        }
      );
    }
  }

  getModalsCount(): number {
    return this.modalsCount;
  }

  removeBackdrop(): void {
    this._backdropLoader.hide();
    this.backdropRef = null;
  }

  /** AFTER PR MERGE MODAL.COMPONENT WILL BE USING THIS CODE */
  /** Scroll bar tricks */
  /** @internal */

  setScrollbar(): void {
    if (!document) {
      return;
    }

    this.originalBodyPadding = parseInt(
      window
        .getComputedStyle(document.body)
        .getPropertyValue('padding-right') || '0',
      10
    );

    if (this.isBodyOverflowing) {
      document.body.style.paddingRight = `${this.originalBodyPadding +
        this.scrollbarWidth}px`;
    }
  }

  private resetScrollbar(): void {
    document.body.style.paddingRight = `${this.originalBodyPadding}px`;
  }

  private _createLoaders(): void {
    const loader = this.clf.createLoader<BuilderModalContainerComponent>(
      null,
      null,
      null
    );
    this.copyEvent(loader.onBeforeShow, this.onShow);
    this.copyEvent(loader.onShown, this.onShown);
    this.copyEvent(loader.onBeforeHide, this.onHide);
    this.copyEvent(loader.onHidden, this.onHidden);
    this.loaders.push(loader);
  }

  private removeLoaders(id?: number | string) {
    if (id != null) {
      const indexToRemove = this.loaders.findIndex(loader => loader.instance?.config.id === id);

      if (indexToRemove >= 0) {
        this.loaders.splice(indexToRemove, 1);
        this.loaders.forEach(
          (loader: ComponentLoader<BuilderModalContainerComponent>, i: number) => {
            if (loader.instance) {
              loader.instance.level = i + 1;
            }
          }
        );
      }
    } else {
      this.loaders.splice(0, this.loaders.length);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private copyEvent(from: EventEmitter<any>, to: EventEmitter<any>) {
    from.subscribe(() => {
      to.emit(this.lastDismissReason);
    });
  }
}
