import { AuthCustomerType } from '@app/auth/models';
import { EnvType } from '@app/shared/models';

const metaDataByEnvTypes = {
  default: {
    favicon: '/assets/images/favicon/favicon.svg',
    title: 'Shift'
  },
  [EnvType.Traffical]: {
    favicon: '/assets/images/themes/traffical/favicon/favicon.svg',
    title: 'Traffical'
  },
  [EnvType.IDF]: {
    favicon: '/assets/images/themes/idf/favicon/favicon.png',
    title: 'שאט"ל'
  },
  [EnvType.IEC]: {
    favicon: '/assets/images/themes/iec/favicon/favicon.svg',
    title: 'חחי רייד'
  },
  [EnvType.Police]: {
    favicon: '/assets/images/themes/police/favicon/favicon.svg',
    title: 'Police'
  }
};

export const metaDataConfig = {
  default: metaDataByEnvTypes,
  [AuthCustomerType.ShuttleCompany]: {
    ...metaDataByEnvTypes,
    [EnvType.Shift]: {
      favicon: '/assets/images/themes/shift-shuttle-company/favicon/favicon.svg',
      title: 'Ctrl'
    }
  }
};
