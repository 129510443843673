import * as moment from 'moment';

export function generateWeekDates(startDate: string, endDateTime: string): Date[] {
  const datesRange: Date[] = [];
  const isNegativeTimezone: boolean = new Date().getTimezoneOffset() > 0;
  const endWeekDate = moment(endDateTime).add(isNegativeTimezone ? 1 : 0, 'day').toDate();
  let startWeekDate = new Date(moment(endDateTime).startOf('week').toDate());

  while (startWeekDate <= endWeekDate) {
    datesRange.push(startWeekDate);
    startWeekDate = moment(startWeekDate).add(1, 'day').toDate();
  }

  return datesRange;
}
