export interface AuditHistory {
  type: string;
  newValue: string;
  originalValue: string;
  author: string;
  dateTimeUtc: string;
}

export interface AuditHistoryRow extends AuditHistory {
  dateTime: string;
}
