import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { cloneDeep } from 'lodash';

import { RoutesRidePassengersCustomer } from '@app/routes/models';
import { routesRidePassengersCustomersComponentConfig } from './routes-ride-passengers-customers.component.config';

@Component({
  selector: 'app-routes-ride-passengers-customers',
  templateUrl: './routes-ride-passengers-customers.component.html',
  styleUrls: [ './routes-ride-passengers-customers.component.scss', './routes-ride-passengers-customers.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesRidePassengersCustomersComponent {
  @Input() customers: RoutesRidePassengersCustomer[];

  @HostBinding('class') hostClasses: string = 'routes-ride-passengers-customers';

  config = cloneDeep(routesRidePassengersCustomersComponentConfig);

  customerTrackBy(index: number, customer: RoutesRidePassengersCustomer) {
    return customer.id;
  }
}
