<div #builderMain class="builder-main__wrapper">
  <div
    uId="builder-main-close"
    class="builder-main__close"
    (click)="close()"
  >
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <ng-container *ngTemplateOutlet="builderDataService.buildModeRideOrders() ? builderMainRideOrders : builderMainDefault"></ng-container>
  <div #builderModal class="builder-main__modal"></div>
</div>

<ng-template #builderSearch>
  <div class="builder-main__map-search">
    <app-builder-search
      [point]="builderDataService.mapRide.point"
      [disabled]="
        !builderDataService.customerIsOwnedBySc() ||
        !builderDataService.isDetailsCustomerIdValid ||
        builderDataService.rideOrder.summaryVisible ||
        !builderDataService.createOrEditRoutesAllowed()
      "
      [masterSubCustomerIds]="builderDataService.masterSubCustomerIds"
      (filterAction)="activeBlockChange('filter')"
      (searchAction)="builderDataService.stationSearch($event)"
      (selectAction)="builderDataService.stationAdd($event)"
      (formControlClick)="updateFocusedSectionIconValue(builderSectionIconValue.Stations)"
    ></app-builder-search>
  </div>
</ng-template>

<ng-template #builderCalculation>
  <div class="builder-main__calculation">
    <div class="builder-main__calculation-title">{{dictionary.calculation | translate}}</div>
    <u-chips-s
      [class]="'u-chips-s_app-builder-calculation'"
      [chips]="builderDataService.calculationChips"
      [(ngModel)]="builderDataService.activeRide.rideCalculationMode"
      [disabled]="!builderDataService.isDetailsCustomerIdValid || builderDataService.rideOrder.summaryVisible || !builderDataService.createOrEditRoutesAllowed()"
      (selectChip)="builderDataService.onCalculationModeSelect($event.value)"
    ></u-chips-s>
    <div
      *ngIf="builderDataService.viewModeDefault() && builderDataService.customerIsOwnedBySc()"
      class="builder-main__calculation-settings"
    >
      <div
        [uId]="'builder-main-settings-icon'"
        class="u-icon u-icon_pointer u-icon_app-builder-settings u-icon_app-builder-settings_small"
        [ngClass]="{
          'u-icon_app-builder-settings_disabled': !builderDataService.customerIsOwnedBySc() ||
          !builderDataService.isDetailsCustomerIdValid ||
          builderDataService.rideOrder.summaryVisible ||
          !builderDataService.createOrEditRoutesAllowed()
        }"
        (click)="settings()"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template
  #builderMapHeader
  let-showEyeFilters="showEyeFilters"
>
  <div class="builder-main__map-header">
    <app-builder-info
      [showEyeFilters]="showEyeFilters"
      [mapRide]="builderDataService.mapRide"
      [activeRide]="builderDataService.activeRide"
    ></app-builder-info>
    <div
      class="builder-main__map-state"
      [ngClass]="{
        'builder-main__map-state_locked': builderDataService.lockedMap,
        'builder-main__map-state_disabled': builderDataService.rideOrder.summaryVisible || disabledLockedToggleBySCCustomerId()
      }"
      [uTooltip]="(builderDataService.lockedMap || !builderDataService.createOrEditRoutesAllowed() ? 'builder.mapState.locked' : 'builder.mapState.unlocked') | translate"
      [tooltipClass]="'u-tooltip_app-builder-map-locked'"
      [placement]="isRtl ? [ 'top-left' ] : [ 'top-right' ]"
      [container]="'body'"
      (click)="builderDataService.createOrEditRoutesAllowed() && builderDataService.toggleLockedMap()"
    ></div>
  </div>
</ng-template>

<ng-template #builderFilter>
  <div
    *ngIf="activeBlock === 'filter'"
    class="builder-main__map-filter"
  >
    <app-builder-filter
      [customerData]="builderDataService.customerData"
      [options]="builderDataService.options"
      [isRtl]="isRtl"
      [masterSubCustomerIds]="builderDataService.masterSubCustomerIds"
      (closeAction)="activeBlockChange('filter')"
      (applyAction)="builderDataService.filter($event)"
    ></app-builder-filter>
  </div>
</ng-template>

<ng-template #builderMap>
  <div class="builder-main__map-main">
    <app-builder-map
      [mapRide]="builderDataService.mapRide"
    ></app-builder-map>
  </div>
</ng-template>

<ng-template #builderLayers>
  <div class="builder-main__map-layers">
    <app-builder-layers></app-builder-layers>
  </div>
</ng-template>

<ng-template #builderSettings>
  <div class="builder-main__map-settings" *ngIf="activeBlock === 'settings'">
    <app-builder-settings
      [addEditForm]="builderDataService.addEditForm"
      (closeAction)="activeBlockChange('settings')"
      (applyAction)="builderDataService.settings($event)"
    ></app-builder-settings>
  </div>
</ng-template>

<ng-template #builderMainRideOrders>
  <div class="builder-main__ride-order">
    <div class="builder-main__ride-order-title">{{dictionary.newRideOrder | translate}}</div>
    <div class="builder-main__ride-order-content">
      <div #map class="builder-main__map">
        <div class="builder-main__map-box-ride-order">
          <div class="builder-main__map-box-ride-order-content">
            <div class="builder-main__map-box-ride-order-item builder-main__map-box-ride-order-item_details">
              <div class="builder-main__map-box-ride-order-item-content builder-main__map-box-ride-order-item-content_dynamic">
                <app-builder-ride-order-details
                  [options]="builderDataService.options"
                  [isRtl]="isRtl"
                  [lang]="lang"
                  [addEditForm]="builderDataService.addEditForm"
                  [activeRide]="builderDataService.activeRide"
                ></app-builder-ride-order-details>
              </div>
            </div>
            <div class="builder-main__map-box-ride-order-item">
              <div
                #mapBoxContent
                class="builder-main__map-box-ride-order-item-content"
              >
                <div class="builder-main__map-box-ride-order-item-wrapper">
                  <ng-container *ngTemplateOutlet="builderCalculation"></ng-container>
                  <ng-container *ngTemplateOutlet="builderSearch"></ng-container>
                </div>
                <div class="builder-main__map-stations">
                  <app-builder-stations
                    [disabled]="builderDataService.rideOrder.summaryVisible"
                    [selectedPassengerId]="selectedPassengerId$ | async"
                    [hasRouteChanged]="editRouteChanged$ | async"
                    [destinationStart]="destinationStart()"
                    [stationShowOnMapIcon]="builderDataService.mapRide.filter.stations"
                    [passengerEyeIcon]="builderDataService.mapRide.filter.passengers"
                    [stations]="builderDataService.activeRide.stations.list"
                    [customerData]="builderDataService.activeRide.stations.customerData"
                    [options]="builderDataService.options"
                    [viewportPopover]="map"
                    [viewportDropdown]="mapBoxContent"
                    [viewportArrivalTimePopover]="builderMain"
                    [authUserInfo]="authUserInfo()"
                    [activeRide]="builderDataService.activeRide"
                    (stationsOrderAction)="builderDataService.stationsOrder()"
                    (stationShowOnMapAction)="builderDataService.stationShowOnMap($event)"
                    (stationRemoveAction)="builderDataService.stationRemove($event)"
                    (stationEditAction)="builderDataService.stationEdit($event)"
                    (stationAccSyncAction)="builderDataService.stationAccSync($event)"
                    (stationAccReturnAction)="builderDataService.stationAccReturn($event)"
                    (stationAccPickUpAction)="builderDataService.stationAccPickUp($event)"
                    (stationSetFirstAction)="builderDataService.stationSetFirst($event)"
                    (stationSetLastAction)="builderDataService.stationSetLast($event)"
                    (stationSetDestinationAction)="builderDataService.stationSetDestination($event)"
                    (stationSaveAction)="builderDataService.stationSave($event)"
                    (stationAddNewPassengerAction)="builderDataService.stationAddNewPassenger($event)"
                    (stationAddAnonymousPassengerAction)="builderDataService.stationAddAnonymousPassenger($event)"
                    (stationEditArrivalTimeAction)="builderDataService.stationEditArrivalTime($event)"
                    (passengerEyeAction)="builderDataService.mapMarkerCenter($event, 'passenger')"
                    (passengerRemoveAction)="builderDataService.passengerRemove($event)"
                    (passengerMoveToStationAction)="builderDataService.passengerMoveToStation($event)"
                    (passengerMoveToFirstAction)="builderDataService.passengerMoveToFirst($event)"
                    (passengerMoveToLastAction)="builderDataService.passengerMoveToLast($event)"
                    (passengerComingSetAction)="builderDataService.passengerComingSet($event)"
                    (passengerSupervisorSetAction)="builderDataService.passengerSupervisorSet($event)"
                    (passengerSupervisorResetAction)="builderDataService.passengerSupervisorReset($event)"
                    (passengerEditAction)="builderDataService.passengerEdit($event)"
                    (passengerAnonymousEditAction)="builderDataService.passengerAnonymousEdit($event)"
                    (passengerDestinationSetAction)="builderDataService.passengerDestinationSet($event)"
                    (passengerNameHoverAction)="builderDataService.passengerNameHover($event)"
                  ></app-builder-stations>
                </div>
              </div>
            </div>
          </div>
          <div class="builder-main__map-box-ride-order-footer">
            <button
              *ngIf="rideOrderBackButtonVisible"
              uButton
              class="builder-main__map-box-ride-order-footer-btn u-font-weight-medium"
              [uId]="'builder-ride-order-back-to-details'"
              (click)="backToOrderDetails()"
            >
              {{dictionary.backToOrderDetails | translate}}
            </button>
            <button
              uButton
              class="builder-main__map-box-ride-order-footer-btn"
              [uId]="'builder-ride-order-' + (builderDataService.rideOrder.summaryVisible ? 'save' : 'continue')"
              [view]="uButtonView.Primary"
              [size]="uButtonSize.Large"
              [disabled]="rideOrderSaveButtonDisabled"
              (click)="builderDataService.rideOrder.summaryVisible ? saveRideOrder() : proceedRideOrderCreation()"
            >
              {{(builderDataService.rideOrder.summaryVisible ? dictionary.saveAndSend : dictionary.continue) | translate}}
            </button>
          </div>
        </div>
        <div class="builder-main__ride-order-summary">
          <app-builder-ride-order-summary
            *ngIf="builderDataService.rideOrder.summaryVisible"
            [isRtl]="isRtl"
            [viewportElement]="builderMain"
          ></app-builder-ride-order-summary>
          <div
            class="builder-main__map-wrapper"
            [ngClass]="{ 'builder-main__map-wrapper_small': builderDataService.rideOrder.summaryVisible }"
          >
            <ng-container *ngTemplateOutlet="builderMapHeader; context: {
              showEyeFilters: false
            }"></ng-container>
            <ng-container *ngTemplateOutlet="builderMap"></ng-container>
            <ng-container *ngTemplateOutlet="builderSettings"></ng-container>
            <ng-container *ngTemplateOutlet="builderFilter"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #builderMainDefault>
  <div
    *ngIf="!builderDataService.modeAdd()"
    class="builder-main__print"
  >
    <div
      uId="builder-main-print"
      class="u-icon u-icon_pointer u-icon_app-builder-print"
      (click)="print()"
    ></div>
  </div>
  <div
    uId="builder-main-expand"
    class="builder-main__expand"
    (click)="builderCommonService.toggleIsExpanded()"
  >
    <div
      *ngIf="(builderCommonService.isExpanded$ | async) === false"
      class="u-icon u-icon_pointer u-icon_app-expand"
      [container]="'body'"
      [uTooltip]="dictionary.expandView | translate"
    ></div>
    <div
      *ngIf="(builderCommonService.isExpanded$ | async) === true"
      class="u-icon u-icon_pointer u-icon_app-minimize"
      [container]="'body'"
      [uTooltip]="dictionary.minimizeView | translate"
    ></div>
  </div>
  <div class="builder-main__tabs">
    <tabset
      class="u-tabset-pills u-tabset-pills_light u-tabset-pills_app-builder"
      type="pills"
      [ngSwitch]="builderDataService.selectedTab"
      [ngClass]="{
        'u-tabset-pills_app-builder_edit': builderDataService.modeEdit() &&
        ([ builderBuildMode.Routes, builderBuildMode.RouteSuggestions ] | includes: builderDataService.buildMode())
      }"
    >
      <tab
        id="map"
        [active]="builderDataService.selectedTab === 'map'"
        [heading]="'builder.tabs.map' | translate"
        [customClass]="
          builderDataService.addEditForm.get('activeRide').invalid ?
            'u-tabset-pills_app-builder-main_invalid-tab' : null
        "
        (selectTab)="selectTab($event)"
      >
        <div *ngSwitchCase="'map'" #map class="builder-main__map">
          <div class="builder-main__map-box">
            <div class="builder-main__map-actions">
              <app-builder-actions />
            </div>
            <div #mapBoxContent class="builder-main__map-box-content">
              <div
                class="builder-main__map-box-content-inactive"
                *ngIf="addEditForm.get('activeRide.cancelled').value"
              >
                <span
                  class="builder-main__map-box-content-inactive-restore"
                  (click)="restoreRide()"
                >
                  {{'builder.restore.restoreRide' | translate}}
                </span>
                <span class="builder-main__map-box-content-inactive-today">{{'builder.restore.today' | translate}}</span>
              </div>
              <div
                #sectionDetails
                class="builder-main__map-section"
                [ngClass]="{ 'builder-main__map-section_focused': focusedSectionIconValue === builderSectionIconValue.Details }"
              >
                <div
                  class="builder-main__map-section-overlay"
                  *ngIf="addEditForm.get('activeRide.cancelled').value"
                ></div>
                <div class="builder-main__map-tuning">
                  <app-builder-tuning
                    [lang]="lang"
                    [addEditForm]="builderDataService.addEditForm"
                    [activeRide]="builderDataService.activeRide"
                    [options]="builderDataService.options"
                    [customerData]="builderDataService.customerData"
                    [viewportPopover]="map"
                    [viewportDropdown]="builderMain"
                    [isRtl]="isRtl"
                    [authCustomer]="authCustomer()"
                    (formControlClick)="updateFocusedSectionIconValue(builderSectionIconValue.Details)"
                  ></app-builder-tuning>
                </div>
              </div>
              <div
                #sectionStations
                class="builder-main__map-section"
                [ngClass]="{ 'builder-main__map-section_focused': focusedSectionIconValue === builderSectionIconValue.Stations }"
              >
                <div
                  class="builder-main__map-section-overlay"
                  *ngIf="addEditForm.get('activeRide.cancelled').value"
                ></div>
                <ng-container *ngTemplateOutlet="builderCalculation"></ng-container>
                <ng-container *ngIf="builderDataService.customerIsOwnedBySc()">
                  <ng-container *ngTemplateOutlet="builderSearch"></ng-container>
                </ng-container>
                <div
                  *ngIf="builderDataService.activeRide.stations.list.length"
                  class="builder-main__map-stations"
                >
                  <app-builder-stations
                    [selectedPassengerId]="selectedPassengerId$ | async"
                    [hasRouteChanged]="editRouteChanged$ | async"
                    [destinationStart]="destinationStart()"
                    [stationShowOnMapIcon]="builderDataService.mapRide.filter.stations"
                    [passengerEyeIcon]="builderDataService.mapRide.filter.passengers"
                    [stations]="builderDataService.activeRide.stations.list"
                    [customerData]="builderDataService.activeRide.stations.customerData"
                    [options]="builderDataService.options"
                    [viewportPopover]="map"
                    [viewportDropdown]="mapBoxContent"
                    [viewportArrivalTimePopover]="builderMain"
                    [authUserInfo]="authUserInfo()"
                    [activeRide]="builderDataService.activeRide"
                    (stationsOrderAction)="builderDataService.stationsOrder()"
                    (stationShowOnMapAction)="builderDataService.stationShowOnMap($event)"
                    (stationRemoveAction)="builderDataService.stationRemove($event)"
                    (stationEditAction)="builderDataService.stationEdit($event)"
                    (stationAccSyncAction)="builderDataService.stationAccSync($event)"
                    (stationAccReturnAction)="builderDataService.stationAccReturn($event)"
                    (stationAccPickUpAction)="builderDataService.stationAccPickUp($event)"
                    (stationSetFirstAction)="builderDataService.stationSetFirst($event)"
                    (stationSetLastAction)="builderDataService.stationSetLast($event)"
                    (stationSetDestinationAction)="builderDataService.stationSetDestination($event)"
                    (stationSaveAction)="builderDataService.stationSave($event)"
                    (stationAddNewPassengerAction)="builderDataService.stationAddNewPassenger($event)"
                    (stationAddAnonymousPassengerAction)="builderDataService.stationAddAnonymousPassenger($event)"
                    (stationEditArrivalTimeAction)="builderDataService.stationEditArrivalTime($event)"
                    (passengerEyeAction)="builderDataService.mapMarkerCenter($event, 'passenger')"
                    (passengerRemoveAction)="builderDataService.passengerRemove($event)"
                    (passengerMoveToStationAction)="builderDataService.passengerMoveToStation($event)"
                    (passengerMoveToFirstAction)="builderDataService.passengerMoveToFirst($event)"
                    (passengerMoveToLastAction)="builderDataService.passengerMoveToLast($event)"
                    (passengerComingSetAction)="builderDataService.passengerComingSet($event)"
                    (passengerSupervisorSetAction)="builderDataService.passengerSupervisorSet($event)"
                    (passengerSupervisorResetAction)="builderDataService.passengerSupervisorReset($event)"
                    (passengerEditAction)="builderDataService.passengerEdit($event)"
                    (passengerAnonymousEditAction)="builderDataService.passengerAnonymousEdit($event)"
                    (passengerDestinationSetAction)="builderDataService.passengerDestinationSet($event)"
                    (passengerNameHoverAction)="builderDataService.passengerNameHover($event)"
                    (formControlClick)="updateFocusedSectionIconValue(builderSectionIconValue.Stations)"
                  ></app-builder-stations>
                </div>
                <div class="builder-main__map-stations" *ngIf="builderDataService.activeRide.stationsInactive.list.length">
                  <div class="builder-main__map-stations-inactive">{{'builder.common.notActiveToday' | translate}}</div>
                  <app-builder-stations
                    [destinationStart]="destinationStart()"
                    [stations]="builderDataService.activeRide.stationsInactive.list"
                    [customerData]="builderDataService.activeRide.stationsInactive.customerData"
                    [options]="builderDataService.options"
                    [viewportPopover]="map"
                    [viewportDropdown]="mapBoxContent"
                    [viewportArrivalTimePopover]="builderMain"
                    [authUserInfo]="authUserInfo()"
                    [inactive]="true"
                    [activeRide]="builderDataService.activeRide"
                    (formControlClick)="updateFocusedSectionIconValue(builderSectionIconValue.Stations)"
                  ></app-builder-stations>
                </div>
              </div>
              <div
                #sectionSC
                class="builder-main__map-section"
                [ngClass]="{ 'builder-main__map-section_focused': focusedSectionIconValue === builderSectionIconValue.SC }"
              >
                <div
                  class="builder-main__map-section-overlay"
                  *ngIf="addEditForm.get('activeRide.cancelled').value"
                ></div>
                <div class="builder-main__map-tuning">
                  <app-builder-tuning
                    [mode]="builderTuningMode.SC"
                    [lang]="lang"
                    [addEditForm]="builderDataService.addEditForm"
                    [activeRide]="builderDataService.activeRide"
                    [options]="builderDataService.options"
                    [customerData]="builderDataService.customerData"
                    [viewportPopover]="map"
                    [viewportDropdown]="builderMain"
                    [isRtl]="isRtl"
                    [authCustomer]="authCustomer()"
                    (formControlClick)="updateFocusedSectionIconValue(builderSectionIconValue.SC)"
                  ></app-builder-tuning>
                </div>
              </div>
            </div>
            <div class="builder-main__map-box-footer">
              <ng-container *ngTemplateOutlet="builderFooter"></ng-container>
            </div>
          </div>
          <div class="builder-main__map-wrapper">
            <ng-container *ngTemplateOutlet="builderMapHeader; context: {
              showEyeFilters: true
            }"></ng-container>
            <ng-container *ngTemplateOutlet="builderMap"></ng-container>
            <ng-container *ngIf="builderDataService.buildModeRoutes() && builderDataService.modeEdit()">
              <ng-container *ngTemplateOutlet="builderLayers"></ng-container>
            </ng-container>
            <ng-container *ngTemplateOutlet="builderSettings"></ng-container>
            <ng-container *ngTemplateOutlet="builderFilter"></ng-container>
          </div>
        </div>
      </tab>
      <tab
        id="details"
        *ngIf="builderDataService.customerIsOwnedBySc()"
        [active]="builderDataService.selectedTab === 'details'"
        [disabled]="routeBuilderFeatureTypeShuttleCompany() && !builderDataService.isDetailsCustomerIdValid"
        [heading]="'builder.tabs.details' | translate"
        [customClass]="
          builderDataService.addEditForm.get('details').invalid ?
            'u-tabset-pills_app-builder-main_invalid-tab' : null
        "
        (selectTab)="selectTab($event)"
      >
        <div
          *ngSwitchCase="'details'"
          class="builder-main__content builder-main__content_details"
          #builderMainDetailsTab
        >
          <app-builder-details
            [options]="builderDataService.options"
            [orderPurposesByRouteTypeOptions]="builderDataStoreService.orderPurposesByRouteTypeOptions"
            [isRtl]="isRtl"
            [lang]="lang"
            [addEditForm]="builderDataService.addEditForm"
            [viewportElement]="builderMainDetailsTab"
          ></app-builder-details>
        </div>
      </tab>
      <tab
        id="agenda"
        *ngIf="builderDataService.customerIsOwnedBySc()"
        [active]="builderDataService.selectedTab === 'agenda'"
        [disabled]="routeBuilderFeatureTypeShuttleCompany() && !builderDataService.isDetailsCustomerIdValid"
        heading="{{'builder.tabs.agenda' | translate}}"
        (selectTab)="selectTab($event)"
      >
        <div *ngSwitchCase="'agenda'" class="builder-main__content builder-main__content_agenda">
          <app-builder-agenda
            [activeRide]="builderDataService.activeRide"
            [options]="builderDataService.options"
            [addEditForm]="builderDataService.addEditForm"
            [authUserInfo]="authUserInfo()"
          ></app-builder-agenda>
        </div>
      </tab>
      <tab
        *ngIf="builderDataService.showHistory()"
        id="history"
        [active]="builderDataService.selectedTab === 'history'"
        [disabled]="routeBuilderFeatureTypeShuttleCompany() && !builderDataService.isDetailsCustomerIdValid"
        [heading]="'builder.tabs.history' | translate"
        (selectTab)="selectTab($event)"
      >
        <div *ngSwitchCase="'history'" class="builder-main__content">
          <app-builder-history
            [routeId]="editRouteId"
          >
          </app-builder-history>
        </div>
      </tab>
      <tab
        *ngIf="builderDataService.showFines()"
        id="fines"
        [active]="builderDataService.selectedTab === 'fines'"
        [disabled]="routeBuilderFeatureTypeShuttleCompany() && !builderDataService.isDetailsCustomerIdValid"
        [heading]="'builder.tabs.fines' | translate"
        (selectTab)="selectTab($event)"
      >
        <div *ngSwitchCase="'fines'" class="builder-main__content">
          <app-builder-fines
            [routeId]="editRouteId"
          >
          </app-builder-fines>
        </div>
      </tab>
    </tabset>
  </div>
  <div class="builder-main__footer" *ngIf="builderDataService.selectedTab !== 'map'">
    <div class="builder-main__footer-wrapper">
      <ng-container *ngTemplateOutlet="builderFooter"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #builderFooter>
  <div
    class="builder-main-footer"
    [ngClass]="{ 'builder-main-footer_with-section-icons': builderDataService.selectedTab === 'map' }"
  >
    <u-menu-icons
      *ngIf="builderDataService.selectedTab === 'map'"
      class="u-menu-icons_app-builder-main"
      [icons]="config.sectionIcons"
      [isRtl]="isRtl"
      (iconClick)="onMenuIconClick($event)"
    ></u-menu-icons>
    <div class="builder-main-footer__actions">
      <div
        *ngIf="builderDataService.viewModeDefault() && (builderDataService.createOrEditRoutesAllowed() || authDataSnapshotService.deleteRoutes())"
        class="builder-main-footer__save"
      >
        <u-droplist
          [class]="'u-droplist_rounded u-droplist_app-builder-save u-droplist_black'"
          [selectedClickable]="true"
          [items]="builderDataService.saveActions"
          [radioButtons]="builderDataService.saveActionsRadioButtons"
          [selected]="builderDataService.saveAction"
          (mousedown)="actionBlockedChange()"
          (changeSelected)="saveActionChange($event)"
          (radioButtonSelect)="onSaveRadioButtonSelect($event)"
        ></u-droplist>
      </div>
    </div>
  </div>
</ng-template>
