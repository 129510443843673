import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { RouteRemplatesInitialData, RouteRemplatesInitialDataType, RoutesFromTemplatesParams, RouteTemplateListItem, RouteTemplatesFromRouteParams, RouteTemplatesListParams } from '@app/route-templates/models';

@Injectable({
  providedIn: 'root'
})
export class RouteTemplatesService {
  private BASE_PATH: string = 'RouteTemplates';

  constructor(private apiService: ApiService) {}

  getInitialData(types: RouteRemplatesInitialDataType[]): Observable<RouteRemplatesInitialData> {
    return this.apiService.get(`${this.BASE_PATH}/InitialData`, { types });
  }

  getAllRouteTemplates(filter: RouteTemplatesListParams): Observable<RouteTemplateListItem[]> {
    return this.apiService.post(`${this.BASE_PATH}/List`, filter);
  }

  deleteTemplate(templateRouteIds: number[]): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}`, { templateRouteIds });
  }

  duplicateTemplate(templateRouteId: number): Observable<RouteTemplateListItem> {
    return this.apiService.post(`${this.BASE_PATH}/Duplicate`, { templateRouteId });
  }

  createTemplateFromRoute(params: RouteTemplatesFromRouteParams): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/FromRoute`, params);
  }

  createRoutesFromTemplates(data: RoutesFromTemplatesParams): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/FromTemplates`, data);
  }
}
