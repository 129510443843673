import { UInputCitiesCityLocation } from '@shift/ulib';

import { PassengerStatusType, PassengerType } from '@app/passengers/models';

export interface PassengerArmyTag {
  tagId: number | string;
  name: string;
}

export interface PassengersArmyGetParametersDefault {
  search: string;
  customerIds: number[];
  departmentsIds: number[];
  tagsIds: number[];
  directions?: number[];
  startDate?: string;
  endDate?: string;
  branchIds?: number[];
  demandBranchIds?: number[];
  demandCities?: UInputCitiesCityLocation[];
  passengerCities?: UInputCitiesCityLocation[];
  skip: number;
  take: number;
}

export interface PassengersArmyGetParameters extends PassengersArmyGetParametersDefault {
  passengerType: PassengerType;
  statusTypes: PassengerStatusType[];
  eligibleForShuttle: boolean;
  shiftIds: number[];
}

export interface PassengersArmyMasterGetParameters extends PassengersArmyGetParametersDefault {
  demandCities?: UInputCitiesCityLocation[];
  passengerCities?: UInputCitiesCityLocation[];
}
