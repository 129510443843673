import { USelectSItem } from '@shift/ulib';

import { RoutePolicyCalculateBy } from '@app/shared/models';

const DICTIONARY_PATH: string = 'builder.settings';

const dictionary: { [key: string]: string; } = {
  title: `${DICTIONARY_PATH}.title`,
  waitingTime: `${DICTIONARY_PATH}.waitingTime`,
  useTollRoads: `${DICTIONARY_PATH}.useTollRoads`,
  pickupOptions: `${DICTIONARY_PATH}.pickupOptions`,
  calculateBy: `${DICTIONARY_PATH}.calculateBy`,
  cheapestRoute: `${DICTIONARY_PATH}.cheapestRoute`,
  useTrafficData: `${DICTIONARY_PATH}.useTrafficData`,
  uToggle: `${DICTIONARY_PATH}.uToggle`,
  speedRate: `${DICTIONARY_PATH}.speedRate`,
  speedRateInfoTooltip: `${DICTIONARY_PATH}.speedRateInfoTooltip`,
  select: 'general.select'
};

const calculateTypes: USelectSItem[] = [
  { value: RoutePolicyCalculateBy.Time, name: `${DICTIONARY_PATH}.calculateType.time` },
  { value: RoutePolicyCalculateBy.TimeAndDistance, name: `${DICTIONARY_PATH}.calculateType.timeAndDistance` }
];

export const builderSettingsComponentConfig = {
  dictionary,
  calculateTypes
};
