import { Injectable, inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { RoutesTempCommentComponent } from '@app/routes/components';
import { RouteTempCommentActiveRoute } from '@app/routes/models';

@Injectable()
export class RoutesTempCommentModalService {
  private readonly bsModalService = inject(BsModalService);

  openModal(activeRoute: RouteTempCommentActiveRoute, viewportElement?: HTMLElement) {
    this.bsModalService.show(
      RoutesTempCommentComponent,
      {
        class: 'u-modal u-modal_app-routes-temp-comment',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          activeRoute,
          viewportElement
        }
      }
    );
  }
}
