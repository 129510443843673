import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { SignalRService } from '@app/shared/services';
import {
  MonitoringDashboardReceiveCoordinates,
  MonitoringDashboardUpdateTracksDelay,
  MonitoringDashboardUpdateTracksStatus,
  MonitoringDashboardUpdatePassengersStatus
} from '@app/monitoring-dashboard/models';

@Injectable()
export class MonitoringDashboardHubService extends SignalRService {
  BASE_PATH = 'dashboardHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onReceiveCoordinates(): Observable<MonitoringDashboardReceiveCoordinates> {
    return this.onChannel<MonitoringDashboardReceiveCoordinates>('ReceiveCoordinates');
  }

  onUpdateTracksStatus(): Observable<MonitoringDashboardUpdateTracksStatus> {
    return this.onChannel<MonitoringDashboardUpdateTracksStatus>('UpdateTracksStatus');
  }

  onUpdateTracksDelay(): Observable<MonitoringDashboardUpdateTracksDelay> {
    return this.onChannel<MonitoringDashboardUpdateTracksDelay>('UpdateTracksDelay');
  }

  onUpdatePassengersStatus(): Observable<MonitoringDashboardUpdatePassengersStatus> {
    return this.onChannel<MonitoringDashboardUpdatePassengersStatus>('UpdatePassengersStatus');
  }

  monitor(rideId: number) {
    if (this.isConnected) { this.signalRClient.invoke('Monitor', rideId); }
  }
}
