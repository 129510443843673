import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private BASE_PATH: string = 'System';

  constructor(
    private apiService: ApiService
  ) {}

  systemSettingsValues(data: string): Observable<any> {
    return this.apiService.get(`${this.BASE_PATH}/Settings/Values/` + data);
  }
}
