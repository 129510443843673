import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { USidebarMenuService } from '@shift/ulib';

import { Station } from '@app/stations/models';
import { StationsAddEditComponent } from '@app/stations/components/stations-add-edit/stations-add-edit.component';

@Injectable()
export class StationsDataService {
  constructor(
    private bsModalService: BsModalService,
    private uSidebarMenuService: USidebarMenuService
  ) {}

  openAddEditModal(editData?: Station) {
    return this.bsModalService.show(
      StationsAddEditComponent,
      {
        class: `u-modal u-modal_content ${this.uSidebarMenuService.getCollapsedValue() ? 'u-modal_content-hide-menu' : ''} u-modal_app-stations`,
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          editData
        }
      }
    );
  }
}
