import { computed, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { patchSignal } from '@app/shared/utils';
import { RoutePlannerRoutesGeneration } from '@app/route-planner/models';
import { BuilderFullRoutesGenerationState } from '@app/builder/models';
import { builderFullRoutesGenerationConfig } from '@app/builder/configs';

@Injectable({
  providedIn: 'root'
})
export class BuilderFullRoutesGenerationService {
  readonly #state = signal<BuilderFullRoutesGenerationState>(builderFullRoutesGenerationConfig.initialState);

  readonly state = this.#state.asReadonly();
  readonly show = computed(() => this.state().show);
  readonly showTooltip = computed(() => this.state().showTooltip);
  readonly disabled = computed(() => this.state().disabled);
  readonly disabledAiMode = computed(() => this.state().disabledAiMode);
  readonly mode = computed(() => this.state().mode);
  readonly modeAi = computed(() => this.mode() === RoutePlannerRoutesGeneration.Ai);

  readonly mode$ = toObservable(this.mode);

  updateShow(show: boolean) {
    patchSignal(this.#state, { show });
  }

  updateShowTooltip(showTooltip: boolean) {
    patchSignal(this.#state, { showTooltip });
  }

  updateDisabled(disabled: boolean) {
    patchSignal(this.#state, { disabled });
  }

  updateDisabledAiMode(disabledAiMode: boolean) {
    patchSignal(this.#state, { disabledAiMode });
  }

  updateMode(mode: RoutePlannerRoutesGeneration) {
    patchSignal(this.#state, { mode });
  }

  reset() {
    this.#state.set(builderFullRoutesGenerationConfig.initialState);
  }
}
