import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { UPopupService } from '@shift/ulib';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { LocalizedToastrService } from '@app/shared/services/localized-toast.service';
import { ContractsService } from '@app/contracts/services';
import { ContractFine } from '@app/contracts/models';
import { ShuttleCompanyFineDataContract, ShuttleCompanyFineNew } from '@app/shuttle-companies/models';
import { ShuttleCompanyFinesService } from '@app/shuttle-companies/services';
import { RouteDailyRow } from '@app/routes/models';
import { routesDailyFineComponentConfig } from './routes-daily-fine.component.config';

@Component({
  selector: 'app-routes-daily-fine',
  templateUrl: './routes-daily-fine.component.html',
  styleUrls: [ './routes-daily-fine.component.scss', './routes-daily-fine.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesDailyFineComponent implements OnInit, OnDestroy {
  @Input() data: RouteDailyRow;

  @HostBinding('class') hostClasses: string = 'routes-daily-fine';

  fineForm: UntypedFormGroup;
  isRtl: boolean = this.localizationService.isRtl();
  shuttleCompanyName: string;
  finesList: ContractFine[] = [];
  contractsList: ShuttleCompanyFineDataContract[] = [];
  config = cloneDeep(routesDailyFineComponentConfig);

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private uPopupService: UPopupService,
    private trackingService: TrackingService,
    private toastr: LocalizedToastrService,
    private localizationService: LocalizationService,
    private contractsService: ContractsService,
    private shuttleCompanyFinesService: ShuttleCompanyFinesService
  ) {}

  ngOnInit() {
    this.initFineForm();
    this.initData();
    this.onDateChange();
    this.onContractChange();
    this.onSelectedFineChange();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initFineForm() {
    this.fineForm = this.fb.group({
      routeId: [ null ],
      price: [ null ],
      rideDate: [ null ],
      contractNumber: [ null, Validators.required ],
      fineId: [ null, Validators.required ],
      comment: [ '' ]
    });
  }

  private initData() {
    if (this.data) {
      this.fineForm.get('rideDate').patchValue(
        moment(this.data.rideStartDateTime).format(AppConstants.DATE_FORMAT_BASE_LINE)
      );
      this.fineForm.get('routeId').patchValue(this.data.routeId);

      if (this.data.shuttleCompany) {
        this.getShuttleCompanyData();
      } else {
        this.fineForm.get('contractNumber').disable();
        this.fineForm.get('fineId').disable();
      }
    }
  }

  private getShuttleCompanyData() {
    this.shuttleCompanyFinesService.getShuttleCompanyData(
      this.data.routeId,
      moment(this.fineForm.get('rideDate').value).format(AppConstants.DATE_FORMAT_BASE_LINE)
    )
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.shuttleCompanyName = data.shuttleCompanyName;
        this.contractsList = data && data.contracts ? data.contracts.map(contract => ({
          name: contract.name,
          value: contract.name
        })) : [];

        this.cdRef.markForCheck();
      });
  }

  private onDateChange() {
    this.fineForm.get('rideDate').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.getShuttleCompanyData());
  }

  private onContractChange() {
    this.fineForm.get('contractNumber').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((contractId: number) => {
        if (contractId) {
          this.loadContractFines();
        } else {
          this.finesList = [];
          this.fineForm.get('fineId').patchValue(null);
        }
      });
  }

  private onSelectedFineChange() {
    this.fineForm.get('fineId').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        const selectedFine = value ? this.finesList.find(fine => fine.value === value) : null;
        const price = selectedFine ? selectedFine.price : null;

        this.fineForm.get('price').patchValue(price);
      });
  }

  private loadContractFines() {
    const contractNumber = this.fineForm.get('contractNumber').value;

    this.contractsService.getContractFines(contractNumber)
      .subscribe(fines => {
        this.finesList = fines.map((ob: any) => ({
          price: ob.fine,
          value: ob.fineId,
          num: parseInt(ob.num, 10),
          name: `${ob.fine} ${contractNumber} ${ob.description}`
        }));
      });
  }

  saveFine() {
    this.trackingService.track('[Fines] - Add new fine');

    const { comment, price, rideDate, contractNumber, routeId, fineId } = this.fineForm.value;
    const fine: ShuttleCompanyFineNew = { comment, price, rideDate, contractNumber, routeId, fineId };

    this.shuttleCompanyFinesService.createFine(fine)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        () => {
          this.toastr.success(this.config.dictionary.createFineMessage.success);

          this.bsModalRef.hide();
        },
        () => this.uPopupService.showErrorMessage({ message: this.config.dictionary.createFineMessage.fail })
      );
  }

  closeModal() {
    if (this.fineForm.dirty) {
      this.uPopupService.showMessage(
        {
          showXIcon: true,
          message: this.config.dictionary.closeConfirm,
          yes: this.config.dictionary.yes,
          no: this.config.dictionary.no
        },
        () => {
          if (this.fineForm.valid) {
            this.saveFine();
          }
        },
        () => this.bsModalRef.hide()
      );
    } else {
      this.bsModalRef.hide();
    }
  }
}
