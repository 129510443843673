import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'builderSearchList'
})
export class BuilderSearchListPipe implements PipeTransform {
  transform(items: any[], value: string) {
    return items.filter(item => {
      if (item && item.name) {
        if (String(item.name).toLowerCase().includes(value.toLowerCase())) {
          return item;
        }
      } else if (item) {
        return String(item).toLowerCase().includes(value.toLowerCase());
      }
    });
  }
}
