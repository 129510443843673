import { Component, OnInit, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import {
  DemandsPassengerShiftSeriesUpdateType,
  DemandsShiftRepeatOptionsAction,
  DemandsShiftRepeatOptionsActionType,
  DemandsShiftRepeatOptionsCheckbox
} from '@app/demands/models';
import { AuthModuleDemandsPassengersViewFeatureType, AuthModuleDemandsShiftsViewFeatureType } from '@app/auth/models';
import { demandsShiftRepeatOptionsComponentConfig } from './demands-shift-repeat-options.component.config';

@Component({
  selector: 'app-demands-shift-repeat-options',
  templateUrl: './demands-shift-repeat-options.component.html',
  styleUrls: [ './demands-shift-repeat-options.component.scss', './demands-shift-repeat-options.component.rtl.scss' ]
})
export class DemandsShiftRepeatOptionsComponent implements OnInit {
  @Output() action: EventEmitter<DemandsShiftRepeatOptionsAction> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'demands-shift-repeat-options';

  config = cloneDeep(demandsShiftRepeatOptionsComponentConfig.default);
  activeOption: number = this.config.activeOption.value;
  isEditMode: boolean;
  hasRepeatChanged: boolean;
  featureType: AuthModuleDemandsPassengersViewFeatureType | AuthModuleDemandsShiftsViewFeatureType;

  constructor(
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.initConfig();
  }

  private initConfig() {
    this.config = cloneDeep(demandsShiftRepeatOptionsComponentConfig[this.featureType] || demandsShiftRepeatOptionsComponentConfig.default);

    if (this.isEditMode) {
      this.config.options = this.config.options.filter(option => this.hasRepeatChanged ? option.value !== DemandsPassengerShiftSeriesUpdateType.OnlyCurrentDemand : true);
    }
  }

  private setModalAction(type: DemandsShiftRepeatOptionsActionType, value: number) {
    this.bsModalRef.hide();
    this.action.emit({ type, value });
  }

  setOption(item: DemandsShiftRepeatOptionsCheckbox) {
    item.check = !item.check;

    if (item.check) {
      this.config.options
        .filter(option => option.value !== item.value)
        .forEach(option => option.check = false);
    }

    this.activeOption = item.check ? item.value : null;
  }

  submitModal() {
    this.setModalAction(DemandsShiftRepeatOptionsActionType.Submit, this.activeOption);
  }

  cancelModal() {
    this.setModalAction(DemandsShiftRepeatOptionsActionType.Cancel, this.activeOption);
  }
}
