const DICTIONARY_PATH: string = 'emails.shuttleCompany.details';

export const emailsShuttleCompanyDetailsComponentConfig = {
  dictionary: {
    attachedFiles: `${DICTIONARY_PATH}.attachedFiles`,
    content: `${DICTIONARY_PATH}.content`,
    addEmail: `${DICTIONARY_PATH}.addEmail`,
    topic: `${DICTIONARY_PATH}.topic`,
    to: `${DICTIONARY_PATH}.to`,
    required: 'general.required',
    enterText: 'Enter text here...'
  }
};
