import { ContactType } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.contacts';

export const contactsComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    newContact: `${DICTIONARY_PATH}.newContact`,
    contact: `${DICTIONARY_PATH}.contact`,
    role: `${DICTIONARY_PATH}.role`,
    name: `${DICTIONARY_PATH}.name`,
    uSelectS: 'uSelectS',
    select: 'general.select',
    remove: 'general.remove'
  },
  contactTypes: [
    { value: ContactType.Phone, name: 'general.contacts.phone' },
    { value: ContactType.Mobile, name: 'general.contacts.mobile' },
    { value: ContactType.Fax, name: 'general.contacts.fax' },
    { value: ContactType.Email, name: 'general.contacts.email' }
  ]
};
