<div class="monitoring-dashboard-layers__container">
  <div 
    class="monitoring-dashboard-layers__menu" 
    [ngClass]="{'monitoring-dashboard-layers__menu_show': showLayersMenu}"
  >
    <div 
      *ngFor="let layer of config.layers"
      class="monitoring-dashboard-layers__menu-item"
      [ngClass]="{'monitoring-dashboard-layers__menu-item_active': layer.active}"
      (click)="toggleLayer(layer)"
    >
      <div class="monitoring-dashboard-layers__menu-item-icon monitoring-dashboard-layers__menu-item-icon_{{layer.id}}"></div>
      <div
        class="monitoring-dashboard-layers__menu-item-title monitoring-dashboard-layers__menu-item-title_{{layer.id}}"
      >
        {{layer.title | translate}}
      </div>
    </div>
  </div>
  <div 
    class="monitoring-dashboard-layers__main" 
    (click)="updateShowLayersMenu(!showLayersMenu)"
  >
    <img class="monitoring-dashboard-layers__main-icon" src="/assets/images/layers/layers.svg">
    <div class="monitoring-dashboard-layers__main-title">{{config.dictionary.title | translate}}</div>
  </div>
</div>
