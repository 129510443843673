import { Pipe, PipeTransform } from '@angular/core';

import { RoutesMovePassengersStationBase } from '@app/routes/models';

@Pipe({
  name: 'routesMovePassengersCount'
})
export class RoutesMovePassengersCountPipe implements PipeTransform {
  transform(stations: RoutesMovePassengersStationBase[]): number {
    let count: number = 0;

    stations.forEach(station => {
      count += station.passengers.length;

      if (station.anonymousPassengersCount) {
        count += station.anonymousPassengersCount;
      }
    });

    return count;
  }
}
