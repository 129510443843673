import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import {
  RouteNotesNote,
  RouteNotesState,
  RouteNotesCreateBody,
  RouteNotesEditNoteMessageBody
} from '@app/route-notes/models';

@Injectable({
  providedIn: 'root'
})
export class RouteNotesService {
  private BASE_PATH: string = 'RouteNotes';

  constructor(
    private apiService: ApiService
  ) {}

  getState(routeIds: number[]): Observable<RouteNotesState[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetState`, { routeIds });
  }

  getRouteNotes(routeId: number): Observable<RouteNotesNote[]> {
    return this.apiService.get(this.BASE_PATH, { routeId });
  }

  getRouteNote(noteId: number): Observable<RouteNotesNote> {
    return this.apiService.get(`${this.BASE_PATH}/Note`, { noteId });
  }

  createNote(body: RouteNotesCreateBody): Observable<number> {
    return this.apiService.post(`${this.BASE_PATH}/Create`, body);
  }

  editNoteMessage(body: RouteNotesEditNoteMessageBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/EditMessage`, body);
  }

  markNotesAsRead(noteIds: number[]): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/MarkAsRead`, { noteIds });
  }

  markNoteAsDone(noteId: number): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/MarkAsDone`, { noteId });
  }
}
