import { TemplateRef } from '@angular/core';

import { AuthModuleFeature, AuthModuleFeatureGroup, AuthPermission } from '@app/auth/models';

export interface EntityMenuItemMetaData {
  [key: string]: {
    service: any;
    function: string;
    transform?: {
      pipes?: [{
        name: any;
        params?: object;
      }];
    };
  };
}

export interface EntityMenuItem {
  name: string;
  action: string;
  required?: boolean;
  tabDependency?: string;
  getDataApiPath?: string;
  isGenericApi?: boolean;
  config?: {
    dictionary?: object;
  };
  type?: object;
  metaData?: EntityMenuItemMetaData;
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  permission?: AuthPermission;
  titleTooltip?: TemplateRef<any> | string;
}

export interface EntityMenu {
  name: string;
  items: EntityMenuItem[];
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  permission?: AuthPermission;
}
