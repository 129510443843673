<div
  #weeklyViewTable
  appWindowResize
  class="routes-weekly__container"
  (windowResize)="windowResize($event)"
>
  <u-grid
    #grid
    class="u-grid_app-routes-weekly"
    [ngClass]="{ 'u-grid_app-routes-weekly-has-required-recalculation-rides': requiredRecalculationRides }"
    [tableName]="tableName"
    [saveTableProps]="true"
    [saveColumnsFilters]="true"
    [resetColumnsFilter]="resetColumnsFilter()"
    [columns]="columns"
    [rows]="routes"
    [rowClassObjects]="rowClassObjects()"
    [columnMode]="'force'"
    [headerHeight]="45"
    [headerOffset]="10"
    [footerHeight]="0"
    [rowHeight]="55"
    [reorderable]="true"
    [scrollbarV]="true"
    [scrollbarH]="true"
    [isRtl]="isRtl"
    [lang]="lang"
    [selected]="selectedRows"
    [selectionType]="'checkbox'"
    [messagesTable]="config().dictionary.messagesTable"
    [showColumnsFilter]="true"
    [messages]="config().dictionary.uGrid | translate"
    [disabledRowProp]="'locked'"
    [rowIdentity]="rowIdentity"
    (sort)="onSortTable($event)"
    (selectRows)="selectRoutes($event)"
    (filterRows)="onFilterRows($event)"
    (resetColumnsFilterChange)="resetColumnsFilterChange($event)"
    (columnsFilteredChange)="columnsFilteredChange($event)"
    (editBtnClickAction)="editRoute($event)"
    (scrollTable)="onScrollTable($event)"
    (selectDotsItemAction)="selectDotsItemAction($event)"
  />
</div>

<ng-template #activitiesColumn ngx-datatable-cell-template let-row="row" let-value="value">
  @if (!row.locked && !row.saveStatus) {
    <div
      (click)="openRouteActivities(row.id)"
      class="routes-weekly__activities"
      [ngClass]="{ 'routes-weekly__activities_disabled': !value.length }"
    >
      <span class="routes-weekly__activities-icon"></span>

      @if (value | activitiesCounter; as activitiesCounter) {
        <span class="routes-weekly__activities-counter">{{activitiesCounter}}</span>
      }
    </div>
  }

  @if (row.locked && !row.saveStatus) {
    <div
      class="routes-weekly__loader"
      #lockedByTooltip="uTooltip"
      [uTooltip]="lockedBy"
      [viewportElement]="weeklyViewTable.querySelector('datatable-body')"
      [tooltipClass]="'u-tooltip_general'"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [container]="'body'"
      [triggers]="'manual'"
      (mouseenter)="openLockedByTooltip(row, lockedByTooltip)"
      (mouseleave)="closeLockedByTooltip(lockedByTooltip)"
    >
      <div class="loader loader_circle loader_all-events"></div>
      <ng-template #lockedBy>
        <div class="route-lock-state-clock">
          <div class="route-lock-state-clock__text">
            {{config().dictionary.doneBy | translate}} {{routeLockState.lockedByUser}}
          </div>
          <div class="route-lock-state-clock__text">
            {{routeLockState.lockDateTime | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}
          </div>
        </div>
      </ng-template>
    </div>
  }

  @if (row.saveStatus) {
    <div
      class="routes-weekly__save-status"
      [ngClass]="{
        'routes-weekly__save-status_successful': row.saveStatus === routeSaveStatus.Successful,
        'routes-weekly__save-status_failed': row.saveStatus === routeSaveStatus.Failed
      }"
    ></div>
  }
</ng-template>

<ng-template
  #dayColumnHeader
  ngx-datatable-header-template
  let-column="column"
>
  <span
    class="routes-weekly__column-day-header"
    [ngClass]="{ 'routes-weekly__column-day-header_active': column.active }"
    (click)="dayHeaderClick(column)"
  >
    {{config().dictionary.tableWeekDays + '.' + [column.index] + '' | translate}}
    <span class="routes-weekly__column-day-number"> {{column.date | date: 'd.MM'}} </span>
  </span>
</ng-template>

<ng-template #codeRoute ngx-datatable-cell-template let-row="row" let-value="value">
  <div class="routes-weekly-code">
    <div class="routes-weekly-code__wrapper">
      <span class="routes-weekly-code__value">{{value}}</span>
    </div>

    @if (row | routesWeeklyHasRequiredRecalculationRides) {
      <div class="routes-weekly-code__warning-icon-wrapper">
        <div
          class="routes-weekly-code__warning-icon"
          [uTooltip]="requiredRecalculation"
          [tooltipClass]="'u-tooltip_app-routes-required-recalculation'"
          [container]="'body'"
          [placement]="[ 'top' ]"
        ></div>
      </div>
    }
  </div>

  <ng-template #requiredRecalculation>
    <div class="routes-weekly-required-recalculation">
      <div class="routes-weekly-required-recalculation__text">
        {{config().dictionary.requiredRecalculation.message | translate}}
      </div>
      <div class="routes-weekly-required-recalculation__text">
        {{config().dictionary.requiredRecalculation.openEditorAndSave | translate}}
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #weeklyPopover>
  <u-weekly-changes
    [items]="activeChangeItems()"
    [showEmail]="showEmail()"
    [messages]="config().dictionary.uWeeklyChanges | translate"
    [placeholder]="config().dictionary.table.search | translate"
    [empty]="true"
    [emptyName]="config().dictionary.removeSelect | translate"
    [emptyValue]="-1"
    (actions)="weeklyChangesActions($event)"
  />
</ng-template>

<ng-template #directionColumn ngx-datatable-cell-template let-row="row">
  <div class="routes-weekly__column">
    <span class="routes-weekly__column-route-direction">
      @if (row.direction === routeDirection.Forward) {
        {{config().dictionary.direction.forward | translate}}
      } @else if (row.direction === routeDirection.Backward) {
        {{config().dictionary.direction.backward | translate}}
      }
    </span>
  </div>
</ng-template>

<ng-template #directionFilter ngx-datatable-header-filter-template let-item="item">
  <div class="routes-weekly__filter-direction">
    @if (item === routeDirection.Forward) {
      <span
        class="routes-weekly__column-route-direction"
        title="{{config().dictionary.direction.forward | translate}}"
      >
        {{config().dictionary.direction.forward | translate}}
      </span>
    }

    @if (item === routeDirection.Backward) {
      <span
        class="routes-weekly__column-route-direction routes-weekly__column-route-direction_back"
        title="{{config().dictionary.direction.backward | translate}}"
      >
        {{config().dictionary.direction.backward | translate}}
      </span>
    }
  </div>
</ng-template>

<ng-template #startTime ngx-datatable-cell-template let-row="row">
  <span class="routes-weekly__column-start-time">
    {{row.startDateTime | date: appConstants.TIME_FORMAT}}
  </span>
</ng-template>

<ng-template #endTime ngx-datatable-cell-template let-row="row">
  <span class="routes-weekly__column-end-time">
    {{row.endDateTime | date: appConstants.TIME_FORMAT}}
  </span>
</ng-template>

<ng-template #hourColumnFilter ngx-datatable-header-filter-template let-item="item">
  <span
    class="routes-weekly__column-filter"
    title="{{item ? (item | date: appConstants.TIME_FORMAT) : (config().dictionary.emptyValue | translate)}}"
  >
    {{item ? (item | date: appConstants.TIME_FORMAT) : (config().dictionary.emptyValue | translate)}}
  </span>
</ng-template>

<ng-template
  #dayColumn
  ngx-datatable-cell-template
  let-row="row"
  let-ride="value"
>
  @if (ride && !ride.isCancelled) {
    <div class="routes-weekly__column">
      @if (ride.isSelfShuttleCompany) {
        <div
          class="routes-weekly__column-day-wrapper"
          [ngClass]="{ 'routes-weekly__column-day-wrapper_disabled-change': !authDataSnapshotService.editRoutes() }"
          [uPopover]="weeklyPopover"
          #popoverDriver="uPopover"
          [container]="'body'"
          [placement]="isRtl ? [ 'bottom-left', 'bottom', 'bottom-right', 'top-left' ] : [ 'bottom-right', 'bottom', 'bottom-left', 'top-right' ]"
          [viewportElement]="weeklyViewTable.querySelector('datatable-body')"
          [autoClose]="'outside'"
          [triggers]="'click'"
          [viewportAutoClose]="false"
          [popoverClass]="'u-popover_app-weekly-changes'"
          (click)="openWeeklyPopover(popoverDriver, ride, row)"
        >
          @if (tableAssignmentType() === 'accompanyName') {
            <span
              class="routes-weekly__column-day-assignment"
              [ngClass]="{ 'routes-weekly__column-day-assignment_required': !ride.accompany }"
              title="{{ride.accompany ? ride.accompany.name : config().dictionary.table.accompanyRequired | translate}}"
            >
              {{ride.accompany ? ride.accompany.name : config().dictionary.table.accompanyRequired | translate}}
            </span>
          }

          @if (tableAssignmentType() === 'driver') {
            <span
              class="routes-weekly__column-day-assignment"
              [ngClass]="{ 'routes-weekly__column-day-assignment_required': !ride.driver }"
              title="{{ride.driver ? ride.driver.name : config().dictionary.table.driverRequired | translate}}"
            >
              {{ride.driver ? ride.driver.name : config().dictionary.table.driverRequired | translate}}
            </span>
          }
        </div>
      }

      @if (!ride.isSelfShuttleCompany && tableAssignmentType() === 'accompanyName') {
        <div
          class="routes-weekly__column-day-wrapper"
          [ngClass]="{ 'routes-weekly__column-day-wrapper_disabled-change': !authDataSnapshotService.editRoutes() }"
          [uPopover]="weeklyPopover"
          #popoverAccompany="uPopover"
          [container]="'body'"
          [placement]="isRtl ? [ 'bottom-left', 'bottom', 'bottom-right', 'top-left' ] : [ 'bottom-right', 'bottom', 'bottom-left', 'top-right' ]"
          [viewportElement]="weeklyViewTable.querySelector('datatable-body')"
          [autoClose]="'outside'"
          [viewportAutoClose]="false"
          [popoverClass]="'u-popover_app-weekly-changes'"
          (click)="openWeeklyPopover(popoverAccompany, ride, row)"
        >
          <span
            class="routes-weekly__column-day-assignment"
            [ngClass]="{ 'routes-weekly__column-day-assignment_required': !ride.accompany }"
            title="{{ride.accompany ? ride.accompany.name : config().dictionary.table.accompanyRequired | translate}}"
          >
            {{ride.accompany ? ride.accompany.name : config().dictionary.table.accompanyRequired | translate}}
          </span>
        </div>
      }

      @if (!ride.isSelfShuttleCompany && tableAssignmentType() === 'driver') {
        <div class="routes-weekly__column-day-wrapper routes-weekly__column-day-wrapper_disable">
          @if (ride.shuttleCompany) {
            <span
              class="routes-weekly__column-day-assignment"
              title="{{ride.shuttleCompany.name}}"
            >
              {{ride.shuttleCompany.name}}
            </span>
          }
        </div>
      }
    </div>
  }

  @if (!ride) {
    <div class="routes-weekly__column">
      <span
        class="routes-weekly__column-day-assignment routes-weekly__column-day-assignment_no-ride"
        title="{{config().dictionary.table.inactive | translate}}"
      >
        {{config().dictionary.table.inactive | translate}}
      </span>
    </div>
  }

  @if (ride && ride.isCancelled) {
    <div class="routes-weekly__column">
      <span
        class="routes-weekly__column-day-assignment routes-weekly__column-day-assignment_ride-cancel"
        title="{{config().dictionary.table.canceled | translate}}"
      >
        {{config().dictionary.table.canceled | translate}}
      </span>
    </div>
  }
</ng-template>

