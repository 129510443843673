<div
  *ngFor="let item of items"
  class="builder-ride-order-confirmation__item"
  [ngClass]="[
    'builder-ride-order-confirmation__item_' + item.type,
    selectedStatus === item.status ? 'builder-ride-order-confirmation__item_selected' : '',
    item.disabled ? 'builder-ride-order-confirmation__item_disabled' : '',
    item.readonly && selectedStatus === item.status ? 'builder-ride-order-confirmation__item_readonly' : ''
  ]"
  (click)="updateSelectedStatus(item.status)"
></div>
