export interface InputBranch {
  branchId: number;
  assignAutomatically: boolean;
}

export interface InputBranchItem {
  branchId: number;
  name: string;
  customerName: string;
  isAssignAutomaticallyAllowed: boolean;
}
