import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

import { AppConstants } from '@app/shared/constants';
import { timerComponentConfig } from './timer.component.config';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: [ './timer.component.scss', './timer.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerComponent implements OnChanges, OnDestroy {
  @Input() startTime: moment.Moment;
  @Input() timerFormat: string;
  @Input() isToday: boolean;
  @Input() daysToRoute: number;

  @HostBinding('class') hostClasses: string = 'timer';

  config = timerComponentConfig;
  interval: NodeJS.Timeout;
  countDown: moment.Moment;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.startTime || changes.isToday) && this.startTime && this.isToday) {
      const today = new Date();
      const time = this.startTime.format(AppConstants.TIME_FORMAT_FULL).split(':');

      this.countDown = moment();

      today.setHours(Number(time[0]));
      today.setMinutes(Number(time[1]));
      today.setSeconds(Number(time[2]));

      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        const distance = today.getTime() - new Date().getTime();
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        this.changeTime(hours, minutes);

        if (distance < 0) {
          clearInterval(this.interval);

          this.countDown = null;
        }

        this.cdRef.markForCheck();
      }, 1000);
    }
  }

  changeTime(hours: number, minutes: number) {
    if (this.countDown.hours() !== hours || this.countDown.minutes() !== minutes) {
      this.countDown = moment().hours(hours).minutes(minutes);
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
