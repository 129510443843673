import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UCommonModule } from '@shift/ulib';

import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { AuthRedirectCallbackComponent, AuthCustomerSwitchRoleComponent } from '@app/auth/components';

const COMPONENTS = [
  AuthRedirectCallbackComponent,
  AuthCustomerSwitchRoleComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    UCommonModule,
    TabsModule,
    SharedModule,
    AuthRoutingModule
  ],
  declarations: [
    ...COMPONENTS
  ]
})
export class AuthModule {}
