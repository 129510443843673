import { AuthCustomerType } from '@app/auth/models';

const DICTIONARY_PATH: string = 'shared.inputShifts';

const dictionary: { [key: string]: string } = {
  title: `${DICTIONARY_PATH}.title`,
  newShift: `${DICTIONARY_PATH}.newShift`,
  shiftName: `${DICTIONARY_PATH}.shift`,
  placeholder: `${DICTIONARY_PATH}.name`,
  removeMessage: `${DICTIONARY_PATH}.confirm.deleteSchedule`,
  warning: `${DICTIONARY_PATH}.warning`,
  yes: 'general.yes',
  no: 'general.no',
  schedule: 'uHourSchedule'
};

const dictionaryArmy: { [key: string]: string } = {
  ...dictionary,
  newShift: `${DICTIONARY_PATH}.newShiftArmy`,
  shiftName: `${DICTIONARY_PATH}.shiftArmy`,
  placeholder: `${DICTIONARY_PATH}.nameArmy`,
  warning: `${DICTIONARY_PATH}.warningArmy`,
  schedule: 'general.uHourScheduleForIdf'
};

const dictionaryMunicipality: { [key: string]: string } = {
  ...dictionary,
  title: `${DICTIONARY_PATH}.titleMunicipality`,
  newShift: `${DICTIONARY_PATH}.newShiftMunicipality`,
  shiftName: `${DICTIONARY_PATH}.shiftMunicipality`,
  placeholder: `${DICTIONARY_PATH}.nameMunicipality`,
  removeMessage: `${DICTIONARY_PATH}.confirm.deleteScheduleMunicipality`
};

export const inputShiftsComponentConfig = {
  dictionaryPath: DICTIONARY_PATH,
  dictionary: {
    default: dictionary,
    [AuthCustomerType.Army]: dictionaryArmy,
    [AuthCustomerType.Municipality]: dictionaryMunicipality
  },
  tracking: {
    default: {
      add: 'add shift',
      delete: 'remove shift'
    },
    [AuthCustomerType.Army]: {
      add: 'add activity',
      delete: 'remove activity'
    },
    [AuthCustomerType.Municipality]: {
      add: 'add activity',
      delete: 'remove activity'
    }
  }
};
