<span
  #popover="uPopover"
  class="builder-routes-passengers-info__icon"
  [uId]="'builder-routes-passengers-info'"
  [ngClass]="{ 'builder-routes-passengers-info__icon_show': popover.isOpen() }"
  [uPopover]="passengersListPopover"
  [placement]="isRtl ? [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom', 'top' ] : [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom', 'top' ]"
  [container]="'body'"
  [popoverClass]="'u-popover_app-routes-ride-passengers'"
  [autoClose]="'outside'"
  [triggers]="'manual'"
  [viewportElement]="viewportElement"
  (click)="openPassengersList()"
  (hidden)="closePassengersList()"
  (uClickOutside)="closePassengersListAndCancelGetInfo()"
>
  <div class="u-icon u-icon_pointer u-icon_app-routes-info"></div>
</span>

<ng-template #passengersListPopover>
  <app-routes-ride-passengers
    [passengers]="passengers()"
  ></app-routes-ride-passengers>
</ng-template>
