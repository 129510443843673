import { createAction, props } from '@ngrx/store';

import { AuthUserInfo } from '@app/auth/models';

const ACTION_PREFIX: string = '[Auth]';

export const init = createAction(`${ACTION_PREFIX} Init`);
export const initFinished = createAction(`${ACTION_PREFIX} Init finished`);
export const initUser = createAction(`${ACTION_PREFIX} Init user`, props<{ data: AuthUserInfo; }>());
export const updateUser = createAction(`${ACTION_PREFIX} Update user`);
export const updateUserSuccess = createAction(`${ACTION_PREFIX} Update user success`, props<{ data: AuthUserInfo; }>());
export const updateCountdown = createAction(`${ACTION_PREFIX} Update countdown`, props<{ counter: number; }>());
export const logout = createAction(`${ACTION_PREFIX} Logout`);
export const logoutSuccess = createAction(`${ACTION_PREFIX} Logout success`);
