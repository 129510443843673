import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment/moment';

import { appConfig, appDictionaryConfig } from '@app/shared/configs';
import { environment } from '@environments/environment';
import { AppLanguage, Languages } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) {
    this.translate.addLangs(appConfig.languages.map(lang => lang.value));
  }

  init() {
    const lang = this.getLanguage() || <AppLanguage>this.translate.getBrowserLang();
    const isLangAvailable = appConfig.languages.find(language => language.value === lang);

    this.setLanguage(isLangAvailable ? lang : Languages.Hebrew);
  }

  setLanguage(lang: AppLanguage) {
    this.translate.use(lang);

    if (appConfig.rtlLanguages.indexOf(lang) !== -1) {
      const dir = document.createAttribute('dir');

      dir.value = 'rtl';

      this.document.documentElement.attributes.setNamedItem(dir);
    } else if (this.document.documentElement.getAttribute('dir') === 'rtl') {
      this.document.documentElement.attributes.removeNamedItem('dir');
    }

    moment.locale(this.getLanguage());

    this.saveLanguage(lang);
  }

  getLanguage(): AppLanguage {
    return localStorage['lang'];
  }

  saveLanguage(lang: AppLanguage) {
    localStorage['lang'] = lang;
  }

  isRtl(lang?: AppLanguage): boolean {
    return appConfig.rtlLanguages.indexOf(lang || this.getLanguage()) !== -1;
  }

  getDirection(): 'rtl' | 'ltr' {
    return this.isRtl() ? 'rtl' : 'ltr';
  }

  getDistanceDictionary(): { [key: string]: string } {
    return appDictionaryConfig.distance[environment.config.metricSystem];
  }
}
