<div class="demands-shift-repeat-options__wrapper">
  <div class="demands-shift-repeat-options__main">
    <div class="demands-shift-repeat-options__message">
      {{config.dictionary.message | translate}}
    </div>
    <div class="demands-shift-repeat-options__list">
      <div 
        class="demands-shift-repeat-options__item" 
        *ngFor="let item of config.options"
      > 
        <div class="demands-shift-repeat-options__item-content" (click)="setOption(item)">
          <div class="demands-shift-repeat-options__item-checkbox">
            <u-checkbox class="u-checkbox_circle-small" [ngModel]="item.check" ></u-checkbox>
          </div>
          <div class="demands-shift-repeat-options__item-name">{{item.name | translate}}</div>
        </div>
      </div>
    </div>
    <div class="demands-shift-repeat-options__actions">
      <button
        uButton
        class="demands-shift-repeat-options__actions-btn demands-shift-repeat-options__actions-btn_clear"
        (click)="cancelModal()"
      >
        {{config.dictionary.cancel | translate}}
      </button>
      <button
        uButton
        class="demands-shift-repeat-options__actions-btn" 
        [disabled]="activeOption === null"
        (click)="submitModal()"
      >
        {{config.dictionary.confirm | translate}}
      </button>
    </div> 
  </div>
</div>
