import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UAddress, USelectSItem } from '@shift/ulib';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { DemandsPassengerShiftAdvancedAddressType } from '@app/demands/models';

@Component({
  selector: 'app-demands-assign-pick',
  templateUrl: './demands-assign-pick.component.html',
  styleUrls: [ './demands-assign-pick.component.scss', 'demands-assign-pick.component.rtl.scss' ]
})
export class DemandsAssignPickComponent implements OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() addressTypes: USelectSItem[] = [];
  @Input() stations: USelectSItem[] = [];
  @Input() passengerSettingsValue: string;

  @Output() closeAction: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'demands-assign-pick';

  private unsubscribe: Subject<void> = new Subject();

  passengerShiftAdvancedAddressType = DemandsPassengerShiftAdvancedAddressType;
  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private localizationService: LocalizationService,
    public trackingService: TrackingService
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateAddress(data: UAddress) {
    this.form.get('address').patchValue({
      fullAddress: data.address,
      latitude: data.lat,
      longitude: data.lng,
      placeId: data.placeId
    });

    this.form.get('address').markAsDirty();
  }
}
