import { UMultiselectItem, USelectSItem } from '@shift/ulib';

import { DaysOfWeek, YesNoNumber } from '@app/shared/models';

const weekDays: (USelectSItem | UMultiselectItem)[] = [
  { name: 'general.weekDays.0', value: DaysOfWeek.Sunday },
  { name: 'general.weekDays.1', value: DaysOfWeek.Monday },
  { name: 'general.weekDays.2', value: DaysOfWeek.Tuesday },
  { name: 'general.weekDays.3', value: DaysOfWeek.Wednesday },
  { name: 'general.weekDays.4', value: DaysOfWeek.Thursday },
  { name: 'general.weekDays.5', value: DaysOfWeek.Friday },
  { name: 'general.weekDays.6', value: DaysOfWeek.Saturday }
];

const yesNoNumbers: USelectSItem[] = [
  { value: YesNoNumber.Yes, name: 'general.yes' },
  { value: YesNoNumber.No, name: 'general.no' }
];

export const appOptionsConfig = {
  weekDays,
  yesNoNumbers
};
