import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Person, PersonRole } from '@app/shared/models';
import { PassengerMember } from '@app/passengers/models';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PersonsService {
  private BASE_PATH: string = 'Persons';

  constructor(
    private apiService: ApiService
  ) {}

  findMember(contact: string, identity: string, role: PersonRole): Observable<PassengerMember> {
    return this.apiService.get(`${this.BASE_PATH}/FindMember`, {
      contact,
      identity,
      role
    });
  }

  findPerson(contact: string = '', identity: string = ''): Observable<Person> {
    return this.apiService.get(`${this.BASE_PATH}/FindPerson`, {
      contact,
      identity
    });
  }
}
