<div class="builder-history__main">
  <u-grid
    [tableName]="'builder_history'"
    [saveTableProps]="true"
    [rows]="rows" 
    [columns]="columns"
    [columnMode]="'force'"
    [headerHeight]="45"
    [headerOffset]="0"
    [footerHeight]="0"
    [rowHeight]="55"
    [reorderable]="true"
    [scrollbarV]="true"
    [scrollbarH]="true"
    [isRtl]="isRtl"
    [lang]="lang"
    [messages]="config.dictionary.uGrid | translate"
  >
  </u-grid>
</div>

<ng-template #isPlannedChange ngx-datatable-cell-template let-value="value">
  <div class="builder-history__details">
    {{value ? (config.dictionary.planned | translate) : (config.dictionary.notPlanned | translate)}}
  </div>
</ng-template>

<ng-template #oldNewChange ngx-datatable-cell-template let-row="row">
  <div class="builder-history__details">
    <div *ngIf="row.type !== rideChangeType.WaypointsChanged && row.type !== rideChangeType.TrackCodeChanged && row.type !== rideChangeType.RouteNewYearDuplicated">
      <div class="builder-history__details-old">
        {{row.oldValue}}
      </div> 
      <div class="builder-history__details-text">
        {{row.newValue}}
      </div>
    </div>
    <div *ngIf="row.type === rideChangeType.TrackCodeChanged">
      <div class="builder-history__details-old">
        {{row.oldValue | builderAuditRouteNumber}}
      </div> 
      <div class="builder-history__details-text">
        {{row.newValue | builderAuditRouteNumber}}
      </div>
    </div>

    <div *ngIf="row.type === rideChangeType.WaypointsChanged" class="builder-history__details-text builder-history__details-text_map-drop">
      {{config.dictionary.routeDragMap | translate}}
    </div>
  </div>   
</ng-template>

<ng-template #period ngx-datatable-cell-template let-row="row">
  <div class="builder-history__details">
    <div class="builder-history__details-text">
        {{row.periodStart | date:'dd/MM/yy'}} - {{row.periodEnd | date:'dd/MM/yy'}}
    </div>
    <div class="builder-history__details-text">
      {{row.activeDays}}
    </div>  
  </div>
</ng-template>

<ng-template #executor ngx-datatable-cell-template let-row="row">
  <div class="builder-history__details">
    <div class="builder-history__details-text">
      {{row.changeAuthorName}}
    </div>
    <div class="builder-history__details-text">
      {{row.changeDate | date:'dd/MM/yy HH:mm'}}
    </div>
  </div>
</ng-template>

<ng-template #approval ngx-datatable-cell-template let-row="row">
  <div class="builder-history__details">
    <div class="builder-history__details-text">
      {{row.approveStatus | builderApprovalStatus | translate}}
    </div>
    <div class="builder-history__details-text">
      {{row.approveStatusSetDate | date:'dd/MM/yy HH:mm'}}
    </div>
  </div>
</ng-template>
