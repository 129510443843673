import { USelectSItem } from '@shift/ulib';

import { AuthModuleDemandsPassengersViewFeatureType } from '@app/auth/models';
import { demandsConfig } from '@app/demands/configs';
import { DemandsPassengerShiftAdvancedAddressType, DemandsPassengerShiftRepeatType, DemandsView } from '@app/demands/models';

const DICTIONARY_PATH: string = 'demands.assignShift';

const dictionary: { [key: string]: string | { [key: string]: string; }; } = {
  title: `${DICTIONARY_PATH}.assignShift`,
  assignPassenger: `${DICTIONARY_PATH}.assignPassenger`,
  branch: `${DICTIONARY_PATH}.branch`,
  shift: `${DICTIONARY_PATH}.shift`,
  leavesIndependently: `${DICTIONARY_PATH}.leavesIndependently`,
  arrivesIndependently: `${DICTIONARY_PATH}.arrivesIndependently`,
  hours: `${DICTIONARY_PATH}.hours`,
  advanced: `${DICTIONARY_PATH}.advanced`,
  pickUp: `${DICTIONARY_PATH}.pickUp`,
  dropOff: `${DICTIONARY_PATH}.dropOff`,
  repeat: `${DICTIONARY_PATH}.repeat`,
  clearRepeat: `${DICTIONARY_PATH}.clearRepeat`,
  dates: `${DICTIONARY_PATH}.dates`,
  repeatEvery: `${DICTIONARY_PATH}.repeatEvery`,
  inDays: `${DICTIONARY_PATH}.inDays`,
  save: `${DICTIONARY_PATH}.save`,
  deleteShift: `${DICTIONARY_PATH}.deleteShift`,
  deleteShiftConfirm: `${DICTIONARY_PATH}.deleteShiftConfirm`,
  deletePassenger: `${DICTIONARY_PATH}.deletePassenger`,
  overlappingTimeConfirm: `${DICTIONARY_PATH}.overlappingTimeConfirm`,
  closeConfirm: `${DICTIONARY_PATH}.closeConfirm`,
  shiftIsNotInPeriod: {
    message: `${DICTIONARY_PATH}.shiftIsNotInPeriod.message`,
    edit: `${DICTIONARY_PATH}.shiftIsNotInPeriod.edit`,
    discard: `${DICTIONARY_PATH}.shiftIsNotInPeriod.discard`
  },
  passenger: `${DICTIONARY_PATH}.passenger`,
  department: `${DICTIONARY_PATH}.department`,
  assign: `${DICTIONARY_PATH}.assign`,
  cancel: `${DICTIONARY_PATH}.cancel`,
  without: 'general.without'
};

const options: { [key: string]: USelectSItem[]; } = {
  shifts: [
    {
      value: null,
      name: `${DICTIONARY_PATH}.options.shifts.special`
    }
  ],
  pickUpAndDropOff: [
    { value: DemandsPassengerShiftAdvancedAddressType.AnyAddress, name: `${DICTIONARY_PATH}.options.pickUpAndDropOff.address` },
    { value: DemandsPassengerShiftAdvancedAddressType.SavedStation, name: `${DICTIONARY_PATH}.options.pickUpAndDropOff.station` },
    { value: DemandsPassengerShiftAdvancedAddressType.PassengerSettings, name: `${DICTIONARY_PATH}.options.pickUpAndDropOff.passengersSettings` }
  ],
  repeatTypes: [
    { value: DemandsPassengerShiftRepeatType.Days, name: `${DICTIONARY_PATH}.options.repeatTypes.days` },
    { value: DemandsPassengerShiftRepeatType.Weeks, name: `${DICTIONARY_PATH}.options.repeatTypes.weeks` }
  ]
};

const datesRange: {
  dates: string[];
  checkDaysActive: number[];
  checkDaysAvailable: number[];
  maxPeriodSelection: {
    amount: number;
    unit: string;
  };
} = {
  dates: [],
  checkDaysActive: demandsConfig.daysOfWeek,
  checkDaysAvailable: demandsConfig.daysOfWeek,
  maxPeriodSelection: { amount: 10, unit: 'years' }
};

const repeatInfo: {
  repeatPeriod: {
    min: number;
    max: number;
  }
} = {
  repeatPeriod: {
    min: 1,
    max: 12
  }
};

const demandsAssignShiftComponent = {
  dictionaryPath: DICTIONARY_PATH,
  dictionary,
  tracking: demandsConfig.tracking[DemandsView.Passengers].default,
  options,
  datesRange,
  repeatInfo
};

const demandsAssignShiftComponentMunicipality = {
  ...demandsAssignShiftComponent,
  dictionary: {
    ...demandsAssignShiftComponent.dictionary,
    title: `${DICTIONARY_PATH}.assignActivity`,
    branch: `${DICTIONARY_PATH}.center`,
    shift: `${DICTIONARY_PATH}.activity`
  },
  tracking: demandsConfig.tracking[DemandsView.Passengers].activitySchedule
};

const demandsAssignShiftComponentArmy = {
  ...demandsAssignShiftComponent,
  dictionary: {
    ...demandsAssignShiftComponent.dictionary,
    branch: `${DICTIONARY_PATH}.base`
  }
};

export const demandsAssignShiftComponentConfig = {
  [DemandsView.Passengers]: {
    default: demandsAssignShiftComponent,
    [AuthModuleDemandsPassengersViewFeatureType.Student]: demandsAssignShiftComponentMunicipality,
    [AuthModuleDemandsPassengersViewFeatureType.Soldier]: demandsAssignShiftComponentArmy
  },
  [DemandsView.Shifts]: {
    default: {
      ...demandsAssignShiftComponent,
      tracking: demandsConfig.tracking[DemandsView.Shifts].default
    },
    [AuthModuleDemandsPassengersViewFeatureType.Student]: {
      ...demandsAssignShiftComponentMunicipality,
      tracking: demandsConfig.tracking[DemandsView.Shifts].activitySchedule
    },
    [AuthModuleDemandsPassengersViewFeatureType.Soldier]: {
      ...demandsAssignShiftComponentArmy,
      tracking: demandsConfig.tracking[DemandsView.Shifts].default
    }
  }
};
