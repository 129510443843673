import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environments/environment';
import { AppModule } from '@app/app.module';

if (environment.production) {
  enableProdMode();
}

function initEnv() {
  const url: string = environment.name ? `/assets/envs/env.${environment.name}.json` : '/api/ClientConfiguration/Web';

  fetch(url)
    .then(res => res.json())
    .then(data => {
      environment.config = data;

      platformBrowserDynamic().bootstrapModule(AppModule);
    });
}

initEnv();
