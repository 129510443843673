import { Pipe, PipeTransform } from '@angular/core';

import { ActivityLive } from '@app/activities/models';
import { ActivitiesDataService } from '@app/activities/services';

@Pipe({
  name: 'activitiesCounter'
})
export class ActivitiesCounterPipe implements PipeTransform {
  constructor(
    public activitiesDataService: ActivitiesDataService
  ) {}

  transform(activities: ActivityLive[]): number {
    return this.activitiesDataService.calculateActivitiesCounter(activities);
  }
}
