import { Component, OnInit, HostBinding, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragStart, DragRef, CdkDragDrop } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';
import { UButtonSize } from '@shift/ulib';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { MovePassengersRouteParticipantType, MovePassengersRouteColor } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { MovePassengersCheckAllPipe, MovePassengersSearchPipe } from '@app/shared/pipes';
import { RouteDirection } from '@app/routes/models';
import { BuilderFullMovePassengersDataService } from '@app/builder/services';
import { RoutePlannerMovePassengersItem, RoutePlannerMovePassengersRoute, RoutePlannerMovePassengersSourceRouteInitedData } from '@app/route-planner/models';
import { builderFullMovePassengersComponentConfig } from './builder-full-move-passengers.component.config';

@Component({
  selector: 'app-builder-full-move-passengers',
  templateUrl: './builder-full-move-passengers.component.html',
  styleUrls: [ './builder-full-move-passengers.component.scss', './builder-full-move-passengers.component.rtl.scss' ],
  providers: [ BuilderFullMovePassengersDataService ]
})
export class BuilderFullMovePassengersComponent implements OnInit {
  @HostBinding('class') hostClasses: string = 'builder-full-move-passengers';

  config = cloneDeep(builderFullMovePassengersComponentConfig);
  route: RoutePlannerMovePassengersSourceRouteInitedData;
  direction = RouteDirection;
  movePassengersRouteParticipantType = MovePassengersRouteParticipantType;
  appConstants = AppConstants;
  movePassengersRouteColor = MovePassengersRouteColor;
  searchRoutes: string = '';
  searchPassengers: string = '';
  draggingPassengers: DragRef = null;
  isRtl: boolean = this.localizationService.isRtl();
  uButtonSize = UButtonSize;

  get passengers(): RoutePlannerMovePassengersItem[] {
    return this.builderFullMovePassengersDataService.passengers;
  }

  get passengersSelected(): RoutePlannerMovePassengersItem[] {
    return this.builderFullMovePassengersDataService.passengersSelected;
  }

  get routes(): RoutePlannerMovePassengersRoute[] {
    return this.builderFullMovePassengersDataService.routes;
  }

  constructor(
    private bsModalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private localizationService: LocalizationService,
    private trackingService: TrackingService,
    public builderFullMovePassengersDataService: BuilderFullMovePassengersDataService
  ) {}

  ngOnInit() {
    this.trackEvent('click on move passenger');

    this.builderFullMovePassengersDataService.init(this.route);
  }

  private trackEvent(message: string) {
    this.trackingService.track(`[${builderFullMovePassengersComponentConfig.trackingId}] - ${message}`);
  }

  close() {
    this.bsModalRef.hide();
  }

  dragPassengersStarted(event: CdkDragStart, passenger: RoutePlannerMovePassengersItem) {
    this.trackEvent('drag');

    this.draggingPassengers = event.source._dragRef;

    if (!passenger.check) {
      passenger.check = true;
    }

    event.source.data = {
      values: this.passengersSelected.map(ob => ({ ...ob }))
    };

    this.changeDetectorRef.detectChanges();
  }

  dragPassengersEnded() {
    this.draggingPassengers = null;

    this.builderFullMovePassengersDataService.refreshPassengers();

    this.changeDetectorRef.detectChanges();
  }

  droppedPassengers(event: CdkDragDrop<any>) {
    if (event.previousContainer !== event.container) {
      const passengerIds: number[] = event.item.data.values.map((ob: RoutePlannerMovePassengersItem) => ob.id);

      this.builderFullMovePassengersDataService.removePassengersByIds(passengerIds);
    } else {
      this.builderFullMovePassengersDataService.updateCheckedPassengers();
    }
  }

  droppedPassengersIntoRoute(event: CdkDragDrop<any>, routeId: number) {
    const passengerIds: number[] = event.item.data.values.map((ob: RoutePlannerMovePassengersItem) => ob.id);

    this.builderFullMovePassengersDataService.movePassengersToRoute(passengerIds, routeId, this.route.id);
  }

  toggleCheckAllPassengers() {
    this.trackEvent('source route-check All passengers');

    const filteredPassengers = new MovePassengersSearchPipe().transform(this.passengers, this.searchPassengers, this.config.passengersSearchFields);
    const checkAll = new MovePassengersCheckAllPipe().transform(filteredPassengers);

    this.builderFullMovePassengersDataService.updateCheckForPassengers(filteredPassengers, !checkAll);
  }

  updateCheckForPassenger(passengerId: number, check: boolean) {
    this.builderFullMovePassengersDataService.updateCheckForPassenger(passengerId, check);
  }

  clickOnStationMap(route: RoutePlannerMovePassengersRoute) {
    this.trackEvent('click on station map icon');

    this.builderFullMovePassengersDataService.updateRouteColor(route.routeId);
  }
}
