import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AuthUserInfo } from '@app/auth/models';
import { appConfig } from '@app/shared/configs';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Intercom;
declare const window;

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  constructor() {
    this.load();
  }

  private load() {
    (function () {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + environment.config.intercomKey;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  init(user: AuthUserInfo) {
    Intercom('boot', {
      app_id: environment.config.intercomKey,
      web_name: environment.config.environmentType,
      role_id: user.person.role,
      role_name: user.person.roleName,
      custom_launcher_selector: `#${appConfig.idPrefix}-menu-chat-button`,
      name: user.person.firstName + ' ' + user.person.lastName,
      user_id: user.person.memberId,
      email: user.person.email || '',
      phone: user.person.mobile || '',
      company: {
        name: user.customer.name,
        company_id: user.customer.customerId
      }
    });
  }

  track(message: string) {
    Intercom('trackEvent', message);
  }
}
