import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';

import { AppConstants } from '@app/shared/constants';
import { RideTypesPerChanges, RouteDailyPlannedAccompany } from '@app/routes/models';
import { routesDailyWarningComponentConfig } from './routes-daily-warning.component.config';

@Component({
  selector: 'app-routes-daily-warning',
  templateUrl: './routes-daily-warning.component.html',
  styleUrls: [ './routes-daily-warning.component.scss', './routes-daily-warning.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesDailyWarningComponent implements OnInit {
  @Input() data: RouteDailyPlannedAccompany | string;
  @Input() type: RideTypesPerChanges;

  @HostBinding('class') hostClasses: string = 'routes-daily-warning';

  subTitle: string;
  config = cloneDeep(routesDailyWarningComponentConfig);

  ngOnInit() {
    this.initSubTitle();
  }

  private initSubTitle() {
    if ([ RideTypesPerChanges.StartHour, RideTypesPerChanges.EndHour ].includes(this.type)) {
      this.subTitle = this.parseHour(this.data as string);

      return;
    }

    if (this.type === RideTypesPerChanges.Accompany) {
      this.subTitle = this.isAnonymousAccompany(this.data as RouteDailyPlannedAccompany) ?
        this.config.dictionary.anonymousAccompany :
        this.data && (this.data as RouteDailyPlannedAccompany).name || this.config.dictionary.notSet;
    }
  }

  private parseHour(date: string): string {
    if (!date) { return; }

    return moment(date).format(AppConstants.TIME_FORMAT);
  }

  private isAnonymousAccompany(accompany: RouteDailyPlannedAccompany): boolean {
    if (!accompany) { return; }

    return !accompany.value && !accompany.name;
  }
}
