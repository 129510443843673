<div
  *ngIf="showRouteApprovals"
  class="routes-daily-approvals__main"
  [ngClass]="{
    'routes-daily-approvals__main_not-approved': actualApprovalsCount | routesDailyApprovalsStatus: expectedApprovalsCount : isRejected : routesDailyApprovalsStatus.NotApproved,
    'routes-daily-approvals__main_approved': actualApprovalsCount | routesDailyApprovalsStatus: expectedApprovalsCount : isRejected : routesDailyApprovalsStatus.Approved,
    'routes-daily-approvals__main_partly-approved': actualApprovalsCount | routesDailyApprovalsStatus: expectedApprovalsCount : isRejected : routesDailyApprovalsStatus.PartlyApproved
  }"
>
  <span class="routes-daily-approvals__main-content">{{routeApprovals}}</span>
  <span
    *ngIf="isRejected"
    class="routes-daily-approvals__main-status"
  >
    {{config.dictionary.unapproved | translate}}
  </span>
</div>
