import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { Errors } from '@app/shared/models';
import {
  DemandsShiftsViewGetTableBody,
  DemandsShiftsViewGetTable,
  DemandsShiftsViewInitialDataType,
  DemandsShiftsViewInitialData,
  DemandsShiftsViewPassenger,
  DemandsPassengerShift,
  DemandsPassengerShiftDeleteParams,
  DemandsShiftsViewPassengerPickUpSettings,
  DemandsShiftsParams,
  DemandsShift,
  DemandsPassengerShiftParams
} from '@app/demands/models';

@Injectable()
export class DemandsShiftsViewService {
  private readonly apiService = inject(ApiService);

  private readonly BASE_PATH: string = 'DemandsShiftsView';

  getInitialData(types: DemandsShiftsViewInitialDataType[]): Observable<DemandsShiftsViewInitialData> {
    return this.apiService.get(`${this.BASE_PATH}/InitialData`, { types });
  }

  getPassengers(departmentId: number): Observable<DemandsShiftsViewPassenger[]> {
    return this.apiService.get(`${this.BASE_PATH}/Passengers`, { departmentId });
  }

  getPassengerPickUpSettings(passengerId: number): Observable<DemandsShiftsViewPassengerPickUpSettings> {
    return this.apiService.get(`${this.BASE_PATH}/Passenger/PickUpSettings`, { passengerId });
  }

  getTable(body: DemandsShiftsViewGetTableBody): Observable<DemandsShiftsViewGetTable> {
    return this.apiService.post(`${this.BASE_PATH}/GetTable`, body);
  }

  getPassengerDemand(params: DemandsPassengerShiftParams): Observable<DemandsPassengerShift> {
    return this.apiService.get(`${this.BASE_PATH}/PassengerDemand`, params);
  }

  getShifts(params: DemandsShiftsParams): Observable<DemandsShift[]> {
    return this.apiService.get(`${this.BASE_PATH}/Shifts`, params);
  }

  savePassengerDemand(params: DemandsPassengerShift): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/PassengerDemand`, params, 'text', false, false, [ Errors.OverlapShift ]);
  }

  updatePassengerDemand(params: DemandsPassengerShift): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/PassengerDemand`, params, false, [ Errors.OverlapShift ]);
  }

  deletePassengerDemand(params: DemandsPassengerShiftDeleteParams): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}/PassengerDemand`, {}, params);
  }
}
