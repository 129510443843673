import { ContactType } from '@app/shared/models';

export enum PersonRole {
  Accompany = 10,
  Passenger = 16
}

export enum PersonParentContactRelativeType {
  Passenger = 0,
  Parent = 35,
  Siblings = 36,
  Other = 80,
  SocialWorker = 232
}

export interface PersonContact {
  id: number;
  value: string;
  type: ContactType;
  isConfirmed: boolean;
  validationErrors: {
    [key: string]: string;
  };
}

export interface PersonParentContact {
  parentId: number;
  relativeType: PersonParentContactRelativeType;
  firstName: string;
  lastName: string;
  contacts: PersonContact[];
}

export interface Person {
  id: number;
  firstName: string;
  lastName: string;
  identity: string;
  address: string;
  latitude: number;
  longitude: number;
  placeId: number;
  gender: number;
  birthDate: string;
  comment: string;
  contacts: PersonContact[];
  includeForUpdateEmails: boolean;
  validationErrors: {
    [key: string]: string;
  };
  parents?: PersonParentContact[];
}
