import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { HubConnectionBuilder, HubConnectionState, HttpTransportType, HubConnection } from '@microsoft/signalr';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  BASE_PATH: string = '';
  signalRClient: HubConnection;

  get isConnected(): boolean {
    return this.signalRClient ? this.signalRClient.state === HubConnectionState.Connected : false;
  }

  constructor(
    public oAuthService: OAuthService
  ) {}

  init(parameters = null): void {
    this.signalRClient = new HubConnectionBuilder()
      .withUrl(`${environment.config.serverUrlBase}${this.BASE_PATH}${parameters ? parameters : ''}`,
        {
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => this.oAuthService.getAccessToken()
        })
      .withAutomaticReconnect()
      .build();
  }

  start() {
    if (this.signalRClient) {
      return this.signalRClient.start();
    }
  }

  stop() {
    if (this.signalRClient) {
      return this.signalRClient.stop();
    }
  }

  onChannel<T>(name: string): Observable<T> {
    return new Observable<T>(observer => {
      this.signalRClient
        .on(name, (data: T) => observer.next(data));
    });
  }

  onClose() {
    return new Observable(observer => this.signalRClient.onclose(err => observer.next(err)));
  }
}
