import { Component, OnInit, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

@Component({
  selector: 'app-builder-days-copy',
  templateUrl: './builder-days-copy.component.html',
  styleUrls: [ './builder-days-copy.component.scss', './builder-days-copy.component.rtl.scss' ]
})
export class BuilderDaysCopyComponent implements OnInit {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-days-copy';

  dayActive: any = null;
  daysAvailable: any = [];
  days: any = [];
  sundayFirstDay: boolean;
  checkboxes: any = [
    { check: true, value: 'duplicate', name: 'builder.daysCopy.duplicate' },
    { check: false, value: 'rebuildNewDays', name: 'builder.daysCopy.rebuildNewDays' }
  ];

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.updateDays();
  }

  checkItem(item: any) {
    item.check = !item.check;

    const otherItems = this.checkboxes.filter((ob: any) => ob.value !== item.value);

    if (item.check) {
      otherItems.forEach((ob: any) => ob.check = false);
    } else {
      otherItems.forEach((ob: any) => ob.check = true);
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  apply() {
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { dayActive: this.dayActive, checkboxes: this.checkboxes } });
  }

  updateDays() {
    const momentDaysOfWeek = moment().localeData().weekdaysMin();
    const daysOfWeek = [];

    for (let i = 1; i < 7; i++) {
      const day = {
        value: i,
        name: momentDaysOfWeek[i]
      };

      daysOfWeek.push(day);
    }

    const firstDay = {
      value: 0,
      name: momentDaysOfWeek[0]
    };

    if (this.sundayFirstDay) {
      daysOfWeek.splice(0, 0, firstDay);
    } else {
      daysOfWeek.push(firstDay);
    }

    this.days = daysOfWeek.filter((obDay: any) => this.daysAvailable.some((el: number) => el === obDay.value));
  }
}
