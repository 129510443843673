import {
  Input,
  Component,
  HostBinding,
  OnInit,
  OnChanges,
  OnDestroy,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep, escape, isEqual } from 'lodash';
import {
  UPopupService,
  UHereMapPolygonAction,
  USelectSItem,
  UHereMapPolygon,
  USideModalButton,
  USideModalConfig,
  UHereMapPolygonSelect,
  UHereMapMarkerCluster,
  UHereMapPolygonChange,
  UHereMapsModule,
  UButtonModule,
  UGridModule,
  UCommonModule,
  UPopoverDirective,
  UPopoverModule,
  UHereMapsPolygonsMode
} from '@shift/ulib';

import { environment } from '@environments/environment';
import { AppLanguage, Area, AreasButton, AreasButtonId, AreasMapPassenger, AreasMapTarget, GeneralButtonProp } from '@app/shared/models';
import { LocalizationService, TrackingService } from '@app/shared/services';
import { AuthDataService } from '@app/auth/services';
import { areasComponentConfig } from './areas.component.config';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: [ './areas.component.scss', './areas.component.rtl.scss' ],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    UHereMapsModule,
    UGridModule,
    UButtonModule,
    UCommonModule,
    UPopoverModule
  ]
})
export class AreasComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() cities: USelectSItem[];
  @Input() passengers: AreasMapPassenger[];
  @Input() targets: AreasMapTarget[];
  @Input() buttons: AreasButton[];
  @Input() showExplanationModal: boolean = true;
  @Input() fullHeight: boolean;
  @Input() trackingId: string;

  @Output() buttonClick: EventEmitter<{ button: AreasButton; popover: UPopoverDirective; }> = new EventEmitter();

  @ViewChild('explanationModalBody', { static: true }) explanationModalBody: TemplateRef<any>;
  @ViewChild('cityAssignmentColumn', { static: true }) cityAssignmentColumn: TemplateRef<any>;
  @ViewChild('areaNameColumn', { static: true }) areaNameColumn: TemplateRef<any>;

  @HostBinding('class')
  get hostClasses() {
    return {
      'areas': true,
      'areas_full-height': this.fullHeight
    };
  }

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(areasComponentConfig);
  markers: UHereMapMarkerCluster[] = [];
  columns = [];
  rows = [];
  showErrors: boolean;
  isPolygonDirty: boolean;
  activePolygonId: number;
  invalidAreaIndex: number;
  scrollToRowIndex: number;
  polygons: UHereMapPolygon[] = [];
  explanationModalConfig: USideModalConfig;
  areas: UntypedFormArray = this.fb.array([]);
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  specificRowClassObjects: { rowPropertyName: string; className: string; value: number; }[];
  uHereMapsPolygonsMode = UHereMapsPolygonsMode;

  constructor(
    private localizationService: LocalizationService,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private uPopupService: UPopupService,
    private trackingService: TrackingService,
    private authDataService: AuthDataService
  ) {}

  ngOnInit() {
    this.attachColumnsTemplate();
    this.updateConfigButtons();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.passengers || changes.targets) && (this.passengers && this.targets)) {
      this.updateMapMarkers();
    }

    if (changes.control && this.control) {
      this.onControlValueChanges();
      this.initAreas();
      this.initExplanationModal();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private onControlValueChanges() {
    this.control.valueChanges
      .pipe(
        filter(data => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe(value => {
        const areaCityIds = this.areas.value.map(area => area.cityId);
        const controlCityIds = value.map(area => area.cityId);

        if (!isEqual(areaCityIds, controlCityIds)) {
          this.initAreas();
          this.initExplanationModal();
        }
      });
  }

  private updateConfigButtons() {
    if (this.buttons?.length) {
      this.config.buttons = [
        ...this.config.buttons,
        ...this.buttons
      ];
    }
  }

  private updateConfigButton(key: GeneralButtonProp, value?: boolean | string, id?: AreasButtonId) {
    this.config.buttons = this.config.buttons.map(button => !id || button.id === id ? ({
      ...button,
      [key]: value
    }) : button);
  }

  private updateMapMarkers() {
    const passengersMarkers = this.passengers.map(passenger => ({
      content: escape(passenger.name),
      position: {
        lat: passenger.latitude,
        lng: passenger.longitude
      },
      icon: this.config.map.markers.passenger.icon
    }));

    const targetsMarkers = this.targets.map(target => ({
      content: escape(target.name),
      latitude: target.latitude,
      longitude: target.longitude,
      position: {
        lat: target.latitude,
        lng: target.longitude
      },
      icon: this.config.map.markers.destination.icon
    }));

    this.markers = [ ...passengersMarkers, ...targetsMarkers ];
  }

  private initAreas() {
    this.areas = this.fb.array(this.control.value.map((area, index) => this.generateAreaForm(index, area)));

    if (this.control.disabled) {
      this.areas.disable();
    }

    this.rows = this.areas.value;

    this.areas.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        filter(data => !!data)
      )
      .subscribe(data => {
        this.rows = data;

        this.control.markAsDirty();
        this.control.patchValue(data.map(item => ({
          cityId: item.cityId,
          cityName: item.cityName,
          cityArea: item.cityArea
        })));
      });

    this.updatePolygons();
    this.checkIfAllAreasValid();
  }

  private updatePolygons() {
    this.polygons = this.areas.controls.map(control => ({
      id: control.value.index,
      content: this.getPolygonTooltipText(control as UntypedFormGroup),
      points: control.value.cityArea.polygon && control.value.cityArea.polygon.map(point => ({
        lat: point.latitude,
        lng: point.longitude
      }))
    }));
  }

  private initExplanationModal() {
    if (!this.showExplanationModal) { return; }

    const userInfo = this.authDataService.userInfo();
    const explanationModalByCustomerType = this.config.explanationModal[userInfo.customer.type] || this.config.explanationModal.default;

    this.explanationModalConfig = explanationModalByCustomerType[environment.config.environmentType] || explanationModalByCustomerType.default;
    this.explanationModalConfig.modal.bodyTemplate = this.explanationModalBody;

    if (!this.areas.value.length) {
      this.explanationModalConfig.initiallyOpen = true;
    }
  }

  private attachColumnsTemplate() {
    this.columns = this.config.columns && this.config.columns
      .map(column => column.cellTemplateName ? ({
        ...column,
        cellTemplate: this[column.cellTemplateName]
      }) : column);
  }

  private generateAreaForm(index: number, data?: Area): UntypedFormGroup {
    return this.fb.group({
      cityId: [ data && data.cityId || null, Validators.required ],
      cityName: [ data && data.cityName || null ],
      cityArea: this.fb.group({
        id: [ data && data.cityArea.id || 0 ],
        name: [ data && data.cityArea.name || null, Validators.required ],
        polygon: [ data && data.cityArea.polygon || [], Validators.required ]
      }, Validators.required),
      selectCityOpen: [ false ],
      index: [ index ]
    });
  }

  private deleteArea(index: number) {
    this.areas.removeAt(this.areas.value.findIndex(area => area.index === index));

    this.areas.controls.forEach((control, controlIndex) => control.get('index').patchValue(controlIndex));

    this.activePolygonId = null;

    this.areas.controls = [ ...this.areas.controls ];

    this.areas.markAsDirty();

    this.updatePolygons();

    this.checkIfAllAreasValid();
  }

  private getPolygonTooltipText(row: UntypedFormGroup): string {
    const cityName = row.controls.cityName.value;
    const areaName = (row.controls.cityArea as UntypedFormGroup).controls.name.value;

    return escape(cityName && areaName ? `${cityName} - ${areaName}` : cityName || areaName);
  }

  private scrollToRow(index: number) {
    setTimeout(() => {
      this.scrollToRowIndex = index;

      this.cdRef.markForCheck();
    });
  }

  private highlightRow(index: number) {
    this.specificRowClassObjects = [ {
      className: 'highlighted-row',
      rowPropertyName: 'index',
      value: index
    } ];
  }

  private clearHighlightedRow() {
    this.specificRowClassObjects = [];
  }

  updatePolygonContent(row: UntypedFormGroup) {
    const rowPolygon = this.polygons.find(polygon => polygon.id === row.value.index);

    if (rowPolygon) {
      rowPolygon.content = this.getPolygonTooltipText(row);
    }
  }

  trackEvent(event: string) {
    this.trackingService.track(`[${this.trackingId}] - ${event}`);
  }

  addNewArea() {
    this.trackEvent('click on \'Add an area\' button');
    this.updateConfigButton(GeneralButtonProp.Disabled, true);

    this.areas.push(this.generateAreaForm(this.areas.length));

    this.activePolygonId = null;
    this.areas.controls = [ ...this.areas.controls ];

    this.areas.updateValueAndValidity();

    this.scrollToRow(this.areas.length);
  }

  onExplanationButtonClick(button: USideModalButton) {
    this.trackEvent(`Explanation side modal - click on ${button.id} button`);
  }

  selectCity(city: USelectSItem, row: UntypedFormGroup) {
    this.updateRowControl(row, 'cityName', city.name);
    this.checkIfAllAreasValid(row);
    this.updatePolygonContent(row);

    this.areas.controls = [ ...this.areas.controls ];
  }

  updateRowControl(row: UntypedFormGroup, controlName: string, value: number | string | boolean) {
    row.controls[controlName].patchValue(value);

    this.areas.markAsDirty();
    this.cdRef.detectChanges();
  }

  deleteConfirmArea(row: UntypedFormGroup) {
    this.trackEvent('click on \'Delete\' icon');

    if ((row.controls.cityArea as UntypedFormGroup).controls.id.value === 0) {
      this.deleteArea(row.value.index);
    } else {
      this.uPopupService.showMessage(
        {
          showXIcon: true,
          message: this.config.dictionary.deleteConfirm,
          yes: this.config.dictionary.delete,
          no: this.config.dictionary.cancel
        },
        () => this.deleteArea(row.value.index)
      );
    }
  }

  onAreaNameChange(row: UntypedFormGroup) {
    if ((row.controls.cityArea as UntypedFormGroup).controls.name.dirty) {
      this.checkUniqueAreaName(row);
      this.updatePolygonContent(row);
    }
  }

  checkUniqueAreaName(row: UntypedFormGroup) {
    const areaNameControl = (row.controls.cityArea as UntypedFormGroup).controls.name;

    areaNameControl.setErrors(null);

    if (!areaNameControl.value) {
      areaNameControl.setErrors({ required: true });
    }

    for (let i = 0; i < this.areas.controls.length && i !== row.controls.index.value; i++) {
      const rowControls = (this.areas.controls[i] as UntypedFormGroup).controls;

      if (
        rowControls.cityId.value === row.controls.cityId.value &&
        rowControls.cityArea.value.name && rowControls.cityArea.value.name.toLowerCase() === (areaNameControl.value && areaNameControl.value.toLowerCase())
      ) {
        areaNameControl.setErrors({ existingArea: true });

        break;
      }
    }

    this.checkIfAllAreasValid(row);
  }

  selectPolygon(data: UHereMapPolygonSelect) {
    this.checkIfAllAreasValid(this.areas.controls[data.id] as UntypedFormGroup);

    if ((!this.activePolygonId || (this.activePolygonId && !this.isPolygonDirty)) && (!this.invalidAreaIndex || (this.invalidAreaIndex === data.id))) {
      this.activePolygonId = data.id;

      this.scrollToRow(data.id);
      this.highlightRow(data.id);
    }
  }

  savePolygon(polygons: UHereMapPolygon[]) {
    this.trackEvent('Map header - click on \'Save\' button');

    this.isPolygonDirty = false;

    this.clearHighlightedRow();

    this.areas.patchValue(this.areas.getRawValue().map(area => ({
      ...area,
      cityArea: {
        ...area.cityArea,
        polygon: polygons.find(polygon => polygon.id === area.index).points.map(point => ({ latitude: point.lat, longitude: point.lng }))
      }
    })));

    this.areas.controls.forEach((control: UntypedFormGroup) => this.checkUniqueAreaName(control));

    this.updateConfigButton(GeneralButtonProp.Disabled, false);
    this.checkIfAllAreasValid(this.areas.controls[this.activePolygonId] as UntypedFormGroup);

    this.polygons = polygons;
    this.activePolygonId = null;
  }

  editPolygon(row: UntypedFormGroup) {
    this.trackEvent('click on Map icon');

    if (!this.isPolygonDirty) {
      if (this.activePolygonId === row.controls.index.value) {
        this.activePolygonId = null;

        this.clearHighlightedRow();
      } else {
        if (!this.polygons.find(polygon => polygon.id === row.controls.index.value)) {
          this.polygons = [
            ...this.polygons,
            {
              id: row.controls.index.value,
              content: this.getPolygonTooltipText(row),
              points: []
            }
          ];
        }

        this.activePolygonId = row.controls.index.value;

        this.highlightRow(this.activePolygonId);
      }
    }
  }

  polygonChange(data: UHereMapPolygonChange) {
    this.isPolygonDirty = data.isPolygonDirty;

    if (data.action === UHereMapPolygonAction.Edit) {
      this.trackEvent('Map - Edit polygon points');
    }
  }

  checkIfAllAreasValid(row?: UntypedFormGroup) {
    const invalidControl = this.areas.controls.find(control => control.invalid);

    if (invalidControl) {
      this.invalidAreaIndex = invalidControl.value.index;

      if (row) {
        if (row.controls.index.value !== this.invalidAreaIndex) {
          this.updateRowControl(row, 'selectCityOpen', false);
          this.updateConfigButton(GeneralButtonProp.Disabled, true);

          this.showErrors = true;
        }
      } else {
        this.updateConfigButton(GeneralButtonProp.Disabled, true);

        this.showErrors = true;
      }
    } else {
      this.updateConfigButton(GeneralButtonProp.Disabled, false);

      this.showErrors = false;
      this.invalidAreaIndex = null;
    }

    this.cdRef.detectChanges();
  }

  onConfigButtonClick = (button: AreasButton, popover?: UPopoverDirective) => {
    this.buttonClick.emit({ button, popover });

    switch(button.id) {
      case AreasButtonId.NewArea: {
        this.addNewArea();

        break;
      }
    }
  };
}
