<u-group
  *ngIf="form && field.inputType"
  id="{{field.dataFields ? field.dataFields[0].name : field.dataField.name}}"
  class="u-group_large-field u-group_dynamic-field"
  [name]="field.title | translate"
  [valid]="form.valid"
  [disabled]="form.disabled"
  [message]="config.dictionary.required | translate"
  [ngSwitch]="field.inputType"
>
  <input
    uInput
    *ngSwitchCase="inputType.Text"
    type="text"
    placeholder="{{field.placeholder | translate}}"
    [uId]="field.id"
    [formControl]="form"
    [valid]="!form.invalid"
    [attr.maxlength]="field.config && field.config.maxLength"
    (blur)="onInputBlur()"
  >

  <u-input-number
    *ngSwitchCase="inputType.Number"
    [uId]="field.id"
    [formControl]="form"
    [valid]="!form.invalid"
    [startWithZero]="field?.config?.inputTypeNumber?.startWithZero"
    [decimal]="field?.config?.inputTypeNumber?.decimal"
    [maxDecimalDigits]="field?.config?.inputTypeNumber?.maxDecimalDigits"
    [min]="field?.config?.inputTypeNumber?.min"
    [max]="field?.config?.inputTypeNumber?.max"
    (blurInput)="onInputBlur()"
    placeholder="{{field.placeholder | translate}}"
  ></u-input-number>

  <app-input-address
    *ngSwitchCase="inputType.Address"
    [uId]="field.id"
    [form]="form"
  ></app-input-address>

  <u-select-s
    *ngSwitchCase="inputType.Select"
    [uId]="field.id"
    [items]="field.config && field.config.selectItems"
    [placeholder]="config.dictionary.select | translate"
    [formControl]="form"
    [emptyName]="config.dictionary.select | translate"
    [emptyRemove]="config.dictionary.removeSelect | translate"
    [emptyValue]="null"
    [emptyHidden]="false"
    [empty]="field?.config?.inputTypeSelect?.empty ?? !field.dataField.required"
    [valid]="!form.invalid"
    [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
    [container]="'body'"
    [classUDropdownContent]="'u-dropdown-content_app-default'"
  ></u-select-s>

  <u-multiselect
    *ngSwitchCase="inputType.Multiselect"
    [uId]="field.id"
    [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
    [placeholder]="(field.placeholder || config.dictionary.select) | translate"
    [messages]="config.dictionary.uMultiselect | translate"
    [items]="field.config && field.config.selectItems"
    [formControl]="form"
    [container]="'body'"
    [classUDropdownContent]="'u-dropdown-content_app-default'"
  ></u-multiselect>

  <textarea
    uTextarea
    *ngSwitchCase="inputType.Textarea"
    [uId]="field.id"
    [formControl]="form"
    [valid]="!form.invalid"
    (blur)="onInputBlur()"
    placeholder="{{field.placeholder | translate}}"
  ></textarea>

  <u-toggle
    *ngSwitchCase="inputType.Toggle"
    [uId]="field.id"
    [formControl]="form"
  ></u-toggle>

  <u-input-date
    *ngSwitchCase="inputType.Date"
    [uId]="field.id"
    [classUInput]="'u-input_text-center'"
    [formControl]="form"
    [container]="'body'"
    [valid]="form.valid"
    [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
    [messages]="config.dictionary.uInputDate | translate"
    [showIcon]="false"
    [disableDaysAfter]="field?.config?.inputTypeDate?.disableDaysAfterToday ? disableDaysAfterToday : null"
  ></u-input-date>
</u-group>

<ng-container *ngIf="field.loadComponent" #dynamicComponentContainer></ng-container>
