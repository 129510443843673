export enum RideDriverApprovalApproveStatus {
  WaitForApproval,
  Approved,
  Rejected,
  Failed,
  RequiredPreSaveRoute
}

export interface RideApproval {
  rideId: number;
  actualApprovals: number;
  expectedApprovals: number;
  hasRejectedApproval: boolean;
}

export interface RideDriverApproval {
  approveStatus: RideDriverApprovalApproveStatus;
  typeName: string;
  newValue: string;
}
