<div class="routes-change-shuttle-company__container">
  <div class="routes-change-shuttle-company__wrapper">
    <h4 class="routes-change-shuttle-company__title">{{config.dictionary.title | translate}}</h4>
  </div>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.shuttleCompany | translate"
  >
    <u-select-s
      [classUInput]="'u-input_arrow-light u-input_light'"  
      [items]="shuttleCompanies"
      [formControl]="form.controls['shuttleCompanyId']"
      [messages]="'uSelectS' | translate"
      [emptyName]="'general.select' | translate"
      [emptyRemove]="'general.removeSelect' | translate"
      [emptyValue]="null"
      [empty]="!isSCMandatory"
      [valid]="form.controls['shuttleCompanyId'].disabled || form.controls['shuttleCompanyId'].valid" 
      [placeholder]="'general.select' | translate"
    ></u-select-s>
  </u-group>
  <u-group 
    *ngIf="!form.get('driverId').disabled"
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.driver | translate"
  >
    <u-select-s
      [classUInput]="'u-input_arrow-light u-input_light'"  
      [items]="drivers"
      [formControl]="form.controls['driverId']"
      [messages]="'uSelectS' | translate"
      [emptyName]="'general.select' | translate"
      [emptyRemove]="'general.removeSelect' | translate"
      [emptyValue]="null"
      [valid]="form.get('driverId').disabled || form.get('driverId').valid" 
      [placeholder]="'general.select' | translate"
    ></u-select-s>
  </u-group>
  <u-group 
    *ngIf="!form.get('carId').disabled"
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.car | translate"
  >
    <u-select-s
      [classUInput]="'u-input_arrow-light u-input_light'"  
      [items]="cars"
      [formControl]="form.controls['carId']"
      [messages]="'uSelectS' | translate"
      [emptyName]="'general.select' | translate"
      [emptyRemove]="'general.removeSelect' | translate"
      [emptyValue]="null"
      [valid]="form.get('carId').disabled || form.get('carId').valid" 
      [placeholder]="'general.select' | translate"
    ></u-select-s>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.type | translate"
  >
    <app-routes-period
      [showTypesChange]="false"
      [showInputType]="true"
      [typeFormControl]="form.controls['type']"
      [changes]="form.controls['datesChange'].value"
      [viewportElement]="viewportElement"
      (updatePeriod)="updatePeriod($event)"
    ></app-routes-period>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.comments | translate"
  >
    <textarea
      uTextarea
      [formControl]="form.controls['comment']"
    ></textarea>
  </u-group>
  <div class="routes-change-shuttle-company__buttons">
    <div class="routes-change-shuttle-company__button">
      <button
        uButton
        class="routes-change-shuttle-company__btn"
        [disabled]="!isChanges || form.invalid"
        [size]="uButtonSize.Small"
        (click)="saveChanges()"
      >
        {{config.dictionary.actions.saveAndClose | translate}}
      </button>
    </div>
    <div class="routes-change-shuttle-company__button">
      <button
        uButton
        class="routes-change-shuttle-company__btn"
        [disabled]="!isChanges || form.invalid"
        [size]="uButtonSize.Small"
        (click)="saveChanges(routesChangeEmailSendType.SendBackgroundEmail)"
      >
        {{config.dictionary.actions.saveAndSendSC | translate}}
      </button>
    </div>
    <div class="routes-change-shuttle-company__button">
      <button
        uButton
        class="routes-change-shuttle-company__btn"
        [disabled]="!isChanges || form.invalid"
        [size]="uButtonSize.Small"
        (click)="saveChanges(routesChangeEmailSendType.GenerateEditableEmail)"
      >
        {{config.dictionary.actions.saveAndEditEmail | translate}}
      </button>
    </div>
  </div>
</div>
