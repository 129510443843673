import { Component, OnInit, HostBinding, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';

import { LocalizationService } from '@app/shared/services';
import { contactsComponentConfig } from './contacts.component.config';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: [ './contacts.component.scss', './contacts.component.rtl.scss' ]
})
export class ContactsComponent implements OnInit {
  @Input() form: UntypedFormArray;
  @Input() viewportElement: HTMLElement;
  @Input() showTitle: boolean = true;

  @HostBinding('class') hostClasses: string = 'contacts';

  @Output() addContactAction: EventEmitter<void> = new EventEmitter();
  @Output() removeContactAction: EventEmitter<{ index: number }> = new EventEmitter();
  @Output() addContactItemAction: EventEmitter<{ index: number }> = new EventEmitter();
  @Output() removeContactItemAction: EventEmitter<{ index: number, indexItem: number }> = new EventEmitter();

  config = cloneDeep(contactsComponentConfig);
  isRtl: boolean = true;
  contacts: UntypedFormArray;

  constructor(private localizationService: LocalizationService) {}

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();
  }

  addContact(): void {
    this.addContactAction.emit();
  }

  removeContact(index: number): void {
    this.removeContactAction.emit({ index });
  }

  addContactItem(index: number): void {
    this.addContactItemAction.emit({ index });
  }

  removeContactItem(index: number, indexItem: number): void {
    this.removeContactItemAction.emit({ index, indexItem });
  }
}
