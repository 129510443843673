import { Component, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalActions } from '@app/shared/models';

@Component({
  selector: 'app-builder-map-filter',
  templateUrl: './builder-map-filter.component.html',
  styleUrls: [ './builder-map-filter.component.scss', './builder-map-filter.component.rtl.scss' ]
})
export class BuilderMapFilterComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-map-filter';

  filter: any = {
    passengers: false,
    stations: false
  };

  constructor(
    private bsModalRef: BsModalRef
  ) {}

  close() {
    this.bsModalRef.hide();
    this.action.emit({ type: ModalActions.Close });
  }

  apply() {
    if (this.filter.passengers || this.filter.stations) {
      this.bsModalRef.hide();
      this.action.emit({ type: 'apply', content: { filter: this.filter } });
    }
  }
}
