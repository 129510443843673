import { ChangeDetectionStrategy, Component, HostBinding, inject, signal } from '@angular/core';
import { cloneDeep } from 'lodash';

import { TrackingService } from '@app/shared/services';
import { BuilderLayer, BuilderLayerId } from '@app/builder/models';
import { BuilderDataService } from '@app/builder/services';
import { builderLayersComponentConfig } from './builder-layers.component.config';

@Component({
  selector: 'app-builder-layers',
  templateUrl: './builder-layers.component.html',
  styleUrls: [ './builder-layers.component.scss', './builder-layers.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderLayersComponent {
  @HostBinding('class') hostClasses: string = 'builder-layers';

  private readonly trackingService = inject(TrackingService);
  private readonly builderDataService = inject(BuilderDataService);

  readonly #config = signal(cloneDeep(builderLayersComponentConfig));

  readonly config = this.#config.asReadonly();

  private trackEvent(message: string) {
    this.trackingService.track(`[${this.config().trackingId}] - ${message}`);
  }

  toggleLayer(layer: BuilderLayer) {
    this.#config.update(state => ({
      ...state,
      layers: state.layers.map(obj => obj.id === layer.id ? { ...layer, active: !obj.active } : layer)
    }));

    this.trackEvent(`layers button > click on ${layer.id.replace('-', ' ')}`);

    if (layer.id === BuilderLayerId.MonitoringPath) {
      this.builderDataService.toggleMapRideShowMonitoredPaths();

      return;
    }
  }
}
