<div class="multi-delete-confirm-header">
  <div class="multi-delete-confirm-header__title">
    <span class="multi-delete-confirm-header__title-text">{{dictionaryStore().title | translate}}</span>
  </div>
  <div class="multi-delete-confirm-header__close" (click)="closePopup()"></div>
</div>

<div class="multi-delete-confirm-body">
  <div class="multi-delete-confirm-body__text multi-delete-confirm-body__text_warning" *ngIf="deleteWarningMessage()">{{deleteWarningMessage()}}</div>
  <div class="multi-delete-confirm-body__text multi-delete-confirm-body__text_warnings" *ngIf="deleteWarningMessages?.length">
    <div class="multi-delete-confirm-body__text multi-delete-confirm-body__text_warning">
      {{dictionaryStore().deletionWillCause | translate}}
    </div>

    <div class="multi-delete-confirm-body__text multi-delete-confirm-body__text_warning" *ngFor="let warning of deleteWarningMessages; let index = index;">
      {{index + 1}}. {{warning | translate}}
    </div>
  </div>
  <div class="multi-delete-confirm-body__text multi-delete-confirm-body__text_warning" *ngIf="!deleteWarningMessages?.length">{{dictionaryStore().permanentDeleteWarning | translate}}</div>
  <div class="multi-delete-confirm-body__text">{{dictionaryStore().enterNumberToConfirm | translate}}</div>

  <div class="multi-delete-confirm-body__amount">
    <span class="multi-delete-confirm-body__amount-title">{{dictionaryStore().itemsAmount | translate}}</span>
    <div class="multi-delete-confirm-body__amount-input">
      <u-input-number
        [class]="'u-input-number_app-delete-route'"
        [min]="0"
        [valueType]="'number'"
        [formControl]="itemsToDeleteAmountCtrl"
        [valid]="itemsToDeleteAmountCtrl.valid"
      ></u-input-number>
      <span class="multi-delete-confirm-body__amount-input-error" *ngIf="itemsToDeleteAmountCtrl.dirty && itemsToDeleteAmountCtrl.invalid">
        {{dictionaryStore().invalidNumber | translate}}
      </span>
    </div>
  </div>

  <button
    uButton
    class="multi-delete-confirm-body__submit"
    [disabled]="!itemsToDeleteAmountCtrl.valid"
    (click)="save()"
  >
    {{dictionaryStore().confirmButton | translate}}
  </button>
</div>
