<app-route-policy-tabs
  [form]="routePolicy"
  [isDirty]="isFormDirty"
  [carsForm]="vehicleTypes"
  [availableCarTypes]="builderFullCalculationService.options().vehicleTypes"
  [citiesCombinationsForm]="citiesCombinationsByBranchService.rulesForm"
  [citiesCombinationsShowGoToSettings]="routePlannerTypeGeneric()"
  [cities]="citiesCombinationsByBranchService.cities$ | async"
  [branchForm]="citiesCombinationsByBranchService.branchForm"
  [branches]="citiesCombinationsByBranchService.branches$ | async"
  [small]="visibleComponents.main"
  [submitText]="(visibleComponents.routes ? 'builder.full.settings.calculateRoutes' : 'builder.full.settings.apply') | translate"
  (action)="closeRoutePolicy()"
></app-route-policy-tabs>
