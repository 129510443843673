import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';

import { LocalizationService } from '@app/shared/services';
import { CitiesPricingTableService } from '@app/shared/services/cities-pricing-table.service';
import { citiesPricingTableComponentConfig } from './cities-pricing-table.component.config';

@Component({
  selector: 'app-cities-pricing-table',
  templateUrl: './cities-pricing-table.component.html'
})
export class CitiesPricingTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() carTypes: { id: number, name: string }[];
  @Input() citiesForm: UntypedFormArray = new UntypedFormArray([]);

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(citiesPricingTableComponentConfig);

  constructor(
    private cdRef: ChangeDetectorRef,
    public localizationService: LocalizationService,
    public citiesPricingTableService: CitiesPricingTableService
  ) {
    citiesPricingTableService.init(this.config.columns, this.config.dictionaryPath);
  }

  ngOnInit() {
    this.citiesPricingTableService.citiesInit
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.cdRef.markForCheck());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['carTypes']) {
      this.citiesPricingTableService.initCitiesTable(this.carTypes, this.citiesForm);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onCitiesEditValuesClose(data): void {
    if (data.newValue || data.newValue === '') {
      const carTypeId: number = +data.prop.match(/\d+/)[0];
      const newVal: number = +data.newValue ? +data.newValue : 0;
      const formIndex: number = this.citiesPricingTableService.citiesRows.value.findIndex(cityRow => cityRow.cityId === data.row.cityId);
      const carTypePricesIndex: number = this.citiesPricingTableService.citiesForm.controls[formIndex].get('carTypePrices').value.findIndex(price => price.carTypeId === carTypeId);

      (this.citiesPricingTableService.citiesForm.controls[formIndex].get('carTypePrices') as UntypedFormArray).at(carTypePricesIndex)
        .patchValue({ [data.prop.replace(/[0-9]/g, '')]: newVal });
      this.citiesPricingTableService.citiesRows.controls[formIndex]
        .patchValue({ [data.prop]: newVal });

      this.citiesPricingTableService.citiesForm.markAsDirty();
      this.citiesPricingTableService.citiesRows.markAsDirty();
    }
  }
}
