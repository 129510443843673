import { RouteDirection } from '@app/routes/models';

export const builderDuplicateRouteComponentConfig = {
  directions: [
    { value: RouteDirection.Forward, name: 'general.direction.forward' },
    { value: RouteDirection.Backward, name: 'general.direction.backward' }
  ],
  presetsStore: [
    { value: 'all', name: 'builder.duplicateRoute.presets.all' },
    { value: 'today', name: 'builder.duplicateRoute.presets.today' },
    { value: 'fromTodayOn', name: 'builder.duplicateRoute.presets.fromTodayOn' },
    { value: 'custom', name: 'builder.duplicateRoute.presets.custom' }
  ],
  routeNameDivider: '>'
};
