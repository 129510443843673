import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class MasterCustomerService {
  private BASE_PATH: string = 'MasterCustomer';

  constructor(
    private apiService: ApiService
  ) {}

  getSubCustomers(): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/SubCustomers`);
  }

  getBranches(customerIds?: number[]): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/Branches`, { customerIds });
  }

  getDepartments(customerIds?: number[]): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/Departments`, { customerIds });
  }

  getTags(customerIds?: number[]): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/Tags`, { customerIds });
  }
}
