import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'app-monitoring-dashboard-timer',
  templateUrl: './monitoring-dashboard-timer.component.html'
})
export class MonitoringDashboardTimerComponent implements OnInit, OnDestroy {
  @Input() startTime: any;
  @Input() textEndTime: string;
  @Input() textBeforeTime: string;
  @Input() shortTime: boolean;

  @Output() endTimer: EventEmitter<any> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'monitoring-dashboard-timer';

  interval: NodeJS.Timeout;
  countDown: any;

  constructor() {}

  ngOnInit() {
    this.interval = setInterval(() => {
      const distance = this.startTime.getTime() - new Date().getTime();
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (!this.shortTime) {
        this.countDown = ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
      } else {
        this.countDown = ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2);
      }

      if (distance < 0) {
        clearInterval(this.interval);
        this.countDown = null;
        this.endTimer.emit(true);

        if (this.textEndTime) {
          this.countDown = this.textEndTime;
        }
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
