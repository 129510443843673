import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UGridScroll, UGridService, UGridSort } from '@shift/ulib';

import {
  AppLanguage,
  TableConfig,
  TablePageConfig,
  TableEditRowValue,
  TablePageRowEditMode,
  HeaderMenuIconValue,
  GridHeaderButton,
  GridHeaderButtonValue,
  GridHeaderTemplate
} from '@app/shared/models';
import { LocalizationService, TrackingService, TablePageService, HeaderMenuIconsService, HeaderDataService } from '@app/shared/services';
import { tablePageComponentConfig } from './table-page.component.config';

@Component({
  selector: 'app-table-page',
  templateUrl: './table-page.component.html',
  styleUrls: [ './table-page.component.scss', 'table-page.component.rtl.scss' ]
})
export class TablePageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: TablePageConfig;

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() excelExport: EventEmitter<void> = new EventEmitter<void>();
  @Output() addRow: EventEmitter<void> = new EventEmitter<void>();
  @Output() editRow: EventEmitter<number> = new EventEmitter<number>();
  @Output() editRowValue: EventEmitter<TableEditRowValue> = new EventEmitter();
  @Output() selectRows: EventEmitter<{[key: string]: any}[]> = new EventEmitter<{[key: string]: any}[]>();
  @Output() closeIconClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() visibleColumns: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollTable: EventEmitter<UGridScroll> = new EventEmitter<UGridScroll>();
  @Output() threeDotsItemAction: EventEmitter<{ action: string; id: number; }> = new EventEmitter<{ action: string; id: number; }>();

  @HostBinding('class') hostClasses: string = 'table-page';

  @ViewChild('normalCell', { static: true }) public normalCell: TemplateRef<any>;
  @ViewChild('translateCell', { static: true }) public translateCell: TemplateRef<any>;
  @ViewChild('booleanCell', { static: true }) public booleanCell: TemplateRef<any>;
  @ViewChild('editRowCell', { static: true }) public editRowCell: TemplateRef<any>;
  @ViewChild('editViewRowCell', { static: true }) public editViewRowCell: TemplateRef<any>;
  @ViewChild('editRowDotsCell', { static: true }) public editRowDotsCell: TemplateRef<any>;

  @ViewChild('modalContainer', { static: true, read: ViewContainerRef }) modalContainer: ViewContainerRef;
  @ViewChild('editRowContainer', { static: true, read: ViewContainerRef }) editRowContainer: ViewContainerRef;

  private unsubscribe: Subject<void> = new Subject();

  tablePageConfig = cloneDeep(tablePageComponentConfig);
  tableConfig: TableConfig;
  columnsFilteredStore: boolean;
  tablePageRowEditMode = TablePageRowEditMode;
  gridHeaderTemplate = GridHeaderTemplate;
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  rowIdentity: Function = row => row.id;

  constructor(
    private localizationService: LocalizationService,
    private trackingService: TrackingService,
    private headerDataService: HeaderDataService,
    private headerMenuIconsService: HeaderMenuIconsService,
    private cdRef: ChangeDetectorRef,
    public uGridService: UGridService,
    public tablePageService: TablePageService
  ) {}

  ngOnInit() {
    if (!this.config.ignoreAppHeader) {
      this.initTopNavHeader();
    }
  }

  ngOnChanges() {
    this.tableConfig = this.config.tableConfig;
    this.tableConfig.columns = this.attachTemplatesToColumns(this.tableConfig.columns);

    this.initTablePageService();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    if (this.config.showGlobalSearch) {
      this.headerDataService.updateShowGlobalSearch(false);
    }

    if (this.config.headerMenuIconsTemplate) {
      this.headerMenuIconsService.resetIcons();
    }

    if (this.config.tableConfig.resetColumnsFiltersStorage) {
      this.resetColumnsFiltersStorage();
    }
  }

  private initTablePageService(): void {
    this.tablePageService.tableId = this.config.id;
    this.tablePageService.modalContainer = this.tableConfig.editRowMode === this.tablePageRowEditMode.SplitLayout ? this.editRowContainer : this.modalContainer;
    this.tablePageService.tablePageConfig = this.config;
    this.tablePageService.initRows(this.tableConfig.rows);
    this.tablePageService.clearSelectedRows();
  }

  private initTopNavHeader(): void {
    if (this.config.headerMenuIconsTemplate) {
      this.headerMenuIconsService.setMenuIconsByTemplate(this.config.headerMenuIconsTemplate);
    }

    this.headerDataService.updateShowGlobalSearch(this.config.showGlobalSearch !== undefined ? this.config.showGlobalSearch : true);

    this.headerMenuIconsService.menuIconClick$.pipe(takeUntil(this.unsubscribe))
      .subscribe(({ value }) => {
        switch (value) {
          case HeaderMenuIconValue.Plus: {
            this.trackingService.track(`[${this.config.trackingId}] - Clicked + to add`);

            if (this.config.modalComponent) {
              this.tablePageService.clearRowHighlight();
              this.tablePageService.openAddEditModal(this.config.modalComponent, this.tablePageService);
            }

            this.addRow.emit();

            break;
          }

          case HeaderMenuIconValue.Refresh: {
            this.refresh.emit();

            break;
          }
        }
      });
  }

  private resetColumnsFiltersStorage() {
    this.uGridService.saveTablePropToLocalStorage({
      propName: 'columnsFiltersStore',
      tableName: this.config.id,
      userId: this.uGridService.getUserIdFromLocalStorage(),
      saveTableProps: true,
      value: []
    });
  }

  deleteClick() {
    this.trackingService.track(`[${this.config.trackingId}] - Clicked on delete icon`);
    this.delete.emit();
  }

  excelClick() {
    this.excelExport.emit();
  }

  rowEditClick(row: any): void {
    this.trackingService.track(`[${this.config.trackingId}] - Edit row clicked`);

    this.editRow.emit(row.id);
  }

  attachTemplatesToColumns(columns: any[]): any[] {
    if (columns) {
      columns.forEach(column => {
        column['cellTemplate'] = column.cellTemplate || (column.cellTemplateName ? this[column.cellTemplateName] || this.config.tableConfig.cellTemplates?.[column.cellTemplateName] : this.normalCell);

        if (column['headerTemplateName']) {
          column['headerTemplate'] = this.config.tableConfig.headerCellTemplates[column.headerTemplateName];
        }

        if (column['filterTemplateName']) {
          column['filterTemplate'] = column.filterTemplate || this[column['filterTemplateName']] || this.config.tableConfig.filterTemplates[column.filterTemplateName];
        }

        if (column['filterTypeItemsFnName']) {
          column['filterTypeItemsFn'] = this.config.tableConfig.filterTypeItemsFns[column.filterTypeItemsFnName];
        }

        if (column['filterTypeItemsSearchFnName']) {
          column['filterTypeItemsSearchFn'] = this.config.tableConfig.filterTypeItemsSearchFns[column.filterTypeItemsSearchFnName];
        }

        if (column['editTypeDropdownName']) {
          column['editTypeDropdown'] = this.config.tableConfig.editTypeDropdown[column.editTypeDropdownName];
        }

        if (column['filterSortingFnName']) {
          column['filterSortingFunction'] = this.config.tableConfig.filterSortingFns[column.filterSortingFnName];
        }

        if (column['cellClassFnName']) {
          column['cellClass'] = this.config.tableConfig.cellClassFns[column.cellClassFnName];
        }

        if (column['comparatorFnName']) {
          column['comparator'] = this.config.tableConfig.comparatorFns[column.comparatorFnName];
        }
      });
    }

    return columns;
  }

  setSelectedRows(data: any): void {
    this.tablePageService.selectedRows = [ ...data.selected ];
    this.selectRows.emit([ ...data.selected ]);
  }

  resetAllColumnsFilter(): void {
    this.tablePageService.updateResetColumnsFilter(true);
  }

  columnsFilteredChange(data: boolean): void {
    this.columnsFilteredStore = data;
  }

  onScrollTable(scrollPosition: UGridScroll): void {
    this.scrollTable.emit(scrollPosition);
    this.tablePageService.tableOffsetY = scrollPosition.offsetY;
  }

  onFilterRows(rows) {
    this.tablePageService.updateFilteredRows(rows);

    this.cdRef.detectChanges();
  }

  onSortTable(sorts: UGridSort[]): void {
    this.tablePageService.tableSorts = sorts;
  }

  resetColumnsFilterChange(value: boolean): void {
    this.tablePageService.updateResetColumnsFilter(value);
  }

  onGridHeaderButtonClick(button: GridHeaderButton) {
    switch (button.value) {
      case GridHeaderButtonValue.ResetFilters: {
        this.resetAllColumnsFilter();

        break;
      }

      case GridHeaderButtonValue.Excel: {
        this.excelClick();

        break;
      }

      case GridHeaderButtonValue.Delete: {
        this.deleteClick();

        break;
      }
    }
  }

  selectThreeDotsItemAction(action: string, row: any) {
    this.threeDotsItemAction.emit({ action, id: row.id });
  }
}
