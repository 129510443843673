import { Component, OnInit, Input, HostBinding, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

import { TrackingService } from '@app/shared/services';
import {
  AuthModuleName,
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType
} from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { builderConfig } from '@app/builder/configs';
import { BuilderActiveRide, BuilderCalculationMode, BuilderMode } from '@app/builder/models';
import { BuilderDataService, BuilderDataStoreService } from '@app/builder/services';
import { builderRideOrderDetailsComponentConfig } from './builder-ride-order-details.component.config';
import { AppConstants } from '@app/shared/constants';

@Component({
  selector: 'app-builder-ride-order-details',
  templateUrl: './builder-ride-order-details.component.html',
  styleUrls: [ './builder-ride-order-details.component.scss', './builder-ride-order-details.component.rtl.scss' ]
})
export class BuilderRideOrderDetailsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() options: any;
  @Input() builderDataConfig: any;
  @Input() addEditForm: any;
  @Input() activeRide: BuilderActiveRide;
  @Input() isRtl: boolean;
  @Input() lang: string;
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'builder-ride-order-details';

  private unsubscribe: Subject<void> = new Subject();

  departmentsType: AuthModuleDepartmentsFeatureType;
  departmentsTypes = AuthModuleDepartmentsFeatureType;
  builderCalculationMode = BuilderCalculationMode;
  disableDaysBefore: string = moment().format(AppConstants.DATE_FORMAT_ISO);
  builderMode = BuilderMode;
  config = cloneDeep(builderRideOrderDetailsComponentConfig);

  constructor(
    private authDataService: AuthDataService,
    private trackingService: TrackingService,
    public builderDataService: BuilderDataService,
    public builderDataStoreService: BuilderDataStoreService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeRide && this.activeRide?.startDateTime) {
      this.updateDisableDaysBefore();
    }
  }

  ngOnInit() {
    this.onAddEditFormActiveRideValueChanges();
    this.getAuthModules();
    this.onDateFormControlValueChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private updateDisableDaysBefore() {
    if (this.builderDataConfig.mode === BuilderMode.Edit) {
      this.disableDaysBefore = moment(`${this.activeRide.date}`, AppConstants.DATE_FORMAT_BASE_LINE).format(AppConstants.DATE_FORMAT_ISO);
    }
  }

  private onAddEditFormActiveRideValueChanges() {
    this.addEditForm.get('activeRide.carTypeId').valueChanges
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.builderDataService.editCarType());

    this.addEditForm.get('activeRide.supervisorId').valueChanges
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(passengerId => {
        if (passengerId) {
          this.builderDataService.passengerSupervisorAdd({ passengerId });

          return;
        }

        this.builderDataService.passengerSupervisorRemove();
      });
  }

  private onDateFormControlValueChanges() {
    this.builderDataService.dateFormControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(date =>
        this.builderDataService.datesRangeChange({
          dates: [ date ],
          checkDaysActive: [ moment(date).day() ],
          checkDaysAvailable: this.builderDataService.datesRange.checkDaysAvailable
        }, true)
      );
  }

  private getAuthModules() {
    this.authDataService.modules$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(modules => {
        this.departmentsType = modules[AuthModuleName.Departments] && modules[AuthModuleName.Departments][AuthModuleDepartmentsFeature.Type];
      });
  }

  track(message: string) {
    this.trackingService.track(`[${builderConfig.trackingId} - Ride order details] - ${message}`);
  }

  onAllowEmptyStationsChange(value: boolean) {
    this.track(`Allow empty stations - ${value ? 'Active' : 'Inactive'}`);
  }
}
