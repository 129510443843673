import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentUTCConvert',
  standalone: true
})
export class MomentUTCConvertPipe implements PipeTransform {
  transform(value: string, from: string, to: string): string {
    return moment.utc(value, from).local().format(to);
  }
}
