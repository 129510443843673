import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Errors } from '@app/shared/models';
import { ApiService } from '@app/shared/services';
import {
  RideMonitoringData,
  RoutesDaily,
  RoutesDailyParams,
  RoutesWeekly,
  RoutesWeeklyParams,
  RoutesChangeBaseParams,
  RoutesChangeTimeTrackBody,
  RoutesChangeAddSupervisorParams,
  RoutesChangeBidNumberParams,
  RoutesChangeSetSupervisorParams,
  RoutesChangeAccompanyParams,
  RoutesChangeAccompanySetRequiredParams,
  RoutesChangeAddPassengerParams,
  RoutesChangeRemovePassengerParams,
  RoutesChangeRemoveShuttleCompanyParams,
  RoutesChangeRestoreRideParams,
  RoutesChangeShuttleSettingsParams,
  RoutesChangeTimeParams,
  RoutesChangeCancelRideParams,
  RoutesChangeCarTypeBody,
  RoutesCancellationData,
  RoutesDeleteParams,
  RoutesEntityList,
  RoutesExportAgendaExcelParams,
  RoutesRidePassengerDetails,
  RouteRideSupervisorsParams,
  RouteRideSupervisors,
  RoutesRidePassengersCustomer,
  RoutesCarTypesBody,
  RoutesCarType,
  RoutesCancellationPolices,
  RoutesCancellationPolicesParams,
  RoutesCarsList,
  RoutesRidePassengers,
  RoutesCustomerPassenger,
  RoutesInitalData,
  RoutesChangeAccompanyCostParams
} from '@app/routes/models';

@Injectable({
  providedIn: 'root'
})
export class RoutesTableService {
  private BASE_PATH: string = 'RoutesTable';

  constructor(
    private apiService: ApiService
  ) {}

  getRoutesDailyTableData(params: RoutesDailyParams, withoutSpinner?: boolean): Observable<RoutesDaily> {
    return this.apiService.post(`${this.BASE_PATH}/GetDaily`, params, 'text', false, withoutSpinner);
  }

  getWeekly(params: RoutesWeeklyParams, withoutSpinner?: boolean): Observable<RoutesWeekly> {
    return this.apiService.post(`${this.BASE_PATH}/GetWeekly`, params, 'text', false, withoutSpinner);
  }

  getInitialData(): Observable<RoutesInitalData> {
    return this.apiService.get(`${this.BASE_PATH}/InitialData`);
  }

  getRidePassengerDetails(rideId: number): Observable<RoutesRidePassengerDetails[]> {
    return this.apiService.get(`${this.BASE_PATH}/RidePassengersDetails`, { rideId });
  }

  getAccompaniesList(): Observable<RoutesEntityList> {
    return this.apiService.get(`${this.BASE_PATH}/AccompaniesList`);
  }

  getDriversList(): Observable<RoutesEntityList> {
    return this.apiService.get(`${this.BASE_PATH}/DriversList`);
  }

  getShuttleList(rideId?: number): Observable<RoutesEntityList> {
    return this.apiService.get(`${this.BASE_PATH}/ShuttleList`, rideId && { rideId });
  }

  getCarsList(): Observable<RoutesCarsList> {
    return this.apiService.get(`${this.BASE_PATH}/CarsList`);
  }

  getCancellationData(rideId: number): Observable<RoutesCancellationData> {
    return this.apiService.get(`${this.BASE_PATH}/CancellationData`, { rideId });
  }

  getCancellationPolices(params: RoutesCancellationPolicesParams): Observable<RoutesCancellationPolices> {
    return this.apiService.post(`${this.BASE_PATH}/GetCancellationPolices`, params);
  }

  getRouteMonitoringData(rideIds: number[]): Observable<RideMonitoringData[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetDailyMonitoringData`, rideIds);
  }

  exportAgendaToExcel(data: RoutesExportAgendaExcelParams): Observable<Blob> {
    return this.apiService.postBlob(`${this.BASE_PATH}/Export/Excel`, data, 'blob');
  }

  changeAccompany(data: RoutesChangeAccompanyParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/Accompany`, data);
  }

  changeAccompanyCost(data: RoutesChangeAccompanyCostParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/Accompany/Cost`, data);
  }

  changeAccompanySetRequired(data: RoutesChangeAccompanySetRequiredParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/Accompany/SetRequired`, data);
  }

  changeResetAccompany(data: RoutesChangeAccompanySetRequiredParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/AccompanyReset`, data);
  }

  changeAddSupervisor(data: RoutesChangeAddSupervisorParams, customerId: number): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/AddSupervisor${customerId ? `?customerId=${customerId}` : ''}`, data);
  }

  changeSetSupervisor(data: RoutesChangeSetSupervisorParams, customerId: number): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/SetSupervisor${customerId ? `?customerId=${customerId}` : ''}`, data);
  }

  changeRemoveSupervisor(data: RoutesChangeBaseParams, customerId: number): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/RemoveSupervisor${customerId ? `?customerId=${customerId}` : ''}`, data);
  }

  changeTime(data: RoutesChangeTimeParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/Time`, data, false, null, [ Errors.PermissionsDenied ]);
  }

  changeShuttleSettings(params: RoutesChangeShuttleSettingsParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/ShuttleSettings`, params);
  }

  changeRemoveShuttleCompany(data: RoutesChangeRemoveShuttleCompanyParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/RemoveShuttleCompany`, data);
  }

  changeRemovePassenger(data: RoutesChangeRemovePassengerParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/RemovePassenger`, data);
  }

  changeAddPassenger(data: RoutesChangeAddPassengerParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/AddPassenger`, data);
  }

  changeCancelRide(data: RoutesChangeCancelRideParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/CancelRide`, data, false, null, [ Errors.PermissionsDenied ]);
  }

  changeRestoreRide(data: RoutesChangeRestoreRideParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/RestoreRide`, data, false, null, [ Errors.PermissionsDenied ]);
  }

  changeBidNumber(data: RoutesChangeBidNumberParams): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/BidNumber`, data);
  }

  deleteFromTable(data: RoutesDeleteParams): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Delete`, data);
  }

  changeCarType(body: RoutesChangeCarTypeBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/CarType`, body);
  }

  changeTimeTrack(data: RoutesChangeTimeTrackBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Change/TimeTrack`, data);
  }

  getCarTypes(body: RoutesCarTypesBody): Observable<RoutesCarType[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetCarTypes`, body);
  }

  getRouteCanceledDates(routeId: number): Observable<string[]> {
    return this.apiService.get(`${this.BASE_PATH}/RouteCanceledDates`, { routeId });
  }

  getRideSupervisors(params: RouteRideSupervisorsParams): Observable<RouteRideSupervisors> {
    return this.apiService.get(`${this.BASE_PATH}/RideSupervisors`, params);
  }

  getRidePassengersCustomers(rideId: number): Observable<RoutesRidePassengersCustomer[]> {
    return this.apiService.get(`${this.BASE_PATH}/RidePassengersCustomers`, { rideId });
  }

  getRidePassengers(rideId: number): Observable<RoutesRidePassengers> {
    return this.apiService.get(`${this.BASE_PATH}/RidePassengers`, { rideId });
  }

  getCustomerPassengers(): Observable<RoutesCustomerPassenger[]> {
    return this.apiService.get(`${this.BASE_PATH}/CustomerPassengers`);
  }
}
