import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  computed,
  inject
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, concatMap, filter, map, reduce, startWith, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, from, of, Subscription, throwError } from 'rxjs';
import { cloneDeep, omit } from 'lodash';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { UButtonSize, UButtonView, UMenuIconStore, UPopupService } from '@shift/ulib';

import {
  LocalizationService,
  OperationGuidService,
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import {
  AppLanguage,
  Errors,
  ModalActions,
  RadioButton,
  SignalRErrorMessage
} from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { DeleteRouteComponent } from '@app/shared/components';
import { RouteShuttleCompaniesService } from '@app/route-shuttle-companies/services';
import {
  AuthModuleName,
  AuthModuleRouteBuilderFeature,
  AuthModuleRouteBuilderFeatureDeleteRoute,
  AuthModuleRouteBuilderFeatureDetail,
  AuthModuleRoutesFeature,
  AuthModuleRoutesFeatureMandatoryField
} from '@app/auth/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import {
  RouteChangeReportMode,
  RouteDirection,
  RoutesExportType
} from '@app/routes/models';
import { RoutesExportModalService } from '@app/routes/services';
import { RouteEditSessionHubService } from '@app/routes/services';
import { RideOrdersService } from '@app/ride-orders/services';
import { RideOrderAddEdit, RideOrderStatus } from '@app/ride-orders/models';
import { RoutePlannerService } from '@app/route-planner/services';
import {
  BuilderAfterSaveOption,
  BuilderBuildMode,
  BuilderCustomerDataParams,
  BuilderCustomerDataType,
  BuilderMode,
  BuilderSaveActionType,
  BuilderSectionIconValue,
  BuilderTuningMode,
  BuilderViewMode
} from '@app/builder/models';
import { builderConfig } from '@app/builder/configs';
import {
  BuilderCommonService,
  BuilderDataService,
  BuilderDataStoreService,
  BuilderModalService,
  BuilderService,
  BuilderTuningStoreService,
  BuilderAgendaStoreService,
  BuilderFilterStoreService,
  BuilderPassengersStoreService,
  BuilderRoutesStoreService
} from '@app/builder/services';
import { builderMainComponentConfig } from './builder-main.component.config';

@Component({
  selector: 'app-builder-main',
  templateUrl: './builder-main.component.html',
  styleUrls: [ './builder-main.component.scss', './builder-main.component.rtl.scss' ]
})
export class BuilderMainComponent implements OnInit, OnDestroy {
  @Output() closeRoute: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-main';

  @ViewChild('builderMain', { static: true }) public builderMain: ElementRef<any>;
  @ViewChild('builderModal', { static: true }) public builderModal: ElementRef<any>;
  @ViewChild('sectionDetails', { static: false }) public sectionDetails: ElementRef<any>;
  @ViewChild('sectionStations', { static: false }) public sectionStations: ElementRef<any>;
  @ViewChild('sectionSC', { static: false }) public sectionSC: ElementRef<any>;

  private readonly destroyRef = inject(DestroyRef);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly uPopupService = inject(UPopupService);
  private readonly trackingService = inject(TrackingService);
  private readonly routeEditSessionHubService = inject(RouteEditSessionHubService);
  private readonly operationGuidService = inject(OperationGuidService);
  private readonly localizationService = inject(LocalizationService);
  private readonly routesExportModalService = inject(RoutesExportModalService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly routePlannerService = inject(RoutePlannerService);
  private readonly routeShuttleCompaniesService = inject(RouteShuttleCompaniesService);
  private readonly rideOrdersService = inject(RideOrdersService);
  private readonly builderModalService = inject(BuilderModalService);
  private readonly builderService = inject(BuilderService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  private readonly builderTuningStoreService = inject(BuilderTuningStoreService);
  private readonly builderAgendaStoreService = inject(BuilderAgendaStoreService);
  private readonly builderFilterStoreService = inject(BuilderFilterStoreService);
  private readonly builderPassengersStoreService = inject(BuilderPassengersStoreService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  public readonly builderCommonService = inject(BuilderCommonService);
  public readonly builderDataStoreService = inject(BuilderDataStoreService);
  public readonly builderDataService = inject(BuilderDataService);

  readonly authUserInfo = this.builderDataService.authUserInfo;
  readonly authCustomer = this.builderDataService.authCustomer;
  readonly authModules = this.builderDataService.authModules;
  readonly routeBuilderFeatureTypeGeneric = this.builderDataService.routeBuilderFeatureTypeGeneric;
  readonly routeBuilderFeatureTypeShuttleCompany = this.builderDataService.routeBuilderFeatureTypeShuttleCompany;
  readonly destinationStart = toSignal(this.builderDataService.addEditForm.get('details.direction').valueChanges.pipe(map(value => value === RouteDirection.Backward)));
  readonly detailsCustomerId = toSignal(this.builderDataService.addEditForm.get('details.customerId').valueChanges);
  readonly disabledLockedToggleBySCCustomerId = computed(() => this.routeBuilderFeatureTypeShuttleCompany() && (this.builderDataService.customer() ? false : !this.detailsCustomerId()));
  readonly builderDataConfig = this.builderDataService.config;

  private deleteModalRef: BsModalRef;

  uButtonSize = UButtonSize;
  uButtonView = UButtonView;
  selectedPassengerId$ = this.builderCommonService.selectedPassengerId$;
  editRouteChanged$ = this.builderCommonService.editRouteChanged$;
  addEditForm: UntypedFormGroup;
  activeBlock: string = null;
  isRtl: boolean = this.localizationService.isRtl();
  dictionary = builderMainComponentConfig.dictionary;
  lang: AppLanguage = this.localizationService.getLanguage();
  editRouteId: number = null;
  editRouteSubscribe: Subscription = null;
  editSubscribes: Subscription[] = [];
  actionBlocked: boolean = false;
  builderMode = BuilderMode;
  builderBuildMode = BuilderBuildMode;
  isDestroyed: boolean;
  canDeleteRoutes: boolean;
  builderViewMode = BuilderViewMode;
  builderTuningMode = BuilderTuningMode;
  builderSectionIconValue = BuilderSectionIconValue;
  focusedSectionIconValue: BuilderSectionIconValue;
  config = cloneDeep(builderMainComponentConfig);

  get rideOrderBackButtonVisible(): boolean {
    const rideOrderRejected = this.builderDataService.rideOrderSummaryForm.get('status').value === RideOrderStatus.Rejected;

    return this.builderDataService.rideOrder.summaryVisible &&
      (!this.builderDataService.rideOrder.readOnlyMode || rideOrderRejected) &&
      (this.builderDataService.rideOrder.hasRequestingDeparment || this.builderDataConfig().mode === BuilderMode.Add);
  }

  get rideOrderSaveButtonDisabled(): boolean {
    const backToDetailsButtonIsClicked = this.builderDataConfig().mode === BuilderMode.Add || !this.builderDataService.rideOrder.summaryVisible;

    return this.builderDataService.rideOrder.readOnlyMode && !backToDetailsButtonIsClicked;
  }

  ngOnInit() {
    this.init();
    this.onEditRouteId();
    this.onEditRouteData();
  }

  ngOnDestroy() {
    this.isDestroyed = true;

    this.builderCommonService.editRouteIdSet(null);

    this.builderDataService.stopRouteEditSession();

    if (this.builderDataConfig().viewMode === BuilderViewMode.Default && this.builderDataConfig().buildMode !== this.builderBuildMode.RouteSuggestions) {
      this.operationGuidService.removeGuid();
      this.builderRoutesStoreService.routesSet([]);
    }

    if (this.builderDataConfig().viewMode === BuilderViewMode.Full) {
      this.builderRoutesStoreService.highlightRoute(null);
      this.editSubscribesDestroy();
    }

    if (this.editRouteSubscribe) {
      this.editRouteSubscribe.unsubscribe();
    }

    this.builderDataService.resetMapRide();
    this.builderTuningStoreService.reset();
    this.builderAgendaStoreService.reset();
    this.builderFilterStoreService.reset();
    this.builderPassengersStoreService.reset();
  }

  private getCustomer() {
    return this.builderService.getCustomer().pipe(tap(customer => this.builderDataService.updateCustomer(customer)));
  }

  private getCustomerSupervisorModuleStatus() {
    return this.builderService.getCustomerSupervisorModuleStatus()
      .pipe(tap(customerSupervisorModuleStatus => this.builderDataService.updateCustomerSupervisorModuleStatus(customerSupervisorModuleStatus)));
  }

  private getCustomerData(params?: BuilderCustomerDataParams) {
    return (this.builderDataConfig().buildMode === BuilderBuildMode.RideOrders ?
      forkJoin([ this.rideOrdersService.getCustomerData(), this.rideOrdersService.getSupervisors() ])
        .pipe(map(([ customerData, supervisors ]) => ({ ...customerData, supervisors }))) :
      this.builderService.getCustomerData(params)
    )
      .pipe(
        tap(customerData => this.builderDataStoreService.loadCustomerData(customerData))
      );
  }

  private init() {
    this.addEditForm = this.builderDataService.addEditForm;

    this.builderDataService.setConfig('modal', this.builderModal);

    this.editRouteId = this.builderCommonService.getEditRouteId();

    if (this.editRouteId && this.editRouteId > 0) {
      this.builderDataService.setConfig('mode', BuilderMode.Edit);
    }

    if (this.builderDataConfig().mode === BuilderMode.Edit && this.builderDataConfig().buildMode !== BuilderBuildMode.RouteSuggestions) {
      this.trackingService.track(`[${(<any>this.activatedRoute).data.value.trackingId || 'Track edit'}] - open`);
    }

    if (this.builderDataService.modeAdd() || this.builderDataService.buildModeRouteSuggestions()) {
      (
        this.routeBuilderFeatureTypeShuttleCompany() && this.builderDataConfig().viewMode === BuilderViewMode.Default ?
          this.builderDataService.addEditForm.get('details.customerId').valueChanges
            .pipe(
              startWith(<string>undefined),
              tap(customerId => customerId && this.addRoute(customerId)),
              map(customerId => customerId ? undefined : { types: [ BuilderCustomerDataType.SubCustomers ] }),
              filter(data => !!data)
            )
          : of(undefined)
      )
        .pipe(
          switchMap(params => params ? this.getCustomerData(params) : forkJoin([ this.getCustomerData(), this.getCustomerSupervisorModuleStatus() ])),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }

    if (!this.builderDataService.createOrEditRoutesAllowed()) {
      this.builderDataService.toggleFormsState(true);
    }

    this.builderDataService.initByFeatureType();

    const authModules = this.authModules();

    const routeBuilder = authModules[AuthModuleName.RouteBuilder] || {};
    const routes = authModules[AuthModuleName.Routes] || {};

    this.initCanDeleteRoutes(routeBuilder[AuthModuleRouteBuilderFeature.DeleteRoute]);

    if (this.builderDataConfig().buildMode !== BuilderBuildMode.RideOrders) {
      this.updateAddEditFormByFeatureMandatoryFields(routes[AuthModuleRoutesFeature.MandatoryFields]);
    }

    this.builderDataService.filterSaveActions(
      BuilderSaveActionType.Delete,
      this.canDeleteRoutes && this.builderDataService.config().buildMode !== BuilderBuildMode.RouteSuggestions && !this.routeBuilderFeatureTypeShuttleCompany()
    );

    if (routeBuilder[AuthModuleRouteBuilderFeature.DefaultReportMode]) {
      this.builderDataService.setSelectedEmailReport(routeBuilder[AuthModuleRouteBuilderFeature.DefaultReportMode]);
    }

    this.builderDataService.updateHasPurchaseOrderFeature(
      !!(authModules?.[AuthModuleName.RouteBuilder]?.[AuthModuleRouteBuilderFeature.Details]?.includes(AuthModuleRouteBuilderFeatureDetail.PurchaseOrder))
    );

    if (this.builderDataConfig().buildMode !== BuilderBuildMode.RouteSuggestions) {
      const isAddMode = this.builderDataConfig().mode === BuilderMode.Add;
      const isDefaultViewMode = this.builderDataConfig().viewMode === BuilderViewMode.Default;

      if (isAddMode && isDefaultViewMode && this.routeBuilderFeatureTypeGeneric()) {
        this.addRoute();
      }

      if (
        (isAddMode && this.builderDataConfig().viewMode === BuilderViewMode.Full) ||
        (this.builderDataConfig().mode === BuilderMode.Edit && isDefaultViewMode)
      ) {
        this.editRoute(this.editRouteId);
      }
    }

    if (this.builderDataConfig().buildMode !== BuilderBuildMode.RideOrders) {
      this.updateFocusedSectionIconValue(BuilderSectionIconValue.Details);
    }
  }

  private onEditRouteId() {
    const isEditMode = this.builderDataConfig().mode === BuilderMode.Edit;
    const isFullViewMode = this.builderDataConfig().viewMode === BuilderViewMode.Full;

    if (this.builderDataConfig().buildMode !== BuilderBuildMode.RouteSuggestions && (isEditMode || isFullViewMode)) {
      this.editRouteSubscribe = this.builderCommonService
        .editRouteId$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((id: number) => {
          if (Number.isInteger(id) && id !== this.editRouteId) {
            this.activeBlockChange(null);
            this.editRoute(id);

            this.editRouteId = id;
          }
        });
    }
  }

  private onEditRouteData() {
    if (this.builderDataConfig().buildMode === BuilderBuildMode.RouteSuggestions) {
      this.builderCommonService.editRouteData$
        .pipe(
          switchMap(data => this.builderDataStoreService.customerDataLoaded$
            .pipe(
              filter(value => !!value),
              map(() => data)
            )
          ),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(data => {
          this.activeBlockChange(null);

          this.builderDataService.updateByFeatureType();
          this.builderDataService.updateRoute(data);

          this.getRouteShuttleCompaniesDates(this.builderDataService.datesChanges.dateFrom, this.builderDataService.datesChanges.dateTo);
          this.getShuttleCompanyContracts();

          this.selectTab({ id: 'map' }, false);
        });
    }
  }

  private updateSectionIcons(iconValue: BuilderSectionIconValue) {
    this.config.sectionIcons = this.config.sectionIcons.map(icon => ({
      ...icon,
      selected: icon.value === iconValue
    }));
  }

  private initCanDeleteRoutes(deleteRoutes: AuthModuleRouteBuilderFeatureDeleteRoute) {
    this.canDeleteRoutes = this.builderDataConfig().viewMode === BuilderViewMode.Full || !!deleteRoutes;
  }

  private updateAddEditFormByFeatureMandatoryFields(mandatoryFields: AuthModuleRoutesFeatureMandatoryField[]) {
    Object.entries(builderConfig.addEditFormFeatureMandatoryFields).forEach(([ key, value ]) => {
      this.builderDataService.updateAddEditFormByFeatureMandatoryFields(
        mandatoryFields,
        key,
        value
      );
    });
  }

  private addRoute(customerId?: number) {
    this.builderService
      .new({
        routeMode: builderConfig.routeModesByBuildMode[this.builderDataService.config().buildMode] || builderConfig.routeModesByBuildMode.default,
        routeType: this.builderCommonService.routeType(),
        ...(customerId ? { customerId } : {})
      })
      .pipe(
        tap(data => this.operationGuidService.setGuid(data.guid)),
        switchMap(data => customerId ? forkJoin([ this.getCustomerData(), this.getCustomerSupervisorModuleStatus() ]).pipe(map(() => data)) : of(data)),
        switchMap(data => this.builderDataStoreService.customerDataLoaded$
          .pipe(
            filter(value => !!value),
            map(() => data)
          )
        ),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        const { details, activeRide } = data;

        this.builderDataService.updateByFeatureType();
        this.builderDataService.updateRoute(data);
        this.builderDataService.updateLockedMap(false);

        if ([ BuilderBuildMode.Routes, BuilderBuildMode.RouteTemplates ].includes(this.builderDataService.config().buildMode)) {
          this.builderRoutesStoreService.routesSet([
            {
              routeId: details.routeId,
              status: null,
              code: details.number,
              name: details.name,
              direction: details.direction,
              days: details.activeDays,
              startDate: details.dateFrom,
              endDate: details.dateTo,
              rideStartDateTime: moment(activeRide.startDateTime).format(AppConstants.TIME_FORMAT),
              rideEndDateTime: moment(activeRide.endDateTime).format(AppConstants.TIME_FORMAT),
              totalPassengers: activeRide.passengers.length,
              carTypeName: null,
              carTypeCapacity: null,
              shuttleCompany: null,
              locked: false,
              allowEmptyStations: details.allowEmptyStations
            }
          ]);

          this.builderRoutesStoreService.routeActiveSet(details.routeId);
          this.builderRoutesStoreService.highlightRoute(details.routeId);

          this.getShuttleCompanyContracts();
          this.editSubscribesInit();
        }

        this.builderDataService.setDefaultDepartment();
      });
  }

  private editRoute(routeId: number) {
    this.builderRoutesStoreService.highlightRoute(routeId);

    this.builderDataService.updateLockedMap(true);

    if (this.builderDataConfig().mode === BuilderMode.Edit) {
      this.builderRoutesStoreService.updateShowBackdrop(true);

      this.builderService
        .initialize(routeId)
        .pipe(
          tap(initialize => this.operationGuidService.setGuid(initialize)),
          switchMap(initialize =>
            from([
              from(this.builderDataService.stopRouteEditSession()),
              from(this.builderDataService.startRouteEditSession(initialize))
            ])
          ),
          concatMap(observable =>
            observable
              .pipe(
                catchError(err => {
                  if (err.message !== SignalRErrorMessage.ConnectionStoppedBeforeHandshakeComplete) {
                    this.uPopupService.showErrorMessage({ message: `${this.config.dictionary.errorCode}.${Errors.ServerIsDown}` });
                  }

                  return throwError(err);
                })
              )
          ),
          reduce((acc, arr) => [ ...acc, arr ], []),
          tap(() => {
            if (this.isDestroyed) {
              this.builderDataService.stopRouteEditSession();
            }
          }),
          filter(() => !this.isDestroyed),
          switchMap(() =>
            forkJoin([
              this.getQuery(routeId),
              this.getCustomerData(),
              this.getCustomerSupervisorModuleStatus(),
              (this.routeBuilderFeatureTypeShuttleCompany() ? this.getCustomer() : of(null))
            ])
              .pipe(map(([ data ]) => data))
          ),
          tap(() => this.builderRoutesStoreService.updateShowBackdrop(false)),
          catchError(err => {
            this.builderRoutesStoreService.updateShowBackdrop(false);

            return throwError(err);
          }),
          switchMap(data => this.builderDataStoreService.customerDataLoaded$
            .pipe(
              filter(value => !!value),
              map(() => data)
            )
          ),
          take(1),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(data =>
          this.builderDataConfig().buildMode === BuilderBuildMode.RideOrders ?
            this.onRideOrderQueryComplete(data as RideOrderAddEdit) :
            this.onQueryComplete(data)
        );
    } else {
      this.getQuery(routeId)
        .pipe(
          switchMap(data => this.builderDataStoreService.customerDataLoaded$
            .pipe(
              filter(value => !!value),
              switchMap(() => (
                this.routeBuilderFeatureTypeShuttleCompany() && this.builderDataConfig().viewMode === BuilderViewMode.Full ?
                  this.getCustomer() : of(null)
              )),
              map(() => data)
            )
          ),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(data =>
          this.builderDataConfig().buildMode === BuilderBuildMode.RideOrders ?
            this.onRideOrderQueryComplete(data as RideOrderAddEdit) :
            this.onQueryComplete(data)
        );
    }
  }

  private getQuery(routeId: number) {
    if (this.builderDataConfig().viewMode === BuilderViewMode.Default) {
      return this.builderDataConfig().buildMode === BuilderBuildMode.RideOrders ?
        this.rideOrdersService.openRideOrder(routeId) :
        this.getRouteEditQuery(routeId);
    }

    if (this.builderDataConfig().viewMode === BuilderViewMode.Full) {
      return this.builderService.open({ routeId });
    }

    return of(null);
  }

  private getRouteEditQuery(routeId: number) {
    return this.builderService.edit({
      routeId,
      ...(this.builderDataConfig().buildMode === BuilderBuildMode.Routes || this.builderDataConfig().buildMode === BuilderBuildMode.RouteSuggestions ? {
        activeDate: moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_LINE)
      } : {}),
      ...(this.builderDataConfig().buildMode === BuilderBuildMode.RouteTemplates ? {
        activeDay: moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).day()
      } : {})
    });
  }

  private getRouteShuttleCompaniesDates(disableDaysBefore: string, disableDaysAfter: string) {
    if (this.routeBuilderFeatureTypeShuttleCompany()) {
      this.routeShuttleCompaniesService.getRouteShuttleCompaniesDates(this.editRouteId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(data => this.builderDataService.setDisabledDays(disableDaysBefore, disableDaysAfter, data.dates));
    }
  }

  private onRideOrderQueryComplete(data: RideOrderAddEdit) {
    this.editSubscribesDestroy();

    this.builderDataService.updateByFeatureType();
    this.builderDataService.updateRoute(data.route);

    if (this.builderDataConfig().mode === BuilderMode.Edit) {
      this.builderDataService.rideOrderSummaryForm.patchValue(data.approvalDetails);
      this.builderDataService.updateRideOrderSummaryVisible(true);
      this.builderDataService.updateRideOrderSummaryVisibleStore(true);

      return;
    }

    this.operationGuidService.setGuid(data.route.guid);
  }

  private getShuttleCompanyContracts() {
    if (this.builderDataConfig().buildMode === BuilderBuildMode.RouteTemplates) { return; }

    this.builderDataService.getShuttleCompanyContracts();
  }

  private onQueryComplete(data) {
    this.editSubscribesDestroy();

    if ([ BuilderBuildMode.Routes, BuilderBuildMode.RouteTemplates ].includes(this.builderDataService.config().buildMode)) {
      this.addEditForm.get('details').valueChanges.pipe(take(1)).subscribe(() => this.editSubscribesInit());
    }

    this.builderRoutesStoreService.routeActiveSet(data.details.routeId);
    this.builderDataService.updateByFeatureType();
    this.builderDataService.updateRoute(data);

    this.getRouteShuttleCompaniesDates(this.builderDataService.datesChanges.dateFrom, this.builderDataService.datesChanges.dateTo);
    this.getShuttleCompanyContracts();

    if (this.builderDataConfig().mode !== BuilderMode.Edit) {
      this.operationGuidService.setGuid(data.guid);
    }

    this.selectTab({ id: 'map' }, false);

    this.cdRef.detectChanges();
  }

  private scrollToSection(value: BuilderSectionIconValue) {
    const elementsByIconValue = {
      [BuilderSectionIconValue.SC]: this.sectionSC,
      [BuilderSectionIconValue.Stations]: this.sectionStations,
      [BuilderSectionIconValue.Details]: this.sectionDetails
    };

    if (elementsByIconValue[value]) {
      elementsByIconValue[value].nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  editSubscribesInit() {
    this.editSubscribes = [];

    const number = this.addEditForm.get('details.number').valueChanges.subscribe(value => { this.builderRoutesStoreService.routeActiveUpdate({ code: value }); });
    const name = this.addEditForm.get('details.name').valueChanges.subscribe(value => { this.builderRoutesStoreService.routeActiveUpdate({ name: value }); });
    const direction = this.addEditForm.get('details.direction').valueChanges.subscribe(value => { this.builderRoutesStoreService.routeActiveUpdate({ direction: value }); });
    const dateFrom = this.addEditForm.get('details.dateFrom').valueChanges.subscribe(value => { this.builderRoutesStoreService.routeActiveUpdate({ startDate: value }); });
    const dateTo = this.addEditForm.get('details.dateTo').valueChanges.subscribe(value => { this.builderRoutesStoreService.routeActiveUpdate({ endDate: value }); });
    const activeDays = this.addEditForm.get('details.activeDays').valueChanges.subscribe(value => { this.builderRoutesStoreService.routeActiveUpdate({ days: value }); });
    const branchNames = this.addEditForm.get('details.branchNames').valueChanges.subscribe(value => { this.builderRoutesStoreService.routeActiveUpdate({ branchNames: value }); });

    this.editSubscribes = [ number, name, direction, dateFrom, dateTo, activeDays, branchNames ];
  }

  editSubscribesDestroy() {
    this.editSubscribes.forEach((ob: any) => ob.unsubscribe());
  }

  edit(routeId: number) {
    this.builderDataService.edit(routeId);
  }

  actionBlockedChange() {
    const focusMapBoxContentElement = this.builderMain.nativeElement.querySelector('.builder-main__map-box-content :focus');
    const focusDetailsElement = this.builderMain.nativeElement.querySelector('.builder-details :focus');
    const types = [ 'input', 'select', 'textarea' ];

    this.actionBlocked = (focusMapBoxContentElement && types.includes(focusMapBoxContentElement.tagName.toLowerCase())) ||
      (focusDetailsElement && types.includes(focusDetailsElement.tagName.toLowerCase()));
  }

  saveActionChange(data) {
    const { value } = data.selected;

    if (value === BuilderSaveActionType.Delete) {
      this.remove();

      return;
    }

    if (this.builderDataService.saveLoading) {
      return;
    }

    this.trackingService.track(`[${builderConfig.trackingId}] - planned car type - click on save`);

    if (this.addEditForm.invalid) {
      this.uPopupService.showErrorMessage({ message: this.dictionary.invalidForm });

      return;
    }

    if (this.actionBlocked) {
      return;
    }

    this.builderCommonService.saveRouteAction();

    if (this.builderDataConfig().buildMode === BuilderBuildMode.RouteSuggestions) {
      return;
    }

    const saveAndTrack = (saveType: number, addMessage: string, editMessage: string) => {
      if (this.builderDataConfig().mode === BuilderMode.Add) {
        this.trackingService.track(addMessage);
      }

      if (this.builderDataConfig().mode === BuilderMode.Edit) {
        this.trackingService.track(editMessage);

        if (!this.routeEditSessionHubService.isConnected) {
          this.uPopupService.showErrorMessage({ message: `${this.config.dictionary.errorCode}.${Errors.ServerIsDown}` });

          return;
        }
      }

      this.builderDataService.validateAndSave(saveType);
    };

    const trackingId = this.builderDataConfig().trackingId || (this.builderDataConfig().mode === BuilderMode.Add ? builderMainComponentConfig.trackingRouteAddId : builderMainComponentConfig.trackingRouteEditId);

    switch (value) {
      case BuilderSaveActionType.SaveClose: {
        saveAndTrack(BuilderAfterSaveOption.None, `[${trackingId}] - click on save & finish`, `[${trackingId}] - click on save & finish`);

        break;
      }

      case BuilderSaveActionType.SaveNew: {
        saveAndTrack(BuilderAfterSaveOption.AddNew, `[${trackingId}] - click on save & new`, `[${trackingId}] - click on save & new`);

        break;
      }

      case BuilderSaveActionType.SaveClone: {
        saveAndTrack(BuilderAfterSaveOption.Duplicate, `[${trackingId}] - click on save & duplicate`, `[${trackingId}] - copy`);

        break;
      }

      case BuilderSaveActionType.SaveReverse: {
        saveAndTrack(BuilderAfterSaveOption.Reverse, `[${trackingId}] - click on save & duplicate to other direction`, `[${trackingId}] - reverse copy`);

        break;
      }

      case BuilderSaveActionType.Close: {
        this.builderDataService.saveAndCloseSuccess(null, true);

        break;
      }

      default: {
        break;
      }
    }
  }

  activeBlockChange(name: string) {
    this.activeBlock = this.activeBlock === name ? null : name;
  }

  menuActions(data: string) {
    if (data === 'auto') {
      this.builderDataService.backToAuto();
    }
  }

  close() {
    this.builderDataService.trackEvent('click on close (X) button');

    if (this.builderDataConfig().viewMode === BuilderViewMode.Default && this.builderDataConfig().buildMode !== BuilderBuildMode.RouteSuggestions) {
      this.router.navigateByUrl(this.builderDataService.getBuildModeRedirectUrl());
    }

    this.closeRoute.emit();
    this.builderCommonService.closeAction();
  }

  remove() {
    this.trackingService.track(`[Route ${this.builderDataConfig().mode === BuilderMode.Add ? 'Add' : 'Edit'}] - click on Delete`);

    const { details } = this.addEditForm.value;

    const initialState: any = {
      routes: [ details.routeId ],
      activeRoute: {
        routeStartDate: details.dateFrom,
        routeEndDate: details.dateTo
      }
    };

    if (this.builderDataConfig().viewMode === BuilderViewMode.Full) {
      initialState.types = [ { name: 'shared.deleteRoute.period.whole', value: 1 } ];
    }

    this.deleteModalRef = this.builderModalService.show(
      DeleteRouteComponent,
      {
        class: 'u-modal u-modal_app-builder-delete-route',
        animated: true,
        initialState,
        ignoreBackdropClick: true
      },
      this.builderDataConfig().modal
    );

    this.deleteModalRef.content.action
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(actions => {
        if (actions) {
          const { type, body } = actions;

          if (type === ModalActions.Save) {
            if (this.builderDataConfig().viewMode === BuilderViewMode.Default) {
              this.builderService.deleteRoute({
                ...omit(body, [ 'routeIds' ]),
                routeId: body.routeIds[0]
              })
                .subscribe(() => this.router.navigateByUrl(this.builderDataService.getBuildModeRedirectUrl()));
            }

            if (this.builderDataConfig().viewMode === BuilderViewMode.Full) {
              this.routePlannerService.deleteRoute(details.routeId)
                .subscribe(() => {
                  this.deleteModalRef.hide();
                  this.builderRoutesStoreService.removeRoute(details.routeId);
                  this.builderCommonService.editRouteIdSet(null);
                  this.closeRoute.emit();
                });
            }
          }
        }
      });
  }

  print() {
    if (this.builderDataConfig().mode === BuilderMode.Add) {
      this.trackingService.track('[Route Add] - click on Print');
    } else {
      this.trackingService.track('[Route Edit] - click on Print');
    }

    const routeIds = [ this.builderCommonService.getEditRouteId() ];

    this.routesExportModalService.openModal(routeIds, RoutesExportType.Pdf);
  }

  selectTab({ id }, trackEvent: boolean = true) {
    if (id) {
      this.builderDataService.selectTab(id);

      if (trackEvent) {
        this.builderDataService.trackEvent(`open ${id} tab`);
      }
    }
  }

  restoreRide() {
    this.uPopupService.showMessage({
      message: 'builder.restore.message',
      yes: 'builder.close.yes',
      no: 'builder.close.no'
    },
    () => {
      this.builderDataService.restoreRide();

      this.uPopupService.showMessage({
        message: 'builder.restore.check',
        yes: 'general.ok'
      },
      () => {});
    });
  }

  onSaveRadioButtonSelect(item: RadioButton) {
    if (this.builderDataService.selectedEmailReportType === item.value) {
      this.builderDataService.setSelectedEmailReport();
    } else {
      this.builderDataService.setSelectedEmailReport(<RouteChangeReportMode>item.value);
    }

    this.builderDataService.trackEvent(builderConfig.saveActionsRadioButtonsEventsTracking[<RouteChangeReportMode>item.value]);
  }

  proceedRideOrderCreation() {
    if (this.addEditForm.invalid) {
      this.uPopupService.showErrorMessage({ message: this.dictionary.invalidForm });

      return;
    }

    const continueButtonClickedAfterBackButton = this.builderDataService.rideOrder.readOnlyMode && this.builderDataConfig().mode === BuilderMode.Edit;

    if (continueButtonClickedAfterBackButton) {
      this.builderDataService.updateRideOrderReadOnlyMode(false);
      this.builderDataService.toggleFormsState();

      this.config.rideOrderConfirmationItemProps.forEach(prop =>
        this.builderDataService.updateRideOrderConfirmationItemsProp(prop)
      );
    }

    this.trackingService.track(`[${builderMainComponentConfig.trackingRideOrders}] - Order modal - Click on 'Continue'`);

    const { routeId, quantity, locationType } = this.addEditForm.get('details').getRawValue();

    this.rideOrdersService.assignShuttleCompany({
      date: this.builderDataService.dateFormControl.value,
      routeId,
      quantity,
      locationType
    })
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        this.builderDataService.rideOrderSummaryForm.patchValue(data);
        this.builderDataService.updateRideOrderSummaryVisible(true);
      });
  }

  saveRideOrder() {
    if (this.builderDataService.rideOrder.savingProcessed) { return; }

    this.trackingService.track(`[${builderMainComponentConfig.trackingRideOrders}] - Order modal - Click on Save & Send`);

    if (this.builderDataService.rideOrderSummaryForm.invalid) {
      this.uPopupService.showErrorMessage({ message: this.dictionary.invalidForm });

      return;
    }

    this.builderDataService.saveRideOrder();
  }

  settings() {
    if (this.builderDataConfig().mode === BuilderMode.Add) {
      this.trackingService.track('[Route Add, Map tab] - click on settings');
    } else {
      this.trackingService.track('[Route Edit, Map tab] - click on settings');
    }

    this.activeBlockChange('settings');
  }

  onMenuIconClick(icon: UMenuIconStore) {
    this.scrollToSection(icon.value as BuilderSectionIconValue);
    this.updateFocusedSectionIconValue(icon.value as BuilderSectionIconValue);
  }

  updateFocusedSectionIconValue(value: BuilderSectionIconValue) {
    if (this.builderDataConfig().buildMode !== BuilderBuildMode.RideOrders && value !== this.focusedSectionIconValue) {

      this.focusedSectionIconValue = value;

      this.updateSectionIcons(value);
    }
  }

  backToOrderDetails() {
    this.builderDataService.updateRideOrderSummaryVisibleStore(false);
    this.builderDataService.updateRideOrderSummaryVisible(false);

    if (!!this.builderDataService.rideOrderHashcalRideTypesItems()?.length) {
      this.rideOrdersService.changeShuttleCompanyHashcalRideType({
        routeId: this.builderDataService.addEditForm.get('details.routeId').value,
        shuttleCompanyId: this.builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').value,
        hashcalRideType: null
      })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
  }
}
