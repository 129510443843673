import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewRef
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import {
  bufferToggle,
  debounceTime,
  delay,
  filter,
  finalize,
  first,
  map,
  mergeAll,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  windowToggle
} from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import {
  UButtonSize,
  UButtonView,
  UDaysOrderService,
  UInputCitiesCity,
  UMultiselectItem,
  UPopupService,
  USearchAppliedFilter,
  USearchFilter,
  USearchFilterType,
  USelectSItem,
  UTooltipDirective
} from '@shift/ulib';

import {
  CommonService,
  CustomerDataService,
  LocalizationService,
  TablePageService,
  TrackingService,
  LoadingDataService,
  HeaderSearchFiltersService,
  HeaderDataService,
  CitiesStoreService
} from '@app/shared/services';
import {
  CustomerDataType,
  DaysOfWeek,
  GlobalSearchFilter,
  ModalActions,
  VisibleComponent
} from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { MultiDeleteConfirmComponent } from '@app/shared/components';
import { appConfig } from '@app/shared/configs';
import { RouteLockState } from '@app/route-lock-state/models';
import { RouteLockStateService } from '@app/route-lock-state/services';
import { RouteTemplatesService } from '@app/route-templates/services';
import { RouteTemplateListItem, RouteTemplatesMode, RouteTemplatesTableRow } from '@app/route-templates/models';
import { AuthModuleName, AuthModuleRouteTemplatesFeature, AuthModuleRouteTemplatesFeatureType } from '@app/auth/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import {
  RouteDirection,
  RouteSaveStatus
} from '@app/routes/models';
import { BuilderCommonService, BuilderRoutesStoreService } from '@app/builder/services';
import { BuilderRoute, BuilderRouteStatus } from '@app/builder/models';
import { RoutesFromTemplatesComponent } from '@app/route-templates/components/routes-from-templates/routes-from-templates.component';
import { ActivityRoute } from '@app/activities/models';
import { ActivitiesDataService, ActivitiesService } from '@app/activities/services';
import { RoutesDataService } from '@app/routes/services';
import { routeTemplatesComponentConfig } from './route-templates.component.config';

@Component({
  selector: 'app-route-templates',
  templateUrl: './route-templates.component.html',
  styleUrls: [ './route-templates.component.scss', './route-templates.component.rtl.scss' ],
  providers: [ TablePageService, LoadingDataService, CitiesStoreService, AuthDataSnapshotService, RoutesDataService ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteTemplatesComponent implements OnInit, OnDestroy {
  @Input() selectedRowIds: number[] = [];
  @Input() mode: RouteTemplatesMode = RouteTemplatesMode.Default;
  @Input() gridHeaderDictionary: { select?: string; all?: string; };
  @Input() extraClass: string;
  @Input() customerId: number;

  @Output() selectedRowsChange: EventEmitter<number[]> = new EventEmitter();

  @ViewChild('emptyBodyTemplate', { static: true }) public emptyBodyTemplate: TemplateRef<any>;
  @ViewChild('routeTemplatesCodeCell', { static: true }) public routeTemplatesCodeCell: TemplateRef<any>;
  @ViewChild('routeTemplatesTimeCell', { static: true }) public routeTemplatesTimeCell: TemplateRef<any>;
  @ViewChild('routeTemplatesDirectionCell', { static: true }) public routeTemplatesDirectionCell: TemplateRef<any>;
  @ViewChild('routeTemplatesActivitiesCell', { static: true }) public routeTemplatesActivitiesCell: TemplateRef<any>;
  @ViewChild('routeTemplatesShuttleCompanyNameCell', { static: true }) public routeTemplatesShuttleCompanyNameCell: TemplateRef<any>;
  @ViewChild('routeTemplatesTimeCellFilter', { static: true }) public routeTemplatesTimeCellFilter: TemplateRef<any>;
  @ViewChild('routeTemplatesDirectionCellFilter', { static: true }) public routeTemplatesDirectionCellFilter: TemplateRef<any>;
  @ViewChild('routeTemplatesShuttleCompanyNameCellFilter', { static: true }) public routeTemplatesShuttleCompanyNameCellFilter: TemplateRef<any>;

  @HostBinding('class')
  get hostClasses() {
    return {
      'route-templates': true,
      [this.extraClass]: !!this.extraClass
    };
  }

  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly router = inject(Router);
  private readonly popupService = inject(UPopupService);
  private readonly modalService = inject(BsModalService);
  private readonly uDaysOrderService = inject(UDaysOrderService);
  private readonly trackingService = inject(TrackingService);
  private readonly commonService = inject(CommonService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly tablePageService = inject(TablePageService);
  private readonly localizationService = inject(LocalizationService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  private readonly customerDataService = inject(CustomerDataService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly activitiesService = inject(ActivitiesService);
  private readonly activitiesDataService = inject(ActivitiesDataService);
  private readonly builderCommonService = inject(BuilderCommonService);
  private readonly citiesStoreService = inject(CitiesStoreService);
  private readonly authDataService = inject(AuthDataService);
  private readonly routeLockStateService = inject(RouteLockStateService);
  private readonly routeTemplatesService = inject(RouteTemplatesService);
  private readonly routesDataService = inject(RoutesDataService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  public readonly loadingDataService = inject(LoadingDataService);

  private userId: number;
  private isDestroyed: boolean;
  private unsubscribe: Subject<void> = new Subject();
  private activitiesUpdateOn: Subject<void> = new Subject();
  private activitiesUpdateOff: Subject<void> = new Subject();

  activitiesUpdateOn$: Observable<void> = this.activitiesUpdateOn.asObservable();
  activitiesUpdateOff$: Observable<void> = this.activitiesUpdateOff.asObservable();
  direction = RouteDirection;
  cellTemplates: {[key: string]: TemplateRef<any>};
  config = cloneDeep(routeTemplatesComponentConfig.default);
  isRtl: boolean = this.localizationService.isRtl();
  customerDataItemsStore: { [key: string]: (USelectSItem | UMultiselectItem | UInputCitiesCity)[]; } = {};
  appConstants: AppConstants = AppConstants;
  showCreateNewRoutes: boolean;
  routeSaveStatus = RouteSaveStatus;
  routeLockState: RouteLockState;
  lockedByTooltipIdsClosed: string[] = [];
  disableCreateNewRoutes: boolean;
  featureType: AuthModuleRouteTemplatesFeatureType;
  uButtonSize = UButtonSize;
  uButtonView = UButtonView;
  routeTemplatesMode = RouteTemplatesMode;

  ngOnInit() {
    this.onCustomerAuth();

    if (this.mode === RouteTemplatesMode.Default) {
      this.activitiesDataService.setShowDate(false);

      this.headerDataService.updateIsDateDisabled(true);
    }
  }

  ngOnDestroy() {
    this.isDestroyed = true;

    this.headerDataService.updateIsDateDisabled(false);
    this.unsubscribe.next();
    this.unsubscribe.complete();

    if (this.router.url.replace('/', '') !== this.config.routeBuilderUrl) {
      this.builderCommonService.lastEditedRouteIdSet(null);
    }

    this.activitiesDataService.resetShowDate();
  }

  private updateSelectedRows() {
    if (this.selectedRowIds?.length && !this.tablePageService.selectedRows.length) {
      this.tablePageService.updateSelectedRows(
        this.tablePageService.rows.filter(row => this.selectedRowIds.includes(row.id))
      );
    }
  }

  private getColumns(columns) {
    if (!this.uDaysOrderService.sundayFirstDay()) {
      columns.splice(
        columns.findIndex(column => column.prop === `day-${DaysOfWeek.Saturday}`),
        0,
        cloneDeep(columns.splice(columns.findIndex(column => column.prop === `day-${DaysOfWeek.Sunday}`), 1))[0]
      );
    }

    if (this.mode === RouteTemplatesMode.Readonly) {
      return columns.filter(column => !this.config.readonlyModeHiddenColumnProps.includes(column.prop));
    }

    return columns.filter(column => !column.feature || this.authDataService.checkFeature(column.feature));
  }

  private updateConfig() {
    const config = cloneDeep(routeTemplatesComponentConfig[this.featureType] || routeTemplatesComponentConfig.default);

    this.config = {
      ...config,
      threeDotsItems: config.threeDotsItems.filter(item => item.permission ? this.authDataSnapshotService.checkPermission(item.permission) : true),
      tablePageConfig: {
        ...config.tablePageConfig,
        ...(this.mode === RouteTemplatesMode.Readonly ? {
          headerMenuIconsTemplate: null,
          gridHeaderTemplate: null,
          ignoreAppHeader: true
        } : {}),
        ...(this.gridHeaderDictionary ? { gridHeaderDictionary: this.gridHeaderDictionary } : {}),
        tableConfig: {
          ...config.tablePageConfig.tableConfig,
          ...(this.mode === RouteTemplatesMode.Readonly ? { hideColumnsFilters: true } : {}),
          columns: this.getColumns(config.tablePageConfig.tableConfig.columns),
          comparatorFns: {
            sortTimeRange: this.sortTimeRange
          }
        }
      }
    };
  }

  private onCustomerAuth() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe),
        takeWhile(() => !this.isDestroyed)
      )
      .subscribe(authUserInfo => {
        this.userId = authUserInfo.person.memberId;
        this.featureType = authUserInfo.modules[AuthModuleName.RouteTemplates][AuthModuleRouteTemplatesFeature.Type];

        this.updateConfig();
        this.setTableConfigTemplates();
        this.onGlobalSearchFilter();
        this.setGlobalFilters();
        this.onActivitiesUpdate();
      });
  }

  private onGlobalSearchFilter() {
    combineLatest([
      this.loadingDataService.refresh$,
      ...(this.mode === RouteTemplatesMode.Readonly ? [] : [
        this.headerSearchFiltersService.appliedSearch$.pipe(tap(() => this.trackEvent('search'))),
        this.headerSearchFiltersService.filters$.pipe(tap(() => this.trackEvent('click on global filter button')))
      ])
    ])
      .pipe(
        debounceTime(100),
        startWith([]),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next) || !this.loadingDataService.getLoadingValue()),
        switchMap(() => {
          this.loadingDataService.updateLoading(true);

          return this.routeTemplatesService.getAllRouteTemplates({
            ...(this.customerId ? { customerId: this.customerId } : {}),
            ...(this.mode === RouteTemplatesMode.Readonly ? {} : {
              searchText: this.headerSearchFiltersService.getSearchValue(),
              ...this.headerSearchFiltersService.getFiltersValue()
            })
          })
            .pipe(
              tap(() => this.activitiesUpdateOff.next()),
              switchMap(templates => forkJoin([ of(templates), this.activitiesService.getRouteTemplatesActivitiesState(templates.map(template => template.routeId)) ])),
              tap(([ templates, activities ]) => {
                this.tablePageService.rows = this.parseData(templates, activities);
                this.activitiesUpdateOn.next();
                this.scrollToLastEditedRouteTemplate();
                this.updateSelectedRows();
              }),
              finalize(() => this.onGetTemplatesComplete())
            );
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private getCitiesFilterLazyItems(name: string, nameToGetParams?: string): Observable<UInputCitiesCity[]> {
    return of(name)
      .pipe(
        switchMap(() => {
          const filterToGetParams = nameToGetParams && this.headerSearchFiltersService.getFilters()?.find(item => item.name === nameToGetParams);
          const params = filterToGetParams?.control?.value || [];

          return this.citiesStoreService.getCitiesBySelectedBranchIds$(params);
        }),
        tap(items => {
          this.customerDataItemsStore[name] = items;

          this.headerSearchFiltersService.updateFiltersItems({ [name]: items });
        })
      );
  }

  private generateGlobalFilterItem(appliedFilter: USearchAppliedFilter, selectedGlobalFilter: USearchFilter, filterObj: GlobalSearchFilter): USearchFilter {
    const filterType: CustomerDataType = this.config.customerDataFilters[filterObj.name];

    return {
      name: filterObj.name,
      items: appliedFilter && selectedGlobalFilter?.items || filterObj.items,
      control: new UntypedFormControl(appliedFilter && selectedGlobalFilter?.control.value || filterObj.value),
      type: filterObj.type,
      title: filterObj.title,
      lazyLoadItems: (!(appliedFilter && selectedGlobalFilter?.items) && !this.customerDataItemsStore[filterObj.name] ?
        (
          filterObj.name === 'cities' ?
            this.getCitiesFilterLazyItems(filterObj.name)
            : this.customerDataService.getCustomerData({ types: [ filterType ] })
              .pipe(
                map(data => {
                  const items = data[filterType].map(obj => ({ name: obj.name, value: obj.id }));

                  this.customerDataItemsStore[filterObj.name] = items;

                  this.headerSearchFiltersService.updateFiltersItems({ [filterObj.name]: items });

                  return items;
                })
              )
        )
        : null)
    };
  }

  private addGlobalFilterResetting(control: UntypedFormControl, resetFiltersByNames: string[] = [], filterToGetParams?: string) {
    control.valueChanges
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        const filters = (this.headerSearchFiltersService.getFilters() || []);

        resetFiltersByNames.forEach(name => {
          const currentFilter = filters.find(item => item.name === name);

          if (currentFilter) {
            currentFilter.items = [];
            currentFilter.lazyLoadItems = [ USearchFilterType.InputCitiesMultiselect, USearchFilterType.InputCitiesSelect ].includes(currentFilter.type) ?
              this.getCitiesFilterLazyItems(currentFilter.name, filterToGetParams) : null;
            currentFilter.control.patchValue([]);
          }
        });

        this.headerSearchFiltersService.updateFiltersControls(filters);
      });
  }

  private setGlobalFilters() {
    if (this.mode === RouteTemplatesMode.Readonly) { return; }

    const selectedGlobalFilters = this.headerSearchFiltersService.getFilters();
    const appliedFilters = this.headerSearchFiltersService.getAppliedFilters();
    const filters: USearchFilter[] = this.config.globalFilters
      .filter(globalFilter => globalFilter.feature ? this.authDataService.checkFeature(globalFilter.feature) : true)
      .map((globalFilter: GlobalSearchFilter) => {
        const selectedGlobalFilter = selectedGlobalFilters.find(obj => obj.name === globalFilter.name);
        const appliedFilter = appliedFilters?.find(obj => obj.name === globalFilter.name);

        const globalFilterItem = this.generateGlobalFilterItem(appliedFilter, selectedGlobalFilter, globalFilter);

        if (globalFilter.resetFiltersByNames) {
          this.addGlobalFilterResetting(globalFilterItem.control, globalFilter.resetFiltersByNames, globalFilter.name);
        }

        return globalFilterItem;
      });

    this.headerSearchFiltersService.applyFilters(filters);
  }

  private scrollToLastEditedRouteTemplate() {
    this.builderCommonService.lastEditedRouteId$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(routeId => {
        setTimeout(() => {
          const rowIndex = this.tablePageService.rows.findIndex(row => row.id === routeId);

          if (rowIndex) {
            this.tablePageService.scrollToRow(rowIndex);
            this.tablePageService.highlightRow(routeId);
          }

          if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
            this.cdRef.detectChanges();
          }
        });
      });
  }

  private setTableConfigTemplates() {
    this.config.tablePageConfig.tableConfig.cellTemplates = {
      routeTemplatesCodeCell: this.routeTemplatesCodeCell,
      routeTemplatesTimeCell: this.routeTemplatesTimeCell,
      routeTemplatesDirectionCell: this.routeTemplatesDirectionCell,
      routeTemplatesActivitiesCell: this.routeTemplatesActivitiesCell,
      routeTemplatesShuttleCompanyNameCell: this.routeTemplatesShuttleCompanyNameCell
    };

    this.config.tablePageConfig.tableConfig.filterTemplates = {
      routeTemplatesDirectionCellFilter: this.routeTemplatesDirectionCellFilter,
      routeTemplatesTimeCellFilter: this.routeTemplatesTimeCellFilter,
      routeTemplatesShuttleCompanyNameCellFilter: this.routeTemplatesShuttleCompanyNameCellFilter
    };

    this.config.tablePageConfig.tableConfig.filterTypeItemsFns = {
      timeFilterTypeItemsFn: this.timeFilterTypeItemsFn
    };

    this.config.tablePageConfig.tableConfig.filterTypeItemsSearchFns = {
      timeFilterTypeItemsSearchFn: this.timeFilterTypeItemsSearchFn
    };
  }

  private parseData(data: RouteTemplateListItem[], activities: ActivityRoute[]): RouteTemplatesTableRow[] {
    return Array.isArray(data) ? data.map(item => this.parseDataItem(item, activities.find(activity => activity.routeId === item.routeId))) : [];
  }

  private parseDataItem(item: RouteTemplateListItem, activity: ActivityRoute): RouteTemplatesTableRow {
    return {
      id: item.routeId,
      routeId: item.routeId,
      code: item.code,
      name: item.name,
      direction: item.direction,
      ...(item.rideDetails.reduce((rideDetail, obj) => {
        rideDetail[`day-${obj.day}`] = obj;
        return rideDetail;
      }, {})),
      totalPassengers: item.totalPassengers,
      shuttleCompanyName: item.shuttleCompanyName,
      carTypeName: item.carTypeName,
      rideDetails: item.rideDetails,
      locked: this.mode !== RouteTemplatesMode.Readonly && item.locked,
      saveStatus: null,
      lockState: null,
      activities: activity && activity.activities,
      customerName: item.customerName
    };
  }

  private deleteRouteTemplate(row: RouteTemplatesTableRow) {
    this.popupService.showMessage({
      message: this.config.dictionary.deleteConfirm, yes: this.config.dictionary.delete, no: this.config.dictionary.cancel
    }, () => {
      this.routeTemplatesService.deleteTemplate([ row.id ])
        .pipe(first())
        .subscribe(() => this.tablePageService.deleteRow(row.id));
    });
  }

  private duplicateRouteTemplate(row: RouteTemplatesTableRow) {
    this.trackEvent('click on Duplicate template');

    this.routeTemplatesService
      .duplicateTemplate(row.id)
      .pipe(
        switchMap(template => forkJoin([ of(template), this.activitiesService.getRouteTemplatesActivitiesState([ template.routeId ]) ])),
        first()
      )
      .subscribe(([ routeTemplate, activities ]) => {
        const newRouteTemplate = this.parseDataItem(routeTemplate, activities[0]);

        this.tablePageService.rows = [ newRouteTemplate, ...this.tablePageService.rows ];

        this.rowEditClick(newRouteTemplate);
      });
  }

  private getLocalStorageTableProps() {
    let localStorageTableProps = JSON.parse(localStorage.getItem(appConfig.storageKeys.usersTablesProps)) || {};

    if (Object.keys(localStorageTableProps).length > 0) {
      localStorageTableProps = localStorageTableProps[this.userId] && localStorageTableProps[this.userId][this.config.tablePageConfig.id];
    }

    let columnsStoreVisible = null;

    if (localStorageTableProps && localStorageTableProps.columnsStoreVisible) {
      columnsStoreVisible = [
        ...localStorageTableProps.columnsStoreVisible,
        'rideStartDateTime',
        'rideEndDateTime'
      ];

      if (localStorageTableProps.columnsStoreVisible.includes('shuttleCompanyName')) {
        columnsStoreVisible.push('shuttleCompany');
      }
    }

    return {
      columnsOrder: localStorageTableProps && localStorageTableProps.columnsOrder,
      columnsStoreVisible
    };
  }

  private onActivitiesUpdate() {
    if (this.mode === RouteTemplatesMode.Readonly) { return; }

    merge(
      this.activitiesDataService.activitiesUpdate$.pipe(bufferToggle(this.activitiesUpdateOff$, () => this.activitiesUpdateOn$)),
      this.activitiesDataService.activitiesUpdate$.pipe(windowToggle(this.activitiesUpdateOn$, () => this.activitiesUpdateOff$))
    )
      .pipe(
        takeUntil(this.unsubscribe),
        mergeAll(),
        map(activities => {
          const routeIds = this.tablePageService.rows.map(row => row.id);

          return activities.filter(activity => routeIds.includes(activity.routeId));
        }),
        filter(activities => !!activities.length),
        map(activitiesUpdate => {
          const { rows, saveStatusRouteIds } = this.routesDataService.getRouteRowsWithActivitiesUpdate(this.tablePageService.rows, activitiesUpdate);

          this.tablePageService.rows = rows;

          this.cdRef.markForCheck();

          return { saveStatusRouteIds };
        }),
        filter(data => !!data.saveStatusRouteIds.length),
        delay(2000),
        tap(({ saveStatusRouteIds }) => {
          if (saveStatusRouteIds.length) {
            this.tablePageService.rows = this.tablePageService.rows.map(route => saveStatusRouteIds.includes(route.id) ? {
              ...route,
              saveStatus: null
            } : route);
          }

          this.cdRef.markForCheck();
        })
      )
      .subscribe();

    this.activitiesUpdateOn.next();
  }

  private onGetTemplatesComplete() {
    this.loadingDataService.updateLoading(false);

    const activeFilters = this.headerSearchFiltersService.getFilters()
      .some((filterItem: GlobalSearchFilter) => filterItem.control && (filterItem.control.value === false || filterItem.control.value === 0) || filterItem.control && filterItem.control.value ? filterItem.control.value.length !== 0 : false);

    this.config.tablePageConfig.tableConfig.emptyBodyTemplate = !this.headerSearchFiltersService.getSearchValue() && !activeFilters && this.emptyBodyTemplate;
    this.cdRef.markForCheck();
  }

  private timeFilterTypeItemsFn(prop, rows) {
    return rows.map(row => row[prop] ? row[prop] : '')
      .filter((value, index, self) =>
        self.findIndex(item => item.startTime === value.startTime && item.endTime === value.endTime) === index);
  }

  private timeFilterTypeItemsSearchFn(value, searchValue) {
    return value && value.startTime === searchValue.startTime && value.endTime === searchValue.endTime;
  }

  private sortTimeRange(
    timePrev: { day: DaysOfWeek; startTime: string; endTime: string; },
    timeNext: { day: DaysOfWeek; startTime: string; endTime: string; }
  ): -1 | 1 {
    if (!timePrev) {
      return 1;
    }

    if (!timeNext) {
      return -1;
    }

    if (moment(timePrev.startTime, AppConstants.TIME_FORMAT).isBefore(moment(timeNext.startTime, AppConstants.TIME_FORMAT))) {
      return -1;
    }

    if (moment(timePrev.startTime, AppConstants.TIME_FORMAT).isAfter(moment(timeNext.startTime, AppConstants.TIME_FORMAT))) {
      return 1;
    }
  }

  openBuilderRouteTemplates() {
    this.trackEvent('click on Create new template (empty state)');
    this.router.navigate([ this.config.routeBuilderUrl ]);
  }

  selectDotsItemAction(action: string, row: RouteTemplatesTableRow) {
    switch (action) {
      case 'new-routes':
        this.createNewRoutes([ row ]);
        break;
      case 'duplicate':
        this.duplicateRouteTemplate(row);
        break;
      case 'delete':
        this.trackEvent('click on Delete template');
        this.deleteRouteTemplate(row);
        break;
    }
  }

  rowEditClick(row: RouteTemplatesTableRow) {
    const routes: BuilderRoute[] = this.tablePageService.filteredRows().map(obj => ({
      status: BuilderRouteStatus.None,
      routeId: obj.id,
      code: obj.code,
      name: obj.name,
      direction: obj.direction,
      days: obj.rideDetails.map(details => details.day),
      startDate: null,
      endDate: null,
      rideStartDateTime: moment(obj.rideDetails[0].startTime, AppConstants.TIME_FORMAT_FULL).format(AppConstants.TIME_FORMAT),
      rideEndDateTime: moment(obj.rideDetails[0].endTime, AppConstants.TIME_FORMAT_FULL).format(AppConstants.TIME_FORMAT),
      totalPassengers: obj.totalPassengers,
      carTypeName: obj.carTypeName,
      carTypeCapacity: obj.carTypeCapacity,
      shuttleCompany: obj.shuttleCompanyName,
      locked: obj.locked
    }));

    this.builderRoutesStoreService.updateGridProps({
      ...this.getLocalStorageTableProps(),
      sorts: this.tablePageService.tableSorts,
      offsetY: this.tablePageService.tableOffsetY
    });

    this.builderCommonService.lastEditedRouteIdSet(row.id);
    this.builderCommonService.editRouteIdSet(row.id);
    this.builderRoutesStoreService.routesSet(routes);

    this.router.navigate([ this.config.routeBuilderUrl ]);
  }

  deleteRouteTemplates() {
    if (this.tablePageService.selectedRows) {
      const rowIds = this.tablePageService.selectedRows.map(row => row.id);

      if (rowIds.length > 1) {
        const deleteModalRef = this.modalService.show(
          MultiDeleteConfirmComponent,
          {
            class: 'u-modal u-modal_app-multi-delete-confirm',
            animated: true,
            initialState: {
              dictionary: this.config.multiDeleteDictionary,
              itemsToDeleteAmount: this.tablePageService.selectedRows.length
            },
            ignoreBackdropClick: true
          }
        );

        deleteModalRef.content.action
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((action: ModalActions) => {
            if (action === ModalActions.Save) {
              deleteModalRef.hide();

              this.routeTemplatesService.deleteTemplate(rowIds)
                .pipe(first())
                .subscribe(() => this.tablePageService.deleteRows(rowIds));
            }
          });
      } else {
        this.deleteRouteTemplate(this.tablePageService.selectedRows[0]);
      }
    }
  }

  onSelectRows(selectedRows: RouteTemplatesTableRow[]) {
    this.showCreateNewRoutes = selectedRows.length > 1;
    this.disableCreateNewRoutes = selectedRows.some(row => row.locked);

    if (this.mode === RouteTemplatesMode.Readonly) {
      this.selectedRowsChange.emit(selectedRows.map(row => row.id));
    }
  }

  createNewRoutes(templates: RouteTemplatesTableRow[] = this.tablePageService.selectedRows) {
    this.modalService.show(
      RoutesFromTemplatesComponent,
      {
        class: 'u-modal u-modal_content u-modal_app-new-routes-from-template',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          templates,
          featureType: this.featureType
        }
      }
    );
  }

  trackEvent(event: string) {
    this.trackingService.track(`[${this.config.tablePageConfig.trackingId}] - ${event}`);
  }

  openLockedByTooltip(routeTemplate: RouteTemplatesTableRow, tooltip: UTooltipDirective) {
    if (tooltip.isOpen()) {
      return;
    }

    this.lockedByTooltipIdsClosed = this.lockedByTooltipIdsClosed.filter(tooltipId => tooltipId !== tooltip.uTooltipWindowId);

    if (routeTemplate.lockState) {
      this.routeLockState = routeTemplate.lockState;

      tooltip.open();

      return;
    }

    this.routeLockStateService.getRouteLockState(routeTemplate.id)
      .pipe(first())
      .subscribe(
        routeLockState => {
          if (this.lockedByTooltipIdsClosed.some(tooltipId => tooltipId === tooltip.uTooltipWindowId)) {
            this.lockedByTooltipIdsClosed = this.lockedByTooltipIdsClosed.filter(tooltipId => tooltipId !== tooltip.uTooltipWindowId);

            return;
          }

          this.tablePageService.rows = this.tablePageService.rows.map(obj => obj.id === routeTemplate.id ? { ...obj, lockState: routeLockState } : obj);
          this.routeLockState = routeLockState;

          tooltip.open();
        }
      );
  }

  closeLockedByTooltip(tooltip: UTooltipDirective) {
    tooltip.close();

    this.lockedByTooltipIdsClosed.push(tooltip.uTooltipWindowId);
  }

  openTemplateActivities(routeId: number) {
    this.trackEvent('click on Activities icon');

    this.commonService.updateVisibleComponent(VisibleComponent.Activities, true);
    this.activitiesDataService.loadTemplateActivity(routeId);
  }
}
