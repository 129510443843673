import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Holiday, HolidaysCalendarItem } from '@app/shared/models';
import { ApiService } from '@app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {
  private readonly apiService = inject(ApiService);

  private readonly BASE_PATH = 'Holidays';

  getCalendar(year: string): Observable<HolidaysCalendarItem[]> {
    return this.apiService.get(`${this.BASE_PATH}/Calendar`, { year });
  }

  getHolidays(startDate: string, endDate: string): Observable<Holiday[]> {
    return this.apiService.get(`${this.BASE_PATH}`, { startDate, endDate });
  }
}
