import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { pairwise } from 'rxjs/operators';
import { isEqual, cloneDeep } from 'lodash';
import * as moment from 'moment';
import { UButtonSize, URangePreset } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { TrackingService, HeaderDataService } from '@app/shared/services';
import { AuthCustomer } from '@app/auth/models';
import { RoutesChangeEmailSendType, RideTimeType, RouteDailyRow } from '@app/routes/models';
import { RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { routesChangeHourComponentConfig } from './routes-change-hour.component.config';

@Component({
  selector: 'app-routes-change-hour',
  templateUrl: './routes-change-hour.component.html',
  styleUrls: [ './routes-change-hour.component.scss', './routes-change-hour.component.rtl.scss' ]
})
export class RoutesChangeHourComponent implements OnInit {
  @Input() activeRide: RouteDailyRow;
  @Input() viewportElement: HTMLElement;
  @Input() timeType: number = RideTimeType.StartTime;
  @Input() authCustomer: AuthCustomer;
  @Input() activeDays: string[];

  @HostBinding('class') hostClasses: string = 'routes-change-hour';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly routesTableService = inject(RoutesTableService);
  private readonly routesCommonService = inject(RoutesCommonService);
  private readonly trackingService = inject(TrackingService);
  private readonly headerDataService = inject(HeaderDataService);

  config = cloneDeep(routesChangeHourComponentConfig);
  form: UntypedFormGroup;
  showSaveBtn = false;
  availablePresets: URangePreset[] = [
    URangePreset.DisplayedDay,
    URangePreset.Today,
    URangePreset.Tomorrow,
    URangePreset.ActiveWeekDay,
    URangePreset.FromTodayOn,
    URangePreset.FromCustomDayOn,
    URangePreset.Custom,
    URangePreset.UpcomingWeek,
    URangePreset.All
  ];
  startHourChange = false;
  endHourChange = false;
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  isSCCustomer: boolean;
  uButtonSize = UButtonSize;

  ngOnInit() {
    this.createForm();

    if (this.activeRide) {
      this.updateHours(this.activeRide);
      this.updateCheckDays(this.activeRide);
    }

    const startHourForm = this.form.get('startHour');
    const endHourForm = this.form.get('endHour');
    const typeTimeForm = this.form.get('timeType');
    const typeForm = this.form.get('type');

    startHourForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: any) => {
        if (value) {
          endHourForm.patchValue('');
          typeTimeForm.patchValue(RideTimeType.StartTime);
          this.showSaveBtn = true;
          this.startHourChange = true;
          this.endHourChange = false;
        }
      });

    endHourForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: any) => {
        if (value) {
          startHourForm.patchValue('');
          typeTimeForm.patchValue(RideTimeType.EndTime);
          this.showSaveBtn = true;
          this.startHourChange = false;
          this.endHourChange = true;
        }
      });

    typeTimeForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.showSaveBtn = value === 1);

    typeForm.valueChanges
      .pipe(
        pairwise(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([ previousValue, currentValue ]: [ number, number ]) => {
        this.showSaveBtn = previousValue !== currentValue;
      });
  }

  get changesForm(): UntypedFormGroup {
    return this.form.get('datesChange') as UntypedFormGroup;
  }

  createForm() {
    this.form = this.fb.group({
      routeId: [ 0 ],
      startHour: [ '' ],
      endHour: [ '' ],
      type: [ 2 , Validators.required ],
      timeType: [ this.timeType ],
      datesChange: this.fb.group({
        dates: [ [] ],
        dateFrom: [ '' ],
        dateTo: [ '' ],
        type: [ this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay ],
        availablePresets: [ this.availablePresets ],
        checkDaysActive: [ [] ],
        checkDaysAvailable: [ [] ]
      }),
      comment: [ '' ]
    });
  }

  updateHours(data: any) {
    const { rideStartDateTime, rideEndDateTime, routeId } = data;

    if (!rideStartDateTime || !rideEndDateTime) { return; }

    const startTime = moment(rideStartDateTime).format(AppConstants.TIME_FORMAT);
    const endTime = moment(rideEndDateTime).format(AppConstants.TIME_FORMAT);

    this.form.patchValue({
      routeId: routeId,
      startHour: startTime,
      endHour: endTime
    });
  }

  updateCheckDays(route: any) {
    const { routeStartDate, routeEndDate, days } = route;
    const activeDate = this.headerDataService.getActiveDate();

    this.changesForm.patchValue({
      dates: [ activeDate ],
      dateFrom: routeStartDate,
      dateTo: routeEndDate,
      checkDaysActive: [ moment(activeDate).get('day') ],
      checkDaysAvailable: days
    });
  }

  updatePeriod(data: any) {
    this.changesForm.patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });
  }

  saveChanges(emailSendType?: RoutesChangeEmailSendType) {
    const { endHour, startHour, routeId, timeType, type, comment } = this.form.value;
    const dates = this.changesForm.get('dates').value;
    const checkDaysActive = this.changesForm.get('checkDaysActive').value;
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
    const time = timeType === RideTimeType.StartTime ? startHour : endHour;
    const body = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        time,
        timeType,
        comment,
        type,
        dateFrom: moment(dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE),
        dateTo: moment(dates[dates.length - 1]).format(AppConstants.DATE_FORMAT_BASE_LINE),
        days: checkDaysActive
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routesTableService.changeTime(body)
      .subscribe(() => {
        if (this.form.controls['type'].value === 1 && (this.startHourChange || this.endHourChange)) {
          this.trackingService.track('[Track daily view change] - fixed change - hours changed');
        }

        if (this.form.controls['type'].value === 2 && (this.startHourChange || this.endHourChange)) {
          this.trackingService.track('[Track daily view change] - temp change - hours changed');
        }

        this.routesCommonService.updateChangedRoute();
      });
  }

  updateDates({ dates, checkDaysActive }): void {
    const datesStore: string[] = this.changesForm.get('dates').value;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        this.changesForm.patchValue({
          dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
          checkDaysActive
        });
      }
    }
  }
}
