export enum EnvType {
  Traffical = 'Traffical',
  Shift = 'Shift',
  IDF = 'IDF',
  UAE = 'UAE',
  ShiftNY = 'ShiftNY',
  IEC = 'IEC',
  Police = 'Police'
}

export enum EnvMetricSystemType {
  Metric = 'Metric',
  Imperial = 'Imperial'
}

export interface Env {
  production: boolean;
  name: string;
  config?: {
    serverUrlBase: string;
    applicationInsightsKey: string;
    helpLink: string;
    logoutTime: number; // minutes
    environmentType: EnvType;
    maxAmountRecordsForPage?: number;
    clarityProjectId: string;
    cobrowseLicense: string;
    googleAnalyticsKey: string;
    plantrackKey: string;
    intercomKey: string;
    dashboardRefreshTime: number; // seconds
    metricSystem: EnvMetricSystemType;
    hereMapsKey: string;
    idpAuthority: string;
  };
}
