import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusesService {
  constructor(
    private translateService: TranslateService
  ) {}

  messageByCode(code: number | string): Observable<string> {
    return this.translateService.get(`general.errorCode.${code}`);
  }
}
