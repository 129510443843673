import { createReducer, on } from '@ngrx/store';

import * as AuthActions from '@app/auth/state/actions';

export const FEATURE_KEY: string = 'countdown';

export interface State {
  counter: number;
}

export const initialState: State = {
  counter: 0
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.updateCountdown, (state, { counter }) => ({ ...state, counter }))
);
