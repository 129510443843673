import { Pipe, PipeTransform } from '@angular/core';

import { RoutesDailyApprovalsStatus } from '@app/routes/models';

@Pipe({
  name: 'routesDailyApprovalsStatus'
})
export class RoutesDailyApprovalsStatusPipe implements PipeTransform {
  transform(actualApprovals: number, expectedApprovals: number, isRejected: boolean, status: string): boolean {
    const approvalsNotNull = actualApprovals !== null && expectedApprovals !== null;
    const approvalsEqual = actualApprovals === expectedApprovals;

    const statuses = {
      [RoutesDailyApprovalsStatus.NotApproved]: approvalsNotNull && isRejected,
      [RoutesDailyApprovalsStatus.Approved]: approvalsNotNull && !isRejected && approvalsEqual,
      [RoutesDailyApprovalsStatus.PartlyApproved]: approvalsNotNull && !isRejected && !approvalsEqual
    };

    return !!statuses[status];
  }
}
