const DICTIONARY_PATH: string = 'emails.shuttleCompany';

export const emailsShuttleCompanyComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    sendConfirm: `${DICTIONARY_PATH}.sendConfirm`,
    confirmUrl: `${DICTIONARY_PATH}.confirmUrl`,
    errors: `${DICTIONARY_PATH}.errors`,
    actions: `${DICTIONARY_PATH}.actions`,
    confirm: 'general.confirm',
    successful: 'general.successful',
    yes: 'general.yes',
    no: 'general.no'
  }
};
