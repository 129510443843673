import { computed, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';

import { HeaderSearchFiltersService } from '@app/shared/services';
import { toastrConfig } from '@app/shared/configs/toastr.config';

@Injectable({
  providedIn: 'root'
})
export class LocalizedToastrService {
  readonly showHeaderFiltersDrawer = toSignal(this.headerSearchFiltersService.showHeaderFiltersDrawer$);
  readonly toastrConfig: Signal<GlobalConfig> = computed(() =>
    cloneDeep(this.showHeaderFiltersDrawer() ? toastrConfig.globalConfigWithFilters : toastrConfig.globalConfig)
  );

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private headerSearchFiltersService: HeaderSearchFiltersService
  ) {}

  show(message?: string, title?: string, override?: Partial<GlobalConfig>, type?: string) {
    this.getLocalizedText(message, title).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.show(localizedMessage, localizedTitle, { ...this.toastrConfig(), ...override }, type);
    });
  }

  success(message?: string, title?: string, override?: Partial<GlobalConfig>) {
    this.getLocalizedText(message, title).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.success(localizedMessage, localizedTitle, { ...this.toastrConfig(), ...override });
    });
  }

  error(message?: string, title?: string, override?: Partial<GlobalConfig>) {
    this.getLocalizedText(message, title).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.error(localizedMessage, localizedTitle, { ...this.toastrConfig(), ...override });
    });
  }

  info(message?: string, title?: string, override?: Partial<GlobalConfig>) {
    this.getLocalizedText(message, title).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.info(localizedMessage, localizedTitle, { ...this.toastrConfig(), ...override });
    });
  }

  warning(message?: string, title?: string, override?: Partial<GlobalConfig>) {
    this.getLocalizedText(message, title).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.warning(localizedMessage, localizedTitle, { ...this.toastrConfig(), ...override });
    });
  }

  getLocalizedText(message, title): Observable<string[]> {
    const arr: Observable<string>[] = [];

    if (message) {
      arr.push(this.translate.get(message));
    }

    if (title) {
      arr.push(this.translate.get(title));
    }

    return forkJoin(arr);
  }
}
