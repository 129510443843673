import { RoutesThreeDotsPopoverItemAction } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.weekly';

export const routesWeeklyComponentConfig = {
  dictionary: {
    tableWeekDays: `${DICTIONARY_PATH}.tableWeekDays`,
    anonymousAccompany: 'general.anonymousAccompany',
    none: 'general.none',
    edit: 'general.edit',
    view: 'general.view',
    requiredRecalculation: {
      message: 'routes.requiredRecalculation.message',
      openEditorAndSave: 'routes.requiredRecalculation.openEditorAndSave'
    }
  },
  hiddenThreeDotsPopoverItemActions: [
    RoutesThreeDotsPopoverItemAction.MovePassengers,
    RoutesThreeDotsPopoverItemAction.NewFine,
    RoutesThreeDotsPopoverItemAction.SplitRoute
  ]
};
