import { Pipe, PipeTransform } from '@angular/core';

import { Activity, ActivityPassengerChange, ActivityPassengerChangesAffectRoutesType } from '@app/activities/models';

@Pipe({
  name: 'activitiesPassengerChangeDash'
})
export class ActivitiesPassengerChangeDashPipe implements PipeTransform {
  transform(activity: Activity, passengerChange: ActivityPassengerChange): boolean {
    if (
      (
        passengerChange.type === ActivityPassengerChangesAffectRoutesType.SchoolChanged &&
        !activity.additionalData.isRouteUpdateFailed &&
        passengerChange.doesAffectRoutes
      ) ||
      !passengerChange.doesAffectRoutes
    ) {
      return true;
    }

    return false;
  }
}
