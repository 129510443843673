import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UTooltipDirective } from '@shift/ulib';
import { cloneDeep } from 'lodash';

import { BuilderPassengersStoreService } from '@app/builder/services';
import { builderStationPassengerMobileComponentConfig } from './builder-station-passenger-mobile.component.config';

@Component({
  selector: 'app-builder-station-passenger-mobile',
  templateUrl: './builder-station-passenger-mobile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderStationPassengerMobileComponent implements OnInit {
  @Input() passengerId: number;

  @ViewChild('tooltip') tooltip: UTooltipDirective;

  private readonly destroyRef = inject(DestroyRef);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly builderPassengersStoreService = inject(BuilderPassengersStoreService);
  private hovered: boolean;

  passengerMobile: string;
  config = cloneDeep(builderStationPassengerMobileComponentConfig);

  ngOnInit() {
    this.builderPassengersStoreService.mobileByPassengerId$(this.passengerId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mobile => {
        this.passengerMobile = mobile;

        this.openTooltip();

        this.cdRef.markForCheck();
      });
  }

  private openTooltip() {
    if (this.hovered && this.passengerMobile !== undefined) {
      this.tooltip.open();
    }
  }

  passengerMobileEnter() {
    this.hovered = true;

    this.openTooltip();

    this.builderPassengersStoreService.getMobileByPassengerId({ passengerId: this.passengerId });
  }

  passengerMobileLeave() {
    this.hovered = false;

    this.tooltip.close();
  }
}
