import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, inject, input, output, OnInit } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UButtonModule, UCommonModule } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { documentsComponentConfig } from './documents.component.config';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: [ './documents.component.scss', './documents.component.rtl.scss' ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ ReactiveFormsModule, CommonModule, TranslateModule, UButtonModule, UCommonModule ]
})
export class DocumentsComponent implements OnInit {
  form = input<FormArray>();

  newDocument = output<void>();
  getDocument = output<FormGroup>();
  removeDocument = output<{ documentForm: FormGroup; index: number; }>();
  removeDocumentFile = output<FormGroup>();
  uploadDocument = output<FormGroup>();

  @HostBinding('class') hostClasses: string = 'documents';

  private readonly destroyRef = inject(DestroyRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly localizationService = inject(LocalizationService);

  readonly config = cloneDeep(documentsComponentConfig);
  readonly isRtl = this.localizationService.isRtl();

  ngOnInit() {
    this.form().valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }
}
