import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { cloneDeep } from 'lodash';

import { CitiesService } from '@app/shared/services';
import { GridColumn } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class CitiesPricingTableService {
  private dictionaryPath: string;
  private dictionary: { [key: string]: string }[];

  carTypes: { name: string; id: number; }[];
  cities: { cityId: number; name: string; }[];
  citiesRows: UntypedFormArray = new UntypedFormArray([]);
  citiesForm: UntypedFormArray = new UntypedFormArray([]);
  columnsStore: GridColumn[] = [];
  citiesColumns: GridColumn[] = [];

  citiesInit: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public fb: UntypedFormBuilder,
    public translate: TranslateService,
    public citiesService: CitiesService
  ) {}

  private translateDictionary(): void {
    this.translate.get(this.dictionaryPath)
      .pipe(first())
      .subscribe(translated => this.dictionary = translated);
  }

  private setCarTypePrices(): void {
    this.citiesForm.controls.forEach((priceControl: UntypedFormGroup) => {
      priceControl.setControl('carTypePrices', this.fb.array(this.carTypes.map(carType => {
        const carForm = priceControl.value.carTypePrices.find(value => value.carTypeId === carType.id);

        return this.fb.group({
          basePrice: [ carForm ? carForm.basePrice : 0 ],
          entrancePrice: [ carForm ? carForm.entrancePrice : 0 ],
          carTypeId: [ carType.id ],
          carTypeName: [ carType.name ]
        });
      })));
    });
  }

  init(columns: GridColumn[], dictionaryPath: string) {
    this.dictionaryPath = dictionaryPath;
    this.columnsStore = cloneDeep(columns);
    this.citiesColumns = cloneDeep(columns);

    this.translateDictionary();
  }

  initCitiesTable(carTypes, citiesForm): void {
    this.carTypes = carTypes;
    this.citiesForm = citiesForm;

    this.fetchCities()
      .pipe(first())
      .subscribe(cities => {
        this.cities = cities;

        if (!this.citiesForm.value[0]) {
          this.initPriceByCityForm();
        }

        this.setCarTypePrices();
        this.setCitiesColumns();
        this.setCitiesRows();

        this.citiesInit.emit();
      });
  }

  fetchCities(): Observable<any> {
    return this.cities ? of(this.cities) : this.citiesService.getFilter()
      .pipe(map(data => data.cities.map(city => ({ cityId: city.cityId, name: city.name }))));
  }

  initPriceByCityForm(): void {
    this.cities.forEach(city => {
      this.citiesForm.push(this.fb.group({
        cityId: [ city.cityId ],
        name: [ city.name ],
        carTypePrices: this.fb.array(this.carTypes ? this.carTypes.map(carType => (this.fb.group({
          basePrice: [ 0 ],
          entrancePrice: [ 0 ],
          carTypeId: [ carType.id ],
          carTypeName: [ carType.name ]
        }))) : [])
      }));
    });
  }

  setCitiesColumns(): void {
    this.citiesColumns = cloneDeep(this.columnsStore);

    this.carTypes.forEach(carType => {
      this.citiesColumns.push({
        prop: `basePrice${carType.id}`,
        editType: 'input',
        name: `${carType.name} ${this.dictionary['base']}`
      });
      this.citiesColumns.push({
        prop: `entrancePrice${carType.id}`,
        editType: 'input',
        name: `${carType.name} ${this.dictionary['entry']}`
      });
    });
  }

  setCitiesRows(): void {
    const citiesRows = new UntypedFormArray([]);

    this.citiesForm.controls
      .forEach((cityPriceControl: UntypedFormGroup) => {
        const row = new UntypedFormGroup({ city: cityPriceControl.get('name'), cityId: cityPriceControl.get('cityId') });

        (cityPriceControl.get('carTypePrices') as UntypedFormArray).controls.forEach((carTypeControl: UntypedFormGroup) => {
          const carTypeId = carTypeControl.get('carTypeId').value;
          row.addControl(`basePrice${carTypeId}`, carTypeControl.get('basePrice'));
          row.addControl(`entrancePrice${carTypeId}`, carTypeControl.get('entrancePrice'));
        });

        citiesRows.push(row);
      });

    this.citiesRows = citiesRows;
  }
}
