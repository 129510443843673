import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';

import { TrackingService } from '@app/shared/services';
import { AuthUserInfo, AuthOptionalModule } from '@app/auth/models';
import { environment } from '@environments/environment';
import { MonitoringDashboardLegend, MonitoringDashboardRide } from '@app/monitoring-dashboard/models';
import { monitoringDashboardConfig } from '@app/monitoring-dashboard/configs';
import { monitoringDashboardInfoComponentConfig } from './monitoring-dashboard-info.component.config';

@Component({
  selector: 'app-monitoring-dashboard-info',
  templateUrl: './monitoring-dashboard-info.component.html',
  styleUrls: [ './monitoring-dashboard-info.component.scss', './monitoring-dashboard-info.component.rtl.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonitoringDashboardInfoComponent implements OnInit {
  @Input() authUserInfo: AuthUserInfo;
  @Input() ride: MonitoringDashboardRide;

  @HostBinding('class') hostClasses: string = 'monitoring-dashboard-info';

  config = cloneDeep(monitoringDashboardInfoComponentConfig.default);
  showLegends = false;

  constructor(
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.initConfig();
  }

  private getLegendsByCustomerSettings(legends: MonitoringDashboardLegend[]): MonitoringDashboardLegend[] {
    return !this.authUserInfo.optionalModules.includes(AuthOptionalModule.Supervisor) ?
      legends.filter(legend => legend.id !== 'supervisor' && legend.id !== 'supervisorMonitoring') : legends;
  }

  initConfig() {
    const config = cloneDeep(monitoringDashboardInfoComponentConfig[this.authUserInfo.customer.type] || monitoringDashboardInfoComponentConfig.default);

    config.legends = this.getLegendsByCustomerSettings(config.legends).map(legend => ({ ...legend, iconUrl: legend.icon[environment.config.environmentType] || legend.icon.default }));

    this.config = config;
  }

  toggleShowLegends() {
    this.showLegends = !this.showLegends;
  }

  track(message: string) {
    this.trackingService.track(`${monitoringDashboardConfig.trackingId} - Header - ${message}`);
  }
}
