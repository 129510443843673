import {
  ChangeDetectorRef,
  computed,
  DestroyRef,
  inject,
  Injectable,
  OnDestroy,
  Signal,
  signal,
  WritableSignal
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  first,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, escape, isEqual, uniq } from 'lodash';
import {
  UChipS,
  UDroplistRadioButtons,
  UGridService,
  UHereMapAddress,
  UHereMapMarkerConfig,
  UHereMapMarkerDragEndLocation,
  UHereMapMarkerInfo,
  UHereMapPathConfig,
  UHereMapPathType,
  UHereMapPathUpdate,
  UHereMapsRoutingMode,
  UPopupService,
  URangePreset,
  USelectSItem
} from '@shift/ulib';

import {
  CommonService,
  OperationGuidService,
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import {
  ContactType,
  DaysOfWeek,
  Errors,
  GeneralOptionsType,
  ModalActions,
  NavigationPaths,
  RoutePolicyCalculateBy,
  RoutePolicyPickUpOption,
  RadioButton
} from '@app/shared/models';
import { mapConfig } from '@app/shared/configs';
import { AppConstants } from '@app/shared/constants';
import { PassengerDetails, PassengersAddEditMode } from '@app/passengers/models';
import { PassengersDataService } from '@app/passengers/services';
import { StationsDataService } from '@app/stations/services';
import { AccompaniesDataService } from '@app/accompanies/services';
import { AccompaniesAddEditMode } from '@app/accompanies/models';
import {
  RouteChangeReportMode,
  RouteDirection,
  RoutesChangeType,
  RoutesChangeCarTypeConfirmAction
} from '@app/routes/models';
import { RouteEditSessionHubService, RoutesChangeDataService } from '@app/routes/services';
import { RoutesFacade } from '@app/routes/state/facades';
import { RideOrdersFacade } from '@app/ride-orders/state/facades';
import { RideOrdersService } from '@app/ride-orders/services';
import { GetRidesPurchaseOrder, RideOrdersShuttleCompanyCostType, RideOrderStatus } from '@app/ride-orders/models';
import {
  BuilderAccompanyOptionsType,
  BuilderActiveRide,
  BuilderActiveRideBase,
  BuilderActiveRideMonitoredPathSourceType,
  BuilderActiveRideTimeType,
  BuilderAddPassengerToExistStationSaveAction,
  BuilderAfterSaveOption,
  BuilderBuildMode,
  BuilderCalculationMode,
  BuilderEditCalculationModeSetBody,
  BuilderConfig,
  BuilderEditContactMobileBody,
  BuilderEditContactNameBody,
  BuilderEditContractSetBody,
  BuilderDataStoreOptions,
  BuilderFilterData,
  BuilderFilterDataType,
  BuilderMapRide,
  BuilderMapRideMonitoredPath,
  BuilderMode,
  BuilderPassenger,
  BuilderEditPurchaseOrderBody,
  BuilderEditPurchaseOrderRowBody,
  BuilderEditRequiresApprovalBody,
  BuilderRideOrderConfirmationItem,
  BuilderRideOrderConfirmationItemProp,
  BuilderRideOrderConfirmationItemType,
  BuilderRidesPurchaseOrder,
  BuilderRouteAddEdit,
  BuilderEditRouteTypeBody,
  BuilderSaveAction,
  BuilderSaveActionType,
  BuilderSearchItem,
  BuilderSearchItemType,
  BuilderSettings,
  BuilderStationArrivalTimeEditAction,
  BuilderStationListItemType,
  BuilderViewMode,
  BuilderActiveRideCostType,
  BuilderExtraFeeOption,
  BuilderEditChargeExtraFeeBody,
  BuilderCustomer,
  BuilderEditHashcalRideTypeBody,
  BuilderEditExecutionCostBody,
  BuilderCustomerSupervisorModuleStatus,
  BuilderEditDriverHoursBody,
  BuilderActiveRideTollRoadsCostType,
  BuilderEditTollRoadsCostBody,
  BuilderEditRegionBody
} from '@app/builder/models';
import {
  AuthModuleRouteBuilderFeatureTransportAdditionalField,
  AuthModuleRouteBuilderFeatureType,
  AuthModuleRoutesFeatureMandatoryField,
  AuthModuleSupervisorsFeatureType
} from '@app/auth/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { ShuttleCompanyRideLocationType } from '@app/shuttle-companies/models';
import { environment } from '@environments/environment';
import { builderConfig } from '@app/builder/configs';
import { BuilderDaysCopyComponent } from '@app/builder/components/builder-days-copy/builder-days-copy.component';
import { BuilderConvertToComponent } from '@app/builder/components/builder-convert-to/builder-convert-to.component';
import { BuilderDaysWeekComponent } from '@app/builder/components/builder-days-week/builder-days-week.component';
import { BuilderAskComponent } from '@app/builder/components/builder-ask/builder-ask.component';
import { BuilderAddPassengerToExistStationComponent } from '@app/builder/components/builder-add-passenger-to-exist-station/builder-add-passenger-to-exist-station.component';
import { BuilderService } from './builder.service';
import { BuilderCommonService } from './builder-common.service';
import { BuilderModalService } from './builder-modal.service';
import { BuilderPopupService } from './builder-popup.service';
import { BuilderStationService } from './builder-station.service';
import { BuilderDataStoreService } from './builder-data-store.service';
import { BuilderTuningStoreService } from './builder-tuning-store.service';
import { BuilderRoutesPassengersInfoStoreService } from './builder-routes-passengers-info-store.service';
import { BuilderRoutesStoreService } from './builder-routes-store.service';

@Injectable()
export class BuilderDataService implements OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly builderModalService = inject(BuilderModalService);
  private readonly builderService = inject(BuilderService);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly router = inject(Router);
  private readonly translateService = inject(TranslateService);
  private readonly uGridService = inject(UGridService);
  private readonly uPopupService = inject(UPopupService);
  private readonly trackingService = inject(TrackingService);
  private readonly commonService = inject(CommonService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly operationGuidService = inject(OperationGuidService);
  private readonly routeEditSessionHubService = inject(RouteEditSessionHubService);
  private readonly routesChangeDataService = inject(RoutesChangeDataService);
  private readonly stationsDataService = inject(StationsDataService);
  private readonly passengersDataService = inject(PassengersDataService);
  private readonly accompaniesDataService = inject(AccompaniesDataService);
  private readonly routesFacade = inject(RoutesFacade);
  private readonly rideOrdersFacade = inject(RideOrdersFacade);
  private readonly rideOrdersService = inject(RideOrdersService);
  private readonly authDataService = inject(AuthDataService);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  private readonly builderPopupService = inject(BuilderPopupService);
  private readonly builderDataStoreService = inject(BuilderDataStoreService);
  private readonly builderCommonService = inject(BuilderCommonService);
  private readonly builderRoutesPassengersInfoStoreService = inject(BuilderRoutesPassengersInfoStoreService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  private readonly builderTuningStoreService = inject(BuilderTuningStoreService);

  private purchaseOrders: BuilderRidesPurchaseOrder[];
  private unsubscribe: Subject<any> = new Subject();

  calculationChips: UChipS[] = [];
  addEditForm: UntypedFormGroup = this.fb.group({
    guid: [ '' ],
    settings: this.fb.group({
      waitingTime: [ null ],
      useTollRoads: [ false ],
      curbApproach: [ RoutePolicyPickUpOption.VehicleAbilities ],
      useTraffic: [ false ],
      calculateBy: [ RoutePolicyCalculateBy.Time ],
      cheapestPrice: [ false ],
      speedRate: [ 100 ]
    }),
    details: this.fb.group({
      dateFrom: [ moment().toISOString() ],
      dateTo: [ moment().toISOString() ],
      activeDays: [ 2 ],
      routeId: [ -1 ],
      name: [ '', { validators: Validators.required } ],
      isManualName: [ false ],
      number: [ null, { validators: [ Validators.required, Validators.pattern(/^\d{1,9}(\.?\d{1,5})?$/) ] } ],
      direction: [ RouteDirection.Forward, { updateOn: 'blur' } ],
      creationDate: [ { value: `${moment().format(AppConstants.DATE_FORMAT_BASE_SLASH)}`, disabled: true } ],
      customerId: [ null, { updateOn: 'blur' } ],
      customerIds: [ [], { updateOn: 'blur' } ],
      departmentId: [ null, { updateOn: 'blur' } ],
      budgetItemId: [ null, { updateOn: 'blur' } ],
      bidNumber: [ null, { validators: [ Validators.pattern(/^[a-z0-9A-Z\u0590-\u05fe]{0,29}$/) ], updateOn: 'blur' } ],
      regulationNumber: [ null, { updateOn: 'blur' } ],
      comment: [ null, { updateOn: 'blur' } ],
      regionCode: [ null, { updateOn: 'blur' } ],
      orderPurposeId: [ null ],
      quantity: [ 1 ],
      locationType: [ ShuttleCompanyRideLocationType.StartLocation ],
      routeType: [ { value: null, disabled: true }, { updateOn: 'blur' } ],
      requiresApproval: [ { value: true, disabled: true } ],
      allowEmptyStations: [ false ],
      contactName: [ '', { updateOn: 'blur' } ],
      contactMobile: [ '', { updateOn: 'blur' } ],
      branchNames: [ [] ]
    }),
    activeRide: this.fb.group({
      shuttleCompanyId: [ null ],
      contractId: [ { value: null, disabled: true } ],
      carTypeId: [ null ],
      supervisorId: [ null ],
      purchaseOrder: [ { value: null, disabled: true } ],
      purchaseOrderRow: [ { value: null, disabled: true } ],
      monitoredPaths: [ null ],
      chargeExtraFee: [ null ],
      extraFee: [ { value: null, disabled: true } ],
      hashcalRideType: [ null ],
      executionCost: [ null, { updateOn: 'blur' } ],
      driverHours: [ null ],
      cost: [ null, { updateOn: 'blur' } ],
      costType: [ BuilderActiveRideCostType.ManualPrice ],
      totalCost: [ { value: null, disabled: true } ],
      cancelled: [ false ],
      tollRoadsCost: [ { value: null, disabled: true }, { updateOn: 'blur' } ],
      tollRoadsCostType: [ BuilderActiveRideTollRoadsCostType.Manual ],
      isMainScSession: [ false ]
    }),
    requiredRecalculation: [ false ]
  });

  datesRange: any = {
    dates: [ moment().toISOString() ],
    checkDaysActive: [ moment().day() ],
    checkDaysAvailable: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ]
  };
  dateFormControl: UntypedFormControl = new UntypedFormControl();

  minDays: number = 1;
  isEditableActiveDays: boolean = true;

  daysOfWeek = [
    { value: DaysOfWeek.Sunday, disabled: true },
    { value: DaysOfWeek.Monday, disabled: true },
    { value: DaysOfWeek.Tuesday, disabled: true },
    { value: DaysOfWeek.Wednesday, disabled: true },
    { value: DaysOfWeek.Thursday, disabled: true },
    { value: DaysOfWeek.Friday, disabled: true },
    { value: DaysOfWeek.Saturday, disabled: true }
  ];

  activeDay: any;

  datesChanges: any = {
    dates: this.datesRange.dates,
    dateFrom: this.datesRange.dates[0],
    dateTo: this.datesRange.dates[this.datesRange.dates.length - 1],
    type: null,
    checkDaysActive: this.datesRange.checkDaysActive,
    checkDaysAvailable: this.datesRange.checkDaysActive
  };

  disabledDays: string[];
  datesChangeType = RoutesChangeType.Planned;

  datesDays: number[] = [];
  datesDaysAvailable: number[] = [];

  activeRide: BuilderActiveRide = {
    accompanies: null,
    passengers: null,
    cancelled: null,
    needAccompany: null,
    plannedTotalPassengers: null,
    seatsCount: null,
    totalPassengers: null,
    rideId: null,
    dayOfWeek: null,
    date: null,
    timeType: BuilderActiveRideTimeType.StartTime,
    plannedTimeType: 1,
    startDateTime: null,
    plannedStartDateTime: null,
    endDateTime: '',
    plannedEndDateTime: null,
    shuttleCompanyId: null,
    contractId: null,
    purchaseOrder: null,
    purchaseOrderRow: null,
    cost: null,
    costType: BuilderActiveRideCostType.ManualPrice,
    carTypeId: null,
    isYitActive: false,
    yitSyncMode: 0,
    plannedYitSyncMode: 0,
    yitSyncValue: null,
    rideCalculationMode: builderConfig.defaultCalculationMode,
    stations: new BuilderStationService(),
    stationsInactive: new BuilderStationService(),
    distance: null,
    duration: null,
    encodedPolylineJson: null,
    waypoints: [],
    branchId: null,
    isAnyTargetStation: false,
    monitoredPaths: null,
    vehicle: null,
    driver: null,
    carId: null,
    driverId: null,
    chargeExtraFee: false,
    hashcalRideType: null,
    executionCost: null,
    driverHours: null,
    extraFee: null,
    totalCost: null,
    tollRoadsCost: null,
    tollRoadsCostType: BuilderActiveRideTollRoadsCostType.Manual,
    isMainScSession: false
  };
  masterSubCustomerIds: number[] = [];
  isSingleDayActiveRide: boolean;

  mapRide: BuilderMapRide = new BuilderMapRide();

  modalDaysCopyRef: BsModalRef;
  modalDaysWeekRef: BsModalRef;
  modalStationRef: BsModalRef;
  modalPassengerRef: BsModalRef;
  modalConvertToRef: BsModalRef;

  editSubscribes: any = [];

  actionsEditDates = {
    date: null,
    daysActive: [],
    daysAvailable: [],
    disableDaysBefore: null,
    disableDaysAfter: null
  };

  editNameStore: any;
  editNumberStore: any;
  editBidNumberStore: string;
  editRegulationNumberStore: string;

  saveAction: BuilderSaveAction;
  saveActions: BuilderSaveAction[] = [];
  saveActionsRadioButtons: UDroplistRadioButtons = { selected: null, items: [] };

  selectedTab = 'map';

  lockedMap: boolean = true;
  routeStore: BuilderRouteAddEdit;
  mapRideAutoZoom: boolean = true;
  saveLoading: boolean;
  hasPurchaseOrderFeature: boolean;
  selectedEmailReportType: RouteChangeReportMode;

  rideOrderSummaryForm: UntypedFormGroup = this.fb.group({
    shuttleCompanyId: [ null, Validators.required ],
    costType: [ null ],
    hashcalRideType: [ null ],
    price: [ null, { updateOn: 'blur' } ],
    totalPrice: [ null ],
    purchaseOrder: [ { value: null }, Validators.required ],
    purchaseOrderRow: [ { value: null }, Validators.required ],
    status: [ null ],
    approvingComments: [ null, { updateOn: 'blur' } ],
    contractId: [ null ]
  });

  rideOrder: {
    purchaseOrders: GetRidesPurchaseOrder[];
    savingProcessed: boolean;
    summaryVisible: boolean;
    summaryVisibleStore: boolean;
    readOnlyMode: boolean;
    hasApprovingDepartment: boolean;
    hasApprovingDepartmentStore: boolean;
    hasRequestingDeparment: boolean;
    confirmationItems: BuilderRideOrderConfirmationItem[];
  } = {
      purchaseOrders: null,
      savingProcessed: false,
      summaryVisible: false,
      summaryVisibleStore: false,
      readOnlyMode: false,
      hasApprovingDepartment: false,
      hasApprovingDepartmentStore: false,
      hasRequestingDeparment: false,
      confirmationItems: [
        {
          status: RideOrderStatus.Approved,
          type: BuilderRideOrderConfirmationItemType.Green
        },
        {
          status: RideOrderStatus.Rejected,
          type: BuilderRideOrderConfirmationItemType.Red
        }
      ]
    };

  readonly #config = signal<BuilderConfig>({
    mode: BuilderMode.Add,
    viewMode: BuilderViewMode.Default,
    buildMode: BuilderBuildMode.Routes,
    modal: null
  });
  readonly #nisText = toSignal(this.translateService.get(builderConfig.dictionary.nis));
  readonly #customer: WritableSignal<BuilderCustomer> = signal(null);
  readonly #customerSupervisorModuleStatus: WritableSignal<BuilderCustomerSupervisorModuleStatus> = signal(null);
  readonly #rideOrderCostType = toSignal(this.rideOrderSummaryForm.get('costType').valueChanges
    .pipe(startWith(this.rideOrderSummaryForm.get('costType').value)));
  readonly #rideOrderShuttleCompanyCostTypes: WritableSignal<RideOrdersShuttleCompanyCostType[]> = signal([]);

  readonly config = this.#config.asReadonly();
  readonly customer = this.#customer.asReadonly();
  readonly mode = computed(() => this.config().mode);
  readonly modeAdd = computed(() => this.mode() === BuilderMode.Add);
  readonly modeEdit = computed(() => this.mode() === BuilderMode.Edit);
  readonly buildMode = computed(() => this.config().buildMode);
  readonly buildModeRoutes = computed(() => this.buildMode() === BuilderBuildMode.Routes);
  readonly buildModeRouteSuggestions = computed(() => this.buildMode() === BuilderBuildMode.RouteSuggestions);
  readonly buildModeRouteTemplates = computed(() => this.buildMode() === BuilderBuildMode.RouteTemplates);
  readonly buildModeRideOrders = computed(() => this.buildMode() === BuilderBuildMode.RideOrders);
  readonly viewMode = computed(() => this.config().viewMode);
  readonly viewModeFull = computed(() => this.viewMode() === BuilderViewMode.Full);
  readonly routePlannerMode = computed(() => this.buildModeRoutes() && this.viewModeFull());
  readonly customerIsOwnedBySc = computed(() => this.customer()?.isOwnedBySc ?? true);
  readonly customerSupervisorModuleStatus = this.#customerSupervisorModuleStatus.asReadonly();
  readonly customerSupervisorModuleStatusActive = computed(() => !!this.customerSupervisorModuleStatus()?.active);
  readonly customerSupervisorModuleStatusFeatureType = computed(() => this.customerSupervisorModuleStatus()?.featureType);
  readonly customerSupervisorModuleStatusFeatureTypeGeneric = computed(() => this.customerSupervisorModuleStatusFeatureType() === AuthModuleSupervisorsFeatureType.Generic);
  readonly customerSupervisorModuleStatusFeatureTypeCommander = computed(() => this.customerSupervisorModuleStatusFeatureType() === AuthModuleSupervisorsFeatureType.Commander);
  readonly customerIsOwnedByScAndSupervisorModuleStatusActive = computed(() => this.customerIsOwnedBySc() && this.customerSupervisorModuleStatusActive());
  readonly authUserInfo = toSignal(this.authDataService.userInfo$.pipe(take(1)));
  readonly authCustomer = computed(() => this.authUserInfo().customer);
  readonly authModules = computed(() => this.authUserInfo().modules);
  readonly routeBuilder = computed(() => this.authModules()?.routeBuilder);
  readonly routeBuilderFeatureRouteHistory = computed(() => this.routeBuilder()?.routeHistory);
  readonly routeBuilderFeatureType = computed(() => this.routeBuilder()?.type);
  readonly routeBuilderFeatureTypeGeneric = computed(() => this.routeBuilderFeatureType() === AuthModuleRouteBuilderFeatureType.Generic);
  readonly routeBuilderFeatureTypeShuttleCompany = computed(() => this.routeBuilderFeatureType() === AuthModuleRouteBuilderFeatureType.ShuttleCompany);
  readonly routeBuilderFeatureMasterCustomer = computed(() => !!this.routeBuilder()?.masterCustomer);
  readonly routeBuilderFeatureTransportAdditionalFields = computed(() => this.routeBuilder()?.transportAdditionalFields);
  readonly routeBuilderFeatureTransportAdditionalFieldsDriverHours = computed(() => this.routeBuilderFeatureTransportAdditionalFields()?.includes(AuthModuleRouteBuilderFeatureTransportAdditionalField.DriverHours));
  readonly routeBuilderAuthShuttleCompanyId = computed(() => this.routeBuilderFeatureTypeShuttleCompany() ? this.authCustomer().customerId : this.authCustomer().selfShuttleCompanyId);
  readonly isManualCostRequired = computed(() => !!this.authModules().routes?.mandatoryFields?.includes(AuthModuleRoutesFeatureMandatoryField.ManualCost));
  readonly rideOrderShuttleCompanyCostTypesItems: Signal<USelectSItem[]> = computed(() => this.#rideOrderShuttleCompanyCostTypes().map(shuttleCompanyCostType => ({ value: shuttleCompanyCostType.id, name: shuttleCompanyCostType.name })));
  readonly rideOrderHashcalRideTypesItems: Signal<USelectSItem[]> = computed(() =>
    this.#rideOrderShuttleCompanyCostTypes()
      .find(shuttleCompanyCostType => shuttleCompanyCostType.id === this.#rideOrderCostType())
      ?.hashcalRideTypes
      ?.map(hashcalRideType => ({ value: hashcalRideType.id, name: hashcalRideType.name }))
  );
  readonly assignAccompanyToRidesAllowed = computed(() => this.buildModeRouteTemplates() || (this.authDataSnapshotService.editRoutes() && this.authDataSnapshotService.assignAccompanyToRides()));
  readonly createOrEditRoutesAllowed = computed(() =>
    this.buildModeRouteTemplates() ||
    this.buildModeRideOrders() ||
    this.authDataSnapshotService.editRoutes() && this.modeEdit() ||
    this.authDataSnapshotService.createRoutes() && this.modeAdd()
  );
  readonly showEditMethod = computed(() => (this.modeAdd() && (this.buildModeRoutes() || this.buildModeRouteSuggestions())) || this.buildModeRouteTemplates());
  readonly showHistory = computed(() => this.routeBuilderFeatureRouteHistory() && this.modeEdit() && (this.buildModeRoutes() || this.buildModeRouteSuggestions()));
  readonly showFines = computed(() => this.routeBuilderFeatureTypeGeneric() && this.customerIsOwnedBySc() && this.modeEdit() && (this.buildModeRoutes() || this.buildModeRouteSuggestions()));

  readonly rideOrderHashcalRideTypesItems$ = toObservable(this.rideOrderHashcalRideTypesItems);
  readonly customer$ = toObservable(this.customer);

  constructor() {
    this.initCalculationChips();
    this.editSubscribesInit();
    this.initDetailsName();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get customerData(): any {
    return this.builderDataStoreService.customerData;
  }

  get options(): BuilderDataStoreOptions {
    return this.builderDataStoreService.options;
  }

  get isManualCostRequiredValid() {
    return !this.isManualCostRequired() ||
      this.addEditForm.get('activeRide.costType').value !== BuilderActiveRideCostType.ManualPrice ||
      !!this.addEditForm.get('activeRide.cost').value ||
      this.addEditForm.get('activeRide.costType').value === null;
  }

  get isDetailsCustomerIdValid() {
    return this.addEditForm.get('details.customerId').disabled || this.addEditForm.get('details.customerId').valid;
  }

  get isSelectedRouteBuilderAuthShuttleCompanyId() {
    return this.addEditForm.get('activeRide.shuttleCompanyId').value === this.routeBuilderAuthShuttleCompanyId();
  }

  private initCalculationChips() {
    this.calculationChips = cloneDeep(
      (this.config().buildMode === BuilderBuildMode.Routes && this.config().viewMode === BuilderViewMode.Default)
      || this.config().buildMode === BuilderBuildMode.RouteTemplates || this.config().buildMode === BuilderBuildMode.RideOrders ?
        builderConfig.calculationChipsFull : builderConfig.calculationChips);
  }

  private initDetailsName() {
    if (this.config().buildMode !== BuilderBuildMode.RouteTemplates) {
      this.isSingleDayActiveRide = moment(this.datesChanges.dateFrom).isSame(moment(this.datesChanges.dateTo), 'day');

      if (!this.isSingleDayActiveRide && !this.addEditForm.get('details.isManualName').value && this.createOrEditRoutesAllowed()) {
        this.addEditForm.get('details.name').enable();
        this.addEditForm.get('details.isManualName').patchValue(true);
        this.addEditForm.get('details.isManualName').disable({ emitEvent: false });
      }
    }

    if (!this.addEditForm.get('details.isManualName').value) {
      this.addEditForm.get('details.name').disable({ emitEvent: false });
    }
  }

  getShuttleCompanyContracts() {
    const shuttleCompanyId = this.addEditForm.get('activeRide.shuttleCompanyId').value;

    if (!shuttleCompanyId) { return; }

    this.builderService.getShuttleCompanyContracts({
      shuttleCompanyPeriods: [ {
        shuttleCompanyId,
        dateFrom: moment(this.datesChanges.dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE),
        dateTo: moment(this.datesChanges.dates[this.datesChanges.dates.length - 1]).format(AppConstants.DATE_FORMAT_BASE_LINE)
      } ]
    })
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        const shuttleCompany = data?.shuttleCompanies?.find(company =>
          company.shuttleCompanyId === shuttleCompanyId
        );

        this.builderDataStoreService.updateOptions({
          contracts: shuttleCompany?.contracts.map(contract =>
            ({ name: contract.name, value: contract.id })
          ) || []
        });
      });
  }

  updateHasPurchaseOrderFeature(value: boolean) {
    this.hasPurchaseOrderFeature = value;
  }

  getRidePurchaseOrders() {
    if (this.hasPurchaseOrderFeature) {
      const purchaseOrderForm = this.addEditForm.get('activeRide.purchaseOrder');
      const purchaseOrderRowForm = this.addEditForm.get('activeRide.purchaseOrderRow');

      if (this.addEditForm.get('activeRide.shuttleCompanyId').value && this.addEditForm.get('details.departmentId').value) {
        this.builderService.getRidesPurchaseOrders({
          routeId: this.addEditForm.get('details.routeId').value,
          period: {
            dateFrom: this.datesRange.dates[0],
            dateTo: this.datesRange.dates[this.datesRange.dates.length - 1],
            days: this.datesRange.checkDaysActive
          }
        })
          .pipe(
            take(1),
            takeUntil(this.unsubscribe)
          )
          .subscribe(orders => {
            this.purchaseOrders = orders;

            this.builderDataStoreService.updateOptions({
              purchaseOrders: orders.map(order => ({ name: order.number, value: order.number }))
            });

            if (this.createOrEditRoutesAllowed()) {
              purchaseOrderForm.enable({ emitEvent: false });
            }

            if (purchaseOrderForm.value) {
              this.setRidePurchaseOrdersRows(purchaseOrderForm.value);
            }
          });
      } else {
        purchaseOrderForm.disable({ emitEvent: false });
        purchaseOrderRowForm.disable({ emitEvent: false });
      }
    }
  }

  setRidePurchaseOrdersRows(purchaseOrder: string) {
    if (this.hasPurchaseOrderFeature && purchaseOrder) {
      this.builderDataStoreService.updateOptions({
        purchaseOrderRows: this.purchaseOrders
          .find(order => order.number === purchaseOrder)
          .rows
          .map(row => ({ name: row.number, value: row.number, subtitle: `${row.remainingBudget} ${this.#nisText()}` }))
      });
    }
  }

  private checkIfPurchaseOrderExistsInList() {
    if (this.hasPurchaseOrderFeature && !this.builderDataStoreService.options.purchaseOrders.find(order => order.value === this.addEditForm.get('activeRide.purchaseOrder').value)) {
      this.addEditForm.get('activeRide.purchaseOrder').reset(null, { emitEvent: false });
      this.resetPurchaseOrderRow();
    }
  }

  private toggleManualRouteName(value: boolean) {
    if (value) {
      this.addEditForm.get('details.name').enable();
    } else {
      this.addEditForm.get('details.name').disable();
    }
  }

  private showPopup(
    data: { message: string; yes: string; no?: string; showXIcon?: boolean; },
    callbacks?: { ok?: Function; cancel?: Function; close?: Function; }
  ) {
    this.uPopupService.showMessage(data, callbacks && callbacks.ok, callbacks && callbacks.cancel, callbacks && callbacks.close);
  }

  private resetRideOrderSummaryForm() {
    const controlsToDisable = [ 'purchaseOrder', 'purchaseOrderRow' ];

    this.rideOrderSummaryForm.reset();

    controlsToDisable.forEach(control =>
      this.rideOrderSummaryForm.get(control).disable({ emitEvent: false })
    );
  }

  private handleErrorAfterSave(error) {
    if (!error.validationResult || !error.validationResult.invalidRide) { return; }

    const invalidRide = error.validationResult.invalidRide;

    if (this.config().mode === BuilderMode.Add) {
      if (this.selectedTab === 'map') {
        this.setActiveDay(invalidRide.dayOfWeek);
      } else {
        this.activeRide.dayOfWeek = invalidRide.dayOfWeek;
        this.selectTab('map');
      }
    }

    if (this.config().mode === BuilderMode.Edit) {
      if (this.selectedTab === 'map') {
        this.actionsEditDatesChange(invalidRide.date);
      } else {
        this.activeRide.date = invalidRide.date;
        this.selectTab('map');
      }
    }

    this.builderPopupService.showMessage(
      {
        message: 'builder.messages.rideErrors',
        ok: 'general.ok'
      },
      null,
      null,
      this.config().modal
    );
  }

  private passengerUpdate(passenger) {
    this.apiTypeRide('passengerUpdate', {
      passengerId: passenger.passengerId,
      moveToNewStation: !!passenger.moveToNewStation,
      moveToNewTarget: !!passenger.moveToNewTarget,
      needAccompany: !!passenger.needAccompany,
      updateName: !!passenger.updateName
    });
  }

  private updateCalculationChips(mode: BuilderCalculationMode = this.activeRide.rideCalculationMode) {
    switch (mode) {
      case BuilderCalculationMode.Optimal: {
        this.initCalculationChips();

        break;
      }

      case BuilderCalculationMode.ManualStations: {
        this.calculationChips = this.calculationChips.map(chip => (
          {
            ...chip,
            disabled: chip.value === BuilderCalculationMode.Manual
          }
        ));

        break;
      }
      case BuilderCalculationMode.Manual: {
        this.calculationChips = this.calculationChips.map(chip => (
          {
            ...chip,
            disabled: !this.customerIsOwnedBySc() && chip.value !== BuilderCalculationMode.Manual
          }
        ));

        break;
      }
    }
  }

  private updateRideCalculationMode(value: BuilderCalculationMode) {
    this.apiTypeRide('rideCalculationMode', { rideCalculationMode: value });
  }

  private checkRideOrderReadOnlyMode() {
    const rideOrderApprovedOrRejected = [ RideOrderStatus.Approved, RideOrderStatus.Rejected ].includes(this.rideOrderSummaryForm.get('status').value);

    if (!this.rideOrder.hasApprovingDepartment && !this.rideOrder.hasRequestingDeparment || rideOrderApprovedOrRejected) {
      this.toggleFormsState(true);
      this.updateRideOrderReadOnlyMode(true);
      this.updateRideOrderConfirmationItemsProp(BuilderRideOrderConfirmationItemProp.Disabled, true);
      this.updateRideOrderConfirmationItemsProp(
        BuilderRideOrderConfirmationItemProp.Readonly,
        true,
        this.rideOrderSummaryForm.get('status').value
      );
    }
  }

  private checkRideOrderRequestingDepartmentAvailability() {
    const departments = this.builderDataStoreService.departmentsByMemberOptions;

    if (this.config().mode === BuilderMode.Edit && departments?.length) {
      this.rideOrder.hasRequestingDeparment = false;

      const requestingDepartmentId = this.addEditForm.get('details.departmentId').value;

      for (const department of departments) {
        if (this.rideOrder.hasRequestingDeparment) { break; }

        if (department.value === requestingDepartmentId) {
          this.rideOrder.hasRequestingDeparment = true;
        }
      }
    }
  }

  private getDatesRangeChangeSourceDay(): string {
    const daysByModeAndBuildMode = {
      [BuilderMode.Add]: {
        [BuilderBuildMode.RideOrders]: this.activeRide.dayOfWeek
      }
    };

    const day = daysByModeAndBuildMode[this.config().mode]?.[this.config().buildMode];

    return Number.isInteger(day) ? day : null;
  }

  checkRideOrderDepartmentsAvailability(purchaseOrders: GetRidesPurchaseOrder[], updateReadonlyMode: boolean = true) {
    const departments = this.builderDataStoreService.departmentsByMemberOptions;

    if (this.config().mode === BuilderMode.Edit && departments?.length) {
      this.checkRideOrderRequestingDepartmentAvailability();
      this.checkRideOrderApprovingDepartmentAvailability(purchaseOrders);

      if (updateReadonlyMode) {
        this.checkRideOrderReadOnlyMode();
        this.updateRideOrderHasApprovingDepartmentStore();
      }
    }
  }

  checkRideOrderApprovingDepartmentAvailability(purchaseOrders?: GetRidesPurchaseOrder[]) {
    const departments = this.builderDataStoreService.departmentsByMemberOptions;

    if (this.config().mode === BuilderMode.Edit && departments?.length) {
      this.rideOrder.hasApprovingDepartment = false;

      if (purchaseOrders) {
        this.rideOrder.purchaseOrders = purchaseOrders;
      }

      const selectedPurchaseOrder = this.rideOrder.purchaseOrders?.find(purchaseOrder =>
        purchaseOrder.number === this.rideOrderSummaryForm.get('purchaseOrder').value
      );
      const selectedRowInOrder = this.rideOrderSummaryForm.get('purchaseOrderRow').value;

      if (selectedPurchaseOrder) {
        const approvingDepartmentId = selectedPurchaseOrder.rows?.find(row => row.number === selectedRowInOrder)?.departmentId;

        if (!approvingDepartmentId) { return; }

        for (const customerDepartment of departments) {
          if (this.rideOrder.hasApprovingDepartment) { break; }

          if (customerDepartment.value === approvingDepartmentId) {
            this.rideOrder.hasApprovingDepartment = true;
          }
        }
      }
    }
  }

  updateRideOrderConfirmationItemsProp(key: BuilderRideOrderConfirmationItemProp, value?: boolean, status?: RideOrderStatus) {
    this.rideOrder.confirmationItems = this.rideOrder.confirmationItems.map(item => !status || item.status === status ? ({
      ...item,
      [key]: value
    }) : item);
  }

  updateRideOrderReadOnlyMode(value: boolean) {
    this.rideOrder.readOnlyMode = value;
  }

  updateRideOrderSummaryVisibleStore(value: boolean) {
    this.rideOrder.summaryVisibleStore = value;
  }

  updateRideOrderHasApprovingDepartment(value: boolean) {
    this.rideOrder.hasApprovingDepartment = value;
  }

  updateRideOrderHasApprovingDepartmentStore() {
    this.rideOrder.hasApprovingDepartmentStore = this.rideOrder.hasApprovingDepartment;
  }

  updateRideOrderConfirmationAndCommentsFieldsState() {
    if (this.config().mode === BuilderMode.Add) { return; }

    if (this.rideOrder.hasApprovingDepartment && this.authDataSnapshotService.approveRideOrders()) {
      this.rideOrderSummaryForm.get('status').enable({ emitEvent: false });
      this.rideOrderSummaryForm.get('approvingComments').enable({ emitEvent: false });

      this.updateRideOrderConfirmationItemsProp(BuilderRideOrderConfirmationItemProp.Disabled);

      return;
    }

    this.rideOrderSummaryForm.get('status').disable({ emitEvent: false });
    this.rideOrderSummaryForm.get('approvingComments').disable({ emitEvent: false });

    this.updateRideOrderConfirmationItemsProp(BuilderRideOrderConfirmationItemProp.Disabled, true);
  }

  toggleFormsState(disabled?: boolean) {
    if (disabled) {
      this.addEditForm.disable({ emitEvent: false });
      this.rideOrderSummaryForm.disable({ emitEvent: false });
      this.dateFormControl.disable({ emitEvent: false });

      return;
    }

    this.addEditForm.enable({ emitEvent: false });
    this.rideOrderSummaryForm.enable({ emitEvent: false });
    this.dateFormControl.enable({ emitEvent: false });
  }

  initByFeatureType() {
    if (this.buildModeRideOrders()) {
      this.addEditForm.get('details.number').disable({ emitEvent: false });
      this.addEditForm.get('details.departmentId').addValidators(Validators.required);
      this.addEditForm.get('details.regionCode').addValidators(Validators.required);
      this.addEditForm.get('details.orderPurposeId').addValidators(Validators.required);
      this.addEditForm.get('details.quantity').addValidators(Validators.required);
      this.addEditForm.get('activeRide.carTypeId').addValidators(Validators.required);
    }

    if (this.routeBuilderFeatureTypeShuttleCompany()) {
      this.saveActionsRadioButtons = { selected: null, items: [] };

      this.addEditForm.get('details.customerId').setValidators(Validators.required);
      this.addEditForm.get('details.customerId').updateValueAndValidity({ emitEvent: false });

      if (this.config().mode === BuilderMode.Edit || (this.config().mode === BuilderMode.Add && this.config().viewMode === BuilderViewMode.Full)) {
        this.addEditForm.get('details.customerId').disable({ emitEvent: false });
      }

      this.addEditForm.get('activeRide.shuttleCompanyId').disable({ emitEvent: false });
    }

    this.updateByFeatureType();
    this.updateFieldsState();
  }

  updateByFeatureType() {
    this.datesChangeType = RoutesChangeType.Planned;

    if (this.routeBuilderFeatureTypeShuttleCompany()) {
      if (this.modeEdit() && !this.customerIsOwnedBySc()) {
        this.datesChangeType = RoutesChangeType.Unplanned;
      }

      if (!this.customerIsOwnedBySc()) {
        this.saveActions = [];
      }
    }
  }

  setDisabledDays(disableDaysBefore: string, disableDaysAfter: string, activeDates: string[]) {
    const availableDays = this.generateDates(disableDaysBefore, disableDaysAfter)
      .map(date => moment(date).startOf('day').format(AppConstants.DATE_FORMAT_ISO));

    this.disabledDays = activeDates && activeDates.length
      ? availableDays.filter(availableDay => !activeDates.some(activeDay => moment(availableDay).isSame(activeDay, 'day'))) : [];
  }

  editSubscribesInit() {
    this.editSubscribes = [];

    const detailsFields = [
      'isManualName',
      'direction',
      'customerIds',
      'departmentId',
      'budgetItemId',
      'comment',
      'regionCode',
      'orderPurposeId',
      'routeType',
      'requiresApproval',
      'allowEmptyStations',
      'contactName',
      'contactMobile'
    ];

    detailsFields.forEach(el => {
      const subscribe = this.addEditForm
        .get(`details.${el}`)
        .valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(value => {
          switch (el) {
            case 'isManualName': {
              this.toggleManualRouteName(value);
              this.editName(true);

              break;
            }

            case 'orderPurposeId': {
              this.apiType(el, {
                orderPurposeId: this.addEditForm.get('details.orderPurposeId').value
              });

              break;
            }

            default: {
              this.apiType(el, { [el]: value });

              break;
            }
          }
        });

      this.editSubscribes.push(subscribe);
    });
  }

  editSubscribesDestroy() {
    this.editSubscribes.forEach(sub => sub.unsubscribe());
  }

  apiType(name: string, value?: any, changeTypeName?: string, skipRouteChanged?: boolean) {
    const params: {
      routeId: number;
      activeDate?: string;
      activeDayOfWeek?: number;
      selectedDate?: string;
      selectedDayOfWeek?: number;
      value?: any;
    } = {
      routeId: this.addEditForm.get('details.routeId').value
    };

    if (name === 'selectDay') {
      if (this.config().mode === BuilderMode.Add) {
        params['selectedDayOfWeek'] = value.selectedDayOfWeek;
      }

      if (this.config().mode === BuilderMode.Edit) {
        if (this.config().buildMode === BuilderBuildMode.Routes || this.config().buildMode === BuilderBuildMode.RouteSuggestions) {
          params['selectedDate'] = value.selectedDate;
        }

        if (this.config().buildMode === BuilderBuildMode.RouteTemplates) {
          params['selectedDayOfWeek'] = value.selectedDayOfWeek;
        }
      }
    } else {
      if (value) {
        params['value'] = value;
      }

      if (this.config().mode === BuilderMode.Add) {
        params['activeDayOfWeek'] = this.activeRide.dayOfWeek;
      }

      if (this.config().mode === BuilderMode.Edit) {
        if (this.config().buildMode === BuilderBuildMode.Routes || this.config().buildMode === BuilderBuildMode.RouteSuggestions) {
          params['activeDate'] = this.activeRide.date;
        }

        if (this.config().buildMode === BuilderBuildMode.RouteTemplates) {
          params['activeDayOfWeek'] = this.activeRide.dayOfWeek;
        }
      }
    }

    let query: any;

    switch (name) {
      case 'previousDay': { query = this.builderService.previousDay(params); break; }
      case 'nextDay': { query = this.builderService.nextDay(params); break; }
      case 'selectDay': { query = this.builderService.selectDay(params); break; }
      case 'settings': { query = this.builderService.editSettings(params); break; }
      case 'rideCalculationMode': { query = this.builderService.setCalculationMode(<BuilderEditCalculationModeSetBody>params); break; }
      case 'waypoints': { query = this.builderService.editWaypoints(params); break; }
      case 'waypointsReset': { query = this.builderService.editWaypointsReset(params); break; }
      case 'direction': { query = this.builderService.editDirection(params); break; }
      case 'name': { query = this.builderService.editName(params); break; }
      case 'period': { query = this.builderService.editPeriod(params); break; }
      case 'number': { query = this.builderService.editNumber(params); break; }
      case 'routeType': { query = this.builderService.editRouteType(params as BuilderEditRouteTypeBody); break; }
      case 'requiresApproval': { query = this.builderService.editRequiresApproval(params as BuilderEditRequiresApprovalBody); break; }
      case 'customerIds': { query = this.builderService.editCustomers(params); break; }
      case 'departmentId': { query = this.builderService.editDepartment(params); break; }
      case 'regionCode': { query = this.builderService.editRegion(params as BuilderEditRegionBody); break; }
      case 'orderPurposeId': { query = this.builderService.editOrderPurpose(params); break; }
      case 'budgetItemId': { query = this.builderService.editBudgetItem(params); break; }
      case 'comment': { query = this.builderService.editComment(params); break; }
      case 'time': { query = this.builderService.editTime(params); break; }
      case 'shuttleCompany': { query = this.builderService.editShuttleCompany(params); break; }
      case 'cost': { query = this.builderService.editCost(params); break; }
      case 'carType': { query = this.builderService.editCarType(params); break; }
      case 'car': { query = this.builderService.editCar(params); break; }
      case 'driver': { query = this.builderService.editDriver(params); break; }
      case 'yit': { query = this.builderService.editYit(params); break; }
      case 'filtersPassengers': { query = this.builderService.editFiltersPassengers(params); break; }
      case 'filtersStations': { query = this.builderService.editFiltersStations(params); break; }
      case 'purchaseOrder': { query = this.builderService.editPurchaseOrder(params as BuilderEditPurchaseOrderBody); break; }
      case 'purchaseOrderRow': { query = this.builderService.editPurchaseOrderRow(params as BuilderEditPurchaseOrderRowBody); break; }
      case 'passengerAdd': { query = this.builderService.editPassengerAdd(params); break; }
      case 'passengerAddToStation': { query = this.builderService.editPassengerAddToStation(params); break; }
      case 'passengerRemove': { query = this.builderService.editPassengerRemove(params); break; }
      case 'passengerMoveToStation': { query = this.builderService.editPassengerMoveToStation(params); break; }
      case 'passengerAddAnonymous': { query = this.builderService.editPassengerAddAnonymous(params); break; }
      case 'passengerRemoveAnonymous': { query = this.builderService.editPassengerRemoveAnonymous(params); break; }
      case 'passengerAmount': { query = this.builderService.editPassengerAmount(params); break; }
      case 'passengerTarget': { query = this.builderService.editPassengerTarget(params); break; }
      case 'passengerMoveToFirstStation': { query = this.builderService.editPassengerMoveToFirstStation(params); break; }
      case 'passengerMoveToLastStation': { query = this.builderService.editPassengerMoveToLastStation(params); break; }
      case 'passengerSupervisorAdd': { query = this.builderService.editPassengerSupervisorAdd(params); break; }
      case 'passengerSupervisorSet': { query = this.builderService.editPassengerSupervisorSet(params); break; }
      case 'passengerSupervisorReset': { query = this.builderService.editPassengerSupervisorReset(params); break; }
      case 'passengerSupervisorRemove': { query = this.builderService.editPassengerSupervisorRemove(params); break; }
      case 'passengerUpdate': { query = this.builderService.editPassengerUpdate(params); break; }
      case 'stationAddSaved': { query = this.builderService.editStationAddSaved(params); break; }
      case 'stationAddBranch': { query = this.builderService.editStationAddBranch(params); break; }
      case 'stationAddByAddress': { query = this.builderService.editStationAddByAddress(params); break; }
      case 'stationAddCustomer': { query = this.builderService.editStationAddCustomer(params); break; }
      case 'stationRemove': { query = this.builderService.editStationRemove(params); break; }
      case 'stationRemoveTarget': { query = this.builderService.editStationRemoveTarget(params); break; }
      case 'stationName': { query = this.builderService.editStationName(params); break; }
      case 'stationAddress': { query = this.builderService.editStationAddress(params); break; }
      case 'stationSetFirst': { query = this.builderService.editStationSetFirst(params); break; }
      case 'stationSetLast': { query = this.builderService.editStationSetLast(params); break; }
      case 'stationSetTarget': { query = this.builderService.editStationSetTarget(params); break; }
      case 'stationSetNonTarget': { query = this.builderService.editStationSetNonTarget(params); break; }
      case 'stationOrder': { query = this.builderService.editStationOrder(params); break; }
      case 'stationEditArrivalTime': { query = this.builderService.editStationArrivalTime(params); break; }
      case 'accompanySet': { query = this.builderService.editAccompanySet(params); break; }
      case 'accompanySetRequired': { query = this.builderService.editAccompanySetRequired(params); break; }
      case 'accompanyReset': { query = this.builderService.editAccompanyReset(params); break; }
      case 'accompanyCost': { query = this.builderService.editAccompanyCost(params); break; }
      case 'accompanyDestination': { query = this.builderService.editAccompanyDestination(params); break; }
      case 'accompanySource': { query = this.builderService.editAccompanySource(params); break; }
      case 'restoreRide': { query = this.builderService.editRestoreRide(params); break; }
      case 'bidNumber': { query = this.builderService.editBidNumber(params); break; }
      case 'regulationNumber': { query = this.builderService.editRegulationNumber(params); break; }
      case 'allowEmptyStations': { query = this.builderService.editAllowEmptyStations(params); break; }
      case 'contractSet': { query = this.builderService.editContractSet(params as BuilderEditContractSetBody); break; }
      case 'contractRemove': { query = this.builderService.editContractRemove(params as BuilderEditContractSetBody); break; }
      case 'contactName': { query = this.builderService.editContactName(params as BuilderEditContactNameBody); break; }
      case 'contactMobile': { query = this.builderService.editContactMobile(params as BuilderEditContactMobileBody); break; }
      case 'chargeExtraFee': { query = this.builderService.editChargeExtraFee(params as BuilderEditChargeExtraFeeBody); break; }
      case 'hashcalRideType': { query = this.builderService.editHashcalRideType(params as BuilderEditHashcalRideTypeBody); break; }
      case 'executionCost': { query = this.builderService.editExecutionCost(params as BuilderEditExecutionCostBody); break; }
      case 'driverHours': { query = this.builderService.editDriverHours(params as BuilderEditDriverHoursBody); break; }
      case 'tollRoadsCost': { query = this.builderService.editTollRoadsCost(params as BuilderEditTollRoadsCostBody); break; }
      default: { break; }
    }

    const notEditChangesTypes: string[] = [
      'previousDay',
      'nextDay',
      'selectDay'
    ];

    if (!notEditChangesTypes.includes(name) && !skipRouteChanged) {
      this.builderCommonService.editRouteChangedSet(true);
    }

    const updateActiveRideFullTypes: string[] = [
      'name',
      'direction',
      'previousDay',
      'nextDay',
      'selectDay',
      'settings',
      'time',
      'period',
      'filtersPassengers',
      'filtersStations',
      'passengerAdd',
      'passengerAddToStation',
      'passengerAddAnonymous',
      'passengerRemove',
      'passengerMoveToStation',
      'passengerRemoveAnonymous',
      'passengerAmount',
      'passengerTarget',
      'passengerMoveToFirstStation',
      'passengerMoveToLastStation',
      'passengerSupervisorAdd',
      'passengerSupervisorSet',
      'passengerSupervisorReset',
      'passengerSupervisorRemove',
      'passengerUpdate',
      'stationAddSaved',
      'stationAddBranch',
      'stationAddByAddress',
      'stationAddCustomer',
      'stationRemove',
      'stationRemoveTarget',
      'stationSetFirst',
      'stationSetLast',
      'stationSetTarget',
      'stationSetNonTarget',
      'stationOrder',
      'stationName',
      'stationAddress',
      'stationEditArrivalTime',
      'accompanySet',
      'accompanySetRequired',
      'accompanyReset',
      'accompanyDestination',
      'accompanySource',
      'waypoints',
      'rideCalculationMode',
      'restoreRide',
      'shuttleCompany',
      'carType',
      'car',
      'driver',
      'contractSet',
      'contractRemove',
      'routeType'
    ];

    query
      .pipe(
        first(),
        tap(() => {
          if (!notEditChangesTypes.includes(name)) {
            if (this.config().mode === BuilderMode.Add) {
              this.setActiveDay(this.activeRide.dayOfWeek, name);
            } else {
              this.actionsEditDatesChange(this.activeRide.date, name);
            }

            this.builderRoutesPassengersInfoStoreService.clearInfo(this.addEditForm.get('details.routeId').value);
          }
        }),
        filter(data => !!data && !!Object.keys(data).length)
      )
      .subscribe(
        (data: BuilderRouteAddEdit) => {
          const { activeRide, details, settings } = data;

          if (activeRide && details && settings) {
            this.routeStore = cloneDeep(data);

            this.editNameStore = details.name;
            this.editNumberStore = details.number;
            this.editBidNumberStore = details.bidNumber;
            this.editRegulationNumberStore = details.regulationNumber;

            this.mapRide.autoZoom = ![ 'stationAddress', 'waypoints' ].includes(changeTypeName) || this.mapRideAutoZoom;

            if (updateActiveRideFullTypes.includes(changeTypeName || name)) {
              this.updateActiveRideFull(activeRide, details, settings);
            } else {
              this.updateActiveRide(activeRide);
            }

            switch (changeTypeName) {
              case 'accompanyCost': {
                this.activeRide.stations.stationAccExpandSync(activeRide);

                break;
              }

              case 'rideCalculationMode':
              case 'stationOrder':
              case 'stationEditArrivalTime':
              case 'waypoints': {
                this.updateCalculationChips();

                break;
              }

              case 'passengerSupervisorSet': {
                this.updateCustomerDataSupervisors();

                break;
              }

              case 'shuttleCompany': {
                if (this.config().buildMode !== BuilderBuildMode.RouteTemplates) {
                  this.getShuttleCompanyContracts();
                }

                this.getRidePurchaseOrders();
                this.resetPurchaseOrderRow();

                break;
              }

              case 'period': {
                this.initDetailsName();
                this.getRidePurchaseOrders();
                this.resetPurchaseOrderRow();

                break;
              }

              case 'departmentId': {
                this.getRidePurchaseOrders();
                this.resetPurchaseOrderRow();

                break;
              }
            }

            const selectDayTypes = [ 'previousDay', 'nextDay', 'selectDay' ];

            if (this.config().mode === BuilderMode.Add && selectDayTypes.includes(changeTypeName || name)) {
              const checkDatesDay = this.datesDays.some((el: number) => el === activeRide.dayOfWeek);

              if (!checkDatesDay) {
                this.datesDays = [ activeRide.dayOfWeek ];
              }
            }

            const [ firstDay ] = details.activeDays;
            const updateActiveRouteByDay = firstDay === activeRide.dayOfWeek || (value && value.days && value.days.includes(firstDay));
            const updateActiveRouteByDate = params && params.selectedDate && moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('day').isSame(moment(params.selectedDate).startOf('day'));

            if (
              (this.config().buildMode === BuilderBuildMode.RouteTemplates || (this.config().buildMode === BuilderBuildMode.Routes && this.config().mode === BuilderMode.Add)) && updateActiveRouteByDay ||
              (this.config().buildMode === BuilderBuildMode.Routes && this.config().mode === BuilderMode.Edit && updateActiveRouteByDate)
            ) {
              switch (changeTypeName) {
                case 'carType': {
                  const carType = this.options.carTypes.find(ob => ob.value === activeRide.carTypeId);

                  this.builderRoutesStoreService.routeActiveUpdate({
                    carTypeName: carType ? carType.name : null,
                    carTypeCapacity: activeRide.seatsCount || null
                  });

                  break;
                }

                case 'shuttleCompany': {
                  const shuttleCompany = this.options.shuttleCompanies.find(ob => ob.value === activeRide.shuttleCompanyId);
                  const carType = this.options.carTypes.find(ob => ob.value === activeRide.carTypeId);

                  this.builderRoutesStoreService.routeActiveUpdate({
                    shuttleCompany: shuttleCompany ? shuttleCompany.name : null,
                    carTypeName: carType ? carType.name : null,
                    carTypeCapacity: activeRide.seatsCount || null
                  });

                  break;
                }

                default: {
                  break;
                }
              }

              const routeActive = this.builderRoutesStoreService.activeRoute();

              if (routeActive) {
                const totalPassengers = activeRide.totalPassengers;
                const rideStartDateTime = moment(activeRide.startDateTime).format(AppConstants.TIME_FORMAT);
                const rideEndDateTime = moment(activeRide.endDateTime).format(AppConstants.TIME_FORMAT);
                const routeActiveUpdate: {
                  totalStations?: number;
                  totalPassengers?: number;
                  rideStartDateTime?: string;
                  rideEndDateTime?: string;
                } = {};

                if (routeActive.totalStations !== activeRide.totalStations) {
                  routeActiveUpdate.totalStations = activeRide.totalStations;
                }

                if (routeActive.totalPassengers !== totalPassengers) {
                  routeActiveUpdate.totalPassengers = totalPassengers;
                }

                if (routeActive.rideStartDateTime !== rideStartDateTime || routeActive.rideEndDateTime !== rideEndDateTime) {
                  routeActiveUpdate.rideStartDateTime = rideStartDateTime;
                  routeActiveUpdate.rideEndDateTime = rideEndDateTime;
                }

                if (Object.keys(routeActiveUpdate).length) {
                  this.builderRoutesStoreService.routeActiveUpdate(routeActiveUpdate);
                }
              }
            }

            this.builderCommonService.updateActiveRouteData(data);

            if (this.config().buildMode === BuilderBuildMode.RideOrders && this.rideOrder.summaryVisible) {
              this.updateRideOrderSummaryVisible(false);
              this.resetRideOrderSummaryForm();
            }
          }
        },
        error => {
          if ([ Errors.VehicleSubceedsCarTypeCapacity, Errors.CarTypeExceedsVehicleCapacity ].includes(error.code)) {
            this.routesChangeDataService.changeCarTypeConfirm(error.description, builderConfig.trackingId)
              .pipe(
                first()
              )
              .subscribe(action => {
                if (action === RoutesChangeCarTypeConfirmAction.Ok) {
                  this.apiType(changeTypeName || name, {
                    ...value,
                    [ error.code === Errors.CarTypeExceedsVehicleCapacity ? 'allowExceedVehicleCapacity' : 'allowSubceedCarTypeCapacity' ]: true
                  });
                } else if (this.routeStore) {
                  this.updateRoute(this.routeStore, true);
                }
              });
          } else if ([ Errors.RideStartInLessThanTwoHours ].includes(error.code)) {
            this.rideStartInLessThanTwoHoursConfirm(error.description)
              .subscribe(applyLateChange => {
                if (applyLateChange) {
                  this.apiType(name, { ...value, applyLateChange });
                } else {
                  this.updateRoute(this.routeStore, true);
                }
              });
          } else if (this.routeStore) {
            this.updateRoute(this.routeStore, true);
          }
        }
      );
  }

  apiTypeRide(name: string, value?: any) {
    const activeDay = moment().day(this.activeDay).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const params = {
      type: this.datesChangeType,
      dateFrom: moment(this.datesChanges.dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE),
      dateTo: moment(this.datesChanges.dates[this.datesChanges.dates.length - 1]).format(AppConstants.DATE_FORMAT_BASE_LINE),
      days: this.showEditMethod() ? this.isEditableActiveDays ? this.datesDays : [ moment(activeDay).day() ] : this.datesChanges.checkDaysActive,
      ...value
    };

    return this.apiType(name, params);
  }

  editName(forced?: boolean) {
    const detailsNameForm = this.addEditForm.get('details.name');

    if (forced || detailsNameForm.valid && this.editNameStore !== detailsNameForm.value) {
      const params = {
        name: detailsNameForm.value,
        isAutoGeneratedName: !this.addEditForm.get('details.isManualName').value
      };

      this.apiType('name', params);
    }
  }

  editNumber() {
    const detailsNumberForm = this.addEditForm.get('details.number');

    if (detailsNumberForm.valid && this.editNumberStore !== Number(detailsNumberForm.value)) {
      const params = {
        number: detailsNumberForm.value
      };

      this.apiType('number', params);
    }
  }

  editPurchaseOrder() {
    const purchaseOrderForm = this.addEditForm.get('activeRide.purchaseOrder');

    if (purchaseOrderForm.valid) {
      const params = {
        purchaseOrder: purchaseOrderForm.value
      };

      this.apiTypeRide('purchaseOrder', params);
    }
  }

  editPurchaseOrderRow() {
    const purchaseOrderRowForm = this.addEditForm.get('activeRide.purchaseOrderRow');

    if (purchaseOrderRowForm.valid) {
      const params = {
        purchaseOrderRow: purchaseOrderRowForm.value
      };

      this.apiTypeRide('purchaseOrderRow', params);
    }
  }

  resetPurchaseOrderRow() {
    if (this.hasPurchaseOrderFeature && !this.addEditForm.get('activeRide.purchaseOrder').value) {
      this.addEditForm.get('activeRide.purchaseOrderRow').reset();
      this.addEditForm.get('activeRide.purchaseOrderRow').disable({ emitEvent: false });
    }
  }

  editBidNumber() {
    const detailsBidNumberForm = this.addEditForm.get('details.bidNumber');

    if (detailsBidNumberForm.valid && this.editBidNumberStore !== detailsBidNumberForm.value) {
      this.apiType('bidNumber', {
        bidNumber: detailsBidNumberForm.value
      });
    }
  }

  editRegulationNumber() {
    const detailsRegulationNumberForm = this.addEditForm.get('details.regulationNumber');

    if (detailsRegulationNumberForm.valid && this.editRegulationNumberStore !== detailsRegulationNumberForm.value) {
      this.apiType('regulationNumber', {
        regulationNumber: detailsRegulationNumberForm.value
      });
    }
  }

  editStartTime() {
    const params = {
      time: this.activeRide.startDateTime,
      timeType: BuilderActiveRideTimeType.StartTime
    };

    this.apiTypeRide('time', params);
  }

  editEndTime() {
    const params = {
      time: this.activeRide.endDateTime,
      timeType: BuilderActiveRideTimeType.EndTime
    };

    this.apiTypeRide('time', params);
  }

  editShuttleCompany() {
    const params = {
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value
    };

    this.apiTypeRide('shuttleCompany', params);
  }

  editCost() {
    const { cost, costType, shuttleCompanyId } = this.addEditForm.get('activeRide').getRawValue();

    this.apiTypeRide(
      'cost',
      {
        cost,
        costType,
        shuttleCompanyId
      }
    );
  }

  editTollRoadsCost() {
    const { tollRoadsCost, tollRoadsCostType, shuttleCompanyId } = this.addEditForm.get('activeRide').getRawValue();

    this.apiTypeRide(
      'tollRoadsCost',
      {
        shuttleCompanyId,
        tollRoadsCost,
        tollRoadsCostType
      }
    );
  }

  editCarType() {
    const params = {
      carTypeId: this.addEditForm.get('activeRide.carTypeId').value,
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value
    };

    this.apiTypeRide('carType', params);
  }

  editCar() {
    const params = {
      carId: this.activeRide.carId,
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value
    };

    this.apiTypeRide('car', params);
  }

  editDriver() {
    const params = {
      driverId: this.activeRide.driverId,
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value
    };

    this.apiTypeRide('driver', params);
  }

  editYit() {
    const params = {
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value,
      active: this.activeRide.isYitActive,
      syncMode: this.activeRide.yitSyncMode,
      syncValue: this.activeRide.yitSyncValue
    };

    this.apiTypeRide('yit', params);
  }

  editContractSet() {
    this.apiTypeRide('contractSet', {
      contractId: this.addEditForm.get('activeRide.contractId').value
    });
  }

  editContractRemove(contractId: string) {
    this.apiTypeRide('contractRemove', {
      contractId
    });
  }

  editChargeExtraFee() {
    const params = {
      chargeExtraFee: this.addEditForm.get('activeRide.chargeExtraFee').value,
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value
    };

    this.apiTypeRide('chargeExtraFee', params);
  }

  editHashcalRideType() {
    const params = {
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value,
      hashcalRideType: this.addEditForm.get('activeRide.hashcalRideType').value
    };

    this.apiTypeRide('hashcalRideType', params);
  }

  editExecutionCost() {
    this.apiTypeRide('executionCost', {
      executionCost: this.addEditForm.get('activeRide.executionCost').value
    });
  }

  editDriverHours() {
    const params = {
      shuttleCompanyId: this.addEditForm.get('activeRide.shuttleCompanyId').value,
      driverHours: this.addEditForm.get('activeRide.driverHours').value
    };

    this.apiTypeRide('driverHours', params);
  }

  setConfig(param: string, data: any) {
    this.#config.set({
      ...this.config(),
      [param]: data
    });

    if (param === 'buildMode' || param === 'mode') {
      this.updateBuildModeData();
    }
  }

  filterSaveActions(value: BuilderSaveActionType, condition: boolean) {
    this.saveActions = this.saveActions.filter(btn => btn.value !== value || condition);
  }

  updateBuildModeData() {
    this.saveAction = cloneDeep(builderConfig.buildMode[this.config().buildMode][!this.createOrEditRoutesAllowed() && this.authDataSnapshotService.deleteRoutes() ? 'closeAction' : 'saveAction' ]);
    this.saveActions = cloneDeep(builderConfig.buildMode[this.config().buildMode].saveActions)
      .filter(saveAction =>
        (saveAction.feature ? this.authDataService.checkFeature(saveAction.feature) : true) &&
        (saveAction.permission ? this.authDataSnapshotService.checkPermission(saveAction.permission) : true)
      );

    if (this.saveActions?.length === 1) {
      this.saveActions = this.saveActions.map(saveAction => saveAction.underlined ? { ...saveAction, underlined: false } : saveAction);
    }

    this.saveActionsRadioButtons = {
      selected: null,
      items: cloneDeep(builderConfig.buildMode[this.config().buildMode].saveActionsRadioButtons)
        .filter((item: RadioButton) => this.authDataSnapshotService.checkPermissionGroup(item.permissionGroup))
    };
    this.isEditableActiveDays = builderConfig.buildMode[this.config().buildMode].isEditableActiveDays;
  }

  updateDatesDaysAvailable(dates: string[]) {
    this.datesDaysAvailable = this.generateDates(dates[0], dates[dates.length - 1])
      .map(date => moment(date).startOf('day').day())
      .filter((day, index, days) => days.indexOf(day) === index);
  }

  datesRangeChange(value: { dates: string[]; checkDaysActive: number[]; checkDaysAvailable: number[]; }, updateFromTemplate?: boolean) {
    const data = { ...value };
    const isSingleSelectedDate = moment(data.dates[0]).startOf('day').isSame(moment(data.dates[data.dates.length - 1]).startOf('day'));
    const isSaturdayActive = this.datesDays.some(day => day === DaysOfWeek.Saturday);
    const isRangeExtended = moment(data.dates[0]).startOf('day').isBefore(moment(this.datesRange.dates[0]).startOf('day')) ||
      moment(data.dates[data.dates.length - 1]).startOf('day').isAfter(moment(this.datesRange.dates[this.datesRange.dates.length - 1]).startOf('day'));

    if (updateFromTemplate && (this.config().buildMode === BuilderBuildMode.Routes || this.config().buildMode === BuilderBuildMode.RouteSuggestions)) {
      data.checkDaysActive = data.checkDaysActive.filter(day => isSingleSelectedDate || isSaturdayActive || day !== DaysOfWeek.Saturday);

      this.datesDays = data.checkDaysActive;
    }

    const checkedDaysNew = data.checkDaysActive.filter((elDay: number) => !this.datesRange.checkDaysActive.some((el: number) => el === elDay));
    const checkedDaysBefore = data.checkDaysActive.filter((elDay: number) => !checkedDaysNew.some((el: number) => el === elDay));

    this.datesRange = {
      dates: data.dates,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    };

    this.datesChanges.dates = this.datesRange.dates;
    this.datesChanges.dateFrom = this.datesRange.dates[0];
    this.datesChanges.dateTo = this.datesRange.dates[this.datesRange.dates.length - 1];
    this.datesChanges.checkDaysActive = data.checkDaysActive;
    this.datesChanges.checkDaysAvailable = data.checkDaysActive;

    this.activeDaysOfWeek(data.checkDaysActive);
    this.updateDatesDaysAvailable(this.datesRange.dates);

    let params = {
      sourceDay: this.getDatesRangeChangeSourceDay(),
      copyToNewDates: false,
      dateFrom: this.datesRange.dates[0],
      dateTo: this.datesRange.dates[this.datesRange.dates.length - 1],
      days: this.datesRange.checkDaysActive
    };

    const periodSend = () => {
      if (this.config().mode === BuilderMode.Edit) {
        if (checkedDaysBefore && checkedDaysBefore.length === 1 && params.days.includes(this.activeRide.dayOfWeek)) {
          this.apiType('period', { ...params, sourceDay: this.activeRide.dayOfWeek, copyToNewDates: true });
        } else if ((checkedDaysNew && checkedDaysNew.length && checkedDaysBefore && checkedDaysBefore.length && checkedDaysBefore.length > 1) || isRangeExtended) {
          if (this.config().buildMode === BuilderBuildMode.Routes || this.config().buildMode === BuilderBuildMode.RouteSuggestions) {
            this.modalDaysWeek(params);
          } else {
            this.apiType('period', { ...params, copyToNewDates: params.sourceDay !== null });
          }
        } else {
          this.apiType('period', params);
        }
      } else {
        this.apiType('period', checkedDaysBefore && checkedDaysBefore.length === 1 && params.days.includes(this.activeRide.dayOfWeek) ? { ...params, sourceDay: this.activeRide.dayOfWeek, copyToNewDates: true } : params);
      }
    };

    if (
      checkedDaysNew &&
      checkedDaysNew.length &&
      checkedDaysBefore &&
      checkedDaysBefore.length &&
      checkedDaysBefore.length > 1
    ) {
      this.modalDaysCopyRef = this.builderModalService.show(
        BuilderDaysCopyComponent,
        {
          class: 'u-modal u-modal_app-builder-popup-rounded',
          animated: true,
          initialState: {
            dayActive: checkedDaysBefore[0],
            daysAvailable: checkedDaysBefore,
            sundayFirstDay: true
          },
          ignoreBackdropClick: true,
          keyboard: false
        },
        this.config().modal
      );

      this.modalDaysCopyRef
        .content
        .action
        .subscribe((action: any) => {
          const { dayActive, checkboxes } = action.content;
          const duplicate = checkboxes.find((ob: any) => ob.value === 'duplicate');

          params = {
            ...params,
            sourceDay: duplicate.check ? dayActive : null
          };

          periodSend();
        });
    } else {
      periodSend();
    }
  }

  datesDaysChange() {
    this.datesRangeChange({
      ...this.datesRange,
      checkDaysActive: this.datesDays
    });
  }

  activeDaysOfWeek(activeDays) {
    this.daysOfWeek.forEach((day: any) => {
      day.disabled = true;
    });

    activeDays.forEach((obDay: any) => {
      this.daysOfWeek.forEach((ob: any) => {
        if (obDay === ob.value) {
          ob.disabled = false;
        }
      });
    });
  }

  setDay() {
    this.daysOfWeek.forEach((ob: any) => {
      if (this.activeRide.dayOfWeek === ob.value) {
        ob.disabled = false;
      }
    });
  }

  setActiveDay(value: number, changeTypeName?: string) {
    this.activeDay = moment().day(value).format('ddd');

    const params = {
      selectedDayOfWeek: value
    };

    this.apiType('selectDay', params, changeTypeName);
  }

  modalDaysWeek(data: any) {
    this.modalDaysWeekRef = this.builderModalService.show(
      BuilderDaysWeekComponent,
      {
        class: 'u-modal u-modal_app-builder-popup-rounded',
        animated: true,
        initialState: {},
        ignoreBackdropClick: true,
        keyboard: false
      },
      this.config().modal
    );

    this.modalDaysWeekRef
      .content
      .action
      .subscribe((action: any) => {
        const { checkboxes } = action.content;
        const duplicate = checkboxes.find((ob: any) => ob.value === 'duplicate');

        const params = {
          ...data,
          copyToNewDates: duplicate.check
        };

        this.apiType('period', params);
      });
  }

  saveDatesChangesChange(data: any) {
    const beforeStartDateRange = moment(data.dates[0]).subtract(1, 'days').format(AppConstants.DATE_FORMAT_BASE_LINE);
    const startDateRange = moment(data.dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE);
    const endDateRange = moment(data.dates[data.dates.length - 1]).format(AppConstants.DATE_FORMAT_BASE_LINE);

    if (moment(this.activeRide.date).isBefore(startDateRange) || moment(this.activeRide.date).isAfter(endDateRange)) {
      this.actionsEditDatesChange(this.getNextAvailableDate(beforeStartDateRange, startDateRange, endDateRange, data.checkDaysActive));
    } else if (!data.checkDaysActive.includes(this.activeRide.dayOfWeek)) {
      this.actionsEditDatesChange(this.getNextAvailableDate(this.activeRide.date, startDateRange, endDateRange, data.checkDaysActive));
    }

    this.datesChanges = {
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      dates: data.dates,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    };

    this.getRidePurchaseOrders();
    this.checkIfPurchaseOrderExistsInList();
  }

  getNextAvailableDate(
    currentDate: string,
    startDateRange: string,
    endDateRange: string,
    availableDays: number[],
    fromStart?: boolean
  ): string {
    let newDate = moment(currentDate).add(1, 'days');

    if (newDate.isSameOrAfter(startDateRange) && newDate.isSameOrBefore(endDateRange) && availableDays.includes(newDate.day())) {
      return newDate.format(AppConstants.DATE_FORMAT_BASE_LINE);
    }

    if (newDate.isAfter(endDateRange)) {
      newDate = moment(startDateRange).subtract(1, 'days');

      if (fromStart) {
        return null;
      } else {
        fromStart = true;
      }
    }

    return this.getNextAvailableDate(newDate.format(AppConstants.DATE_FORMAT_BASE_LINE), startDateRange, endDateRange, availableDays, fromStart);
  }

  datesChangesChange(dates: string[]) {
    const datesStore: string[] = this.datesChanges.dates;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        this.datesChanges.dates = dates;
      }
    }
  }

  // Date active actions
  actionsEditDatesUpdate(activeRide: BuilderActiveRideBase, details: any) {
    if (this.config().mode === BuilderMode.Edit) {
      this.actionsEditDates = {
        date: activeRide.date,
        daysActive: details.activeDays,
        daysAvailable: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ],
        disableDaysBefore: moment(details.dateFrom).subtract(1, 'days').toISOString(),
        disableDaysAfter: moment(details.dateTo).add(1, 'days').toISOString()
      };
    }
  }

  actionsEditDatesChange(date: string, changeTypeName?: string) {
    const params = {};

    if (this.config().buildMode === BuilderBuildMode.Routes || this.config().buildMode === BuilderBuildMode.RouteSuggestions) {
      params['selectedDate'] = moment(date).format(AppConstants.DATE_FORMAT_BASE_LINE);
    }

    if (this.config().buildMode === BuilderBuildMode.RouteTemplates) {
      params['selectedDayOfWeek'] = moment(date).day();
    }

    this.activeDay = moment(date).format('ddd');

    this.apiType('selectDay', params, changeTypeName);
  }

  activeRidePrev() {
    this.apiType('previousDay');
  }

  activeRideNext() {
    this.apiType('nextDay');
  }

  backToAuto() {
    const isPassengerWithoutDestination = this.activeRide.stations.isPassengerWithoutDestination();

    if (isPassengerWithoutDestination) {
      this.builderPopupService.showMessage(
        {
          message: 'builder.messages.setDestination',
          ok: 'general.ok'
        },
        () => {},
        () => {},
        this.config().modal
      );
    } else {
      this.updateRideCalculationMode(BuilderCalculationMode.Optimal);
    }
  }

  mapMarkerCenter(data: any, type: string) {
    if (
      (this.mapRide.filter.passengers && type === 'passenger') ||
      (this.mapRide.filter.stations && type === 'station')
    ) {
      const { markerCenter } = this.mapRide;

      if (!markerCenter.position || (markerCenter.position && markerCenter.position.lat !== data.latitude && markerCenter.position.lng !== data.longitude)) {
        this.mapRide.markerCenter.position = {
          lat: data.latitude,
          lng: data.longitude
        };
      } else {
        this.mapRide.markerCenter.position = null;
      }
    }
  }

  mapPoint(data: UHereMapAddress) {
    this.mapRide.point = data;

    this.cdRef.detectChanges();
  }

  mapFilter(data: any) {
    this.mapRide.filter = data;
  }

  mapDirectionsChanged(data: UHereMapPathUpdate) {
    if (data.id === 'main') {
      this.mapRideAutoZoom = false;

      this.apiTypeRide('waypoints', {
        rideId: this.activeRide.rideId,
        waypoints: data.waypoints.map(waypoint => ({
          latitude: waypoint.lat,
          longitude: waypoint.lng,
          stopover: waypoint.stopover
        }))
      });
    }
  }

  updateMapRideData(reload?: boolean) {
    const stations = this.activeRide.stations.list.filter((ob: any) => ob.latitude && ob.longitude);

    let markerStationNumber = 0;
    let markerDestinationNumber = 0;

    const destinations = stations.filter(station => station.type === BuilderStationListItemType.Destination);

    const markersByCustomerType = mapConfig.markers[this.authCustomer().type] || mapConfig.markers.default;
    const markers = markersByCustomerType[environment.config.environmentType] || markersByCustomerType.default;

    const markersStations: UHereMapMarkerConfig[] = stations
      .map(station => {
        const markerByType = {
          [BuilderStationListItemType.Station]: markers.station,
          [BuilderStationListItemType.Destination]: destinations.length > 1 ? markers.multipleDestination : markers.destination,
          [BuilderStationListItemType.Accompany]: markers.accompany
        };

        let labelContent: number = null;

        switch (station.type) {
          case BuilderStationListItemType.Station: {
            markerStationNumber += 1;
            labelContent = markerStationNumber;

            break;
          }

          case BuilderStationListItemType.Destination: {
            markerDestinationNumber += 1;
            labelContent = markerDestinationNumber;

            break;
          }
        }

        return {
          id: station.rideStationId,
          label: station.type === BuilderStationListItemType.Destination && destinations.length <= 1 ? null : {
            content: labelContent,
            style: markerByType[station.type].label.style
          },
          content: escape(station.address),
          position: {
            lat: station.latitude,
            lng: station.longitude
          },
          icon: markerByType[station.type].icon,
          draggable: true
        };
      });

    const markersPassengers = stations
      .filter((ob: any) => ob.passengers && ob.passengers.length)
      .map((obStation: any) => obStation.passengers.map((ob: any) => ({
        id: ob.passengerId,
        label: '',
        content: escape(ob.name),
        position: {
          lat: ob.latitude,
          lng: ob.longitude
        },
        icon: {
          url: '/assets/images/map/point-passenger.svg',
          width: 48,
          height: 48
        },
        draggable: false
      })))
      .reduce((acc: any, ob: any) => acc.concat(ob), []);

    const markersPassengersClear = markersPassengers
      .filter((obPassenger: any) =>
        !markersStations.some((ob: any) => ob.position.lat === obPassenger.position.lat && ob.position.lng === obPassenger.position.lng)
      );

    if (!isEqual(this.mapRide.markers.stations, markersStations) || reload) {
      this.mapRide.markers.stations = markersStations;

      this.mapRide.markerCenter.config = {
        ...this.mapRide.markerCenter.config,
        bounds: {
          points: this.mapRide.markers.stations.map(station => station.position)
        }
      };

      this.mapRide.bounds = {
        points: this.mapRide.markers.stations.map(station => station.position)
      };
    }

    if (!isEqual(this.mapRide.markers.passengers, markersPassengers) || reload) {
      this.mapRide.markers.passengers = markersPassengers;
    }

    if (!isEqual(this.mapRide.markers.passengersClear, markersPassengersClear) || reload) {
      this.mapRide.markers.passengersClear = markersPassengersClear;
    }

    if (!markersStations.length) {
      this.mapRide.fitCenter.position = {
        lat: this.mapRide.coords.lat,
        lng: this.mapRide.coords.lng
      };
    } else {
      this.mapRide.fitCenter.position = null;
    }

    let waypointsAccompanyFirst: any = [];
    const stationFirst = stations[0];
    const stationSecond = stations[1];

    if (stations && stations.length > 1 && stationFirst && stationFirst.type === 'accompany' && stationFirst.latitude && stationFirst.longitude && stationSecond) {
      waypointsAccompanyFirst = [
        {
          lat: stationFirst.latitude,
          lng: stationFirst.longitude
        },
        {
          lat: stationSecond.latitude,
          lng: stationSecond.longitude
        }
      ];
    }

    let waypointsAccompanyLast: any = [];
    const stationPenult = stations[stations.length - 2];
    const stationLast = stations[stations.length - 1];

    if (stations && stations.length > 1 && stationPenult && stationLast && stationLast.type === 'accompany' && stationLast.latitude && stationLast.longitude) {
      waypointsAccompanyLast = [
        {
          lat: stationPenult.latitude,
          lng: stationPenult.longitude
        },
        {
          lat: stationLast.latitude,
          lng: stationLast.longitude
        }
      ];
    }

    const useTollRoads = this.addEditForm.get('settings.useTollRoads').value;
    const calculateBy = this.addEditForm.get('settings.calculateBy').value;

    let paths: UHereMapPathConfig[] = [];

    const waypointsMain = this.activeRide.waypoints
      .map(waypoint => ({
        lat: waypoint.latitude,
        lng: waypoint.longitude,
        stopover: waypoint.stopover
      }));

    if (waypointsMain.length) {
      if (this.activeRide.encodedPolylineJson) {
        paths = [
          {
            id: 'main',
            type: UHereMapPathType.Polylines,
            color: mapConfig.pathColors.main,
            waypoints: waypointsMain,
            polylines: JSON.parse(this.activeRide.encodedPolylineJson),
            draggable: true,
            routingMode: calculateBy === RoutePolicyCalculateBy.Time ? UHereMapsRoutingMode.Fast : UHereMapsRoutingMode.Short,
            avoidTollRoads: !useTollRoads
          },
          ...paths
        ];
      } else {
        paths = [
          {
            id: 'main',
            type: UHereMapPathType.Waypoints,
            color: mapConfig.pathColors.main,
            waypoints: waypointsMain,
            routingMode: calculateBy === RoutePolicyCalculateBy.Time ? UHereMapsRoutingMode.Fast : UHereMapsRoutingMode.Short,
            avoidTollRoads: !useTollRoads
          },
          ...paths
        ];
      }
    }

    if (waypointsAccompanyFirst.length) {
      paths = [
        {
          id: 'accompanyFirst',
          type: UHereMapPathType.Waypoints,
          color: mapConfig.pathColors.accompany,
          waypoints: waypointsAccompanyFirst,
          routingMode: calculateBy === RoutePolicyCalculateBy.Time ? UHereMapsRoutingMode.Fast : UHereMapsRoutingMode.Short,
          avoidTollRoads: !useTollRoads
        },
        ...paths
      ];
    }

    if (waypointsAccompanyLast.length) {
      paths = [
        {
          id: 'accompanyLast',
          type: UHereMapPathType.Waypoints,
          color: mapConfig.pathColors.accompany,
          waypoints: waypointsAccompanyLast,
          routingMode: calculateBy === RoutePolicyCalculateBy.Time ? UHereMapsRoutingMode.Fast : UHereMapsRoutingMode.Short,
          avoidTollRoads: !useTollRoads
        },
        ...paths
      ];
    }

    if (!isEqual(this.mapRide.paths, paths) || reload) {
      this.mapRide.paths = paths;
    }

    const monitoredPaths: BuilderMapRideMonitoredPath[] = [];
    const monitoredPathsMarkers: UHereMapMarkerInfo[][] = [];
    const activeRideMonitoredPaths = this.addEditForm.get('activeRide.monitoredPaths').getRawValue();

    if (activeRideMonitoredPaths) {
      activeRideMonitoredPaths
        .filter(path => ![ BuilderActiveRideMonitoredPathSourceType.Passenger ].includes(path.sourceType))
        .forEach(path => {
          monitoredPaths.push({
            config: {
              type: UHereMapPathType.Points,
              color: builderConfig.mapRide.monitoredPathColors[path.sourceType],
              points: path.coordinates.map(coordinates => ({
                lat: coordinates.latitude,
                lng: coordinates.longitude
              }))
            },
            sourceType: path.sourceType
          });

          monitoredPathsMarkers.push(
            path.coordinates
              .map(coordinates => ({
                position: {
                  lat: coordinates.latitude,
                  lng: coordinates.longitude
                },
                icon: {
                  color: builderConfig.mapRide.monitoredPathMarkerColors[path.sourceType]
                },
                content: moment.utc(coordinates.locatedAt).local().format(AppConstants.TIME_FORMAT)
              }))
              .reduce((acc, item, index) =>
                index === 0 || index === path.coordinates.length - 1 || !acc.some(obj => obj.content === item.content) ? [ ...acc, item ] : acc,
              []
              )
          );
        });
    }

    if (!isEqual(this.mapRide.monitoredPaths, monitoredPaths)) {
      this.mapRide.monitoredPaths = monitoredPaths;
    }

    if (!isEqual(this.mapRide.monitoredPathsMarkers, monitoredPathsMarkers)) {
      this.mapRide.monitoredPathsMarkers = monitoredPathsMarkers;
    }
  }

  toggleMapRideShowMonitoredPaths() {
    this.updateMapRideShowMonitoredPaths(!this.mapRide.showMonitoredPaths);
  }

  updateMapRideShowMonitoredPaths(showMonitoredPaths: boolean) {
    this.mapRide.showMonitoredPaths = showMonitoredPaths;
  }

  resetMapRide() {
    this.mapRide = new BuilderMapRide();
  }

  settings(params: BuilderSettings) {
    this.apiType('settings', params);
  }

  filter(params: BuilderFilterData) {
    if (params.dataType === BuilderFilterDataType.Passengers) {
      this.apiTypeRide('filtersPassengers', params);
    }

    if (params.dataType === BuilderFilterDataType.Stations) {
      this.apiTypeRide('filtersStations', params);
    }
  }

  // Station actions

  stationSearch(data: any) {
    if (this.mapRide.point && this.mapRide.point.address !== data) {
      this.mapPoint(null);
    }
  }

  stationAddress(data: UHereMapMarkerDragEndLocation) {
    this.mapRideAutoZoom = false;

    this.apiTypeRide('stationAddress', {
      rideId: this.activeRide.rideId,
      rideStationId: data.id,
      address: data.address,
      latitude: data.lat,
      longitude: data.lng,
      placeId: data.placeId
    });
  }

  stationAdd(station: BuilderSearchItem) {
    switch (station.type) {
      case BuilderSearchItemType.Passenger: {
        if (station.id === GeneralOptionsType.AddNew) {
          this.openAddNewPassengerModal(null, PassengersAddEditMode.AssignToRoute)
            .subscribe((data: { type: ModalActions; value: PassengerDetails; }) => {
              if (station.addToStation) {
                this.addPassengerToExistStation(data.value.passengerId, `${data.value.firstName} ${data.value.lastName}`);
              } else {
                this.passengerAdd(data.value.passengerId);
              }
            });

          return;
        }

        if (this.config().buildMode === BuilderBuildMode.RouteSuggestions) {
          const selectedPassengerId = this.builderCommonService.getSelectedPassengerId();

          if (selectedPassengerId && selectedPassengerId !== station.id) {
            this.showPopup({ message: 'builder.messages.assignOnlyCurrentPassenger', yes: 'general.ok' });

            return;
          }
        }

        if (station.addToStation) {
          this.addPassengerToExistStation(station.id as number, station.name);
        } else {
          this.passengerAdd(station.id as number);
        }

        break;
      }

      case BuilderSearchItemType.Station: {
        if (station.id === GeneralOptionsType.AddNew) {
          this.openAddNewStationModal();

          return;
        }

        this.apiTypeRide('stationAddSaved', { stationId: station.id });

        break;
      }

      case BuilderSearchItemType.Address: {
        this.apiTypeRide('stationAddByAddress', {
          address: station.address,
          latitude: station.latitude,
          longitude: station.longitude,
          placeId: station.placeId,
          name: station.name
        });

        break;
      }

      case BuilderSearchItemType.School: {
        this.apiTypeRide('stationAddCustomer', { customerId: station.id });

        break;
      }

      case BuilderSearchItemType.Accompany: {
        if (station.id === BuilderAccompanyOptionsType.Required) {
          this.apiTypeRide('accompanySetRequired');
        } else {
          if (station.id === GeneralOptionsType.AddNew) {
            this.openAddNewAccompaniesModal()
              .subscribe(({ value }) => this.apiTypeRide('accompanySet', { accompanyId: value.accompanyId }));

            return;
          }

          this.apiTypeRide('accompanySet', { accompanyId: station.id });
        }

        break;
      }

      case BuilderSearchItemType.Branch: {
        this.apiTypeRide('stationAddBranch', { branchId: station.id });

        break;
      }
    }
  }

  stationsOrder() {
    const rideStationIds = this.activeRide
      .stations
      .list
      .filter((ob: any) => ob.rideStationId !== null && ob.rideStationId !== 'required')
      .map((ob: any) => ob.rideStationId);

    this.apiTypeRide('stationOrder', {
      rideId: this.activeRide.rideId,
      rideStationIds
    });
  }

  trackEvent(event: string) {
    const trackingId = this.config().trackingId || `Route ${this.config().mode.charAt(0).toUpperCase() + this.config().mode.slice(1)}`;

    this.trackingService.track(`[${trackingId}] - ${event}`);
  }

  stationShowOnMap(data: any) {
    if (this.config().mode === BuilderMode.Edit) {
      this.trackingService.track('[Route Edit, Map tab] - click on display station on map');
    }

    this.mapMarkerCenter(data, 'station');
  }

  stationRemove({ rideStationId }) {
    if (this.config().mode === BuilderMode.Edit) {
      this.trackingService.track('[Route Edit, Map tab] - click on remove station');
    }

    const station = this.activeRide.stations.stationById(rideStationId);

    if (station.type === 'accompany') {
      this.apiTypeRide('accompanyReset', { resetRequiredFlag: true });
    }

    if (station.type === 'station') {
      this.modalStationRef = this.builderModalService.show(
        BuilderAskComponent,
        {
          class: 'u-modal u-modal_app-builder-ask',
          animated: true,
          initialState: {
            message: 'builder.stationRemoveAsk.message',
            checkboxes: [
              { check: true, value: 'target', name: 'builder.stationRemoveAsk.removeTarget' }
            ]
          },
          ignoreBackdropClick: true
        },
        this.config().modal
      );

      this.modalStationRef
        .content
        .action
        .subscribe((action: any) => {
          if (action.type === 'apply') {
            const { checkboxes } = action.content;

            const params = {
              rideId: this.activeRide.rideId,
              rideStationId,
              removeTarget: checkboxes.find((ob: any) => ob.value === 'target').check
            };

            this.apiTypeRide('stationRemove', params);
          }
        });
    }

    if (station.type === 'destination') {
      this.modalStationRef = this.builderModalService.show(
        BuilderAskComponent,
        {
          class: 'u-modal u-modal_app-builder-ask',
          animated: true,
          initialState: {
            message: 'builder.destinationRemoveAsk.message',
            checkboxes: [
              { check: true, value: 'passenger', name: 'builder.destinationRemoveAsk.removePassenger' },
              { check: true, value: 'station', name: 'builder.destinationRemoveAsk.removeStation' }
            ],
            dependencies: [
              {
                source: 'station',
                from: 'passenger'
              }
            ]
          },
          ignoreBackdropClick: true
        },
        this.config().modal
      );

      this.modalStationRef
        .content
        .action
        .subscribe((action: any) => {
          if (action.type === 'apply') {
            const { checkboxes } = action.content;

            const removePassenger = checkboxes.find((ob: any) => ob.value === 'passenger').check;
            const removeStation = checkboxes.find((ob: any) => ob.value === 'station').check;

            const params = {
              rideId: this.activeRide.rideId,
              rideStationId,
              removePassenger: removePassenger,
              removeStation: removePassenger ? removeStation : false
            };

            this.apiTypeRide('stationRemoveTarget', params);
          }
        });
    }
  }

  stationEdit(data: any) {
    const station = this.activeRide.stations.stationById(data.rideStationId);

    if (station.type === 'accompany') {
      if (this.config().mode === BuilderMode.Edit) {
        this.trackingService.track('[Track edit] - accompany changed');
      }

      if (data.accompanyId === GeneralOptionsType.AddNew) {
        this.openAddNewAccompaniesModal()
          .subscribe(({ value }) => this.apiTypeRide('accompanySet', { accompanyId: value.accompanyId }));

        return;
      }

      if (data.accompanyId === BuilderAccompanyOptionsType.Required) {
        this.apiTypeRide('accompanySetRequired');
      } else {
        this.apiTypeRide('accompanySet', { accompanyId: data.accompanyId });
      }
    } else {
      if (data.param === 'address') {
        this.mapRideAutoZoom = true;

        this.apiTypeRide('stationAddress', {
          rideId: this.activeRide.rideId,
          rideStationId: data.rideStationId,
          address: data.address,
          latitude: data.latitude,
          longitude: data.longitude,
          placeId: data.placeId
        });
      }

      if (data.param === 'name') {
        const params = {
          rideId: this.activeRide.rideId,
          rideStationId: data.rideStationId,
          name: data.name
        };

        this.apiTypeRide('stationName', params);
      }
    }
  }

  stationAccSync({ rideStationId }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const { options } = station.expand;
    const params = {
      costType: null,
      costPerHour: null,
      hours: null,
      totalCost: null,
      accompanyId: null
    };

    if (options.costOption === 0) {
      params.costType = 0;
      params.totalCost = options.cost;
    }

    if (options.costOption === 1 && options.priceOption === 0) {
      params.costType = 1;
      params.costPerHour = options.cost;
      params.hours = options.price;
    }

    if (options.costOption === 1 && options.priceOption === 1) {
      params.costType = 2;
      params.costPerHour = options.cost;
    }

    if (options.accompanyId) {
      params.accompanyId = options.accompanyId;
    }

    this.apiTypeRide('accompanyCost', params);
  }

  stationAccReturn({ rideStationId, returnHome }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const { options } = station.expand;
    const params: any = {
      returnHome
    };

    if (options.accompanyId) {
      params.accompanyId = options.accompanyId;
    }

    this.apiTypeRide('accompanyDestination', params);
  }

  stationAccPickUp({ rideStationId, type }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const { options } = station.expand;
    const params: any = {};

    if (type === 'home') {
      params['sourceType'] = 0;
    }

    if (type === 'first') {
      params['sourceType'] = 1;
    }

    if (options.accompanyId) {
      params.accompanyId = options.accompanyId;
    }

    this.apiTypeRide('accompanySource', params);
  }

  stationSetFirst({ rideStationId }) {
    if (this.config().mode === BuilderMode.Edit) {
      this.trackingService.track('[Route Edit, Map tab] - station - make first');
    }

    const params = {
      rideId: this.activeRide.rideId,
      rideStationId
    };

    this.apiTypeRide('stationSetFirst', params);
  }

  stationSetLast({ rideStationId }) {
    if (this.config().mode === BuilderMode.Edit) {
      this.trackingService.track('[Route Edit, Map tab] -  station - make last');
    }

    const params = {
      rideId: this.activeRide.rideId,
      rideStationId
    };

    this.apiTypeRide('stationSetLast', params);
  }

  stationSave({ rideStationId }) {
    const station = this.activeRide.stations.stationById(rideStationId);

    this.builderService.stationSave({
      stationId: +station.rideStationId,
      name: station.name,
      fullAddress: station.address,
      longitude: station.longitude,
      latitude: station.latitude
    })
      .pipe(first())
      .subscribe();
  }

  stationSetDestination({ rideStationId, type }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const params: {
      rideId: number;
      rideStationId: number;
      passengerIds: number[];
      addAnonymousPassenger?: boolean;
    } = {
      rideId: this.activeRide.rideId,
      rideStationId: rideStationId,
      passengerIds: []
    };

    const items = this.activeRide
      .stations
      .list
      .filter((ob: any) => ob.type === 'station' && ob.rideStationId !== rideStationId)
      .reduce((acc: any, obStation: any) => [ ...acc, ...obStation.passengers.filter((ob: any) => ob.passengerId) ], [])
      .map((ob: any) => ({ value: ob.passengerId, name: ob.name }));

    if (station.type === 'station' && type) {
      if (this.config().mode === BuilderMode.Edit) {
        this.trackingService.track('[Route Edit, Map tab] - station - convert to destination');
      }

      const isAnyDestination = this.activeRide.stations.isAnyDestination();

      if (isAnyDestination) {
        this.modalConvertToRef = this.builderModalService.show(
          BuilderConvertToComponent,
          {
            class: 'u-modal u-modal_app-builder-popup',
            animated: true,
            initialState: {
              items
            },
            ignoreBackdropClick: true
          },
          this.config().modal
        );

        this.modalConvertToRef
          .content
          .action
          .subscribe((action: any) => {
            const { checkboxes, passengers } = action.content;
            const anonymous = checkboxes.find((ob: any) => ob.value === 'anonymous');

            params.passengerIds = anonymous.check ? [] : passengers;
            params.addAnonymousPassenger = anonymous.check;

            this.apiTypeRide('stationSetTarget', params);
          });
      } else {
        this.apiTypeRide('stationSetTarget', params);
      }
    } else {
      this.apiTypeRide('stationSetNonTarget', params);
    }
  }

  passengerAddToStation(passengerId: number, passengerRideStationId: number, isPassengerStationRequired: boolean = false, targetRideStationId?: number) {
    this.apiTypeRide('passengerAddToStation', {
      rideId: this.activeRide.rideId,
      passengerId,
      passengerRideStationId,
      isPassengerStationRequired,
      targetRideStationId
    });
  }

  stationAddNewPassenger({ rideStationId }) {
    this.openAddNewPassengerModal(null, PassengersAddEditMode.AssignToRoute)
      .subscribe((data: { type: ModalActions; value: PassengerDetails; }) =>
        this.passengerAddToStation(data.value.passengerId, rideStationId)
      );
  }

  stationAddAnonymousPassenger({ rideStationId }) {
    if (this.config().mode === BuilderMode.Edit) {
      this.trackingService.track('[Route Edit, Map tab]  - add anonymous passenger');
    }

    const params = {
      rideId: this.activeRide.rideId,
      rideStationId
    };

    this.apiTypeRide('passengerAddAnonymous', params);
  }

  onCalculationModeSelect(mode: BuilderCalculationMode) {
    switch (mode) {
      case BuilderCalculationMode.Optimal: {
        this.backToAuto();
        this.trackEvent('click on \'optimal\' chip');

        break;
      }

      case BuilderCalculationMode.ManualStations: {
        this.trackEvent('click on \'manual stations\' chip');

        this.updateRideCalculationMode(BuilderCalculationMode.ManualStations);

        break;
      }
    }
  }

  stationEditArrivalTime(data: BuilderStationArrivalTimeEditAction) {
    const params = {
      rideId: this.activeRide.rideId,
      ...data
    };

    this.apiTypeRide('stationEditArrivalTime', params);
    this.trackEvent('Station card - edit station\'s arrival time');
  }

  passengerMoveToFirst({ rideStationId, passengerId, ridePassengerId }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const passengersCount = station.passengers.filter((ob: any) => ob.passengerId !== passengerId).length;

    if (passengersCount === 0) {
      this.modalPassengerRef = this.builderModalService.show(
        BuilderAskComponent,
        {
          class: 'u-modal u-modal_app-builder-ask',
          animated: true,
          initialState: {
            message: 'builder.passengerAsk.message',
            checkboxes: [
              { check: true, value: 'station', name: 'builder.passengerAsk.removeStation' }
            ]
          },
          ignoreBackdropClick: true
        },
        this.config().modal
      );

      this.modalPassengerRef
        .content
        .action
        .subscribe((action: any) => {
          if (action.type === 'apply') {
            const { checkboxes } = action.content;

            const params = {
              rideId: this.activeRide.rideId,
              ridePassengerId: ridePassengerId,
              removeStation: checkboxes.find((ob: any) => ob.value === 'station').check
            };

            this.apiTypeRide('passengerMoveToFirstStation', params);
          }
        });
    } else {
      const params = {
        rideId: this.activeRide.rideId,
        ridePassengerId: ridePassengerId,
        removeStation: false
      };

      this.apiTypeRide('passengerMoveToFirstStation', params);
    }
  }

  passengerMoveToLast({ rideStationId, passengerId, ridePassengerId }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const passengersCount = station.passengers.filter((ob: any) => ob.passengerId !== passengerId).length;

    if (passengersCount === 0) {
      this.modalPassengerRef = this.builderModalService.show(
        BuilderAskComponent,
        {
          class: 'u-modal u-modal_app-builder-ask',
          animated: true,
          initialState: {
            message: 'builder.passengerAsk.message',
            checkboxes: [
              { check: true, value: 'station', name: 'builder.passengerAsk.removeStation' }
            ]
          },
          ignoreBackdropClick: true
        },
        this.config().modal
      );

      this.modalPassengerRef
        .content
        .action
        .subscribe((action: any) => {
          if (action.type === 'apply') {
            const { checkboxes } = action.content;

            const params = {
              rideId: this.activeRide.rideId,
              ridePassengerId: ridePassengerId,
              removeStation: checkboxes.find((ob: any) => ob.value === 'station').check
            };

            this.apiTypeRide('passengerMoveToLastStation', params);
          }
        });
    } else {
      const params = {
        rideId: this.activeRide.rideId,
        ridePassengerId: ridePassengerId,
        removeStation: false
      };

      this.apiTypeRide('passengerMoveToLastStation', params);
    }
  }

  passengerComingSet({ rideStationId, passengerId, type }) {
    if (type) {
      this.passengerAdd(passengerId);

      return;
    }

    const station = this.activeRide.stations.stationById(rideStationId);
    const passengersCount = station.passengers.filter(passenger => passenger.passengerId !== passengerId).length;

    if (passengersCount === 0) {
      this.modalPassengerRef = this.builderModalService.show(
        BuilderAskComponent,
        {
          class: 'u-modal u-modal_app-builder-ask',
          animated: true,
          initialState: {
            message: 'builder.passengerAsk.message',
            checkboxes: [
              { check: true, value: 'station', name: 'builder.passengerAsk.removeStation' },
              { check: true, value: 'destination', name: 'builder.passengerAsk.removeDestination' }
            ]
          },
          ignoreBackdropClick: true
        },
        this.config().modal
      );

      this.modalPassengerRef
        .content
        .action
        .subscribe(action => {
          if (action.type === 'apply') {
            const { checkboxes } = action.content;

            this.apiTypeRide('passengerRemove', {
              passengerId,
              removeStation: checkboxes.find(checkbox => checkbox.value === 'station').check,
              removeTarget: checkboxes.find(checkbox => checkbox.value === 'destination').check
            });
          }
        });
    } else {
      this.apiTypeRide('passengerRemove', {
        passengerId,
        removeStation: false,
        removeTarget: false
      });
    }
  }

  passengerSupervisorAdd({ passengerId }) {
    this.apiTypeRide('passengerSupervisorAdd', {
      rideId: this.activeRide.rideId,
      passengerId
    });
  }

  passengerSupervisorSet({ passengerId }) {
    this.apiTypeRide('passengerSupervisorSet', {
      rideId: this.activeRide.rideId,
      passengerId
    });
  }

  passengerSupervisorReset({ passengerId }) {
    this.apiTypeRide('passengerSupervisorReset', {
      rideId: this.activeRide.rideId,
      passengerId
    });
  }

  passengerSupervisorRemove() {
    this.apiTypeRide('passengerSupervisorRemove', {
      rideId: this.activeRide.rideId
    });
  }

  passengerEdit(passenger: BuilderPassenger) {
    this.passengersDataService.editPassengerById(passenger.passengerId, PassengersAddEditMode.AssignToRoute, this.addEditForm.get('details.routeId').value)
      .pipe(
        switchMap(data => data.content.action),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(({ type, value }) => {
        switch (type) {
          case ModalActions.Delete: {
            this.passengerRemove({
              ridePassengerId: passenger.ridePassengerId,
              passengerId: passenger.passengerId
            });

            break;
          }

          case ModalActions.Submit: {
            this.passengerUpdate(value);

            break;
          }
        }
      });
  }

  passengerRemove({ ridePassengerId, passengerId }) {
    if (this.config().mode === BuilderMode.Edit) {
      this.trackingService.track('[Route Edit, Map tab] - remove passenger from station');
    }

    this.modalPassengerRef = this.builderModalService.show(
      BuilderAskComponent,
      {
        class: 'u-modal u-modal_app-builder-ask',
        animated: true,
        initialState: {
          message: 'builder.passengerAsk.message',
          checkboxes: [
            { check: true, value: 'station', name: 'builder.passengerAsk.removeStation' },
            { check: true, value: 'destination', name: 'builder.passengerAsk.removeDestination' }
          ]
        },
        ignoreBackdropClick: true
      },
      this.config().modal
    );

    this.modalPassengerRef
      .content
      .action
      .subscribe((action: any) => {
        if (action.type === 'apply') {
          const { checkboxes } = action.content;

          const params = {
            removeStation: checkboxes.find((ob: any) => ob.value === 'station').check,
            removeTarget: checkboxes.find((ob: any) => ob.value === 'destination').check
          };

          if (passengerId) {
            params['passengerId'] = passengerId;

            this.apiTypeRide('passengerRemove', params);
          } else {
            params['rideId'] = this.activeRide.rideId;
            params['ridePassengerId'] = ridePassengerId;

            this.apiTypeRide('passengerRemoveAnonymous', params);
          }
        }
      });
  }

  passengerMoveToStation({ rideStationId, ridePassengerId }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const stationMovePassenger = this.activeRide.stations.stationMovePassenger(ridePassengerId, rideStationId);
    const passengersCount = station.passengers.filter((ob: any) => ob.ridePassengerId !== ridePassengerId).length;

    if (passengersCount === 0) {
      this.modalPassengerRef = this.builderModalService.show(
        BuilderAskComponent,
        {
          class: 'u-modal u-modal_app-builder-ask',
          animated: true,
          initialState: {
            message: 'builder.passengerMoveToStationAsk.message',
            checkboxes: [
              { check: true, value: 'station', name: 'builder.passengerMoveToStationAsk.removeStation' }
            ]
          },
          ignoreBackdropClick: true
        },
        this.config().modal
      );

      this.modalPassengerRef
        .content
        .action
        .subscribe((action: any) => {
          if (action.type === 'apply') {
            const { checkboxes } = action.content;

            const params = {
              removeStation: checkboxes.find((ob: any) => ob.value === 'station').check,
              rideId: this.activeRide.rideId,
              rideStationId: stationMovePassenger.rideStationId,
              ridePassengerId
            };

            this.apiTypeRide('passengerMoveToStation', params);
          }
        });
    } else {
      const params = {
        removeStation: false,
        rideId: this.activeRide.rideId,
        rideStationId: stationMovePassenger.rideStationId,
        ridePassengerId
      };

      this.apiTypeRide('passengerMoveToStation', params);
    }
  }

  passengerAnonymousEdit({ ridePassengerId, amount }) {
    const params = {
      rideId: this.activeRide.rideId,
      ridePassengerId,
      amount
    };

    this.apiTypeRide('passengerAmount', params);
  }

  passengerDestinationSet({ rideStationId, ridePassengerId, targetRideStationId }) {
    const station = this.activeRide.stations.stationById(rideStationId);
    const passenger = station.passengers.find((ob: any) => ob. ridePassengerId === ridePassengerId);

    if (passenger.targetStationId === 0) {
      const params = {
        rideId: this.activeRide.rideId,
        targetId: targetRideStationId,
        ridePassengerId,
        removeTarget: false
      };

      this.apiTypeRide('passengerTarget', params);
    } else {
      this.modalStationRef = this.builderModalService.show(
        BuilderAskComponent,
        {
          class: 'u-modal u-modal_app-builder-ask',
          animated: true,
          initialState: {
            message: 'builder.passengerDestinationAsk.message',
            checkboxes: [
              { check: true, value: 'target', name: 'builder.passengerDestinationAsk.removeTarget' }
            ]
          },
          ignoreBackdropClick: true
        },
        this.config().modal
      );

      this.modalStationRef
        .content
        .action
        .subscribe((action: any) => {
          if (action.type === 'apply') {
            const { checkboxes } = action.content;
            const params = {
              rideId: this.activeRide.rideId,
              targetId: targetRideStationId,
              ridePassengerId,
              removeTarget: checkboxes.find((ob: any) => ob.value === 'target').check
            };

            this.apiTypeRide('passengerTarget', params);
          }
        });
    }
  }

  passengerNameHover({ passengerId }) {
    this.builderService.getPassengerCustomerName(passengerId)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(name => this.activeRide.stations.updatePassengerCustomerName(passengerId, name));
  }

  restoreRide() {
    const params = {
      dateFrom: this.activeRide.date,
      dateTo: this.activeRide.date,
      days: [ moment(this.activeRide.date).day() ]
    };

    this.apiTypeRide('restoreRide', params);
  }

  updateRoute(data: any, hardReloadMap?: boolean) {
    this.routeStore = cloneDeep(data);

    this.editSubscribesDestroy();

    const { guid, settings, details, activeRide, requiredRecalculation } = data;

    this.addEditForm.patchValue({
      guid,
      settings,
      requiredRecalculation,
      details: {
        ...details,
        isManualName: !details.isAutoGeneratedName,
        creationDate: moment(details.creationDate).format(AppConstants.DATE_FORMAT_BASE_SLASH)
      }
    });

    if (this.createOrEditRoutesAllowed()) {
      this.toggleManualRouteName(this.addEditForm.get('details.isManualName').value);
    }

    const dateFrom: string = moment(details.dateFrom).format(AppConstants.DATE_FORMAT_ISO);
    const dateTo: string = moment(details.dateTo).format(AppConstants.DATE_FORMAT_ISO);

    this.datesRange = {
      dates: [ dateFrom, dateTo ],
      checkDaysActive: details.activeDays,
      checkDaysAvailable: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ]
    };

    this.datesChanges = {
      dates: !this.customerIsOwnedBySc() && !this.headerDataService.isTodayActiveDate() ? [ data.activeRide.date ] : this.datesRange.dates,
      dateFrom: this.datesRange.dates[0],
      dateTo: this.datesRange.dates[this.datesRange.dates.length - 1],
      type: null,
      checkDaysActive: this.datesRange.checkDaysActive,
      checkDaysAvailable: this.datesRange.checkDaysActive
    };

    this.dateFormControl.patchValue(this.datesRange.dates[0], { emitEvent: false });

    this.initDetailsName();

    if ((this.config().buildMode === BuilderBuildMode.Routes || this.config().buildMode === BuilderBuildMode.RouteSuggestions) && this.config().mode === BuilderMode.Edit) {
      const activePreset = !this.customerIsOwnedBySc() ? (this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.Custom) : URangePreset.All;

      const datesChanges = this.getDates(activePreset, this.datesRange.dates[this.datesRange.dates.length - 1]);

      this.datesChanges = {
        ...this.datesChanges,
        type: activePreset,
        ...datesChanges
      };
    }

    this.datesDays = this.datesRange.checkDaysActive;

    this.activeDaysOfWeek(this.datesRange.checkDaysActive);
    this.updateDatesDaysAvailable(this.datesRange.dates);

    this.actionsEditDatesUpdate(activeRide, details);

    this.editNameStore = details.name;
    this.editNumberStore = details.number;
    this.editBidNumberStore = details.bidNumber;

    this.updateActiveRideFull(activeRide, null, null, hardReloadMap);

    this.editSubscribesInit();

    this.builderCommonService.updateActiveRouteData(data);

    this.getRidePurchaseOrders();
  }

  updateActiveRide(activeRide: BuilderActiveRideBase) {
    this.activeRide.rideId = activeRide.rideId;
    this.activeRide.dayOfWeek = activeRide.dayOfWeek;
    this.activeRide.date = activeRide.date;
    this.activeRide.timeType = activeRide.timeType;
    this.activeRide.plannedTimeType = activeRide.plannedTimeType;
    this.activeRide.startDateTime = moment(activeRide.startDateTime).format(AppConstants.TIME_FORMAT);
    this.activeRide.plannedStartDateTime = activeRide.plannedStartDateTime ? moment(activeRide.plannedStartDateTime).format(AppConstants.TIME_FORMAT) : activeRide.plannedStartDateTime;
    this.activeRide.endDateTime = moment(activeRide.endDateTime).format(AppConstants.TIME_FORMAT);
    this.activeRide.plannedEndDateTime = activeRide.plannedEndDateTime ? moment(activeRide.plannedEndDateTime).format(AppConstants.TIME_FORMAT) : activeRide.plannedEndDateTime;
    this.activeRide.carTypeId = activeRide.carTypeId;
    this.activeRide.vehicle = activeRide.vehicle;
    this.activeRide.driver = activeRide.driver;
    this.activeRide.carId = activeRide.vehicle?.carId || null;
    this.activeRide.driverId = activeRide.driver?.driverId || null;
    this.activeRide.isYitActive = activeRide.isYitActive;
    this.activeRide.yitSyncMode = activeRide.yitSyncMode;
    this.activeRide.plannedYitSyncMode = activeRide.plannedYitSyncMode;
    this.activeRide.yitSyncValue = activeRide.yitSyncValue;
    this.activeRide.distance = activeRide.distance ? +activeRide.distance.toFixed(2) : 0;
    this.activeRide.duration = activeRide.duration ? moment.duration(activeRide.duration).format(AppConstants.TIME_FORMAT_FULL) : null;
    this.activeRide.encodedPolylineJson = activeRide.encodedPolylineJson;
    this.activeRide.waypoints = activeRide.waypoints || [];
    this.activeRide.cancelled = activeRide.cancelled;
    this.activeRide.accompanies = activeRide.accompanies;
    this.activeRide.seatsCount = activeRide.seatsCount;
    this.activeRide.branchId = activeRide.branchId;
    this.activeRide.isAnyTargetStation = activeRide.isAnyTargetStation;
    this.activeRide.passengers = activeRide.passengers;

    this.daysOfWeek = this.daysOfWeek.map(day => ({ name: moment().day(day.value).format('ddd'), ...day }));
    this.activeDay = moment().day(activeRide.dayOfWeek).format('ddd');

    this.addEditForm.get('activeRide').patchValue({
      shuttleCompanyId: activeRide.shuttleCompanyId,
      contractId: activeRide.contractId,
      carTypeId: activeRide.carTypeId,
      supervisorId: activeRide.passengers.find(passenger => passenger.isSupervisor)?.passengerId || null,
      purchaseOrder: activeRide.purchaseOrder,
      purchaseOrderRow: activeRide.purchaseOrderRow,
      monitoredPaths: activeRide.monitoredPaths,
      chargeExtraFee: activeRide.chargeExtraFee,
      extraFee: activeRide.extraFee,
      hashcalRideType: activeRide.hashcalRideType,
      executionCost: activeRide.executionCost,
      driverHours: activeRide.driverHours,
      cost: activeRide.cost,
      costType: activeRide.costType,
      cancelled: activeRide.cancelled,
      totalCost: activeRide.totalCost,
      tollRoadsCost: activeRide.tollRoadsCost,
      tollRoadsCostType: activeRide.tollRoadsCostType,
      isMainScSession: activeRide.isMainScSession
    }, { emitEvent: false });

    this.updateHashcalRideTypeState();
    this.updateFieldsState();

    this.setDay();
  }

  updateHashcalRideTypeState() {
    if (this.builderTuningStoreService.hashcalRideTypesItems()?.[this.addEditForm.get('activeRide.costType').value]?.length) {
      this.addEditForm.get('activeRide.hashcalRideType').setValidators(Validators.required);
    } else {
      this.addEditForm.get('activeRide.hashcalRideType').clearValidators();
    }

    this.addEditForm.get('activeRide.hashcalRideType').updateValueAndValidity({ emitEvent: false });
  }

  updateFieldsState() {
    const activeRideCostType = this.addEditForm.get('activeRide.costType');
    const activeRideTollRoadsCostType = this.addEditForm.get('activeRide.tollRoadsCostType');
    const activeRideShuttleCompanyId = this.addEditForm.get('activeRide.shuttleCompanyId');
    const activeRidePurchaseOrder = this.addEditForm.get('activeRide.purchaseOrder');
    const enabledByFeatureType = !this.routeBuilderFeatureTypeShuttleCompany() || this.isSelectedRouteBuilderAuthShuttleCompanyId;
    const enabled = (
      this.isDetailsCustomerIdValid &&
      this.customerIsOwnedBySc() &&
      this.createOrEditRoutesAllowed() &&
      enabledByFeatureType
    );
    const fields = {
      'details.direction': enabled,
      'details.creationDate': false,
      'details.requiresApproval': false,
      'details.allowEmptyStations': !this.routePlannerMode() && this.createOrEditRoutesAllowed(),
      'details.routeType': this.createOrEditRoutesAllowed(),
      'activeRide.carTypeId': enabled,
      'activeRide.chargeExtraFee': enabled,
      'activeRide.executionCost': this.isDetailsCustomerIdValid && this.createOrEditRoutesAllowed(),
      'activeRide.driverHours': this.isDetailsCustomerIdValid && this.createOrEditRoutesAllowed() && enabledByFeatureType,
      'activeRide.totalCost': false,
      'activeRide.extraFee': false,
      'activeRide.costType': enabled && activeRideShuttleCompanyId.value,
      'activeRide.cost': enabled && activeRideShuttleCompanyId.value && !builderConfig.autoCalcSCCostTypes.includes(activeRideCostType.value) && activeRideCostType.value !== null,
      'activeRide.tollRoadsCost': enabled && activeRideShuttleCompanyId.value && activeRideTollRoadsCostType.value === BuilderActiveRideTollRoadsCostType.Manual,
      'activeRide.tollRoadsCostType': enabled && activeRideShuttleCompanyId.value,
      'activeRide.contractId': enabled && activeRideShuttleCompanyId.value,
      'activeRide.purchaseOrder': enabled,
      'activeRide.purchaseOrderRow': enabled && activeRidePurchaseOrder.value
    };

    Object.keys(fields).forEach(field => {
      if (fields[field]) {
        this.addEditForm.get(field).enable({ emitEvent: false });
      } else {
        this.addEditForm.get(field).disable({ emitEvent: false });
      }
    });
  }

  updateActiveRideFull(activeRide: BuilderActiveRideBase, details?: any, settings?: any, hardReloadMap?: boolean) {
    if (details) {
      this.editSubscribesDestroy();

      this.addEditForm.patchValue({
        details
      });

      this.actionsEditDatesUpdate(activeRide, details);

      this.editSubscribesInit();
    }

    if (settings) {
      this.addEditForm.patchValue({
        settings
      });
    }

    this.activeRide = <BuilderActiveRide>{};

    this.updateActiveRide(activeRide);

    const accompanies = this.activeRide.accompanies;

    if (accompanies.length) {
      accompanies.forEach(accompany => {
        const currentAcc = this.customerData.accompanies.find(acc => acc.id === accompany.accompanyId);

        if (!currentAcc) {
          this.builderDataStoreService.updateCustomerData({
            accompanies: [
              ...this.customerData.accompanies,
              {
                id: accompany.accompanyId,
                name: accompany.name
              }
            ]
          });

          this.builderDataStoreService.updateOptions({
            accompanies: [
              ...this.options.accompanies,
              {
                value: accompany.accompanyId,
                name: accompany.name,
                hidden: true
              }
            ],
            accompaniesWithoutRequired: [
              ...this.options.accompaniesWithoutRequired,
              {
                value: accompany.accompanyId,
                name: accompany.name,
                hidden: true
              }
            ]
          });
        }
      });
    }

    this.activeRide.stations = new BuilderStationService();
    this.activeRide.stations.customerDataSet(this.customerData);
    this.activeRide.stations.parse(activeRide, true);

    this.activeRide.stationsInactive = new BuilderStationService();
    this.activeRide.stationsInactive.customerDataSet(this.customerData);
    this.activeRide.stationsInactive.parse(activeRide, false);

    this.updateMapRideData(hardReloadMap);

    if (
      (this.config().buildMode === BuilderBuildMode.Routes || this.config().buildMode === BuilderBuildMode.RouteSuggestions) &&
      this.datesChangeType === RoutesChangeType.Unplanned &&
      (
        this.datesChanges.type === URangePreset.Today ||
        this.datesChanges.type === URangePreset.DisplayedDay ||
        (!this.customerIsOwnedBySc() && this.datesChanges.type === URangePreset.Custom)
      )
    ) {
      const preset = moment().startOf('day').isSame(moment(this.activeRide.date).startOf('day')) ?
        this.rangeHaveToday(URangePreset.Today) : !this.customerIsOwnedBySc() ? URangePreset.Custom : this.rangeHaveDisplayedDay(URangePreset.DisplayedDay);

      if (preset) {
        const updateChangesDates = this.getDates(preset, this.datesChanges.dateTo);

        this.datesChanges.type = preset;
        this.datesChanges.dates = updateChangesDates.dates;
        this.datesChanges.checkDaysActive = updateChangesDates.checkDaysActive;
      }
    }

    this.activeRide.rideCalculationMode = activeRide.rideCalculationMode;

    this.updateCalculationChips();
  }

  refreshRide() {
    if (this.config().mode === BuilderMode.Add) {
      this.setActiveDay(this.activeRide.dayOfWeek);
    }

    if (this.config().mode === BuilderMode.Edit) {
      this.actionsEditDatesChange(this.activeRide.date);
    }
  }

  rangeHaveDisplayedDay(preset: string): string | null {
    const displayedDay = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('day');

    return displayedDay.isSameOrAfter(moment(this.datesRange.dates[0]).startOf('day')) && displayedDay.isSameOrBefore(moment(this.datesRange.dates[this.datesRange.dates.length - 1]).startOf('day')) ? preset : null;
  }

  rangeHaveToday(preset: string): string | null {
    const today = moment().startOf('day');

    if (
      today.isSameOrAfter(moment(this.datesRange.dates[0]).startOf('day')) &&
      today.isSameOrBefore(moment(this.datesRange.dates[this.datesRange.dates.length - 1]).startOf('day'))
    ) {
      return preset;
    } else {
      return null;
    }
  }

  rangeHaveTomorrow(preset: string, from?: string, to?: string): string | null {
    const tomorrow = moment().add(1, 'days').startOf('day');
    const dateFrom = moment(from ? from : this.datesRange.dates[0]).startOf('day');
    const dateTo = moment(to ? to : this.datesRange.dates[this.datesRange.dates.length - 1]).startOf('day');

    if (
      tomorrow.isSameOrAfter(dateFrom) &&
      tomorrow.isSameOrBefore(dateTo)
    ) {
      return preset;
    } else {
      return null;
    }
  }

  changePreset(value: number) {
    if (value === RoutesChangeType.Unplanned) {
      const preset = this.headerDataService.isTodayActiveDate() ? this.rangeHaveToday(URangePreset.Today) : this.rangeHaveDisplayedDay(URangePreset.DisplayedDay);

      if (preset) {
        const updateChangesDates = this.getDates(preset,  this.datesChanges.dateTo);

        this.datesChanges.type = preset;
        this.datesChanges.dates = updateChangesDates.dates;
        this.datesChanges.checkDaysActive = updateChangesDates.checkDaysActive;
      }
    }

    if (value === RoutesChangeType.Planned) {
      const preset = this.config().mode === BuilderMode.Edit ? this.rangeHaveTomorrow(URangePreset.FromTomorrowOn) : this.rangeHaveToday(URangePreset.FromTodayOn);

      if (preset) {
        const updateChangesDates = this.getDates(preset,  this.datesChanges.dateTo);

        this.datesChanges.type = preset;
        this.datesChanges.dates = updateChangesDates.dates;
        this.datesChanges.checkDaysActive = updateChangesDates.checkDaysActive;
      } else {
        const updateChangesDates = this.getDates(URangePreset.All,  this.datesChanges.dateTo);

        this.datesChanges.type = URangePreset.All;
        this.datesChanges.dates = updateChangesDates.dates;
        this.datesChanges.checkDaysActive = updateChangesDates.checkDaysActive;
      }
    }
  }

  getDates(preset: string, dateTo: string): { dates: string[]; checkDaysActive: number[]; } {
    const today = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const tomorrow = moment().add(1, 'days').startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    if (preset === URangePreset.DisplayedDay || preset === URangePreset.Custom) {
      const presetDates = [ this.activeRide.date ];

      return {
        dates: presetDates,
        checkDaysActive: this.getActiveDays(presetDates)
      };
    }

    if (preset === URangePreset.Today) {
      const presetDates = [ today ];
      const activeDays = this.getActiveDays(presetDates);

      return {
        dates: presetDates,
        checkDaysActive: activeDays
      };
    }

    if (preset === URangePreset.FromTodayOn) {
      const presetDates = [ today, dateTo ];
      const activeDays = this.getActiveDays(presetDates);

      return {
        dates: presetDates,
        checkDaysActive: activeDays
      };
    }

    if (preset === URangePreset.FromTomorrowOn) {
      const presetDates = [ tomorrow, dateTo ];
      const activeDays = this.getActiveDays(presetDates);

      return {
        dates: presetDates,
        checkDaysActive: activeDays
      };
    }

    if (preset === URangePreset.All) {
      const presetDates = [ this.datesRange.dates[0], dateTo ];
      const activeDays = this.getActiveDays(presetDates);

      return {
        dates: presetDates,
        checkDaysActive: activeDays
      };
    }
  }

  generateDates(dateFrom: string, dateTo: string): Date[] {
    const datesRange = [];
    let startDate = new Date(moment(dateFrom).startOf('day').toDate());
    const endDate = new Date(moment(dateTo).startOf('day').toDate());

    while (startDate <= endDate) {
      datesRange.push(startDate);
      startDate = moment(startDate).add(1, 'day').toDate();
    }

    return datesRange;
  }

  getActiveDays(dates: string[]): number[] {
    const days = this.generateDates(dates[0], dates[dates.length - 1])
      .map((date: Date) => moment(date).day());

    return uniq(days);
  }

  edit(routeId: number) {
    if (this.config().viewMode === BuilderViewMode.Default) {
      if (this.builderCommonService.getEditRouteChanged()) {
        this.showPopup(
          { message: 'builder.close.confirm', yes: 'builder.close.yes', no: 'builder.close.no', showXIcon: true },
          {
            ok: () => this.validateAndSave(BuilderAfterSaveOption.None, routeId),
            cancel: () => {
              this.builderCommonService.editRouteIdSet(routeId);
              this.builderRoutesStoreService.routesRestore();
            }
          }
        );
      } else {
        this.builderCommonService.editRouteIdSet(routeId);
      }
    }

    if (this.config().viewMode === BuilderViewMode.Full) {
      this.builderCommonService.editRouteIdSet(routeId);
    }
  }

  saveAndCloseSuccess(nextRouteId: number, redirect: boolean) {
    this.builderCommonService.editRouteChangedSet(false);

    if (nextRouteId) {
      this.builderCommonService.editRouteIdSet(nextRouteId);
    } else if (redirect) {
      this.router.navigateByUrl(this.getBuildModeRedirectUrl());
    }
  }

  validateAndSave(option: number, nextRouteId?: number, redirect: boolean = true) {
    if (this.addEditForm.invalid || !this.isManualCostRequiredValid) {
      this.uPopupService.showErrorMessage({ message: builderConfig.dictionary.invalidForm });

      return;
    }

    if (
      this.config().mode === BuilderMode.Edit &&
      option === BuilderAfterSaveOption.None &&
      !this.builderCommonService.getEditRouteChanged() &&
      !this.selectedEmailReportType &&
      !this.addEditForm.get('requiredRecalculation').value
    ) {
      this.saveAndCloseSuccess(nextRouteId, redirect);

      return;
    }

    this.saveLoading = true;

    this.save(option, nextRouteId, redirect);
  }

  save(option: number, nextRouteId?: number, redirect: boolean = true, processExtraFeeOption: BuilderExtraFeeOption = BuilderExtraFeeOption.CheckExtraFee) {
    this.builderService
      .save({
        routeId: this.addEditForm.get('details.routeId').value,
        activeDayOfWeek: this.activeRide.dayOfWeek,
        activeDate: this.activeRide.date,
        afterSaveOption: option,
        reportMode: this.selectedEmailReportType || RouteChangeReportMode.None,
        processExtraFeeOption
      })
      .pipe(
        finalize(() => this.saveLoading = false)
      )
      .subscribe(
        data => {
          if (this.config().mode === BuilderMode.Add) {
            this.routesFacade.dailyItemsReset();
          }

          if (option === BuilderAfterSaveOption.None) {
            this.saveAndCloseSuccess(nextRouteId, redirect);
          } else {
            this.editSubscribesDestroy();
            this.stopRouteEditSession();
            this.setConfig('mode', BuilderMode.Add);
            this.toggleFormsState(!this.createOrEditRoutesAllowed());

            const { newRoute } = data;
            const { details, activeRide } = newRoute;

            const carType = this.options.carTypes.find(ob => ob.value === activeRide.carTypeId);
            const shuttleCompany = this.options.shuttleCompanies.find(ob => ob.value === activeRide.shuttleCompanyId);

            this.operationGuidService.setGuid(newRoute.guid);

            this.builderRoutesStoreService.routesAdd(
              {
                routeId: details.routeId,
                status: null,
                code: details.number,
                name: details.name,
                direction: details.direction,
                days: details.activeDays,
                startDate: details.dateFrom,
                endDate: details.dateTo,
                rideStartDateTime: moment(activeRide.startDateTime).format(AppConstants.TIME_FORMAT),
                rideEndDateTime: moment(activeRide.endDateTime).format(AppConstants.TIME_FORMAT),
                totalPassengers: activeRide.passengers.length,
                carTypeName: carType ? carType.name : null,
                carTypeCapacity: carType ? +carType.value : null,
                shuttleCompany: shuttleCompany ? shuttleCompany.name : null,
                locked: false,
                allowEmptyStations: details.allowEmptyStations
              },
              true
            );

            this.builderRoutesStoreService.routeActiveSet(details.routeId);
            this.builderRoutesStoreService.removeExistedRoutes();

            this.updateByFeatureType();
            this.updateRoute(newRoute);

            this.builderRoutesStoreService.highlightRoute(details.routeId);

            this.builderCommonService.editRouteChangedSet(false);

            this.selectTab('map');

            this.builderRoutesStoreService.resetColumnsFilterChange(true);

            this.uGridService.saveTablePropToLocalStorage({
              propName: 'columnsFiltersStore',
              tableName: 'rides-daily',
              userId: this.uGridService.getUserIdFromLocalStorage(),
              saveTableProps: true,
              value: []
            });

            this.uGridService.saveTablePropToLocalStorage({
              propName: 'columnsFiltersStore',
              tableName: 'rides-weekly',
              userId: this.uGridService.getUserIdFromLocalStorage(),
              saveTableProps: true,
              value: []
            });
          }
        },
        error => {
          if ([ Errors.ExtraFeeNecessary ].includes(error.code)) {
            this.chargeExtraFeeConfirm(error.description, true)
              .subscribe(action => this.save(option, nextRouteId, redirect, action));
          } else {
            this.handleErrorAfterSave(error);
          }
        }
      );
  }

  saveRideOrder(redirect: boolean = true, processExtraFeeOption: BuilderExtraFeeOption = BuilderExtraFeeOption.CheckExtraFee) {
    if (
      this.config().mode === BuilderMode.Edit &&
      !this.builderCommonService.getEditRouteChanged()
    ) {
      this.saveAndCloseSuccess(null, redirect);

      return;
    }

    if (this.addEditForm.invalid || this.rideOrderSummaryForm.invalid) {
      this.uPopupService.showErrorMessage({ message: builderConfig.dictionary.invalidForm });

      return;
    }

    this.rideOrder.savingProcessed = true;

    this.rideOrdersService.saveRideOrder(this.addEditForm.get('details.routeId').value, processExtraFeeOption)
      .pipe(
        finalize(() => this.rideOrder.savingProcessed = false)
      )
      .subscribe(
        () => {
          if (this.config().mode === BuilderMode.Add) {
            this.rideOrdersFacade.itemsReset();
          }

          this.saveAndCloseSuccess(null, redirect);
        },
        error => {
          if ([ Errors.ExtraFeeNecessary ].includes(error.code)) {
            this.chargeExtraFeeConfirm(error.description, true)
              .subscribe(action => this.saveRideOrder(redirect, action));
          } else {
            this.handleErrorAfterSave(error);
          }
        }
      );
  }

  chargeExtraFeeConfirm(message: string, showXIcon?: boolean): Observable<BuilderExtraFeeOption> {
    return new Observable(subscriber => {
      this.uPopupService.showMessage(
        {
          message,
          showXIcon,
          yes: builderConfig.dictionary.chargeExtraFeeConfirm.ok,
          no: builderConfig.dictionary.chargeExtraFeeConfirm.cancel,
          class: 'u-modal u-modal_app-builder-charge-extra-fee-popup'
        },
        () => {
          subscriber.next(BuilderExtraFeeOption.Apply);
          subscriber.complete();
        },
        () => {
          subscriber.next(BuilderExtraFeeOption.DoNotApply);
          subscriber.complete();
        },
        () => subscriber.complete()
      );
    });
  }

  rideStartInLessThanTwoHoursConfirm(message: string): Observable<boolean> {
    return new Observable(subscriber => {
      this.uPopupService.showMessage(
        {
          message,
          yes: builderConfig.dictionary.rideStartInLessThanTwoHoursConfirm.ok,
          no: builderConfig.dictionary.rideStartInLessThanTwoHoursConfirm.cancel
        },
        () => {
          subscriber.next(true);
          subscriber.complete();
        },
        () => {
          subscriber.next(false);
          subscriber.complete();
        }
      );
    });
  }

  closeMessage(): Observable<boolean> {
    if (!this.authDataSnapshotService.editRoutes() && this.config().mode === BuilderMode.Edit) {
      return of(true);
    }

    if (this.config().viewMode === BuilderViewMode.Default) {
      const editRouteChanged = this.builderCommonService.getEditRouteChanged();

      if (!editRouteChanged) {
        return of(true);
      }

      return new Observable((subscriber: any) => {
        this.showPopup(
          { message: 'builder.close.confirm', yes: 'builder.close.yes', no: 'builder.close.no', showXIcon: true },
          {
            ok: () => {
              const isRideOrdersMode = this.config().buildMode === BuilderBuildMode.RideOrders;

              if (isRideOrdersMode) {
                this.saveRideOrder();
              } else if (this.selectedEmailReportType) {
                this.validateAndSave(BuilderAfterSaveOption.None);
              }

              const formValid = isRideOrdersMode ? this.addEditForm.valid && this.rideOrderSummaryForm.valid : this.addEditForm.valid;

              subscriber.next(!editRouteChanged || formValid);
              subscriber.complete();
            },
            cancel: () => {
              subscriber.next(true);
              subscriber.complete();
            },
            close: () => {
              subscriber.next(false);
              subscriber.complete();
            }
          }
        );
      });
    }

    if (this.config().viewMode === BuilderViewMode.Full) {
      if (!this.builderRoutesStoreService.routesFullChanged()) {
        return of(true);
      }

      return new Observable((subscriber: any) => {
        this.showPopup(
          { message: 'builder.full.close.confirm', yes: 'general.yes', no: 'general.no', showXIcon: true },
          {
            ok: () => {
              subscriber.next(true);
              subscriber.complete();
            },
            cancel: () => {
              subscriber.next(false);
              subscriber.complete();
            },
            close: () => {
              subscriber.next(false);
              subscriber.complete();
            }
          }
        );
      });
    }
  }

  selectTab(tab: string) {
    if (this.selectedTab !== tab && tab === 'map') {
      this.refreshRide();
    }

    this.selectedTab = tab;
  }

  async startRouteEditSession(guid: string) {
    if (this.config().viewMode === BuilderViewMode.Default && this.config().mode === BuilderMode.Edit) {
      await this.routeEditSessionHubService.startRouteEditSession(guid);
    }
  }

  async stopRouteEditSession() {
    if (this.config().viewMode === BuilderViewMode.Default && this.config().mode === BuilderMode.Edit) {
      await this.routeEditSessionHubService.stopRouteEditSession();
    }
  }

  toggleLockedMap() {
    this.trackingService.track(`[Route ${this.config().mode}, map] - click on ${this.lockedMap ? 'lock' : 'unlock'} route button`);

    this.lockedMap = !this.lockedMap;
  }

  updateLockedMap(value: boolean) {
    this.lockedMap = value;
  }

  getBuildModeRedirectUrl() {
    const previousFullUrl = this.commonService.previousFullUrl();
    const redirectUrls = {
      [BuilderBuildMode.Routes]: NavigationPaths.Rides,
      [BuilderBuildMode.RouteTemplates]: NavigationPaths.RouteTemplates,
      [BuilderBuildMode.RideOrders]: NavigationPaths.RideOrders
    };

    return previousFullUrl && previousFullUrl.includes(redirectUrls[this.config().buildMode]) ? previousFullUrl : redirectUrls[this.config().buildMode];
  }

  openAddNewPassengerModal(editData?, mode?: PassengersAddEditMode) {
    return this.passengersDataService.openAddEditModal(editData, mode)
      .pipe(
        switchMap(data => data.content.action),
        take(1),
        takeUntil(this.unsubscribe),
        filter(({ type }) => [ ModalActions.Submit ].includes(type))
      );
  }

  openAddNewStationModal() {
    this.stationsDataService.openAddEditModal().content.action
      .pipe(
        take(1),
        takeUntil(this.unsubscribe),
        filter(({ type }) => type === ModalActions.Submit)
      )
      .subscribe(({ value }) => this.apiTypeRide('stationAddSaved', { stationId: value }));
  }

  openAddNewAccompaniesModal() {
    return this.accompaniesDataService.openAddEditModal(null, AccompaniesAddEditMode.AssignToRoute).content.action
      .pipe(
        take(1),
        takeUntil(this.unsubscribe),
        filter(({ type }) => type === ModalActions.Submit),
        tap(({ value }) => {
          const accompany = this.builderDataStoreService.customerData.accompanies.find(item => item.id === value.accompanyId);

          if (!accompany) {
            const contact = value.contacts.find(item => item.type === ContactType.Mobile);

            this.builderDataStoreService.addAccompany(
              {
                id: value.accompanyId,
                address: value.address,
                latitude: value.latitude,
                longitude: value.longitude,
                name: `${value.firstName} ${value.lastName}`,
                mobile: contact && contact.value
              }
            );
          }
        })
      );
  }

  setSelectedEmailReport(reportMode: RouteChangeReportMode = RouteChangeReportMode.None) {
    this.selectedEmailReportType = reportMode;
    this.saveActionsRadioButtons = {
      ...this.saveActionsRadioButtons,
      selected: reportMode
    };
  }

  getFirstDateFromRangeByDateOfWeek(dayOfWeek: number) {
    const dates = this.generateDates(this.datesRange.dates[0], this.datesRange.dates[this.datesRange.dates.length - 1]);
    let firstDate = null;

    for (const date of dates) {
      const data = moment(date).startOf('day');

      if (data.day() === dayOfWeek) {
        firstDate = data.format(AppConstants.DATE_FORMAT_ISO);

        break;
      }
    }

    return firstDate;
  }

  updateAddEditFormByFeatureMandatoryFields(
    mandatoryFields: AuthModuleRoutesFeatureMandatoryField[],
    formGroupName: string,
    mandatoryFieldsByControlName: { [key: string]: AuthModuleRoutesFeatureMandatoryField; }
  ) {
    const formGroup = this.addEditForm.get(formGroupName);

    if (mandatoryFields && mandatoryFields.length && formGroup) {
      Object.entries(mandatoryFieldsByControlName).forEach(([ key, value ]) => {
        if (mandatoryFields.includes(value)) {
          const control = formGroup.get(key);

          control.setValidators([ Validators.required ]);
          control.updateValueAndValidity({ emitEvent: false });
        }
      });
    }
  }

  updateRideOrderSummaryVisible(value: boolean) {
    this.rideOrder.summaryVisible = value;

    if (value) {
      this.dateFormControl.disable({ emitEvent: false });
      this.addEditForm.get('details').disable({ emitEvent: false });
      this.addEditForm.get('activeRide').disable({ emitEvent: false });
    } else {
      this.dateFormControl.enable({ emitEvent: false });
      this.addEditForm.get('details').enable({ emitEvent: false });
      this.addEditForm.get('activeRide').enable({ emitEvent: false });
    }

    this.updateLockedMap(value);
  }

  updateCustomerDataSupervisors() {
    if (this.config().buildMode === BuilderBuildMode.RideOrders) {
      const customerDataSupervisors = this.builderDataStoreService.customerData.supervisors;
      const supervisorId = this.activeRide.passengers.find(passenger => passenger.isSupervisor)?.passengerId;
      const supervisorExist = supervisorId && customerDataSupervisors.some(supervisor => supervisor.id === supervisorId);

      if (!supervisorExist) {
        this.rideOrdersService.getSupervisors()
          .pipe(
            take(1),
            takeUntil(this.unsubscribe)
          )
          .subscribe(supervisors => {
            this.builderDataStoreService.updateCustomerData({ supervisors });
            this.builderDataStoreService.updateOptions({ supervisors: supervisors.map(supervisor => ({ value: supervisor.id, name: supervisor.name })) });
          });
      }
    }
  }

  setDefaultDepartment() {
    if (this.config().buildMode === BuilderBuildMode.RideOrders && this.config().mode === BuilderMode.Add) {
      const departments = this.builderDataStoreService.customerData.departments;

      if (departments.length === 1) {
        this.addEditForm.get('details.departmentId').patchValue(departments[0].id, { emitEvent: false });

        this.apiType('departmentId', { departmentId: departments[0].id }, null, true);
      }
    }
  }

  passengerAdd(passengerId: number) {
    this.apiTypeRide('passengerAdd', { passengerId });
  }

  addPassengerToExistStation(passengerId: number, passengerName: string) {
    this.builderModalService.show(
      BuilderAddPassengerToExistStationComponent,
      {
        class: 'u-modal u-modal_app-builder-add-passenger-to-exist-station',
        animated: true,
        initialState: {
          passengerId,
          passengerName,
          stations: this.activeRide.stations.list
            .reduce((acc, station) =>
              station.type === BuilderStationListItemType.Station ? [ ...acc, { value: station.rideStationId, name: station.name } ] : acc, []
            ),
          destinations: this.activeRide.stations.list
            .reduce((acc, station) =>
              station.type === BuilderStationListItemType.Destination ? [ ...acc, { value: station.rideStationId, name: station.name } ] : acc, []
            )
        },
        ignoreBackdropClick: true
      },
      this.config().modal
    )
      .content
      .saveAction
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe((data: BuilderAddPassengerToExistStationSaveAction) =>
        this.passengerAddToStation(data.passengerId, data.stationId, true, data.destinationId)
      );
  }

  updateCustomer(customer: BuilderCustomer) {
    this.#customer.set(customer);
  }

  updateCustomerSupervisorModuleStatus(customerSupervisorModuleStatus: BuilderCustomerSupervisorModuleStatus) {
    this.#customerSupervisorModuleStatus.set(customerSupervisorModuleStatus);
  }

  getRideOrderShuttleCompanyCostTypes() {
    const contractId = this.rideOrderSummaryForm.get('contractId').value;

    if (!contractId) {
      this.#rideOrderShuttleCompanyCostTypes.set([]);

      return;
    }

    this.rideOrdersService.getShuttleCompanyCostTypes({ contractId })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(shuttleCompanyCostTypes => this.#rideOrderShuttleCompanyCostTypes.set(shuttleCompanyCostTypes));
  }
}
