<u-address
  [valid]="form.get('fullAddress').disabled || form.get('fullAddress').valid && form.get('latitude').valid && form.get('longitude').valid"
  [disabled]="form.get('fullAddress').disabled"
  [address]="{
    address: form.get('fullAddress').value,
    lat: form.get('latitude').value,
    lng: form.get('longitude').value
  }"
  (addressChange)="updateAddress($event)"
  (mapIconClick)="trackingService.track('Address field - click on map icon')"
></u-address>
