const DICTIONARY_PATH: string = 'routes.daily.movePassengers';

export const routesDailyMovePassengersConfig = {
  trackingId: 'Move passengers Daily',
  itemsToLoadLimit: 10,
  requestDelay: 1000,
  updateDropListDelay: 300,
  idPrefixes: {
    route: 'routes-daily-table-row',
    passengerRoute: 'routes-daily-move-passengers-route'
  },
  dictionary: {
    errors: {
      differentDirection: `${DICTIONARY_PATH}.errors.differentDirection`,
      routeLocked: `${DICTIONARY_PATH}.errors.routeLocked`,
      existOnRoute: `${DICTIONARY_PATH}.errors.existOnRoute`,
      routeCanceled: `${DICTIONARY_PATH}.errors.routeCanceled`,
      routeManualOrShuttle: `${DICTIONARY_PATH}.errors.routeManualOrShuttle`
    }
  }
};
