<div class="builder-full-skipped-passengers__wrapper">
  <div class="builder-full-skipped-passengers__header">
    <div class="builder-full-skipped-passengers__header-block">
      <div class="builder-full-skipped-passengers__header-title">{{skippedPassengers.length}} {{config.dictionary.title | translate}}</div>
      <div class="builder-full-skipped-passengers__header-subtitle">({{skippedPassengersNoDemands.length}} {{config.dictionary.subTitle | translate}})</div>
    </div>
    <div 
      class="builder-full-skipped-passengers__close"
      (click)="close(builderFullSkippedAction.Close)"
    ></div>
  </div>
  <div class="builder-full-skipped-passengers__grid">
    <u-grid
      [tableName]="'builder-full-skipped-passengers'"
      [saveTableProps]="true"
      [class]="'u-grid_white'"
      [columns]="columns"
      [rows]="skippedPassengers"
      [columnMode]="'flex'"
      [headerHeight]="25"
      [headerOffset]="3"
      [footerHeight]="0"
      [rowHeight]="50"
      [reorderable]="false"
      [scrollbarV]="true"
      [scrollbarH]="true"
      [isRtl]="isRtl"
      [lang]="lang"
      [showColumnsFilter]="false"
      [messages]="'uGrid' | translate"
    ></u-grid>
  </div>
  <div class="builder-full-skipped-passengers__footer">
    <div class="builder-full-skipped-passengers__footer-actions">
      <button
        uButton
        class="builder-full-skipped-passengers__footer-download"
        (click)="download()"
      >
        {{config.dictionary.actions.download | translate}}
      </button>
      <button
        uButton
        class="u-font-weight-medium"
        (click)="close(builderFullSkippedAction.CloseContinue)"
      >
        {{config.dictionary.actions.close | translate}}
      </button>
    </div>
  </div>
</div>

<ng-template #reasonColumn ngx-datatable-cell-template let-value="value">
  <div class="builder-full-skipped-passengers__cell" title="{{config.dictionary.skipReason[value] | translate}}">
    {{config.dictionary.skipReason[value] | translate}}
  </div>
</ng-template>
