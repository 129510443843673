import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { UPopupService } from '@shift/ulib';

import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/services';
import { AuthError } from '@app/auth/models';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(
    private oAuthService: OAuthService,
    private uPopupService: UPopupService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.url.includes(environment.config.idpAuthority)) {
            const { error } = err;

            if (error && error.error_description && error.error !== AuthError.InvalidRefreshToken) {
              this.uPopupService.showErrorMessage({ message: error.error_description });
            }
          }

          if (err.status === 401) {
            this.authService.clearStorages();

            this.oAuthService.initLoginFlow();
          }

          return throwError(err);
        })
      );
  }
}
