import { Injectable } from '@angular/core';

import { authConfig } from '@app/auth/configs';

@Injectable({
  providedIn: 'root'
})
export class AuthSessionStorageService {
  get length() {
    return sessionStorage.length;
  }

  clear() {
    sessionStorage.clear();
  }

  getItem(key: string) {
    const storageKey = authConfig.sessionStorageKeys[key] || key;

    let str = sessionStorage.getItem(storageKey);

    if (!str) {
      const lsStr = localStorage.getItem(storageKey);

      if (lsStr) {
        sessionStorage.setItem(storageKey, lsStr);

        str = sessionStorage.getItem(storageKey);
      }
    }

    return str && decodeURIComponent(atob(str));
  }

  key(index: number) {
    const str = sessionStorage.key(index);

    return str && decodeURIComponent(atob(str));
  }

  removeItem(key: string) {
    sessionStorage.removeItem(authConfig.sessionStorageKeys[key] || key);
  }

  setItem(key: string, value: string) {
    sessionStorage.setItem(authConfig.sessionStorageKeys[key] || key, btoa(encodeURIComponent(value)));
    localStorage.setItem(authConfig.sessionStorageKeys[key] || key, btoa(encodeURIComponent(value)));
  }
}
