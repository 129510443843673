import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { Errors } from '@app/shared/models';
import { BuilderExtraFeeOption } from '@app/builder/models';
import {
  RideOrdersSummary,
  RideOrdersAssignShuttleCompanyBody,
  RideOrdersGetShuttleCompaniesParams,
  RideOrdersGetShuttleCompanyCostTypesParams,
  RideOrdersChangeShuttleCompanyBody,
  RideOrdersChangeShuttleCompanyCostBody,
  GetRidesPurchaseOrder,
  GetRidesPurchaseOrdersBody,
  RideOrdersChangePurchaseOrderBody,
  RideOrdersChangePurchaseOrderRowBody,
  RideOrderStatusUpdated,
  RideOrdersApprovingCommentsBody,
  RideOrderAddEdit,
  RideOrdersChangeContractBody,
  RideOrdersRemoveContractBody,
  RideOrdersShuttleCompanyCostType,
  RideOrdersChangeShuttleCompanyHashcalRideTypeBody,
  RideOrdersCustomerData
} from '@app/ride-orders/models';

@Injectable({
  providedIn: 'root'
})
export class RideOrdersService {
  private BASE_PATH: string = 'RideOrders';

  constructor(private apiService: ApiService) {}

  openRideOrder(routeId: number): Observable<RideOrderAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/Open`, { routeId });
  }

  getCustomerData(): Observable<RideOrdersCustomerData> {
    return this.apiService.get(`${this.BASE_PATH}/CustomerData`);
  }

  getSupervisors(): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/Supervisors`);
  }

  getShuttleCompanies(params: RideOrdersGetShuttleCompaniesParams): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/ShuttleCompanies`, params);
  }

  getShuttleCompanyCostTypes(params: RideOrdersGetShuttleCompanyCostTypesParams): Observable<RideOrdersShuttleCompanyCostType[]> {
    return this.apiService.get(`${this.BASE_PATH}/ShuttleCompanyCostTypes`, params);
  }

  assignShuttleCompany(body: RideOrdersAssignShuttleCompanyBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/AssignShuttleCompany`, body, 'text', false, false, null, true);
  }

  getRidesPurchaseOrders(body: GetRidesPurchaseOrdersBody): Observable<GetRidesPurchaseOrder[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetRidesPurchaseOrders`, body, 'text', false, false, null, true);
  }

  changeShuttleCompany(body: RideOrdersChangeShuttleCompanyBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangeShuttleCompany`, body, 'text', false, false, null, true);
  }

  changeShuttleCompanyCost(body: RideOrdersChangeShuttleCompanyCostBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangeShuttleCompanyCost`, body, 'text', false, false, null, true);
  }

  changeShuttleCompanyHashcalRideType(body: RideOrdersChangeShuttleCompanyHashcalRideTypeBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangeShuttleCompanyHashcalRideType`, body, 'text', false, false, null, true);
  }

  changePurchaseOrder(body: RideOrdersChangePurchaseOrderBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangePurchaseOrder`, body, 'text', false, false, null, true);
  }

  changePurchaseOrderRow(body: RideOrdersChangePurchaseOrderRowBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangePurchaseOrderRow`, body, 'text', false, false, null, true);
  }

  changeStatus(body: RideOrderStatusUpdated): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangeStatus`, body, 'text', false, false, null, true);
  }

  changeApprovingComments(body: RideOrdersApprovingCommentsBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangeApprovingComments`, body, 'text', false, false, null, true);
  }

  changeContract(body: RideOrdersChangeContractBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/ChangeContract`, body, 'text', false, false, null, true);
  }

  removeContract(body: RideOrdersRemoveContractBody): Observable<RideOrdersSummary> {
    return this.apiService.post(`${this.BASE_PATH}/RemoveContract`, body, 'text', false, false, null, true);
  }

  saveRideOrder(routeId: number, processExtraFeeOption: BuilderExtraFeeOption): Observable<number> {
    return this.apiService.post(this.BASE_PATH, { routeId, processExtraFeeOption }, 'text', false, false, [ Errors.ExtraFeeNecessary ], true);
  }
}
