export enum ReOptimizationStatus {
  Started = 'Started',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Failed = 'Failed',
  AnotherSessionAlreadyInProgress = 'AnotherSessionAlreadyInProgress'
}

export enum ReOptimizationSummaryType {
  ReOptimization = 'ReOptimization',
  Cleanup = 'Cleanup'
}

export interface ReOptimizationSummary {
  type: ReOptimizationSummaryType;
  sessionId: string;
  reOptimizationStatus: number;
  inputNumberOfRides: number;
  outputNumberOfRides: number;
  inputTotalDistanceKm: number;
  outputTotalDistanceKm: number;
  inputVehicleOccupancyPercent: number;
  outputVehicleOccupancyPercent: number;
  inputTotalCost: number;
  outputTotalCost: number;
  executionDateTime: string;
  affectedRoutes: number[];
}

export interface ReOptimizationChange {
  routeId: number;
  routeCode: number;
  routeName: string;
  typeName: string;
  oldValue: string;
  newValue: string;
}

export interface ReOptimizationResponse {
  sessionId: string;
  status: ReOptimizationStatus;
}
