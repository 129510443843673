import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'errorMessage',
  standalone: true
})
export class ErrorMessagePipe implements PipeTransform {
  transform(errors: ValidationErrors, errorMessages: object): string {
    return errors && errorMessages[Object.keys(errors)[0]] || '';
  }
}
