import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  ReactiveFormsModule
} from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, map, pairwise, takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual, cloneDeep } from 'lodash';
import * as moment from 'moment';
import { UDatePickerDayIndicatorConfig, UInputDirective } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { AuthCustomer } from '@app/auth/models';
import { RoutesPeriodsDefaultData } from '@app/routes/models';
import { RoutesPeriodComponent } from '@app/routes/components';
import { routesPeriodsComponentConfig } from './routes-periods.component.config';

@Component({
  selector: 'app-routes-periods',
  templateUrl: './routes-periods.component.html',
  styleUrls: [ './routes-periods.component.scss', './routes-periods.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgForOf,
    TranslateModule,
    NgIf,
    UInputDirective,
    RoutesPeriodComponent
  ]
})
export class RoutesPeriodsComponent implements OnInit, OnDestroy {
  @Input() dayIndicatorConfig: UDatePickerDayIndicatorConfig;
  @Input() defaultData: RoutesPeriodsDefaultData;
  @Input() maxPeriodCount: number = 10;
  @Input() form: UntypedFormControl;
  @Input() authCustomer: AuthCustomer;
  @Input() activeDays: string[];
  @Input() hidePlusIcon: boolean;
  @Input() isOwnedBySc: boolean;

  @Output() clickOnPlusIcon: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'routes-periods';

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(routesPeriodsComponentConfig);
  formStore: UntypedFormArray = this.formBuilder.array([]);

  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initForm() {
    if (this.form) {
      if (this.form.value.length) {
        this.generateFormStore(this.form.value);
      }

      this.formStore.valueChanges
        .pipe(
          takeUntil(this.unsubscribe)
        )
        .subscribe(data => this.form.patchValue(data));

      this.form.valueChanges
        .pipe(
          takeUntil(this.unsubscribe),
          pairwise(),
          filter(([ prev, next ]) => !isEqual(prev, next)),
          map(data => data && data.length && data[data.length - 1]),
          filter(data => !!data && !data.length)
        )
        .subscribe(() => {
          this.formStore.clear();
          this.addPeriod();

          this.cdRef.markForCheck();
        });

      if (!this.form.value.length) {
        this.addPeriod();
      }
    }
  }

  private generatePeriod(period: RoutesPeriodsDefaultData) {
    return this.formBuilder.group({
      dates: [ period.dates, [ Validators.required ] ],
      dateFrom: [ period.dateFrom ],
      dateTo: [ period.dateTo ],
      type: [ period.type ],
      availablePresets: [ period.availablePresets ],
      checkDaysActive: [ period.checkDaysActive ],
      checkDaysAvailable: [ period.checkDaysAvailable ],
      comment: [ period.comment ]
    });
  }

  private generateFormStore(periods: RoutesPeriodsDefaultData[]) {
    this.formStore = this.formBuilder.array(periods.map(period => this.generatePeriod(period)));
  }

  addPeriod() {
    if (this.maxPeriodCount && this.maxPeriodCount <= this.formStore.value.length) {
      return;
    }

    this.formStore.push(this.generatePeriod(this.defaultData));
  }

  removePeriod(index: number) {
    this.formStore.removeAt(index);
  }

  updatePeriod(data, period: UntypedFormGroup) {
    period.patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });
  }

  updateDates({ dates, checkDaysActive }, period: UntypedFormGroup): void {
    const datesStore: string[] = period.get('dates').value;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        period.patchValue({
          dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
          checkDaysActive
        });
      }
    }
  }
}
