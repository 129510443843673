import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { USelectSItem } from '@shift/ulib';

import { AuthUserInfo } from '@app/auth/models';
import { AuthService } from '@app/auth/services';
import { UserService } from '@app/user/services';
import { UserCustomer, UserCustomerRole } from '@app/user/models';
import { authCustomerSwitchRoleComponentConfig } from './auth-customer-switch-role.component.config';

@Component({
  selector: 'app-auth-customer-switch-role',
  templateUrl: './auth-customer-switch-role.component.html',
  styleUrls: [ './auth-customer-switch-role.component.scss', './auth-customer-switch-role.component.rtl.scss' ]
})
export class AuthCustomerSwitchRoleComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses: string = 'auth-customer-switch-role';

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(authCustomerSwitchRoleComponentConfig);
  form: UntypedFormGroup;
  userInfo: AuthUserInfo;
  customers: USelectSItem[] = [];
  roles: USelectSItem[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getUserCustomerRoles();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getUserCustomerRoles() {
    this.userService.getUserCustomerRoles()
      .pipe(first())
      .subscribe(customers => {
        this.customers = this.getCustomersSlim(customers);

        const selectedCustomer = this.getCustomerById(customers, this.userInfo.customer.customerId);

        if (selectedCustomer) {
          this.roles = this.getRolesSlim(selectedCustomer);

          const selectedRole = this.getRoleSlimById(this.roles, this.userInfo.person.role);

          this.form.patchValue({
            customer: selectedCustomer.customerId,
            role: selectedRole.value
          });

          this.onCustomerChange(customers);
        }
      });
  }

  private getCustomersSlim(customers: UserCustomer[]): USelectSItem[] {
    return customers.map((customer: UserCustomer) => ({ value: customer.customerId, name: customer.name }));
  }

  private getCustomerById(customers: UserCustomer[], customerId: number): UserCustomer {
    return customers.find((customer: UserCustomer) => customer.customerId === customerId);
  }

  private getRolesSlim(customer: UserCustomer): USelectSItem[] {
    return customer.roles.map((role: UserCustomerRole) => ({ value: role.roleId, name: role.name }));
  }

  private getRoleSlimById(roles: USelectSItem[], roleId: number): USelectSItem {
    return roles.find((role: USelectSItem) => role.value === roleId);
  }

  private initForm() {
    this.form = this.fb.group({
      customer: [ null ],
      role: [ null ]
    });
  }

  private onCustomerChange(customers: UserCustomer[]) {
    this.form.get('customer').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((customerId: number) => {
        const selectedCustomer: UserCustomer = customers.find((customer: UserCustomer) => customer.customerId === customerId);

        this.roles = selectedCustomer.roles.map((ob: UserCustomerRole) => ({ value: ob.roleId, name: ob.name }));

        const selectedRole = this.roles.find((ob: USelectSItem) => ob.value === this.userInfo.person.role) || this.roles[0];

        this.form.get('role').patchValue(selectedRole.value);
      });
  }

  setRole() {
    this.authService.switchRole(this.form.value.customer, this.form.value.role)
      .pipe(
        take(1)
      )
      .subscribe(() => location.reload());
  }

  closeForm() {
    this.bsModalRef.hide();
  }
}
