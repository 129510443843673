import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obData',
  pure: false,
  standalone: true
})
export class ObDataPipe implements PipeTransform {
  transform(items: any[], ob: object, separator: string = ', '): string {
    return items.map(el => ob[el]).join(separator);
  }
}
