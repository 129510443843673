<div
  #tooltip="uTooltip"
  class="u-icon u-icon_pointer u-icon_app-builder-mobile"
  [uId]="'builder-station-passenger-mobile'"
  [uTooltip]="mobile"
  [tooltipClass]="'u-tooltip_general'"
  [placement]="[ 'top', 'bottom' ]"
  [container]="'body'"
  [triggers]="'manual'"
  (mouseenter)="passengerMobileEnter()"
  (mouseleave)="passengerMobileLeave()"
></div>
<ng-template #mobile>
  {{passengerMobile === null ? (config.dictionary.noMobile | translate) : passengerMobile}}
</ng-template>
