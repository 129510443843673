const DICTIONARY_PATH: string = 'shared.citiesPricingTable';

export const citiesPricingTableComponentConfig = {
  dictionaryPath: DICTIONARY_PATH,
  tableName: 'citiesPricingTable',
  dictionary: {
    uGrid: 'uGrid'
  },
  columns: [
    {
      prop: 'city',
      name: `${DICTIONARY_PATH}.city`
    }
  ]
};
