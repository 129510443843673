import { Component, HostBinding, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

import { EntityAreaButton } from '@app/shared/models';
import { UButtonSize, UButtonView } from '@shift/ulib';

@Component({
  selector: 'app-entity-area',
  templateUrl: './entity-area.component.html',
  styleUrls: [ './entity-area.component.scss', './entity-area.component.rtl.scss' ]
})
export class EntityAreaComponent {
  @Input() title: string;
  @Input() attention: string;
  @Input() buttons: EntityAreaButton[];
  @Input() titleTooltip: TemplateRef<any> | string;

  @Output() buttonAction: EventEmitter<EntityAreaButton> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'entity-area';

  uButtonSize = UButtonSize;
  uButtonView = UButtonView;

  buttonClick(button: EntityAreaButton) {
    this.buttonAction.emit(button);
  }
}
