<div
  class="demands-day-bulk-assign-text"
  *ngIf="passengers && (demands ? demands.length === 0 : true)">
  {{config.dictionary.assignShift | translate}}
</div>

<ng-container *ngIf="(!demands || (demands && !demands.length)) && (isBulkAssignOpen ? passengers : passenger)">
  <div
    class="demands-day-action demands-day-action_new"
    [ngClass]="{
      'demands-day-action_bulk': passengers,
      'demands-day-action_new_visible': popover.isOpen()
    }"
    #popover="uPopover"
    [uPopover]="assignShift" 
    [placement]="isRtl ? [ 'right-top', 'right-bottom', 'right', 'left-top', 'left-bottom', 'left' ] : [ 'left-top', 'left-bottom', 'left', 'right-top', 'right-bottom', 'right' ]"
    [container]="'body'"
    [viewportAutoClose]="false"
    [autoClose]="false"
    [verticalAlignByViewport]="true"
    [popoverClass]="'u-popover_app-demands-assign-shift'"
    [triggers]="'manual'"
    (click)="assignNewShift(popover)"
  >
    <div class="demands-day-action-new"></div>
  </div>
</ng-container>

<ng-container *ngIf="demands && demands.length">
  <div
    *ngFor="let demand of demands"
    class="demands-day-action demands-day-action_type"
    [ngStyle]="{
      'border-color': demand.branchColor
    }"
    #popover="uPopover"
    [uPopover]="assignShift" 
    [placement]="isRtl ? [ 'right-top', 'right-bottom', 'right', 'left-top', 'left-bottom', 'left' ] : [ 'left-top', 'left-bottom', 'left', 'right-top', 'right-bottom', 'right' ]"
    [container]="'body'"
    [viewportAutoClose]="false"
    [autoClose]="false"
    [verticalAlignByViewport]="true"
    [popoverClass]="'u-popover_app-demands-assign-shift'"
    [triggers]="'manual'"
    (click)="passenger ? assignShiftEdit(demand, popover) : assignShiftDemandEdit(demand, popover)"
  >
    <div class="demands-day-action__header">
      <div class="demands-day-action__header-title">
        {{hasBranches ? demand.branchName : null}}
      </div>
      <div class="demands-day-action__header-icons">
        <div
          class="demands-day-action__header-icon"
          uTooltip="{{
            (demand.demandType === demandsDemandType.Regular || demand.demandType === demandsDemandType.Reservation) ?
            (demand.createDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME) : null
          }}"
          [placement]="[ 'top' ]"
          [tooltipClass]="'u-tooltip_app-demands-icon'"
          [container]="'body'"
          [ngClass]="{
            'demands-day-action__header-icon_clock': demand.demandType === demandsDemandType.Regular,
            'demands-day-action__header-icon_mobile': demand.demandType === demandsDemandType.Reservation,
            'demands-day-action__header-icon_repeat': demand.demandType === demandsDemandType.Repeatable
          }"
        ></div>
        <div
          *ngIf="demand.demandType === demandsDemandType.Repeatable"
          class="demands-day-action__header-icon demands-day-action__header-icon_clock"
          uTooltip="{{demand.createDate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}"
          [placement]="[ 'top' ]"
          [tooltipClass]="'u-tooltip_app-demands-icon'"
          [container]="'body'"
        ></div>
      </div>
    </div>
    <div class="demands-day-action__content">
      <div class="demands-day-action__content-item">{{demand.shiftId === null ? (dictionary.special | translate) : demand.shiftName}}</div>
      <div class="demands-day-action__content-item demands-day-action__content-item_actions">
        <div class="demands-day-action__content-item-text">
          {{demand.startTime ? (demand.startTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT) : null}} -
          {{demand.endTime ? (demand.endTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT) : null}}
          <span *ngIf="demand.isOvernightTime" class="demands-day-action__content-item-next-day">{{dictionary.nextDay | translate}}</span>
        </div>
        <div
          *ngIf="passengers ? demands.length < 3 : !isBulkAssignOpen"
          class="demands-day-action-new demands-day-action__content-item-new"
          (click)="$event.stopPropagation(); assignNewShift(popover)"
        ></div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #assignShift>
  <app-demands-assign-shift
    [featureType]="featureType"
    [date]="date"
    [dayOfWeek]="dayOfWeek"
    [passenger]="passenger"
    [passengers]="passengers"
    [editData]="assignShiftEditData"
    (closeAction)="assignShiftClose()"
  ></app-demands-assign-shift>
</ng-template>
