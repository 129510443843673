import {
  Input,
  Output,
  Component,
  HostBinding,
  OnDestroy,
  OnChanges,
  ViewChild,
  SimpleChanges,
  EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';

import { TrackingService } from '@app/shared/services';
import { BuilderActiveRide, BuilderBuildMode, BuilderCalculationMode, BuilderMode } from '@app/builder/models';
import { BuilderDataService } from '@app/builder/services';

@Component({
  selector: 'app-builder-actions',
  templateUrl: './builder-actions.component.html',
  styleUrls: [ './builder-actions.component.scss', './builder-actions.component.rtl.scss' ]
})
export class BuilderActionsComponent implements OnChanges, OnDestroy {
  @Input() config: any;
  @Input() activeRide: BuilderActiveRide;
  @Input() isRtl: boolean;
  @Input() disabled: boolean;

  @Output() menuAction: EventEmitter<string> = new EventEmitter();
  @Output() prevAction: EventEmitter<void> = new EventEmitter();
  @Output() nextAction: EventEmitter<void> = new EventEmitter();
  @Output() setActiveDayAction: EventEmitter<number> = new EventEmitter();

  @HostBinding('class')
  get hostClasses() {
    return {
      'builder-actions': true,
      'builder-actions_disabled': this.disabled
    };
  }

  @ViewChild('dayActive', { static: false }) public dayActive;

  private unsubscribe: Subject<void> = new Subject();

  showRecalculate: boolean;
  builderMode = BuilderMode;
  builderBuildMode = BuilderBuildMode;

  constructor(
    private trackingService: TrackingService,
    public builderDataService: BuilderDataService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeRide) {
      this.showRecalculate = !!this.activeRide && this.activeRide.rideCalculationMode !== BuilderCalculationMode.Optimal && !this.builderDataService.activeRide.stations.isPassengerWithoutDestination();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  menu(data: string) {
    this.menuAction.emit(data);
  }

  prev() {
    if (this.config.mode === 'edit') {
      this.trackingService.track('[Route Edit, Map tab] - click on previous day');
    }

    this.prevAction.emit();
  }

  next() {
    if (this.config.mode === 'edit') {
      this.trackingService.track('[Route Edit, Map tab] - click on next day');
    }

    this.nextAction.emit();
  }

  selectDate() {
    if (this.config.mode === 'edit') {
      this.trackingService.track('[Route Edit, Map tab] - select ride date');
    }
  }

  setActiveDay(ob: any) {
    if (ob.disabled) {
      return;
    }

    this.setActiveDayAction.emit(ob.value);
    this.dayActive.close();
  }
}
