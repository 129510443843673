import {
  OnInit,
  WritableSignal,
  Input,
  Output,
  signal,
  inject,
  Component,
  DestroyRef,
  ChangeDetectionStrategy,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, finalize, first, switchMap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { from, throwError } from 'rxjs';
import { UPopupService } from '@shift/ulib';

import { OperationGuidService } from '@app/shared/services';
import { RouteDirection } from '@app/routes/models';
import { RouteSplitPassenger } from '@app/route-split/models';
import { RouteEditSessionHubService } from '@app/routes/services';
import { RouteSplitService } from '@app/route-split/services';
import { RouteSplitCardComponent } from '@app/route-split/components';

@Component({
  selector: 'app-route-split',
  templateUrl: './route-split.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ RouteSplitCardComponent ],
  providers: [ RouteSplitService ]
})
export class RouteSplitComponent implements OnInit, OnDestroy {
  @Input() route: { id: number; direction: RouteDirection; allowEmptyStations: boolean; };
  @Input() trackingId: string;
  @Input() viewportElement: HTMLElement;

  @Output() splitAction: EventEmitter<number[]> = new EventEmitter<number[]>();

  private readonly destroyRef = inject(DestroyRef);
  private readonly uPopupService = inject(UPopupService);
  private readonly routeSplitService = inject(RouteSplitService);
  private readonly operationGuidService = inject(OperationGuidService);
  private readonly routeEditSessionHubService = inject(RouteEditSessionHubService);
  public readonly bsModalRef = inject(BsModalRef);

  #loading: WritableSignal<boolean> = signal(false);
  #routePassengers: WritableSignal<RouteSplitPassenger[]> = signal([]);

  loading = this.#loading.asReadonly();
  routePassengers = this.#routePassengers.asReadonly();

  ngOnInit() {
    this.initRouteSplit();
  }

  async ngOnDestroy() {
    await this.resetSession();
  }

  private initRouteSplit() {
    this.#loading.set(true);

    this.routeSplitService
      .initialize()
      .pipe(
        first(),
        switchMap(guid =>
          from(this.initSession(guid))
            .pipe(
              catchError(err => {
                this.uPopupService.showErrorMessage({ message: 'general.error' });

                return throwError(err);
              })
            )
        ),
        switchMap(() => this.routeSplitService.getRoutePassengers(this.route.id)),
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.#loading.set(false))
      )
      .subscribe(passengers => this.#routePassengers.set(passengers));
  }

  async initSession(guid: string) {
    this.operationGuidService.setGuid(guid);

    await this.routeEditSessionHubService.startRouteEditSession(guid);
  }

  async resetSession(){
    await this.routeEditSessionHubService.stopRouteEditSession();

    this.operationGuidService.removeGuid();
  }

  onContinueClick(data: { passengerIds: number[]; keepEmptyStations: boolean; }) {
    this.#loading.set(true);

    this.routeSplitService.splitRoutePassengers({
      routeId: this.route.id,
      passengerIds: data.passengerIds,
      keepEmptyStations: data.keepEmptyStations
    })
      .pipe(
        finalize(() => this.#loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(routeIds => {
        this.bsModalRef.hide();
        this.splitAction.emit(routeIds);
      });
  }
}
