import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'numberOrString',
  standalone: true
})
export class NumberOrStringPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: string, format: string): number | string {
    const parsedValue = parseFloat(value);

    return isNaN(parsedValue) ? value : this.decimalPipe.transform(parsedValue, format);
  }
}
