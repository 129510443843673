import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemsFieldSum',
  standalone: true
})
export class ItemsFieldSumPipe implements PipeTransform {
  transform(items: any[], field: string ): number {
    return items.filter(item => item[field]).reduce((acc, obj) => acc + obj[field], 0);
  }
}
