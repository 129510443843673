const DICTIONARY_PATH: string = 'shared.passengerAskPopup';

export const passengerAskPopupComponentConfig = {
  dictionary: {
    message: `${DICTIONARY_PATH}.message`,
    cancel: `${DICTIONARY_PATH}.cancel`,
    apply: `${DICTIONARY_PATH}.apply`,
    removeStation: `${DICTIONARY_PATH}.removeStation`,
    removeDestination: `${DICTIONARY_PATH}.removeDestination`
  }
};
