import { createReducer, on } from '@ngrx/store';

import * as RoutesActions from '@app/routes/state/actions';
import { RoutesViewType } from '@app/routes/models';

export const FEATURE_KEY: string = 'viewType';

export interface State {
  value: RoutesViewType;
}

export const initialState: State = {
  value: RoutesViewType.Daily
};

export const reducer = createReducer(
  initialState,
  on(
    RoutesActions.viewTypeUpdate,
    (state, { value }) => ({
      ...state,
      value
    })
  )
);
