import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  signal,
  computed,
  inject,
  DestroyRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, switchMap, take } from 'rxjs/operators';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { UDaysOrderService, URangePreset, USelectSItem } from '@shift/ulib';

import { routesConfig } from '@app/routes/configs';
import { LocalizationService, TrackingService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { GeneralOptionsType } from '@app/shared/models';
import { RoutesChangeType, RouteRideSupervisor, RouteRideSupervisorLevel, RouteRideSupervisors } from '@app/routes/models';
import { AuthUserInfo } from '@app/auth/models';
import {
  BuilderDayOfWeek,
  BuilderMode,
  BuilderBuildMode,
  BuilderCalculationMode,
  BuilderAccompanyOptionsType,
  BuilderActiveRideCostType,
  BuilderConfig,
  BuilderActiveRide,
  BuilderGetShuttleCompaniesDetails,
  BuilderGetShuttleCompanyContractsShuttleCompany,
  BuilderEditContractSetBody,
  BuilderEditHashcalRideTypeBody,
  BuilderEditDriverHoursBody
} from '@app/builder/models';
import { BuilderService, BuilderModalService, BuilderCommonService, BuilderDataService, BuilderAgendaStoreService, BuilderRoutesStoreService } from '@app/builder/services';
import { builderConfig } from '@app/builder/configs';
import { BuilderDuplicateChangesComponent } from '../builder-duplicate-changes/builder-duplicate-changes.component';
import { BuilderDaysDuplicateComponent } from '../builder-days-duplicate/builder-days-duplicate.component';
import { builderAgendaComponentConfig } from './builder-agenda.component.config';

@Component({
  selector: 'app-builder-agenda',
  templateUrl: './builder-agenda.component.html',
  styleUrls: [ './builder-agenda.component.scss', './builder-agenda.component.rtl.scss' ]
})
export class BuilderAgendaComponent implements OnInit {
  @Input() builderDataConfig: BuilderConfig;
  @Input() activeRide: BuilderActiveRide;
  @Input() options: any;
  @Input() addEditForm: any;
  @Input() authUserInfo: AuthUserInfo;

  @HostBinding('class') hostClasses: string = 'builder-agenda';

  @ViewChild('agenda', { static: false }) agenda: ElementRef<HTMLElement>;

  private readonly destroyRef = inject(DestroyRef);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly uDaysOrderService = inject(UDaysOrderService);
  private readonly builderModalService = inject(BuilderModalService);
  private readonly builderService = inject(BuilderService);
  private readonly localizationService = inject(LocalizationService);
  private readonly trackingService = inject(TrackingService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  private readonly builderCommonService = inject(BuilderCommonService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly builderAgendaStoreService = inject(BuilderAgendaStoreService);
  public readonly builderDataService = inject(BuilderDataService);

  readonly #isHashcalRideTypes = signal(false);

  readonly isHashcalRideTypes = this.#isHashcalRideTypes.asReadonly();
  readonly authModules = this.builderDataService.authModules;
  readonly customerIsOwnedByScAndSupervisorModuleStatusActive = this.builderDataService.customerIsOwnedByScAndSupervisorModuleStatusActive;
  readonly customerSupervisorModuleStatusFeatureTypeGeneric = this.builderDataService.customerSupervisorModuleStatusFeatureTypeGeneric;
  readonly customerSupervisorModuleStatusFeatureTypeCommander = this.builderDataService.customerSupervisorModuleStatusFeatureTypeCommander;
  readonly routeBuilderFeatureTransportAdditionalFieldsDriverHours = this.builderDataService.routeBuilderFeatureTransportAdditionalFieldsDriverHours;
  readonly routeBuilderFeatureTypeShuttleCompany = this.builderDataService.routeBuilderFeatureTypeShuttleCompany;
  readonly routeBuilderAuthShuttleCompanyId = this.builderDataService.routeBuilderAuthShuttleCompanyId;
  readonly accompanyFeatureType = computed(() => this.authModules()?.accompany?.type);

  time: any;
  items: any = [];
  select: any = '';
  text: any = '';
  week: BuilderDayOfWeek[] = [];
  weekStore: BuilderDayOfWeek[] = [];
  builderCalculationMode = BuilderCalculationMode;
  shuttleCompaniesDetails: BuilderGetShuttleCompaniesDetails[] = [];
  shuttleCompaniesWithContracts: BuilderGetShuttleCompanyContractsShuttleCompany[] = [];
  sc = true;
  acc = true;
  isRtl = true;
  modalRef: BsModalRef;
  dateWeek: any;
  builderMode = BuilderMode;
  builderBuildMode = BuilderBuildMode;
  rideSupervisors: USelectSItem[] = [];
  autoCalcSCCostTypes: BuilderActiveRideCostType[] = builderConfig.autoCalcSCCostTypes;
  config = cloneDeep(builderAgendaComponentConfig);

  constructor() {
    this.weekInit();
  }

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();

    const details = this.addEditForm.get('details').getRawValue();

    this.dateWeek = {
      date: moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_LINE),
      disableDaysBefore: details.dateFrom,
      disableDaysAfter: details.dateTo
    };

    if (this.builderDataConfig.mode === BuilderMode.Add || this.builderDataConfig.buildMode === BuilderBuildMode.RouteTemplates && this.builderDataConfig.mode === BuilderMode.Edit) {
      this.weekRoute();
    }
  }

  dateWeekClick() {
    if (this.builderDataConfig.mode === BuilderMode.Edit) {
      this.trackingService.track('[Route Edit, Agenda tab] - click on calendar');
    }
  }

  dateWeekChange() {
    this.weekRoute();
    this.getRideSupervisors();
  }

  weekInit() {
    const week = [];

    for (let i = 0; i < 7; i++) {
      week.push(new BuilderDayOfWeek({
        active: false,
        dayOfWeek: i
      }));
    }

    if (!this.uDaysOrderService.sundayFirstDay()) {
      week.push(cloneDeep(week.splice(0, 1)[0]));
    }

    this.week = week;
  }

  year() {
    return moment().format('YYYY');
  }

  dayOfWeekShort(value: number) {
    return moment().day(value).format('ddd');
  }

  dateOfWeek(date: string) {
    return moment(date, AppConstants.DATE_FORMAT_BASE_LINE).format('DD.MM');
  }

  dateOfWeekIsToday(date: string) {
    return moment(date, AppConstants.DATE_FORMAT_BASE_LINE).isSame(moment(), 'day');
  }

  private getRouteIdAndActiveDate(): { routeId: number; activeDate: string; } {
    return {
      routeId: this.addEditForm.get('details').getRawValue().routeId,
      ...(
        (this.builderDataConfig.buildMode === BuilderBuildMode.Routes || this.builderDataConfig.buildMode === BuilderBuildMode.RouteSuggestions) &&
        this.builderDataConfig.mode === BuilderMode.Edit &&
        { activeDate: moment(this.dateWeek.date).format(AppConstants.DATE_FORMAT_BASE_LINE) }
      )
    };
  }

  private getRideSupervisors() {
    if (!this.customerIsOwnedByScAndSupervisorModuleStatusActive()) { return; }

    this.builderService
      .getRideSupervisors(this.getRouteIdAndActiveDate())
      .pipe(
        take(1),
        filter(data => !!data)
      )
      .subscribe(supervisors => this.updateRideSupervisors(supervisors));
  }

  private updateRideSupervisors(supervisors: RouteRideSupervisors) {
    this.rideSupervisors = Object.values(supervisors).reduce((acc: USelectSItem[], items: RouteRideSupervisor[]) =>
      [
        ...acc,
        ...items.map(item => ({
          value: item.passengerId,
          name: item.name,
          sticky: item.level >= RouteRideSupervisorLevel.Low && item.level !== null,
          icon: {
            urls: {
              default: routesConfig.rideSupervisorIcons[item.level]
            }
          }
        }))
      ],
    []
    );
  }

  private getShuttleCompanyContractsByDate(weekDate: string): { id: string; name: string; }[] {
    return this.shuttleCompaniesWithContracts.find(company => {
      const formattedWeekDate = moment(weekDate).format(AppConstants.DATE_FORMAT_BASE_LINE);
      const dateFrom = moment(company.dateFrom).format(AppConstants.DATE_FORMAT_BASE_LINE);
      const dateTo = moment(company.dateTo).format(AppConstants.DATE_FORMAT_BASE_LINE);

      return moment(dateFrom).isSame(formattedWeekDate) &&
        moment(dateTo).isSame(formattedWeekDate);
    })?.contracts || [];
  }

  weekRoute() {
    this.builderService
      .week(this.getRouteIdAndActiveDate())
      .subscribe(data => {
        this.updateWeekDays(data);

        this.cdRef.markForCheck();
      });
  }

  updateWeekDays(data: any) {
    const weekDays = data;
    const week = [];

    weekDays.forEach(ob => {
      const dayOfWeek = new BuilderDayOfWeek();
      const day: any = {
        ...ob,
        date: this.builderDataConfig.mode === BuilderMode.Add ?
          isFinite(parseInt(ob.dayOfWeek, 10)) && this.builderDataService.getFirstDateFromRangeByDateOfWeek(ob.dayOfWeek) :
          ob.date && moment(ob.date, AppConstants.DATE_FORMAT_BASE_LINE).startOf('day').format(AppConstants.DATE_FORMAT_ISO),
        active: !!ob.ride,
        ride: ob.ride ? {
          ...ob.ride,
          startDateTime: ob.ride.startDateTime ? moment(ob.ride.startDateTime).format(AppConstants.TIME_FORMAT) : null,
          endDateTime: ob.ride.endDateTime ? moment(ob.ride.endDateTime).format(AppConstants.TIME_FORMAT) : null,
          accompany: ob.ride.accompany ?
            {
              ...ob.ride.accompany,
              hours: ob.ride.accompany.hours !== null ? moment().startOf('day').add(ob.ride.accompany.hours * 60, 'minutes').format(AppConstants.TIME_FORMAT) : null
            } : {
              accompanyId: ob.ride.needAccompany ? 'required' : 'without'
            },
          transport: ob.ride.transport ? {
            ...ob.ride.transport,
            carId: ob.ride.transport.vehicle?.carId || null,
            driverId: ob.ride.transport.driver?.driverId || null,
            hashcalRideType: ob.ride.transport.hashcalRideType
          } : {},
          supervisorId: ob.ride.supervisorId || null
        } : {}
      };

      dayOfWeek.merge(day);
      week.push(dayOfWeek);
    });

    const updateWeek = () => {
      week.forEach(obWeek => {
        const sc = this.shuttleCompaniesDetails.find(ob => ob.shuttleCompany.shuttleCompanyId === obWeek.ride.transport.shuttleCompanyId);
        let transport: any = {};

        if (sc) {
          transport = {
            costTypes: sc.details.costTypes.map(costType => ({ value: costType.id, name: costType.name })),
            carTypeIds: sc.details.carTypes.map(ob => ({ value: ob.id, name: ob.name, cars: ob.cars })),
            hashcalRideTypes: sc.details.costTypes.filter(costType => costType.hashcalRideTypes).reduce((acc, item) => ({
              ...acc,
              [item.id]: item.hashcalRideTypes.map(({ id, name }) => ({ value: id, name }))
            }), {}),
            carIds: [],
            driverIds: sc.details.drivers.map(ob => ({ value: ob.id, name: ob.name })),
            contracts: this.getShuttleCompanyContractsByDate(obWeek.date).map(ob =>
              ({ value: ob.id, name: ob.name })
            )
          };

          const selectedCarTypeId = obWeek.ride && obWeek.ride.transport && obWeek.ride.transport.carTypeId;
          const selectedCarType = sc.details.carTypes && sc.details.carTypes.find(carType => carType.id === selectedCarTypeId);

          transport.carIds = sc.details.carTypes ? sc.details.carTypes
            .filter(carType => !selectedCarType || carType.capacity)
            .reduce((acc, carType) =>
              [ ...acc, ...carType.cars.map(car => ({ value: car.id, name: `${car.name} (${carType.name})` })) ], []
            ) : [];
        } else {
          transport = {
            shuttleCompanies: this.options.shuttleCompanies,
            costTypes: [],
            carTypeIds: this.options.carTypes
          };
        }

        obWeek.merge({
          options: {
            transport
          }
        });
      });

      if (!this.uDaysOrderService.sundayFirstDay()) {
        week.push(cloneDeep(week.splice(0, 1)[0]));
      }

      this.#isHashcalRideTypes.set(!!week.find(day => day.options.transport.hashcalRideTypes));

      this.week = week;
      this.weekStore = cloneDeep(week);
    };

    const shuttleCompanies = week.filter(ob => ob.ride.transport.shuttleCompanyId);

    if (shuttleCompanies.length) {
      this.builderAgendaStoreService.getShuttleCompaniesAndContracts({
        shuttleCompanies: {
          shuttleCompanyRides: shuttleCompanies.map(ob => ({
            shuttleCompanyId: ob.ride.transport.shuttleCompanyId,
            contractId: ob.ride.transport.contractId
          })),
          routeMode: builderConfig.routeModesByBuildMode[this.builderDataConfig.buildMode] ||
            builderConfig.routeModesByBuildMode.default
        },
        shuttleCompanyContracts: this.builderDataConfig.buildMode === BuilderBuildMode.RouteTemplates ? null : {
          shuttleCompanyPeriods: shuttleCompanies.map(ob => ({
            shuttleCompanyId: ob.ride.transport.shuttleCompanyId,
            dateFrom: moment(ob.date).format(AppConstants.DATE_FORMAT_BASE_LINE),
            dateTo: moment(ob.date).format(AppConstants.DATE_FORMAT_BASE_LINE)
          }))
        }
      });

      this.builderAgendaStoreService.state$
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(state => !!state.shuttleCompanies || !!state.shuttleCompanyContracts)
        )
        .subscribe(state => {
          this.shuttleCompaniesDetails = [ ...this.shuttleCompaniesDetails, ...state.shuttleCompanies?.shuttleCompaniesDetails ];
          this.shuttleCompaniesWithContracts = [
            ...this.shuttleCompaniesWithContracts,
            ...(state.shuttleCompanyContracts?.shuttleCompanies || [])
          ];

          updateWeek();
        });
    } else {
      updateWeek();
    }
  }

  weekElement(index: number, element: any) {
    return element ? element.dayOfWeek : null;
  }

  scToggle() {
    this.sc = !this.sc;

    if (this.builderDataConfig.mode === BuilderMode.Add) {
      if (this.sc) {
        this.trackingService.track('[Route Add, Agenda] - expand SC');
      } else {
        this.trackingService.track('[Route Add, Agenda] - minimize SC');
      }
    } else {
      if (this.sc) {
        this.trackingService.track('[Route Edit, Agenda tab] - click on expand SC');
      } else {
        this.trackingService.track('[Route Edit, Agenda tab] - click on minimize SC');
      }
    }
  }

  accToggle() {
    this.acc = !this.acc;

    if (this.builderDataConfig.mode === BuilderMode.Add) {
      if (this.acc) {
        this.trackingService.track('[Route Add, Agenda] - expand acc');
      } else {
        this.trackingService.track('[Route Add, Agenda] - minimize acc');
      }
    } else {
      if (this.acc) {
        this.trackingService.track('[Route Edit, Agenda tab] - click on expand acc');
      } else {
        this.trackingService.track('[Route Edit, Agenda tab] - click on minimize acc');
      }
    }
  }

  apiType(name: string, value?: any) {
    const details = this.addEditForm.get('details').getRawValue();
    const params = {
      routeId: details.routeId,
      activeDayOfWeek: 0
    };

    if (value) {
      params['value'] = value;
    }

    if (this.builderDataConfig.mode === BuilderMode.Edit) {
      params['activeDate'] = moment(this.dateWeek.date).format(AppConstants.DATE_FORMAT_BASE_LINE);
    }

    let query: any;

    switch (name) {
      case 'duplicate': { query = this.builderService.duplicate(params); break; }
      case 'time': { query = this.builderService.editTime(params); break; }
      case 'shuttleCompany': { query = this.builderService.editShuttleCompany(params); break; }
      case 'cost': { query = this.builderService.editCost(params); break; }
      case 'carType': { query = this.builderService.editCarType(params); break; }
      case 'car': { query = this.builderService.editCar(params); break; }
      case 'driver': { query = this.builderService.editDriver(params); break; }
      case 'accompanySet': { query = this.builderService.editAccompanySet(params); break; }
      case 'accompanyReset': { query = this.builderService.editAccompanyReset(params); break; }
      case 'accompanyCost': { query = this.builderService.editAccompanyCost(params); break; }
      case 'supervisorAdd': { query = this.builderService.editPassengerSupervisorAdd(params); break; }
      case 'supervisorRemove': { query = this.builderService.editPassengerSupervisorRemove(params); break; }
      case 'contractSet': { query = this.builderService.editContractSet(params as BuilderEditContractSetBody); break; }
      case 'contractRemove': { query = this.builderService.editContractRemove(params as BuilderEditContractSetBody); break; }
      case 'hashcalRideType': { query = this.builderService.editHashcalRideType(params as BuilderEditHashcalRideTypeBody); break; }
      case 'driverHours': { query = this.builderService.editDriverHours(params as BuilderEditDriverHoursBody); break; }
      default: { break; }
    }

    this.builderCommonService.editRouteChangedSet(true);

    query
      .pipe(
        take(1),
        switchMap(() => this.builderService.week(params))
      )
      .subscribe(
        data => {
          if (data) {
            this.updateWeekDays(data);
            this.updateActiveRoute(name, value, data);
          }

          if (name === 'duplicate') {
            this.weekRoute();
          }
        },
        () => {
          if (this.weekStore) {
            this.week = cloneDeep(this.weekStore);
          }
        }
      );
  }

  updateActiveRoute(name: string, params, rides) {
    const updateActiveRouteByDay = params && params.days && params.days.includes(this.activeRide.dayOfWeek);
    const updateActiveRouteByDate = params && params.dateFrom && params.dateTo && moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).startOf('day').isSame(moment(params.dateFrom).startOf('day'));

    if (
      (this.builderDataConfig.buildMode === BuilderBuildMode.RouteTemplates || (this.builderDataConfig.buildMode === BuilderBuildMode.Routes && this.builderDataConfig.mode === BuilderMode.Add)) && updateActiveRouteByDay ||
      (this.builderDataConfig.buildMode === BuilderBuildMode.Routes && this.builderDataConfig.mode === BuilderMode.Edit && updateActiveRouteByDate)
    ) {
      switch (name) {
        case 'carType': {
          const carType = this.options.carTypes.find(obj => obj.value === params.carTypeId);

          this.builderRoutesStoreService.routeActiveUpdate({
            carTypeName: carType ? carType.name : null
          });

          break;
        }

        case 'shuttleCompany': {
          const shuttleCompany = this.options.shuttleCompanies.find(obj => obj.value === params.shuttleCompanyId);

          this.builderRoutesStoreService.routeActiveUpdate({
            shuttleCompany: shuttleCompany ? shuttleCompany.name : null
          });

          break;
        }
      }

      const routeActive = this.builderRoutesStoreService.activeRoute();
      const updatedActiveRide = rides.find(ride => ride.dayOfWeek === this.activeRide.dayOfWeek);

      if (routeActive && updatedActiveRide && updatedActiveRide.ride) {
        const rideStartDateTime = moment(updatedActiveRide.ride.startDateTime).format(AppConstants.TIME_FORMAT);
        const rideEndDateTime = moment(updatedActiveRide.ride.endDateTime).format(AppConstants.TIME_FORMAT);
        const routeActiveUpdate: {
          rideStartDateTime?: string;
          rideEndDateTime?: string;
        } = {};

        if (routeActive.rideStartDateTime !== rideStartDateTime || routeActive.rideEndDateTime !== rideEndDateTime) {
          routeActiveUpdate.rideStartDateTime = rideStartDateTime;
          routeActiveUpdate.rideEndDateTime = rideEndDateTime;
        }

        if (Object.keys(routeActiveUpdate).length) {
          this.builderRoutesStoreService.routeActiveUpdate(routeActiveUpdate);
        }
      }
    }
  }

  apiTypeRide(name: string, value?: any) {
    const details = this.addEditForm.get('details').getRawValue();
    const params = {
      type: value && value.type || (this.builderDataConfig.buildMode === BuilderBuildMode.RouteTemplates ? RoutesChangeType.Planned : this.builderDataConfig.mode === BuilderMode.Add ? RoutesChangeType.Planned : RoutesChangeType.Unplanned),
      dateFrom: details.dateFrom,
      dateTo: details.dateTo,
      days: details.activeDays,
      ...value
    };

    return this.apiType(name, params);
  }

  editWeekDayOneParams(weekDay: BuilderDayOfWeek) {
    const params: any = {};

    if ((this.builderDataConfig.buildMode === BuilderBuildMode.Routes || this.builderDataConfig.buildMode === BuilderBuildMode.RouteSuggestions) && this.builderDataConfig.mode === BuilderMode.Edit) {
      params.dateFrom = weekDay.date;
      params.dateTo = weekDay.date;
    }

    return params;
  }

  editAll(weekDay: BuilderDayOfWeek, options?: any) {
    this.apiTypeRide('duplicate', {
      rideId: weekDay.ride.rideId,
      days: [ weekDay.dayOfWeek ],
      ...options
    });
  }

  editStartTime(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      time: weekDay.ride.startDateTime,
      timeType: 1,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('time', params);
  }

  editEndTime(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      time: weekDay.ride.endDateTime,
      timeType: 2,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('time', params);
  }

  editShuttleCompany(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      carTypeId: weekDay.ride.transport.carTypeId,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('shuttleCompany', params);
  }

  editCost(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      cost: weekDay.ride.transport.cost,
      costType: weekDay.ride.transport.costType,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('cost', params);
  }

  editHashcalRideType(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      carTypeId: weekDay.ride.transport.carTypeId,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      days: [ weekDay.dayOfWeek ],
      hashcalRideType: weekDay.ride.transport.hashcalRideType,
      ...options
    };

    this.apiTypeRide('hashcalRideType', params);
  }

  editCarType(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      carTypeId: weekDay.ride.transport.carTypeId,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('carType', params);
  }

  editCar(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      carId: weekDay.ride.transport.carId,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('car', params);
  }

  editDriver(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      driverId: weekDay.ride.transport.driverId,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('driver', params);
  }

  editDriverHours(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      driverHours: weekDay.ride.transport.driverHours,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      timeType: 1,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    this.apiTypeRide('driverHours', params);
  }

  changeSupervisor(passengerId: number, weekDay: BuilderDayOfWeek, options?: object) {
    this.apiTypeRide(
      passengerId ? 'supervisorAdd' : 'supervisorRemove',
      {
        ...this.editWeekDayOneParams(weekDay),
        driverId: weekDay.ride.transport.driverId,
        shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
        days: [ weekDay.dayOfWeek ],
        ...(passengerId && { passengerId }),
        ...options
      }
    );
  }

  editAccompanyId(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      accompanyId: weekDay.ride.accompany.accompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    if (params.accompanyId === GeneralOptionsType.AddNew) {
      this.builderDataService.openAddNewAccompaniesModal()
        .subscribe(({ value }) => this.apiTypeRide('accompanySet', { ...params, accompanyId: value.accompanyId }));

      setTimeout(() => {
        const weekDayStore = this.weekStore.find(day => day.ride.rideId === weekDay.ride.rideId);

        if (weekDayStore) {
          weekDay.ride.accompany.accompanyId = weekDayStore.ride.accompany.accompanyId;
        }
      });

      return;
    }

    if (params.accompanyId === BuilderAccompanyOptionsType.Required) {
      this.apiTypeRide('accompanyReset', params);
    } else {
      if (params.accompanyId === BuilderAccompanyOptionsType.Without) {
        this.apiTypeRide('accompanyReset', {
          ...params,
          resetRequiredFlag: true
        });
      } else {
        this.apiTypeRide('accompanySet', params);
      }
    }
  }

  editAccompanyCostType(weekDay: BuilderDayOfWeek) {
    weekDay.ride.accompany.costPerHour = null;
    weekDay.ride.accompany.hours = null;
    weekDay.ride.accompany.totalCost = null;
  }

  editAccompanyCost(weekDay: BuilderDayOfWeek, options?: any) {
    const params = {
      ...this.editWeekDayOneParams(weekDay),
      costType: weekDay.ride.accompany.costType,
      costPerHour: weekDay.ride.accompany.costPerHour,
      hours: weekDay.ride.accompany.hours ? moment.duration(weekDay.ride.accompany.hours).asMinutes() / 60 : null,
      totalCost: weekDay.ride.accompany.totalCost,
      accompanyId: weekDay.ride.accompany.accompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    };

    if (params.costType === 1) {
      params.totalCost = null;
    }

    if (params.costType === 2) {
      params.hours = null;
      params.totalCost = null;
    }

    if (
      params.costType === null ||
      // (params.costType === 1 && (!params.hours || !params.costPerHour)) ||
      (params.costType === 2 && !params.costPerHour)
    ) {
      return;
    }

    this.apiTypeRide('accompanyCost', params);
  }

  editContract(weekDay: BuilderDayOfWeek, options?: any) {
    const contractIdStore = this.weekStore.find(week => week.dayOfWeek === weekDay.dayOfWeek)?.ride?.transport?.contractId;

    this.apiTypeRide(weekDay.ride.transport.contractId ? 'contractSet' : 'contractRemove', {
      ...this.editWeekDayOneParams(weekDay),
      contractId: weekDay.ride.transport.contractId || contractIdStore,
      shuttleCompanyId: weekDay.ride.transport.shuttleCompanyId,
      days: [ weekDay.dayOfWeek ],
      ...options
    });
  }

  duplicate(type: string, weekDay: BuilderDayOfWeek) {
    this.trackingService.track('[Route Edit, Agenda tab] - click on duplicate');

    const details = this.addEditForm.get('details').getRawValue();

    if (this.builderDataConfig.mode === BuilderMode.Add || this.builderDataConfig.buildMode === BuilderBuildMode.RouteTemplates && this.builderDataConfig.mode === BuilderMode.Edit) {
      this.modalRef = this.builderModalService.show(
        BuilderDaysDuplicateComponent,
        {
          class: 'u-modal u-modal_app-builder-popup',
          animated: true,
          initialState: {
            days: details.activeDays,
            daysAvailable: details.activeDays
          },
          ignoreBackdropClick: true
        },
        this.builderDataConfig.modal
      );

      this.modalRef
        .content
        .action
        .subscribe((action: any) => {
          const { days } = action.content;

          this.duplicateType(type, weekDay, { days });
        });
    }

    if ((this.builderDataConfig.buildMode === BuilderBuildMode.Routes || this.builderDataConfig.buildMode === BuilderBuildMode.RouteSuggestions) && this.builderDataConfig.mode === BuilderMode.Edit) {
      this.modalRef = this.builderModalService.show(
        BuilderDuplicateChangesComponent,
        {
          class: 'u-modal u-modal_app-builder-popup-edit',
          animated: true,
          initialState: {
            datesChanges: {
              dates: [
                moment(weekDay.date).startOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO),
                moment(weekDay.date).endOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO)
              ],
              dateFrom: details.dateFrom,
              dateTo: details.dateTo,
              checkDaysActive: details.activeDays,
              checkDaysAvailable: details.activeDays,
              type: URangePreset.ActiveWeekDay
            },
            duplicateDay: weekDay.date,
            plannedType: RoutesChangeType.Unplanned,
            changeTypes: this.options.changeTypesEdit,
            viewportElement: this.agenda.nativeElement.querySelector('builder-main__content')
          },
          ignoreBackdropClick: true
        },
        this.builderDataConfig.modal
      );

      this.modalRef
        .content
        .action
        .subscribe((action: any) => {
          const actionType = action.type;
          const { datesChanges, plannedType } = action.content;

          if (actionType === 'apply') {
            this.duplicateType(type, weekDay, {
              rideId: weekDay.ride.rideId,
              days: datesChanges.checkDaysActive,
              dateFrom: moment(datesChanges.dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE),
              dateTo: moment(datesChanges.dates[1]).format(AppConstants.DATE_FORMAT_BASE_LINE),
              type: plannedType
            });
          }
        });
    }
  }

  duplicateType(type: string, weekDay: BuilderDayOfWeek, options?: any) {
    switch (type) {
      case 'all': { this.editAll(weekDay, options); break; }
      case 'startTime': { this.editStartTime(weekDay, options); break; }
      case 'endTime': { this.editEndTime(weekDay, options); break; }
      case 'shuttleCompany': { this.editShuttleCompany(weekDay, options); break; }
      case 'cost': { this.editCost(weekDay, options); break; }
      case 'carType': { this.editCarType(weekDay, options); break; }
      case 'car': { this.editCar(weekDay, options); break; }
      case 'driver': { this.editDriver(weekDay, options); break; }
      case 'driverHours': { this.editDriverHours(weekDay, options); break; }
      case 'accompanySet': { this.editAccompanyId(weekDay, options); break; }
      case 'accompanyCost': { this.editAccompanyCost(weekDay, options); break; }
      case 'contractSet': { this.editContract(weekDay, options); break; }
      case 'hashcalRideType': { this.editHashcalRideType(weekDay, options); break; }
      default: { break; }
    }
  }
}
