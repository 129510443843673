import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UPopupService } from '@shift/ulib';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { LocalizedToastrService, ValidationService, TrackingService, LocalizationService } from '@app/shared/services';
import { ModalActions } from '@app/shared/models';
import {
  AuthModuleName,
  AuthModulePassengersFeature,
  AuthModulePassengersFeatureType
} from '@app/auth/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { PassengersService } from '@app/passengers/services/passengers.service';
import {
  PassengerDetails,
  PassengerPickUpType,
  PassengersAddEditMode,
  PassengersInitialData,
  PassengersTabName
} from '@app/passengers/models';
import { PassengersAddEditDataService } from '@app/passengers/services/passengers-add-edit-data.service';
import { passengersConfig } from '@app/passengers/configs';

@Component({
  selector: 'app-passengers-generic-add-edit',
  templateUrl: './passengers-generic-add-edit.component.html',
  providers: [ AuthDataSnapshotService ]
})
export class PassengersGenericAddEditComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter();

  private readonly fb = inject(UntypedFormBuilder);
  private readonly translateService = inject(TranslateService);
  private readonly modalRef = inject(BsModalRef);
  private readonly modalRemoveRef = inject(BsModalRef);
  private readonly validationService = inject(ValidationService);
  private readonly uPopupService = inject(UPopupService);
  private readonly trackingService = inject(TrackingService);
  private readonly localizationService = inject(LocalizationService);
  private readonly localizedToastrService = inject(LocalizedToastrService);
  private readonly authDataService = inject(AuthDataService);
  private readonly passengersService = inject(PassengersService);
  public readonly passengersAddEditDataService = inject(PassengersAddEditDataService);
  public readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  private unsubscribe: Subject<void> = new Subject();

  addEditForm: UntypedFormGroup;
  editPassenger: { editData: PassengerDetails; initialData: PassengersInitialData; };
  buttons = cloneDeep(passengersConfig.addEditButtons).filter(button =>
    !button.permission || this.authDataSnapshotService.checkPermission(button.permission)
  );
  title: string;
  isRtl = this.localizationService.isRtl();
  isPassengerSavingActive: boolean;
  mode: PassengersAddEditMode = PassengersAddEditMode.Default;
  excludedRouteId: number;
  featureType: AuthModulePassengersFeatureType;
  passengersTabName = PassengersTabName;

  ngOnInit() {
    if (this.editPassenger && !this.editPassenger.editData) {
      this.buttons = this.buttons.filter(button => button.action !== ModalActions.Delete);
    }

    this.translateService.get('passengers.addEdit.passengerTitle').subscribe((title: string) =>  {
      this.title = title;
    });

    this.getAuthModules();
    this.initAddEditForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.passengersAddEditDataService.resetData();
  }

  private getAuthModules() {
    this.authDataService.modules$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(modules => {
        this.featureType = modules[AuthModuleName.Passengers] && modules[AuthModuleName.Passengers][AuthModulePassengersFeature.Type];
      });
  }

  private initAddEditForm() {
    this.addEditForm = this.fb.group({
      details: this.fb.group({
        id: [ 0 ],
        passengerId: [ 0 ],
        passengerNumber: [ '' ],
        firstName: [ '', Validators.required ],
        lastName: [ '', Validators.required ],
        identity: [ '' ],
        gender: [ 0 ],
        address: [ '', Validators.required ],
        latitude: [ '', Validators.required ],
        longitude: [ '', Validators.required ],
        placeId: [ null ],
        pickUpType: [ PassengerPickUpType.FromHome, Validators.required ],
        birthDate: [ '' ],
        status: [ '', Validators.required ],
        comment: [ '' ],
        stationId: [ null ],
        stationAddress: this.fb.group({
          fullAddress: [],
          latitude: [],
          longitude: [],
          placeId: []
        }),
        departmentId: [ null ],
        role: [ '' ],
        contacts: this.fb.array([]),
        branchId: [ 0 ],
        refundEligibility: [ null ]
      })
    });

    const details = this.addEditForm.get('details') as UntypedFormGroup;

    if (this.featureType === AuthModulePassengersFeatureType.Iec) {
      details.addControl('sapCode', this.fb.control(null, Validators.required));
      details.addControl('aadCode', this.fb.control(null, Validators.required));
    }

    const identityForm = details.get('identity');
    const contactsForm = details.get('contacts');

    identityForm.setValidators([
      Validators.minLength(9),
      Validators.maxLength(9),
      ValidationService.number,
      ValidationService.identity,
      this.checkIdentifierPersonContactsShiftPassengersGeneric.bind(this, contactsForm)
    ]);

    if (this.editPassenger && this.editPassenger.editData) {
      this.updatePassenger(this.editPassenger.editData);
    }

    if (!this.authDataSnapshotService.managePassengers()) {
      this.addEditForm.disable();
    }
  }

  private track(name: string) {
    this.trackingService.track(`[${passengersConfig.trackingId}] - ${name}`);
  }

  private checkIdentifierPersonContactsShiftPassengersGeneric = (personContacts: UntypedFormArray, control: UntypedFormControl) => {
    const allRemove = !personContacts.value.some(obContact => obContact.type === 14);

    return !!personContacts.value.length && !allRemove || !!control.value ? null : { required: true };
  };

  private updatePassenger(data: PassengerDetails) {
    this.addEditForm.get('details').patchValue({
      id: data.id,
      passengerId: data.passengerId,
      passengerNumber: data.passengerNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      identity: data.identity,
      gender: data.gender,
      address: data.address,
      latitude: data.latitude,
      longitude: data.longitude,
      placeId: data.placeId,
      pickUpType: data.pickUpType,
      birthDate: data.birthDate,
      status: data.status,
      comment: data.comment,
      stationId: data.stationId,
      stationAddress: {
        latitude: data.stationAddress && data.stationAddress.latitude,
        longitude: data.stationAddress && data.stationAddress.longitude,
        placeId: data.stationAddress && data.stationAddress.placeId,
        fullAddress: data.stationAddress && data.stationAddress.fullAddress
      },
      departmentId: data.departmentId,
      role: data.role,
      branchId: data.branchId,
      refundEligibility: data.refundEligibility,
      ...(this.featureType === AuthModulePassengersFeatureType.Iec ? {
        sapCode: data.sapCode,
        aadCode: data.aadCode
      } : {})
    });

    if (data.contacts) {
      this.updatePassengerContacts(data.contacts);
    }
  }

  private updatePassengerContacts(passengerContacts: any) {
    const passengerContactsForm = this.addEditForm.get('details.contacts') as UntypedFormArray;

    passengerContactsForm.controls.forEach((ob: any, i: number) => passengerContactsForm.removeAt(i));
    passengerContactsForm.controls = [];

    passengerContacts.forEach((passengerContact: any) => {
      const personContactForm = this.fb.group({
        type: [ passengerContact.type, [ Validators.required ] ],
        id: [ passengerContact.id ],
        value: [ passengerContact.value ],
        isConfirmed: [ passengerContact.isConfirmed ],
        oldValue: [ passengerContact.oldValue ]
      });

      const type = personContactForm.get('type');
      const data = personContactForm.get('value');

      this.validationService.updateValidationContactData(data, type.value);

      type.valueChanges.subscribe((value: any) => {
        if (!value) { return; }

        this.validationService.updateValidationContactData(data, value);
      });

      passengerContactsForm.push(personContactForm);
    });
  }

  private closeModal() {
    if (!this.addEditForm.dirty) {
      this.action.emit({ type: ModalActions.Close });

      return;
    }

    if (this.uPopupService.popupRef) { return; }

    this.uPopupService.showMessage(
      {
        showXIcon: true,
        message: 'general.closeConfirm',
        yes: 'general.yes',
        no: 'general.no'
      },
      () => {
        if (this.addEditForm.valid) {
          this.submit();
        } else {
          this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.error.passengerNotValid });
        }
      },
      () => {
        this.modalRef.hide();
        this.action.emit({ type: ModalActions.Close, value: this.editPassenger && this.editPassenger.editData && this.editPassenger.editData.id });
      }
    );
  }

  private deletePassenger() {
    const passengerId = this.addEditForm.get('details.passengerId').value;

    if (passengerId <= 0) {
      this.modalRef.hide();

      return;
    }

    this.uPopupService.showMessage(
      {
        message: 'passengers.addEdit.deleteConfirm',
        yes: 'general.yes',
        no: 'general.no'
      },
      () => {
        this.passengersService.deletePassengers([ passengerId ])
          .pipe(
            take(1)
          )
          .subscribe(() => {
            this.track(ModalActions.Delete);

            this.action.emit({ type: ModalActions.Delete });
            this.modalRef.hide();
          });
      }
    );
  }

  submit() {
    if (this.isPassengerSavingActive) {
      return;
    }

    this.track(this.editPassenger && !this.editPassenger.editData ? 'Saved new' : 'Edited passenger saved');

    if (this.addEditForm.valid && !this.addEditForm.dirty) {
      this.modalRef.hide();

      return;
    }

    this.savePassenger();
  }

  private savePassenger() {
    if (!this.addEditForm.valid) {
      this.uPopupService.showErrorMessage({ message: passengersConfig.dictionary.error.passengerNotValid });

      return;
    }

    this.isPassengerSavingActive = true;

    const stationAddress = this.addEditForm.get('details.stationAddress').value;

    this.passengersAddEditDataService.savePassengerAndCheckRoutes(
      {
        ...this.addEditForm.getRawValue(),
        details: {
          ...this.addEditForm.get('details').getRawValue(),
          stationAddress: stationAddress && Object.values(stationAddress).every(value => !!value) ? stationAddress : null
        }
      },
      AuthModulePassengersFeatureType.Generic
    )
      .pipe(
        tap(data => {
          if (!data) {
            this.isPassengerSavingActive = false;
          }
        }),
        filter(data => !!data),
        finalize(() => this.isPassengerSavingActive = false),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([ passenger, wasEditedByRoutesPopup ]) => {
        this.modalRemoveRef.hide();
        this.localizedToastrService.success('general.successful');

        this.track(this.addEditForm.get('details').value.id > 0 ? 'edit' : 'add');

        if (wasEditedByRoutesPopup && this.mode === PassengersAddEditMode.AssignToRoute) {
          const passengerRoutes = passenger.passengerRoutes;
          const removeFromCurrentRoute = this.excludedRouteId && passengerRoutes && passengerRoutes.length &&
            passengerRoutes.some(route => !route.keep && route.routeId === this.excludedRouteId);

          this.action.emit({
            type: removeFromCurrentRoute ? ModalActions.Delete : ModalActions.Submit,
            ...(removeFromCurrentRoute ? {} : { value: passenger })
          });

          return;
        }

        this.action.emit({ type: ModalActions.Submit, value: passenger });
      });
  }

  updatePersonExist(person: any) {
    this.addEditForm.get('details').patchValue({
      passengerId: 0,
      id: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      identity: person.identity,
      address: person.address,
      birthDate: person.birthDate,
      comment: person.comment,
      latitude: person.latitude,
      longitude: person.longitude
    });

    if (person.contacts) {
      this.updatePassengerContacts(person.contacts);
    }
  }

  updatePassengerExist(id: number) {
    this.passengersService.getPassengerDetails(id)
      .subscribe(passenger => {
        this.updatePassenger(passenger);
      });
  }

  checkActions(action: ModalActions) {
    switch (action) {
      case ModalActions.Submit: {
        this.submit();

        break;
      }

      case ModalActions.Delete: {
        this.deletePassenger();

        break;
      }

      case ModalActions.Shutdown: {
        this.closeModal();

        break;
      }
    }
  }

  onTabSelect(tabName: PassengersTabName, addEditForm: UntypedFormGroup) {
    this.passengersAddEditDataService.onTabSelect(tabName, addEditForm)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }
}
