import { Directive, HostListener, EventEmitter, Output } from '@angular/core';

import { WindowResize } from '@app/shared/models';

@Directive({
  selector: '[appWindowResize]',
  standalone: true
})
export class WindowResizeDirective {
  @Output() windowResize: EventEmitter<WindowResize> = new EventEmitter();

  @HostListener('window:resize', [ '$event' ]) onResize({ target }) {
    const width = target.innerWidth;
    const height = target.innerHeight;

    this.windowResize.emit({ width, height });
  }
}
