import { EntityModalButton } from '@shift/ulib';

import { ModalActions } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.accessibilityAffidavit';

const buttons: EntityModalButton[] = [
  { name: 'general.close', action: ModalActions.Close }
];

export const accessibilityAffidavitComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`
  },
  buttons
};
