<div class="demands-routes-popup__container">
  <div
    class="demands-routes-popup__close"
    (click)="closePopup()"
  ></div>
  <div class="demands-routes-popup__title-wrapper">
    <div class="demands-routes-popup__title">
      {{config.dictionary.title | translate}}
    </div>
    <div class="demands-routes-popup__title demands-routes-popup__title_two">
      {{config.dictionary.subTitle | translate}}
    </div>
  </div>

  <div class="demands-routes-popup__grid">
    <u-grid
      class="u-grid_white u-grid_app-routes-remove"
      [tableName]="'passenger_route_popup'"
      [saveTableProps]="true"
      [columns]="columns"
      [rows]="routes"
      [columnMode]="'force'"
      [headerHeight]="35"
      [footerHeight]="0"
      [rowHeight]="55"
      [reorderable]="true"
      [scrollbarV]="true"
      [scrollbarH]="false"
      [isRtl]="isRtl"
      [lang]="lang"
      [showColumnsFilter]="false"
      [messages]="'uGrid' | translate"
    ></u-grid>
  </div>

  <div class="demands-routes-popup__buttons">
    <button
      class="demands-routes-popup__buttons-cancel"
      (click)="closePopup()"
    >
      {{config.dictionary.actions.cancel | translate}}
    </button>
    <button
      class="demands-routes-popup__buttons-submit"
      (click)="save()"
    >
      {{config.dictionary.actions.save | translate}}
    </button>
  </div>

  <ng-template
    ngx-datatable-cell-template
    let-row="row"
    #direction
  >
    <div class="demands-routes-popup-direction">
      <span
        *ngIf="row.direction === routeDirection.Forward"
        class="demands-routes-popup-direction__text"
      >
        {{config.dictionary.directions.forward | translate}}
      </span>
      <span
        *ngIf="row.direction === routeDirection.Backward"
        class="demands-routes-popup-direction__text"
      >
        {{config.dictionary.directions.backward | translate}}
      </span>
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    let-row="row"
    #actionsButton
  >
    <div class="demands-routes-popup-actions">
      <button
        class="demands-routes-popup-actions__btn"
        [ngClass]="{ 'demands-routes-popup-actions__btn_active': !row.keep }"
        (click)="toggleActionButton(row, demandsPassengersRoutesActions.Remove)"
      >
        {{config.dictionary.actions.remove | translate}}
      </button>
      <button
        class="demands-routes-popup-actions__btn"
        [ngClass]="{ 'demands-routes-popup-actions__btn_active': row.keep }"
        (click)="toggleActionButton(row, demandsPassengersRoutesActions.Keep)"
      >
        {{config.dictionary.actions.keep | translate}}
      </button>
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    let-value="value"
    #normalCell
  >
    <div
      [uTooltip]="value"
      [container]="'body'"
      class="demands-routes-popup-cell"
    >
      {{value}}
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    let-value="value"
    #date
  >
    {{value | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_SHORT}}
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    let-row="row"
    #hours
  >
    <div class="demands-routes-popup-time">
      <span class="demands-routes-popup-time__hour">{{row.startTime}}</span>
      <span class="demands-routes-popup-time__divider">-</span>
      <span class="demands-routes-popup-time__hour">{{row.endTime}}</span>
      <span
        *ngIf="row.isOvernight"
        class="demands-routes-popup-time__overnight"
      >
        ({{config.dictionary.table.nextDay | translate}})
      </span>
    </div>
  </ng-template>
</div>
