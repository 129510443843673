const DICTIONARY_PATH: string = 'routes.daily.movePassengers';

export const routesDailyMovePassengersComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    loadMore: `${DICTIONARY_PATH}.loadMore`,
    clearAll: `${DICTIONARY_PATH}.clearAll`,
    openAllRides: `${DICTIONARY_PATH}.openAllRides`,
    closeAllRides: `${DICTIONARY_PATH}.closeAllRides`,
    warnings: {
      noRoutes: `${DICTIONARY_PATH}.warnings.noRoutes`,
      passengersMoving: `${DICTIONARY_PATH}.warnings.passengersMoving`
    },
    passengers: `${DICTIONARY_PATH}.passengers`,
    anonymousPassengers: `${DICTIONARY_PATH}.anonymousPassengers`,
    fromRoutes: `${DICTIONARY_PATH}.fromRoutes`
  }
};
