import { Component, HostBinding, Injector, ViewChild, computed, effect, inject, signal, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UMenuIcon, UMenuIconsModule, UTooltipDirective, UTooltipModule } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { RoutePlannerRoutesGeneration } from '@app/route-planner/models';
import { BuilderFullRoutesGenerationService } from '@app/builder/services';
import { builderFullRoutesGenerationComponentConfig } from './builder-full-routes-generation.component.config';

@Component({
  selector: 'app-builder-full-routes-generation',
  templateUrl: './builder-full-routes-generation.component.html',
  styleUrls: [ './builder-full-routes-generation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ TranslateModule, NgIf, UTooltipModule, UMenuIconsModule ],
  providers: [ AuthDataSnapshotService ]
})
export class BuilderFullRoutesGenerationComponent implements AfterViewInit {
  @HostBinding('class') hostClasses: string = 'builder-full-routes-generation';

  @ViewChild('tooltip') tooltip: UTooltipDirective;

  private readonly injector = inject(Injector);
  private readonly localizationService = inject(LocalizationService);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  private readonly builderFullRoutesGenerationService = inject(BuilderFullRoutesGenerationService);

  readonly #config = signal(cloneDeep(builderFullRoutesGenerationComponentConfig));

  readonly config = this.#config.asReadonly();
  readonly modeMenuIconsByDirectionAndFeature = computed(() =>
    this.config().modeMenuIcons[this.isRtl ? 'rtl' : 'ltr']
      .filter(viewIcon => !viewIcon.feature || this.authDataSnapshotService.checkFeature(viewIcon.feature))
  );
  readonly modeMenuIcons = computed(() =>
    this.modeMenuIconsByDirectionAndFeature()
      .map(viewIcon => ({
        ...viewIcon,
        selected: viewIcon.value === this.builderFullRoutesGenerationService.mode(),
        disabled: this.builderFullRoutesGenerationService.disabled() || (viewIcon.value === RoutePlannerRoutesGeneration.Ai ? this.builderFullRoutesGenerationService.disabledAiMode() : false)
      }))
  );
  readonly modeAi = this.builderFullRoutesGenerationService.modeAi;

  readonly isRtl: boolean = this.localizationService.isRtl();

  ngAfterViewInit() {
    effect(() => {
      if (this.builderFullRoutesGenerationService.showTooltip()) {
        this.tooltip.open();
      } else {
        this.tooltip.close();
      }
    }, { injector: this.injector });
  }

  onModeMenuIconClick(icon: UMenuIcon) {
    this.builderFullRoutesGenerationService.updateMode(icon.value as RoutePlannerRoutesGeneration);

    if (this.builderFullRoutesGenerationService.showTooltip() && this.builderFullRoutesGenerationService.modeAi()) {
      this.closeTooltip();
    }
  }

  closeTooltip() {
    this.builderFullRoutesGenerationService.updateShowTooltip(false);
  }
}
