import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RouteSplitPassenger } from '@app/route-split/models';
import { RouteDirection } from '@app/routes/models';
import { RoutePlannerService } from '@app/route-planner/services';
import { BuilderRoute } from '@app/builder/models';
import { builderFullConfig } from '@app/builder/configs';
import { BuilderRoutesStoreService } from '@app/builder/services';

@Component({
  selector: 'app-builder-full-split-route',
  templateUrl: './builder-full-split-route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderFullSplitRouteComponent implements OnInit {
  @Input() routeId: number;
  @Input() allowEmptyStations: boolean;
  @Input() routeDirection: RouteDirection;

  @Output() editRoute: EventEmitter<BuilderRoute> = new EventEmitter();

  private readonly destroyRef = inject(DestroyRef);
  private readonly routePlannerService = inject(RoutePlannerService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  public readonly bsModalRef = inject(BsModalRef);

  #routePassengers: WritableSignal<RouteSplitPassenger[]> = signal([]);
  #loading: WritableSignal<boolean> = signal(false);

  routePassengers = this.#routePassengers.asReadonly();
  loading = this.#loading.asReadonly();
  builderFullConfig = builderFullConfig;

  ngOnInit() {
    this.getSplitPassengers();
  }

  private getSplitPassengers() {
    this.routePlannerService.getSplitPassengers(this.routeId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(passengers => this.#routePassengers.set(passengers));
  }

  onContinueClick(data: { passengerIds: number[]; keepEmptyStations: boolean; }) {
    this.#loading.set(true);

    this.routePlannerService.split({
      routeId: this.routeId,
      passengerIds: data.passengerIds,
      keepEmptyStations: data.keepEmptyStations
    })
      .pipe(
        finalize(() => this.#loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(res => {
        const updatedRoute = res.find(route => route.routeId === this.routeId);
        const newRoute = res.find(route => route.routeId !== this.routeId);

        this.builderRoutesStoreService.addRouteFromSummary(newRoute);
        this.builderRoutesStoreService.updateRoute(this.routeId, {
          totalPassengers: updatedRoute.totalPassengers,
          carTypeName: updatedRoute.carType?.name,
          rideStartDateTime: updatedRoute.startTime,
          rideEndDateTime: updatedRoute.endTime,
          branchNames: updatedRoute.branchNames
        });

        this.editRoute.emit(this.builderRoutesStoreService.routes().find(route => route.routeId === newRoute.routeId));

        this.bsModalRef.hide();
      });
  }
}
