const DICTIONARY_PATH: string = 'shared.documents';

export const documentsComponentConfig = {
  dictionary: {
    newDocument: `${DICTIONARY_PATH}.newDocument`,
    document: `${DICTIONARY_PATH}.document`,
    documentName: `${DICTIONARY_PATH}.documentName`,
    attachFile: `${DICTIONARY_PATH}.attachFile`,
    uInputDate: 'uInputDate'
  }
};
