const DICTIONARY_PATH: string = 'builder.details';

export const builderDetailsComponentConfig = {
  dictionary: {
    name: `${DICTIONARY_PATH}.name`,
    required: `${DICTIONARY_PATH}.required`,
    manualRouteName: `${DICTIONARY_PATH}.manualRouteName`,
    number: `${DICTIONARY_PATH}.number`,
    direction: `${DICTIONARY_PATH}.direction`,
    creationDate: `${DICTIONARY_PATH}.creationDate`,
    period: `${DICTIONARY_PATH}.period`,
    activeDays: `${DICTIONARY_PATH}.activeDays`,
    customers: `${DICTIONARY_PATH}.customers`,
    select: `${DICTIONARY_PATH}.select`,
    command: `${DICTIONARY_PATH}.command`,
    department: `${DICTIONARY_PATH}.department`,
    departmentDivision: `${DICTIONARY_PATH}.departmentDivision`,
    region: `${DICTIONARY_PATH}.region`,
    orderPurpose: `${DICTIONARY_PATH}.orderPurpose`,
    budgetItem: `${DICTIONARY_PATH}.budgetItem`,
    contractCode: `${DICTIONARY_PATH}.contractCode`,
    regulationNumber: `${DICTIONARY_PATH}.regulationNumber`,
    comment: `${DICTIONARY_PATH}.comment`,
    routeType: `${DICTIONARY_PATH}.routeType`,
    requiresApproval: `${DICTIONARY_PATH}.requiresApproval`,
    emptyStations: {
      title: `${DICTIONARY_PATH}.emptyStations.title`,
      warning: `${DICTIONARY_PATH}.emptyStations.warning`
    },
    contact: `${DICTIONARY_PATH}.contact`,
    contactName: `${DICTIONARY_PATH}.contactName`,
    mobile: `${DICTIONARY_PATH}.mobile`,
    mobileNumber: `${DICTIONARY_PATH}.mobileNumber`,
    forward: 'builder.options.directions.forward',
    backward: 'builder.options.directions.backward',
    removeSelect: 'general.removeSelect',
    inputDateRange: 'uInputDateRange',
    selectDays: 'uSelectDays',
    selectS: 'uSelectS',
    multiselect: 'uMultiselect',
    toggle: 'uToggle'
  }
};
