<ng-container *ngIf="{
  routesQueueCounter: routesDailyMovePassengersDataService.routesQueueCounter$ | async,
  loadedPassengerRoutes: routesDailyMovePassengersDataService.loadedPassengerRoutes$ | async,
  availableRoutes: routesDailyMovePassengersDataService.availableRoutes$ | async,
  dragActive: routesDailyMovePassengersDataService.dragActive$ | async,
  passengerRoutesExpanded: routesDailyMovePassengersDataService.passengerRoutesExpanded$ | async
} as obsObj">
  <div class="routes-daily-move-passengers__header">
    <div class="routes-daily-move-passengers__header-section">
      <div class="routes-daily-move-passengers__header-text">
        {{config.dictionary.title | translate}}
      </div>
      <div
        class="u-icon u-icon_app-info"
        [uTooltip]="config.dictionary.warnings.passengersMoving | translate"
        [tooltipClass]="'u-tooltip_general'"
        [placement]="[ 'top' ]"
        [container]="'body'"
      ></div>
    </div>
    <div class="routes-daily-move-passengers__header-section">
      <div class="routes-daily-move-passengers__header-resize-icon u-icon u-icon_app-expand"></div>
      <div
        class="routes-daily-move-passengers__header-close-icon u-icon u-icon_app-close-gray"
        [uId]="'routes-daily-move-passengers-close'"
        (click)="closeAction.emit()"
      ></div>
    </div>
  </div>
  <div
    *ngIf="!obsObj.loadedPassengerRoutes.length"
    class="routes-daily-move-passengers__warning"
  >
    {{config.dictionary.warnings.noRoutes | translate}}
  </div>
  <div
    *ngIf="obsObj.loadedPassengerRoutes.length"
    class="routes-daily-move-passengers__buttons"
  >
    <button
      uButton
      class="routes-daily-move-passengers__button"
      [uId]="'routes-daily-move-passengers-clear-all'"
      (click)="routesDailyMovePassengersDataService.uncheckAll()"
    >
      {{config.dictionary.clearAll | translate}}
    </button>
    <button
      uButton
      class="routes-daily-move-passengers__button routes-daily-move-passengers__button_with-divider"
      [uId]="'routes-daily-move-passengers-toggle-all-rides'"
      (click)="routesDailyMovePassengersDataService.updatePassengerRoutesExpanded(!obsObj.passengerRoutesExpanded)"
    >
      {{(obsObj.passengerRoutesExpanded ? config.dictionary.closeAllRides : config.dictionary.openAllRides) | translate}}
    </button>
  </div>
  <div
    class="routes-daily-move-passengers__routes"
    cdkDropList
    cdkScrollable
    cdkDropListSortingDisabled
    [cdkDropListConnectedTo]="obsObj.availableRoutes | routesDailyMovePassengersRouteIds: obsObj.loadedPassengerRoutes"
  >
    <div
      *ngFor="let route of obsObj.loadedPassengerRoutes; let idx = index; trackBy: trackByRouteId"
      class="routes-daily-move-passengers__route"
      cdkDrag
      cdkDropList
      cdkScrollable
      cdkDropListSortingDisabled
      [id]="routesDailyMovePassengersConfig.idPrefixes.passengerRoute + '-' + route.routeId"
      [ngClass]="{
        'routes-daily-move-passengers__route_disabled': route.disabled || route.invalidDirection || (route.checked && obsObj.dragActive),
        'routes-daily-move-passengers__route_hovered-by-drag-item': route.hoveredByDragItem
      }"
      [cdkDropListConnectedTo]="obsObj.availableRoutes | routesDailyMovePassengersRouteIds: obsObj.loadedPassengerRoutes"
      [cdkDragDisabled]="!route.passengers.length"
      (cdkDragStarted)="routesDailyMovePassengersDataService.onPassengersRouteDragStart($event, route, idx)"
      (cdkDragDropped)="routesDailyMovePassengersDataService.onDragDropped()"
      (cdkDropListEntered)="routesDailyMovePassengersDataService.onDropListEntered($event, route)"
      (cdkDropListExited)="routesDailyMovePassengersDataService.onDropListExited(route)"
      (cdkDropListDropped)="routesDailyMovePassengersDataService.onDropListDropped($event, route)"
    >
      <div class="routes-daily-move-passengers__item routes-daily-move-passengers__item_header">
        <div class="routes-daily-move-passengers__item-section">
          <u-checkbox
            class="u-checkbox_box u-checkbox_app-routes-daily-move-passengers routes-daily-move-passengers__item-checkbox"
            [uId]="'routes-daily-move-passengers-route-checkbox-' + route.routeId"
            [disabled]="!route.passengers.length"
            [(ngModel)]="route.checked"
            (ngModelChange)="routesDailyMovePassengersDataService.onPassengerRouteCheckedChange(route)"
          ></u-checkbox>
          <div
            class="routes-daily-move-passengers__item-section-title"
            (click)="routesDailyMovePassengersDataService.updatePassengerRouteExpanded(route.routeId, route.expanded)"
          >
            {{route.name}}
          </div>
          <div
            *ngIf="route.passengers?.length"
            class="routes-daily-move-passengers__item-section-arrow"
            [ngClass]="{
              'routes-daily-move-passengers__item-section-arrow_inversed': route.expanded
            }"
            (click)="routesDailyMovePassengersDataService.updatePassengerRouteExpanded(route.routeId, route.expanded)"
          ></div>
        </div>
        <div class="routes-daily-move-passengers__item-section routes-daily-move-passengers__item-section_drawer">
          <div class="routes-daily-move-passengers__item-section-subtitle">
            {{route.number}}
          </div>
          <div class="routes-daily-move-passengers__item-section-subtitle">
            {{route.startTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT}} -
            {{route.endTime | momentConvert : appConstants.TIME_FORMAT_FULL : appConstants.TIME_FORMAT}}
          </div>
          <div
            *ngIf="route.vehicleTypeName"
            class="routes-daily-move-passengers__item-section-subtitle"
          >
            {{route.vehicleTypeName}}
          </div>
        </div>
      </div>
      <ng-container *ngIf="route.expanded">
        <div
          *ngFor="let passenger of route.passengers; let passengerIdx = index; trackBy: trackByPassengerId"
          class="routes-daily-move-passengers__item"
          cdkDrag
          [ngClass]="{
            'routes-daily-move-passengers__item_disabled': passenger.disabled || (passenger.checked && obsObj.dragActive)
          }"
          (cdkDragStarted)="routesDailyMovePassengersDataService.onPassengerDragStart($event, route, passenger, passengerIdx)"
        >
          <div class="routes-daily-move-passengers__item-section">
            <u-checkbox
              class="u-checkbox_box u-checkbox_app-routes-daily-move-passengers routes-daily-move-passengers__item-checkbox"
              [uId]="'routes-daily-move-passengers-passenger-checkbox-' + route.routeId + '-' + (passenger.passengerId || passenger.rideStationId)"
              [(ngModel)]="passenger.checked"
              (ngModelChange)="
                passenger.isAnonymous ? routesDailyMovePassengersDataService.onAnonymousPassengerCheckedChange(route, passenger) :
                routesDailyMovePassengersDataService.onPassengerCheckedChange(route, passenger)
              "
            ></u-checkbox>
            <div
              *ngIf="!passenger.isAnonymous"
              class="routes-daily-move-passengers__item-section-container"
              [uId]="'routes-daily-move-passengers-name-mobile-' + route.routeId + '-' + passenger.passengerId"
            >
              <div class="routes-daily-move-passengers__item-section-text">
                {{passenger.name}}
              </div>
              <div class="routes-daily-move-passengers__item-section-text routes-daily-move-passengers__item-section-text_no-end-dots">
                {{passenger.mobile}}
              </div>
            </div>
            <div
              *ngIf="passenger.isAnonymous"
              class="routes-daily-move-passengers__item-section-container"
              [uId]="'routes-daily-move-passengers-name-mobile-' + + route.routeId + '-' + passenger.rideStationId"
            >
              {{passenger.amount}} {{config.dictionary.anonymousPassengers | translate}}
            </div>
          </div>
          <div
            *ngIf="!passenger.isAnonymous"
            class="routes-daily-move-passengers__item-section routes-daily-move-passengers__item-section_drawer"
          >
            <div class="routes-daily-move-passengers__item-section-text">
              {{passenger.stationName}}
            </div>
          </div>
          <ng-container *cdkDragPlaceholder></ng-container>
          <div
            *cdkDragPreview
            class="routes-daily-move-passengers-drag-preview-wrapper"
          >
            <ng-container *ngTemplateOutlet="dragPreview; context: { passenger: passenger }"></ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *cdkDragPlaceholder></ng-container>
      <div
        *cdkDragPreview
        class="routes-daily-move-passengers-drag-preview-wrapper"
      >
        <ng-container *ngTemplateOutlet="dragPreview"></ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="obsObj.loadedPassengerRoutes.length || obsObj.routesQueueCounter"
    class="routes-daily-move-passengers__footer"
  >
    <button
      uButton
      class="u-font-weight-bold"
      [disabled]="!obsObj.routesQueueCounter"
      (click)="routesDailyMovePassengersDataService.loadMorePassengerRoutes()"
    >
      {{config.dictionary.loadMore | translate}} {{obsObj.routesQueueCounter ? '(' + obsObj.routesQueueCounter + ')' : ''}}
    </button>
  </div>
</ng-container>

<ng-template
  #dragPreview
  let-passenger="passenger"
>
  <ng-container *ngIf="{
    invalidPassengers: routesDailyMovePassengersDataService.invalidPassengers$ | async,
    checkedEntitiesAmount: routesDailyMovePassengersDataService.checkedEntitiesAmount$ | async
  } as obsObj">
    <div
      class="routes-daily-move-passengers-drag-preview"
      [class.routes-daily-move-passengers-drag-preview_invalid]="obsObj.invalidPassengers.length"
    >
      <ng-container *ngIf="obsObj.checkedEntitiesAmount.passengers > 1; else dragPreviewSinglePassenger;">
        <div
          class="routes-daily-move-passengers-drag-preview__section"
        >
          <span class="routes-daily-move-passengers-drag-preview__section-text">
            {{obsObj.checkedEntitiesAmount.passengers}} {{config.dictionary.passengers | translate}}
          </span>
          <span class="routes-daily-move-passengers-drag-preview__section-pipe">
            |
          </span>
          <span
            class="routes-daily-move-passengers-drag-preview__section-text"
            [translate]="config.dictionary.fromRoutes"
            [translateParams]="{ amount: obsObj.checkedEntitiesAmount.routes }"
          ></span>
        </div>
        <ng-container *ngIf="obsObj.invalidPassengers.length">
          <div
            *ngFor="let invalidPassenger of obsObj.invalidPassengers; trackBy: trackByPassengerId"
            class="routes-daily-move-passengers-drag-preview__section-wrapper"
          >
            <div
              class="routes-daily-move-passengers-drag-preview__section routes-daily-move-passengers-drag-preview__section_with-gap
              routes-daily-move-passengers-drag-preview__section_with-bullet"
            >
              <ng-container *ngTemplateOutlet="dragPreviewPassengerInfo; context: {
                passenger: invalidPassenger
              }"></ng-container>
            </div>
            <div
              *ngFor="let error of invalidPassenger.errors"
              class="routes-daily-move-passengers-drag-preview__section routes-daily-move-passengers-drag-preview__section_with-error"
            >
              {{error | translate}}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #dragPreviewSinglePassenger>
        <div
          *ngIf="passenger"
          class="routes-daily-move-passengers-drag-preview__section-wrapper"
        >
          <div class="routes-daily-move-passengers-drag-preview__section routes-daily-move-passengers-drag-preview__section_with-gap">
            <ng-container *ngTemplateOutlet="dragPreviewPassengerInfo; context: {
              passenger: passenger
            }"></ng-container>
          </div>
          <div class="routes-daily-move-passengers-drag-preview__section">
            <div class="routes-daily-move-passengers-drag-preview__section-subtitle">
              {{passenger.stationName}}
            </div>
          </div>
          <ng-container *ngIf="obsObj.invalidPassengers.length">
            <div
              *ngFor="let error of obsObj.invalidPassengers[0].errors"
              class="routes-daily-move-passengers-drag-preview__section routes-daily-move-passengers-drag-preview__section_with-error"
            >
              {{error | translate}}
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-template>

<ng-template
  #dragPreviewPassengerInfo
  let-passenger="passenger"
>
  <div
    *ngIf="!passenger.isAnonymous"
    class="routes-daily-move-passengers-drag-preview__section-title"
  >
    {{passenger.name}}
  </div>
  <div
    *ngIf="!passenger.isAnonymous"
    class="routes-daily-move-passengers-drag-preview__section-title"
  >
    {{passenger.mobile}}
  </div>
  <div
    *ngIf="passenger.isAnonymous"
    class="routes-daily-move-passengers-drag-preview__section-title"
  >
    {{passenger.amount}} {{config.dictionary.anonymousPassengers | translate}}
  </div>
</ng-template>
