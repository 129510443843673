export * from './routes/routes.component';
export * from './routes-daily/routes-daily.component';
export * from './routes-daily/routes-daily-approvals/routes-daily-approvals.component';
export * from './routes-daily/routes-daily-fine/routes-daily-fine.component';
export * from './routes-daily/routes-daily-warning/routes-daily-warning.component';
export * from './routes-daily/routes-daily-move-passengers/routes-daily-move-passengers.component';
export * from './routes-weekly/routes-weekly.component';
export * from './routes-move-passengers/routes-move-passengers.component';
export * from './routes-temp-comment/routes-temp-comment.component';
export * from './routes-cancel-ride/routes-cancel-ride.component';
export * from './routes-restore-ride/routes-restore-ride.component';
export * from './routes-period/routes-period.component';
export * from './routes-periods/routes-periods.component';
export * from './routes-ride-passengers/routes-ride-passengers.component';
export * from './routes-ride-passengers-customers/routes-ride-passengers-customers.component';
export * from './routes-change/routes-change.component';
export * from './routes-change/routes-change-hour/routes-change-hour.component';
export * from './routes-change/routes-change-accompany/routes-change-accompany.component';
export * from './routes-change/routes-change-accompany-cost/routes-change-accompany-cost.component';
export * from './routes-change/routes-change-passenger/routes-change-passenger.component';
export * from './routes-change/routes-change-shuttle-company/routes-change-shuttle-company.component';
export * from './routes-change/routes-change-time-tracking/routes-change-time-tracking.component';
export * from './routes-change/routes-change-car/routes-change-car.component';
export * from './routes-change/routes-change-supervisor/routes-change-supervisor.component';
export * from './routes-export/routes-export.component';
export * from './routes-ride-drivers/routes-ride-drivers.component';
