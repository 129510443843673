import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { SignalRService } from '@app/shared/services';
import { NextYearOnProgress } from '@app/next-year/models';

@Injectable({
  providedIn: 'root'
})
export class NextYearHubService extends SignalRService  {
  BASE_PATH = 'nextYearHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onInititalized(): Observable<void> {
    return this.onChannel<void>('onInititalized');
  }

  onProgress(): Observable<NextYearOnProgress> {
    return this.onChannel<NextYearOnProgress>('onProgress');
  }

  onSuccess(): Observable<void> {
    return this.onChannel<void>('onSuccess');
  }

  onFailed(): Observable<void> {
    return this.onChannel<void>('onFailed');
  }

  stopNextYearProcess() {
    if (this.isConnected) { this.signalRClient.invoke('Cancel'); }
  }
}
