import { KeyCode, UStep } from '@shift/ulib';

import { SelectSItem } from '@app/shared/models';
import { mapConfig } from '@app/shared/configs';
import { AuthModuleName, AuthModuleRoutePlannerFeature, AuthModuleRoutePlannerFeatureDirections } from '@app/auth/models';
import {
  RoutePlannerDirectionType,
  RoutePlannerExistingRouteType,
  RoutePlannerRouteGenerationMode
} from '@app/route-planner/models';
import {
  BuilderTimeType,
  BuilderFullCostType,
  BuilderFullStep,
  BuilderFullRoutesFor,
  BuilderFullOptions,
  BuilderFullConfigData
} from '@app/builder/models';

const DICTIONARY_PATH: string = 'builder.full';

const optionsDefault: BuilderFullOptions = {
  routesFor: [
    { value: BuilderFullRoutesFor.All, name: 'builder.full.filter.options.routesFor.all' },
    { value: BuilderFullRoutesFor.Schools, name: 'builder.full.filter.options.routesFor.schoolsOnly' },
    { value: BuilderFullRoutesFor.Activities, name: 'builder.full.filter.options.routesFor.activitiesOnly' }
  ],
  directions: [
    { value: RoutePlannerDirectionType.Forward, name: 'builder.full.filter.options.directions.forward' },
    { value: RoutePlannerDirectionType.Backward, name: 'builder.full.filter.options.directions.backward' },
    {
      value: RoutePlannerDirectionType.BackwardAndForward,
      name: 'builder.full.filter.options.directions.backwardAndForward',
      feature: {
        module: AuthModuleName.RoutePlanner,
        name: AuthModuleRoutePlannerFeature.Directions,
        values: [ AuthModuleRoutePlannerFeatureDirections.PickupDropOff ]
      }
    }
  ],
  timeTypes: [
    { value: BuilderTimeType.StartTime, name: 'builder.full.filter.options.timeTypes.startTime' },
    { value: BuilderTimeType.EndTime, name: 'builder.full.filter.options.timeTypes.endTime' }
  ],
  pricingTypes: [
    { value: BuilderFullCostType.FixedPrice, name: 'builder.full.filter.options.pricingTypes.fixed' },
    { value: BuilderFullCostType.AccordingToContract, name: 'builder.full.filter.options.pricingTypes.accordingToContract' }
  ],
  planningTypes: [
    { value: RoutePlannerRouteGenerationMode.New, name: 'builder.full.filter.options.planningTypes.optimizedRoutes' },
    { value: RoutePlannerRouteGenerationMode.ByTemplate, name: 'builder.full.filter.options.planningTypes.templates' }
  ],
  routeTypes: [],
  shuttleCompanies: [],
  vehicleTypes: []
};

const options: BuilderFullOptions = {
  ...optionsDefault
};

const existingRouteTypeOnTemplateOption: SelectSItem = {
  value: RoutePlannerExistingRouteType.ExistingTypeOnTemplate,
  name: `${DICTIONARY_PATH}.filter.options.routeTypes.existingTypeOnTemplate`
};

const configData: BuilderFullConfigData = {
  sessionId: null,
  maxDate: null,
  minDate: null,
  routePolicy: null,
  cityCombinations: {
    citiesTree: {
      cities: [],
      showBranchHierarchy: false
    },
    rules: [],
    useTollRoads: false
  },
  shuttleCompanies: [],
  vehicleTypes: [],
  shifts: [],
  routePlannerPassengersAmountRequestLimit: null,
  branches: [],
  routeTypes: [],
  isDemandsPlanningEnabled: false,
  isTemplatesPlanningEnabled: false
};

const steps: UStep[] = [
  {
    name: `${DICTIONARY_PATH}.steps.selectPassengers`,
    value: BuilderFullStep.SelectPassengers,
    active: true
  },
  {
    name: `${DICTIONARY_PATH}.steps.planningDetails`,
    value: BuilderFullStep.PlanningDetails,
    active: true
  },
  {
    name: `${DICTIONARY_PATH}.steps.routesResults`,
    value: BuilderFullStep.RoutesResults,
    disabled: true
  }
];

export const builderFullConfig = {
  trackingId: 'Route planner',
  algorithmHotkeys: [
    [ KeyCode.ShiftLeft, KeyCode.ControlLeft, KeyCode.KeyS ],
    [ KeyCode.ShiftRight, KeyCode.ControlRight, KeyCode.KeyS ],
    [ KeyCode.ControlLeft, KeyCode.ShiftLeft, KeyCode.KeyS ],
    [ KeyCode.ControlRight, KeyCode.ShiftRight, KeyCode.KeyS ]
  ],
  steps,
  calculationRoutes: {
    progressbar: {
      value: 5, // Default value for progress bar, means 5%
      increaseStep: 5, // Default value for increasing progress bar, means 5% per one iteration
      timeInterval: 1000 // Default time interval for increasing progress bar
    }
  },
  map: {
    coords: mapConfig.coords,
    zoom: mapConfig.zoom,
    maxZoom: mapConfig.maxZoom,
    markers: {
      passenger: {
        icon: {
          url: '/assets/images/map/point-passenger.svg',
          width: 48,
          height: 48
        }
      },
      destination: {
        icon: {
          url: '/assets/images/map/point-destination.svg',
          width: 48,
          height: 48
        }
      }
    }
  },
  optionsDefault,
  options,
  configData,
  existingRouteTypeOnTemplateOption,
  dictionary: {
    ridesWithoutShuttleCount: `${DICTIONARY_PATH}.routes.ridesWithoutShuttleCount`
  }
};
