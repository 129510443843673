export interface HeaderDashboard {
  rideId: number;
}

export interface HeaderDashboardDetails {
  activeRideId: number;
  isUpdate: boolean;
  isShowPassengers: boolean;
  tracksTraveling: number;
  passed: number;
}
