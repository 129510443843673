import {
  Action,
  combineReducers
} from '@ngrx/store';

import * as coreReducers from '@app/shared/state/reducers';
import * as authStatusReducer from '@app/auth/state/reducers/auth-status.reducer';
import * as authCountdownReducer from '@app/auth/state/reducers/auth-countdown.reducer';

export const FEATURE_KEY = 'auth';

export interface AuthState {
  status: authStatusReducer.State;
  countdown: authCountdownReducer.State;
}

export interface State extends coreReducers.State {
  [FEATURE_KEY]: AuthState;
}

export function reducers(state: AuthState | undefined | any, action: Action) {
  return combineReducers({
    [authStatusReducer.FEATURE_KEY]: authStatusReducer.reducer,
    [authCountdownReducer.FEATURE_KEY]: authCountdownReducer.reducer
  })(state, action);
}
