import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'builderAuditRouteNumber'
})
export class BuilderAuditRouteNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return !isNaN(value) ? parseFloat(value) : 0;
  }
}
