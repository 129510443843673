import { URangePreset } from '@shift/ulib';

import { MovePassengersRoute, MovePassengersRouteMapViewStationType, MovePassengersRouteMapViewWaypoint, MovePassengersRouteBase } from '@app/shared/models';

export enum RoutesMovePassengersSaveActionType {
  SaveAndClose = 'saveAndClose',
  SaveAndSendToSC = 'saveAndSendToSC',
  Save = 'save'
}

export enum RoutesMovePassengersFilterType {
  EducationalInstitution = 'educationalInstitution',
  ActivityCenters = 'activityCenters'
}

export interface RoutesMovePassengersSaveAction {
  name: string;
  value: string;
}

export interface RoutesMovePassengersActionParamsStations {
  rideStationId: number;
  passengerIds: number[];
}

export interface RoutesMovePassengersActionParams {
  type: number;
  routeId: number;
  stations: RoutesMovePassengersActionParamsStations[];
  dateFrom: string;
  dateTo: string;
  days: number[];
  allowCapacityChange: boolean;
}

export interface RoutesMovePassengersPassengerBase {
  passengerId: number;
  name: string;
}

export interface RoutesMovePassengersPassenger extends RoutesMovePassengersPassengerBase {
  checked: boolean;
  isDragging: boolean;
}

export interface RoutesMovePassengersStationBase {
  rideStationId: number;
  rideId: number;
  name: string;
  address: string;
  time: string;
  anonymousPassengersCount: number;
  passengers: RoutesMovePassengersPassengerBase[];
}

export interface RoutesMovePassengersStation extends RoutesMovePassengersStationBase {
  checked: boolean;
  isDragging: boolean;
  passengers: RoutesMovePassengersPassenger[];
}

export interface RoutesMovePassengersRouteMapViewGetParams {
  routes: {
    routeId: number;
    rideId: number;
  }[];
}

export interface RoutesMovePassengersRouteMapViewStation {
  rideStationId: number;
  type: MovePassengersRouteMapViewStationType;
  name: string;
  arrivalTime: string;
  address: string;
  latitude: number;
  longitude: number;
}

export interface RoutesMovePassengersRouteMapView {
  encodedPolylineJson: string;
  distance: number;
  duration: string;
  rideId: number;
  routeId: number;
  stations: RoutesMovePassengersRouteMapViewStation[];
  waypoints: MovePassengersRouteMapViewWaypoint[];
}

export interface RoutesMovePassengersActionData {
  stations: RoutesMovePassengersStationBase[];
  updatedRoute: RoutesMovePassengersRouteBase;
}

export interface RoutesMovePassengersGetRoutes {
  stations: RoutesMovePassengersStationBase[];
  routes: RoutesMovePassengersRouteBase[];
}

export class RoutesMovePassengersRoute extends MovePassengersRoute {}

export interface RoutesMovePassengersRouteInitData {
  routeId: number;
  code: number;
  name: string;
  direction: number;
  routeStartDate: string;
  routeEndDate: string;
  days: number[];
  activeDate: string;
  activeDay: number;
}

export class RoutesMovePassengersRouteBase extends MovePassengersRouteBase {}

export interface RoutesMovePassengersGetRoutesParams {
  dateFrom: string;
  dateTo: string;
  days: number[];
}

export interface RoutesMovePassengersForm {
  typeChange: number;
  dates: string[];
  dateFrom: string;
  dateTo: string;
  type: string;
  availablePresets: URangePreset[];
  checkDaysActive: number[];
  checkDaysAvailable: number[];
  typeFilter: RoutesMovePassengersFilterType;
  branchIds?: number[];
  schoolIds?: number[];
}

export interface RoutesMovePassengersFinish {
  routesLastAuditLogIds: number[];
}

export interface RoutesMovePassengersChangesOptions {
  type: number;
  dateFrom: string;
  dateTo: string;
  days: number[];
  branchIds?: number[];
  schoolIds?: number[];
}
