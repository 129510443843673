import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'movePassengersRouteDistance',
  standalone: true
})
export class MovePassengersRouteDistancePipe implements PipeTransform {
  transform(value: number): string {
    if (!value) { return '0.00'; }

    return value.toFixed(2);
  }
}
