import {
  Component,
  DestroyRef,
  HostBinding,
  OnDestroy,
  OnInit,
  inject,
  output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UButtonSize, USidebarMenuService } from '@shift/ulib';

import { LocalizationService, TrackingService } from '@app/shared/services';
import { RoutePlannerRouteGenerationMode } from '@app/route-planner/models';
import { BuilderFullCostType, BuilderFullRoutesFor, BuilderFullStep } from '@app/builder/models';
import { BuilderFullCalculationService, BuilderFullCommonService, BuilderRoutesStoreService } from '@app/builder/services';
import { builderFullConfig } from '@app/builder/configs';
import { builderFullFilterComponentConfig } from './builder-full-filter.component.config';

@Component({
  selector: 'app-builder-full-filter',
  templateUrl: './builder-full-filter.component.html',
  styleUrls: [ './builder-full-filter.component.scss', './builder-full-filter.component.rtl.scss' ]
})
export class BuilderFullFilterComponent implements OnInit, OnDestroy {
  readonly loadMap = output<void>();
  readonly toggleSettings = output<void>();
  readonly loadPassengersGrid = output<void>();

  @HostBinding('class') hostClasses: string = 'builder-full-filter';

  private readonly destroyRef = inject(DestroyRef);
  private readonly uSidebarMenuService = inject(USidebarMenuService);
  private readonly localizationService = inject(LocalizationService);
  private readonly builderRoutesStoreService = inject(BuilderRoutesStoreService);
  private readonly builderFullCommonService = inject(BuilderFullCommonService);
  private readonly trackingService = inject(TrackingService);
  public readonly builderFullCalculationService = inject(BuilderFullCalculationService);

  private formValueChangesSub: Subject<void>;

  readonly options = this.builderFullCalculationService.options;
  readonly configData = this.builderFullCalculationService.configData;

  isRtl: boolean = this.localizationService.isRtl();
  config = builderFullFilterComponentConfig;
  uButtonSize = UButtonSize;
  builderFullCostType = BuilderFullCostType;
  builderFullRoutesFor = BuilderFullRoutesFor;
  routePlannerRouteGenerationMode = RoutePlannerRouteGenerationMode;

  ngOnInit() {
    this.onSideMenuCollapsed();
    this.onCustomerIdChanges();
  }

  ngOnDestroy() {
    if (this.formValueChangesSub) {
      this.formValueChangesSub.next();
      this.formValueChangesSub.complete();
    }
  }

  private onSideMenuCollapsed() {
    this.uSidebarMenuService.collapsed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(collapsed =>
        this.updateRouteTemplatesModalClassNames(collapsed)
      );
  }

  private updateRouteTemplatesModalClassNames(collapsed: boolean) {
    this.config.templatesFilterInputModal = {
      ...this.config.templatesFilterInputModal,
      modalClassNames: `u-modal u-modal_content u-modal_app-route-templates ${collapsed ? 'u-modal_app-route-templates-hide-menu' : ''}`
    };
  }

  private onCustomerIdChanges() {
    this.form.get('customerId')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(customerId => this.updateRouteTemplatesModalCustomerId(customerId));
  }

  private updateRouteTemplatesModalCustomerId(customerId: number) {
    this.config.templatesFilterInputModal = {
      ...this.config.templatesFilterInputModal,
      contentComponentInputs: {
        ...this.config.templatesFilterInputModal.contentComponentInputs,
        customerId
      }
    };
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${builderFullConfig.trackingId}] - ${event}`);
  }

  get form(): UntypedFormGroup {
    return this.builderFullCalculationService.filter;
  }

  get visibleComponents() {
    return this.builderFullCommonService.visibleComponents;
  }

  get passengers() {
    return this.builderFullCommonService.passengers;
  }

  calculate() {
    this.trackEvent('click on calculate');

    this.builderRoutesStoreService.resetMessagesTable();
    this.builderFullCalculationService.calculateRoutes();

    if (this.formValueChangesSub) {
      this.formValueChangesSub.next();
      this.formValueChangesSub.complete();
    }

    this.formValueChangesSub = new Subject();

    this.builderFullCalculationService.form.valueChanges
      .pipe(takeUntil(this.formValueChangesSub))
      .subscribe(() => {
        this.builderFullCommonService.updateSteps([
          {
            value: BuilderFullStep.RoutesResults,
            disabled: true,
            clickable: false
          }
        ]);

        this.formValueChangesSub.next();
        this.formValueChangesSub.complete();
      });
  }
}
