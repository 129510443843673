import { GlobalConfig } from 'ngx-toastr';

const globalConfig: GlobalConfig = {
  autoDismiss: false,
  countDuplicates: false,
  iconClasses: {
    error: 'toast-error',
    info: 'toast-info',
    success: 'toast-success',
    warning: 'toast-warning'
  },
  maxOpened: 0,
  newestOnTop: true,
  preventDuplicates: false,
  resetTimeoutOnDuplicate: false,
  closeButton: false,
  disableTimeOut: false,
  easeTime: 0,
  easing: 'ease',
  enableHtml: false,
  extendedTimeOut: 100,
  messageClass: 'toast-message',
  onActivateTick: false,
  positionClass: 'shift-toast',
  progressAnimation: undefined,
  progressBar: false,
  tapToDismiss: true,
  timeOut: 2000,
  titleClass: 'toast-title',
  toastClass: 'toast-title',
  includeTitleDuplicates: false,
  payload: undefined
};


export const toastrConfig = {
  globalConfig,
  globalConfigWithFilters: {
    ...globalConfig,
    positionClass: 'shift-toast-with-filters'
  }
};
