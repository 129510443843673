import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RouteSyncBody } from '@app/route-sync/models';

@Injectable({
  providedIn: 'root'
})
export class RouteSyncService {
  private readonly apiService = inject(ApiService);

  private readonly BASE_PATH: string = 'RouteSync';

  getMaxDaysRange(): Observable<number> {
    return this.apiService.get(`${this.BASE_PATH}/MaxDaysRange`);
  }

  saveRouteSync(body: RouteSyncBody): Observable<void> {
    return this.apiService.post(this.BASE_PATH, body);
  }
}
