export class InputShift {
  shiftId: number = null;
  name: string = '';
  days: InputShiftDay[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface InputShiftDay {
  active: boolean;
  dayOfWeek: number;
  startTime: string;
  endTime: string;
}

export interface InputShiftDayOfWeek {
  check: boolean;
  dayOfWeek: number;
  startTime: string;
  endTime: string;
}
