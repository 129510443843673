<div class="input-shifts__main" *ngIf="form">
  <div class="input-shifts__header">
    <div class="u-text-line u-text-line_border-off input-shifts__header-title">{{dictionary.title | translate}}</div>
    <button
      uButton
      class="u-font-weight-medium input-shifts__header-action"
      (click)="inputShiftsService.addShift(); sendEventData(eventDataType.Click, tracking.add)"
    >
      {{dictionary.newShift | translate}}
    </button>
  </div>
  <div
    *ngIf="!form.controls.length && branchesFeatureType && branchesFeatureType !== authModuleBranchesFeatureType.ActivityCenter"
    class="input-shifts__warning"
  >
    {{dictionary.warning | translate}}
  </div>
  <div class="input-shifts__items">
    <div
      *ngFor="let shiftForm of form.controls; let index = index"
      [formGroup]="shiftForm"
      class="input-shifts__item"
    >
      <u-group
        class="u-group_large-field input-shifts__group"
        [name]="dictionary.shiftName | translate"
      >
        <input
          uInput
          type="text"
          [placeholder]="dictionary.placeholder | translate"
          [valid]="!shiftForm.get('name').pending ? shiftForm.get('name').valid : true"
          formControlName="name"
        >
        <div class="input-shifts__actions">
          <div
            class="input-shifts__actions-btn input-shifts__actions-btn_remove"
            (click)="removeShift(index); sendEventData(eventDataType.Click, tracking.delete)"
          ></div>
        </div>
      </u-group>
      <u-hour-schedule
        [havePopup]="true"
        [messages]="dictionary.schedule | translate"
        [alwaysDayCopy]="true"
        [disabledValidation]="true"
        [defaultTime]="false"
        formControlName="days"
      ></u-hour-schedule>
    </div>
  </div>
</div>
