<div class="emails-send-shuttle-companies-changes__wrapper">
  <div class="emails-send-shuttle-companies-changes__header">
    <span class="emails-send-shuttle-companies-changes__header-title">
      {{config.dictionary.title | translate}}
    </span>
    <div class="emails-send-shuttle-companies-changes__header-close" (click)="closeClick()"></div>
  </div>
  <u-group
    class="u-group u-group_app-emails-send-shuttle-companies-changes"
    [name]="config.dictionary.datesFiltrationType.title | translate"
  >
    <u-select-s 
      [items]="config.datesFiltrationType" 
      [placeholder]="config.dictionary.select | translate"
      [formControl]="filtersForm.controls['datesFiltrationType']"
      [valid]="filtersForm.controls['datesFiltrationType'].valid"
      [messages]="config.dictionary.uSelectS | translate"
      [empty]="false"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
    ></u-select-s>
  </u-group>
  <div class="emails-send-shuttle-companies-changes__presets">
    <u-date-range-presets
      [class]="'u-date-range-presets_app-emails-send-shuttle-companies-changes'"
      [defaultDate]="true" 
      [checkDays]="true"
      [presets]="config.uDateRangePresets"
      [mode]="uDateRangePresetsMode.LineRange"
      (changeDatesAction)="changeDatesAction($event)"
    ></u-date-range-presets>
  </div>
  <u-group 
    class="u-group u-group_app-emails-send-shuttle-companies-changes"
    [name]="config.dictionary.rides.shuttleCompanies | translate"
  >
    <u-multiselect
      [messages]="config.dictionary.uMultiselect | translate"
      [placeholder]="config.dictionary.select | translate"
      [items]="shuttleCompanies"
      [mode]="'counter'"
      [valid]="filtersForm.controls['shuttleCompanies'].valid"
      [formControl]="filtersForm.controls['shuttleCompanies']"
      [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
    ></u-multiselect>
  </u-group>
  <u-group
    class="u-group u-group_app-emails-send-shuttle-companies-changes"
    [name]="config.dictionary.rides.changesTypes | translate"
  >
    <u-select-s 
      [items]="config.changeType" 
      [placeholder]="config.dictionary.select | translate"
      [formControl]="filtersForm.controls['changeType']"
      [valid]="filtersForm.controls['changeType'].valid"
      [messages]="config.dictionary.uSelectS | translate"
      [empty]="false"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
    ></u-select-s>
  </u-group>
  <u-group 
    class="u-group u-group_app-emails-send-shuttle-companies-changes"
    [name]="config.dictionary.rides.changeSubject | translate"
  >
    <u-multiselect 
      [messages]="config.dictionary.uMultiselect | translate"
      [placeholder]="config.dictionary.select | translate"
      [items]="changeSubjects"
      [mode]="'counter'"
      [valid]="filtersForm.controls['changeSubjects'].valid"
      [formControl]="filtersForm.controls['changeSubjects']"
      [placement]="isRtl ? [ 'top-right', 'top-left' ] : [ 'top-left', 'top-right' ]"
    ></u-multiselect>
  </u-group>
  <u-group 
    class="u-group u-group_app-emails-send-shuttle-companies-changes"
    [name]="config.dictionary.rides.includeChanges | translate"
  >
    <div class="emails-send-shuttle-companies-changes__actions">
      <div class="emails-send-shuttle-companies-changes__action">
        <u-checkbox 
          class="u-checkbox_square-medium" 
          [ngModel]="!lastChanges" 
          (click)="toggleLastChanges(false)"
        ></u-checkbox>
        <div class="emails-send-shuttle-companies-changes__action-name">{{config.dictionary.rides.all | translate}}</div>
      </div>
      <div class="emails-send-shuttle-companies-changes__action">
        <u-checkbox 
          class="u-checkbox_square-medium" 
          [ngModel]="lastChanges" 
          (click)="toggleLastChanges(true)"
        ></u-checkbox>
        <div class="emails-send-shuttle-companies-changes__action-name">{{config.dictionary.rides.lastChanges | translate}}</div>
      </div>
    </div>
  </u-group>
  <div class="emails-send-shuttle-companies-changes__buttons">
    <div 
      class="emails-send-shuttle-companies-changes__action"
      [ngClass]="{ 'emails-send-shuttle-companies-changes__action_disabled': filtersForm.invalid }"
    >
      <u-checkbox class="u-checkbox_box" [formControl]="filtersForm.controls['openForEdit']"></u-checkbox>
      <div class="emails-send-shuttle-companies-changes__action-name">{{config.dictionary.rides.openForEdit | translate}}</div>
    </div>
    <div>
      <button
        uButton
        type="button"
        [disabled]="filtersForm.invalid"
        (click)="sendEmails()"
       >
        {{config.dictionary.rides.send | translate}}
      </button>
    </div>
  </div>
</div>
