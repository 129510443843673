import { UChipS } from '@shift/ulib';

import {
  AuthModuleName,
  AuthModuleRouteBuilderFeature,
  AuthModuleRoutesFeatureMandatoryField,
  AuthPermission,
  AuthPermissionGroupCondition
} from '@app/auth/models';
import { DaysOfWeek, GeneralOptionsType, RadioButton } from '@app/shared/models';
import { RouteChangeReportMode, RouteDirection } from '@app/routes/models';
import {
  BuilderAccompanyOptionsType,
  BuilderActiveRideCostType,
  BuilderActiveRideMonitoredPathSourceType,
  BuilderBuildMode,
  BuilderCalculationMode,
  BuilderRouteMode,
  BuilderSaveAction,
  BuilderSaveActionType
} from '@app/builder/models';

const DICTIONARY_PATH: string = 'builder';

const saveAction: BuilderSaveAction = { value: BuilderSaveActionType.SaveClose, name: `${DICTIONARY_PATH}.options.save.saveClose` };
const closeAction: BuilderSaveAction = { value: BuilderSaveActionType.Close, name: `${DICTIONARY_PATH}.options.save.close` };

const deleteRouteSaveAction: BuilderSaveAction = {
  value: BuilderSaveActionType.Delete,
  name: `${DICTIONARY_PATH}.options.save.deleteRoute`,
  color: '#FF0000',
  underlined: true
};

const saveNewSaveAction: BuilderSaveAction = {
  value: BuilderSaveActionType.SaveNew,
  name: `${DICTIONARY_PATH}.options.save.saveNew`
};

const saveCloneSaveAction: BuilderSaveAction = {
  value: BuilderSaveActionType.SaveClone,
  name: `${DICTIONARY_PATH}.options.save.saveClone`
};

const saveReverseSaveAction: BuilderSaveAction = {
  value: BuilderSaveActionType.SaveReverse,
  name: `${DICTIONARY_PATH}.options.save.saveReverse`
};

const saveActions: BuilderSaveAction[]  = [
  {
    ...deleteRouteSaveAction,
    permission: AuthPermission.DeleteRoutes
  },
  {
    ...saveNewSaveAction,
    permission: AuthPermission.CreateRoutes
  },
  {
    ...saveCloneSaveAction,
    permission: AuthPermission.CreateRoutes
  },
  {
    ...saveReverseSaveAction,
    permission: AuthPermission.CreateRoutes
  }
];

const saveActionsRadioButtons: RadioButton[] = [
  {
    id: 'send-email',
    value: RouteChangeReportMode.BackgroundEmail,
    name: `${DICTIONARY_PATH}.options.save.radioButtons.sendEmail`,
    permissionGroup: {
      condition: AuthPermissionGroupCondition.Or,
      values: [
        [
          AuthPermission.CreateRoutes
        ],
        [
          AuthPermission.EditRoutes
        ]
      ]
    }
  },
  {
    id: 'edit-email',
    value: RouteChangeReportMode.EditableEmail,
    name: `${DICTIONARY_PATH}.options.save.radioButtons.editEmail`,
    permissionGroup: {
      condition: AuthPermissionGroupCondition.Or,
      values: [
        [
          AuthPermission.CreateRoutes
        ],
        [
          AuthPermission.EditRoutes
        ]
      ]
    }
  }
];

const saveActionsRadioButtonsEventsTracking = {
  [RouteChangeReportMode.BackgroundEmail]: 'Click on Send Email',
  [RouteChangeReportMode.EditableEmail]: 'Click on Edit Email'
};

const calculationChips: UChipS[] = [
  {
    id: 'builder-main-optimal',
    value: BuilderCalculationMode.Optimal,
    name: `${DICTIONARY_PATH}.calculation.optimal`,
    confirmation: {
      no: 'general.no',
      yes: 'general.yes',
      title: 'general.attention',
      message: `${DICTIONARY_PATH}.calculation.optimalConfirm`
    },
    tooltip: `${DICTIONARY_PATH}.calculation.optimalTooltip`
  },
  {
    id: 'builder-main-manual-stations',
    disabled: true,
    value: BuilderCalculationMode.ManualStations,
    name: `${DICTIONARY_PATH}.calculation.manualStations`,
    confirmation: {
      no: 'general.no',
      yes: 'general.yes',
      title: 'general.attention',
      message: `${DICTIONARY_PATH}.calculation.manualStationsConfirm`
    },
    tooltip: `${DICTIONARY_PATH}.calculation.manualStationsTooltip`
  }
];

const calculationChipsFull: UChipS[] = [
  ...calculationChips,
  {
    id: 'builder-main-manual',
    disabled: true,
    value: BuilderCalculationMode.Manual,
    name: `${DICTIONARY_PATH}.calculation.manual`,
    tooltip: `${DICTIONARY_PATH}.calculation.manualTooltip`
  }
];

const customerData = {
  schools: [],
  departments: [],
  branches: [],
  cities: [],
  shifts: [],
  classes: [],
  subCustomers: [],
  shuttleCompanies: [],
  carTypes: [],
  accompanies: [
    { id: BuilderAccompanyOptionsType.Without, name: `${DICTIONARY_PATH}.options.accompanies.without`, address: null, latitude: null, longitude: null },
    {
      id: GeneralOptionsType.AddNew,
      name: `${DICTIONARY_PATH}.options.accompanies.addNewAccompany`,
      address: null,
      latitude: null,
      longitude: null,
      permission: AuthPermission.ManageAccompanies
    },
    { id: BuilderAccompanyOptionsType.Required, name: `${DICTIONARY_PATH}.options.accompanies.required`, address: null, latitude: null, longitude: null },
    { id: null, name: `${DICTIONARY_PATH}.options.accompanies.anonymous`, address: null, latitude: null, longitude: null }
  ],
  budgetTypes: [],
  tags: [],
  supervisors: []
};

export const builderConfig = {
  trackingId: 'Builder',
  routeAddEditTrackingId: 'Route Add/Edit',
  defaultCalculationMode: BuilderCalculationMode.Optimal,
  dictionaryPath: DICTIONARY_PATH,
  dictionary: {
    directions: {
      [RouteDirection.Forward]: 'general.direction.forward',
      [RouteDirection.Backward]: 'general.direction.backward'
    },
    chargeExtraFeeConfirm: {
      ok: `${DICTIONARY_PATH}.chargeExtraFeeConfirm.ok`,
      cancel: `${DICTIONARY_PATH}.chargeExtraFeeConfirm.cancel`
    },
    rideStartInLessThanTwoHoursConfirm: {
      ok: 'general.save',
      cancel: 'general.cancel'
    },
    invalidForm: 'general.invalidForm',
    nis: 'general.nis'
  },
  availableDays: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ],
  buildMode: {
    [BuilderBuildMode.Routes] : {
      trackingId: null,
      saveAction,
      closeAction,
      saveActions: [
        {
          ...deleteRouteSaveAction,
          permission: AuthPermission.DeleteRoutes
        },
        {
          ...saveNewSaveAction,
          feature: {
            module: AuthModuleName.RouteBuilder,
            name: AuthModuleRouteBuilderFeature.NewRoute
          },
          permission: AuthPermission.CreateRoutes
        },
        {
          ...saveCloneSaveAction,
          feature: {
            module: AuthModuleName.RouteBuilder,
            name: AuthModuleRouteBuilderFeature.NewRoute
          },
          permission: AuthPermission.CreateRoutes
        },
        {
          ...saveReverseSaveAction,
          feature: {
            module: AuthModuleName.RouteBuilder,
            name: AuthModuleRouteBuilderFeature.NewRoute
          },
          permission: AuthPermission.CreateRoutes
        }
      ],
      saveActionsRadioButtons,
      isEditableActiveDays: true
    },
    [BuilderBuildMode.RouteSuggestions] : {
      trackingId: 'Route Suggestions - Builder',
      saveAction,
      closeAction,
      saveActions: [],
      saveActionsRadioButtons: [],
      isEditableActiveDays: true
    },
    [BuilderBuildMode.RouteTemplates] : {
      trackingId: 'Routes & rides, Route templates - Template builder',
      saveAction,
      closeAction,
      saveActions: [
        deleteRouteSaveAction,
        saveNewSaveAction,
        saveCloneSaveAction,
        saveReverseSaveAction
      ],
      saveActionsRadioButtons: [],
      isEditableActiveDays: true
    },
    [BuilderBuildMode.RideOrders] : {
      trackingId: 'Ride orders',
      saveAction,
      closeAction,
      saveActions: [],
      saveActionsRadioButtons: [],
      isEditableActiveDays: false
    }
  },
  customerData,
  calculationChips,
  calculationChipsFull,
  saveActionsRadioButtonsEventsTracking,
  autoCalcSCCostTypes: [
    BuilderActiveRideCostType.PricePerKm,
    BuilderActiveRideCostType.PriceByCity,
    BuilderActiveRideCostType.PriceByKmRanges,
    BuilderActiveRideCostType.HashcalPrice
  ],
  addEditFormFeatureMandatoryFields: {
    details: {
      departmentId: AuthModuleRoutesFeatureMandatoryField.Department,
      orderPurposeId: AuthModuleRoutesFeatureMandatoryField.OrderPurpose,
      regionCode: AuthModuleRoutesFeatureMandatoryField.Region
    },
    activeRide: {
      shuttleCompanyId: AuthModuleRoutesFeatureMandatoryField.ShuttleCompany,
      carTypeId: AuthModuleRoutesFeatureMandatoryField.PlannedVehicleType,
      purchaseOrder: AuthModuleRoutesFeatureMandatoryField.PurchaseOrder,
      purchaseOrderRow: AuthModuleRoutesFeatureMandatoryField.PurchaseOrderRow
    }
  },
  routeModesByBuildMode: {
    default: BuilderRouteMode.Regular,
    [BuilderBuildMode.RouteTemplates]: BuilderRouteMode.Template,
    [BuilderBuildMode.RideOrders]: BuilderRouteMode.RideOrder
  },
  mapRide: {
    monitoredPathColors: {
      [BuilderActiveRideMonitoredPathSourceType.Car]: '#39B54A',
      [BuilderActiveRideMonitoredPathSourceType.Driver]: '#c53cbf',
      [BuilderActiveRideMonitoredPathSourceType.Accompany]: '#02c0ff',
      [BuilderActiveRideMonitoredPathSourceType.Supervisor]: '#02c0ff',
      [BuilderActiveRideMonitoredPathSourceType.ExternalDriver]: '#c53cbf'
    },
    monitoredPathMarkerColors: {
      [BuilderActiveRideMonitoredPathSourceType.Car]: '#39B54A',
      [BuilderActiveRideMonitoredPathSourceType.Driver]: '#c53cbf',
      [BuilderActiveRideMonitoredPathSourceType.Accompany]: '#02c0ff',
      [BuilderActiveRideMonitoredPathSourceType.Supervisor]: '#02c0ff',
      [BuilderActiveRideMonitoredPathSourceType.ExternalDriver]: '#c53cbf'
    }
  }
};
