import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UInputCitiesItem, USelectSItem } from '@shift/ulib';

import { CityCombinationsRule } from '@app/shared/models';
import { transformToInputCities } from '@app/shared/utils';
import { BranchesService } from '@app/branches/services';

@Injectable()
export class CitiesCombinationsByBranchService implements OnDestroy {
  private customerId: number;
  private branchSubscription: Subscription;
  private unsubscribe: Subject<void> = new Subject();
  private branches: BehaviorSubject<USelectSItem[]> = new BehaviorSubject([]);
  private citiesCombinationsByBranchId: BehaviorSubject<{
    [key: string]: {
      cities: UInputCitiesItem[];
      rules: CityCombinationsRule[];
    };
  }> = new BehaviorSubject({});

  branchForm: UntypedFormControl = this.fb.control(null);
  rulesForm: UntypedFormControl = this.fb.control([]);
  cities$ = this.citiesCombinationsByBranchId.asObservable().pipe(map(item => item[this.branchForm.value]?.cities || []));
  branches$ = this.branches.asObservable();

  constructor(
    private fb: UntypedFormBuilder,
    private branchesService: BranchesService
  ) {}

  ngOnDestroy() {
    this.branchUnsubscribe();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private updateBranchForm(branchId: number) {
    this.branchForm.patchValue(branchId);
  }

  private updateBranches(branches: USelectSItem[]) {
    this.branches.next(branches);
  }

  private updateRulesForm(rules: CityCombinationsRule[]) {
    this.rulesForm.patchValue(rules);
  }

  private branchUnsubscribe() {
    if (this.branchSubscription) {
      this.branchSubscription.unsubscribe();
    }
  }

  initBranchesData(branchId: number, branches: USelectSItem[]) {
    this.updateBranches(branches);
    this.updateBranchForm(branchId);
  }

  onBranchFormValueChanges() {
    this.branchUnsubscribe();

    this.branchSubscription = this.branchForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        startWith(this.branchForm.value),
        pairwise(),
        tap(([ prevBranchId ]) =>
          this.updateCitiesCombinationsRulesByBranchId(this.rulesForm.value, prevBranchId)
        ),
        map(([ , branchId ]) => branchId),
        switchMap(branchId => {
          if (this.citiesCombinationsByBranchId.value[branchId]) {
            this.updateRulesForm(this.citiesCombinationsByBranchId.value[branchId].rules);

            return of(null);
          }

          return this.branchesService.getBranchCityCombinations({ branchId, ...(this.customerId ? { customerId: this.customerId } : {}) })
            .pipe(
              tap(data => this.updateCitiesCombinationsByBranchId(data.rules, transformToInputCities(data.citiesTree)))
            );
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  updateCitiesCombinationsByBranchId(rules: CityCombinationsRule[], cities: UInputCitiesItem[], branchId: number = this.branchForm.value) {
    this.citiesCombinationsByBranchId.next({
      ...this.citiesCombinationsByBranchId.value,
      [branchId]: {
        cities,
        rules
      }
    });

    this.updateRulesForm(rules);
  }

  updateCitiesCombinationsRulesByBranchId(rules: CityCombinationsRule[], branchId = this.branchForm.value) {
    this.citiesCombinationsByBranchId.next({
      ...this.citiesCombinationsByBranchId.value,
      [branchId]: {
        ...(this.citiesCombinationsByBranchId.value[branchId] || {}),
        rules
      }
    });
  }

  getRules(): CityCombinationsRule[] {
    return Object.entries(this.citiesCombinationsByBranchId.value).reduce((acc, [ key, value ]) => ([
      ...acc,
      ...(+key === this.branchForm.value ? this.rulesForm.value : value.rules)
    ]), []);
  }

  updateCustomerId(customerId: number) {
    this.customerId = customerId;
  }
}
