import { Component, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { ModalContainerComponent, ModalOptions } from 'ngx-bootstrap/modal';

import { builderModalConfig } from '@app/builder/configs';

/* eslint-disable */
@Component({
  selector: 'app-modal-container',
  template: `
    <div [class]="'modal-dialog' + (config.class ? ' ' + config.class : '')" role="document">
      <div class="modal-content"><ng-content></ng-content></div>
    </div>
  `,
  host: {
    class: 'modal modal-absolute',
    role: 'dialog',
    tabindex: '-1',
    '[attr.aria-modal]': 'true'
  },
  styleUrls: [ './builder-modal-container.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class BuilderModalContainerComponent extends ModalContainerComponent {
  constructor(
    private options: ModalOptions,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    super(options, element, renderer);
  }

  ngOnInit() {
    this.initClassesAndStyles();
  }

  initClassesAndStyles() {
    if (this.isAnimated) {
      this.renderer.addClass(
        this._element.nativeElement,
        builderModalConfig.classNames.fade
      );
    }

    this.renderer.setStyle(
      this._element.nativeElement,
      'display',
      'block'
    );

    setTimeout(() => {
      this.isShown = true;
      this.renderer.addClass(
        this._element.nativeElement,
        builderModalConfig.classNames.show
      );
    }, this.isAnimated ? builderModalConfig.backdropTimeout : 0);

    if (document && document.body) {
      if (this.bsModalService.getModalsCount() === 1) {
        this.bsModalService.setScrollbar();
      }

      this.renderer.addClass(document.body, builderModalConfig.classNames.open);
    }

    if (this._element.nativeElement) {
      this._element.nativeElement.focus();
    }
  }
}
