import { Pipe, PipeTransform } from '@angular/core';

import { BuilderBuildMode, BuilderConfig, BuilderMode } from '@app/builder/models';

@Pipe({
  name: 'builderIsRemoveStationAllowed'
})
export class BuilderIsRemoveStationAllowedPipe implements PipeTransform {
  transform(builderConfig: BuilderConfig, station, selectedPassengerId: number): boolean {
    if (builderConfig.buildMode === BuilderBuildMode.RouteSuggestions) {
      return builderConfig.mode === BuilderMode.Add ? false : !!(station && station.passengers && station.passengers.filter(passenger => passenger.passengerId === selectedPassengerId).length);
    }

    return true;
  }
}

