import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RideOrder, RideOrdersBody, RideOrdersCustomerData } from '@app/ride-orders/models';

@Injectable({
  providedIn: 'root'
})
export class RideOrdersTableService {
  private BASE_PATH: string = 'RideOrdersTable';

  constructor(
    private apiService: ApiService
  ) {}

  getRideOrders(body: RideOrdersBody): Observable<RideOrder[]> {
    return this.apiService.post(`${this.BASE_PATH}/Get`, body);
  }

  getCustomerData(): Observable<RideOrdersCustomerData> {
    return this.apiService.get(`${this.BASE_PATH}/CustomerData`);
  }
}
