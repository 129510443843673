import * as moment from 'moment';
import { URangePreset, UMultiselectItem } from '@shift/ulib';

import { AuthCustomerType } from '@app/auth/models';
import { GeneralCardButton, GeneralCardButtonValue } from '@app/shared/models';
import { RouteTempCommentApplicableTo } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.tempComment';

const availableRangePresets: URangePreset[] = [
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

const commentApplicableToDriverItem: UMultiselectItem = {
  value: RouteTempCommentApplicableTo.Driver,
  name: `${DICTIONARY_PATH}.commentApplicableTo.driver`
};

const buttons: GeneralCardButton[] = [
  {
    value: GeneralCardButtonValue.SaveAndClose,
    name: 'general.saveAndClose'
  }
];

export const routesTempCommentComponentConfig = {
  dictionaryPath: DICTIONARY_PATH,
  trackingId: 'Route table, Temp comment',
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    period: `${DICTIONARY_PATH}.period`,
    commentFor: `${DICTIONARY_PATH}.commentFor`,
    max250Chars: `${DICTIONARY_PATH}.max250Chars`,
    commentContent: `${DICTIONARY_PATH}.commentContent`,
    commentWillDisplayedForAll: `${DICTIONARY_PATH}.commentWillDisplayedForAll`
  },
  minDate: moment().subtract(1, 'days').startOf('day'),
  maxDate: moment().add(30, 'days').startOf('day'),
  availableRangePresetsToday: [ ...availableRangePresets, URangePreset.Today, URangePreset.Tomorrow, URangePreset.DisplayedDay ],
  availableRangePresetsFuture: [ ...availableRangePresets, URangePreset.DisplayedDay ],
  availableRangePresetsPast: [ ...availableRangePresets ],
  buttons,
  commentApplicableToItems: {
    default: [
      commentApplicableToDriverItem,
      {
        value: RouteTempCommentApplicableTo.BusinessRideSupervisor,
        name: `${DICTIONARY_PATH}.commentApplicableTo.rideSupervisor`
      }
    ],
    [AuthCustomerType.Municipality]: [
      commentApplicableToDriverItem,
      {
        value: RouteTempCommentApplicableTo.Accompany,
        name: `${DICTIONARY_PATH}.commentApplicableTo.accompany`
      }
    ],
    [AuthCustomerType.Army]: [
      commentApplicableToDriverItem,
      {
        value: RouteTempCommentApplicableTo.ArmyRideCommander,
        name: `${DICTIONARY_PATH}.commentApplicableTo.rideCommander`
      }
    ]
  }
};
