<div
  *ngIf="showTypesChange" 
  class="routes-period__wrapper"
>
  <u-select-s
    [classUInput]="'u-input_arrow-light u-input_light'"
    [classUDropdownContent]="'u-dropdown-content_app-routes-change'"
    [container]="'body'"
    [items]="config.types"
    [formControl]="typeFormControl"
    [messages]="'uSelectS' | translate" 
    [empty]="false"
    [placeholder]="placeholder | translate"
    [valid]="typeFormControl.valid || typeFormControl.disabled"
      ></u-select-s>
</div>
<div
  *ngIf="showInputType"
  class="routes-period__wrapper"
>
  <input
    uInput
    type="text"
    [class]="'u-input_text-grey'"
    [disabled]="typeFormControl.disable"
    [value]="(typeFormControl.value === routesChangeType.Planned ? config.dictionary.planned : config.dictionary.unplanned) | translate"
  >
</div>
<div
  class="routes-period__wrapper"
  [ngClass]="{ 'routes-period__wrapper_full': !showTypesChange && !showInputType }"
>
  <u-range
    [classUDropdownContent]="'u-dropdown-content_app-routes-change-big'"
    [messages]="'uRange' | translate"
    [viewportElement]="viewportElement"
    [classUInput]="'u-input_cursor-pointer u-input_light u-input_arrow'"
    [daysOnly]="false"
    [type]="changes.type"
    [container]="container"
    [dayIndicatorConfig]="dayIndicatorConfig"
    [availablePresets]="
      (!showTypesChange && !(isSCCustomer && !isOwnedBySc)) ? changes.availablePresets : config.availablePresets ?
        config.availablePresets : changes.availablePresets ? changes.availablePresets : availablePresets
    "
    [dateFrom]="changes.dateFrom"
    [dateTo]="changes.dateTo"
    [disableDaysBefore]="disableDaysBefore"
    [disableDaysAfter]="disableDaysAfter"
    [checkDaysActive]="changes.checkDaysActive"
    [checkDaysAvailable]="changes.checkDaysAvailable"
    [dates]="changes.dates"
    [isRtl]="isRtl"
    [lang]="lang"
    [placement]="isRtl ? [ 'bottom-left', 'top-left'] : [ 'bottom-right', 'top-right' ]"
    [sundayFirstDay]="true"
    [placementCalendar]="isRtl ? ['right', 'left'] : ['left', 'right']"
    [checkDays]="true"
    [showIcon]="false"
    [required]="true"
    [singleDateSelection]="isSCCustomer && !isOwnedBySc"
    [selectedPlaceholder]="placeholderURange | translate"
    [disabledDays]="disabledDays"
    [inputValid]="inputValidURange"
    [displayedDay]="displayedDay"
    [ngModel]="null"
    [disabled]="disabledURange"
    [showDatesForCustomPreset]="showDatesForCustomPreset"
    (saveAction)="changesSave($event)"
    (datesChange)="datesChange($event)"
  ></u-range>
</div>
