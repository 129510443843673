import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, HostBinding } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { uniq, cloneDeep } from 'lodash';
import * as moment from 'moment';
import { UCommonModule, UDatePickerDayIndicatorConfig, URangePreset } from '@shift/ulib';

import { AppLanguage } from '@app/shared/models';
import { LocalizationService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { RoutesChangePresetsType, RoutesChangeType } from '@app/routes/models';
import { AuthCustomer, AuthCustomerType } from '@app/auth/models';
import { routesPeriodComponentConfig } from './routes-period.component.config';

@Component({
  selector: 'app-routes-period',
  templateUrl: './routes-period.component.html',
  styleUrls: [ './routes-period.component.scss', './routes-period.component.rtl.scss' ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgIf,
    NgClass,
    UCommonModule
  ]
})
export class RoutesPeriodComponent implements OnInit, OnChanges, OnDestroy {
  @Input() typeFormControl: UntypedFormControl;
  @Input() changes;
  @Input() viewportElement;
  @Input() container: string;
  @Input() showTypesChange = true;
  @Input() showInputType: boolean;
  @Input() dayIndicatorConfig: UDatePickerDayIndicatorConfig;
  @Input() changePresetsType: RoutesChangePresetsType;
  @Input() isCustomHeaderDate: boolean;
  @Input() authCustomer: AuthCustomer;
  @Input() activeDays: string[];
  @Input() needPresetAutoUpdate: boolean = true;
  @Input() placeholder: string = 'general.select';
  @Input() placeholderURange: string = 'general.select';
  @Input() disabledURange: boolean;
  @Input() inputValidURange: boolean = true;
  @Input() showDatesForCustomPreset: boolean;
  @Input() isOwnedBySc: boolean;

  @Output() updatePeriod = new EventEmitter();
  @Output() updateDates = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'routes-period';

  private unsubscribe: Subject<void> = new Subject();

  activeDate = this.headerDataService.getActiveDate();
  config = cloneDeep(routesPeriodComponentConfig.default);
  routesChangeType = RoutesChangeType;
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  disableDaysBefore: string;
  disableDaysAfter: string;
  availablePresets: URangePreset[];
  isSCCustomer: boolean;
  disabledDays: string[];
  displayedDay: string = this.headerDataService.getDate();

  constructor(
    private localizationService: LocalizationService,
    private headerDataService: HeaderDataService
  ) {}

  ngOnInit() {
    this.setAvailablePresets();

    if (this.typeFormControl) {
      this.typeFormControl.valueChanges
        .pipe(
          takeUntil(this.unsubscribe),
          distinctUntilChanged(),
          filter(() => this.needPresetAutoUpdate)
        )
        .subscribe((value: RoutesChangeType) => this.updatePreset(value));
    }
  }

  ngOnChanges(changes) {
    if (changes.authCustomer && this.authCustomer) {
      this.config = cloneDeep(routesPeriodComponentConfig[this.authCustomer.type] || routesPeriodComponentConfig.default);
      this.setCustomerType();
      this.setAvailablePresets();
    }

    if (changes.changes && this.changes) {
      const { dateFrom, dateTo } = this.changes;

      this.updateDisableDays(dateFrom, dateTo);
    }

    if (changes.activeDays && this.activeDays) {
      this.setDisabledDays(this.disableDaysBefore, this.disableDaysAfter, this.activeDays);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setAvailablePresets() {
    this.availablePresets = this.config.availablePresets || this.isToday(this.headerDataService.getDate())
      ? this.config.availablePresetsByTypes.today : this.config.availablePresetsByTypes.displayedDay;
  }

  private setCustomerType() {
    this.isSCCustomer = this.authCustomer && this.authCustomer.type === AuthCustomerType.ShuttleCompany;
  }

  private setDisabledDays(disableDaysBefore: string, disableDaysAfter: string, activeDays: string[]) {
    if (this.isSCCustomer) {
      const availableDays = this.generateDates(disableDaysBefore, disableDaysAfter)
        .map(date => moment(date).startOf('day').format(AppConstants.DATE_FORMAT_ISO));

      this.disabledDays = activeDays && activeDays.length
        ? availableDays.filter(availableDay => !activeDays.some(activeDay => moment(availableDay).isSame(activeDay, 'day'))) : [];
    }
  }

  updatePreset(value: RoutesChangeType) {
    const changePresetsType = this.changePresetsType || this.config.changePresetsType;

    this.changePreset(value === RoutesChangeType.Planned ? this.rangeHaveTomorrow(URangePreset.FromTomorrowOn) ? changePresetsType.planned : URangePreset.All : changePresetsType.fixed);
  }

  changesSave(data: any) {
    this.updatePeriod.emit(data);
  }

  updateDisableDays(dateFrom: string, dateTo: string): void {
    this.disableDaysBefore = dateFrom ? moment(dateFrom).subtract(1, 'day').format(AppConstants.DATE_FORMAT_ISO) : null;
    this.disableDaysAfter = dateTo ? moment(dateTo).add(1, 'day').format(AppConstants.DATE_FORMAT_ISO) : null;
  }

  datesChange(dates: string[]): void {
    const checkDaysActive = this.getActiveDays(dates);

    this.updateDates.emit({ dates, checkDaysActive });
  }

  getDates(preset: string): string[] {
    const { dateFrom, dateTo } = this.changes;
    const today = moment().format(AppConstants.DATE_FORMAT_ISO);
    const tomorrow = moment().add(1, 'days').startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    if (preset === URangePreset.DisplayedDay) {
      return [ this.headerDataService.getDate() ];
    }

    if (preset === URangePreset.Today) {
      return [ today ];
    }

    if (preset === URangePreset.FromTodayOn) {
      return [ today, dateTo ];
    }

    if (preset === URangePreset.FromTomorrowOn) {
      return [ tomorrow, dateTo ];
    }

    if (preset === URangePreset.All) {
      return [ dateFrom, dateTo ];
    }

    if (preset === URangePreset.Custom) {
      return (preset === URangePreset.Custom && this.isCustomHeaderDate) ? [ this.headerDataService.getDate() ] : [];
    }
  }

  changePreset(preset: string): void {
    switch (preset) {
      case URangePreset.DisplayedDay: {
        this.availablePresets = this.isToday(this.headerDataService.getDate()) ? this.config.availablePresetsByTypes.today : this.config.availablePresetsByTypes.displayedDay;

        const type = this.isToday(this.headerDataService.getDate()) ? URangePreset.Today : URangePreset.DisplayedDay;
        const dates = this.getDates(type);

        this.updatePeriod.emit({
          ...this.changes,
          dates,
          checkDaysActive: this.getActiveDays(dates),
          type
        });

        break;
      }

      case URangePreset.Today: {
        this.availablePresets = this.config.availablePresetsByTypes.today;

        const type = this.isToday(this.headerDataService.getDate()) ? URangePreset.Today : URangePreset.Custom;
        const dates = this.getDates(type);
        const checkDaysActive = this.getActiveDays(dates);
        const data = {
          ...this.changes,
          dates,
          checkDaysActive,
          type
        };

        this.updatePeriod.emit(data);

        break;
      }

      case URangePreset.FromTodayOn: {
        this.availablePresets = this.config.availablePresetsByTypes.fromTodayOn;

        const dates = this.getDates(URangePreset.FromTodayOn);
        const checkDaysActive = this.getActiveDays(dates);
        const data = {
          ...this.changes,
          type: URangePreset.FromTodayOn,
          dates,
          checkDaysActive
        };

        this.updatePeriod.emit(data);

        break;
      }

      case URangePreset.FromTomorrowOn: {
        this.availablePresets = this.config.availablePresetsByTypes.fromTomorrowOn;

        const dates = this.getDates(URangePreset.FromTomorrowOn);
        const checkDaysActive = this.getActiveDays(dates);
        const data = {
          ...this.changes,
          type: URangePreset.FromTomorrowOn,
          dates,
          checkDaysActive
        };

        this.updatePeriod.emit(data);

        break;
      }

      case URangePreset.All: {
        this.availablePresets = this.config.availablePresetsByTypes.all;

        const dates = this.getDates(URangePreset.All);
        const checkDaysActive = this.getActiveDays(dates);
        const data = {
          ...this.changes,
          type: URangePreset.All,
          dates,
          checkDaysActive
        };

        this.updatePeriod.emit(data);

        break;
      }

      case URangePreset.Custom: {
        this.availablePresets = this.config.availablePresetsByTypes.custom;

        const dates = this.getDates(URangePreset.Custom);
        const checkDaysActive = this.getActiveDays(dates);
        const data = {
          ...this.changes,
          type: URangePreset.Custom,
          dates,
          checkDaysActive
        };

        this.updatePeriod.emit(data);

        break;
      }
    }
  }

  generateDates(dateFrom: any, dateTo: any): string[] {
    const datesRange = [];
    let startDate = new Date(moment(dateFrom).startOf('day').toDate());
    const endDate = new Date(moment(dateTo).startOf('day').toDate());

    while (startDate <= endDate) {
      datesRange.push(startDate);
      startDate = moment(startDate).add(1, 'day').toDate();
    }

    return datesRange;
  }

  getActiveDays(dates: string[]): string[] {
    const days = this.generateDates(dates[0], dates[dates.length - 1])
      .map((date: string) => moment(date).day());

    return uniq(days);
  }

  isToday(date: string): boolean {
    const today = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const isoDate = moment(date, AppConstants.DATE_FORMAT_ISO).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    return moment(today).isSame(isoDate);
  }

  rangeHaveTomorrow(preset: string): string | null {
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (
      tomorrow.isSameOrAfter(this.changes.dateFrom) &&
      tomorrow.isSameOrBefore(this.changes.dateTo)
    ) {
      return preset;
    } else {
      return null;
    }
  }
}
