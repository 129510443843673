import { Component, HostBinding, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

import { EntityMenu, EntityMenuItem, EntityAreaButton } from '@app/shared/models';

@Component({
  selector: 'app-entity-layout',
  templateUrl: './entity-layout.component.html',
  styleUrls: [ './entity-layout.component.scss', './entity-layout.component.rtl.scss' ]
})
export class EntityLayoutComponent {
  @Input() title: string;
  @Input() attention: string;
  @Input() menu: EntityMenu[];
  @Input() buttons: EntityAreaButton[] = [
    { name: 'general.saveAndClose', action: 'submit', clear: false }
  ];
  @Input() activeAction: string;
  @Input() titleTooltip: TemplateRef<any> | string;

  @Output() menuItemAction: EventEmitter<EntityMenuItem> = new EventEmitter();
  @Output() buttonAction: EventEmitter<EntityAreaButton> = new EventEmitter();
  @Output() activeActionChange: EventEmitter<string> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'entity-layout';

  activeActionSet(data: string) {
    this.activeActionChange.emit(data);
  }

  menuItem(item: EntityMenuItem) {
    this.menuItemAction.emit(item);
  }

  button(button: EntityAreaButton) {
    this.buttonAction.emit(button);
  }
}
