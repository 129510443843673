import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RideOrdersState, FEATURE_KEY } from '@app/ride-orders/state/reducers';

export const selectRideOrdersState = createFeatureSelector<RideOrdersState>(
  FEATURE_KEY
);

export const selectRideOrdersInitializedState = createSelector(
  selectRideOrdersState,
  (state) => state.rideOrders.initialized
);

export const selectRideOrdersItemsState = createSelector(
  selectRideOrdersState,
  (state) => state.rideOrders.items
);

export const selectRideOrdersParamsState = createSelector(
  selectRideOrdersState,
  (state) => state.rideOrders.params
);
