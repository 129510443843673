const DICTIONARY_PATH: string = 'shared.routePolicy';

export const routePolicyComponentConfig = {
  trackingId: 'Route Policy',
  dictionary: {
    maxShuttleTime: `${DICTIONARY_PATH}.maxShuttleTime`,
    minutes: `${DICTIONARY_PATH}.minutes`,
    getEarlyToDest: `${DICTIONARY_PATH}.getEarlyToDest`,
    pickupFromCentralStations: `${DICTIONARY_PATH}.pickupFromCentralStations`,
    meters: `${DICTIONARY_PATH}.meters`,
    pickupFromBusStops: `${DICTIONARY_PATH}.pickupFromBusStops`,
    waitOnStation: `${DICTIONARY_PATH}.waitOnStation`,
    seconds: `${DICTIONARY_PATH}.seconds`,
    pickUpFrom: `${DICTIONARY_PATH}.pickUpFrom`,
    calculate: `${DICTIONARY_PATH}.calculate`,
    speedRate: `${DICTIONARY_PATH}.speedRate`,
    speedRateInfoTooltip: `${DICTIONARY_PATH}.speedRateInfoTooltip`,
    equalDistribution: `${DICTIONARY_PATH}.equalDistribution`,
    equalDistributionInfo: `${DICTIONARY_PATH}.equalDistributionInfo`,
    useTrafficData: `${DICTIONARY_PATH}.useTrafficData`,
    tollRoads: `${DICTIONARY_PATH}.tollRoads`,
    share: `${DICTIONARY_PATH}.share`,
    getEarlyBy: `${DICTIONARY_PATH}.getEarlyBy`,
    minutesFrom: `${DICTIONARY_PATH}.minutesFrom`,
    directRoute: `${DICTIONARY_PATH}.directRoute`,
    useCitySettings: `${DICTIONARY_PATH}.useCitySettings`,
    routeNamePolicy: `${DICTIONARY_PATH}.routeNamePolicy`,
    passengerAddressPolicy: `${DICTIONARY_PATH}.passengerAddressPolicy`,
    pickupOptions: {
      title: `${DICTIONARY_PATH}.pickupOptions.title`
    },
    cheapestPrice: {
      title: `${DICTIONARY_PATH}.cheapestPrice.title`,
      infoTooltip: `${DICTIONARY_PATH}.cheapestPrice.infoTooltip`,
      onlyUsingPricing: `${DICTIONARY_PATH}.cheapestPrice.onlyUsingPricing`
    },
    select: 'general.select'
  }
};
