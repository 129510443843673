<div
  class="week-switch__reset"
  *ngIf="showToday()"
  (click)="resetWeek()"
>
  {{config.dictionary.today | translate}}
</div>
<div class="week-switch__date">
  <u-input-date-range
    [(ngModel)]="dates"
    (ngModelChange)="rangeDatesSave($event)"
    [class]="'u-input-date-range_app-week-switch'"
    [mode]="'pick'"
    [container]="'body'"
    [datePickerRangeMode]="uDatePickerRangeMode.Week"
    [sundayFirstDay]="true"
    [checkDays]="false"
    [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
    [messages]="config.dictionary.uInputDateRange | translate"
    [weekShowArrows]="true"
    [weekStartDateLimit]="startDateLimit"
    [weekEndDateLimit]="endDateLimit"
    [showIcon]="false"
    [datePickerRangeFooterTemplate]="datePickerRangeFooterTemplate"
  ></u-input-date-range>
</div>
