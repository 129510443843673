<div class="entity-area__main">
  <div class="entity-area__header">
    <div class="entity-area__title">{{title | translate}}</div>
    <div *ngIf="attention" class="entity-area__attention">{{attention | translate}}</div>
    <div
      *ngIf="titleTooltip"
      class="entity-area__header-info"
      [placement]="[ 'top', 'bottom' ]"
      [uTooltip]="titleTooltip"
      [tooltipClass]="'u-tooltip_general'"
      [container]="'body'"
    ></div>
  </div>
  <div class="entity-area__content">
    <ng-content></ng-content>
  </div>
</div>
<div class="entity-area__actions">
  <button
    *ngFor="let button of buttons"
    uButton
    class="entity-area__actions-btn"
    [size]="uButtonSize.Large"
    [view]="button.clear ? uButtonView.Light : uButtonView.Primary"
    (click)="buttonClick(button)"
  >
    {{button.name | translate}}
  </button>
</div>
