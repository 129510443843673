<div class="routes-change-passenger__container">
  <div class="routes-change-passenger__wrapper">
    <h4 class="routes-change-passenger__title">{{config.dictionary.title | translate}}</h4>
  </div>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.passengers | translate"
  >
    <div class="routes-change-passenger__wrappers">
      <div class="routes-change-passenger__item">
        <u-select-s
          [classUInput]="'u-input_arrow-light u-input_light'"
          [classUDropdownContent]="'u-dropdown-content_app-routes-change'" 
          [items]="actions"
          [formControl]="form.controls['action']"
          [valid]="form.controls['action'].disabled || form.controls['action'].valid"
          [messages]="'uSelectS' | translate" 
          [empty]="false"
          [placement]="[ 'bottom-left', 'bottom-right' ]"
          [placeholder]="'general.select' | translate"
        ></u-select-s>
      </div>
      <div class="routes-change-passenger__item">
        <u-select-s
          [classUInput]="'u-input_arrow-light u-input_light'"
          [classUDropdownContent]="'u-dropdown-content_app-routes-change u-dropdown-content_app-routes-change-passenger'"
          [items]="passengers"
          [formControl]="form.controls['passengerId']"
          [valid]="form.controls['passengerId'].valid"
          [messages]="'uSelectS' | translate"
          [placement]="['bottom-right', 'bottom-left']"
          [empty]="false"
          [adaptive]="true"
          [placeholder]="'general.select' | translate"
        ></u-select-s>
      </div>
    </div>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.type | translate"
  >
    <app-routes-period
      [typeFormControl]="form.controls['type']"
      [changes]="form.controls['datesChange'].value"
      [viewportElement]="viewportElement"
      (updatePeriod)="updatePeriod($event)"
      (updateDates)="updateDates($event)"
    ></app-routes-period>
  </u-group>
  <u-group
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.recalculateRoute | translate"
  >
    <div class="routes-change-passenger__item">
      <u-select-s
        [classUInput]="'u-input_arrow-light u-input_light'"
        [classUDropdownContent]="'u-dropdown-content_app-routes-change u-dropdown-content_app-routes-change-passenger'"
        [items]="form.get('action').value === passengersChangesActions.Remove ? config.recalculateRouteOptionsFull : config.recalculateRouteOptions"
        [formControl]="form.controls['routeRecalculateMode']"
        [valid]="form.controls['routeRecalculateMode'].valid"
        [messages]="'uSelectS' | translate"
        [placement]="['bottom-right', 'bottom-left']"
        [empty]="false"
        [adaptive]="true"
        [placeholder]="'general.select' | translate"
      ></u-select-s>
    </div>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.comments | translate"
  >
    <textarea
      uTextarea
      [formControl]="form.controls['comment']"
    ></textarea>
  </u-group>

  <div class="routes-change-passenger__buttons">
    <div class="routes-change-passenger__button">
      <button
        uButton
        class="routes-change-passenger__btn"
        [disabled]="!showSaveBtn"
        [size]="uButtonSize.Small"
        (click)="saveChanges()"
      >
        {{config.dictionary.actions.saveAndClose | translate}}
      </button>
    </div>
    <div class="routes-change-passenger__button">
      <button
        uButton
        class="routes-change-passenger__btn"
        [disabled]="!showSaveBtn"
        [size]="uButtonSize.Small"
        (click)="saveChanges(routesChangeEmailSendType.SendBackgroundEmail)"
      >
        {{config.dictionary.actions.saveAndSendSC | translate}}
      </button>
    </div>
    <div class="routes-change-passenger__button">
      <button
        uButton
        class="routes-change-passenger__btn"
        [disabled]="!showSaveBtn"
        [size]="uButtonSize.Small"
        (click)="saveChanges(routesChangeEmailSendType.GenerateEditableEmail)"
      >
        {{config.dictionary.actions.saveAndEditEmail | translate}}
      </button>
    </div>
  </div>
</div>
