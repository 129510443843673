export enum GeneralCardButtonValue {
  Ok = 'ok',
  SaveAndClose = 'saveAndClose',
  SaveAndSendSC = 'saveAndSendSC',
  SaveAndEditEmail = 'saveAndEditEmail',
  Cancel = 'cancel',
  Continue = 'continue'
}

export interface GeneralCardButton {
  name: string;
  value: GeneralCardButtonValue;
  disabled?: boolean;
  id?: string;
}
