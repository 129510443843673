import { UInputCitiesCityLocation } from '@shift/ulib';

import { PassengerType, PassengerStatusType } from './passengers.models';

export class PassengersGenericGetParameters {
  search: string;
  passengerType: PassengerType;
  departmentsIds: number[];
  statusTypes: PassengerStatusType[];
  eligibleForShuttle: any;
  shiftIds: number[];
  directions?: number[];
  startDate?: string;
  endDate?: string;
  branchIds?: number[];
  demandBranchIds?: number[];
  demandCities?: UInputCitiesCityLocation[];
  passengerCities?: UInputCitiesCityLocation[];
  customerId?: number;
  skip: number;
  take: number;
}
