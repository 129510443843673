import { Injectable } from '@angular/core';
import { from, Subscription } from 'rxjs';
import { delay, filter, retryWhen, switchMap, takeWhile } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { appConfig } from '@app/shared/configs';
import { SignalRService } from '@app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class RouteEditSessionHubService extends SignalRService {
  private reconnectSubscription: Subscription;

  BASE_PATH = 'routeEditSessionHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  private clearReconnectSubscription() {
    if (this.reconnectSubscription) {
      this.reconnectSubscription.unsubscribe();
      this.reconnectSubscription = null;
    }
  }

  async startRouteEditSession(guid: string) {
    this.init(`?guid=${guid}`);

    await this.start();

    this.clearReconnectSubscription();

    this.reconnectSubscription = this.onClose()
      .pipe(
        filter(err => !!err),
        switchMap(() =>
          from(this.start())
            .pipe(
              retryWhen(err =>
                err.pipe(
                  delay(appConfig.retryRequestTimeout),
                  takeWhile(() => !this.isConnected)
                )
              )
            )
        )
      )
      .subscribe();
  }

  async stopRouteEditSession() {
    if (this.signalRClient) {
      this.clearReconnectSubscription();

      if (this.isConnected) {
        await this.stop();
      }
    }
  }
}
