<div class="demands-week-duplicate__wrapper">
  <div class="demands-week-duplicate__close" (click)="closeModal()">
    <div class="u-icon u-icon_pointer u-icon_app-demands-close"></div>
  </div>
  <div class="demands-week-duplicate__main">
    <div
      class="demands-week-duplicate__title"
      [translate]="'demands.duplicatePopup.title'"
      [translateParams]="{passengerName: passengerName}"
    >
    </div>
    <u-group
      class="u-group_app-demands-duplicate"
      [name]="'demands.duplicatePopup.dates' | translate"
    >
      <u-input-date-range
        [disableDays]="disableDays"
        [formControl]="form.controls['dates']"
        [datePickerRangeMode]="datePickerRangeMode.Week"
        [sundayFirstDay]="true"
        [checkDays]="false"
        [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
        [messages]="'uInputDateRange' | translate"
        [showIcon]="false"
      ></u-input-date-range>
    </u-group>
    <u-group
      class="u-group_app-demands-duplicate"
      [name]="'demands.duplicatePopup.days' | translate"
    >
      <u-select-days
        class="u-select-days_app-demands-duplicate"
        [messages]="'uSelectDays' | translate"
        [formControl]="form.controls['days']"
        [sundayFirstDay]="true"
        [availableDays]="availableDays"
        [rtl]="isRtl"
        [lang]="translateService.currentLang"
        [showInfo]="false"
        [showAllBtn]="false"
        [minDays]="1"
      ></u-select-days>
    </u-group>
    <div class="demands-week-duplicate__actions">
      <button
        class="demands-week-duplicate__actions-btn demands-week-duplicate__actions-btn_clear"
        (click)="actionClick('cancel')"
      >
        {{'demands.duplicatePopup.cancel' | translate}}
      </button>
      <button
        class="demands-week-duplicate__actions-btn"
        (click)="actionClick('applyGoWeek')"
      >
        {{'demands.duplicatePopup.applyGoWeek' | translate}}
      </button>
      <button
        class="demands-week-duplicate__actions-btn"
        (click)="actionClick('apply')"
      >
        {{'demands.duplicatePopup.apply' | translate}}
      </button>
    </div> 
  </div>
</div>
