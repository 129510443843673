import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { Errors } from '@app/shared/models';
import {
  RouteRideSupervisors,
  RouteRideSupervisorsParams
} from '@app/routes/models';
import {
  BuilderEditCalculationModeSetBody,
  BuilderGetRidesPurchaseOrdersBody,
  BuilderGetShuttleCompanies,
  BuilderGetShuttleCompaniesBody,
  BuilderEditRequiresApprovalBody,
  BuilderRidesPurchaseOrder,
  BuilderRouteAddEdit,
  BuilderEditRouteTypeBody,
  BuilderSearch,
  BuilderStationSave,
  BuilderStationSaveBody,
  BuilderEditPurchaseOrderBody,
  BuilderEditPurchaseOrderRowBody,
  BuilderGetShuttleCompanyContracts,
  BuilderGetShuttleCompanyContractsBody,
  BuilderEditContractSetBody,
  BuilderEditContactNameBody,
  BuilderEditContactMobileBody,
  BuilderEditChargeExtraFeeBody,
  BuilderCustomerDataParams,
  BuilderCustomer,
  BuilderNewParams,
  BuilderEditHashcalRideTypeBody,
  BuilderEditExecutionCostBody,
  BuilderCustomerSupervisorModuleStatus,
  BuilderEditDriverHoursBody,
  BuilderEditTollRoadsCostBody,
  BuilderEditRegionBody
} from '@app/builder/models';

@Injectable({
  providedIn: 'root'
})
export class BuilderService {
  private BASE_PATH = 'RouteBuilder';

  constructor (
    private apiService: ApiService
  ) {}

  initialize(routeId: number): Observable<string> {
    return this.apiService.post(`${this.BASE_PATH}/Initialize`, { routeId });
  }

  new(params: BuilderNewParams): Observable<BuilderRouteAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/New`, params);
  }

  save(params: object): Observable<any> {
    return this.apiService.post(this.BASE_PATH, params, 'text', false, false, [ Errors.ExtraFeeNecessary ], true);
  }

  edit(params: object): Observable<BuilderRouteAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/Edit`, params);
  }

  open(params: object): Observable<any> {
    return this.apiService.get(`${this.BASE_PATH}/Open`, params);
  }

  previousDay(params: object): Observable<BuilderRouteAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/PreviousDay`, params);
  }

  nextDay(params: object): Observable<BuilderRouteAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/NextDay`, params);
  }

  week(params: object): Observable<any> {
    return this.apiService.get(`${this.BASE_PATH}/Week`, params);
  }

  getRideSupervisors(params: RouteRideSupervisorsParams): Observable<RouteRideSupervisors> {
    return this.apiService.get(`${this.BASE_PATH}/RideSupervisors`, params);
  }

  editSettings(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Settings`, params);
  }

  setCalculationMode(body: BuilderEditCalculationModeSetBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/CalculationModeSet`, body, false, null, null, true);
  }

  editWaypoints(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Waypoints`, params, false, null, [ Errors.PermissionsDenied ]);
  }

  editWaypointsReset(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/WaypointsReset`, params);
  }

  editDirection(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Direction`, params);
  }

  editName(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Name`, params);
  }

  editNumber(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Number`, params);
  }

  editBidNumber(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/BidNumber`, params);
  }

  editRegulationNumber(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/RegulationNumber`, params);
  }

  editAllowEmptyStations(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/AllowEmptyStations`, params);
  }

  editPeriod(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Period`, params);
  }

  editCustomers(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Customers`, params);
  }

  editDepartment(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Department`, params);
  }

  editRegion(body: BuilderEditRegionBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Region`, body);
  }

  editOrderPurpose(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/OrderPurpose`, params);
  }

  editBudgetItem(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/BudgetItem`, params);
  }

  editComment(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Comment`, params);
  }

  editTime(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Time`, params, false, null, [ Errors.PermissionsDenied ]);
  }

  editShuttleCompany(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/ShuttleCompany`, params, false, [ Errors.RideStartInLessThanTwoHours ], null, true);
  }

  editCost(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Cost`, params, false, null, null, true);
  }

  editCarType(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/CarType`, params, false, [ Errors.VehicleSubceedsCarTypeCapacity, Errors.CarTypeExceedsVehicleCapacity ], null, true);
  }

  editCar(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Car`, params, false, [ Errors.VehicleSubceedsCarTypeCapacity, Errors.CarTypeExceedsVehicleCapacity ]);
  }

  editDriver(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Driver`, params);
  }

  editYit(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Yit`, params);
  }

  editFiltersPassengers(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Filters/Passengers`, params);
  }

  editFiltersStations(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Filters/Stations`, params);
  }

  editPassengerAdd(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Add`, params, false, null, null, true);
  }

  editPassengerAddToStation(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/AddToStation`, params, false, null, null, true);
  }

  editPassengerRemove(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Remove`, params);
  }

  editPassengerMoveToStation(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/MoveToStation`, params);
  }

  editPassengerAddAnonymous(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/AddAnonymous`, params);
  }

  editPassengerRemoveAnonymous(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/RemoveAnonymous`, params);
  }

  editPassengerAmount(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Amount`, params);
  }

  editPassengerTarget(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Target`, params);
  }

  editPassengerMoveToFirstStation(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/MoveToFirstStation`, params);
  }

  editPassengerMoveToLastStation(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/MoveToLastStation`, params);
  }

  editPassengerSupervisorAdd(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Supervisor/Add`, params);
  }

  editPassengerSupervisorSet(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Supervisor/Set`, params);
  }

  editPassengerSupervisorRemove(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Supervisor/Remove`, params);
  }

  editPassengerSupervisorReset(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Supervisor/Reset`, params);
  }

  editPassengerUpdate(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Passenger/Update`, params);
  }

  editStationAddSaved(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/AddSaved`, params);
  }

  editStationAddByAddress(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/AddByAddress`, params, false, null, null, true);
  }

  editStationAddCustomer(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/AddCustomer`, params);
  }

  editStationRemove(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/Remove`, params);
  }

  editStationRemoveTarget(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/RemoveTarget`, params);
  }

  editStationName(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/Name`, params);
  }

  editStationAddress(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/Address`, params, false, null, null, true);
  }

  editStationSetFirst(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/SetFirst`, params);
  }

  editStationSetLast(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/SetLast`, params);
  }

  editStationSetTarget(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/SetTarget`, params);
  }

  editStationSetNonTarget(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/SetNonTarget`, params);
  }

  editStationOrder(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/Order`, params, false, null, [ Errors.PermissionsDenied ]);
  }

  editStationArrivalTime(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/ArrivalDateTime`, params);
  }

  editAccompanySet(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Accompany/Set`, params);
  }

  editAccompanySetRequired(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Accompany/SetRequired`, params);
  }

  editAccompanyReset(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Accompany/Reset`, params);
  }

  editAccompanyCost(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Accompany/Cost`, params);
  }

  editAccompanyDestination(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Accompany/Destination`, params);
  }

  editAccompanySource(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Accompany/Source`, params);
  }

  editRestoreRide(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/RestoreRide`, params);
  }

  editRouteType(body: BuilderEditRouteTypeBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/RouteType`, body, false, null, null, true);
  }

  editRequiresApproval(body: BuilderEditRequiresApprovalBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/RequiresApproval`, body);
  }

  editStationAddBranch(params: object): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Station/AddBranch`, params);
  }

  editPurchaseOrder(body: BuilderEditPurchaseOrderBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/PurchaseOrder`, body);
  }

  editPurchaseOrderRow(body: BuilderEditPurchaseOrderRowBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/PurchaseOrderRow`, body);
  }

  editContractSet(body: BuilderEditContractSetBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Contract/Set`, body);
  }

  editContractRemove(body: BuilderEditContractSetBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/Contract/Remove`, body);
  }

  editContactName(body: BuilderEditContactNameBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/ContactName`, body);
  }

  editContactMobile(body: BuilderEditContactMobileBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/ContactMobile`, body);
  }

  editChargeExtraFee(body: BuilderEditChargeExtraFeeBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/ChargeExtraFee`, body);
  }

  editHashcalRideType(body: BuilderEditHashcalRideTypeBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/HashcalRideType`, body, false, null, null, true);
  }

  editExecutionCost(body: BuilderEditExecutionCostBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/ExecutionCost`, body);
  }

  editDriverHours(body: BuilderEditDriverHoursBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/DriverHours`, body);
  }

  editTollRoadsCost(body: BuilderEditTollRoadsCostBody): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Edit/TollRoadsCost`, body);
  }

  search(params: { searchText: string; masterSubCustomerIds?: number[]; }): Observable<BuilderSearch> {
    return this.apiService.post(`${this.BASE_PATH}/Search`, params);
  }

  getCustomer(): Observable<BuilderCustomer> {
    return this.apiService.get(`${this.BASE_PATH}/Customer`);
  }

  getCustomerSupervisorModuleStatus(): Observable<BuilderCustomerSupervisorModuleStatus> {
    return this.apiService.get(`${this.BASE_PATH}/Customer/SupervisorModule/Status`);
  }

  getCustomerData(params?: BuilderCustomerDataParams): Observable<any> {
    return this.apiService.get(`${this.BASE_PATH}/CustomerData`, params);
  }

  getShuttleCompanies(body: BuilderGetShuttleCompaniesBody): Observable<BuilderGetShuttleCompanies> {
    return this.apiService.post(`${this.BASE_PATH}/GetShuttleCompanies`, body);
  }

  deleteRoute(params: object = {}): Observable<any> {
    return this.apiService.post(`${this.BASE_PATH}/Delete`, params);
  }

  selectDay(params: object = {}): Observable<BuilderRouteAddEdit> {
    return this.apiService.get(`${this.BASE_PATH}/SelectDay`, params);
  }

  duplicate(params: object): Observable<any> {
    return this.apiService.put(`${this.BASE_PATH}/Duplicate`, params);
  }

  stationSave(body: BuilderStationSaveBody): Observable<BuilderStationSave> {
    return this.apiService.post(`${this.BASE_PATH}/Station/Save`, body, 'text', false, false, null, true);
  }

  getPassengerCustomerName(passengerId: number): Observable<string> {
    return this.apiService.get(`${this.BASE_PATH}/Passenger/CustomerName`, { passengerId });
  }

  getPassengerMobile(passengerId: number): Observable<string> {
    return this.apiService.get(`${this.BASE_PATH}/Passenger/Mobile`, { passengerId });
  }

  getRidesPurchaseOrders(body: BuilderGetRidesPurchaseOrdersBody): Observable<BuilderRidesPurchaseOrder[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetRidesPurchaseOrders`, body, 'text', false, false, null, true);
  }

  getShuttleCompanyContracts(body: BuilderGetShuttleCompanyContractsBody): Observable<BuilderGetShuttleCompanyContracts> {
    return this.apiService.post(`${this.BASE_PATH}/GetShuttleCompanyContracts`, body);
  }
}
