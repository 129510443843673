<u-here-map
  [center]="mapRide.coords"
  [zoom]="mapRide.zoom"
  [bounds]="mapRide.bounds"
>
  <u-here-map-point
    *ngIf="!builderDataService.lockedMap"
    [address]="mapRide.point"
    (selectPoint)="builderDataService.mapPoint($event)"
  ></u-here-map-point>
  <u-here-map-point-center
    [position]="mapRide.fitCenter.position"
  ></u-here-map-point-center>
  <u-here-map-point-center
    [position]="mapRide.markerCenter.position"
    [config]="mapRide.markerCenter.config"
  ></u-here-map-point-center>
  <u-here-map-traffic *ngIf="mapRide.traffic"></u-here-map-traffic>
  <ng-container *ngIf="mapRide.filter.stations">
    <u-here-map-marker
      *ngFor="let marker of mapRide.markers.stations"
      [config]="marker"
      [draggable]="builderDataService.lockedMap ? false : marker.draggable"
      (dragEndLocation)="builderDataService.stationAddress($event)"
    ></u-here-map-marker>
  </ng-container>
  <ng-container *ngIf="!mapRide.filter.stations && mapRide.filter.passengers">
    <u-here-map-marker
      *ngFor="let marker of mapRide.markers.passengers"
      [config]="marker"
      [draggable]="builderDataService.lockedMap ? false : marker.draggable"
    ></u-here-map-marker>
  </ng-container>
  <ng-container *ngIf="mapRide.filter.stations && mapRide.filter.passengers">
    <u-here-map-marker
      *ngFor="let marker of mapRide.markers.passengersClear"
      [config]="marker"
      [draggable]="builderDataService.lockedMap ? false : marker.draggable"
    ></u-here-map-marker>
  </ng-container>
  <ng-container *ngIf="mapRide.filter.stations">
    <u-here-map-path
      *ngFor="let path of mapRide.paths"
      [config]="path"
      [draggable]="builderDataService.lockedMap ? false : path.draggable"
      (update)="builderDataService.mapDirectionsChanged($event)"
    ></u-here-map-path>
  </ng-container>
  <ng-container 
    *ngIf="mapRide.showMonitoredPaths && builderDataService.config().buildMode === builderBuildMode.Routes && builderDataService.config().mode === builderMode.Edit"
  >
    <u-here-map-path
      *ngFor="let monitoredPath of mapRide.monitoredPaths"
      [config]="monitoredPath.config"
    ></u-here-map-path>
    <u-here-map-markers-info
      *ngFor="let monitoredPathsMarker of mapRide.monitoredPathsMarkers"
      [markers]="monitoredPathsMarker"
    ></u-here-map-markers-info>
  </ng-container>
</u-here-map>
