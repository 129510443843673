import { Pipe, PipeTransform } from '@angular/core';

import { RouteWeeklyRow } from '@app/routes/models';

@Pipe({
  name: 'routesWeeklyHasRequiredRecalculationRides'
})
export class RoutesWeeklyHasRequiredRecalculationRidesPipe implements PipeTransform {
  transform(route: RouteWeeklyRow): boolean {
    return !!route.rides && route.rides.some(ride => ride.requiredRecalculation);
  }
}
