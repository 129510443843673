<div class="notes-list-header">
  <div class="notes-list-header__title">
    <div class="notes-list-header__title-text">
      <span class="notes-list-header__title-text-main">{{config.dictionary.title | translate}}</span>
      <span
        *ngIf="subTitle"
        class="notes-list-header__title-text-sub"
        [uTooltip]="subTitle"
        [container]="'body'"
      >
        {{subTitle}}
      </span>
    </div>
  </div>
  <span class="notes-list-header__close" (click)="close()"></span>
</div>
<div class="notes-list-body">
  <button
    uButton
    class="u-font-weight-medium notes-list-body__add"
    [withPlus]="true"
    [disabled]="pendingNewNote"
    (click)="addNewNote($event)"
  >
    {{config.dictionary.new  | translate}}
  </button>
  <div class="notes-list-body__content">
    <app-note
      *ngFor="let note of notes; trackBy: trackById;"
      [note]="note"
      [attr.id]="'note' + note.id"
      (remove)="onRemoveNote($event)"
      (noteRead)="noteRead($event)"
      (noteSave)="saveNewNote($event)"
      (noteUpdate)="editNote.emit($event)"
      (clickInsideNote)="onClickInsideNote($event)"
      (clickOutsideNote)="onClickOutsideNote($event)"
    ></app-note>
  </div>
</div>
