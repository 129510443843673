import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';

import { InputShift, InputShiftDayOfWeek } from '@app/shared/models';
import { AuthCustomerType } from '@app/auth/models';
import { inputShiftsConfig } from '@app/shared/configs';

@Injectable({
  providedIn: 'root'
})
export class InputShiftsService {
  form = this.fb.array([]);
  shiftId = 0;
  customerCategoryType: AuthCustomerType;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  initForm(form: UntypedFormArray, customerCategoryType?: AuthCustomerType) {
    const shifts: InputShift[] = form.value;

    if (shifts.length) {
      form.controls = [];

      shifts.forEach(shift => form.push(this.generateShift(shift)));
    }

    this.form = form;
    this.customerCategoryType = customerCategoryType;
  }

  checkUniqueShiftName = (control: AbstractControl): Observable<{ unique: boolean; }> => {
    if (!control.valueChanges || control.pristine) {
      return of(null);
    }

    return control.valueChanges
      .pipe(
        debounceTime(0),
        distinctUntilChanged(),
        take(1),
        map(() => {
          if (this.form && this.form.value.filter((ob: InputShift) => ob.name === control.value).length > 1) {
            return { unique: true };
          }

          return null;
        })
      );
  };

  generateShiftDays(): InputShiftDayOfWeek[] {
    const activeDays = (inputShiftsConfig[this.customerCategoryType] || inputShiftsConfig.default).activeDays;
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push({
        check: i < activeDays,
        dayOfWeek: i,
        startTime: null,
        endTime: null
      });
    }

    return days;
  }

  generateShift(shift: InputShift): UntypedFormGroup {
    const days = this.generateShiftDays();

    return this.fb.group({
      shiftId: [ shift.shiftId ],
      name: [ shift.name, [ Validators.required ], this.checkUniqueShiftName ],
      days: [ days.map(obDay => {
        const day = shift.days.find(ob => ob.dayOfWeek === obDay.dayOfWeek);

        if (day) {
          return {
            check: !!(day.startTime || day.endTime),
            dayOfWeek: day.dayOfWeek,
            startTime: day.startTime,
            endTime: day.endTime
          };
        }

        return {
          check: obDay.check,
          dayOfWeek: obDay.dayOfWeek,
          startTime: obDay.startTime,
          endTime: obDay.endTime
        };
      }) ]
    });
  }

  addShift() {
    this.shiftId--;
    this.form.markAsDirty();
    this.form.insert(0, this.generateShift(new InputShift({ shiftId: this.shiftId })));
  }

  removeShift({ index }: { index: number }) {
    this.form.markAsDirty();
    this.form.removeAt(index);
  }
}
