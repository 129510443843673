import { Injectable } from '@angular/core';

import { commonConfig } from '@app/shared/configs';
import { VisibleComponent, VisibleComponents } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class CommonStorageService {
  get(): { visibleComponents: Partial<VisibleComponents>; } {
    return JSON.parse(localStorage.getItem(commonConfig.storageKey));
  }

  update(data: { visibleComponents?: Partial<VisibleComponents>; }) {
    localStorage.setItem(commonConfig.storageKey, JSON.stringify({
      ...this.get(),
      ...data
    }));
  }

  getVisibleComponents() {
    return this.get()?.visibleComponents;
  }

  updateVisibleComponents(visibleComponents: Partial<VisibleComponents>) {
    this.update({
      visibleComponents: Object.keys(visibleComponents)
        .reduce((acc, key: VisibleComponent) =>
          commonConfig.visibleComponentsStorage.includes(key) ? { ...acc, [key]: visibleComponents[key] } : acc
        , {})
    });
  }
}
