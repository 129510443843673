<div class="activities-item__wrapper" (mouseover)="toggleRemoveIcon(true)" (mouseleave)="toggleRemoveIcon(false)">
  <div class="activities-item__icon" [ngClass]="{ 'activities-item__icon_type-temp-comment': activity().type === activityType.TempComment }"></div>
  <div class="activities-item__content">
    <div class="activities-item__content-item activities-item__type">
      <div class="activities-item__title">{{(config().dictionary.types | translate)[activity().additionalData?.type || activity().type]}}</div>
      <div class="activities-item__actions">
        <div
          class="activities-item__actions-icon activities-item__actions-icon_clock"
          [uTooltip]="doneBy"
          [tooltipClass]="'u-tooltip_general'"
          [placement]="[ 'top' ]"
          [container]="'body'"
        ></div>
        <ng-template #doneBy>
          <div class="activities-item-clock">
            <div class="activities-item-clock__text">{{config().dictionary.doneBy | translate}} {{activity().createdBy}}</div>
            <div class="activities-item-clock__text">
              {{activity().createdDateTime | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}
            </div>
          </div>
        </ng-template>
        <div
          class="activities-item__actions-icon activities-item__actions-icon_archive"
          [ngClass]="{ 'activities-item__actions-icon_disabled': activity().isArchived }"
          [uTooltip]="notificationWillBeArchived"
          [tooltipClass]="'u-tooltip_general'"
          [placement]="[ 'top' ]"
          [disableTooltip]="activity().isArchived"
          [container]="'body'"
          (click)="!activity().isArchived && archive.emit(activity().activityId)"
        ></div>
        <ng-template #notificationWillBeArchived>
          <div class="activities-item-archive__text">{{config().dictionary.notificationWillBeArchived | translate}}</div>
        </ng-template>
        <div
          class="activities-item__actions-icon activities-item__actions-icon_excel"
          *ngIf="
            activity().type === activityType.RouteBulkChange && activity().additionalData &&
            activity().additionalData.changeStatus && activity().additionalData.changeStatus !== activityAdditionalStatus.Success
          "
          [ngClass]="{ 'activities-item__actions-icon_disabled': !activity().additionalData.fileCacheKey }"
          [uTooltip]="fileIsExpired"
          [tooltipClass]="'u-tooltip_general'"
          [placement]="[ 'top' ]"
          [container]="'body'"
          [disableTooltip]="!!activity().additionalData.fileCacheKey"
          (click)="activity().additionalData.fileCacheKey && downloadExcel.emit(activity())"
        >
        </div>
        <ng-template #fileIsExpired>
          <div class="activities-item-excel__text">{{config().dictionary.fileIsExpired | translate}}</div>
        </ng-template>
        <div
          *ngIf="showDelete()"
          class="activities-item__actions-icon activities-item__actions-icon_remove"
          (click)="delete.emit(activity().activityId)"
        ></div>
        <div
          *ngIf="activity().type === activityType.RouteAuditCompleted || activity().type === activityType.RouteRestored || activity().type === activityType.RouteCancelled"
          class="activities-item__actions-icon activities-item__actions-icon_email"
          [ngClass]="{ 'activities-item__actions-icon_disabled': !activity().additionalData.shuttleCompaniesEmailKeys }"
          [uTooltip]="emailIsExpired"
          [tooltipClass]="'u-tooltip_general'"
          [placement]="[ 'top' ]"
          [container]="'body'"
          [disableTooltip]="!!activity().additionalData.shuttleCompaniesEmailKeys"
          (click)="activity().additionalData.shuttleCompaniesEmailKeys && sendEmails.emit(activity())"
        ></div>
        <ng-template #emailIsExpired>
          <div class="activities-item-email__text">{{config().dictionary.emailIsExpired | translate}}</div>
        </ng-template>
      </div>
    </div>

    <ng-container *ngIf="activity().type === activityType.RoutePassengerUpdated">
      <div class="activities-item__content-item activities-item__route-name activities-item__route-name_expanded">
        {{activity().routeCode}} {{activity().routeName}}
      </div>
      <div class="activities-item__content-item">
        {{activity().additionalData.passengerName}}
      </div>
      <div class="activities-item__content-item">
        <div class="activities-item__dots-list">
          <div 
            *ngFor="let change of activity().additionalData.changes" 
            class="activities-item__dots-list-item"
          >
            {{(config().dictionary.passengerChanges | translate)[change.type]}}{{(activity() | activitiesPassengerChangeDash: change) ? ' - ' : ' '}}{{(activity() | activitiesPassengerChangeExtraData: change) | translate}}
          </div>
        </div>
      </div>
      <div
        class="activities-item__content-item activities-item__status"
        [ngClass]="{
          'activities-item__status_success': !activity().additionalData.isRouteUpdateFailed,
          'activities-item__status_fail': activity().additionalData.isRouteUpdateFailed
        }"
      >
        {{(activity().additionalData.isRouteUpdateFailed ? config().dictionary.statuses.failed : config().dictionary.statuses.success) | translate}}
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.RoutePassengerRemoved">
      <div class="activities-item__content-item activities-item__route-name activities-item__route-name_expanded">
        {{activity().routeCode}} {{activity().routeName}}
      </div>
      <div class="activities-item__content-item">
        {{activity().additionalData.passengerName}}
      </div>
      <div
        class="activities-item__content-item activities-item__status"
        [ngClass]="{
          'activities-item__status_success': !activity().additionalData.isRouteUpdateFailed,
          'activities-item__status_fail': activity().additionalData.isRouteUpdateFailed
        }"
      >
        {{(activity().additionalData.isRouteUpdateFailed ? config().dictionary.statuses.failed : config().dictionary.statuses.success) | translate}}
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.RouteNewYearDuplicated || activity().type === activityType.TemplateNewYearDuplicated">
      <div class="activities-item__content-item activities-item__route-name activities-item__route-name_expanded">
        {{activity().routeCode}} {{activity().routeName}}
      </div>
      <div *ngIf="activity().type === activityType.RouteNewYearDuplicated" class="activities-item__content-item">
        {{config().dictionary.routeDuplicated | translate}}{{activity().additionalData.isAnyPassengersRemoved ? (' - ' + (config().dictionary.somePassengersWereRemoved | translate)) : null}}
      </div>
      <div *ngIf="activity().type === activityType.TemplateNewYearDuplicated" class="activities-item__content-item">
        {{config().dictionary.templateDuplicated | translate}}{{activity().additionalData.isAnyPassengersRemoved ? (' - ' + (config().dictionary.somePassengersWereRemoved | translate)) : null}}
      </div>
    </ng-container>
    
    <ng-container *ngIf="activity().type === activityType.RouteRestored || activity().type === activityType.RouteCancelled">
      <div class="activities-item__content-item activities-item__route-name activities-item__route-name_expanded">
        {{activity().routeCode}} {{activity().routeName}}
      </div>
      <div class="activities-item__content-item">
        {{config().dictionary[activity().type === activityType.RouteRestored ? 'rideRestoration': 'rideCancelation'] | translate}}
      </div>
      <div class="activities-item__content-item activities-item__additional-status">
        <div
          class="activities-item__additional-status-text"
          [ngClass]="{
            'activities-item__additional-status-text_failed': activity().additionalData.status === activityAdditionalStatus.Failed || activity().additionalData.status === activityAdditionalStatus.PartialSuccess,
            'activities-item__additional-status-text_finished': activity().additionalData.status === activityAdditionalStatus.Success
          }"
        >
          {{(config().dictionary.routeRestoredAndRouteCancelledStatuses[activity().additionalData.status] | translate)}}
        </div>
        <div
          *ngIf="activity().additionalData.failedPeriods && activity().additionalData.failedPeriods.length"
          class="activities-item__arrow"
          [ngClass]="{ 'activities-item__arrow_up': activity().expanded }"
          (click)="toggle.emit(activity().activityId)"
        ></div>
      </div>
      <div
        *ngIf="activity().additionalData.failedPeriods && activity().additionalData.failedPeriods.length && activity().expanded"
        class="activities-item__comment"
      >
        <div
          *ngFor="let failedPeriod of activity().additionalData.failedPeriods"
          class="activities-item__comment-item"
        > 
          <ng-container *ngIf="failedPeriod.dateFrom === failedPeriod.dateTo">
            {{failedPeriod.dateFrom | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_SHORT}}
          </ng-container>
          <ng-container *ngIf="failedPeriod.dateFrom !== failedPeriod.dateTo">
            {{failedPeriod.dateFrom | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_SHORT}} -
            {{failedPeriod.dateTo | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_SHORT}}
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.RouteAuditCompleted || activity().type === activityType.RouteSaveFail">
      <div class="activities-item__content-item activities-item__route-name activities-item__route-name_expanded">
        {{activity().routeCode}} {{activity().routeName}}
      </div>
      <div
        *ngIf="activity().type === activityType.RouteAuditCompleted"
        class="activities-item__content-item activities-item__status activities-item__status_success"
      >
        {{config().dictionary.statuses.success | translate}}
      </div>
      <div
        *ngIf="activity().type === activityType.RouteSaveFail && activity().additionalData && activity().additionalData.errorDescriptions"
        class="activities-item__content-item activities-item__status activities-item__status_fail"
      >
        <div class="activities-item__status-item" *ngFor="let activityErrorDescription of activity().additionalData.errorDescriptions">
          {{activityErrorDescription}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.TempComment">
      <div
        class="activities-item__content-item activities-item__route-name"
        [ngClass]="{ 'activities-item__route-name_expanded': activity().expanded }"
      >
        {{activity().routeName}}
      </div>
      <div class="activities-item__content-item activities-item__content-item_arrow">
        <div class="activities-item__period">
          {{activity().startDateTime | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_SIMPLE}} -
          {{activity().endDateTime | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_SIMPLE}}
        </div>
        <div class="activities-item__arrow" [ngClass]="{ 'activities-item__arrow_up': activity().expanded }" (click)="toggle.emit(activity().activityId)"></div>
      </div>
      <div *ngIf="activity().expanded" class="activities-item__comment">
        {{activity().additionalData.comment}}
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.RouteBulkChange">
      <div
        class="activities-item__content-item activities-item__route-name"
        [ngClass]="{ 'activities-item__route-name_expanded': !activity().additionalData.fileCacheKey || activity().expanded }"
      >
        {{(config().dictionary.bulk.changeSubject[activity().additionalData.changeSubject] | translate)}} - {{activity().additionalData.routesCount}} {{config().dictionary.bulk.routes | translate}}
      </div>
      <div class="activities-item__content-item activities-item__additional-status">
        <div
          class="activities-item__additional-status-text"
          [ngClass]="{
            'activities-item__additional-status-text_saving': activity().additionalData.changeStatus === activityAdditionalStatus.InProgress,
            'activities-item__additional-status-text_failed': activity().additionalData.changeStatus === activityAdditionalStatus.Failed || activity().additionalData.changeStatus === activityAdditionalStatus.PartialSuccess,
            'activities-item__additional-status-text_finished': activity().additionalData.changeStatus === activityAdditionalStatus.Success
          }"
        >
          {{(config().dictionary.bulk.statuses | translate)[activity().additionalData.changeStatus]}}
        </div>
        <div
          *ngIf="activity().additionalData.fileCacheKey"
          class="activities-item__arrow"
          [ngClass]="{ 'activities-item__arrow_up': activity().expanded }"
          (click)="toggle.emit(activity().activityId)"
        ></div>
      </div>
      <div
        *ngIf="activity().additionalData.fileCacheKey && activity().expanded"
        class="activities-item__comment"
      >
        {{config().dictionary.bulk.description | translate}}
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.TemplateFromRoute">
      <div class="activities-item__content-item activities-item__route-name activities-item__route-name_expanded">
        {{activity().routeCode}} {{activity().routeName}}
      </div>
      <div class="activities-item__content-item activities-item__additional-status">
        <div
          class="activities-item__additional-status-text"
          [ngClass]="{
            'activities-item__additional-status-text_saving': activity().additionalData.status === activityAdditionalStatus.InProgress,
            'activities-item__additional-status-text_failed': activity().additionalData.status === activityAdditionalStatus.Failed || activity().additionalData.status === activityAdditionalStatus.NoRidesAvailable,
            'activities-item__additional-status-text_finished': activity().additionalData.status === activityAdditionalStatus.Success
          }"
        >
          {{(config().dictionary.templateFromRoute.statuses | translate)[activity().additionalData.status]}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.RoutesFromTemplates">
      <div 
        *ngIf="activity() | activityShuttleCompanyHasMoreThanSingleContract"
        class="activities-item__content-item activities-item__additional-status"
      >
        <div class="activities-item__additional-status-text activities-item__additional-status-text_failed">
          {{config().dictionary.routesFromTemplates.shuttleCompanyHasMoreThanSingleContractForSelectedPeriod | translate}}
        </div>
      </div>
      <div class="activities-item__content-item activities-item__additional-status">
        <div
          class="activities-item__additional-status-text"
          [ngClass]="{
            'activities-item__additional-status-text_saving': activity().additionalData.status === activityAdditionalStatus.InProgress,
            'activities-item__additional-status-text_failed': activity().additionalData.status === activityAdditionalStatus.Failed || activity().additionalData.status === activityAdditionalStatus.PartialSuccess,
            'activities-item__additional-status-text_finished': activity().additionalData.status === activityAdditionalStatus.Success
          }"
        >
          {{(config().dictionary.routesFromTemplates.statuses | translate)[activity().additionalData.status]}}
        </div>
        <div
          class="activities-item__arrow"
          [ngClass]="{ 'activities-item__arrow_up': activity().expanded }"
          (click)="toggle.emit(activity().activityId)"
        ></div>
      </div>
      <div
        *ngIf="activity().expanded"
        class="activities-item__comment"
      >
        <ng-container *ngFor="let processedTemplate of activity().additionalData.processedTemplates">
          <div
            *ngFor="let creationResult of processedTemplate.creationResults"
            class="activities-item__comment-item"
            [ngClass]="{ 
              'activities-item__comment-item_failed': !(config().processedTemplatesSuccessCreationResults | includes: creationResult.result) 
            }"
          >
            <ng-container *ngIf="creationResult.date">
              {{creationResult.date | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_SHORT }} - {{processedTemplate.templateCode}}
            </ng-container>
            <ng-container *ngIf="!creationResult.date">{{processedTemplate.templateCode}} - {{processedTemplate.templateName}}</ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.ManualScRoutesSync">
      <div class="activities-item__content-item">{{activity().additionalData.syncSystem}}</div>
      <div class="activities-item__content-item activities-item__additional-status">
        <div *ngIf="activity().additionalData.syncedRoutesCount" class="activities-item__additional-status-text activities-item__additional-status-text_finished">
          {{activity().additionalData.syncedRoutesCount}} {{(config().dictionary.routesSent | translate)}}
        </div>
        <div *ngIf="activity().additionalData.failedRoutesCount" class="activities-item__additional-status-text activities-item__additional-status-text_failed">
          {{activity().additionalData.failedRoutesCount}} {{(config().dictionary.routesFailed | translate)}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activity().type === activityType.ReOptimization">
      <div
        *ngIf="activity().routeCode || activity().routeName"
        class="activities-item__content-item activities-item__route-name activities-item__route-name_expanded"
      >
        {{activity().routeCode}} {{activity().routeName}}
      </div>
      <div class="activities-item__content-item activities-item__additional-status">
        <div
          class="activities-item__additional-status-text"
          [ngClass]="{
            'activities-item__additional-status-text_saving': activity().additionalData.status === activityAdditionalStatus.InProgress || activity().additionalData.status === activityAdditionalStatus.Started,
            'activities-item__additional-status-text_failed': activity().additionalData.status === activityAdditionalStatus.Failed || activity().additionalData.status === activityAdditionalStatus.AnotherSessionAlreadyInProgress,
            'activities-item__additional-status-text_finished': activity().additionalData.status === activityAdditionalStatus.Finished
          }"
        >
          {{(config().dictionary.reOptimizationStatuses | translate)[activity().additionalData.status]}}
        </div>
      </div>
    </ng-container>
  </div>
</div>

