<div class="passenger-ask-popup__wrapper">
  <div class="passenger-ask-popup__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="passenger-ask-popup__main">
    <div class="passenger-ask-popup__message">
      
      {{config.dictionary.message | translate}}
    </div>
    <div class="passenger-ask-popup__list">
      <div class="passenger-ask-popup__item" *ngFor="let item of checkboxes">
        <div class="passenger-ask-popup__item-field">
          <u-checkbox class="u-checkbox u-checkbox_square-medium" [(ngModel)]="item.check"></u-checkbox>
        </div>
        <div class="passenger-ask-popup__item-name">{{item.name | translate}}</div>
      </div>
    </div>
    <div class="passenger-ask-popup__actions">
      <button class="passenger-ask-popup__actions-btn passenger-ask-popup__actions-btn_clear" (click)="close()">{{config.dictionary.cancel | translate}}</button>
      <button class="passenger-ask-popup__actions-btn" (click)="apply()">{{config.dictionary.apply | translate}}</button>
    </div> 
  </div>
</div>
