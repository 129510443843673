import { USearchFilter, USearchFilterType } from '@shift/ulib';

import { demandsConfig } from '@app/demands/configs';
import { DaysOfWeek, HeaderMenuIconsTemplate, GlobalSearchFilter, TablePageConfig, ShiftsOption, DepartmentsOption } from '@app/shared/models';
import {
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType,
  AuthModuleName,
  AuthModuleDemandsShiftsViewFeatureType
} from '@app/auth/models';
import { DemandsShiftsViewInitialDataType, DemandsView } from '@app/demands/models';

const DEMANDS_DICTIONARY_PATH: string = 'demands';
const DICTIONARY_PATH: string = `${DEMANDS_DICTIONARY_PATH}.shiftsView`;

const columns = [
  {
    prop: 'hours',
    name: `${DICTIONARY_PATH}.columns.hours`,
    filterType: 'select',
    minWidth: 144,
    cellTemplateName: 'hoursCell'
  },
  {
    prop: 'shiftName',
    name: `${DICTIONARY_PATH}.columns.shift`,
    filterType: 'select',
    minWidth: 144,
    cellTemplateName: 'shiftCell',
    filterSortingFnName: 'shiftNameFilterSortingFn'
  }
];

const branchesFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'branchIds',
  type: USearchFilterType.Multiselect,
  title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.branches`
};

const shiftsFilter: GlobalSearchFilter = {
  items: [
    {
      value: ShiftsOption.Special,
      name: `${DEMANDS_DICTIONARY_PATH}.globalFilters.options.shifts.special`
    }
  ],
  itemsLazyLoad: true,
  value: [],
  name: 'shiftIds',
  type: USearchFilterType.Multiselect,
  title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.shifts`
};

const departmentsFilter: GlobalSearchFilter = {
  items: [
    {
      value: DepartmentsOption.Without,
      name: 'general.without'
    }
  ],
  itemsLazyLoad: true,
  value: [],
  name: 'departmentIds',
  type: USearchFilterType.Multiselect,
  title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.departments`,
  feature: {
    module: AuthModuleName.Departments,
    name: AuthModuleDepartmentsFeature.Type,
    values: [ AuthModuleDepartmentsFeatureType.Generic, AuthModuleDepartmentsFeatureType.Iec ]
  }
};

const baseFilters: (GlobalSearchFilter)[] = [
  departmentsFilter,
  {
    ...departmentsFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.departmentsDivision`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Division ]
    }
  },
  {
    ...departmentsFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.commands`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Section ]
    }
  }
];

const globalFilters: USearchFilter[] = [
  branchesFilter,
  shiftsFilter,
  ...baseFilters
];

const globalFiltersStudent: USearchFilter[] = [
  {
    ...branchesFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.activityCenters`
  },
  {
    ...shiftsFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.activities`
  },
  ...baseFilters
];

const globalFiltersSoldier: USearchFilter[] = [
  {
    ...branchesFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.bases`
  },
  shiftsFilter,
  ...baseFilters
];

const initialDataFilters = {
  branchIds: DemandsShiftsViewInitialDataType.Branches,
  shiftIds: DemandsShiftsViewInitialDataType.Shifts,
  departmentIds: DemandsShiftsViewInitialDataType.Departments
};

const tablePageConfig: TablePageConfig = {
  id: 'demandsShiftsTable',
  trackingId: demandsConfig.tracking[DemandsView.Shifts].default.id,
  headerMenuIconsTemplate: HeaderMenuIconsTemplate.DemandsShiftsView,
  modalComponent: null,
  tableConfig: {
    columns,
    showCounters: true,
    showSelected: false
  }
};

const demandsShiftsViewComponent = {
  dictionary: {
    specialShift: `${DEMANDS_DICTIONARY_PATH}.globalFilters.options.shifts.special`,
    weekDays: `${DEMANDS_DICTIONARY_PATH}.weekDays`,
    to: `${DICTIONARY_PATH}.to`,
    yes: 'general.yes',
    no: 'general.no'
  },
  emptyTime: '--:--',
  tablePageConfig,
  daysOfWeek: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ],
  rowPadding: 40,
  departmentHeight: 22,
  shiftHeight: 40,
  globalFilters: globalFilters,
  initialDataFilters
};

const demandsShiftsViewStudentComponent = {
  ...demandsShiftsViewComponent,
  tablePageConfig: {
    ...demandsShiftsViewComponent.tablePageConfig,
    id: 'demandsShiftsTableStudent',
    trackingId: demandsConfig.tracking[DemandsView.Shifts].activitySchedule.id
  },
  globalFilters: globalFiltersStudent
};

const demandsShiftsViewSoldierComponent = {
  ...demandsShiftsViewComponent,
  tablePageConfig: {
    ...demandsShiftsViewComponent.tablePageConfig,
    id: 'demandsShiftsTableSoldier'
  },
  globalFilters: globalFiltersSoldier
};

const demandsShiftsViewGenericComponent = {
  ...demandsShiftsViewComponent,
  tablePageConfig: {
    ...demandsShiftsViewComponent.tablePageConfig,
    id: 'demandsShiftsTableGeneric'
  }
};

export const demandsShiftsViewComponentConfig = {
  default: demandsShiftsViewComponent,
  [AuthModuleDemandsShiftsViewFeatureType.Student]: demandsShiftsViewStudentComponent,
  [AuthModuleDemandsShiftsViewFeatureType.Generic]: demandsShiftsViewGenericComponent,
  [AuthModuleDemandsShiftsViewFeatureType.Soldier]: demandsShiftsViewSoldierComponent
};
