<div class="passengers-routes-popup__container">
  <div class="passengers-routes-popup__close" (click)="closePopup()"></div>
  <div class="passengers-routes-popup__title-wrapper">
    <div class="passengers-routes-popup__title">
      {{config.dictionary.title | translate}}
    </div>
  </div>

  <div class="passengers-routes-popup__grid">
    <u-grid
      class="u-grid_white u-grid_app-routes-remove"
      [tableName]="'passenger_route_popup'"
      [saveTableProps]="true"
      [columns]="columns"
      [rows]="routes"
      [columnMode]="'force'"
      [headerHeight]="35"
      [footerHeight]="0"
      [rowHeight]="50"
      [reorderable]="true"
      [scrollbarV]="true"
      [scrollbarH]="false"
      [isRtl]="isRtl"
      [lang]="lang"
      [showColumnsFilter]="false"
      [messages]="'uGrid' | translate"
    ></u-grid>
  </div>

  <div class="passengers-routes-popup__buttons">
    <button
      class="passengers-routes-popup__buttons-cancel"
      (click)="closePopup()"
    >
      {{config.dictionary.actions.cancel | translate}}
    </button>
    <button
      class="passengers-routes-popup__buttons-submit"
      (click)="submit()"
    >
      {{config.dictionary.actions.save | translate}}
    </button>
  </div>

  <ng-template 
    ngx-datatable-cell-template
    let-row="row"
    #direction
  >
    <div class="passengers-routes-popup-direction">
      <span
        *ngIf="row.direction === directionType.Forward"  
        class="passengers-routes-popup-direction__text"
      >
        {{config.dictionary.directions.forward | translate}}
      </span>
      <span
        *ngIf="row.direction === directionType.Backward"  
        class="passengers-routes-popup-direction__text"
      >
        {{config.dictionary.directions.backward | translate}}
      </span>
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    let-value="value"
    #normalCell
  >
    <div
      [uTooltip]="value"
      [container]="'body'"
      class="passengers-routes-popup-cell"
    >
      {{value}}
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    let-row="row"
    #routePeriod
  >
    <div
      class="passengers-routes-popup-period"
    >
      <ng-container *ngIf="row.isTemplate">
        {{config.dictionary.table.template | translate}}
      </ng-container>
      <ng-container *ngIf="!row.isTemplate">
        <span class="passengers-routes-popup-period__date">{{row.dateFrom | date:config.dateFormat}}</span>
        <span class="passengers-routes-popup-period__divider">-</span>
        <span class="passengers-routes-popup-period__date">{{row.dateTo | date:config.dateFormat}}</span>
      </ng-container>
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    #keepOnRouteHeader
  >
    <div class="passengers-routes-popup-keep-on-route__header">
      <u-group class="u-group_app-passengers-routes-popup">
        <u-checkbox
          class="u-checkbox_box"
          [formControl]="actionsForm.get('keepOnRoute.all')"
        ></u-checkbox>
        <span class="passengers-routes-popup-keep-on-route__header-text">
          {{config.dictionary.table.keepOnRoute | translate}}
        </span>
      </u-group>
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    #keepOnRoute
    let-rowIndex="rowIndex"
  >
    <u-checkbox
      class="u-checkbox_box"
      [formControl]="keepOnRouteControls.at(rowIndex).get('keepOnRoute')"
    ></u-checkbox>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    #updateRouteHeader
  >
    <div class="passengers-routes-popup-update-route__header">
      <u-group class="u-group_app-passengers-routes-popup">
        <u-checkbox
          class="u-checkbox_box"
          [formControl]="actionsForm.get('updateRoute.all')"
        ></u-checkbox>
        <span class="passengers-routes-popup-update-route__header-text">
          {{config.dictionary.table.updateRoute | translate}}
        </span>
        <div
          class="passengers-routes-popup-update-route__header-icon"
          [uTooltip]="updateRouteInfo"
          [container]="'body'"
          [placement]="[ 'top' ]"
        ></div>
        <ng-template #updateRouteInfo>
          <div class="passengers-routes-popup-update-route__info">
            <div class="passengers-routes-popup-update-route__info-text">
              {{config.dictionary.table.updateRouteInfo.firstPart | translate}}
            </div>
            <div class="passengers-routes-popup-update-route__info-text">
              {{config.dictionary.table.updateRouteInfo.secondPart | translate}}
            </div>
          </div>
        </ng-template>
      </u-group>
    </div>
  </ng-template>

  <ng-template
    ngx-datatable-cell-template
    #updateRoute
    let-rowIndex="rowIndex"
  >
    <u-checkbox
      class="u-checkbox_box"
      [formControl]="updateRouteControls.at(rowIndex).get('updateRoute')"
    ></u-checkbox>
  </ng-template>
</div>
