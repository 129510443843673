import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { AppConstants } from '@app/shared/constants';
import {
  BuilderActiveRide,
  BuilderStationArrivalTimeFormField,
  BuilderStationArrivalTimeSaveAction,
  BuilderStationListItem
} from '@app/builder/models';
import { builderStationArrivalTimeComponentConfig } from './builder-station-arrival-time.component.config';

@Component({
  selector: 'app-builder-station-arrival-time',
  templateUrl: './builder-station-arrival-time.component.html',
  styleUrls: [ './builder-station-arrival-time.component.scss', './builder-station-arrival-time.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderStationArrivalTimeComponent implements OnInit {
  @Input() station: BuilderStationListItem;
  @Input() activeRide: BuilderActiveRide;

  @Output() closeAction: EventEmitter<void> = new EventEmitter();
  @Output() saveAction: EventEmitter<BuilderStationArrivalTimeSaveAction> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-station-arrival-time-popup';

  form: UntypedFormGroup;
  builderStationArrivalTimeFormField = BuilderStationArrivalTimeFormField;
  config = cloneDeep(builderStationArrivalTimeComponentConfig);

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = this.fb.group({
      [BuilderStationArrivalTimeFormField.Time]: [
        this.station && this.station.arrivalTime && moment(this.station.arrivalTime).format(AppConstants.TIME_FORMAT) || null
      ],
      [BuilderStationArrivalTimeFormField.DayAfter]: [
        this.station && this.station.arrivalTime && this.activeRide &&
        moment(moment(this.station.arrivalTime).format(AppConstants.DATE_FORMAT_BASE_LINE)).isAfter(this.activeRide.date)
      ]
    });
  }

  editStationArrivalTime() {
    if (!this.form.dirty) {
      this.closeAction.emit();

      return;
    }

    this.saveAction.emit({
      arrivalTime: this.form.get(BuilderStationArrivalTimeFormField.Time).value,
      isNextDay: !!this.form.get(BuilderStationArrivalTimeFormField.DayAfter).value
    });
  }
}
