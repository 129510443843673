<div class="demands-excel-export__wrapper">
  <div class="demands-excel-export__main">
    <div
      class="demands-excel-export__title"
      [translate]="config.dictionary.title"
    >
    </div>
    <div class="demands-excel-export__block">
      <u-date-range-presets
        class="u-date-range-presets_app-demands-excel-export"
        [presets]="uDateRangePresets"
        [activePreset]="activePreset"
        [activeWeekDate]="startDate"
        [checkDaysBtn]="false"
        [defaultDate]="false"
        [maxPeriodSelectionFromSelectedDate]="{ amount: 1, unit: 'months' }"
        [mode]="uDateRangePresetsMode.GroupRange"
        (changeDatesAction)="changeDatesAction($event)"
      ></u-date-range-presets>
    </div>
    <div class="demands-excel-export__actions">
      <button
        class="demands-excel-export__actions-btn demands-excel-export__actions-btn_cancel"
        (click)="closeModal()"
      >
        {{config.dictionary.cancel | translate}}
      </button>
      <button
        class="demands-excel-export__actions-btn"
        (click)="applyAction()"
      >
        {{config.dictionary.apply | translate}}
      </button>
    </div>
  </div>
</div>
