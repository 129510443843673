import { AuthModuleDemandsPassengersViewFeatureType } from '@app/auth/models';
import {
  DemandsPassengerShiftSeriesUpdateType,
  DemandsShiftRepeatOptionsCheckbox
} from '@app/demands/models';

const DICTIONARY_PATH: string = 'demands.shiftRepeatOptions';

const dictionary: { [key: string]: string; } = {
  message: `${DICTIONARY_PATH}.editRepeatingShift`,
  cancel: `${DICTIONARY_PATH}.cancel`,
  confirm: `${DICTIONARY_PATH}.confirm`
};

const onlyCurrentShiftOption: DemandsShiftRepeatOptionsCheckbox = {
  check: true,
  value: DemandsPassengerShiftSeriesUpdateType.OnlyCurrentDemand,
  name: `${DICTIONARY_PATH}.onlyCurrentShift`
};

const thisAndAllFutureShiftsOption: DemandsShiftRepeatOptionsCheckbox = {
  check: false,
  value: DemandsPassengerShiftSeriesUpdateType.CurrentAndFutureDemands,
  name: `${DICTIONARY_PATH}.thisAndAllFutureShifts`
};

const allShiftsInTheSeriesOption: DemandsShiftRepeatOptionsCheckbox = {
  check: false,
  value: DemandsPassengerShiftSeriesUpdateType.AllDemands,
  name: `${DICTIONARY_PATH}.allShiftsInTheSeries`
};

const options: DemandsShiftRepeatOptionsCheckbox[] = [ onlyCurrentShiftOption, thisAndAllFutureShiftsOption, allShiftsInTheSeriesOption ];

const optionsMunicipality: DemandsShiftRepeatOptionsCheckbox[] = [
  { ...onlyCurrentShiftOption, name: `${DICTIONARY_PATH}.onlyCurrentAssignment` },
  { ...thisAndAllFutureShiftsOption, name: `${DICTIONARY_PATH}.thisAndAllFutureAssignments` },
  { ...allShiftsInTheSeriesOption, name: `${DICTIONARY_PATH}.allAssignmentsInTheSeries` }
];

const demandsShiftRepeatOptionsComponent = {
  dictionaryPath: DICTIONARY_PATH,
  dictionary,
  options,
  activeOption: onlyCurrentShiftOption
};

const demandsShiftRepeatOptionsComponentMunicipality = {
  ...demandsShiftRepeatOptionsComponent,
  dictionary: {
    ...demandsShiftRepeatOptionsComponent.dictionary,
    message: `${DICTIONARY_PATH}.editRepeatingAssignment`
  },
  options: optionsMunicipality
};

export const demandsShiftRepeatOptionsComponentConfig = {
  default: demandsShiftRepeatOptionsComponent,
  [AuthModuleDemandsPassengersViewFeatureType.Student]: demandsShiftRepeatOptionsComponentMunicipality
};
