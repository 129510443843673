<app-general-card
  [title]="config.dictionary.title | translate"
  [buttons]="config.buttons"
  (buttonClick)="onContinueClick()"
  (closeAction)="onCloseClick()"
>
  <app-table-page
    class="table-page_white table-page_route-split-card"
    [ngClass]="{ 'table-page_route-split-card-with-empty-stations': allowEmptyStations }"
    [config]="config.table"
    (selectRows)="toggleContinueButtonState()"
  ></app-table-page>

  <div class="route-split-card__empty-stations" *ngIf="allowEmptyStations">
    <div class="route-split-card__empty-stations-title">{{config.dictionary.allowEmptyStations.title | translate}}</div>
    <div class="route-split-card__empty-stations-select-action">{{config.dictionary.allowEmptyStations.selectAction | translate}}</div>

    <u-radio-buttons [items]="config.emptyStationsActions" [formControl]="emptyStationsAction" (selectItem)="toggleContinueButtonState()"></u-radio-buttons>
  </div>
</app-general-card>

<ng-template
  #nameCell
  ngx-datatable-cell-template
  let-row="row"
>
  <div
    class="route-split-card-name-title"
    [title]="row.name"
  >
    {{row.name}}
  </div>
  <div
    *ngIf="row.isSupervisor"
    class="route-split-card-name-subtitle"
  >
    {{config.dictionary.supervisorTitlesByFeatureType[supervisorsFeatureType] | translate}}
  </div>
</ng-template>
