<app-general-card
  [title]="config.dictionary.title"
  [buttons]="config.buttons"
  (buttonClick)="setRole()"
  (closeAction)="closeForm()"
>
  <form class="auth-customer-switch-role__form" [formGroup]="form">
    <u-group
      class="u-group_app-auth-customer-switch-role"
      [name]="config.dictionary.customer | translate"
    >
      <u-select-s 
        [classUInput]="'u-input_cursor-pointer'" 
        [items]="customers"
        formControlName="customer"
        [empty]="false"
      ></u-select-s>
    </u-group>
    <u-group
      class="u-group_app-auth-customer-switch-role"
      [name]="config.dictionary.role | translate"
    >
      <u-select-s 
        [classUInput]="'u-input_cursor-pointer'" 
        [items]="roles"
        formControlName="role"
        [empty]="false"
      ></u-select-s>
    </u-group>
  </form>
</app-general-card>
