import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CityCombinationsRule, CityCombinationsRuleType } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class CitiesCombinationsDataService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private clearAttachedCities(attachedCities: UntypedFormControl) {
    attachedCities.patchValue([]);
    attachedCities.clearValidators();
    attachedCities.updateValueAndValidity({ emitEvent: false });
    attachedCities.disable({ emitEvent: false });
  }

  generateCitiesCombinationsRule(rule: CityCombinationsRule): UntypedFormGroup {
    const citiesCombinationsRuleForm = this.fb.group({
      id: [ rule.id ],
      name: [ rule.name, Validators.maxLength(100) ],
      isActive: [ rule.isActive ],
      type: [ rule.type, Validators.required ],
      baseCities: [ rule.baseCities, Validators.required ],
      useTollRoads: [ rule.type === CityCombinationsRuleType.FixedGroup ? rule.useTollRoads : false ],
      attachedCities: [ rule.attachedCities ],
      branchId: [ rule.branchId ]
    });

    const type = citiesCombinationsRuleForm.get('type');
    const baseCities = citiesCombinationsRuleForm.get('baseCities');
    const attachedCities = citiesCombinationsRuleForm.get('attachedCities');

    if (!type.value) {
      baseCities.disable({ emitEvent: false });
      attachedCities.disable({ emitEvent: false });
    }

    if (baseCities && (!baseCities.value || !baseCities.value.length)) { attachedCities.disable(); }

    type.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: string) => {
        baseCities.patchValue([]);

        value ? baseCities.enable({ emitEvent: false }) : baseCities.disable({ emitEvent: false });

        citiesCombinationsRuleForm.get('useTollRoads')
          .patchValue(
            value === CityCombinationsRuleType.FixedGroup ? rule.useTollRoads : false,
            { emitEvent: false }
          );
      });

    baseCities.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: number[]) => {
        if (value.length) {
          attachedCities.enable({ emitEvent: false });

          if (type.value !== CityCombinationsRuleType.FixedGroup) {
            attachedCities.setValidators(Validators.required);
            attachedCities.updateValueAndValidity({ emitEvent: false });
          }
        } else {
          this.clearAttachedCities(attachedCities as UntypedFormControl);
        }
      });

    return citiesCombinationsRuleForm;
  }
}
