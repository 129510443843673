<u-multiselect
  [showSelectAll]="showSelectAll"
  [disableTooltip]="false"
  [placeholder]="placeholder | translate"
  [items]="branchesItems"
  [lazyLoadItems]="branchesLazyLoadItems"
  [classUInput]="classUInput"
  [classUDropdownContent]="classUDropdownContent"
  [formControl]="branchesControl"
  [container]="container"
  [mode]="uMultiselectModeType.Cascade"
  [valid]="disabled || valid"
  [placement]="placement"
  [messages]="'uMultiselect' | translate"
  [readonly]="readonly"
  [infoTooltipTemplates]="{ tooltip: tooltip }"
  [infoTooltipClass]="infoTooltipClass"
></u-multiselect>

<ng-template #tooltip>
  <div class="input-branches__tooltip">
    <p class="input-branches__tooltip-text">
      {{config.dictionary.assignAutomatically.info.title | translate}}
    </p>
    <p class="input-branches__tooltip-text">
      {{config.dictionary.assignAutomatically.info.subtitle | translate}}
    </p>
  </div>
</ng-template>
