import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UHereMapsReverseGeocode, UHereMapsReverseGeocodeLoaderService } from '@shift/ulib';

import { GeoDataService } from '@app/shared/services/api/geo-data.service';

@Injectable({
  providedIn: 'root'
})
export class ReverseGeocodeService implements UHereMapsReverseGeocodeLoaderService {
  constructor(
    private geoDataService: GeoDataService
  ) {}

  fetch(lat: number, lng: number): Observable<UHereMapsReverseGeocode> {
    return this.geoDataService.placeInfo(lat, lng)
      .pipe(
        map(address => ({
          address: address.fullAddress,
          lat: address.latitude,
          lng: address.longitude,
          placeId: address.placeId
        }))
      );
  }
}
