<div class="builder-station-arrival-time-popup__title">
  {{config.dictionary.title | translate}}
</div>
<div class="builder-station-arrival-time-popup__form-controls">
  <div class="builder-station-arrival-time-popup__form-control-title">
    {{config.dictionary.time | translate}}
  </div>
  <div
    *ngIf="form"
    class="builder-station-arrival-time-popup__fields"
  >
    <u-group class="u-group_app-stations-arrival-time-popup">
      <u-input-time
        uId="builder-station-arrival-time-popup-time"
        [class]="'u-input-time_app-stations-arrival-time-popup'"
        [formControl]="form.get(builderStationArrivalTimeFormField.Time)"
        [valid]="form.get('time').valid"
      ></u-input-time>
    </u-group>
    <u-group class="u-group_app-stations-arrival-time-popup u-group_app-stations-arrival-time-popup-checkbox">
      <u-checkbox
        class="u-checkbox_box"
        uId="builder-station-arrival-time-popup-checkbox"
        [formControl]="form.get(builderStationArrivalTimeFormField.DayAfter)"
      ></u-checkbox>
      <div class="builder-station-arrival-time-popup__checkbox-label">
        {{config.dictionary.theDayAfter | translate}}
      </div>
    </u-group>
  </div>
</div>
<div class="builder-station-arrival-time-popup__buttons">
  <div
    class="builder-station-arrival-time-popup__button"
    uId="builder-station-arrival-time-popup-cancel"
    (click)="closeAction.emit()"
  >
    {{config.dictionary.cancel | translate | lowercase}}
  </div>
  <div
    class="builder-station-arrival-time-popup__button"
    uId="builder-station-arrival-time-popup-save-and-close"
    (click)="editStationArrivalTime()"
  >
    {{config.dictionary.saveAndClose | translate | lowercase}}
  </div>
</div>
