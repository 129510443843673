import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { UPopupService, URangePreset } from '@shift/ulib';

import {
  RouteTempCommentActiveRoute,
  RouteTempCommentApplicableTo
} from '@app/routes/models';
import { AppLanguage, DaysOfWeek } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { LocalizationService, LocalizedToastrService, TrackingService, HeaderDataService } from '@app/shared/services';
import { ActivitiesService } from '@app/activities/services';
import { AuthDataService } from '@app/auth/services';
import { AuthCustomerType } from '@app/auth/models';
import { routesTempCommentComponentConfig } from './routes-temp-comment.component.config';

@Component({
  selector: 'app-routes-temp-comment',
  templateUrl: './routes-temp-comment.component.html',
  styleUrls: [ './routes-temp-comment.component.scss', './routes-temp-comment.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesTempCommentComponent implements OnInit {
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'routes-temp-comment';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly bsModalRef = inject(BsModalRef);
  private readonly popupService = inject(UPopupService);
  private readonly toastr = inject(LocalizedToastrService);
  private readonly activitiesService = inject(ActivitiesService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly localizationService = inject(LocalizationService);
  private readonly trackingService = inject(TrackingService);
  private readonly authDataService = inject(AuthDataService);

  form: UntypedFormGroup;
  activeRoute: RouteTempCommentActiveRoute;
  authCustomerType: AuthCustomerType;
  isMunicipalityCustomer: boolean;
  config = cloneDeep(routesTempCommentComponentConfig);
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();

  ngOnInit() {
    this.track('click on add comment');
    this.initAuthCustomer();
    this.initForm();
  }

  private initAuthCustomer() {
    this.authDataService.customer$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(customer => {
        this.authCustomerType = customer.type;
        this.isMunicipalityCustomer = customer.type === AuthCustomerType.Municipality;
      });
  }

  private updateButtonsDisabledState(disabled: boolean) {
    this.config.buttons = this.config.buttons.map(btn => ({ ...btn, disabled }));
  }

  track(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  checkApplicableTo() {
    this.track('click on save');

    this.activitiesService.getTempCommentApplicableTo({
      routeId: this.activeRoute.routeId,
      startDate: this.form.value.datesChange.dates[0],
      endDate: this.form.value.datesChange.dates[this.form.value.datesChange.dates.length - 1]
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(applicableTo => {
        let message: string;
        const formApplicableTo: RouteTempCommentApplicableTo[] = this.form.get('applicableTo').value;

        if (applicableTo.length === 0 && formApplicableTo.includes(RouteTempCommentApplicableTo.Driver) && formApplicableTo.includes(RouteTempCommentApplicableTo.Accompany)) {
          message = `${this.config.dictionaryPath}.warnings.noDriversOrAccompanies`;
        } else {
          if (formApplicableTo.includes(RouteTempCommentApplicableTo.Driver) && !applicableTo.includes(RouteTempCommentApplicableTo.Driver)) {
            message = `${this.config.dictionaryPath}.warnings.noDrivers`;
          }

          if (formApplicableTo.includes(RouteTempCommentApplicableTo.Accompany) && !applicableTo.includes(RouteTempCommentApplicableTo.Accompany)) {
            message = `${this.config.dictionaryPath}.warnings.noAccompanies`;
          }
        }

        if (message) {
          this.popupService.showMessage({
            message,
            yes: 'general.save',
            no: 'general.edit'
          }, () => this.saveComment());
        } else {
          this.saveComment();
        }
      });
  }

  initForm() {
    const activeDate = this.headerDataService.getDate();
    const isActiveRoute = moment(activeDate, AppConstants.DATE_FORMAT_ISO).isSameOrAfter(moment(), 'day') && this.activeRoute['active'];

    this.form = this.fb.group({
      applicableTo: [ [], Validators.required ],
      commentText: [ '', Validators.required ],
      datesChange: this.fb.group({
        dates: [ isActiveRoute ? [ this.headerDataService.getDate(), this.headerDataService.getDate() ] : [], Validators.required ],
        dateFrom: [],
        dateTo: [],
        type: [ isActiveRoute ? URangePreset.DisplayedDay : URangePreset.Custom ],
        availablePresets: [ this.headerDataService.isTodayActiveDate() ? this.config.availableRangePresetsToday :
          moment(activeDate, AppConstants.DATE_FORMAT_ISO).isBefore(moment(), 'day') ? this.config.availableRangePresetsPast : this.config.availableRangePresetsFuture ],
        checkDaysActive: [ [] ],
        checkDaysAvailable: [ [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ] ],
        displayedDay: [ activeDate ]
      })
    });

    this.updateButtonsDisabledState(this.form.invalid);

    this.form.get('applicableTo').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.track('select roles'));

    this.form.valueChanges
      .pipe(
        map(() => this.form.invalid),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(invalid => this.updateButtonsDisabledState(invalid));
  }

  closeConfirmComment() {
    this.track('click on close');

    if (this.form.dirty) {
      this.popupService.showMessage({
        showXIcon: true,
        message: 'general.saveChanges',
        yes: 'general.yes',
        no: 'general.no'
      }, () => {
        if (this.form.valid) {
          this.saveComment();
        }
      }, () => this.bsModalRef.hide());
    } else {
      this.bsModalRef.hide();
    }
  }

  saveComment() {
    const formValue = this.form.getRawValue();

    this.activitiesService.addNewTempComment({
      routeId: this.activeRoute.routeId,
      commentText: formValue.commentText,
      applicableTo: formValue.applicableTo,
      startDate: moment(formValue.datesChange.dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE),
      endDate: moment(formValue.datesChange.dates[formValue.datesChange.dates.length - 1]).format(AppConstants.DATE_FORMAT_BASE_LINE)
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () => {
          this.bsModalRef.hide();
          this.toastr.success('general.successful');
        },
        () => this.popupService.showErrorMessage({ message:  'general.error' })
      );
  }

  onDatesSave(data) {
    this.track('select period');

    this.form.get('datesChange').patchValue(data);
  }
}
