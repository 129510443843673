import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentConvert',
  standalone: true
})
export class MomentConvertPipe implements PipeTransform {
  transform(value: string, from: string, to: string): string {
    return moment(value, from).format(to);
  }
}
