const DICTIONARY_PATH: string = 'shared.layout';

export const layoutComponentConfig = {
  dictionary: {
    logoutCountdownCounter: {
      message: `${DICTIONARY_PATH}.logoutCountdownCounter.message`,
      seconds: `${DICTIONARY_PATH}.logoutCountdownCounter.seconds`
    }
  }
};
