import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UDropdownModule, UCommonModule, UTooltipModule, UGridModule, UIdModule, UPopoverModule, UNgxEditorModule, UButtonModule, UVirtualScrollerModule, UClickOutsideDirective } from '@shift/ulib';

import {
  AddEditModalComponent,
  CitiesPricingTableComponent,
  ContactsComponent,
  DeleteRouteComponent,
  DynamicFieldComponent,
  EntityAreaComponent,
  EntityLayoutComponent,
  EntityMenuComponent,
  InputAddressComponent,
  InputCommunicationsComponent,
  InputContactsComponent,
  InputMonitoringComponent,
  InputShiftsComponent,
  PassengerAskPopupComponent,
  RoutePolicyComponent,
  RoutePolicyTabsComponent,
  TablePageComponent,
  TimerComponent,
  WeekSwitchComponent,
  MultiDeleteConfirmComponent,
  CitiesCombinationsComponent,
  MovePassengersInfoComponent,
  GeneralCardComponent,
  InputBranchesComponent,
  CitiesPolicyComponent,
  AccessibilityAffidavitComponent,
  GridHeaderComponent
} from '@app/shared/components';
import {
  KmMileConvertorPipe,
  IncludesPipe,
  AddEditModalFieldsCheckFeaturePipe,
  AddEditModalFieldsCheckConditionsPipe,
  AddEditModalTabsFilterPipe,
  AddEditModalColumnsCheckFeaturePipe,
  MovePassengersRouteDistancePipe
} from '@app/shared/pipes';

const COMPONENTS = [
  AddEditModalComponent,
  CitiesPricingTableComponent,
  ContactsComponent,
  DeleteRouteComponent,
  DynamicFieldComponent,
  EntityAreaComponent,
  EntityLayoutComponent,
  EntityMenuComponent,
  InputAddressComponent,
  InputCommunicationsComponent,
  InputContactsComponent,
  InputMonitoringComponent,
  InputShiftsComponent,
  InputBranchesComponent,
  PassengerAskPopupComponent,
  RoutePolicyComponent,
  TablePageComponent,
  TimerComponent,
  WeekSwitchComponent,
  MultiDeleteConfirmComponent,
  CitiesCombinationsComponent,
  RoutePolicyTabsComponent,
  MovePassengersInfoComponent,
  GeneralCardComponent,
  CitiesPolicyComponent,
  AccessibilityAffidavitComponent
];

@NgModule({
  imports: [
    UButtonModule,
    CommonModule,
    UCommonModule,
    FormsModule,
    TabsModule,
    ReactiveFormsModule,
    TranslateModule,
    UDropdownModule,
    UNgxEditorModule,
    UIdModule,
    UPopoverModule,
    UTooltipModule,
    UGridModule,
    ModalModule,
    StoreModule,
    UVirtualScrollerModule,
    GridHeaderComponent,
    IncludesPipe,
    MovePassengersRouteDistancePipe,
    AddEditModalFieldsCheckFeaturePipe,
    AddEditModalFieldsCheckConditionsPipe,
    AddEditModalTabsFilterPipe,
    AddEditModalColumnsCheckFeaturePipe,
    KmMileConvertorPipe,
    UClickOutsideDirective
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SharedModule {}
