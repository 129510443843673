export enum EmailsSendShuttleCompaniesChangesType {
  All,
  Planned,
  UnPlanned
}

export enum EmailsSendShuttleCompaniesChangesDatesType {
  ChangePeriod,
  CreationDate
}

export interface EmailContent {
  subject: string;
  attachment: {
    id: string;
    name: string;
  };
  attachments: any[];
  content: string;
  receivers: string[];
}

export interface EmailErrorMessages {
  maxRecipients: {
    sending: string;
    remove: string;
  };
  limitEmails: string;
}

export interface EmailsContentShuttleCompanyChangesParams {
  shuttleCompanyId: number;
  changeType: EmailsSendShuttleCompaniesChangesType;
  from: string;
  to: string;
  days: number[];
  datesFiltrationType: number;
  changeSubjects: number[];
  lastRouteAuditLogId: number;
  routeId: number;
  onlyLastChange: boolean;
}

export interface EmailsContentShuttleCompanyChanges {
  changesCount: number;
  attachment: {
    id: string;
    name: string;
  };
  content: string;
  subject: string;
  receivers: string[];
}

export interface EmailsShuttleCompanyChangesParams {
  attachment: string;
  content: string;
  subject: string[];
  receivers: string[];
}

export interface EmailsShuttleCompanyChanges {
  urlsReplaced: boolean;
}

export interface EmailsShuttleCompany {
  id: number;
  name: string;
  notAvailable: boolean;
}

export interface EmailsSendBackgroundShuttleCompanyChangesParams {
  shuttleCompanyIds: number[];
  from: string;
  to: string;
  datesFiltrationType: number;
  changeType?: EmailsSendShuttleCompaniesChangesType;
  days: number[];
  changeSubjects: number[];
  routeId: number;
  lastRouteAuditLogId: number;
  onlyLastChange: boolean;
}

export interface EmailsDataModalContentData {
  changeSubjects?: number[];
  lastRouteAuditLogId?: number;
  datesFiltrationType?: number;
  shuttleCompanies: number[];
  openForEdit: boolean;
  onlyLastChange?: boolean;
  routeId?: number;
  changeType?: EmailsSendShuttleCompaniesChangesType;
  dates: {
    from: string;
    to: string;
    days: number[];
  };
}

export interface EmailsDataModalContentParams {
  changeType: EmailsSendShuttleCompaniesChangesType;
  from: string;
  to: string;
  days: number[];
  datesFiltrationType: number;
  changeSubjects: number[];
  lastRouteAuditLogId: number;
  routeId: number;
  onlyLastChange: boolean;
}

export interface EmailsDataModalParams {
  sendBackground: boolean;
  params: EmailsContentShuttleCompanyChangesParams[];
}

export interface EmailsDataModalBackgroundParams {
  sendBackground: boolean;
  params: EmailsSendBackgroundShuttleCompanyChangesParams;
}
