import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';

import { AppConstants } from '@app/shared/constants';
import { TrackingService } from '@app/shared/services';
import { LocalizationService } from '@app/shared/services';
import { AppLanguage, ModalActions } from '@app/shared/models';
import { RouteDirection } from '@app/routes/models';
import { PassengerRoute, PassengersRoutesActions, PassengersRoutesPopupAction } from '@app/passengers/models';
import { passengersRoutesPopupComponentConfig } from './passengers-routes-popup.component.config';

@Component({
  selector: 'app-passengers-routes-popup',
  templateUrl: './passengers-routes-popup.component.html',
  styleUrls: [ './passengers-routes-popup.component.scss', './passengers-routes-popup.component.rtl.scss' ]
})
export class PassengersRoutesPopupComponent implements OnInit, OnDestroy {
  @Output() action: EventEmitter<PassengersRoutesPopupAction> = new EventEmitter();

  @ViewChild('normalCell', { static: true }) private normalCell: TemplateRef<any>;
  @ViewChild('routePeriod', { static: true }) private routePeriod: TemplateRef<any>;
  @ViewChild('direction', { static: true }) private direction: TemplateRef<any>;
  @ViewChild('keepOnRouteHeader', { static: true }) private keepOnRouteHeader: TemplateRef<any>;
  @ViewChild('keepOnRoute', { static: true }) private keepOnRoute: TemplateRef<any>;
  @ViewChild('updateRouteHeader', { static: true }) private updateRouteHeader: TemplateRef<any>;
  @ViewChild('updateRoute', { static: true }) private updateRoute: TemplateRef<any>;

  @HostBinding('class') hostClasses: string = 'passengers-routes-popup';

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(passengersRoutesPopupComponentConfig);
  routes: PassengerRoute[] = [];
  columns: any[] = [];
  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  passenger: {
    passengerId: number;
    passengerRoutes: PassengerRoute[];
  };
  appConstants = AppConstants;
  directionType = RouteDirection;
  actions = PassengersRoutesActions;
  actionsForm: UntypedFormGroup;
  keepOnRouteControls: UntypedFormArray;
  updateRouteControls: UntypedFormArray;
  moveToNewTarget: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private localizationService: LocalizationService,
    private modalRef: BsModalRef,
    private trackingService: TrackingService
  ) { }

  ngOnInit() {
    this.attachColumnsTemplate();
    this.initRoutes();
    this.initActionsForm(this.passenger.passengerRoutes);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private track(name: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${name}`);
  }

  private initActionsFormControls(routes: PassengerRoute[]) {
    this.keepOnRouteControls = this.actionsForm.get('keepOnRoute.keepOnRouteControls') as UntypedFormArray;
    this.updateRouteControls = this.actionsForm.get('updateRoute.updateRouteControls') as UntypedFormArray;

    routes.forEach(() => {
      this.keepOnRouteControls.push(this.fb.group({
        keepOnRoute: [ true ]
      }));

      this.updateRouteControls.push(this.fb.group({
        updateRoute: [ true ]
      }));
    });
  }

  private disableUpdateRouteFormControls() {
    const isAllKeepOnRouteControlsUnchecked = this.keepOnRouteControls.controls.every(control => !control.get('keepOnRoute').value);

    this.actionsForm.get('updateRoute.all')[isAllKeepOnRouteControlsUnchecked ? 'disable' : 'enable']({ emitEvent: false });

    this.updateRouteControls.controls.forEach((control, index) => {
      const updateRoute = control.get('updateRoute');
      const keepRoute = this.keepOnRouteControls.at(index).get('keepOnRoute');

      if (!keepRoute.value && !updateRoute.value) {
        updateRoute.patchValue(true);
      }

      updateRoute[keepRoute.value ? 'enable' : 'disable']({ emitEvent: false });
    });
  }

  private onActionsFormValueChange(formGroup: UntypedFormGroup, formArray: UntypedFormArray, formArrayItemName: string) {
    const checkboxAll = formGroup.get('all');

    checkboxAll.valueChanges
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(value => {
        formArray.controls.forEach(control => control.enabled && control.get(formArrayItemName).patchValue(value, { emitEvent: false }));

        if (formArrayItemName === 'keepOnRoute') {
          this.disableUpdateRouteFormControls();
        }
      });

    formArray.controls.forEach(control => {
      control.valueChanges
        .pipe(
          map((data: object) => {
            const values = Object.values(data);

            return values.length ? values[0] : null;
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe(value => {
          this.track(
            formArrayItemName === 'keepOnRoute' ?
              value ? 'check Keep on route' : 'uncheck Keep on route' :
              value ? 'check Update route' : 'uncheck Update route'
          );

          const isAllChecked = formArray.controls.every(formControl => formControl.get(formArrayItemName).value);

          if (checkboxAll.value !== isAllChecked) {
            checkboxAll.patchValue(isAllChecked, { emitEvent: false });
          }

          if (formArrayItemName === 'keepOnRoute') {
            this.disableUpdateRouteFormControls();
          }
        });
    });
  }

  private initActionsForm(routes: PassengerRoute[]) {
    if (!this.passenger || !routes || !routes.length) { return; }

    this.actionsForm = this.fb.group({
      keepOnRoute: this.fb.group({
        all: [ true ],
        keepOnRouteControls: this.fb.array([])
      }),
      updateRoute: this.fb.group({
        all: [ true ],
        updateRouteControls: this.fb.array([])
      })
    });

    this.initActionsFormControls(routes);
    this.onActionsFormValueChange(this.actionsForm.get('keepOnRoute') as UntypedFormGroup, this.keepOnRouteControls, 'keepOnRoute');
    this.onActionsFormValueChange(this.actionsForm.get('updateRoute') as UntypedFormGroup, this.updateRouteControls, 'updateRoute');
  }

  private initRoutes() {
    if (this.passenger) {
      this.routes = this.passenger.passengerRoutes;
    }
  }

  private attachColumnsTemplate() {
    this.columns = this.config.columns.map(column => ({
      ...column,
      cellTemplate: this[column.cellTemplateName] || this.normalCell,
      ...(this[column.headerTemplateName] ? { headerTemplate: this[column.headerTemplateName] } : {})
    }));
  }

  private savePassengerRoutes() {
    const data: PassengersRoutesPopupAction = {
      type: ModalActions.Save,
      params: {}
    };

    if (this.passenger) {
      data.params.routes = this.passenger.passengerRoutes.map((route, index) => {
        const keepOnRoute = this.keepOnRouteControls.at(index);
        const updateRoute = this.updateRouteControls.at(index);

        return {
          ...route,
          keep: keepOnRoute && keepOnRoute.get('keepOnRoute').value,
          update: updateRoute && updateRoute.get('updateRoute').value
        };
      });
    }

    this.action.emit(data);
    this.modalRef.hide();
  }

  closePopup() {
    if (!this.modalRef) { return; }

    this.modalRef.hide();
    this.action.emit({ type: ModalActions.Cancel, removeRoutes: null });
    this.modalRef = null;
  }

  submit() {
    if (!this.routes || !this.routes.length) {
      this.action.emit({ type: null, removeRoutes: [], removeErrorPopup: false });
      this.modalRef.hide();

      return;
    }

    this.savePassengerRoutes();
  }
}
