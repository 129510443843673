<div
  *ngFor="let passenger of passengers; trackBy: passengerTrackBy"
  class="routes-ride-passengers__item"
>
  <div class="routes-ride-passengers__item-header">
    <span
      *ngIf="!passenger.name"
      class="routes-ride-passengers__item-header-name"
      uTooltip="{{passenger.amount}} {{config.dictionary.anonymousPassengers | translate}}"
      [placement]="[ 'top', 'bottom' ]"
      [container]="'body'"
    >
      {{passenger.amount}} {{config.dictionary.anonymousPassengers | translate}}
    </span>
    <span
      *ngIf="passenger.name"
      class="routes-ride-passengers__item-header-name"
      uTooltip="{{passenger.name}}"
      [placement]="[ 'top', 'bottom' ]"
      [container]="'body'"
    >
      {{passenger.name}}
    </span>
    <span
      class="routes-ride-passengers__item-header-status"
      [ngClass]="{ 'routes-ride-passengers__item-header-status_red': passenger.checkIn === false }"
    >
      {{passenger.status}}
    </span>
  </div>
  <div *ngIf="passenger.mobile" class="routes-ride-passengers__item-mobile">{{passenger.mobile}}</div>
  <div *ngIf="passenger.address" class="routes-ride-passengers__item-address">{{passenger.address}}</div>
</div>
