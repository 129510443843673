import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  ViewChild,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  DestroyRef,
  signal
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { first, take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UGridSort, UPopoverDirective, UPopupService, USearchConfig, USidebarMenuService } from '@shift/ulib';

import { AppLanguage, GridColumn } from '@app/shared/models';
import {
  HeaderSearchFiltersService,
  LocalizationService,
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import { AuthModuleBranchesFeature, AuthModuleBranchesFeatureType, AuthModuleName } from '@app/auth/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { RouteDirection } from '@app/routes/models';
import { RoutePlannerService } from '@app/route-planner/services';
import { builderConfig, builderFullConfig, builderRoutesConfig } from '@app/builder/configs';
import {
  BuilderBuildMode,
  BuilderDuplicateRouteAction,
  BuilderMode,
  BuilderRoute,
  BuilderRouteStatus,
  BuilderViewMode
} from '@app/builder/models';
import { BuilderCommonService, BuilderDataService, BuilderFullRoutesGenerationService, BuilderRoutesPassengersInfoStoreService, BuilderRoutesStoreService } from '@app/builder/services';
import { BuilderDuplicateRouteComponent } from '../builder-duplicate-route/builder-duplicate-route.component';
import { BuilderFullSplitRouteComponent } from '../builder-full/builder-full-split-route/builder-full-split-route.component';
import { BuilderFullMovePassengersComponent } from '../builder-full/builder-full-move-passengers/builder-full-move-passengers.component';
import { builderRoutesComponentConfig } from './builder-routes.component.config';

@Component({
  selector: 'app-builder-routes',
  templateUrl: './builder-routes.component.html',
  styleUrls: [ './builder-routes.component.scss', './builder-routes.component.rtl.scss' ]
})
export class BuilderRoutesComponent implements OnInit, OnDestroy {
  @Output() init: EventEmitter<void> = new EventEmitter();
  @Output() editRouteAction: EventEmitter<void> = new EventEmitter();
  @Output() closeRouteAction: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-routes';

  @ViewChild('statusColumn', { static: true }) public statusColumn: TemplateRef<any>;
  @ViewChild('codeColumn', { static: true }) public codeColumn: TemplateRef<any>;
  @ViewChild('periodColumn', { static: true }) public periodColumn: TemplateRef<any>;
  @ViewChild('periodFilter', { static: true }) public periodFilter: TemplateRef<any>;
  @ViewChild('carTypeCell', { static: true }) public carTypeCell: TemplateRef<any>;
  @ViewChild('directionCell', { static: true }) public directionCell: TemplateRef<any>;
  @ViewChild('passengersCell', { static: true }) public passengersCell: TemplateRef<any>;
  @ViewChild('branchesCell', { static: true }) public branchesCell: TemplateRef<any>;

  private directionFilters: {
    [RouteDirection.Forward]: string;
    [RouteDirection.Backward]: string;
  } = {
      [RouteDirection.Forward]: this.translateService.instant(builderConfig.dictionary.directions[RouteDirection.Forward]),
      [RouteDirection.Backward]: this.translateService.instant(builderConfig.dictionary.directions[RouteDirection.Backward])
    };

  readonly #config = signal(cloneDeep(builderRoutesComponentConfig));

  readonly config = this.#config.asReadonly();

  none = this.translateService.instant(this.#config().dictionary.none);
  builderMode = BuilderMode;
  builderViewMode = BuilderViewMode;
  addEditForm: UntypedFormGroup;
  isRtl = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  columns: any[] = [];
  selectedRows: BuilderRoute[] = [];
  editRouteId: number;
  routeSwitchBlocked: boolean = false;
  routeStatus: any = {
    null: 'builder.routes.routeStatuses.new',
    [BuilderRouteStatus.Pending]: 'builder.routes.routeStatuses.pending',
    [BuilderRouteStatus.InProgress]: 'builder.routes.routeStatuses.inProgress',
    [BuilderRouteStatus.Saved]: 'builder.routes.routeStatuses.saved',
    [BuilderRouteStatus.Failed]: 'builder.routes.routeStatuses.failed'
  };
  moment: any = moment;
  scrollToOffsetY: number;
  threeDotsRow: BuilderRoute;
  threeDotsRowPopover: UPopoverDirective;
  builderRouteStatus = BuilderRouteStatus;
  tableSorts: UGridSort[];
  searchConfigBackup: USearchConfig;
  builderRoutesConfig = builderRoutesConfig;
  builderConfig = builderConfig;

  constructor(
    private destroyRef: DestroyRef,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private localizationService: LocalizationService,
    private routePlannerService: RoutePlannerService,
    private uPopupService: UPopupService,
    private uSidebarMenuService: USidebarMenuService,
    private headerDataService: HeaderDataService,
    private trackingService: TrackingService,
    private bsModalService: BsModalService,
    private duplicateModalRef: BsModalRef,
    private headerSearchFiltersService: HeaderSearchFiltersService,
    private builderRoutesPassengersInfoStoreService: BuilderRoutesPassengersInfoStoreService,
    private authDataService: AuthDataService,
    public builderDataService: BuilderDataService,
    public builderRoutesStoreService: BuilderRoutesStoreService,
    public builderCommonService: BuilderCommonService,
    public authDataSnapshotService: AuthDataSnapshotService,
    public builderFullRoutesGenerationService: BuilderFullRoutesGenerationService
  ) {}

  ngOnInit() {
    this.init.emit();

    this.searchConfigBackup = cloneDeep(this.headerSearchFiltersService.getSearchConfig());
    this.addEditForm = this.builderDataService.addEditForm;

    this.generateColumns();
    this.headerSearchFiltersService.reset();

    this.builderRoutesStoreService.highlightRoute(this.builderCommonService.getEditRouteId());
    this.builderRoutesStoreService.resetTableColumnsFilters();

    const { sorts, offsetY } = this.builderRoutesStoreService.gridProps();

    this.tableSorts = sorts;

    setTimeout(() => this.scrollToOffsetY = offsetY);

    this.onEditRouteId();

    if (this.headerDataService.showGlobalSearch) {
      this.onGlobalSearch();
    }
  }

  ngOnDestroy() {
    this.headerSearchFiltersService.setSearchConfig(this.searchConfigBackup);
  }

  private track(message: string) {
    this.trackingService.track(`[${builderFullConfig.trackingId}] - ${message}`);
  }

  private onEditRouteId() {
    this.builderCommonService.editRouteId$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(routeId => {
        this.editRouteId = routeId;
        this.cdRef.detectChanges();
      });
  }

  private directionFilterTypeItemsSearchFn = (value: RouteDirection, searchValue: string): boolean => {
    if (searchValue === this.directionFilters[RouteDirection.Forward]) {
      return value === RouteDirection.Forward;
    }

    if (searchValue === this.directionFilters[RouteDirection.Backward]) {
      return value === RouteDirection.Backward;
    }

    return true;
  };

  private branchesColumnFilterTypeItemsFn = (prop, rows) => rows.reduce((accRows, row) =>
    accRows.concat(row[prop]?.length ? row[prop].reduce((acc, item) => acc = [ ...acc, item ], []) : [ '' ])
  , []);

  private branchesColumnFilterSorting = items => items.map(item => ({ value: item, name: item || this.none }));

  private generateColumns() {
    const { columnsOrder, columnsStoreVisible, hiddenColumns } = this.builderRoutesStoreService.gridProps();

    const branchesColumn: GridColumn = {
      prop: 'branchNames',
      name: 'builder.routes.branches',
      minWidth: 156,
      hideColumn: true,
      cellTemplate: this.branchesCell,
      filterType: 'select',
      filterProperty: 'value',
      filterTypeItemsFn: this.branchesColumnFilterTypeItemsFn,
      filterSortingFunction: this.branchesColumnFilterSorting,
      feature: {
        module: AuthModuleName.Branches,
        name: AuthModuleBranchesFeature.Type,
        values: [ AuthModuleBranchesFeatureType.Generic, AuthModuleBranchesFeatureType.Iec ]
      }
    };

    const defaultTableColumns: GridColumn[] = [
      { prop: 'code', name: 'builder.routes.code', cellTemplate: this.codeColumn, filterType: 'select', minWidth: 136 },
      { prop: 'name', name: 'builder.routes.name', filterType: 'select', minWidth: 170 },
      {
        prop: 'direction',
        name: 'builder.routes.direction',
        filterType: 'select',
        resizeable: false,
        minWidth: 110,
        maxWidth: this.isRtl ? 90 : 110,
        cellTemplate: this.directionCell,
        filterTypeItemsFn: () => [ this.directionFilters?.[RouteDirection.Forward], this.directionFilters?.[RouteDirection.Backward] ],
        filterTypeItemsSearchFn: this.directionFilterTypeItemsSearchFn
      },
      { prop: 'rideStartDateTime', name: 'builder.routes.startTime', filterType: 'select', resizeable: false, minWidth: 115, maxWidth: 115 },
      { prop: 'rideEndDateTime', name: 'builder.routes.endTime', filterType: 'select', resizeable: false, minWidth: 115, maxWidth: 115 },
      ...(
        this.builderDataService.config().buildMode === BuilderBuildMode.Routes && this.builderDataService.config().viewMode === BuilderViewMode.Full ?
          [
            branchesColumn,
            {
              ...branchesColumn,
              name: 'builder.routes.bases',
              feature: {
                module: AuthModuleName.Branches,
                name: AuthModuleBranchesFeature.Type,
                values: [ AuthModuleBranchesFeatureType.Base ]
              }
            },
            {
              ...branchesColumn,
              name: 'builder.routes.activityCenters',
              feature: {
                module: AuthModuleName.Branches,
                name: AuthModuleBranchesFeature.Type,
                values: [ AuthModuleBranchesFeatureType.ActivityCenter ]
              }
            }
          ] : []
      ),
      ...(
        this.builderDataService.config().buildMode === BuilderBuildMode.Routes && this.builderDataService.config().viewMode === BuilderViewMode.Default ?
          [ { prop: 'totalStations', name: 'builder.routes.stations', filterType: 'select' , minWidth: 88, maxWidth: 88 } ] : []
      ),
      {
        prop: 'totalPassengers',
        name: 'builder.routes.passengers',
        filterType: 'select',
        resizeable: false,
        minWidth: this.isRtl ? 95 : 125,
        maxWidth: this.isRtl ? 95 : 125,
        cellTemplate: this.passengersCell,
        cellClass: 'datatable-body-cell_overflow-visible'
      },
      { prop: 'shuttleCompany', name: 'builder.routes.sc', filterType: 'select', minWidth: 130 },
      { prop: 'carTypeName', name: 'builder.routes.carType', filterType: 'select', cellTemplate: this.carTypeCell, minWidth: 115 }
    ];

    const tableColumns: GridColumn[] = this.builderDataService.config().buildMode === BuilderBuildMode.Routes ? [
      { prop: 'status', name: 'builder.routes.status', cellTemplate: this.statusColumn, resizeable: false, minWidth: 136, maxWidth: 160 },
      ...defaultTableColumns,
      { prop: 'days', name: 'builder.routes.period', cellTemplate: this.periodColumn, filterTemplate: this.periodFilter,  filterType: 'select', minWidth: 187 },
      { prop: 'errorMessage', name: 'builder.routes.error', filterType: 'select', minWidth: 216 }
    ] : defaultTableColumns;

    if (hiddenColumns) {
      tableColumns.filter(col => hiddenColumns.includes(col.prop as string)).forEach(col => col['hideColumn'] = true);
    }

    if (columnsStoreVisible) {
      tableColumns
        .filter(column => !columnsStoreVisible.includes(column.prop as string))
        .forEach(column => column['hideColumn'] = true);
    }

    let columns = [ ...tableColumns ];

    if (columnsOrder) {
      columnsOrder.forEach(colKey => {
        const columnObjIndex = tableColumns.findIndex(column => column.prop === colKey);

        if (columnObjIndex >= 0) {
          columns.push(tableColumns[columnObjIndex]);

          tableColumns.splice(columnObjIndex, 1);
        }
      });

      columns = [ ...columns, ...tableColumns ];
    }

    this.columns = columns.filter(column => !column.feature || this.authDataService.checkFeature(column.feature));
  }

  private onGlobalSearch() {
    this.headerSearchFiltersService.appliedSearch$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(searchQuery => {
        this.track('search');

        this.builderRoutesStoreService.searchRoutes(searchQuery);

        this.cdRef.markForCheck();
      });
  }

  activateRow(data: any) {
    const { type, row } = data;

    if (type === 'click') {
      this.editRoute(row);
    }
  }

  selectedRowsChange(data: any) {
    this.selectedRows = data.selected;
  }

  selectThreeDotsRow(row: BuilderRoute, popover: UPopoverDirective) {
    this.track('click on 3 dot icon');

    this.threeDotsRow = row;
    this.threeDotsRowPopover = popover;
  }

  movePassengers() {
    this.builderRoutesPassengersInfoStoreService.reset();

    this.bsModalService.show(
      BuilderFullMovePassengersComponent,
      {
        class: `u-modal u-modal_content ${this.uSidebarMenuService.getCollapsedValue() ? 'u-modal_content-hide-menu ' : ''}u-modal_app-builder-full-move-passengers`,
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: {
          route: {
            id: this.threeDotsRow.routeId,
            number: this.threeDotsRow.code,
            name: this.threeDotsRow.name,
            direction: this.threeDotsRow.direction
          }
        }
      });
  }

  splitRoute() {
    this.closeRouteAction.emit();

    this.builderRoutesPassengersInfoStoreService.clearInfo(this.threeDotsRow.routeId);

    this.track('Routes table - 3 dots - click on Split routes');

    this.bsModalService
      .show(
        BuilderFullSplitRouteComponent,
        {
          class: 'u-modal u-modal_app-builder-split-route',
          animated: true,
          ignoreBackdropClick: true,
          backdrop: false,
          keyboard: false,
          initialState: {
            routeId: this.threeDotsRow.routeId,
            routeDirection: this.threeDotsRow.direction,
            allowEmptyStations: this.threeDotsRow.allowEmptyStations
          }
        }
      )
      .content
      .editRoute
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(route => this.editRoute(route));
  }

  duplicateRoute() {
    this.trackingService.track(`[${builderFullConfig.trackingId}, duplicate route] - click on duplicate route`);

    this.duplicateModalRef = this.bsModalService.show(
      BuilderDuplicateRouteComponent,
      {
        class: 'u-modal u-modal_app-duplicate-route',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          activeRoute: this.threeDotsRow
        }
      });

    this.duplicateModalRef
      .content
      .action
      .subscribe((action: BuilderDuplicateRouteAction) => {
        if (action) {
          const { type, body } = action;
          const actionTypes: string[] = [ 'save', 'saveAndClose' ];

          if (actionTypes.includes(type)) {
            this.routePlannerService
              .duplicate(body)
              .pipe(first())
              .subscribe((data: any) => {
                this.builderRoutesStoreService.addRouteFromSummary(data);

                if (type === 'saveAndClose') {
                  this.duplicateModalRef.hide();
                } else {
                  this.duplicateModalRef
                    .content
                    .form
                    .patchValue({
                      routeId: data.routeId
                    });
                }
              });
          }
        }
      });
  }

  removeRoute() {
    this.track('click on delete');

    const { routeId } = this.threeDotsRow;

    this.uPopupService.showMessage({
      message: 'builder.routes.confirmations.removeRoute',
      yes: 'general.yes',
      no: 'general.no'
    },
    () => {
      this.routePlannerService.deleteRoute(routeId)
        .pipe(first())
        .subscribe(() => {
          this.builderRoutesStoreService.removeRoute(routeId);

          if (this.builderCommonService.getEditRouteId() === routeId) {
            this.builderCommonService.editRouteIdSet(null);
            this.closeRouteAction.emit();
          }
        });
    });
  }

  editRoute(row: BuilderRoute) {
    if (this.builderDataService.config().mode === 'edit' && !this.routeSwitchBlocked) {
      this.builderDataService.edit(row.routeId);
    }

    if (this.builderDataService.config().viewMode === BuilderViewMode.Full && (row.status === null || row.status === 3)) {
      this.editRouteAction.emit();

      this.builderDataService.edit(row.routeId);
      this.builderRoutesPassengersInfoStoreService.clearInfo(row.routeId);

      this.track('click on edit');
    }

    this.builderCommonService.lastEditedRouteIdSet(row.routeId);
  }
}
