const DICTIONARY_PATH: string = 'passengers.removeFromRoute';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  actions: {
    cancel: `${DICTIONARY_PATH}.actions.cancel`,
    save: `${DICTIONARY_PATH}.actions.save`,
    confirm: `${DICTIONARY_PATH}.actions.confirm`
  },
  directions: {
    forward: `${DICTIONARY_PATH}.table.directions.forward`,
    backward: `${DICTIONARY_PATH}.table.directions.backward`
  },
  table: {
    passenger: `${DICTIONARY_PATH}.table.passenger`,
    routeCode: `${DICTIONARY_PATH}.table.routeCode`,
    routeName: `${DICTIONARY_PATH}.table.routeName`,
    direction: `${DICTIONARY_PATH}.table.direction`,
    period: `${DICTIONARY_PATH}.table.period`,
    nextDay: `${DICTIONARY_PATH}.table.nextDay`,
    keepOnRoute: `${DICTIONARY_PATH}.table.keepOnRoute`,
    updateRoute: `${DICTIONARY_PATH}.table.updateRoute`,
    updateRouteInfo: {
      firstPart: `${DICTIONARY_PATH}.table.updateRouteInfo.firstPart`,
      secondPart: `${DICTIONARY_PATH}.table.updateRouteInfo.secondPart`
    },
    template: `${DICTIONARY_PATH}.template`
  }
};

export const passengersRoutesPopupComponentConfig =  {
  trackingId: 'Keep/remove from route',
  dictionary,
  dateFormat: 'dd/MM/yy',
  columns: [
    { prop: 'code', name: dictionary.table.routeCode,  minWidth: 115, maxWidth: 115, hideColumn: false, resizeable: false },
    { prop: 'name', name: dictionary.table.routeName, minWidth: 170, maxWidth: 170, custom: false, resizeable: false },
    { prop: 'direction', name: dictionary.table.direction, minWidth: 107, maxWidth: 107, cellTemplateName: 'direction', custom: false, resizeable: false },
    { prop: 'period', name: dictionary.table.period, minWidth: 153, maxWidth: 180, cellTemplateName: 'routePeriod',  resizeable: false },
    { prop: 'keepOnRoute', custom: true, name: '', hideable: false, minWidth: 164, maxWidth: 164, cellTemplateName: 'keepOnRoute', headerTemplateName: 'keepOnRouteHeader', resizeable: false },
    { prop: 'updateRoute', custom: true, name: '', hideable: false, minWidth: 183, maxWidth: 183, cellTemplateName: 'updateRoute', headerTemplateName: 'updateRouteHeader', resizeable: false }
  ]
};
