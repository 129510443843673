import {
  Input,
  Output,
  Component,
  HostBinding,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { AuthDataService } from '@app/auth/services';
import { MovePassengersInfoRoute } from '@app/shared/models';
import { environment } from '@environments/environment';
import { movePassengersInfoComponentConfig } from './move-passengers-info.component.config';

@Component({
  selector: 'app-move-passengers-info',
  templateUrl: './move-passengers-info.component.html',
  styleUrls: [ './move-passengers-info.component.scss', './move-passengers-info.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MovePassengersInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sourceRoute: MovePassengersInfoRoute;
  @Input() routes: MovePassengersInfoRoute[] = [];

  @Output() routeDeleteIconClick: EventEmitter<number> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'move-passengers-info';

  private unsubscribe: Subject<void> = new Subject();

  isRoutesListVisible: boolean = false;
  isLegendVisible: boolean = false;
  config;

  constructor(private authDataService: AuthDataService) {}

  ngOnInit() {
    this.getAccompanyFeatureType();
  }

  ngOnChanges(changes) {
    if (changes.routes && this.routes) {
      if (!this.routes.length) {
        this.isRoutesListVisible = false;
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getAccompanyFeatureType() {
    this.authDataService.modules$
      .pipe(
        map(modules => modules?.accompany?.type),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(type => this.initConfig(!!type));
  }

  private initConfig(hasAccompanyFeature: boolean) {
    const config = cloneDeep(movePassengersInfoComponentConfig);
    const userInfo = this.authDataService.userInfo();
    const legendsByCustomerType = config.legends[userInfo.customer.type] || config.legends.default;
    const legendsByEnvType = legendsByCustomerType[environment.config.environmentType] || legendsByCustomerType.default;

    this.config = {
      ...config,
      legends: hasAccompanyFeature ? [ config.accompanyStationLegend, ...legendsByEnvType ] : legendsByEnvType
    };
  }

  toggleRoutesList() {
    this.isRoutesListVisible = !this.isRoutesListVisible;
  }

  toggleLegend() {
    this.isLegendVisible = !this.isLegendVisible;
  }

  closeLegend() {
    this.isLegendVisible = false;
  }

  deleteRoute(routeId: number) {
    this.routeDeleteIconClick.emit(routeId);
  }
}
