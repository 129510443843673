import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { ApiService } from '@app/shared/services';
import { FileSaverService } from '@app/files/services';
import {
  PassengersGetParams,
  PassengersHistoryRow,
  Passenger,
  PassengerBase,
  PassengerRoute,
  PassengersInitialData,
  PassengersInitialDataType,
  PassengerSettings,
  PassengerDetails
} from '@app/passengers/models';
import { AuthModuleName, AuthModulePassengersFeature, AuthModulePassengersFeatureType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class PassengersService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private BASE_PATH: string = 'Passengers';
  private BASE_PATH_TYPE: string = '';

  constructor(
    private apiService: ApiService,
    private fileSaverService: FileSaverService,
    private authDataService: AuthDataService
  ) {
    this.initBasePathType();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initBasePathType() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.Passengers, AuthModulePassengersFeature.Type)
      .pipe(
        filter(data => !!data),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(type => this.BASE_PATH_TYPE = type === AuthModulePassengersFeatureType.Generic ? '' : `/${type}`);
  }

  getInitialData(params: { types: PassengersInitialDataType[]; customerId?: number; } ): Observable<PassengersInitialData> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/InitialData`, params);
  }

  getPassengerDetails(id: number): Observable<PassengerDetails> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${id}/Details`);
  }

  getPassengerSettings(id: number): Observable<PassengerSettings> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/${id}/Settings`);
  }

  getRoutesInfoByIds(params: { passengerId: number; }): Observable<PassengerRoute[]> {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/Routes`, params);
  }

  getPassengers(params: PassengersGetParams) {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/Get`, params);
  }

  findClosestStation(address: string, lat: number, lng: number) {
    return this.apiService.get(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/FindClosestStation`, { address, lat, lng });
  }

  getPassengerEligibility(params: any): Observable<any> {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/GetPassengerEligibility`, params);
  }

  deletePassengers(passengerIds: number[]) {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}/Delete`, passengerIds);
  }

  updatePassenger(passenger: PassengerDetails): Observable<PassengerBase> {
    return this.apiService.put(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, passenger);
  }

  savePassenger(passenger: Passenger): Observable<PassengerBase> {
    return this.apiService.post(`${this.BASE_PATH}${this.BASE_PATH_TYPE}`, passenger);
  }

  exportToExcelStudent(data, fileName) {
    this.apiService.postBlob(`${this.BASE_PATH}/Student/Export/Excel`, data, 'blob')
      .pipe(take(1))
      .subscribe(res => this.fileSaverService.downloadBlobFile(res, fileName));
  }

  getAuditLogs(passengerId: number): Observable<PassengersHistoryRow[]> {
    return this.apiService.get(`AuditLogs/PassengerLogs`, { passengerId });
  }

  getHoursForSchoolClass(params) {
    return this.apiService.post('PassengerHours/HoursBySchoolClass', params);
  }
}
