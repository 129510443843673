import { Component, OnInit, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UDatePickerRangeMode } from '@shift/ulib';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { AppConstants } from '@app/shared/constants';
import { LocalizationService } from '@app/shared/services';
import {
  DemandsDayOfWeek,
  DemandsPassengerShiftWeekDuplicateAction,
  DemandsTablePassenger,
  DemandsWeekDuplicateActionType
} from '@app/demands/models';
import { demandsConfig } from '@app/demands/configs';
import { demandsWeekDuplicateComponentConfig } from './demands-week-duplicate.component.config';

@Component({
  selector: 'app-demands-week-duplicate',
  templateUrl: './demands-week-duplicate.component.html',
  styleUrls: [ './demands-week-duplicate.component.scss', './demands-week-duplicate.component.rtl.scss' ]
})
export class DemandsWeekDuplicateComponent implements OnInit {
  @Output() action: EventEmitter<DemandsPassengerShiftWeekDuplicateAction> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'demands-week-duplicate';

  config = cloneDeep(demandsWeekDuplicateComponentConfig);
  form: UntypedFormGroup;
  isRtl: boolean = this.localizationService.isRtl();
  datePickerRangeMode = UDatePickerRangeMode;
  availableDays: DemandsDayOfWeek[] = demandsConfig.daysOfWeek;
  startDate: string;
  endDate: string;
  passengerName: string;
  disableDays: string[] = [];
  passenger: DemandsTablePassenger;
  passengers: DemandsTablePassenger[];

  constructor(
    private bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private localizationService: LocalizationService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!this.passenger.passengerId) {
      this.translateService.get(this.config.dictionary.passengers)
        .pipe(first())
        .subscribe(translated => this.passengerName = `${this.passengers.length} ${translated}`);
    } else {
      this.passengerName = `${this.passenger.firstName} ${this.passenger.lastName}`;
    }

    this.disableActiveWeek();
    this.generateForm();
  }

  generateForm() {
    this.form = this.fb.group({
      dates: [
        [
          moment(this.startDate).add(1, 'weeks').startOf('day').format(AppConstants.DATE_FORMAT_ISO),
          moment(this.endDate).add(1, 'weeks').startOf('day').format(AppConstants.DATE_FORMAT_ISO)
        ],
        Validators.required
      ],
      days: [ this.availableDays ]
    });
  }

  disableActiveWeek() {
    let dayOfWeek = moment(this.startDate).startOf('week');
    const endOfWeek = moment(this.endDate).endOf('week');

    while (dayOfWeek <= endOfWeek) {
      this.disableDays.push(dayOfWeek.format(AppConstants.DATE_FORMAT_ISO));
      dayOfWeek = dayOfWeek.clone().add(1, 'day');
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  actionClick(type: string) {
    const duplicateDates = this.form.get('dates').value;
    const duplicateDays = this.form.get('days').value;

    switch (type) {
      case 'applyGoWeek':
        this.action.emit({ type: DemandsWeekDuplicateActionType.ApplyGoWeek, value: { duplicateDates, duplicateDays } });
        break;

      case 'apply':
        this.action.emit({ type: DemandsWeekDuplicateActionType.Apply, value: { duplicateDates, duplicateDays } });
        break;
    }

    this.bsModalRef.hide();
  }
}
