import { Component, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-builder-convert-to',
  templateUrl: './builder-convert-to.component.html',
  styleUrls: [ './builder-convert-to.component.scss', './builder-convert-to.component.rtl.scss' ]
})
export class BuilderConvertToComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-convert-to';

  items: any = [];
  passengers: any = [];
  checkboxes: any = [
    { check: true, value: 'anonymous', name: 'builder.convertTo.anonymous' },
    { check: false, value: 'specific', name: 'builder.convertTo.specific' }
  ];
  specific = this.checkboxes[1];

  constructor(
    private bsModalRef: BsModalRef
  ) {}

  checkItem(item: any) {
    item.check = !item.check;

    const otherItems = this.checkboxes.filter((ob: any) => ob.value !== item.value);

    if (item.check) {
      otherItems.forEach((ob: any) => ob.check = false);
    } else {
      otherItems.forEach((ob: any) => ob.check = true);
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  apply() {
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { passengers: this.passengers, checkboxes: this.checkboxes } });
  }

}
