import {
  UHereMapMarkerLive,
  UHereMapPathLiveConfig,
  UHereMapPathLivePoint,
  UHereMapPathLiveIcon,
  UHereMapPathLivePolylineOptions,
  UHereMapPathConfig
} from '@shift/ulib';

import { RouteDailyRideStatus, RouteDirection } from '@app/routes/models';

export enum MonitoringDashboardSourceType {
  Car = 1,
  Driver = 2,
  Passenger = 3,
  Accompany = 4,
  Supervisor = 5,
  ExternalDriver = 6
}

export enum MonitoringDashboardLayerId {
  AllAcc = 'all-acc',
  AllDrivers = 'all-drivers',
  AllVehicles = 'all-vehicles',
  Traffic = 'traffic',
  MonitoringPath = 'monitoring-path'
}

export enum MonitoringDashboardPlatform {
  Android,
  iOS
}

export enum MonitoringDashboardStationType {
  Station = 0,
  Destination = 10,
  Accompany = 20
}

export interface MonitoringDashboardPathLive {
  sourceType: MonitoringDashboardSourceType;
  points: UHereMapPathLivePoint[];
  showPointsInfo?: boolean;
  showPolyline?: boolean;
  polylineOptions?: UHereMapPathLivePolylineOptions;
  icon?: UHereMapPathLiveIcon;
  config?: UHereMapPathLiveConfig;
}

export interface MonitoringDashboardReceiveCoordinates {
  sourceType: MonitoringDashboardSourceType;
  memberId: number;
  latitude: number;
  longitude: number;
  locatedAt: string;
}

export interface MonitoringDashboardPassengerLive {
  id: number;
  points: UHereMapMarkerLive[];
}

export interface MonitoringDashboardLastCoordinateItem {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  locatedAt: string;
}

export interface MonitoringDashboardLastCoordinate {
  coordinates: MonitoringDashboardLastCoordinateItem[];
  type: MonitoringDashboardSourceType;
}

export interface MonitoringDashboardLayer {
  id: MonitoringDashboardLayerId;
  type?: MonitoringDashboardSourceType;
  title: string;
  active?: boolean;
}

export interface MonitoringDashboardLayersAction {
  type: MonitoringDashboardSourceType;
  updateFuncName: string;
  clearFuncName: string;
}

export interface MonitoringDashboardRideBase {
  direction: RouteDirection;
  delayTime: number;
  endDateTime: string;
  routeId: number;
  rideId: number;
  rideStatus: RouteDailyRideStatus;
  routeName: string;
  startDateTime: string;
  supplierName: string;
}

export interface MonitoringDashboardWaypoint {
  latitude: number;
  longitude: number;
  stopover: boolean;
}

export interface MonitoringDashboardMonitoredPath {
  coordinates: {
    latitude: number;
    longitude: number;
    locatedAt: string;
  }[];
  memberId: number;
  sourceType: MonitoringDashboardSourceType;
}

export interface MonitoringDashboardStationPassenger {
  active: boolean;
  appVersion: string;
  batteryLevel: number;
  checkIn: boolean;
  checkInAt: string;
  gpsStatus: boolean;
  hasMobileApp: boolean;
  id: number;
  lastInteractionDateTime: string;
  monitored: boolean;
  name: string;
  phone: string;
  platform: MonitoringDashboardPlatform;
  selfReported: boolean;
}

export interface MonitoringDashboardStationBase {
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  passengers: MonitoringDashboardStationPassenger[];
  type: MonitoringDashboardStationType;
  actualArriveDateTime: string;
  address: string;
  anonymousPassengersCount: number;
  arrivalDateTime: string;
  passengersCount: number;
}

export interface MonitoringDashboardStation extends MonitoringDashboardStationBase {
  noEntrance: boolean;
  arrivalGapInMin: number;
  showPassengers: boolean;
}

export interface MonitoringDashboardAccompany {
  appVersion: string;
  batteryLevel: number;
  gpsStatus: boolean;
  hasMobileApp: boolean;
  id: number;
  lastInteractionDateTime: string;
  name: string;
  phone: string;
  platform: MonitoringDashboardPlatform;
  destinationStationId: number;
  sourceStationId: number;
  isAnonymous: boolean;
}

export interface MonitoringDashboardDriver {
  appVersion: string;
  batteryLevel: number;
  gpsStatus: boolean;
  hasMobileApp: boolean;
  id: number;
  lastInteractionDateTime: string;
  name: string;
  phone: string;
  platform: MonitoringDashboardPlatform;
}

export interface MonitoringDashboardRideDetailsBase {
  accompanyDistance: number;
  accompanyDuration: string;
  carType: string;
  code: number;
  delayTime: number;
  distance: number;
  distanceWithAccompany: number;
  duration: string;
  durationWithAccompany: string;
  endHour: string;
  startHour: string;
  useTollRoads: boolean;
  encodedPolylineJson: string;
  direction: RouteDirection;
  waypoints: MonitoringDashboardWaypoint[];
  monitoredPaths: MonitoringDashboardMonitoredPath[];
  stations: MonitoringDashboardStationBase[];
  accompany: MonitoringDashboardAccompany;
  driver: MonitoringDashboardDriver;
}

export interface MonitoringDashboardRideDetails extends MonitoringDashboardRideDetailsBase {
  stations: MonitoringDashboardStation[];
}

export interface MonitoringDashboardRide extends MonitoringDashboardRideBase {
  statusText?: string;
  details: MonitoringDashboardRideDetails;
}

export interface MonitoringDashboardMapMarkerExtraOptions {
  icon: {
    url: string;
    width: number;
    height: number;
  };
  opacity?: number;
}

export interface MonitoringDashboardLegend {
  id: string;
  title: string;
  description?: string;
  icon: { [key: string]: string; };
  iconUrl?: string;
}

export interface MonitoringDashboardMapPath {
  config: UHereMapPathConfig;
  sourceType?: MonitoringDashboardSourceType;
}

export interface MonitoringDashboardUpdateTracksStatus {
  id: number;
  status: RouteDailyRideStatus;
}

export interface MonitoringDashboardUpdateTracksDelay {
  id: number;
  delay: number;
}

export interface MonitoringDashboardUpdatePassengersStatus {
  passengerId: number;
  status: MonitoringDashboardPassengerStatus;
}

export enum MonitoringDashboardPassengerStatus {
  Monitored = 'Monitored',
  Picked = 'Picked'
}
