import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/shared/services';
import {
  FilesBaseParams,
  FilesDeleteParams,
  FilesDownloadParams,
  FilesGetFromFolderItem,
  FilesUpload
} from '@app/files/models';
import { FileSaverService } from './file-saver.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private readonly apiService = inject(ApiService);
  private readonly fileSaverService = inject(FileSaverService);

  private BASE_PATH: string = 'Files';

  isSizeValid(file: File, size: number): boolean {
    return ((file.size) / 1024) / 1024 < size;
  }

  isFileFormatValid(file: File, availableExtensions: string[], availableTypes: string[]): boolean {
    if (availableTypes.includes(file.type)) { return true; }

    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1);

    return availableExtensions.includes(fileExt);
  }

  getFromFolder(params: FilesBaseParams): Observable<FilesGetFromFolderItem[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetFromFolder`, params);
  }

  download(params: FilesDownloadParams): Observable<void> {
    return this.apiService.postBlob(`${this.BASE_PATH}/Download`, params, 'blob')
      .pipe(tap(res => this.fileSaverService.downloadBlobFile(res, params.fileName)));
  }

  upload(params: FormData): Observable<FilesUpload> {
    return this.apiService.postFormFile(`${this.BASE_PATH}/Upload`, params, true);
  }

  delete(params: FilesDeleteParams): Observable<void> {
    return this.apiService.delete(this.BASE_PATH, params);
  }
}
