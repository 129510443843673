<div class="entity-layout__menu">
  <app-entity-menu
    [menu]="menu"
    [activeAction]="activeAction"
    (menuItemAction)="menuItem($event)"
    (activeActionChange)="activeActionSet($event)"
  ></app-entity-menu>
</div>
<div class="entity-layout__main">
  <app-entity-area
    [title]="title"
    [attention]="attention"
    [buttons]="buttons"
    [titleTooltip]="titleTooltip"
    (buttonAction)="button($event)"
  >
    <ng-content></ng-content>
  </app-entity-area>
</div>
