import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { exhaustMap, filter, map, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, fromEvent, merge, of, timer } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { Store } from '@ngrx/store';

import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/services';
import * as AuthActions from '@app/auth/state/actions';
import * as AuthSelectors from '@app/auth/state/selectors';
import { AuthUserInfo } from '@app/auth/models';
import { counterTimer, fromEventCustom } from '@app/shared/utils';

@Injectable()
export class AuthEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.init),
      exhaustMap(() =>
        this.oAuthService.getAccessToken() && this.oAuthService.hasValidAccessToken() ?
          this.authService.loadUserProfile()
            .pipe(
              switchMap((data: AuthUserInfo) => ([ AuthActions.initUser({ data }), AuthActions.initFinished() ]))
            )
          : of(AuthActions.initFinished())
      )
    ));

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateUser),
      switchMap(() => this.store.select(AuthSelectors.selectAuthStatusInitedState).pipe(take(1))),
      filter(data => !!data),
      switchMap(() =>
        this.oAuthService.getAccessToken() && this.oAuthService.hasValidAccessToken() ?
          this.authService.loadUserProfile()
            .pipe(
              map((data: AuthUserInfo) => AuthActions.updateUserSuccess({ data }))
            )
          : EMPTY
      )
    ));

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.oAuthService.logOut()),
        map(() => AuthActions.logoutSuccess())
      )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        tap(() => this.authService.clearUserTablesColumnsFiltersLocalStorage())
      ),
    { dispatch: false }
  );

  countdown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initUser),
      exhaustMap(() => {
        const events = [
          fromEvent(window, 'DOMContentLoaded'),
          fromEvent(window, 'mousemove'),
          fromEvent(window, 'click'),
          fromEventCustom(window, 'scroll', true)
        ];

        return merge(...events)
          .pipe(
            switchMapTo(timer(environment.config.logoutTime * 60 * 1000)),
            switchMap(() => counterTimer(60, 1000)
              .pipe(
                takeUntil(
                  merge(...events)
                    .pipe(
                      tap(() => this.store.dispatch(AuthActions.updateCountdown({ counter: 0 })))
                    )
                )
              )
            ),
            takeUntil(this.actions$.pipe(ofType(AuthActions.logout))),
            switchMap((counter: number) => counter <= 0 ? [ AuthActions.updateCountdown({ counter: 0 }), AuthActions.logout() ] : [ AuthActions.updateCountdown({ counter }) ])
          );
      })
    )
  );

  constructor(
    private readonly store: Store<any>,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly oAuthService: OAuthService
  ) {}
}
