import { Pipe, PipeTransform } from '@angular/core';

import { RoutesMovePassengersRoute } from '@app/routes/models';

@Pipe({
  name: 'routesMovePassengersRouteIds'
})
export class RoutesMovePassengersRouteIdsPipe implements PipeTransform {
  transform(routes: RoutesMovePassengersRoute[]): string[] {
    return routes.map(route => `route-${route.routeId}`);
  }
}
