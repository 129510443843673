import { createReducer, on } from '@ngrx/store';

import { RideOrder } from '@app/ride-orders/models';
import * as RideOrdersActions from '@app/ride-orders/state/actions';

export const FEATURE_KEY: string = 'rideOrders';

export interface State {
  initialized: boolean;
  params: any;
  items: RideOrder[];
}

export const initialState: State = {
  initialized: false,
  params: null,
  items: null
};

export const reducer = createReducer(
  initialState,
  on(
    RideOrdersActions.initialized,
    (state) => ({ ...state, initialized: true })
  ),
  on(
    RideOrdersActions.load,
    (state, { params }) => ({
      ...state,
      params
    })
  ),
  on(
    RideOrdersActions.loadSuccess,
    (state, { items }) => ({ ...state, items: items.map(item => ({ ...item })) })
  ),
  on(
    RideOrdersActions.itemsReset,
    (state) => ({
      ...state,
      items: null
    })
  ),
  on(
    RideOrdersActions.updateItems,
    (state, { items }) => ({
      ...state,
      items: state.items?.map(item => {
        const updatedItem = items.find(obj => obj.routeId === item.routeId);

        return updatedItem ? { ...item, ...updatedItem } : item;
      })
    })
  ),
  on(
    RideOrdersActions.updateRideOrderStatus,
    (state, { routeId, status }) => ({
      ...state,
      items: state.items?.map(item => item.routeId === routeId ? { ...item, status } : item)
    })
  )
);
