import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { AuthFacade } from '@app/auth/state/facades';

@Component({
  selector: 'app-auth-redirect-callback',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthRedirectCallbackComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    private oAuthService: OAuthService
  ) {}

  ngOnInit() {
    combineLatest([
      this.authFacade.isInited$,
      this.authFacade.isLoggedIn$
    ])
      .pipe(
        takeUntil(this.unsubscribe),
        filter(([ isInited ]) => isInited)
      )
      .subscribe(([ , isLoggedIn ]) => {
        if (isLoggedIn) {
          this.router.navigate([ '/' ]);
        } else {
          this.oAuthService.initLoginFlow();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
