import { UInputCitiesCity } from '@shift/ulib';

import { Cities } from '@app/shared/models';

export function transformToInputCities(cities: Cities): UInputCitiesCity[] {
  return cities.cities.map(city => ({
    ...city,
    branches: city.branches.map(branch => ({
      ...branch,
      hiddenInList: !cities.showBranchHierarchy,
      areas: branch?.areas?.length ? branch.areas : null
    }))
  }));
}
