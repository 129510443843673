import { AuthModuleName } from '@app/auth/models';
import { MenuIcon } from '@app/shared/models';
import { DemandsView } from '@app/demands/models';

const DICTIONARY_PATH: string = 'shared.header.demandsView';

const viewMenuIcons: MenuIcon[] = [
  {
    value: DemandsView.Passengers,
    name: `${DICTIONARY_PATH}.passengers`,
    id: 'header-demands-view-passengers',
    urls: {
      default: '/assets/images/header/demands-view/passengers.svg',
      hover: '/assets/images/header/demands-view/passengers-hover.svg'
    },
    config: {
      style: {
        width: 17,
        height: 17
      }
    },
    feature: {
      module: AuthModuleName.DemandsPassengersView
    }
  },
  {
    value: DemandsView.Shifts,
    name: `${DICTIONARY_PATH}.shifts`,
    id: 'header-demands-view-shifts',
    urls: {
      default: '/assets/images/header/demands-view/shifts.svg',
      hover: '/assets/images/header/demands-view/shifts-hover.svg'
    },
    config: {
      style: {
        width: 17,
        height: 17
      }
    },
    feature: {
      module: AuthModuleName.DemandsShiftsView
    }
  }
];

export const headerDemandsViewComponentConfig = {
  viewMenuIcons
};
