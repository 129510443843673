const DICTIONARY_PATH: string = 'routes.changeHour';

export const routesChangeHourComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    hours: `${DICTIONARY_PATH}.hours`,
    type: `${DICTIONARY_PATH}.type`,
    comments: `${DICTIONARY_PATH}.comments`,
    actions: {
      saveAndClose: 'general.saveAndClose',
      saveAndSendSC: 'general.saveAndSendSC',
      saveAndEditEmail: 'general.saveAndEditEmail'
    }
  }
};
