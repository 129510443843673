import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'movePassengersCheckAll',
  standalone: true
})
export class MovePassengersCheckAllPipe implements PipeTransform {
  transform(items): boolean {
    return !!items && !!items.length && items.every(item => item.check);
  }
}
