<u-grid
  [class]="'u-grid_white'"
  [tableName]="'shuttle-policy-cities'"
  [saveTableProps]="true"
  [rows]="citiesPolicyForm.getRawValue() || []"
  [columns]="config.grid.columns"
  [columnMode]="config.grid.columnMode"
  [headerHeight]="45"
  [headerOffset]="0"
  [footerHeight]="0"
  [rowHeight]="55"
  [reorderable]="true"
  [scrollbarV]="true"
  [scrollbarH]="true"
  [isRtl]="isRtl"
  [lang]="lang"
  [rowIdentity]="rowIdentity"
  [saveColumnsFilters]="true"
  [showColumnsFilter]="false"
  [editMode]="config.grid.editMode"
  [messages]="config.dictionary.uGrid | translate"
  (editValuesClose)="onEditValuesClose($event)"
></u-grid>

<ng-template #toggleCell ngx-datatable-cell-template let-value="value" let-row="row" let-column="column">
  <u-checkbox
    class="u-checkbox_circle u-checkbox_green"
    [container]="'body'"
    [uTooltip]="(value ? dictionary.yes : dictionary.no) | translate"
    [formControl]="citiesPolicyForm.at(row.index).get(column.prop)"
    (change)="column.prop === 'enabled' ? onEnabledToggle(citiesPolicyForm.at(row.index).get(column.prop).value, row.index) : ''"
  ></u-checkbox>
</ng-template>

<ng-template #maxTravelTimeCell ngx-datatable-cell-template let-row="row" let-value="value">
  <div
    class="cities-policy__cell"
    [ngClass]="{ 'cities-policy__cell_disabled': !row.enabled }"
    (click)="cellClick($event, row.enabled)"
  >
    {{value ? value : (dictionary.none | translate)}}
  </div>
</ng-template>

<ng-template #cityCentralStationAddressCell ngx-datatable-cell-template let-row="row" let-value="value">
  <div
    class="cities-policy__cell"
    [ngClass]="{ 'cities-policy__cell_disabled': !row.enabled }"
    (click)="cellClick($event, row.enabled)"
  >
    {{value ? value : (dictionary.withoutAddress | translate)}}
  </div>
</ng-template>
