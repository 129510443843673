<div class="input-communications__wrapper" *ngIf="form">
  <u-group
    *ngFor="let contactForm of form.controls; let i = index; let last = last;"
    [formGroup]="contactForm"
    class="u-group_large-field u-group_app-input-communications u-group_flex input-communications__group"
    [name]="showTitle ? (config.dictionary.communications | translate) : null"
  >
    <div class="input-communications__container">
      <div class="input-communications__item">
        <u-select-s
          [items]="config.contactTypes"
          [messages]="config.dictionary.uSelectS | translate"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [valid]="(contactForm.disabled || contactForm.get('type').disabled) ? true : contactForm.get('type').valid"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.remove | translate"
          [placeholder]="config.dictionary.select | translate"
          formControlName="type"
          [container]="'body'"
          [classUDropdownContent]="'u-dropdown-content_app-default'"
        ></u-select-s>
      </div>
      <div class="input-communications__item">
        <input
          *ngIf="contactForm.get('value')"
          uInput
          type="text"
          (change)="onCommunicationChange($event.target.value, i)"
          [valid]="!contactForm.get('value').pending && !contactForm.disabled ? contactForm.get('value').valid : true"
          formControlName="value"
          placeholder="{{contactTypeNames[contactForm.value['type']] | translate}}"
        >
        <input
          *ngIf="contactForm.get('contact')"
          uInput
          type="text"
          (change)="onCommunicationChange($event.target.value, i)"
          [valid]="!contactForm.get('contact').pending && !contactForm.disabled ? contactForm.get('contact').valid : true"
          formControlName="contact"
          placeholder="{{contactTypeNames[contactForm.value['type']] | translate}}"
        >
      </div>
    </div>
    <div class="input-communications__actions">
      <div
        class="input-communications__actions-btn input-communications__actions-btn_remove"
        *ngIf="(!last || i > 0 || showRemoveIconInLastContact) && !contactForm.get('type').disabled"
        (click)="removeContact(i)"
      ></div>
      <div class="input-communications__actions-btn input-communications__actions-btn_add" *ngIf="last && showAddIcon" (click)="addContact()">+</div>
    </div>
  </u-group>
</div>
