export const tablePageComponentConfig = {
  dictionary: {
    edit: 'general.edit',
    view: 'general.view',
    yes: 'general.yes',
    no: 'general.no',
    uGrid: 'uGrid',
    uGridMessagesTable: 'uGridMessagesTable'
  }
};
