import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GridHeaderButton } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class GridHeaderService {
  private buttonClick: Subject<GridHeaderButton> = new Subject();

  buttonClick$ = this.buttonClick.asObservable();

  updateButtonClick(button: GridHeaderButton) {
    this.buttonClick.next(button);
  }
}
