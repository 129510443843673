import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'builderHighlightStation'
})
export class BuilderHighlightStationPipe implements PipeTransform {
  transform(station, selectedPassengerId: number): boolean {
    return station && station.passengers && station.passengers.some(passenger => passenger.passengerId === selectedPassengerId);
  }
}

