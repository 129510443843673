import { Pipe, PipeTransform } from '@angular/core';

import { BuilderActiveRideDriver, BuilderDayOfWeekDriver } from '@app/builder/models';

@Pipe({
  name: 'builderDriverName'
})
export class BuilderDriverNamePipe implements PipeTransform {
  transform(driver: BuilderActiveRideDriver | BuilderDayOfWeekDriver, showName: boolean): string {
    return (driver?.driverId === null || driver && showName) ?
      `${driver.firstName} ${driver.lastName}`: null;
  }
}

