import { Component, OnInit, Input, HostBinding, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep, isEqual } from 'lodash';
import * as moment from 'moment/moment';
import { UButtonSize, URangePreset } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { HeaderDataService, TrackingService } from '@app/shared/services';
import {
  RoutesChangeType,
  RoutesChangeEmailSendType,
  RoutesChangeAccompanyCostCalculationCostType,
  RoutesChangeAccompanyCostCalculationHoursPriceOption,
  RouteDailyRow
} from '@app/routes/models';
import { RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { routesChangeAccompanyCostComponentConfig } from './routes-change-accompany-cost.component.config';

@Component({
  selector: 'app-routes-change-accompany-cost',
  templateUrl: './routes-change-accompany-cost.component.html',
  styleUrls: [ './routes-change-accompany-cost.component.scss', './routes-change-accompany-cost.component.rtl.scss' ],
  providers: [ BsModalRef ]
})
export class RoutesChangeAccompanyCostComponent implements OnInit {
  @Input() activeRide: RouteDailyRow;
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'routes-change-accompany';

  private readonly fb = inject(UntypedFormBuilder);
  private readonly routesCommonService = inject(RoutesCommonService);
  private readonly trackingService = inject(TrackingService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly routesTableService = inject(RoutesTableService);

  form: UntypedFormGroup;
  uButtonSize = UButtonSize;
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  costType = RoutesChangeAccompanyCostCalculationCostType;
  config = cloneDeep(routesChangeAccompanyCostComponentConfig);

  ngOnInit() {
    this.createForm();
  }

  get changesForm(): UntypedFormGroup {
    return this.form.get('datesChange') as UntypedFormGroup;
  }

  createForm() {
    this.form = this.fb.group({
      routeId: [ this.activeRide.routeId ],
      accompanyId: [ this.activeRide.accompany.value ],
      costType: [ this.activeRide.accompany.costType || RoutesChangeAccompanyCostCalculationCostType.Fixed ],
      totalCost: [ this.activeRide.accompany.cost ],
      costPerHour: [ this.activeRide.accompany.costPerHour ],
      hours: [ this.activeRide.accompany.hours ],
      priceOption: [ RoutesChangeAccompanyCostCalculationHoursPriceOption.Manual ],
      type: [ RoutesChangeType.Unplanned, Validators.required ],
      datesChange: this.fb.group({
        dates: [ [ this.headerDataService.getActiveDate() ], Validators.required ],
        dateFrom: [ '' ],
        dateTo: [ '' ],
        type: [ this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay ],
        availablePresets: [ this.config.availableDatePresets ],
        checkDaysActive: [ [ moment(this.headerDataService.getActiveDate()).weekday() ] ],
        checkDaysAvailable: [ this.config.checkDaysAvailable ]
      }),
      comment: [ '' ]
    });
  }

  updatePeriod(data: any) {
    this.changesForm.patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });
  }

  updateDates({ dates, checkDaysActive }): void {
    const datesStore: string[] = this.changesForm.get('dates').value;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        this.changesForm.patchValue({
          dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
          checkDaysActive
        });
      }
    }
  }

  submit(emailSendType?: RoutesChangeEmailSendType) {
    this.trackingService.track(`[Track daily view change] - ${this.form.value.type === RoutesChangeType.Planned ? 'fixed' : 'temp'} change - accompany cost changed`);

    this.saveAccompanyCost(emailSendType);
  }

  saveAccompanyCost(emailSendType?: RoutesChangeEmailSendType) {
    const { accompanyId, type, routeId, hours, totalCost, costPerHour, costType, comment } = this.form.value;
    const dates = this.changesForm.get('dates').value;
    const checkDaysActive = this.changesForm.get('checkDaysActive').value;
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
    const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    const body = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        accompanyId,
        comment,
        type,
        dateFrom,
        dateTo,
        hours,
        totalCost,
        costType,
        costPerHour,
        days: checkDaysActive
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routesTableService.changeAccompanyCost(body)
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  selectHoursPriceOption(option: RoutesChangeAccompanyCostCalculationHoursPriceOption) {
    if (option === RoutesChangeAccompanyCostCalculationHoursPriceOption.Auto) {
      this.form.get('hours').disable();
    } else {
      this.form.get('hours').enable();
    }
  }
}
