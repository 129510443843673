import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  UButtonModule,
  UCommonModule,
  UHereMapsModule,
  UPopoverModule,
  USearchModule,
  UTooltipModule,
  UVirtualScrollerModule
} from '@shift/ulib';

import { SharedModule } from '@app/shared/shared.module';
import { ItemsFieldSumPipe, KmMileConvertorPipe } from '@app/shared/pipes';
import {
  MonitoringDashboardComponent,
  MonitoringDashboardMapComponent,
  MonitoringDashboardLayersComponent,
  MonitoringDashboardInfoComponent,
  MonitoringDashboardTimerComponent
} from '@app/monitoring-dashboard/components';
import {
  MonitoringDashboardIsMonitoredPipe,
  MonitoringDashboardRidesSearchPipe,
  MonitoringDashboardStationsSearchPipe
} from '@app/monitoring-dashboard/pipes';
import {
  MonitoringDashboardService,
  MonitoringDashboardHubService,
  MonitoringDashboardDataService,
  MonitoringDashboardRidesService
} from '@app/monitoring-dashboard/services';

const COMPONENTS = [
  MonitoringDashboardComponent,
  MonitoringDashboardMapComponent,
  MonitoringDashboardLayersComponent,
  MonitoringDashboardInfoComponent,
  MonitoringDashboardTimerComponent
];

const PIPES = [
  MonitoringDashboardIsMonitoredPipe,
  MonitoringDashboardRidesSearchPipe,
  MonitoringDashboardStationsSearchPipe
];

const SERVICES = [
  MonitoringDashboardService,
  MonitoringDashboardHubService,
  MonitoringDashboardDataService,
  MonitoringDashboardRidesService
];

@NgModule({
  imports: [
    CommonModule,
    UCommonModule,
    TranslateModule,
    UVirtualScrollerModule,
    FormsModule,
    UPopoverModule,
    UTooltipModule,
    UHereMapsModule,
    UButtonModule,
    SharedModule,
    USearchModule,
    ReactiveFormsModule,
    ItemsFieldSumPipe,
    KmMileConvertorPipe
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES
  ],
  providers: [
    ...SERVICES
  ],
  exports: [
    MonitoringDashboardComponent
  ]
})
export class MonitoringDashboardModule {}
