import { Injectable, inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { MultiDeleteConfirmInitialState } from '@app/shared/models';
import { MultiDeleteConfirmComponent } from '@app/shared/components';

@Injectable({
  providedIn: 'root'
})
export class MultiDeleteConfirmModalService {
  private readonly bsModalService = inject(BsModalService);

  openModal(initialState: MultiDeleteConfirmInitialState) {
    return this.bsModalService.show(
      MultiDeleteConfirmComponent,
      {
        class: 'u-modal u-modal_app-multi-delete-confirm',
        animated: true,
        initialState,
        ignoreBackdropClick: true
      }
    );
  }
}
