import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import {
  ReOptimizationChange,
  ReOptimizationSummary,
  ReOptimizationResponse
} from '@app/re-optimization/models';

@Injectable()
export class ReOptimizationService {
  private BASE_PATH: string = 'ReOptimization';

  constructor(private apiService: ApiService) {}

  getReOptimizationSummary(date: string): Observable<ReOptimizationSummary[]> {
    return this.apiService.get(this.BASE_PATH, { date });
  }

  getReOptimizationChanges(sessionId: string): Observable<ReOptimizationChange[]> {
    return this.apiService.get(`${this.BASE_PATH}/Changes`, { sessionId });
  }

  reOptimize(routeIds: number[]): Observable<ReOptimizationResponse> {
    return this.apiService.post(this.BASE_PATH, routeIds);
  }

  cleanUp(routeIds: number[]): Observable<ReOptimizationResponse> {
    return this.apiService.post(`${this.BASE_PATH}/Cleanup`, routeIds);
  }
}
