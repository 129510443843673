import { Component, HostBinding, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UAddress } from '@shift/ulib';

import { TrackingService } from '@app/shared/services';

@Component({
  selector: 'app-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: [ './input-address.component.scss' ]
})
export class InputAddressComponent {
  @Input() form: UntypedFormGroup;

  @HostBinding('class') hostClasses: string = 'input-address';

  constructor(
    public trackingService: TrackingService
  ) {}

  updateAddress(data: UAddress) {
    this.form.markAsDirty();
    this.form.patchValue({
      fullAddress: data.address,
      latitude: data.lat,
      longitude: data.lng,
      placeId: data.placeId
    });
  }
}
