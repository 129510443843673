<div class="builder-map-filter__wrapper" [delayClickOutsideInit]="true" (uClickOutside)="close()">
  <div class="builder-map-filter__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-map-filter__main">
    <div class="builder-map-filter__message">
      {{'builder.mapFilter.showOnMap' | translate}}
    </div>
    <div class="builder-map-filter__list">
      <div class="builder-map-filter__item">
        <div class="builder-map-filter__item-field">
          <u-checkbox class="u-checkbox u-checkbox_square-medium" [(ngModel)]="filter.passengers"></u-checkbox>
        </div>
        <div class="builder-map-filter__item-name">{{'builder.mapFilter.passengers' | translate}}</div>
      </div>
      <div class="builder-map-filter__item">
        <div class="builder-map-filter__item-field">
          <u-checkbox class="u-checkbox u-checkbox_square-medium" [(ngModel)]="filter.stations"></u-checkbox>
        </div>
        <div class="builder-map-filter__item-name">{{'builder.mapFilter.stations' | translate}}</div>
      </div>
    </div>
    <div class="builder-map-filter__actions">
      <button class="builder-map-filter__actions-btn builder-map-filter__actions-btn_clear" (click)="close()">{{'builder.mapFilter.cancel' | translate}}</button>
      <button class="builder-map-filter__actions-btn" (click)="apply()">{{'builder.mapFilter.apply' | translate}}</button>
    </div> 
  </div>
</div>