import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Errors } from '@app/shared/models';
import { ApiService } from '@app/shared/services';
import {
  RoutesChangeCarTypeBody,
  RoutesChangeCarBody,
  RoutesChangeRemovePassengerParams,
  RoutesChangeAddPassengerParams
} from '@app/routes/models';

@Injectable({
  providedIn: 'root'
})
export class RouteChangeValidationService {
  private BASE_PATH = 'RouteChangeValidation';

  constructor(
    private apiService: ApiService
  ) {}

  validationCarType(params: RoutesChangeCarTypeBody): Observable<void> {
    return this.apiService.post(
      `${this.BASE_PATH}/CarType`,
      params,
      'text',
      false,
      false,
      [ Errors.VehicleSubceedsCarTypeCapacity, Errors.CarTypeExceedsVehicleCapacity ]
    );
  }

  validationCar(params: RoutesChangeCarBody): Observable<void> {
    return this.apiService.post(
      `${this.BASE_PATH}/Car`,
      params,
      'text',
      false,
      false,
      [ Errors.VehicleSubceedsCarTypeCapacity, Errors.CarTypeExceedsVehicleCapacity ]
    );
  }

  validationAddPassenger(params: RoutesChangeAddPassengerParams): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/AddPassenger`, params, 'text', false, false, null, true);
  }

  validationRemovePassenger(params: RoutesChangeRemovePassengerParams): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/RemovePassenger`, params, 'text', false, false, null, true);
  }
}
