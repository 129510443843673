import { UInputModalConfig, UInputModalMode } from '@shift/ulib';

import { RouteTemplatesComponent } from '@app/route-templates/components';
import { RouteTemplatesMode } from '@app/route-templates/models';

const DICTIONARY_PATH: string = 'builder.full.filter';

const templatesFilterInputModal: UInputModalConfig = {
  contentComponent: RouteTemplatesComponent,
  placeholder: 'general.select',
  mode: UInputModalMode.Counter,
  showCloseIcon: true,
  modalClassNames: 'u-modal u-modal_content u-modal_app-route-templates',
  contentComponentInitialValueKey: 'selectedRowIds',
  contentComponentEventName: 'selectedRowsChange',
  contentComponentInputs: {
    extraClass: 'route-templates_input-modal',
    mode: RouteTemplatesMode.Readonly,
    gridHeaderDictionary: {
      all: `${DICTIONARY_PATH}.templates`
    }
  }
};

export const builderFullFilterComponentConfig = {
  templatesFilterInputModal,
  dictionary: {
    mapView: `${DICTIONARY_PATH}.mapView`,
    tableView: `${DICTIONARY_PATH}.tableView`,
    routesPolicy: `${DICTIONARY_PATH}.routesPolicy`
  }
};
