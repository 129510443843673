import { UMenuIcon, UMenuIconChild } from '@shift/ulib';

import { AuthModuleFeature, AuthModuleFeatureGroup, AuthPermission, AuthPermissionGroup } from '@app/auth/models';

export enum HeaderMenuIconsTemplate {
  Accompanies = 'accompanies',
  Accounting = 'accounting',
  Branches = 'branches',
  BuilderDefaultRoutes = 'builderDefaultRoutes',
  BuilderDefaultTemplates = 'builderDefaultTemplates',
  BuilderFull = 'builderFull',
  BuilderFullResults = 'builderFullResults',
  Contracts = 'contracts',
  Drivers = 'drivers',
  Passengers = 'passengers',
  PassengersArmy = 'passengersArmy',
  PassengersMunicipality = 'passengersMunicipality',
  Reports = 'reports',
  RideSupervisors = 'rideSupervisors',
  RideOrders = 'rideOrders',
  RouteTemplates = 'routeTemplates',
  RoutesDaily = 'routesDaily',
  RoutesSC = 'routesSC',
  RoutesWeekly = 'routesWeekly',
  Schools = 'schools',
  DemandsPassengersView = 'demandsPassengersView',
  DemandsShiftsView = 'demandsShiftsView',
  ShuttleCompanies = 'shuttleCompanies',
  ShuttleCompaniesGeneral = 'shuttleCompaniesGeneral',
  ShuttleCompaniesCustomers = 'shuttleCompaniesCustomers',
  Stations = 'Stations'
}

export interface HeaderMenuIcon extends UMenuIcon {
  replaceByOneChild?: boolean;
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  activateOnClick?: boolean;
  children?: HeaderMenuIconChild[];
  permission?: AuthPermission;
  permissionGroup?: AuthPermissionGroup;
  checkFnName?: HeaderMenuIconCheckFnName;
  fetchItemsKey?: HeaderMenuIconFetchItemsKey;
  childrenDividerBefore?: HeaderMenuIconValue;
}

export interface HeaderMenuIconChild extends UMenuIconChild {
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  permission?: AuthPermission;
  permissionGroup?: AuthPermissionGroup;
  checkFnName?: HeaderMenuIconCheckFnName;
  fetchItemsKey?: HeaderMenuIconFetchItemsKey;
}

export enum HeaderMenuIconValue {
  Activities = 'activities',
  MonitoringDashboard = 'monitoring-dashboard',
  Feed = 'feed',
  Import = 'import',
  Notes = 'notes',
  NewBuilder = 'new-builder',
  NewBuilderFull = 'new-builder-full',
  NewPassenger = 'new-passenger',
  NextYear = 'next-year',
  Plus = 'plus',
  Refresh = 'refresh',
  SendChanges = 'send-changes',
  PassengersImport = 'passengers-import',
  ImportContract = 'import-contract',
  MovePassengers = 'move-passengers',
  RegularRoute = 'regular-route',
  FixedRoute = 'fixed-route',
  ShiftsRoute = 'shifts-route',
  SpecialRoute = 'special-route',
  ShuttleRoute = 'shuttle-route',
  DeliveryRoute = 'delivery-route',
  AddNewRoute = 'add-new-route',
  AddNewTemplate = 'add-new-template',
  Quote = 'quote',
  ProFormaInvoice = 'pro-forma-invoice',
  TaxInvoice = 'tax-invoice',
  TaxInvoiceReceipt = 'tax-invoice-receipt',
  Receipt = 'receipt',
  CreditTaxInvoice = 'credit-tax-invoice',
}

export enum HeaderMenuIconFetchItemsKey {
  RouteTypes = 'routeTypes',
  AccountingDocuments = 'accountingDocuments'
}

export enum HeaderMenuIconCheckFnName {
  CheckRouteTypes = 'checkRouteTypes',
  CheckAccountingDocuments = 'checkAccountingDocuments'
}
