import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

import { TrackingService } from '@app/shared/services/tracking.service';
import { FeedDataHolder, FeedFilter, FeedFilterData, FeedStatus, FeedType } from '@app/feed/models';
import { feedConfig } from '@app/feed/configs';

@Injectable({
  providedIn: 'root'
})
export class FeedFilterService {
  private selectedFilter: BehaviorSubject<FeedFilter> = new BehaviorSubject(new FeedFilter());

  selectedFilter$ = this.selectedFilter.asObservable();
  feedData = new FeedDataHolder();
  filteringEnabled = false;
  filteringEnabledEvent: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private trackingService: TrackingService
  ) {}

  private enableFiltering() {
    this.filteringEnabled = true;

    if (this.selectedFilter.value.page && this.selectedFilter.value.value) {
      this.selectedFilter.next({
        ...this.selectedFilter.value,
        items: this.getTargetItems(this.selectedFilter.value.value)
      });
    }

    this.filteringEnabledEvent.next();
  }

  private getTargetItems(filterName: FeedStatus) {
    switch (filterName) {
      // daily
      case FeedStatus.RidesToReOptimize: return this.feedData.reOptimization?.forReOptimization;
      case FeedStatus.RidesWithEmptyStations: return this.feedData.reOptimization?.forCleanup;
      case FeedStatus.OnGoingWithMonitoring: return this.feedData.realTime?.withMonitoring;
      case FeedStatus.OnGoingWithoutMonitoring: return this.feedData.realTime?.withoutMonitoring;
      case FeedStatus.OnGoingWithoutEntrance: return this.feedData.realTime?.withoutEntrance;
      case FeedStatus.Finished: return this.feedData.finished?.finished;
      case FeedStatus.FinishedMonitored: return this.feedData.finished?.monitored;
      case FeedStatus.FinishedWithoutEntrance: return this.feedData.finished?.withoutEntrance;
      case FeedStatus.FinishedWithDelay: return this.feedData.finished?.withDelay;
      case FeedStatus.FinishedHasNotPickedPassengers: return this.feedData.finished?.notCheckedIn;
      case FeedStatus.DuplicatedAccompany: return this.feedData.task?.duplicatedAccompany;
      case FeedStatus.DuplicatedDriver: return this.feedData.task?.duplicatedDriver;
      case FeedStatus.WithoutSupervisor: return this.feedData.task?.withoutSupervisor;
      case FeedStatus.DuplicatedSupervisor: return this.feedData.task?.duplicatedSupervisor;
      case FeedStatus.DuplicatedPassenger: return this.feedData.task?.routesWithDuplicatedPassenger;
      // weekly
      case FeedStatus.NeededAccompany: return this.feedData.task?.missingAccompany;
      case FeedStatus.NeededDriver: return this.feedData.task?.missingDriver;
      case FeedStatus.NeededShuttleCompany: return this.feedData.task?.missingShuttleCompany;
      // general
      case FeedStatus.WithoutPrice: return this.feedData.task?.withoutPrice;
    }
  }

  initVisibleFeedBlocks() {
    const visibleFeedBlocks = JSON.parse(localStorage.getItem(feedConfig.visibleFeedBlocksKey));

    if (!visibleFeedBlocks) {
      const expandedTemplate = {
        [FeedType.Finished]: false,
        [FeedType.Task]: false,
        [FeedType.RealTime]: false
      };

      localStorage.setItem(feedConfig.visibleFeedBlocksKey, JSON.stringify(expandedTemplate));
    }
  }

  isBlockVisible(blockName: FeedType) {
    const visibleFeedBlocks = JSON.parse(localStorage.getItem(feedConfig.visibleFeedBlocksKey));

    return visibleFeedBlocks[blockName];
  }

  setVisibleFeedBlocks(blockName: FeedType, value: boolean) {
    const visibleFeedBlocks = JSON.parse(localStorage.getItem(feedConfig.visibleFeedBlocksKey));

    visibleFeedBlocks[blockName] = value;

    localStorage.setItem(feedConfig.visibleFeedBlocksKey, JSON.stringify(visibleFeedBlocks));
  }

  setData(data: FeedFilterData) {
    this.feedData[data.prop] = <any>data.data;

    if ((this.feedData.task && this.feedData.realTime && this.feedData.finished) || this.feedData.reOptimization) {
      this.enableFiltering();
    }
  }

  setSelectedFilter(filter: FeedFilter) {
    if (!filter) { return; }

    if (this.filteringEnabled) {
      filter.items = this.getTargetItems(filter.value);
    }

    this.selectedFilter.next(filter);
  }

  feedTracking(feedStatus: FeedStatus) {
    const feedStatusMessages = {
      [FeedStatus.OnGoingWithMonitoring]: 'filter - on Going With Monitoring',
      [FeedStatus.OnGoingWithoutMonitoring]: 'filter - on Going Without Monitoring',
      [FeedStatus.OnGoingWithoutEntrance]: 'filter - on Going Without Entrance',
      [FeedStatus.Finished]: 'filter - finished',
      [FeedStatus.FinishedMonitored]: 'filter - finished Monitored',
      [FeedStatus.FinishedWithoutEntrance]: 'finished Without Entrance',
      [FeedStatus.FinishedWithDelay]: 'finished With Delay',
      [FeedStatus.FinishedHasNotPickedPassengers]: 'filter - finished has not picked passengers',
      [FeedStatus.AccompaniesWithNoAssignment]: 'filter - accompany with no assignment',
      [FeedStatus.DuplicatedAccompany]: 'filter - duplicate assignment for accompany',
      [FeedStatus.NeededAccompany]: 'filter - needed Accompany',
      [FeedStatus.DriversWithNoAssignment]: 'filter - driver with no assignment',
      [FeedStatus.DuplicatedDriver]: 'filter - duplicate assignment for driver',
      [FeedStatus.DuplicatedPassenger]: 'filter - duplicate assignment for passenger',
      [FeedStatus.NeededDriver]: 'filter - needed Driver',
      [FeedStatus.NeededShuttleCompany]: 'filter - needed Shuttle Company',
      [FeedStatus.WithoutPrice]: 'filter - without Price',
      [FeedStatus.WithoutSupervisor]: 'filter - Routes without assigned supervisor',
      [FeedStatus.DuplicatedSupervisor]: 'filter - duplicate assignment for supervisor',
      [FeedStatus.RidesToReOptimize]: 'click on Re-Optimization',
      [FeedStatus.RidesWithEmptyStations]: 'click on Rides with empty stations'
    };

    if (feedStatusMessages[feedStatus]) {
      this.trackingService.track(`[${feedConfig.trackingId}] - ${feedStatusMessages[feedStatus]}`);
    }
  }

  clearSelectedFilter() {
    this.setSelectedFilter(new FeedFilter());

    this.router.navigate([], {
      queryParams: { page: null, feedFilter: null },
      queryParamsHandling: 'merge',
      onSameUrlNavigation: 'reload'
    });
  }
}
