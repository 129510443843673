export interface CitiesPolicyCity {
  cityId: number;
  name: string;
  enabled: boolean;
  maxDurationMinutes: number;
  useCityCentralStation: boolean;
  cityCentralStationAddress: {
    cityId: number;
    placeId: string;
    addressId: number;
    fullAddress: string;
    latitude: number;
    longitude: number;
  };
}
