import { Injectable } from '@angular/core';

import { demandsConfig } from '@app/demands/configs';
import { DemandsView } from '@app/demands/models';

@Injectable({
  providedIn: 'root'
})
export class DemandsStorageService {
  get(): { view: DemandsView; } {
    return JSON.parse(localStorage.getItem(demandsConfig.storageKey));
  }

  update(data: { view?: DemandsView; }) {
    localStorage.setItem(demandsConfig.storageKey, JSON.stringify({
      ...this.get(),
      ...data
    }));
  }

  getView() {
    return this.get()?.view;
  }

  updateView(view: DemandsView) {
    this.update({ view });
  }
}
