<div class="builder-layers__container">
  <div 
    class="builder-layers__menu"
  >
    <div 
      *ngFor="let layer of config().layers"
      class="builder-layers__menu-item"
      [ngClass]="{ 'builder-layers__menu-item_active': layer.active }"
      (click)="toggleLayer(layer)"
    >
      <div class="builder-layers__menu-item-icon builder-layers__menu-item-icon_{{layer.id}}"></div>
      <div
        class="builder-layers__menu-item-title builder-layers__menu-item-title_{{layer.id}}"
      >
        {{layer.title | translate}}
      </div>
    </div>
  </div>
</div>
