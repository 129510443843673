import { Injectable, computed, inject, signal } from '@angular/core';
import { exhaustMap } from 'rxjs/operators';
import { pipe } from 'rxjs';

import { patchSignal, rxMethod, tapResponse } from '@app/shared/utils';
import { StationsState } from '@app/stations/models';
import { stationsConfig } from '@app/stations/configs';
import { StationsService } from '@app/stations/services';

@Injectable()
export class StationsStoreService {
  private readonly stationsService = inject(StationsService);

  readonly #state = signal<StationsState>(stationsConfig.initialState);

  readonly state = this.#state.asReadonly();
  readonly stations = computed(() => this.state().stations);

  readonly getSavedStations = rxMethod<void>(
    pipe(
      exhaustMap(() =>
        this.stationsService.getSavedStations()
          .pipe(
            tapResponse(
              stations => patchSignal(this.#state, { stations }),
              () => null
            )
          )
      )
    )
  );

  deleteStations(ids: number[]) {
    patchSignal(this.#state, state => ({ stations: state.stations.filter(station => !ids.includes(station.stationId)) }));
  }
}
