export enum RouteSplitEmptyStationsAction {
  Keep,
  Remove
}

export interface RouteSplitPassenger {
  id: number;
  name: string;
  mobile: string;
  isSupervisor: boolean;
  stationName: string;
  targetName: string;
}

export interface RouteSplitPassengers {
  routeId: number;
  passengerIds: number[];
  keepEmptyStations: boolean;
}
