import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RideApproval, RideDriverApproval } from '@app/ride-approvals/models';

@Injectable({
  providedIn: 'root'
})
export class RideApprovalsService {
  private readonly apiService = inject(ApiService);

  private BASE_PATH: string = 'RideApprovals';

  getRideApprovals(rideIds: number[], withoutSpinner?: boolean): Observable<RideApproval[]> {
    return this.apiService.post(`${this.BASE_PATH}/Get`, rideIds, 'text', false, withoutSpinner);
  }

  getRideDriverApprovals(rideId: number): Observable<RideDriverApproval[]> {
    return this.apiService.get(`${this.BASE_PATH}/GetDriverApprovals`, { rideId });
  }
}
