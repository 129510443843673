import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { concatMap, delay, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { chunk } from 'lodash';

import {
  CommonService,
  HeaderDataService
} from '@app/shared/services';
import { ActivityRoute, ActivityType, ActivityUpdate } from '@app/activities/models';
import { ActivitiesService, ActivitiesDataService } from '@app/activities/services';
import { NavigationPaths } from '@app/shared/models';
import { AuthUserInfo } from '@app/auth/models';
import * as AuthSelectors from '@app/auth/state/selectors';
import * as RoutesActions from '@app/routes/state/actions';
import * as RoutesSelectors from '@app/routes/state/selectors';
import { routesConfig } from '@app/routes/configs';
import { RideMonitoringData, RoutesViewType } from '@app/routes/models';
import { RoutesTableService } from '@app/routes/services';
import { RouteNotesState } from '@app/route-notes/models';
import { RouteNotesService } from '@app/route-notes/services';
import { RideApproval } from '@app/ride-approvals/models';
import { RideApprovalsService } from '@app/ride-approvals/services';
import { RoutesTableHubService } from '@app/routes/services';

@Injectable()
export class RoutesEffects {
  dailyInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyInit),
      withLatestFrom(this.store.select(RoutesSelectors.selectRoutesDailyInitedState)),
      filter(([ , inited ]) => !inited),
      map(() => RoutesActions.dailyInited())
    )
  );

  dailyItemsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyItemsInit),
      withLatestFrom(this.store.select(RoutesSelectors.selectRoutesDailyItemsState)),
      filter(([ , items ]) => !items),
      map(([ { params } ]) => RoutesActions.dailyLoad({ params }))
    )
  );

  dailyLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyLoad),
      switchMap(({ params }) =>
        this.routesTableService.getRoutesDailyTableData(params)
          .pipe(takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyLoadCancel))))
      ),
      map(data => RoutesActions.dailyLoadSuccess({ items: data.value }))
    )
  );

  dailyLoadRouteNotesState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyLoadSuccess),
      switchMap(({ items }) => from(
        chunk(items?.map(item => item.routeId), routesConfig.extraRoutesDataLoadBuffer)
          .map(routeIdsChunk => this.routeNotesService.getState(routeIdsChunk))
      )),
      concatMap((request: Observable<RouteNotesState[]>) => request),
      map(items => RoutesActions.dailyLoadNotes({ items }))
    )
  );

  dailyLoadActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyLoadSuccess),
      withLatestFrom(this.store.select(RoutesSelectors.selectRoutesDailyParamsState)),
      switchMap(([ { items }, { date } ]) => from(
        chunk(items?.map(item => item.routeId), routesConfig.extraRoutesDataLoadBuffer)
          .map(routeIdsChunk => this.activitiesService.getRouteDailyActivities({ date, routeIds: routeIdsChunk }))
      )),
      concatMap((request: Observable<ActivityRoute[]>) => request),
      map(items => RoutesActions.dailyLoadActivities({ items }))
    )
  );

  dailyLoadDriverApprovals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyLoadSuccess),
      switchMap(({ items } ) => from(
        chunk(items?.filter(item => !!item.driver).map(item => item.rideId), routesConfig.extraRoutesDataLoadBuffer)
          .map(rideIdsChunk => this.rideApprovalsService.getRideApprovals(rideIdsChunk))
      )),
      concatMap((request: Observable<RideApproval[]>) => request),
      map(items => RoutesActions.dailyLoadDriverApprovals({ items }))
    )
  );

  dailyLoadMonitoringData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyLoadSuccess),
      switchMap(({ items } ) => from(
        chunk(items?.map(item => item.rideId), routesConfig.extraRoutesDataLoadBuffer)
          .map(rideIdsChunk => this.routesTableService.getRouteMonitoringData(rideIdsChunk))
      )),
      concatMap((request: Observable<RideMonitoringData[]>) => request),
      map(items => RoutesActions.dailyLoadMonitoringData({ items }))
    )
  );

  dailyHubInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyInited),
      switchMap(() => {
        this.routesTableHubService.init();

        return from(this.routesTableHubService.start())
          .pipe(takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyReset))));
      }),
      map(() => RoutesActions.dailyHubInited())
    )
  );

  dailyHubDestroy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyReset),
      tap(() => {
        if (this.routesTableHubService) {
          this.routesTableHubService.stop();
        }
      })
    ),
  { dispatch: false }
  );

  dailyUpdateRouteNotesAdded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyHubInited),
      switchMap(() =>
        this.routesTableHubService.onRouteNoteAdded()
          .pipe(takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyReset))))
      ),
      withLatestFrom(this.store.select(AuthSelectors.selectAuthStatusDataState)),
      map(([ noteAdded, userInfo ]) => RoutesActions.dailyUpdateRouteNoteAdded({ data: { ...noteAdded, createdByDifferentUser: noteAdded.createdByMemberId !== userInfo.person.memberId } }))
    )
  );

  dailyUpdateRouteNotesUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyHubInited),
      switchMap(() =>
        this.routesTableHubService.onRouteNoteUpdated()
          .pipe(takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyReset))))
      ),
      withLatestFrom(this.store.select(AuthSelectors.selectAuthStatusDataState)),
      map(([ updatedNote, userInfo ]) => RoutesActions.dailyUpdateRouteNoteUpdated({ data: { ...updatedNote, modifiedByDifferentUser: updatedNote.lastModifyByMemberId !== userInfo.person.memberId } }))
    )
  );

  dailyUpdateRouteNotesRemoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyHubInited),
      switchMap(() =>
        this.routesTableHubService.onRouteNoteMarkedAsDone()
          .pipe(takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyReset))))
      ),
      map(removedNote =>  RoutesActions.dailyUpdateRouteNoteRemoved({ data: removedNote }))
    )
  );

  dailyUpdateRideStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyHubInited),
      switchMap(() =>
        this.routesTableHubService.onUpdateRideStatus()
          .pipe(takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyReset))))
      ),
      withLatestFrom(this.store.select(RoutesSelectors.selectRoutesDailyItemsState)),
      filter(([ data, items ]) => !!items && items.some(item => data && item.rideId === data?.id)),
      map(([ data ]) => RoutesActions.dailyUpdateRideStatus({ data }))
    )
  );

  dailyUpdateDriverApproval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyHubInited),
      switchMap(() =>
        this.routesTableHubService.onUpdateApprovals()
          .pipe(takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyReset))))
      ),
      withLatestFrom(this.store.select(RoutesSelectors.selectRoutesDailyItemsState)),
      filter(([ data, items ]) => !!items && items.some(item => item.routeId === data.routeId)),
      map(([ data ]) => RoutesActions.dailyUpdateDriverApprovals({ data }))
    )
  );

  dailyActivitiesUpdateInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyInited),
      switchMap(() =>
        this.activitiesDataService.activitiesUpdate$
          .pipe(
            withLatestFrom(
              this.store.select(RoutesSelectors.selectRoutesDailyItemsState),
              this.store.select(AuthSelectors.selectAuthStatusDataState)
            ),
            map(([ activities, routes, userInfo ]) => {
              const routeIds = routes?.filter(row => !!row.activities).map(row => row.routeId) || [];
              const activitiesUpdate = activities.filter(activity => routeIds.includes(activity.routeId));

              return [ activitiesUpdate, routes, userInfo, this.commonService.showMovePassengers() ];
            }),
            filter(items => items.every(item => !Array.isArray(item) || item.length)),
            map(([ activitiesUpdate, routes, userInfo, showMovePassengers ]: [ ActivityUpdate[], any[], AuthUserInfo, boolean ]) => {
              const saveStatusRouteIds: number[] = [];
              const savedRouteIds: number[] = activitiesUpdate
                .filter(activity => activity.type === ActivityType.RouteSaveSuccess).map(activity => activity.routeId);

              const updatedRoutes = routes.map(route => {
                const routeActivitiesUpdate = activitiesUpdate.filter(activity => activity.routeId === route.routeId);

                if (routeActivitiesUpdate.length) {
                  const saveStatus = this.activitiesDataService.getRouteSaveStatus(route.saveStatus, routeActivitiesUpdate);

                  if (saveStatus) {
                    saveStatusRouteIds.push(route.routeId);
                  }

                  const locked = this.activitiesDataService.isLockedRoute(route.locked, routeActivitiesUpdate);

                  return {
                    saveStatus,
                    routeId: route.routeId,
                    activities: this.activitiesDataService.parseActivities(route.activities, routeActivitiesUpdate),
                    lockState: locked ? route.lockState : null,
                    locked: locked && showMovePassengers ? userInfo.person.memberId !== routeActivitiesUpdate[0].memberId : locked
                  };
                }

                return route;
              });

              this.store.dispatch(RoutesActions.dailyActivitiesUpdateItems({ items: updatedRoutes }));

              return { saveStatusRouteIds, savedRouteIds };
            }),
            filter(data => !!(data.saveStatusRouteIds.length || data.savedRouteIds.length)),
            delay(2000),
            concatMap(({ saveStatusRouteIds, savedRouteIds }) => {
              if (savedRouteIds.length) {
                if (saveStatusRouteIds.length) {
                  this.store.dispatch(RoutesActions.dailyClearItemsSaveStatus({ routeIds: saveStatusRouteIds }));
                }

                return of(savedRouteIds)
                  .pipe(
                    withLatestFrom(
                      this.store.select(RoutesSelectors.selectRoutesDailyParamsState),
                      this.store.select(AuthSelectors.selectAuthStatusDataState)
                    ),
                    concatMap(([ routeIds, params, userInfo ]) =>
                      this.routesTableService.getRoutesDailyTableData({ ...params, routeIds }, true)
                        .pipe(
                          switchMap(daily => {
                            const rideIds = daily.value?.filter(item => !!item.driver).map(item => item.rideId);

                            return rideIds?.length ?
                              this.rideApprovalsService.getRideApprovals(rideIds, true)
                                .pipe(
                                  map(approvals => ({
                                    ...daily,
                                    value: daily.value.map(item => {
                                      const driverApprovals = approvals.find(obj => obj.rideId === item.rideId);

                                      return driverApprovals ? {
                                        ...item,
                                        ...(item.driver && {
                                          driver: {
                                            ...item.driver,
                                            approvals: {
                                              actual: driverApprovals.actualApprovals,
                                              expected: driverApprovals.expectedApprovals,
                                              rejected: driverApprovals.hasRejectedApproval
                                            }
                                          }
                                        })
                                      } : item;
                                    })
                                  }))
                                )
                              : of(daily);
                          }),
                          map(data => this.commonService.showMovePassengers() ? {
                            ...data,
                            value: data.value.map(route => route.locked && route.lockedByUserId === userInfo.person.memberId ? {
                              ...route,
                              locked: false
                            } : route)
                          } : data)
                        )
                    ),
                    filter(data => !!(data.success && data.value)),
                    map(({ value }) => value.length ? RoutesActions.dailyUpdateItems({ items: value }) : RoutesActions.dailyDeleteItems({ routeIds: savedRouteIds }))
                  );
              }

              return of(RoutesActions.dailyClearItemsSaveStatus({ routeIds: saveStatusRouteIds }));
            }),
            takeUntil(this.actions$.pipe(ofType(RoutesActions.dailyReset)))
          )
      )
    )
  );

  dailyItemsReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.dailyInited),
      switchMap(() => this.headerDataService.dateForm.valueChanges),
      filter(() => this.router.url !== `/${NavigationPaths.Rides}`),
      map(() => RoutesActions.dailyItemsReset())
    )
  );

  weeklySelectedFiltersInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.weeklySelectedFiltersInit),
      withLatestFrom(this.store.select(RoutesSelectors.selectRoutesWeeklySelectedFilters)),
      filter(([ , type ]) => !type),
      map(([ { filters } ]) => RoutesActions.weeklySelectedFiltersUpdate({ filters }))
    )
  );

  viewTypeUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoutesActions.viewTypeUpdate),
      map(({ value }) => value === RoutesViewType.Weekly ? RoutesActions.dailyReset() : RoutesActions.weeklyReset())
    )
  );

  constructor(
    private readonly store: Store<any>,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly routeNotesService: RouteNotesService,
    private readonly routesTableService: RoutesTableService,
    private readonly activitiesDataService: ActivitiesDataService,
    private readonly routesTableHubService: RoutesTableHubService,
    private readonly commonService: CommonService,
    private readonly headerDataService: HeaderDataService,
    private readonly activitiesService: ActivitiesService,
    private readonly rideApprovalsService: RideApprovalsService
  ) {}
}
