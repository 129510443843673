import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UMultiselectItem } from '@shift/ulib';

import { MasterCustomerService, TrackingService } from '@app/shared/services';
import { MasterCustomerDataType } from '@app/shared/models';
import {
  AuthModuleBranchesFeature,
  AuthModuleBranchesFeatureType,
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType,
  AuthModuleEducationInstitutionsFeature,
  AuthModuleName,
  AuthModuleRouteBuilderFeature,
  AuthModuleRouteBuilderFeatureFilter,
  AuthModuleTagsFeature
} from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { BuilderDataService, BuilderDataStoreService, BuilderFilterStoreService } from '@app/builder/services';
import {
  BuilderConfig,
  BuilderCustomerData,
  BuilderDataStoreOptions,
  BuilderFilterData,
  BuilderFilterDataType,
  BuilderMode
} from '@app/builder/models';
import { builderFilterComponentConfig } from './builder-filter.component.config';

@Component({
  selector: 'app-builder-filter',
  templateUrl: './builder-filter.component.html',
  styleUrls: [ './builder-filter.component.scss', './builder-filter.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuilderFilterComponent implements OnChanges, OnInit, OnDestroy {
  @Input() customerData: BuilderCustomerData;
  @Input() options: BuilderDataStoreOptions;
  @Input() builderDataConfig: BuilderConfig;
  @Input() isRtl: boolean;
  @Input() masterSubCustomerIds: number[] = [];

  @Output() closeAction: EventEmitter<void> = new EventEmitter();
  @Output() applyAction: EventEmitter<BuilderFilterData> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-filter';

  private unsubscribe: Subject<void> = new Subject();

  filterForm: UntypedFormGroup;
  container = 'body';
  placement = [ 'bottom-right' ];
  showTags: boolean;
  showShifts: boolean;
  showGender: boolean;
  showSchools: boolean;
  showAccompany: boolean;
  showBranches: boolean;
  showEligibleShuttle: boolean;
  hasMasterCustomerFeature: boolean;
  passengersModuleAvailable: boolean;
  branchesType: AuthModuleBranchesFeatureType;
  branchesTypes = AuthModuleBranchesFeatureType;
  departmentsType: AuthModuleDepartmentsFeatureType;
  departmentsTypes = AuthModuleDepartmentsFeatureType;
  builderFilterDataType = BuilderFilterDataType;
  masterCustomerDataType = MasterCustomerDataType;
  lazyLoadItems: {
    [MasterCustomerDataType.Departments]: Observable<UMultiselectItem[]>;
    [MasterCustomerDataType.Tags]: Observable<UMultiselectItem[]>;
    [MasterCustomerDataType.Branches]: Observable<UMultiselectItem[]>;
  };
  cities = this.builderFilterStoreService.citiesBySelectedBranchIds([]);
  config = cloneDeep({
    ...builderFilterComponentConfig,
    dataTypes: builderFilterComponentConfig.dataTypes.filter(item => !item.feature || this.authDataService.checkFeature(item.feature))
  });

  constructor(
    private fb: UntypedFormBuilder,
    private trackingService: TrackingService,
    private authDataService: AuthDataService,
    private masterCustomerService: MasterCustomerService,
    private builderFilterStoreService: BuilderFilterStoreService,
    public builderDataService: BuilderDataService,
    public builderDataStoreService: BuilderDataStoreService
  ) {}

  ngOnInit() {
    this.builderFilterStoreService.getCities();

    this.getAuthModulesFeatures();
    this.initFilterForm();
    this.initPlacement();
    this.updateLazyLoadItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.masterSubCustomerIds && this.masterSubCustomerIds && this.filterForm) {
      this.filterForm.patchValue({
        departmentIds: [],
        tagIds: [],
        branchIds: []
      });

      this.updateLazyLoadItems();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getLazyLoadItems(filterName: MasterCustomerDataType, masterSubCustomerIds: number[]): Observable<UMultiselectItem[]> {
    const params = masterSubCustomerIds.length ? masterSubCustomerIds : this.options.masterSubCustomers.map(item => +item.value);

    return of(filterName)
      .pipe(
        switchMap(name => {
          switch (name) {
            case MasterCustomerDataType.Departments:
              return this.masterCustomerService.getDepartments(params);

            case MasterCustomerDataType.Tags:
              return this.masterCustomerService.getTags(params);

            case MasterCustomerDataType.Branches:
              return this.masterCustomerService.getBranches(params);

            default:
              return of([]);
          }
        }),
        map(items => items.map(item => ({ value: item.id, name: item.name })))
      );
  }

  private updateLazyLoadItems() {
    if (!this.hasMasterCustomerFeature) { return; }

    this.lazyLoadItems = {
      [MasterCustomerDataType.Departments]: this.getLazyLoadItems(MasterCustomerDataType.Departments, this.masterSubCustomerIds),
      [MasterCustomerDataType.Tags]: this.getLazyLoadItems(MasterCustomerDataType.Tags, this.masterSubCustomerIds),
      [MasterCustomerDataType.Branches]: this.getLazyLoadItems(MasterCustomerDataType.Branches, this.masterSubCustomerIds)
    };
  }

  private initFilterForm() {
    this.filterForm = this.fb.group({
      customerId: [ 1 ],
      dataType: [ { value: this.passengersModuleAvailable ? BuilderFilterDataType.Passengers : BuilderFilterDataType.Stations, disabled: this.hasMasterCustomerFeature } ],
      stationsFilterType: [ 1 ],
      assignmentType: [ 0 ],
      eligibleToShuttle: [ null ],
      needAccompany: [ null ],
      gender: [ 0 ],
      schoolIds: [ [] ],
      classTypeIds: [ [] ],
      departmentIds: [ [] ],
      cities: [ [] ],
      shiftIds: [ [] ],
      tagIds: [ [] ],
      branchIds: [ [] ]
    });

    this.filterForm.get('branchIds').valueChanges
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(branchIds => {
        this.cities = this.builderFilterStoreService.citiesBySelectedBranchIds(branchIds);
        this.filterForm.get('cities').patchValue([]);
      });
  }

  private initPlacement() {
    this.placement = this.isRtl ? [ 'bottom-right' ] : [ 'bottom-left' ];
  }

  private getAuthModulesFeatures() {
    this.authDataService.modules$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(modules => {
        const routeBuilderFilters = modules[AuthModuleName.RouteBuilder] && modules[AuthModuleName.RouteBuilder][AuthModuleRouteBuilderFeature.Filters];

        this.showShifts = (routeBuilderFilters || []).includes(AuthModuleRouteBuilderFeatureFilter.Shifts);
        this.showGender = (routeBuilderFilters || []).includes(AuthModuleRouteBuilderFeatureFilter.Gender);
        this.showEligibleShuttle = (routeBuilderFilters || []).includes(AuthModuleRouteBuilderFeatureFilter.EligibleShuttle);
        this.departmentsType = modules[AuthModuleName.Departments] && modules[AuthModuleName.Departments][AuthModuleDepartmentsFeature.Type];
        this.showTags = !!(modules[AuthModuleName.Tags] && modules[AuthModuleName.Tags][AuthModuleTagsFeature.Type]);
        this.branchesType = modules[AuthModuleName.Branches] && modules[AuthModuleName.Branches][AuthModuleBranchesFeature.Type];
        this.showSchools = !!(modules[AuthModuleName.EducationInstitutions] && modules[AuthModuleName.EducationInstitutions][AuthModuleEducationInstitutionsFeature.Type]);
        this.showAccompany = !!modules[AuthModuleName.Accompany];
        this.showBranches = [ AuthModuleBranchesFeatureType.Generic, AuthModuleBranchesFeatureType.Iec, AuthModuleBranchesFeatureType.Base ].includes(modules?.[AuthModuleName.Branches]?.[AuthModuleBranchesFeature.Type]);
        this.hasMasterCustomerFeature = !!(modules[AuthModuleName.RouteBuilder] && modules[AuthModuleName.RouteBuilder][AuthModuleRouteBuilderFeature.MasterCustomer]);
        this.passengersModuleAvailable = !!modules?.[AuthModuleName.Passengers];

        this.initFilterForm();
      });
  }

  apply() {
    this.trackingService.track(
      `[Route ${this.builderDataConfig.mode === BuilderMode.Add ? 'Add' : 'Edit'}, Map tab] - confirmed filtering`
    );

    const filterValue = this.filterForm.getRawValue();
    const addEditForm = this.builderDataService.addEditForm;
    const direction = addEditForm.get('details.direction').value;

    const filterData: BuilderFilterData = {
      direction,
      dataType: filterValue.dataType,
      cities: filterValue.cities
    };

    if (filterValue.dataType === BuilderFilterDataType.Passengers) {
      if (this.showSchools) {
        filterData.schoolIds = filterValue.schoolIds;
        filterData.classTypeIds = filterValue.classTypeIds;
      }

      if (this.showEligibleShuttle) {
        filterData.eligibleToShuttle = filterValue.eligibleToShuttle;
      }

      if (this.showAccompany) {
        filterData.needAccompany = filterValue.needAccompany;
      }

      if (this.showGender) {
        filterData.gender = filterValue.gender;
      }

      if (this.options.masterSubCustomers) {
        filterData.masterSubCustomerIds = this.masterSubCustomerIds.length ?
          this.masterSubCustomerIds : this.options.masterSubCustomers.map(item => +item.value);
      }

      filterData.assignmentType = filterValue.assignmentType;
    }

    if (filterValue.dataType === BuilderFilterDataType.Stations) {
      filterData.stationsFilterType = filterValue.stationsFilterType;
    }

    if (this.showShifts) {
      filterData.shiftIds = filterValue.shiftIds;
    }

    if (this.showTags) {
      filterData.tagIds = filterValue.tagIds;
    }

    if (!!this.branchesType) {
      filterData.branchIds = filterValue.branchIds;
    }

    if (!!this.departmentsType) {
      filterData.departmentIds = filterValue.departmentIds;
    }

    this.applyAction.emit(filterData);
  }
}
