import { GlobalSearchFilter, HashcalRideType } from '@app/shared/models';
import { RouteLockState } from '@app/route-lock-state/models';
import { RideCostType } from '@app/routes/models';
import { BuilderRouteAddEdit } from '@app/builder/models';
import { ShuttleCompanyRideLocationType } from '@app/shuttle-companies/models';

export enum RideOrderStatus {
  WaitForApproval,
  Approved,
  Rejected
}

export enum RideOrdersRowProp {
  StationsList = 'stationsList',
  Locked = 'locked',
  LockState = 'lockState'
}

export enum RideOrdersCustomerDataType {
  OrderStatuses = 'orderStatuses',
  Departments = 'departments',
  MemberDepartments = 'memberDepartments'
}

export interface RideOrdersBody {
  date: string;
  searchText?: string;
  statuses?: RideOrderStatus[];
  requestingDepartmentIds?: number[];
  approvingDepartmentIds?: number[];
}

export interface RideOrder {
  routeId: number;
  orderNumber: number;
  status: RideOrderStatus;
  statusUpdatedDateTime: string;
  approvingDepartment: string;
  requestingDepartment: string;
  orderPurpose: string;
  vehicleType: string;
  vehiclesQuantity: number;
  shuttleCompany: string;
  startDateTime: string;
  endDateTime: string;
  startLocation: string;
  endLocation: string;
  stationsTotal: number;
  supervisor: string;
  approvingComments: string;
  requestingComments: string;
  createDateTime: string;
  readOnly: boolean;
  createdBy: string;
}

export interface RideOrderItem extends RideOrder {
  [RideOrdersRowProp.StationsList]: RideOrderStation[];
  [RideOrdersRowProp.Locked]: boolean;
  [RideOrdersRowProp.LockState]: RouteLockState;
}

export interface RideOrderStation {
  id: number;
  name: string;
  address: string;
}

export interface RideOrdersCustomerDataValue {
  id: number;
  name: string;
}

export interface RideOrdersCustomerData {
  [RideOrdersCustomerDataType.OrderStatuses]: (Omit<RideOrdersCustomerDataValue, 'id'> & { id: RideOrderStatus; })[];
  [RideOrdersCustomerDataType.Departments]: RideOrdersCustomerDataValue[];
  [RideOrdersCustomerDataType.MemberDepartments]: RideOrdersCustomerDataValue[];
}

export interface RideOrdersGeneralBody {
  routeId: number;
}

export interface RideOrdersAssignShuttleCompanyBody extends RideOrdersGeneralBody {
  date: string;
  quantity: number;
  locationType: ShuttleCompanyRideLocationType;
}

export interface RideOrdersChangeShuttleCompanyBody extends RideOrdersGeneralBody {
  shuttleCompanyId: number;
}

export interface RideOrdersChangeShuttleCompanyCostBody extends RideOrdersGeneralBody {
  shuttleCompanyId: number;
  costType: number;
  price: number;
}

export interface RideOrdersChangeShuttleCompanyHashcalRideTypeBody extends RideOrdersGeneralBody {
  shuttleCompanyId: number;
  hashcalRideType: HashcalRideType;
}

export interface RideOrdersSummary {
  shuttleCompanyId: number;
  costType: RideCostType;
  price: number;
  totalPrice: number;
  purchaseOrder: string;
  purchaseOrderRow: string;
  status: RideOrderStatus;
  approvingComments: string;
  contractId: string;
  hashcalRideType: HashcalRideType;
}

export interface RideOrdersGetShuttleCompaniesParams {
  branchId: number;
  date: string;
}

export interface RideOrdersGetShuttleCompanyCostTypesParams {
  contractId: string;
}

export interface RideOrdersShuttleCompanyCostType {
  id: number;
  name: string;
  hashcalRideTypes: {
    id: number;
    name: string;
  }[];
}

export interface GetRidesPurchaseOrdersBody extends RideOrdersGeneralBody {
  period: {
    dateFrom: string;
    dateTo: string;
    days: number[];
  };
}

export interface GetRidesPurchaseOrder {
  number: string;
  rows: GetRidesPurchaseOrderRow[];
}

export interface GetRidesPurchaseOrderRow {
  number: string;
  departmentId: number;
  totalBudget: number;
  remainingBudget: number;
}

export interface RideOrdersChangePurchaseOrderBody extends RideOrdersGeneralBody {
  purchaseOrder: string;
}

export interface RideOrdersChangePurchaseOrderRowBody extends RideOrdersGeneralBody {
  purchaseOrderRow: string;
}

export interface RideOrderStatusUpdated extends RideOrdersGeneralBody {
  status: RideOrderStatus;
}

export interface RideOrdersApprovingCommentsBody extends RideOrdersGeneralBody {
  comments: string;
}

export interface RideOrdersChangeContractBody extends RideOrdersGeneralBody {
  contractId: string;
}

export interface RideOrdersRemoveContractBody extends RideOrdersGeneralBody {
  contractId: string;
}

export interface RideOrderActivitiesUpdateItem {
  routeId: number;
  lockState: RouteLockState;
  locked: boolean;
}

export interface RideOrderAddEdit {
  routeId: number;
  assignScByLocationType: ShuttleCompanyRideLocationType;
  vehiclesQuantity: number;
  route: BuilderRouteAddEdit;
  approvalDetails: RideOrdersSummary;
}

export interface RideOrdersGlobalSearchFilter extends GlobalSearchFilter {
  valueByCustomerDataType?: RideOrdersCustomerDataType;
}
