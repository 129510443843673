<div class="builder-actions__info">
  <div class="builder-actions__dates">
    <div class="builder-actions__dates-arrows">
      <div class="builder-actions__dates-arrow">
        <div class="u-icon u-icon_pointer u-icon_app-builder-arrow-left" (click)="isRtl ? next() : prev()"></div>
      </div>
      <div class="builder-actions__dates-arrow">
        <div class="u-icon u-icon_pointer u-icon_app-builder-arrow-right" (click)="isRtl ? prev() : next()"></div>
      </div>
    </div>
    <div 
      class="builder-actions__list"  
      *ngIf="(config.mode === builderMode.Add && (config.buildMode === builderBuildMode.Routes || config.buildMode === builderBuildMode.RouteSuggestions)) || config.buildMode === builderBuildMode.RouteTemplates"
      #dayActive="uDropdown"
      uDropdown 
      [autoClose]="'outside'"
      [placement]="isRtl ? [ 'bottom-left' ] : ['bottom-right']"
    >
      <div
        uId="builder-actions-day"
        class="builder-actions__dates-day"
        uDropdownToggle
        *ngIf="!builderDataService.isEditableActiveDays"
      >
        {{activeRide.dayOfWeek !== null ? builderDataService.activeDay : null}}
      </div>
      <div
        uId="builder-actions-editable-day"
        class="builder-actions__dates-day"
        *ngIf="builderDataService.isEditableActiveDays"
      >
        {{activeRide.dayOfWeek !== null ? builderDataService.activeDay : null}}
      </div>
      <div class="u-dropdown-menu u-dropdown_app-builder-actions" uDropdownMenu>
        <div class="u-dropdown-content">
          <div class="u-list u-list_app-builder-actions">
            <div 
              class="u-list__item" 
              *ngFor="let day of builderDataService.daysOfWeek"
              [ngClass]="{'u-list__item_disabled': day.disabled}"
              (click)="setActiveDay(day)" 
              uDropdownItem
            >
              {{day.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      uId="builder-actions-active-day"
      class="builder-actions__dates-day builder-actions__dates-day_inactive" 
      *ngIf="config.mode === 'edit' && (config.buildMode === builderBuildMode.Routes || config.buildMode === builderBuildMode.RouteSuggestions)"
    >
      {{builderDataService.activeDay}}
    </div>
    <div
      class="builder-actions__dates-content" 
      *ngIf="config.mode === 'edit' && (config.buildMode === builderBuildMode.Routes || config.buildMode === builderBuildMode.RouteSuggestions)"
    >
      <u-text-date
        [uId]="'builder-actions-date'"
        class="u-text-date_app-builder-actions"
        [sundayFirstDay]="true"
        [classUDropdownContent]="'u-dropdown-content_app-builder-actions-calendar'"
        [(ngModel)]="builderDataService.actionsEditDates.date"
        [daysActive]="builderDataService.actionsEditDates.daysActive"      
        [daysAvailable]="builderDataService.actionsEditDates.daysAvailable"
        [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
        [disabledDays]="builderDataService.disabledDays"
        [disableDaysBefore]="builderDataService.actionsEditDates.disableDaysBefore"
        [disableDaysAfter]="builderDataService.actionsEditDates.disableDaysAfter"
        [messages]="'uTextDate' | translate"
        (ngModelChange)="selectDate(); builderDataService.actionsEditDatesChange($event)"
      ></u-text-date>
    </div>
  </div>
</div>
