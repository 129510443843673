import {
  Input,
  Output,
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EntityModalButton, UInputCitiesCity, UMultiselectItem, USelectSItem } from '@shift/ulib';

import { AddEditModalAction, RoutePolicyTabName } from '@app/shared/models';
import { LocalizationService } from '@app/shared/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { routePolicyTabsConfig } from './route-policy-tabs.component.config';

@Component({
  selector: 'app-route-policy-tabs',
  templateUrl: './route-policy-tabs.component.html',
  styleUrls: [ './route-policy-tabs.component.scss', './route-policy-tabs.component.rtl.scss' ]
})
export class RoutePolicyTabsComponent implements OnInit, OnChanges {
  @Input() small: boolean;
  @Input() form: UntypedFormGroup;
  @Input() isDirty: boolean;
  @Input() submitText: string;
  @Input() carsForm: UntypedFormControl;
  @Input() branchForm: UntypedFormControl;
  @Input() citiesPricingForm: UntypedFormArray;
  @Input() cities: UInputCitiesCity[];
  @Input() citiesCombinationsForm: UntypedFormArray;
  @Input() citiesCombinationsShowGoToSettings: boolean = true;
  @Input() availableCarTypes: UMultiselectItem[];
  @Input() selectedCarTypes: { name: string; id: number; }[];
  @Input() branches: USelectSItem[];

  @Output() tabSelect = new EventEmitter<string>();
  @Output() action = new EventEmitter<AddEditModalAction>();

  routePolicyTabName = RoutePolicyTabName;
  selectedTab: RoutePolicyTabName = RoutePolicyTabName.Policy;
  isRtl: boolean = this.localizationService.isRtl();
  config = cloneDeep(routePolicyTabsConfig);

  constructor(
    private translate: TranslateService,
    private localizationService: LocalizationService,
    private authDataSnapshotService: AuthDataSnapshotService
  ) {}

  ngOnInit() {
    this.checkPermissions();
    this.translateButtons();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDirty) {
      this.config.buttons.forEach(button => button.disabled = !this.isDirty);
    }

    if (changes.submitText) {
      this.config.buttons.find(button => button.action === AddEditModalAction.Submit).name = this.submitText;
    }
  }

  private checkPermissions() {
    if (!this.authDataSnapshotService.policiesAndSettingsManagement()) {
      this.form?.disable();
      this.carsForm?.disable();
      this.branchForm?.disable();
      this.citiesPricingForm?.disable();
      this.citiesCombinationsForm?.disable();
    }
  }

  private translateButtons() {
    this.config.buttons.forEach((button: EntityModalButton) =>
      this.translate.get(button.name).pipe(first())
        .subscribe(translatedName => button.name = translatedName));
  }

  onAction(modalAction: AddEditModalAction) {
    this.action.emit(modalAction);
  }

  selectTab(name: RoutePolicyTabName) {
    this.selectedTab = name;
  }
}
