import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OperationGuidService {
  private guid: string;

  getGuid(): string {
    return this.guid;
  }

  setGuid(guid: string) {
    this.guid = guid;
  }

  removeGuid() {
    this.guid = undefined;
  }
}
