import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'minutesToTime',
  pure: false,
  standalone: true
})
export class MinutesToTimePipe implements PipeTransform {
  transform(value: number | string, timeUnits?: { minutes: string; hours: string; days: string; }): string {
    if (!value) {
      return;
    }

    const duration = moment.duration(value, 'minutes');
    const days = duration.days() ? `${duration.days()} ${timeUnits.days}` : '';
    const hours = duration.hours() ? `${duration.hours()} ${timeUnits.hours}` : '';
    const minutes = duration.minutes() ? `${duration.minutes()} ${timeUnits.minutes}` : '';

    return [ days, hours, minutes ].filter(item => !!item).join(', ');
  }
}
