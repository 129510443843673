import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NavigationPaths } from '@app/shared/models';

@Component({
  selector: 'app-home',
  template: '',
  standalone: true
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.redirection();
  }

  redirection() {
    this.router.navigate([ NavigationPaths.Rides ]);
  }
}
