<u-modal-entity
  class="u-modal-entity_app-emails-shuttle-company"
  [title]="config.dictionary.title | translate"
  [buttons]="buttons"
  [formChanged]="true"
  (action)="checkActions($event)"
>
  <tabset class="u-tabset-pills" type="pills">
    <tab
      id="info"
      heading=""
    >
      <div class="u-modal__body" tabindex="-1">
        <app-emails-shuttle-company-details [emailContentForm]="emailContentForm"></app-emails-shuttle-company-details>
      </div>
    </tab>
  </tabset>
</u-modal-entity>
