import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthGuard } from '@app/auth/guards/auth.guard';
import { AuthUserInfo, AuthOptionalModule } from '@app/auth/models';
import { AuthFacade } from '@app/auth/state/facades';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class AuthBIGuard extends AuthGuard {
  constructor(
    router: Router,
    authFacade: AuthFacade,
    authDataService: AuthDataService
  ) {
    super(router, authFacade, authDataService);
  }

  hasAccess(userInfo: AuthUserInfo): boolean {
    return userInfo?.optionalModules.includes(AuthOptionalModule.BI);
  }
}
