import { Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, first, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, throwError, Subscriber, Subject } from 'rxjs';
import * as moment from 'moment';
import { cloneDeep, isEqual, escape } from 'lodash';
import { UHereMapMarkerConfig, UHereMapPathConfig, UHereMapPathType, UHereMapPathWaypoint, UPopupService } from '@shift/ulib';

import { AuthCustomerType, AuthModulePassengersFeatureType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { OperationGuidService, TrackingService } from '@app/shared/services';
import {
  Errors,
  MovePassengersError,
  MovePassengersInfoRoute,
  MovePassengersMapData,
  MovePassengersRouteColor,
  MovePassengersRouteColorItem,
  MovePassengersRouteMapViewStationType
} from '@app/shared/models';
import {
  RoutesMovePassengersActionData,
  RoutesMovePassengersActionParams,
  RoutesMovePassengersActionParamsStations,
  RoutesMovePassengersForm,
  RoutesMovePassengersRoute,
  RoutesMovePassengersRouteInitData,
  RoutesMovePassengersRouteMapView,
  RoutesMovePassengersRouteMapViewGetParams,
  RoutesMovePassengersRouteMapViewStation,
  RoutesMovePassengersStation,
  RoutesMovePassengersSaveAction,
  RoutesMovePassengersStationBase,
  RoutesMovePassengersChangesOptions,
  RoutesChangeType,
  RoutesMovePassengersFilterType
} from '@app/routes/models';
import { routesMovePassengersConfig } from '@app/routes/configs';
import { movePassengersConfig } from '@app/shared/configs';
import { environment } from '@environments/environment';
import { AppConstants } from '@app/shared/constants';
import { RouteStationsMoveService } from './route-stations-move.service';
import { RouteEditSessionHubService } from './route-edit-session-hub.service';

@Injectable()
export class RoutesMovePassengersDataService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private dataStore: {
    stations: RoutesMovePassengersStation[];
  } = {
      stations: []
    };
  private passengerFeatureType: AuthModulePassengersFeatureType;

  #isLoading: WritableSignal<boolean> = signal(false);

  isLoading = this.#isLoading.asReadonly();
  routes: RoutesMovePassengersRoute[] = [];
  stations: RoutesMovePassengersStation[] = [];
  anonymousPassengers: { [key: number]: { checked: boolean; }; } = {};
  form: UntypedFormGroup;
  changesOptions: RoutesMovePassengersChangesOptions = {
    type: RoutesChangeType.Planned,
    dateFrom: null,
    dateTo: null,
    days: []
  };
  hasChanges: boolean = false;
  singleDaySelected: boolean;
  checkedItemsNumber: { stations: number; passengers: number; } = {
    stations: null,
    passengers: null
  };
  saveActions: RoutesMovePassengersSaveAction[] = [];
  selectedSaveAction: RoutesMovePassengersSaveAction;
  routesColors: MovePassengersRouteColorItem[] = cloneDeep(movePassengersConfig.routesColors);
  infoSourceRoute: MovePassengersInfoRoute;
  infoRoutes: MovePassengersInfoRoute[] = [];
  mapData: MovePassengersMapData = new MovePassengersMapData();
  sourceRoute: RoutesMovePassengersRouteInitData;
  showBackdrop: boolean;

  constructor(
    private uPopupService: UPopupService,
    private routeStationsMoveService: RouteStationsMoveService,
    private operationGuidService: OperationGuidService,
    private formBuilder: UntypedFormBuilder,
    private trackingService: TrackingService,
    private routeEditSessionHubService: RouteEditSessionHubService,
    private authDataService: AuthDataService
  ) {
    this.initForm(routesMovePassengersConfig.defaultForm);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getCurrentFilterControl(): FormControl {
    return this.form.get(this.getCurrentFilterControlName()) as FormControl;
  }

  private getCurrentFilterControlName(): string {
    return this.form.get('typeFilter').value === RoutesMovePassengersFilterType.EducationalInstitution ? 'schoolIds' : 'branchIds';
  }

  private updateChangesOptionsAndRoutes(key: string, value: string) {
    this.changesOptions = {
      ...this.changesOptions,
      [key]: value
    };

    this.updateRoutesAndStations();
  }

  checkUpdateRoutesAndStationsAvailability(): boolean {
    const typeChange = this.form.get('typeChange').value;
    const dates = this.form.get('datesChange.dates').value;

    if (routesMovePassengersConfig.mandatoryFilterFeatureTypes.includes(this.passengerFeatureType)) {
      const currentFilterControl = this.getCurrentFilterControl();

      return typeChange && dates?.length && currentFilterControl?.value?.length;
    }

    return typeChange && dates?.length;
  }

  private updateForm(schoolIds: number[]) {
    this.form.setControl('schoolIds', this.formBuilder.control(schoolIds, {
      updateOn: 'blur',
      validators: [ Validators.required ]
    }));

    this.form.get('typeFilter').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.form.get('branchIds').patchValue([], { emitEvent: false });
        this.form.get('schoolIds').patchValue([], { emitEvent: false });
      });

    this.form.get('schoolIds').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value =>
        this.checkUpdateRoutesAndStationsAvailability() &&
        this.updateChangesOptionsAndRoutes('schoolIds', value)
      );
  }

  updateIsLoading(value: boolean) {
    this.#isLoading.set(value);
  }

  updatePassengerFeatureType(type: AuthModulePassengersFeatureType) {
    this.passengerFeatureType = type;
  }

  initSaveButton(type: AuthCustomerType) {
    const { mainAction } = routesMovePassengersConfig.saveButton;

    this.saveActions = routesMovePassengersConfig.saveButton.actions;
    this.selectedSaveAction = mainAction[type] || mainAction.default;
  }

  resetSingleDaySelected() {
    this.singleDaySelected = null;
  }

  initForm(data: RoutesMovePassengersForm) {
    this.changesOptions = {
      type: data.typeChange,
      dateFrom: data.dates[0],
      dateTo: data.dates[data.dates.length - 1],
      days: data.checkDaysActive,
      branchIds: data.branchIds
    };

    this.form = this.generateForm(data);

    if (this.passengerFeatureType === AuthModulePassengersFeatureType.Student) {
      this.updateForm(data.schoolIds);
    }

    this.form.get('typeChange').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.updateShowBackdrop(false);
        this.trackAction('select change type');

        if (this.checkUpdateRoutesAndStationsAvailability()) {
          this.updateChangesOptionsAndRoutes('type', value);
        }
      });

    this.form.get('branchIds').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value =>
        this.checkUpdateRoutesAndStationsAvailability() &&
          this.updateChangesOptionsAndRoutes('branchIds', value)
      );
  }

  generateForm(data: RoutesMovePassengersForm): UntypedFormGroup {
    return this.formBuilder.group({
      typeChange: [ data.typeChange, Validators.required ],
      datesChange: this.formBuilder.group({
        dates: [ data.dates, Validators.required ],
        dateFrom: [ data.dateFrom ],
        dateTo: [ data.dateTo ],
        type: [ data.type ],
        availablePresets: [ data.availablePresets ],
        checkDaysActive: [ data.checkDaysActive ],
        checkDaysAvailable: [ data.checkDaysAvailable ]
      }),
      typeFilter: [ data.typeFilter ],
      branchIds: [ data.branchIds, {
        updateOn: 'blur',
        validators: [
          ...(routesMovePassengersConfig.mandatoryFilterFeatureTypes.includes(this.passengerFeatureType) ? [ Validators.required ] : [])
        ]
      } ]
    });
  }

  private getDaysBetweenDates(dates: string[]): number {
    if (!dates || !dates.length) { return null; }

    if (dates && dates.length <= 1) { return 0; }

    return moment(dates[dates.length - 1]).diff(moment(dates[0]), 'days');
  }

  updatePeriod(data) {
    this.updateShowBackdrop(false);
    this.trackAction('select change period');

    this.form.get('datesChange').patchValue({
      dates: data.dates,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });

    const currentFilterName = this.getCurrentFilterControlName();

    this.changesOptions = {
      type: this.form.value.typeChange,
      dateFrom: data.dates[0],
      dateTo: data.dates[data.dates.length - 1],
      days: data.checkDaysActive,
      [currentFilterName]: this.form.value[currentFilterName]
    };

    if (this.checkUpdateRoutesAndStationsAvailability()) {
      this.updateRoutesAndStations();
    }
  }

  private updateCheckedItemsNumber() {
    let stationsNumber: number = 0;
    let passengersNumber: number = 0;

    this.stations.forEach(station => {
      stationsNumber += station.checked ? 1 : 0;
      passengersNumber += station.passengers.filter(passenger => passenger.checked).length;
    });

    this.checkedItemsNumber = {
      stations: stationsNumber,
      passengers: passengersNumber
    };
  }

  toggleStationCheckbox(index: number) {
    const currentStation = this.stations[index];

    if (!currentStation) { return; }

    const isStationChecked = currentStation.checked;
    const anonymousPassenger = this.anonymousPassengers[currentStation.rideStationId];

    currentStation.passengers = currentStation.passengers.map(passenger => ({
      ...passenger,
      checked: isStationChecked
    }));

    if (anonymousPassenger) {
      anonymousPassenger.checked = isStationChecked;
    }

    this.updateCheckedItemsNumber();
  }

  togglePassengerCheckbox(stationIndex: number, passengerIndex: number) {
    const currentStation = this.stations[stationIndex];

    if (!currentStation) { return; }

    const currentPassenger = this.stations[stationIndex].passengers[passengerIndex];

    if (!currentPassenger) { return; }

    const areAllPassengersChecked = currentStation.passengers.every(passenger => passenger.checked);
    const anonymousPassenger = this.anonymousPassengers[currentStation.rideStationId];

    if (areAllPassengersChecked !== currentStation.checked && !anonymousPassenger) {
      currentStation.checked = areAllPassengersChecked;
    }

    if (anonymousPassenger && !areAllPassengersChecked && currentStation.checked) {
      anonymousPassenger.checked = areAllPassengersChecked;
      currentStation.checked = areAllPassengersChecked;
    }

    this.updateCheckedItemsNumber();
  }

  toggleAnonymousPassengerCheckbox(stationIndex: number) {
    const currentStation = this.stations[stationIndex];

    if (!currentStation) { return; }

    const anonymousPassenger = this.anonymousPassengers[currentStation.rideStationId];

    if (!anonymousPassenger) { return; }

    currentStation.checked = anonymousPassenger.checked;

    if (currentStation.checked) {
      currentStation.passengers = currentStation.passengers.map(passenger => ({
        ...passenger,
        checked: anonymousPassenger.checked
      }));
    }

    this.updateCheckedItemsNumber();
  }

  private disableFormControls() {
    this.form.get('typeChange').disable({ emitEvent: false });
    this.form.get('datesChange.dates').disable({ emitEvent: false });
    this.form.get('typeFilter').disable({ emitEvent: false });
    this.form.get('branchIds').disable({ emitEvent: false });

    if (this.form.get('schoolIds')) {
      this.form.get('schoolIds').disable({ emitEvent: false });
    }
  }

  private getMarkersStations(stations: RoutesMovePassengersRouteMapViewStation[], routeId: number, routeColor?: MovePassengersRouteColor): UHereMapMarkerConfig[] {
    if (!stations || !stations.length) { return []; }

    let markerStationNumber = 0;
    let markerDestinationNumber = 0;

    const destinations = stations.filter(station => station.type === MovePassengersRouteMapViewStationType.Target);

    let labelContent: number = null;

    return stations.map(station => {
      switch (station.type) {
        case MovePassengersRouteMapViewStationType.Station: {
          markerStationNumber += 1;
          labelContent = markerStationNumber;

          break;
        }

        case MovePassengersRouteMapViewStationType.Target: {
          markerDestinationNumber += 1;
          labelContent = markerDestinationNumber;

          break;
        }
      }

      const userInfo = this.authDataService.userInfo();
      const map = movePassengersConfig.map;
      const currentMarker = map.markers[station.type];
      const currentMarkerDefaultByCustomerType = currentMarker.default[userInfo.customer.type] || currentMarker.default.default;
      const currentMarkerDefaultByEnvType = currentMarkerDefaultByCustomerType[environment.config.environmentType] || currentMarkerDefaultByCustomerType.base;
      const currentMarkerIcon = currentMarker?.[routeColor] || currentMarkerDefaultByEnvType;

      return {
        id: `${routeId}-${station.rideStationId}`,
        label: (station.type === MovePassengersRouteMapViewStationType.Target && destinations.length <= 1) ||
          station.type === MovePassengersRouteMapViewStationType.Accompany ? null :
          {
            content: station.type === MovePassengersRouteMapViewStationType.Target ? '' : labelContent,
            style: currentMarkerIcon.label.style
          },
        content: escape(station.address),
        position: {
          lat: station.latitude,
          lng: station.longitude
        },
        icon: currentMarkerIcon.icon,
        draggable: false,
        zIndex: routeColor ? map.zIndex.commonRoute : map.zIndex.sourceRoute
      };
    });
  }

  private updateMapDataMarkerCenterConfigAndBounds() {
    this.mapData.markerCenter.config = {
      ...this.mapData.markerCenter.config,
      bounds: {
        points: this.mapData.markers.stations.map(station => station.position)
      }
    };

    this.mapData.bounds = {
      points: this.mapData.markers.stations.map(station => station.position)
    };
  }

  private setMarkersStations(markersStations: UHereMapMarkerConfig[]) {
    if (isEqual(this.mapData.markers.stations, markersStations)) { return; }

    this.mapData.markers.stations = [ ...this.mapData.markers.stations, ...markersStations ];

    this.updateMapDataMarkerCenterConfigAndBounds();
  }

  private setMapDataPosition(markersStations: UHereMapMarkerConfig[]) {
    this.mapData.fitCenter.position = markersStations.length ? null : {
      lat: this.mapData.coords.lat,
      lng: this.mapData.coords.lng
    };
  }

  private getFirstAccompanyWaypoints(
    stations: RoutesMovePassengersRouteMapViewStation[],
    firstStation: RoutesMovePassengersRouteMapViewStation,
    secondStation: RoutesMovePassengersRouteMapViewStation
  ): UHereMapPathWaypoint[] {
    if (
      stations && stations.length > 1 && firstStation && firstStation.type === MovePassengersRouteMapViewStationType.Accompany &&
      firstStation.latitude && firstStation.longitude && secondStation
    ) {
      return [
        {
          lat: firstStation.latitude,
          lng: firstStation.longitude,
          stopover: false
        },
        {
          lat: secondStation.latitude,
          lng: secondStation.longitude,
          stopover: false
        }
      ];
    }
  }

  private getLastAccompanyWaypoints(
    stations: RoutesMovePassengersRouteMapViewStation[],
    penultStation: RoutesMovePassengersRouteMapViewStation,
    lastStation: RoutesMovePassengersRouteMapViewStation
  ): UHereMapPathWaypoint[] {
    if (
      stations && stations.length > 1 && penultStation && lastStation && lastStation.type === MovePassengersRouteMapViewStationType.Accompany &&
      lastStation.latitude && lastStation.longitude
    ) {
      return [
        {
          lat: penultStation.latitude,
          lng: penultStation.longitude,
          stopover: false
        },
        {
          lat: lastStation.latitude,
          lng: lastStation.longitude,
          stopover: false
        }
      ];
    }
  }

  private generateMainPathConfig(mapView: RoutesMovePassengersRouteMapView, routeColor?: string): UHereMapPathConfig[] {
    const mainWaypoints = mapView.waypoints
      .map(waypoint => ({
        lat: waypoint.latitude,
        lng: waypoint.longitude,
        stopover: waypoint.stopover
      }));

    if (!mainWaypoints.length) { return []; }

    const { encodedPolylineJson } = mapView;

    const map = movePassengersConfig.map;
    const zIndex = routeColor ? map.zIndex.commonRoute : map.zIndex.sourceRoute;
    const color = routeColor || map.path.colors.main;
    const lineWidth = 3;

    if (encodedPolylineJson) {
      return [
        {
          id: `${mapView.routeId}`,
          type: UHereMapPathType.Polylines,
          color,
          waypoints: mainWaypoints,
          polylines: JSON.parse(mapView.encodedPolylineJson),
          zIndex,
          lineWidth
        }
      ];
    }

    return [
      {
        id: `${mapView.routeId}`,
        type: UHereMapPathType.Waypoints,
        color,
        waypoints: mainWaypoints,
        zIndex,
        lineWidth
      }
    ];
  }

  private generateAccompanyPathConfig(
    mapView: RoutesMovePassengersRouteMapView,
    stations: RoutesMovePassengersRouteMapViewStation[]
  ): UHereMapPathConfig[] {
    const firstAccompanyWaypoints = this.getFirstAccompanyWaypoints(stations, stations[0], stations[1]);
    const lastAccompanyWaypoints = this.getLastAccompanyWaypoints(stations, stations[stations.length - 2], stations[stations.length - 1]);

    if ((!firstAccompanyWaypoints || !firstAccompanyWaypoints.length) && (!lastAccompanyWaypoints || !lastAccompanyWaypoints.length)) {
      return [];
    }

    const map = movePassengersConfig.map;

    const firstAccompanyPathConfig = {
      id: `lastAccompany-${mapView.routeId}`,
      type: UHereMapPathType.Waypoints,
      color: map.path.colors.accompany,
      waypoints: lastAccompanyWaypoints
    };

    const lastAccompanyPathConfig = {
      id: `lastAccompany-${mapView.routeId}`,
      type: UHereMapPathType.Waypoints,
      color: map.path.colors.accompany,
      waypoints: lastAccompanyWaypoints
    };

    if (firstAccompanyWaypoints && firstAccompanyWaypoints.length && lastAccompanyWaypoints && lastAccompanyWaypoints.length) {
      return [ firstAccompanyPathConfig, lastAccompanyPathConfig ];
    }

    if (firstAccompanyWaypoints && firstAccompanyWaypoints.length) {
      return [ firstAccompanyPathConfig ];
    }

    return [ lastAccompanyPathConfig ];
  }

  private setWaypointsAndPaths(
    data: RoutesMovePassengersRouteMapView,
    stations: RoutesMovePassengersRouteMapViewStation[],
    routeColor?: string
  ) {
    const paths: UHereMapPathConfig[] = [
      ...this.generateMainPathConfig(data, routeColor),
      ...this.generateAccompanyPathConfig(data, stations)
    ];

    if (!isEqual(this.mapData.paths, paths)) {
      this.mapData.paths = [ ...this.mapData.paths, ...paths ];
    }
  }

  private setMapData(data: RoutesMovePassengersRouteMapView) {
    const stations = data.stations.filter(station => station.latitude && station.longitude);
    const route = this.routes.find(obj => obj.routeId === data.routeId);
    const routeColor = route && this.routesColors.find(obj => obj.name === route.color);

    const markersStations: UHereMapMarkerConfig[] = this.getMarkersStations(stations, data.routeId, routeColor && routeColor.name);

    this.setMarkersStations(markersStations);
    this.setMapDataPosition(markersStations);
    this.setWaypointsAndPaths(data, stations, routeColor && routeColor.value);
  }

  resetMapData() {
    this.mapData = new MovePassengersMapData();
    this.infoSourceRoute = null;
    this.infoRoutes = [];
  }

  private updateMapDataMarkersStations(mapViewData: RoutesMovePassengersRouteMapView, routeColorName: MovePassengersRouteColor) {
    const markersStations = this.getMarkersStations(mapViewData.stations, mapViewData.routeId, routeColorName);

    this.mapData.markers.stations = this.mapData.markers.stations.filter(station => {
      if ((station.id as string).startsWith(`${mapViewData.routeId}`)) {
        return markersStations.some(markerStation => markerStation.id === station.id);
      }

      return !!station;
    });

    this.mapData.markers.stations = this.mapData.markers.stations.map(station =>
      markersStations.find(currentStation => currentStation.id === station.id) || station
    );

    this.mapData.markers.stations = [
      ...this.mapData.markers.stations,
      ...markersStations.filter(markerStation => !this.mapData.markers.stations.some(station => markerStation.id === station.id))
    ];

    this.updateMapDataMarkerCenterConfigAndBounds();
    this.setMapDataPosition(markersStations);
  }

  private updateMapDataPath(mapViewData: RoutesMovePassengersRouteMapView, currentPath: UHereMapPathConfig) {
    this.mapData.paths = this.mapData.paths.map(path => path.id === currentPath.id ? {
      ...path,
      waypoints: mapViewData.stations.map(station => ({
        lat: station.latitude,
        lng: station.longitude,
        stopover: false
      })),
      polylines: JSON.parse(mapViewData.encodedPolylineJson)
    } : path);
  }

  private updateMapData(mapViewData: RoutesMovePassengersRouteMapView, currentPath: UHereMapPathConfig, routeColor: MovePassengersRouteColor) {
    this.updateMapDataMarkersStations(mapViewData, routeColor);
    this.updateMapDataPath(mapViewData, currentPath);
  }

  private updateInfoSourceRoute(routeMapViewData: RoutesMovePassengersRouteMapView) {
    this.infoSourceRoute = {
      routeId: this.sourceRoute.routeId,
      name: this.sourceRoute.name,
      code: this.sourceRoute.code,
      distance: routeMapViewData.distance,
      duration: moment.duration(routeMapViewData.duration).format(AppConstants.TIME_FORMAT_FULL)
    };
  }

  private updateInfoRoutes(mapViewData: RoutesMovePassengersRouteMapView, routeId: number) {
    const route = this.routes.find(obj => obj.routeId === routeId);
    const isInfoRouteExist = this.infoRoutes.some(obj => obj.routeId === route.routeId);
    const areInfoRoutesFull = this.infoRoutes.length === this.routesColors.length;

    if (!isInfoRouteExist && areInfoRoutesFull) { return; }

    const infoRoute = {
      routeId: route.routeId,
      name: route.name,
      code: route.number,
      distance: mapViewData.distance,
      duration: moment.duration(mapViewData.duration).format(AppConstants.TIME_FORMAT_FULL),
      color: route.color
    };

    this.infoRoutes = isInfoRouteExist ?
      this.infoRoutes.map(obj => obj.routeId === route.routeId ? infoRoute : obj) :
      [ ...this.infoRoutes, infoRoute ];
  }

  private getMapViewParams(routes: { routeId: number; rideId: number; }[]): RoutesMovePassengersRouteMapViewGetParams {
    return { routes };
  }

  updateRoutesAndStations() {
    this.updateIsLoading(true);
    this.disableFormControls();
    this.resetRoutesColors();
    this.resetMapData();

    const currentFilterName = this.getCurrentFilterControlName();

    this.routeStationsMoveService.getRoutes({
      dateFrom: this.changesOptions.dateFrom,
      dateTo: this.changesOptions.dateTo,
      days: this.changesOptions.days,
      [currentFilterName]: this.changesOptions[currentFilterName]
    })
      .pipe(
        finalize(() => this.updateIsLoading(false)),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        data => {
          const { stations } = data;
          const [ station ] = stations;

          if (station) {
            this.loadMap(
              this.getMapViewParams([ { routeId: this.sourceRoute.routeId, rideId: station.rideId } ])
            );
          }

          this.routes = data.routes.map(ob => new RoutesMovePassengersRoute(ob));

          this.singleDaySelected = this.getDaysBetweenDates(this.form.get('datesChange.dates').value) === 0;

          this.updateStations(stations);
        },
        () => {
          this.updateShowBackdrop(false);
          this.resetForm({
            dateFrom: this.sourceRoute.routeStartDate,
            dateTo: this.sourceRoute.routeEndDate,
            checkDaysActive: [ this.sourceRoute.activeDay ],
            checkDaysAvailable: this.sourceRoute.days
          });
        }
      );
  }

  private resetRoutesColors() {
    this.routesColors = this.routesColors.map(color => ({ ...color, available: true }));
  }

  private toggleRouteColor(currentColor: MovePassengersRouteColor, routeId: number, available: boolean) {
    this.routesColors = this.routesColors.map(color => currentColor && color.name === currentColor ? {
      ...color,
      available
    } : color);

    this.routes = this.routes.map(route => route.routeId === routeId ? {
      ...route,
      color: available ? null : currentColor
    } : route);
  }

  updateRouteColor(routeId: number) {
    const selectedRoute = this.routes.find(route => route.routeId === routeId);

    if (!selectedRoute) { return; }

    if (selectedRoute.color) {
      this.removeRouteFromPathsByRouteId(routeId);
      this.removeRouteFromInfoRoutes(routeId);
      this.toggleRouteColor(selectedRoute.color, selectedRoute.routeId, true);

      return;
    }

    const availableColor = this.routesColors.find(color => color.available);

    this.toggleRouteColor(availableColor && availableColor.name || null, selectedRoute.routeId, false);

    this.loadMap(this.getMapViewParams([ { routeId: selectedRoute.routeId, rideId: selectedRoute.rideId } ]), selectedRoute.routeId);
  }

  private getRouteColorByValue(value: string) {
    return this.routesColors.find(color => color.value === value);
  }

  private getPathById(routeId: number) {
    return this.mapData.paths.find(path => +path.id === routeId);
  }

  private removeRouteFromPathsByRouteId(id: number) {
    this.mapData.paths = this.mapData.paths.filter(path => +path.id !== id && ![ `firstAccompany-${id}`, `lastAccompany-${id}` ].includes(path.id));
    this.mapData.markers.stations = this.mapData.markers.stations.filter(station => !(station.id as string).startsWith(`${id}`));
  }

  private removeRouteFromInfoRoutes(routeId: number) {
    this.infoRoutes = this.infoRoutes.filter(route => route.routeId !== routeId);
  }

  private loadMap(params: RoutesMovePassengersRouteMapViewGetParams, routeId?: number, updateMap?: boolean) {
    return this.routeStationsMoveService.getRouteMapViewData(params)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        data.forEach(mapViewData => {
          const isSourceRoute = mapViewData.routeId === this.sourceRoute.routeId;

          if (isSourceRoute) {
            this.updateInfoSourceRoute(mapViewData);
          }

          if (routeId && !isSourceRoute) {
            this.updateInfoRoutes(mapViewData, routeId);
          }

          if (updateMap) {
            const currentPath = this.getPathById(mapViewData.routeId);

            if (currentPath) {
              const routeColor = this.getRouteColorByValue(currentPath.color);

              this.updateMapData(
                mapViewData,
                currentPath,
                routeColor && routeColor.name
              );
            }

            return;
          }

          this.setMapData(mapViewData);
        });
      });
  }

  async initSession(guid: string) {
    this.operationGuidService.setGuid(guid);

    await this.startRouteEditSession(guid);
  }

  removeSession(): void {
    this.operationGuidService.removeGuid();
  }

  private updateStations(stations: RoutesMovePassengersStationBase[]) {
    this.stations = stations.map(station => {
      if (station.anonymousPassengersCount && this.singleDaySelected) {
        this.anonymousPassengers = {
          ...this.anonymousPassengers,
          [station.rideStationId]: { checked: false }
        };
      }

      return {
        ...station,
        isDragging: false,
        checked: false,
        passengers: station.passengers.map(passenger => ({
          ...passenger,
          isDragging: false,
          checked: false
        }))
      };
    });

    this.dataStore.stations = this.stations;
  }

  removeStationById(stationId: number): void {
    this.dataStore.stations = this.stations;
    this.stations = this.stations.filter(station => station.rideStationId !== stationId);
  }

  removePassengerById(passengerId: number, stationId: number) {
    this.dataStore.stations = this.stations;

    const currentStation = this.stations.find(station => station.rideStationId === stationId);

    if (currentStation) {
      currentStation.passengers = currentStation.passengers.filter(passenger => passenger.passengerId !== passengerId);
    }
  }

  removeStationsAndPassengersByIds(draggedStations: RoutesMovePassengersStation[]) {
    this.dataStore.stations = this.stations;

    this.stations = this.stations
      .filter(station => !station.checked)
      .map(station => {
        const draggedStation = draggedStations.find(currentDraggedStation =>
          station.rideStationId === currentDraggedStation.rideStationId
        );

        const checkedPassengerIds = draggedStation && draggedStation.passengers
          .filter(passenger => passenger.checked)
          .map(passenger => passenger.passengerId);

        return {
          ...station,
          passengers: station.passengers ||
            (draggedStation && station.passengers.filter(passenger => !checkedPassengerIds.includes(passenger.passengerId)))
        };
      });
  }

  revertAllStations(): void {
    this.stations = this.dataStore.stations;
  }

  updateRouteById = (routeId: number, route: object): void => {
    this.routes = this.routes.map(ob => ob.routeId === routeId ? {
      ...ob,
      ...route
    } : ob);
  };

  updateMoveToRouteData(data: RoutesMovePassengersActionData, targetRoute: RoutesMovePassengersRoute) {
    const { updatedRoute, stations } = data;
    const { routeId, rideId } = updatedRoute;

    this.updateRouteById(routeId, updatedRoute);
    this.updateStations(stations);

    const [ station ] = stations;

    if (!station) {
      this.removeRouteFromPathsByRouteId(this.sourceRoute.routeId);

      if (!targetRoute.color) { return; }
    }

    const currentTargetRoute = { routeId, rideId };

    this.loadMap(
      this.getMapViewParams(station ? [ currentTargetRoute, { routeId: this.sourceRoute.routeId, rideId: station.rideId } ] : [ currentTargetRoute ]),
      routeId,
      true
    );
  }

  moveToRoute(currentStations: RoutesMovePassengersActionParamsStations[], sourceRouteId: number, targetRoute: RoutesMovePassengersRoute) {
    if (!currentStations || !currentStations.length) { return; }

    this.updateIsLoading(true);

    const params: RoutesMovePassengersActionParams = {
      routeId: targetRoute && targetRoute.routeId,
      stations: currentStations,
      type: this.changesOptions.type,
      dateFrom: this.changesOptions.dateFrom,
      dateTo: this.changesOptions.dateTo,
      days: this.changesOptions.days,
      allowCapacityChange: false
    };

    this.routeStationsMoveService
      .move(params, [ Errors.OverloadedCarType ])
      .pipe(
        first(),
        tap(() => this.hasChanges = true),
        finalize(() => this.updateIsLoading(false))
      )
      .subscribe(
        data => this.updateMoveToRouteData(data, targetRoute),
        err => {
          if (err.code === Errors.OverloadedCarType) {
            this.moveToRouteConfirmCarChange(
              {
                ...params,
                allowCapacityChange: true
              },
              targetRoute
            );
          } else {
            this.revertAllStations();
          }
        }
      );
  }

  moveToRouteConfirmCarChange(params: RoutesMovePassengersActionParams, targetRoute: RoutesMovePassengersRoute) {
    this.confirmCarChange()
      .pipe(
        first(),
        switchMap((valid: boolean) => valid ?
          this.routeStationsMoveService.move(params) :
          throwError(new Error(MovePassengersError.CancelMovePassengers))
        ),
        tap(() => this.hasChanges = true)
      )
      .subscribe(
        data => this.updateMoveToRouteData(data, targetRoute),
        err => {
          this.revertAllStations();

          if (err.message !== MovePassengersError.CancelMovePassengers) {
            this.movePassengersError();
          }
        }
      );
  }

  private confirmCarChange(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.uPopupService.showMessage({
        message: 'routes.movePassengers.confirmCarChange',
        yes: 'general.yes',
        no: 'general.no'
      },
      () => {
        subscriber.next(true);
        subscriber.complete();
      },
      () => {
        subscriber.next(false);
        subscriber.complete();
      },
      () => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  private movePassengersError(): void {
    this.uPopupService.showMessage({
      message: 'routes.movePassengers.movePassengersError',
      yes: 'general.confirm'
    }, null);
  }

  trackAction(name: string): void {
    this.trackingService.track(`${routesMovePassengersConfig.trackingId} - ${name}`);
  }

  async startRouteEditSession(guid: string) {
    await this.routeEditSessionHubService.startRouteEditSession(guid);
  }

  async stopRouteEditSession() {
    await this.routeEditSessionHubService.stopRouteEditSession();
  }

  private clearStationsAndRoutes() {
    this.updateIsLoading(true);

    this.stations = [];
    this.routes = [];
  }

  resetForm(opts: { dateFrom: string; dateTo: string; checkDaysActive: number[]; checkDaysAvailable: number[]; }) {
    const schoolIds = this.form.get('schoolIds');
    const branchIds = this.form.get('branchIds');

    this.form.patchValue({
      typeChange: null,
      branchIds: [],
      datesChange: {
        dates: [],
        dateFrom: opts.dateFrom,
        dateTo: opts.dateTo,
        type: '',
        availablePresets: routesMovePassengersConfig.defaultForm.availablePresets,
        checkDaysActive: opts.checkDaysActive,
        checkDaysAvailable: opts.checkDaysAvailable
      }
    }, { emitEvent: false });

    this.form.get('typeChange').enable({ emitEvent: false });
    this.form.get('datesChange.dates').enable({ emitEvent: false });
    this.form.get('typeFilter').enable({ emitEvent: false });
    branchIds.enable({ emitEvent: false });

    if (schoolIds) {
      schoolIds.patchValue([], { emitEvent: false });
      schoolIds.enable({ emitEvent: false });
    }

    this.clearStationsAndRoutes();
  }

  showResetChangesConfirmationPopup(
    popupOpts: { showXIcon: boolean; message: string; yes: string; no: string; },
    formOpts: { dateFrom: string; dateTo: string; checkDaysActive: number[]; checkDaysAvailable: number[]; },
    okCallback?: Function
  ) {
    if (this.isLoading()) { return; }

    this.uPopupService.showMessage(
      {
        showXIcon: popupOpts.showXIcon,
        message: popupOpts.message,
        yes: popupOpts.yes,
        no: popupOpts.no
      },
      () => {
        this.resetForm(formOpts);

        if (okCallback && okCallback instanceof Function) {
          okCallback();
        }
      }
    );
  }

  updateStationsAndPassengersDraggingState(isDragging: boolean) {
    this.stations.forEach(station => {
      station.isDragging = isDragging && station.checked;

      station.passengers.forEach(passenger => passenger.isDragging = isDragging && passenger.checked);
    });
  }

  updateShowBackdrop(value: boolean) {
    this.showBackdrop = value;
  }
}
