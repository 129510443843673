import { inject, Injectable } from '@angular/core';

import { BuilderPopupComponent } from '@app/builder/components/builder-popup/builder-popup.component';
import { BuilderModalService } from './builder-modal.service';

@Injectable()
export class BuilderPopupService {
  private readonly builderModalService = inject(BuilderModalService);

  showMessage(options: Object, okCallback: any, cancelCallback: any = null, placementRef: any = null) {
    this.builderModalService.show(
      BuilderPopupComponent,
      {
        class: 'u-modal u-modal_app-builder-popup',
        animated: true,
        initialState: {
          options: options
        }
      },
      placementRef
    )
      .content
      .action
      .subscribe(({ result }) => {
        if (result === 'ok' && okCallback) {
          okCallback();
        }

        if (result === 'cancel' && cancelCallback) {
          cancelCallback();
        }
      });
  }
}
