import { Component, OnInit, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalActions } from '@app/shared/models';

@Component({
  selector: 'app-builder-ask',
  templateUrl: './builder-ask.component.html',
  styleUrls: [ './builder-ask.component.scss', './builder-ask.component.rtl.scss' ]
})
export class BuilderAskComponent implements OnInit {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-ask';

  message: string = '';
  checkboxes: any[] = [];
  checkboxesStore: any = [];
  dependencies: any = [];

  constructor(
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.checkboxesStore = this.checkboxes.map((ob: any) => ({ ...ob }));
  }

  show(item: any) {
    const dependency = this.dependencies.find((ob: any) => ob.source === item.value);

    if (dependency) {
      return this.checkboxesStore.some((ob: any) => ob.value === dependency.from && ob.check);
    }

    return true;
  }

  close() {
    this.bsModalRef.hide();
    this.action.emit({ type: ModalActions.Close, content: { checkboxes: this.checkboxes } });
  }

  apply() {
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { checkboxes: this.checkboxesStore } });
  }
}
