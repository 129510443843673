import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

import { AddEditModalField } from '@app/shared/models';

@Pipe({
  name: 'addEditModalFieldsCheckConditions',
  standalone: true
})
export class AddEditModalFieldsCheckConditionsPipe implements PipeTransform {
  transform(fields: AddEditModalField[], value): AddEditModalField[] {
    if (fields) {
      return fields.filter(field => !field.conditions || field.conditions.show?.values.includes(get(value, field.conditions?.show.fieldName)));
    }

    return [];
  }
}
