import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiService } from '@app/shared/services';
import { UserBranch, UserCustomer, UserDepartment } from '@app/user/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private BASE_PATH: string = 'User';

  constructor (
    private apiService: ApiService
  ) {}

  getUserCustomerRoles(): Observable<UserCustomer[]> {
    return this.apiService.get(`${this.BASE_PATH}/Roles`);
  }

  getUserDepartments(): Observable<UserDepartment[]> {
    return this.apiService.get(`${this.BASE_PATH}/Departments`);
  }

  getUserBranches(): Observable<UserBranch[]> {
    return this.apiService.get(`${this.BASE_PATH}/Branches`);
  }
}
