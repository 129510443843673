import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UPopupService } from '@shift/ulib';

import { InputContact } from '@app/shared/models';
import { InputContactsService, ValidationService } from '@app/shared/services';

@Injectable()
export class BranchesAddEditInputContactsService extends InputContactsService {
  constructor(
    fb: UntypedFormBuilder,
    validationService: ValidationService,
    uPopupService: UPopupService
  ) {
    super(fb, validationService, uPopupService);
  }

  generateContactForm(contact: InputContact): UntypedFormGroup {
    return this.fb.group({
      groupId: [ contact.groupId ],
      role: [ contact.role, [ Validators.required ] ],
      name: [ contact.name, [ Validators.required ] ],
      contacts: this.fb.array([])
    });
  }
}
