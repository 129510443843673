import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  standalone: true
})
export class JoinPipe implements PipeTransform {
  transform(items: (string | number)[], separator: string = ', '): string {
    return items?.join(separator);
  }
}
