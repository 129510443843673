import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UPopoverDirective } from '@shift/ulib';

import {
  DemandsTableRow,
  DemandsPassengerRide,
  DemandsTableBulkShiftAssignments,
  DemandsShiftsViewUpdateShift
} from '@app/demands/models';
import { demandsConfig } from '@app/demands/configs';

@Injectable()
export class DemandsCommonService {
  private backdrop: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private backdropClick: Subject<void> = new Subject();
  private bulkRowHeight: BehaviorSubject<number> = new BehaviorSubject<number>(demandsConfig.shiftHeight + demandsConfig.rowPadding);
  private updatedPassengers: Subject<{ passengers: DemandsTableRow[]; rides?: DemandsPassengerRide[]; }> = new Subject();
  private updateShift: Subject<DemandsShiftsViewUpdateShift> = new Subject();

  backdrop$ = this.backdrop.asObservable();
  backdropClick$ = this.backdropClick.asObservable();
  bulkRowHeight$ = this.bulkRowHeight.asObservable();
  updatedPassengers$ = this.updatedPassengers.asObservable();
  updateShift$ = this.updateShift.asObservable();
  assignShiftPopover: UPopoverDirective;
  assignPassengerPopover: UPopoverDirective;
  bulkShiftAssignments: DemandsTableBulkShiftAssignments = {};

  constructor() {
    this.initBulkShiftAssignments();
  }

  initBulkShiftAssignments() {
    demandsConfig.daysOfWeek.forEach(day => this.bulkShiftAssignments[day] = []);
  }

  assignShiftUpdate(passengers: DemandsTableRow[], rides?: DemandsPassengerRide[]) {
    passengers.forEach(passenger =>
      demandsConfig.daysOfWeek.forEach(day => passenger[`dayOfWeek-${day}`] = passenger.demands && passenger.demands.filter(demand => demand.dayOfWeek === day))
    );

    this.updatedPassengers.next({ passengers, rides });
  }

  assignPassengerDemandUpdate(data: DemandsShiftsViewUpdateShift) {
    this.updateShift.next(data);
  }

  calculateBulkRowHeight() {
    const maxBulkAssignments = demandsConfig.daysOfWeek.reduce((acc, day) =>
      this.bulkShiftAssignments[day].length > acc ? this.bulkShiftAssignments[day].length : acc, 0);

    this.bulkRowHeight.next(maxBulkAssignments > 1 ?
      (maxBulkAssignments * demandsConfig.shiftHeight) + demandsConfig.rowPadding : demandsConfig.shiftHeight + demandsConfig.rowPadding);
  }

  setBackdrop(value: boolean) {
    this.backdrop.next(value);
  }

  clickOnBackdrop() {
    this.backdropClick.next();
  }
}
