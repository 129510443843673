import { Component, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import { passengerAskPopupComponentConfig } from './passenger-ask-popup.component.config';

@Component({
  selector: 'app-passenger-ask-popup',
  templateUrl: './passenger-ask-popup.component.html',
  styleUrls: [ './passenger-ask-popup.component.scss', './passenger-ask-popup.component.rtl.scss' ]
})
export class PassengerAskPopupComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'passenger-ask-popup';

  config = cloneDeep(passengerAskPopupComponentConfig);
  checkboxes: any[] = [
    { check: true, value: 'station', name: this.config.dictionary.removeStation },
    { check: true, value: 'destination', name: this.config.dictionary.removeDestination }
  ];

  constructor(
    private bsModalRef: BsModalRef
  ) {}

  close() {
    this.bsModalRef.hide();
  }

  apply() {
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { checkboxes: this.checkboxes } });
  }
}
