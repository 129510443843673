import { createReducer, on } from '@ngrx/store';

import * as RoutesActions from '@app/routes/state/actions';
import { RoutesWeeklyFilters } from '@app/routes/models';

export const FEATURE_KEY: string = 'weekly';

export interface State {
  selectedFilters: RoutesWeeklyFilters;
}

export const initialState: State = {
  selectedFilters: null
};

export const reducer = createReducer(
  initialState,
  on(
    RoutesActions.weeklySelectedFiltersUpdate,
    (state, { filters }) => ({
      ...state,
      selectedFilters: { ...state.selectedFilters, ...filters }
    })
  )
);
