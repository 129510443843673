<u-here-map
  class="builder-full-map__area"
  [center]="coords"
  [zoom]="zoom"
  [maxZoom]="maxZoom"
>
  <u-here-map-markers-cluster
    *ngIf="markers.length"
    [markers]="markers"
  ></u-here-map-markers-cluster>
</u-here-map>
