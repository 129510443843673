<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.name | translate}}
      <span
        class="builder-details__group-name-error"
        *ngIf="!addEditForm.get('details.name').valid  && !addEditForm.get('details.name').disabled"
      >
        {{config.dictionary.required | translate}}
      </span>
    </div>
    <div
      class="builder-details__group-field"
      [ngClass]="{ 'builder-details__group-field_disabled': addEditForm.get('details.name').disabled }"
    >
      <input
        uInput
        class="u-input_app-builder-details"
        [uPopover]="addEditForm.get('details.name').value"
        [valid]="addEditForm.get('details.name').valid || addEditForm.get('details.name').disabled"
        [formControl]="addEditForm.get('details.name')"
        [placeholder]="config.dictionary.name | translate"
        (blur)="builderDataService.editName()"
        [triggers]="'hover'"
        [popoverClass]="'u-popover_app-builder'"
      >
    </div>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name"></div>
    <div
      class="builder-details__group-field"
      [ngClass]="{ 'builder-details__group-field_disabled': !builderDataService.isSingleDayActiveRide }"
    >
      <u-checkbox
        class="u-checkbox_box u-checkbox_app-round-checkbox"
        [formControl]="addEditForm.get('details.isManualName')"
      ></u-checkbox>
      <span class="builder-details__item-label">{{config.dictionary.manualRouteName | translate}}</span>
    </div>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.number | translate}}
      <span
        class="builder-details__group-name-error"
        *ngIf="!addEditForm.get('details.number').valid && addEditForm.get('details.number').enabled"
      >
        {{config.dictionary.required | translate}}
      </span>
    </div>
    <div class="builder-details__group-field">
      <input
        uInput
        [valid]="addEditForm.get('details.number').valid"
        [formControl]="addEditForm.get('details.number')"
        [placeholder]="config.dictionary.number | translate"
        [mask]="'0*.00000'"
        [dropSpecialCharacters]="false"
        (blur)="builderDataService.editNumber()"
      >
    </div>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.emptyStations.title | translate}}
    </div>
    <div class="builder-details__group-field builder-details__group-field_flex">
      <u-toggle
        [formControl]="addEditForm.get('details.allowEmptyStations')"
        [messages]="config.dictionary.toggle | translate"
        (toggleChanged)="onAllowEmptyStationsChange($event)"
      ></u-toggle>
      <div
        class="builder-details__group-field-info"
        [uTooltip]="config.dictionary.emptyStations.warning | translate"
        [placement]="['top']"
        [container]="'body'"
        [tooltipClass]="'u-tooltip_general'"
      ></div>
    </div>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.routeType | translate}}
    </div>
    <div class="builder-details__group-field">
      <u-select-s
        [empty]="false"
        [class]="'u-select-s_app-builder-details'"
        [items]="options.routeTypes"
        [formControl]="addEditForm.get('details.routeType')"
        [placeholder]="config.dictionary.select | translate"
        [messages]="config.dictionary.selectS | translate"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
      ></u-select-s>
    </div>
  </div>
</div>
<div
  *ngIf="
    !!currentRouteBuilderOrderPurpose &&
    (orderPurposesByRouteTypeOptions?.[addEditForm.get('details.routeType').value]?.length || orderPurposesByRouteTypeOptions?.['null']?.length)
  "
  class="builder-details__item"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.orderPurpose | translate}}
    </div>
    <div class="builder-details__group-field">
      <u-select-s
        [emptyName]="config.dictionary.select | translate"
        [emptyRemove]="config.dictionary.removeSelect | translate"
        [emptyValue]="null"
        [items]="orderPurposesByRouteTypeOptions[addEditForm.get('details.routeType').value] || orderPurposesByRouteTypeOptions['null'] || []"
        [formControl]="addEditForm.get('details.orderPurposeId')"
        [placeholder]="config.dictionary.select | translate"
        [messages]="config.dictionary.selectS | translate"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [valid]="addEditForm.get('details.orderPurposeId').valid"
      ></u-select-s>
    </div>
  </div>
</div>
<div
  class="builder-details__item"
  *ngIf="routeBuilderDetails | includes: routeBuilderDetail.RequiresApproval"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.requiresApproval | translate}}
    </div>
    <u-toggle
      [uId]="'builder-details-requires-approval'"
      [formControl]="addEditForm.get('details.requiresApproval')"
      [messages]="config.dictionary.toggle | translate"
      (toggleChanged)="onRequiresApprovalChange($event)"
    ></u-toggle>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">{{config.dictionary.direction | translate}}</div>
    <div class="builder-details__group-field">
      <input
        uInput
        [value]="(addEditForm.get('details.direction').value === routeDirection.Forward ? config.dictionary.forward : config.dictionary.backward) | translate"
        [placeholder]="config.dictionary.direction | translate"
        disabled
      >
    </div>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">{{config.dictionary.creationDate | translate}}</div>
    <div class="builder-details__group-field" [ngClass]="{'form-disabled': addEditForm.get('details.creationDate').disabled === true}">
      <input
        uInput
        [formControl]="addEditForm.get('details.creationDate')"
        [placeholder]="config.dictionary.creationDate | translate"
      >
    </div>
  </div>
</div>
<div *ngIf="builderDataService.config().buildMode === builderBuildMode.Routes || builderDataService.config().buildMode === builderBuildMode.RouteSuggestions" class="builder-details__item">
  <div class="builder-details__group-double">
    <div class="builder-details__group-name">
      {{config.dictionary.period | translate}}
    </div>
    <div class="builder-details__group-field">
      <u-input-date-range
        [class]="'u-input-date-range_blue'"
        [container]="'body'"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [(ngModel)]="builderDataService.datesRange.dates"
        [disabled]="!builderDataService.createOrEditRoutesAllowed()"
        [sundayFirstDay]="true"
        [defaultDate]="true"
        [checkDays]="true"
        [checkDaysBtn]="false"
        [checkDaysActive]="builderDataService.datesRange.checkDaysAvailable"
        [checkDaysAvailable]="builderDataService.datesRange.checkDaysAvailable"
        (saveAction)="builderDataService.datesRangeChange($event, true)"
        [messages]="config.dictionary.inputDateRange | translate"
      ></u-input-date-range>
    </div>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.activeDays | translate}}
    </div>
    <div class="builder-details__group-field">
      <u-select-days
        class="u-select-days_app-builder"
        [container]="'body'"
        [messages]="config.dictionary.selectDays | translate"
        [(ngModel)]="builderDataService.datesDays"
        [disabled]="!builderDataService.createOrEditRoutesAllowed()"
        [sundayFirstDay]="true"
        [rtl]="isRtl"
        [lang]="lang"
        [availableDays]="builderDataService.datesDaysAvailable"
        [showInfo]="false"
        [showAllBtn]="false"
        [minDays]="builderDataService.minDays"
        (ngModelChange)="builderDataService.datesDaysChange()"
      ></u-select-days>
    </div>
  </div>
</div>
<div
  class="builder-details__item"
  *ngIf="routeBuilderDetails | includes: routeBuilderDetail.Customers"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.customers | translate}}
    </div>
    <div class="builder-details__group-field">
      <u-multiselect
        [class]="'u-multiselect_app-builder-details'"
        [placeholder]="config.dictionary.select | translate"
        [items]="options.subCustomers"
        [formControl]="addEditForm.get('details.customerIds')"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [messages]="config.dictionary.multiselect | translate"
      ></u-multiselect>
    </div>
  </div>
</div>
<div
  class="builder-details__item"
  *ngIf="routeBuilderDetails | includes: routeBuilderDetail.Region"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{config.dictionary.region | translate}}
    </div>
    <div class="builder-details__group-field">
      <u-select-s
        *ngIf="addEditForm.get('details.regionCode') as formControl"
        [emptyName]="config.dictionary.select | translate"
        [emptyRemove]="config.dictionary.removeSelect | translate"
        [emptyValue]="null"
        [items]="options.regions"
        [formControl]="formControl"
        [placeholder]="config.dictionary.select | translate"
        [messages]="config.dictionary.selectS | translate"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [valid]="formControl.valid"
      ></u-select-s>
    </div>
  </div>
</div>
<div
  *ngIf="!hasMasterCustomerFeature"
  class="builder-details__item"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">
      {{(departmentsType === departmentsTypes.Section ? config.dictionary.command : departmentsType === departmentsTypes.Division ? config.dictionary.departmentDivision : config.dictionary.department) | translate}}
    </div>
    <div class="builder-details__group-field">
      <u-select-s
        *ngIf="addEditForm.get('details.departmentId') as formControl"
        [emptyName]="config.dictionary.select | translate"
        [emptyRemove]="config.dictionary.removeSelect | translate"
        [emptyValue]="null"
        [items]="addEditForm.get('details.regionCode').value ? builderDataStoreService.departmentsByRegionCodesOptions[addEditForm.get('details.regionCode').value] : options.departments"
        [formControl]="formControl"
        [placeholder]="config.dictionary.select | translate"
        [messages]="config.dictionary.selectS | translate"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [valid]="formControl.valid"
      ></u-select-s>
    </div>
  </div>
</div>
<div class="builder-details__double-items">
  <div class="builder-details__item">
    <div class="builder-details__group">
      <div class="builder-details__group-name">
        {{config.dictionary.contact | translate}}
      </div>
      <div class="builder-details__group-field">
        <input
          uInput
          [placeholder]="config.dictionary.contactName | translate"
          [formControl]="addEditForm.get('details.contactName')"
        >
      </div>
    </div>
  </div>
  <div class="builder-details__item">
    <div class="builder-details__group">
      <div class="builder-details__group-name">
        {{config.dictionary.mobile | translate}}
      </div>
      <div class="builder-details__group-field">
        <input
          uInput
          [placeholder]="config.dictionary.mobileNumber | translate"
          [formControl]="addEditForm.get('details.contactMobile')"
        >
      </div>
    </div>
  </div>
</div>
<div
  class="builder-details__item"
  *ngIf="routeBuilderDetails | includes: routeBuilderDetail.BudgetItem"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">{{config.dictionary.budgetItem | translate}}</div>
    <div class="builder-details__group-field">
      <u-select-s
        [emptyName]="config.dictionary.select | translate"
        [emptyRemove]="config.dictionary.removeSelect | translate"
        [emptyValue]="null"
        [items]="options.budgetTypes"
        [formControl]="addEditForm.get('details.budgetItemId')"
        [placeholder]="config.dictionary.select | translate"
        [messages]="config.dictionary.selectS | translate"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
      ></u-select-s>
    </div>
  </div>
</div>
<div
  class="builder-details__item"
  *ngIf="routeBuilderDetails | includes: routeBuilderDetail.BidNumber"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">{{config.dictionary.contractCode | translate}}</div>
    <div class="builder-details__group-field">
      <input
        uInput
        [valid]="addEditForm.get('details.bidNumber').valid"
        [formControl]="addEditForm.get('details.bidNumber')"
        [placeholder]="config.dictionary.contractCode | translate"
        (blur)="builderDataService.editBidNumber()"
      >
    </div>
  </div>
</div>
<div
  class="builder-details__item"
  *ngIf="routeBuilderDetails | includes: routeBuilderDetail.RegulationNumber"
>
  <div class="builder-details__group">
    <div class="builder-details__group-name">{{config.dictionary.regulationNumber | translate}}</div>
    <div class="builder-details__group-field">
      <input
        uInput
        [valid]="addEditForm.get('details.regulationNumber').valid"
        [formControl]="addEditForm.get('details.regulationNumber')"
        [placeholder]="config.dictionary.regulationNumber | translate"
        [maxlength]="100"
        (blur)="builderDataService.editRegulationNumber()"
        (click)="track('click on Regulation no.')"
      >
    </div>
  </div>
</div>
<div class="builder-details__item">
  <div class="builder-details__group">
    <div class="builder-details__group-name">{{config.dictionary.comment | translate}}</div>
    <div class="builder-details__group-field">
      <textarea
        uTextarea
        [formControl]="addEditForm.get('details.comment')"
        [placeholder]="config.dictionary.comment | translate"
      ></textarea>
    </div>
  </div>
</div>
