<u-group
  class="u-group_large-field input-monitoring-group"
  [name]="config.dictionary.title | translate"
>
  <div class="input-monitoring__double">
    <div class="input-monitoring__double-item">
      <u-select-s
        [items]="monitoringVendors"
        [placeholder]="config.dictionary.select | translate"
        [formControl]="form.get('monitoringVendorId')"
        [emptyName]="config.dictionary.select | translate"
        [emptyRemove]="config.dictionary.removeSelect | translate"
        [emptyValue]="null"
        [emptyHidden]="false"
        [empty]="true"
        [valid]="!form.get('monitoringVendorId').invalid"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [container]="'body'"
        [classUDropdownContent]="'u-dropdown-content_app-default'"
      ></u-select-s>
    </div>
    <div class="input-monitoring__double-item">
      <input
        uInput
        type="text"
        [placeholder]="config.dictionary.status | translate"
        [formControl]="form.get('monitoringCarStatusName')"
      >
    </div>
  </div>
</u-group>
