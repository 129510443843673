<div class="builder-ride-order-summary__item">
  <div class="builder-ride-order-summary__group">
    <div class="builder-ride-order-summary__group-name">
      {{config.dictionary.shuttleCompany | translate}}
    </div>
    <div class="builder-ride-order-summary__group-field">
      <u-select-s
        [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-summary'"
        [container]="'body'"
        [empty]="false"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [items]="shuttleCompanies"
        [messages]="config.dictionary.select.messages | translate"
        [placeholder]="config.dictionary.select.placeholder | translate"
        [formControl]="builderDataService.rideOrderSummaryForm.get('shuttleCompanyId')"
        [valid]="builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').valid || builderDataService.rideOrderSummaryForm.get('shuttleCompanyId').disabled"
      ></u-select-s>
    </div>
  </div>
</div>

<div class="builder-ride-order-summary__item">
  <div class="builder-ride-order-summary__group">
    <div class="builder-ride-order-summary__group-name">
      {{config.dictionary.contract | translate}}
    </div>
    <div class="builder-ride-order-summary__group-field">
      <u-select-s
        [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-summary'"
        [viewportElement]="viewportElement"
        [formControl]="builderDataService.rideOrderSummaryForm.get('contractId')"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [container]="'body'"
        [items]="shuttleCompanyContracts"
        [empty]="true"
        [emptyValue]="null"
        [messages]="config.dictionary.select.messages | translate"
        [placeholder]="config.dictionary.select.placeholder | translate"
      ></u-select-s>
    </div>
  </div>
</div>

<div class="builder-ride-order-summary__item">
  <div class="builder-ride-order-summary__group">
    <div class="builder-ride-order-summary__group-name">
      {{config.dictionary.pricelist | translate}}
    </div>
    <div class="builder-ride-order-summary__group-field">
      <u-select-s
        [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-summary'"
        [container]="'body'"
        [empty]="false"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [items]="builderDataService.rideOrderShuttleCompanyCostTypesItems()"
        [formControl]="builderDataService.rideOrderSummaryForm.get('costType')"
        [valid]="builderDataService.rideOrderSummaryForm.get('costType').valid || builderDataService.rideOrderSummaryForm.get('costType').disabled"
        [messages]="config.dictionary.select.messages | translate"
        [placeholder]="config.dictionary.select.placeholder | translate"
      ></u-select-s>
    </div>
  </div>
</div>

<div *ngIf="builderDataService.rideOrderHashcalRideTypesItems()?.length" class="builder-ride-order-summary__item">
  <div class="builder-ride-order-summary__group">
    <div class="builder-ride-order-summary__group-name">
      {{config.dictionary.hashcalRideType | translate}}
    </div>
    <div class="builder-ride-order-summary__group-field">
      <u-select-s
        [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-summary'"
        [container]="'body'"
        [empty]="false"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [items]="builderDataService.rideOrderHashcalRideTypesItems()"
        [formControl]="builderDataService.rideOrderSummaryForm.get('hashcalRideType')"
        [valid]="builderDataService.rideOrderSummaryForm.get('hashcalRideType').valid || builderDataService.rideOrderSummaryForm.get('hashcalRideType').disabled"
        [messages]="config.dictionary.select.messages | translate"
        [placeholder]="config.dictionary.select.placeholder | translate"
      ></u-select-s>
    </div>
  </div>
</div>

<div class="builder-ride-order-summary__item">
  <div class="builder-ride-order-summary__group">
    <div class="builder-ride-order-summary__group-name">
      {{config.dictionary.ridePrice | translate}}
    </div>
    <div class="builder-ride-order-summary__group-field">
      <u-input-number
        *ngIf="builderDataService.rideOrderSummaryForm.get('price') as formControl"
        [placeholder]="config.dictionary.ridePrice | translate"
        [decimal]="true"
        [formControl]="formControl"
        [valid]="formControl.disabled || formControl.valid"
      ></u-input-number>
    </div>
  </div>

  <div *ngIf="builderDataService.rideOrderSummaryForm.get('totalPrice').value" class="builder-ride-order-summary__subtitle">
    <span class="builder-ride-order-summary__subtitle-title">{{(config.dictionary.totalOrder | translate)}}:</span>
    <span class="builder-ride-order-summary__subtitle-value">{{builderDataService.rideOrderSummaryForm.get('totalPrice').value}}</span>
  </div>
</div>

<div class="builder-ride-order-summary__item">
  <div class="builder-ride-order-summary__group">
    <div class="builder-ride-order-summary__group-name">
      {{config.dictionary.purchaseOrder | translate}}
    </div>
    <div class="builder-ride-order-summary__group-field">
      <u-select-s
        [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-summary'"
        [container]="'body'"
        [empty]="false"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [items]="purchaseOrdersItems()"
        [messages]="config.dictionary.select.messages | translate"
        [placeholder]="config.dictionary.select.placeholder | translate"
        [formControl]="builderDataService.rideOrderSummaryForm.get('purchaseOrder')"
        [valid]="builderDataService.rideOrderSummaryForm.get('purchaseOrder').valid || builderDataService.rideOrderSummaryForm.get('purchaseOrder').disabled"
      ></u-select-s>
    </div>
  </div>
</div>

<div class="builder-ride-order-summary__item">
  <div class="builder-ride-order-summary__group">
    <div class="builder-ride-order-summary__group-name">
      {{config.dictionary.purchaseOrderRow | translate}}
    </div>
    <div class="builder-ride-order-summary__group-field">
      <u-select-s
        [classUDropdownContent]="'u-dropdown-content_app-builder-ride-order-summary'"
        [container]="'body'"
        [empty]="false"
        [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
        [viewportElement]="viewportElement"
        [showNotAvailableMessage]="false"
        [items]="rowsInOrdersByOrderNumberItems()?.[builderDataService.rideOrderSummaryForm.get('purchaseOrder').value]"
        [messages]="config.dictionary.select.messages | translate"
        [placeholder]="config.dictionary.select.placeholder | translate"
        [formControl]="builderDataService.rideOrderSummaryForm.get('purchaseOrderRow')"
        [valid]="builderDataService.rideOrderSummaryForm.get('purchaseOrderRow').valid || builderDataService.rideOrderSummaryForm.get('purchaseOrderRow').disabled"
      ></u-select-s>
    </div>
  </div>
  <div class="builder-ride-order-summary__subtitle builder-ride-order-summary__subtitle_purchase-order-row" *ngIf="builderDataService.rideOrderSummaryForm.get('purchaseOrderRow').value">
    <span class="builder-ride-order-summary__subtitle-title">{{config.dictionary.budget | translate}}:</span>
    <div class="builder-ride-order-summary__subtitle-value-purchase-order-row-container">
      <span
        class="builder-ride-order-summary__subtitle-value"
        [class.builder-ride-order-summary__subtitle-value_highlighted]="builderDataService.rideOrderSummaryForm.get('totalPrice').value <= selectedRowInOrder()?.remainingBudget"
      >
        {{selectedRowInOrder()?.remainingBudget}}
      </span>
      <span class="builder-ride-order-summary__subtitle-value">/ {{selectedRowInOrder()?.totalBudget}}</span>
    </div>
  </div>
</div>

<ng-container *ngIf="showConfirmationAndCommentsFields">
  <div class="builder-ride-order-summary__item">
    <div class="builder-ride-order-summary__group">
      <div class="builder-ride-order-summary__group-name">
        {{config.dictionary.confirmation | translate}}
      </div>
      <div class="builder-ride-order-summary__group-field">
        <app-builder-ride-order-confirmation
          [formControl]="builderDataService.rideOrderSummaryForm.get('status')"
          [items]="builderDataService.rideOrder.confirmationItems"
          (approve)="track('Confirmation field - Set as approved')"
          (reject)="track('Confirmation field - Set as not approved')"
        ></app-builder-ride-order-confirmation>
      </div>
    </div>
  </div>

  <div class="builder-ride-order-summary__item">
    <div class="builder-ride-order-summary__group">
      <div class="builder-ride-order-summary__group-name">
        {{config.dictionary.comments | translate}}
      </div>
      <div class="builder-ride-order-summary__group-field">
        <textarea
          uTextarea
          [formControl]="builderDataService.rideOrderSummaryForm.get('approvingComments')"
          [placeholder]="config.dictionary.comments | translate"
        ></textarea>
      </div>
    </div>
  </div>
</ng-container>
