const DICTIONARY_PATH: string = 'builder.history';

export const builderHistoryComponentConfig = {
  dictionary: {
    uGrid: 'uGrid',
    planned: `${DICTIONARY_PATH}.planned`,
    notPlanned: `${DICTIONARY_PATH}.notPlanned`,
    routeDragMap: `${DICTIONARY_PATH}.routeDragMap`
  }
};
