export enum EventDataType {
  Click = 'click',
  Change = 'change',
  Toggle = 'toggle'
}

export interface EventData {
  name: string;
  type?: EventDataType;
  value?: boolean | string | number | object;
}
