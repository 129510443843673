<div
  *ngIf="form()"
  class="documents__main"
>
  <div class="documents__header">
    <button
      uButton
      class="u-font-weight-medium"
      [withPlus]="true"
      (click)="newDocument.emit()"
    >
      {{config.dictionary.newDocument | translate}}
    </button>
  </div>
  <div class="documents__items">
    <div
      *ngFor="let documentForm of form().controls; let i = index"
      [formGroup]="documentForm"
      class="documents__item"
    >
      <u-group
        class="u-group_large-field u-group_flex u-group_app-documents documents__group"
        [name]="config.dictionary.document | translate"
      >
      <div class="documents__group-item">
        <input
          uInput
          type="text"
          formControlName="documentName"
          [placeholder]="config.dictionary.documentName | translate"
          [valid]="documentForm.get('documentName').valid"
        >
      </div>
      <div class="documents__group-item">
        <u-input-date
          formControlName="expirationDate"
          [classUInput]="'u-input_text-center'"
          [valid]="documentForm.get('expirationDate').valid"
          [placement]="isRtl ? [ 'bottom-right' ] : [ 'bottom-left' ]"
          [messages]="config.dictionary.uInputDate | translate"
          [showIcon]="false"
          [container]="'body'"
        ></u-input-date>
      </div>
      <div class="documents__group-item">
        <u-input-file
          *ngIf="!documentForm.get('fileName').value"
          formControlName="file"
          class="u-input-file_box"
          [placeholder]="config.dictionary.attachFile | translate"
          (change)="uploadDocument.emit(documentForm)"
        ></u-input-file>
        <div *ngIf="documentForm.get('fileName').value" class="documents__file">
          <span class="documents__file-name" (click)="getDocument.emit(documentForm)">{{documentForm.get('fileName').value}}</span>
          <span class="documents__file-remove" (click)="removeDocumentFile.emit(documentForm)"></span>
        </div>
      </div>
      </u-group>
      <div class="documents__actions">
        <div class="documents__actions-btn documents__actions-btn_remove" (click)="removeDocument.emit({ documentForm, index: i })"></div>
      </div>
    </div>
  </div>
</div>
