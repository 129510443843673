<div class="routes-change-car__title">{{config.dictionary.title | translate}}</div>

<u-group
  *ngIf="form"
  class="u-group_flex u-group_app-routes-change-car"
  [name]="config.dictionary.carType | translate"
>
  <div class="routes-change-car__field-wrapper">
    <u-select-s
      [messages]="'uSelectS' | translate"
      [items]="carTypeItems"
      [empty]="false"
      [formControl]="form.get('carType')"
      [valid]="form.get('carType').valid"
      [placement]="[ 'bottom' ]"
      [container]="'body'"
      [placeholder]="'general.select' | translate"
    ></u-select-s>
    <div
      *ngIf="carTypeItems && !carTypeItems.length"
      class="routes-change-car__error-message"
    >
      {{config.dictionary.noVehicles | translate}}
    </div>
  </div>
</u-group>

<u-group
  class="u-group_app-routes-change-car u-group_flex"
  [name]="config.dictionary.changeType | translate"
>
  <app-routes-period
    [showTypesChange]="false"
    [showInputType]="true"
    [typeFormControl]="form.controls['type']"
    [changes]="form.controls['datesChange'].value"
    [viewportElement]="viewportElement"
    (updatePeriod)="updatePeriod($event)"
    (updateDates)="updateDates($event)"
  ></app-routes-period>
</u-group>

<u-group
  class="u-group_app-routes-change-car u-group_flex"
  [name]="config.dictionary.comments | translate"
>
  <textarea
    uTextarea
    [formControl]="form.controls['comment']"
    [placeholder]="config.dictionary.comments | translate"
  ></textarea>
</u-group>

<div
  class="routes-change-car__actions"
>
  <button
    uButton
    class="u-font-weight-medium"
    [size]="uButtonSize.Small"
    (click)="saveChanges()"
  >
    {{config.dictionary.actions.saveAndClose | translate}}
  </button>
  <div class="routes-change-car__actions-divider"></div>
  <button
    uButton
    class="u-font-weight-medium"
    [size]="uButtonSize.Small"
    (click)="saveChanges(routesChangeEmailSendType.SendBackgroundEmail)"
  >
    {{config.dictionary.actions.saveAndSendSC | translate}}
  </button>
  <div class="routes-change-car__actions-divider"></div>
  <button
    uButton
    class="u-font-weight-medium"
    [size]="uButtonSize.Small"
    (click)="saveChanges(routesChangeEmailSendType.GenerateEditableEmail)"
  >
    {{config.dictionary.actions.saveAndEditEmail | translate}}
  </button>
</div>
