import { UInputCitiesCityLocation, UMultiselectItem, USelectSItem } from '@shift/ulib';

import { DaysOfWeek } from '@app/shared/models';
import { RouteDirection } from '@app/routes/models';

export enum DemandsDemandType {
  Regular = 1,
  Repeatable,
  Reservation
}

export enum DemandsTableParamsDemandType {
  ReservationApp,
  WebSystem
}

export enum DemandsPassengerShiftAdvancedAddressType {
  AnyAddress,
  SavedStation,
  PassengerSettings
}

export enum DemandsPassengerShiftRepeatType {
  Days,
  Weeks
}

export enum DemandsPassengerShiftSeriesUpdateType {
  OnlyCurrentDemand,
  CurrentAndFutureDemands,
  AllDemands
}

export enum DemandsWeekDuplicateActionType {
  Apply = 'apply',
  ApplyGoWeek = 'applyGoWeek',
  Close = 'close'
}

export enum DemandsShiftRepeatOptionsActionType {
  Submit = 'submit',
  Cancel = 'cancel'
}

export enum DemandsPassengersRoutesActions {
  Remove,
  Keep
}

export enum DemandsView {
  Passengers = 'passengers',
  Shifts = 'shifts'
}

export enum DemandsShiftsViewInitialDataType {
  Branches = 'branches',
  Departments = 'departments',
  Shifts = 'shifts'
}

export interface DemandsPassengerShiftAddress {
  fullAddress: string;
  latitude: number;
  longitude: number;
  placeId: string;
}

export class DemandsPassengerShiftAdvancedAddress {
  addressType: DemandsPassengerShiftAdvancedAddressType = DemandsPassengerShiftAdvancedAddressType.PassengerSettings;
  address: DemandsPassengerShiftAddress = {
    fullAddress: null,
    latitude: 0,
    longitude: 0,
    placeId: null
  };
  stationId: number = null;
  stationName: string = null;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class DemandsPassengerShiftAdvancedRepeatInfo {
  startDate: string = null;
  endDate: string = null;
  activeDays: DemandsDayOfWeek[] = [];
  repeatType: DemandsPassengerShiftRepeatType = DemandsPassengerShiftRepeatType.Weeks;
  repeatPeriod: number = 1;
}

export interface DemandsPassengerShiftParams {
  passengerId: number;
  demandId: string;
}

export interface DemandsPassengerShiftWeekDuplicateAction {
  type: DemandsWeekDuplicateActionType;
  value: {
    duplicateDates: string[];
    duplicateDays: DemandsDayOfWeek[];
  };
}

export interface DemandsPassengerShiftDeleteParams extends DemandsPassengerShiftParams {
  seriesUpdateType: DemandsPassengerShiftSeriesUpdateType;
}

export interface DemandsBulkPassengersShiftsParams {
  passengerIds: number[];
  shifts: DemandsPassengerShift[];
}

export interface DemandsTableBulkShiftAssignments {
  [DaysOfWeek.Sunday]?: DemandsBulkPassengerShift[];
  [DaysOfWeek.Monday]?: DemandsBulkPassengerShift[];
  [DaysOfWeek.Tuesday]?: DemandsBulkPassengerShift[];
  [DaysOfWeek.Wednesday]?: DemandsBulkPassengerShift[];
  [DaysOfWeek.Thursday]?: DemandsBulkPassengerShift[];
  [DaysOfWeek.Friday]?: DemandsBulkPassengerShift[];
  [DaysOfWeek.Saturday]?: DemandsBulkPassengerShift[];
}

export class DemandsPassengerShift {
  seriesUpdateType?: DemandsPassengerShiftSeriesUpdateType = null;
  demandId: string = null;
  passengerId?: number = null;
  branchId: number = null;
  shiftId: number = -1;
  startTime: string = '';
  endTime: string = '';
  date: string = null;
  dayOfWeek: DemandsDayOfWeek = null;
  comesIndependent: boolean = false;
  leavesIndependent: boolean = false;
  advancedSettings: {
    pickUpPassengerAddress: DemandsPassengerShiftAdvancedAddress;
    dropOffPassengerAddress: DemandsPassengerShiftAdvancedAddress;
    repeatInfo: DemandsPassengerShiftAdvancedRepeatInfo;
  } = {
      pickUpPassengerAddress: new DemandsPassengerShiftAdvancedAddress(),
      dropOffPassengerAddress: new DemandsPassengerShiftAdvancedAddress(),
      repeatInfo: null
    };
  type: DemandsDemandType = DemandsDemandType.Regular;
  assignOverlap?: boolean;

  constructor(values?: DemandsPassengerShift) {
    Object.assign(this, values);
  }
}

export class DemandsBulkPassengerShift extends DemandsPassengerShift {
  cardId: string;
  shiftName: string;
  branchName: string;
  createDate: string;
  demandType: DemandsDemandType;
}

export interface DemandsBulkPassengersShiftsDeleteParams {
  passengerIds: number[];
  shift: DemandsPassengerShift;
}

class DemandsPassengersShiftsWeekDuplicateParams {
  activeWeekStartDate: string;
  activeWeekEndDate: string;
  duplicateWeekStartDate: string;
  duplicateWeekEndDate: string;
  duplicateWeekActiveDays: DemandsDayOfWeek[];
}

export interface DemandsPassengerShiftsWeekDuplicateParams extends DemandsPassengersShiftsWeekDuplicateParams {
  passengerId: number;
}

export interface DemandsBulkPassengersShiftsWeekDuplicateParams extends DemandsPassengersShiftsWeekDuplicateParams {
  passengerIds: number[];
}

export interface DemandsPassengerShiftsWeekDeleteParams {
  passengerId: number;
  activeWeekStartDate: string;
  activeWeekEndDate: string;
}

export interface DemandsBulkPassengersShiftsWeekDeleteParams {
  passengerIds: number[];
  activeWeekStartDate: string;
  activeWeekEndDate: string;
}

export interface DemandsCustomerData {
  [key: string]: (USelectSItem | UMultiselectItem)[];
}

export interface DemandsDemand {
  cardId?: string;
  passengerId: number;
  demandId: string;
  demandType: DemandsDemandType;
  date: string;
  createDate: string;
  dayOfWeek: DemandsDayOfWeek;
  startTime: string;
  endTime: string;
  isOvernightTime: boolean;
  shiftId: number;
  shiftName: string;
  branchId: number;
  branchName: string;
  branchColor: string;
}

export interface DemandsTableParams {
  startDate: string;
  endDate: string;
  departmentIds: number[];
  cities: UInputCitiesCityLocation[];
  tagIds: number[];
  branchIds: number[];
  shiftIds: number[];
  demandType: DemandsTableParamsDemandType;
  passengerIds: number[];
  search: string;
  skip: number;
  take: number;
}

export interface DemandsTablePassenger {
  id: number;
  passengerId: number;
  firstName: string;
  lastName: string;
  address: string;
  latitude: number;
  longitude: number;
  departmentId: number;
  department: string;
  branchId: number;
  branch: string;
  mobileContactId: number;
  mobileContact: string;
  gender: number;
  birthDate: string;
  status: number;
  eligibleForShuttle: boolean;
  pickUpType: number;
  stationId: number;
  stationName: string;
  tags: string;
  comments: string;
  demands: DemandsDemand[];
}

export interface DemandsTableBusinessPassenger extends DemandsTablePassenger {
  passengerNumber: string;
  identity: string;
  distanceToStation: number;
}

export interface DemandsTableMunicipalityPassenger extends DemandsTablePassenger {
  identity: string;
  phoneContactId: number;
  phoneContact: string;
  distanceToSchool: number;
  eligibleForAccompany: boolean;
  needElevator: boolean;
  schoolId: number;
  schoolIdentity: string;
  schoolName: string;
  schoolAddress: string;
  class: string;
  classTypeId: number;
  classNumber: number;
  parents: {
    parentName: string;
    parentMobile: string;
  }[];
  parent1Name: string;
  parent1Mobile: string;
  parent2Name: string;
  parent2Mobile: string;
  disabilityCodes: {
    id: number;
  }[];
  disabilityCode: number;
  secondaryDisabilityCode: number;
  thirdDisabilityCode: number;
}

export type DemandsTableRow = DemandsTablePassenger | DemandsTableBusinessPassenger | DemandsTableMunicipalityPassenger;

export type DemandsDayOfWeek = DaysOfWeek.Sunday | DaysOfWeek.Monday | DaysOfWeek.Tuesday | DaysOfWeek.Wednesday | DaysOfWeek.Thursday | DaysOfWeek.Friday | DaysOfWeek.Saturday;

export type DemandsDayOfWeekKey = keyof DemandsDayOfWeek;

export interface DemandsPassengerShiftUpdate {
  passenger: DemandsTableRow;
  passengersRides: DemandsPassengerRide[];
}

export interface DemandsPassengerBulkShiftUpdate {
  passengers: DemandsTableRow[];
  passengersRides: DemandsPassengerRide[];
}

export interface DemandsPassengerRide {
  passengerId: number;
  passengerName: string;
  routeId: number;
  routeNumber: number;
  routeName: string;
  direction: RouteDirection;
  date: string;
  startTime: string;
  endTime: string;
  isOvernight: boolean;
}

export interface DemandsTable {
  passengers: DemandsTableRow[];
  totalCount: number;
}

export interface DemandsShiftsParams {
  branchId: number;
  dayOfWeek: DemandsDayOfWeek;
}

export interface DemandsShift {
  id: number;
  name: string;
  activeDays: DemandsDayOfWeek[];
}

export interface DemandsShiftsTimeParams {
  shiftId: number;
  dayOfWeek: DemandsDayOfWeek;
}

export interface DemandsShiftsTime {
  shiftId: number;
  dayOfWeek: DemandsDayOfWeek;
  startTime: string;
  endTime: string;
}

export interface DemandsShiftByTimeParams {
  branchId: number;
  startTime: string;
  endTime: string;
  dayOfWeek: DemandsDayOfWeek;
}

export interface DemandsValidateRepeatPeriodParams {
  shiftId: number;
  repeatInfo: DemandsPassengerShiftAdvancedRepeatInfo;
}

export interface DemandsValidateRepeatPeriod {
  isValid: boolean;
}

export interface DemandsExcelColumn {
  field: string;
  alias: string;
}

export interface DemandsExcelParams {
  startDate: string;
  endDate: string;
  departmentIds: number[];
  cities: UInputCitiesCityLocation[];
  tagIds: number[];
  branchIds: number[];
  shiftIds: number[];
  demandType: DemandsTableParamsDemandType;
  passengerIds: number[];
  search: string;
  columns: DemandsExcelColumn[];
}

export interface DemandsShiftRepeatOptionsCheckbox {
  check: boolean;
  value: number;
  name: string;
}

export interface DemandsShiftRepeatOptionsAction {
  type: string;
  value: number;
}

export interface DemandsConfirmationPopupOptions {
  message: string;
  yes?: string;
  no?: string;
}

export class DemandsConfirmationPopupValues {
  yes: boolean = true;
  no: boolean = false;
  close: boolean = false;
}

export interface DemandsExcelExport {
  value: {
    dateFrom: string;
    dateTo: string;
  };
}

export interface DemandsRemovePassengersFromRideParams {
  passengersRides: DemandsRemovePassengerRideParam[];
}

export interface DemandsRemovePassengerRideParam {
  date: string;
  routeId: number;
  passengerId: number;
}

export interface DemandsPassengersRoute {
  code: number;
  direction: number;
  isTemplate: boolean;
  name: string;
  routeId: number;
  keep: boolean;
  passengerId?: number;
  passengerName?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  isOvernight?: boolean;
  dateFrom?: string;
  dateTo?: string;
}

export interface DemandsPassengersRoutesPopupAction {
  type: string;
  removeRoutes?: number[];
  removeErrorPopup?: boolean;
  params?: {
    passengersRides?: DemandsRemovePassengerRideParam[];
  };
}

export interface DemandsState {
  showViewToggle: boolean;
  view: DemandsView;
}


export interface DemandsShiftsViewInitialData {
  [key: string]: {
    id: number;
    name: string;
    branchId?: number;
    days?: {
      shiftId: number;
      dayOfWeek: DaysOfWeek;
      startTime: string;
      endTime: string;
    }[];
  }[];
}

export interface DemandsShiftsViewPassenger {
  memberId: number;
  name: string;
  mobile: string;
}

export interface DemandsShiftsViewPassengerPickUpSettings {
  pickUpType: number;
  station: {
    id: number;
    name: string;
  };
  address: {
    latitude: number;
    longitude: number;
    fullAddress: string;
    placeId: string;
    addressId: number;
    cityId: number;
    street: string;
    houseNumber: string;
  };
}

export interface DemandsShiftsViewGetTableBody {
  startDate: string;
  endDate: string;
  departmentIds: number[];
  branchIds: number[];
  shiftIds: number[];
  shiftStartTime?: string;
  shiftEndTime?: string;
  search: string;
  skip: number;
  take: number;
}

export interface DemandsShiftsViewShiftDemand {
  demandId: string;
  passengerId: number;
  firstName: string;
  lastName: string;
  departmentId: number;
  departmentName: string;
  dayOfWeek: number;
  demandType: DemandsDemandType;
  createDate: string;
}

export interface DemandsShiftsViewShift {
  shiftId: number;
  shiftName: string;
  startTime: string;
  endTime: string;
  daysBitmask: number;
  days: number[];
  branchId: number;
  branchName: string;
  branchColor: string;
  demands: DemandsShiftsViewShiftDemand[];
}

export interface DemandsShiftsViewShiftRowDayOfWeekDepartment {
  departmentId: number;
  departmentName: string;
  demands: DemandsShiftsViewShiftDemand[];
}

export interface DemandsShiftsViewShiftRow extends DemandsShiftsViewShift {
  'dayOfWeek-0': DemandsShiftsViewShiftRowDayOfWeekDepartment[];
  'dayOfWeek-1': DemandsShiftsViewShiftRowDayOfWeekDepartment[];
  'dayOfWeek-2': DemandsShiftsViewShiftRowDayOfWeekDepartment[];
  'dayOfWeek-3': DemandsShiftsViewShiftRowDayOfWeekDepartment[];
  'dayOfWeek-4': DemandsShiftsViewShiftRowDayOfWeekDepartment[];
  'dayOfWeek-5': DemandsShiftsViewShiftRowDayOfWeekDepartment[];
  'dayOfWeek-6': DemandsShiftsViewShiftRowDayOfWeekDepartment[];
}

export interface DemandsShiftsViewGetTable {
  shifts: DemandsShiftsViewShift[];
  totalCount: number;
}

export interface DemandsShiftsViewUpdateShift {
  shiftId: number;
  branchId: number;
  startTime: string;
  endTime: string;
}
