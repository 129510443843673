import { DOCUMENT } from '@angular/common';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { metaDataConfig } from '@app/shared/configs';
import { AuthDataService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  private readonly title = inject(Title);
  private readonly document = inject(DOCUMENT);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authDataService = inject(AuthDataService);

  init() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((userInfo) => {
        const metaDataByCustomerType = metaDataConfig[userInfo.customer.type] || metaDataConfig.default;
        const config = metaDataByCustomerType[environment.config.environmentType] || metaDataByCustomerType.default;

        this.title.setTitle(config.title);
        this.document.getElementById('favicon').setAttribute('href', config.favicon);
      });
  }
}
