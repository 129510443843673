import { Component, OnInit, Input, HostBinding, inject, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isEqual, cloneDeep } from 'lodash';
import * as moment from 'moment';
import { UButtonSize, URangePreset, USelectSItem } from '@shift/ulib';

import { HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuthCustomer } from '@app/auth/models';
import {
  RoutesChangeEmailSendType,
  RouteRideSupervisor,
  RouteRideSupervisorLevel,
  RouteDailyRow
} from '@app/routes/models';
import { RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { routesConfig } from '@app/routes/configs';
import { routesChangeSupervisorComponentConfig } from './routes-change-supervisor.component.config';

@Component({
  selector: 'app-routes-change-supervisor',
  templateUrl: './routes-change-supervisor.component.html',
  styleUrls: [ './routes-change-supervisor.component.scss', './routes-change-supervisor.component.rtl.scss' ],
  providers: [ BsModalRef ]
})
export class RoutesChangeSupervisorComponent implements OnInit {
  @Input() authCustomer: AuthCustomer;
  @Input() activeRide: RouteDailyRow;
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'routes-change-supervisor';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly routesTableService = inject(RoutesTableService);
  private readonly routesCommonService = inject(RoutesCommonService);
  private readonly headerDataService = inject(HeaderDataService);

  config;
  form: UntypedFormGroup;
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  rideSupervisors: USelectSItem[] = [];
  uButtonSize = UButtonSize;

  get changesForm(): UntypedFormGroup {
    return this.form.get('datesChange') as UntypedFormGroup;
  }

  ngOnInit() {
    this.getRideSupervisors();
    this.initConfig();
  }

  private initConfig() {
    this.config = cloneDeep(routesChangeSupervisorComponentConfig[this.authCustomer.type] || routesChangeSupervisorComponentConfig.default);

    this.initForm();
    this.updateCheckDays();
  }

  private getRideSupervisors() {
    this.routesTableService.getRideSupervisors({
      routeId: this.activeRide.routeId,
      activeDate: this.headerDataService.getActiveDate(),
      customerId: this.activeRide.routeCustomerId
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        this.rideSupervisors = Object.values(data).reduce((acc: USelectSItem[], items: RouteRideSupervisor[]) =>
          [
            ...acc,
            ...items.map(item => ({
              value: item.passengerId,
              name: item.name,
              sticky: item.level >= RouteRideSupervisorLevel.Low && item.level !== null,
              icon: {
                urls: {
                  default: routesConfig.rideSupervisorIcons[item.level]
                }
              }
            }))
          ],
        []
        );
      });
  }

  private initForm() {
    this.form = this.fb.group({
      routeId: [ this.activeRide.routeId ],
      passengerId: [ this.activeRide.supervisor && this.activeRide.supervisor.value || '' ],
      type: [ 2, Validators.required ],
      datesChange: this.fb.group({
        dates: [ [] ],
        dateFrom: [ '' ],
        dateTo: [ '' ],
        type: [ this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay ],
        availablePresets: [ this.config.availablePresets ],
        checkDaysActive: [ [] ],
        checkDaysAvailable: [ [] ]
      }),
      comment: [ '' ]
    });
  }

  private updateCheckDays() {
    const { routeStartDate, routeEndDate, days } = this.activeRide;
    const activeDate = this.headerDataService.getActiveDate();

    this.changesForm.patchValue({
      dates: [ activeDate ],
      dateFrom: routeStartDate,
      dateTo: routeEndDate,
      checkDaysActive: [ moment(activeDate).get('day') ],
      checkDaysAvailable: days
    });
  }

  save(emailSendType?: RoutesChangeEmailSendType) {
    const { passengerId, type, routeId, comment } = this.form.value;
    const dates = this.changesForm.get('dates').value;
    const checkDaysActive = this.changesForm.get('checkDaysActive').value;
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
    const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    const body = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        comment,
        type,
        dateFrom,
        dateTo,
        days: checkDaysActive,
        passengerId,
        rideId: this.activeRide.rideId
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    const method = passengerId ? (this.activeRide.isFullManualRide ? 'changeSetSupervisor' : 'changeAddSupervisor') : 'changeRemoveSupervisor';

    this.routesTableService[method](body, this.activeRide.routeCustomerId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  updatePeriod(data) {
    this.changesForm.patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });
  }

  updateDates({ dates, checkDaysActive }) {
    const datesStore: string[] = this.changesForm.get('dates').value;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        this.changesForm.patchValue({
          dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
          checkDaysActive
        });
      }
    }
  }
}
