export interface WeekSwitch {
  initDate?: string;
  startDateLimit: string;
  endDateLimit: string;
}

export interface WeekSwitchStartEnd {
  startDate: string;
  endDate: string;
}
