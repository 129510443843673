import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
  standalone: true
})
export class AutofocusDirective implements AfterViewInit {
  @Input() appAutofocus: boolean = true;

  constructor(
    private el: ElementRef
  ) {}

  ngAfterViewInit() {
    if (this.appAutofocus) {
      this.el.nativeElement.focus();
    }
  }
}
