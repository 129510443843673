import { Injectable, TemplateRef, WritableSignal, inject, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UPopoverDirective } from '@shift/ulib';

import { AuthModuleBranchesFeature, AuthModuleBranchesFeatureType, AuthModuleName } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { GeneralButton } from '@app/shared/models';
import { branchesAddEditCopyFromBranchConfig } from '@app/branches/configs';

@Injectable()
export class BranchesAddEditCopyFromBranchDataService {
  private authDataService = inject(AuthDataService);

  private unsubscribe: Subject<void> = new Subject();

  #buttons: WritableSignal<GeneralButton[]> = signal(null);
  #activePopover: WritableSignal<UPopoverDirective> = signal(null);

  buttons = this.#buttons.asReadonly();
  activePopover = this.#activePopover.asReadonly();

  private attachTemplateToButtons(templatesByButtonId: { [key: string]: TemplateRef<any>; }) {
    this.#buttons.update(buttons => buttons.map(button => templatesByButtonId[button.id] ? ({
      ...button,
      popover: {
        ...button.popover,
        template: templatesByButtonId[button.id]
      }
    }) : button));
  }

  private updateButtons(type: AuthModuleBranchesFeatureType) {
    this.#buttons.set(branchesAddEditCopyFromBranchConfig[type]?.buttons || branchesAddEditCopyFromBranchConfig.default.buttons);
  }

  initButtons(templatesByButtonId?: { [key: string]: TemplateRef<any>; }) {
    this.authDataService.moduleFeatureByName$(AuthModuleName.Branches, AuthModuleBranchesFeature.Type)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(type => {
        this.updateButtons(type);

        if (templatesByButtonId) {
          this.attachTemplateToButtons(templatesByButtonId);
        }
      });
  }

  onButtonClick({ popover }) {
    if (popover) {
      this.#activePopover.set(popover);
    }
  }

  onCopyFromBranchClose() {
    this.activePopover()?.close();

    this.#activePopover.set(null);
  }

  destroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
