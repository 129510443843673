import { UHereMapBounds, UHereMapLatLng, UHereMapMarkerConfig, UHereMapPathConfig, UHereMapPointCenterConfig } from '@shift/ulib';

export enum MovePassengersError {
  CancelMovePassengers = 'Cancel move passengers'
}

export enum MovePassengersRouteColor {
  Purple = 'purple',
  Green = 'green',
  Blue = 'blue'
}

export enum MovePassengersRouteParticipantType {
  Target = 1,
  Passenger = 3,
  Accompany = 4
}

export enum MovePassengersRouteMapViewStationType {
  Station = 0,
  Target = 10,
  Accompany = 20
}

export interface MovePassengersLegend {
  id: string;
  title: string;
  description?: string;
  iconUrl: string;
}

export interface MovePassengersInfoRoute {
  routeId: number;
  code: number;
  name: string;
  distance: number;
  duration: string;
  color?: MovePassengersRouteColor;
}

export interface MovePassengersRouteColorItem {
  name: MovePassengersRouteColor;
  value: string;
  available: boolean;
}

export interface MovePassengersRouteMapViewWaypoint {
  stopover: boolean;
  latitude: number;
  longitude: number;
}

export class MovePassengersRouteBase {
  routeId: number;
  rideId: number;
  name: string;
  number: number;
  activeDays: number[];
  startTime: string;
  endTime: string;
  totalPassengers: number;
  participants: {
    isTarget: boolean;
    name: string;
    amount: number;
    address: string;
  }[];
  dateFrom: string;
  dateTo: string;
}

export class MovePassengersRoute extends MovePassengersRouteBase {
  color: MovePassengersRouteColor;

  constructor(values: object) {
    super();
    Object.assign(this, values);
  }
}

export class MovePassengersMapData {
  bounds: UHereMapBounds = null;
  fitCenter: {
    position: UHereMapLatLng;
  } = {
      position: null
    };

  coords: {
    lat: number,
    lng: number
  } = {
      lat: 31.768319,
      lng: 35.21371
    };

  zoom = 7;
  markerCenter: {
    position: UHereMapLatLng;
    config: UHereMapPointCenterConfig;
  } = {
      position: null,
      config: {
        zoom: 17
      }
    };

  markers: {
    stations: UHereMapMarkerConfig[]
  } = {
      stations: []
    };

  paths: UHereMapPathConfig[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
