<div class="routes-change-supervisor__wrapper">
  <div class="routes-change-supervisor__header">
    <h4 class="routes-change-supervisor__title">{{config.dictionary.title | translate}}</h4>
  </div>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.rideSupervisor | translate"
  >
    <u-select-s
      [classUInput]="'u-input_arrow-light u-input_light'"  
      [items]="rideSupervisors"
      [formControl]="form.controls['passengerId']"
      [empty]="true"
      [emptyValue]="null"
      [emptyName]="config.dictionary.removeSelect | translate"
      [emptyRemove]="config.dictionary.removeSelect | translate"
      [placeholder]="config.dictionary.select | translate"
    ></u-select-s>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.type | translate"
  >
    <app-routes-period
      [typeFormControl]="form.controls['type']"
      [changes]="form.controls['datesChange'].value"
      [viewportElement]="viewportElement"
      (updatePeriod)="updatePeriod($event)"
      (updateDates)="updateDates($event)"
    ></app-routes-period>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_flex"
    [name]="config.dictionary.comments | translate"
  >
    <textarea
      uTextarea
      [formControl]="form.controls['comment']"
    ></textarea>
  </u-group>
  <div class="routes-change-supervisor__buttons">
    <div class="routes-change-supervisor__button">
      <button
        uButton
        class="routes-change-supervisor__btn"
        [disabled]="!form.dirty"
        [size]="uButtonSize.Small"
        (click)="save()"
      >
        {{config.dictionary.actions.saveAndClose | translate}}
      </button>
    </div>
    <div class="routes-change-supervisor__button">
      <button
        uButton
        class="routes-change-supervisor__btn"
        [disabled]="!form.dirty"
        [size]="uButtonSize.Small"
        (click)="save(routesChangeEmailSendType.SendBackgroundEmail)"
      >
        {{config.dictionary.actions.saveAndSendSC | translate}}
      </button>
    </div>
    <div class="routes-change-supervisor__button">
      <button
        uButton
        class="routes-change-supervisor__btn u-font-weight-medium"
        [disabled]="!form.dirty"
        [size]="uButtonSize.Small"
        (click)="save(routesChangeEmailSendType.GenerateEditableEmail)"
      >
        {{config.dictionary.actions.saveAndEditEmail | translate}}
      </button>
    </div>
  </div>
</div>
