import { ActivityLive } from '@app/activities/models';
import { DaysOfWeek } from '@app/shared/models';
import { RouteLockState } from '@app/route-lock-state/models';
import { RouteSaveStatus, RouteDirection } from '@app/routes/models';

export enum RouteRemplatesInitialDataType {
  AllowedAmountOfDailyRoutes = 'allowedAmountOfDailyRoutes'
}

export enum RouteTemplatesMode {
  Default = 'default',
  Readonly = 'readonly'
}

export interface RouteTemplatesListParams {
  customerId?: number;
  searchText?: string;
  schoolIds?: number[];
  classTypeIds?: number[];
  passengerIds?: number[];
  departmentIds?: number[];
  branchIds?: number[];
  tagIds?: number[];
}

export interface RouteTemplateListItem {
  routeId: number;
  code: string;
  name: string;
  direction: RouteDirection;
  rideDetails: {
    day: DaysOfWeek;
    startTime: string;
    endTime: string
  }[];
  totalPassengers: number;
  shuttleCompanyName: string;
  carTypeName: string;
  locked: boolean;
  customerName: string;
}


export interface RouteTemplatesTableRow extends RouteTemplateListItem {
  id: number;
  saveStatus: RouteSaveStatus;
  lockState: RouteLockState;
  activities: ActivityLive[];
}

export interface RouteTemplatesFromRouteParams {
  routeId: number;
  activeDate: string;
}

export interface RoutesFromTemplatesParams {
  templateIds: number[];
  startDate: string;
  endDate: string;
  withDrivers: boolean;
  withAccompanies: boolean;
  withShuttleCompany: boolean;
  asDailyRoutes: boolean;
}

export interface RouteRemplatesInitialData {
  allowedAmountOfDailyRoutes: number;
}
