import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';

import { ApiService } from '@app/shared/services';
import {
  BulkChangeCustomerData,
  BulkChangeCustomerDataItem,
  BulkChangeCustomerDataItemVehicle,
  BulkChangeCustomerDataType,
  BulkChangeDriversResponse,
  BulkChangeGetDataForRidesBody,
  BulkChangeResponse,
  BulkChangeShuttleCompanyCloseRidesBody,
  BulkChangeShuttleCompanyOpenRidesBody
} from '@app/bulk-change/models';

@Injectable({
  providedIn: 'root'
})
export class BulkChangeService {
  private readonly apiService = inject(ApiService);

  private BASE_PATH: string = 'BulkChange';

  getCustomerData(types?: BulkChangeCustomerDataType[]): Observable<BulkChangeCustomerData> {
    return this.apiService.get(`${this.BASE_PATH}/CustomerData`, { types });
  }

  getVehiclesForRides(body: BulkChangeGetDataForRidesBody): Observable<BulkChangeCustomerDataItemVehicle[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetVehiclesForRides`, body);
  }

  getShuttleCompaniesForRides(body: BulkChangeGetDataForRidesBody): Observable<BulkChangeCustomerDataItem[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetShuttleCompaniesForRides`, body);
  }

  getAccompaniesInPeriod(params: { from: string; to: string; }): Observable<BulkChangeResponse> {
    return this.apiService.get(`${this.BASE_PATH}/Accompanies/InPeriod`, params);
  }

  getDriversInPeriod(params: { from: string; to: string; }): Observable<BulkChangeDriversResponse> {
    return this.apiService.get(`${this.BASE_PATH}/Drivers/InPeriod`, params);
  }

  downloadErrorsExcel(params: { cacheKey: string; }) {
    return this.apiService.getBlob(`${this.BASE_PATH}/DownloadErrorsExcel`, 'blob', params);
  }

  shuttleCompanyCloseRides(body: BulkChangeShuttleCompanyCloseRidesBody): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/ShuttleCompany/CloseRides`, body);
  }

  shuttleCompanyOpenRides(body: BulkChangeShuttleCompanyOpenRidesBody): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/ShuttleCompany/OpenRides`, body);
  }

  saveGenericBulkChange<T>(entityBaseUrl: string, requestType?: string, params?: T): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}${entityBaseUrl}${requestType ? requestType : ''}`, params);
  }
}
