import { UGridNgxDatatable, UGridEditMode, UGridEditType } from '@shift/ulib';

const DICTIONARY_PATH: string = 'shared.citiesPolicy';

export const citiesPolicyComponentConfig = {
  minMaxDurationMinutes: 10,
  dictionary: {
    uGrid: 'uGrid',
    yes: 'general.yes',
    no: 'general.no',
    none: 'general.none',
    withoutAddress: `${DICTIONARY_PATH}.withoutAddress`
  },
  grid: {
    columnMode: UGridNgxDatatable.ColumnMode.force,
    editMode: UGridEditMode.Inline,
    columns: [
      {
        name: `${DICTIONARY_PATH}.cities`,
        prop: 'name',
        minWidth: 128,
        maxWidth: 170,
        resizeable: true
      },
      {
        name: `${DICTIONARY_PATH}.allowRides`,
        prop: 'enabled',
        minWidth: 128,
        maxWidth: 128,
        resizeable: true,
        filterType: 'select',
        cellTemplateName: 'toggleCell',
        filterTypeItemsFnName: 'isAllowedFilterItems',
        filterTypeItemsSearchFnName: 'isAllowedFilterItemsSearch'
      },
      {
        name: `${DICTIONARY_PATH}.maxTravelTime`,
        prop: 'maxDurationMinutes',
        minWidth: 128,
        maxWidth: 128,
        editType: UGridEditType.InputNumber,
        editTypeInputNumber: {
          min: 10,
          max: 600
        },
        cellTemplateName: 'maxTravelTimeCell',
        resizeable: true
      },
      {
        name: `${DICTIONARY_PATH}.allowRidesCentralStations`,
        prop: 'useCityCentralStation',
        minWidth: 128,
        maxWidth: 128,
        resizeable: true,
        filterType: 'select',
        cellTemplateName: 'toggleCell',
        filterTypeItemsFnName: 'isAllowedFilterItems',
        filterTypeItemsSearchFnName: 'isAllowedFilterItemsSearch'
      },
      {
        name: `${DICTIONARY_PATH}.centralStation`,
        prop: 'cityCentralStationAddress.fullAddress',
        minWidth: 170,
        maxWidth: 170,
        editType: UGridEditType.InputAddress,
        editTypeInputAddress: { latProp: 'cityCentralStationAddress.latitude', lngProp: 'cityCentralStationAddress.longitude', placeIdProp: 'cityCentralStationAddress.placeId' },
        resizeable: true,
        cellTemplateName: 'cityCentralStationAddressCell'
      }
    ]
  }
};
