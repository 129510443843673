import { UMenuIcon } from '@shift/ulib';

import { RoutesViewType } from '@app/routes/models';

const DICTIONARY_PATH: string = 'shared.header.routesViewType';

const routesViewTypeIcons: UMenuIcon[] = [
  {
    value: RoutesViewType.Daily,
    name: `${DICTIONARY_PATH}.dailyView`,
    id: 'header-routes-view-type-daily',
    urls: {
      default: '/assets/images/header/routes-view-type/daily.svg',
      hover: '/assets/images/header/routes-view-type/daily-hover.svg'
    },
    config: {
      style: {
        width: 17,
        height: 17
      }
    }
  },
  {
    value: RoutesViewType.Weekly,
    name: `${DICTIONARY_PATH}.weeklyView`,
    id: 'header-routes-view-type-weekly',
    urls: {
      default: '/assets/images/header/routes-view-type/weekly.svg',
      hover: '/assets/images/header/routes-view-type/weekly-hover.svg'
    },
    config: {
      style: {
        width: 17,
        height: 17
      }
    }
  }
];

export const headerRoutesViewTypeComponentConfig = {
  routesViewTypeIcons
};
