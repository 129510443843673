import { AuthModuleDemandsPassengersViewFeatureType } from '@app/auth/models';
import { demandsConfig } from '@app/demands/configs';
import { DemandsView } from '@app/demands/models';

const DICTIONARY_PATH: string = 'demands.day';

const dictionary: { [key: string]: string; } = {
  assignShift: `demands.assignShift.assignShift`,
  nextDay: `${DICTIONARY_PATH}.nextDay`,
  special: `${DICTIONARY_PATH}.special`
};

const demandsDayComponent = {
  dictionaryPath: DICTIONARY_PATH,
  dictionary,
  tracking: demandsConfig.tracking[DemandsView.Passengers].default
};

const demandsDayStudentComponent = {
  ...demandsDayComponent,
  dictionary: {
    ...demandsDayComponent.dictionary,
    assignShift: `demands.assignShift.assignActivity`
  },
  tracking: demandsConfig.tracking[DemandsView.Passengers].activitySchedule
};

const demandsDaySoldierComponent = {
  ...demandsDayComponent
};

export const demandsDayComponentConfig = {
  default: demandsDayComponent,
  [AuthModuleDemandsPassengersViewFeatureType.Student]: demandsDayStudentComponent,
  [AuthModuleDemandsPassengersViewFeatureType.Soldier]: demandsDaySoldierComponent
};
