<u-modal-entity
  class="u-modal-entity_app-route-policy-tabs"
  [title]="''"
  [isRtl]="isRtl"
  [buttons]="config.buttons"
  [formChanged]="isDirty"
  (action)="onAction($event)"
>
  <tabset
    type="pills"
    class="u-tabset-pills u-tabset-pills_app-route-policy-tabs"
  >
    <tab
      #routePolicyTab
      class="route-policy-tabs__tab"
      [active]="selectedTab === routePolicyTabName.Policy"
      [heading]="config.dictionary.routePolicy | translate"
      (selectTab)="tabSelect.emit('routePolicy'); selectTab(routePolicyTabName.Policy)"
    >
      <ng-container
        *ngIf="selectedTab === routePolicyTabName.Policy"
      >
        <u-group
          *ngIf="carsForm"
          class="u-group_large-field u-group_flex u-group_app-route-policy"
          [name]="config.dictionary.availableCarTypes | translate"
        >
          <u-multiselect
            [placeholder]="config.dictionary.select | translate"
            [items]="availableCarTypes"
            [formControl]="carsForm"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [messages]="config.dictionary.uMultiselect | translate"
            [mode]="'counter-pro'"
          ></u-multiselect>
        </u-group>
      
        <app-route-policy
          [form]="form"
        ></app-route-policy>
      </ng-container>
    </tab>

    <tab
      #citiesCombinationsTab
      class="route-policy-tabs__tab route-policy-tabs__tab_cities-combinations-wrapper"
      [active]="selectedTab === routePolicyTabName.CitiesCombinations"
      [heading]="config.dictionary.citiesCombinations | translate"
      (selectTab)="tabSelect.emit('citiesCombinations'); selectTab(routePolicyTabName.CitiesCombinations)"
    >
      <ng-container *ngIf="selectedTab === routePolicyTabName.CitiesCombinations">
        <u-group
          *ngIf="branchForm"
          class="u-group_large-field u-group_flex u-group_app-route-policy u-group_app-route-policy_no-info"
        >
          <u-select-s
            [empty]="false"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [items]="branches"
            [formControl]="branchForm"
          ></u-select-s>
        </u-group>
        <div class="route-policy-tabs__tab-cities-combinations">
          <app-cities-combinations
            [cities]="cities"
            [routePolicyForm]="form"
            [showAddButton]="false"
            [showDeleteButton]="false"
            [showGoToSettings]="citiesCombinationsShowGoToSettings"
            [cityCombinationRulesForm]="citiesCombinationsForm"
          ></app-cities-combinations>
        </div>
      </ng-container>
    </tab>

    <tab
      #citiesPricingTab
      *ngIf="selectedCarTypes"
      class="route-policy-tabs__tab"
      [active]="selectedTab === routePolicyTabName.CitiesPricing"
      [heading]="config.dictionary.citiesPricing | translate"
      (selectTab)="tabSelect.emit('citiesPricing'); selectTab(routePolicyTabName.CitiesPricing)"
    >
      <app-cities-pricing-table
        *ngIf="selectedTab === routePolicyTabName.CitiesPricing"
        [carTypes]="selectedCarTypes"
        [citiesForm]="citiesPricingForm"
      ></app-cities-pricing-table>
    </tab>
  </tabset>
</u-modal-entity>

<div
  class="route-policy-tabs__warning"
  [ngClass]="{ 'route-policy-tabs__warning_small': small }"
>
  <div class="route-policy-tabs__warning-icon"></div>
  <div class="route-policy-tabs__warning-text">
    {{config.dictionary.attention | translate}}
  </div>
</div>
