import { BuilderRideOrderConfirmationItemProp, BuilderSectionIconValue } from '@app/builder/models';

import { UMenuIcon } from '@shift/ulib';

const DICTIONARY_PATH: string = 'builder';

const sectionIcon: UMenuIcon = {
  value: BuilderSectionIconValue.Details,
  name: `${DICTIONARY_PATH}.common.sections.directionAndTime`,
  id: 'builder-section-icon-details',
  urls: {
    default: '/assets/images/builder/triangle.svg',
    hover: '/assets/images/builder/triangle-black.svg'
  },
  config: {
    style: {
      width: 12,
      height: 16
    },
    tooltip: {
      placement: {
        ltr: [ 'top' ],
        rtl: [ 'top' ]
      }
    }
  }
};

const sectionIcons: UMenuIcon[] = [
  sectionIcon,
  {
    ...sectionIcon,
    value: BuilderSectionIconValue.Stations,
    id: 'builder-section-icon-stations',
    name: `${DICTIONARY_PATH}.common.sections.stations`
  },
  {
    ...sectionIcon,
    value: BuilderSectionIconValue.SC,
    id: 'builder-section-icon-sc',
    name: `${DICTIONARY_PATH}.common.sections.shuttleCompany`
  }
];

export const builderMainComponentConfig = {
  trackingRideOrders: 'Ride Orders',
  trackingRouteAddId: 'Route Add',
  trackingRouteEditId: 'Route Edit',
  sectionIcons,
  dictionary: {
    invalidForm: 'general.invalidForm',
    errorCode: 'general.errorCode',
    calculation: `${DICTIONARY_PATH}.calculation.title`,
    newRideOrder: `${DICTIONARY_PATH}.common.newRideOrder`,
    backToOrderDetails: `${DICTIONARY_PATH}.common.backToOrderDetails`,
    continue: `${DICTIONARY_PATH}.common.continue`,
    saveAndSend: `${DICTIONARY_PATH}.common.saveAndSend`,
    expandView: `${DICTIONARY_PATH}.common.expandView`,
    minimizeView: `${DICTIONARY_PATH}.common.minimizeView`
  },
  rideOrderConfirmationItemProps: [ BuilderRideOrderConfirmationItemProp.Disabled, BuilderRideOrderConfirmationItemProp.Readonly ]
};
