import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UHolidaysItem, UHolidaysLoaderService } from '@shift/ulib';

import { HolidaysService } from '@app/shared/services/api';

@Injectable({
  providedIn: 'root'
})
export class HolidaysLoaderService implements UHolidaysLoaderService {
  private readonly holidaysService = inject(HolidaysService);

  fetchItems(year: string): Observable<UHolidaysItem[]> {
    return this.holidaysService.getCalendar(year);
  }
}
