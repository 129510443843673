const DICTIONARY_PATH: string = 'builder.addPassengerToExistStation';

export const builderAddPassengerToExistStationComponentConfig = {
  trackingId: 'Add to an existing station',
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    selectStation: `${DICTIONARY_PATH}.selectStation`,
    selectDestination: `${DICTIONARY_PATH}.selectDestination`,
    addPassengerAssignedDestination: `${DICTIONARY_PATH}.addPassengerAssignedDestination`,
    station: `${DICTIONARY_PATH}.station`,
    destination: `${DICTIONARY_PATH}.destination`,
    save: 'general.save',
    uSelectS: 'uSelectS'
  }
};
