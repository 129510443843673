<div class="routes-daily-fine__header">
  <span class="routes-daily-fine__header-title">
    {{(config.dictionary.title | translate) + ' - ' + data.name}}
  </span>
  <span
    class="routes-daily-fine__header-close"
    (click)="closeModal()"
  ></span>
</div>
<div class="routes-daily-fine__filter">
  <label class="routes-daily-fine__filter-title">{{config.dictionary.date | translate}}</label>
  <div class="routes-daily-fine__filter-item">
    <u-input-date
      [container]="'body'"
      [showIcon]="false"
      [classUInput]="'u-input_text-center u-input_cursor-pointer'"
      [formControl]="fineForm.get('rideDate')"
      [disableDaysBefore]="data.routeStartDate"
      [disableDaysAfter]="data.routeEndDate"
      [placement]="[ 'bottom-right' ]"
      [valid]="fineForm.get('rideDate').valid"
      [messages]="config.dictionary.uInputDate | translate"
    ></u-input-date>
  </div>
</div>
<div class="routes-daily-fine__filter">
  <label class="routes-daily-fine__filter-title">{{config.dictionary.shuttleCompany | translate}}</label>
  <div class="routes-daily-fine__filter-item">
    <input
      uInput
      type="text"
      [value]="shuttleCompanyName || (config.dictionary.noShuttleCompany | translate)"
      [disabled]="true"
    >
  </div>
</div>
<div class="routes-daily-fine__filter">
  <label class="routes-daily-fine__filter-title">{{config.dictionary.contract | translate}}</label>
  <div class="routes-daily-fine__filter-item">
    <u-select-s
      [adaptive]="true"
      [empty]="true"
      [emptyName]="config.dictionary.select | translate"
      [emptyRemove]="config.dictionary.removeSelect | translate"
      [messages]="config.dictionary.uSelectS | translate"
      [items]="contractsList"
      [formControl]="fineForm.get('contractNumber')"
      [classUDropdownContent]="'u-dropdown-content_app-fines-lg'"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [placeholder]="config.dictionary.selectPlaceholder | translate"
      [valid]="fineForm.get('contractNumber').valid"
    ></u-select-s>
  </div>
</div>
<div class="routes-daily-fine__filter">
  <label class="routes-daily-fine__filter-title">{{config.dictionary.violation | translate}}</label>
  <div class="routes-daily-fine__filter-item">
    <u-select-s
      class="u-select-s_app-fine"
      [container]="'body'"
      [messages]="config.dictionary.uSelectS | translate"
      [classUDropdownContent]="'u-dropdown-content_app-fines-lg'"
      [items]="finesList"
      [formControl]="fineForm.get('fineId')"
      [placeholder]="config.dictionary.selectPlaceholder | translate"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [empty]="true"
      [emptyName]="config.dictionary.select | translate"
      [emptyRemove]="config.dictionary.removeSelect | translate"
      [valid]="fineForm.get('fineId').valid"
    ></u-select-s>
  </div>
</div>
<div class="routes-daily-fine__filter">
  <label class="routes-daily-fine__filter-title">{{config.dictionary.amount | translate}}</label>
  <div class="routes-daily-fine__filter-item routes-daily-fine__filter-item_price">
    <input
      uInput
      type="text"
      [value]="fineForm.get('price') && fineForm.get('price').value + ' ₪'"
      [disabled]="true"
    >
  </div>
</div>
<div class="routes-daily-fine__filter">
  <label class="routes-daily-fine__filter-title">{{config.dictionary.comment | translate}}</label>
  <div class="routes-daily-fine__filter-item">
      <textarea
        class="routes-daily-fine__filter_comment"
        name="text"
        [formControl]="fineForm.get('comment')"
        [placeholder]="config.dictionary.commentPlaceholder | translate"
      ></textarea>
  </div>
</div>
<button
  type="button"
  class="routes-daily-fine__apply"
  [disabled]="fineForm.invalid"
  (click)="saveFine()"
>
  {{config.dictionary.save | translate}}
</button>
