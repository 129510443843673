import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UPopupService } from '@shift/ulib';

import { TrackingService } from '@app/shared/services';
import { RoutesChangeCarTypeConfirmAction } from '@app/routes/models';
import { routesConfig } from '@app/routes/configs';

@Injectable({
  providedIn: 'root'
})
export class RoutesChangeDataService {
  constructor(
    private uPopupService: UPopupService,
    private trackingService: TrackingService
  ) {}

  changeCarTypeConfirm(message: string, trackingId: string): Observable<RoutesChangeCarTypeConfirmAction> {
    return new Observable(subscriber => {
      this.uPopupService.showMessage({
        message,
        yes: routesConfig.dictionary.changeCarTypeConfirm.ok,
        no: routesConfig.dictionary.changeCarTypeConfirm.cancel
      },
      () => {
        this.trackingService.track(`[${trackingId}] - planned car type - edit dialog - car larger than executing popup - click on OK`);

        subscriber.next(RoutesChangeCarTypeConfirmAction.Ok);
        subscriber.complete();
      },
      () => {
        subscriber.next(RoutesChangeCarTypeConfirmAction.Cancel);
        subscriber.complete();
      });
    });
  }
}
