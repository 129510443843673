import { DaysOfWeek } from '@app/shared/models';
import { DemandsDayOfWeek, DemandsView } from '@app/demands/models';

const daysOfWeek: DemandsDayOfWeek[] = [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ];

export const demandsConfig = {
  tracking: {
    [DemandsView.Passengers]: {
      default: {
        id: 'Demands - Passengers view',
        branch: 'branch',
        shift: 'shift',
        passenger: 'passenger'
      },
      activitySchedule: {
        id: 'Activity Schedule - Passengers view',
        branch: 'center',
        shift: 'activity',
        passenger: 'passenger'
      }
    },
    [DemandsView.Shifts]: {
      default: {
        id: 'Demands - Shifts view',
        branch: 'branch',
        shift: 'shift',
        passenger: 'passenger'
      },
      activitySchedule: {
        id: 'Activity Schedule - Shifts view',
        branch: 'center',
        shift: 'activity',
        passenger: 'passenger'
      }
    }
  },
  daysOfWeek,
  dictionaryPath: 'demands',
  shiftHeight: 83,
  rowPadding: 14,
  storageKey: 'demands',
  dictionary: {
    yes: 'general.yes',
    no: 'general.no'
  }
};
