import { AuthCustomerType } from '@app/auth/models';
import { EnvType } from '@app/shared/models';

const markerBase = {
  icon: {
    width: 48,
    height: 48
  },
  label: {
    style: {
      bottom: '12px'
    }
  }
};

const stationMarker = {
  ...markerBase,
  label: {
    ...markerBase.label,
    style: {
      ...markerBase.label.style,
      color: '#000'
    }
  },
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/point-station.svg'
  }
};

const stationMarkerOrange = {
  ...stationMarker,
  icon: {
    ...stationMarker.icon,
    url: '/assets/images/themes/orange/map/point-station.svg'
  }
};

const stationMarkerGreen = {
  ...stationMarker,
  icon: {
    ...stationMarker.icon,
    url: '/assets/images/themes/green/map/point-station.svg'
  }
};

const destinationMarker = {
  ...markerBase,
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/point-destination.svg'
  }
};

const multipleDestinationMarker = {
  ...markerBase,
  label: {
    ...markerBase.label,
    style: {
      ...markerBase.label.style,
      color: '#000'
    }
  },
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/point-multiple-destination.svg'
  }
};

const accompanyMarker = {
  ...markerBase,
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/point-accompany.svg'
  }
};

const supervisorMarker = {
  ...markerBase,
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/point-supervisor.svg'
  }
};

const defaultMarkers = {
  station: stationMarker,
  accompany: accompanyMarker,
  destination: destinationMarker,
  multipleDestination: multipleDestinationMarker,
  supervisor: supervisorMarker
};

const orangeMarkers = {
  ...defaultMarkers,
  station: stationMarkerOrange
};

const greenMarkers = {
  ...defaultMarkers,
  station: stationMarkerGreen
};

const markersByEnvTypes = {
  default: defaultMarkers,
  [EnvType.Traffical]: orangeMarkers,
  [EnvType.IDF]: orangeMarkers
};

export const mapConfig = {
  coords: {
    lat: 31.768319,
    lng: 35.21371
  },
  zoom: 7,
  maxZoom: 18,
  pathColors: {
    main: '#00000',
    accompany: '#02C0FF'
  },
  markers: {
    default: markersByEnvTypes,
    [AuthCustomerType.ShuttleCompany]: {
      ...markersByEnvTypes,
      [EnvType.Shift]: greenMarkers
    }
  }
};
