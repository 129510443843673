import { AuthCustomerType } from '@app/auth/models';
import { MovePassengersRouteColorItem, MovePassengersRouteColor, MovePassengersRouteMapViewStationType, EnvType } from '@app/shared/models';

const routesColors: MovePassengersRouteColorItem[] = [
  { name: MovePassengersRouteColor.Purple, value: '#AA00FF', available: true },
  { name: MovePassengersRouteColor.Green, value: '#00A2A2', available: true },
  { name: MovePassengersRouteColor.Blue, value: '#007EF0', available: true }
];

const markerBase = {
  icon: {
    width: 48,
    height: 48
  },
  label: {
    style: {
      bottom: '12px'
    }
  }
};

const stationMarker = {
  ...markerBase,
  label: {
    ...markerBase.label,
    style: {
      ...markerBase.label.style,
      color: '#000'
    }
  },
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/station.svg'
  }
};

const stationMarkerOrange = {
  ...stationMarker,
  icon: {
    ...stationMarker.icon,
    url: '/assets/images/themes/orange/map/point-station.svg'
  }
};

const stationMarkerGreen = {
  ...stationMarker,
  icon: {
    ...stationMarker.icon,
    url: '/assets/images/themes/green/map/point-station.svg'
  }
};

const destinationMarker = {
  ...markerBase,
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/destination.svg'
  }
};

const accompanyMarker = {
  ...markerBase,
  icon: {
    ...markerBase.icon,
    url: '/assets/images/map/point-accompany.svg'
  }
};

const defaultStationMarkerByEnvTypes = {
  base: stationMarker,
  [EnvType.Traffical]: stationMarkerOrange,
  [EnvType.IDF]: stationMarkerOrange
};

export const movePassengersConfig = {
  routesColors,
  map: {
    zIndex: {
      sourceRoute: 2,
      commonRoute: 1
    },
    path: {
      colors: {
        main: '#00000',
        accompany: '#02C0FF'
      },
      lineWidth: 3
    },
    markers: {
      [MovePassengersRouteMapViewStationType.Station]: {
        default: {
          default: defaultStationMarkerByEnvTypes,
          [AuthCustomerType.ShuttleCompany]: {
            ...defaultStationMarkerByEnvTypes,
            [EnvType.Shift]: stationMarkerGreen
          }
        },
        [MovePassengersRouteColor.Purple]: {
          ...stationMarker,
          label: {
            ...stationMarker.label,
            style: {
              ...stationMarker.label.style,
              color: '#AA00FF'
            }
          },
          icon: {
            ...stationMarker.icon,
            url: '/assets/images/map/station-purple.svg'
          }
        },
        [MovePassengersRouteColor.Green]: {
          ...stationMarker,
          label: {
            ...stationMarker.label,
            style: {
              ...stationMarker.label.style,
              color: '#00A2A2'
            }
          },
          icon: {
            ...stationMarker.icon,
            url: '/assets/images/map/station-green.svg'
          }
        },
        [MovePassengersRouteColor.Blue]: {
          ...stationMarker,
          label: {
            ...stationMarker.label,
            style: {
              ...stationMarker.label.style,
              color: '#007EF0'
            }
          },
          icon: {
            ...stationMarker.icon,
            url: '/assets/images/map/station-blue.svg'
          }
        }
      },
      [MovePassengersRouteMapViewStationType.Target]: {
        default: {
          default: {
            base: destinationMarker
          }
        },
        [MovePassengersRouteColor.Purple]: {
          ...destinationMarker,
          icon: {
            ...destinationMarker.icon,
            url: '/assets/images/map/destination-purple.svg'
          }
        },
        [MovePassengersRouteColor.Green]: {
          ...destinationMarker,
          icon: {
            ...destinationMarker.icon,
            url: '/assets/images/map/destination-green.svg'
          }
        },
        [MovePassengersRouteColor.Blue]: {
          ...destinationMarker,
          icon: {
            ...destinationMarker.icon,
            url: '/assets/images/map/destination-blue.svg'
          }
        }
      },
      [MovePassengersRouteMapViewStationType.Accompany]: {
        default: {
          default: {
            base: accompanyMarker
          }
        }
      }
    }
  }
};
