export const authConfig = {
  clientId: 'shift-web',
  responseType: 'code',
  scope: 'openid offline_access shift_web_api',
  sessionStorageKeys: {
    'access_token': 'at',
    'refresh_token': 'rt'
  },
  callbackUrls: [ 'signin-callback', 'signout-callback' ],
  userProfileFields: [ 'customer', 'person', 'optionalModules', 'modules', 'policies', 'permissions' ],
  accessTokenExtraTime: 60
};
