import { URangePreset } from '@shift/ulib';

import { DaysOfWeek } from '@app/shared/models';
import {
  RoutesCancellationBillingType,
  RoutesCancellationChargeType
} from '@app/routes/models';
import { PassengerPickUpType } from '@app/passengers/models';
import { RideTimeType } from './rides.models';

export enum RoutesChangeEmailSendType {
  SendBackgroundEmail = 'sendBackgroundEmail',
  GenerateEditableEmail = 'generateEditableEmail'
}

export enum RoutesChangeViewType {
  Accompany,
  Hour,
  Passenger,
  ShuttleCompany,
  Car,
  Supervisor,
  TimeTracking,
  AccompanyCost
}

export enum RoutesChangeAccompanyType {
  Remove = 'remove',
  Without = 'without',
  Required = 'required'
}

export enum RoutesChangeAccompanyCostCalculationCostType {
  Fixed,
  Hourly
}

export enum RoutesChangeAccompanyCostCalculationHoursPriceOption {
  Manual,
  Auto
}

export enum RoutesChangeAccompanyReturn {
  No,
  Yes
}

export enum RoutesChangesAccompanySourceStationType {
  Home,
  FirstStation
}

export interface RoutesChangePresetsType {
  planned: URangePreset;
  fixed: URangePreset;
}

export interface RoutesChangeOptions {
  timeType: RideTimeType.StartTime | RideTimeType.EndTime;
}

export enum RoutesChangeType {
  Planned = 1,
  Unplanned
}

export enum RoutesChangeCarTypeConfirmAction {
  Ok = 'ok',
  Cancel = 'cancel'
}

export interface RoutesChangeCarTypeBody {
  routeId: number;
  activeDate: string;
  value: {
    comment: string;
    type: RoutesChangeType;
    dateFrom: string;
    dateTo: string;
    days: DaysOfWeek[];
    carTypeId: number;
    allowExceedVehicleCapacity?: boolean;
  };
  sendBackgroundEmail?: boolean;
  generateEditableEmail?: boolean;
}

export interface RoutesChangeCarBody {
  routeId: number;
  value: {
    dateFrom: string;
    dateTo: string;
    days: DaysOfWeek[];
    type: RoutesChangeType;
    shuttleCompanyId: number;
    carId: number;
  };
}


export interface RoutesChangeValueBase {
  dateFrom: string;
  dateTo: string;
  days: DaysOfWeek[];
  comment: string;
  type: RoutesChangeType;
}

export interface RoutesChangeValueMultiplePeriodsBase {
  periods: {
    dateFrom: string;
    dateTo: string;
    days: DaysOfWeek[];
    comment?: string;
  }[];
  type: RoutesChangeType;
}

export interface RoutesChangeBaseParams {
  routeId: number;
  activeDate: string;
  sendBackgroundEmail: boolean;
  generateEditableEmail: boolean;
}

export interface RoutesChangeBaseDatesParams extends RoutesChangeBaseParams {
  value: RoutesChangeValueBase;
}

export interface RoutesChangeBaseMultiplePeriodsParams extends RoutesChangeBaseParams {
  value: RoutesChangeValueMultiplePeriodsBase;
}

export interface RoutesChangeCancelRideValue extends RoutesChangeValueMultiplePeriodsBase {
  cancellationBillingData: RoutesChangeCancellationBillingData;
}

export interface RoutesChangeCancellationBillingData {
  billingType: RoutesCancellationBillingType;
  cancellationClauseId: number;
  chargeType: RoutesCancellationChargeType;
  chargeAmount: number;
}

export interface RoutesChangeCancelRideParams extends RoutesChangeBaseMultiplePeriodsParams {
  value: RoutesChangeCancelRideValue;
}

export interface RoutesChangeShuttleSettingsValue extends RoutesChangeValueBase {
  updateShuttleCompany: boolean;
  updateDriver: boolean;
  updateCar: boolean;
  shuttleCompanyId: number;
  driverId: number;
  carId: number;
  allowSubceedCarTypeCapacity?: boolean;
}

export interface RoutesChangeShuttleSettingsParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeShuttleSettingsValue;
}

export interface RoutesChangeAccompanyValue extends RoutesChangeValueBase {
  accompanyId: number;
  sourceType: RoutesChangesAccompanySourceStationType;
  returnHome: boolean;
}

export interface RoutesChangeAccompanyParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeAccompanyValue;
}

export interface RoutesChangeAccompanyCostParams {
  routeId: number;
  activeDate: string;
  sendBackgroundEmail: boolean;
  generateEditableEmail: boolean;
  value: {
    dateFrom: string;
    dateTo: string;
    days: DaysOfWeek[],
    type: RoutesChangeType;
    comment: string,
    costType: number;
    costPerHour: number;
    hours: number;
    totalCost: number;
    accompanyId: number;
  }
}

export interface RoutesChangeAccompanySetRequiredValue extends RoutesChangeValueBase {
  resetRequiredFlag: boolean;
}

export interface RoutesChangeAccompanySetRequiredParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeAccompanySetRequiredValue;
}

export interface RoutesChangeAddSupervisorValue extends RoutesChangeValueBase {
  passengerId: number;
}

export interface RoutesChangeAddSupervisorParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeAddSupervisorValue;
}

export interface RoutesChangeSetSupervisorParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeSetSupervisorValue;
}

export interface RoutesChangeSetSupervisorValue extends RoutesChangeValueBase {
  rideId: number;
  passengerId: number;
}

export interface RoutesChangeTimeValue extends RoutesChangeValueBase {
  time: string;
  timeType: RideTimeType;
}

export interface RoutesChangeTimeParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeTimeValue;
}

export interface RoutesChangeRemoveShuttleCompanyValue extends RoutesChangeValueBase {
  shuttleCompanyId: number;
}

export interface RoutesChangeRemoveShuttleCompanyParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeRemoveShuttleCompanyValue;
}

export interface RoutesChangeRemovePassengerValue extends RoutesChangeValueBase {
  passengerId: number;
  removeStation: boolean;
  removeTarget: boolean;
  cancelRideIfNotValid: boolean;
  unApproveRides: boolean;
  optimizeVehicleCapacity: boolean;
  passengerIds: number[];
}

export interface RoutesChangeRemovePassengerParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeRemovePassengerValue;
}

export interface RoutesChangeAddPassengerValue extends RoutesChangeValueBase {
  passengerId: number;
  isNextYearMode: boolean;
  pickUpType: PassengerPickUpType;
  passengerIds: number[];
}

export interface RoutesChangeAddPassengerParams extends RoutesChangeBaseDatesParams {
  value: RoutesChangeAddPassengerValue;
}

export interface RoutesChangeRestoreRideValue extends RoutesChangeValueMultiplePeriodsBase {
  restoreEmpty: boolean;
}

export interface RoutesChangeRestoreRideParams extends RoutesChangeBaseMultiplePeriodsParams {
  value: RoutesChangeRestoreRideValue;
}

export interface RoutesChangeBidNumberParams extends RoutesChangeBaseParams {
  value: RoutesChangeBidNumberValue;
}

export interface RoutesChangeBidNumberValue {
  bidNumber: string;
}

export interface RoutesChangeTimeTrackBody {
  routeId: number;
  activeDate: string;
  value: {
    rideId: number;
    comment?: string;
    startDateTime?: string;
    endDateTime?: string;
  };
}
