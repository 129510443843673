import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output, ViewRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { RideOrderStatus } from '@app/ride-orders/models';
import { BuilderRideOrderConfirmationItem } from '@app/builder/models';

@Component({
  selector: 'app-builder-ride-order-confirmation',
  templateUrl: './builder-ride-order-confirmation.component.html',
  styleUrls: [ './builder-ride-order-confirmation.component.scss', './builder-ride-order-confirmation.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BuilderRideOrderConfirmationComponent,
      multi: true
    }
  ]
})
export class BuilderRideOrderConfirmationComponent implements ControlValueAccessor {
  @Input() readonly: boolean;
  @Input() items: BuilderRideOrderConfirmationItem[] = [];

  @Output() approve: EventEmitter<void> = new EventEmitter();
  @Output() reject: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-ride-order-confirmation';

  disabled: boolean;
  selectedStatus: RideOrderStatus;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {}

  private updateValue(value: RideOrderStatus) {
    this.selectedStatus = value;

    this.writeValue(value);
    this.propagateChange(value);
    this.dispatchActionEvent();
  }

  private dispatchActionEvent() {
    const eventsByStatus = {
      [RideOrderStatus.Approved]: this.approve,
      [RideOrderStatus.Rejected]: this.reject
    };

    eventsByStatus[this.selectedStatus]?.emit();
  }

  private detectChanges() {
    if (!(<ViewRef>this.cdRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  writeValue(status: RideOrderStatus) {
    this.selectedStatus = status;

    this.detectChanges();
  }

  registerOnChange(fn: (_: RideOrderStatus) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: RideOrderStatus) => void) {
    this.propagateTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;

    this.detectChanges();
  }

  propagateChange = (_: RideOrderStatus) => {};

  propagateTouched = (_: RideOrderStatus) => {};

  updateSelectedStatus(status: RideOrderStatus) {
    this.updateValue(this.selectedStatus === status ? RideOrderStatus.WaitForApproval : status);
  }
}
