import { createReducer, on } from '@ngrx/store';

import {
  RouteDailyItem,
  RoutesDailyParams,
  RoutesDailyFilterType
} from '@app/routes/models';
import * as RoutesActions from '@app/routes/state/actions';

export const FEATURE_KEY: string = 'daily';

export interface State {
  inited: boolean;
  selectedFilters: {
    type: RoutesDailyFilterType;
  };
  params: RoutesDailyParams;
  items: RouteDailyItem[];
}

export const initialState: State = {
  selectedFilters: {
    type: RoutesDailyFilterType.DailyRoutes
  },
  inited: false,
  params: null,
  items: null
};

export const reducer = createReducer(
  initialState,
  on(
    RoutesActions.dailyInited,
    (state) => ({
      ...state,
      inited: true
    })
  ),
  on(
    RoutesActions.dailyLoad,
    (state, { params }) => ({
      ...state,
      params
    })
  ),
  on(
    RoutesActions.dailyLoadSuccess,
    (state, { items }) => ({ ...state, items: items?.map(item => ({ ...item })) })
  ),
  on(
    RoutesActions.dailyLoadActivities,
    (state, { items }) => ({
      ...state,
      items: state.items && state.items.map(item => {
        const itemUpdate = items.find(obj => obj.routeId === item.routeId);

        return {
          ...item,
          activities: itemUpdate && itemUpdate.activities || []
        };
      })
    })
  ),
  on(
    RoutesActions.dailyLoadMonitoringData,
    (state, { items }) => ({
      ...state,
      items: state.items && state.items.map(item => {
        const itemUpdate = items.find(obj => obj.rideId === item.rideId);

        return itemUpdate ? { ...item, ...itemUpdate } : item;
      })
    })
  ),
  on(
    RoutesActions.dailyClearItemsSaveStatus,
    (state, { routeIds }) => ({
      ...state,
      items: state.items && state.items.map(item => routeIds.includes(item.routeId) ? { ...item, saveStatus: null } : item)
    })
  ),
  on(
    RoutesActions.dailyUpdateItems,
    (state, { items }) => ({
      ...state,
      items: state.items && state.items.map(item => {
        const itemUpdate = items.find(obj => obj.routeId === item.routeId);

        return itemUpdate ? { ...item, ...itemUpdate } : item;
      })
    })
  ),
  on(
    RoutesActions.dailyDeleteItems,
    (state, { routeIds }) => ({
      ...state,
      items: state.items && state.items.filter(item => !routeIds.includes(item.routeId))
    })
  ),
  on(
    RoutesActions.dailyActivitiesUpdateItems,
    (state, { items }) => ({
      ...state,
      items: state.items && state.items.map(item => {
        const itemUpdate = items.find(obj => obj.routeId === item.routeId);

        return itemUpdate ? { ...item, ...itemUpdate } : item;
      })
    })
  ),
  on(
    RoutesActions.dailyUpdateRideStatus,
    (state, { data }) => ({
      ...state,
      items: state.items && state.items.map(item => item.rideId === data.id ? { ...item, rideStatus: data.status } : item)
    })
  ),
  on(
    RoutesActions.dailyLoadNotes,
    (state, { items }) => ({
      ...state,
      items: state.items && state.items.map(item => {
        const routeNoteState = items.find(obj => obj.routeId === item.routeId);

        if (routeNoteState) {
          return {
            ...item,
            notes: {
              anyNote: routeNoteState.anyNote,
              unReadNoteIds: routeNoteState.unReadNoteIds
            }
          };
        }

        return item;
      })
    })
  ),
  on(
    RoutesActions.dailyUpdateRouteNoteAdded,
    (state, { data }) => ({
      ...state,
      items: state.items && state.items.map((item: RouteDailyItem) => {
        if (data && item.routeId === data.routeId) {
          return {
            ...item,
            notes: {
              ...item.notes,
              anyNote: true,
              unReadNoteIds: data && data.createdByDifferentUser ? [ ...item.notes.unReadNoteIds, data.noteId ] : item.notes.unReadNoteIds
            }
          };
        }

        return item;
      })
    })
  ),
  on(
    RoutesActions.dailyUpdateRouteNotesRead,
    (state, { data }) => ({
      ...state,
      items: state.items && state.items.map((item: RouteDailyItem) => {
        if (data && item.routeId === data.routeId) {
          return {
            ...item,
            notes: {
              ...item.notes,
              unReadNoteIds: item.notes.unReadNoteIds.filter(noteId => !data.noteIds.includes(noteId))
            }
          };
        }

        return item;
      })
    })
  ),
  on(
    RoutesActions.dailyUpdateRouteNoteRemoved,
    (state, { data }) => ({
      ...state,
      items: state.items && state.items.map((item: RouteDailyItem) => {
        if (data && item.routeId === data.routeId) {
          return {
            ...item,
            notes: {
              ...item.notes,
              anyNote: data.anyNote,
              unReadNoteIds: item.notes.unReadNoteIds.filter(noteId => noteId !== data.noteId)
            }
          };
        }

        return item;
      })
    })
  ),
  on(
    RoutesActions.dailyUpdateRouteNoteUpdated,
    (state, { data }) => ({
      ...state,
      items: state.items && state.items.map((item: RouteDailyItem) => {
        if (data && item.routeId === data.routeId) {
          return {
            ...item,
            notes: {
              ...item.notes,
              anyNote: true,
              unReadNoteIds: data && data.modifiedByDifferentUser ?
                (item.notes.unReadNoteIds.includes(data.noteId) ? item.notes.unReadNoteIds : [ data.noteId, ...item.notes.unReadNoteIds ]) :
                item.notes.unReadNoteIds
            }
          };
        }

        return item;
      })
    })
  ),
  on(
    RoutesActions.dailyLoadDriverApprovals,
    (state, { items }) => ({
      ...state,
      items: state.items && state.items.map(item => {
        const driverApprovals = items.find(obj => obj.rideId === item.rideId);

        return driverApprovals ? {
          ...item,
          ...(item.driver && {
            driver: {
              ...item.driver,
              approvals: {
                actual: driverApprovals.actualApprovals,
                expected: driverApprovals.expectedApprovals,
                rejected: driverApprovals.hasRejectedApproval
              }
            }
          })
        } : item;
      })
    })
  ),
  on(
    RoutesActions.dailyUpdateDriverApprovals,
    (state, { data }) => ({
      ...state,
      items: state.items && state.items.map(
        item => item.routeId === data.routeId && item.driver ? {
          ...item,
          driver: {
            ...item.driver,
            approvals: {
              ...item.driver.approvals,
              rejected: data.isRejected,
              actual: data.actualApprovalsCount
            }
          }
        } : item
      )
    })
  ),
  on(
    RoutesActions.dailySelectedFiltersUpdate,
    (state, { filters }) => ({
      ...state,
      selectedFilters: { ...state.selectedFilters, ...filters }
    })
  ),
  on(
    RoutesActions.dailyItemsReset,
    (state) => ({
      ...state,
      items: null
    })
  ),
  on(
    RoutesActions.dailyReset,
    () => ({
      ...initialState
    })
  )
);
