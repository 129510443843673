import { UHereMapCoordinates } from '@shift/ulib';

import { GeneralButton } from '@app/shared/models/general.models';

export enum AreasButtonId {
  NewArea = 'NewArea'
}

export class Area {
  cityId: number;
  cityName: string;
  cityArea: {
    id: number;
    name: string;
    polygon?: UHereMapCoordinates[];
  };
}

export class Areas {
  areas: Area[];
  cities: { id: number; name: string; }[];
  map: {
    passengers: AreasMapPassenger[];
    targets: AreasMapTarget[];
  };
}

export interface AreasMapPassenger {
  id: number;
  name: string;
  targetId: number;
  latitude: number;
  longitude: number;
  fullAddress: string;
  addressId: number;
  cityId: number;
}

export interface AreasMapTarget {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  fullAddress: string;
  addressId: number;
  cityId: number;
}


export interface AreasButton extends Omit<GeneralButton, 'id'> {
  id: AreasButtonId;
}
