<ng-container
  *ngIf="[ builderBuildMode.Routes, builderBuildMode.RouteTemplates, builderBuildMode.RideOrders ] | includes: builderDataService.config().buildMode"
>
  <div class="builder-default__top">
    <button
      uButton
      *ngIf="builderRoutesStoreService.gridColumns().filtered"
      class="builder-default__top-reset-filter"
      (click)="builderRoutesStoreService.resetColumnsFilterChange(true)"
    >
      {{'builder.top.clearFilters' | translate}}
    </button>
  </div>
  <div class="builder-default__split">
    <u-split
      *ngIf="config.splitPanels"
      [useTransition]="true"
      [dir]="isRtl ? 'rtl' : 'ltr'"
      direction="horizontal"
    >
      <u-split-area
        *ngIf="builderDataService.config().buildMode !== builderBuildMode.RideOrders"
        class="builder-default__grid u-split-area-layout u-split-area-layout_app-default"
        [size]="config.splitPanels.routes.size"
        [minSize]="config.splitPanels.routes.minSize"
        [panelName]="config.splitPanels.routes.panelName"
        [order]="1"
      >
        <app-builder-routes></app-builder-routes>
      </u-split-area>
      <u-split-area
        class="builder-default__main"
        [ngClass]="{ 'builder-default__main_expanded': (builderCommonService.isExpanded$ | async) === true }"
        [size]="config.splitPanels.main.size"
        [minSize]="config.splitPanels.main.minSize"
        [panelName]="builderDataService.config().buildMode !== builderBuildMode.RideOrders && config.splitPanels.main.panelName"
        [order]="2"
      >
        <app-builder-main></app-builder-main>
      </u-split-area>
    </u-split>
  </div>
</ng-container>
<app-builder-main *ngIf="builderDataService.config().buildMode === builderBuildMode.RouteSuggestions"></app-builder-main>
