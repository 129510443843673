import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingDataService {
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private refresh: BehaviorSubject<void> = new BehaviorSubject(null);

  refresh$: Observable<void> = this.refresh.asObservable();

  getLoadingValue() {
    return this.loading.getValue();
  }

  updateLoading(value: boolean) {
    this.loading.next(value);
  }

  updateRefresh() {
    this.refresh.next();
  }
}
