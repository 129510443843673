<u-grid
  class="u-grid_app-pricing-table-cities"
  [rows]="citiesPricingTableService.citiesRows.value"
  [columns]="citiesPricingTableService.citiesColumns"
  [columnMode]="'force'"
  [headerHeight]="45"
  [headerOffset]="10"
  [footerHeight]="0"
  [rowHeight]="55"
  [reorderable]="true"
  [scrollbarV]="true"
  [scrollbarH]="true"
  [isRtl]="localizationService.isRtl()"
  [editMode]="'cell'"
  [showColumnsFilter]="true"
  [messages]="config.dictionary.uGrid | translate"
  [tableName]="config.tableName"
  (editValuesClose)="onCitiesEditValuesClose($event)">
</u-grid>
