import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { UDateRangePreset, UDateRangePresetsMode } from '@shift/ulib';
import * as moment from 'moment';

import { AppConstants } from '@app/shared/constants';
import { LocalizationService } from '@app/shared/services';
import { DemandsExcelExport } from '@app/demands/models';
import { demandsExcelExportComponentConfig } from './demands-excel-export.component.config';

@Component({
  selector: 'app-demands-excel-export',
  templateUrl: './demands-excel-export.component.html',
  styleUrls: [ './demands-excel-export.component.scss', './demands-excel-export.component.rtl.scss' ]
})
export class DemandsExcelExportComponent {
  @Output() export: EventEmitter<DemandsExcelExport> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'demands-excel-export';

  config = demandsExcelExportComponentConfig;
  isRtl: boolean = this.localizationService.isRtl();
  activePreset: string = UDateRangePreset.ActiveWeekDay;
  uDateRangePresets: UDateRangePreset[] = [ UDateRangePreset.ActiveWeekDay, UDateRangePreset.NextWeek, UDateRangePreset.CurrentMonth, UDateRangePreset.Custom ];
  uDateRangePresetsMode = UDateRangePresetsMode;
  startDate: string;
  endDate: string;
  dateFrom: string = moment().format(AppConstants.DATE_FORMAT_ISO);
  dateTo: string = moment().format(AppConstants.DATE_FORMAT_ISO);

  constructor(
    private bsModalRef: BsModalRef,
    private localizationService: LocalizationService,
    public translateService: TranslateService
  ) {}

  closeModal() {
    this.bsModalRef.hide();
  }

  applyAction() {
    const dateFrom = this.dateFrom;
    const dateTo = this.dateTo;

    this.export.emit({ value: { dateFrom, dateTo } });

    this.bsModalRef.hide();
  }

  changeDatesAction({ from, to }) {
    this.dateFrom = from;
    this.dateTo = to;
  }
}
