import { URangePreset } from '@shift/ulib';

import { DaysOfWeek } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';

const DICTIONARY_PATH: string = 'bulkChange';

export const bulkChangeConfig = {
  trackingId: 'Bulk change',
  dateFormat: AppConstants.DATE_FORMAT_BASE_LINE,
  checkDaysAvailable: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ],
  availablePresetsInit: [
    URangePreset.DisplayedDay,
    URangePreset.Today,
    URangePreset.Tomorrow,
    URangePreset.ActiveWeekDay,
    URangePreset.FromCustomDayOn,
    URangePreset.All,
    URangePreset.Custom,
    URangePreset.UpcomingWeek
  ],
  accompany: {
    dictionary: {
      fieldName: `${DICTIONARY_PATH}.accompany`
    },
    apiBasePath: '/Accompany'
  },
  accompanyCost: {
    apiBasePath: '/Accompany/Assigned/Cost'
  },
  vehicle: {
    dictionary: {
      fieldName: `${DICTIONARY_PATH}.vehicle`
    },
    apiBasePath: '/Vehicle'
  },
  driver: {
    dictionary: {
      fieldName: `${DICTIONARY_PATH}.driver`
    },
    apiBasePath: '/Driver'
  },
  shuttleCompany: {
    dictionary: {
      fieldName: `${DICTIONARY_PATH}.shuttleCompany`
    },
    apiBasePath: '/ShuttleCompany'
  },
  shuttleCompanyRidePrice: {
    apiBasePath: '/ShuttleCompany/Assigned/Cost'
  },
  shuttleCompanyCloseRide: {
    apiBasePath: '/ShuttleCompany/CloseRides'
  },
  shuttleCompanyOpenRide: {
    apiBasePath: '/ShuttleCompany/OpenRides'
  },
  cancel: {
    apiBasePath: '/CancelRides',
    multiplePeriods: true
  },
  restore: {
    apiBasePath: '/RestoreRides',
    multiplePeriods: true
  },
  hour: {
    hourTypes: [
      { value: 1, name: `${DICTIONARY_PATH}.changeHourTypes.startTime` },
      { value: 2, name: `${DICTIONARY_PATH}.changeHourTypes.endTime` }
    ],
    dictionary: {
      fieldName: `${DICTIONARY_PATH}.changeHour`
    },
    apiBasePath: '/Time'
  },
  executionPrice: {
    apiBasePath: '/ExecutionCost'
  },
  changesApiBasePaths: {
    swap: '/Swap',
    assign: '/Assign',
    remove: '/Remove'
  }
};
