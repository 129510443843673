import { PlacementArray } from '@shift/ulib/utils/positioning';

import { AuthModuleBranchesFeatureType } from '@app/auth/models';
import { BranchesAddEditGeneralButtonId } from '@app/branches/models';

const DICTIONARY_PATH: string = 'branches.addEdit.copyFromAnotherBranch';

const copyFromBranchButton = {
  id: BranchesAddEditGeneralButtonId.CopyFromBranch,
  title: `${DICTIONARY_PATH}.title`,
  popover: {
    template: null,
    placement: {
      rtl: [ 'left-top' ] as PlacementArray,
      ltr: [ 'right-top' ] as PlacementArray
    },
    class: 'u-popover_rounded u-popover_app-branches-add-edit-copy-from-branch',
    verticalOffset: -12
  }
};

export const branchesAddEditCopyFromBranchConfig = {
  default: {
    buttons: [
      copyFromBranchButton
    ]
  },
  [AuthModuleBranchesFeatureType.ActivityCenter]: {
    buttons: [
      {
        ...copyFromBranchButton,
        title: `${DICTIONARY_PATH}.titleActivityCenter`
      }
    ]
  },
  [AuthModuleBranchesFeatureType.Base]: {
    buttons: [
      {
        ...copyFromBranchButton,
        title: `${DICTIONARY_PATH}.titleBase`
      }
    ]
  }
};
