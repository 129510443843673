import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class NextYearService {
  private BASE_PATH: string = 'NextYear';

  constructor(
    private apiService: ApiService
  ) {}

  start(data) {
    return this.apiService.post(`${this.BASE_PATH}/Start`, data);
  }

  checkCurrentStep() {
    return this.apiService.get(`${this.BASE_PATH}/StepStatus`);
  }

  getNextYearStartDate() {
    return this.apiService.get(`${this.BASE_PATH}/StartDate`);
  }

  getNextYearActivationStatus() {
    return this.apiService.get(`${this.BASE_PATH}/NextYearStatus`);
  }

  getNextYearName() {
    return this.apiService.get(`${this.BASE_PATH}/Name`);
  }

  getProgress() {
    return this.apiService.get(`${this.BASE_PATH}/Progress`);
  }

  validatePeriod(body: { startDate: string; endDate: string; nextYearStartDate: string; }): Observable<{ isValid: boolean; }> {
    return this.apiService.post(`${this.BASE_PATH}/ValidatePeriod`, body);
  }
}
