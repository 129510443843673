import { Component, HostBinding, Signal, computed, inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import { UMenuIcon, UMenuIconsModule } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { MenuIcon } from '@app/shared/models';
import { AuthDataService } from '@app/auth/services';
import { DemandsStoreService } from '@app/demands/services';
import { DemandsView } from '@app/demands/models';
import { headerDemandsViewComponentConfig } from './header-demands-view.component.config';

@Component({
  selector: 'app-header-demands-view',
  templateUrl: './header-demands-view.component.html',
  standalone: true,
  imports: [ UMenuIconsModule ]
})
export class HeaderDemandsViewComponent {
  @HostBinding('class') hostClasses: string = 'header-demands-view';

  private readonly localizationService = inject(LocalizationService);
  private readonly demandsStoreService = inject(DemandsStoreService);
  private readonly authDataService = inject(AuthDataService);

  config = cloneDeep(headerDemandsViewComponentConfig);
  isRtl: boolean = this.localizationService.isRtl();
  viewMenuIcons: Signal<MenuIcon[]> = computed(() =>
    this.config.viewMenuIcons
      .filter(viewIcon => !viewIcon.feature || this.authDataService.checkFeature(viewIcon.feature))
      .map(viewIcon => ({ ...viewIcon, selected: viewIcon.value === this.demandsStoreService.view() }))
  );

  onViewMenuIconClick(icon: UMenuIcon) {
    this.demandsStoreService.updateView(icon.value as DemandsView);
  }
}
