<div
  class="builder-stations__list"
  [ngClass]="{'builder-stations__list_inactive': inactive}"
  [dndDropzone]="['station', 'destination']"
  (dndDrop)="onDrop($event, stations)"
>
  <ng-container *ngTemplateOutlet="stationsList; context: { $implicit: stations }"></ng-container>
</div>

<ng-template #warningAccompany>
  <div class="builder-stations-warning">
    <div class="builder-stations-warning__info">
      <div class="builder-stations-warning__info-item builder-stations-warning__info-item_bold">{{config.dictionary.warning.original | translate}}</div>
      <div class="builder-stations-warning__info-item" *ngIf="warningAccompanyInfo.name">{{config.dictionary.warning.name | translate}}: {{warningAccompanyInfo.name | translate}}</div>
      <div class="builder-stations-warning__info-item" *ngIf="warningAccompanyInfo.mobile">{{config.dictionary.warning.phone | translate}}: {{warningAccompanyInfo.mobile}}</div>
      <div class="builder-stations-warning__info-item" *ngIf="warningAccompanyInfo.address">{{config.dictionary.warning.address | translate}}: {{warningAccompanyInfo.address}}</div>
      <div class="builder-stations-warning__info-item" *ngIf="warningAccompanyInfo.id !== 'without' && warningAccompanyInfo.pickUpHome !== null">{{config.dictionary.warning.bringBack | translate}}: {{warningAccompanyInfo.pickUpHome | translate}}</div>
      <div class="builder-stations-warning__info-item" *ngIf="warningAccompanyInfo.id !== 'without' && warningAccompanyInfo.hours !== null">{{config.dictionary.warning.hour | translate}}: {{warningAccompanyInfo.hours}}</div>
      <div class="builder-stations-warning__info-item" *ngIf="warningAccompanyInfo.id !== 'without' && warningAccompanyInfo.costType !== null">{{config.dictionary.warning.priceType | translate}}: {{warningAccompanyInfo.costType | translate}}</div>
      <div class="builder-stations-warning__info-item" *ngIf="warningAccompanyInfo.id !== 'without' && warningAccompanyInfo.totalCost !== null">{{config.dictionary.warning.totalPrice | translate}}: {{warningAccompanyInfo.totalCost}}</div>
    </div>
  </div>
</ng-template>

<ng-template #destinationInfo>
  <div class="builder-stations-destination-info">
    <p class="builder-stations-destination-info__name">{{passengerDestinationHover.name}}</p>
    <p class="builder-stations-destination-info__address">{{passengerDestinationHover.address}}</p>
  </div>
</ng-template>

<ng-template #passengerDestinationChange>
  <div class="builder-passenger-destination-change">
    <div class="builder-passenger-destination-change__name">{{config.dictionary.passenger.destinationChange.title | translate}}</div>
    <div class="builder-passenger-destination-change__search">
      <input
        uInput
        class="u-input_thin u-input_app-builder-passenger-destination-change"
        placeholder="{{config.dictionary.passenger.destinationChange.search | translate}}"
        [(ngModel)]="passengerDestinationEdit.search"
      >
    </div>
    <div class="builder-passenger-destination-change__items">
      <div class="builder-passenger-destination-change__item">
        <div class="builder-passenger-destination-change__name">{{config.dictionary.passenger.destinationChange.destinations | translate}}</div>
        <div
          class="builder-passenger-destination-change__list"
          *ngIf="(passengerDestinationEdit.destinations | builderSearchList: passengerDestinationEdit.search).length"
        >
          <div
            class="builder-passenger-destination-change__list-item"
            *ngFor="let item of passengerDestinationEdit.destinations | builderSearchList: passengerDestinationEdit.search"
            (click)="passengerDestinationSet(item.targetRideStationId)"
          >
            {{item.order}} {{item.name}}
          </div>
        </div>
        <div
          class="builder-passenger-destination-change__not-found"
          *ngIf="!(passengerDestinationEdit.destinations | builderSearchList: passengerDestinationEdit.search).length"
        >
          {{config.dictionary.passenger.destinationChange.notFound | translate}}
        </div>
      </div>
      <div class="builder-passenger-destination-change__item">
        <div class="builder-passenger-destination-change__name">{{config.dictionary.passenger.destinationChange.stations | translate}}</div>
        <div class="builder-passenger-destination-change__list">
          <div
            class="builder-passenger-destination-change__list-item"
            *ngFor="let item of passengerDestinationEdit.stations | builderSearchList: passengerDestinationEdit.search"
            (click)="passengerDestinationSet(item.targetRideStationId)"
          >
            {{item.order}} {{item.name}}
          </div>
        </div>
        <div
          class="builder-passenger-destination-change__not-found"
          *ngIf="!(passengerDestinationEdit.stations | builderSearchList: passengerDestinationEdit.search).length"
        >
          {{config.dictionary.passenger.destinationChange.notFound | translate}}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #passengerDestinationMark>
  <div class="builder-station-passenger-info-mark">
    <div class="builder-station-passenger__destination builder-station-passenger__destination_red"></div>
    <div  class="builder-station-passenger-info-mark__text">
      {{config.dictionary.warning.selectDestination | translate}}
    </div>
  </div>
</ng-template>

<ng-template #stationsList let-list>
  <div dndPlaceholderRef class="builder-stations__dnd-placeholder"></div>
  <div
    *ngFor="let station of list; let index = index; let last = last"
    class="builder-stations__item"
    [dndDraggable]="station"
    [dndType]="station.type"
    [dndEffectAllowed]="'move'"
    [dndDisableIf]="
      (activeRide.rideCalculationMode === builderCalculationMode.Manual && routeBuilderFeatureTypeShuttleCompany()) ||
      stationEdit.id === station.rideStationId ||
      list.length <= 1 ||
      (station.type === 'accompany' && !builderDataService.assignAccompanyToRidesAllowed()) ||
      !builderDataService.createOrEditRoutesAllowed()
    "
    (dndStart)="onDragStart()"
    (dndCopied)="onDragged(station, list, 'copy', 'station', station)"
    (dndLinked)="onDragged(station, list, 'link', 'station', station)"
    (dndMoved)="onDragged(station, list, 'move', 'station', station)"
    (dndCanceled)="onDragged(station, list, 'none', 'station', station)"
    (dndEnd)="onDragEnd()"
  >
  <div
    class="builder-station"
    [ngClass]="{
      'builder-station_highlight': builderDataService.buildModeRouteSuggestions() && !hasRouteChanged && (station | builderHighlightStation: selectedPassengerId)
    }"
    [attr.id]="station | builderStationId"
    appScrollToEl
    [scrollToEl]="builderDataService.buildModeRouteSuggestions() && !hasRouteChanged && (station | builderHighlightStation: selectedPassengerId)"
    [dndDropzone]="['passenger']"
    [dndDisableIf]="!builderDataService.customerIsOwnedBySc() || station.type !== 'station'"
    (dndDrop)="onDrop($event, station.passengers)"
  >
    <div class="builder-station__main">
      <div class="builder-station__content">
        <div class="builder-station__content-item">
          <div
            *ngIf="stationShowOnMapIcon"
            uId="builder-station-show-on-map"
            class="builder-station__button builder-station__button-show-on-map"
            [container]="'body'"
            [placement]="[ 'top', 'bottom' ]"
            [viewportElement]="viewportDropdown"
            [tooltipClass]="'u-tooltip_app-builder-station'"
            [uTooltip]="config.dictionary.station.zoomStation | translate"
            [ngClass]="{
              'builder-station__button_hidden': station.type === 'accompany' && (!station.expand.options.accompanyId || station.expand.options.accompanyId === 'required')
            }"
            (click)="stationShowOnMap(station)"
          >
            <div class="u-icon u-icon_pointer u-icon_app-builder-show-in-map"></div>
          </div>
          <div class="builder-station__content-info">
            <div
              class="builder-station__type  builder-station__type_blue"
              [ngClass]="{'builder-station__type-two': station.expand.options.accompanyId !== 'required'}"
              *ngIf="station.type === 'accompany' && station.expand"
            >
              <div
                class="builder-station__type-toggle"
                *ngIf="station.expand.options.accompanyId !== 'required' && !routeBuilderFeatureTypeShuttleCompany()"
              >
                <div
                  class="u-icon u-icon_pointer u-icon_app-builder-arrow-toggle"
                  [ngClass]="{
                    'u-icon_app-builder-arrow-toggle-right': !isRtl,
                    'u-icon_app-builder-arrow-toggle-down': station.expand.check
                  }"
                  (click)="stationExpandToggle(station)"
                ></div>
              </div>
              <div class="builder-station__type-text">{{(!station.destination ? config.dictionary.station.type.accompany : config.dictionary.station.type.destination) | translate}} {{station.order}}</div>
            </div>
            <div class="builder-station__type" *ngIf="station.type === 'station'">{{config.dictionary.station.type.station | translate}} {{station.order}}</div>
            <div
              class="builder-station__type builder-station__type_destination"
              *ngIf="station.type === 'destination'"
            >
              {{(destinationStart ? config.dictionary.station.type.start : config.dictionary.station.type.destination) | translate}} {{station.order}}
            </div>
          </div>
          <div class="builder-station__content-data">
            <div
              class="builder-station__name"
              [ngClass]="{
                'builder-station__name_red': station.name === config.dictionary.required,
                'builder-station__name_disabled': (station.type === 'accompany' && !builderDataService.assignAccompanyToRidesAllowed()) || !builderDataService.createOrEditRoutesAllowed()
              }"
              (click)="stationEditChange(station, 'name'); formControlClick.emit();"
            >
              {{station.name | translate}}
            </div>
            <div
              *ngIf="stationEdit.id === station.rideStationId && stationEdit.param === 'name'"
              class="builder-station__edit-field"
              [delayClickOutsideInit]="true"
              (uClickOutside)="stationEditClose(station, $event)"
            >
              <input *ngIf="station.type === 'station' || station.type === 'destination'" uInput class="u-input_thin" type="text" [(ngModel)]="stationEdit.data">
              <u-select-s
                *ngIf="station.type === 'accompany'"
                [classUInput]="'u-input_thin'"
                [items]="options.accompaniesWithoutRequired"
                [(ngModel)]="stationEdit.data"
                [messages]="'uSelectS' | translate"
                [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
                [empty]="true"
                [emptyValue]="'required'"
                [disabled]="!builderDataService.assignAccompanyToRidesAllowed()"
                [emptyName]="config.dictionary.required | translate"
                [emptyRemove]="'general.removeSelect' | translate"
                [placeholder]="'uSelectS.placeholder' | translate"
                (ngModelChange)="stationEditClose(station)"
              ></u-select-s>
            </div>
          </div>
        </div>
        <div class="builder-station__content-item" *ngIf="station.type === 'accompany' && station.phone">
          <div class="builder-station__content-info"></div>
          <div class="builder-station__content-data">
            <div class="builder-station__text">{{station.phone}}</div>
          </div>
        </div>
        <div class="builder-station__content-item">
          <div
            class="builder-station__content-info"
            [ngClass]="{
              'builder-station__content-info_clickable': builderDataService.customerIsOwnedBySc() && editStationArrivalDateTime &&
                (station.type === builderStationListItemType.Accompany ? station.expand.options.pickUpHome : true) &&
                builderDataService.createOrEditRoutesAllowed()
            }"
            [uPopover]="arrivalTimePopUp"
            [placement]="isRtl ? [ 'left-top', 'left-bottom' ] : [ 'right-top', 'right-bottom' ]"
            [viewportElement]="viewportArrivalTimePopover"
            [viewportAutoClose]="false"
            [popoverClass]="'u-popover_app-stations'"
            [autoClose]="'outside'"
            [triggers]="'click'"
            [container]="'body'"
            [uId]="'builder-' + station.type + '-' + station.order + '-hour'"
            #arrivalTimePopover="uPopover"
          >
            {{station.arrivalTime | date: appConstants.TIME_FORMAT}}
          </div>
          <ng-template #arrivalTimePopUp>
            <app-builder-station-arrival-time
              [station]="station"
              [activeRide]="activeRide"
              (saveAction)="editStationArrivalTime($event, station.rideStationId); arrivalTimePopover.close();"
              (closeAction)="arrivalTimePopover.close()"
            ></app-builder-station-arrival-time>
          </ng-template>
          <div
            class="builder-station__content-data"
            [ngClass]="{ 'builder-station__content-data_disabled': station.type === 'accompany' || !builderDataService.createOrEditRoutesAllowed() }"
          >
            <ng-container *ngIf="station.rideStationId !== 'required'">
              <div
                class="builder-station__text"
                (click)="stationEditChange(station, 'address'); formControlClick.emit();"
              >
                {{station.address}}
              </div>
              <div
                *ngIf="stationEdit.id === station.rideStationId && stationEdit.param === 'address'"
                class="builder-station__edit-field"
                [delayClickOutsideInit]="true"
                (uClickOutside)="stationEditClose(station, $event)"
              >
                <u-address
                  [showMapIcon]="false"
                  [classUInput]="'u-input_thin'"
                  [container]="'body'"
                  [placement]="isRtl ? [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ] : [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ]"
                  [placeholder]="''"
                  [address]="{
                    address: stationEdit.data,
                    lat: stationEdit.extra.latitude,
                    lng: stationEdit.extra.longitude
                  }"
                  (addressChange)="updateAddress($event)"
                  (mapIconClick)="trackingService.track('Address field - click on map icon')"
                ></u-address>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="builder-station__buttons"
        [ngClass]="{
          'builder-station__buttons_bottom': station.type === 'accompany',
          'builder-station__buttons_show': dropdown?.isOpen(),
          'builder-station__buttons_warning': station.type === 'accompany' && station.expand.options.planned && builderDataService.modeEdit()
        }"
      >
        <div
          *ngIf="
            index < stations.length - 1 &&
            station.type !== 'accompany' &&
            !(activeRide.rideCalculationMode === builderCalculationMode.Manual && routeBuilderFeatureTypeShuttleCompany()) &&
            builderDataService.createOrEditRoutesAllowed()
          "
          class="builder-station__button"
        >
          <div
            class="builder-station__icon-button"
            [container]="'body'"
            [placement]="[ 'top', 'bottom' ]"
            [viewportElement]="viewportDropdown"
            [tooltipClass]="'u-tooltip_app-builder-station'"
            [uTooltip]="config.dictionary.station.moveStationDown | translate"
            (click)="moveStationDown(station)"
          >
            <div class="u-icon u-icon_app-builder-move-down"></div>
          </div>
        </div>
        <div
          *ngIf="
            index >= 1 && station.type !== 'accompany' &&
            !(activeRide.rideCalculationMode === builderCalculationMode.Manual && routeBuilderFeatureTypeShuttleCompany()) &&
            builderDataService.createOrEditRoutesAllowed()
          "
          class="builder-station__button"
        >
          <div
            class="builder-station__icon-button"
            [container]="'body'"
            [placement]="[ 'top', 'bottom' ]"
            [viewportElement]="viewportDropdown"
            [tooltipClass]="'u-tooltip_app-builder-station'"
            [uTooltip]="config.dictionary.station.moveStationUp | translate"
            (click)="moveStationUp(station)"
          >
            <div class="u-icon u-icon_app-builder-move-up"></div>
          </div>
        </div>
        <div
          *ngIf="builderDataService.customerIsOwnedBySc() && (builderDataService.config() | builderIsRemoveStationAllowed: station : selectedPassengerId) && builderDataService.createOrEditRoutesAllowed()"
          class="builder-station__button"
          [ngClass]="{
            'builder-station__button_hidden': station.type === 'accompany' && !builderDataService.assignAccompanyToRidesAllowed()
          }"
        >
          <div
            class="builder-station__icon-button"
            [uId]="'builder-station-remove'"
            [container]="'body'"
            [placement]="[ 'top', 'bottom' ]"
            [viewportElement]="viewportDropdown"
            [tooltipClass]="'u-tooltip_app-builder-station'"
            [uTooltip]="config.dictionary.station.deleteStation | translate"
            (click)="stationRemove(list, station)"
          >
            <div class="u-icon u-icon_app-builder-remove"></div>
          </div>
        </div>
        <div
          *ngIf="builderDataService.customerIsOwnedBySc() && builderDataService.createOrEditRoutesAllowed()"
          uDropdown
          #dropdown="uDropdown"
          class="builder-station__button"
          [ngClass]="{
            'builder-station__button_hidden': station.type === 'accompany' && (!station.expand.options.accompanyId || station.expand.options.accompanyId === 'required' || !builderDataService.assignAccompanyToRidesAllowed())
          }"
          [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
          [viewportElement]="viewportDropdown"
          [viewportAutoClose]="true"
          [container]="'body'"
        >
          <div
            uDropdownToggle
            class="builder-station__icon-button"
            [uId]="'builder-station-dots-more-actions'"
            [tooltipClass]="'u-tooltip_app-builder-station'"
            [uTooltip]="config.dictionary.station.moreActions | translate"
            [container]="'body'"
            [placement]="[ 'top', 'bottom' ]"
          >
            <div class="u-icon u-icon_app-builder-dots-more"></div>
          </div>
          <div uDropdownMenu class="u-dropdown-menu">
            <div *ngIf="dropdown.isOpen()" class="u-dropdown-content">
              <div class="u-list u-list_app-builder-station">
                <div
                  *ngIf="station.type === 'accompany' && station.expand.options.pickUpHome && !last"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationAccPickUpFirst(station.rideStationId)"
                >
                  {{config.dictionary.station.pickUpFirst | translate}}
                </div>
                <div
                  *ngIf="station.type === 'accompany' && !station.expand.options.pickUpHome && !last"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationAccPickUpHome(station.rideStationId)"
                >
                  {{config.dictionary.station.pickUpHome | translate}}
                </div>
                <div
                  *ngIf="station.type === 'accompany' && !station.expand.options.return"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationAccWithBack(station.rideStationId)"
                >
                  {{config.dictionary.station.withBack | translate}}
                </div>
                <div
                  *ngIf="station.type === 'accompany' && station.expand.options.return"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationAccWithoutBack(station.rideStationId)"
                >
                  {{config.dictionary.station.withoutBack | translate}}
                </div>
                <div
                  *ngIf="station.type !== 'accompany' && station.type !== 'destination'"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationSetFirst(station.rideStationId)"
                >
                  {{config.dictionary.station.setAsFirst | translate}}
                </div>
                <div
                  *ngIf="station.type !== 'accompany' && station.type !== 'destination'"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationSetLast(station.rideStationId)"
                >
                  {{config.dictionary.station.setAsLast | translate}}
                </div>
                <div
                  *ngIf="!routeBuilderFeatureTypeShuttleCompany() && station.type !== 'accompany' && station.type !== 'destination' && !routeBuilderFeatureMasterCustomer()"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationSave(station.rideStationId)"
                >
                  {{config.dictionary.station.saveInStationsList | translate}}
                </div>
                <div
                  *ngIf="station.type !== 'accompany' && station.type !== 'destination'"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationSetDestination(station.rideStationId, true)"
                >
                  {{(destinationStart ? config.dictionary.station.convertToSource : config.dictionary.station.convertToDestination) | translate}}
                </div>
                <div
                  *ngIf="station.type !== 'accompany' && station.type === 'destination'"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationSetDestination(station.rideStationId, false)"
                >
                  {{config.dictionary.station.convertToStation | translate}}
                </div>
                <div
                  *ngIf="
                    !!passengersFeatureType() &&
                    routeBuilderFeatureTypeGeneric() &&
                    station.type !== 'accompany' &&
                    station.type !== 'destination' &&
                    !routeBuilderFeatureMasterCustomer() &&
                    authDataSnapshotService.managePassengers()
                  "
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationAddNewPassenger(station.rideStationId)"
                >
                  {{config.dictionary.station.addNewPassenger | translate}}
                </div>
                <div
                  *ngIf="station.type !== 'accompany' && station.type === 'station' && !builderDataService.buildModeRouteSuggestions()"
                  class="u-list__item"
                  uDropdownItem
                  (click)="stationAddAnonymousPassenger(station.rideStationId)"
                >
                  {{config.dictionary.station.addAnonymousPassenger | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="builder-station__warning"
        *ngIf="station.type === 'accompany' && station.expand.options.planned && builderDataService.modeEdit()"
        [uPopover]="warningAccompany"
        [container]="'body'"
        [placement]="isRtl ? [ 'left-top', 'left-bottom' ] : [ 'right-top', 'right-bottom' ]"
        [viewportElement]="viewportPopover"
        [viewportAutoClose]="true"
        [triggers]="'click'"
        [popoverClass]="
          isRtl ?
          'u-popover_app-builder-station u-popover_app-builder-station-left-top' :
          'u-popover_app-builder-station u-popover_app-builder-station-right-top'
        "
        [autoClose]="'outside'"
        (click)="warningAccompanyInfoSet(station)"
        (hidden)="warningAccompanyInfoReset()"
      >
        !
      </button>
    </div>
    <div
      class="builder-station__options"
      *ngIf="station.type === 'accompany' && station.expand && station.expand.check"
    >
      <div class="builder-station__options-item">
        <div class="builder-station__group-double">
          <div class="builder-station__group-double-name">
            {{(station.expand.options.costOption === 0 ? config.dictionary.station.accompany.cost : config.dictionary.station.accompany.costH) | translate}}
          </div>
          <div class="builder-station__group-double-fields">
            <div class="builder-station__group-double-field">
              <input
                uInput
                [(ngModel)]="station.expand.options.cost"
                [mask]="'0*.00'"
                [disabled]="!builderDataService.assignAccompanyToRidesAllowed()"
                [dropSpecialCharacters]="false"
                [ngModelOptions]="{updateOn: 'blur'}"
                (ngModelChange)="stationAccExpandEdit(station)"
              >
            </div>
            <div class="builder-station__group-double-field">
              <u-select-s
                [items]="options.costAccompany"
                [(ngModel)]="station.expand.options.costOption"
                [container]="'body'"
                [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
                [viewportElement]="viewportDropdown"
                [empty]="false"
                [disabled]="!builderDataService.assignAccompanyToRidesAllowed()"
                [messages]="'uSelectS' | translate"
                [placeholder]="'uSelectS.placeholder' | translate"
                (ngModelChange)="stationAccExpandEdit(station)"
              ></u-select-s>
            </div>
          </div>
        </div>
      </div>
      <div class="builder-station__options-item" *ngIf="station.expand.options.costOption !== 0">
        <div class="builder-station__group-double">
          <div class="builder-station__group-double-name">{{config.dictionary.station.accompany.hours | translate}}</div>
          <div class="builder-station__group-double-fields">
            <div class="builder-station__group-double-field">
              <input
                uInput
                [(ngModel)]="station.expand.options.price"
                [mask]="'0*.00'"
                [dropSpecialCharacters]="false"
                [ngModelOptions]="{updateOn: 'blur'}"
                (ngModelChange)="stationAccExpandEdit(station)"
                [disabled]="(station.expand.options.costOption === 1 && station.expand.options.priceOption === 1) || (station.expand.options.costOption === 0 && station.expand.options.priceOption === 1) || !builderDataService.assignAccompanyToRidesAllowed()"
              >
            </div>
            <div class="builder-station__group-double-field">
              <u-select-s
                [items]="options.priceAccompany"
                [(ngModel)]="station.expand.options.priceOption"
                [container]="'body'"
                [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
                [viewportElement]="viewportDropdown"
                [empty]="false"
                [disabled]="!builderDataService.assignAccompanyToRidesAllowed()"
                [messages]="'uSelectS' | translate"
                [placeholder]="'uSelectS.placeholder' | translate"
                (ngModelChange)="stationAccExpandEdit(station)"
              ></u-select-s>
            </div>
          </div>
        </div>
      </div>
      <div class="builder-station__options-item" *ngIf="station.expand.options.costOption !== 0">
        <div class="builder-station__group-double">
          <div class="builder-station__group-double-name">{{config.dictionary.station.accompany.cost | translate}}</div>
          <div class="builder-station__group-double-fields">
            <div class="builder-station__group-double-field">
              <input
                uInput
                [(ngModel)]="station.expand.options.priceH"
                [mask]="'0*.00'"
                [dropSpecialCharacters]="false"
                [disabled]="true"
              >
            </div>
            <div class="builder-station__group-double-field"></div>
          </div>
        </div>
      </div>
      <div class="builder-station__options-item" *ngIf="station.expand.options.accompanyId !== null && station.expand.options.accompanyId !== 'required'">
        <div class="builder-station__group">
          <div class="builder-station__group-name">{{config.dictionary.station.accompany.return | translate}}</div>
          <div class="builder-station__group-field">
            <u-select-s
              [items]="options.returnAccompany"
              [(ngModel)]="station.expand.options.return"
              [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
              [viewportElement]="viewportDropdown"
              [empty]="false"
              [disabled]="!builderDataService.assignAccompanyToRidesAllowed()"
              [messages]="'uSelectS' | translate"
              [placeholder]="'uSelectS.placeholder' | translate"
              (ngModelChange)="stationAccExpandEditReturn(station)"
            ></u-select-s>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="station.type === 'station' && station.passengers"
      [dndDropzone]="['passenger']"
      (dndDrop)="onDrop($event, station.passengers)"
      class="builder-station__passengers"
    >
      <ng-container *ngTemplateOutlet="passengersList; context: { $implicit: station.passengers, station: station }"></ng-container>
    </div>
  </div>
  </div>
</ng-template>

<ng-template #passengersList let-list let-station="station">
  <div dndPlaceholderRef class="builder-station__passengers-dnd-placeholder">{{config.dictionary.station.dragToHere | translate}}</div>
  <div
    *ngFor="let passenger of list"
    class="builder-station__passengers-item"
    [dndDraggable]="passenger"
    [dndType]="passenger.type"
    [dndEffectAllowed]="'move'"
    [dndDisableIf]="
      !builderDataService.customerIsOwnedBySc() ||
      (activeRide.rideCalculationMode === builderCalculationMode.Manual && routeBuilderFeatureTypeShuttleCompany()) ||
      passenger.passengerId === null ||
      !builderDataService.createOrEditRoutesAllowed()
    "
    (dndStart)="onDragStart()"
    (dndCopied)="onDragged(passenger, list, 'copy', 'passenger', station)"
    (dndLinked)="onDragged(passenger, list, 'link', 'passenger', station)"
    (dndMoved)="onDragged(passenger, list, 'move', 'passenger', station)"
    (dndCanceled)="onDragged(passenger, list, 'none', 'passenger', station)"
    (dndEnd)="onDragEnd()"
  >
    <div
      class="builder-station-passenger"
      [ngClass]="{ 'builder-station-passenger_is-supervisor': customerIsOwnedByScAndSupervisorModuleStatusActive() && passenger.isSupervisor && passenger.destination > 0 }"
    >
      <div
        *ngIf="passenger.destination === 0"
        class="builder-station-passenger__info-mark"
        [uTooltip]="passengerDestinationMark"
        [tooltipClass]="'u-tooltip_app-builder-station-info-mark'"
        [container]="'body'"
        [placement]="isRtl ? [ 'top-right', 'top' ] : [ 'top-left', 'top' ]"
        [viewportElement]="viewportPopover"
        [triggers]="'click'"
        [autoClose]="'outside'"
      >
        i
      </div>
      <div class="builder-station-passenger__info">
        <div
          *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeCommander() && passenger.isSupervisor && passenger.destination > 0"
          class="builder-station-passenger__power-of-attorney"
        >
          {{config.dictionary.station.passenger.commander | translate}}
        </div>
        <div
          *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeGeneric() && passenger.isSupervisor && passenger.destination > 0"
          class="builder-station-passenger__power-of-attorney"
        >
          {{config.dictionary.station.passenger.supervisor | translate}}
        </div>
        <div
          class="builder-station-passenger__destination"
          *ngIf="passenger.active"
          [ngClass]="{
            'builder-station-passenger__destination_red': passenger.destination === 0
          }"
          [uPopover]="passengerDestinationChange"
          #popover="uPopover"
          [container]="'body'"
          [placement]="isRtl ? [ 'left-top', 'left-bottom' ] : [ 'right-top', 'right-bottom' ]"
          [viewportElement]="viewportPopover"
          [viewportAutoClose]="true"
          [triggers]="'click'"
          [popoverClass]="'u-popover_rounded u-popover_app-builder-passenger-destination-change'"
          [autoClose]="'outside'"
          [disablePopover]="!builderDataService.customerIsOwnedBySc() || !builderDataService.createOrEditRoutesAllowed()"
          (click)="passengerDestinationInit(station.rideStationId, passenger.ridePassengerId, passenger.targetStationId, popover)"
        >
          <div
            class="builder-station-passenger__destination-number"
            [uPopover]="destinationInfo"
            [triggers]="'hover'"
            [container]="'body'"
            [placement]="isRtl ? [ 'right' ] : [ 'left' ]"
            [popoverClass]="'u-popover_app-builder-passenger-destination-info'"
            (mouseenter)="passengerDestinationHoverInit(passenger.targetStationId)"
          >
            {{passenger.destination !== 0 ? passenger.destination : null}}
          </div>
        </div>
      </div>
      <div
        class="builder-station-passenger__main"
        [ngClass]="{
          'builder-station-passenger__main_disabled': !passenger.active || !builderDataService.createOrEditRoutesAllowed(),
          'builder-station-passenger__main_has-supervisor-level': (passenger.supervisorLevel >= routeRideSupervisorLevel.Low && passenger.supervisorLevel !== null)
        }"
      >
        <div
          *ngIf="passenger.name === null && passenger.passengerId === null && passengerAnonymousEdit.ridePassengerId !== passenger.ridePassengerId"
          class="builder-station-passenger__amount"
          [ngClass]="{ 'builder-station-passenger__amount_disabled': passenger.passengerType === builderPassengerType.External || !builderDataService.createOrEditRoutesAllowed() }"
          (click)="passengerAnonymousEditChange(station, passenger)"
        >
          {{passenger.amount}}
        </div>
        <div
          *ngIf="passengerAnonymousEdit.rideStationId === station.rideStationId && passengerAnonymousEdit.ridePassengerId === passenger.ridePassengerId"
          class="builder-station-passenger__field"
          [delayClickOutsideInit]="true"
          (uClickOutside)="passengerAnonymousEditClose(passenger)"
        >
          <input
            uInput
            class="u-input_thin"
            type="text"
            [mask]="'0*'"
            [dropSpecialCharacters]="false"
            [(ngModel)]="passengerAnonymousEdit.amount"
          >
        </div>
        <div
          class="builder-station-passenger__name"
          [ngClass]="{
            'builder-station-passenger__name_highlight': builderDataService.buildModeRouteSuggestions() && !hasRouteChanged && passenger.passengerId === selectedPassengerId
          }"
          [uTooltip]="passenger.customerName ? ((config.dictionary.customer | translate) + ': ' + passenger.customerName) : null"
          [tooltipClass]="'u-tooltip_general'"
          [placement]="[ 'top', 'bottom' ]"
          [container]="'body'"
          [disableTooltip]="!passenger.name || !routeBuilderFeatureMasterCustomer()"
          (mouseenter)="passengerNameHover(passenger)"
        >
          {{(passenger.name || config.dictionary.station[passenger.passengerType === builderPassengerType.External ? 'externalPassengers' : 'passengers']) | translate}}
        </div>
      </div>
      <div
        *ngIf="
          customerIsOwnedByScAndSupervisorModuleStatusActive() && passenger.supervisorLevel >= routeRideSupervisorLevel.Low && passenger.supervisorLevel !== null && passengerAnonymousEdit.rideStationId !== station.rideStationId &&
          passengerAnonymousEdit.ridePassengerId !== passenger.ridePassengerId
        "
        class="builder-station-passenger__supervisor"
      >
        <div
          class="builder-station-passenger__supervisor-icon"
          [ngClass]="{
            'builder-station-passenger__supervisor-icon_green': passenger.supervisorLevel === routeRideSupervisorLevel.High,
            'builder-station-passenger__supervisor-icon_orange': passenger.supervisorLevel === routeRideSupervisorLevel.Medium,
            'builder-station-passenger__supervisor-icon_red': passenger.supervisorLevel === routeRideSupervisorLevel.Low
          }"
        ></div>
        <span
          *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeCommander()"
          class="builder-station-passenger__title"
        >
          {{config.dictionary.station.passenger.commander | translate}}
        </span>
        <span
          *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeGeneric()"
          class="builder-station-passenger__title"
        >
          {{config.dictionary.station.passenger.supervisor | translate}}
        </span>
      </div>
      <div
        class="builder-station-passenger__buttons"
        [ngClass]="{
          'builder-station-passenger__buttons_show': dropdown?.isOpen(),
          'builder-station-passenger__buttons_hidden': passenger.passengerType === builderPassengerType.External,
          'builder-station-passenger__buttons_auto-space': !(customerIsOwnedByScAndSupervisorModuleStatusActive() && passenger.supervisorLevel >= routeRideSupervisorLevel.Low && passenger.supervisorLevel !== null && passengerAnonymousEdit.rideStationId !== station.rideStationId &&
            passengerAnonymousEdit.ridePassengerId !== passenger.ridePassengerId)
        }"
      >
        <div *ngIf="passengerEyeIcon && builderDataService.customerIsOwnedBySc()" class="builder-station-passenger__button builder-station-passenger__button_show-on-map" (click)="passengerEye(passenger)">
          <div class="u-icon u-icon_pointer u-icon_app-builder-show-in-map"></div>
        </div>
        <div *ngIf="builderDataService.customerIsOwnedBySc() && builderDataService.createOrEditRoutesAllowed()" class="builder-station-passenger__button">
          <div
            *ngIf="builderDataService.config() | builderIsRemovePassengerAllowed: passenger.passengerId : selectedPassengerId"
            class="builder-station-passenger__icon-button"
            [uId]="'builder-station-passenger-remove'"
            (click)="passengerRemove(passenger, station)"
          >
            <div class="u-icon u-icon_app-builder-remove"></div>
          </div>
        </div>
        <div
          *ngIf="passenger.passengerId !== null && !routeBuilderFeatureMasterCustomer()"
          class="builder-station-passenger__button"
        >
          <div class="builder-station-passenger__icon-button">
            <app-builder-station-passenger-mobile
              [passengerId]="passenger.passengerId"
            ></app-builder-station-passenger-mobile>
          </div>
        </div>
        <div
          *ngIf="builderDataService.customerIsOwnedBySc() && builderDataService.createOrEditRoutesAllowed()"
          class="builder-station-passenger__button"
          uDropdown
          #dropdown="uDropdown"
          [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
          [viewportElement]="viewportDropdown"
          [viewportAutoClose]="true"
          [container]="'body'"
        >
          <div
            uDropdownToggle
            class="builder-station-passenger__icon-button"
            [uId]="'builder-station-passenger-dots-more'"
          >
            <div class="u-icon u-icon_app-builder-dots-more"></div>
          </div>
          <div uDropdownMenu class="u-dropdown-menu">
            <div *ngIf="dropdown.isOpen()" class="u-dropdown-content">
              <div class="u-list u-list_app-stations">
                <div
                  *ngIf="
                    !builderDataService.viewModeFull() &&
                    passenger.name &&
                    passenger.passengerId &&
                    !routeBuilderFeatureTypeShuttleCompany() &&
                    !routeBuilderFeatureMasterCustomer() &&
                    authDataSnapshotService.managePassengers()
                  "
                  uDropdownItem
                  class="u-list__item"
                  (click)="passengerEdit(passenger)"
                >
                  {{config.dictionary.station.passenger.editPassengerDetails | translate}}
                </div>
                <div
                  uDropdownItem
                  class="u-list__item"
                  (click)="passengerMoveToFirst(station.rideStationId, passenger.passengerId, passenger.ridePassengerId)"
                >
                  {{config.dictionary.station.passenger.moveToFirst | translate}}
                </div>
                <div
                  uDropdownItem
                  class="u-list__item"
                  (click)="passengerMoveToLast(station.rideStationId, passenger.passengerId, passenger.ridePassengerId)"
                >
                  {{config.dictionary.station.passenger.moveToLast | translate}}
                </div>
                <div
                  *ngIf="passenger.passengerId && !passenger.active"
                  uDropdownItem
                  class="u-list__item"
                  (click)="passengerComingSet(station.rideStationId, passenger.passengerId, true)"
                >
                  {{config.dictionary.station.passenger.coming | translate}}
                </div>
                <div
                  *ngIf="builderDataService.modeEdit() && passenger.passengerId && passenger.active"
                  uDropdownItem
                  class="u-list__item"
                  (click)="passengerComingSet(station.rideStationId, passenger.passengerId, false)"
                >
                  {{config.dictionary.station.passenger.notComing | translate}}
                </div>
                <div
                  *ngIf="
                    customerIsOwnedByScAndSupervisorModuleStatusActive() && 
                    passenger.name && passenger.passengerId && 
                    (routeBuilderFeatureTypeShuttleCompany() ? passenger.supervisorLevel !== null && !passenger.isSupervisor : !passenger.isSupervisor) && 
                    !builderDataService.buildModeRouteTemplates() &&
                    authDataSnapshotService.managePassengers()
                  "
                  uDropdownItem
                  class="u-list__item"
                  (click)="passengerSupervisorSet(passenger.passengerId)"
                >
                  <span
                    *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeCommander()"
                    class="u-list__item-button"
                  >
                    {{config.dictionary.station.passenger.assignAsRideCommander | translate}}
                  </span>
                  <span
                    *ngIf="customerIsOwnedByScAndSupervisorModuleStatusActive() && customerSupervisorModuleStatusFeatureTypeGeneric()"
                    class="u-list__item-button"
                  >
                    {{config.dictionary.station.passenger.assignAsRideSupervisor | translate}}
                  </span>
                </div>
                <div
                  *ngIf="
                    customerIsOwnedByScAndSupervisorModuleStatusActive() && passenger.name && passenger.passengerId && passenger.isSupervisor
                    && !builderDataService.buildModeRouteTemplates()
                  "
                  uDropdownItem
                  class="u-list__item"
                  (click)="passengerSupervisorReset(passenger.passengerId)"
                >
                  {{config.dictionary.station.passenger.convertToPassenger | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
