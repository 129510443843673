import { AddEditModalConfig, Contacts } from '@app/shared/models';
import { AuthCustomerType } from '@app/auth/models';

export enum ShuttleCompanyCustomerStatus {
  Active = 223,
  Inactive = 225
}

export interface ShuttleCompanyCustomer {
  id?: number;
  isEditable: boolean;
  customerId: number;
  type: AuthCustomerType;
  departmentId: number;
  name: string;
  address: {
    fullAddress: string;
    latitude: number;
    longitude: number;
  };
  businessIdentity: string;
  status: ShuttleCompanyCustomerStatus;
  comments?: string;
  contacts?: Contacts[];
  existingInCurrentCustomer: boolean;
}

export interface ShuttleCompanyCustomerSlim {
  customerId: number;
  name: string;
  address: string;
  phone: string;
  department: string;
  comments: string;
}

export interface ShuttleCompaniesCustomersAddEditComponentConfig {
  dictionary: { [key: string]: string; };
  addEditModalConfig: AddEditModalConfig;
}
