<div class="routes-from-templates__header">
  <span class="routes-from-templates__header-text">{{newRoutesPopupTitle}}</span>
</div>
<div class="routes-from-templates__body">
  <u-group
    class="u-group_app-new-routes-from-template u-group_app-new-routes-from-template_period"
    [name]="config.dictionary.period | translate"
  >
    <u-range
      *ngIf="newRoutesForm && newRoutesForm.get('datesChange')"
      [messages]="'uRange' | translate"
      [classUInput]="'u-input_cursor-pointer u-input_arrow'"
      [classUDropdownContent]="'u-dropdown-content_app-new-routes-from-template'"
      [sundayFirstDay]="true"
      [autoClose]="false"
      [container]="'body'"
      [dateRangeContainer]="'body'"
      [availablePresets]="newRoutesForm.get('datesChange').get('availablePresets').value"
      [type]="newRoutesForm.get('datesChange.type').value"
      [dates]="newRoutesForm.get('datesChange').get('dates').value"
      [dateFrom]="newRoutesForm.get('datesChange').get('dateFrom').value"
      [dateTo]="newRoutesForm.get('datesChange').get('dateTo').value"
      [checkDaysActive]="newRoutesForm.get('datesChange').get('checkDaysActive').value"
      [checkDaysAvailable]="newRoutesForm.get('datesChange').get('checkDaysAvailable').value"
      [displayedDay]="newRoutesForm.get('datesChange').get('displayedDay').value"
      [showDaysPicker]="false"
      [isRtl]="isRtl"
      [lang]="lang"
      [inputValid]="newRoutesForm.get('datesChange').valid"
      [placement]="isRtl ? [ 'bottom-left' ] : [ 'bottom-right' ]"
      [placementCalendar]="isRtl ? [ 'left-top', 'right-top' ] : [ 'right-top', 'left-top' ]"
      [maxPeriodSelectionFromSelectedDate]="maxPeriodSelectionFromSelectedDate"
      [checkDays]="true"
      [showIcon]="false"
      [required]="true"
      (saveAction)="onDatesSave($event)"
    ></u-range>
    <div class="routes-from-templates__body-checkbox">
      <u-checkbox
        class="u-checkbox_box u-checkbox_app-round-checkbox"
        [formControl]="newRoutesForm.get('asDailyRoutes')"
      ></u-checkbox>
      <span class="routes-from-templates__body-checkbox-text">
        {{config.dictionary.duplicateAsDailyRoutes | translate}}
      </span>
    </div>
  </u-group>
  <u-group
    class="u-group_app-new-routes-from-template"
    [name]="config.dictionary.shuttleCompany | translate"
  >
    <u-toggle
      [formControl]="newRoutesForm.controls['shuttleCompany']"
      [messages]="config.dictionary.toggle | translate"
    ></u-toggle>
  </u-group>
  <u-group
    class="u-group_app-new-routes-from-template"
    [name]="config.dictionary.drivers | translate"
  >
    <u-toggle
      [formControl]="newRoutesForm.controls['drivers']"
      [messages]="config.dictionary.toggle | translate"
    ></u-toggle>
  </u-group>
  <u-group
    *ngIf="isMunicipalityFeatureType"
    class="u-group_app-new-routes-from-template"
    [name]="config.dictionary.accompanies | translate"
  >
    <u-toggle
      [formControl]="newRoutesForm.controls['accompanies']"
      [messages]="config.dictionary.toggle | translate"
    ></u-toggle>
  </u-group>
</div>
<div class="routes-from-templates__actions">
  <button
    uButton
    class="u-font-weight-medium routes-from-templates__actions-btn"
    (click)="closeNewRoutesPopup()"
  >
    {{config.dictionary.cancel | translate}}
  </button>
  <button
    uButton
    class="u-font-weight-medium routes-from-templates__actions-btn"
    (click)="saveNewRoutesPopup()"
  >
    {{config.dictionary.saveAndClose | translate}}
  </button>
</div>
