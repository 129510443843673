import { USelectSItem } from '@shift/ulib';

import { ModalActions } from '@app/shared/models';
import {
  PassengerType,
  PassengerGender,
  PassengerPickUpType,
  PassengerDemandStatus,
  PassengersInitialDataType
} from '@app/passengers/models';
import { AuthModulePassengersFeatureType, AuthPermission } from '@app/auth/models';

const DICTIONARY_PATH: string = 'passengers';
const PASSENGERS_ADD_EDIT_DICTIONARY_PATH: string = `${DICTIONARY_PATH}.addEdit`;

const genders = [
  { value: PassengerGender.No, name: 'general.sex.no' },
  { value: PassengerGender.Male, name: 'general.sex.male' },
  { value: PassengerGender.Female, name: 'general.sex.female' }
];

const collecting: USelectSItem[] = [
  { value: PassengerPickUpType.FromHome, name: 'general.collecting.fromHome' },
  { value: PassengerPickUpType.FromStation, name: 'general.collecting.fromStation' }
];

const defaultInitialBaseDataTypes = [
  PassengersInitialDataType.Statuses
];

const addEditButtons = [
  {
    name: 'general.delete',
    action: ModalActions.Delete,
    clear: true,
    permission: AuthPermission.ManagePassengers
  },
  {
    name: 'general.saveAndClose',
    action: ModalActions.Submit,
    clear: false,
    permission: AuthPermission.ManagePassengers
  }
];

const initialBaseDataTypes = {
  default: defaultInitialBaseDataTypes,
  [AuthModulePassengersFeatureType.Student]: [
    ...defaultInitialBaseDataTypes,
    PassengersInitialDataType.Genders,
    PassengersInitialDataType.Schools
  ]
};

const defaultInitialBaseAddEditDataTypes = [
  PassengersInitialDataType.Branches,
  PassengersInitialDataType.Departments,
  PassengersInitialDataType.RouteTypes,
  PassengersInitialDataType.Statuses,
  PassengersInitialDataType.RefundEligibilities
];

const initialBaseAddEditDataTypes = {
  default: defaultInitialBaseAddEditDataTypes,
  [AuthModulePassengersFeatureType.Student]: [
    PassengersInitialDataType.Departments,
    PassengersInitialDataType.Statuses,
    PassengersInitialDataType.RouteTypes,
    PassengersInitialDataType.AccompanyApprovals,
    PassengersInitialDataType.DisabilityCodes,
    PassengersInitialDataType.Schools,
    PassengersInitialDataType.ShuttleApprovals,
    PassengersInitialDataType.RefundEligibilities
  ],
  [AuthModulePassengersFeatureType.Soldier]: [
    ...defaultInitialBaseAddEditDataTypes,
    PassengersInitialDataType.Tags
  ]
};

export const passengersConfig = {
  trackingId: 'Passengers',
  addEditButtons,
  options: {
    genders,
    collecting,
    passengersTypes: [
      { value: PassengerType.All, name: `${DICTIONARY_PATH}.general.types.all` },
      { value: PassengerType.Assigned, name: `${DICTIONARY_PATH}.general.types.assigned` },
      { value: PassengerType.Unassigned, name: `${DICTIONARY_PATH}.general.types.unassigned` }
    ],
    demandStatuses: [
      { value: PassengerDemandStatus.WithoutSchool, name: `${DICTIONARY_PATH}.general.demandStatuses.withoutSchool` },
      { value: PassengerDemandStatus.WithSchool, name: `${DICTIONARY_PATH}.general.demandStatuses.withSchool` },
      { value: PassengerDemandStatus.WithoutSchedule, name: `${DICTIONARY_PATH}.general.demandStatuses.withoutSchedule` },
      { value: PassengerDemandStatus.WithSchedule, name: `${DICTIONARY_PATH}.general.demandStatuses.withSchedule` }
    ]
  },
  dictionaryPath: DICTIONARY_PATH,
  dictionary: {
    without: 'general.without',
    deleteConfirmType: `${DICTIONARY_PATH}.deleteConfirmType`,
    deleteWarningMessages: [
      `${DICTIONARY_PATH}.deleteWarningMessages.willBeRemovedFromRoutes`,
      `${DICTIONARY_PATH}.deleteWarningMessages.willDeleteDemands`
    ],
    confirmationPopups: {
      existPerson: {
        message: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.existPerson`,
        yes: 'general.yes',
        no: 'general.no'
      },
      existPassenger: {
        message: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.existPassenger`,
        yes: 'general.yes',
        no: 'general.no'
      },
      existPassengerDetails: {
        message: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.existPassengerDetails`,
        yes: 'general.ok'
      }
    },
    error: {
      passengerNotValid: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.error.passengerNotValid`
    },
    confirmRoutesPopup: {
      message: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.confirmRoutesPopup.message`,
      confirm: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.confirmRoutesPopup.confirm`
    },
    saveErrors: {
      commonError: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.saveErrors.commonError`,
      mobileOrIdError: `${PASSENGERS_ADD_EDIT_DICTIONARY_PATH}.saveErrors.mobileOrIdError`
    }
  },
  initialBaseDataTypes,
  initialBaseAddEditDataTypes
};
