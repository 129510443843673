import { inject, Pipe, PipeTransform } from '@angular/core';

import { AuthDataService } from '@app/auth/services';
import { AddEditModalTab } from '@app/shared/models';
import { AddEditModalService } from '@app/shared/services';

@Pipe({
  name: 'addEditModalTabsFilter',
  standalone: true
})
export class AddEditModalTabsFilterPipe implements PipeTransform {
  private readonly authDataService = inject(AuthDataService);

  transform(tabs: AddEditModalTab[], addEditModalService: AddEditModalService): AddEditModalTab[] {
    return tabs
      .filter(tab =>
        (!tab.showInEditModeOnly || addEditModalService.isEditMode) &&
        (
          tab.feature ? this.authDataService.checkFeature(tab.feature) :
            tab.features ? this.authDataService.checkFeatures(tab.features) :
              tab.featureGroup ? this.authDataService.checkFeatureGroup(tab.featureGroup) : true
        )
      )
      .map(tab => ({
        ...tab,
        subTabs: tab.subTabs?.filter(subTab =>
          (!subTab.showInEditModeOnly || addEditModalService.isEditMode) &&
          (
            subTab.feature ? this.authDataService.checkFeature(subTab.feature) :
              subTab.features ? this.authDataService.checkFeatures(subTab.features) :
                subTab.featureGroup ? this.authDataService.checkFeatureGroup(subTab.featureGroup) : true
          )
        )
      }));
  }
}
