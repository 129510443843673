import { Component, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-builder-days-week',
  templateUrl: './builder-days-week.component.html',
  styleUrls: [ './builder-days-week.component.scss', './builder-days-week.component.rtl.scss' ]
})
export class BuilderDaysWeekComponent {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-days-week';

  checkboxes: any = [
    { check: true, value: 'duplicate', name: 'builder.daysWeek.duplicate' },
    { check: false, value: 'rebuildNewDays', name: 'builder.daysWeek.rebuildNewDays' }
  ];

  constructor(
    private bsModalRef: BsModalRef
  ) {}

  checkItem(item: any) {
    item.check = !item.check;

    const otherItems = this.checkboxes.filter((ob: any) => ob.value !== item.value);

    if (item.check) {
      otherItems.forEach((ob: any) => ob.check = false);
    } else {
      otherItems.forEach((ob: any) => ob.check = true);
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  apply() {
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { checkboxes: this.checkboxes } });
  }
}
