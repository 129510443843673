import { URangePreset } from '@shift/ulib';

import { GeneralCardButton, GeneralCardButtonValue } from '@app/shared/models';
import { routesConfig } from '@app/routes/configs';

const DICTIONARY_PATH: string = 'routes.restoreRide';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  period: `${DICTIONARY_PATH}.period`,
  actions: {
    saveAndClose: 'general.saveAndClose',
    saveAndSendSC: 'general.saveAndSendSC',
    saveAndEditEmail: 'general.saveAndEditEmail'
  },
  noCanceledRides: `${DICTIONARY_PATH}.noCanceledRides`
};

const availablePresets: URangePreset[] = [
  URangePreset.DisplayedDay,
  URangePreset.Today,
  URangePreset.Tomorrow,
  URangePreset.ActiveWeekDay,
  URangePreset.FromTodayOn,
  URangePreset.FromCustomDayOn,
  URangePreset.All,
  URangePreset.Custom,
  URangePreset.UpcomingWeek
];

const buttons: GeneralCardButton[] = [
  {
    value: GeneralCardButtonValue.SaveAndClose,
    name: 'general.saveAndClose'
  },
  {
    value: GeneralCardButtonValue.SaveAndSendSC,
    name: 'general.saveAndSendSC'
  },
  {
    value: GeneralCardButtonValue.SaveAndEditEmail,
    name: 'general.saveAndEditEmail'
  }
];

export const routesRestoreRideComponentConfig = {
  dictionary,
  trackingId: `${routesConfig.trackingId} - Restore ride`,
  availablePresets,
  buttons
};
