import { Component, OnInit, HostBinding, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { appConfig } from '@app/shared/configs';
import { LocalizationService } from '@app/shared/services';
import { AppLanguage, DaysOfWeek } from '@app/shared/models';

@Component({
  selector: 'app-builder-days-duplicate',
  templateUrl: './builder-days-duplicate.component.html',
  styleUrls: [ './builder-days-duplicate.component.scss', './builder-days-duplicate.component.rtl.scss' ]
})
export class BuilderDaysDuplicateComponent implements OnInit {
  @Output() action = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'builder-days-duplicate';

  days: number[] = [];
  daysAvailable: number[] = [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ];
  isRtl = true;
  lang: AppLanguage = appConfig.defaultLanguage;

  constructor(
    private bsModalRef: BsModalRef,
    public localizationService: LocalizationService
  ) { }

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();
    this.lang = this.localizationService.getLanguage();
  }

  close() {
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { days: this.days } });
  }

  apply() {
    this.bsModalRef.hide();
    this.action.emit({ type: 'apply', content: { days: this.days } });
  }
}
