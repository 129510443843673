<div class="builder-duplicate-changes__wrapper" [delayClickOutsideInit]="true" (uClickOutside)="close()">
  <div class="builder-duplicate-changes__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-duplicate-changes__main">
    <div class="builder-duplicate-changes__message">
      {{'builder.duplicateChanges.message' | translate}}
    </div>
    <div class="builder-duplicate-changes__list" (click)="$event.stopPropagation()">
      <div class="builder-duplicate-changes__list-title">{{'builder.duplicateChanges.type' | translate}}</div>
      <div class="builder-duplicate-changes__list-block">
        <div class="builder-duplicate-changes__list-item">
          <u-select-s
            [placeholder]="'general.search' | translate"
            [items]="changeTypes"
            [formControl]="form.get('plannedType')"
            [container]="'body'"
            [placement]="isRtl ? [ 'bottom-right' ] : [ 'bottom-left' ]"
            [empty]="false"
            [messages]="'uSelectS' | translate"
          ></u-select-s>
        </div>
        <div class="builder-duplicate-changes__list-item">
          <u-range
            [messages]="'uRange' | translate"
            [classUInput]="'u-input_cursor-pointer u-input_arrow'"
            [classUDropdownContent]="'u-dropdown-content_app-builder-duplicate-changes'"
            [sundayFirstDay]="true"
            [dateFrom]="datesChanges.dateFrom"
            [dateTo]="datesChanges.dateTo"
            [customDay]="duplicateDay"
            [type]="datesChanges.type"
            [checkDaysActive]="datesChanges.checkDaysActive"
            [checkDaysAvailable]="datesChanges.checkDaysAvailable"
            [dates]="datesChanges.dates"
            [isRtl]="isRtl"
            [lang]="lang"
            [viewportElement]="viewportElement"
            [placement]="isRtl ? [ 'bottom-right' ] : [ 'bottom-left' ]"
            [placementCalendar]="isRtl ? ['left-top', 'right-top'] : ['right-top', 'left-top']"
            [availablePresets]="form.get('plannedType').value === routeChangesType.Planned ? availablePresetsPlanned : availablePresetsNotPlanned"
            [checkDays]="true"
            [showIcon]="false"
            [required]="true"
            (saveAction)="save($event)"
          ></u-range>
        </div>
      </div>
    </div>
    <div class="builder-duplicate-changes__actions">
      <button class="builder-duplicate-changes__actions-btn" (click)="apply()">{{'builder.duplicateChanges.apply' | translate}}</button>
    </div> 
  </div>
</div>
