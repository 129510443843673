import { Injectable } from '@angular/core';
import { escape } from 'lodash';
import { UHereMapMarkerCluster } from '@shift/ulib';

import { builderFullConfig } from '@app/builder/configs';
import { RoutePlannerService } from '@app/route-planner/services';
import { BuilderFullCalculationService } from './builder-full-calculation.service';
import { BuilderFullCommonService } from './builder-full-common.service';

@Injectable()
export class BuilderFullMapService {
  markers: UHereMapMarkerCluster[] = [];

  constructor(
    private routePlannerService: RoutePlannerService,
    private builderFullCalculationService: BuilderFullCalculationService,
    private builderFullCommonService: BuilderFullCommonService
  ) {}

  loadMapData() {
    const passengerIds = this.builderFullCalculationService.passengerIds;
    const routePolicy = this.builderFullCalculationService.routePolicy.value;

    this.routePlannerService
      .loadMap({
        passengerIds,
        routePolicy
      })
      .subscribe((data: any) => {
        this.parseMarkers(data);

        this.builderFullCommonService.updateVisible({
          map: true,
          passengers: false
        });
      });
  }

  parseMarkers({ passengers, targets }) {
    const passengersMarkers = passengers.map(passenger => ({
      content: escape(passenger.name),
      position: {
        lat: passenger.latitude,
        lng: passenger.longitude
      },
      icon: builderFullConfig.map.markers.passenger.icon
    }));

    const targetsMarkers = targets.map(target => ({
      content: escape(target.name),
      latitude: target.latitude,
      longitude: target.longitude,
      position: {
        lat: target.latitude,
        lng: target.longitude
      },
      icon: builderFullConfig.map.markers.destination.icon
    }));

    this.markers = [ ...passengersMarkers, ...targetsMarkers ];
  }

  resetMarkers() {
    this.markers = [];
  }
}
