import { Injectable, computed, inject, signal } from '@angular/core';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { pipe, Subject } from 'rxjs';

import { patchSignal, rxMethod, tapResponse } from '@app/shared/utils';
import { RoutePlannerService } from '@app/route-planner/services';
import { RoutePlannerPassenger } from '@app/route-planner/models';
import { BuilderRoutesPassengersInfoState } from '@app/builder/models';

@Injectable()
export class BuilderRoutesPassengersInfoStoreService {
  private readonly routePlannerService = inject(RoutePlannerService);

  private readonly cancelGetInfoRequest = new Subject<void>();

  readonly #state = signal<BuilderRoutesPassengersInfoState>({ info: {} });

  readonly state = this.#state.asReadonly();
  readonly info = computed(() => this.state().info);
  readonly infoByRouteId = (routeId: number) => computed(() => this.info()[routeId]);

  readonly getInfo = rxMethod<{
    routeId: number;
  }>(
    pipe(
      filter(({ routeId }) => this.infoByRouteId(routeId)() === undefined),
      switchMap(({ routeId }) =>
        this.routePlannerService.getPassengers(routeId)
          .pipe(
            takeUntil(this.cancelGetInfoRequest),
            tapResponse(
              info => this.updateInfo(routeId, info),
              () => null
            )
          )
      )
    )
  );

  updateInfo(routeId: number, info: RoutePlannerPassenger[]) {
    patchSignal(this.#state, state => ({
      info: {
        ...state.info,
        [routeId]: info || null
      }
    }));
  }

  clearInfo(routeId: number) {
    patchSignal(this.#state, state => ({
      info: {
        ...state.info,
        [routeId]: undefined
      }
    }));
  }

  reset() {
    this.#state.set({ info: {} });
  }

  cancelGetInfo() {
    this.cancelGetInfoRequest.next();
  }
}
