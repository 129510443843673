<div class="monitoring-dashboard-map__wrapper">
  <u-here-map
    [center]="coords"
    [zoom]="zoom"
    [bounds]="bounds"
  >
    <u-here-map-point-center
      [position]="stationCenter.position"
      [config]="stationCenter.config"
    ></u-here-map-point-center>
    <u-here-map-traffic *ngIf="traffic"></u-here-map-traffic>
    <u-here-map-marker
      *ngFor="let marker of monitoringDashboardDataService.markers"
      [config]="marker"
    ></u-here-map-marker>
    <u-here-map-path
      *ngFor="let path of monitoringDashboardDataService.paths"
      [config]="path.config"
    ></u-here-map-path>
    <u-here-map-path-live
      *ngFor="let pathLive of monitoringDashboardDataService.pathsLive; trackBy: trackBySourceType"
      [polylineOptions]="pathLive.polylineOptions"
      [showPointsInfo]="pathLive.showPointsInfo"
      [showPolyline]="pathLive.showPolyline"
      [icon]="pathLive.icon"
      [points]="pathLive.points"
    ></u-here-map-path-live>
    <u-here-map-markers-info
      *ngFor="let pathMarkers of monitoringDashboardDataService.monitoredPathsMarkers"
      [markers]="pathMarkers"
    ></u-here-map-markers-info>
    <u-here-map-markers-live
      *ngIf="monitoringDashboardDataService.allVehicles.length"
      [markers]="monitoringDashboardDataService.allVehicles"
    ></u-here-map-markers-live>
    <u-here-map-markers-live
      *ngIf="monitoringDashboardDataService.allAccompanies.length"
      [markers]="monitoringDashboardDataService.allAccompanies"
    ></u-here-map-markers-live>
    <u-here-map-markers-live
      *ngIf="monitoringDashboardDataService.allDrivers.length"
      [markers]="monitoringDashboardDataService.allDrivers"
    ></u-here-map-markers-live>
    <u-here-map-markers-live
      *ngFor="let passengerLive of monitoringDashboardDataService.passengersLive"
      [markers]="passengerLive.points"
    ></u-here-map-markers-live>
  </u-here-map>
</div>
