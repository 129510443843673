import { PassengersInitialDataState } from '@app/passengers/models';

const initialState: PassengersInitialDataState = {
  data: undefined,
  initialBaseDataTypesLoaded: false,
  customerId: undefined
};

export const passengersInitialDataConfig = {
  initialState
};
