import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  HostBinding
} from '@angular/core';
import { cloneDeep } from 'lodash';

import { LocalizationService } from '@app/shared/services';
import { AppLanguage } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { ShuttleCompanyFinesService } from '@app/shuttle-companies/services';
import { builderFinesComponentConfig } from './builder-fines.component.config';

@Component({
  selector: 'app-builder-fines',
  templateUrl: './builder-fines.component.html',
  styleUrls: [ './builder-fines.component.scss', './builder-fines.component.rtl.scss' ]
})
export class BuilderFinesComponent implements OnInit {
  @Input() builderDataConfig: any;
  @Input() routeId: number;

  @HostBinding('class') hostClasses: string = 'builder-fines';

  @ViewChild('dateColumn', { static: false }) public dateTemplate: TemplateRef<any>;
  @ViewChild('commentColumn', { static: false }) public commentTemplate: TemplateRef<any>;
  @ViewChild('finePriceColumn', { static: false }) public finePriceTemplate: TemplateRef<any>;
  @ViewChild('createdByColumn', { static: false }) public createdByTemplate: TemplateRef<any>;
  @ViewChild('deletedByColumn', { static: false }) public deletedByTemplate: TemplateRef<any>;
  @ViewChild('fineDescriptionColumn', { static: false }) public fineDescriptionTemplate: TemplateRef<any>;

  rows: any[] = [];
  lang: AppLanguage;
  columns: any[] = [];
  isRtl: boolean = this.localizationService.isRtl();
  config = cloneDeep(builderFinesComponentConfig);
  appConstants = AppConstants;

  constructor(
    private shuttleCompanyFinesService: ShuttleCompanyFinesService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.lang = this.localizationService.getLanguage();

    this.setColumns();
    this.setTableData(this.routeId);
  }

  setColumns() {
    this.columns = [
      { name: this.config.dictionary.executor, prop: 'createdByName', cellTemplate: this.createdByTemplate, minWidth: 102, width: 120, resizeable: true },
      { name: this.config.dictionary.date, prop: 'date', cellTemplate: this.dateTemplate, minWidth: 60, width: 60, resizeable: true },
      { name: this.config.dictionary.shuttleCompany, prop: 'supplierName', minWidth: 102, resizeable: true },
      { name: this.config.dictionary.contract, prop: 'contractNumber', minWidth: 85, width: 85, resizeable: true },
      { name: this.config.dictionary.violation, prop: 'fineDescription', cellTemplate: this.fineDescriptionTemplate, minWidth: 102, width: 120, resizeable: true },
      { name: this.config.dictionary.amount, prop: 'finePriceTemplate', cellTemplate: this.finePriceTemplate, minWidth: 81, width: 81, resizeable: true },
      { name: this.config.dictionary.comment, prop: 'comment', cellTemplate: this.commentTemplate, minWidth: 94, width: 94, resizeable: true },
      { name: '', hideable: false, prop: 'deletedByName', cellTemplate: this.deletedByTemplate, minWidth: 119, width: 140, resizeable: true }
    ];
  }

  setTableData(routeId: number) {
    if (routeId) {
      const params = {
        routeId: routeId
      };
      this.shuttleCompanyFinesService.getFines(params)
        .subscribe(data => {
          if (data) {
            this.rows = data;
          }
        });
    }
  }

  deleteFine(id: number, routeId: number) {
    const params = {
      id: id
    };
    this.shuttleCompanyFinesService.deleteFine(params)
      .subscribe(() => this.setTableData(routeId));
  }
}
