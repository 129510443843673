import { UDateFormatPipe, USearchFilter, USearchFilterType, UThreeDotsPopoverItem } from '@shift/ulib';

import { demandsConfig } from '@app/demands/configs';
import { CustomerDataType, DaysOfWeek, HeaderMenuIconsTemplate, GlobalSearchFilter, ShiftsOption, GridHeaderTemplate } from '@app/shared/models';
import { PassengerGender } from '@app/passengers/models';
import { AppConstants } from '@app/shared/constants';
import {
  AuthModuleDemandsPassengersViewFeature,
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType,
  AuthModuleFeatureGroupCondition,
  AuthModuleName,
  AuthModuleDemandsPassengersViewFeatureType,
  AuthModuleDemandsShiftsViewFeature
} from '@app/auth/models';
import { DemandsView } from '@app/demands/models';

const DEMANDS_DICTIONARY_PATH: string = 'demands';

const bulkAssignRow = {
  id: -1,
  showCloseIcon: true,
  bulkAssignShift: true,
  firstName: `${DEMANDS_DICTIONARY_PATH}.assignShift.bulkAssignShift`
};

const bulkAssignRowStudent = {
  id: -1,
  showCloseIcon: true,
  bulkAssignShift: true,
  firstName: `${DEMANDS_DICTIONARY_PATH}.assignShift.bulkAssignActivity`
};

const threeDotsItems: UThreeDotsPopoverItem[] = [
  { name: `${DEMANDS_DICTIONARY_PATH}.table.threeDots.duplicate`, action: 'duplicateWeek' },
  { name: `${DEMANDS_DICTIONARY_PATH}.table.threeDots.delete`, action: 'deleteWeek' }
];

const PASSENGERS_TABLE_DICTIONARY_PATH: string = 'passengers.table';

const baseColumns = [
  {
    prop: 'check',
    name: '',
    hideable: false,
    width: 53,
    maxWidth: 53,
    minWidth: 53,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    headerCheckboxable: true,
    checkboxable: true,
    custom: true,
    hideDivider: true
  },
  {
    prop: 'firstName',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.firstName`,
    filterType: 'text',
    minWidth: 170,
    cellTemplateName: 'demandsNameCell'
  },
  {
    prop: 'lastName',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.lastName`,
    filterType: 'text',
    minWidth: 170
  }
];

const departmentColumn = {
  prop: 'department',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.department`,
  filterType: 'select',
  minWidth: 119,
  hideColumn: true,
  cellTemplateName: 'departmentCell',
  feature: {
    module: AuthModuleName.Departments,
    name: AuthModuleDepartmentsFeature.Type,
    values: [ AuthModuleDepartmentsFeatureType.Generic, AuthModuleDepartmentsFeatureType.Iec ]
  }
};

const branchColumn = {
  prop: 'branch',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.branch`,
  minWidth: 119,
  sortable: true,
  hideColumn: true,
  filterType: 'select'
};

const addressColumn = {
  prop: 'address',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.address`,
  minWidth: 221,
  sortable: true,
  hideColumn: true,
  filterType: 'text'
};

const mobileContactColumn = {
  prop: 'mobileContact',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.mobile`,
  minWidth: 102,
  sortable: true,
  hideColumn: true,
  filterType: 'text'
};

const statusColumn = {
  prop: 'status',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.status`,
  minWidth: 102,
  sortable: true,
  hideColumn: true,
  filterType: 'select',
  cellTemplateName: 'statusCell',
  filterTemplateName: 'statusCellFilter'
};

const commentColumn = {
  prop: 'comments',
  propAlias: 'comment',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.comment`,
  minWidth: 119,
  sortable: true,
  hideColumn: true,
  filterType: 'text',
  cellTemplateName: 'commentCell'
};

const stationNameColumn = {
  prop: 'stationName',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.stationName`,
  minWidth: 119,
  sortable: true,
  hideColumn: true,
  filterType: 'select',
  cellTemplateName: 'stationCell'
};

const distanceToStationCell = {
  prop: 'distanceToStation',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.distanceToStation`,
  sortable: true,
  minWidth: 136,
  filterType: 'select',
  hideColumn: true,
  filterTypeItemsFnName: 'distanceToStationFilterTypeItemsFn',
  filterTypeItemsSearchFn: (value, searchValue) => {
    if (!isNaN(searchValue)) {
      return value === searchValue;
    }

    return value >= 0 && (searchValue.between ? value >= searchValue.between : true) && (searchValue.to ? value <= searchValue.to : true);
  },
  filterTemplateName: 'distanceToStationCellFilter',
  cellTemplateName: 'distanceToStationCell'
};

const eligibleForShuttleColumn = {
  prop: 'eligibleForShuttle',
  propAlias: 'eligibleForTransportation',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.eligibleForTransportation`,
  minWidth: 119,
  sortable: true,
  hideColumn: true,
  filterType: 'select',
  cellTemplateName: 'yesNoCell',
  filterTemplateName: 'yesNoCellFilter'
};

const identityColumn = {
  prop: 'identity',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.identity`,
  minWidth: 119,
  sortable: true,
  hideColumn: true,
  filterType: 'text'
};

const birthDateColumn = {
  prop: 'birthDate',
  name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.birthDate`,
  hideColumn: true,
  sortable: true,
  minWidth: 119,
  filterType: 'text',
  pipe: new UDateFormatPipe(AppConstants.DATE_FORMAT_BASE_DOT)
};

const columns = [
  ...baseColumns,
  departmentColumn,
  {
    ...departmentColumn,
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.departmentDivision`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Division ]
    }
  },
  {
    prop: 'role',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.role`,
    minWidth: 109,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  identityColumn,
  addressColumn,
  mobileContactColumn,
  {
    prop: 'passengerNumber',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.passengerNumber`,
    minWidth: 102,
    sortable: true,
    hideColumn: true,
    filterType: 'text'
  },
  branchColumn,
  eligibleForShuttleColumn,
  statusColumn,
  commentColumn,
  stationNameColumn
];

const columnsStudent = [
  ...baseColumns,
  identityColumn,
  addressColumn,
  mobileContactColumn,
  {
    prop: 'phoneContact',
    propAlias: 'phone',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.phone`,
    minWidth: 102,
    sortable: true,
    hideColumn: true,
    filterType: 'text'
  },
  {
    prop: 'schoolIdentity',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.schoolIdentity`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  {
    prop: 'schoolName',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.schoolName`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  {
    prop: 'class',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.class`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  {
    prop: 'disabilityCode',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.disabilityCode`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  {
    prop: 'secondaryDisabilityCode',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.secondaryDisabilityCode`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  {
    prop: 'thirdDisabilityCode',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.thirdDisabilityCode`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  departmentColumn,
  {
    prop: 'gender',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.gender`,
    sortable: true,
    hideColumn: true,
    minWidth: 119,
    filterType: 'select',
    cellTemplateName: 'genderCell',
    filterTemplateName: 'genderCellFilter'
  },
  birthDateColumn,
  commentColumn,
  statusColumn,
  {
    prop: 'eligibleForAccompany',
    propAlias: 'needAccompany',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.needAccompany`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select',
    cellTemplateName: 'yesNoCell',
    filterTemplateName: 'yesNoCellFilter'
  },
  eligibleForShuttleColumn,
  {
    prop: 'needElevator',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.needElevator`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select',
    cellTemplateName: 'yesNoCell',
    filterTemplateName: 'yesNoCellFilter'
  },
  {
    prop: 'parent1Name',
    propAlias: 'parent1Name',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.parent1Name`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'text'
  },
  {
    prop: 'parent1Mobile',
    propAlias: 'parent1Mobile',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.parent1Mobile`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'text'
  },
  {
    prop: 'parent2Name',
    propAlias: 'parent2Name',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.parent2Name`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'text'
  },
  {
    prop: 'parent2Mobile',
    propAlias: 'parent2Mobile',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.parent2Mobile`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'text'
  },
  {
    prop: 'distanceToSchool',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.distanceToSchool`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'select'
  },
  {
    prop: 'schoolAddress',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.schoolAddress`,
    minWidth: 119,
    sortable: true,
    hideColumn: true,
    filterType: 'text'
  },
  stationNameColumn
];

const columnsSoldier = [
  ...baseColumns,
  {
    ...departmentColumn,
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.command`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Section ]
    }
  },
  {
    ...branchColumn,
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.base`
  },
  addressColumn,
  mobileContactColumn,
  {
    prop: 'tags',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.tags`,
    sortable: true,
    minWidth: 102,
    cellTemplateName: 'tagsCell'
  },
  birthDateColumn,
  statusColumn,
  commentColumn,
  eligibleForShuttleColumn,
  {
    prop: 'pickUpType',
    propAlias: 'pickUpFromStation',
    name: `${PASSENGERS_TABLE_DICTIONARY_PATH}.pickUpFromStation`,
    sortable: true,
    minWidth: 119,
    cellTemplateName: 'yesNoCell',
    filterTemplateName: 'yesNoCellFilter',
    filterType: 'select'
  },
  stationNameColumn
];

const columnsGeneric = [
  ...columns,
  distanceToStationCell
];

const citiesFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'cities',
  type: USearchFilterType.InputCitiesMultiselect,
  title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.cities`
};

const branchesFilter: GlobalSearchFilter = {
  items: null,
  value: [],
  name: 'branchIds',
  type: USearchFilterType.Multiselect,
  title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.branches`,
  resetFiltersByNames: [ citiesFilter.name ]
};

const shiftsFilter: GlobalSearchFilter = {
  items: [
    {
      value: ShiftsOption.Without,
      name: `${DEMANDS_DICTIONARY_PATH}.globalFilters.options.shifts.without`
    },
    {
      value: ShiftsOption.Special,
      name: `${DEMANDS_DICTIONARY_PATH}.globalFilters.options.shifts.special`
    }
  ],
  itemsLazyLoad: true,
  value: [],
  name: 'shiftIds',
  type: USearchFilterType.Multiselect,
  title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.shifts`,
  featureGroup: {
    condition: AuthModuleFeatureGroupCondition.Or,
    values: [
      [
        {
          module: AuthModuleName.DemandsPassengersView,
          name: AuthModuleDemandsPassengersViewFeature.Type
        }
      ],
      [
        {
          module: AuthModuleName.DemandsShiftsView,
          name: AuthModuleDemandsShiftsViewFeature.Type
        }
      ]
    ]
  }
};

const departmentsGlobalFilter: GlobalSearchFilter = {
  items: [],
  value: [],
  name: 'departmentIds',
  type: USearchFilterType.Multiselect,
  title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.departments`,
  feature: {
    module: AuthModuleName.Departments,
    name: AuthModuleDepartmentsFeature.Type,
    values: [ AuthModuleDepartmentsFeatureType.Generic, AuthModuleDepartmentsFeatureType.Iec ]
  }
};

const baseFilters: (GlobalSearchFilter)[] = [
  {
    items: [
      {
        value: 0,
        name: 'general.yes'
      },
      {
        value: 1,
        name: 'general.no'
      }
    ],
    value: null,
    name: 'demandType',
    type: USearchFilterType.Select,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.byReservation`,
    emptyName: `${DEMANDS_DICTIONARY_PATH}.globalFilters.byReservation`
  },
  departmentsGlobalFilter,
  {
    ...departmentsGlobalFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.departmentsDivision`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Division ]
    }
  },
  {
    ...departmentsGlobalFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.commands`,
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Section ]
    }
  }
];

const globalFilters: USearchFilter[] = [
  branchesFilter,
  citiesFilter,
  shiftsFilter,
  ...baseFilters
];

const globalFiltersStudent: USearchFilter[] = [
  {
    ...branchesFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.activityCenters`
  },
  citiesFilter,
  {
    ...shiftsFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.activities`
  },
  ...baseFilters
];

const globalFiltersSoldier: USearchFilter[] = [
  {
    ...branchesFilter,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.bases`
  },
  citiesFilter,
  shiftsFilter,
  ...baseFilters,
  {
    items: [],
    value: [],
    name: 'tagIds',
    type: USearchFilterType.Multiselect,
    title: `${DEMANDS_DICTIONARY_PATH}.globalFilters.tags`
  }
];

const customerDataFilters = {
  branchIds: CustomerDataType.Branches,
  shiftIds: CustomerDataType.AvailableShifts,
  departmentIds: CustomerDataType.Departments
};

const demandsPassengersViewComponent = {
  dictionary: {
    genders: {
      [PassengerGender.Male]: 'passengers.general.genders.male',
      [PassengerGender.Female]: 'passengers.general.genders.female'
    },
    specialShift: `${DEMANDS_DICTIONARY_PATH}.globalFilters.options.shifts.special`,
    weekDays: `${DEMANDS_DICTIONARY_PATH}.weekDays`
  },
  table: {
    id: 'demandsTable',
    trackingId: demandsConfig.tracking[DemandsView.Passengers].default.id,
    headerMenuIconsTemplate: HeaderMenuIconsTemplate.DemandsPassengersView,
    gridHeaderTemplate: GridHeaderTemplate.DemandsPassengersView,
    modalComponent: null,
    dictionary: {
      yes: 'general.yes',
      no: 'general.no',
      deleteConfirm: `${DEMANDS_DICTIONARY_PATH}.table.deleteConfirm`,
      passengers: `${DEMANDS_DICTIONARY_PATH}.table.threeDots.passengers`,
      deleteWeekConfirm: `${DEMANDS_DICTIONARY_PATH}.table.threeDots.deleteConfirm`
    },
    tableConfig: {
      columns,
      showCounters: true
    }
  },
  bulkAssignRow,
  bulkAssignRowStudent,
  threeDotsItems,
  daysOfWeek: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ],
  globalFilters,
  customerDataFilters
};

const demandsPassengersStudentComponent = {
  ...demandsPassengersViewComponent,
  table: {
    ...demandsPassengersViewComponent.table,
    id: 'demandsPassengersTableStudent',
    trackingId: demandsConfig.tracking[DemandsView.Passengers].activitySchedule.id,
    tableConfig: {
      ...demandsPassengersViewComponent.table.tableConfig,
      columns: columnsStudent
    }
  },
  globalFilters: globalFiltersStudent
};

const demandsPassengersSoldierComponent = {
  ...demandsPassengersViewComponent,
  table: {
    ...demandsPassengersViewComponent.table,
    id: 'demandsPassengersTableSoldier',
    tableConfig: {
      ...demandsPassengersViewComponent.table.tableConfig,
      columns: columnsSoldier
    }
  },
  globalFilters: globalFiltersSoldier,
  customerDataFilters: {
    ...customerDataFilters,
    tagIds: CustomerDataType.Tags
  }
};

const demandsPassengersGenericComponent = {
  ...demandsPassengersViewComponent,
  dictionary: {
    ...demandsPassengersViewComponent.dictionary,
    noStation: `${PASSENGERS_TABLE_DICTIONARY_PATH}.noStation`,
    noValue: `${PASSENGERS_TABLE_DICTIONARY_PATH}.noValue`,
    between: `${PASSENGERS_TABLE_DICTIONARY_PATH}.between`,
    under: `${PASSENGERS_TABLE_DICTIONARY_PATH}.under`,
    above: `${PASSENGERS_TABLE_DICTIONARY_PATH}.above`
  },
  table: {
    ...demandsPassengersViewComponent.table,
    id: 'demandsPassengersTableGeneric',
    tableConfig: {
      ...demandsPassengersViewComponent.table.tableConfig,
      columns: columnsGeneric
    }
  }
};

export const demandsPassengersViewComponentConfig = {
  default: demandsPassengersViewComponent,
  [AuthModuleDemandsPassengersViewFeatureType.Student]: demandsPassengersStudentComponent,
  [AuthModuleDemandsPassengersViewFeatureType.Generic]: demandsPassengersGenericComponent,
  [AuthModuleDemandsPassengersViewFeatureType.Soldier]: demandsPassengersSoldierComponent
};
