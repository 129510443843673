import { URangePreset } from '@shift/ulib';

import { RoutesChangePresetsType, RoutesChangeType } from '@app/routes/models';
import { AuthCustomerType } from '@app/auth/models';

const dictionary = {
  planned: 'general.planned',
  unplanned: 'general.unplanned'
};

const changePresetsType: RoutesChangePresetsType = {
  planned: URangePreset.All,
  fixed: URangePreset.DisplayedDay
};

const routesPeriodComponent = {
  dictionary,
  changePresetsType,
  types: [
    { name: dictionary.planned, value: RoutesChangeType.Planned },
    { name: dictionary.unplanned, value: RoutesChangeType.Unplanned }
  ],
  availablePresetsByTypes: {
    displayedDay: [
      URangePreset.DisplayedDay,
      URangePreset.Today,
      URangePreset.Tomorrow,
      URangePreset.ActiveWeekDay,
      URangePreset.FromCustomDayOn,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    today: [
      URangePreset.DisplayedDay,
      URangePreset.Today,
      URangePreset.Tomorrow,
      URangePreset.ActiveWeekDay,
      URangePreset.FromCustomDayOn,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    fromTodayOn: [
      URangePreset.ActiveWeekDay,
      URangePreset.FromTodayOn,
      URangePreset.FromCustomDayOn,
      URangePreset.All,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    fromTomorrowOn: [
      URangePreset.ActiveWeekDay,
      URangePreset.FromTomorrowOn,
      URangePreset.FromCustomDayOn,
      URangePreset.All,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    all: [
      URangePreset.ActiveWeekDay,
      URangePreset.FromTodayOn,
      URangePreset.FromTomorrowOn,
      URangePreset.FromCustomDayOn,
      URangePreset.All,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    custom: [
      URangePreset.ActiveWeekDay,
      URangePreset.FromCustomDayOn,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ]
  },
  availablePresets: null
};

const routesPeriodComponentShuttleCompany = {
  ...routesPeriodComponent,
  types: [
    { name: dictionary.unplanned, value: RoutesChangeType.Unplanned }
  ],
  availablePresets: [
    URangePreset.Today,
    URangePreset.Custom
  ]
};

export const routesPeriodComponentConfig = {
  default: routesPeriodComponent,
  [AuthCustomerType.ShuttleCompany]: routesPeriodComponentShuttleCompany
};
