import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiBaseError } from '@app/shared/models';
import { ApiService } from '@app/shared/services';
import { RoutesDailyMovePassengersBody, RoutesDailyMovePassengersRouteBase } from '@app/routes/models';

@Injectable()
export class RoutesPassengersMoveService {
  private BASE_PATH: string = 'RoutesPassengersMove';

  constructor(
    private apiService: ApiService
  ) {}

  initialize(): Observable<string> {
    return this.apiService.post(`${this.BASE_PATH}/Initialize`);
  }

  getRoutes(date: string, routeIds: number[]): Observable<RoutesDailyMovePassengersRouteBase[]> {
    return this.apiService.get(`${this.BASE_PATH}/Routes`, { date, routeIds });
  }

  removeRoutes(routeIds: number[]): Observable<void> {
    return this.apiService.get(`${this.BASE_PATH}/RemoveRoutes`, { routeIds });
  }

  movePassengers(body: RoutesDailyMovePassengersBody): Observable<void | { errors: ApiBaseError[]; }> {
    return this.apiService.post(this.BASE_PATH, body, 'text', false, false, null, true);
  }
}
