import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgProgressModule } from 'ngx-progressbar';
import { cloneDeep } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';

import { ActivitiesModule } from '@app/activities/activities.module';
import { NotesModule } from '@app/notes/notes.module';
import { AuthFacade } from '@app/auth/state/facades';
import { appConfig } from '@app/shared/configs';
import { VisibleComponent } from '@app/shared/models';
import { CommonService, HeaderSearchFiltersService, LocalizationService, HeaderDataService, LayoutService } from '@app/shared/services';
import { HeaderComponent } from '../header/header.component';
import { SidebarMenuComponent } from '../sidebar-menu/sidebar-menu.component';
import { layoutComponentConfig } from './layout.component.config';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss', './layout.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ RouterModule, CommonModule, NgProgressModule, TranslateModule, ActivitiesModule, NotesModule, HeaderComponent, SidebarMenuComponent ]
})
export class LayoutComponent {
  @HostBinding('class')
  get hostClasses() {
    return {
      'layout': true,
      [this.layoutService.class()]: !!this.layoutService.class()
    };
  }

  private readonly authFacade = inject(AuthFacade);
  private readonly localizationService = inject(LocalizationService);
  private readonly layoutService = inject(LayoutService);
  public readonly commonService = inject(CommonService);
  public readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  public readonly headerDataService = inject(HeaderDataService);

  readonly progressBarId: string = appConfig.progressBarId;
  readonly isRtl: boolean = this.localizationService.isRtl();
  readonly visibleComponent = VisibleComponent;

  readonly countdownCounter$ = this.authFacade.countdownCounter$;

  config = cloneDeep(layoutComponentConfig);
}
