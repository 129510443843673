import {
  assertInInjectionContext,
  DestroyRef,
  effect,
  inject,
  Injector,
  isSignal,
  Signal,
  untracked,
  WritableSignal
} from '@angular/core';
import { Observable, fromEvent, merge, combineLatest, interval, of, EMPTY, isObservable, noop, Subject } from 'rxjs';
import { distinctUntilChanged, share, filter, switchMap, map, catchError, finalize, tap  } from 'rxjs/operators';
import { KeyCode } from '@shift/ulib';

import { PatchSignalUpdater, RxMethod, TapResponseObserver } from '@app/shared/models';

export const fromEventCustom = (target, eventName, type = false) => new Observable((subscriber) => {
  const handler = event => subscriber.next(event);

  // Add the event handler to the target
  target.addEventListener(eventName, handler, type);

  return () => {
    // Detach the event handler from the target
    target.removeEventListener(eventName, handler);
  };
});

export const counterTimer = (count: number, intervalTime: number) => of(new Date())
  .pipe(
    switchMap(startDate =>
      interval(intervalTime)
        .pipe(
          map(() => count - Math.round((new Date().valueOf() - startDate.valueOf()) / 1000))
        )
    )
  );

export const shortcut = (keyCodes: KeyCode[]) => {
  const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown');
  const keyUp$ = fromEvent<KeyboardEvent>(document, 'keyup');

  const keyEvents = merge(keyDown$, keyUp$).pipe(
    distinctUntilChanged((prev, next) => prev.code === next.code && prev.type === next.type),
    share()
  );

  const createKeyPressStream = (charCode: KeyCode) =>
    keyEvents.pipe(filter(event => event.code === charCode.valueOf()));

  return combineLatest(keyCodes.map(el => createKeyPressStream(el)))
    .pipe(
      filter<KeyboardEvent[]>(arr => arr.every(ob => ob.type === 'keydown'))
    );
};

export const patchSignal = <T>(source: WritableSignal<T>, updater: Partial<T> | PatchSignalUpdater<Partial<T>>) => {
  source.update(state => ({ ...state, ...(typeof updater === 'function' ? updater(state) : updater) }));
};

export function sequence() {
  return (source: Observable<KeyboardEvent[]>) => source.pipe(
    filter(arr => {
      const sorted = [ ...arr ]
        .sort((prev, next) => (prev.timeStamp < next.timeStamp ? -1 : 1))
        .map(ob => ob.code)
        .join();

      const seq = arr.map(ob => ob.code).join();

      return sorted === seq;
    })
  );
}

export function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function isNotNullOrUndefined<T>() {
  return (source$: Observable<null | undefined | T>): Observable<T> => source$.pipe(filter(inputIsNotNullOrUndefined));
}

export function tapResponse<T, E>(
  observerOrNext: TapResponseObserver<T, E> | ((value: T) => void),
  error?: (error: E) => void,
  complete?: () => void
): (source$: Observable<T>) => Observable<T> {
  const observer: TapResponseObserver<T, E> =
    typeof observerOrNext === 'function'
      ? {
        next: observerOrNext,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        error: error!,
        complete
      }
      : observerOrNext;

  return (source) =>
    source.pipe(
      tap({ next: observer.next, complete: observer.complete }),
      catchError(err => {
        observer.error(err);

        return EMPTY;
      }),
      observer.finalize ? finalize(observer.finalize) : (source$) => source$
    );
}

export function rxMethod<Input>(
  generator: (source$: Observable<Input>) => Observable<unknown>,
  config?: { injector?: Injector }
): RxMethod<Input> {
  if (!config?.injector) {
    assertInInjectionContext(rxMethod);
  }

  const injector = config?.injector ?? inject(Injector);
  const destroyRef = injector.get(DestroyRef);
  const source$ = new Subject<Input>();
  const sourceSub = generator(source$).subscribe();

  destroyRef.onDestroy(() => sourceSub.unsubscribe());

  const rxMethodFn = (input: Input | Signal<Input> | Observable<Input>) => {
    if (isSignal(input)) {
      const watcher = effect(
        () => {
          const value = input();

          untracked(() => source$.next(value));
        },
        { injector }
      );

      const instanceSub = { unsubscribe: () => watcher.destroy() };

      sourceSub.add(instanceSub);

      return instanceSub;
    }

    if (isObservable(input)) {
      const instanceSub = input.subscribe((value) => source$.next(value));

      sourceSub.add(instanceSub);

      return instanceSub;
    }

    source$.next(input);

    return { unsubscribe: noop };
  };

  rxMethodFn.unsubscribe = sourceSub.unsubscribe.bind(sourceSub);

  return rxMethodFn;
}
