import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { isNotNullOrUndefined } from '@app/shared/utils';
import {
  RouteDailyItem,
  RoutesDailyFilters,
  RoutesDailyFilterType,
  RoutesDailyParams,
  RoutesWeeklyFilters
} from '@app/routes/models';
import * as RoutesActions from '@app/routes/state/actions';
import * as RoutesSelectors from '@app/routes/state/selectors';
import { RoutesViewType } from '@app/routes/models';

@Injectable()
export class RoutesFacade {
  dailyItems$: Observable<RouteDailyItem[]> = this.store.select(RoutesSelectors.selectRoutesDailyItemsState);
  dailyParams$: Observable<RoutesDailyParams> = this.store.select(RoutesSelectors.selectRoutesDailyParamsState);
  dailySelectedFiltersType$: Observable<RoutesDailyFilterType> = this.store.select(RoutesSelectors.selectRoutesDailySelectedFiltersType);
  weeklySelectedFilters$: Observable<RoutesWeeklyFilters> = this.store.select(RoutesSelectors.selectRoutesWeeklySelectedFilters).pipe(isNotNullOrUndefined());
  viewTypeValue$: Observable<RoutesViewType> = this.store.select(RoutesSelectors.selectRoutesViewTypeValue);

  dailyLoadSuccess$ = this.actions$.pipe(
    ofType(RoutesActions.dailyLoadSuccess),
    map(({ items }) => items)
  );

  dailyRouteNoteAdded$ = this.actions$.pipe(
    ofType(RoutesActions.dailyUpdateRouteNoteAdded),
    map(({ data }) => data)
  );

  dailyRouteNoteEdited$ = this.actions$.pipe(
    ofType(RoutesActions.dailyUpdateRouteNoteUpdated),
    map(({ data }) => data)
  );

  dailyRouteNoteRemoved$ = this.actions$.pipe(
    ofType(RoutesActions.dailyUpdateRouteNoteRemoved),
    map(({ data }) => data)
  );

  constructor(
    private readonly store: Store<any>,
    private readonly actions$: Actions
  ) {}

  private dispatch(action: Action) {
    this.store.dispatch(action);
  }

  dailyInit() {
    this.dispatch(RoutesActions.dailyInit());
  }

  dailyItemsInit(params: RoutesDailyParams) {
    this.dispatch(RoutesActions.dailyItemsInit({ params }));
  }

  dailyLoad(params: RoutesDailyParams) {
    this.dispatch(RoutesActions.dailyLoad({ params }));
  }

  dailyLoadCancel() {
    this.dispatch(RoutesActions.dailyLoadCancel());
  }

  dailySelectedFiltersUpdate(filters: RoutesDailyFilters) {
    this.dispatch(RoutesActions.dailySelectedFiltersUpdate({ filters }));
  }

  dailyItemsReset() {
    this.dispatch(RoutesActions.dailyItemsReset());
  }

  weeklySelectedFiltersInit(filters: RoutesWeeklyFilters) {
    this.dispatch(RoutesActions.weeklySelectedFiltersInit({ filters }));
  }

  weeklySelectedFiltersUpdate(filters: RoutesWeeklyFilters) {
    this.dispatch(RoutesActions.weeklySelectedFiltersUpdate({ filters }));
  }

  weeklyReset() {
    this.dispatch(RoutesActions.weeklyReset());
  }

  viewTypeUpdate(value: RoutesViewType) {
    this.dispatch(RoutesActions.viewTypeUpdate({ value }));
  }

  routeNotesRead(routeId: number, noteIds: number[]) {
    this.dispatch(RoutesActions.dailyUpdateRouteNotesRead({ data: { routeId, noteIds } }));
  }
}
