export enum RoutesDailyMovePassengersRouteDirection {
  Forward = 120,
  Backward = 121
}

export enum RoutesDailyMovePassengersRideChangePlanType  {
  Planned,
  Unplanned
}

export interface RoutesDailyMovePassengerBase {
  passengerId: number;
  rideStationId: number;
  routeId: number;
  name: string;
  isAnonymous: boolean;
  amount: number;
  mobile: string;
  stationName: string;
}

export interface RoutesDailyMovePassenger extends RoutesDailyMovePassengerBase {
  checked: boolean;
  disabled?: boolean;
  errors?: string[];
  temp?: boolean;
}

export interface RoutesDailyMovePassengersRouteBase {
  routeId: number;
  rideId: number;
  name: string;
  number: number;
  direction: RoutesDailyMovePassengersRouteDirection;
  startTime: string;
  endTime: string;
  vehicleTypeName: string;
  passengers: RoutesDailyMovePassengerBase[];
}

export interface RoutesDailyMovePassengersRoute extends RoutesDailyMovePassengersRouteBase {
  checked: boolean;
  passengers: RoutesDailyMovePassenger[];
  disabled?: boolean;
  invalidDirection?: boolean;
  temp?: boolean;
  dragProcessed?: boolean;
  hoveredByDragItem?: boolean;
  expanded?: boolean;
}

export interface RoutesDailyMovePassengersBody {
  date: string;
  allowCapacityChange: boolean;
  type: RoutesDailyMovePassengersRideChangePlanType;
  routeId: number;
  stations: {
    routeId: number;
    rideStationId: number;
    passengerIds: number[];
  }[];
}

export interface RoutesDailyMovePassengersMoveFinished {
  sessionId: string;
  destinationRouteId: number;
  sourceRouteIds: number[];
  errors: {
    routeId: number;
    errorCode: number;
    errorDescription: string;
  }[];
}
