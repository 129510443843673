import { AuthModuleName, AuthModuleRoutesTableFeature, AuthModuleRoutesTableFeatureMasterCustomer, AuthModuleRoutesTableFeatureType } from '@app/auth/models';
import { RoutesThreeDotsPopoverItemAction, RoutesViewTypeMode, RoutesWeeklyColumn } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.weekly';

const columns: RoutesWeeklyColumn[] = [
  {
    prop: 'check',
    name: '',
    hideable: false,
    width: 43,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    headerCheckboxable: true,
    checkboxable: true,
    custom: true,
    hideDivider: true
  },
  {
    prop: 'activities',
    name: '',
    frozenLeft: true,
    width: 48,
    hideable: false,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    custom: true,
    hideDivider: true,
    cellTemplateName: 'activitiesColumn',
    cellClass: 'activities-body-cell'
  },
  {
    prop: 'code',
    name: `${DICTIONARY_PATH}.tableColumns.rideNumber`,
    cellTemplateName: 'codeRoute',
    filterType: 'select',
    resizeable: false,
    minWidth: 158,
    maxWidth: 158
  },
  {
    prop: 'name',
    name: `${DICTIONARY_PATH}.tableColumns.routeName`,
    filterType: 'select',
    resizeable: false,
    minWidth: 111,
    maxWidth: 111
  },
  {
    prop: 'direction',
    name: `${DICTIONARY_PATH}.tableColumns.direction`,
    cellTemplateName: 'directionColumn',
    filterType: 'select',
    filterTemplateName: 'directionFilter',
    resizeable: false,
    minWidth: 94,
    maxWidth: 94
  },
  {
    prop: 'startDateTime',
    name: `${DICTIONARY_PATH}.tableColumns.startTime`,
    cellTemplateName: 'startTime',
    filterType: 'select',
    filterTemplateName: 'hourColumnFilter',
    resizeable: false,
    minWidth: 85,
    maxWidth: 85
  },
  {
    prop: 'endDateTime',
    name: `${DICTIONARY_PATH}.tableColumns.endTime`,
    cellTemplateName: 'endTime',
    filterType: 'select',
    filterTemplateName: 'hourColumnFilter',
    resizeable: false,
    minWidth: 94,
    maxWidth: 94
  },
  {
    prop: 'totalPassengers',
    name: `${DICTIONARY_PATH}.tableColumns.passengers`,
    filterType: 'select',
    resizeable: false,
    minWidth: 106,
    maxWidth: 107
  },
  {
    prop: 'totalPassengerCustomers',
    name: `${DICTIONARY_PATH}.tableColumns.customers`,
    filterType: 'select',
    resizeable: false,
    minWidth: 136,
    maxWidth: 136,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.MasterCustomer,
      values: [ AuthModuleRoutesTableFeatureMasterCustomer.MasterCustomerFeature ]
    }
  },
  {
    prop: 'shuttleCompany',
    name: `${DICTIONARY_PATH}.tableColumns.shuttleCompany`,
    filterType: 'select',
    resizeable: false,
    minWidth: 111,
    maxWidth: 111,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Type,
      values: [ AuthModuleRoutesTableFeatureType.Generic, AuthModuleRoutesTableFeatureType.Municipality, AuthModuleRoutesTableFeatureType.Army, AuthModuleRoutesTableFeatureType.ShuttleCompany ]
    }
  }
];

export const routesWeeklyComponentConfig = {
  dictionary: {
    tableWeekDays: `${DICTIONARY_PATH}.tableWeekDays`,
    anonymousAccompany: 'general.anonymousAccompany',
    none: 'general.none',
    edit: 'general.edit',
    view: 'general.view',
    doneBy: 'general.doneBy',
    removeSelect: 'general.removeSelect',
    direction: {
      forward: 'general.direction.forward',
      backward: 'general.direction.backward'
    },
    uGrid: 'uGrid',
    emptyValue: 'uGrid.emptyValue',
    uWeeklyChanges: 'uWeeklyChanges',
    requiredRecalculation: {
      message: 'routes.requiredRecalculation.message',
      openEditorAndSave: 'routes.requiredRecalculation.openEditorAndSave'
    },
    messagesTable: null,
    table: {
      search: `${DICTIONARY_PATH}.table.search`,
      accompanyRequired: `${DICTIONARY_PATH}.table.accompanyRequired`,
      driverRequired: `${DICTIONARY_PATH}.table.driverRequired`,
      inactive: `${DICTIONARY_PATH}.table.inactive`,
      canceled: `${DICTIONARY_PATH}.table.canceled`
    }
  },
  hiddenThreeDotsPopoverItemActions: [
    RoutesThreeDotsPopoverItemAction.MovePassengers,
    RoutesThreeDotsPopoverItemAction.NewFine,
    RoutesThreeDotsPopoverItemAction.SplitRoute
  ],
  columns,
  viewTypeMode: RoutesViewTypeMode.WeeklyView
};
