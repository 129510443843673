import { Component, OnInit, Input, HostBinding, inject, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isEqual, cloneDeep } from 'lodash';
import * as moment from 'moment';
import { UButtonSize, URangePreset, USelectSItem } from '@shift/ulib';

import {
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import {
  RoutesChangeType,
  RoutesChangeEmailSendType,
  RoutesChangeAccompanyType,
  RoutesChangeAccompanyReturn,
  RoutesChangesAccompanySourceStationType,
  RouteDailyRow
} from '@app/routes/models';
import { RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { routesChangeAccompanyComponentConfig } from './routes-change-accompany.component.config';

@Component({
  selector: 'app-routes-change-accompany',
  templateUrl: './routes-change-accompany.component.html',
  styleUrls: [ './routes-change-accompany.component.scss', './routes-change-accompany.component.rtl.scss' ],
  providers: [ BsModalRef ]
})
export class RoutesChangeAccompanyComponent implements OnInit {
  @Input() activeRide: RouteDailyRow;
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'routes-change-accompany';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly routesTableService = inject(RoutesTableService);
  private readonly routesCommonService = inject(RoutesCommonService);
  private readonly trackingService = inject(TrackingService);
  private readonly headerDataService = inject(HeaderDataService);

  config = cloneDeep(routesChangeAccompanyComponentConfig);
  form: UntypedFormGroup;
  accompanyType = RoutesChangeAccompanyType;
  accompaniesFullOptions: USelectSItem[] = cloneDeep(this.config.accompaniesFullOptions);
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  uButtonSize = UButtonSize;

  ngOnInit() {
    if (!this.activeRide.isFullManualRide) {
      this.getAccompanies();
    }

    this.createForm();

    if (this.activeRide) {
      this.updateForm(this.activeRide);
      this.updateCheckDays(this.activeRide);
    }
  }

  get changesForm(): UntypedFormGroup {
    return this.form.get('datesChange') as UntypedFormGroup;
  }

  createForm() {
    this.form = this.fb.group({
      routeId: [ '' ],
      accompanyId: [ this.accompanyType.Without ],
      sourceType: [ RoutesChangesAccompanySourceStationType.FirstStation ],
      returnHome: [ RoutesChangeAccompanyReturn.No ],
      type: [ RoutesChangeType.Unplanned, Validators.required ],
      datesChange: this.fb.group({
        dates: [ [] ],
        dateFrom: [ '' ],
        dateTo: [ '' ],
        type: [ this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay ],
        availablePresets: [ this.config.availableDatePresets ],
        checkDaysActive: [ [] ],
        checkDaysAvailable: [ [] ]
      }),
      comment: [ '' ]
    });
  }

  updateForm(route: RouteDailyRow) {
    const { accompany, routeId, needAccompany } = route;

    if (!accompany && needAccompany) {
      const accompanyForm = this.form.get('accompanyId');

      accompanyForm.setValidators([
        Validators.required
      ]);

      accompanyForm.patchValue('required');
    }

    if (accompany) {
      if (!accompany.value && !accompany.name) {
        this.form.patchValue({
          accompanyId: null
        });
      } else {
        this.form.patchValue({
          accompanyId: accompany.value
        });
      }
    }

    if (routeId) {
      this.form.patchValue({
        routeId
      });
    }

    this.form.patchValue({
      sourceType: route.accompany?.sourceType,
      returnHome: route.accompany?.returnHome
    });
  }

  updateCheckDays(route: RouteDailyRow) {
    const { routeStartDate, routeEndDate, days } = route;
    const activeDate = this.headerDataService.getActiveDate();

    this.changesForm.patchValue({
      dates: [ activeDate ],
      dateFrom: routeStartDate,
      dateTo: routeEndDate,
      checkDaysActive: [ moment(activeDate).get('day') ],
      checkDaysAvailable: days
    });
  }

  getAccompanies() {
    this.routesTableService.getAccompaniesList()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        if (res.success) {
          this.accompaniesFullOptions = [
            ...this.accompaniesFullOptions,
            ...res.value
          ];
        }
      });
  }

  submit(emailSendType?: RoutesChangeEmailSendType) {
    const { accompanyId, type } = this.form.value;

    this.trackingService.track(`[Track daily view change] - ${type === RoutesChangeType.Planned ? 'fixed' : 'temp'} change - accompany changed`);

    if (accompanyId === this.accompanyType.Remove) {
      this.resetAccompany(false, emailSendType);
    } else if (accompanyId === this.accompanyType.Without) {
      this.resetAccompany(true, emailSendType);
    } else if (accompanyId === this.accompanyType.Required) {
      this.setRequiredAccompany(emailSendType);
    } else {
      this.saveAccompany(emailSendType);
    }
  }

  saveAccompany(emailSendType?: RoutesChangeEmailSendType) {
    const { accompanyId, type, routeId, comment, sourceType, returnHome } = this.form.value;
    const dates = this.changesForm.get('dates').value;
    const checkDaysActive = this.changesForm.get('checkDaysActive').value;
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
    const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    const body = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        accompanyId,
        comment,
        type,
        dateFrom,
        dateTo,
        days: checkDaysActive,
        sourceType,
        returnHome
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routesTableService.changeAccompany(body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  setRequiredAccompany(emailSendType?: RoutesChangeEmailSendType) {
    const { type, routeId, comment } = this.form.value;
    const dates = this.changesForm.get('dates').value;
    const checkDaysActive = this.changesForm.get('checkDaysActive').value;
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
    const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    const body = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        type,
        comment,
        dateFrom,
        dateTo,
        resetRequiredFlag: false,
        days: checkDaysActive
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routesTableService.changeAccompanySetRequired(body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  resetAccompany(resetRequiredFlag: boolean, emailSendType?: RoutesChangeEmailSendType) {
    const { type, routeId, comment } = this.form.value;
    const dates = this.changesForm.get('dates').value;
    const checkDaysActive = this.changesForm.get('checkDaysActive').value;
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
    const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    const body = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        type,
        comment,
        dateFrom,
        dateTo,
        resetRequiredFlag,
        days: checkDaysActive
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routesTableService.changeResetAccompany(body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  updatePeriod(data: any) {
    this.changesForm.patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });
  }

  updateDates({ dates, checkDaysActive }): void {
    const datesStore: string[] = this.changesForm.get('dates').value;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        this.changesForm.patchValue({
          dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
          checkDaysActive
        });
      }
    }
  }
}
