const DICTIONARY_PATH: string = 'builder.full.skippedPassengers';

const trackingId: string = 'Route planner';

const dictionary = {
  title: `${DICTIONARY_PATH}.title`,
  subTitle: `${DICTIONARY_PATH}.subTitle`,
  table: {
    columns: {
      name: `${DICTIONARY_PATH}.table.columns.name`,
      phone: `${DICTIONARY_PATH}.table.columns.phone`,
      address: `${DICTIONARY_PATH}.table.columns.address`,
      destination: `${DICTIONARY_PATH}.table.columns.destination`,
      reason: `${DICTIONARY_PATH}.table.columns.reason`
    }
  },
  actions: {
    download: `${DICTIONARY_PATH}.actions.download`,
    close: `${DICTIONARY_PATH}.actions.close`
  },
  skipReason: {
    PassengerUnknown: `${DICTIONARY_PATH}.skipReason.PassengerUnknown`,
    PassengerStationUnknown: `${DICTIONARY_PATH}.skipReason.PassengerStationUnknown`,
    TargetStationUnknown: `${DICTIONARY_PATH}.skipReason.TargetStationUnknown`,
    PassengerCityNotAvailable: `${DICTIONARY_PATH}.skipReason.PassengerCityNotAvailable`,
    PassengerNoDemands: `${DICTIONARY_PATH}.skipReason.PassengerNoDemands`,
    NoPathAvailable: `${DICTIONARY_PATH}.skipReason.NoPathAvailable`,
    AlgorithmTimeout: `${DICTIONARY_PATH}.skipReason.AlgorithmTimeout`,
    AlgorithmFailed: `${DICTIONARY_PATH}.skipReason.AlgorithmFailed`,
    InternalError: `${DICTIONARY_PATH}.skipReason.InternalError`,
    NoActiveContract: `${DICTIONARY_PATH}.skipReason.NoActiveContract`,
    NoAvailableVehicles: `${DICTIONARY_PATH}.skipReason.NoAvailableVehicles`,
    ReservationAddressTypeNotAllowed: `${DICTIONARY_PATH}.skipReason.ReservationAddressTypeNotAllowed`,
    AmbiguousContractArea: `${DICTIONARY_PATH}.skipReason.AmbiguousContractArea`,
    NoMatchingTemplate: `${DICTIONARY_PATH}.skipReason.NoMatchingTemplate`,
    PassengerIsTravelingAlone: `${DICTIONARY_PATH}.skipReason.PassengerIsTravelingAlone`,
    UndefinedSchedule: `${DICTIONARY_PATH}.skipReason.UndefinedSchedule`
  }
};

const columns = [
  {
    prop: 'name',
    name: dictionary.table.columns.name,
    minWidth: 109,
    maxWidth: 109,
    hideColumn: false
  },
  {
    prop: 'phone',
    name: dictionary.table.columns.phone,
    minWidth: 95,
    maxWidth: 95
  },
  {
    prop: 'passengerStation',
    name: dictionary.table.columns.address,
    minWidth: 136,
    maxWidth: 136
  },
  {
    prop: 'targetStation',
    name: dictionary.table.columns.destination,
    maxWidth: 116,
    minWidth: 116
  },
  {
    prop: 'skipReason',
    name: dictionary.table.columns.reason,
    minWidth: 123,
    cellTemplateName: 'reasonColumn'
  }
];

export const builderFullSkippedPassengersComponentConfig = {
  trackingId,
  dictionary,
  columns
};
