<div #demandsTable class="demands-shifts-view-table">
  <app-table-page
    *ngIf="initialized()"
    class="table-page_demands-shifts-view"
    [config]="config().tablePageConfig"
    (refresh)="refresh()"
    (scrollTable)="onScrollTable($event)"
  ></app-table-page>
</div>

<div 
  *ngIf="demandsCommonService.backdrop$ | async" 
  class="demands-shifts-view-backdrop"
  (click)="demandsCommonService.clickOnBackdrop()"
></div>

<ng-template
  #dayCellHeader
  ngx-datatable-header-template
  let-column="column"
>
  <span
    class="demands-shifts-view-table-day-column-header"
  >
    <span class="demands-shifts-view-table-day-column-header__day">{{(config().dictionary.weekDays + '.' + [column.dayOfWeek]) | translate}}</span>
    <span class="demands-shifts-view-table-day-column-header__date">{{column.date}}</span>
  </span>
</ng-template>

<ng-template 
  #dayCell 
  ngx-datatable-cell-template
  let-value="value"
  let-column="column"
  let-row="row"
>
  <app-demands-passengers-day
    class="demands-shifts-view-table-day-cell"
    [featureType]="featureType()"
    [departments]="value" 
    [date]="column.dateISO"
    [dayOfWeek]="column.dayOfWeek"
    [shift]="row"
    [departmentGroupHeights]="rowDepartmentGroupHeights()[row.shiftId + '-' + row.branchId + '-' + row.startTime + '-' + row.endTime]"
  ></app-demands-passengers-day>
</ng-template>

<ng-template #hoursCell ngx-datatable-cell-template let-row="row">
  <div
    class="demands-shifts-view-table-hours-cell"
  >
    <div class="demands-shifts-view-table-hours-cell__text">{{row.hours}}</div>
    <div class="demands-shifts-view-table-hours-cell__days">{{row.days | obData: (config().dictionary.weekDays | translate) }}</div>
  </div>
</ng-template>

<ng-template #shiftCell ngx-datatable-cell-template let-row="row">
  <div
    class="demands-shifts-view-table-shift-cell"
  >
    <div class="demands-shifts-view-table-shift-cell__name">{{row.shiftName}}</div>
    <div class="demands-shifts-view-table-shift-cell__branch-name">{{row.branchName}}</div>
  </div>
</ng-template>