import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/shared/services';
import { FileSaverService } from '@app/files/services';
import { AccompanyAddEdit, AccompanyAddEditResponse, AccompanyExportToExcelBody, AccompanyResponse } from '@app/accompanies/models';

@Injectable({
  providedIn: 'root'
})
export class AccompaniesService {
  private BASE_PATH: string = 'Accompanies';

  constructor(
    private apiService: ApiService,
    private fileSaverService: FileSaverService
  ) {}

  getAccompanies(): Observable<AccompanyResponse> {
    return this.apiService.get(this.BASE_PATH);
  }

  getAccompany(id: number): Observable<AccompanyAddEditResponse> {
    return this.apiService.get(`${this.BASE_PATH}/${id}`);
  }

  saveAccompany(data: AccompanyAddEdit): Observable<AccompanyAddEditResponse> {
    return this.apiService.post(this.BASE_PATH, data);
  }

  deleteAccompanies(ids: number[]): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Delete`, ids);
  }

  exportToExcel(body: AccompanyExportToExcelBody, fileName: string): Observable<Blob> {
    return this.apiService.postBlob(`${this.BASE_PATH}/Export/Excel`, body, 'blob')
      .pipe(
        tap(res => this.fileSaverService.downloadBlobFile(res, fileName))
      );
  }
}
