import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

import { PassengerRoute, PassengersRoutesPopupAction } from '@app/passengers/models';
import { PassengersRoutesPopupComponent } from '@app/passengers/components/shared/passengers-routes-popup/passengers-routes-popup.component';

@Injectable({
  providedIn: 'root'
})
export class PassengersRoutesPopupService {
  constructor(
    private bsModalService: BsModalService
  ) {}

  showRoutesPopup(passengerId: number, passengerRoutes: PassengerRoute[]): Observable<PassengersRoutesPopupAction> {
    return this.bsModalService.show(
      PassengersRoutesPopupComponent,
      {
        class: 'u-modal_app-routes-popup',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: {
          passenger: {
            passengerRoutes,
            passengerId
          }
        }
      }
    ).content.action;
  }
}

