import { inject, Pipe, PipeTransform } from '@angular/core';

import { AuthDataService } from '@app/auth/services';
import { AddEditModalField } from '@app/shared/models';

@Pipe({
  name: 'addEditModalFieldsCheckFeature',
  standalone: true
})
export class AddEditModalFieldsCheckFeaturePipe implements PipeTransform {
  private readonly authDataService = inject(AuthDataService);

  transform(fields: AddEditModalField[]): AddEditModalField[] {
    if (fields) {
      return fields.filter(field => !field.dataField?.feature || this.authDataService.checkFeature(field.dataField?.feature));
    }

    return [];
  }
}
