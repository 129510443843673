import { DestroyRef, Injectable, inject } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { first, switchMap, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AuthDataService } from '@app/auth/services';
import { NotesService } from '@app/notes/services';
import { NotesItem } from '@app/notes/models';
import { RemindersService } from '@app/reminder/services';
import { ReminderComponent } from '@app/reminder/components';
import { Reminder } from '@app/reminder/models';

@Injectable({
  providedIn: 'root'
})
export class ReminderDataService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly authDataService = inject(AuthDataService);
  private readonly notesService = inject(NotesService);
  private readonly modalService = inject(BsModalService);
  private readonly reminderService = inject(RemindersService);

  private isInited: boolean;
  private currentReminderIndex: number = 0;

  notes: NotesItem[];
  reminders: Reminder[];
  modalRef: BsModalRef;

  private checkReminder(reminder: Reminder) {
    if (this.currentReminderIndex < this.reminders.length) {
      if (moment(reminder.dtDateTime).isSameOrBefore(moment())) {
        this.openReminderModal(reminder);

        this.modalRef.content.closeAction
          .pipe(first())
          .subscribe(() => {
            this.modalRef.hide();

            this.notes.splice(this.notes.findIndex(note => note.iNoteId === this.reminders[this.currentReminderIndex].iNoteId));
            this.reminders.splice(this.currentReminderIndex, 1);

            this.currentReminderIndex++;

            this.checkReminder(this.reminders[this.currentReminderIndex]);
          });
      } else {
        this.currentReminderIndex++;

        this.checkReminder(this.reminders[this.currentReminderIndex]);
      }
    }
  }

  private openReminderModal(reminder: Reminder) {
    this.modalRef = this.modalService.show(
      ReminderComponent,
      {
        class: 'u-modal u-modal_popup u-modal_app-reminder',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          reminder,
          note: this.notes.find(note => note.iReminderId === reminder.iReminderId)
        }
      }
    );
  }


  init() {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        switchMap(() => forkJoin([ this.reminderService.getAllReminders(), this.notesService.getAllNotes() ])),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([ reminders, notes ]) => {
        this.isInited = true;
        this.notes = notes;
        this.reminders = reminders;

        this.checkReminder(this.reminders[this.currentReminderIndex]);
      });
  }

  checkForReminders() {
    if (this.isInited) {
      this.currentReminderIndex = 0;

      this.checkReminder(this.reminders[this.currentReminderIndex]);
    }
  }
}
