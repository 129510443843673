import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { DemandsStoreService } from '@app/demands/services';
import { DemandsView } from '@app/demands/models';

@Component({
  selector: 'app-demands',
  templateUrl: './demands.component.html'
})
export class DemandsComponent implements OnInit, OnDestroy {
  public readonly demandsStoreService = inject(DemandsStoreService);

  demandsView = DemandsView;

  ngOnInit() {
    this.demandsStoreService.updateShowViewToggle(true);
  }

  ngOnDestroy() {
    this.demandsStoreService.updateShowViewToggle(false);
  }
}
