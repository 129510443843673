import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';

import { HeaderMenuIconsService, LocalizationService } from '@app/shared/services';
import { AuthDataService } from '@app/auth/services';
import { AuthModuleName, AuthModuleRouteBuilderFeature } from '@app/auth/models';
import { BuilderCommonService, BuilderDataService, BuilderRoutesStoreService } from '@app/builder/services';
import { BuilderBuildMode } from '@app/builder/models';
import { builderDefaultComponentConfig } from './builder-default.component.config';

@Component({
  selector: 'app-builder-default',
  templateUrl: './builder-default.component.html',
  styleUrls: [ './builder-default.component.scss', './builder-default.component.rtl.scss' ]
})
export class BuilderDefaultComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses: string = 'builder-default';

  private unsubscribe: Subject<void> = new Subject();

  isRtl: boolean = true;
  builderBuildMode = BuilderBuildMode;
  config = cloneDeep(builderDefaultComponentConfig);

  constructor(
    public builderDataService: BuilderDataService,
    private localizationService: LocalizationService,
    public builderRoutesStoreService: BuilderRoutesStoreService,
    private headerMenuIconsService: HeaderMenuIconsService,
    private authDataService: AuthDataService,
    public builderCommonService: BuilderCommonService
  ) {}

  ngOnInit() {
    this.isRtl = this.localizationService.isRtl();

    this.getBuilderFeatureType();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getBuilderFeatureType() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.RouteBuilder, AuthModuleRouteBuilderFeature.Type)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(type => {
        this.headerMenuIconsService.setMenuIconsByTemplate(
          (this.config[type] || this.config.default).buildMode[this.builderDataService.config().buildMode]?.headerMenuIconsTemplate
        );
      });
  }
}
