import { ChangeDetectorRef, ComponentFactoryResolver, ViewContainerRef, ViewRef, Injectable, signal } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { UGridService, UGridSort, UGridStorageProps, USidebarMenuService } from '@shift/ulib';

import { HeaderDataService } from '@app/shared/services/header-data.service';
import { AddEditModalService } from '@app/shared/services/add-edit-modal.service';
import { AddEditModalAction, TablePageRowEditMode, ExportToExcelObject, TablePageConfig } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class TablePageService {
  #resetColumnsFilter = signal(false);
  #specificRowClassObjects = signal([]);
  #filteredRows = signal([]);

  readonly resetColumnsFilter = this.#resetColumnsFilter.asReadonly();
  readonly specificRowClassObjects = this.#specificRowClassObjects.asReadonly();
  readonly filteredRows = this.#filteredRows.asReadonly();

  stickyRow;
  stickyRowHeight$: Observable<number>;
  tableId: string;
  rows: any[] = [];
  tablePageConfig: TablePageConfig;
  editRowData: any;
  isAddEditMode: boolean;
  selectedRows: any[] = [];
  modalContainer: ViewContainerRef;
  tableOffsetY: number;
  tableSorts: UGridSort[];
  totalCount: number;
  scrollToRowIndex: number;
  addEditModalAction: Subject<{ type: AddEditModalAction; data: any; }> = new Subject();

  constructor(
    private bsModalService: BsModalService,
    private headerDataService: HeaderDataService,
    private addEditModalService: AddEditModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private uGridService: UGridService,
    private uSidebarMenuService: USidebarMenuService
  ) {}

  initRows(rows?: any[]): void {
    this.rows = rows || this.rows || [];

    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
      this.cdRef.markForCheck();
    }
  }

  deleteRow(id: string | number): void {
    this.rows = this.rows.filter(row => row.id !== id);
    this.selectedRows = this.selectedRows.filter(row => row.id !== id);

    this.initRows();
  }

  deleteRows(ids: number[]): void {
    this.rows = this.rows.filter(row => !ids.includes(row.id));
    this.selectedRows = this.selectedRows.filter(row => !ids.includes(row.id));

    this.initRows();
  }

  updateSelectedRows(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  openAddEditModal(modalComponent, tablePageService: TablePageService, editData?, isEditMode?: boolean): void {
    this.closeConfirmModal()
      .pipe(
        take(1),
        filter(isClosed => isClosed)
      )
      .subscribe(() => this.initNewAddEditModal(modalComponent, tablePageService, editData, isEditMode));
  }

  closeConfirmModal(): Observable<boolean> {
    if (this.modalContainer.length) {
      return this.addEditModalService.closeConfirmModal()
        .pipe(
          take(1),
          map(res => !!res.action)
        );
    }

    return of(true);
  }

  private onActionChange(type: AddEditModalAction, data) {
    this.addEditModalAction.next({ type, data });

    switch (type) {
      case AddEditModalAction.Add: {
        this.onAddRow(data);

        break;
      }

      case AddEditModalAction.Edit: {
        this.onEditRow(data);

        break;
      }

      case AddEditModalAction.Delete: {
        this.deleteRow(data.id);
        this.closeAddEditModal();

        break;
      }

      case AddEditModalAction.ShutDown: {
        this.closeAddEditModal();

        break;
      }
    }
  }

  private initNewAddEditModal(modalComponent, tablePageService: TablePageService, editData?, isEditMode?: boolean) {
    this.modalContainer.clear();

    this.editRowData = editData;
    this.addEditModalService.isEditMode = isEditMode !== undefined ? isEditMode : !!editData;

    const editMode = this.tablePageConfig.tableConfig.editRowMode || TablePageRowEditMode.Modal;

    this.addEditModalService.editMode = editMode;

    if (editMode === TablePageRowEditMode.Modal) {
      this.addEditModalService.modalRef = this.bsModalService.show(
        modalComponent,
        {
          class: `u-modal u-modal_content u-modal_app-add-edit-modal${this.uSidebarMenuService.getCollapsedValue() ? ' u-modal_content-hide-menu' : '' }`,
          animated: true,
          ignoreBackdropClick: true,
          backdrop: true,
          keyboard: false,
          initialState: {
            editData,
            tablePageService,
            addEditModalService: this.addEditModalService
          }
        }
      );

      this.addEditModalService.modalRef.content.action.subscribe(({ type, data }) => this.onActionChange(type, data));
    } else {
      this.toggleAddEditMode(true);

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalComponent);
      const componentRef: any = this.modalContainer.createComponent(componentFactory);
      const modalComponentRef = componentRef.instance;

      modalComponentRef.editData = editData;
      modalComponentRef.tablePageService = tablePageService;
      modalComponentRef.addEditModalService = this.addEditModalService;

      modalComponentRef.action.subscribe(({ type, data }) => this.onActionChange(type, data));
    }

    this.cdRef.markForCheck();
  }

  closeAddEditModal() {
    this.editRowData = null;

    this.clearRowHighlight();
    this.modalContainer.clear();
    this.addEditModalService.reset();
    this.toggleAddEditMode(false);
  }

  private onAddRow(newRowObj) {
    if (newRowObj) {
      this.initRows([ newRowObj, ...this.rows ]);
      this.highlightRow(newRowObj.id);
    }
  }

  onEditRow(newRowObj): void {
    if (newRowObj) {
      if (!this.rows.find(row => row.id === newRowObj.id)) {
        this.onAddRow(newRowObj);

        return;
      }

      this.rows = this.rows.map(row => row.id === newRowObj.id ? { ...row, ...newRowObj } : row);

      this.cdRef.markForCheck();
    }
  }

  updateRows(rowsToUpdate): void {
    this.rows = this.rows.map(row => {
      const rowToUpdate = rowsToUpdate.find(updatedRow => row.id === updatedRow.id);

      return rowToUpdate ? { ...row, ...rowToUpdate } : row;
    });

    this.cdRef.markForCheck();
  }

  highlightRow(rowId: number, freeze?: boolean) {
    this.#specificRowClassObjects.update(state => [
      ...state,
      {
        className: 'highlighted-row',
        rowPropertyName: 'id',
        value: rowId
      }
    ]);

    if (!freeze) {
      setTimeout(() => this.clearRowHighlight(), 3000);
    }
  }

  clearRowHighlight() {
    this.#specificRowClassObjects.set([]);
  }

  clearSelectedRows() {
    this.selectedRows = [];
  }

  updateFilteredRows(rows) {
    this.#filteredRows.set(rows);
  }

  updateResetColumnsFilter(value: boolean) {
    this.#resetColumnsFilter.set(value);
  }

  getExcelExportObject(): Observable<ExportToExcelObject> {
    return forkJoin([
      this.translateService.get(this.tablePageConfig.dictionary.exportToExcel.columns),
      this.translateService.get(this.tablePageConfig.dictionary.exportToExcel.excelFileName)
    ])
      .pipe(map(([ translatedValues, excelFileName ]) => {
        const columns = this.tablePageConfig.tableConfig.columns
          .filter(column =>
            !column.hideColumn &&
            column.prop !== 'check' &&
            column.prop !== 'filter' &&
            (this.tablePageConfig.tableConfig.skipExportToExcelColumns ? !this.tablePageConfig.tableConfig.skipExportToExcelColumns.includes(column.prop) : true)
          )
          .map(column => ({ field: column.prop, alias: translatedValues[column.propAlias ? column.propAlias : column.prop] }));

        return {
          data: {
            columns,
            ids: this.selectedRows.map(row => row.id)
          },
          fileName: `${excelFileName}.xlsx`
        };
      }));
  }

  toggleAddEditMode(isAddEditMode: boolean = !this.isAddEditMode) {
    this.isAddEditMode = isAddEditMode;
    this.headerDataService.updateShowGlobalSearch(!isAddEditMode);

    this.tablePageConfig.tableConfig.columns = this.tablePageConfig.tableConfig.columns.map(column => column.prop === 'check' ? { ...column, hideColumn: isAddEditMode } : column);

    const gridStorageProps: UGridStorageProps = {
      saveTableProps: true,
      propName: 'columnsStoreVisible',
      tableName: this.tableId,
      userId: this.uGridService.getUserIdFromLocalStorage()
    };
    const colStoreVisible = this.uGridService.getTablePropFromLocalStorage(gridStorageProps);

    if (colStoreVisible) {
      this.uGridService.saveTablePropToLocalStorage({
        ...gridStorageProps,
        value: this.tablePageConfig.tableConfig.columns.filter(col => !col.hideColumn && (isAddEditMode ? colStoreVisible.includes(col.prop) : true)).map(col => col.prop)
      });
    }
  }

  scrollToRow(index: number) {
    this.scrollToRowIndex = index;
  }

  updateTotalCount(totalCount: number) {
    this.totalCount = totalCount;
  }
}
