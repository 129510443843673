import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { Kpi } from '@app/kpi/models';

@Injectable({
  providedIn: 'root'
})
export class KpiService {
  private BASE_PATH: string = 'KPI';

  constructor(private apiService: ApiService) {}

  getAll(date: string): Observable<Kpi> {
    return this.apiService.get(`${this.BASE_PATH}`, { date }, true);
  }
}
