import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UAddressAutocompleteItem, UAddressAutocompleteItemById, UAddressAutocompleteLoaderService } from '@shift/ulib';

import { GeoDataService } from '@app/shared/services/api/geo-data.service';

@Injectable({
  providedIn: 'root'
})
export class AddressAutocompleteService implements UAddressAutocompleteLoaderService {
  useFetchItemById: boolean;

  constructor(
    private geoDataService: GeoDataService
  ) {}

  fetchItems = (text: string): Observable<UAddressAutocompleteItem[]> => this.geoDataService.autocomplete(text)
    .pipe(
      map(response => response.map(address => ({
        name: address.fullAddress,
        data: {
          lat: address.latitude,
          lng: address.longitude,
          id: address.placeId
        }
      })))
    );

  fetchItemById(placeId: string): Observable<UAddressAutocompleteItemById> {
    return of(null);
  }
}
