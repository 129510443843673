import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, WritableSignal, computed, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin, from, of, Subject, Subscription } from 'rxjs';
import { filter, first, map, mergeAll, pairwise, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { UPopupService, USelectSItem } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { CustomerDataService, LocalizationService, TrackingService, ValidationService, HeaderDataService } from '@app/shared/services';
import { AppLanguage, CustomerDataType, Errors } from '@app/shared/models';
import { StationsService } from '@app/stations/services';
import { AuthModuleDemandsPassengersViewFeatureType, AuthModuleDemandsShiftsViewFeatureType } from '@app/auth/models';
import {
  DemandsShiftsViewInitialDataType,
  DemandsShiftsViewPassenger,
  DemandsShiftsViewPassengerPickUpSettings,
  DemandsShiftsViewShiftDemand,
  DemandsShiftsViewShiftRow,
  DemandsBulkPassengerShift,
  DemandsBulkPassengersShiftsParams,
  DemandsConfirmationPopupValues,
  DemandsDayOfWeek,
  DemandsDemandType,
  DemandsPassengerBulkShiftUpdate,
  DemandsPassengerShift,
  DemandsPassengerShiftAdvancedAddress,
  DemandsPassengerShiftAdvancedAddressType,
  DemandsPassengerShiftAdvancedRepeatInfo,
  DemandsPassengerShiftRepeatType,
  DemandsPassengerShiftSeriesUpdateType,
  DemandsPassengerShiftUpdate,
  DemandsShift,
  DemandsShiftRepeatOptionsActionType,
  DemandsTableRow,
  DemandsView
} from '@app/demands/models';
import { DemandsShiftsViewService, DemandsCommonService, DemandsPassengersViewService, DemandsAssignShiftDataService } from '@app/demands/services';
import { demandsConfig } from '@app/demands/configs';
import { demandsAssignShiftComponentConfig } from './demands-assign-shift.component.config';

@Component({
  selector: 'app-demands-assign-shift',
  templateUrl: './demands-assign-shift.component.html',
  styleUrls: [ './demands-assign-shift.component.scss', 'demands-assign-shift.component.rtl.scss' ]
})
export class DemandsAssignShiftComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() date: string;
  @Input() featureType: AuthModuleDemandsPassengersViewFeatureType | AuthModuleDemandsShiftsViewFeatureType;
  @Input() dayOfWeek: DemandsDayOfWeek;
  @Input() passenger: DemandsTableRow;
  @Input() passengers: DemandsTableRow[];
  @Input() editData: DemandsPassengerShift | DemandsBulkPassengerShift;
  @Input() assignPassengerDemand: boolean;
  @Input() shift: DemandsShiftsViewShiftRow;
  @Input() passengerShiftDemand: DemandsShiftsViewShiftDemand;
  @Input() view: DemandsView;

  @Output() closeAction: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'demands-assign-shift';

  private startTimeChangeFromShift: boolean;
  private endTimeChangeFromShift: boolean;
  private shiftsArr: DemandsShift[];
  private unsubscribe: Subject<void> = new Subject();

  #departments: WritableSignal<{ id: number; name: string; }[]> = signal([]);
  #passengers: WritableSignal<DemandsShiftsViewPassenger[]> = signal([]);
  #passengerPickUpSettings: WritableSignal<DemandsShiftsViewPassengerPickUpSettings> = signal(undefined);

  readonly departmentsItems = computed(() => [
    {
      value: null,
      name: this.config.dictionary.without
    },
    ...this.#departments().map(department => ({ value: department.id, name: department.name }))
  ]);
  readonly passengersItems = computed(() =>
    this.#passengers().map(passenger => ({ value: passenger.memberId, name: passenger.name, subtitle: passenger.mobile }))
  );
  readonly passengerPickUpSettings = this.#passengerPickUpSettings.asReadonly();

  config;
  dictionary;
  form: UntypedFormGroup;
  branches: USelectSItem[] = [];
  shifts: USelectSItem[] = [];
  stations: USelectSItem[] = [];
  advanced: boolean;
  repeatInfo: boolean;
  timeChange: Subscription;
  shiftChange: Subscription;
  passengerShiftAdvancedAddressType = DemandsPassengerShiftAdvancedAddressType;
  passengerShiftRepeatType = DemandsPassengerShiftRepeatType;
  datesRange;
  availableDays: DemandsDayOfWeek[] = [];
  lang: AppLanguage = this.localizationService.getLanguage();
  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private destroyRef: DestroyRef,
    private formBuilder: UntypedFormBuilder,
    private customerDataService: CustomerDataService,
    private demandsPassengersViewService: DemandsPassengersViewService,
    private demandsCommonService: DemandsCommonService,
    private stationsService: StationsService,
    private uPopupService: UPopupService,
    private trackingService: TrackingService,
    private validationService: ValidationService,
    private cd: ChangeDetectorRef,
    private localizationService: LocalizationService,
    private headerDataService: HeaderDataService,
    private demandsAssignShiftDataService: DemandsAssignShiftDataService,
    private demandsShiftsViewService: DemandsShiftsViewService
  ) {}

  ngOnInit() {
    this.getInitialData();
    this.onBackdropClick();
    this.initConfig();
    this.initForm();
    this.initCustomerData();
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getInitialData() {
    if (this.assignPassengerDemand) {
      forkJoin([
        this.demandsShiftsViewService.getInitialData([ DemandsShiftsViewInitialDataType.Departments ]),
        this.demandsShiftsViewService.getPassengers(this.passengerShiftDemand?.departmentId),
        this.passengerShiftDemand?.passengerId ? this.demandsShiftsViewService.getPassengerPickUpSettings(this.passengerShiftDemand?.passengerId) : of(null)
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(([ { departments }, passengers, passengerPickUpSettings ]) => {
          this.#departments.set(departments);
          this.#passengers.set(passengers);
          this.#passengerPickUpSettings.set(passengerPickUpSettings);

          this.updatePassengerPickupSettingsForm(passengerPickUpSettings);
        });
    }
  }

  onBackdropClick() {
    this.demandsCommonService
      .backdropClick$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.closePopup());
  }

  initConfig() {
    this.config = cloneDeep(demandsAssignShiftComponentConfig[this.view || DemandsView.Passengers][this.featureType] || demandsAssignShiftComponentConfig[this.view || DemandsView.Passengers].default);
    this.dictionary = this.config.dictionary;
    this.datesRange = cloneDeep(this.config.datesRange);
  }

  initCustomerData() {
    this.customerDataService
      .getCustomerData({ types: [ CustomerDataType.Branches ] })
      .pipe(
        first(),
        map(data => data.branches.map(branch => ({ name: branch.name, value: branch.id })))
      )
      .subscribe(branches => {
        this.branches = branches;

        if (this.editData) {
          if (this.branches.length === 1) {
            this.form.get('branchId').disable();
          }

          if (this.editData.advancedSettings.repeatInfo) {
            this.repeatInfo = true;
          }
        } else {
          if (this.branches.length === 1) {
            this.form.get('branchId').patchValue(this.branches[0].value);
            this.form.get('branchId').disable();
          } else {
            if (this.assignPassengerDemand && this.shift.branchId !== null) {
              this.form.get('branchId').patchValue(this.shift.branchId);
            }

            if (!this.assignPassengerDemand && this.passenger.branchId !== null) {
              this.form.get('branchId').patchValue(this.passenger.branchId);
            }
          }
        }

        this.onFormChanges();
        this.getShifts();
      });

    this.stationsService
      .getSavedStations()
      .pipe(
        first(),
        map(stations => stations.map(station => ({ name: station.name, value: station.stationId })))
      )
      .subscribe(data => {
        this.stations = data;
      });
  }

  getShifts() {
    const params = {
      branchId: this.form.get('branchId').value,
      dayOfWeek: this.dayOfWeek
    };

    (this.assignPassengerDemand ? this.demandsShiftsViewService.getShifts(params) : this.demandsPassengersViewService.getShifts(params))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(shifts => {
        this.shiftsArr = shifts;
        this.shifts = [ ...this.config.options.shifts, ...shifts.map(shift => ({ name: shift.name, value: shift.id })) ];
      });
  }

  initForm() {
    this.form = this.getPassengerShiftForm();

    if (this.assignPassengerDemand) {
      this.form.addControl('departmentId', new UntypedFormControl({ value: this.passengerShiftDemand?.departmentId || null, disabled: !!this.editData }));
      this.form.addControl('passengerId', new UntypedFormControl({ value: this.passengerShiftDemand?.passengerId, disabled: !!this.editData }, [ Validators.required ]));

      this.form.get('departmentId').valueChanges
        .pipe(
          switchMap(departmentId => this.demandsShiftsViewService.getPassengers(departmentId)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(passengers => {
          this.form.get('passengerId').patchValue(null);

          this.#passengers.set(passengers);
        });

      this.form.get('passengerId').valueChanges
        .pipe(
          switchMap(passengerId => this.demandsShiftsViewService.getPassengerPickUpSettings(passengerId)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(passengerPickUpSettings => {
          this.#passengerPickUpSettings.set(passengerPickUpSettings);

          this.updatePassengerPickupSettingsForm(passengerPickUpSettings);
        });
    }

    if (this.passenger) {
      this.form.addControl('passengerId', new UntypedFormControl(this.passenger.passengerId));
    }

    if (this.passengers) {
      this.form.addControl('passengerIds', new UntypedFormControl(this.passengers.map(passenger => passenger.passengerId)));
    }
  }

  updatePassengerPickupSettingsForm(passengerPickUpSettings: DemandsShiftsViewPassengerPickUpSettings) {
    const address: DemandsPassengerShiftAdvancedAddress = new DemandsPassengerShiftAdvancedAddress({
      address: {
        fullAddress: passengerPickUpSettings?.address?.fullAddress,
        latitude: passengerPickUpSettings?.address?.latitude,
        longitude: passengerPickUpSettings?.address?.longitude
      },
      stationId: passengerPickUpSettings?.station?.id
    });

    this.form.get('advancedSettings.pickUpPassengerAddress').patchValue(address);
    this.form.get('advancedSettings.dropOffPassengerAddress').patchValue(address);
  }

  getPassengerShiftForm(): UntypedFormGroup {
    const address: DemandsPassengerShiftAdvancedAddress = this.passenger ?
      new DemandsPassengerShiftAdvancedAddress({
        address: {
          fullAddress: this.passenger.address,
          latitude: this.passenger.latitude,
          longitude: this.passenger.longitude
        },
        stationId: this.passenger.stationId
      }) : new DemandsPassengerShiftAdvancedAddress();

    let data: DemandsPassengerShift = {
      ...new DemandsPassengerShift(),
      date: this.date,
      dayOfWeek: this.dayOfWeek,
      passengerId: this.assignPassengerDemand ? this.passengerShiftDemand?.passengerId : this.passenger.passengerId,
      advancedSettings: {
        pickUpPassengerAddress: address,
        dropOffPassengerAddress: address,
        repeatInfo: null
      }
    };

    if (!this.editData && this.assignPassengerDemand) {
      data = {
        ...data,
        branchId: this.shift.branchId,
        shiftId: this.shift.shiftId,
        startTime: this.shift.startTime,
        endTime: this.shift.endTime
      };
    }

    const initialData = this.editData ? {
      ...this.editData,
      type: this.editData.type === DemandsDemandType.Reservation ? DemandsDemandType.Regular : this.editData.type
    } : data;

    const { repeatInfo } = initialData.advancedSettings;

    this.datesRange.dates = repeatInfo && repeatInfo.startDate && repeatInfo.endDate ? [ repeatInfo.startDate, repeatInfo.endDate ] : [];
    this.datesRange.checkDaysActive = repeatInfo && repeatInfo.activeDays || [];
    this.availableDays = repeatInfo ? this.generateDaysOfWeek(repeatInfo.startDate, repeatInfo.endDate) : [];

    return this.generateForm(initialData);
  }

  generateDaysOfWeek(startDate: string, endDate: string) {
    if (!startDate || !endDate) {
      return [];
    }

    const daysOfWeek: DemandsDayOfWeek[] = [];
    let startDateClone = new Date(moment(startDate).startOf('day').toDate());
    const endDateClone = new Date(moment(endDate).startOf('day').toDate());

    while (startDateClone <= endDateClone && daysOfWeek.length < 7) {
      const dayOfWeek = <DemandsDayOfWeek>moment(startDateClone).startOf('day').day();

      if (!daysOfWeek.includes(dayOfWeek)) {
        daysOfWeek.push(dayOfWeek);
      }

      startDateClone = moment(startDateClone).add(1, 'day').toDate();
    }

    return daysOfWeek;
  }

  generateForm(data: DemandsPassengerShift) {
    const form = this.formBuilder.group({
      demandId: [ data.demandId ],
      branchId: [ { value: data.branchId, disabled: !!this.assignPassengerDemand }, [ Validators.required ] ],
      shiftId: [ { value: data.shiftId, disabled: !!this.assignPassengerDemand }, [ ValidationService.numberOrNull ] ],
      startTime: [ { value: data.startTime, disabled: !!this.assignPassengerDemand } ],
      endTime: [ { value: data.endTime, disabled: !!this.assignPassengerDemand } ],
      date: [ data.date ],
      dayOfWeek: [ data.dayOfWeek ],
      comesIndependent: [ data.comesIndependent ],
      leavesIndependent: [ data.leavesIndependent ],
      advancedSettings: this.formBuilder.group({
        pickUpPassengerAddress: this.generateAddressForm(data.advancedSettings.pickUpPassengerAddress),
        dropOffPassengerAddress: this.generateAddressForm(data.advancedSettings.dropOffPassengerAddress)
      }),
      type: [ data.type ]
    });

    if (data.advancedSettings.repeatInfo) {
      (<UntypedFormGroup>form.get('advancedSettings'))
        .setControl('repeatInfo', this.generateRepeatInfoForm(data.advancedSettings.repeatInfo));

      form.get('type').patchValue(DemandsDemandType.Repeatable);
    }

    const validators = [
      this.validationService.conditionalValidator(() => !form.get('startTime').value && !form.get('endTime').value, Validators.required)
    ];

    form.get('startTime').setValidators(validators);
    form.get('endTime').setValidators(validators);

    form.get('startTime')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(form.get('startTime').value),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next)),
        tap(() => {
          if (!this.startTimeChangeFromShift) {
            this.trackAssign('Change start time');
          }

          this.startTimeChangeFromShift = false;
        })
      )
      .subscribe(() => {
        form.get('endTime').updateValueAndValidity();
      });

    form.get('endTime')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(form.get('endTime').value),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next)),
        tap(() => {
          if (!this.endTimeChangeFromShift) {
            this.trackAssign('Change end time');
          }

          this.endTimeChangeFromShift = false;
        })
      )
      .subscribe(() => {
        form.get('startTime').updateValueAndValidity();
      });

    return form;
  }

  generateAddressForm(data: DemandsPassengerShiftAdvancedAddress): UntypedFormGroup {
    const form = this.formBuilder.group({
      addressType: [ data.addressType ],
      address: this.formBuilder.group({
        fullAddress: [ data.address && data.address.fullAddress ],
        latitude: [ data.address && data.address.latitude ],
        longitude: [ data.address && data.address.longitude ],
        placeId: [ data.address && data.address.placeId ]
      }),
      stationId: [ data.stationId ]
    });

    form.get('stationId').setValidators([
      this.validationService.conditionalValidator(
        () => form.get('addressType').value === this.passengerShiftAdvancedAddressType.SavedStation,
        Validators.required
      )
    ]);

    const addressFields = [ 'fullAddress', 'latitude', 'longitude' ];

    for (const addressField of addressFields) {
      form.get(`address.${addressField}`).setValidators([
        this.validationService.conditionalValidator(
          () => form.get('addressType').value === this.passengerShiftAdvancedAddressType.AnyAddress,
          Validators.required
        )
      ]);
    }

    form.get('addressType')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(form.get('addressType').value),
        pairwise()
      )
      .subscribe(([ prev ]) => {
        if (prev === this.passengerShiftAdvancedAddressType.AnyAddress) {
          form.get('address').patchValue({
            fullAddress: null,
            latitude: 0,
            longitude: 0
          });
        }

        if (prev === this.passengerShiftAdvancedAddressType.SavedStation) {
          form.get('address').patchValue({
            stationId: null
          });
        }

        form.get('stationId').updateValueAndValidity();

        for (const addressField of addressFields) {
          form.get(`address.${addressField}`).updateValueAndValidity();
        }
      });

    return form;
  }

  generateRepeatInfoForm(data: DemandsPassengerShiftAdvancedRepeatInfo): UntypedFormGroup {
    const form = this.formBuilder.group({
      startDate: [ data.startDate, [ Validators.required ] ],
      endDate: [ data.endDate, [ Validators.required ] ],
      activeDays: [ data.activeDays ],
      repeatType: [ data.repeatType ],
      repeatPeriod: [ data.repeatPeriod ]
    });

    form
      .get('repeatType')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        pairwise()
      )
      .subscribe(([ prev, next ]: DemandsPassengerShiftRepeatType[]) => {
        if (prev === DemandsPassengerShiftRepeatType.Days && next === DemandsPassengerShiftRepeatType.Weeks) {
          this.datesRange.checkDaysActive = this.editData && this.editData.advancedSettings.repeatInfo ?
            this.editData.advancedSettings.repeatInfo.activeDays : [ <DemandsDayOfWeek>moment(this.date).startOf('day').day() ];
        }
      });

    return form;
  }

  track(message: string) {
    this.trackingService.track(`[${this.config.tracking.id}] - ${message}`);
  }

  trackAssign(message: string) {
    this.track(`Assign - ${message}`);
  }

  trackEditRepeatShift(message: string) {
    this.track(`Edit repeat ${this.config.tracking.shift} - ${message}`);
  }

  onFormChanges() {
    this.form.get('branchId')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        tap(() => this.trackAssign(`Select ${this.config.tracking.branch}`)),
        switchMap((branchId: number) => this.demandsPassengersViewService.getShifts({ branchId, dayOfWeek: this.dayOfWeek }))
      )
      .subscribe(shifts => {
        this.shiftsArr = shifts;
        this.shifts = [ ...this.config.options.shifts, ...shifts.map(shift => ({ name: shift.name, value: shift.id })) ];
        this.form.get('shiftId').patchValue(-1);
      });

    this.onShiftChange();
    this.onTimeChange();

    const fieldsTrackMessages = {
      'comesIndependent': 'Mark as comes independently',
      'leavesIndependent': 'Mark as leaves independently',
      'advancedSettings.pickUpPassengerAddress.addressType': 'Change pickup point',
      'advancedSettings.dropOffPassengerAddress.addressType': 'Change drop-off point'
    };

    for (const field of Object.keys(fieldsTrackMessages)) {
      this.form.get(field)
        .valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => this.trackAssign(fieldsTrackMessages[field]));
    }
  }

  onShiftChange() {
    this.shiftChange = this.form.get('shiftId')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        tap(() => this.trackAssign(`Select ${this.config.tracking.shift}`)),
        startWith(this.form.get('shiftId').value),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next)),
        map(arr => arr[arr.length - 1]),
        switchMap((shiftId: number) => this.demandsPassengersViewService.getTime({ shiftId, dayOfWeek: this.dayOfWeek }))
      )
      .subscribe(time => {
        if (this.timeChange) {
          this.timeChange.unsubscribe();
        }

        this.startTimeChangeFromShift = true;
        this.endTimeChangeFromShift = true;

        this.form.patchValue({
          startTime: time && time.startTime || null,
          endTime: time && time.endTime || null
        });

        this.onTimeChange();
      });
  }

  onTimeChange() {
    this.timeChange = from([ 'startTime', 'endTime' ])
      .pipe(
        takeUntil(this.unsubscribe),
        map(item => this.form.get(item).valueChanges),
        mergeAll()
      )
      .subscribe(() => {
        this.getShiftByTime();
      });
  }

  getShiftByTime() {
    this.demandsPassengersViewService
      .getShiftByTime({
        branchId: this.form.get('branchId').value,
        startTime: this.form.get('startTime').value,
        endTime: this.form.get('endTime').value,
        dayOfWeek: this.dayOfWeek
      })
      .pipe(first())
      .subscribe(shiftId => {
        if (this.shiftChange) {
          this.shiftChange.unsubscribe();
        }

        this.form.get('shiftId').patchValue(Number.isInteger(shiftId) ? shiftId : null);
      });
  }

  toggleAdvanced() {
    this.trackAssign('Click on advanced');
    this.advanced = !this.advanced;
  }

  showRepeatInfo() {
    if (!this.form.get('advancedSettings.repeatInfo')) {
      const repeatInfo: DemandsPassengerShiftAdvancedRepeatInfo = {
        ...new DemandsPassengerShiftAdvancedRepeatInfo(),
        startDate: this.date,
        endDate: moment(this.date).startOf('day').add(1, 'years').format(AppConstants.DATE_FORMAT_ISO),
        activeDays: [ <DemandsDayOfWeek>moment(this.date).startOf('day').day() ]
      };

      (<UntypedFormGroup>this.form.get('advancedSettings'))
        .setControl('repeatInfo', this.generateRepeatInfoForm(repeatInfo));

      this.datesRange.dates = repeatInfo && repeatInfo.startDate && repeatInfo.endDate ? [ repeatInfo.startDate, repeatInfo.endDate ] : [];
      this.datesRange.checkDaysActive = repeatInfo && repeatInfo.activeDays || [];
      this.availableDays = repeatInfo ? this.generateDaysOfWeek(repeatInfo.startDate, repeatInfo.endDate) : [];

      this.form.get('type').patchValue(DemandsDemandType.Repeatable);
    }

    this.repeatInfo = true;
    this.track(`Click on repeat ${this.config.tracking.shift}`);
  }

  hideRepeatInfo() {
    this.repeatInfo = false;

    if (!this.editData || this.form.get('advancedSettings.repeatInfo').dirty) {
      (<UntypedFormGroup>this.form.get('advancedSettings')).removeControl('repeatInfo');

      this.datesRange.dates = [];
      this.datesRange.checkDaysActive = [];
      this.availableDays = [];

      this.form.get('type').patchValue(DemandsDemandType.Regular);
    }
  }

  onDatesChange(value) {
    this.form.get('advancedSettings.repeatInfo').patchValue({
      startDate: value.dates[0],
      endDate: value.dates[value.dates.length - 1],
      activeDays: value.checkDaysActive
    });

    this.form.get('advancedSettings.repeatInfo').markAsDirty();

    this.availableDays = value.dates.length ? this.generateDaysOfWeek(value.dates[0], value.dates[value.dates.length - 1]) : [];

    Object.assign(this.datesRange, value);
  }

  activeDaysChange() {
    this.form.get('advancedSettings.repeatInfo').patchValue({
      activeDays: this.datesRange.checkDaysActive
    });
  }

  closePopup() {
    if (this.form.dirty) {
      this.demandsAssignShiftDataService.openConfirmationPopup({ message: this.dictionary.closeConfirm })
        .pipe(
          first(),
          filter(value => value)
        )
        .subscribe(() => {
          this.closeAction.emit();
        });
    } else {
      this.closeAction.emit();
    }
  }

  apply() {
    if (this.form.valid) {
      if (this.passengerShiftDemand) {
        this.validateAndSubmitPassengerShift(false, true);
      }

      if (this.passenger) {
        this.validateAndSubmitPassengerShift();
      }

      if (this.passengers) {
        this.submitPassengersShifts();
      }
    }
  }

  validateAndSubmitPassengerShift(assignOverlap?: boolean, passengerDemand?: boolean) {
    const repeatInfo = this.form.get('advancedSettings.repeatInfo');

    of(repeatInfo && repeatInfo.value || null)
      .pipe(
        first(),
        switchMap(info => info ? this.validateRepeatPeriod() : of(info)),
        switchMap(
          () => repeatInfo && this.editData && this.editData.type === DemandsDemandType.Repeatable ?
            this.demandsAssignShiftDataService.shiftRepeatOptions(true, repeatInfo.dirty)
              .pipe(
                filter(({ type, value }) => type === DemandsShiftRepeatOptionsActionType.Submit && value !== null),
                tap(({ value }) => {
                  const trackMessages = {
                    [DemandsPassengerShiftSeriesUpdateType.CurrentAndFutureDemands]: `Edit ${this.config.tracking.shift} and all future`,
                    [DemandsPassengerShiftSeriesUpdateType.AllDemands]: 'Edit series'
                  };

                  if (trackMessages[value]) {
                    this.trackEditRepeatShift(trackMessages[value]);
                  }
                }),
                map(({ value }) => value)
              )
            :
            of(repeatInfo && this.editData && this.editData.type !== DemandsDemandType.Repeatable ? DemandsPassengerShiftSeriesUpdateType.AllDemands : null)
              .pipe(
                tap(() => {
                  if (this.form.get('advancedSettings.repeatInfo')) {
                    this.track('Save repeating shift');
                  }
                })
              )
        ),
        switchMap(
          (seriesUpdateType: DemandsPassengerShiftSeriesUpdateType) => {
            const value = this.form.getRawValue();
            const params = {
              ...value,
              seriesUpdateType,
              advancedSettings: {
                ...value.advancedSettings,
                dropOffPassengerAddress: {
                  ...value.advancedSettings.dropOffPassengerAddress,
                  address: value.advancedSettings.dropOffPassengerAddress.addressType === this.passengerShiftAdvancedAddressType.AnyAddress ? value.advancedSettings.dropOffPassengerAddress.address : null
                },
                pickUpPassengerAddress: {
                  ...value.advancedSettings.pickUpPassengerAddress,
                  address: value.advancedSettings.pickUpPassengerAddress.addressType === this.passengerShiftAdvancedAddressType.AnyAddress ? value.advancedSettings.pickUpPassengerAddress.address : null
                }
              },
              assignOverlap
            };

            return this.editData ?
              (passengerDemand ? this.demandsShiftsViewService.updatePassengerDemand(params) : this.demandsPassengersViewService.updatePassengerShift(params)) :
              (passengerDemand ? this.demandsShiftsViewService.savePassengerDemand(params) : this.demandsPassengersViewService.savePassengerShift(params));
          }
        )
      )
      .subscribe(
        res => {
          this.closeAction.emit();

          if (passengerDemand) {
            const value = this.form.getRawValue();

            this.demandsCommonService.assignPassengerDemandUpdate({
              shiftId: value.shiftId,
              branchId: value.branchId,
              startTime: value.startTime,
              endTime: value.endTime
            });
          } else {
            const data = <DemandsTableRow | DemandsPassengerShiftUpdate>res;

            this.demandsCommonService.assignShiftUpdate(
              [ 'passenger' in data ? data.passenger : data ],
              'passengersRides' in data && data.passengersRides
            );
          }
        },
        error => {
          if (error.code === Errors.OverlapShift) {
            this.uPopupService.showMessage(
              {
                message: error.description,
                yes: this.config.dictionary.assign,
                no: this.config.dictionary.cancel
              },
              () => this.validateAndSubmitPassengerShift(true, passengerDemand),
              () => this.closeAction.emit()
            );
          }
        }
      );
  }

  validateRepeatPeriod() {
    return this.demandsPassengersViewService
      .validateRepeatPeriod({
        shiftId: this.form.getRawValue().shiftId,
        repeatInfo: this.form.get('advancedSettings.repeatInfo').value
      })
      .pipe(
        switchMap(valid =>
          !valid.isValid ? this.demandsAssignShiftDataService.openConfirmationPopup({
            message: this.dictionary.shiftIsNotInPeriod.message,
            yes: this.dictionary.shiftIsNotInPeriod.edit,
            no: this.dictionary.shiftIsNotInPeriod.discard
          }, {
            ...new DemandsConfirmationPopupValues(),
            close: true
          })
            .pipe(
              tap(value => {
                if (!value) {
                  this.closeAction.emit();
                }
              }),
              filter(() => false)
            ) : of(true)
        )
      );
  }

  submitPassengersShifts() {
    const params: DemandsBulkPassengersShiftsParams = this.getBulkPassengersShiftsParams();

    this.demandsPassengersViewService.saveBulkPassengersShifts(params)
      .subscribe(passengers => this.onSaveBulkPassengersShifts(passengers));
  }

  onSaveBulkPassengersShifts(updates: DemandsPassengerBulkShiftUpdate) {
    const formValue = this.form.getRawValue();
    const repeatInfo = formValue.advancedSettings.repeatInfo;
    const branchObj = this.branches.find(branch => branch.value === formValue.branchId);
    const shiftObj = this.shiftsArr.find(shift => shift.id === formValue.shiftId);

    if (formValue.type === DemandsDemandType.Repeatable) {
      const weekRange = this.headerDataService.getWeekSwitchChangeValue();
      const repeatStartDateClone = moment(repeatInfo.startDate);
      const cardId = (this.editData instanceof DemandsBulkPassengerShift && this.editData.cardId) || uuidv4();

      while (repeatStartDateClone.isSameOrBefore(moment(weekRange.endDate))) {
        const dayOfWeek = <DemandsDayOfWeek>repeatStartDateClone.get('day');

        if (shiftObj && shiftObj.activeDays ? shiftObj.activeDays.includes(dayOfWeek) && repeatInfo.activeDays.includes(dayOfWeek) : repeatInfo.activeDays.includes(dayOfWeek)) {
          const passengerDemand = updates.passengers
            .find(passenger => passenger.demands?.find(demand => demand.dayOfWeek === dayOfWeek))
            ?.demands.find(demand => demand.shiftId === formValue.shiftId && demand.dayOfWeek === dayOfWeek);

          this.demandsCommonService.bulkShiftAssignments[dayOfWeek].push({
            ...formValue,
            startTime: passengerDemand?.startTime,
            endTime: passengerDemand?.endTime,
            createDate: moment(),
            demandType: this.form.get('type').value,
            branchName: branchObj && branchObj.name,
            shiftName: shiftObj && shiftObj.name,
            cardId
          });
        }

        repeatStartDateClone.add(1, 'days');
      }
    } else {
      const passengerDemand = updates.passengers
        .find(passenger => passenger.demands?.find(demand => demand.dayOfWeek === this.dayOfWeek))
        ?.demands.find(demand => demand.shiftId === formValue.shiftId && demand.dayOfWeek === this.dayOfWeek);

      this.demandsCommonService.bulkShiftAssignments[this.dayOfWeek].push({
        ...formValue,
        startTime: passengerDemand?.startTime,
        endTime: passengerDemand?.endTime,
        createDate: moment(),
        demandType: this.form.get('type').value,
        branchName: branchObj && branchObj.name,
        shiftName: shiftObj && shiftObj.name,
        cardId: (this.editData instanceof DemandsBulkPassengerShift && this.editData.cardId) || uuidv4()
      });
    }

    this.closeAction.emit();
    this.demandsCommonService.calculateBulkRowHeight();
    this.demandsCommonService.assignShiftUpdate(updates.passengers, updates.passengersRides);
  }

  getBulkPassengersShiftsParams(): DemandsBulkPassengersShiftsParams {
    const formValue = this.form.getRawValue();
    const passengerIds = formValue.passengerIds;
    const existingShifts: DemandsPassengerShift[] = [];

    delete formValue['passengerIds'];

    this.setAddressRequestObj(formValue);

    if (this.editData) {
      formValue.demandId = null;

      if (this.editData.type === DemandsDemandType.Repeatable) {
        demandsConfig.daysOfWeek.forEach(day => {
          this.demandsCommonService.bulkShiftAssignments[day] = this.demandsCommonService.bulkShiftAssignments[day]
            .filter(shift => shift.cardId !== this.editData['cardId']);
        });
      } else {
        this.demandsCommonService.bulkShiftAssignments[this.dayOfWeek] = this.demandsCommonService.bulkShiftAssignments[this.dayOfWeek]
          .filter(shift => shift.cardId !== this.editData['cardId']);
      }
    }

    Object.keys(this.demandsCommonService.bulkShiftAssignments).forEach(day => {
      existingShifts.push(...this.demandsCommonService.bulkShiftAssignments[day].map(shift => {
        const shiftObj = new DemandsPassengerShift(cloneDeep(shift));

        delete shiftObj.passengerId;
        delete shiftObj.seriesUpdateType;

        this.setAddressRequestObj(shiftObj);

        return shiftObj;
      }));
    });

    const params: DemandsBulkPassengersShiftsParams = {
      passengerIds,
      shifts: [ ...existingShifts, formValue ]
    };

    delete params.shifts['passengerIds'];

    return params;
  }

  setAddressRequestObj(shift: DemandsPassengerShift) {
    if (shift.advancedSettings.pickUpPassengerAddress.address && shift.advancedSettings.pickUpPassengerAddress.address.fullAddress === null) {
      shift.advancedSettings.pickUpPassengerAddress.address = null;
    }

    if (shift.advancedSettings.dropOffPassengerAddress.address && shift.advancedSettings.dropOffPassengerAddress.address.fullAddress === null) {
      shift.advancedSettings.dropOffPassengerAddress.address = null;
    }
  }

  delete() {
    if (this.passenger || this.passengerShiftDemand) {
      this.deleteConfirmPopup(!this.repeatInfo)
        .pipe(
          switchMap((seriesUpdateType: DemandsPassengerShiftSeriesUpdateType) => {
            const { passengerId, demandId } = this.form.getRawValue();
            const params = {
              passengerId,
              demandId,
              seriesUpdateType
            };

            return this.passenger ? this.demandsPassengersViewService.deletePassengerShift(params) : this.demandsShiftsViewService.deletePassengerDemand(params);
          })
        )
        .subscribe(res => {
          this.closeAction.emit();

          if (this.passenger) {
            const data = <DemandsPassengerShiftUpdate>res;

            this.demandsCommonService.assignShiftUpdate([ data.passenger ], data.passengersRides);
          }

          if (this.passengerShiftDemand) {
            const value = this.form.getRawValue();

            this.demandsCommonService.assignPassengerDemandUpdate({
              shiftId: value.shiftId,
              branchId: value.branchId,
              startTime: value.startTime,
              endTime: value.endTime
            });
          }
        });
    }

    if (this.passengers) {
      const formValue = this.form.getRawValue();
      const passengerIds = formValue.passengerIds;

      delete formValue['passengerIds'];

      this.setAddressRequestObj(formValue);

      this.deleteConfirmPopup(true)
        .pipe(
          switchMap(() => {
            const shift = cloneDeep(this.demandsCommonService.bulkShiftAssignments[this.dayOfWeek].find(shiftObj => shiftObj.cardId === this.editData['cardId']));

            delete shift['passengerIds'];

            this.setAddressRequestObj(shift);

            return this.demandsPassengersViewService.deleteBulkPassengersShifts({
              shift,
              passengerIds
            });
          })
        )
        .subscribe(update => {
          if (this.editData.type === DemandsDemandType.Repeatable) {
            demandsConfig.daysOfWeek.forEach(day => this.deleteExistingBulkShift(day));
          } else {
            this.deleteExistingBulkShift(this.dayOfWeek);
          }

          this.closeAction.emit();
          this.demandsCommonService.calculateBulkRowHeight();
          this.demandsCommonService.assignShiftUpdate(update.passengers, update.passengersRides);
        });
    }
  }

  deleteExistingBulkShift(day: DemandsDayOfWeek) {
    const existingShiftIndex = this.demandsCommonService.bulkShiftAssignments[day]
      .findIndex(shift => shift.cardId === this.editData['cardId']);

    if (existingShiftIndex >= 0) {
      this.demandsCommonService.bulkShiftAssignments[day].splice(existingShiftIndex, 1);
    }
  }

  deleteConfirmPopup(isBulkMode?: boolean) {
    return this.demandsAssignShiftDataService.openConfirmationPopup({ message: this.dictionary.deleteShiftConfirm })
      .pipe(
        first(),
        filter(value => value),
        switchMap(() =>
          this.editData && this.form.get('type').value === DemandsDemandType.Repeatable && !isBulkMode ?
            this.demandsAssignShiftDataService.shiftRepeatOptions()
              .pipe(
                filter(({ type, value }) => type === DemandsShiftRepeatOptionsActionType.Submit && value !== null),
                tap(({ value }) => {
                  const trackMessages = {
                    [DemandsPassengerShiftSeriesUpdateType.OnlyCurrentDemand]: 'Delete occurrence',
                    [DemandsPassengerShiftSeriesUpdateType.CurrentAndFutureDemands]: `Delete ${this.config.tracking.shift} and all future`,
                    [DemandsPassengerShiftSeriesUpdateType.AllDemands]: 'Delete series'
                  };

                  if (trackMessages[value]) {
                    this.trackEditRepeatShift(trackMessages[value]);
                  }
                }),
                map(({ value }) => value)
              ) : of(null)
        ));
  }
}
