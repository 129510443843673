import {
  Input,
  Output,
  Component,
  HostBinding,
  OnInit,
  OnChanges,
  ElementRef,
  EventEmitter
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { NotesListItem } from '@app/notes/models';
import { noteComponentConfig } from './note.component.config';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: [ './note.component.scss', './note.component.rtl.scss' ]
})
export class NoteComponent implements OnInit, OnChanges {
  @Input() note: NotesListItem;

  @Output() remove: EventEmitter<number> = new EventEmitter();
  @Output() noteRead: EventEmitter<number> = new EventEmitter();
  @Output() noteSave: EventEmitter<NotesListItem> = new EventEmitter();
  @Output() noteUpdate: EventEmitter<NotesListItem> = new EventEmitter();
  @Output() clickInsideNote: EventEmitter<number> = new EventEmitter();
  @Output() clickOutsideNote: EventEmitter<number> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'note';

  text: UntypedFormControl;
  isFocused: boolean;
  config = cloneDeep(noteComponentConfig);

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.text = new UntypedFormControl(this.note.text);
  }

  ngOnChanges() {
    if (this.note.id && !this.note.isRead) {
      this.checkReadNotes();
    }
  }

  private checkReadNotes() {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting === true) {
        this.noteRead.emit(this.note.id);
        observer.disconnect();
      }
    }, { threshold: [ 0 ] });

    if (this.elementRef.nativeElement) {
      observer.observe(this.elementRef.nativeElement);
    }
  }

  updateFocused(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  removeNote(event: MouseEvent) {
    event.stopPropagation();

    this.remove.emit(this.note.id);
  }

  onClickInsideNote(noteTextarea: HTMLElement) {
    noteTextarea.style.height = `${noteTextarea.scrollHeight}px`;

    this.clickInsideNote.emit(this.note.id);
  }

  onClickOutsideNote(noteTextarea: HTMLElement) {
    noteTextarea.style.height = '64px';

    if (this.note.allowEdit && this.text.dirty) {
      if (this.note.id >= 0) {
        this.noteUpdate.emit({ ...this.note, text: this.text.value });
      } else {
        this.noteSave.emit({ ...this.note, text: this.text.value });
      }
    }

    this.clickOutsideNote.emit(this.note.id);

    this.updateFocused(false);
  }
}
