import { URangePreset } from '@shift/ulib';

import { AuthCustomerType, AuthModulePassengersFeatureType } from '@app/auth/models';
import { RoutesMovePassengersSaveAction, RoutesMovePassengersSaveActionType } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.movePassengers';

const saveAndCloseSaveAction: RoutesMovePassengersSaveAction = {
  value: RoutesMovePassengersSaveActionType.SaveAndClose,
  name: `${DICTIONARY_PATH}.saveAndClose`
};

const saveAndSendToSCSaveAction: RoutesMovePassengersSaveAction = {
  value: RoutesMovePassengersSaveActionType.SaveAndSendToSC,
  name: `${DICTIONARY_PATH}.saveAndSendSC`
};

const saveActions: RoutesMovePassengersSaveAction[] = [
  saveAndCloseSaveAction,
  saveAndSendToSCSaveAction,
  { value: RoutesMovePassengersSaveActionType.Save, name: `${DICTIONARY_PATH}.save` }
];

export const routesMovePassengersConfig = {
  trackingId: '[Route table, Move passenger]',
  defaultForm: {
    typeChange: null,
    dates: [],
    dateFrom: '',
    dateTo: '',
    type: '',
    availablePresets: [
      URangePreset.DisplayedDay,
      URangePreset.Today,
      URangePreset.Tomorrow,
      URangePreset.ActiveWeekDay,
      URangePreset.FromTodayOn,
      URangePreset.FromCustomDayOn,
      URangePreset.All,
      URangePreset.Custom,
      URangePreset.UpcomingWeek
    ],
    checkDaysActive: [],
    checkDaysAvailable: [],
    typeFilter: null
  },
  saveButton: {
    actions: saveActions,
    mainAction: {
      default: saveAndSendToSCSaveAction,
      [AuthCustomerType.Municipality]: saveAndCloseSaveAction
    }
  },
  mandatoryFilterFeatureTypes: [ AuthModulePassengersFeatureType.Iec, AuthModulePassengersFeatureType.Student ]
};
