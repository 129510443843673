export interface ShuttleCompanyFineNew {
  price: number;
  fineId: number;
  routeId: number;
  comment: string;
  rideDate: string;
  contractNumber: string;
}

export interface ShuttleCompanyFineData {
  shuttleCompanyName: string;
  contracts: ShuttleCompanyFineDataContract[];
}

export interface ShuttleCompanyFineDataContract {
  id?: number;
  name: string;
  value?: string;
}
