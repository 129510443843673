import { inject, Pipe, PipeTransform } from '@angular/core';

import { AddEditModalColumn } from '@app/shared/models';
import { AuthDataService } from '@app/auth/services';

@Pipe({
  name: 'addEditModalColumnsCheckFeature',
  standalone: true
})
export class AddEditModalColumnsCheckFeaturePipe implements PipeTransform {
  private readonly authDataService = inject(AuthDataService);

  transform(columns: AddEditModalColumn[]): AddEditModalColumn[] {
    if (columns) {
      return columns.filter(column => !column?.feature || this.authDataService.checkFeature(column?.feature));
    }

    return [];
  }
}
